import React, { type ReactElement } from 'react'
import { ThreeDots } from 'react-loader-spinner'

const Dots = (): ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ThreeDots height="30" width="30" radius="9" color="#9BB0F9" ariaLabel="three-dots-loading" wrapperStyle={{}} />
    </div>
  )
}

export default Dots
