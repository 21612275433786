import React, { forwardRef } from 'react'
import { StyleTableHeaderCell } from './styles'
import { TableHeaderCellProps } from './types'

export const TableHeaderCell = forwardRef<HTMLTableCellElement, TableHeaderCellProps>(
  ({ children, ...props }, ref): React.ReactElement => {
    return (
      <StyleTableHeaderCell ref={ref} {...props}>
        {children}
      </StyleTableHeaderCell>
    )
  }
)
export default TableHeaderCell
