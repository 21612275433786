import React from 'react'
import { LabelProps } from './types'
import { StyledLabelContainer, StyledLabelText } from './styles'

const Label = (props: LabelProps): React.ReactElement => {
  return (
    <StyledLabelContainer color={props.color}>
      <StyledLabelText variant="h4">{props.text}</StyledLabelText>
    </StyledLabelContainer>
  )
}

export default Label
