import { Typography, Button, Link, Grid, styled } from '@precis-digital/kurama'
import { useCurrentClient } from 'shared/context/ClientContext'
import React, { useState } from 'react'
import PendingInvites from 'workspace/components/WorkspaceDetails/PendingInvitesList'
import MembersTable from 'workspace/components/WorkspaceDetails/MembersTable'
import { Role } from 'shared/components/Role'
import { useAuth } from 'shared/context/AuthContext'
import { useQueryMember } from 'workspace/api'
import Dots from 'shared/components/Loader/Dots'
import InviteNewMembersPopup from '../Popup/InviteNewMembersPopup'
import { useTranslation } from 'shared/translations'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import { ensureAbsoluteUrl } from 'shared/utils'
import { makeErrorToast } from 'shared/components/Toaster'
import { useChangePath } from 'shared/components/Router'
import PERMISSIONS from 'shared/constants/permissions'
import { APP_ROUTES } from 'shared/routes'

const WorkspaceDetails = (): React.ReactElement => {
  const { currentClient, isLoading: isClientLoading } = useCurrentClient()
  const { currentUser, isLoading: isLoadingAuth } = useAuth()
  const { data: member, isLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const { changePath } = useChangePath()

  const handleUnauthorized = (): React.ReactElement => {
    makeErrorToast(
      'Only admins can access the workspace details page. Therefore, you have been redirected to the home page.',
      {
        persistThroughRouteChange: true
      }
    )
    void changePath(APP_ROUTES.home.basePage)
    return <> </>
  }

  if (isLoading || isClientLoading || isLoadingAuth) {
    return (
      <Grid container>
        <Dots />
      </Grid>
    )
  }

  return (
    <Role
      authorizedMemberRoles={PERMISSIONS.VIEW_WORKSPACE_DETAILS}
      memberRole={member?.role}
      onUnauthorized={handleUnauthorized}
    >
      <StyledAdminPageContainer>
        <StyledWorkSpaceContainer container>
          <StyledWorkSpaceAvatarContainer>
            <AvatarWithLabel size="large" client={currentClient} />
            <Grid className="description">
              <Typography variant="h2">{currentClient.name}</Typography>
              <Link variant="body3" href={ensureAbsoluteUrl(currentClient?.url ?? '')} target="_blank" rel="noreferrer">
                {currentClient?.url ?? ''}
              </Link>
            </Grid>
          </StyledWorkSpaceAvatarContainer>
        </StyledWorkSpaceContainer>
        <TabContent tabIndex={0} clientId={currentClient.id} />
      </StyledAdminPageContainer>
    </Role>
  )
}

const TabContent = ({ tabIndex, clientId }: { tabIndex: number; clientId: string }): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('workspace')
  switch (tabIndex) {
    case 0:
    default:
      return (
        <>
          <StyledMembersListContainer container>
            <Typography variant="h2">{t('titles.members')}</Typography>
            <StyledTabsContent item>
              <Grid>
                <Button
                  variant="filled"
                  onClick={() => {
                    setIsOpen(true)
                  }}
                >
                  {t('buttons.inviteMembers')}
                </Button>
                <InviteNewMembersPopup
                  open={isOpen}
                  onClose={() => {
                    setIsOpen(false)
                  }}
                />
              </Grid>
              <Typography className="description" variant="body3">
                {t('descriptions.inviteMembers')}
              </Typography>
            </StyledTabsContent>
            <MembersTable clientId={clientId} />
            <PendingInvites clientId={clientId} />
          </StyledMembersListContainer>
        </>
      )
  }
}

const StyledAdminPageContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 24
}))

const StyledWorkSpaceContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px 0px 24px',
  gap: 24,
  height: 88,
  flex: 'none',
  alignSelf: 'stretch'
}))
const StyledWorkSpaceAvatarContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  height: 64,
  flex: 'none'
}))
const StyledMembersListContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px 0px 24px',
  gap: 24,
  height: 96,
  flex: 'none',
  alignSelf: 'stretch',
  '& h2': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1px 0px 3px',
    gap: 10,
    height: 40,
    flex: 'none'
  }
}))
const StyledTabsContent = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'flex-end',
  gap: 8,
  height: 72,
  flex: 'none',
  display: 'inline-flex',
  marginLeft: 'auto',
  flexDirection: 'column',
  '.description': {
    height: '24px',
    color: theme.palette.neutrals.stone120
  }
}))

export default WorkspaceDetails
