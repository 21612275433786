export type OperatorType =
  | 'equals'
  | 'not_equals'
  | 'contains'
  | 'not_contains'
  | 'starts_with'
  | 'not_starts_with'
  | 'ends_with'
  | 'not_ends_with'
  | 'matches_regex'
  | 'not_matches_regex'

export type DimensionType = 'source_medium' | 'source' | 'medium' | 'campaign' | 'hostname'

export interface Conditions {
  /**
   * The ID of the operator used
   */
  id: OperatorType | DimensionType
  /**
   * The label or descriptive name for the operator ID
   */
  label: string
}

export const DIMENSIONS_FIELDS: { [type in DimensionType]: Conditions } = {
  source_medium: {
    label: 'Source / Medium',
    id: 'source_medium'
  },
  source: {
    label: 'Source',
    id: 'source'
  },
  medium: {
    label: 'Medium',
    id: 'medium'
  },
  campaign: {
    label: 'Campaign',
    id: 'campaign'
  },
  hostname: {
    label: 'Hostname',
    id: 'hostname'
  }
}

export const DIMENSION_OPERATORS: { [type in OperatorType]: Conditions } = {
  equals: {
    id: 'equals',
    label: 'Exactly matches'
  },
  not_equals: {
    id: 'not_equals',
    label: 'Does not exactly match'
  },
  contains: {
    id: 'contains',
    label: 'Contains'
  },
  not_contains: {
    id: 'not_contains',
    label: 'Does not contain'
  },
  starts_with: {
    id: 'starts_with',
    label: 'Starts with'
  },
  not_starts_with: {
    id: 'not_starts_with',
    label: 'Does not start with'
  },
  ends_with: {
    id: 'ends_with',
    label: 'Ends with'
  },
  not_ends_with: {
    id: 'not_ends_with',
    label: 'Does not end with'
  },
  matches_regex: {
    id: 'matches_regex',
    label: 'Matches regex'
  },
  not_matches_regex: {
    id: 'not_matches_regex',
    label: 'Does not match regex'
  }
}

export type OperatorInputType = 'string' | 'single_select' | 'multi_select'
export interface OperatorWithInputType {
  id: string
  label: string
  type: OperatorInputType
}

export type ExtendedOperatorType = OperatorType | 'is' | 'not_is' | 'one_of' | 'not_one_of'

export const DIMENSION_OPERATORS_WITH_TYPES: Record<ExtendedOperatorType, OperatorWithInputType> = {
  equals: {
    ...DIMENSION_OPERATORS.equals,
    type: 'string'
  },
  not_equals: {
    ...DIMENSION_OPERATORS.not_equals,
    type: 'string'
  },
  contains: {
    ...DIMENSION_OPERATORS.contains,
    type: 'string'
  },
  not_contains: {
    ...DIMENSION_OPERATORS.not_contains,
    type: 'string'
  },
  matches_regex: {
    ...DIMENSION_OPERATORS.matches_regex,
    type: 'string'
  },
  not_matches_regex: {
    ...DIMENSION_OPERATORS.not_matches_regex,
    type: 'string'
  },
  is: {
    label: 'Is',
    id: 'is',
    type: 'single_select'
  },
  not_is: {
    label: 'Is not',
    id: 'not_is',
    type: 'single_select'
  },
  one_of: {
    label: 'Is one of',
    id: 'one_of',
    type: 'multi_select'
  },
  not_one_of: {
    label: 'Is not one of',
    id: 'not_one_of',
    type: 'multi_select'
  },
  starts_with: {
    label: 'Starts with',
    id: 'starts_with',
    type: 'string'
  },
  not_starts_with: {
    label: 'Does not start with',
    id: 'not_starts_with',
    type: 'string'
  },
  ends_with: {
    label: 'Ends with',
    id: 'ends_with',
    type: 'string'
  },
  not_ends_with: {
    label: 'Does not end with',
    id: 'not_ends_with',
    type: 'string'
  }
}
