import JsCookie from 'js-cookie'
import config from 'shared/config'

const AUTH_COOKIES_EXPIRY_DAYS = config('NEXT_PUBLIC_AUTH_COOKIES_EXPIRY_DAYS') as number
const BASE_DOMAIN_URL = config('NEXT_PUBLIC_BASE_DOMAIN_URL') as string

const MS_IN_A_DAY = 864e5

export const getDefaultCookieAttributes = (): { expires: Date; domain: string; secure: boolean } => {
  return {
    expires: new Date(Date.now() + AUTH_COOKIES_EXPIRY_DAYS * MS_IN_A_DAY),
    domain: BASE_DOMAIN_URL,
    secure: config('NODE_ENV') === 'production'
  }
}

const Cookies = JsCookie.withAttributes(getDefaultCookieAttributes())

export const get = (name: string): string | undefined => {
  return Cookies.get(name)
}

export const store = (name: string, value: string, expires?: string): void => {
  Cookies.set(name, value, { ...(expires != null ? { expires: new Date(expires) } : {}) })
}

export const remove = (name: string): void => {
  Cookies.remove(name, { domain: BASE_DOMAIN_URL })
}

export const removeAll = (): void => {
  const cookies = Object.keys(Cookies.get())

  cookies.forEach((cookieName) => {
    remove(cookieName)
  })
}
