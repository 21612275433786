import NiceModal, { type NiceModalHandler, useModal } from '@ebay/nice-modal-react'
import { type OverScreenIds } from './types'

declare type NiceModalArgs<T> = T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
  ? Omit<React.ComponentProps<T>, 'id'>
  : Record<string, unknown>

const OverScreen = {
  register: <T extends React.FC<any>>(
    id: OverScreenIds,
    component: T,
    props?: Partial<NiceModalArgs<T>> | undefined
  ): void => {
    NiceModal.register(id, component, props)
  },
  show: async <T>(id: OverScreenIds, props?: NiceModalArgs<T>): Promise<T> => {
    return await NiceModal.show(id, props)
  },
  remove: (id: OverScreenIds): void => {
    NiceModal.remove(id)
  },
  create: NiceModal.create,
  Provider: NiceModal.Provider
}

interface OverScreenHandler extends NiceModalHandler {}

const useOverScreen = (modal: OverScreenIds, args?: Record<string, unknown>): Omit<OverScreenHandler, 'hide'> => {
  return useModal(modal, args)
}

export default OverScreen

export { OverScreen, useOverScreen }
