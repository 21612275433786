import type { CredentialResponse } from 'google-one-tap'
import Script from 'next/script'
import { type ReactElement } from 'react'
import config from 'shared/config'

interface LoadProps {
  authId: HTMLElement | null
  locale?: string
  text: 'signin_with' | 'continue_with'
  onCredentialResponse: (credentialResponse: CredentialResponse) => void
}

export default function Load({ onCredentialResponse, authId, text, locale }: LoadProps): ReactElement {
  const handleLoad = (): void => {
    window.google.accounts.id.initialize({
      client_id: config('NEXT_PUBLIC_GOOGLE_SIGN_CLIENT_ID') as string,
      callback: onCredentialResponse,
      auto_select: true
    })
    if (authId != null) {
      window.google.accounts.id.renderButton(authId, { theme: 'outline', size: 'large', locale, text, width: 353 })
    }
  }

  return (
    <>
      <Script
        id="google-sign-in"
        onLoad={handleLoad}
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
      />
    </>
  )
}
