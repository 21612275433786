import { forwardRef, Fragment } from 'react'
import Skeleton from '../Skeleton'
import { DashboardFrameProps } from './types'
import Grid from '../Grid'
import { StyledGreyContainer, StyledWhiteContainer } from './styles'
import Divider from '../Divider'

export const DashboardFrame = forwardRef<HTMLDivElement, DashboardFrameProps>(
  ({ header, toolbar, summary, content, ...props }, ref): React.ReactElement => {
    return (
      <Grid display="flex" flexDirection="column" gap="24px" ref={ref} {...props}>
        <Grid>{header ?? <HeaderSkeleton />}</Grid>
        {toolbar !== null ? toolbar : <ToolbarSkeleton />}
        <Grid display="flex" flexDirection="column" gap="24px" paddingTop="24px">
          {summary ?? <SummarySkeleton />}
        </Grid>
        {Array.isArray(content) &&
          content.map((element, index) => {
            return (
              <Grid key={index}>
                <Grid>
                  <Divider />
                </Grid>
                {element ?? <ContentSkeleton />}
              </Grid>
            )
          })}
      </Grid>
    )
  }
)

const HeaderSkeleton = (): React.ReactElement => {
  return (
    <>
      <Skeleton variant="text" height={20} width="10%" />
      <Skeleton variant="text" height={44} width="20%" />
      <Skeleton variant="text" height={20} width="10%" />
      <Skeleton variant="text" height={40} width="30%" />
    </>
  )
}

const ToolbarSkeleton = (): React.ReactElement => {
  return (
    <Grid display="flex" alignItems="center" width="100%" justifyContent="space-between" height="48px">
      <Grid display="flex" gap="8px" alignItems="center">
        <Skeleton height="40px" width="102px" />
        <Skeleton height="40px" width="123px" />
      </Grid>

      <Grid display="flex" height="40px" gap="8px">
        <Grid xs>
          <Skeleton height="100%" width="298px" />
        </Grid>

        <Skeleton height="100%" width="87px" />
      </Grid>
    </Grid>
  )
}

const SummarySkeleton = (): React.ReactElement => {
  return (
    <>
      <Grid>
        <Skeleton variant="text" height={20} width="20%" />
      </Grid>
      <StyledGreyContainer
        justifyContent="space-between"
        display="flex"
        borderRadius="32px"
        gap="24px"
        padding="20px"
        marginBottom={4}
      >
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </StyledGreyContainer>
    </>
  )
}

const CardSkeleton = (): React.ReactElement => (
  <StyledWhiteContainer
    width="100%"
    height={160}
    padding="19px"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    borderRadius="24px"
  >
    <Skeleton variant="text" width="20%" height={30} />
    <Skeleton variant="text" width="30%" height={30} />
    <Skeleton variant="text" width="65%" height={30} />
  </StyledWhiteContainer>
)

const ContentSkeleton = (): React.ReactElement => {
  return (
    <Grid>
      <Skeleton variant="rectangular" height={400} />
    </Grid>
  )
}

export default DashboardFrame
