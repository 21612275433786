import { borderRadius as Type, BorderRadiusOptions } from './types'
import { styled } from '../../../utils'
export * from './types'

const StyledBox = styled('div')<{ borderRadius: string }>`
  width: 100px;
  height: 100px;
  background-color: #9bb0f9;
  border-radius: ${({ borderRadius }) => borderRadius};
` as React.ComponentType<any>

export const Example = ({ borderRadius }: { borderRadius: BorderRadiusOptions }): React.ReactElement => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ textAlign: 'center' }}>
        <StyledBox borderRadius={Type[borderRadius]} />
        <div style={{ color: '#333333', paddingTop: '10px' }}>{borderRadius}</div>
        <div style={{ color: '#333333', fontSize: '14px', paddingTop: '10px' }}>{Type[borderRadius]}</div>
      </div>
    </div>
  )
}
export default Example
