import React from 'react'
import { ArrowRightIcon, Avatar, Button, Card, Divider, Grid, PopUp, Typography, styled } from '@precis-digital/kurama'
import { PLATFORMS, type IPlatform } from 'dataSource/constants'
import { useTranslation } from 'shared/translations'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import { useQueryClientCredentials } from 'dataSource/api'
import { useAuth } from 'shared/context/AuthContext'
import { useFetchAccountCredentials } from 'dataSource/hooks'
import { type ClientAccountsResp } from 'shared/api/accounts'

const UserOrServiceAccountSelectionPopup = ({
  platform,
  dataSourceToReconnect
}: {
  platform: IPlatform
  dataSourceToReconnect?: ClientAccountsResp
}): React.ReactElement => {
  const { t } = useTranslation('dataSource', { keyPrefix: 'popups.reconnectUserOrServiceAccountPopup' })
  const { currentUser } = useAuth()
  const { data: credentials, refetch } = useQueryClientCredentials(currentUser?.id as string, platform.platform ?? '')
  useFetchAccountCredentials(platform, refetch)
  const userOrServiceAccountSelectionPopup = useOverScreen('userOrServiceAccountSelectionPopup')
  const handlePoupClose = (): void => {
    OverScreen.remove('userOrServiceAccountSelectionPopup')
  }
  const dataSource = PLATFORMS.find((p) => p.platform === platform.platform)

  const handleServiceAccountAccessClick = (): void => {
    void OverScreen.show('serviceAccountAccessPopup', {
      datasource: dataSource,
      initialGCPProject: {
        initialProjectId: dataSourceToReconnect?.externalAccountId,
        accountId: dataSourceToReconnect?.id
      }
    })
  }

  const closeConnectNewDataSourcePopup = (): void => {
    OverScreen.remove('connectNewDataSourcePopup')
  }

  const handleGoBackFromUserCredentials = (): void => {
    OverScreen.remove('selectDataSourceCredentialsPopup')
    void OverScreen.show('userOrServiceAccountSelectionPopup', { platform: dataSource, dataSourceToReconnect })
  }

  const handleUserCredentialsAccessClick = (): void => {
    void OverScreen.show('selectDataSourceCredentialsPopup', {
      datasource: dataSource,
      onGoBack: handleGoBackFromUserCredentials,
      credentials,
      isReconnect: true,
      internalAccountId: dataSourceToReconnect?.id
    })
    OverScreen.remove('userOrServiceAccountSelectionPopup')
  }

  return (
    <PopUp open={userOrServiceAccountSelectionPopup.visible} handleOpen={handlePoupClose}>
      <StyledHeader>
        <Avatar size="large" imageUrl={platform.iconUrl} kind="image" />
        <Typography variant="h2">{t('title')}</Typography>
      </StyledHeader>
      <StyledAuthDetailsCard
        onClick={() => {
          closeConnectNewDataSourcePopup()
          handleServiceAccountAccessClick()
        }}
      >
        <StyledOption>
          <Typography variant="h4">{t('authenticateServiceAccount')}</Typography>
          <Button
            onClick={() => {
              closeConnectNewDataSourcePopup()
              handleServiceAccountAccessClick()
            }}
            variant="tonal"
            leftIcon={<ArrowRightIcon />}
          />
        </StyledOption>
        <Divider />
        <Typography variant="body3">{t('authenticateServiceAccountExplainer')}</Typography>
      </StyledAuthDetailsCard>
      <StyledAuthDetailsCard
        onClick={() => {
          closeConnectNewDataSourcePopup()
          handleUserCredentialsAccessClick()
        }}
      >
        {' '}
        <StyledOption>
          <Typography variant="h4">{t('authenticateUserAccount')}</Typography>
          <Button
            onClick={() => {
              closeConnectNewDataSourcePopup()
              handleUserCredentialsAccessClick()
            }}
            variant="tonal"
            leftIcon={<ArrowRightIcon />}
          />
        </StyledOption>
        <Divider />
        <Typography variant="body3">{t('authenticateUserAccountExplainer')}</Typography>
      </StyledAuthDetailsCard>
    </PopUp>
  )
}

const StyledHeader = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  '& h2': {
    marginTop: '25px',
    marginBottom: '27px'
  }
}))

export const StyledOption = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))

export const StyledAuthDetailsCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'left',
  width: '100%',
  '& > *': {
    textAlign: 'left'
  },
  paddingBottom: '24px'
}))

export default OverScreen.create(UserOrServiceAccountSelectionPopup)
