import React from 'react'

export const Right = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M11.0001 9L14.0001 12L11.0001 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
