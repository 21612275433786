import { subDays, addDays, subMonths, formatDateToString, isWithinInterval } from 'shared/dateFns'
import { type AttributionResp } from 'shared/api/attributionModels'
import { convertListOfStringsToSentence } from 'shared/utils'

export const hasValidFilters = (integratedAttribution: AttributionResp): boolean => {
  if (
    integratedAttribution?.analyticsAccount?.filterConditions
      ?.map(
        (filter) =>
          filter.filterConditionSqlEventAttributionConnector === undefined &&
          filter.filterConditionSqlSessionAttributionConnector === undefined
      )
      .includes(true) ??
    false
  ) {
    return false
  } else {
    return true
  }
}

const BACKFILL_OFFSET_DAYS = 2

export const getDefaultDateRange = (
  data: { minDate: Date; maxDate: Date },
  todaysDate: Date
): { startDefaultDate: string; endDefaultDate: string } => {
  const defaultEndDate = subDays(todaysDate, BACKFILL_OFFSET_DAYS)
  const defaultStartDate = subMonths(defaultEndDate, 1)
  const defaultDates = { startDefaultDate: defaultStartDate, endDefaultDate: defaultEndDate }

  const lastDataDate = new Date(data.maxDate)
  if (lastDataDate < defaultDates.endDefaultDate) {
    defaultDates.endDefaultDate = lastDataDate
    defaultDates.startDefaultDate = subMonths(lastDataDate, 1)
  }
  const firstDataDate = new Date(data.minDate)
  if (firstDataDate > defaultDates.startDefaultDate) {
    defaultDates.startDefaultDate = firstDataDate
  }

  return {
    startDefaultDate: formatDateToString({ date: defaultDates.startDefaultDate, targetFormat: 'yyyy-MM-dd' }),
    endDefaultDate: formatDateToString({ date: defaultDates.endDefaultDate, targetFormat: 'yyyy-MM-dd' })
  }
}

export const computeAttributionDateRange = (
  attributionDates: Array<{ distinctDate: string }> | null
): {
  minDate: Date
  maxDate: Date
} => {
  if (attributionDates !== null) {
    const distinctDates = attributionDates.map((item) => item.distinctDate)
    const minDate = new Date(distinctDates[0])
    const maxDate = new Date(distinctDates[distinctDates.length - 1])
    return { minDate, maxDate }
  }

  const minDate = new Date(1999, 0, 1)
  const maxDate = new Date(9999, 11, 31)
  return { minDate, maxDate }
}

export const computeAttributionMissingDates = (attributionDates: Array<{ distinctDate: string }> | null): string[] => {
  if (attributionDates !== null) {
    const distinctDates = attributionDates.map((item) =>
      formatDateToString({ date: item.distinctDate, targetFormat: 'yyyy-MM-dd' })
    )
    const minDate = new Date(distinctDates[0])
    const maxDate = new Date(distinctDates[distinctDates.length - 1])
    const missingDates = []
    for (let date = minDate; date <= maxDate; date = addDays(date, 1)) {
      const formattedDate = formatDateToString({ date, targetFormat: 'yyyy-MM-dd' })
      if (!distinctDates.includes(formattedDate)) {
        missingDates.push(formattedDate)
      }
    }
    return missingDates
  }

  return []
}

export const getMissingDataInDateRange = (
  startDate: string,
  endDate: string,
  missingDates: string[],
  attributionDateRange?: { minDate: Date; maxDate: Date }
): string[] => {
  const missingDatesInDateRange: string[] = []
  if (attributionDateRange != null) {
    const { minDate } = attributionDateRange
    if (new Date(startDate) < minDate) {
      for (let date = new Date(startDate); date < minDate; date = addDays(date, 1)) {
        const formattedDate = formatDateToString({ date, targetFormat: 'yyyy-MM-dd' })
        missingDatesInDateRange.push(formattedDate)
      }
    }
  }
  for (let date = new Date(startDate); date <= new Date(endDate); date = addDays(date, 1)) {
    const formattedDate = formatDateToString({ date, targetFormat: 'yyyy-MM-dd' })
    if (missingDates.includes(formattedDate)) {
      missingDatesInDateRange.push(formattedDate)
    }
  }
  return missingDatesInDateRange
}

const inDateFormat = 'yyyy-MM-dd'
const outDateFormat = 'MMM do, yyyy'
export const condenseMissingDatesToText = (missingDates: string[]): string => {
  if (missingDates.length === 0) {
    return ''
  }
  missingDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

  const ranges = []
  let start = missingDates[0]
  let end = missingDates[0]

  for (let i = 1; i < missingDates.length; i++) {
    const currentDate = missingDates[i]
    const previousDate = addDays(new Date(missingDates[i - 1]), 1)

    if (
      formatDateToString({ date: currentDate, targetFormat: inDateFormat }) ===
      formatDateToString({ date: previousDate, targetFormat: inDateFormat })
    ) {
      end = currentDate
    } else {
      ranges.push({ start, end })
      start = currentDate
      end = currentDate
    }
  }

  ranges.push({ start, end })

  const formattedRanges = ranges.map((range) => {
    const startDate = new Date(range.start)
    const endDate = new Date(range.end)
    if (range.start === range.end) {
      return formatDateToString({ date: startDate, targetFormat: outDateFormat })
    } else {
      return `${formatDateToString({ date: startDate, targetFormat: outDateFormat })} to ${formatDateToString({
        date: endDate,
        targetFormat: outDateFormat
      })}`
    }
  })

  return convertListOfStringsToSentence(formattedRanges)
}

export const allowOutsideRange = (
  date: Date,
  attributionDateRange: { minDate: Date; maxDate: Date },
  missingDates: string[]
): boolean => {
  /***

   should return true if the selected date is OUTSIDE the date range, in which case
   it will be rendered as gray and not selectable

   ***/

  // remove the time from the dates... perhaps this should be done further up,
  // but a bug occurred because the time for the date variable was 00:00 CEST when it was read in, but the
  // dateIntervalStart and dateIntervalEnd both had the time 02:00 CEST so the dates were not aligned
  // the dates should be treated as time agnostic, but there seems to be an issue with how they're serialized
  // The date picker itself also seems to change the time arbitrarily and we were seeing the timestamps swithc arbitrarily
  // from 00:00 to 12:00, this is likely due to inconsistencies in the date serialization
  // https://stackoverflow.com/questions/2698725/comparing-date-part-only-without-comparing-time-in-javascript
  const dateWithoutTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  const dateIntervalStart = new Date(
    Date.UTC(
      attributionDateRange.minDate.getFullYear(),
      attributionDateRange.minDate.getMonth(),
      attributionDateRange.minDate.getDate()
    )
  )
  const dateIntervalEnd = new Date(
    Date.UTC(
      attributionDateRange.maxDate.getFullYear(),
      attributionDateRange.maxDate.getMonth(),
      attributionDateRange.maxDate.getDate()
    )
  )

  const dateString = formatDateToString({ date: dateWithoutTime, targetFormat: 'yyyy-MM-dd' })

  // missing dates is an array of dates that have missing data and should be greyed out in the UI, for the calendar picker
  if (missingDates.includes(dateString)) {
    return true
  }

  const isWithinIntervalBool = isWithinInterval(dateWithoutTime, {
    start: dateIntervalStart,
    end: dateIntervalEnd
  })
  return !isWithinIntervalBool
}
