import { TextField, chipClasses } from '@mui/material'
import { styled, type color } from '../../utils'
import { CSSObject } from '@mui/material/styles'
import { Theme } from '../theme'

export const StyledInput = styled(TextField)(
  ({ theme, hasValue, color }: { theme?: Theme; hasValue: boolean; color: color }): CSSObject => ({
    '.MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: theme?.borderRadius.medium,
      backgroundColor: hasValue ? theme?.palette.neutrals.white0 : theme?.palette.neutrals.stone100,
      [`& .${chipClasses.root}`]: {
        height: 'auto'
      },
      '.MuiOutlinedInput-input': {
        fontFamily: theme?.typography.body2.fontFamily,
        fontSize: theme?.typography.body2.fontSize,
        paddingTop: '8px',
        paddingBottom: '8px',
        '&::placeholder': {
          color: hasValue ? theme?.palette.secondary.main : theme?.palette.neutrals.stone150
        }
      },
      fieldset: {
        border: hasValue ? `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}` : 'none'
      },
      '&:hover': {
        backgroundColor: theme?.palette.neutrals.stone90,
        border: 'none',
        fieldset: {
          border: 'none'
        }
      },
      '&:focus': {
        backgroundColor: theme?.palette.neutrals.white0,
        fieldset: {
          border: `1px solid ${theme?.palette.primary.main ?? '#5578F5'}`,
          boxShadow: `0 0 0 3px ${theme?.palette.semantic.info05 ?? '#F5F7FE'}`
        }
      },
      ...(color === 'success' && {
        ...StyledSuccessInput(theme)
      }),
      ...(color === 'error' && {
        ...StyledErrorInput(theme)
      })
    },
    '.MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: theme?.palette.neutrals.white0,
      fieldset: {
        border: `1px solid ${theme?.palette?.primary?.main ?? '#5578F5'}`,
        boxShadow: `0 0 0 3px ${theme?.palette.semantic.info05 ?? '#F5F7FE'}`
      },
      ...(color === 'success' && {
        ...SuccessInputFocus(theme)
      }),
      ...(color === 'error' && {
        ...ErrorInputFocus(theme)
      })
    },
    '.MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: theme?.palette.neutrals.stone90,
      color: theme?.palette.neutrals.stone120,
      fieldset: {
        border: 'none'
      }
    }
  })
)

const StyledSuccessInput = (theme?: Theme): CSSObject => ({
  backgroundColor: theme?.palette.neutrals.white0,
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`
  },
  '&:hover': {
    fieldset: {
      border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`,
      boxShadow: `0 0 0 3px ${theme?.palette.semantic.success05 ?? '#E8FDF7'}`
    }
  }
})

const SuccessInputFocus = (theme?: Theme): CSSObject => ({
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`,
    boxShadow: `0 0 0 3px ${theme?.palette.semantic.success05 ?? '#E8FDF7'}`
  }
})

const StyledErrorInput = (theme?: Theme): CSSObject => ({
  backgroundColor: theme?.palette.neutrals.white0,
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`
  },
  '&:hover': {
    fieldset: {
      border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`,
      boxShadow: `0 0 0 3px ${theme?.palette.semantic.error05 ?? '#FFF0F5'}`
    }
  }
})

const ErrorInputFocus = (theme?: Theme): CSSObject => ({
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`,
    boxShadow: `0 0 0 3px ${theme?.palette.semantic.error05 ?? '#FFF0F5'}`
  }
})

export const StyledLabel = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.secondary.main,
    fontFamily: theme?.typography.h5.fontFamily,
    fontSize: theme?.typography.h5.fontSize,
    marginBottom: '8px'
  })
)

export const StyledMessage = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme?.typography.body3.fontFamily,
    fontSize: theme?.typography.body3.fontSize,
    marginTop: '8px',
    paddingLeft: '6px',
    span: {
      paddingLeft: '6px'
    }
  })
)

export const StyledClearButton = styled('button')(
  (): CSSObject => ({
    cursor: 'pointer',
    display: 'flex',
    border: 'none',
    padding: '0',
    backgroundColor: 'transparent'
  })
)
