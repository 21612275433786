import React, { useState } from 'react'
import { useTranslation } from 'shared/translations'
import { getPlatformDetailsByPlatform } from 'dataSource'
import { formatDateToString } from 'shared/dateFns'
import Severity from 'recommendations/components/Severity'
import {
  getSeverityChipStyles,
  convertPlatformName,
  getTableDataFromCard,
  getAccountOccurences,
  getCardMetadataFromTranslations,
  isDuplicatedColumn,
  generateMetricColumn
} from 'recommendations/utils'
import { snakeCaseToTitleCase, camelCaseToTitleCase, convertSnakeCaseToCamelCase } from 'shared/utils'
import { ExpandedNumberFormat } from 'shared/components/NumberFormat'
import Dots from 'shared/components/Loader/Dots'

import { supportedMetrics, rules, legacyPopupTableColumns } from 'recommendations/constants'

import { useTable, useFilters, type Column, type TableOptions, useSortBy, usePagination } from 'react-table'

import { type Data } from 'shared/components/ChartToolTip'

import {
  type GroupedCard,
  type RecommendationsFullRule,
  type PopupTableData,
  type AccountDetails,
  type ColumnProps,
  type MetricDetails
} from 'recommendations/types'

import ChartToolTip from 'shared/components/ChartToolTip'

import { ReportTable } from 'shared/components/ReportTable'

import {
  PopUp,
  styled,
  Grid,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Toggle,
  Divider,
  Checkbox,
  BarChart,
  Link,
  MultiSelectDropdown,
  type MultiSelectDropdownItem,
  type VerticalBarData,
  Tooltip
} from '@precis-digital/kurama'

import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'

interface CellProps {
  row: Record<string, any>
  metricDetails: MetricDetails
  columnKey: string
}

const CustomCell: React.FC<CellProps> = ({ row, metricDetails, columnKey }) => {
  const value = row.original.campaignDetails[columnKey]
  const currencyCode = row.original.campaignDetails.currencyCode ?? undefined

  switch (metricDetails.type) {
    case 'date':
      return <Typography variant="body2">{formatDateToString({ date: value })}</Typography>
    case 'string':
      return <Typography variant="body2">{value}</Typography>
    default:
      return <ExpandedNumberFormat value={value as number} id={metricDetails.type} currencyCode={currencyCode} />
  }
}

const AccountCell: React.FC<{ value: unknown; uiUrlTemplate?: string; iconUrl: string }> = ({
  value,
  uiUrlTemplate,
  iconUrl
}) => {
  const accountDetails = value as AccountDetails

  return uiUrlTemplate !== undefined ? (
    <Grid style={{ display: 'flex', alignItems: 'center' }}>
      <Grid style={{ paddingRight: '8px' }}>
        <Avatar kind="image" size="small" imageUrl={iconUrl} />
      </Grid>
      <Link href={uiUrlTemplate.replace('{accountId}', accountDetails.id)} target="_blank" rel="noreferrer">
        <Typography variant="body2">{accountDetails.name}</Typography>
      </Link>
    </Grid>
  ) : (
    <>{accountDetails.name}</>
  )
}
export const renderCampaignDetailsCell = ({
  row,
  key
}: {
  row: Record<string, any>
  key: string
}): React.ReactElement => <CustomCell row={row} metricDetails={supportedMetrics[key]} columnKey={key} />
const RecommendationsPopup = (cardData: GroupedCard): React.ReactElement => {
  const [tabValue, setTabValue] = useState<number>(0)
  const { t } = useTranslation('recommendation')
  const screen = useOverScreen('recommendationsPopup')
  const [limit, setLimit] = React.useState<number>(10)
  const [page, setPage] = React.useState<number>(0)
  const [columnsFilter, setColumnsFilter] = React.useState<Record<string, boolean>>({})
  const [tableData, setTableData] = React.useState<PopupTableData[]>([])
  const [barChartData, setBarChartData] = React.useState<VerticalBarData[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const [paginatedData, setPaginatedData] = React.useState<PopupTableData[]>([])

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const ruleForCard: RecommendationsFullRule = React.useMemo(() => {
    return getCardMetadataFromTranslations(t, cardData.ruleId)
  }, [cardData.ruleId, t])

  const platformMetaData = getPlatformDetailsByPlatform(convertPlatformName(ruleForCard.Platform))
  const iconUrl = platformMetaData?.iconUrl
  const uiUrlTemplate = platformMetaData?.uiUrlTemplate

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue)
  }

  function getNestedValue<T>(obj: T, path: string): any {
    return path.split('.').reduce((acc, key) => (acc as any)?.[key], obj)
  }

  const sortData = React.useCallback((data: PopupTableData[], sortBy: any[]): PopupTableData[] => {
    if (sortBy.length === 0) {
      return data
    }

    let { id, desc } = sortBy[0]

    id = id === 'account' ? 'account.name' : id

    const sortedData = [...data].sort((a, b) => {
      const nestedA = getNestedValue(a, id)
      const nestedB = getNestedValue(b, id)
      if (nestedA < nestedB) {
        return desc === true ? 1 : -1
      }
      if (nestedA > nestedB) {
        return desc === true ? -1 : 1
      }
      return 0
    })

    return sortedData
  }, [])

  const severityMeta = getSeverityChipStyles(ruleForCard.severity ?? 1)

  const calculateTableData = React.useMemo(() => {
    return getTableDataFromCard(cardData, ruleForCard)
  }, [cardData, ruleForCard])

  const calculateBarChartData = React.useMemo(() => {
    const accountOccurrences = getAccountOccurences(calculateTableData)
    const rawBarData = Object.entries(accountOccurrences).map(([accountName, count]) => ({
      y: accountName,
      revenue: count
    }))

    return rawBarData.sort((a, b) => b.revenue - a.revenue)
  }, [calculateTableData])

  React.useEffect(() => {
    setIsLoading(true)
    setTableData(calculateTableData)
    setBarChartData(calculateBarChartData)
    setIsLoading(false)
  }, [calculateTableData, calculateBarChartData])

  const basicColumnIds = Object.keys(supportedMetrics)
    .filter((key) => supportedMetrics[key].columnType === 'dimension')
    .map((key) => `campaignDetails.${key}`)

  React.useEffect(() => {
    if (tableData.length === 0) {
      return
    }
    const firstCampaignDetails = tableData[0].campaignDetails
    if (firstCampaignDetails !== null) {
      const metricKeys = Object.keys(firstCampaignDetails).filter((key) => {
        return supportedMetrics[key] !== undefined && !legacyPopupTableColumns.includes(key)
      })

      const defaultColumns = rules[cardData.ruleId].DefaultColumns.map(convertSnakeCaseToCamelCase)
      const metricColumnsFilter = Object.fromEntries(defaultColumns.map((key) => [camelCaseToTitleCase(key), true]))

      metricKeys.forEach((key) => {
        const formattedHeader = camelCaseToTitleCase(key)

        if (!Object.prototype.hasOwnProperty.call(metricColumnsFilter, formattedHeader)) {
          metricColumnsFilter[formattedHeader] = false
        }
      })

      setColumnsFilter(metricColumnsFilter)
    }
  }, [tableData, cardData.ruleId])

  const handleColumnsFilterChange = (_: string | null, value: MultiSelectDropdownItem[]): void => {
    const newMetricFilter = Object.fromEntries(
      Object.keys(columnsFilter).map((key) => [key, value.map((item) => item.value).includes(key)])
    )
    setColumnsFilter(newMetricFilter)
  }

  const renderFilters = (): React.ReactElement => {
    return (
      <Grid
        container
        item
        gap="8px"
        style={{
          justifyContent: 'end',
          marginTop: '-64px'
        }}
      >
        <MultiSelectDropdown
          title={t('popup.allColumns')}
          buttonTitle={t('popup.allColumns')}
          options={Object.keys(columnsFilter).map((key) => ({ label: key, value: key }))}
          value={Object.keys(columnsFilter).filter((key) => columnsFilter[key])}
          onChange={handleColumnsFilterChange}
        />
      </Grid>
    )
  }

  const columns: Array<Column<ColumnProps>> = React.useMemo(() => {
    const returnColumns: Array<Column<ColumnProps>> = [
      {
        Header: 'Account',
        accessor: 'account',
        Cell: ({ value }: { value: unknown }) => (
          <AccountCell value={value} uiUrlTemplate={uiUrlTemplate} iconUrl={iconUrl} />
        )
      }
    ]

    tableData.forEach((campaign) => {
      if (campaign.campaignDetails === null) {
        return
      }
      Object.entries(campaign.campaignDetails).forEach(([key]) => {
        if (supportedMetrics[key] === undefined) return

        const formattedHeader = camelCaseToTitleCase(key)
        if (!columnsFilter[formattedHeader]) {
          return
        }
        const isDuplicate = isDuplicatedColumn(returnColumns, formattedHeader)
        if (!isDuplicate) {
          const newColumn = generateMetricColumn(key, formattedHeader)
          returnColumns.push(newColumn)
        }
      })
    })

    const supportedMetricsKeys = Object.keys(supportedMetrics)

    const getOrderIndex = (column: Column<ColumnProps>): number => {
      if (typeof column.accessor !== 'string') return Infinity
      const accessor = column.accessor.replace('campaignDetails.', '')
      return supportedMetricsKeys.indexOf(accessor)
    }

    returnColumns.sort((a, b) => {
      if (a.accessor === 'account') return -1
      if (b.accessor === 'account') return 1

      const orderIndexA = getOrderIndex(a)
      const orderIndexB = getOrderIndex(b)

      if (orderIndexA === -1) return 1
      if (orderIndexB === -1) return -1

      return orderIndexA - orderIndexB
    })

    const accountIndex = returnColumns.findIndex((column) => column.accessor === 'account')
    if (accountIndex > 0) {
      const [accountColumn] = returnColumns.splice(accountIndex, 1)
      returnColumns.unshift(accountColumn)
    }

    return returnColumns
  }, [uiUrlTemplate, iconUrl, tableData, columnsFilter])

  const options: TableOptions<ColumnProps> = {
    data: paginatedData,
    columns,
    manualSortBy: true,
    initialState: {
      pageIndex: 0,
      sortBy: [{ id: 'account', desc: false }]
    },
    pageCount: Math.ceil(tableData.length / limit)
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    setPageSize
  } = useTable(options, useFilters, useSortBy, usePagination, useFilters)

  React.useEffect(() => {
    const sortedData = sortData(tableData, sortBy)
    const startIndex = page * limit
    const endIndex = startIndex + limit
    setPaginatedData(sortedData.slice(startIndex, endIndex))
  }, [page, limit, tableData, sortBy, sortData])

  const renderTabs = (): React.ReactElement => {
    if (tabValue === 0) {
      return (
        <Grid style={{ marginBottom: '-24px' }}>
          <ReportTable
            t={t}
            basic
            basicColumnsIds={[...basicColumnIds, 'account']}
            tableProps={{
              styles: {
                container: { height: '400px', margin: 0 },
                table: { margin: 0 }
              },
              getTableProps,
              getTableBodyProps,
              headerGroups,

              rows,
              prepareRow,
              renderFilters,
              pagination: {
                count: tableData.length,
                page,
                handleChangePage,
                rowsPerPage: limit,
                rowsPerPageOptions: [10, 20, 50, 100],
                handleChangeRowsPerPage: (event) => {
                  setLimit(parseInt(event.target.value, 10))
                  setPageSize(parseInt(event.target.value, 10))
                }
              }
            }}
            isLoading={rows.length === 0}
          />
        </Grid>
      )
    } else if (tabValue === 1) {
      return (
        <Grid style={{ paddingTop: '24px' }}>
          <Typography variant="h3">{ruleForCard.Title}</Typography>
          <Typography variant="body2">{ruleForCard.Recommendation}</Typography>
          <Typography variant="body2" style={{ marginTop: '20px' }}>
            {ruleForCard.Documentation}
          </Typography>
        </Grid>
      )
    }
    return <></>
  }

  return (
    <StyledPopUp
      title={t('popup.title')}
      open={screen.visible}
      handleOpen={(): void => {
        screen.remove()
      }}
    >
      {isLoading ? (
        <Dots />
      ) : (
        <Grid container>
          <StyledGridWithBorder xs={8} padding="24px">
            <Grid container sx={{ marginBottom: '24px' }}>
              <Grid item xs={1} sx={{ verticalAlign: 'middle' }}>
                <Avatar style={{ height: '100%', border: 'none' }} kind="image" size="medium" imageUrl={iconUrl} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h3" style={{ marginBottom: '-4px' }}>
                  {ruleForCard.Title}
                </Typography>
                <Typography variant="body3">
                  {snakeCaseToTitleCase(platformMetaData.platform)} • {snakeCaseToTitleCase(ruleForCard.category)}
                </Typography>
              </Grid>
              <Grid item xs={1} sx={{ verticalAlign: 'middle' }}>
                <Grid style={{ float: 'right', paddingTop: '7px' }}>
                  <Severity
                    label={severityMeta.label}
                    textColor={severityMeta.color}
                    backgroundColor={severityMeta.backgroundColor}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Tabs currentTab={tabValue} handleChange={handleTabChange}>
                <Tab value={0}>{t('popup.whereTabTitle')}</Tab>
                <Tab value={1}>{t('popup.whyTabTitle')}</Tab>
              </Tabs>
            </Grid>

            {renderTabs()}
          </StyledGridWithBorder>
          <Grid item xs={4}>
            <Grid container direction="column">
              <StyledGridActionSettings>
                <Tooltip kind="singleline" title={t('popup.comingSoon')} placement="bottom-start">
                  <StyledTextDisabled variant="h3">{t('popup.action')}</StyledTextDisabled>
                  <Grid display="flex" justifyContent="space-between" alignItems="center" marginTop="20px">
                    <StyledTextDisabled variant="body2">{t('popup.enableEmailNotifications')}</StyledTextDisabled>
                    <Toggle disabled checked={false} />
                  </Grid>
                </Tooltip>
                <Divider />
                <Tooltip kind="singleline" title={t('popup.comingSoon')} placement="bottom-start">
                  <StyledTextDisabled variant="h3">{t('popup.settings')}</StyledTextDisabled>
                  <Grid display="flex" justifyContent="space-between" alignItems="center">
                    <StyledTextDisabled variant="body2">{t('popup.markAsResolved')}</StyledTextDisabled>
                    <Checkbox disabled checked={false} />
                  </Grid>
                </Tooltip>
              </StyledGridActionSettings>
              <StyledGridBarChart>
                <Typography variant="h3">{t('popup.issuesByDataSource')}</Typography>
                <BarChart
                  containerWidth={260}
                  internalLabels
                  layout="vertical"
                  overflow={barChartData.length > 10}
                  barRadius={15}
                  data={barChartData}
                  renderTooltip={({ dataKey, payload }) => (
                    <ChartToolTip
                      {...{
                        payload: payload as Data,
                        value: payload.revenue as string,
                        header: 'Issues'
                      }}
                    />
                  )}
                  yAxis={{
                    type: 'category',
                    dataKey: 'y',
                    axisLine: false,
                    tick: false,
                    tickLine: false,
                    mirror: true
                  }}
                  xAxis={{
                    hide: true,
                    axisLine: false,
                    type: 'number',
                    domain: [0, barChartData[0].revenue]
                  }}
                />
              </StyledGridBarChart>
            </Grid>
          </Grid>
        </Grid>
      )}
    </StyledPopUp>
  )
}

const StyledGridWithBorder = styled(Grid)(({ theme }) => ({
  borderRadius: '32px',
  border: `1px solid ${theme.palette.neutrals.stone100 as string}`
}))

const StyledPopUp = styled(PopUp)(() => ({
  '> div:nth-of-type(3)': {
    height: '782px',
    width: '1128px'
  }
}))

const StyledTextDisabled = styled(Typography)(() => ({
  opacity: '0.5'
}))

const StyledGridBarChart = styled(StyledGridWithBorder)(() => ({
  padding: '24px 24px 48px 24px',
  height: '368px',
  width: 'calc(100% - 32px)',
  marginLeft: '32px',
  marginTop: '32px'
}))

const StyledGridActionSettings = styled(StyledGridWithBorder)(() => ({
  padding: '24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '250px',
  width: 'calc(100% - 32px)',
  marginLeft: '32px'
}))

export default OverScreen.create(RecommendationsPopup)
