import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import React, { type ReactElement } from 'react'
import DataSourcesTable from './DataSourcesTable'
import { Grid, Typography } from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'
import OtherDataSourcesTable from './OtherDataSourcesTable'

const DataSourcesTab = (): ReactElement => {
  const { dataSourcesMap } = useCurrentChannelGrouping()
  const { t } = useTranslation('channelGrouping')

  const dataSourcesWithChannels = Object.values(dataSourcesMap).filter(
    (dataSourceItem) => dataSourceItem.channels.length > 0 && dataSourceItem.connectedDataSources.length > 0
  )
  const dataSourcesWithoutChannels = Object.values(dataSourcesMap).filter(
    (dataSourceItem) => dataSourceItem.channels.length === 0 || dataSourceItem.connectedDataSources.length === 0
  )

  return (
    <Grid container flexDirection="column" gap="24px">
      <Grid display="flex" justifyContent="space-between" marginTop="16px" height="40px" alignItems="flex-end">
        <Typography variant="h2">
          {dataSourcesWithChannels.length === 0
            ? t('mainFormView.dataSourcesTab.noDataSourcesTitle')
            : t('mainFormView.dataSourcesTab.title')}
        </Typography>
      </Grid>
      <DataSourcesTable dataSources={dataSourcesWithChannels} />
      <OtherDataSourcesTable
        notConnectedDataSources={dataSourcesWithoutChannels}
        hasAnyDataSourcesWithChannels={dataSourcesWithChannels.length > 0}
      />
    </Grid>
  )
}

export default DataSourcesTab
