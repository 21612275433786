import React, { forwardRef } from 'react'
import { StyledTabs } from './styles'
import { TabsProps } from './types'

const Tabs = forwardRef<HTMLButtonElement, TabsProps>(
  ({ variant = 'standard', handleChange, currentTab = 0, children, ...props }, ref): React.ReactElement => {
    return (
      <StyledTabs value={currentTab} variant={variant} {...props} onChange={handleChange} ref={ref}>
        {children}
      </StyledTabs>
    )
  }
)

export default Tabs
