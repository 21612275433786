import { Grid, Typography } from '@precis-digital/kurama'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import React, { type ReactElement } from 'react'
import UsedInEmptyState from './UsedInEmptyState'
import { useTranslation } from 'shared/translations'
import UsedInTable from './UsedInTable'

const UsedInTab = (): ReactElement => {
  const { currentChannelGrouping, usedInConfigs, isLoadingUsedInConfigsStatuses } = useCurrentChannelGrouping()
  const { t } = useTranslation('channelGrouping')

  const showEmptyState = usedInConfigs.length === 0

  return (
    <Grid container flexDirection="column" gap="24px">
      {!showEmptyState ? (
        <>
          <Grid display="flex" flexDirection="column" maxWidth="540px" marginTop="16px" gap="8px">
            <Grid height="40px" display="flex" alignItems="flex-end">
              <Typography variant="h2">{t('mainFormView.usedInTab.title')}</Typography>
            </Grid>
            <Typography variant="body2">
              {t('mainFormView.usedInTab.subTitle', {
                channelGroupingName: currentChannelGrouping.name
              })}
            </Typography>
          </Grid>
          <UsedInTable usedInConfigs={usedInConfigs} isLoadingStatuses={isLoadingUsedInConfigsStatuses} />
        </>
      ) : (
        <UsedInEmptyState />
      )}
    </Grid>
  )
}

export default UsedInTab
