import { useRef, useEffect, type Dispatch, type SetStateAction } from 'react'
import { styled, Grid, DotsLoader } from '@precis-digital/kurama'
import AccountPlate from './AccountPlate'
import { type IAccountsList, type IPricingPlan } from 'dataSource/components/Popup/AccountSelectionPopup'
import { type IPlatform } from 'dataSource/constants'

interface AccountsListProps {
  accountsList: {
    filteredAccountsList: IAccountsList[]
    originalAccountsList: IAccountsList[]
  }
  dataSource: IPlatform
  handleOnSelectAccount: (accountId: string) => void
  pricingPlans: IPricingPlan[]
  setPricingPlans: Dispatch<SetStateAction<IPricingPlan[]>>
  connectedAccounts?: {
    selectedConnectedAccounts: IAccountsList[]
    originalConnectedAccounts: IAccountsList[]
  }
  onNearBottom?: () => void
  isLoadingAccounts: boolean
}

export const AccountsList = ({
  accountsList,
  dataSource,
  handleOnSelectAccount,
  pricingPlans,
  setPricingPlans,
  connectedAccounts,
  onNearBottom,
  isLoadingAccounts
}: AccountsListProps): React.ReactElement => {
  const accountsListRef = useRef<HTMLDivElement | null>(null)

  const isScrollNearBottom = (): boolean => {
    const threshold = 10 // pixels from the bottom
    const scrollableElement = accountsListRef.current

    if (scrollableElement != null) {
      const position = scrollableElement.scrollTop + scrollableElement.clientHeight
      const height = scrollableElement.scrollHeight
      return height - position < threshold
    }
    return false
  }

  useEffect(() => {
    const handleScroll = (): void => {
      if (isScrollNearBottom()) {
        if (onNearBottom != null) onNearBottom()
      }
    }

    const scrollableElement = accountsListRef.current
    if (scrollableElement != null) {
      scrollableElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollableElement != null) {
        scrollableElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [onNearBottom])
  const hasConnectedAccounts =
    connectedAccounts !== undefined && connectedAccounts?.selectedConnectedAccounts.length > 0
  const hasUnSelectedAccounts = accountsList.filteredAccountsList.length > 0 && connectedAccounts === undefined

  return (
    <StyledContentList ref={accountsListRef}>
      {hasConnectedAccounts
        ? connectedAccounts.selectedConnectedAccounts.map((account) => {
            return (
              <AccountPlate
                account={account}
                key={`${account.id}`}
                has360={account.has360}
                externalAccountId={account.externalAccountId}
                isAccountSelected
                dataSource={dataSource}
                handleSelectAccount={handleOnSelectAccount}
                pricingPlans={pricingPlans}
                setPricingPlans={setPricingPlans}
                disabled
              />
            )
          })
        : hasUnSelectedAccounts
        ? accountsList.filteredAccountsList.map((account, index) => {
            return (
              <AccountPlate
                account={account}
                key={`${account.id}`}
                isAccountSelected={accountsList.filteredAccountsList[index]?.selected}
                dataSource={dataSource}
                handleSelectAccount={handleOnSelectAccount}
                pricingPlans={pricingPlans}
                setPricingPlans={setPricingPlans}
              />
            )
          })
        : null}
      {isLoadingAccounts && (
        <DotsLoaderContainer>
          <DotsLoader />
        </DotsLoaderContainer>
      )}
    </StyledContentList>
  )
}

export const StyledContentList = styled(Grid)(() => ({
  width: '33em',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  paddingRight: '1.333em',
  gap: 16,
  overflowY: 'auto',
  maxHeight: '30vh'
}))

export const DotsLoaderContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}))

export default AccountsList
