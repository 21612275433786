import { PopUp, Typography, Divider, Button, PlusIcon, Link } from '@precis-digital/kurama'
import {
  StyledUpperText,
  StyledLeaveWorkspacePopupFooter,
  StyledAssignedTextInvite,
  StyledAddMembersButton
} from '../Popup/styles'
import React, { useState } from 'react'
import { MailInput } from './MailInput'
import { type MemberRole } from 'shared/api/clients'
import { useCreateMessage, useQueryMembers, useQueryMessages, useResendMessage } from 'workspace/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import { noop } from 'shared/utils'
import { useTranslation } from 'shared/translations'
import { USER_PERMISSIONS_DOCUMENTATION_LINK } from 'shared/constants/links'
import Image from 'next/image'
import emailSymbol from 'public/assets/images/email_symbol.png'
import { useQueryClient } from 'react-query'
import Dots from 'shared/components/Loader/Dots'
import { makeToastWithLoading } from 'shared/components/Toaster'

interface InviteMembersPopupProps {
  open: boolean
  onClose: () => void
}
interface Invite {
  toEmail: string
  role: MemberRole
  clientId: string
}
type Invites = Invite[]

const InviteNewMembersPopup = (props: InviteMembersPopupProps): React.ReactElement => {
  const { open, onClose } = props
  const { currentClient } = useCurrentClient()

  const { t } = useTranslation('workspace')

  const emptyInvite: Invite = {
    clientId: currentClient.id,
    toEmail: '',
    role: 'viewer'
  }
  const { data: members } = useQueryMembers(currentClient.id)
  const [invites, setInvites] = useState<Invites>([{ ...emptyInvite }])
  const { data: messages } = useQueryMessages(currentClient.id)

  const setRole = (index: number, role: MemberRole): void => {
    const invitesToUpdate: Invites = [...invites]
    const invite = invitesToUpdate[index]
    invite.role = role
    setInvites(invitesToUpdate)
  }
  const setEmail = (index: number, email: string): void => {
    const invitesToUpdate: Invites = [...invites]
    const invite = invitesToUpdate[index]
    invite.toEmail = email
    setInvites(invitesToUpdate)
  }

  const addInvite = (): void => {
    const updatedInvites: Invites = [...invites, { ...emptyInvite }]
    setInvites(updatedInvites)
  }

  const [validEmails, setValidEmails] = useState<boolean[]>([])
  const setValidEmail = (index: number, valid: boolean): void => {
    const validEmailsToUpdate = [...validEmails]
    validEmailsToUpdate[index] = valid
    invites.map((invite, index) => {
      if (invite.toEmail === emptyInvite.toEmail && invites.length > 1) {
        validEmailsToUpdate[index] = true
      }
      return invite
    })
    setValidEmails(validEmailsToUpdate)
  }

  const { mutateAsync: sendInvitation } = useCreateMessage()
  const { mutateAsync: resendMessage } = useResendMessage()

  const resendFailedInvitation = async (invite: Invite): Promise<void> => {
    const findMessageId = (email: string): string | undefined =>
      messages?.find((message) => message.toEmail === email)?.id
    const messageId = findMessageId(invite.toEmail)
    messageId !== undefined ? await resendMessage({ messageId, clientId: currentClient.id }) : noop()
  }
  const shouldHaveEmail = (invite: Invite): boolean => invite.toEmail !== emptyInvite.toEmail

  const queryClient = useQueryClient()
  const [isSendingInvites, setIsSendingInvites] = useState<boolean>(false)
  const handleSendInvitations = async (): Promise<void> => {
    const { toastOnSuccess } = makeToastWithLoading()
    if (canSendInvites) {
      setIsSendingInvites(true)
      await Promise.all(
        invites.filter(shouldHaveEmail).map(async (invite) => {
          try {
            await sendInvitation(invite)
          } catch {
            await resendFailedInvitation(invite)
          } finally {
            await queryClient.invalidateQueries('allmessages')
          }
        })
      )
      setIsSendingInvites(false)
      toastOnSuccess(`Member invitation${invites.length > 1 ? 's' : ''} successfully sent`)
      handleClose()
    }
  }

  const canSendInvites = validEmails.length > 0 && !validEmails.includes(false)
  const handleClose = (): void => {
    setInvites([{ ...emptyInvite }])
    setValidEmails([])
    onClose()
  }

  const isDuplicate = (email: string): boolean => {
    if (email === emptyInvite.toEmail) return false
    return invites.filter((invite) => invite.toEmail === email).length >= 2
  }
  const isAlreadyMember = (email: string): boolean => {
    if (members === undefined) return false
    return members.filter((member) => member.email === email).length >= 1
  }

  return (
    <PopUp handleOpen={handleClose} open={open}>
      <Image
        src={emailSymbol.src}
        width={544}
        height={179}
        alt="email symbol"
        style={{
          maxWidth: '100%',
          height: 'auto'
        }}
      />
      <StyledUpperText>
        <Typography variant="h2">
          {' '}
          {t('titles.inviteMembersPopup')} {currentClient.name}{' '}
        </Typography>
      </StyledUpperText>
      <Typography variant="h3"> {t('titles.emailAddress')} </Typography>
      <StyledAssignedTextInvite variant="h4">
        {t('titles.accountPermissions')}{' '}
        <Link href={USER_PERMISSIONS_DOCUMENTATION_LINK} target="_blank" rel="noreferrer">
          {t('titles.accountPermissionsLink')}
        </Link>
        .
      </StyledAssignedTextInvite>

      {invites.map((invite, index) => (
        <MailInput
          key={index}
          index={index}
          setEmail={setEmail}
          setValidEmail={setValidEmail}
          setRole={setRole}
          email={invite.toEmail}
          role={invite.role}
          duplicateEmailCheck={isDuplicate}
          alreadyMemberEmailCheck={isAlreadyMember}
          handleSendInvitations={handleSendInvitations}
        />
      ))}

      <StyledAddMembersButton leftIcon={<PlusIcon />} onClick={addInvite} scheme="light" variant="outlined">
        {t('buttons.addMoreMembers')}
      </StyledAddMembersButton>
      <Divider />
      <StyledLeaveWorkspacePopupFooter>
        {isSendingInvites ? (
          <Dots />
        ) : (
          <>
            <Button onClick={handleClose} scheme="light" variant="outlined">
              {t('buttons.cancel')}
            </Button>
            <Button
              onClick={() => {
                void handleSendInvitations()
              }}
              scheme="light"
              variant="filled"
              disabled={!canSendInvites}
            >
              {t('buttons.sendInvitations')}
            </Button>
          </>
        )}
      </StyledLeaveWorkspacePopupFooter>
    </PopUp>
  )
}

export default InviteNewMembersPopup
