import React from 'react'

export const CursorMouse = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M1.45416 1L7.28681 15L9.35752 8.90336L15.4542 6.83265L1.45416 1Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
