import { styled, Input, SearchIcon } from '@precis-digital/kurama'
import { type Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type IPlatform } from 'dataSource/constants'

export const InputName = 'accountName'
export interface IFieldsValues {
  [InputName]: string
}

interface SearchInputProps {
  control: Control<IFieldsValues, string>
  onSearch: (inputName: typeof InputName, e: React.ChangeEvent<HTMLInputElement>) => void
  clearInput: (inputName: typeof InputName, e: React.MouseEvent) => void
  dataSourceMeta: IPlatform
}

export const SearchInput = ({
  control,
  onSearch,
  clearInput,
  dataSourceMeta
}: SearchInputProps): React.ReactElement => {
  const { t } = useTranslation('dataSource')
  return (
    <StyledAccountSearch>
      <Controller
        name={InputName}
        control={control}
        render={({ field: { name, value } }) => {
          return (
            <Input
              assistive
              name={InputName}
              value={value}
              placeholder={String(
                t('popups.accountSelection.findAccountInput', {
                  externalEntitiesLabel: dataSourceMeta.externalEntitiesLabel
                })
              )}
              onChange={(e) => {
                onSearch(name, e)
              }}
              clearInput={(e) => {
                clearInput(name, e)
              }}
              icon={<SearchIcon />}
            />
          )
        }}
      />
    </StyledAccountSearch>
  )
}

export const StyledAccountSearch = styled('form')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 0px 0px',
  gap: 8,
  '>div': {
    width: '100%'
  }
}))

export default SearchInput
