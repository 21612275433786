import { type IPlatform } from 'dataSource/constants'
import { type Platforms } from './accounts'
import fetch from './fetch'
const client = fetch()

export type CreditInput = 'ddav2' | 'xgboost' | 'native' | 'rba' | 'lnda'

export interface AttributionResp {
  id: number
  clientId: string
  clientName: string
  name: string
  sourceProjectId: number
  sourceProject: string
  sourceAuthAccount: string
  targetProjectId: string
  targetProject: string
  targetAuthAccount: string
  sourceDataset: string
  targetDataset: string
  targetCurrency?: string
  lookbackDays: number
  xgboostLookback?: number
  xgboostModelLookback?: number
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
  creditAllocator: boolean
  performanceCurves: boolean
  reallocations: Reallocation[]
  analyticsAccount?: AnalyticsAccountRes
  adAccounts: AdAccountOutput[]
  runSchedule: boolean
  schedule: string
  creditInput: CreditInput
  channelGroupingId?: number
  channelGroupingMetadata?: ChannelGroupingMetadata
  includeIntercept?: boolean
  recencyWeighting?: number
}
export interface AttributionModelReq {
  name: string
  clientId: number
  creditInput: CreditInput
  lookbackDays: number
  xgboostLookback?: number
  xgboostModelLookback?: number
  adAccounts: AdAccountReq[] | null
  sourceProjectId: number
  targetProjectId: number
  sourceDataset: string
  targetDataset: string
  runSchedule: boolean
  creditAllocator: boolean
  performanceCurves: boolean
  reallocations: Reallocation[]
  analyticsAccount?: AnalyticsAccountReq
  channelGroupingId: number
  targetCurrency?: string
  channelGroupingMetadata: ChannelGroupingMetadata
  includeIntercept?: boolean
  recencyWeighting?: number
}

export interface Reallocation {
  channel: string
  allocation: number
}

export interface AnalyticsAccountRes {
  accountId: number
  filterConditions?: FilterConditionRes[]
  externalAccountId: string
  platform: string
  has360?: boolean
  accountName: string
  channelGrouping: [ChannelDefinitionAnalytics]
  userIdIndex?: number
  channelGroupingSql?: string
  credentialsUsed: string
  conversionField: string
}
export interface AnalyticsAccountReq {
  accountId: number
  filterConditions?: FilterConditionReq[]
  platform: string
  conversionField?: string
}

export interface Rule {
  field: string
  operator: string
  value: string
}

export interface ChannelGrouping {
  exclude: boolean
  impressionChannel: boolean
  name: Platforms
  rules: Rule
}

export interface ChannelDefinitionAnalytics {
  name: string
  exclude?: boolean
  rules: Rule
}

export interface FilterConditionRes {
  filterConditionSql?: string
  filterConditionSqlEventAttributionConnector?: string
  filterConditionSqlSessionAttributionConnector?: string
  name: string
  rules: Rule[][]
}

export interface FilterConditionReq {
  name: string
  rules: Rule[][]
}

export interface AdAccountRes {
  clickField?: string
  conversionField: string
  accountId: number
  gclidBidding?: boolean
  gclidBiddingChannels?: [string]
  channelGrouping?: [ChannelGroupingReq]
  platform: string
  impressionChannels?: [string]
  filterConditions: [FilterConditionRes]
}

export type AdAccountOutput = {
  accountId: number
  accountName: string
  channelGrouping: ChannelGrouping
  channelGroupingSql: string
  connectorsConfigs: number
  externalAccountId: string
  filterConditions: FilterConditionRes[]
  gclidBiddingChannels?: string
  impressionsChannels?: string
  platform: Platforms
  filterConditionsSql?: string
  conversionField?: string
  clickField?: string
} & Pick<IPlatform, 'iconUrl'>

export interface AdAccountReq {
  accountId: number
  platform: string
  filterConditions?: [FilterConditionReq]
  conversionField?: string
  clickField?: string
  gclidBiddingChannels?: [string]
  impressionChannels?: [string]
}

export interface ChannelGroupingReq {
  name: string
  exclude?: boolean
  rules: Rule
}

export type ChannelGroupingMetadata = Record<
  string,
  {
    exclude: boolean
    impressionChannel: boolean
    reallocation: number
    meanPrior?: number
    adStock?: number
  }
>

export interface ConfigIdAndClientIdReq {
  clientId: string
  modelId: number
}

export const paths = {
  integrationAttributions: '/integrated_attributions'
}

export interface IntegrationAttributionsResp extends Array<AttributionResp> {}

export const getIntegrationAtributions = async (clientId: string): Promise<IntegrationAttributionsResp> => {
  return await client.get<IntegrationAttributionsResp>({
    path: `${paths.integrationAttributions}?client_id=${clientId}&credit_allocator=true`
  })
}

export const getIntegrationAtribution = async (modelId: number, clientId: string): Promise<AttributionResp> => {
  return await client.get<AttributionResp>({
    path: `${paths.integrationAttributions}/${modelId}?client_id=${clientId}`
  })
}

export const createIntegrationAtribution = async (data: AttributionModelReq): Promise<AttributionResp> => {
  const path = `${paths.integrationAttributions}`
  return await client.post<AttributionResp>({
    path,
    data
  })
}

export const deleteIntegrationAtribution = async (data: ConfigIdAndClientIdReq): Promise<AttributionResp> => {
  const path = `${paths.integrationAttributions}/${data.modelId}?client_id=${data.clientId}`
  return await client.del<AttributionResp>({
    path
  })
}

export const updateIntegrationAtribution = async ({
  data,
  configId
}: {
  data: AttributionModelReq
  configId: number
}): Promise<AttributionResp> => {
  const path = `${paths.integrationAttributions}/${configId}`
  return await client.put<AttributionResp>({
    path,
    data
  })
}
