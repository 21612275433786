import { forwardRef } from 'react'
import { StyledTab } from './styles'
import { TabProps } from './types'

const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ value, adornmentPosition, children, disabled, ...props }, ref): React.ReactElement => {
    return (
      <StyledTab
        label={children}
        value={value}
        adornmentPosition={adornmentPosition}
        disableFocusRipple
        disabled={disabled}
        {...props}
        ref={ref}
      />
    )
  }
)

export default Tab
