import React from 'react'

export const Account = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <circle cx="12.2707" cy="7.95572" r="3.647" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M19.5539 19.6913C19.5539 15.7702 16.3752 12.5916 12.4542 12.5916C8.53314 12.5916 5.35449 15.7702 5.35449 19.6913"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
})
