import { type ReactElement, createContext, useContext } from 'react'
import { type ChannelGroupingProviderProps, type ChannelGroupingContextProps } from './types'
import { useMutationUpdateChannelGrouping, useQueryChannelGrouping } from 'channelGrouping/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import Dots from 'shared/components/Loader/Dots'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import { getChannelByIdAndDataSource } from 'channelGrouping/utils'
import { type Platforms } from 'shared/api/accounts'
import useGetChannelsMap from 'channelGrouping/hooks/useGetChannelsMap'
import useGetDataSourcesMap from 'channelGrouping/hooks/useGetDataSourcesMap'
import { type ChannelItem } from 'shared/api/channelGroupings'
import { makeErrorToast } from 'shared/components/Toaster'
import { omit } from 'ramda'
import { APP_ROUTES } from 'shared/routes'
import { useTranslation } from 'shared/translations'
import { useGetUsedInConfigs } from 'channelGrouping/hooks/useGetUsedInConfigs'

const ChannelGroupingContext = createContext<ChannelGroupingContextProps | undefined>(undefined)

export const useCurrentChannelGrouping = (): ChannelGroupingContextProps => {
  const channelGroupingContext = useContext(ChannelGroupingContext)
  if (channelGroupingContext == null) {
    throw new Error('No channelGroupingContext.Provider found when calling channelGroupingContext.')
  }
  return channelGroupingContext
}

export const ChannelGroupingProvider = ({
  channelGroupingId,
  children
}: ChannelGroupingProviderProps): ReactElement => {
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('channelGrouping')
  const {
    data: channelGroupingResp,
    isLoading: channelGroupingRespIsLoading,
    isSuccess: channelGroupingRespIsSuccess,
    error: channelGroupingRespError
  } = useQueryChannelGrouping(channelGroupingId, currentClient.id)

  const { mutate: updateChannelGroupingMutation, isLoading: isUpdatingChannelGrouping } =
    useMutationUpdateChannelGrouping()

  const updateChannelGrouping: ChannelGroupingContextProps['updateChannelGrouping'] = (newFields, queryOptions) => {
    if (channelGroupingResp == null) {
      return
    }
    updateChannelGroupingMutation(
      {
        ...channelGroupingResp,
        ...newFields
      },
      queryOptions
    )
  }

  const deleteAllChannelsInDataSource: ChannelGroupingContextProps['deleteAllChannelsInDataSource'] = (
    dataSource,
    queryOptions
  ) => {
    if (channelGroupingResp == null) {
      return
    }

    const newChannelGrouping = omit([dataSource], channelGroupingResp.channelGrouping)

    updateChannelGroupingMutation(
      {
        ...channelGroupingResp,
        channelGrouping: newChannelGrouping
      },
      queryOptions
    )
  }

  const updateSingleChannel: ChannelGroupingContextProps['updateSingleChannel'] = (
    dataSource,
    channelId,
    newFields,
    queryOptions
  ) => {
    if (channelGroupingResp == null || channelGroupingResp.channelGrouping[dataSource] == null) {
      return
    }

    const { channel, index: channelIndex } = getChannelByIdAndDataSource(
      channelGroupingResp.channelGrouping,
      channelId,
      dataSource
    )
    if (channel == null || channelIndex == null) {
      return
    }

    const newChannels = [...(channelGroupingResp.channelGrouping[dataSource] as ChannelItem[])]
    const newChannel = {
      ...channel,
      ...newFields
    }
    newChannels[channelIndex] = newChannel
    updateChannelGroupingMutation(
      {
        ...channelGroupingResp,
        channelGrouping: {
          ...channelGroupingResp.channelGrouping,
          [dataSource]: newChannels
        }
      },
      queryOptions
    )
  }

  const updateChannelAcrossDataSources: ChannelGroupingContextProps['updateChannelAcrossDataSources'] = (
    channelId,
    newFields,
    queryOptions
  ) => {
    if (channelGroupingResp == null || dataSourcesMap == null) {
      return
    }

    const newChannelGrouping = { ...channelGroupingResp.channelGrouping }

    if ('name' in newFields) {
      const otherChannelWithSameName = Object.entries(channelsMap).find(([id, channel]) => {
        return channel.name.toLowerCase() === newFields.name?.toLowerCase() && id !== channelId
      })

      if (otherChannelWithSameName != null) {
        makeErrorToast(t('channelWithThisNameAlreadyExists', { channelName: newFields.name }))
        return
      }
    }

    // Update the channel with the same ID in all data sources
    Object.entries(channelGroupingResp.channelGrouping).forEach(([dataSource]) => {
      const dataSourceChannels = dataSourcesMap[dataSource as Platforms]?.channels as ChannelItem[]

      let channel: ChannelItem | undefined
      let channelIndex: number | undefined

      dataSourceChannels.forEach((channelItem, index) => {
        if (channelItem.id === channelId) {
          channel = channelItem
          channelIndex = index
        }
      })

      if (channel == null || channelIndex == null || newChannelGrouping[dataSource as Platforms] == null) {
        return
      }

      const newChannel = {
        ...channel,
        ...newFields
      }

      // We've already checked just above that this is not null. Therfore the non-null assertion is safe
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newChannelGrouping[dataSource as Platforms]![channelIndex] = newChannel
    })

    updateChannelGroupingMutation(
      {
        ...channelGroupingResp,
        channelGrouping: newChannelGrouping
      },
      queryOptions
    )
  }

  const updateChannelsInSingleDataSource: ChannelGroupingContextProps['updateChannelsInSingleDataSource'] = (
    dataSource,
    newChannels,
    queryOptions
  ) => {
    if (channelGroupingResp == null) {
      return
    }

    const newChannelGrouping = {
      ...channelGroupingResp,
      channelGrouping: { ...channelGroupingResp.channelGrouping, [dataSource]: newChannels }
    }

    if (newChannels.length === 0) {
      newChannelGrouping.channelGrouping = omit([dataSource], newChannelGrouping.channelGrouping)
    }

    updateChannelGroupingMutation(newChannelGrouping, queryOptions)
  }

  const channelsMap = useGetChannelsMap(channelGroupingResp)
  const dataSourcesMap = useGetDataSourcesMap(channelGroupingResp)
  const { usedInConfigs, isLoadingUsedInConfigsStatuses } = useGetUsedInConfigs(channelGroupingResp)

  const isLoading =
    channelGroupingResp == null || channelsMap == null || dataSourcesMap == null || usedInConfigs == null

  if (channelGroupingRespError != null) {
    if (channelGroupingRespError.status_code === 404) {
      return <NotFoundPage hasDashboardFrame returnPath={APP_ROUTES.customGroupings.basePage} />
    }
  }

  return isLoading ? (
    <Dots />
  ) : (
    <ChannelGroupingContext.Provider
      value={{
        currentChannelGrouping: channelGroupingResp,
        isLoadingChannelGrouping: channelGroupingRespIsLoading,
        isSuccessChannelGrouping: channelGroupingRespIsSuccess,
        channelGroupingId,
        channelsMap,
        dataSourcesMap,
        usedInConfigs,
        isLoadingUsedInConfigsStatuses,
        updateChannelGrouping,
        isUpdatingChannelGrouping,
        updateSingleChannel,
        updateChannelsInSingleDataSource,
        updateChannelAcrossDataSources,
        deleteAllChannelsInDataSource
      }}
    >
      {children}
    </ChannelGroupingContext.Provider>
  )
}
