import { styled } from '../../utils'
import { Theme, CSSObject } from '@mui/material/styles'
import { Box, Collapse, Drawer, List, ListItem } from '@mui/material'
import Grid from '../Grid'

const drawerWidth = 308

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: 500
  }),
  overflowX: 'hidden',
  background: theme?.palette.neutrals.stone90
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: 500
  }),
  overflowX: 'hidden',
  width: '80px',
  background: theme?.palette.neutrals.stone90
})

export const StyledGridDrawerFooter = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'flex-end'
}))

export const StyledDrawer = styled(Drawer)(
  ({ theme, open }: any): CSSObject => ({
    width: drawerWidth,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      border: 'initial',
      '& > .MuiList-root': {
        li: {
          padding: 0
        }
      }
    },
    '& .MuiListItem-root': {
      padding: '0 11px',
      '& .MuiListItemButton-root': {
        display: 'inline-flex',
        minHeight: 57,
        '& .MuiListItemIcon-root': {
          minWidth: 0,
          color: theme?.palette.secondary.main
        },
        '& .MuiListItemText-root': {
          marginLeft: '32px',
          ...(open === true && {})
        }
      }
    },
    ...(open === true && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(open === false && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    }),
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  })
)

export const StyledListItemNavItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isExpanded' && prop !== 'textColor' && prop !== 'isSelected'
})(
  ({
    isSelected,
    textColor,
    isExpanded,
    theme
  }: {
    isSelected?: boolean
    textColor?: string
    isExpanded?: boolean
    theme?: Theme
  }) => ({
    backgroundColor: isSelected === true ? theme?.palette.neutrals.stone100 : isExpanded === true ? '#F3F8F9' : 'none',
    borderRadius: theme?.borderRadius.xLarge,
    borderBottomLeftRadius: isExpanded === true ? 0 : theme?.borderRadius.xLarge,
    borderBottomRightRadius: isExpanded === true ? 0 : theme?.borderRadius.xLarge,
    margin: 0,
    '& .MuiListItemButton-root': {
      backgroundColor:
        isSelected === true ? theme?.palette.neutrals.stone100 : isExpanded === true ? '#F3F8F9' : 'none',
      borderRadius: theme?.borderRadius.xLarge,
      ':hover': {
        backgroundColor: '#DDEAEE'
      },
      ...(textColor != null && {
        color: textColor,
        svg: {
          color: textColor
        }
      })
    }
  })
)

export const StyledBoxChevronContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isNavBarOpen'
})(
  ({ isNavBarOpen, theme }: { isNavBarOpen: boolean; theme?: Theme }): CSSObject => ({
    display: 'flex',
    marginLeft: '16px',
    opacity: isNavBarOpen ? 1 : 0,
    transition: theme?.transitions.create('opacity', {
      easing: theme?.transitions.easing.easeInOut,
      duration: isNavBarOpen ? 220 : 0,
      delay: isNavBarOpen ? 280 : 0
    })
  })
) as React.ComponentType<any>

export const StyledListFooterContainer = styled(List)<{ open: boolean; theme?: Theme }>(({ open, theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.neutrals.stone100}`,
  '> li.MuiListItem-root': {
    '.MuiListItemButton-root': {
      '.MuiListItemText-root': {
        marginLeft: '24px'
      }
    }
  },
  '> div.MuiCollapse-root': {
    '.MuiButtonBase-root': {
      '.MuiListItemText-root': {
        marginLeft: '24px'
      }
    }
  },
  '.MuiListItemText-root': {
    '.MuiListItemText-secondary': {
      color: theme.palette.neutrals.stone150,
      textOverflow: 'ellipsis',
      overflow: open ? 'hidden' : 'initial'
    },
    '.MuiListItemButton-root': {
      padding: '9px 16px 9px 20px',
      '.MuiListItemIcon-root': {
        minWidth: 0
      }
    },
    '.MuiCollapse-root': {
      marginLeft: '38px',
      visibility: open ? 'initial' : 'hidden'
    }
  },
  '.MuiButtonBase-root': {
    paddingLeft: '8px'
  },
  '> .MuiListItem-root': {
    '.MuiButtonBase-root': {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}))

export const StyledListItemLogoContainer = styled(ListItem)<{ open: boolean }>(({ open }) => ({
  '&.MuiListItem-root': {
    padding: open ? '8px 22px !important' : '8px 22px'
  },
  display: 'block',
  padding: 0,
  marginBottom: '12px',
  '.MuiListItemButton-root': {
    width: '100%',
    padding: '0 16px 0 0'
  }
}))

export const StyledGridNavContentSubItemContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasCustomIcon' && prop !== 'textColor' && prop !== 'isSelected'
})<{
  isSelected: boolean
  textColor?: string
  hasCustomIcon?: boolean
  theme?: Theme
}>(({ isSelected, textColor, hasCustomIcon, theme }) => ({
  display: 'flex',
  gap: '32px',
  marginLeft: '8px',
  padding: '8px 16px 8px 8px',
  width: '100%',
  color: textColor ?? (isSelected ? theme?.palette.primary.main : theme?.palette.secondary.main),
  'h5, p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  svg: {
    display: isSelected || hasCustomIcon === true ? 'block' : 'none'
  },
  '&:hover': {
    svg: {
      display: 'block'
    },
    p: {
      ...theme.typography.h5
    }
  }
}))

export const StyledListNavContentContainer = styled(List)<{ open: boolean }>(({ open }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  height: '100%',
  padding: '0 11px',
  '.MuiCollapse-wrapper': {
    '.MuiListItem-root': {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
}))

export const StyledCollapseNavItemChildren = styled(Collapse, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ open: boolean; isSelected: boolean; theme?: Theme }>(({ open, isSelected, theme }) => ({
  margin: '0',
  backgroundColor: open ? (isSelected ? theme?.palette.neutrals.stone100 : '#F3F8F9') : 'transparent',
  borderRadius: theme?.borderRadius.xLarge,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  '& .MuiCollapse-wrapperInner .MuiGrid-root > li.MuiListItem-root': {
    padding: 0,
    paddingRight: '24px'
  }
}))
