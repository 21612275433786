import React, { type ReactElement } from 'react'
import BaseCell from './BaseCell'
import { type ListViewCellProps } from '../../types'
import { type ErrorCodeType, type ConfigStatus, type ErrorDetails } from 'shared/configStatuses'
import StatusWithBadge from 'shared/components/StatusWithBadge'
import { Grid } from '@precis-digital/kurama'
import { textAlignToFlexJustify } from '../../utils'
import { type DAGViewTypes } from 'shared/hooks/useDagView'
import { type DateInput } from 'shared/dateFns'

interface StatusCellProps<T extends object> extends ListViewCellProps<T> {
  status: ConfigStatus
  errorCode?: ErrorCodeType | null
  lastUpdated?: DateInput
  isLoadingStatus?: boolean
  errorDetails?: ErrorDetails | null
  dagViewInformation?: {
    dagViewType?: DAGViewTypes
    configId?: number
    configName?: string
    isActive?: boolean
    isPageWithConfigId?: boolean
  }
}

const StatusCell = <T extends object>({
  status,
  errorCode,
  lastUpdated,
  isLoadingStatus,
  errorDetails,
  dagViewInformation,
  ...props
}: StatusCellProps<T>): ReactElement => {
  return (
    <BaseCell width={props.column.parsedWidth} align={props.column.align}>
      <Grid container justifyContent={textAlignToFlexJustify(props.column.align)} gap="4px">
        <StatusWithBadge
          isLoading={isLoadingStatus}
          typographyVariant="h4"
          status={status}
          errorCode={errorCode}
          lastUpdated={lastUpdated}
          errorDetails={errorDetails}
          dagViewInformation={dagViewInformation}
        />
      </Grid>
    </BaseCell>
  )
}

export default StatusCell
