import React, { forwardRef } from 'react'
import { StyleTableBodyCell } from './styles'
import { TableBodyCellProps } from './types'

export const TableBodyCell = forwardRef<HTMLTableCellElement, TableBodyCellProps>(
  ({ children, ...props }, ref): React.ReactElement => {
    return (
      <StyleTableBodyCell ref={ref} {...props}>
        {children}
      </StyleTableBodyCell>
    )
  }
)
export default TableBodyCell
