import { Grid, SelectInput, SelectItem, Slider, styled, Toggle, Typography } from '@precis-digital/kurama'
import { useFormContext } from 'react-hook-form'
import { AccordionFormStep, AccordionFormStepItem } from 'shared/components/AccordionFormStep'
import FormItem from 'shared/components/FormItem'
import { useTranslation } from 'shared/translations'
import type { AttributionFormData, StepProps } from '..'
import {
  CREDIT_INPUTS,
  LOOKBACK_DAYS,
  RECENCY_WEIGHTING,
  RECENCY_WEIGHTING_DEFAULT,
  RECENCY_WEIGHTING_MAX,
  RECENCY_WEIGHTING_MIN
} from '../constants'
import { useCurrentClient } from 'shared/context/ClientContext'
import { currencies } from 'shared/constants/currency'

const GeneralStep = ({
  stepNumber,
  title,
  isPreviousOrCurrentStep,
  handleUpdate,
  toggleEdit,
  isNewConfig,
  editModes,
  optimizeBudget
}: StepProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel')
  const { control, getValues, setValue } = useFormContext<AttributionFormData>()
  const formData = getValues()

  const { currentClient } = useCurrentClient()

  const getDescriptiveLabelFromValue = (
    distributor: Array<{ value: number; label: string; descriptiveLabel: string }>,
    value: number | undefined
  ): string => {
    const descriptiveLabel = distributor.find((item) => item.value === value)?.descriptiveLabel
    return descriptiveLabel ?? ''
  }

  const isRBA = formData.model?.id === CREDIT_INPUTS.RBA
  return (
    <AccordionFormStep
      isLastStep
      stepName={t('form.step', { stepNumber })}
      title={title}
      expanded={isPreviousOrCurrentStep}
    >
      <AccordionFormStepItem title={t('createModel.advanced')} isCreateEvent={isNewConfig}>
        {(!isRBA || optimizeBudget) && (
          <FormItem
            name="general.lookbackDays"
            label={t('createModel.lookbackDays')}
            rules={{ required: true }}
            defaultValue="30"
            render={({ field }) => {
              return (
                <SelectInput fullWidth {...field}>
                  {LOOKBACK_DAYS.map((lookbackDay) => {
                    return (
                      <SelectItem key={lookbackDay} value={lookbackDay}>
                        {lookbackDay}
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              )
            }}
            editModes={editModes}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            currentValue={formData.general?.lookbackDays}
            control={control}
            canEdit
            isCreateEvent={isNewConfig}
            helperText={t('createModel.sharedHelperText.lookbackDays')}
          />
        )}
        <FormItem
          name="general.currency"
          label={t('createModel.currency')}
          rules={{ required: true }}
          defaultValue={currentClient?.currency}
          render={({ field }) => {
            return (
              <SelectInput fullWidth {...field}>
                {currencies.map((currency) => {
                  return (
                    <SelectItem key={currency.code} value={currency.code}>
                      {currency.name} ({currency.code})
                    </SelectItem>
                  )
                })}
              </SelectInput>
            )
          }}
          editModes={editModes}
          toggleEdit={toggleEdit}
          handleUpdate={handleUpdate}
          currentValue={formData.general?.currency}
          control={control}
          canEdit
          isCreateEvent={isNewConfig}
          helperText={optimizeBudget ? t('createModel.boHelperText.currency') : t('createModel.amHelperText.currency')}
        />
        {isRBA && !optimizeBudget && (
          <>
            <FormItem
              name="general.intercept"
              label={t('createModel.intercept')}
              defaultValue={false}
              xs={12}
              render={({ field }) => {
                return (
                  <Grid display="flex" justifyContent="space-between" alignItems="center">
                    <Grid xs={8}>
                      <Typography variant="body3">{t('createModel.rbaHelperText.intercept')}</Typography>
                    </Grid>
                    <Toggle
                      name="general.intercept"
                      defaultChecked={false}
                      checked={formData.general?.intercept ?? false}
                      onChange={(event) => {
                        setValue('general.intercept', event.target.checked)
                      }}
                    />
                  </Grid>
                )
              }}
              editModes={editModes}
              toggleEdit={toggleEdit}
              handleUpdate={handleUpdate}
              currentValue={formData.general?.intercept}
              control={control}
              canEdit
              isCreateEvent={isNewConfig}
            />
            <FormItem
              name="general.weighting"
              label={t('createModel.weighting')}
              defaultValue={RECENCY_WEIGHTING_DEFAULT}
              xs={12}
              render={({ field }) => {
                return (
                  <>
                    <Grid display="flex" xs={8}>
                      <Typography variant="body3">{t('createModel.rbaHelperText.weighting')}</Typography>
                    </Grid>
                    {field.value != null && (
                      <Typography variant="body3">
                        {getDescriptiveLabelFromValue(RECENCY_WEIGHTING, field.value)}
                      </Typography>
                    )}
                    <Grid>
                      <StyledSliderGrid>
                        <StyledSlider
                          {...field}
                          defaultValue={0}
                          marks={RECENCY_WEIGHTING.map((item) => ({ value: item.value }))}
                          step={null}
                          max={RECENCY_WEIGHTING[RECENCY_WEIGHTING.length - 1].value}
                        />
                      </StyledSliderGrid>
                      <Grid display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="body3">{RECENCY_WEIGHTING_MIN}</Typography>
                        <Typography variant="body3">{RECENCY_WEIGHTING_MAX}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              }}
              currentValue={getDescriptiveLabelFromValue(RECENCY_WEIGHTING, getValues('general.weighting'))}
              canEdit
              control={control}
              isCreateEvent={isNewConfig}
              editModes={editModes}
              toggleEdit={toggleEdit}
              handleUpdate={handleUpdate}
            />
          </>
        )}
      </AccordionFormStepItem>
    </AccordionFormStep>
  )
}

export default GeneralStep

const StyledSlider = styled(Slider)(() => ({
  width: '100%'
}))
const StyledSliderGrid = styled(Grid)(() => ({
  width: '100%',
  padding: '0px 20px 0px 20px'
}))
