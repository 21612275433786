import { useQueryChannelGroupingPerformanceData } from 'channelGrouping/api'
import { DATA_SOURCE_METRICS } from 'channelGrouping/constants/metrics'
import { type ChannelSummedDataSourceMetricValues, type SummedDataSourceMetricValues } from 'channelGrouping/types'
import { useMemo } from 'react'
import { type Platforms } from 'shared/api/accounts'
import { type ChannelGroupingReportType } from 'shared/api/analytics'
import { useCurrentClient } from 'shared/context/ClientContext'
import { capitalize } from 'shared/utils'
import toCamelCase from 'camelcase-keys'
import { DATA_SOURCE_ACCOUNT_ID_DIMENSION_MAP } from 'channelGrouping/constants/dimensions'
import { DATA_SOURCES_WITHOUT_CURRENCIES } from 'channelGrouping/constants'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { useShouldUseAnalyticsQueryCache } from './useShouldUseAnalyticsCache'

const useGetDataSourceMetricValues = (
  channelGroupingId: number,
  dataSource: Platforms,
  dataSourceIds?: string[],
  enabled: boolean = true
): {
  sumValues?: SummedDataSourceMetricValues
  channelsValues?: ChannelSummedDataSourceMetricValues
  accountsValues?: Record<string, SummedDataSourceMetricValues>
  isLoading: boolean
  isError: boolean
  dateStart: Date
  dateEnd: Date
} => {
  const { currentClient } = useCurrentClient()
  const { currentChannelGrouping } = useCurrentChannelGrouping()

  const dataSourceMetrics = DATA_SOURCE_METRICS[dataSource]

  const accountIdDimension = DATA_SOURCE_ACCOUNT_ID_DIMENSION_MAP[dataSource]

  if (accountIdDimension == null) {
    throw new Error(`No account id dimension found for ${dataSource}`)
  }

  const parsedAccountIdDimension = Object.keys(toCamelCase({ [accountIdDimension.toLowerCase()]: true }))[0]

  const queryFields = useMemo(() => {
    return [
      {
        name: 'channel_grouping'
      },
      {
        name: accountIdDimension
      },
      ...dataSourceMetrics.map((metric) => ({
        name: metric.id,
        aggregation: 'SUM'
      }))
    ]
  }, [accountIdDimension, dataSourceMetrics])

  const filters = useMemo(() => {
    if (dataSourceIds != null) {
      return [
        {
          field: accountIdDimension,
          operator: 'IN',
          value: dataSourceIds
        }
      ]
    }
  }, [accountIdDimension, dataSourceIds])

  const { yesterday, fourteenDaysAgo } = useMemo(() => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const fourteenDaysAgo = new Date()
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14)

    return {
      yesterday,
      fourteenDaysAgo
    }
  }, [])

  const shouldUseCache = useShouldUseAnalyticsQueryCache(currentChannelGrouping.updatedAt)

  const {
    data: performanceDataResponse,
    isLoading,
    isError,
    isFetching
  } = useQueryChannelGroupingPerformanceData({
    report: dataSource as ChannelGroupingReportType,
    clientId: currentClient.id,
    channelGroupingId,
    currencyCode: DATA_SOURCES_WITHOUT_CURRENCIES.includes(dataSource) ? undefined : currentClient.currency,
    fields: queryFields,
    dateStart: fourteenDaysAgo,
    dateEnd: yesterday,
    useCache: shouldUseCache,
    filters,
    enabled
  })

  const { sumValues, channelsValues, accountsValues } = useMemo(() => {
    if (performanceDataResponse?.data == null) {
      return {
        sumValues: undefined,
        channelsValues: undefined,
        accountsValues: undefined
      }
    }

    const sumValues: SummedDataSourceMetricValues = {}
    const channelsValues: ChannelSummedDataSourceMetricValues = {}
    const accountsValues: Record<string, SummedDataSourceMetricValues> = {}

    performanceDataResponse.data.forEach((record) => {
      if (channelsValues[record.channelGrouping] == null) {
        channelsValues[record.channelGrouping] = {}
      }
      if (accountsValues[record[parsedAccountIdDimension]] == null) {
        accountsValues[record[parsedAccountIdDimension]] = {}
      }

      dataSourceMetrics.forEach((metric) => {
        const parsedMetricId = capitalize(Object.keys(toCamelCase({ [metric.id.toLowerCase()]: true }))[0])
        const cirrusMetricId = `sum${parsedMetricId}`
        if (channelsValues[record.channelGrouping][metric.id] == null) {
          channelsValues[record.channelGrouping][metric.id] = 0
        }
        channelsValues[record.channelGrouping][metric.id] += (record[cirrusMetricId] as number) ?? 0

        if (sumValues[metric.id] == null) {
          sumValues[metric.id] = 0
        }
        sumValues[metric.id] += (record[cirrusMetricId] as number) ?? 0

        if (accountsValues[record[parsedAccountIdDimension]][metric.id] == null) {
          accountsValues[record[parsedAccountIdDimension]][metric.id] = 0
        }
        accountsValues[record[parsedAccountIdDimension]][metric.id] += (record[cirrusMetricId] as number) ?? 0
      })
    })

    return {
      sumValues,
      channelsValues,
      accountsValues
    }
  }, [performanceDataResponse, dataSourceMetrics, parsedAccountIdDimension])

  return {
    sumValues,
    channelsValues,
    accountsValues,
    isLoading: isFetching || isLoading,
    isError,
    dateStart: fourteenDaysAgo,
    dateEnd: yesterday
  }
}

export default useGetDataSourceMetricValues
