import { PLATFORM } from 'dataSource'
import { type Platforms } from 'shared/api/accounts'

type DimensionType = 'string' | 'multi_select' | 'single_select'
type DimensionOperatorType =
  | 'equals'
  | 'not_equals'
  | 'contains'
  | 'not_contains'
  | 'matches_regex'
  | 'not_matches_regex'
  | 'is'
  | 'not_is'
  | 'one_of'
  | 'not_one_of'

interface BaseDimension {
  label: string
  id: string
  type: DimensionType
  operators: DimensionOperator[]
}

interface StringDimension extends BaseDimension {
  type: 'string'
}

interface SelectDimension extends BaseDimension {
  type: 'single_select' | 'multi_select'
  options: Array<{
    /**
     * The value of the option.
     */
    value: string
    /**
     * The label that is displayed in the typography.
     */
    label: string
  }>
}

export type Dimension = StringDimension | SelectDimension

interface DimensionOperator {
  label: string
  id: DimensionOperatorType
}

const DIMENSION_OPERATORS: Record<DimensionOperatorType, DimensionOperator> = {
  equals: {
    label: 'Exactly matches',
    id: 'equals'
  },
  not_equals: {
    label: 'Does not exactly match',
    id: 'not_equals'
  },
  contains: {
    label: 'Contains',
    id: 'contains'
  },
  not_contains: {
    label: 'Does not contain',
    id: 'not_contains'
  },
  matches_regex: {
    label: 'Matches regex',
    id: 'matches_regex'
  },
  not_matches_regex: {
    label: 'Does not match regex',
    id: 'not_matches_regex'
  },
  is: {
    label: 'Is',
    id: 'is'
  },
  not_is: {
    label: 'Is not',
    id: 'not_is'
  },
  one_of: {
    label: 'Is one of',
    id: 'one_of'
  },
  not_one_of: {
    label: 'Is not one of',
    id: 'not_one_of'
  }
}

const DIMENSION_TYPE_OPERATORS: Record<DimensionType, DimensionOperator[]> = {
  string: [
    DIMENSION_OPERATORS.equals,
    DIMENSION_OPERATORS.not_equals,
    DIMENSION_OPERATORS.contains,
    DIMENSION_OPERATORS.not_contains,
    DIMENSION_OPERATORS.matches_regex,
    DIMENSION_OPERATORS.not_matches_regex
  ],
  single_select: [DIMENSION_OPERATORS.is, DIMENSION_OPERATORS.not_is],
  multi_select: [DIMENSION_OPERATORS.one_of, DIMENSION_OPERATORS.not_one_of]
}

export const DATA_SOURCE_ACCOUNT_ID_DIMENSION_MAP: Partial<Record<Platforms, string>> = {
  [PLATFORM.GOOGLE]: 'customer__id',
  [PLATFORM.FACEBOOK]: 'account_id',
  [PLATFORM.MICROSOFT_ADVERTISING]: 'AccountNumber',
  [PLATFORM.DV360]: 'filter_advertiser',
  [PLATFORM.LINKEDIN]: 'account_id',
  [PLATFORM.TIKTOK]: 'account_id',
  [PLATFORM.ANALYTICS]: 'account_id',
  [PLATFORM.GA4]: 'property_id',
  [PLATFORM.SNAPCHAT]: 'account_id'
}

export const DATA_SOURCE_DIMENSIONS: Record<Platforms, Dimension[]> = {
  [PLATFORM.GOOGLE]: [
    {
      label: 'Account name',
      id: 'customer__descriptive_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Account ID',
      id: 'customer__id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign name',
      id: 'campaign__name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ID',
      id: 'campaign__id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign status',
      id: 'campaign__status',
      type: 'multi_select',
      operators: DIMENSION_TYPE_OPERATORS.multi_select,
      options: [
        { label: 'Enabled', value: 'ENABLED' },
        { label: 'Paused', value: 'PAUSED' },
        { label: 'Removed', value: 'REMOVED' }
      ]
    },
    {
      label: 'Campaign type',
      id: 'campaign__advertising_channel_type',
      type: 'multi_select',
      operators: DIMENSION_TYPE_OPERATORS.multi_select,
      options: [
        { label: 'Search', value: 'SEARCH' },
        { label: 'Display', value: 'DISPLAY' },
        { label: 'Shopping', value: 'SHOPPING' },
        { label: 'Video', value: 'VIDEO' },
        { label: 'Discovery', value: 'DISCOVERY' },
        { label: 'Hotel', value: 'HOTEL' },
        { label: 'Local', value: 'LOCAL' },
        { label: 'Local services', value: 'LOCAL_SERVICES' },
        { label: 'Multi channel', value: 'MULTI_CHANNEL' },
        { label: 'Performance max', value: 'PERFORMANCE_MAX' },
        { label: 'Smart', value: 'SMART' },
        { label: 'Travel', value: 'TRAVEL' }
      ]
    },
    {
      label: 'Campaign sub type',
      id: 'campaign__advertising_channel_sub_type',
      type: 'multi_select',
      operators: DIMENSION_TYPE_OPERATORS.multi_select,
      options: [
        {
          label: 'App campaign',
          value: 'APP_CAMPAIGN'
        },
        {
          label: 'App campaign for engagement',
          value: 'APP_CAMPAIGN_FOR_ENGAGEMENT'
        },
        {
          label: 'App campaign for pre-registration',
          value: 'APP_CAMPAIGN_FOR_PRE_REGISTRATION'
        },
        {
          label: 'Display express',
          value: 'DISPLAY_EXPRESS'
        },
        {
          label: 'Display Gmail ad',
          value: 'DISPLAY_GMAIL_AD'
        },
        {
          label: 'Display mobile app',
          value: 'DISPLAY_MOBILE_APP'
        },
        {
          label: 'Display smart campaign',
          value: 'DISPLAY_SMART_CAMPAIGN'
        },
        {
          label: 'Local campaign',
          value: 'LOCAL_CAMPAIGN'
        },
        {
          label: 'Search express',
          value: 'SEARCH_EXPRESS'
        },
        {
          label: 'Search mobile app',
          value: 'SEARCH_MOBILE_APP'
        },
        {
          label: 'Shopping comparison listing ads',
          value: 'SHOPPING_COMPARISON_LISTING_ADS'
        },
        {
          label: 'Shopping smart ads',
          value: 'SHOPPING_SMART_ADS'
        },
        {
          label: 'Smart campaign',
          value: 'SMART_CAMPAIGN'
        },
        {
          label: 'Travel activities',
          value: 'TRAVEL_ACTIVITIES'
        },
        {
          label: 'Video action',
          value: 'VIDEO_ACTION'
        },
        {
          label: 'Video non-skippable',
          value: 'VIDEO_NON_SKIPPABLE'
        },
        {
          label: 'Video outstream',
          value: 'VIDEO_OUTSTREAM'
        },
        {
          label: 'Video reach target frequency',
          value: 'VIDEO_REACH_TARGET_FREQUENCY'
        },
        {
          label: 'Video sequence',
          value: 'VIDEO_SEQUENCE'
        }
      ]
    }
  ],
  [PLATFORM.FACEBOOK]: [
    {
      label: 'Account name',
      id: 'account_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Account ID',
      id: 'account_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign name',
      id: 'campaign_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ID',
      id: 'campaign_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign buying type',
      id: 'buying_type',
      type: 'single_select',
      operators: DIMENSION_TYPE_OPERATORS.single_select,
      options: [
        { label: 'Auction (default)', value: 'AUCTION' },
        { label: 'Reserved', value: 'RESERVED' }
      ]
    },
    {
      label: 'Campaign objective',
      id: 'objective',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Ad set name',
      id: 'adset_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Ad set ID',
      id: 'adset_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Ad name',
      id: 'ad_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Ad ID',
      id: 'ad_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.MICROSOFT_ADVERTISING]: [
    {
      label: 'Account name',
      id: 'AccountName',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Account ID',
      id: 'AccountNumber',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign name',
      id: 'CampaignName',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ID',
      id: 'CampaignId',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign type',
      id: 'CampaignType',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ad distribution',
      id: 'AdDistribution',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Network',
      id: 'Network',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.DV360]: [
    {
      label: 'Partner name',
      id: 'filter_partner_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Partner ID',
      id: 'filter_partner',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Advertiser name',
      id: 'filter_advertiser_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Advertiser ID',
      id: 'filter_advertiser',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign name',
      id: 'filter_media_plan_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ID',
      id: 'filter_media_plan',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Insertion order name',
      id: 'filter_insertion_order_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Insertion order ID',
      id: 'filter_insertion_order',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Line item name',
      id: 'filter_line_item_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Line item ID',
      id: 'filter_line_item',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Creative type',
      id: 'filter_creative_type',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Position in content',
      id: 'filter_position_in_content',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.LINKEDIN]: [
    {
      label: 'Account name',
      id: 'account_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Account ID',
      id: 'account_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign name',
      id: 'campaign_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ID',
      id: 'campaign_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign type',
      id: 'campaign_type',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign objective',
      id: 'campaign_objective_type',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.TIKTOK]: [
    {
      label: 'Account name',
      id: 'advertiser_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Account ID',
      id: 'account_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign name',
      id: 'campaign_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign objective',
      id: 'objective_type',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign dedicate type',
      id: 'campaign_dedicate_type',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Ad group name',
      id: 'adgroup_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Ad name',
      id: 'ad_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.ANALYTICS]: [
    {
      label: 'Source',
      id: 'source',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Medium',
      id: 'medium',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Source / Medium',
      id: 'source_medium',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign',
      id: 'campaign',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ID',
      id: 'adwordsCampaignId',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Account ID',
      id: 'account_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Property ID',
      id: 'web_property_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'View name',
      id: 'profile_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'View ID',
      id: 'profile_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Hostname',
      id: 'hostname',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.GA4]: [
    {
      label: 'Property name',
      id: 'property_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Property ID',
      id: 'property_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Source',
      id: 'source',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Medium',
      id: 'medium',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Source / Medium',
      id: 'source_medium',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign',
      id: 'campaign',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Stream ID',
      id: 'streamId',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Hostname',
      id: 'hostname',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.SNAPCHAT]: [
    {
      label: 'Account name',
      id: 'account_name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Account ID',
      id: 'account_id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign name',
      id: 'name',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign ID',
      id: 'id',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Campaign objective',
      id: 'objective',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    },
    {
      label: 'Buy model',
      id: 'buy_model',
      type: 'string',
      operators: DIMENSION_TYPE_OPERATORS.string
    }
  ],
  [PLATFORM.GOOGLE_SEARCH_CONSOLE]: [],
  [PLATFORM.GCP]: [],
  [PLATFORM.MERCHANT_CENTER]: [],
  [PLATFORM.GOOGLE_SHEETS]: [],
  [PLATFORM.AMAZON]: [],
  [PLATFORM.CRITEO]: []
}

const FILTER_SPECIFIC_DIMENSIONS = {
  [PLATFORM.GA4]: [
    {
      label: 'Country',
      id: 'geo_country'
    }
  ]
}

const platform = 'ga4'

export const DIMENSIONS_IN_THE_FILTER_LIST = [
  ...DATA_SOURCE_DIMENSIONS[platform],
  ...(FILTER_SPECIFIC_DIMENSIONS[platform] ?? [])
]

export const DEFAULT_INSPECT_RESULTS_TABLE_DIMENSIONS: Record<Platforms, string[]> = {
  [PLATFORM.GOOGLE]: ['customer__descriptive_name', 'campaign__name'],
  [PLATFORM.FACEBOOK]: ['account_name', 'campaign_name'],
  [PLATFORM.AMAZON]: ['accountName', 'campaignName'],
  [PLATFORM.CRITEO]: ['Advertiser', 'Campaign'],
  [PLATFORM.MICROSOFT_ADVERTISING]: ['AccountName', 'CampaignName'],
  [PLATFORM.DV360]: ['filter_partner_name', 'filter_media_plan_name'],
  [PLATFORM.LINKEDIN]: ['account_name', 'campaign_name'],
  [PLATFORM.TIKTOK]: ['advertiser_name', 'campaign_name'],
  [PLATFORM.ANALYTICS]: ['profile_name', 'source_medium', 'campaign'],
  [PLATFORM.GA4]: ['property_name', 'source_medium', 'campaign'],
  [PLATFORM.SNAPCHAT]: ['account_name', 'name'],
  [PLATFORM.GOOGLE_SEARCH_CONSOLE]: [],
  [PLATFORM.GCP]: [],
  [PLATFORM.MERCHANT_CENTER]: [],
  [PLATFORM.GOOGLE_SHEETS]: []
}
