import { useMemo } from 'react'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'

/**
 * Determines whether or not the Analytics request should allow to use the cache.
 * The cache is allowed if the last update was more than 30 seconds ago, to ensure we update
 * the data after a user makes a change.
 * @param updatedAt
 * @returns boolean determining whether or not the cache should be used
 */
export const useShouldUseAnalyticsQueryCache = (updatedAt: ChannelGroupingResp['updatedAt']): boolean => {
  return useMemo(() => {
    const thirtySecondsAfterLastUpdate = new Date(updatedAt)
    thirtySecondsAfterLastUpdate.setSeconds(thirtySecondsAfterLastUpdate.getSeconds() + 30)

    return new Date() > thirtySecondsAfterLastUpdate
  }, [updatedAt])
}
