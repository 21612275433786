import { Avatar, Grid, Tooltip, Typography, styled } from '@precis-digital/kurama'
import { type Metric } from 'channelGrouping/constants/metrics'
import { type SummedDataSourceMetricValues } from 'channelGrouping/types'
import React, { type ReactElement } from 'react'
import { type Platforms, type ClientAccountsResp } from 'shared/api/accounts'
import { useCurrentClient } from 'shared/context/ClientContext'
import formatToMoney, { formatToNumber } from 'shared/numberFormat'
import { useTranslation } from 'shared/translations'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

interface ConnectedDataSourcesAnnotationProps {
  connectedDataSources: ClientAccountsResp[]
  dataSource: Platforms
  accountsMetricValues?: Record<string, SummedDataSourceMetricValues>
  selectedMetric?: Metric
  isPerformanceDataError?: boolean
}

const ConnectedDataSourcesAnnotation = ({
  connectedDataSources,
  dataSource,
  accountsMetricValues,
  selectedMetric,
  isPerformanceDataError
}: ConnectedDataSourcesAnnotationProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const { currentClient } = useCurrentClient()

  const dataSourceMeta = getPlatformDetailsByPlatform(dataSource)

  const title = t('connectedAccounts', {
    count: connectedDataSources.length,
    suffix: connectedDataSources.length > 1 ? 's' : ''
  })

  return (
    <StyledTooltipFitContent
      title={title}
      body={
        <Grid display="flex" flexDirection="column" gap="4px">
          {connectedDataSources
            .sort((a, b) => {
              if (accountsMetricValues == null || selectedMetric == null) return a.name.localeCompare(b.name)
              const aMetricValue = accountsMetricValues[a.externalAccountId]?.[selectedMetric.id] ?? 0
              const bMetricValue = accountsMetricValues[b.externalAccountId]?.[selectedMetric.id] ?? 0
              return bMetricValue - aMetricValue
            })
            .map((connectedDataSource) => (
              <Grid
                key={connectedDataSource.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="16px"
              >
                <Grid display="flex" gap="8px" alignItems="center">
                  <Avatar size="small" kind="image" imageUrl={dataSourceMeta?.iconUrl ?? ''} />
                  <Grid display="flex" flexDirection="column">
                    <StyledTypography1LineClamp variant="body2">{connectedDataSource.name}</StyledTypography1LineClamp>
                    <StyledTypographyId variant="body3">{connectedDataSource.externalAccountId}</StyledTypographyId>
                  </Grid>
                </Grid>
                {isPerformanceDataError !== true && (
                  <StyledGridPerformanceDataTextContainer>
                    {selectedMetric != null && (
                      <Typography variant="body3">
                        {selectedMetric.type === 'currency'
                          ? formatToMoney({
                              value:
                                accountsMetricValues?.[connectedDataSource.externalAccountId]?.[selectedMetric.id] ?? 0,
                              currencyCode: currentClient.currency
                            })
                          : formatToNumber(
                              accountsMetricValues?.[connectedDataSource.externalAccountId]?.[selectedMetric.id] ?? 0
                            )}{' '}
                        {[selectedMetric.label]}
                      </Typography>
                    )}
                  </StyledGridPerformanceDataTextContainer>
                )}
              </Grid>
            ))}
        </Grid>
      }
      kind="multiline"
    >
      <StyledTypographyBlueAnnotation variant="body3">{title}</StyledTypographyBlueAnnotation>
    </StyledTooltipFitContent>
  )
}

const StyledTooltipFitContent = styled(Tooltip)(({ theme }) => ({
  '.MuiTooltip-tooltip': {
    width: 'fit-content',
    maxWidth: '400px'
  }
}))

const StyledTypography1LineClamp = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

const StyledGridPerformanceDataTextContainer = styled(Grid)(({ theme }) => ({
  textWrap: 'nowrap'
}))

const StyledTypographyId = styled(StyledTypography1LineClamp)(({ theme }) => ({
  color: theme?.palette.neutrals.stone120,
  marginTop: '-2px'
}))

const StyledTypographyBlueAnnotation = styled(Typography)(({ theme }) => ({
  color: theme?.palette.primary.main,
  width: 'fit-content'
}))

export default ConnectedDataSourcesAnnotation
