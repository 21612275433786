import React, { useState } from 'react'
import { PopUp, Typography, Label, Input, Toggle, Button, Divider } from '@precis-digital/kurama'

import {
  StyledCautionCard,
  StyledUpperText,
  StyledLeaveWorkspacePopupFooter,
  StyledPasswordInput,
  StyledTextSpan,
  StyledCautionText,
  StyledConfirmationBlock
} from '../Popup/styles'
import { type PopupProps } from './types'
import { handleConfirmationChange } from './utils'
import { useDeleteClient } from 'workspace/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'
import yellowCone from 'public/assets/images/yellow_cone.png'
import Image from 'next/image'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { APP_ROUTES } from 'shared/routes'
import { useChangePath } from 'shared/components/Router'

const CONFIRMATION_DELETE_WORD = 'DELETE'

const DeleteWorkspacePopup = (props: PopupProps): React.ReactElement => {
  const { open, onClose } = props

  const { changePath } = useChangePath()
  const { currentClient, clearCachedClient } = useCurrentClient()
  const [deleteInputValue, setDeleteInputValue] = useState('')
  const [confirmConditions, setConfirmConditions] = useState(false)
  const hasManuallyConfirmedToLeave = deleteInputValue === CONFIRMATION_DELETE_WORD
  const leaveInputColor = hasManuallyConfirmedToLeave ? 'success' : 'error'

  const { t } = useTranslation('workspace')

  const { mutate: deleteClient } = useDeleteClient()

  const handleDeleteClient = async (): Promise<void> => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    deleteClient(
      { clientId: currentClient.id },
      {
        onSuccess: async () => {
          toastOnSuccess(`Successfully deleted the workspace '${currentClient.name}'.`)

          await changePath(APP_ROUTES.workspace.listPage).then(() => {
            clearCachedClient()
          })
        },
        onError: () => {
          toastOnError('An unknown error occurred while trying to delete the workspace')
        }
      }
    )
  }

  return (
    <div>
      <PopUp open={open} handleOpen={onClose}>
        <Image
          src={yellowCone.src}
          width={544}
          height={179}
          alt="yellow cone"
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
        <StyledUpperText>
          <Typography variant="h2">
            {' '}
            {t('titles.deleteWorkspacePopup')} {currentClient.name}?
          </Typography>
        </StyledUpperText>
        <StyledCautionCard>
          <Label color="orange" text="Caution" />
          <StyledCautionText variant="h4">
            <StyledTextSpan> {t('descriptions.cautionBold')} </StyledTextSpan>
            {t('descriptions.caution')}
          </StyledCautionText>
        </StyledCautionCard>
        <Typography variant="h3"> {t('titles.confirmDeleteWorkspace')} </Typography>
        <StyledPasswordInput>
          <Typography variant="body2">{t('titles.typeDelete')}</Typography>
          <Input
            name="leave"
            value={deleteInputValue}
            {...(deleteInputValue !== '' ? { color: leaveInputColor } : null)}
            onChange={(e) => {
              handleConfirmationChange(e, CONFIRMATION_DELETE_WORD, setDeleteInputValue)
            }}
          />
        </StyledPasswordInput>
        <Divider />
        <StyledConfirmationBlock>
          <Typography variant="body1">{t('descriptions.understandingDeleteWorkspace')}</Typography>
          <Toggle
            onChange={(): void => {
              setConfirmConditions(!confirmConditions)
            }}
            defaultChecked={false}
            checked={confirmConditions}
          />
        </StyledConfirmationBlock>
        <Divider />
        <StyledLeaveWorkspacePopupFooter>
          <Button onClick={onClose} scheme="light" variant="outlined">
            {t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              void handleDeleteClient()
            }}
            scheme="light"
            variant="filled"
            disabled={!(confirmConditions && hasManuallyConfirmedToLeave)}
          >
            {t('buttons.deleteWorkspacePopup')}
          </Button>
        </StyledLeaveWorkspacePopupFooter>
      </PopUp>
    </div>
  )
}

export default DeleteWorkspacePopup
