import React, { useState, useEffect, useMemo, type SyntheticEvent } from 'react'
import _ from 'lodash'
import { equals } from 'ramda'
import {
  Accordion,
  PopUp,
  Typography,
  Avatar,
  styled,
  Grid,
  Divider,
  ArrowLeftIcon,
  ChevronDownIcon,
  Button,
  Banner,
  Input,
  Skeleton
} from '@precis-digital/kurama'
import { useForm } from 'react-hook-form'
import {
  getPlatformDetailsByPlatform,
  mapToAccounts,
  SearchQueryMatchesCriteria,
  areAllExternalAccountsConnected
} from 'dataSource/utils'
import { useAddAccount, useQueryClientAccounts, useQueryExternalAccounts } from 'dataSource/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'react-i18next'
import AccountsList from 'dataSource/components/Popup/AccountsList'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import { SearchInput, type IFieldsValues, type InputName } from 'dataSource/components/Popup/SearchInput'
import { type ExternalAccount } from 'shared/api/credentials'
import { type AddAccountReq, type Platforms } from 'shared/api/accounts'
import { type users } from 'shared/api'
import { makeToastWithLoading, makeErrorToast, closeToaster } from 'shared/components/Toaster'
import { infiniteScrollSupportMap } from 'dataSource/components/Popup/constants'
import { capitalize } from 'shared/utils'
import ActionButtons from 'dataSource/components/Popup/ActionButtons'
export interface AccountSelectionPopupProps {
  platform: Platforms
  credentialId: string
  datasource: {
    iconUrl: string
    commonIconUrl: string
    label: string
    oauthUrl: string
    platform: string
    externalEntityLabel: string
    externalEntitiesLabel: string
    isManualAccountInput: boolean
  }
  credentials: users.CredentialsResp[]
}

export interface IAccountsList extends ExternalAccount {
  connected: boolean
  selected?: boolean
  has360?: boolean
  externalAccountId: string
  clientId: string
  credentialId: string
  platform: Platforms
}

export interface IPricingPlan {
  id: string
  pricingPlan: 'free' | 'paid'
}

const sortAccounts = (a: IAccountsList, b: IAccountsList): number =>
  (a.displayName ?? a.name ?? a.regionId ?? a.externalAccountId ?? a.id).localeCompare(
    b.displayName ?? b.name ?? b.regionId ?? b.externalAccountId ?? b.id
  )

export const AccountSelectionPopup = ({
  platform,
  credentialId,
  datasource,
  credentials
}: AccountSelectionPopupProps): React.ReactElement => {
  const { t } = useTranslation('dataSource')
  const { t: tManuallyInputAccount } = useTranslation('dataSource', { keyPrefix: 'popups.manualAccountInputPopup' })
  const [openConnectedAccounts, setOpenConnectedAccounts] = useState(false)
  const [errorOnAddingDataSource, setErrorOnAddingDataSource] = useState(false)
  const [targetExternalAccountId, setTargetExternalAccountId] = useState('')

  const { currentClient } = useCurrentClient()
  const [connectedAccounts, setConnectedAccounts] = useState<{
    originalConnectedAccounts: IAccountsList[]
    selectedConnectedAccounts: IAccountsList[]
  }>({
    originalConnectedAccounts: [],
    selectedConnectedAccounts: []
  })
  const { control, setValue } = useForm<IFieldsValues>({})
  const accountSelectionScreen = useOverScreen('accountSelectionPopup')
  const [pricingPlans, setPricingPlans] = useState<IPricingPlan[]>([])
  const [searchInput, setSearchInput] = useState('')
  const [accountsList, setAccountsList] = useState<{
    originalAccountsList: IAccountsList[]
    filteredAccountsList: IAccountsList[]
    selectedAccountsToAdd: IAccountsList[]
  }>({
    originalAccountsList: [],
    filteredAccountsList: [],
    selectedAccountsToAdd: []
  })

  const [canAddAccounts, setCanAddAccounts] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [isDebouncingSearch, setIsDebouncingSearch] = useState(false)
  const MILLISECONDS_500 = 500
  const debouncedSearch = useMemo(
    () =>
      _.debounce((query) => {
        setSearchQuery(query)
        setIsDebouncingSearch(false)
      }, MILLISECONDS_500),
    []
  )

  const supportsInfiniteScroll = infiniteScrollSupportMap[platform]
  const handleInfiniteScroll = async (): Promise<void> => {
    void fetchNextPage()
  }

  const handleOnSelectAccount = (accountId: string): void => {
    const accountToToggle = accountsList.filteredAccountsList.find(
      (account) => account.id === accountId
    ) as IAccountsList

    accountToToggle.selected = !(accountToToggle.selected ?? false)

    const updatedSelectedAccountsToAdd = accountToToggle.selected
      ? [...accountsList.selectedAccountsToAdd, accountToToggle]
      : accountsList.selectedAccountsToAdd.filter((acc) => acc.id !== accountId)

    setAccountsList((prevState) => ({
      ...prevState,
      selectedAccountsToAdd: updatedSelectedAccountsToAdd,
      filteredAccountsList: prevState.filteredAccountsList.map((account) =>
        account.id === accountId ? accountToToggle : account
      )
    }))

    if (!isAnalyticsPlatform) {
      const canAdd = accountsList.filteredAccountsList.some(
        (account) => (account.selected ?? false) && !account.connected
      )
      setCanAddAccounts(canAdd)
    }
  }

  const handleOnCancel = (): void => {
    accountSelectionScreen.remove()
  }
  const handleConnectedAccounts = (event: SyntheticEvent, expanded: boolean): void => {
    setOpenConnectedAccounts(expanded)
  }
  const extractSpreadsheetId = (input: string): string => {
    const regex = /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/
    const match = regex.exec(input)
    return match != null ? match[1] : input
  }
  const [isAddingAccount, setIsAddingAccount] = useState(false)

  const handleOnSubmit = async (): Promise<void> => {
    setIsAddingAccount(true)
    setCanAddAccounts(false)
    const { toastOnError, toastOnSuccess } = makeToastWithLoading()

    try {
      if (datasource.isManualAccountInput) {
        const externalAccountId = extractSpreadsheetId(targetExternalAccountId)
        const manualAccount: AddAccountReq = {
          clientId: currentClient.id,
          platform,
          externalAccountId,
          credentialsId: credentialId
        }
        await addAccount(manualAccount)
      } else {
        const promises = accountsList.selectedAccountsToAdd
          .filter((account) => account.selected)
          .map(async (account) => {
            const mappedAccount: AddAccountReq = {
              ...(account.name !== '' && account.name !== undefined ? { name: account.name } : {}),
              ...(account.platform === 'analytics'
                ? {
                    externalPropertyId: account.propertyId,
                    externalParentAccountId: account.parentId
                  }
                : {}),
              ...(account.platform === 'amazon'
                ? {
                    regionId: account.regionId,
                    countryCode: account.countryCode
                  }
                : {}),
              ...(account.platform === 'google'
                ? {
                    canManageClients: account.canManageClients
                  }
                : {}),
              clientId: account.clientId,
              credentialsId: credentialId,
              platform: account.platform,
              externalAccountId: account.id,
              has_360: pricingPlans.find((plan) => plan.id === account.id && plan.pricingPlan === 'paid') !== undefined
            }
            return await addAccount(mappedAccount)
          })

        await Promise.all(promises)
        setIsAddingAccount(false)
        setCanAddAccounts(true)
      }

      accountSelectionScreen.remove()
      toastOnSuccess(t('popups.accountSelection.addAccountsSuccessMessage'))
    } catch (error) {
      setErrorOnAddingDataSource(true)
      closeToaster()
      if (!datasource.isManualAccountInput) {
        toastOnError(t('popups.accountSelection.addAccountsErrorMessage'))
      }
    } finally {
      if (!datasource.isManualAccountInput) {
        accountSelectionScreen.remove()
      }
    }
  }
  const handleGoBackCredentialSelection = (): void => {
    OverScreen.remove('selectDataSourceCredentialsPopup')
    void OverScreen.show('connectNewDataSourcePopup')
  }

  const handleGoBackAccountSelection = (): void => {
    accountSelectionScreen.remove()
    void OverScreen.show('selectDataSourceCredentialsPopup', {
      datasource,
      credentials,
      onGoBack: handleGoBackCredentialSelection
    })
  }
  const handleOnSearch = (inputName: typeof InputName, e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setValue(inputName, value)
    setSearchInput(value)
    const searchValueLowerCase = value.toLowerCase()

    if (supportsInfiniteScroll) {
      setIsDebouncingSearch(true)
      debouncedSearch(value)
    } else {
      const filteredAccounts = accountsList.originalAccountsList.filter(
        SearchQueryMatchesCriteria(searchValueLowerCase)
      )
      const filteredConnectedAccounts = connectedAccounts.originalConnectedAccounts.filter(
        SearchQueryMatchesCriteria(searchValueLowerCase)
      )

      setAccountsList({
        ...accountsList,
        filteredAccountsList: filteredAccounts
      })
      setConnectedAccounts({
        ...connectedAccounts,
        selectedConnectedAccounts: filteredConnectedAccounts
      })
    }
  }
  const clearSearch = (inputName: typeof InputName, e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    setValue(inputName, '')
    setSearchInput('')
    setAccountsList({
      ...accountsList,
      filteredAccountsList: accountsList.originalAccountsList
    })
    setConnectedAccounts({
      ...connectedAccounts,
      selectedConnectedAccounts: connectedAccounts.originalConnectedAccounts
    })
  }

  const {
    data: accounts,
    isSuccess: isAccountsSuccess,
    isFetchingNextPage: isFetchingNextPageExternalAccounts,
    isLoading: isFetchingExternalAccounts,
    fetchNextPage,
    error: isFetchExternalAccountsError
  } = useQueryExternalAccounts(platform, credentialId, searchQuery)
  const { data: clientAccounts, isSuccess: isClientAccountsSuccess } = useQueryClientAccounts(currentClient.id)
  const { mutateAsync: addAccount } = useAddAccount()
  const allAccounts = useMemo(() => {
    return accounts?.pages.flatMap((page) => page.data) ?? []
  }, [accounts?.pages])
  const dataSource = getPlatformDetailsByPlatform(platform)
  const isManualAccountInput = datasource.isManualAccountInput
  useEffect(() => {
    if (isManualAccountInput && targetExternalAccountId !== '') {
      setCanAddAccounts(true)
    } else {
      setCanAddAccounts(false)
    }
    if (pricingPlans.length > 0) {
      setCanAddAccounts(true)
    }
  }, [pricingPlans, targetExternalAccountId, isManualAccountInput])

  const externalEntityLabelOrWholeLink = tManuallyInputAccount('externalEntityLabelOrWholeLink', {
    externalEntityLabel: capitalize(datasource.externalEntityLabel)
  })

  useEffect(() => {
    let connected = []
    if (isAccountsSuccess && isClientAccountsSuccess) {
      connected = clientAccounts.filter(
        (clientAccount) => clientAccount.connected && clientAccount.platform === platform
      )
      if (connected != null && connected.length > 0) {
        const mappedConnectedAccounts = mapToAccounts(connected, currentClient, platform).sort(sortAccounts)
        setConnectedAccounts({
          ...connectedAccounts,
          originalConnectedAccounts: mappedConnectedAccounts,
          selectedConnectedAccounts: mappedConnectedAccounts
        })
      }
      const notConnectedAccounts = allAccounts.filter(
        (account) =>
          !clientAccounts.some(
            (clientAccount) =>
              clientAccount.externalAccountId === account.id && clientAccount.platform === dataSource.platform
          )
      )
      const mappedAccounts = mapToAccounts(notConnectedAccounts, currentClient, platform).sort(sortAccounts)
      const isDataChanged = !equals(accountsList.originalAccountsList, mappedAccounts)

      if (isDataChanged) {
        setAccountsList((prevState) => ({
          ...prevState,
          originalAccountsList: mappedAccounts,
          filteredAccountsList: mappedAccounts
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountsSuccess, isClientAccountsSuccess, allAccounts, clientAccounts, currentClient, platform])

  const isAnalyticsPlatform: boolean = platform === 'analytics'
  const allAccountsConnected: boolean = areAllExternalAccountsConnected(
    connectedAccounts.originalConnectedAccounts,
    allAccounts
  )

  const credentialName = credentials.find((cred) => cred.id === credentialId)?.name
  const popupAccountContent = (): React.ReactNode => {
    const hasNoAccounts =
      accountsList.originalAccountsList.length === 0 && connectedAccounts?.originalConnectedAccounts.length === 0
    const searchNotFound =
      accountsList.filteredAccountsList.length === 0 &&
      connectedAccounts?.selectedConnectedAccounts.length === 0 &&
      searchInput !== '' &&
      !isDebouncingSearch &&
      !isFetchingNextPageExternalAccounts

    if (datasource.isManualAccountInput) {
      return (
        <>
          <StyledTitleContainer>
            <Avatar size="large" imageUrl={datasource.iconUrl} kind="image" />
            <Typography variant="h2">
              {tManuallyInputAccount('Title', {
                label: datasource.label,
                externalEntityLabel: datasource.externalEntitiesLabel
              })}
            </Typography>
          </StyledTitleContainer>
          {errorOnAddingDataSource && (
            <Banner variant="error">
              <Typography variant="body2">
                <strong>{tManuallyInputAccount('ohSnap')}</strong>{' '}
                {tManuallyInputAccount('somethingWentWrongAccessing', {
                  externalEntityLabel: datasource.externalEntityLabel
                })}
              </Typography>
            </Banner>
          )}
          <StyledInputExplainerText>
            <Avatar size="small" imageUrl={datasource.iconUrl} kind="image" />
            <Typography variant="body2">
              <strong>
                {tManuallyInputAccount('enterYourAccountId', {
                  datasourceLabel: datasource.label,
                  externalEntityLabel: datasource.externalEntityLabel
                })}
              </strong>
            </Typography>
            <Typography variant="body3">{tManuallyInputAccount('required')}</Typography>
          </StyledInputExplainerText>
          <ExternalIdExplainerContainer>
            <Typography variant="body3">
              {tManuallyInputAccount('externalAccountIdExplainer', {
                label: datasource.label,
                externalEntityLabel: datasource.externalEntityLabel
              })}
            </Typography>
            <Typography variant="body3">{tManuallyInputAccount('findingSpreadsheetId')}</Typography>
          </ExternalIdExplainerContainer>
          <StyledInputContainer>
            <Input
              name={externalEntityLabelOrWholeLink}
              placeholder={externalEntityLabelOrWholeLink}
              onChange={(e) => {
                setTargetExternalAccountId(e.target.value)
              }}
              fullWidth
              value={targetExternalAccountId}
            />
          </StyledInputContainer>
          <Typography variant="body3">
            {tManuallyInputAccount('explainerText', {
              label: datasource.label,
              externalEntityLabel: datasource.externalEntityLabel
            })}
          </Typography>
        </>
      )
    }
    if (isFetchExternalAccountsError != null) {
      makeErrorToast(
        t('popups.accountSelection.unexpectedErrorFetchingAccounts', {
          externalEntitiesLabel: dataSource.externalEntitiesLabel
        })
      )
    }
    if (isFetchingExternalAccounts) {
      return (
        <Grid display="flex" flexDirection="column" gap="16px" width="100%">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} width="100%" height="94px" />
          ))}
        </Grid>
      )
    }
    if (!datasource.isManualAccountInput && hasNoAccounts && searchNotFound) {
      if (searchInput === '') {
        return (
          <StyledNoItemsFound>
            <Typography variant="body1">
              {t('popups.accountSelection.noAccountsFoundForCredentials', {
                externalEntitiesLabel: dataSource.externalEntitiesLabel
              })}
            </Typography>
            <StyledCredentialText variant="h4">
              {credentialName} ({credentialId})
            </StyledCredentialText>
            <Typography variant="body1">
              {t('popups.accountSelection.makeSureYouHaveAccessWithCredentials', {
                externalEntitiesLabel: dataSource.externalEntitiesLabel
              })}
            </Typography>
          </StyledNoItemsFound>
        )
      } else {
        return (
          <StyledNoItemsFound>
            <Typography variant="body1">{t('popups.accountSelection.noAccountsAvailable')}</Typography>
            <Typography variant="h1">“{searchInput}”</Typography>
          </StyledNoItemsFound>
        )
      }
    }
    if (!datasource.isManualAccountInput && searchNotFound) {
      return (
        <StyledNoItemsFound>
          <Typography variant="body1">{t('popups.accountSelection.noAccountsAvailable')}</Typography>
          <Typography variant="h1">“{searchInput}”</Typography>
        </StyledNoItemsFound>
      )
    } else {
      return [
        !datasource.isManualAccountInput && !allAccountsConnected && (
          <AccountsList
            accountsList={accountsList}
            handleOnSelectAccount={handleOnSelectAccount}
            dataSource={dataSource}
            pricingPlans={pricingPlans}
            setPricingPlans={setPricingPlans}
            onNearBottom={supportsInfiniteScroll ? handleInfiniteScroll : undefined}
            isLoadingAccounts={isFetchingNextPageExternalAccounts}
          />
        ),
        connectedAccounts.selectedConnectedAccounts.length > 0 && (
          <>
            <Divider />
            <StyledAccordion
              expanded={openConnectedAccounts || searchInput !== ''}
              onChange={handleConnectedAccounts}
              Details={
                <AccountsList
                  accountsList={accountsList}
                  handleOnSelectAccount={handleOnSelectAccount}
                  dataSource={dataSource}
                  connectedAccounts={connectedAccounts}
                  pricingPlans={pricingPlans}
                  setPricingPlans={setPricingPlans}
                  isLoadingAccounts={false}
                />
              }
              Summary={
                <StyledAccordionSummary>
                  <Typography variant="h4">{t('popups.accountSelection.connectedAccountsSubtitle')}</Typography>
                  {allAccountsConnected && (
                    <Typography variant="body1">
                      {t('popups.accountSelection.connectedAllAccountsMessage', {
                        dataSource: dataSource.label,
                        externalEntitiesLabel: dataSource.externalEntitiesLabel
                      })}
                    </Typography>
                  )}
                </StyledAccordionSummary>
              }
              expandIcon={<ChevronDownIcon />}
            />
          </>
        )
      ]
    }
  }

  return (
    <PopUp
      open={accountSelectionScreen.visible}
      handleOpen={(): void => {
        accountSelectionScreen.remove()
      }}
    >
      <StyledContent>
        <StyledContentHeader>
          <StyledBackButton variant="text" onClick={handleGoBackAccountSelection} leftIcon={<ArrowLeftIcon />}>
            {t('popups.accountSelection.backButton')}
          </StyledBackButton>
          {!datasource.isManualAccountInput && (
            <>
              <StyledAvatar size="large" imageUrl={dataSource.iconUrl} kind="image" />
              <Typography variant="h2">
                {t('popups.accountSelection.title', {
                  label: dataSource.label,
                  externalEntitiesLabel: dataSource.externalEntitiesLabel
                })}
              </Typography>
              <SearchInput
                control={control}
                onSearch={handleOnSearch}
                clearInput={clearSearch}
                dataSourceMeta={dataSource}
              />
            </>
          )}
        </StyledContentHeader>
        {popupAccountContent()}
        {((!allAccountsConnected && accountsList.filteredAccountsList.length !== 0) ||
          datasource.isManualAccountInput) && (
          <>
            <Divider />
            <ActionButtons
              onSubmit={() => {
                if (canAddAccounts) {
                  void handleOnSubmit()
                }
              }}
              isSubmitDisabled={!canAddAccounts}
              onCancel={handleOnCancel}
              isAddingAccount={isAddingAccount}
              errorOnAddingDataSource={errorOnAddingDataSource}
            />
          </>
        )}
      </StyledContent>
    </PopUp>
  )
}

export const StyledInputContainer = styled('div')(() => ({
  width: '100%',
  paddingTop: '8px',
  paddingBottom: '24px'
}))

export const StyledInputExplainerText = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'left',
  gap: '4px',
  width: '100%',
  marginTop: '10px',
  marginBottom: '10px'
}))

export const StyledActions = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: 0,
  gap: 8,
  width: '100%'
}))

const StyledBackButton = styled(Button)(() => ({
  position: 'absolute',
  top: '-60px',
  left: '-16px'
}))

const StyledTitleContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px'
}))

export const StyledCredentialText = styled(Typography)({
  margin: '8px'
})

export const StyledContent = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0px 24px',
  gap: 8
}))
export const StyledContentHeader = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 0px 16px',
  textAlign: 'center',
  position: 'relative'
}))

export const StyledAvatar = styled(Avatar)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  margin: '0px 0px 24px',
  gap: 10
}))

export const StyledAccordion = styled(Accordion)(() => ({
  '&::before': {
    opacity: 0
  },
  '& div:nth-of-type(2)': {
    '& > div > div > div > div': {
      paddingLeft: '0',
      paddingBottom: '0'
    }
  }
}))

export const StyledAccordionSummary = styled(Grid)(() => ({
  flexDirection: 'column'
}))

const StyledNoItemsFound = styled(Grid)(() => ({
  overflowWrap: 'break-word',
  textAlign: 'center',
  width: '100%',
  marginTop: '74px',
  marginBottom: '74px'
}))

export const StyledLoadingContainer = styled(Grid)(() => ({
  width: '100%'
}))

const ExternalIdExplainerContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%'
}))

export default OverScreen.create(AccountSelectionPopup)
