import React from 'react'
import MUISvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './types'
const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'inherit', viewBox, styles, inheritViewBox = false, children, ...props }, ref): React.ReactElement => {
    return (
      <MUISvgIcon htmlColor={color} sx={styles} viewBox={viewBox} inheritViewBox={inheritViewBox} {...props} ref={ref}>
        {children}
      </MUISvgIcon>
    )
  }
)

export default Icon
