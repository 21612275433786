import Select from '@mui/material/Select'
import { styled } from '../../../utils'
import { color } from './types'
import { CSSObject, Theme } from '@mui/material/styles'

export const StyledSelectContainer = styled('div')<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  ...(fullWidth == null || !fullWidth ? { width: '480px' } : {})
}))
export const StyledSelect = styled(Select)(({ theme, color }: { theme?: Theme; color?: color }) => ({
  '&.MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '16px',
    fontSize: '0.875rem',
    width: '100%',
    lineHeight: '24px',
    '>div': {
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      '>div': {
        minWidth: 'auto',
        paddingRight: '8px'
      },
      '&:has(div)': {
        paddingLeft: '16px'
      }
    },
    '>div[aria-expanded="true"] ~ svg': {
      transform: 'rotate(180deg)'
    },
    svg: {
      position: 'absolute',
      right: '7px',
      pointerEvents: 'none'
    },
    fieldset: {
      border: '1px solid #EEF4F6'
    },
    '&:hover': {
      backgroundColor: theme?.palette.neutrals.stone90,
      border: 'none',
      fieldset: {
        border: 'none'
      }
    },
    '.select-placeholder': {
      color: theme?.palette.neutrals.stone150
    }
  },
  '&.MuiInputBase-root.MuiOutlinedInput-root:has(div > .select-placeholder)': {
    backgroundColor: theme?.palette.neutrals.stone100
  },
  '&.MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme?.palette.neutrals.stone90,
    color: theme?.palette.neutrals.stone120
  },
  '.MuiSelect-icon': {
    top: 'unset',
    color: theme?.palette.secondary.main
  },
  ...StyledSelectFocus(theme, color)
}))

const StyledSelectFocus = (theme?: Theme, color?: color): CSSObject => ({
  '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: theme?.palette.neutrals.white0,
    fieldset: {
      border: `1px solid ${theme?.palette.primary.main ?? '#5578F5'}`,
      boxShadow: `0 0 0 3px ${theme?.palette.semantic.info05 ?? '#F5F7FE'}`
    },

    ...(color === 'success' && {
      fieldset: {
        border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`,
        boxShadow: `0 0 0 3px ${theme?.palette.semantic.success05 ?? '#E8FDF7'}`
      }
    }),
    ...(color === 'error' && {
      fieldset: {
        border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`,
        boxShadow: `0 0 0 3px ${theme?.palette.semantic.error05 ?? '#FFF0F5'}`
      }
    })
  }
})

export const StyledMessage = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme?.typography.body3.fontFamily,
    fontSize: theme?.typography.body3.fontSize,
    marginTop: '8px',
    paddingLeft: '6px',
    span: {
      paddingLeft: '6px'
    }
  })
)
