import { Popover } from '@mui/material'
import { Theme } from '../../theme'
import { styled } from '../../../utils'

export const StyledPopover = styled(Popover)(({ theme }: { theme?: Theme }) => ({
  '.MuiPopover-paper': {
    color: 'inherit',
    borderRadius: theme?.borderRadius.xxLarge,
    boxShadow: theme?.elevation.mediumDepth
  }
}))
