import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'facebook_visual_creative_report',
  active: true,
  label: 'Facebook Visual Creative Report',
  category: 'Platform specific',
  description:
    'The Facebook Visual Creative report provides quick overviews and visual insight on image and video creative performance, along with standard campaign performance.',
  image_url: 'https://lh3.google.com/u/0/d/1-FbVG3QVmsb84Mx0o3QuGDN4VPDPDfCl=w1920-h914-iv1',
  authors: [
    {
      name: 'Tobias Hink',
      email: 'hink@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjyNEc4WJk3HgY11avoS-2opMitZKNbLBZcaWvS=s126-p-k-rw-no'
    },
    {
      name: 'Joella Berg',
      email: 'joella@precisdigital.com',
      image_url: 'https://drive.google.com/uc?id=1uX3nOAOFswYoVXn3gqg3DErkA4xEC2uY'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2396946433',
    link_description: 'Knowledge Hub: Facebook Visual Creative Report'
  },
  dashboard: {
    template_report_id: 'a32ced09-4619-43ee-8870-3d4f22695403',
    report_name_template: 'copy of Facebook Visual Creative Report - {today}',
    datasources: [
      {
        alias: 'ds1148',
        datasourceName: 'copy of facebook_visual_creative_report',
        type: 'TABLE',
        tableId: 'facebook_visual_creative_report_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      }
    ]
  },
  sources: {
    facebook: {
      reports: ['ad_performance', 'image_performance_report', 'video_performance_report']
    }
  },
  inputs: {
    facebook: [
      {
        id: 'facebookCustomChannelGrouping',
        type: 'case_statement',
        label: 'Custom Channel Grouping',
        tooltip: 'The Channel Grouping that defines your campaigns, ad sets or ads on Facebook/Meta',
        required: true,
        mutable: true,
        dimensions: [
          {
            value: 'account_id',
            label: 'Account ID',
            type: 'string'
          },
          {
            value: 'campaign_name',
            label: 'Campaign',
            type: 'string'
          },
          {
            value: 'adset_name',
            label: 'Ad set Name',
            type: 'string'
          },
          {
            value: 'ad_name',
            label: 'Ad Name',
            type: 'string'
          }
        ]
      },
      {
        id: 'facebookCustomMarketGrouping',
        type: 'case_statement',
        label: 'Custom Market Grouping',
        tooltip: 'The Market Grouping that defines your Markets for Facebook/Meta',
        required: true,
        mutable: true,
        dimensions: [
          {
            value: 'account_id',
            label: 'Account ID',
            type: 'string'
          },
          {
            value: 'campaign_name',
            label: 'Campaign',
            type: 'string'
          },
          {
            value: 'adset_name',
            label: 'Ad set Name',
            type: 'string'
          },
          {
            value: 'ad_name',
            label: 'Ad Name',
            type: 'string'
          }
        ]
      }
    ],
    other: [
      {
        id: 'targetCurrency',
        type: 'dropdown',
        label: 'Target Currency',
        defaultValue: 'EUR',
        tooltip: 'The currency to transform all currency-based columns (cost, revenue, etc) to.',
        required: true,
        mutable: true
      }
    ]
  }
}

export default config
