import { type Platforms as DataSourcePlatforms } from 'shared/api/accounts'
import { ADDITIONAL_CONNECTOR_PLATFORMS_META, INTERNAL_USE_ONLY_PLATFORMS, SERVICE_DATASOURCE_MAP } from './constants'
import { type ConnectorSourceMeta } from './types'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

export const getConnectorSourceMeta = (platform: string): ConnectorSourceMeta | undefined => {
  const parsedPlatform = SERVICE_DATASOURCE_MAP[platform as keyof typeof SERVICE_DATASOURCE_MAP] ?? platform

  const platformMeta = getPlatformDetailsByPlatform(parsedPlatform as DataSourcePlatforms)

  return platformMeta != null
    ? { ...platformMeta, implemented: true }
    : ADDITIONAL_CONNECTOR_PLATFORMS_META.find((connectorPlatform) => connectorPlatform.platform === parsedPlatform)
}

export const checkInternalPlatformAccess = (platform: string, hasInternalFeatureAccess?: boolean): boolean => {
  return hasInternalFeatureAccess === true || !INTERNAL_USE_ONLY_PLATFORMS.includes(platform as DataSourcePlatforms)
}
