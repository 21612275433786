import { get, store, remove } from 'shared/cookieStore'

export const AUTH_TOKEN_KEY = 'authToken'
export const getStoredAuthToken = (): string | undefined => get(AUTH_TOKEN_KEY)
export const storeAuthToken = (token: string, expires?: string): void => {
  store(AUTH_TOKEN_KEY, token, expires)
}
export const removeStoredAuthToken = (): void => {
  remove(AUTH_TOKEN_KEY)
}
