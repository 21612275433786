import { Badge, type BadgeColor, Grid, styled, Typography, Tooltip } from '@precis-digital/kurama'
import { DEFINED_MODELS } from 'attributionModel/constants'
import { useTranslation } from 'shared/translations'
import { objectKeys } from 'shared/utils'

export interface ChartLegendProps {
  selectedRecords: Record<
    string,
    {
      badgeColor: BadgeColor
      opacity?: number
    }
  >
}
const ChartLegend = ({ selectedRecords }: ChartLegendProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel', { keyPrefix: 'dashboard' })
  return (
    <Grid container gap="13px">
      {objectKeys(selectedRecords).map((key) => {
        const badgeColor = selectedRecords[key].badgeColor
        const opacity = selectedRecords[key].opacity

        const label = (
          <Grid>
            <Badge color={badgeColor} opacity={opacity} />
            <Typography variant="body3">{key}</Typography>
          </Grid>
        )
        const model = Object.values(DEFINED_MODELS).find((model) => model.label === key)
        return (
          <StyledCell key={key}>
            {model != null ? (
              <Tooltip kind="multiline" placement="right" title={key} body={t(`tooltipText.${model.value}`)}>
                {label}
              </Tooltip>
            ) : (
              label
            )}
          </StyledCell>
        )
      })}
    </Grid>
  )
}

export default ChartLegend

const StyledCell = styled(Grid)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'start',
  '>div': {
    backgroundColor: theme.palette.neutrals.stone100,
    borderRadius: '14px',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    gap: '4px'
  }
}))
