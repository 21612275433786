import React from 'react'

export const Block = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line x1="15.2619" y1="8.60104" x2="8.07154" y2="15.7914" stroke="currentColor" strokeWidth="1.7" />
    </svg>
  )
})
