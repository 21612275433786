import { Divider, Input, Toggle, Typography } from '@precis-digital/kurama'
import { StyledPasswordInput, StyledUnderstandToggle } from 'workspace/components/Popup/styles'
import { handleConfirmationChange } from 'workspace/components/Popup/utils'

interface deleteInputProps {
  deleteInputValue: string
  hasUnderstanding: boolean
  setHasUnderstanding: React.Dispatch<React.SetStateAction<boolean>>
  setDeleteInputValue: React.Dispatch<React.SetStateAction<string>>
  confirmDeleteWord: string
  deleteInputColor: 'success' | 'error'
  translations: (key: string, options?: Record<string, unknown> | undefined) => string
}

const DeleteInput = (props: deleteInputProps): React.ReactElement => {
  const {
    deleteInputValue,
    hasUnderstanding,
    setHasUnderstanding,
    setDeleteInputValue,
    confirmDeleteWord,
    deleteInputColor,
    translations: t
  } = props

  return (
    <>
      <Typography variant="h3"> {t('deleteInputShared.confirmTitle')}</Typography>
      <StyledPasswordInput>
        <Typography variant="body2">{t('deleteInputShared.typeDelete')}</Typography>
        <Input
          name="delete input"
          value={deleteInputValue}
          {...(deleteInputValue !== '' ? { color: deleteInputColor } : null)}
          onChange={(e) => {
            handleConfirmationChange(e, confirmDeleteWord, setDeleteInputValue)
          }}
        />
      </StyledPasswordInput>
      <Divider />
      <StyledUnderstandToggle>
        <Typography variant="body2">{t('deleteInputShared.confirmUnderstanding')}</Typography>
        <Toggle
          aria-label={t('deleteInputShared.confirmLabel')}
          defaultChecked={hasUnderstanding}
          checked={hasUnderstanding}
          onChange={() => {
            setHasUnderstanding(!hasUnderstanding)
          }}
        />
      </StyledUnderstandToggle>
    </>
  )
}

export default DeleteInput
