import {
  Button,
  Divider,
  Grid,
  MaximizeIcon,
  MinimizeIcon,
  PlusIcon,
  Tooltip,
  Typography
} from '@precis-digital/kurama'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { useState, type ReactElement, useCallback, useEffect } from 'react'
import ChannelsTableCard from './ChannelsTableCard'
import { useTranslation } from 'shared/translations'
import { DragDropContext, Droppable, Draggable, type DropResult } from '@hello-pangea/dnd'
import { makeErrorToast } from 'shared/components/Toaster'
import { getNewChannelColor } from 'channelGrouping/utils'
import ChannelsTableNewChannelCard from './ChannelsTableNewChannelCard'
import { type ChannelItem } from 'shared/api/channelGroupings'
import { OTHER_CHANNEL_NAME, OTHER_CHANNEL_COLOR } from 'channelGrouping/constants'

const ChannelsTable = (): ReactElement => {
  const { dataSourceItem, selectedMetric, changeChannelPosition } = useCurrentChannelGroupingDataSource()
  const [newChannel, setNewChannel] = useState<ChannelItem | undefined>()
  const [hasCreatedFirstChannel, setHasCreatedFirstChannel] = useState(false)
  const [localDataSourceChannels, setLocalDataSourceChannels] = useState<ChannelItem[]>(dataSourceItem.channels)

  useEffect(() => {
    setLocalDataSourceChannels(dataSourceItem.channels)
  }, [dataSourceItem.channels])

  const { t } = useTranslation('channelGrouping')

  const [expandedChannelIds, setExpandedChannelIds] = useState<string[]>([])
  const handleExpandButton = (): void => {
    if (expandedChannelIds.length > 0) {
      setExpandedChannelIds([])
    } else {
      setExpandedChannelIds(dataSourceItem.channels.map((channelItem) => channelItem.id))
    }
  }

  const handleCardExpandClick = (channelIdToExpand: string, expanded: boolean): void => {
    if (!expanded) {
      setExpandedChannelIds(expandedChannelIds.filter((channelId) => channelId !== channelIdToExpand))
    } else {
      setExpandedChannelIds([...expandedChannelIds, channelIdToExpand])
    }
  }

  const onDragEnd = ({ draggableId: channelId, destination }: DropResult): void => {
    if (destination == null) return
    const previousIndex = localDataSourceChannels.findIndex((channel) => channel.id === channelId)
    if (destination.index === previousIndex) return

    setLocalDataSourceChannels((localDataSourceChannels) => {
      const channel = localDataSourceChannels.find((channel) => channel.id === channelId)
      if (channel == null) return localDataSourceChannels

      const newIndex = destination.index
      const oldIndex = localDataSourceChannels.indexOf(channel)
      const newLocalDataSourceChannels = [...localDataSourceChannels]
      newLocalDataSourceChannels.splice(oldIndex, 1)
      newLocalDataSourceChannels.splice(newIndex, 0, channel)
      return newLocalDataSourceChannels
    })

    changeChannelPosition(channelId, destination.index)
  }

  const startCreatingNewChannel = useCallback((): void => {
    if (newChannel != null) {
      makeErrorToast(t('dataSourceFormView.channelsTab.errorNoMultipleNewChannels'))
    }

    setNewChannel({
      name: '',
      color: getNewChannelColor(dataSourceItem.channels),
      id: '',
      category: 'other',
      rules: {}
    })
  }, [dataSourceItem.channels, newChannel, t])

  if (dataSourceItem.channels.length === 0 && newChannel == null && !hasCreatedFirstChannel) {
    startCreatingNewChannel()
  }

  const isAnyCardExpanded = expandedChannelIds.filter((channelId) => channelId !== 'other').length > 0

  return (
    <Grid display="flex" flexDirection="column">
      {localDataSourceChannels.length > 0 && (
        <>
          <Grid display="flex" alignItems="center" marginTop="4px">
            <Grid width="calc(60% - 60px)" display="flex" gap="14px">
              <Grid width="142px">
                <Typography variant="h5">{t('dataSourceFormView.channelsTab.tableHeader.order')}</Typography>
              </Grid>
              <Typography variant="h5">{t('dataSourceFormView.channelsTab.tableHeader.channel')}</Typography>
            </Grid>
            <Grid width="calc(40% + 60px)" display="flex" justifyContent="flex-end">
              <Grid display="flex" width="98px" justifyContent="flex-end" alignItems="center" flexShrink={0}>
                <Typography variant="h5">{t('dataSourceFormView.channelsTab.tableHeader.dataSources')}</Typography>
              </Grid>
              <Grid width="16px" flexShrink={0} />
              <Grid display="flex" width="calc(100% - 60px)" alignItems="center" justifyContent="flex-end">
                <Grid width="50%" textAlign="center" flexShrink={0}>
                  <Typography variant="h5">{t('dataSourceFormView.channelsTab.tableHeader.conditions')}</Typography>
                </Grid>
                <Grid width="50%" textAlign="center" flexShrink={0}>
                  <Typography variant="h5">{selectedMetric.label}</Typography>
                </Grid>
              </Grid>
              <Grid display="flex" marginLeft="24px" alignItems="center">
                <Button
                  variant="text"
                  onClick={handleExpandButton}
                  leftIcon={expandedChannelIds.length > 0 ? <MinimizeIcon /> : <MaximizeIcon />}
                />
              </Grid>
            </Grid>
          </Grid>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-channel-cards">
              {(provided) => (
                <Grid display="flex" flexDirection="column" {...provided.droppableProps} ref={provided.innerRef}>
                  {localDataSourceChannels.map((channel, index) => {
                    return (
                      <Draggable
                        key={`${channel.id}-${channel.name}-${channel.color}`}
                        draggableId={channel.id}
                        index={index}
                        isDragDisabled={isAnyCardExpanded}
                      >
                        {(provided) => (
                          <ChannelsTableCard
                            key={`${channel.id}-${channel.name}-${channel.color}`}
                            channel={channel}
                            isExpanded={expandedChannelIds.includes(channel.id)}
                            isAnyCardExpanded={isAnyCardExpanded}
                            handleExpandClick={(expanded) => {
                              handleCardExpandClick(channel.id, expanded)
                            }}
                            index={index}
                            draggableProvided={provided}
                          />
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
          <Grid height="16px" />
        </>
      )}
      {newChannel != null && (
        <ChannelsTableNewChannelCard
          newChannel={newChannel}
          setNewChannel={setNewChannel}
          indexForNewChannel={localDataSourceChannels.length}
          setHasCreatedFirstChannel={setHasCreatedFirstChannel}
        />
      )}
      {localDataSourceChannels.length > 0 && (
        <>
          <AddNewChannelButton disabled={newChannel != null} startCreatingNewChannel={startCreatingNewChannel} />
          <Divider />
          <ChannelsTableCard
            key={OTHER_CHANNEL_NAME}
            channel={{
              name: OTHER_CHANNEL_NAME,
              color: OTHER_CHANNEL_COLOR,
              category: 'other',
              rules: {},
              id: 'other'
            }}
            isExpanded={expandedChannelIds.includes('other')}
            isAnyCardExpanded={expandedChannelIds.length > 0}
            handleExpandClick={(expanded) => {
              handleCardExpandClick('other', expanded)
            }}
            index={localDataSourceChannels.length}
          />
        </>
      )}
    </Grid>
  )
}

interface AddNewChannelButtonProps {
  startCreatingNewChannel: () => void
  disabled: boolean
}
const AddNewChannelButton = ({ startCreatingNewChannel, disabled }: AddNewChannelButtonProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const ButtonComponent = (
    <Button
      variant="filled"
      fullWidth
      disabled={disabled}
      onClick={() => {
        startCreatingNewChannel()
      }}
    >
      <Grid display="flex" justifyContent="center" width="100%">
        <Typography variant="h5">{t('dataSourceFormView.channelsTab.addNewChannelButton.title')}</Typography>
        <PlusIcon />
      </Grid>
    </Button>
  )

  if (disabled) {
    return (
      <Tooltip
        title={t('dataSourceFormView.channelsTab.addNewChannelButton.disabledTooltipTitle')}
        body={t('dataSourceFormView.channelsTab.addNewChannelButton.disabledTooltipBody')}
        kind="multiline"
      >
        <div>{ButtonComponent}</div>
      </Tooltip>
    )
  }

  return ButtonComponent
}

export default ChannelsTable
