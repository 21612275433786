/**
 * Custom hook to get the current user's role and information.
 */
import { type clients } from 'shared/api'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryMember } from 'workspace/api'

const useCurrentMember = (): {
  role: clients.MemberRole
  isLoading: boolean
  member?: clients.MemberResp
} => {
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()
  const { data: member, isLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')

  return {
    role: member?.role ?? 'viewer',
    member,
    isLoading
  }
}

export default useCurrentMember
