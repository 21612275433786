import Grid from '../Grid'
import { styled } from '../../utils'
import { keyframes } from '../theme'

const skeletonAnimation = keyframes({
  '0%': {
    opacity: 1
  },
  '50%': {
    opacity: 0.4
  },
  '100%': {
    opacity: 1
  }
})

export const StyledGreyContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.stone100,
  animation: `${skeletonAnimation} 1.5s ease-in-out 0.5s infinite normal none running`
}))

export const StyledWhiteContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0
}))
