import fetch from './fetch'
import { type UserDetailsResp } from './clients'

const client = fetch()

export interface UserResp {
  id: number
  name: string
  email: string
  status: 'deleted'
}
export interface UpdateUserReq {
  id: string
  name: string
  email?: string
  phone: string
  companyName: string
  jobTitle: string
}
export interface CredentialsResp {
  name: string
  id: string
  scopes: string
  accessToken: string
  refreshToken: string
  externalUserId: string
  externalUserName: string
  isMissingScopes: boolean
  createdAt: string
  updatedAt: string
  updatedBy: string
  createdBy: string
}
export const paths = {
  users: '/users'
}

export const getUserDetails = async (userId: string): Promise<UserDetailsResp> => {
  return await client.get<UserDetailsResp>({
    path: `${paths.users}/${userId}`
  })
}

export const updateUserProfile = async (data: UpdateUserReq): Promise<UserDetailsResp> => {
  const { id: userId, ...userDetailsToUpdate } = data
  delete userDetailsToUpdate.email
  return await client.patch<UserDetailsResp>({
    path: `${paths.users}/${userId}`,
    data: userDetailsToUpdate
  })
}

export const del = async (userId: string): Promise<UserResp> => {
  return await client.del<UserResp>({
    path: `${paths.users}/${userId}`
  })
}

export const getUserCredentials = async (userId: string, platform: string): Promise<CredentialsResp> => {
  return await client.get<CredentialsResp>({
    path: `${paths.users}/${userId}/credentials?platform=${platform}`
  })
}
