import { Card, Grid, styled, Typography, Button } from '@precis-digital/kurama'

export const StyledCautionCard = styled(Card)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: '17px',
  marginTop: '19px',
  div: {
    ':first-of-type': {
      marginRight: theme.spacing(5),
      alignSelf: 'center'
    }
  }
}))

export const StyledUpperText = styled(Grid)({
  textAlign: 'center',
  marginBottom: '19px'
})

export const StyledAdminCard = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
  margin: '8px',
  padding: '14px 8px 14px',
  div: {
    ':first-of-type': {
      marginRight: theme.spacing(3)
    }
  }
}))

export const StyledLeaveWorkspacePopupFooter = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '24px',
  '>Button': {
    marginLeft: theme.spacing(1)
  }
}))

export const StyledPasswordInput = styled(Grid)(({ theme }) => ({
  marginTop: '27px',
  marginBottom: '25px',
  '>div': {
    width: '542px',
    marginTop: theme.spacing(1)
  },
  '>p': {
    fontFamily: 'Graphik-Medium'
  }
}))

export const StyledBoldText = styled(Typography)({
  fontWeight: 'bold'
})

export const StyledTextSpan = styled('span')({
  fontWeight: 'bold',
  fontFamily: 'Graphik-Regular'
})

export const StyledAssignedText = styled(Typography)({
  marginTop: '14px',
  margonBottom: '1px',
  fontFamily: 'Graphik-Regular'
})

export const StyledAssignedTextInvite = styled(Typography)({
  marginTop: '14px',
  margonBottom: '1px',
  fontFamily: 'Graphik-Regular',
  marginBottom: '25px'
})

export const StyledAdminWrapper = styled(Card)({
  display: 'inline-flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  padding: '0px',
  marginBottom: '8px',
  height: '88px',
  paddingRight: '28px'
})

export const StyledCautionText = styled(Typography)({
  fontFamily: 'Graphik-Regular'
})

export const StyledConfirmationBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0.6rem 0'
})

export const StyledMailInputButton = styled(Button)({
  width: '100px',
  height: '40px',
  '.MuiTypography-root': {
    textTransform: 'capitalize'
  }
})

export const StyledRoleDropdown = styled(Grid)({
  '>svg': {
    display: 'flex'
  }
})

export const StyledMailInputWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: '16px',
  width: '544px',
  display: 'inline-flex',
  '>div': {
    '.MuiTextField-root': {
      width: '437px',
      '.MuiInputBase-root': {
        width: '100%',
        borderRadius: '50px 0px 0px 50px',
        height: '40px'
      }
    }
  },
  '.MuiButton-root': {
    width: '107px',
    height: '40px',
    borderRadius: '0px 50px 50px 0px'
  },
  borderRadius: '50px'
}))

export const StyledAddMembersButton = styled(Button)({
  marginTop: '38px',
  '>div': {
    '>div': {
      padding: '0px'
    }
  }
})

export const StyledUnderstandToggle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
  marginBottom: '48px',
  marginTop: '9px',
  '>p': {
    marginRight: '26px'
  }
}))
