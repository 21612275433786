import type { ReactElement } from 'react'
import { Button, Divider, Grid, Typography, styled } from '@precis-digital/kurama'
import manWithPhone from 'public/assets/images/man_w_phone.png'
import alvieLogoFull from 'public/assets/images/alvie_logo_full.svg'
import { StyledGridRightSideContainer, StyledGridTextContainer } from './styles'
import Image from 'next/image'
import { logger } from 'shared/utils'
import { APP_ROUTES } from 'shared/routes'
import { useChangePath } from 'shared/components/Router'

const log = logger().child({
  namespace: 'error'
})

interface Error {
  message: string
  traceId?: string
}
export const InvalidClient = ({
  error,
  hasDashboardFrame = true
}: {
  error: Error
  hasDashboardFrame?: boolean
}): ReactElement => {
  log.error(error.message)
  const { changePath } = useChangePath()
  const handleViewAllWorkspacesClick = (): void => {
    void changePath(APP_ROUTES.workspace.listPage)
  }

  const handleLogoClick = (): void => {
    void changePath(APP_ROUTES.home.basePage)
  }

  return (
    <StyledGridPageContainer hasDashboardFrame={hasDashboardFrame}>
      <StyledGridTopSectionContainer hasDashboardFrame={hasDashboardFrame}>
        <StyledGridImageContainer>
          <Image src={manWithPhone} alt="Man with phone" priority />
        </StyledGridImageContainer>
        {!hasDashboardFrame && (
          <StyledGridLogoContainer onClick={handleLogoClick}>
            <Image src={alvieLogoFull} alt="Alvie Logo Icon Blue" width={128} height={64} priority />
          </StyledGridLogoContainer>
        )}
        <StyledGridRightSideContainer>
          <StyledGridTextContainer>
            <Typography variant="h2">Oops, we could not find this workspace.</Typography>
            <Typography variant="body2">
              Either the workspace you requested does not exist or you do not have access. If this is a mistake, please
              reach out to your workspace admin.
            </Typography>
          </StyledGridTextContainer>
          <StyledGridButtonsContainer>
            <Button variant="filled" onClick={handleViewAllWorkspacesClick}>
              View all workspaces
            </Button>
          </StyledGridButtonsContainer>
        </StyledGridRightSideContainer>
      </StyledGridTopSectionContainer>
      <Divider />
      <StyledGridErrorCodeMessageContainer>
        <Typography variant="h4">403</Typography>
        <Typography variant="body2">
          - That's just an error which means that you don't have access to what you were looking for.
        </Typography>
      </StyledGridErrorCodeMessageContainer>
    </StyledGridPageContainer>
  )
}

const StyledGridPageContainer = styled(Grid)(({ hasDashboardFrame }: { hasDashboardFrame: boolean }) => ({
  height: hasDashboardFrame ? '100%' : '100vh',
  padding: hasDashboardFrame ? '0px 48px' : '48px 48px 24px 48px',
  maxWidth: '1280px',
  margin: 'auto'
}))

const StyledGridTopSectionContainer = styled(Grid)(({ hasDashboardFrame }: { hasDashboardFrame: boolean }) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
  height: `calc(100% - ${hasDashboardFrame ? '80px' : '108px'})`,
  position: 'relative'
}))

const StyledGridImageContainer = styled(Grid)(() => ({
  width: '40%',
  marginLeft: '160px',
  marginRight: '-100px'
}))

const StyledGridButtonsContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px'
}))

const StyledGridLogoContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  gap: '26px',
  padding: '14px',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.neutrals.stone90
  },
  borderRadius: '16px'
}))

const StyledGridErrorCodeMessageContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '4px'
}))

export default InvalidClient
