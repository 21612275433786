import { styled } from '../../utils'
import { Accordion } from '@mui/material'
import { CSSObject } from '@mui/material/styles'

export const StyledAccordion = styled(Accordion)<{ disabled: boolean }>(
  ({ disabled }): CSSObject => ({
    boxShadow: 'none',
    borderRadius: 'none',
    width: '100%',
    '.MuiAccordionSummary-root': {
      padding: '0',
      '.MuiAccordionSummary-content': {
        margin: '0'
      },
      '&.Mui-expanded': {
        minHeight: 'auto'
      }
    },
    '.MuiAccordionDetails-root': {
      paddingTop: '18px'
    },
    ...(disabled && {
      '&.Mui-disabled': {
        backgroundColor: 'transparent'
      }
    })
  })
)
