import React, { forwardRef } from 'react'
import { StyledToggle, StyledThumb } from './styles'
import { ToggleProps } from './types'
import { CheckIcon } from '../index'

const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(
  ({ disabled = false, onChange, checked, defaultChecked, ...props }, ref): React.ReactElement => {
    return (
      <StyledToggle
        checkedIcon={
          <StyledThumb>
            <CheckIcon />
          </StyledThumb>
        }
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        defaultChecked={defaultChecked}
        {...props}
        ref={ref}
      />
    )
  }
)

export default Toggle
