import theme from '../theme'
export const badgeColors = [
  'pink',
  'steel',
  'mint',
  'dark-mint',
  'beige',
  'dark-beige',
  'indigo',
  'dark-indigo',
  'yellow',
  'red',
  'dark-red',
  'green',
  'purple',
  'dark-purple',
  'extra-dark-purple',
  'success',
  'error',
  'warning',
  'info',
  'stone'
] as const
export type BadgeColor = (typeof badgeColors)[number]
export interface BadgeProps {
  /**
   * color of the badge
   */
  color: BadgeColor

  /**
   * opacity of the badge
   */
  opacity?: number
}

export const colorMap = new Map<BadgeColor, string>([
  ['pink', theme.palette.graphs.pink05],
  ['steel', theme.palette.graphs.steel100],
  ['mint', theme.palette.graphs.mint05],
  ['dark-mint', theme.palette.graphs.mint100],
  ['beige', theme.palette.graphs.beige100],
  ['dark-beige', theme.palette.graphs.beige110],
  ['indigo', theme.palette.graphs.indigo10],
  ['dark-indigo', theme.palette.graphs.indigo100],
  ['yellow', theme.palette.graphs.yellow100],
  ['red', theme.palette.graphs.red05],
  ['dark-red', theme.palette.graphs.red50],
  ['green', theme.palette.graphs.green100],
  ['purple', theme.palette.graphs.purple100],
  ['dark-purple', theme.palette.graphs.purple120],
  ['extra-dark-purple', theme.palette.graphs.purple150],
  ['success', theme.palette.semantic.success100],
  ['error', theme.palette.semantic.error100],
  ['warning', theme.palette.semantic.warning100],
  ['info', theme.palette.semantic.info100],
  ['stone', theme.palette.neutrals.stone100]
])
