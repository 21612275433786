import { Button, EditIcon, Grid, Input, Typography, styled } from '@precis-digital/kurama'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import React, { useState, type ReactElement } from 'react'
import MemberTimestampLine from 'shared/components/MemberTimestampLine'
import { useQueryParameterState } from 'shared/components/Router'
import { useTranslation } from 'shared/translations'
import { trimWhitespace } from 'shared/utils'
const Header = (): ReactElement => {
  const { currentChannelGrouping, updateChannelGrouping } = useCurrentChannelGrouping()
  const { t } = useTranslation('channelGrouping')

  const [isEditingName, setIsEditingName] = useQueryParameterState('isEditingName', false)
  const [isTextHovered, setIsTextHovered] = useState<boolean>(false)

  const [localChannelGroupingName, setLocalChannelGroupingName] = useState<string>(currentChannelGrouping.name)

  const handleSave = (): void => {
    if (localChannelGroupingName === currentChannelGrouping.name) {
      setIsEditingName(false)
      return
    }
    updateChannelGrouping(
      { name: trimWhitespace(localChannelGroupingName) },
      {
        onSuccess: () => {
          setIsEditingName(false)
        }
      }
    )
  }

  return (
    <Grid container flexDirection="column" gap="8px">
      <Grid
        display="flex"
        alignItems="center"
        gap="8px"
        onMouseEnter={() => {
          setIsTextHovered(true)
        }}
        onMouseLeave={() => {
          setIsTextHovered(false)
        }}
      >
        {isEditingName === true ? (
          <Input
            value={localChannelGroupingName}
            name="channelGroupingName"
            onChange={(e) => {
              setLocalChannelGroupingName(e.target.value)
            }}
            fullWidth
            multiline
            backgroundColor="transparent"
            fontSizeVariant="h1"
            autoFocus
            onFocus={(event) => {
              event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length)
            }}
            onBlur={(event) => {
              handleSave()
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()
                handleSave()
              }
            }}
          />
        ) : (
          <>
            <Typography variant="h1">{currentChannelGrouping.name}</Typography>
            {isTextHovered && (
              <StyledButtonIcon
                variant="text"
                onClick={() => {
                  setIsEditingName(true)
                }}
                rightIcon={<EditIcon />}
              />
            )}
          </>
        )}
      </Grid>
      <MemberTimestampLine
        t={t}
        timestamp={currentChannelGrouping.updatedAt}
        memberId={currentChannelGrouping.updatedBy}
      />
    </Grid>
  )
}

const StyledButtonIcon = styled(Button)(() => ({
  alignSelf: 'center'
}))

export default Header
