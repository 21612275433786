import { Card, styled } from '@precis-digital/kurama'

const Block = styled(Card)<{ marginTop?: string }>(({ theme, marginTop }) => ({
  borderRadius: '32px',
  backgroundColor: theme.palette.neutrals.white0,
  display: 'flex',
  alignItems: 'start',
  width: '100%',
  flexDirection: 'column',
  marginTop
}))

export default Block
