import { styled } from '../../utils'
import { Tooltip as MUITooltip, TooltipProps as MUITooltipProps, Theme } from '@mui/material'
import Grid from '../Grid'
import { TooltipKind } from './types'

export const TooltipTitleContainer = styled(Grid)(({ theme }: { theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
  lineHeight: theme?.typography.body1.lineHeight,
  fontSize: '12px',
  fontWeight: theme?.typography.body1.fontWeight
}))

export const TooltipBodyContainer = styled(Grid)(({ theme }: { theme?: Theme }) => ({
  fontSize: theme?.typography.fontSize,
  lineHeight: theme?.typography.body1.lineHeight,
  display: 'flex',
  alignItems: 'center'
}))

export const TooltipContainer = styled(Grid)<{ kind: TooltipKind; titleBodyGap?: string }>(
  ({ kind, titleBodyGap }) => ({
    padding: kind === 'singleline' ? '8px' : '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: titleBodyGap ?? (kind === 'singleline' ? '8px' : '16px')
  })
)
export const StyledTooltip = styled((props: MUITooltipProps) => (
  <MUITooltip classes={{ popper: props.className }} {...props} />
))(
  ({
    theme,
    kind,
    width,
    maxHeight,
    maxWidth,
    allowOverflow
  }: {
    theme?: Theme
    kind: TooltipKind
    width?: string
    maxHeight?: string
    maxWidth?: string
    allowOverflow?: boolean
  }) => ({
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme?.palette.secondary.main,
      borderRadius: kind === 'singleline' ? theme?.borderRadius.xSmall : theme?.borderRadius.xLarge,
      width: width ?? (kind === 'singleline' ? 'fit-content' : '228px'),
      ...(allowOverflow === true && {
        overflowY: 'auto',
        overflowX: 'hidden'
      }),
      maxHeight,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '10px',
      border: '1px solid',
      maxWidth: maxWidth ?? 'none',
      wordBreak: 'break-word'
    }
  })
)
