import React, { useMemo } from 'react'
import {
  Card,
  Grid,
  Typography,
  styled,
  MultiSelectDropdown,
  type MultiSelectDropdownItem
} from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'
import { ReportTable } from 'shared/components/ReportTable'
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  type CellProps,
  type FooterProps,
  type Row,
  type ColumnInstance
} from 'react-table'
import {
  CHANNEL,
  CONVERSIONS,
  CONVERSION_VALUE,
  COST_PER_ACTION,
  DIMENSIONS,
  MARKET,
  ROAS,
  SPEND
} from 'home/constants'
import { makeErrorToast } from 'shared/components/Toaster'
import { DEFAULT_DATA_COLUMN_PROPERTIES } from 'shared/reactTable'
import PercentageChip from 'shared/components/PercentageChip'
import NumberSummaryTooltip from 'shared/components/NumberSummaryTooltip'
import { ExpandedNumberFormat } from 'shared/components/NumberFormat'
import { BasicCpaFooter, BasicRoasFooter } from 'shared/reactTable/components'
import { useCurrentClient } from 'shared/context/ClientContext'
import { type OriginalRow } from 'shared/reactTable/types'
import { type SummaryData } from './Summary'
import { type TransformToDetailedDataFn, type Report } from 'home/transformations'
import { type Metric } from 'home/reportTemplateParameters'

export interface ReportProps<TReport extends Report, TMetric extends Metric> {
  metrics: readonly TMetric[]
  timePeriod: [string, string]
  previousTimePeriod: [string, string]
  summaryData?: SummaryData
  metricToPreviousMetricMap: Partial<Record<TMetric, string>>
  reports: TReport[]
  reportsForPreviousTimePeriod: TReport[]
  transformToDetailedReportData: TransformToDetailedDataFn<TReport>
}

const ValueCell = ({ value }: CellProps<OriginalRow>): React.ReactElement => {
  return (
    <Grid container flexWrap="nowrap" alignItems="center" gap="8px">
      <Grid item xs={12}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  )
}

interface MetricCellProps {
  value: number
  row: Row<OriginalRow>
  metric: Metric
  metricToPreviousMetricMap: Partial<Record<Metric, string>>
  previousTimePeriod: [string, string]
  timePeriod: [string, string]
}

const MetricCell = ({
  value,
  row,
  metric,
  metricToPreviousMetricMap,
  previousTimePeriod,
  timePeriod
}: MetricCellProps): React.ReactElement => {
  const {
    currentClient: { currency: targetCurrencyCode }
  } = useCurrentClient()
  const { t } = useTranslation('home')

  const previousMetricKey: string | undefined = metricToPreviousMetricMap[metric]
  const previousValue = previousMetricKey != null ? (row.original[previousMetricKey] as number) : 0

  return (
    <StyledGridCell>
      <ExpandedNumberFormat value={value} id={metric} currencyCode={targetCurrencyCode} />
      <NumberSummaryTooltip
        presentValue={value}
        previousValue={previousValue}
        previousTimePeriod={previousTimePeriod}
        timePeriod={timePeriod}
        currencyCode={targetCurrencyCode}
        id={metric}
        title={t(metric)}
      >
        <PercentageChip presentValue={value} previousValue={previousValue} />
      </NumberSummaryTooltip>
    </StyledGridCell>
  )
}

interface TableFooterProps {
  rows: Array<Row<OriginalRow>>
  column: ColumnInstance<OriginalRow>
  metric: Metric
  summaryData?: SummaryData
}

const Footer = ({ rows, column, metric, summaryData }: TableFooterProps): React.ReactElement => {
  const {
    currentClient: { currency: targetCurrencyCode }
  } = useCurrentClient()

  switch (metric) {
    case ROAS:
      return (
        <BasicRoasFooter
          rows={rows}
          column={column}
          currencyCode={targetCurrencyCode}
          revenueField={CONVERSION_VALUE}
          spendField={SPEND}
        />
      )
    case COST_PER_ACTION:
      return (
        <BasicCpaFooter
          rows={rows}
          column={column}
          currencyCode={targetCurrencyCode}
          spendField={SPEND}
          conversionField={CONVERSIONS}
        />
      )
    default:
      return (
        <ExpandedNumberFormat
          value={summaryData?.[metric as keyof SummaryData] ?? 0}
          id={metric}
          currencyCode={targetCurrencyCode}
        />
      )
  }
}

export const DetailedReport = <TReport extends Report, TMetric extends Metric>({
  metrics,
  reports,
  reportsForPreviousTimePeriod,
  summaryData,
  previousTimePeriod,
  timePeriod,
  metricToPreviousMetricMap,
  transformToDetailedReportData
}: ReportProps<TReport, TMetric>): React.ReactElement => {
  const { t } = useTranslation('home')

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([
    CHANNEL,
    MARKET,
    SPEND,
    CONVERSION_VALUE,
    ROAS
  ])

  const selectedDimensions = useMemo(() => {
    return DIMENSIONS.filter((option) => selectedOptions.includes(option))
  }, [selectedOptions])

  const data = useMemo(() => {
    return transformToDetailedReportData({
      reports,
      reportsForPreviousTimePeriod,
      selectedDimensions
    })
  }, [reports, reportsForPreviousTimePeriod, selectedDimensions, transformToDetailedReportData])

  const metricOptions = metrics.map((metric) => ({
    label: t(metric),
    value: metric,
    subCategory: t('filter.metrics')
  }))

  const dimensionOptions = DIMENSIONS.map((dimension) => ({
    label: t(`filter.${dimension}`),
    value: dimension,
    subCategory: t('filter.dimensions')
  }))

  const columns = React.useMemo(() => {
    return [
      {
        ...DEFAULT_DATA_COLUMN_PROPERTIES,
        Header: t('channels'),
        accessor: CHANNEL,
        Cell: ValueCell
      },
      {
        ...DEFAULT_DATA_COLUMN_PROPERTIES,
        Header: t('markets'),
        accessor: MARKET,
        Cell: ValueCell
      },
      ...metrics.map((metric) => {
        return {
          ...DEFAULT_DATA_COLUMN_PROPERTIES,
          Header: t(metric),
          accessor: metric,
          Cell: ({ value, row }: CellProps<OriginalRow>) => (
            <MetricCell
              value={value}
              row={row}
              metricToPreviousMetricMap={metricToPreviousMetricMap}
              metric={metric}
              previousTimePeriod={previousTimePeriod}
              timePeriod={timePeriod}
            />
          ),
          Footer: ({ rows, column }: FooterProps<OriginalRow>) => (
            <Footer metric={metric} summaryData={summaryData} column={column} rows={rows} />
          )
        }
      })
    ].filter((column) => {
      return selectedOptions.includes(column.accessor)
    })
  }, [t, metrics, metricToPreviousMetricMap, previousTimePeriod, timePeriod, summaryData, selectedOptions])

  const handleFilterChange = (_: string | null, value: MultiSelectDropdownItem[]): void => {
    const selectedMetricsCount = metrics.filter((column) => value.map((metric) => metric.value).includes(column)).length
    const selectedDimensionsCount = DIMENSIONS.filter((column) =>
      value.map((dimension) => dimension.value).includes(column)
    ).length

    if (selectedDimensionsCount === 0) {
      makeErrorToast(t('filter.minimumOneDimensionError'))
      return
    }

    if (selectedMetricsCount === 0) {
      makeErrorToast(t('filter.minimumOneMetricError'))
      return
    }

    setSelectedOptions(value.map((option) => option.value))
  }

  const renderFilters = (): React.ReactElement => {
    return (
      <Grid display="flex" gap="8px" alignItems="center">
        <Typography variant="body2">{t('showMe')}</Typography>
        <MultiSelectDropdown
          title={t('selectColumns')}
          buttonTitle={t('allColumns')}
          options={[...dimensionOptions, ...metricOptions]}
          value={selectedOptions}
          allowSelectAll={false}
          onChange={handleFilterChange}
        />
      </Grid>
    )
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page: pageRows,
    prepareRow,
    footerGroups,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        sortBy: [
          {
            id: metrics[0] ?? CHANNEL,
            desc: true
          }
        ]
      }
    },
    useFilters,
    useSortBy,
    usePagination
  )
  return (
    <StyledBlock>
      <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{t('detailedReporting')}</Typography>
        {data.length > 0 && renderFilters()}
      </Grid>
      {data.length > 0 ? (
        <ReportTable
          t={t}
          basicColumnsIds={[CHANNEL, 'market']}
          tableProps={{
            styles: {
              container: { maxHeight: 'unset', margin: 0 },
              table: { margin: 0 }
            },
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows: pageRows,
            prepareRow,
            footerGroups,
            pagination: {
              count: rows.length,
              page: pageIndex,
              handleChangePage: (_, newPage) => {
                gotoPage(newPage)
              },
              rowsPerPage: pageSize,
              rowsPerPageOptions: [10, 20, 50, 100],
              handleChangeRowsPerPage: (event) => {
                setPageSize(parseInt(event.target.value, 10))
                gotoPage(0)
              }
            }
          }}
        />
      ) : (
        <Typography style={{ marginTop: '4px' }} variant="body2">
          {t('noDetailedReport')}
        </Typography>
      )}
    </StyledBlock>
  )
}

const StyledBlock = styled(Card)(({ theme }) => ({
  borderRadius: '24px',
  width: '100%',
  backgroundColor: theme.palette.neutrals.white0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  button: {
    paddingLeft: '16px'
  },
  marginTop: 0
}))

const StyledGridCell = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '8px'
}))

export default DetailedReport
