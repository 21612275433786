import { ArrowLeftIcon, Button, Divider, Grid, ShopIcon, Typography, styled } from '@precis-digital/kurama'
import alienWithFlag from 'public/assets/images/alien_w_flag.png'
import alvieLogoFull from 'public/assets/images/alvie_logo_full.svg'
import { type ReactElement } from 'react'

import Image from 'next/image'
import { useChangePath, useGoBack } from '../Router'
import { APP_ROUTES } from 'shared/routes'

export const NotFoundPage = ({
  returnPath,
  hasDashboardFrame = false
}: {
  returnPath?: string
  hasDashboardFrame?: boolean
}): ReactElement => {
  const { changePath } = useChangePath()
  const goBack = useGoBack()
  const handleTakeMeBackClick = (): void => {
    if (returnPath != null) {
      void changePath(returnPath)
    } else {
      goBack()
    }
  }

  const handleTakeMeHomeClick = (): void => {
    void changePath(APP_ROUTES.home.basePage)
  }

  return (
    <StyledGridPageContainer hasDashboardFrame={hasDashboardFrame}>
      <StyledGridTopSectionContainer hasDashboardFrame={hasDashboardFrame}>
        <StyledGridImageContainer>
          <Image
            src={alienWithFlag}
            alt="Alien with Flag"
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </StyledGridImageContainer>
        {!hasDashboardFrame && (
          <StyledGridLogoContainer onClick={handleTakeMeHomeClick}>
            <Image
              src={alvieLogoFull}
              alt="Alvie Logo Icon Blue"
              width={128}
              height={64}
              style={{
                maxWidth: '100%',
                height: 'auto'
              }}
            />{' '}
          </StyledGridLogoContainer>
        )}
        <StyledGridRightSideContainer>
          <StyledGridTextContainer>
            <Typography variant="h2">
              Oh, man. This guy is totally lost...
              <br />
              And so are you.
            </Typography>
            <Typography variant="body2">
              Either something went wrong or the page doesn't exist anymore. We're as shocked as you are - but don't
              worry, there's an easy fix:
            </Typography>
          </StyledGridTextContainer>
          <Grid alignSelf="center" gap="8px" display="flex">
            <Button leftIcon={<ArrowLeftIcon />} variant="filled" onClick={handleTakeMeBackClick}>
              Take me back
            </Button>
            <Button leftIcon={<ShopIcon />} variant="tonal" onClick={handleTakeMeHomeClick}>
              Take me home
            </Button>
          </Grid>
        </StyledGridRightSideContainer>
      </StyledGridTopSectionContainer>
      <Divider />
      <StyledGridErrorCodeMessageContainer>
        <Typography variant="h4">404</Typography>
        <Typography variant="body2">
          - That's just an error which means that what you were looking for does not exist.
        </Typography>
      </StyledGridErrorCodeMessageContainer>
    </StyledGridPageContainer>
  )
}

const StyledGridPageContainer = styled(Grid)(({ hasDashboardFrame }: { hasDashboardFrame: boolean }) => ({
  height: hasDashboardFrame ? '100%' : '100vh',
  padding: hasDashboardFrame ? '0px 48px' : '48px 48px 24px 48px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxWidth: '1280px',
  margin: 'auto'
}))

const StyledGridLogoContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  gap: '26px',
  padding: '14px',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.neutrals.stone90
  },
  borderRadius: '16px'
}))

const StyledGridTopSectionContainer = styled(Grid)(({ hasDashboardFrame }: { hasDashboardFrame: boolean }) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
  height: `calc(100% - ${hasDashboardFrame ? '80px' : '108px'})`,
  position: 'relative'
}))

const StyledGridImageContainer = styled(Grid)(() => ({
  width: '40%'
}))

const StyledGridRightSideContainer = styled(Grid)(() => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  button: {
    alignSelf: 'center'
  }
}))

const StyledGridTextContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '0px 48px',
  gap: '8px'
}))

const StyledGridErrorCodeMessageContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '4px'
}))

export default NotFoundPage
