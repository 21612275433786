import {
  FlowChart,
  Grid,
  PopUp,
  Skeleton,
  Typography,
  styled,
  Button,
  Divider,
  Tooltip,
  EditIcon,
  SendIcon,
  PlayIcon
} from '@precis-digital/kurama'
import {
  useMutationUpdateAttribution,
  useQueryDagStatusByModelId,
  useQueryIntegratedAttributions
} from 'attributionModel/api'
import { getDAGNodeDetails } from 'shared/components/DagViewStatusPopupUtils'
import {
  useMutationRunConnectorConfigNow,
  useMutationUpdateConnector,
  useQueryConnectorConfigDagStatusById,
  useQueryConnectorConfigs
} from 'connectors/api'
import { useQueryClientAccounts } from 'dataSource/api'
import { useMemo, type ReactElement, useEffect, useState } from 'react'
import {
  useMutationRunReportConfigNow,
  useMutationUpdateReportingSolutions,
  useQueryReportingSolutionDagStatusById,
  useQueryReportingSolutionsConfigs
} from 'reportingSolution/api'
import { UnStyledRetainDefaultQueryLink, useChangePath, useQueryString } from 'shared/components/Router'
import StatusWithBadge from 'shared/components/StatusWithBadge'
import {
  type ConfigStatus,
  defaultErrorCode,
  type ErrorCodeType,
  type ErrorDetails,
  getErrorCodeDetails,
  STATUSES,
  transformDAGStatusToNodesAndEdges
} from 'shared/configStatuses'
import { useCurrentClient } from 'shared/context/ClientContext'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { formatDateTimeToAtFormat } from 'shared/dateFns'
import {
  POPUP_QUERY_PARAMETER_KEY,
  type DAGViewTypes,
  type ShowDagViewProps,
  CONFIG_ID_QUERY_PARAMETER_KEY
} from 'shared/hooks/useDagView'
import { useQueryBudgetOptimiserDagStatusByModelId } from 'budgetOptimiser/api'
import { type ReportType } from 'shared/api/analytics'
import { APP_ROUTES } from 'shared/routes'
import { makeToastWithLoading } from './Toaster'
import pausedIcon from 'public/assets/images/pause.png'
import Image from 'next/image'
import { transformConfigToRequest } from 'reportingSolution/components/EditOrCreatePage/utils'
import { transformAttributionRespToReq } from 'attributionModel'
import { omit } from 'ramda'

type DagViewStatusProps = ShowDagViewProps & {
  type: DAGViewTypes
  isPageWithConfigId?: boolean
}

const typeQueryMap = {
  attributionModel: useQueryDagStatusByModelId,
  reportingSolution: useQueryReportingSolutionDagStatusById,
  budgetOptimiser: useQueryBudgetOptimiserDagStatusByModelId,
  connector: useQueryConnectorConfigDagStatusById
}

const getEditConfigLink = (type: DAGViewTypes, configId: number): string => {
  switch (type) {
    case 'attributionModel':
      return `${APP_ROUTES.attributionModels.editConfigPage({ configId: configId.toString() })}`
    case 'reportingSolution':
      return `${APP_ROUTES.reportingSolutions.editConfigPage({ configId: configId.toString() })}`
    case 'budgetOptimiser':
      return `${APP_ROUTES.budgetOptimiser.editConfigPage({ configId: configId.toString() })}`
    case 'connector':
      return `${APP_ROUTES.connectors.editConfigPage({ configId: configId.toString() })}`
  }
}

export const DETAILED_STATUS_POPUP_TEXT = 'detailedStatus'

const StatusBody = ({
  status,
  isLoading,
  errorCode,
  errorDetails
}: {
  status: ConfigStatus
  isLoading: boolean
  errorCode?: ErrorCodeType
  errorDetails?: ErrorDetails
}): ReactElement => {
  const { t } = useTranslation('common')
  const openIntercomArticle = (articleId: number | undefined) => {
    return (): void => {
      if (articleId != null) {
        window.Intercom('showArticle', articleId)
        return
      }
      window.Intercom('showArticle', 206976)
    }
  }

  if (isLoading) {
    return <Skeleton height="150px" />
  }
  switch (status) {
    case STATUSES.active:
    case STATUSES.paused:
    case STATUSES.running:
    case STATUSES.unknown:
      return (
        <>
          <StyledDagViewHeader variant="h3">{t(`dagView.statusExplainers.${status}.header`)}</StyledDagViewHeader>
          <Typography variant="h5">{t(`dagView.statusExplainers.${status}.subHeader`)}</Typography>
          <Typography variant="body2">{t(`dagView.statusExplainers.${status}.body`)}</Typography>
        </>
      )
    case STATUSES.error:
      return (
        <>
          <StyledDagViewHeader variant="h3">{t('dagView.statusExplainers.error.header')}</StyledDagViewHeader>
          <Typography variant="h5">{t('dagView.statusExplainers.error.subHeader')}</Typography>
          <StyledErrorTextContainer variant="body2">
            {t(`errors.${errorCode ?? defaultErrorCode}`, errorDetails?.translationPlaceholders ?? undefined)}
          </StyledErrorTextContainer>
          <StyledLearnMoreText variant="body2" onClick={openIntercomArticle(errorDetails?.intercomArticleId)}>
            {t('dagView.statusExplainers.error.learnMore')}
          </StyledLearnMoreText>
        </>
      )
    default:
      return (
        <>
          <StyledDagViewHeader variant="h3">{t(`dagView.statusExplainers.unknown.header`)}</StyledDagViewHeader>
          <Typography variant="h5">{t(`dagView.statusExplainers.unknown.subHeader`)}</Typography>
          <Typography variant="body2">{t(`dagView.statusExplainers.unknown.body`)}</Typography>
        </>
      )
  }
}

const ActionButton = ({
  configStatus,
  isLoading,
  hasRunNowButton,
  type,
  configId,
  currentClientId,
  t,
  runNow,
  activateServiceAndRunNow
}: {
  configStatus: ConfigStatus
  isLoading: boolean
  hasRunNowButton: boolean
  type: DAGViewTypes
  configId: number
  currentClientId: string
  t: (key: string, options?: Record<string, unknown>) => string
  runNow: (type: DAGViewTypes, configId: number, clientId: string) => Promise<void>
  activateServiceAndRunNow: (type: DAGViewTypes, configId: number, clientId: string) => Promise<void>
}): ReactElement => {
  if (isLoading) {
    return <Skeleton height="40px" />
  }

  if (configStatus === STATUSES.paused) {
    if (type === 'budgetOptimiser') {
      return (
        <Tooltip
          title={
            <Typography variant="body3">
              {t('dagView.activateServiceNotSupported', { pipelineLabel: t(`dagView.${type}`) })}
            </Typography>
          }
          kind="singleline"
        >
          <span>
            <Button fullWidth disabled scheme="light" variant="filled" leftIcon={<PlayIcon />} href="#">
              {t('dagView.activateService', { pipelineLabel: t(`dagView.${type}`) })}
            </Button>
          </span>
        </Tooltip>
      )
    }
    return (
      <Button
        fullWidth
        variant="filled"
        leftIcon={<PlayIcon />}
        onClick={() => {
          void activateServiceAndRunNow(type, configId, currentClientId)
        }}
      >
        {t('dagView.activateService', { pipelineLabel: t(`dagView.${type}`) })}
      </Button>
    )
  }

  if (!hasRunNowButton) {
    return (
      <Tooltip
        title={
          <Typography variant="body3">
            {t('dagView.startARunNotSupported', { pipelineLabel: t(`dagView.${type}`) })}
          </Typography>
        }
        kind="singleline"
      >
        <span>
          <Button fullWidth disabled scheme="light" variant="filled" leftIcon={<SendIcon />} href="#">
            {t('dagView.runServiceNow', { pipelineLabel: t(`dagView.${type}`) })}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <Button
      fullWidth
      variant="filled"
      scheme="light"
      leftIcon={<SendIcon />}
      disabled={configStatus === STATUSES.running}
      onClick={() => {
        void runNow(type, configId, currentClientId)
      }}
    >
      {t('dagView.runServiceNow', { pipelineLabel: t(`dagView.${type}`) })}
    </Button>
  )
}

const DagViewFlowChartContent = ({
  isLoading,
  nodesAndEdges,
  type,
  isActive,
  t
}: {
  isLoading: boolean
  nodesAndEdges: any
  type: DAGViewTypes
  isActive: boolean
  t: (key: string, options?: Record<string, unknown>) => string
}): ReactElement => {
  if (isLoading) {
    return <Skeleton height="100%" />
  }

  if (nodesAndEdges != null && isActive) {
    return <FlowChart nodes={nodesAndEdges.nodes} edges={nodesAndEdges.edges} />
  }

  if (isActive) {
    return (
      <Typography variant="body2">
        {t(
          type === 'budgetOptimiser'
            ? 'dagView.noStatusDataAvailableForBudgetOptimiser'
            : 'dagView.noStatusDataAvailable'
        )}
      </Typography>
    )
  }

  return (
    <ResponsiveContainer>
      <StyledImageWrapper>
        <Image src={pausedIcon.src} alt="Paused icon" layout="fill" objectFit="contain" />
      </StyledImageWrapper>
      <StyledPausedText variant="body2">{t('dagView.pausedConfigMessage')}</StyledPausedText>
    </ResponsiveContainer>
  )
}
const DagViewStatusPopup = ({
  configId,
  configName,
  isActive,
  configStatus,
  isPageWithConfigId,
  type,
  useNativeDag
}: DagViewStatusProps): ReactElement => {
  const { t } = useTranslation('common')
  const { currentClient } = useCurrentClient()
  const hasRunNowButton = type === 'reportingSolution' || type === 'connector'
  const { query } = useQueryString()
  const [isDagViewQueryAdded, setIsDagViewQueryAdded] = useState(false)

  const { changePath, populatedPathName } = useChangePath()

  const { mutate: runReportConfigNow } = useMutationRunReportConfigNow()
  const { mutate: runConnectorConfigNow } = useMutationRunConnectorConfigNow()
  const { mutate: updateConnectorConfig } = useMutationUpdateConnector()
  const { mutate: updateReportingSolutionConfig } = useMutationUpdateReportingSolutions()
  const { mutate: updateIntegrationAtributionModel } = useMutationUpdateAttribution()

  const runNow = async (type: DAGViewTypes, configId: number): Promise<void> => {
    const { toastOnError, toastOnSuccess } = makeToastWithLoading()
    switch (type) {
      case 'reportingSolution':
        runReportConfigNow(
          { clientId: currentClient.id, configId },
          {
            onSuccess: () => {
              toastOnSuccess(t('dagView.runNowSuccess'))
            },
            onError: () => {
              toastOnError(t('dagView.runNowError'))
            }
          }
        )
        break
      case 'connector':
        runConnectorConfigNow(
          { clientId: currentClient.id, configId },
          {
            onSuccess: () => {
              toastOnSuccess(t('dagView.runNowSuccess'))
            },
            onError: () => {
              toastOnError(t('dagView.runNowError'))
            }
          }
        )
        break
    }
  }

  const activateServiceAndRunNow = async (type: DAGViewTypes, configId: number): Promise<void> => {
    const { toastOnError, toastOnSuccess } = makeToastWithLoading()
    if (type === 'connector') {
      updateConnectorConfig(
        {
          configId,
          clientId: currentClient.id,
          runSchedule: true
        },
        {
          onSuccess: () => {
            runConnectorConfigNow({ clientId: currentClient.id, configId })
            toastOnSuccess(t('dagView.activateServiceSuccess', { pipelineLabel: t(`dagView.${type}`) }))
          },
          onError: () => {
            toastOnError(t('dagView.errorActivatingService', { pipelineLabel: t(`dagView.${type}`) }))
          }
        }
      )
    }
    if (type === 'reportingSolution') {
      const selectedConfig = reportingSolutionConfigs?.find((config) => config.configId === configId)

      if (selectedConfig == null) {
        return
      }
      const accountProjectId = clientAccounts?.find(
        (account) => account.externalAccountId === selectedConfig.targetProjectId
      )?.id
      updateReportingSolutionConfig(
        {
          configId,
          data: transformConfigToRequest({
            config: {
              ...selectedConfig,
              runSchedule: true,
              targetProjectId: accountProjectId?.toString() ?? ''
            },
            clientId: currentClient.id
          })
        },
        {
          onSuccess: () => {
            runReportConfigNow({ clientId: currentClient.id, configId })
            toastOnSuccess(t('dagView.activateServiceSuccess', { pipelineLabel: t(`dagView.${type}`) }))
          },
          onError: () => {
            toastOnError(t('dagView.errorActivatingService', { pipelineLabel: t(`dagView.${type}`) }))
          }
        }
      )
    }
    if (type === 'attributionModel') {
      const selectedConfig = attributionConfigs?.find((config) => configId === config.id)
      if (selectedConfig == null) {
        return
      }
      updateIntegrationAtributionModel(
        {
          data: {
            ...transformAttributionRespToReq(selectedConfig),
            runSchedule: true
          },
          configId
        },
        {
          onSuccess: () => {
            toastOnSuccess(t('dagView.activateServiceSuccess', { pipelineLabel: t(`dagView.${type}`) }))
          },
          onError: () => {
            toastOnError(t('dagView.errorActivatingService', { pipelineLabel: t(`dagView.${type}`) }))
          }
        }
      )
    }
  }

  useEffect(() => {
    void changePath(
      populatedPathName,
      {
        ...query,
        [POPUP_QUERY_PARAMETER_KEY]: DETAILED_STATUS_POPUP_TEXT,
        ...(isPageWithConfigId !== true && { [CONFIG_ID_QUERY_PARAMETER_KEY]: configId.toString() })
      },
      true
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding changePath to dependency array puts the component in an infinite loop
  }, [configId, isPageWithConfigId])

  useEffect(() => {
    if (query?.popup === DETAILED_STATUS_POPUP_TEXT) {
      setIsDagViewQueryAdded(true)
    }
  }, [query?.popup])

  const { data: clientAccounts, isLoading: isLoadingClientAccounts } = useQueryClientAccounts(
    currentClient.id,
    isActive
  )
  const { data: connectorConfigs, isLoading: isLoadingConnectorConfigs } = useQueryConnectorConfigs(
    currentClient.id,
    isActive
  )
  const { data: reportingSolutionConfigs, isLoading: isLoadingReportingSolutionsConfigs } =
    useQueryReportingSolutionsConfigs(currentClient.id, isActive)

  const { data: attributionConfigs, isLoading: isLoadingAttributionConfigs } = useQueryIntegratedAttributions(
    currentClient.id
  )

  let reportType: ReportType | undefined

  if (type === 'budgetOptimiser') {
    reportType = useNativeDag === true ? 'budget_optimiser_native_dag' : 'budget_optimiser_non_native_dag'
  }

  const { data: dagStatusData, isLoading: isLoadingDagViewData } = typeQueryMap[type](
    currentClient.id,
    configId,
    isActive,
    reportType
  )

  const nodesAndEdges = useMemo(() => {
    if (
      dagStatusData != null &&
      dagStatusData?.length !== 0 &&
      clientAccounts != null &&
      connectorConfigs != null &&
      reportingSolutionConfigs != null &&
      attributionConfigs != null
    ) {
      const output = transformDAGStatusToNodesAndEdges(dagStatusData[0])
      const nodes = getDAGNodeDetails({
        nodes: output.nodes,
        accounts: clientAccounts,
        connectorConfigs,
        reportingSolutionConfigs,
        attributionConfigs,
        ...((type === 'attributionModel' || type === 'budgetOptimiser') && { attributionModelConfigName: configName }),
        t,
        clientId: currentClient.id
      })

      return {
        nodes,
        edges: output.edges
      }
    }
  }, [
    clientAccounts,
    configName,
    connectorConfigs,
    dagStatusData,
    reportingSolutionConfigs,
    t,
    type,
    currentClient.id,
    attributionConfigs
  ])

  const dagViewStatusPopup = useOverScreen('dagViewStatusPopup')

  const getGcpProjectId = (type: DAGViewTypes, configId: string | undefined): string => {
    switch (type) {
      case 'attributionModel': {
        const attributionConfig = attributionConfigs?.find((config) => config.id.toString() === configId)
        return attributionConfig?.targetProjectId ?? ''
      }
      case 'reportingSolution': {
        const reportingSolutionConfig = reportingSolutionConfigs?.find(
          (config) => config.configId.toString() === configId
        )
        return reportingSolutionConfig?.targetProjectId ?? ''
      }
      case 'budgetOptimiser': {
        const budgetOptimiserConfig = attributionConfigs?.find((config) => config.id.toString() === configId)
        return budgetOptimiserConfig?.targetProjectId ?? ''
      }
      case 'connector': {
        const connectorConfig = connectorConfigs?.find((config) => config.configId.toString() === configId)
        return connectorConfig?.targetProjectId ?? ''
      }
      default:
        return ''
    }
  }

  const errorCode = (dagStatusData?.[0]?.errorCode as ErrorCodeType) ?? undefined
  const errorMessage = dagStatusData?.[0]?.errorMessage

  const gcpProjectId = getGcpProjectId(type, dagStatusData?.[0]?.configId as string)
  const errorDetails =
    getErrorCodeDetails(
      errorCode,
      errorMessage,
      gcpProjectId !== '' ? gcpProjectId : undefined,
      t(`dagView.${type}`)
    ) ?? undefined
  const lastUpdatedTime = dagStatusData?.[0]?.lastUpdated

  useEffect(() => {
    if (query?.popup !== DETAILED_STATUS_POPUP_TEXT && isDagViewQueryAdded) {
      dagViewStatusPopup.remove()
    }
  }, [dagViewStatusPopup, query?.popup, isDagViewQueryAdded, populatedPathName])

  return (
    <StyledPopUp
      open={dagViewStatusPopup.visible}
      handleOpen={(): void => {
        dagViewStatusPopup.remove()
        void changePath(
          populatedPathName,
          omit([CONFIG_ID_QUERY_PARAMETER_KEY, POPUP_QUERY_PARAMETER_KEY])(query),
          true
        )
      }}
      title={t('dagView.detailedStatus')}
    >
      <Grid container gap="24px" height="100%">
        <StyledGridWithBorder
          padding="10px"
          xs
          height="60vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DagViewFlowChartContent
            isLoading={
              isLoadingDagViewData ||
              isLoadingClientAccounts ||
              isLoadingConnectorConfigs ||
              isLoadingReportingSolutionsConfigs ||
              isLoadingAttributionConfigs
            }
            nodesAndEdges={nodesAndEdges}
            type={type}
            isActive={isActive}
            t={t}
          />
        </StyledGridWithBorder>
        <StyledGridWithBorder
          xs={3}
          padding="20px"
          height="60vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Grid display="flex" flexDirection="column" height="100%">
            {isLoadingDagViewData ? (
              <Skeleton height="60px" />
            ) : (
              <Tooltip title={configName} kind="singleline">
                <StyledDagViewHeader variant="h3">{configName}</StyledDagViewHeader>
              </Tooltip>
            )}
            <InfoGrid>
              <Grid container direction="column">
                {isLoadingDagViewData && configStatus !== 'paused' ? (
                  <Skeleton height="40px" />
                ) : (
                  configStatus !== 'paused' && (
                    <>
                      <Grid>
                        <Typography variant="h5">{t('dagView.lastUpdate')}</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body3">
                          {lastUpdatedTime != null ? formatDateTimeToAtFormat(lastUpdatedTime) : t('unknown')}
                        </Typography>
                      </Grid>
                    </>
                  )
                )}
              </Grid>
              {isLoadingDagViewData ? (
                <Skeleton height="40px" />
              ) : (
                <StatusWithBadge
                  typographyVariant="h5"
                  status={configStatus}
                  errorCode={dagStatusData?.[0]?.errorCode}
                  lastUpdated={dagStatusData?.[0]?.lastUpdated}
                  errorDetails={errorDetails}
                  hasTooltip={false}
                />
              )}
            </InfoGrid>
            <Divider />
            <StatusBody
              status={configStatus}
              isLoading={isLoadingDagViewData}
              errorCode={errorCode}
              errorDetails={errorDetails}
            />
            <ButtonGrid>
              {isLoadingDagViewData ? (
                <Skeleton height="40px" />
              ) : (
                <Button
                  fullWidth
                  variant="outlined"
                  leftIcon={<EditIcon />}
                  href={getEditConfigLink(type, configId)}
                  LinkComponent={UnStyledRetainDefaultQueryLink}
                >
                  {t('dagView.editService', { pipelineLabel: t(`dagView.${type}`) })}
                </Button>
              )}
              {isLoadingDagViewData ? (
                <Skeleton height="40px" />
              ) : (
                <ActionButton
                  configStatus={configStatus}
                  isLoading={isLoadingDagViewData}
                  hasRunNowButton={hasRunNowButton}
                  type={type}
                  configId={configId}
                  currentClientId={currentClient.id}
                  t={t}
                  runNow={runNow}
                  activateServiceAndRunNow={activateServiceAndRunNow}
                />
              )}
            </ButtonGrid>
          </Grid>
        </StyledGridWithBorder>
      </Grid>
    </StyledPopUp>
  )
}

export default OverScreen.create(DagViewStatusPopup)

const StyledGridWithBorder = styled(Grid)(({ theme }) => ({
  borderRadius: '32px',
  border: `1px solid ${theme.palette.neutrals.stone100 as string}`
}))

const StyledPopUp = styled(PopUp)(() => ({
  '> div:nth-of-type(3)': {
    width: '90vw'
  }
}))

const InfoGrid = styled(Grid)(() => ({
  padding: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px'
}))

const StyledDagViewHeader = styled(Typography)(() => ({
  marginBottom: '16px',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  maxWidth: '100%',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  boxSizing: 'border-box'
}))
const ResponsiveContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100%',
  width: '100%'
}))

const StyledErrorTextContainer = styled(Typography)(() => ({
  maxHeight: '30%',
  overflowY: 'auto',
  overflowX: 'hidden',
  wordWrap: 'break-word'
}))

const StyledImageWrapper = styled('div')({
  width: '100%',
  maxWidth: '750px',
  position: 'relative',
  aspectRatio: '3 / 2'
})

const StyledPausedText = styled(Typography)(() => ({
  marginTop: '-64px'
}))

const StyledLearnMoreText = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.semantic.info100
}))

const ButtonGrid = styled(Grid)(() => ({
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}))
