import React from 'react'

export const SortAscending = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M7.99973 7.83315L7.99973 16.116"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.802 10.2742L7.99995 7.83276L10.1979 10.2742"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
