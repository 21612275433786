import { Grid, Tooltip, Typography } from '@precis-digital/kurama'
import { formatNumberDifference, type FormatIds } from 'shared/numberFormat'
import PercentageTypography from 'shared/components/PercentageTypography'
import { formatDateToString } from 'shared/dateFns'
import { ExpandedNumberFormat } from 'shared/components/NumberFormat'
import { type CurrencyCodes } from 'shared/constants/currency'

interface BaseSummaryTooltipProps {
  children: React.ReactNode
  presentValue: number
  previousValue: number
  previousTimePeriod?: [string, string]
  timePeriod?: [string, string]
  currencyCode: CurrencyCodes
  id: FormatIds
  title: string
  reverseColorScheme?: boolean
  previousValueHeaderText?: string
  presentValueHeaderText?: string
}

interface TimePeriodSummaryTooltipProps extends BaseSummaryTooltipProps {
  previousTimePeriod: [string, string]
  timePeriod: [string, string]
}

interface HeaderTextSummaryTooltipProps extends BaseSummaryTooltipProps {
  previousValueHeaderText: string
  presentValueHeaderText: string
}

const NumberSummaryTooltip = ({
  children,
  presentValue,
  previousValue,
  previousTimePeriod,
  timePeriod,
  currencyCode,
  id,
  title,
  reverseColorScheme,
  previousValueHeaderText,
  presentValueHeaderText
}: TimePeriodSummaryTooltipProps | HeaderTextSummaryTooltipProps): React.ReactElement => {
  return (
    <Tooltip
      kind="multiline"
      width="fit-content"
      title={
        <Grid display="flex" alignItems="center" gap="4px">
          <Typography variant="body3">{title}</Typography>
          <PercentageTypography
            presentValue={presentValue}
            previousValue={previousValue}
            reverseColorScheme={reverseColorScheme}
          />
          (
          <Typography variant="body3">
            {formatNumberDifference({
              presentValue,
              previousValue,
              id,
              currencyCode
            })}
          </Typography>
          )
        </Grid>
      }
      body={
        <Grid container flexDirection="column">
          <Grid display="flex" justifyContent="space-between" alignItems="center" gap="8px">
            <Typography variant="body2">
              {presentValueHeaderText ??
                `${
                  timePeriod != null
                    ? `${formatDateToString({ date: timePeriod[0] })} - ${formatDateToString({ date: timePeriod[1] })}`
                    : ''
                }`}
            </Typography>
            <ExpandedNumberFormat value={presentValue} id={id} currencyCode={currencyCode} />
          </Grid>
          <Grid display="flex" justifyContent="space-between" alignItems="center" gap="8px">
            <Typography variant="body2">
              {previousValueHeaderText ??
                `${
                  previousTimePeriod != null
                    ? `${formatDateToString({ date: previousTimePeriod[0] })} - ${formatDateToString({
                        date: previousTimePeriod[1]
                      })}`
                    : ''
                }`}
            </Typography>
            <ExpandedNumberFormat value={previousValue} id={id} currencyCode={currencyCode} />
          </Grid>
        </Grid>
      }
    >
      <Grid>{children}</Grid>
    </Tooltip>
  )
}

export default NumberSummaryTooltip
