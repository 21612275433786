import React from 'react'

export const Edit = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M13.5448 20.089H20.0365"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8472 4.72549C13.605 3.76003 14.8295 3.81041 15.796 4.56818L17.2252 5.6889C18.1917 6.44666 18.5341 7.62188 17.7763 8.58939L9.25371 19.4624C8.9689 19.8264 8.53398 20.0413 8.0713 20.0464L4.78421 20.0886L4.03981 16.8858C3.93493 16.4365 4.03981 15.9635 4.32461 15.5985L12.8472 4.72549Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2516 6.76126L16.1807 10.6252"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
