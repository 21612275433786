import React from 'react'

export const MoveVertical = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M13.0195 14.8747C13.0195 14.3916 12.6278 14 12.1447 14C11.6616 14 11.27 14.3916 11.27 14.8747C11.27 15.3578 11.6616 15.7495 12.1447 15.7495C12.6278 15.7495 13.0195 15.3578 13.0195 14.8747Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89592 14.8747C6.89592 14.3916 6.5043 14 6.0212 14C5.53811 14 5.14648 14.3916 5.14648 14.8747C5.14648 15.3578 5.53811 15.7495 6.0212 15.7495C6.5043 15.7495 6.89592 15.3578 6.89592 14.8747Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1421 14.8747C19.1421 14.3916 18.7505 14 18.2674 14C17.7843 14 17.3927 14.3916 17.3927 14.8747C17.3927 15.3578 17.7843 15.7495 18.2674 15.7495C18.7505 15.7495 19.1421 15.3578 19.1421 14.8747Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0195 9.12528C13.0195 8.64219 12.6278 8.25056 12.1447 8.25056C11.6616 8.25056 11.27 8.64219 11.27 9.12528C11.27 9.60837 11.6616 10 12.1447 10C12.6278 10 13.0195 9.60837 13.0195 9.12528Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89592 9.12522C6.89592 8.64212 6.5043 8.2505 6.0212 8.2505C5.53811 8.2505 5.14648 8.64212 5.14648 9.12522C5.14648 9.60831 5.53811 9.99994 6.0212 9.99994C6.5043 9.99994 6.89592 9.60831 6.89592 9.12522Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1421 9.12528C19.1421 8.64219 18.7505 8.25056 18.2674 8.25056C17.7843 8.25056 17.3927 8.64219 17.3927 9.12528C17.3927 9.60837 17.7843 10 18.2674 10C18.7505 10 19.1421 9.60837 19.1421 9.12528Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
