import { Table, TablePagination, styled, TableRow } from '@precis-digital/kurama'
import React, { type ChangeEvent } from 'react'
import { type ReactElement } from 'react-markdown/lib/react-markdown'

interface ListViewPaginationProps {
  pageIndex: number
  setPageSize: (pageSize: number) => void
  pageSize: number
  rowCount: number
  gotoPage: (page: number) => void
}

const ListViewPagination = ({
  pageIndex,
  setPageSize,
  pageSize,
  rowCount,
  gotoPage
}: ListViewPaginationProps): ReactElement => {
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>): void => {
    setPageSize(parseInt(event.target.value, 10))
    gotoPage(0)
  }
  return (
    <StyledTable>
      <tfoot>
        <TableRow>
          <TablePagination
            count={rowCount}
            page={pageIndex}
            onPageChange={(_, page) => {
              gotoPage(page)
            }}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 20, { label: 'All', value: rowCount }]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </tfoot>
    </StyledTable>
  )
}

const StyledTable = styled(Table)(() => ({
  marginTop: '16px'
}))

export default ListViewPagination
