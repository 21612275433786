import Typography from '../../Typography'
import { styled } from '../../../utils'

/**
 * Styling of the Typography to create the grey sub header.
 */
export const StyledTypographyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone120,
  padding: '12px 0px 8px'
}))
