import { useState, useRef, useEffect, type ReactElement } from 'react'
import type { CredentialResponse } from 'google-one-tap'
import { Typography, Grid, Link as KuramaLink, styled, Frame2 } from '@precis-digital/kurama'
import LoadGsiScript from 'shared/components/LoadGsiScript'
import { useAuth } from 'shared/context/AuthContext'
import jwtDecode from 'jwt-decode'
import { type auth } from 'shared/api'
import { useRouter } from 'next/router'
import { logger, isValidReturnToUrl, getQueryParamsAndPathnameFromUrl } from 'shared/utils'
import ProgressBarLoader from 'shared/components/Loader/ProgressBarLoader'
import { useTranslation } from 'shared/translations'
import { Workspace } from 'auth/components/Workspace'
import { getWorkspaceNameFromUrl } from 'auth/utils'
import config from 'shared/config'
import { APP_ROUTES } from 'shared/routes'
import { useChangePath } from 'shared/components/Router'

const log = logger().child({ namespace: `${__dirname} ${__filename}` })

log.info('Signing up')

interface tokenDetails {
  email: string
  picture: string
  given_name: string
  name: string
}

export const SignUp = (): ReactElement => {
  const { t } = useTranslation('auth', { keyPrefix: 'signUp' })
  const refButton = useRef<HTMLSpanElement>(null)
  const router = useRouter()
  const returnTo = router.query.returnTo as string
  const workspaceName = getWorkspaceNameFromUrl(returnTo)
  // eslint-disable-next-line
  const [_, setMounted] = useState<boolean>(false)
  const { mustAuth, signup, isLoading, login } = useAuth()

  useEffect(() => {
    if (refButton.current != null) {
      setMounted(true)
    }
  }, [])

  const handleCredentialResponse = (credentialRes: CredentialResponse): void => {
    const idToken = credentialRes.credential
    const tokenDetails: tokenDetails = jwtDecode(idToken)

    const user: auth.AuthSignUpReq = {
      email: tokenDetails.email,
      name: tokenDetails.name,
      avatarUrl: tokenDetails?.picture,
      idToken,
      mode: 'sign-up',
      idTokenProvider: 'google'
    }
    const userSignIn: auth.AuthSignInReq = {
      email: tokenDetails.email,
      idToken,
      mode: 'sign-in',
      idTokenProvider: 'google'
    }

    void signup(user).catch(async () => {
      await login(userSignIn)
    })
  }

  const { changePath } = useChangePath()
  if (!mustAuth) {
    log.info(returnTo)
    if (isValidReturnToUrl(returnTo, config('NEXT_PUBLIC_DASHBOARD_URL') as string)) {
      const { queryParams, pathname } = getQueryParamsAndPathnameFromUrl(
        returnTo,
        config('NEXT_PUBLIC_DASHBOARD_URL') as string
      )
      void changePath(pathname, queryParams)
    } else {
      void changePath(APP_ROUTES.home.basePage)
    }
  }

  return (
    <>
      {isLoading || (!mustAuth && <ProgressBarLoader />)}
      {mustAuth && (
        <Frame2>
          <StyledContainer container>
            <Grid item xs={12}>
              <Typography variant="h2">
                {workspaceName !== null ? t('titleWithWorkspace', { workspaceName }) : t('title')}
              </Typography>
              <Typography variant="body3"> {t('subTitle')}</Typography>
            </Grid>
            {workspaceName !== null ? <Workspace name={workspaceName} /> : null}
            <StyledOauthButtonContainer item xs={12}>
              <span ref={refButton}>
                {refButton.current != null && (
                  <LoadGsiScript
                    onCredentialResponse={handleCredentialResponse}
                    authId={refButton.current}
                    text="continue_with"
                  />
                )}
              </span>
            </StyledOauthButtonContainer>
            <StyledSignInDescription item xs={12}>
              <Typography variant="body3">
                {t('question')}{' '}
                <KuramaLink variant="body3" href={APP_ROUTES.auth.signInPage}>
                  {t('toSignIn')}
                </KuramaLink>
              </Typography>
            </StyledSignInDescription>
          </StyledContainer>
        </Frame2>
      )}
    </>
  )
}

const StyledContainer = styled(Grid)((): any => ({
  textAlign: 'center'
}))

const StyledOauthButtonContainer = styled(Grid)((): any => ({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center'
})) as any

const StyledSignInDescription = styled(Grid)((): any => ({
  marginTop: '5px'
}))

export default SignUp
