import React from 'react'
import { styled } from '../../../utils'
import theme from '../index'
import { Size } from './types'

const SpacingExample = ({ size }: { size: Size }): React.ReactElement => {
  return (
    <StyledContainer>
      <div>
        <StyledRow>
          <h5>Vertical</h5>
          <h5>Horizontal</h5>
        </StyledRow>
        <StyledRow>
          <StyledVertical height={size} />
          <span>{spacing[size]} px</span>
          <StyledHorizontal width={size} />
        </StyledRow>
        <div>
          Usage: <code>theme.spacing({size})</code>
        </div>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled('div')`
  width: 400px;
  justify-content: space-between;
`
const StyledRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledVertical = styled('div')<{ height: number }>`
  background-color: ${({ theme }) => theme.palette.main.blue50};
  width: 40px;
  height: ${({ height }) => theme.spacing(height)};
  margin: 10px 0;
`
const StyledHorizontal = styled('div')<{ width: number }>`
  background-color: ${({ theme }) => theme.palette.main.blue50};
  height: 40px;
  width: ${({ width }) => theme.spacing(width)};
  margin: 10px 0;
`

export default SpacingExample
export const spacing = [4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 80, 96]
