import { ArrowLeftIcon, Button, Divider, Grid, LoadIcon, Typography, styled } from '@precis-digital/kurama'
import Image from 'next/image'
import alvieLogoFull from 'public/assets/images/alvie_logo_full.svg'
import manWithPhone from 'public/assets/images/man_w_phone.png'
import { useEffect, type ReactElement } from 'react'
import { logger } from 'shared/utils'
import { useChangePath, useGoBack, useReload } from '../Router'
import { StyledGridRightSideContainer, StyledGridTextContainer } from './styles'
import { APP_ROUTES } from 'shared/routes'
import { logAndReportError } from 'shared/errorReporter'
import { InternalServerError } from 'shared/errors'

const log = logger().child({
  namespace: 'error'
})

export const ErrUnExpected = ({
  error = new InternalServerError(),
  hasDashboardFrame = true
}: {
  error: Error | null | undefined
  hasDashboardFrame?: boolean
}): ReactElement => {
  useEffect(() => {
    if (error != null) {
      log.error(error.message)
      logAndReportError(new InternalServerError({ cause: error }))
    }
  }, [error])

  const { changePath } = useChangePath()
  const reload = useReload()
  const goBack = useGoBack()

  const handleRefreshClick = async (): Promise<void> => {
    reload()
  }

  const handleTakeMeBackClick = (): void => {
    goBack()
    reload()
  }

  const handleLogoClick = (): void => {
    void changePath(APP_ROUTES.home.basePage)
  }

  return (
    <StyledGridPageContainer hasDashboardFrame={hasDashboardFrame}>
      <StyledGridTopSectionContainer hasDashboardFrame={hasDashboardFrame}>
        <StyledGridImageContainer>
          <Image
            src={manWithPhone}
            alt="Man with phone"
            priority
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </StyledGridImageContainer>
        {!hasDashboardFrame && (
          <StyledGridLogoContainer onClick={handleLogoClick}>
            <Image
              src={alvieLogoFull}
              alt="Alvie Logo Icon Blue"
              width={128}
              height={64}
              priority
              style={{
                maxWidth: '100%',
                height: 'auto'
              }}
            />
          </StyledGridLogoContainer>
        )}
        <StyledGridRightSideContainer>
          <StyledGridTextContainer>
            <Typography variant="h2">Oops, the app is throwing a tantrum...</Typography>
            <Typography variant="body2">
              Something unexpected occurred and the page you're after isn't behaving. Just like you, we're surprised -
              but fear not, we're already on it!
            </Typography>
          </StyledGridTextContainer>
          <StyledGridButtonsContainer>
            <Button leftIcon={<ArrowLeftIcon />} variant="filled" onClick={handleTakeMeBackClick}>
              Take me back
            </Button>
            <Button
              leftIcon={<LoadIcon />}
              variant="tonal"
              onClick={() => {
                void handleRefreshClick()
              }}
            >
              Refresh the page
            </Button>
          </StyledGridButtonsContainer>
        </StyledGridRightSideContainer>
      </StyledGridTopSectionContainer>
      <Divider />
      <StyledGridErrorCodeMessageContainer>
        <Typography variant="h4">500</Typography>
        <Typography variant="body2">
          - That's just an error which means something broke that we hadn't predicted could happen.
        </Typography>
      </StyledGridErrorCodeMessageContainer>
    </StyledGridPageContainer>
  )
}

const StyledGridPageContainer = styled(Grid)(({ hasDashboardFrame }: { hasDashboardFrame: boolean }) => ({
  height: hasDashboardFrame ? '100%' : '100vh',
  padding: hasDashboardFrame ? '0px 48px' : '48px 48px 24px 48px',
  maxWidth: '1280px',
  margin: 'auto'
}))

const StyledGridTopSectionContainer = styled(Grid)(({ hasDashboardFrame }: { hasDashboardFrame: boolean }) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
  height: `calc(100% - ${hasDashboardFrame ? '80px' : '108px'})`,
  position: 'relative'
}))

const StyledGridImageContainer = styled(Grid)(() => ({
  width: '40%',
  marginLeft: '160px',
  marginRight: '-100px'
}))

const StyledGridButtonsContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '16px'
}))

const StyledGridLogoContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  gap: '26px',
  padding: '14px',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.neutrals.stone90
  },
  borderRadius: '16px'
}))

const StyledGridErrorCodeMessageContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '4px'
}))

export default ErrUnExpected
