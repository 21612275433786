import React from 'react'

export const Calendar = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <rect x="4.4541" y="6" width="16.0001" height="14.0001" rx="3" stroke="currentColor" strokeWidth="2" />
      <line x1="5.27783" y1="11" x2="19.6301" y2="11" stroke="currentColor" strokeWidth="2" />
      <line x1="8.95117" y1="7" x2="8.95117" y2="4.00005" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <line x1="15.7766" y1="7" x2="15.7766" y2="4.00005" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
})
