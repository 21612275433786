import { BriefcaseIcon, PlatformsIcon, ShoppingBagIcon } from '@precis-digital/kurama'
import { type ReactElement } from 'react'
import { type ExternalAppType, type PrecisAppsPlatforms } from 'shared/api/precisApps'
import { ASSETS_BASE_URL } from 'shared/constants'
export const PRECIS_APPS_AVATAR_URL = `${ASSETS_BASE_URL}/precis_apps_avatar.png`
export const DEFAULT_PRECIS_APPS_TAB = 'about'

export interface PrecisAppPlatformMeta {
  label: string
  platform: PrecisAppsPlatforms
  iconUrl: string
}

export const PRECIS_APPS_PLATFORMS: PrecisAppPlatformMeta[] = [
  {
    label: 'Ahrefs',
    platform: 'ahrefs',
    iconUrl: `${ASSETS_BASE_URL}/ahrefs.svg`
  },
  {
    label: 'Shopify',
    platform: 'shopify',
    iconUrl: `${ASSETS_BASE_URL}/shopify.svg`
  },
  {
    label: 'Google Search Console',
    platform: 'google_search_console',
    iconUrl: `${ASSETS_BASE_URL}/google_search_console.svg`
  },
  {
    label: 'Merchant Center',
    platform: 'merchant_center',
    iconUrl: `${ASSETS_BASE_URL}/merchant_center.svg`
  },
  {
    label: 'Snapchat',
    platform: 'snapchat',
    iconUrl: `${ASSETS_BASE_URL}/snapchat.svg`
  },
  {
    label: 'TikTok',
    platform: 'tiktok',
    iconUrl: `${ASSETS_BASE_URL}/tiktok.svg`
  }
]

export interface PrecisExternalAppTypeMeta {
  label: string
  type: ExternalAppType
  iconUrl: string
}

export const PRECIS_APPS_EXTERNAL_APP_TYPES: PrecisExternalAppTypeMeta[] = [
  {
    label: 'Google Sheet',
    type: 'google_sheet',
    iconUrl: `${ASSETS_BASE_URL}/google_sheets.svg`
  },
  {
    label: 'Google Colab',
    type: 'google_colab',
    iconUrl: `${ASSETS_BASE_URL}/google_colab.png`
  },
  {
    label: 'Looker Studio',
    type: 'looker_studio',
    iconUrl: `${ASSETS_BASE_URL}/looker_studio.png`
  },
  {
    label: 'Precis Central App',
    type: 'precis_central_app',
    iconUrl: `${ASSETS_BASE_URL}/central_app.png`
  }
]

export interface PrecisAppTagMeta {
  label: string
  value: string
}

export const PRECIS_APPS_TAGS: PrecisAppTagMeta[] = []

export interface PrecisAppClientVerticalMeta {
  label: string
  value: string
  icon: ReactElement
}

export const PRECIS_APPS_CLIENT_VERTICALS: PrecisAppClientVerticalMeta[] = [
  {
    label: 'Ecommerce',
    value: 'ecommerce',
    icon: <ShoppingBagIcon />
  },
  {
    label: 'B2B/SaaS',
    value: 'b2b_saas',
    icon: <BriefcaseIcon />
  },
  {
    label: 'App/Subscription',
    value: 'app_subscription',
    icon: <PlatformsIcon />
  }
]
