import { type controlledFilterValues } from './hooks'
import { type TransformFiltersParams, type ListViewFilterDefinition } from './types'
import { isNilOrEmpty } from 'shared/utils'

export const textAlignToFlexJustify = (textAlign: string | undefined): string => {
  switch (textAlign) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return 'flex-start'
  }
}

export const transformQueryParamsToFilters = <T>(
  value: string,
  filters: Array<ListViewFilterDefinition<T>> = []
): controlledFilterValues<T> => {
  const params: Partial<controlledFilterValues<T>> = {}
  const allowedFilters: Array<keyof controlledFilterValues<T>> = filters.map((filter) => filter.accessor)
  const keyValuePairs = value.split(' ')
  keyValuePairs.forEach((pair) => {
    const [key, values] = pair.split(':')
    const filter = filters.find((filter) => (filter.urlKey ?? filter.accessor) === key)
    const filterKey = filter?.accessor ?? key
    if (allowedFilters.includes(filterKey)) {
      if (filter?.type === 'multi-select') params[filterKey as keyof controlledFilterValues<T>] = values?.split(',')
      else params[filterKey as keyof controlledFilterValues<T>] = values?.split(',')[0]
    }
  })

  return params as controlledFilterValues<T>
}

export const testValueIsFilterDefault = <T>(
  filter: ListViewFilterDefinition<T>,
  value: string | string[] | null | undefined
): boolean => {
  if ((filter.defaultValue == null || filter.defaultValue.length === 0) && (value == null || value.length === 0))
    return true
  if ((filter.defaultValue == null || filter.defaultValue.length === 0) !== (value == null || value.length === 0))
    return false
  if (filter.type === 'multi-select') {
    const defaultValue = filter.defaultValue as string[]
    const testValue = value as string[]
    return defaultValue.length === testValue.length && defaultValue.every((v) => testValue.includes(v))
  } else {
    return filter.defaultValue === value
  }
}

export const transformFiltersToQueryParams = <T>(
  selectedFilterValues: TransformFiltersParams,
  filters: Array<ListViewFilterDefinition<T>> = []
): string => {
  return Object.entries(selectedFilterValues)
    .filter(([key, value]) => {
      const filter = filters.find((filter) => filter.accessor === key)
      return filter != null && !testValueIsFilterDefault<T>(filter, value)
    })
    .map(([key, value]) => {
      const filter = filters.find((filter) => filter.accessor === key)
      const filterKey = filter?.urlKey ?? key
      let response: string = ''
      if (Array.isArray(value)) {
        if ((value ?? [])?.length > 0) {
          response = `${filterKey}:${value.join(',')}`
        }
      } else if (!isNilOrEmpty(value)) {
        response = `${filterKey}:${value}`
      }
      return response
    })
    .join(' ')
    .trim()
}
