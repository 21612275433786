import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'pmax_breakdown_report_v2',
  active: true,
  label: 'Performance Max Breakdown Report V2',
  category: 'Platform specific',
  description:
    "The Performance Max Breakdown V2 report provides a reliable estimate of how spend, impressions, clicks, conversion etc. is allocated across placements for your PMAX campaign. Furthermore it gives a detailed view of each asset's historical performance along with the asset performance score (best, good, bad etc.).",
  image_url:
    'https://lh6.googleusercontent.com/O9FWdZF9_c9zcrSfzqDH1aJL0T0DSlzdAHcZESc1lnJZzEBS4qMFm4YROhhEMpyk0ZE=w2400',
  authors: [
    {
      name: 'Tobias Hink',
      email: 'hink@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjyNEc4WJk3HgY11avoS-2opMitZKNbLBZcaWvS=s126-p-k-rw-no'
    },
    {
      name: 'Richard Drott',
      email: 'richard@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjdVf50meWLWtrD7fZh-C9E7wxLY93SoNKssggIBg=s126-p-k-rw-no'
    },
    {
      name: 'Joella Berg',
      email: 'joella@precisdigital.com',
      image_url: 'https://drive.google.com/uc?id=1uX3nOAOFswYoVXn3gqg3DErkA4xEC2uY'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2537095230',
    link_description: 'Knowledge Hub: PMAX Breakdown Report V2'
  },
  dashboard: {
    template_report_id: '80582a09-ddc8-40eb-b00b-27639f9cf11c',
    report_name_template: '{name} - Performance Max Breakdown Report V2 - {today}',
    datasources: [
      {
        alias: 'main',
        datasourceName: '{name} - PMAX Breakdown',
        type: 'TABLE',
        tableId: 'pmax_breakdown_report_v2_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'assets',
        datasourceName: '{name} - Assets',
        type: 'TABLE',
        tableId: 'pmax_breakdown_v2_assets_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'conversions',
        datasourceName: '{name} - Conversions',
        type: 'TABLE',
        tableId: 'pmax_breakdown_v2_conv_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'placements',
        datasourceName: '{name} - Placements',
        type: 'TABLE',
        tableId: 'pmax_breakdown_v2_placements_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'products',
        datasourceName: '{name} - Products',
        type: 'TABLE',
        tableId: 'pmax_breakdown_v2_products_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      }
    ]
  },
  sources: {
    gads: {
      reports: [
        'campaign_asset_performance',
        'asset_group_assets',
        'campaign_performance_base',
        'shopping_campaign_performance',
        'campaign_conversion_expanded',
        'video_display_placement_report',
        'shopping_campaign_conversion_performance',
        'campaign_asset_conversions',
        'shopping_merchant_center_insights'
      ]
    },
    merchant_center: {
      reports: ['Products'],
      optional: true
    }
  },
  inputs: {
    other: [
      {
        id: 'targetCurrency',
        type: 'dropdown',
        options: [
          {
            value: 'SEK',
            label: 'Swedish Kronar (SEK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197564.png'
          },
          {
            value: 'DKK',
            label: 'Danish Kroner (DKK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197565.png'
          },
          {
            value: 'NOK',
            label: 'Norwegian Kroner (NOK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197579.png'
          },
          {
            value: 'EUR',
            label: 'Euros (EUR)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197615.png'
          },
          {
            value: 'USD',
            label: 'US Dollar (USD)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197484.png'
          }
        ],
        label: 'Target Currency',
        defaultValue: 'EUR',
        tooltip: 'The currency to transform all currency-based columns (cost, revenue, etc) to.',
        required: true,
        mutable: true
      }
    ]
  }
}

export default config
