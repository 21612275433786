import React from 'react'

export const UploadImage = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M12.8055 6H8.45416C6.79731 6 5.45416 7.34315 5.45416 9V16C5.45416 17.6569 6.79731 19 8.45416 19H15.8596C17.5164 19 18.8596 17.6569 18.8596 16V12.9459"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.45416 13.8108L7.36909 11.8959C7.97459 11.2904 8.96325 11.3138 9.53942 11.9473L15.9542 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3731 14.6757L13.907 13.1417C14.4928 12.556 15.4426 12.556 16.0284 13.1417L18.8596 15.973"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8596 9.49308L18.8596 3.57745"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.265 6L18.8596 3.4054L21.4542 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
