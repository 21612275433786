import { type CurrencyCodes } from 'shared/constants/currency'
import fetch from './fetch'
const client = fetch()

export interface FeaturePlan {
  name: string
  reportingSolutions: {
    enabled: boolean
    price: number
    priceCurrency: string
    arimaPlusForecastingReport: boolean
    blackFridayReport: boolean
    channelGroupingPerformanceReportV2: boolean
    dv360PerformanceReport: boolean
    facebookVisualCreativeReport: boolean
    googleAdsKeywordQualityScoreDashboard: boolean
    performanceMaxBreakdownReportV2: boolean
    productMixModelingReport: boolean
    searchSynergiesReport: boolean
    shoppingInsightsBestsellerReport: boolean
    shoppingInsightsReportV2: boolean
    spendImpactBrandAnalysisReport: boolean
  }
  connectors: {
    enabled: boolean
    price: number
    priceCurrency: string
    ahrefs: boolean
    dv360: boolean
    facebook: boolean
    googleAds: boolean
    ga4: boolean
    googleAnalytics: boolean
    merchantCenter: boolean
    googleSearchConsole: boolean
    googleSheets: boolean
    linkedin: boolean
    microsoftAdvertising: boolean
    shopify: boolean
    snapchat: boolean
    tiktok: boolean
  }
  budgetOptimiser: {
    enabled: boolean
    price: number
    priceCurrency: string
  }
  attributionModels: {
    enabled: boolean
    price: number
    priceCurrency: string
    ddaV2: boolean
    integratedAttribution: boolean
    rba: boolean
  }
  enhancedSignals: {
    enabled: boolean
    price: number
    priceCurrency: string
    transformations: {
      profit: boolean
    }
    targets: {
      ga4MpGtagPrecisEcomm: boolean
      ga4MpGtagPurchase: boolean
      ga4MpFirebasePurchase: boolean
      ga4MpFirebasePrecisEcomm: boolean
      gadsClickConversions: boolean
      sgtm: boolean
      metaConversion: boolean
    }
  }
}

export type ClientFeatureType =
  | 'reportingSolutions'
  | 'connectors'
  | 'budgetOptimiser'
  | 'attributionModels'
  | 'enhancedSignals'

export interface ClientReq {
  name: string
  url: string
  currency?: CurrencyCodes
}

export interface ClientResp {
  name: string
  url: string
  avatarUrl?: string
  id: string
  currency: CurrencyCodes
  isSoloAdmin?: boolean
  featurePlan: FeaturePlan
}

export interface UserDetailsResp {
  system_role: string
  name: string
  email: string
  phone: string
  companyName: string
  jobTitle: string
  avatarUrl?: string
  createdAt: string
  updatedAt: string
  id: string
}

export const paths = {
  clients: '/clients'
}

export interface ClientsResp extends Array<ClientResp> {}

export const createClient = async (clients: ClientReq): Promise<ClientResp> => {
  return await client.post<ClientResp>({
    path: paths.clients,
    data: clients
  })
}

export const getClients = async (): Promise<ClientsResp> => {
  return await client.get<ClientsResp>({
    path: paths.clients
  })
}

export const getClientsWithField = async (field: string): Promise<ClientsResp> => {
  return await client.get<ClientsResp>({
    path: `${paths.clients}?fields=${field}`
  })
}

type userRole = 'viewer' | 'manager' | 'editor'

export interface MembersItem {
  name: string
  email: string
  id: string
  role: userRole
  createdAt: string
  updatedAt: string
}
export interface MembersResp extends Array<MembersItem> {}

export const getMembers = async (clientId: string): Promise<MembersResp> => {
  return await client.get<MembersResp>({
    path: `/clients/${clientId}/users`
  })
}

export type MemberRole = 'viewer' | 'editor' | 'admin'
export interface MemberResp {
  name: string
  email: string
  id: string
  role: MemberRole
}

export const getMember = async (clientId: string, userId: string): Promise<MemberResp> => {
  return await client.get<MemberResp>({
    path: `/clients/${clientId}/users/${userId}`
  })
}

export interface PatchMemberReq {
  clientId: string
  userId: string
  role: string
}

export const updateMember = async (data: PatchMemberReq): Promise<MemberResp> => {
  const { clientId, userId, role } = data
  return await client.patch<MemberResp>({
    path: `/clients/${clientId}/users/${userId}`,
    data: { role }
  })
}
export interface PatchClientReq {
  name?: string
  url?: string
  id: string
  currency?: string
}

export const updateClient = async (data: PatchClientReq): Promise<ClientResp> => {
  const { id: clientId, ...clientDetailsToUpdate } = data
  return await client.patch<ClientResp>({
    path: `/clients/${clientId}`,
    data: clientDetailsToUpdate
  })
}

export interface DeleteMemberReq {
  clientId: string
  userId: string
}

export const deleteMember = async (data: DeleteMemberReq): Promise<MemberResp> => {
  const { clientId, userId } = data
  return await client.del<MemberResp>({
    path: `/clients/${clientId}/users/${userId}`
  })
}

export interface PatchUpdateMemberRoleReq {
  clientId: string
  userId: string
  role: string
}

export const updateMemberRole = async (data: PatchUpdateMemberRoleReq): Promise<MemberResp> => {
  const { clientId, userId, role } = data
  return await client.patch<MemberResp>({
    path: `/clients/${clientId}/users/${userId}`,
    data: {
      role
    }
  })
}

export interface DeleteClientResp {
  id: number
  status: string
  url?: string
}

export interface DeleteClientReq {
  clientId: string
}

export const deleteClient = async (data: DeleteClientReq): Promise<DeleteClientResp> => {
  const { clientId } = data
  return await client.del<DeleteClientResp>({
    path: `/clients/${clientId}`
  })
}

export const getUserDetailsByEmail = async (userEmail: string, clientId: string): Promise<UserDetailsResp | null> => {
  const response = await client.get<UserDetailsResp[]>({
    path: `${paths.clients}/${clientId}/users?email=${userEmail}`
  })
  return response.length === 1 ? response[0] : null
}
