import { Tooltip } from '@precis-digital/kurama'
import React, { type ReactElement } from 'react'
import { useTranslation } from 'shared/translations'

const NoPerformanceDataSymbol = (): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  return (
    <Tooltip title={t('errorFetchingPerformanceData')} kind="singleline" body={undefined}>
      <span>－</span>
    </Tooltip>
  )
}

export default NoPerformanceDataSymbol
