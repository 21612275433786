import { styled } from '../../utils'
import { BadgeColor, colorMap } from './types'
import { Theme, CSSObject } from '@mui/material/styles'

export const StyledBadge = styled('div')(
  ({ color, opacity, theme }: { color: BadgeColor; opacity?: number; theme?: Theme }): CSSObject => ({
    width: '12px',
    height: '12px',
    borderRadius: theme?.borderRadius.xxSmall,
    background: colorMap.get(color),
    flexShrink: 0,
    opacity
  })
)
