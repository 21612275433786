import { useAuth } from 'shared/context/AuthContext'
import { useQueryMember } from 'workspace/api'
import React from 'react'
import { Dropdown, DropdownList, DropdownItem } from '@precis-digital/kurama'
import { StyledRoleDropdown } from '../Popup/styles'
import { type MemberRole } from 'shared/api/clients'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'

interface RoleDropdownProps {
  open: boolean
  onSelect: (role: MemberRole) => void
  onClose: () => void
  anchorElementRef: React.MutableRefObject<null>
}
export const RoleDropdown = (props: RoleDropdownProps): React.ReactElement => {
  const { open, onSelect, onClose, anchorElementRef } = props
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()

  const { data: currentMember } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const adminCanOnlySelectAdmin = currentMember?.role === 'admin'

  const { t } = useTranslation('workspace')

  return (
    <StyledRoleDropdown>
      <Dropdown
        open={open}
        onClose={() => {
          onClose()
        }}
        anchorEl={anchorElementRef.current}
      >
        <DropdownList>
          <DropdownItem
            onClick={() => {
              onSelect('viewer')
            }}
            annotation={t('descriptions.viewerInvite')}
          >
            {t('titles.assignViewer')}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onSelect('editor')
            }}
            annotation={t('descriptions.editorInvite')}
          >
            {t('titles.assignEditor')}
          </DropdownItem>
          {adminCanOnlySelectAdmin ? (
            <DropdownItem
              onClick={() => {
                onSelect('admin')
              }}
              annotation={t('descriptions.adminInvite')}
            >
              {t('titles.assignAdmin')}
            </DropdownItem>
          ) : null}
        </DropdownList>
      </Dropdown>
    </StyledRoleDropdown>
  )
}
