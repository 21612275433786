import { DropdownListSubCategoryProps } from './types'
import { StyledTypographyAnnotation } from './styles'
import { forwardRef, ReactElement } from 'react'
import { DROPDOWN_LIST_SUB_CATEGORY_HEADER_CLASS_NAME } from '../classNames'

export const DropdownListSubCategory = forwardRef<HTMLHeadingElement, DropdownListSubCategoryProps>(
  ({ header, children, ...props }, ref): ReactElement => {
    return (
      <>
        {header !== '' && (
          <StyledTypographyAnnotation
            className={DROPDOWN_LIST_SUB_CATEGORY_HEADER_CLASS_NAME}
            variant="h5"
            {...props}
            ref={ref}
          >
            {header}
          </StyledTypographyAnnotation>
        )}
        {children}
      </>
    )
  }
)

export default DropdownListSubCategory
