async function initMocks(): Promise<void> {
  if (typeof window === 'undefined') {
    const { nodeServer: server } = await import('./server')
    server.listen()
  } else {
    const { worker } = await import('./browser')
    await worker.start({
      quiet: true
    })
  }
}

void initMocks()

export {}
