import { Grid, Skeleton, SortAscendingIcon, SortDescendingIcon, Typography, styled } from '@precis-digital/kurama'
import React, { type ReactElement } from 'react'
import { type HeaderGroup } from 'react-table'
import { type ListViewColumnInstance } from '../types'

interface ListViewTableHeaderProps<T extends object> {
  /**
   * NOTE: This is a SINGLE react-table headerGroup object
   * Multi headers not supported.
   */
  headerGroup: HeaderGroup<T>
  isClickable: boolean
  hasMoreMenu: boolean
  isLoading: boolean
}

const ListViewTableHeader = <T extends object>({
  headerGroup,
  isClickable,
  hasMoreMenu,
  isLoading
}: ListViewTableHeaderProps<T>): ReactElement => {
  return (
    <Grid container {...headerGroup.getHeaderGroupProps()} padding="0px 19px" flexWrap="nowrap" height="48px">
      <Grid container flexWrap="nowrap" gap="4px">
        {headerGroup.headers.map((column) => (
          <ListViewTableHeaderCell
            isLoading={isLoading}
            column={column as ListViewColumnInstance<T>}
            key={column.getHeaderProps().key}
          />
        ))}
        {hasMoreMenu ? (
          <Grid width="40px" minWidth="40px" />
        ) : isClickable ? (
          <Grid marginLeft="16px" width="40px" minWidth="40px" />
        ) : null}
      </Grid>
    </Grid>
  )
}
interface ListViewTableHeaderCellProps<T extends object> {
  column: ListViewColumnInstance<T>
  isLoading: boolean
}

const flexAlignMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
}

const ListViewTableHeaderCell = <T extends object>({
  column,
  isLoading
}: ListViewTableHeaderCellProps<T>): ReactElement => {
  const sortingIcon =
    column?.isSorted === false ? (
      <Grid width="16px" height="24px" />
    ) : column?.isSortedDesc === true ? (
      <SortDescendingIcon />
    ) : (
      <SortAscendingIcon />
    )
  const headerProps = {
    ...column.getHeaderProps(),
    ...column.getSortByToggleProps()
  }

  let minWidth: string | number = 0
  if (column.width != null && typeof column.width === 'string' && column.width.includes('px')) {
    // If the width is set as a pixel value, we need to set the minWidth to the same value
    minWidth = column.width
  }

  return (
    <Grid
      container
      width={column.parsedWidth}
      minWidth={minWidth}
      justifyContent={flexAlignMap[column.align ?? 'left']}
      alignItems="center"
    >
      {isLoading ? (
        <Skeleton height="24px" width="100px" />
      ) : (
        <StyledGridHeaderCellClickableContainer
          container
          width="fit-content"
          isSortable={column?.canSort === true && !isLoading}
          title={headerProps.title}
          onClick={isLoading ? undefined : headerProps.onClick} // eslint-disable-line react/jsx-handler-names
          justifyContent={flexAlignMap[column.align ?? 'left']}
        >
          {(column.align === 'right' || column.align === 'center') && sortingIcon}
          <Typography variant="h5">{column.render('Header')}</Typography>
          {(column.align ?? 'left') === 'left'
            ? sortingIcon
            : column.align === 'center' && <Grid width="16px" height="24px" />}
        </StyledGridHeaderCellClickableContainer>
      )}
    </Grid>
  )
}

const StyledGridHeaderCellClickableContainer = styled(Grid)<{ isSortable: boolean }>(({ theme, isSortable }) => ({
  cursor: isSortable ? 'pointer' : 'default',
  svg: {
    color: theme.palette.primary.main
  }
}))

export default ListViewTableHeader
