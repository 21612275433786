import { type KeyPrefix, type Namespace } from 'i18next'
import { useTranslation as useTranslationBase, appWithTranslation as appWithTranslationBase } from 'next-i18next'
import { type UseTranslationResponse, type UseTranslationOptions } from 'react-i18next'

const missingKeyHandler = (lng: string, ns: string | string[], key: string, fallbackValue: string): void => {
  throw Error(`Missing translation key '${key}' in '${lng}' language`)
}

export const useTranslation = <N extends Namespace = 'common', TKPrefix extends KeyPrefix<N> = undefined>(
  ns: N | Readonly<N>,
  args?: UseTranslationOptions<TKPrefix>
): Pick<UseTranslationResponse<N, TKPrefix>, 't' | 'i18n'> => {
  const { t, i18n } = useTranslationBase(ns, args)
  i18n.on('missingKey', missingKeyHandler)
  return { t, i18n }
}

export const appWithTranslation = appWithTranslationBase
