import { type Report } from 'home/transformations'
import { type FeedResp } from 'shared/api/analytics'
import { type accounts } from 'shared/api'
import { groupCards, sortByTimestampAndSeverity } from 'recommendations/utils'
import { type Account } from 'recommendations/types'
import { defaultToZero } from 'shared/utils'

type AggType = Record<string, Record<string, number>>
type DataFrame = Array<Record<string, number>>
const aggReport = (report: Report[], key: string[]): AggType => {
  const aggByChannel: Record<string, Record<string, number>> = {}
  report.forEach((record) => {
    const recordKey = key.length === 0 ? 'total' : key.map((k) => record[k as keyof Report]).join('::')
    if (aggByChannel[recordKey] === undefined) {
      aggByChannel[recordKey] = {
        conversions: record.conversions,
        revenue: record.conversionValue,
        spend: record.spend
      }
    } else {
      aggByChannel[recordKey].conversions += record.conversions
      aggByChannel[recordKey].revenue += record.conversionValue
      aggByChannel[recordKey].spend += record.spend
    }
  })

  return aggByChannel
}

const formatPercentChange = (percentChange: number): string => (percentChange > 0 ? '+' : '') + `${percentChange}%`
function calculatePercentageChange(current: number, prior: number): string {
  if (prior === 0) {
    return 'N/A'
  }
  const percentChange = Math.round(((current - prior) / prior) * 100)
  return formatPercentChange(percentChange)
}

function calculateRatio(numerator: number, prior: number): number | undefined {
  if (prior === 0) {
    return undefined
  }
  const percentChange = Math.round((numerator / prior) * 100)
  return percentChange
}

const joinCurrentPeriodAggToPriorPeriodAgg = (currentPeriodAgg: AggType, priorPeriodAgg: AggType): DataFrame => {
  return Object.keys(currentPeriodAgg).map((k) => {
    const newRecord: Record<string, any> = {
      key: k,
      spend: currentPeriodAgg[k].spend,
      revenue: currentPeriodAgg[k].revenue,
      conversions: currentPeriodAgg[k].conversions,
      roas: calculateRatio(currentPeriodAgg[k].revenue, currentPeriodAgg[k].spend),
      prior_period_spend: defaultToZero(priorPeriodAgg[k]?.spend),
      prior_period_conversions: defaultToZero(priorPeriodAgg[k]?.conversions),
      prior_period_revenue: defaultToZero(priorPeriodAgg[k]?.revenue),
      prior_period_roas: calculateRatio(
        defaultToZero(priorPeriodAgg[k]?.revenue),
        defaultToZero(priorPeriodAgg[k]?.spend)
      )
    }

    newRecord.change_in_spend = newRecord.spend - newRecord.prior_period_spend
    newRecord.change_in_conversions = newRecord.conversions - newRecord.prior_period_conversions
    newRecord.change_in_revenue = newRecord.revenue - newRecord.prior_period_revenue
    newRecord.change_in_roas = newRecord.roas - newRecord.prior_period_roas

    newRecord.percent_change_in_spend = calculatePercentageChange(newRecord.spend, newRecord.prior_period_spend)
    newRecord.percent_change_in_conversions = calculatePercentageChange(
      newRecord.conversions,
      newRecord.prior_period_conversions
    )
    newRecord.percent_change_in_revenue = calculatePercentageChange(newRecord.revenue, newRecord.prior_period_revenue)
    newRecord.percent_change_in_roas = calculatePercentageChange(newRecord.roas, newRecord.prior_period_roas)

    return newRecord
  })
}

export const flattenHomePageStatsData = (
  currentPeriodData: Report[],
  previousPeriodData: Report[]
): [DataFrame, DataFrame, DataFrame] => {
  const totalsCurrentPeriod = aggReport(currentPeriodData, [])
  const totalsPriorPeriod = aggReport(previousPeriodData, [])
  const totalsAggData = joinCurrentPeriodAggToPriorPeriodAgg(totalsCurrentPeriod, totalsPriorPeriod)

  const channelAggCurrentPeriod = aggReport(currentPeriodData, ['channel'])
  const channelAggPriorPeriod = aggReport(previousPeriodData, ['channel'])
  let channelAggData = joinCurrentPeriodAggToPriorPeriodAgg(channelAggCurrentPeriod, channelAggPriorPeriod)
  channelAggData = channelAggData.map((record) => ({
    ...record,
    change_in_spend_contribution: record.change_in_spend / totalsAggData[0].change_in_spend,
    change_in_revenue_contribution: record.change_in_revenue / totalsAggData[0].change_in_revenue,
    significant_change_roas: record.change_in_roas * Math.log(record.spend + 1)
  }))

  const marketAggCurrentPeriod = aggReport(currentPeriodData, ['market'])
  const marketAggPriorPeriod = aggReport(previousPeriodData, ['market'])
  let marketAggData = joinCurrentPeriodAggToPriorPeriodAgg(marketAggCurrentPeriod, marketAggPriorPeriod)
  marketAggData = marketAggData.map((record) => ({
    ...record,
    change_in_spend_contribution: record.change_in_spend / totalsAggData[0].change_in_spend,
    change_in_revenue_contribution: record.change_in_revenue / totalsAggData[0].change_in_revenue,
    significant_change_roas: record.change_in_roas * Math.log(record.spend + 1)
  }))

  return [totalsAggData, channelAggData, marketAggData]
}

export const generateHomePageContext = (
  fromDate: string,
  toDate: string,
  priorPeriodFromDate: string,
  priorPeriodToDate: string,
  customerName: string,
  currencyCode: string,
  totalsData: DataFrame,
  channelData: DataFrame,
  marketData: DataFrame
): string => {
  const formatCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      notation: 'compact',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    })
    return formatter.format(value)
  }

  const {
    spend,
    conversions,
    revenue,
    roas,
    change_in_spend: changeInSpend,
    change_in_conversions: changeInConversions,
    change_in_revenue: changeInRevenue,
    change_in_roas: changeInRoas,
    percent_change_in_revenue: percentChangeInRevenue,
    percent_change_in_spend: percentChangeInSpend,
    percent_change_in_roas: percentChangeInRoas,
    percent_change_in_conversions: percentChangeInConversions
  } = totalsData[0]

  const numChannelsWithSpend = channelData.filter((r) => r.spend > 0).length
  const numChannelsWithSpendAndNoRevenue = channelData.filter((r) => r.spend > 0 && r.revenue < 1).length
  const summary =
    '' +
    `Summary Stats for Performance data for ${customerName} for the date range ${fromDate} to ${toDate}` +
    '\n' +
    `Compared against the prior period of ${priorPeriodFromDate} to ${priorPeriodToDate}` +
    '\n' +
    'Totals: ' +
    '\n' +
    ` - Current Period Total Spend: ${formatCurrency(spend)}, Period over Period Change in Spend: ${formatCurrency(
      changeInSpend
    )}, Period over Period Percent Change in Spend: ${percentChangeInSpend}` +
    '\n' +
    ` - Current Period Total Conversions: ${conversions}, Period over Period Change in Conversions: ${changeInConversions}, Period over Period Percent Change in Conversions: ${percentChangeInConversions}` +
    '\n' +
    ` - Current Period Total Revenue: ${formatCurrency(
      revenue
    )}, Period over Period Change in Revenue: ${formatCurrency(
      changeInRevenue
    )}, Period over Period Percent Change in Revenue: ${percentChangeInRevenue}` +
    '\n' +
    ` - Current Period ROAS: ${roas}, Period over Period Change in ROAS: ${changeInRoas}, Period over Period Percent Change in ROAS: ${percentChangeInRoas}` +
    '\n' +
    ` - Percent of Channels with Spend and No revenue: ${
      calculateRatio(numChannelsWithSpendAndNoRevenue, numChannelsWithSpend) as unknown as string
    }%` +
    '\n\n' +
    'Top 5 Channels with Greatest Increase In Spend: ' +
    '\n' +
    channelData
      .filter((r) => r.change_in_spend > 0)
      .sort((a, b) => (a.change_in_spend > b.change_in_spend ? -1 : 1))
      .slice(0, 5)
      .map(
        (r) =>
          ` - Channel: ${r.key}, Current Period Spend: ${formatCurrency(r.spend)}, Change In Spend: ${formatCurrency(
            r.change_in_spend
          )}`
      )
      .join('\n') +
    '\n' +
    'Bottom 5 Channels with Greatest Decrease In Spend: ' +
    '\n' +
    channelData
      .filter((r) => r.change_in_spend < 0)
      .sort((a, b) => (a.change_in_spend < b.change_in_spend ? -1 : 1))
      .slice(0, 5)
      .map(
        (r) =>
          ` - Channel: ${r.key}, Current Period Spend: ${formatCurrency(r.spend)}, Change In Spend: ${formatCurrency(
            r.change_in_spend
          )}`
      )
      .join('\n') +
    '\n' +
    'Top 5 Channels with Greatest Increase In Revenue: ' +
    '\n' +
    channelData
      .filter((r) => r.change_in_revenue > 0)
      .sort((a, b) => (a.change_in_revenue > b.change_in_revenue ? -1 : 1))
      .slice(0, 5)
      .map(
        (r) =>
          ` - Channel: ${r.key}, Current Period Revenue: ${formatCurrency(
            r.revenue
          )}, Change In Revenue: ${formatCurrency(r.change_in_revenue)}, Percent Change In Revenue: ${
            r.percent_change_in_revenue
          }`
      )
      .join('\n') +
    '\n' +
    'Top 5 Markets with Greatest Increase In Revenue: ' +
    '\n' +
    marketData
      .filter((r) => r.change_in_revenue > 0)
      .sort((a, b) => (a.change_in_revenue > b.change_in_revenue ? -1 : 1))
      .slice(0, 5)
      .map(
        (r) =>
          ` - Market: ${r.key}, Current Period Revenue: ${formatCurrency(
            r.revenue
          )}, Change In Revenue: ${formatCurrency(r.change_in_revenue)}, Percent Change In Revenue: ${
            r.percent_change_in_revenue
          }`
      )
      .join('\n') +
    '\n' +
    'Bottom 5 Markets with Greatest Decrease In Revenue: ' +
    '\n' +
    marketData
      .filter((r) => r.change_in_revenue < 0)
      .sort((a, b) => (a.change_in_revenue < b.change_in_revenue ? -1 : 1))
      .slice(0, 5)
      .map(
        (r) =>
          ` - Market: ${r.key}, Current Period Revenue: ${formatCurrency(
            r.revenue
          )}, Change In Revenue: ${formatCurrency(r.change_in_revenue)}, Percent Change In Revenue: ${
            r.percent_change_in_revenue
          }`
      )
      .join('\n')

  return summary
}

export const formatFeedDataForMimir = (
  data: FeedResp[],
  dataSources: accounts.ClientAccountsResp[] | undefined
): string => {
  if (data.length === 0) {
    return 'There are no issues found'
  }
  const agg: Record<string, Record<string, { count: number; severity: number; accounts: Account[] }>> = {}

  const mostSevereErrors: string[] = []
  const lessSevereErrors: string[] = []
  const groupedCards = groupCards(data, dataSources)

  groupedCards
    .slice(0, 25)
    .sort((a, b) => sortByTimestampAndSeverity(a, b))
    .forEach((card) => {
      const message = `${card.title} - ${card.message}`
      if (agg[card.platform] === undefined) {
        agg[card.platform] = {}
      }
      if (agg[card.platform][message] === undefined) {
        agg[card.platform][message] = {
          count: 0,
          severity: 0,
          accounts: []
        }
      }

      agg[card.platform][message].count += 1
      const hasCategoryError = card.category === 'Error'
      agg[card.platform][message].severity = card.severity
      agg[card.platform][message].accounts = card.accounts
      const accountsString = JSON.stringify(card.accounts, (key, value) => (key === 'iconUrl' ? undefined : value))
      if (card.severity === 5 || card.severity === 4 || hasCategoryError) {
        mostSevereErrors.push(
          ` - Platform: ${card.platform}, Accounts: ${accountsString}, Error Type: ${card.category}, Error: ${message}`
        )
      } else {
        lessSevereErrors.push(
          ` - Platform: ${card.platform}, Accounts: ${accountsString}, Error Type: ${card.category}, Error: ${message}`
        )
      }
    })

  const prompt =
    `Summary Statistics: \n` +
    'Severe Issues identified across all accounts (severity of 4/5 or 5/5 or category is error): \n' +
    mostSevereErrors.join('\n') +
    '\n' +
    'Issues Identified with severity less than 4 out of 5 \n' +
    lessSevereErrors.join('\n') +
    '\n'
  return prompt
}
