import { type ChannelsMap } from 'channelGrouping/types'
import { useQueryClientAccounts } from 'dataSource/api'
import { useMemo } from 'react'
import { type Platforms } from 'shared/api/accounts'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'
import { useCurrentClient } from 'shared/context/ClientContext'

const useGetChannelsMap = (channelGroupingResp: ChannelGroupingResp | undefined): ChannelsMap => {
  const { currentClient } = useCurrentClient()
  const { data: clientAccounts } = useQueryClientAccounts(currentClient.id)

  const channelsMap = useMemo(() => {
    if (channelGroupingResp == null || clientAccounts == null) {
      return {}
    }
    const channelsMap: ChannelsMap = {}

    Object.entries(channelGroupingResp.channelGrouping).forEach(([dataSource, channelItems]) => {
      const connectedDataSources = clientAccounts.filter((account) => account.platform === (dataSource as Platforms))
      if (connectedDataSources.length === 0) {
        return
      }

      channelItems.forEach((channelItem, index) => {
        if (channelsMap[channelItem.id] == null) {
          channelsMap[channelItem.id] = {
            id: channelItem.id,
            name: channelItem.name,
            color: channelItem.color,
            note: channelItem.note,
            category: channelItem.category,
            channelRules: {}
          }
        }

        channelsMap[channelItem.id].channelRules[dataSource as Platforms] = {
          rules: channelItem.rules,
          index
        }
      })
    })

    return channelsMap
  }, [channelGroupingResp, clientAccounts])

  return channelsMap
}

export default useGetChannelsMap
