import Button from '../Button'
import { styled } from '../../utils'
import { Theme } from '../theme'

export const StyledButtonSelectOpener = styled(Button)(({ width, theme }: { width: string; theme?: Theme }) => ({
  width,
  '> div': {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%'
  },
  '.MuiTypography-root': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'block',
    textAlign: 'left',
    ...theme?.typography.body2
  }
}))
