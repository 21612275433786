import { ArrowLeftIcon, Button, CommentIcon, Divider, Grid, Typography, styled } from '@precis-digital/kurama'
import dashboardWithNewTag from 'public/assets/images/dashboard_w_new_tag.png'
import { type ReactElement } from 'react'

import Head from 'next/head'
import Image from 'next/image'

import { type ClientFeatureType } from 'shared/api/clients'
import { ALVIE_SUPPORT_EMAIL } from 'shared/constants'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'
import { openNewTab } from 'shared/utils'
import { useGoBack } from 'shared/components/Router'

export const NoAccessToFeature = ({ featureId }: { featureId: ClientFeatureType }): ReactElement => {
  const { currentClient } = useCurrentClient()
  const { currentUser } = useAuth()
  const { t } = useTranslation('common')
  const goBack = useGoBack()

  const featureIdToNameMap: Record<ClientFeatureType, string> = {
    reportingSolutions: t('sideNavigation.reportingSolutions'),
    attributionModels: t('sideNavigation.attributionModels'),
    connectors: t('sideNavigation.connectors'),
    budgetOptimiser: t('sideNavigation.budgetOptimiser'),
    enhancedSignals: t('sideNavigation.enhancedSignals')
  }

  const featureName = featureIdToNameMap[featureId]

  const handleTakeMeBackClick = (): void => {
    goBack()
  }

  const handleContactUsClick = (): void => {
    const subject = encodeURIComponent(t('noAccessToFeature.contactUsEmail.subject', { moduleName: featureName }))
    const body = encodeURIComponent(
      t('noAccessToFeature.contactUsEmail.body', {
        moduleName: featureName,
        workspaceName: currentClient.name,
        userName: currentUser?.name ?? ''
      })
    )
    openNewTab(`mailto:${ALVIE_SUPPORT_EMAIL}?subject=${subject}&body=${body}`)
  }

  return (
    <>
      <Head>
        <title>{featureName}</title>
      </Head>
      <StyledGridPageContainer>
        <StyledGridTopSectionContainer>
          <StyledGridImageContainer>
            <Image style={{ width: '100%', height: '100%' }} src={dashboardWithNewTag} alt="Dashboard with 'NEW' tag" />
          </StyledGridImageContainer>
          <StyledGridRightSideContainer>
            <StyledGridTextContainer>
              <Typography variant="h2">
                {t('noAccessToFeature.header.fragment1')}
                <br />
                {t('noAccessToFeature.header.fragment2', { moduleName: featureName })}
              </Typography>
              <Typography variant="body2">
                {t('noAccessToFeature.description', { moduleName: featureName, workspaceName: currentClient.name })}
              </Typography>
            </StyledGridTextContainer>
            <Grid alignSelf="center" gap="8px" display="flex">
              <Button leftIcon={<ArrowLeftIcon />} variant="filled" onClick={handleTakeMeBackClick}>
                {t('noAccessToFeature.takeMeBack')}
              </Button>
              <Button leftIcon={<CommentIcon />} variant="tonal" onClick={handleContactUsClick}>
                {t('noAccessToFeature.contactUs')}
              </Button>
            </Grid>
          </StyledGridRightSideContainer>
        </StyledGridTopSectionContainer>
        <Divider />
        <StyledGridErrorCodeMessageContainer>
          <Typography variant="h4">403</Typography>
          <Typography variant="body2">{t('noAccessToFeature.errorDescription')}</Typography>
        </StyledGridErrorCodeMessageContainer>
      </StyledGridPageContainer>
    </>
  )
}

const StyledGridPageContainer = styled(Grid)(() => ({
  height: '100%',
  padding: '0px 48px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxWidth: '1280px',
  margin: 'auto'
}))

const StyledGridTopSectionContainer = styled(Grid)(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  height: `calc(100% - 80px)`,
  position: 'relative'
}))

const StyledGridImageContainer = styled(Grid)(() => ({
  width: '40%',
  transform: 'scale(1.4)'
}))

const StyledGridRightSideContainer = styled(Grid)(() => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  button: {
    alignSelf: 'center'
  }
}))

const StyledGridTextContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '0px 16px',
  gap: '8px',
  zIndex: 1
}))

const StyledGridErrorCodeMessageContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '4px'
}))

export default NoAccessToFeature
