import { Avatar, Button, Card, Grid, PlusIcon, Typography, styled } from '@precis-digital/kurama'
import ConnectedDataSourcesAnnotation from 'channelGrouping/components/ConnectedDataSourcesAnnotation'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { type DataSourceItem } from 'channelGrouping/types'
import { type IPlatform } from 'dataSource'
import { useQueryClientCredentials } from 'dataSource/api'
import { useFetchAccountCredentials, useOpenDataSourceAuthenticationModal } from 'dataSource/hooks'
import React, { useState, type ReactElement } from 'react'
import { useChangePath } from 'shared/components/Router'
import { useAuth } from 'shared/context/AuthContext'
import { useTranslation } from 'shared/translations'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

import { APP_ROUTES } from 'shared/routes'
interface OtherDataSourcesTableProps {
  notConnectedDataSources: DataSourceItem[]
  hasAnyDataSourcesWithChannels: boolean
}

const OtherDataSourcesTable = ({
  notConnectedDataSources,
  hasAnyDataSourcesWithChannels
}: OtherDataSourcesTableProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  if (notConnectedDataSources.length === 0) {
    return <></>
  }

  return (
    <Grid display="flex" flexDirection="column" gap="32px">
      {hasAnyDataSourcesWithChannels && (
        <Typography variant="h3">{t('mainFormView.dataSourcesTab.otherDataSourcesTableTitle')}</Typography>
      )}
      <Grid display="flex" flexDirection="column" gap="16px">
        {notConnectedDataSources
          .sort((a, b) => b.connectedDataSources.length - a.connectedDataSources.length)
          .map((dataSource) => (
            <OtherDataSourceTableCard key={dataSource.platform} dataSource={dataSource} />
          ))}
      </Grid>
    </Grid>
  )
}

interface OtherDataSourceTableCardProps {
  dataSource: DataSourceItem
}

const OtherDataSourceTableCard = ({ dataSource }: OtherDataSourceTableCardProps): ReactElement => {
  const hasConnectedDataSources = dataSource.connectedDataSources.length > 0

  const { changePath } = useChangePath()
  const { t } = useTranslation('channelGrouping')
  const { channelGroupingId } = useCurrentChannelGrouping()
  const { currentUser } = useAuth()

  const handleClick = (): void => {
    if (hasConnectedDataSources) {
      void changePath(
        APP_ROUTES.customGroupings.channelsPage({
          channelGroupingId: channelGroupingId.toString(),
          dataSource: dataSource.platform
        })
      )
    } else {
      setSelectedPlatform(getPlatformDetailsByPlatform(dataSource.platform) ?? null)
    }
  }

  const [selectedPlatform, setSelectedPlatform] = useState<IPlatform | null>(null)
  const {
    data: credentials,
    isSuccess: isCredentialsSuccess,
    refetch
  } = useQueryClientCredentials(currentUser?.id as string, dataSource.platform)
  useFetchAccountCredentials(selectedPlatform, refetch)
  useOpenDataSourceAuthenticationModal(
    isCredentialsSuccess,
    selectedPlatform,
    credentials,
    setSelectedPlatform,
    false,
    false
  )

  return (
    <StyledCardOtherDataSourceTableCard
      key={dataSource.platform}
      hasConnectedDataSources={hasConnectedDataSources}
      onClick={hasConnectedDataSources ? handleClick : undefined}
    >
      <Grid width="100%" display="flex" justifyContent="space-between" height="48px">
        <Grid display="flex" gap="16px" alignItems="center">
          <Avatar kind="image" size="medium" imageUrl={dataSource.iconUrl} />
          <Grid display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h3">{dataSource.label}</Typography>
            {hasConnectedDataSources && (
              <ConnectedDataSourcesAnnotation
                connectedDataSources={dataSource.connectedDataSources}
                dataSource={dataSource.platform}
              />
            )}
            {!hasConnectedDataSources && (
              <StyledTypographyGreyAnnotation variant="body3">
                {t('mainFormView.dataSourcesTab.tableCard.noConnectedAccounts', {
                  dataSourceLabel: dataSource.label
                })}
              </StyledTypographyGreyAnnotation>
            )}
          </Grid>
        </Grid>
        <Grid display="flex" alignItems="center">
          {hasConnectedDataSources ? (
            <StyledButton variant="tonal" rightIcon={<PlusIcon />} onClick={handleClick}>
              {t('mainFormView.dataSourcesTab.tableCard.defineChannels')}
            </StyledButton>
          ) : (
            <StyledButton variant="filled" onClick={handleClick}>
              {t('mainFormView.dataSourcesTab.tableCard.connectYourFirstDataSource', {
                dataSourceLabel: dataSource.label
              })}
            </StyledButton>
          )}
        </Grid>
      </Grid>
    </StyledCardOtherDataSourceTableCard>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center'
}))

const StyledCardOtherDataSourceTableCard = styled(Card)<{ hasConnectedDataSources: boolean }>(
  ({ hasConnectedDataSources, theme }) => ({
    margin: 0,
    backgroundColor: hasConnectedDataSources ? theme?.palette.neutrals.white0 : theme?.palette.neutrals.stone90,
    borderColor: hasConnectedDataSources ? undefined : theme?.palette.neutrals.stone90
  })
)

const StyledTypographyGreyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme?.palette.neutrals.stone120
}))

export default OtherDataSourcesTable
