import { calDifferenceRelativeToPrevious } from 'shared/utils'
import { FIRSTSUBHEADER, SECONDSUBHEADER } from './constants'
import { type Row, type IdType } from 'react-table'

export const DEFAULT_DATA_COLUMN_PROPERTIES = {
  sortDescFirst: true
}

export const differenceColumnSortTypeFn = <T extends object>(rowA: Row<T>, rowB: Row<T>, columnId: string): number => {
  const firstRatio = calDifferenceRelativeToPrevious(
    rowA.values[columnId][SECONDSUBHEADER],
    rowA.values[columnId][FIRSTSUBHEADER]
  )
  const secondRatio = calDifferenceRelativeToPrevious(
    rowB.values[columnId][SECONDSUBHEADER],
    rowB.values[columnId][FIRSTSUBHEADER]
  )
  if (firstRatio < secondRatio) return -1
  if (firstRatio > secondRatio) return 1
  return 0
}

export const reactTableMultiSelectFilter = <T extends object>(
  rows: Array<Row<T>>,
  columnIds: Array<IdType<T>>,
  filterValue: string[]
): Array<Row<T>> => {
  const columnId = columnIds[0]
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => filterValue.some((v) => String(row.values[columnId]).toLowerCase() === v.toLowerCase()))
}
