import React from 'react'

export const Play = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M4 10.6909C4 6.9956 6.99561 4 10.6909 4H13.3091C17.0044 4 20 6.99561 20 10.6909V13.3091C20 17.0044 17.0044 20 13.3091 20H10.6909C6.9956 20 4 17.0044 4 13.3091V10.6909Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10.2051 8.58984L15.3203 11.9999L10.2051 15.41V8.58984Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
