import {
  Button,
  Card,
  Dropdown,
  Grid,
  Input,
  LinkIcon,
  LoadIcon,
  RightIcon,
  Tooltip,
  Typography,
  styled
} from '@precis-digital/kurama'
import ChatMessage from 'mimir/components/ChatMessage'
import { ChatMessageRole, MIMIR_BASE_AGENT_ID } from 'mimir/constants'
import React, { useState, type ReactElement, useEffect } from 'react'
import { useMimir } from 'shared/context/MimirContext'
import { chatMessageFactory, generateMimirChatUrl } from 'mimir/utils'
import { useRouter } from 'next/router'
import { openNewTab } from 'shared/utils'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'
interface MimirPopoverProps {
  anchorEl: HTMLElement | null
}

const MimirPopover = ({ anchorEl }: MimirPopoverProps): ReactElement => {
  const {
    isMimirOpen,
    suggestedQuestions,
    dataContext,
    toggleMimir,
    messages,
    askMimir,
    clearMessageHistory,
    isWaitingForResponse
  } = useMimir()

  const [chatInputState, setChatInputState] = useState<string>('')
  const [inputError, setInputError] = useState<string | undefined>()
  const { t } = useTranslation('common', { keyPrefix: 'mimirPopover' })

  const handleSubmit = (): void => {
    if (chatInputState === '') {
      return
    }
    localAskMimir(chatInputState)
    setChatInputState('')
  }

  const localAskMimir = (question: string): void => {
    askMimir({
      userFacingMessage: question,
      options: {
        onError: (error) => {
          setInputError(error.message)
        }
      }
    })
  }

  useEffect(() => {
    setInputError(undefined)
    if (chatInputState === '\n') {
      setChatInputState('')
    }
  }, [chatInputState])

  const router = useRouter()
  const isMimirChatRoute = router.asPath.startsWith(APP_ROUTES.mimir.chatPage)

  const dataContextWarning = dataContext == null && !isMimirChatRoute ? t('noDataWarning') : t('hasDataWarning')

  return (
    <StyledDropdown
      anchorEl={anchorEl}
      open={isMimirOpen}
      onClose={() => {
        toggleMimir()
      }}
    >
      <Grid minWidth="400px" width="30vw" display="flex" flexDirection="column">
        <Grid display="flex" flexDirection="column" gap="4px" padding="20px 20px 8px 20px">
          {messages.length === 0 && (
            <Grid display="flex" flexDirection="column" gap="8px">
              <ChatMessage
                message={{
                  role: ChatMessageRole.assistant,
                  agentId: MIMIR_BASE_AGENT_ID,
                  text: t('defaultMessage')
                }}
                renderLinks={false}
                size="small"
              />
              <Grid display="flex" flexDirection="column" gap="4px">
                {suggestedQuestions != null &&
                  suggestedQuestions.length > 0 &&
                  suggestedQuestions.map((question) => (
                    <StyledCardSuggestion
                      key={question}
                      onClick={() => {
                        localAskMimir(question)
                      }}
                    >
                      <Typography variant="body3">{question}</Typography>
                      <RightIcon />
                    </StyledCardSuggestion>
                  ))}
              </Grid>
            </Grid>
          )}
          {messages.map((message, index) => {
            return <ChatMessage key={index} message={message} renderLinks={false} size="small" />
          })}
          {isWaitingForResponse && (
            <ChatMessage
              message={{
                role: ChatMessageRole.assistant,
                agentId: MIMIR_BASE_AGENT_ID,
                text: ''
              }}
              isLoading
              renderLinks={false}
              size="small"
            />
          )}
        </Grid>
        <StyledGridInputContainer hasMessages={messages.length > 0}>
          <Grid display="flex" width="100%" gap="4px">
            <Grid display="flex" flexDirection="column" flexGrow={1}>
              <Input
                placeholder={t('inputPlaceholder')}
                value={chatInputState}
                onChange={(e) => {
                  setChatInputState(e.target.value)
                }}
                color={inputError != null ? 'error' : undefined}
                helperText={inputError != null ? inputError : undefined}
                fullWidth
                multiline
                maxRows={3}
                name="mimir-input"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    handleSubmit()
                  }
                }}
                rightIcon={<RightIcon />}
                onRightIconClick={handleSubmit}
              />
            </Grid>
            <Tooltip kind="singleline" title={t('clearHistoryButtonTooltip')} body={undefined}>
              <Button
                onClick={() => {
                  clearMessageHistory()
                }}
                disabled={messages.length === 0}
                variant="outlined"
                leftIcon={<LoadIcon />}
              />
            </Tooltip>
            <Tooltip kind="singleline" title={t('continueConversationButtonTooltip')} body={undefined}>
              <Button
                onClick={() => {
                  openNewTab(
                    generateMimirChatUrl(
                      messages.length > 0
                        ? messages
                        : [
                            chatMessageFactory({
                              role: ChatMessageRole.assistant,
                              agentId: MIMIR_BASE_AGENT_ID,
                              text: t('defaultMessage')
                            })
                          ],
                      chatInputState !== '' ? chatInputState : undefined
                    )
                  )
                }}
                variant="outlined"
                leftIcon={<LinkIcon />}
              />
            </Tooltip>
          </Grid>
          {dataContextWarning != null && (
            <StyledTypographyContextAnnotation title={dataContextWarning} variant="body3">
              {dataContextWarning}
            </StyledTypographyContextAnnotation>
          )}
        </StyledGridInputContainer>
      </Grid>
    </StyledDropdown>
  )
}

const StyledGridInputContainer = styled(Grid)<{ hasMessages: boolean }>(({ hasMessages, theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  bottom: '0',
  padding: `${hasMessages ? '16px' : '0px'} 20px 16px 20px`,
  boxShadow: hasMessages ? '0px -6px 12px 0px #7090b00f' : undefined
}))

const StyledDropdown = styled(Dropdown)(() => ({
  maxHeight: '80vh'
}))

const StyledCardSuggestion = styled(Card)(() => ({
  margin: 0,
  padding: '4px 0px 4px 8px'
}))

const StyledTypographyContextAnnotation = styled(Typography)(({ theme }) => ({
  marginTop: '4px',
  paddingLeft: '4px',
  paddingRight: '4px',
  fontSize: '0.6rem',
  color: theme.palette.neutrals.stone150,
  lineHeight: '1.1rem'
}))

export default MimirPopover
