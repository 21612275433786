import { styled } from '../../utils'
import { Chip as MUIChip } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const StyledChip = styled(MUIChip, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'hasDelete' && prop !== 'hasClick'
})<{
  hasDelete: boolean
  hasClick: boolean
  avatar: React.ReactElement
  theme: Theme
  backgroundColor?: string
  textColor?: string
}>`
  background-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.palette.neutrals.stone100};
  color: ${({ theme, textColor }) => textColor ?? theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-family: ${({ theme }) => theme.typography.body3.fontFamily};
  font-size: ${({ theme }) => theme.typography.body3.fontSize};

  &:hover {
    background-color: ${({ theme, hasClick }) => hasClick && theme.palette.neutrals.stone90};
    cursor: ${({ hasClick }) => hasClick && 'pointer'};
  }

  .MuiChip-avatar {
    margin-right: 0;
    color: inherit;
  }

  .MuiChip-label {
    padding-right: ${({ hasDelete }) => (hasDelete ? '3px' : '12px')};
    padding-left: ${({ avatar }) => (avatar != null ? '4px' : '12px')};
  }

  div[kind='image'] {
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }

  .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.palette.secondary.main};
    margin-left: 0;
    margin-right: 7px;
    g {
      path {
        fill: #dee9ec;
      }
    }
  }

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.neutrals.stone90};
    opacity: 1;
    color: ${({ theme }) => theme.palette.neutrals.stone120};

    div[kind='image'] {
      opacity: 0.5;
    }

    svg {
      > path {
        stroke: ${({ theme }) => theme.palette.neutrals.stone120};
      }
    }
  }
` as React.ComponentType<any>
