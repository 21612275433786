import { useMemo } from 'react'
import { type clients } from 'shared/api'

const roles: [clients.MemberRole, clients.MemberRole, clients.MemberRole] = ['viewer', 'editor', 'admin']

/**
 * Used to check if a user is allowed to perform a specific action.
 * If 'exactRole' is not passed, it will rely on the assumption that anything and editor can do, so can an admin do.
 */
export const useHasAccess = (
  authorizedRole: clients.MemberRole,
  memberRole: clients.MemberRole,
  exactRole: boolean = false
): boolean => {
  const hasAccess = useMemo(() => {
    const authorisedRoleIndex = roles.findIndex((role) => role === authorizedRole)
    const memberRoleIndex = roles.findIndex((role) => role === memberRole)

    return exactRole ? memberRoleIndex === authorisedRoleIndex : memberRoleIndex >= authorisedRoleIndex
  }, [authorizedRole, memberRole, exactRole])

  return hasAccess || false
}
