import fetch from './fetch'
import { type AttributionModelPerformanceSummary } from '../../attributionModel/components/Dashboard/Summary'
import { type ErrorCodeType } from 'shared/configStatuses'

const client = fetch()

export const paths = {
  analytics: '/analytics'
}

export type DateField = 'date' | 'target_date' | 'timestamp' | 'publish_time'
export type Direction = 'DESC' | 'ASC'

export interface Filter {
  field: string
  operator: string
  logicalOperator?: string
  value?: string[] | string | number | boolean
}

export interface AttributionModelsMetricsResp extends Array<AttributionModelPerformanceSummary> {}
export type ReportType =
  | 'channel_grouping_performance_report_v2'
  | 'performance_summary_ia'
  | 'detailed_report_ia'
  | 'detailed_report_dda'
  | 'detailed_report_lnda'
  | 'detailed_report_ga4'
  | 'event_feed'
  | 'attribution'
  | 'connectors_client_configs_status'
  | 'transform_client_configs_status'
  | 'connectors_config_status'
  | 'transform_config_status'
  | 'attribution_dag'
  | 'attribution_client_configs_status'
  | 'attribution_model_config_status'
  | 'transform_dag'
  | 'budget_optimiser_non_native_dag'
  | 'budget_optimiser_native_dag'
  | 'budget_optimiser_client_configs_status'
  | 'budget_optimiser_config_status'
  | 'transformed_events'
  | 'transformed_events_timestamp'
  | 'profit'
  | 'connectors_dag'
  | ChannelGroupingReportType
  | 'reporting_base'

export type ChannelGroupingReportType = 'analytics' | 'ga4' | 'facebook' | 'google' | 'dv360' | 'linkedin' | 'tiktok'

export interface AnalyticsReqField {
  name: string
  aggregation?: string
}

export interface AnalyticsReqOrderBy {
  fields: string[]
  direction: Direction
}
export interface AnalyticsReq {
  id?: string
  collection: 'attribution' | 'standard_reports' | 'event_feed' | 'status' | 'enhanced_signals' | 'channel_grouping'
  report: ReportType
  singleRow?: boolean
  clientId: string
  cursor?: number
  limit?: number
  fields?: AnalyticsReqField[]
  filters?: Filter[]
  orderBy?: AnalyticsReqOrderBy
  groupBy?: string[]
  targetCurrencyCode?: string
  useCache?: boolean
}

export type AnalyticsRespRecord = Record<string, string | number>
export interface AnalyticsResp {
  data: AnalyticsRespRecord[]
  nextCursor: number | string
  previousCursor: number | string
  statusCode: number
  totalRows: number
}

export interface DatesInAttributionReportResp {
  distinctDate: string
}

export interface DatesInBudgetOptimiserOptimalsResp {
  distinctTargetDate: string
}

export interface AttributionResponse {
  channel: string
  date: string
  ddaV2Conversions: number | null
  ddaV2Revenue: number | null
  iaUserBasedReallocationConversions: number | null
  iaUserBasedReallocationRevenue: number | null
  regressionBasedAttributionConversions: number | null
  regressionBasedAttributionRevenue: number | null
  impressions: number | null
  lndaConversions: number | null
  lndaRevenue: number | null
  ga4DefaultAttributionConversions: number | null
  ga4DefaultAttributionRevenue: number | null
  ga4LndaAttributionConversions: number | null
  ga4LndaAttributionRevenue: number | null
  spend: number | null
}

export type FeedPlatforms = 'facebook' | 'google_ads' | 'bing' | 'dv360'
export type BudgetOptimiserOptimalsPlatforms = 'facebook' | 'googleads' | 'bing' | 'dv360'

export interface FeedRespJsonPayloadData {
  id: string
  metric: number
  name: string
  campaign_id: string
}

export interface FeedRespJsonPayload {
  ruleId: string
  data: FeedRespJsonPayloadData[]
}

export interface FeedResp {
  client_id: number
  message: string
  messageId: string
  platform: FeedPlatforms
  publishTime: string
  sourceService: string
  subscriptionName: string
  tags: string[]
  timestamp: string
  title: string
  jsonPayload: FeedRespJsonPayload
}

export interface FeedDistinctPlatforminResp {
  distinctPlatform: FeedPlatforms
}

export interface ChannelGroupingPerformanceStandardReportResp {
  customChannelGrouping: string
  sumTransactions: number | null
  sumAdCost: number | null
  date: string
  marketChannelGrouping: string
  sumTransactionRevenue: number | null
}

export type ChannelGroupingPerformanceTotalsResp = Pick<
  ChannelGroupingPerformanceStandardReportResp,
  'sumTransactions' | 'sumAdCost' | 'sumTransactionRevenue'
>

export interface ChannelGroupingPerformanceTotalsGroupedByDateResp extends ChannelGroupingPerformanceTotalsResp {
  date: string
}

export interface ReportingBaseReportResp {
  channelGrouping: string
  sumConversionValue: number | null
  sumConversions: number | null
  sumCost: number | null
  marketName: string
  date: string
  sumClicks: number | null
  sumImpressions: number | null
  sumVideoViews: number | null
  sumViewableImpressions: number | null
  sumSessions: number | null
}

export interface ReportingBaseConversionEventResp {
  conversionSource: string
  conversionName: string
  sumConversions: number | null
}

export type ReportingBaseFilterParametersResp = Pick<ReportingBaseReportResp, 'marketName' | 'channelGrouping'>

export type ReportingBaseTotalsResp = Pick<
  ReportingBaseReportResp,
  | 'sumConversionValue'
  | 'sumConversions'
  | 'sumCost'
  | 'sumClicks'
  | 'sumImpressions'
  | 'sumVideoViews'
  | 'sumViewableImpressions'
  | 'sumSessions'
>

export interface ReportingBaseTotalsGroupedByDateResp extends ReportingBaseTotalsResp {
  date: string
}

export interface ReportingBaseTotalsGroupedByDateAndBreakdownResp extends ReportingBaseTotalsGroupedByDateResp {
  marketName: string
  channelGrouping: string
}
export interface BudgetOptimiserResp {
  targetDate: string
  targetType: string
  optGroupName: string
  optGroup: string
  optFactorStr: string
  platform: BudgetOptimiserOptimalsPlatforms
  platformChannelGrouping: string
  accountId: string
  campaignId: string
  campaignName: string
  daysWClicks: number
  isOptimizable: boolean
  spend: number
  clicks: number
  optSpend: number
  conversions: number
  newConversions: number
  optFitTotalNewConversions: number
  optClicks: number
  optPlatformConversions: number
  campaignNameOnly: string
  revenue: number
  optPlatformRevenue: number
  optFitTotalNewRevenue: number
  newRevenue: number
  groupOptFitRevenueConfidenceFactor: number
  rankIncrease: number
  rankDecrease: number
}
export interface ReqOptimalsDetails {
  collection: 'bo'
  report: 'bo_optimals'
  id: string
  clientId: string
  filters?: Filter[]
  fields?: Array<{ name: string; aggregation?: string }>
  orderBy?: {
    fields: DateField[]
    direction: Direction
  }
  targetCurrencyCode?: string
}

export interface BudgetOptmisersTotalsResp {
  targetDate: string
  clientName: string
  platform: string
  platformChannelGrouping: string
  accountId: string
  campaignName: string
  campaignId: string
  spend: number
  conversions: number
  newConversions: number
  confidencePercent: number
  fittable: boolean
  hasLowVarSpend: boolean
  hasLowVarSpendEa: boolean
  hasHighMeanImpressionShare: boolean
  hasLowScoreR2: boolean
  isOptimizable: boolean
  isNotOptimizable: boolean
  isNotFittable: boolean
  campaignNameOnly: string
  jointRevenueConfidenceFactor: number
  jointConversionsConfidenceFactor: number
  newRevenue: number
  newOrderValue: number
  paramA: number
  paramB: number
  imprParamA: number
  imprParamB: number
  daysWClicks: number
  newConversionRate: number
  newClickConversionRate: number
  newImpressionConversionRate: number
  hasImpressionShare: boolean
  isImpressionCampaign: boolean
  newImpressionOrderValue: number
  meanEligibleAuctions: number
  targetCurrency: string
}

export interface ReqTotalDetails {
  collection: 'bo'
  report: 'bo_total'
  fields?: Array<{ name: string }>
  id: string
  clientId: string
  filters: Filter[]
  targetCurrencyCode?: string
}

export interface BudgetOptimiserPreprocResp {
  accountCurrency: string | null
  accountId: string
  campaignId: string
  campaignMame: string
  caseName: string | null
  clicks: number
  clicksEa: number
  conversions: number
  date: string
  eligibleAuctions: number
  fitClicks: number
  hasIs: boolean
  impressions: number
  platform: string
  revenue: number
  schemaVersion: string
  searchImpressionShare: number
  spend: number
  spendEa: number
  targetCurrency: string | null
  targetDate: string
}
export interface ReqPreprocDetails {
  collection: 'bo'
  report: 'bo_preproc'
  fields?: Array<{ name: string }>
  id: string
  clientId: string
  filters: Filter[]
  targetCurrencyCode?: string
}

export interface CurrencyConversionRatesResp {
  date: string
  sourceDate: string
  baseCurrency: string
  targetCurrency: string
  exchangeRate: number
  runId: string
  lastUpdated: string
}
export interface ReqCurrencyConversionRatesDetails {
  collection: 'conversion_rates'
  report: 'conversion_rates'
  fields?: Array<{ name: string }>
  id: string
  clientId: string
  filters: Filter[]
}

export const API_CONFIG_STATUSES = {
  failed: 'failed',
  completed: 'completed',
  fetch_failed: 'fetch_failed',
  running: 'running',
  queued: 'queued'
} as const

export type E2EStatus = (typeof API_CONFIG_STATUSES)[keyof typeof API_CONFIG_STATUSES]

export interface ConfigStatusResp {
  clientId: string
  configId: string
  error: string
  lastTimeFinished: string | null
  lastTimeQueued: string | null
  lastTimeRunning: string | null
  lastUpdated: string
  message: string | null
  payload: string
  report: string
  service: string
  status: E2EStatus
  statusCode: number | null
  targetDataset: string
  targetProjectId: string
  taskId: string
  errorCode: ErrorCodeType | null
  errorMessage: string | null
}

export type DagService = 'attribution_etl' | 'attribution_model' | 'connectors' | 'transform' | 'bo'
export interface DagChildrenStatusItem {
  children: DagChildrenStatusItem[]
  configId: string
  error: string
  errorCode: ErrorCodeType | null
  errorMessage: string | null
  lastUpdated: string
  message: string
  service: DagService
  status: E2EStatus
}

export interface DagStatusResp {
  children: DagChildrenStatusItem
  clientId: string
  config: string
  configId: string
  error: string
  errorCode: ErrorCodeType | null
  errorMessage: string | null
  lastUpdated: string
  message: string
  service: DagService
  status: E2EStatus
}

export interface EnhancedSignalsEventsResp {
  targetCurrencyCode: string
  publishTime: string
  publishTimeTimestamp: string
  transactionId: string
  currency: string
  name: string
  market: string
  unmatchedProductIds: string[] | null
  value: number
}
export interface EnhancedSignalsMarketsResp {
  distinctMarket: string
}

export interface EnhancedSignalsAggregatedEventsResp {
  targetCurrencyCode: string
  publishTime: string
  countTransactionId: number
  sumValue: number
}

export const postAttributionModelMetricsReport = async (
  data: AnalyticsReq,
  token?: string
): Promise<AttributionModelsMetricsResp> => {
  return await client.post<AttributionModelsMetricsResp>({
    path: paths.analytics,
    data,
    token
  })
}

export const postFeed = async (data: AnalyticsReq, token?: string): Promise<FeedResp[]> => {
  return await client.post<FeedResp[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postReport = async <T = ReportingBaseReportResp[]>(data: AnalyticsReq, token?: string): Promise<T> => {
  return await client.post<T>({
    path: paths.analytics,
    data,
    token
  })
}

export const postBudgetOptimiser = async (data: ReqOptimalsDetails, token?: string): Promise<BudgetOptimiserResp[]> => {
  return await client.post<BudgetOptimiserResp[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postBudgetOptimiserTotals = async (
  data: ReqTotalDetails,
  token?: string
): Promise<BudgetOptmisersTotalsResp[]> => {
  return await client.post<BudgetOptmisersTotalsResp[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postBudgetOptimiserPreproc = async (
  data: ReqPreprocDetails,
  token?: string
): Promise<BudgetOptimiserPreprocResp[]> => {
  return await client.post<BudgetOptimiserPreprocResp[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postAttribution = async (data: AnalyticsReq, token?: string): Promise<AttributionResponse[]> => {
  return await client.post<AttributionResponse[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postGetDatesInAttributionReport = async (
  data: AnalyticsReq,
  token?: string
): Promise<DatesInAttributionReportResp[]> => {
  return await client.post<DatesInAttributionReportResp[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postGetDatesInBudgetOptimizerOptimals = async (
  data: ReqOptimalsDetails,
  token?: string
): Promise<DatesInBudgetOptimiserOptimalsResp[]> => {
  return await client.post<DatesInBudgetOptimiserOptimalsResp[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postGetCurrencyConversionRates = async (
  data: ReqCurrencyConversionRatesDetails,
  token?: string
): Promise<CurrencyConversionRatesResp[]> => {
  return await client.post<CurrencyConversionRatesResp[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postConfigStatuses = async (
  reportType: ReportType,
  clientId: string,
  useCache: boolean = false
): Promise<ConfigStatusResp[]> => {
  return await client.post<ConfigStatusResp[]>({
    path: paths.analytics,
    data: {
      clientId,
      collection: 'status',
      report: reportType,
      useCache
    }
  })
}

export const postConfigStatusById = async <T>(
  reportType: ReportType,
  clientId: string,
  configId: number,
  useCache: boolean = false
): Promise<T> => {
  return await client.post<T>({
    path: paths.analytics,
    data: {
      clientId,
      collection: 'status',
      report: reportType,
      id: configId,
      useCache
    }
  })
}

export const postEnhancedSignalsEvents = async <T>(data: AnalyticsReq, token?: string): Promise<T[]> => {
  return await client.post<T[]>({
    path: paths.analytics,
    data,
    token
  })
}

export const postChannelGroupingReport = async <T>(data: Omit<AnalyticsReq, 'collection'>): Promise<T[]> => {
  return await client.post<T[]>({
    path: paths.analytics,
    data: {
      ...data,
      collection: 'channel_grouping'
    },
    rawResponse: true
  })
}
