import { type RecommendationsRules, type SupportedMetrics } from 'recommendations/types'

export const MoveAffectedAccountsThreshold = 1800
export const factorToReduceMaxCharactersBy = 0.6

export const supportedMetrics: SupportedMetrics = {
  campaignName: { type: 'string', columnType: 'dimension' },
  campaignId: { type: 'string', columnType: 'dimension' },
  insertionOrderName: { type: 'string', columnType: 'dimension' },
  insertionOrderId: { type: 'string', columnType: 'dimension' },
  adGroupName: { type: 'string', columnType: 'dimension' },
  adGroupId: { type: 'string', columnType: 'dimension' },
  biddingStrategyType: { type: 'string', columnType: 'dimension' },
  adsetName: { type: 'string', columnType: 'dimension' },
  lineItemName: { type: 'string', columnType: 'dimension' },
  lineItemId: { type: 'string', columnType: 'dimension' },
  adName: { type: 'string', columnType: 'dimension' },
  adId: { type: 'string', columnType: 'dimension' },
  adType: { type: 'string', columnType: 'dimension' },
  countryName: { type: 'string', columnType: 'dimension' },
  countryCode: { type: 'string', columnType: 'dimension' },
  countryId: { type: 'string', columnType: 'dimension' },
  keywordText: { type: 'string', columnType: 'dimension' },
  keywords: { type: 'string', columnType: 'dimension' },
  keywordId: { type: 'string', columnType: 'dimension' },
  clickType: { type: 'string', columnType: 'dimension' },
  status: { type: 'string', columnType: 'dimension' },
  publisherPlatform: { type: 'string', columnType: 'dimension' },
  platformPosition: { type: 'string', columnType: 'dimension' },
  devicePlatform: { type: 'string', columnType: 'dimension' },
  publisherUrl: { type: 'string', columnType: 'dimension' },
  floodlightName: { type: 'string', columnType: 'dimension' },
  conversionName: { type: 'string', columnType: 'dimension' },
  floodlightId: { type: 'string', columnType: 'dimension' },
  budgetSegmentDescription: { type: 'string', columnType: 'dimension' },
  budgetSegment: { type: 'string', columnType: 'dimension' },
  budgetStartDate: { type: 'date', columnType: 'dimension' },
  budgetEndDate: { type: 'date', columnType: 'dimension' },
  adSpend: { type: 'spend', columnType: 'metric' },
  adSpendLast7Days: { type: 'spend', columnType: 'metric' },
  adSpendLast30Days: { type: 'spend', columnType: 'metric' },
  adSpendPrevious7Days: { type: 'spend', columnType: 'metric' },
  adSpendPrevious30Days: { type: 'spend', columnType: 'metric' },
  adSpendYesterday: { type: 'spend', columnType: 'metric' },
  averageDailyAdSpend: { type: 'spend', columnType: 'metric' },
  impressions: { type: 'numeric', columnType: 'metric' },
  impressionsLast7Days: { type: 'numeric', columnType: 'metric' },
  impressionsPrevious7Days: { type: 'numeric', columnType: 'metric' },
  impressionsLast30Days: { type: 'numeric', columnType: 'metric' },
  impressionsPrevious30Days: { type: 'numeric', columnType: 'metric' },
  fallbackImpressionsLast30Days: { type: 'numeric', columnType: 'metric' },
  lostImpressions: { type: 'numeric', columnType: 'metric' },
  reachImpressions: { type: 'numeric', columnType: 'metric' },
  clicks: { type: 'numeric', columnType: 'metric' },
  clicksLast7Days: { type: 'numeric', columnType: 'metric' },
  clicksPrevious7Days: { type: 'numeric', columnType: 'metric' },
  clicksLast30Days: { type: 'numeric', columnType: 'metric' },
  clicksPrevious30Days: { type: 'numeric', columnType: 'metric' },
  adsetClicksLast7Days: { type: 'numeric', columnType: 'metric' },
  conversions: { type: 'numeric', columnType: 'metric' },
  conversionsLast30Days: { type: 'numeric', columnType: 'metric' },
  conversionsPrevious30Days: { type: 'numeric', columnType: 'metric' },
  cpa: { type: 'spend', columnType: 'metric' },
  cpc: { type: 'spend', columnType: 'metric' },
  cpaLast30Days: { type: 'spend', columnType: 'metric' },
  cpaPrevious30Days: { type: 'spend', columnType: 'metric' },
  benchmarkCpa: { type: 'spend', columnType: 'metric' },
  dailyBudget: { type: 'spend', columnType: 'metric' },
  accountAverageDailyBudget: { type: 'spend', columnType: 'metric' },
  ctrLast14Days: { type: 'numeric', columnType: 'metric' },
  ctrPrevious14Days: { type: 'numeric', columnType: 'metric' },
  lifetimeImpressions: { type: 'numeric', columnType: 'metric' },
  frequency: { type: 'numeric', columnType: 'metric' },
  viewableImpressionPercentage: { type: 'percentChange', columnType: 'metric' },
  qualityScore: { type: 'numeric', columnType: 'metric' },
  numberOfAds: { type: 'numeric', columnType: 'metric' },
  landingPageCount: { type: 'numeric', columnType: 'metric' },
  adSpendPercentageChange: { type: 'percentChange', columnType: 'metric' },
  adSpendPercentageDrop: { type: 'percentDrop', columnType: 'metric' },
  adSpendPercentageIncrease: { type: 'percentIncrease', columnType: 'metric' },
  cpaPercentageChange: { type: 'percentChange', columnType: 'metric' },
  cpaPercentageDrop: { type: 'percentDrop', columnType: 'metric' },
  cpaPercentageIncrease: { type: 'percentIncrease', columnType: 'metric' },
  conversionsPercentageChange: { type: 'percentChange', columnType: 'metric' },
  conversionsPercentageDrop: { type: 'percentDrop', columnType: 'metric' },
  conversionsPercentageIncrease: { type: 'percentIncrease', columnType: 'metric' },
  clicksPercentageChange: { type: 'percentChange', columnType: 'metric' },
  clicksPercentageDrop: { type: 'percentDrop', columnType: 'metric' },
  clicksPercentageIncrease: { type: 'percentIncrease', columnType: 'metric' },
  ctrPercentageChange: { type: 'percentChange', columnType: 'metric' },
  impressionsPercentageChange: { type: 'percentChange', columnType: 'metric' },
  budgetPacingPercentage: { type: 'percentChange', columnType: 'metric' },
  targetKpi: { type: 'numeric', columnType: 'metric' },
  realisedKpi: { type: 'numeric', columnType: 'metric' },
  kpiDifference: { type: 'percentDrop', columnType: 'metric' }
}

export const legacyPopupTableColumns = ['name', 'metric', 'id']

export const rules: RecommendationsRules = {
  dv360_creative_fatigue: {
    RuleId: 'dv360_creative_fatigue',
    Platform: 'dv360',
    severity: 4,
    DefaultColumns: ['creative_name', 'ctr_percentage_change', 'ctr_last_14_days', 'ctr_previous_14_days']
  },
  dv360_high_io_reach_freq: {
    RuleId: 'dv360_high_io_reach_freq',
    Platform: 'dv360',
    severity: 3,
    DefaultColumns: ['insertion_order_name', 'line_item_name', 'frequency']
  },
  dv360_fallback_ads_shown: {
    RuleId: 'dv360_fallback_ads_shown',
    Platform: 'dv360',
    severity: 4,
    DefaultColumns: ['creative_name', 'fallback_impressions_last_30_days', 'impressions_last_30_days']
  },
  dv360_high_li_reach_freq: {
    RuleId: 'dv360_high_li_reach_freq',
    Platform: 'dv360',
    severity: 3,
    DefaultColumns: ['insertion_order_name', 'frequency']
  },
  google_ads_campaign_off_target: {
    RuleId: 'google_ads_campaign_off_target',
    Platform: 'google_ads',
    severity: 4,
    DefaultColumns: ['campaign_name', 'target_kpi', 'realised_kpi', 'kpi_difference', 'ad_spend']
  },
  facebook_ad_pixel_action_drop: {
    RuleId: 'facebook_ad_pixel_action_drop',
    Platform: 'facebook',
    severity: 4,
    DefaultColumns: [
      'conversion_name',
      'conversions_percentage_drop',
      'conversions_last_30_days',
      'conversions_previous_30_days'
    ]
  },
  google_ads_keywords_mixed_match_type: {
    RuleId: 'google_ads_keywords_mixed_match_type',
    Platform: 'google_ads',
    severity: 1,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'ad_spend']
  },
  google_ads_campaign_changen_country_spend: {
    RuleId: 'google_ads_campaign_changen_country_spend',
    Platform: 'google_ads',
    severity: 5,
    DefaultColumns: ['country_name', 'ad_spend_percentage_change', 'ad_spend_last_7_days', 'ad_spend_previous_7_days']
  },
  google_ads_adgroups_without_ads: {
    RuleId: 'google_ads_adgroups_without_ads',
    Platform: 'google_ads',
    severity: 4,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'lifetime_impressions']
  },
  dv360_low_viewability: {
    RuleId: 'dv360_low_viewability',
    Platform: 'dv360',
    severity: 4,
    DefaultColumns: ['insertion_order_name', 'viewable_impression_percentage', 'impressions', 'viewable_impressions']
  },
  google_ads_adgroup_off_target: {
    RuleId: 'google_ads_adgroup_off_target',
    Platform: 'google_ads',
    severity: 3,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'target_kpi', 'realised_kpi', 'kpi_difference', 'ad_spend']
  },
  dv360_budget_pacing_too_fast: {
    RuleId: 'dv360_budget_pacing_too_fast',
    Platform: 'dv360',
    severity: 3,
    DefaultColumns: [
      'budget_segment',
      'filter_budget_segment_description',
      'budget_start_date',
      'budget_end_date',
      'budget_pacing_percentage'
    ]
  },
  bing_campaign_account_no_conversions: {
    RuleId: 'bing_campaign_account_no_conversions',
    Platform: 'bing',
    severity: 5,
    DefaultColumns: ['conversions']
  },
  dv360_campaign_floodlight_trigger_drop: {
    RuleId: 'dv360_campaign_floodlight_trigger_drop',
    Platform: 'dv360',
    severity: 4,
    DefaultColumns: [
      'floodlight_name',
      'conversions_percentage_drop',
      'conversions_last_30_days',
      'conversions_previous_30_days'
    ]
  },
  dv360_budget_pacing_too_slow: {
    RuleId: 'dv360_budget_pacing_too_slow',
    Platform: 'dv360',
    severity: 3,
    DefaultColumns: [
      'budget_segment',
      'filter_budget_segment_description',
      'budget_start_date',
      'budget_end_date',
      'budget_pacing_percentage'
    ]
  },
  google_ads_keywords_spend_drop: {
    RuleId: 'google_ads_keywords_spend_drop',
    Platform: 'google_ads',
    severity: 3,
    DefaultColumns: [
      'campaign_name',
      'ad_group_name',
      'keyword_text',
      'ad_spend_percentage_drop',
      'ad_spend_yesterday',
      'average_daily_ad_spend'
    ]
  },
  google_ads_campaigns_loss_impressions: {
    RuleId: 'google_ads_campaigns_loss_impressions',
    Platform: 'google_ads',
    severity: 4,
    DefaultColumns: ['campaign_name', 'lost_impressions']
  },
  google_ads_campaigns_not_recording_conversions: {
    RuleId: 'google_ads_campaigns_not_recording_conversions',
    Platform: 'google_ads',
    severity: 5,
    DefaultColumns: ['campaign_name', 'ad_spend_last_30_days', 'conversions']
  },
  bing_campaign_drop_conversions: {
    RuleId: 'bing_campaign_drop_conversions',
    Platform: 'bing',
    severity: 3,
    DefaultColumns: [
      'campaign_name',
      'conversions_percentage_drop',
      'conversions_last_30_days',
      'conversions_previous_30_days'
    ]
  },
  google_ads_campaign_performance_max: {
    RuleId: 'google_ads_campaign_performance_max',
    Platform: 'google_ads',
    severity: 1,
    DefaultColumns: []
  },
  dv360_high_budget: {
    RuleId: 'dv360_high_budget',
    Platform: 'dv360',
    severity: 5,
    DefaultColumns: [
      'budget_segment_description',
      'budget_start_date',
      'budget_end_date',
      'daily_budget',
      'account_average_daily_budget'
    ]
  },
  google_ads_few_conversions: {
    RuleId: 'google_ads_few_conversions',
    Platform: 'google_ads',
    severity: 2,
    DefaultColumns: ['campaign_name', 'ad_spend', 'conversions']
  },
  google_ads_adgroups_without_rsa_ads: {
    RuleId: 'google_ads_adgroups_without_rsa_ads',
    Platform: 'google_ads',
    severity: 3,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'lifetime_impressions']
  },
  google_ads_adgroups_disapproved_ads: {
    RuleId: 'google_ads_adgroups_disapproved_ads',
    Platform: 'google_ads',
    severity: 3,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'ad_type', 'ad_id', 'status']
  },
  google_ads_adgroups_spend_bad_keywords: {
    RuleId: 'google_ads_adgroups_spend_bad_keywords',
    Platform: 'google_ads',
    severity: 2,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'quality_score', 'ad_spend']
  },
  google_ads_campaigns_smart_bid_coverage: {
    RuleId: 'google_ads_campaigns_smart_bid_coverage',
    Platform: 'google_ads',
    severity: 4,
    DefaultColumns: ['campaign_name', 'bidding_strategy_type', 'ad_spend']
  },
  google_ads_ad_low_strength: {
    RuleId: 'google_ads_ad_low_strength',
    Platform: 'google_ads',
    severity: 2,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'ad_id', 'ad_spend']
  },
  google_ads_campaigns_spend_drop: {
    RuleId: 'google_ads_campaigns_spend_drop',
    Platform: 'google_ads',
    severity: 4,
    DefaultColumns: ['campaign_name', 'ad_spend_percentage_drop', 'ad_spend_yesterday', 'average_daily_ad_spend']
  },
  google_ads_adgroups_without_keywords: {
    RuleId: 'google_ads_adgroups_without_keywords',
    Platform: 'google_ads',
    severity: 2,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'lifetime_impressions']
  },
  google_ads_campaign_no_sitelink_clicks: {
    RuleId: 'google_ads_campaign_no_sitelink_clicks',
    Platform: 'google_ads',
    severity: 1,
    DefaultColumns: ['campaign_name', 'click_type', 'clicks']
  },
  google_ads_adgroups_more_than_1_url: {
    RuleId: 'google_ads_adgroups_more_than_1_url',
    Platform: 'google_ads',
    severity: 2,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'landing_page_count']
  },
  google_ads_adgroups_without_dsa_ads: {
    RuleId: 'google_ads_adgroups_without_dsa_ads',
    Platform: 'google_ads',
    severity: 3,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'lifetime_impressions']
  },
  google_ads_adgroup_disapproved_keywords: {
    RuleId: 'google_ads_adgroup_disapproved_keywords',
    Platform: 'google_ads',
    severity: 2,
    DefaultColumns: ['campaign_name', 'ad_group_name', 'disapproved_keywords']
  },
  bing_campaign_partners_no_conversions: {
    RuleId: 'bing_campaign_partners_no_conversions',
    Platform: 'bing',
    severity: 1,
    DefaultColumns: ['campaign_name', 'publisher_url', 'ad_spend', 'conversions']
  },
  google_ads_adgroup_spend_drop: {
    RuleId: 'google_ads_adgroup_spend_drop',
    Platform: 'google_ads',
    severity: 3,
    DefaultColumns: [
      'campaign_name',
      'ad_group_name',
      'ad_spend_percentage_drop',
      'ad_spend_yesterday',
      'average_daily_ad_spend'
    ]
  },
  google_ads_campaign_no_auto_tagging: {
    RuleId: 'google_ads_campaign_no_auto_tagging',
    Platform: 'google_ads',
    severity: 4,
    DefaultColumns: []
  },
  dv360_campaign_change_in_country_spend: {
    RuleId: 'dv360_campaign_change_in_country_spend',
    Platform: 'dv360',
    severity: 5,
    DefaultColumns: ['country_code', 'ad_spend_percentage_change', 'ad_spend_last_7_days', 'ad_spend_previous_7_days']
  },
  facebook_campaign_spend_drop: {
    RuleId: 'facebook_campaign_spend_drop',
    Platform: 'facebook',
    severity: 2,
    DefaultColumns: ['campaign_name', 'ad_spend_percentage_drop', 'ad_spend_last_30_days', 'ad_spend_previous_30_days']
  },
  facebook_cpa_increase: {
    RuleId: 'facebook_cpa_increase',
    Platform: 'facebook',
    severity: 2,
    DefaultColumns: ['campaign_name', 'cpa_percentage_increase', 'cpa_last_30_days', 'cpa_previous_30_days']
  },
  facebook_impression_loss: {
    RuleId: 'facebook_impression_loss',
    Platform: 'facebook',
    severity: 2,
    DefaultColumns: [
      'campaign_name',
      'impressions_percentage_change',
      'impressions_last_7_days',
      'impressions_previous_7_days'
    ]
  },
  facebook_low_ad_performance: {
    RuleId: 'facebook_low_ad_performance',
    Platform: 'facebook',
    severity: 2,
    DefaultColumns: [
      'campaign_name',
      'adset_name',
      'ad_name',
      'clicks_percentage_change',
      'clicks_last_7_days',
      'adset_clicks_last_7_days'
    ]
  },
  facebook_low_number_of_ads: {
    RuleId: 'facebook_low_number_of_ads',
    Platform: 'facebook',
    severity: 1,
    DefaultColumns: ['campaign_name', 'adset_name', 'number_of_ads']
  },
  facebook_platform_cpa_anomaly: {
    RuleId: 'facebook_platform_cpa_anomaly',
    Platform: 'facebook',
    severity: 3,
    DefaultColumns: [
      'campaign_name',
      'publisher_platform',
      'conversion_name',
      'cpa_percentage_increase',
      'cpa_last_30_days',
      'benchmark_cpa'
    ]
  },
  facebook_platform_device_cpa_anomaly: {
    RuleId: 'facebook_platform_device_cpa_anomaly',
    Platform: 'facebook',
    severity: 2,
    DefaultColumns: [
      'campaign_name',
      'publisher_platform',
      'device_platform',
      'conversion_name',
      'cpa_percentage_increase',
      'cpa_last_30_days',
      'benchmark_cpa'
    ]
  },
  facebook_platform_position_cpa_anomaly: {
    RuleId: 'facebook_platform_position_cpa_anomaly',
    Platform: 'facebook',
    severity: 3,
    DefaultColumns: [
      'campaign_name',
      'publisher_platform',
      'platform_position',
      'conversion_name',
      'cpa_percentage_increase',
      'cpa_last_30_days',
      'benchmark_cpa'
    ]
  }
}

export type SupportedMetricKeys = keyof typeof supportedMetrics
