import Summary from './Summary'
import { formatDateToString } from 'shared/dateFns'
import ProgressOverTime, { type ProgressOverTimeRecords } from './ProgressOvertime'
import React, { type ReactNode } from 'react'
import { type StandardReportResp } from 'shared/api/standardReports'
import { Link, Typography } from '@precis-digital/kurama'
import { APP_ROUTES } from 'shared/routes'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'
import { useTranslation } from 'shared/translations'
import { type Metric } from 'home/reportTemplateParameters'

const ReportDataError = ({
  selectedMetrics,
  report,
  errorType
}: {
  selectedMetrics: Metric[]
  errorType: 'status' | 'unknown'
  report?: StandardReportResp
}): React.ReactElement => {
  const { t } = useTranslation('home')
  const date = formatDateToString({ date: new Date(), targetFormat: 'yyyy-MM-dd' })

  let errorBody: ReactNode = null

  if (errorType != null) {
    errorBody =
      errorType === 'status' && report != null ? (
        <>
          <Typography variant="h5">{t('chartReportStatusErrorTitle')}</Typography>
          <Typography variant="body3">
            {t('chartReportStatusErrorPrompt1')}
            <UnStyledRetainDefaultQueryLink
              href={APP_ROUTES.reportingSolutions.editConfigPage({ configId: report.configId.toString() })}
            >
              <Link variant="body3" href="#">
                {t('chartReportStatusErrorPrompt2')}
              </Link>
            </UnStyledRetainDefaultQueryLink>{' '}
            {t('chartReportStatusErrorPrompt3')}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h5">{t('chartReportUnknownErrorTitle')}</Typography>
          <Typography variant="body3">{t('chartReportUnknownErrorPrompt')}</Typography>
        </>
      )
  }

  const progressOverTimeRecords: Partial<Record<Metric, ProgressOverTimeRecords>> = {}
  selectedMetrics.forEach((metric) => {
    progressOverTimeRecords[metric] = []
  })

  return (
    <>
      <Summary
        startDate={date}
        endDate={date}
        previousTimePeriod={[date, date]}
        summaryData={null}
        summaryDataForPreviousTimePeriod={null}
        progressOverTimeRecords={progressOverTimeRecords}
        errorBody={errorBody}
      />
      <ProgressOverTime
        metrics={[]}
        reports={[]}
        reportsForPreviousTimePeriod={[]}
        timePeriod={[date, date]}
        errorBody={errorBody}
      />
    </>
  )
}

export default ReportDataError
