import { useCurrentClient } from 'shared/context/ClientContext'
import config from 'shared/config'

/**
 * Used to compose the service account email for the client.
 */
export const useClientServiceAccountEmail = (): string => {
  const { currentClient } = useCurrentClient()
  return `alvie-${currentClient.id}@${config('NEXT_PUBLIC_ALVIE_SA_DOMAIN_NAME') as string}`
}
