import { type ListViewCellProps } from '../../types'

import React, { type ReactElement } from 'react'
import BaseCell from './BaseCell'
import { Grid, Typography, styled } from '@precis-digital/kurama'

interface TitleCellProps<T extends object> extends ListViewCellProps<T> {
  title: string
  subTitle?: string
}

const TitleCell = <T extends object>({ title, subTitle, ...props }: TitleCellProps<T>): ReactElement => {
  return (
    <BaseCell width={props.column.parsedWidth} align="left">
      <Grid container flexDirection="column" overflow="hidden" flexWrap="nowrap" minWidth={0}>
        <StyledTypographyTitle variant="h4" title={title}>
          {title}
        </StyledTypographyTitle>
        {subTitle != null && <StyledTypographySubtitle variant="body3">{subTitle}</StyledTypographySubtitle>}
      </Grid>
    </BaseCell>
  )
}

const StyledTypographyTitle = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
  lineClamp: 1
}))

const StyledTypographySubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word'
}))

export default TitleCell
