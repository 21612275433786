import {
  CancelIcon,
  CheckIcon,
  Toaster as KuramaToaster,
  type ToasterProps,
  type ToasterVariant
} from '@precis-digital/kurama'
import { useState, type ReactNode, useEffect, useRef } from 'react'
import { isClientEnvironment } from 'shared/utils'

interface MakeToastOptions {
  onClose?: () => void
  onConfirm?: () => void
  persistThroughRouteChange?: boolean
}

interface MakeErrorToastOptions extends MakeToastOptions {}

interface MakeSuccessToastOptions extends MakeToastOptions {
  confirmToClose?: boolean
}

interface MakeToastWithPromiseHandlers {
  toastOnSuccess: (message: string | ReactNode, successOptions?: MakeSuccessToastOptions) => void
  toastOnError: (message: string | ReactNode, successOptions?: MakeErrorToastOptions) => void
  cancelToast: () => void
}

interface ToasterOptions {
  open: boolean
  isLoading: boolean
  variant: ToasterVariant
  description: string | ReactNode
  successDelay?: number
  onClose?: () => void
  onConfirm?: () => void
  persistThroughRouteChange?: boolean
}

const defaultToasterOptions: ToasterOptions = {
  open: false,
  isLoading: false,
  variant: 'success',
  description: '',
  successDelay: undefined,
  onClose: undefined,
  onConfirm: undefined,
  persistThroughRouteChange: false
}

let memoryState: ToasterOptions = {
  ...defaultToasterOptions
}

type Listener = (state: ToasterOptions) => void

type ReducerActionType = 'SHOW_TOAST' | 'CLOSE_TOAST'

interface ReducerAction {
  type: ReducerActionType
  open?: boolean
  isLoading?: boolean
  variant?: ToasterVariant
  description?: string | ReactNode
  successDelay?: number
  onClose?: () => void
  onConfirm?: () => void
  persistThroughRouteChange?: boolean
}

const listeners: Listener[] = []

const dispatch = (action: ReducerAction): void => {
  memoryState = reducer(memoryState, action)
  listeners.forEach((listener) => {
    listener(memoryState)
  })
}

const addListener = (listener: Listener): (() => void) => {
  listeners.push(listener)
  return () => {
    const index = listeners.indexOf(listener)
    if (index > -1) {
      listeners.splice(index, 1)
    }
  }
}

const reducer = (state: ToasterOptions, action: ReducerAction): ToasterOptions => {
  switch (action.type) {
    case 'SHOW_TOAST':
      return {
        ...state,
        open: true,
        isLoading: action?.isLoading ?? false,
        variant: action?.variant ?? state.variant,
        description: action?.description,
        persistThroughRouteChange: action?.persistThroughRouteChange ?? false,
        successDelay: action.successDelay,
        onClose: action.onClose,
        onConfirm: action.onConfirm
      }
    case 'CLOSE_TOAST':
      if (state.onClose != null) state.onClose()
      return {
        ...state,
        ...defaultToasterOptions,
        variant: state.variant,
        description: state.description,
        successDelay: state.successDelay
      }
    default:
      return state
  }
}

export const makeSuccessToast = (message: string | ReactNode, options?: MakeSuccessToastOptions): void => {
  const action: ReducerAction = {
    type: 'SHOW_TOAST',
    isLoading: false,
    variant: 'success',
    description: message
  }

  if (options?.confirmToClose !== true) {
    action.successDelay = 1500
  }

  if (options?.onClose != null) {
    action.onClose = options.onClose
  }

  if (options?.onConfirm != null) {
    action.onConfirm = options.onConfirm
  }

  if (options?.persistThroughRouteChange === true) {
    action.persistThroughRouteChange = true
  }

  dispatch(action)
}

export const makeErrorToast = (message: string | ReactNode, options?: MakeErrorToastOptions): void => {
  const action: ReducerAction = {
    type: 'SHOW_TOAST',
    isLoading: false,
    variant: 'error',
    description: message
  }

  if (options?.onClose != null) {
    action.onClose = options.onClose
  }

  if (options?.onConfirm != null) {
    action.onConfirm = options.onConfirm
  }

  if (options?.persistThroughRouteChange === true) {
    action.persistThroughRouteChange = true
  }

  dispatch(action)
}

export const makeToastWithLoading = (options?: MakeToastOptions): MakeToastWithPromiseHandlers => {
  const toastOnSuccess = (successMessage: string | ReactNode, successOptions?: MakeSuccessToastOptions): void => {
    const toastOptions = {
      ...options,
      ...successOptions
    }
    makeSuccessToast(successMessage, toastOptions)
  }

  const toastOnError = (errorMessage: string | ReactNode, errorOptions?: MakeErrorToastOptions): void => {
    const toastOptions = {
      ...options,
      ...errorOptions
    }
    makeErrorToast(errorMessage, toastOptions)
  }

  const cancelToast = (): void => {
    closeToaster()
  }
  const action: ReducerAction = {
    type: 'SHOW_TOAST',
    isLoading: true
  }

  if (options?.onClose != null) {
    action.onClose = options.onClose
  }

  if (options?.onConfirm != null) {
    action.onConfirm = options.onConfirm
  }

  if (options?.persistThroughRouteChange === true) {
    action.persistThroughRouteChange = true
  }

  dispatch(action)
  return { toastOnSuccess, toastOnError, cancelToast }
}

export const closeToaster = (): void => {
  dispatch({ type: 'CLOSE_TOAST' })
}

export const Toaster = (): React.ReactElement => {
  const [toasterState, setToasterState] = useState(memoryState)

  useEffect(() => {
    const removeListener = addListener(setToasterState)
    return () => {
      removeListener()
    }
  }, [])

  const locationOnLoad = useRef(isClientEnvironment() ? window.location.pathname : null)
  useEffect(() => {
    locationOnLoad.current = isClientEnvironment() ? window.location.pathname : null
  }, [toasterState])

  const locationNow = isClientEnvironment() ? window.location.pathname : null

  useEffect(() => {
    if (
      toasterState.persistThroughRouteChange !== true &&
      locationOnLoad.current !== locationNow &&
      locationOnLoad != null &&
      locationNow != null
    ) {
      closeToaster()
    }
  }, [toasterState.persistThroughRouteChange, locationNow, locationOnLoad])

  const toasterVariantProps: Record<
    ToasterVariant,
    Pick<ToasterProps, 'headerMessage' | 'headerIcon' | 'confirmButtonMessage'>
  > = {
    success: {
      headerMessage: 'Woo-hoo!',
      headerIcon: <CheckIcon />,
      confirmButtonMessage: 'Ok, Great!'
    },
    error: {
      headerMessage: 'Oh snap!',
      headerIcon: <CancelIcon />,
      confirmButtonMessage: 'Ok, I understand'
    }
  }

  const baseToasterProps = {
    loadingMessage: 'Processing...'
  }

  return (
    <KuramaToaster
      open={toasterState.open}
      loading={toasterState.isLoading}
      variant={toasterState.variant}
      onConfirm={toasterState.onConfirm != null ? () => toasterState.onConfirm?.() : undefined}
      onClose={closeToaster}
      description={toasterState.description}
      successDelay={toasterState.successDelay}
      {...baseToasterProps}
      {...toasterVariantProps[toasterState.variant]}
    />
  )
}

export default Toaster
