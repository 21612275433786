import { breakPointsOptions, BreakPoints } from './types'
export * from './types'

export const breakpointToColor: Record<breakPointsOptions, string> = {
  xs: 'grey',
  sm: 'stone90',
  md: 'stone100',
  lg: 'stone120',
  xl: 'stone150'
}
export const breakpoints: BreakPoints = {
  values: {
    xs: 0,
    sm: 905,
    md: 1280,
    lg: 1440,
    xl: 1648
  }
}
