import React from 'react'

export const PrecisApps = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <g clipPath="url(#clip0_693_1872)">
        <path
          d="M10.1 14.95C12.8614 14.95 15.1 12.7114 15.1 9.95001C15.1 7.18859 12.8614 4.95001 10.1 4.95001C7.33855 4.95001 5.09998 7.18859 5.09998 9.95001C5.09998 12.7114 7.33855 14.95 10.1 14.95Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path d="M5 4.04999V20.05" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
        <path
          d="M18.4 15.85C19.2836 15.85 20 15.1337 20 14.25C20 13.3664 19.2836 12.65 18.4 12.65C17.5163 12.65 16.8 13.3664 16.8 14.25C16.8 15.1337 17.5163 15.85 18.4 15.85Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_693_1872">
          <rect width="16" height="16.1" fill="none" transform="translate(4 3.95001)" />
        </clipPath>
      </defs>
    </svg>
  )
})
