import React, { forwardRef, useEffect, useState } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import { StyledDateRangePickerWrapper, StyledDivider, StyledCalendarInfo, StyledCalendarInfoContainer } from './styles'
import { DateRangePicker } from 'react-dates'

import moment, { Moment } from 'moment'
import { CalendarProps } from './types'
import { CalendarIcon, ChevronDownIcon, LeftIcon, RightIcon } from '../Icons'
import Button from '../Button'
import { handleDatesChange } from './utils'
import Grid from '../Grid'

const stringToMoment = (date: string): Moment | null => {
  const momentDate = moment(date)
  return momentDate.isValid() ? momentDate : null
}

const momentToString = (date: Moment, dateRangeSelectedFormat: string): string | null => {
  if (dateRangeSelectedFormat === 'iso') return date?.toISOString() ?? null
  return date?.format(dateRangeSelectedFormat) ?? null
}

const DateRangeCalendar = forwardRef<HTMLDivElement, CalendarProps>(
  (
    {
      displayFormat = 'MMM Do, YYYY',
      onDateRangeSelected,
      dateRangeSelectedFormat = 'YYYY-MM-DD',
      onCancel,
      defaultStartDate,
      defaultEndDate,
      applyText,
      cancelText,
      renderCalendarInfo,
      isOutsideRange = () => false,
      onApplyDateRangeClick,
      ...props
    },
    ref
  ): React.ReactElement => {
    const [startDate, setStartDate] = useState<Moment | null>(
      defaultStartDate != null ? stringToMoment(defaultStartDate) : moment().subtract(1, 'month')
    )
    const [endDate, setEndDate] = useState<Moment | null>(
      defaultEndDate != null ? stringToMoment(defaultEndDate) : moment()
    )

    const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(startDate)
    const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(endDate)

    const [focusedInput, setFocusedInput] = React.useState<'startDate' | 'endDate' | null>(null)

    useEffect(() => {
      if (focusedInput == null) return
      setFocusedInput('startDate')
    }, [focusedInput])

    const handleApplyDateRange = (): void => {
      onApplyDateRangeClick?.()
      persistAppliedDates()
      closeCalendar()
    }

    const closeCalendar = (): void => {
      setFocusedInput(null)
    }

    const persistAppliedDates = (): void => {
      setSelectedStartDate(startDate)
      setSelectedEndDate(endDate)
    }

    const rollbackDates = (): void => {
      setStartDate(selectedStartDate)
      setEndDate(selectedEndDate)
    }
    const handleCancel = (): void => {
      rollbackDates()
      closeCalendar()
      onCancel()
    }

    useEffect(() => {
      onDateRangeSelected(
        selectedStartDate == null ? null : momentToString(selectedStartDate, dateRangeSelectedFormat),
        selectedEndDate == null ? null : momentToString(selectedEndDate, dateRangeSelectedFormat)
      )
    }, [selectedStartDate, selectedEndDate])

    return (
      <StyledDateRangePickerWrapper {...props}>
        <DateRangePicker
          displayFormat={displayFormat}
          startDate={startDate}
          startDateId="START_DATE"
          endDate={endDate}
          endDateId="END_DATE"
          weekDayFormat="ddd"
          firstDayOfWeek={1}
          navPrev={<LeftIcon />}
          navNext={<RightIcon />}
          readOnly
          onClose={handleCancel}
          onDatesChange={({ startDate: newStartDate, endDate: newEndDate }) => {
            handleDatesChange({
              newStartDate,
              newEndDate,
              previousStartDate: startDate,
              previousEndDate: endDate,
              setStartDate,
              setEndDate
            })
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusInput) => setFocusedInput(focusInput)}
          renderCalendarInfo={() => {
            const defaultCalendarInfo = (
              <CalendarInfo
                onSelectedDateRange={handleApplyDateRange}
                onCancel={handleCancel}
                applyText={applyText}
                cancelText={cancelText}
              />
            )
            return (
              <StyledCalendarInfoContainer>
                <StyledDivider />
                {renderCalendarInfo?.(defaultCalendarInfo) ?? (
                  <Grid display="flex" justifyContent="end">
                    {defaultCalendarInfo}
                  </Grid>
                )}
              </StyledCalendarInfoContainer>
            )
          }}
          calendarInfoPosition="bottom"
          customInputIcon={<CalendarIcon />}
          customArrowIcon="-"
          keepOpenOnDateSelect
          keepFocusOnInput
          reopenPickerOnClearDates
          isOutsideRange={(day) => isOutsideRange(day.toDate())}
        />
        <ChevronDownIcon />
      </StyledDateRangePickerWrapper>
    )
  }
)

export default DateRangeCalendar

interface CalendarInfoProps {
  onCancel: () => void
  onSelectedDateRange: () => void
  applyText?: string
  cancelText?: string
}
const CalendarInfo = ({
  onCancel,
  onSelectedDateRange,
  applyText,
  cancelText
}: CalendarInfoProps): React.ReactElement => {
  return (
    <StyledCalendarInfo>
      <Button onClick={onCancel} scheme="light" variant="tonal">
        {cancelText}
      </Button>
      <Button onClick={onSelectedDateRange} scheme="light" variant="filled">
        {applyText}
      </Button>
    </StyledCalendarInfo>
  )
}
