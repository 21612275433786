import {
  Accordion,
  Avatar,
  Badge,
  Button,
  ChevronDownIcon,
  Chip,
  DotsLoader,
  EditIcon,
  Grid,
  Typography,
  styled
} from '@precis-digital/kurama'
import { type Theme } from '@precis-digital/kurama/src/components/theme'
import ConnectedDataSourcesAnnotation from 'channelGrouping/components/ConnectedDataSourcesAnnotation'
import NoPerformanceDataSymbol from 'channelGrouping/components/NoPerformanceDataSymbol'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import useGetDataSourceMetricValues from 'channelGrouping/hooks/useGetDataSourceMetricValues'
import { useMetricsSelectionState } from 'channelGrouping/hooks/useMetricsSelectionState'
import { type DataSourceItem } from 'channelGrouping/types'
import React, { type ReactNode, type ReactElement } from 'react'
import { useChangePath } from 'shared/components/Router'
import { useCurrentClient } from 'shared/context/ClientContext'
import formatToMoney, { formatToNumber } from 'shared/numberFormat'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'
interface DataSourcesTableCardProps {
  dataSource: DataSourceItem
  isExpanded: boolean
  handleExpandClick: (isExpanded: boolean) => void
}

const DataSourcesTableCard = ({
  dataSource,
  isExpanded,
  handleExpandClick
}: DataSourcesTableCardProps): ReactElement => {
  return (
    <StyledAccordionCardContainer
      expanded={isExpanded}
      onChange={() => {
        handleExpandClick(!isExpanded)
      }}
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true
      }}
      expandIcon={<ChevronDownIcon />}
      Summary={<DataSourcesTableCardSummary dataSource={dataSource} isExpanded={isExpanded} />}
      Details={<DataSourcesTableCardDetails dataSource={dataSource} />}
    />
  )
}

interface DataSourcesTableCardSummaryProps {
  dataSource: DataSourceItem
  isExpanded: boolean
}

const DataSourcesTableCardSummary = ({ dataSource, isExpanded }: DataSourcesTableCardSummaryProps): ReactElement => {
  const { selectedMetric } = useMetricsSelectionState(dataSource.platform)
  const { channelGroupingId } = useCurrentChannelGrouping()
  const { currentClient } = useCurrentClient()

  const { sumValues, accountsValues, isLoading, isError } = useGetDataSourceMetricValues(
    channelGroupingId,
    dataSource.platform,
    dataSource.connectedDataSources.map((connectedDataSource) => connectedDataSource.externalAccountId)
  )

  const renderPerformanceDataValue = (): ReactNode => {
    if (isLoading) {
      return <></>
    }
    if (isError) {
      return <NoPerformanceDataSymbol />
    }
    return selectedMetric?.type === 'currency'
      ? formatToMoney({ value: sumValues?.[selectedMetric.id] ?? 0, currencyCode: currentClient.currency })
      : formatToNumber(sumValues?.[selectedMetric.id] ?? 0)
  }

  return (
    <Grid container gap="24px" flexWrap="nowrap">
      <Grid width="calc(50% + 40px)" display="flex" alignItems="center" gap="16px">
        <Avatar kind="image" size={isExpanded ? 'large' : 'medium'} imageUrl={dataSource.iconUrl} />
        <Grid display="flex" flexDirection="column">
          <Typography variant={isExpanded ? 'h2' : 'h3'}>{dataSource.label}</Typography>
          <ConnectedDataSourcesAnnotation
            connectedDataSources={dataSource.connectedDataSources}
            dataSource={dataSource.platform}
            accountsMetricValues={accountsValues}
            selectedMetric={selectedMetric}
            isPerformanceDataError={isError}
          />
        </Grid>
      </Grid>
      <Grid width="50%" display="flex" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
        <Grid width="calc(30% + 20px)" display="flex" justifyContent="center">
          <Typography variant="body2">{dataSource.channels.length}</Typography>
        </Grid>
        <Grid marginRight="4px" display="flex" justifyContent="flex-end" gap="12px" flexWrap="nowrap">
          <Typography variant="body2">
            {selectedMetric?.label}: {renderPerformanceDataValue()}
          </Typography>
          {isLoading && <DotsLoader width={24} height={24} />}
        </Grid>
      </Grid>
    </Grid>
  )
}

interface DataSourcesTableCardDetailsProps {
  dataSource: DataSourceItem
}

const DataSourcesTableCardDetails = ({ dataSource }: DataSourcesTableCardDetailsProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const { changePath } = useChangePath()
  const { channelGroupingId } = useCurrentChannelGrouping()

  const handleEditChannelsClick = (): void => {
    void changePath(
      APP_ROUTES.customGroupings.channelsPage({
        channelGroupingId: channelGroupingId.toString(),
        dataSource: dataSource.platform
      })
    )
  }

  return (
    <Grid container gap="16px">
      <StyledGridChannelChipsContainer>
        <Typography variant="h3">{t('mainFormView.dataSourcesTab.tableCard.detailsTitle')}</Typography>
        <Grid container rowGap="16px" columnGap="8px">
          {dataSource.channels.map((channel) => (
            <Chip
              key={channel.name}
              label={channel.name}
              avatar={
                <Grid width="24px" height="24px" display="flex" alignItems="center" justifyContent="center">
                  <Badge color={channel.color} />
                </Grid>
              }
            />
          ))}
        </Grid>
      </StyledGridChannelChipsContainer>
      <Button variant="filled" fullWidth onClick={handleEditChannelsClick} rightIcon={<EditIcon />}>
        {t('mainFormView.dataSourcesTab.tableCard.editChannels')}
      </Button>
    </Grid>
  )
}

const StyledGridChannelChipsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette.neutrals.stone90,
  gap: '16px',
  padding: '20px',
  borderRadius: '24px',
  width: '100%'
}))

const StyledAccordionCardContainer = styled(Accordion)(({ theme, expanded }: { theme?: Theme; expanded: boolean }) => ({
  borderRadius: theme?.borderRadius.xLarge,
  outline: `1px solid ${theme?.palette.neutrals.stone100 as string}`,
  boxShadow: expanded ? theme?.elevation.mediumDepth : 'none',
  transition: 'box-shadow 200ms ease-in-out',
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  },
  '.MuiAccordionSummary-root': {
    backgroundColor: theme?.palette.neutrals.white0,
    padding: '20px',
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge,
    '.MuiAccordionSummary-expandIconWrapper': {
      width: '40px',
      justifyContent: 'center',
      color: theme?.palette.secondary.main
    },
    '.kurama-AvatarGroup': {
      alignItems: 'center'
    }
  },
  '&.MuiAccordion-root:before': {
    display: 'none'
  },
  '.MuiAccordionDetails-root': {
    padding: '20px',
    paddingTop: 0,
    backgroundColor: theme?.palette.neutrals.white0,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  }
}))

export default DataSourcesTableCard
