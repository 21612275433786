import { type NextRouter } from 'next/router'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { type Platforms } from 'shared/api/accounts'
import { type AttributionResp } from 'shared/api/attributionModels'
import { type IntegrationAttributionResp as BOIntegratedAttributionResp } from 'shared/api/budgetOptimiser'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'
import { type ConnectorResp, type ConnectorsResp } from 'shared/api/connectors'
import { type EnhancedSignalsResp } from 'shared/api/enhancedSignals'
import { type PrecisAppResp } from 'shared/api/precisApps'
import { type StandardReportResp, type StandardReportsResp } from 'shared/api/standardReports'
import { useCurrentClient } from 'shared/context/ClientContext'
import { capitalize, logger } from 'shared/utils'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

import { queryKeys as attributionModelQueryKeys } from 'attributionModel/api'
import { queryKeys as budgetOptimiserQueryKeys } from 'budgetOptimiser/api'
import { queryKeys as channelGroupingQueryKeys } from 'channelGrouping/api'
import { queryKeys as connectorsQueryKeys } from 'connectors/api'
import { queryKeys as enhancedSignalsQueryKeys } from 'enhancedSignals/api'
import { queryKeys as precisAppsQueryKeys } from 'precisApp/api'
import { queryKeys as reportingSolutionsQueryKeys } from 'reportingSolution/api'

const log = logger().child({ namespace: `${__dirname} ${__filename}` })

export interface BreadcrumbPathProps {
  label: string
  path: string
  disabled?: boolean
}

export const useBreadcrumbsPathProps = (router: NextRouter): BreadcrumbPathProps[] => {
  const pathSegments = getSegments(router.asPath)
  const routeSegments = getSegments(router.route)
  const { currentClient } = useCurrentClient()
  const queryClient = useQueryClient()

  const parsedSegments = useMemo(() => {
    if (pathSegments.length === 0) {
      return [
        {
          label: 'Home',
          path: '/'
        }
      ]
    }

    const segmentsParser = MODULE_SEGMENTS_PARSERS[pathSegments?.[0]] ?? defaultModuleSegmentsParser
    return segmentsParser(pathSegments, routeSegments, queryClient, currentClient)
  }, [pathSegments, routeSegments, queryClient, currentClient])

  return parsedSegments
}

export const getSegments = (path: string): string[] => {
  const pathArray = path.split('?')
  const segments = pathArray.length > 0 ? pathArray[0].split('/') : []
  return segments.filter((segment) => segment !== '')
}

const isQuery = (segment: string): boolean => {
  return /\[\w+]/.exec(segment) !== null
}

type RouteSegmentsParser = (
  /**
   * The segments of the path that are actually rendered in the URL.
   */
  segments: string[],
  /**
   * The segments of the path as NextJS sees them.
   * I.e. it renders the dynamic segments as /example/[xx_id]/edit.
   */
  routeSegments: string[],
  queryClient: ReturnType<typeof useQueryClient>,
  currentClient: ReturnType<typeof useCurrentClient>['currentClient'],
  disableQueryParams?: boolean
) => BreadcrumbPathProps[]
/**
 * Used as a default if no module specific parser is found
 */
const defaultModuleSegmentsParser: RouteSegmentsParser = (
  segments,
  routeSegments,
  queryClient,
  currentClient,
  disableQueryParams
) => {
  let path = ''

  const breadcrumbPathProps: BreadcrumbPathProps[] = []

  segments.forEach((segment, index) => {
    path += `/${segment}`

    const label = capitalize(segment).replace(/-/g, ' ')

    if (isQuery(routeSegments[index]) && disableQueryParams !== true) {
      log.warn(
        `Breadcrumb label for ${segment} is a dynamic route parameter. Please add a custom parser for this module.`
      )
    }

    breadcrumbPathProps.push({
      label,
      path,
      disabled: disableQueryParams === true && isQuery(routeSegments[index])
    })
  })

  return breadcrumbPathProps
}

const connectorsModuleSegmentsParser: RouteSegmentsParser = (segments, routeSegments, queryClient, currentClient) => {
  const breadcrumbPathProps: BreadcrumbPathProps[] = []

  let path = ''

  segments.forEach((segment, index) => {
    path += `/${segment}`
    let label = capitalize(segment).replace(/-/g, ' ')

    if (routeSegments[index] === '[connector_id]') {
      const connectorId = parseInt(segment)
      let connectorName: string | undefined = (
        queryClient.getQueryData([
          connectorsQueryKeys.clientConnectors,
          currentClient.id,
          connectorId
        ]) as ConnectorResp[]
      )?.[0]?.job

      if (connectorName == null) {
        connectorName = (
          queryClient.getQueryData([connectorsQueryKeys.clientConnectors, currentClient.id]) as ConnectorsResp
        )?.find((connector) => connector.configId === connectorId)?.job
      }

      label = connectorName ?? '...'
    }

    breadcrumbPathProps.push({
      label,
      path
    })
  })

  return breadcrumbPathProps
}

const reportingSolutionsModuleSegmentsParser: RouteSegmentsParser = (
  segments,
  routeSegments,
  queryClient,
  currentClient
) => {
  const breadcrumbPathProps: BreadcrumbPathProps[] = []

  let path = ''

  segments.forEach((segment, index) => {
    path += `/${segment}`
    let label = capitalize(segment).replace(/-/g, ' ')

    if (routeSegments[index] === '[reporting_solution_id]') {
      const reportingSolutionId = parseInt(segment)
      let reportingSolutionName: string | undefined = (
        queryClient.getQueryData([
          reportingSolutionsQueryKeys.reportingSolutionsConfigs,
          currentClient.id,
          reportingSolutionId
        ]) as StandardReportResp
      )?.name

      if (reportingSolutionName == null) {
        reportingSolutionName = (
          queryClient.getQueryData([
            reportingSolutionsQueryKeys.reportingSolutionsConfigs,
            currentClient.id
          ]) as StandardReportsResp
        )?.find((reportingSolution) => reportingSolution.configId === reportingSolutionId)?.name
      }

      label = reportingSolutionName ?? '...'
    }

    breadcrumbPathProps.push({
      label,
      path
    })
  })

  return breadcrumbPathProps
}

const attributionModelsModuleSegmentsParser: RouteSegmentsParser = (
  segments,
  routeSegments,
  queryClient,
  currentClient
) => {
  const breadcrumbPathProps: BreadcrumbPathProps[] = []

  let path = ''

  segments.forEach((segment, index) => {
    path += `/${segment}`
    let label = capitalize(segment).replace(/-/g, ' ')

    if (routeSegments[index] === '[attribution_model_id]') {
      const attributionModelId = parseInt(segment)
      let attributionModelName: string | undefined = (
        queryClient.getQueryData([
          attributionModelQueryKeys.integrationAttribution,
          attributionModelId,
          currentClient.id
        ]) as AttributionResp
      )?.name

      if (attributionModelName == null) {
        attributionModelName = (
          queryClient.getQueryData([
            attributionModelQueryKeys.integrationAttribution,
            currentClient.id
          ]) as AttributionResp[]
        )?.find((attributionModel) => attributionModel.id === attributionModelId)?.name
      }

      label = attributionModelName ?? '...'
    }

    breadcrumbPathProps.push({
      label,
      path,
      disabled: routeSegments[index] === '[attribution_model_id]'
    })
  })

  return breadcrumbPathProps
}

const budgetOptimiserModuleSegmentsParser: RouteSegmentsParser = (
  segments,
  routeSegments,
  queryClient,
  currentClient
) => {
  const breadcrumbPathProps: BreadcrumbPathProps[] = []

  let path = ''

  segments.forEach((segment, index) => {
    path += `/${segment}`
    let label = capitalize(segment).replace(/-/g, ' ')

    if (routeSegments[index] === '[budget_optimiser_model_id]') {
      const budgetOptimiserId = parseInt(segment)
      let budgetOptimiserName: string | undefined = (
        queryClient.getQueryData([
          budgetOptimiserQueryKeys.budgetOptimiser,
          currentClient.id,
          budgetOptimiserId
        ]) as BOIntegratedAttributionResp
      )?.name

      if (budgetOptimiserName == null) {
        budgetOptimiserName = (
          queryClient.getQueryData([
            budgetOptimiserQueryKeys.budgetOptimiser,
            currentClient.id
          ]) as BOIntegratedAttributionResp[]
        )?.find((budgetOptimiser) => budgetOptimiser.id === budgetOptimiserId)?.name
      }

      label = budgetOptimiserName ?? '...'
    }

    breadcrumbPathProps.push({
      label,
      path,
      disabled: routeSegments[index] === '[budget_optimiser_model_id]'
    })
  })

  return breadcrumbPathProps
}

const precisAppsModuleSegmentsParser: RouteSegmentsParser = (segments, routeSegments, queryClient, currentClient) => {
  const breadcrumbPathProps: BreadcrumbPathProps[] = []
  let path = ''

  segments.forEach((segment, index) => {
    path += `/${segment}`

    let label = capitalize(segment).replace(/-/g, ' ')
    if (routeSegments[index] === '[precis_app_id]') {
      let precisAppName: string | undefined = (
        queryClient.getQueryData([precisAppsQueryKeys.precisApp, currentClient.id, segment]) as PrecisAppResp
      )?.title
      if (precisAppName == null) {
        precisAppName = (
          queryClient.getQueryData([precisAppsQueryKeys.precisApps, currentClient.id]) as PrecisAppResp[]
        )?.find((app) => app.id === segment)?.title
      }

      if (precisAppName != null) {
        label = precisAppName
      }
    }

    if (routeSegments[index] === '[tab_id]') {
      return
    }

    breadcrumbPathProps.push({
      label,
      path
    })
  })

  return breadcrumbPathProps
}

const customGroupingModuleSegmentsParser: RouteSegmentsParser = (
  segments,
  routeSegments,
  queryClient,
  currentClient
) => {
  const breadcrumbPathProps: BreadcrumbPathProps[] = []

  let path = ''

  segments.forEach((segment, index) => {
    path += `/${segment}`
    let label = capitalize(segment).replace(/-/g, ' ')

    if (routeSegments[index] === '[custom_grouping_id]') {
      const channelGroupingId = parseInt(segment)
      let channelGroupingName: string | undefined = (
        queryClient.getQueryData([
          channelGroupingQueryKeys.channelGrouping,
          channelGroupingId,
          currentClient.id
        ]) as ChannelGroupingResp
      )?.name

      if (channelGroupingName == null) {
        channelGroupingName = (
          queryClient.getQueryData([
            channelGroupingQueryKeys.channelGroupings,
            channelGroupingId,
            currentClient.id
          ]) as ChannelGroupingResp
        )?.name
      }

      label = channelGroupingName ?? '...'
    }

    if (routeSegments[index] === '[tab_id]' || routeSegments[index] === '[data_source_tab_id]') {
      return
    }

    if (routeSegments.includes('[data_source_tab_id]') && segment === 'data-sources') {
      return
    }

    breadcrumbPathProps.push({
      label:
        routeSegments[index] === '[data_source_id]'
          ? getPlatformDetailsByPlatform(segment as Platforms)?.label ?? label
          : label,
      path
    })
  })

  return breadcrumbPathProps
}

const enhancedSignalsModuleSegmentsParser: RouteSegmentsParser = (
  segments,
  routeSegments,
  queryClient,
  currentClient
) => {
  const breadcrumbPathProps: BreadcrumbPathProps[] = []

  let path = ''

  segments.forEach((segment, index) => {
    path += `/${segment}`
    let label = capitalize(segment).replace(/-/g, ' ')

    if (routeSegments[index] === '[enhanced_signals_id]') {
      const enhancedSignalsId = parseInt(segment)
      let enhancedSignalsName: string | undefined = (
        queryClient.getQueryData([
          enhancedSignalsQueryKeys.enhancedSignalsConfigs,
          enhancedSignalsId,
          currentClient.id
        ]) as EnhancedSignalsResp
      )?.configName

      if (enhancedSignalsName == null) {
        enhancedSignalsName = (
          queryClient.getQueryData([
            enhancedSignalsQueryKeys.enhancedSignalsConfigs,
            currentClient.id
          ]) as EnhancedSignalsResp[]
        )?.find((enhancedSignals) => enhancedSignals.id === enhancedSignalsId)?.configName
      }

      label = enhancedSignalsName ?? '...'
    }

    breadcrumbPathProps.push({
      label,
      path,
      disabled: routeSegments[index] === '[enhanced_signals_id]'
    })
  })

  return breadcrumbPathProps
}

const MODULE_SEGMENTS_PARSERS: Record<string, RouteSegmentsParser> = {
  connectors: connectorsModuleSegmentsParser,
  'reporting-solutions': reportingSolutionsModuleSegmentsParser,
  'attribution-models': attributionModelsModuleSegmentsParser,
  'budget-optimiser': budgetOptimiserModuleSegmentsParser,
  'precis-apps': precisAppsModuleSegmentsParser,
  'custom-groupings': customGroupingModuleSegmentsParser,
  'enhanced-signals': enhancedSignalsModuleSegmentsParser
}
