import React, { useMemo, type ReactElement } from 'react'
import {
  Accordion,
  AccountIcon,
  Avatar,
  Button,
  ChevronDownIcon,
  Divider,
  DropdownItem,
  Grid,
  Tab,
  Tabs,
  TrashIcon,
  Typography
} from '@precis-digital/kurama'
import {
  StyledAccordion,
  StyledCard,
  StyledCardContent,
  StyledFlyoutContainer,
  StyledFlyoutFooter,
  StyledGraySpan,
  StyledTypography,
  StyledTypographyAnnotation
} from './styles'
import Dots from 'shared/components/Loader/Dots'
import { Role } from 'shared/components/Role'
import { type Client, useCurrentClient } from 'shared/context/ClientContext'
import {
  type Member,
  useDeleteMember,
  useQueryMembers,
  useQueryMessages,
  useResendMessage,
  useUpdateMemberRole
} from 'workspace/api'
import { useAuth, type User } from 'shared/context/AuthContext'
import { CurrentUser } from 'workspace/components/CurrentUser'
import { LeaveWorkspaceButton } from './ViewerFlyout'
import { type UseMutateAsyncFunction } from 'react-query'
import { type DeleteMemberReq, type MemberResp } from 'shared/api/clients'
import { type ClientError } from 'shared/api/fetch'
import { capitalize } from 'shared/utils/stringFormatter'
import useExpandedCards from 'shared/hooks/useExpandedCards'
import OverScreen from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import { formatDateTimeToAtFormat } from 'shared/dateFns'
import { makeToastWithLoading } from 'shared/components/Toaster'
import PERMISSIONS from 'shared/constants/permissions'

const AccordionSummary = ({ email, createdAt }: { email: string; createdAt: string }): React.ReactElement => {
  const { t } = useTranslation('workspace')
  return (
    <StyledCardContent>
      <Avatar kind="label" label="J" size="medium" />
      <div>
        <Typography variant="h3">{email}</Typography>
        <StyledTypographyAnnotation variant="body3">
          {t('descriptions.sentOn', { datetime: formatDateTimeToAtFormat(createdAt) })}
        </StyledTypographyAnnotation>
      </div>
    </StyledCardContent>
  )
}
const AccordionDetails = (
  handleCreateMessage: (messageId: string, clientId: string) => void,
  messageId: string,
  clientId: string,
  t: string
): React.ReactNode => {
  return (
    <>
      <Button
        variant="filled"
        onClick={() => {
          handleCreateMessage(messageId, clientId)
        }}
      >
        {t}
      </Button>
    </>
  )
}
interface RemoveButtonProps {
  member: Member
  currentMember: Member
  currentClient: Client
  deleteMember: UseMutateAsyncFunction<MemberResp, ClientError, DeleteMemberReq, unknown>
}
export const RemoveFromWorkspaceButton = (props: RemoveButtonProps): React.ReactElement => {
  const { member, currentClient, deleteMember, currentMember } = props
  const { mutate: updateMember } = useUpdateMemberRole()

  const { t } = useTranslation('workspace')

  const handleRemoveMember = async (): Promise<void> => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    await deleteMember(
      {
        clientId: currentClient.id,
        userId: member.id
      },
      {
        onSuccess() {
          toastOnSuccess(`Successfully removed ${member.name} from this workspace`)
        },
        onError() {
          toastOnError(`An error occuring while removing ${member.name} from this workspace. Please try again.`)
        }
      }
    )
  }
  const handleUpdateMember = (clientId: string, userId: string, role: string): void => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    updateMember(
      { clientId, userId, role },
      {
        onSuccess() {
          toastOnSuccess(`${member.name} assigned as ${role}`)
        },
        onError() {
          toastOnError(`An error occuring while assigning ${member.name} as ${role}. Please try again.`)
        }
      }
    )
  }
  const handleUnauthorized = (): React.ReactElement => {
    return <Typography variant="body3">{t('descriptions.noPermissions')}</Typography>
  }
  return (
    <Role
      authorizedMemberRoles={PERMISSIONS.VIEW_EDITOR_FLYOUT}
      memberRole={member?.role}
      onUnauthorized={handleUnauthorized}
    >
      <Role
        authorizedMemberRoles={PERMISSIONS.ASSIGN_AS_EDITOR_WORKSPACE}
        memberRole={currentMember?.role}
        onUnauthorized={handleUnauthorized}
      >
        <Grid>
          <DropdownItem
            onClick={() => {
              handleUpdateMember(currentClient.id, member.id, 'editor')
            }}
            annotation={t('descriptions.editorDescription')}
          >
            {t('buttons.assignEditor')}
          </DropdownItem>
          <Divider />
          <DropdownItem
            onClick={() => {
              void handleRemoveMember()
            }}
            icon={<TrashIcon />}
          >
            {t('buttons.remove')}
          </DropdownItem>
        </Grid>
      </Role>
    </Role>
  )
}

interface FlyoutProps {
  tab: number
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void
}
const EditorFlyout = (props: FlyoutProps): ReactElement => {
  const { currentClient } = useCurrentClient()
  const { currentUser } = useAuth()
  const { data: members, isLoading: isLoadingMembers, isSuccess: isMembersSuccess } = useQueryMembers(currentClient.id)
  const {
    data: messages,
    isLoading: isLoadingMessages,
    isSuccess: isMessagesSuccess
  } = useQueryMessages(currentClient.id)
  const { tab, handleTabChange } = props
  const [shadow, setShadow] = useExpandedCards()
  const { mutate: resendMessage } = useResendMessage()
  const { mutateAsync: deleteMember } = useDeleteMember()

  const { t } = useTranslation('workspace')

  const handleResendMessage = (messageId: string, clientId: string): void => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    resendMessage(
      { messageId, clientId },
      {
        onSuccess() {
          toastOnSuccess('New member notified')
        },
        onError() {
          toastOnError('An error occuring while notifying the new member. Please try again.')
        }
      }
    )
  }

  const currentMember = members?.find((member) => member.id.toString() === currentUser?.id?.toString())

  const sortedMembers = useMemo(() => {
    return members?.sort((a, b) => a.name.localeCompare(b.name))
  }, [members])

  return (
    <StyledFlyoutContainer>
      <Divider />
      {props.tab === 0 ? (
        <>
          <Typography variant="h3">{t('titles.members')}</Typography>
          {isLoadingMembers ? (
            <Dots />
          ) : isMembersSuccess ? (
            sortedMembers?.map((member, index) => {
              if (member.id === currentUser?.id) {
                return (
                  <StyledCard key={member.id} shadow={shadow[index]} condensedMargin>
                    <Accordion
                      onChange={() => {
                        setShadow(index, !shadow[index])
                      }}
                      Summary={<CardContentWrapper member={member} key={member.id} currentUser={currentUser} />}
                      expandIcon={<ChevronDownIcon />}
                      Details={<LeaveWorkspaceButton />}
                    />
                  </StyledCard>
                )
              } else {
                return (
                  <StyledCard key={member.id} shadow={shadow[index]} condensedMargin>
                    <Accordion
                      onChange={() => {
                        setShadow(index, !shadow[index])
                      }}
                      Summary={<CardContentWrapper member={member} key={member.id} currentUser={currentUser} />}
                      expandIcon={<ChevronDownIcon />}
                      Details={
                        currentMember != null && (
                          <Grid container gap="8px" flexDirection="column">
                            <RemoveFromWorkspaceButton
                              member={member}
                              currentMember={currentMember}
                              currentClient={currentClient}
                              deleteMember={deleteMember}
                            />

                            <DropdownItem
                              onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                void OverScreen.show('profileFlyout', { id: member?.id })
                              }}
                              icon={<AccountIcon />}
                            >
                              {t('buttons.userProfile')}
                            </DropdownItem>
                          </Grid>
                        )
                      }
                    />
                  </StyledCard>
                )
              }
            })
          ) : null}
        </>
      ) : (
        <>
          <Typography variant="h3">{t('titles.pendingInvites')}</Typography>
          {isLoadingMessages ? (
            <Dots />
          ) : isMessagesSuccess ? (
            messages.map((message, index) => {
              if (message.status === 'pending') {
                return (
                  <StyledCard key={message.id} shadow={shadow[index]}>
                    <StyledAccordion
                      onChange={() => {
                        setShadow(index, !shadow[index])
                      }}
                      Summary={<AccordionSummary email={message.toEmail} createdAt={message.createdAt} />}
                      expandIcon={<ChevronDownIcon />}
                      Details={AccordionDetails(
                        handleResendMessage,
                        message.id,
                        message.clientId,
                        t('buttons.notifyPending')
                      )}
                    />
                  </StyledCard>
                )
              }
              return null
            })
          ) : null}
        </>
      )}
      <StyledFlyoutFooter>
        <Divider />
        <Tabs currentTab={tab} handleChange={handleTabChange} variant="fullWidth">
          <Tab value={0}>{t('titles.members')}</Tab>
          <Tab value={1}>{t('buttons.pendingInvites')}</Tab>
        </Tabs>
      </StyledFlyoutFooter>
    </StyledFlyoutContainer>
  )
}
interface StyledCardWrapperProps {
  key?: React.Key
  member: Member
  currentUser?: User
}

const CardContentWrapper = (props: StyledCardWrapperProps): ReactElement => {
  const { member, currentUser } = props
  return (
    <>
      <StyledCardContent>
        <AvatarWithLabel size="medium" user={member} />
        <Grid>
          <Typography variant="h3">
            <CurrentUser memberName={member.name} memberId={member.id} currentUserId={currentUser?.id ?? ''} />
          </Typography>
          <Typography variant="body3">
            <StyledGraySpan>{member.email}</StyledGraySpan>
          </Typography>
        </Grid>
      </StyledCardContent>
      <StyledTypography variant="body2">{capitalize(member.role)}</StyledTypography>
    </>
  )
}

export default EditorFlyout
