import { Grid, Progress, Typography, styled } from '@precis-digital/kurama'
import Image from 'next/image'
import alvieLogoFull from 'public/assets/images/alvie_logo_full.svg'
import piechart3D from 'public/assets/images/piechart_3d.png'
import { type ReactElement } from 'react'
import { useTranslation } from 'shared/translations'

const ProgressBarLoader = (): ReactElement => {
  const { t } = useTranslation('common')
  return (
    <StyledGridPageContainer>
      <StyledGridLogoContainer>
        <Image
          priority
          src={alvieLogoFull}
          alt={t('progressBarLoader.alvieIconAltTag')}
          width={128}
          height={64}
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
      </StyledGridLogoContainer>

      <StyledGridContentContainer>
        <Image
          src={piechart3D}
          priority
          alt="piechart"
          width={540}
          height={232}
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
        <Typography variant="h2">{t('progressBarLoader.header')}</Typography>

        <StyledProgress value={0} variant="indeterminate" />
        <Typography variant="h5">{t('progressBarLoader.subHeader')}</Typography>
        <Typography variant="body2">{t('progressBarLoader.description')}</Typography>
      </StyledGridContentContainer>
    </StyledGridPageContainer>
  )
}

const StyledProgress = styled(Progress)(({ theme }) => ({
  '> span:first-of-type': {
    height: '16px',
    display: 'inline-flex',
    width: '294px',
    marginTop: '24px',
    marginBottom: '16px',
    'span:first-of-type': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

const StyledGridPageContainer = styled(Grid)(() => ({
  padding: '48px 48px 96px 48px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  height: '100vh',
  maxWidth: '1280px',
  margin: 'auto',
  position: 'relative'
}))

const StyledGridLogoContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  height: '64px',
  width: '100%',
  display: 'flex',
  gap: '26px',
  padding: '14px'
}))

const StyledGridContentContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  minWidth: '540px',
  textAlign: 'center',
  padding: '32px 0px',
  '> h2': {
    marginTop: '56px'
  }
}))

export default ProgressBarLoader
