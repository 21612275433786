import React from 'react'
import { PaginationProps, TablePaginationActionsProps } from './types'
import { StyledTablePagination, StyledPaginationActions } from './styles'
import Button from '../../Button'
import { ArrowLeftIcon, ArrowRightIcon } from '../../Icons'

function TablePaginationActions(props: TablePaginationActionsProps): React.ReactElement {
  const { count, page, rowsPerPage, onPageChange, shouldDisableNextButton } = props
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page + 1)
  }

  return (
    <StyledPaginationActions>
      <Button
        onClick={handleBackButtonClick}
        variant="text"
        disabled={page === 0}
        leftIcon={<ArrowLeftIcon />}
        aria-label="previous page"
      />
      <Button
        onClick={handleNextButtonClick}
        variant="text"
        disabled={shouldDisableNextButton ?? page >= Math.ceil(count / rowsPerPage) - 1}
        leftIcon={<ArrowRightIcon />}
        aria-label="next page"
      />
    </StyledPaginationActions>
  )
}

export const TablePagination = ({ shouldDisableNextButton, ...props }: PaginationProps): React.ReactElement => {
  return (
    <StyledTablePagination
      ActionsComponent={(subprops) => (
        <TablePaginationActions {...subprops} shouldDisableNextButton={shouldDisableNextButton} />
      )}
      labelRowsPerPage="Rows per page"
      {...props}
    />
  )
}

export default TablePagination
