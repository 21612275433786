import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryMembers } from 'workspace/api'

export const useIsSoloAdmin = (): boolean => {
  const { currentClient } = useCurrentClient()
  const { currentUser } = useAuth()
  const { data: members = [] } = useQueryMembers(currentClient.id)
  const adminMembers = members?.filter((member) => member?.role === 'admin')

  return adminMembers.length === 1 && currentUser?.email === adminMembers[0].email
}
