import { FormFrame, Grid } from '@precis-digital/kurama'
import Role, { type RoleProps } from './Role'
import { type clients } from 'shared/api'

const DEFAULT_ROLE: [clients.MemberRole] = ['viewer']
interface EditOrCreatePageLayoutProps {
  /**
   * The role configuration to apply to the layout
   */
  roleConfiguration?: Omit<RoleProps, 'children'>
  /**
   * If the form should have a banner at the top
   * Pass a <Banner /> component here
   */
  formBanner?: React.ReactNode
  /**
   * The header of the form
   */
  formHeader: React.ReactNode
  /**
   * The part of the form that contains the fields
   */
  formBody: React.ReactNode[]
  /**
   * The footer of the form
   */
  formFooter?: React.ReactNode
  /**
   * This shows at the right hand side of the layout and can be used to display logos, progress information etc
   */
  formInformation?: React.ReactNode
}

const EditOrCreatePageLayout = ({
  roleConfiguration,
  formBanner,
  formFooter,
  formHeader,
  formBody,
  formInformation
}: EditOrCreatePageLayoutProps): React.ReactElement => {
  const handleUnauthorized =
    roleConfiguration?.onUnauthorized ??
    (() => {
      return <></>
    })

  return (
    <Role
      authorizedMemberRoles={roleConfiguration?.authorizedMemberRoles ?? DEFAULT_ROLE}
      memberRole={roleConfiguration?.memberRole ?? 'viewer'}
      onUnauthorized={handleUnauthorized}
    >
      <Grid container gap="24px">
        {formBanner}
        <FormFrame header={formHeader} content={formBody} information={formInformation} footer={formFooter} />
      </Grid>
    </Role>
  )
}

export default EditOrCreatePageLayout
