import React, { type ReactElement } from 'react'
import { type ListViewCreateNewButtonProps } from 'shared/components/ListView/CreateNewButton'
import { type ListViewFilterDefinition, type ListViewTabsDefinition } from './types'
import Header from './Header'
import { Grid } from '@precis-digital/kurama'
import Tabs from './Tabs'
import Filters from './Filters'
import Table, { type ListViewTableProps } from './Table'
import { useGetControlledFilters, useGetControlledTabs } from './hooks'
import MemberTimestampCell from './Table/TableCells/MemberTimestampCell'
import StatusCell from './Table/TableCells/StatusCell'
import TextCell from './Table/TableCells/TextCell'
import TitleCell from './Table/TableCells/TitleCell'
import TitleWithIconCell from './Table/TableCells/TitleWithIconCell'
import TitleWithAvatarsCell from './Table/TableCells/TitleWithAvatarsCell'
import AvatarCellsWithTooltip from './Table/TableCells/AvatarCellsWithTooltip'

export interface ListViewProps<T extends object> {
  isLoading: boolean
  title: string
  createNewButtonProps?: ListViewCreateNewButtonProps
  tableProps: Omit<ListViewTableProps<T>, 'isLoading' | 'filterValues' | 'controlledFilters' | 'tabsValues'>
  filters?: Array<ListViewFilterDefinition<T>>
  tabs?: ListViewTabsDefinition<T>
  persistFilters?: boolean
}

const ListView = <T extends object>({
  isLoading,
  tableProps,
  filters,
  tabs,
  title,
  createNewButtonProps,
  persistFilters
}: ListViewProps<T>): ReactElement => {
  const { controlledTabs, handleTabChange } = useGetControlledTabs<T>(tabs, tableProps.data)

  const { controlledFilters, filterValues, initialFilterValues, resetFilters } = useGetControlledFilters<T>(
    filters,
    tableProps.data,
    persistFilters
  )

  tableProps.initialState = {
    ...tableProps.initialState,
    filters: Object.entries(initialFilterValues).map(([key, value]) => ({ id: key, value }))
  }

  return (
    <Grid container flexDirection="column" gap="24px">
      <Header title={title} createNewButtonProps={createNewButtonProps} />
      <>
        {controlledTabs != null && (
          <Tabs tabs={controlledTabs} handleTabChange={handleTabChange} data={tableProps.data} isLoading={isLoading} />
        )}
        {controlledFilters != null && controlledFilters.length > 0 && (
          <Filters filters={controlledFilters} isLoading={isLoading} resetFilters={resetFilters} />
        )}
        <Table
          isLoading={isLoading}
          {...tableProps}
          filterValues={filterValues}
          controlledFilters={controlledFilters}
          tabsValues={controlledTabs}
        />
      </>
    </Grid>
  )
}

export default ListView

export {
  MemberTimestampCell,
  StatusCell,
  TextCell,
  TitleCell,
  TitleWithAvatarsCell,
  AvatarCellsWithTooltip,
  TitleWithIconCell
}
