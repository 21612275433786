import { Grid, SingleSelectDropdown, Typography, styled, defaultTheme as theme } from '@precis-digital/kurama'
import Filter, { type Option, type ActiveFilter } from 'shared/components/Filter'
import StatusWithBadge from 'shared/components/StatusWithBadge'
import { type ErrorCodeType, type ConfigStatus, type ErrorDetails } from 'shared/configStatuses'
import { useTranslation } from 'shared/translations'
import { type TimePeriodType } from './StartPage'
import FilterListItemLabel from 'shared/components/Filter/ListItemLabel'
import MoreMenuDropdown, { type MoreMenuDropdownProps } from './MoreMenuDropDown'
import { OPERATOR_OPTIONS } from 'home/constants'
import { type StandardReportResp } from 'shared/api/standardReports'
import { checkIfReportTemplateWithNoConversionEvents } from './utils'

interface ToolbarProps {
  selectedStandardReportConfig: StandardReportResp | undefined
  statusInfo: {
    selectedReportStatus: ConfigStatus | undefined
    selectedReportErrorCode?: ErrorCodeType | null
    selectedReportErrorDetails?: ErrorDetails | null
    selectedReportStatusLastUpdated?: string | null
    selectedReportStatusMessage?: string | null
  }
  filterProps: {
    canFilter: boolean
    selectedFilters: Array<ActiveFilter<'market' | 'channel'>>
    setSelectedFilters: React.Dispatch<React.SetStateAction<Array<ActiveFilter<'market' | 'channel'>>>>
    selectedDate: {
      startDate: string
      endDate: string
    }
    changeDateRange: (startDate: string | null, endDate: string | null) => void
    handleApplyDateRangeClick: () => void
    setUserSelectedPeriodType: (periodType: TimePeriodType) => void
    parameterOptions: Option[]
    additionalCustomControls?: React.ReactNode
    userSelectedPeriodType: TimePeriodType
  }
  moreMenuProps: MoreMenuDropdownProps
}

const Toolbar = ({
  statusInfo,
  selectedStandardReportConfig,
  filterProps: {
    canFilter,
    selectedFilters,
    setSelectedFilters,
    selectedDate,
    changeDateRange,
    handleApplyDateRangeClick,
    setUserSelectedPeriodType,
    userSelectedPeriodType,
    parameterOptions,
    additionalCustomControls
  },
  moreMenuProps: {
    canSwitchReports,
    disableConversionEvents,
    handleSwitchReports,
    handleSelectTopCardMetrics,
    handleSelectConversionEvents,
    topCardMetricsCount,
    conversionEventsCount
  }
}: ToolbarProps): React.ReactElement => {
  const { t } = useTranslation('home')

  const isReportTemplateWithNoConversionEvents =
    checkIfReportTemplateWithNoConversionEvents(selectedStandardReportConfig)

  return (
    <Grid container gap={theme.spacing(1)} direction="row" alignItems="center">
      <Grid xs display="flex" flexDirection="row" gap={theme.spacing(1)} alignItems="center" minWidth={0}>
        {statusInfo.selectedReportStatus != null && (
          <StyledStatusWithBadge
            status={statusInfo.selectedReportStatus}
            typographyVariant="h5"
            lastUpdated={statusInfo.selectedReportStatusLastUpdated ?? ''}
            errorCode={statusInfo.selectedReportErrorCode}
            errorDetails={statusInfo.selectedReportErrorDetails}
            dagViewInformation={{
              dagViewType: 'reportingSolution',
              configId: selectedStandardReportConfig?.configId ?? 0,
              configName: selectedStandardReportConfig?.name ?? '',
              isActive: selectedStandardReportConfig?.runSchedule ?? false,
              isPageWithConfigId: true
            }}
          />
        )}
        {canFilter && (
          <Filter
            t={t}
            activeFilters={selectedFilters}
            onUpdateFilters={setSelectedFilters}
            DateRangeCalendarProps={{
              defaultStartDate: selectedDate.startDate,
              defaultEndDate: selectedDate.endDate,
              onDateRangeSelected: changeDateRange,
              onApplyDateRangeClick: handleApplyDateRangeClick,
              renderCalendarInfo: (defaultCalendarInfo) => (
                <Grid display="flex" justifyContent="space-between" alignItems="center">
                  <Grid display="flex" alignItems="center" gap="8px">
                    <Typography variant="body3">{t('compareWith')}</Typography>
                    <Grid>
                      <SingleSelectDropdown
                        disablePortal
                        onSelect={(value) => {
                          setUserSelectedPeriodType(value as TimePeriodType)
                        }}
                        value={userSelectedPeriodType}
                        title={t('compareWith')}
                        options={[
                          {
                            value: 'previous_period',
                            label: t('previousPeriod')
                          },
                          {
                            value: 'previous_year',
                            label: t('previousYear')
                          }
                        ]}
                      />
                    </Grid>
                  </Grid>

                  {defaultCalendarInfo}
                </Grid>
              )
            }}
            parseFilterToChipLabel={(filter) => {
              const symbol = OPERATOR_OPTIONS[filter.operator].label?.toLowerCase()

              let value: string = ''
              if (Array.isArray(filter.data)) {
                value = filter.data.map((item) => item.label ?? item.value).join(', ')
              } else {
                value = typeof filter.data === 'string' ? filter.data : filter.data.label
              }
              return `${filter.parameter.label} ${symbol} ${value?.toString() ?? ''}`
            }}
            renderListItemLabel={(filter) => {
              return <FilterListItemLabel filter={filter} includeText={t('filter.include')} />
            }}
            parameterOptions={parameterOptions}
            operatorOptions={OPERATOR_OPTIONS}
            additionalCustomControls={additionalCustomControls}
          />
        )}
      </Grid>
      <Grid>
        <MoreMenuDropdown
          canSwitchReports={canSwitchReports}
          handleSwitchReports={handleSwitchReports}
          handleSelectTopCardMetrics={handleSelectTopCardMetrics}
          disableConversionEvents={disableConversionEvents || isReportTemplateWithNoConversionEvents}
          handleSelectConversionEvents={
            !isReportTemplateWithNoConversionEvents
              ? () => {
                  handleSelectConversionEvents?.()
                }
              : undefined
          }
          topCardMetricsCount={topCardMetricsCount}
          conversionEventsCount={conversionEventsCount}
          {...(isReportTemplateWithNoConversionEvents && {
            disableConversionEventsReason: t('settingOnlyAvailableForReportType')
          })}
        />
      </Grid>
    </Grid>
  )
}

const StyledStatusWithBadge = styled(StatusWithBadge)(() => ({
  cursor: 'pointer'
}))

export default Toolbar
