import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'search_synergies_report',
  active: true,
  label: 'Search Synergies Report',
  category: 'Search',
  description: 'Find cross-channel insights by leveraging search-data from both Google Search Console and Google Ads.',
  image_url: '',
  authors: [
    {
      name: 'Emil Wallgren',
      email: 'emil@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a/AAcHTtdTMs-VKvbs9sHVo2ZqCt-9rw7mrcDS9j5HgmItuH7Mww=s96-c-rg-br100'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2750644229/Search+Synergies+Report',
    link_description: 'Knowledge Hub: Search Synergies Report'
  },
  dashboard: {
    template_report_id: '7d2766be-d906-4060-8e61-41b957b372c1',
    report_name_template: 'copy of Search Synergies Report - {today}',
    datasources: [
      {
        alias: 'ds72',
        datasourceName: 'copy of search_term_opportunities',
        type: 'TABLE',
        tableId: 'search_synergies_report__search_term_opportunities_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'ds121',
        datasourceName: 'copy of paid_organic_search_term_performance',
        type: 'TABLE',
        tableId: 'search_synergies_report__paid_organic_search_term_performance_gads_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'ds137',
        datasourceName: 'copy of cost_table',
        type: 'TABLE',
        tableId: 'search_synergies_report__cost_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'ds182',
        datasourceName: 'copy of lpe_table',
        type: 'TABLE',
        tableId: 'search_synergies_report__landing_page_experience_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      }
    ]
  },
  sources: {
    gads: {
      reports: ['search_term_report', 'keyword_performance', 'landing_page', 'paid_organic_search_term_performance']
    },
    google_search_console: {
      reports: ['page_query_all_dimensions', 'query_all_dimensions']
    }
  },
  inputs: {
    gads: [
      {
        id: 'gadsMarket',
        type: 'case_statement',
        label: 'Custom Market Grouping',
        helperText: 'Determine market by the Google Ads account ID',
        required: true,
        mutable: true,
        dimensions: [
          {
            value: 'account_id',
            label: 'Account ID',
            type: 'string'
          },
          {
            value: 'campaign__name',
            label: 'Campaign',
            type: 'string'
          }
        ],
        defaultValue: "CASE WHEN (LOWER(account_id) = '12345678') THEN 'Sweden' ELSE 'Other' END"
      },
      {
        id: 'campaignNameFilter',
        type: 'string',
        label: 'Campaign Name Filter',
        helperText:
          'Filter the report to only include campaigns with names containing this string. Leave empty to include all campaigns.',
        required: false,
        mutable: true,
        defaultValue: ''
      }
    ],
    google_search_console: [
      {
        id: 'gscMarket',
        type: 'case_statement',
        label: 'Custom Market Grouping',
        helperText:
          "Determine market by the Google Search Console account ID. Use lower case and remove any dot and 'http://' characters. For example, if the account ID is http://www.domain.se then use wwwdomainse.",
        required: true,
        mutable: true,
        dimensions: [
          {
            value: 'account_id',
            label: 'Account ID',
            type: 'string'
          },
          {
            value: 'country',
            label: 'Country',
            type: 'string'
          }
        ],
        defaultValue:
          "CASE WHEN (LOWER(account_id) LIKE '%wwwdomainse%') OR (LOWER(country) = 'sweden') THEN 'Sweden' ELSE 'Other' END"
      }
    ],
    other: [
      {
        id: 'targetCurrency',
        type: 'dropdown',
        options: [
          {
            value: 'SEK',
            label: 'Swedish Kronor (SEK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197564.png'
          },
          {
            value: 'DKK',
            label: 'Danish Kroner (DKK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197565.png'
          },
          {
            value: 'NOK',
            label: 'Norwegian Kroner (NOK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197579.png'
          },
          {
            value: 'EUR',
            label: 'Euros (EUR)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197615.png'
          },
          {
            value: 'USD',
            label: 'US Dollar (USD)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/412/412828.png'
          },
          {
            value: 'GBP',
            label: 'GBP Pound (GBP)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/555/555417.png'
          }
        ],
        label: 'Target Currency',
        defaultValue: 'EUR',
        helperText: 'The currency to transform all currency-based columns (cost, revenue, etc) to.',
        required: true,
        mutable: true
      }
    ]
  }
}

export default config
