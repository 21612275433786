import { Grid, styled, Typography, defaultTheme as theme } from '@precis-digital/kurama'
import {
  STATUSES,
  type ConfigStatus,
  defaultErrorCode,
  type ErrorDetails,
  type ErrorCodeType
} from 'shared/configStatuses'
import { useTranslation } from 'shared/translations'

interface StatusTooltipBodyProps {
  errorCode?: ErrorCodeType | null
  status: ConfigStatus
  hasLinkToDagView: boolean
  errorDetails: ErrorDetails | null
  openDagView?: () => void
}

const StatusTooltipBody: React.FC<StatusTooltipBodyProps> = ({
  status,
  hasLinkToDagView = false,
  errorCode,
  errorDetails,
  openDagView
}): React.ReactElement => {
  const { t } = useTranslation('common')
  const getMessage = (): string => {
    switch (status) {
      case STATUSES.error:
        return t(`errors.${errorCode ?? defaultErrorCode}`, errorDetails?.translationPlaceholders ?? undefined)
      case STATUSES.disconnected:
        return t('errors.ERR100')
      case STATUSES.running:
        return t('configIsRunning')
      case STATUSES.paused:
        return t('dagView.pausedConfigMessage')
      default:
        return ''
    }
  }

  const STATUS_BODY_MAP: Partial<Record<ConfigStatus, React.ReactElement>> = {
    [STATUSES.paused]: <Typography variant="body2">{getMessage()}</Typography>,
    [STATUSES.error]: (
      <Grid sx={{ cursor: hasLinkToDagView ? 'pointer' : 'default' }} onClick={openDagView}>
        <TextContainer>
          <ErrorTypography>
            <strong>{t('weFoundAnError')}</strong>
            <span>{getMessage()}</span>
          </ErrorTypography>
        </TextContainer>
        <Grid>{hasLinkToDagView && <Typography variant="body2">{t('clickToViewDetails')}</Typography>}</Grid>
      </Grid>
    ),
    [STATUSES.disconnected]: (
      <TextContainer>
        <ErrorTypography variant="body2">
          <strong>{t('weFoundAnError')}</strong>
          <span>{getMessage()}</span>{' '}
        </ErrorTypography>
      </TextContainer>
    ),
    [STATUSES.unknown]: (
      <TextContainer>
        <Typography variant="body2">
          <strong>{t('unknown')}</strong>
        </Typography>
        <Typography variant="body2">{t('unknownStatusMessage')}</Typography>
      </TextContainer>
    ),
    [STATUSES.running]: (
      <Grid sx={{ cursor: hasLinkToDagView ? 'pointer' : 'default' }} onClick={openDagView}>
        <TextContainer>
          <Typography variant="body2">
            <strong>{t('currentlyRunning')}</strong>
          </Typography>
          <Typography variant="body2">{getMessage()}</Typography>
        </TextContainer>
        {hasLinkToDagView && (
          <Grid>
            <Typography variant="body2">{t('clickToViewDetails')}</Typography>
          </Grid>
        )}
      </Grid>
    ),
    [STATUSES.active]: (
      <Grid sx={{ cursor: hasLinkToDagView ? 'pointer' : 'default' }} onClick={openDagView}>
        <Typography variant="body2">{t('everythingIsRunningSmoothly')}</Typography>
        {hasLinkToDagView && (
          <Grid>
            <Typography variant="body2">{t('clickToViewDetails')}</Typography>
          </Grid>
        )}
      </Grid>
    ),
    [STATUSES.connected]: <Typography variant="body2">{t('everythingIsRunningSmoothly')}</Typography>
  }

  const tooltipBody = STATUS_BODY_MAP[status]

  return tooltipBody != null ? <Grid width="100%">{tooltipBody}</Grid> : <></>
}

const ErrorTypography = styled(Typography)(() => ({
  color: theme.palette.semantic.error90,
  fontSize: '14px',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  span: {
    display: 'block'
  }
}))

const TextContainer = styled(Grid)(() => ({
  backgroundColor: `${theme.palette.neutrals.white0}0D`,
  padding: '12px',
  borderRadius: theme.borderRadius.small,
  marginBottom: '16px'
}))

export default StatusTooltipBody
