import React from 'react'
import Avatar from '../Avatar/Avatar'
import { ChipProps } from './types'
import { CancelBkgIcon } from '../Icons'
import { StyledChip } from './styles'

const Chip = ({
  label,
  disabled = false,
  backgroundColor,
  avatarSrc,
  onClick,
  onDelete,
  avatarBgColor,
  avatar,
  color,
  ...props
}: ChipProps): React.ReactElement => {
  const hasDelete = onDelete != null
  const hasClick = onClick != null

  return (
    <StyledChip
      label={label}
      disabled={disabled}
      backgroundColor={backgroundColor}
      textColor={color}
      onClick={onClick != null ? onClick : undefined}
      onDelete={hasDelete ? onDelete : undefined}
      deleteIcon={<CancelBkgIcon />}
      avatar={
        avatar != null ? (
          avatar
        ) : avatarSrc != null ? (
          <Avatar kind="image" imageUrl={avatarSrc} size="small" backgroundColor={avatarBgColor} />
        ) : null
      }
      hasDelete={hasDelete}
      hasClick={hasClick}
      {...props}
    />
  )
}

export default Chip
