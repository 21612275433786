import React from 'react'

export const Trash = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M6 8L7.33346 17.4205C7.54291 18.9001 8.80944 20 10.3039 20H13.6961C15.1906 20 16.4571 18.9001 16.6665 17.4205L18 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M14.1066 6.7604C14.1066 7.31269 14.5543 7.7604 15.1066 7.7604C15.6589 7.7604 16.1066 7.31269 16.1066 6.7604H14.1066ZM7.89362 6.7604C7.89362 7.31269 8.34133 7.7604 8.89362 7.7604C9.4459 7.7604 9.89362 7.31269 9.89362 6.7604H7.89362ZM11.512 5H12.4882V3H11.512V5ZM12.4882 5C13.3329 5 14.1066 5.73773 14.1066 6.7604H16.1066C16.1066 4.73402 14.5357 3 12.4882 3V5ZM9.89362 6.7604C9.89362 5.73773 10.6673 5 11.512 5V3C9.4645 3 7.89362 4.73402 7.89362 6.7604H9.89362Z"
        fill="currentColor"
      />
      <path d="M4 7.85547H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
})
