import React, {
  useState,
  useEffect,
  createContext,
  type ReactNode,
  useContext,
  type SetStateAction,
  type Dispatch,
  useCallback
} from 'react'
import { noop, getFromStore, saveToStore, removeToStore } from 'shared/utils'
import { useAuth } from 'shared/context/AuthContext'
import { useQueryClients } from 'workspace/api'
import { equals, head, not, pick, pipe } from 'ramda'
import { type FeaturePlan, type ClientsResp } from 'shared/api/clients'
import { useQueryString, useReload, useChangePath } from 'shared/components/Router'
import { CLIENT_ID_QUERY_PARAM, APP_ROUTES } from 'shared/routes'
import { client as queryClient } from 'shared/reactQuery'
import { DEFAULT_CURRENCY_CODE, type CurrencyCodes } from 'shared/constants/currency'
import { DEFAULT_FEATURE_PLAN } from 'shared/constants/featurePlans'

export interface BaseClient {
  id: string
  name: string
  url?: string
  avatarUrl?: string
  currency: CurrencyCodes
}
export interface Client extends BaseClient {
  featurePlan: FeaturePlan
}

export interface Clients extends Array<Client> {}

export interface IClientContext {
  currentClient: Client
  changeCurrentClient: (client: Partial<Client>) => Promise<void>
  clearCachedClient: () => void
  isLoading: boolean
  isValidClient: boolean
  isSuccess: boolean
  error?: Error
}

export const NoClient = {
  name: '',
  url: '',
  id: '',
  avatarUrl: '',
  currency: DEFAULT_CURRENCY_CODE,
  featurePlan: DEFAULT_FEATURE_PLAN
}

const defaultValue: IClientContext = {
  currentClient: NoClient,
  changeCurrentClient: async () => {
    noop()
  },
  clearCachedClient: noop,
  isValidClient: false,
  isLoading: true,
  isSuccess: false
}

interface Props {
  children: ReactNode
}

const ClientContext = createContext<IClientContext>(defaultValue)

export const getRootPath = (path: string): string => {
  const parts = path.split('/')
  const firstPart = parts.length > 1 ? parts[1] : ''
  return `/${firstPart}`
}

const getFirstAlphabiticalOrderedClient = (clients: Client[]): Client | undefined =>
  head(clients.sort((curr: Client, next: Client) => curr.name.localeCompare(next.name))) as Client

const getIndexOfClient = ({
  clients = [],
  currentClient
}: {
  clients: ClientsResp | undefined
  currentClient: Client
}): number => clients.findIndex((client) => client.id === currentClient.id)

export const saveClientToStore = (currentClient: BaseClient | undefined): void => {
  if (currentClient != null) {
    saveToStore('clients', JSON.stringify(currentClient))
  } else {
    removeToStore('clients')
  }
}

const changeCurrentClient =
  (setCurrentClient: Dispatch<SetStateAction<Client>>) =>
  ({ client, currentClient }: { client: Partial<Client>; currentClient: Client }): Client => {
    const newClient = {
      ...(client as Client),
      ...(client?.name != null ? { name: client.name } : {})
    }

    setCurrentClient(newClient)
    saveClientToStore(pick(['id', 'name', 'url', 'avatarUrl', 'currency'], newClient))
    return newClient
  }

export const useCurrentClient = (): IClientContext => useContext<IClientContext>(ClientContext)

const INCORRECT_CLIENT_ID_IN_QUERYSTRINGERROR = new Error('Incorrect client id in query string')

export const ClientProvider = (props: Props): React.ReactElement => {
  const [error, setError] = useState<Error | undefined>(undefined)
  const { isSuccess: isAuthSuccess } = useAuth()
  const reload = useReload()
  const { pathname, changePath } = useChangePath()
  const { pushQuery } = useQueryString()
  const {
    query: { clientId: queryClientId }
  } = useQueryString()

  const redirectToWorkspaceList = useCallback((): void => {
    void changePath(APP_ROUTES.workspace.listPage)
  }, [changePath])

  const getDefaultCurrentClient = (): Client => {
    if (queryClientId != null) {
      return {
        ...defaultValue.currentClient,
        id: queryClientId as string
      }
    }
    if (typeof window !== 'undefined' && getFromStore('clients') !== null) {
      return JSON.parse(getFromStore('clients') as string)
    }

    return defaultValue.currentClient
  }

  const [currentClient, setCurrentClient] = useState<Client>(getDefaultCurrentClient)

  const isValidClient =
    currentClient.id !== defaultValue.currentClient.id &&
    currentClient.name !== defaultValue.currentClient.name &&
    error == null

  const canFetchAllClients = isAuthSuccess
  const {
    data: clients = [],
    isLoading: isLoadingAllClients,
    isSuccess: isClientsSuccess
  } = useQueryClients(canFetchAllClients)

  const clearCachedClient = useCallback(() => {
    saveClientToStore(undefined)
    setCurrentClient(defaultValue.currentClient)
  }, [])

  const changeClientAndQuery = useCallback(
    async ({ client, currentClient }: { client: Partial<Client>; currentClient: Client }): Promise<void> => {
      const query = {
        [CLIENT_ID_QUERY_PARAM]: client.id?.toString()
      }
      await changePath(getRootPath(pathname), query)
      queryClient().clear()

      reload()
    },
    [changePath, reload, pathname]
  )

  useEffect(() => {
    const hasInvalidClientAndNoQueryClientId = queryClientId == null && isClientsSuccess && not(isValidClient)

    void (async function changeClient() {
      if (hasInvalidClientAndNoQueryClientId) {
        redirectToWorkspaceList()
      }
    })()
  }, [
    clients,
    isClientsSuccess,
    isValidClient,
    currentClient,
    queryClientId,
    changeClientAndQuery,
    redirectToWorkspaceList
  ])

  useEffect(() => {
    // If the query string does not have a client id, and the current client is not valid, and there are clients, then change the current client to a new client
    void (async function changeClient() {
      const isNotActiveClient = pipe(() => ({ clients, currentClient }), getIndexOfClient, equals(-1))
      const possibleNewClient = getFirstAlphabiticalOrderedClient(clients as Client[])

      if (
        queryClientId == null &&
        isValidClient &&
        isNotActiveClient() &&
        clients.length > 0 &&
        possibleNewClient != null
      ) {
        await changeClientAndQuery({
          client: possibleNewClient,
          currentClient
        })
      }

      if (queryClientId == null && isValidClient) {
        await pushQuery({
          [CLIENT_ID_QUERY_PARAM]: currentClient.id?.toString()
        })
      }
    })()
  }, [clients, isValidClient, currentClient, queryClientId, changeClientAndQuery, pushQuery])

  useEffect(() => {
    const hasQueryClientIdNotMatchingCurrentClientId = queryClientId != null && isClientsSuccess && not(isValidClient)

    if (hasQueryClientIdNotMatchingCurrentClientId) {
      const client = clients.find((client) => client.id.toString() === queryClientId.toString())
      client != null
        ? changeCurrentClient(setCurrentClient)({ client, currentClient })
        : setError(INCORRECT_CLIENT_ID_IN_QUERYSTRINGERROR)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClientId, clients, isClientsSuccess, currentClient])

  useEffect(() => {
    const clientWithFullDetails = clients.find((client) => client.id.toString() === currentClient.id.toString())

    const hasClientWithMissingDetails =
      clientWithFullDetails !== undefined && !equals(clientWithFullDetails, currentClient)

    if (hasClientWithMissingDetails) {
      changeCurrentClient(setCurrentClient)({ client: clientWithFullDetails, currentClient })
    }
  }, [clients, currentClient])

  const isSuccess = isClientsSuccess
  const isLoading = isLoadingAllClients

  return (
    <ClientContext.Provider
      value={{
        changeCurrentClient: async (client): Promise<void> => {
          await changeClientAndQuery({ client, currentClient })
        },
        clearCachedClient,
        isSuccess,
        isLoading,
        currentClient,
        isValidClient,
        error
      }}
    >
      {props.children}
    </ClientContext.Provider>
  )
}
export default ClientContext
