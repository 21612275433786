import { type ListViewCellProps } from '../../types'

import React, { type ReactElement } from 'react'
import BaseCell from './BaseCell'
import { Grid, Typography, styled } from '@precis-digital/kurama'
import { textAlignToFlexJustify } from '../../utils'

interface TextCellProps<T extends object> extends ListViewCellProps<T> {
  text: string
  leftAdornment?: ReactElement
  annotation?: string
}

const TextCell = <T extends object>({ text, leftAdornment, annotation, ...props }: TextCellProps<T>): ReactElement => {
  return (
    <BaseCell width={props.column.parsedWidth} align={props.column.align ?? 'center'}>
      <Grid container flexDirection="column" overflow="hidden">
        <Grid container gap="4px" justifyContent={textAlignToFlexJustify(props.column.align)} flexWrap="nowrap">
          {leftAdornment}
          <StyledTypographyText variant="body2" title={text}>
            {text}
          </StyledTypographyText>
        </Grid>
        {annotation != null && (
          <Grid container gap="4px" justifyContent={textAlignToFlexJustify(props.column.align)} flexWrap="nowrap">
            <StyledTypographyAnnotation variant="body3" title={annotation}>
              {annotation}
            </StyledTypographyAnnotation>
          </Grid>
        )}
      </Grid>
    </BaseCell>
  )
}

const StyledTypographyText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineClamp: 1
}))

const StyledTypographyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export default TextCell
