import React from 'react'
import { AVATAR_CLASS_NAME } from '../classNames'
import { StyledImage, StyledAvatar, StyledLabel } from './styles'
import { AvatarProps } from './types'

export function convertLabel(label: string): string {
  if (label.length > 1) {
    return label.substring(0, 1)
  }
  return label
}

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(({ size, kind, ...props }, ref): React.ReactElement => {
  return (
    <StyledAvatar size={size ?? 'medium'} kind={kind} className={AVATAR_CLASS_NAME} {...props} ref={ref}>
      {kind === 'label' && <StyledLabel>{'label' in props ? convertLabel(props.label) : ''}</StyledLabel>}
      {kind === 'image' && (
        <StyledImage
          src={'imageUrl' in props ? props.imageUrl : ''}
          objectFit={'objectFit' in props ? props.objectFit : 'fill'}
        />
      )}
      {kind === 'number' && <StyledLabel kind={kind}>{'number' in props ? `${props?.number}` : ''}</StyledLabel>}
    </StyledAvatar>
  )
})

export default Avatar
