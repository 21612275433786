import { Grid, GrowthIcon, Typography, styled } from '@precis-digital/kurama'

export const ChannelWithIcon = ({ value }: { value: string }): React.ReactElement => {
  return (
    <Grid container flexWrap="nowrap" alignItems="center" gap="8px">
      <StyledIconContainer item>
        <GrowthIcon />
      </StyledIconContainer>
      <Grid item xs={11}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  )
}

export const Channel = ({ value }: { value: string }): React.ReactElement => {
  return (
    <Grid container flexWrap="nowrap" alignItems="center" gap="8px">
      <Grid item xs={12}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  )
}

const StyledIconContainer = styled(Grid)(({ theme }) => ({
  width: '32px',
  height: '32px',
  backgroundColor: theme.palette.neutrals.stone100,
  borderRadius: '15px',
  textAlign: 'center',
  padding: '2px'
}))
export default Channel
