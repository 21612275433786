import { type ClientAccountsResp, type Platforms } from 'shared/api/accounts'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

import { defaultTheme as theme } from '@precis-digital/kurama'
import { PLATFORM } from 'dataSource'
import { type Client } from 'shared/context/ClientContext'
import { rules } from 'recommendations/constants'
import {
  type GroupedCard,
  type Account,
  type MutationFunction,
  type SortByTypes,
  type SeverityTypes,
  type PopupTableData,
  type RecommendationsRuleMetadata,
  type RecommendationsFullRule,
  type RuleKey,
  type ColumnProps
} from 'recommendations/types'
import type { FeedResp, FeedPlatforms, Filter, AnalyticsReqOrderBy } from 'shared/api/analytics'
import { objectKeys, snakeCaseToTitleCase } from 'shared/utils'
import { type Column } from 'react-table'
import { renderCampaignDetailsCell } from 'recommendations/components/RecommendationsPopup/RecommendationsPopup'

export const getCardMetadataFromTranslations = (
  t: (key: string, options?: Record<string, unknown> | undefined) => string,
  ruleId: string
): RecommendationsFullRule => {
  const ruleMetadata: RecommendationsRuleMetadata = rules[ruleId]
  const ruleKeys: RuleKey[] = [
    'Title',
    'Recommendation',
    'Documentation',
    'category',
    'MetricName',
    'AffectedEntityType'
  ]

  const initialTranslations: Record<RuleKey, string> = {
    Title: '',
    Recommendation: '',
    Documentation: '',
    category: '',
    MetricName: '',
    AffectedEntityType: ''
  }
  const translations = ruleKeys.reduce((acc, key) => {
    acc[key] = t(`rules.${ruleId}.${key}` as any)
    return acc
  }, initialTranslations)

  const severity = ruleMetadata.severity as unknown

  return {
    RuleId: ruleId,
    Platform: ruleMetadata.Platform,
    Title: translations.Title,
    Recommendation: translations.Recommendation,
    Documentation: translations.Documentation,
    severity: severity as number,
    category: translations.category,
    MetricName: translations.MetricName,
    AffectedEntityType: translations.AffectedEntityType,
    DefaultColumns: ruleMetadata.DefaultColumns
  }
}

const groupCards = (cards: FeedResp[], dataSources: ClientAccountsResp[] | undefined): GroupedCard[] => {
  const groupedData = cards.reduce<GroupedCard[]>((acc, card) => {
    const key = `${card.title}_${card.platform}`
    const existingCard = acc.find((existingCard) => existingCard.key === key)

    if (card.jsonPayload !== null) {
      if (existingCard == null) {
        const newCard = {
          key,
          title: card.title,
          messageId: card.messageId,
          category: parseCategory(card.tags),
          platform: card.platform,
          message: card.message,
          timestamp: card.timestamp,
          severity: parseSeverity(card.tags),
          sourceService: card.sourceService,
          ruleId: card.jsonPayload?.ruleId,
          accounts: [] as Account[]
        }

        const account = getAccount(card.tags, dataSources)

        if (account != null) {
          account.campaigns = card.jsonPayload
          newCard.accounts.push(account)
        }
        acc.push(newCard)
      } else {
        const account = getAccount(card.tags, dataSources)

        if (existingCard.ruleId === null) {
          existingCard.ruleId = card.jsonPayload?.ruleId
        }

        const hasAlreadyAddedAccount: boolean = (existingCard as { accounts: Account[] }).accounts.some(
          (acc) => acc.id === (account?.id ?? '')
        )
        if (account !== undefined && !hasAlreadyAddedAccount) {
          account.campaigns = card.jsonPayload
          existingCard.accounts.push(account)
        }
      }
    }

    return acc
  }, [])

  return groupedData
}

const getAccount = (tags: string[], dataSources: ClientAccountsResp[] | undefined): Account | undefined => {
  const accountId = getAccountId(tags)
  const dataSource = dataSources?.find((dataSource) => dataSource.externalAccountId === accountId)

  if (dataSource != null) {
    const dataSourceMeta = getPlatformDetailsByPlatform(dataSource.platform)
    return {
      id: accountId ?? '',
      name: dataSource.name ?? accountId,
      iconUrl: dataSourceMeta?.iconUrl ?? '',
      campaigns: { data: [], ruleId: '' }
    }
  }

  return undefined
}

const parseSeverity = (tags: string[]): number => {
  const severityTag = tags.find((tag) => tag.includes('severity'))
  return severityTag != null ? parseInt(severityTag.split(':')[1], 10) : 1
}

const parseCategory = (tags: string[]): string => {
  const categoryTag = tags.find((tag) => tag.includes('category'))
  const category = categoryTag != null ? categoryTag.split(':')[1].trim() : ''

  return snakeCaseToTitleCase(category)
}

const criticalStyle = {
  backgroundColor: theme.palette.semantic.error05,
  label: 'Critical',
  color: theme.palette.semantic.error100
}

const warningStyle = {
  backgroundColor: theme.palette.semantic.warning05,
  label: 'Warning',
  color: theme.palette.semantic.warning100
}

const infoStyle = {
  backgroundColor: theme.palette.semantic.info05,
  label: 'Info',
  color: theme.palette.semantic.info100
}

const severityStyles: Record<number, { backgroundColor: string; label: string; color: string; icon?: string }> = {
  5: criticalStyle,
  4: warningStyle,
  3: warningStyle,
  2: warningStyle,
  1: infoStyle
}

const getSeverityChipStyles = (severity: number): Record<string, any> => {
  return severity !== null ? severityStyles[severity] : {}
}

const criticalTooltipContent = {
  title: 'Critical Alert',
  body: 'This is a critical recommendation that requires immediate attention.'
}
const warningTooltipContent = {
  title: 'Warning',
  body: 'This recommendation has a medium severity level and should be reviewed.'
}
const infoTooltipContent = {
  title: 'Information',
  body: 'This is an informational recommendation that can help you follow Precis Digital best practices.'
}

const severityContentMap: Record<number, { title: string; body: string }> = {
  5: criticalTooltipContent,
  4: warningTooltipContent,
  3: warningTooltipContent,
  2: warningTooltipContent,
  1: infoTooltipContent
}

const getSeverityTooltipContent = (severity: number): { title: string; body: string } => {
  const content = severityContentMap[severity]

  if (content !== undefined) {
    return content
  } else {
    return {
      title: 'Unknown Severity',
      body: 'No information available for this severity level.'
    }
  }
}
const convertPlatformName = (platform: FeedPlatforms): Platforms => {
  if (platform === 'google_ads') {
    return PLATFORM.GOOGLE
  }
  if (platform === 'bing') {
    return PLATFORM.MICROSOFT_ADVERTISING
  }
  return platform
}

const getAccountId = (tags: string[]): string | undefined => {
  return tags
    .find((tag) => {
      return tag.includes('account')
    })
    ?.split(':')[1]
    .replace(' ', '')
}

const getFeed =
  (
    post: MutationFunction,
    options?: {
      filters?: Filter[]
      orderBy?: AnalyticsReqOrderBy
    }
  ) =>
  (currentClient: Client): void => {
    const defaultOptions: { filters?: Filter[]; orderBy?: AnalyticsReqOrderBy } = {
      orderBy: { fields: ['timestamp'], direction: 'DESC' }
    }
    post({
      collection: 'event_feed',
      report: 'event_feed',
      clientId: currentClient?.id,
      ...defaultOptions,
      ...(options != null && {
        ...(options.filters != null && options.filters.length > 0 && { filters: options.filters }),
        ...(options.orderBy != null && { orderBy: options.orderBy })
      })
    })
  }

const isMessageTruncated = (message: string, maxCharacters: number): boolean => {
  return message.length > maxCharacters
}

const truncateMessage = (message: string, maxLength: number): string => {
  return message.length > maxLength ? message.substring(0, maxLength).trim() + '...' : message
}

const sortByTimestampAndSeverity = (a: GroupedCard, b: GroupedCard): number => {
  const aDate = new Date(a.timestamp).toISOString().split('T')[0]
  const bDate = new Date(b.timestamp).toISOString().split('T')[0]

  if (aDate < bDate) {
    return 1
  } else if (aDate > bDate) {
    return -1
  } else {
    return b.severity - a.severity
  }
}

export const getSortByQuery = (sortBy: SortByTypes): AnalyticsReqOrderBy => {
  switch (sortBy) {
    case 'date_DESC':
      return { fields: ['timestamp'], direction: 'DESC' }
    case 'date_ASC':
      return { fields: ['timestamp'], direction: 'ASC' }
    default:
      return { fields: ['timestamp'], direction: 'DESC' }
  }
}

export const getFilterByQuery = (selectedPlatform: Record<FeedPlatforms, boolean>): Filter[] => {
  const shouldFilterByPlatform = Object.values(selectedPlatform).some((isSelected) => isSelected)
  if (shouldFilterByPlatform) {
    return [
      {
        field: 'platform',
        operator: 'IN',
        value: objectKeys(selectedPlatform).filter((key) => selectedPlatform[key])
      }
    ]
  }
  return []
}

export const filterBySeverity = (
  groupedData: GroupedCard[],
  severity: Record<SeverityTypes, boolean>
): GroupedCard[] => {
  return groupedData.filter((card) => {
    if (!severity.critical && !severity.warning && !severity.info) {
      return card
    }
    if (
      (severity.critical && card.severity === 5) ||
      (severity.warning && card.severity >= 2 && severity.warning && card.severity <= 4) ||
      (severity.info && card.severity === 1)
    ) {
      return card
    }
    return null
  })
}
export {
  sortByTimestampAndSeverity,
  truncateMessage,
  isMessageTruncated,
  groupCards,
  getAccount,
  parseSeverity,
  parseCategory,
  getSeverityChipStyles,
  getSeverityTooltipContent,
  convertPlatformName,
  getFeed
}

export const getTableDataFromCard = (
  cardData: GroupedCard,
  cardMetaData: RecommendationsRuleMetadata
): PopupTableData[] => {
  const tableData: PopupTableData[] = []
  cardData.accounts.forEach((account: Account) => {
    if (account.campaigns !== null) {
      account.campaigns.data.forEach((campaign) => {
        const { name, id, metric, ...campaignDetails } = campaign

        const row = {
          account: {
            name: account.name,
            id: account.id
          },
          iconUrl: account.iconUrl,
          campaignDetails
        }
        tableData.push(row)
      })
    } else {
      const row = {
        account: {
          name: account.name,
          id: account.id
        },
        iconUrl: account.iconUrl,
        campaignDetails: null
      }
      tableData.push(row)
    }
  })

  return tableData
}

export const getAccountOccurences = (tableData: PopupTableData[]): Record<string, number> => {
  const initialAcc: Record<string, number> = {}
  return tableData.reduce((acc, campaign) => {
    if (typeof acc[campaign.account.name] === 'undefined' || Number.isNaN(acc[campaign.account.name])) {
      acc[campaign.account.name] = 0
    }
    acc[campaign.account.name] += 1
    return acc
  }, initialAcc)
}

export const isDuplicatedColumn = (returnColumns: Array<Column<ColumnProps>>, formattedHeader: string): boolean => {
  return returnColumns.some((column) => column.Header === formattedHeader)
}

export const generateMetricColumn = (
  key: string,
  formattedHeader: string
): { Header: string; accessor: string; Cell: ({ row }: { row: Record<string, any> }) => React.ReactElement } => {
  return {
    Header: formattedHeader,
    accessor: `campaignDetails.${key}`,
    Cell: ({ row }: { row: Record<string, any> }): React.ReactElement => {
      return renderCampaignDetailsCell({ row, key })
    }
  }
}
