import { styled } from '../../utils'
import Typography from '../Typography'
import { LabelColor } from './types'
import { Theme, CSSObject } from '@mui/material/styles'

export const StyledLabelText = styled(Typography)({
  minHeight: '24px'
})

export const StyledLabelContainer = styled('div')(({ color, theme }: { color: LabelColor; theme?: Theme }): any => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  alignSelf: 'start', // prevents component from stretching...
  padding: '8px 16px',
  borderRadius: theme?.borderRadius.medium,
  ...(['success', 'green'].includes(color) && {
    ...StyledGreenLabel(theme)
  }),
  ...(['warning', 'orange'].includes(color) && {
    ...StyledOrangeLabel(theme)
  }),
  ...(['info', 'blue'].includes(color) && {
    ...StyledBlueLabel(theme)
  }),
  ...(['error', 'red'].includes(color) && {
    ...StyledRedLabel(theme)
  })
}))

const StyledGreenLabel = (theme?: Theme): CSSObject => ({
  background: theme?.palette.semantic.success05,
  color: theme?.palette.semantic.success100
})

const StyledOrangeLabel = (theme?: Theme): CSSObject => ({
  background: theme?.palette.semantic.warning05,
  color: theme?.palette.semantic.warning100
})

const StyledBlueLabel = (theme?: Theme): CSSObject => ({
  background: theme?.palette.semantic.info05,
  color: theme?.palette.semantic.info100
})

const StyledRedLabel = (theme?: Theme): CSSObject => ({
  background: theme?.palette.semantic.error05,
  color: theme?.palette.semantic.error100
})
