import React, { useMemo, type ReactElement } from 'react'
import { MemberTimestampCell, StatusCell, TextCell, TitleCell } from 'shared/components/ListView'
import { type ListViewCellProps, type ListViewColumnDefinition } from 'shared/components/ListView/types'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { useTranslation } from 'shared/translations'
import { useQueryMember } from 'workspace/api'
import MoreMenuDropDown from './MoreMenuDropDown'
import { type StandardReportResp, type StandardReportsResp } from 'shared/api/standardReports'
import {
  useQueryReportingSolutionConfigsById,
  useQueryReportingSolutionsConfigs,
  useQueryReportingSolutionsConfigsStatusById,
  useQueryReportingSolutionsConfigsStatuses
} from 'reportingSolution/api'
import { useQueryClientAccountPlatform, useQueryClientAccounts } from 'dataSource/api'
import { type ConfigStatusResp } from 'shared/api/analytics'
import { getReportTemplateById } from 'reportingSolution/utils'
import {
  getStatusFromAPIStatusData,
  type ConfigStatus,
  ORDERED_TAB_STATUSES,
  getErrorCodeDetails
} from 'shared/configStatuses'
import { useQueryString } from 'shared/components/Router'
import useDagView from 'shared/hooks/useDagView'
import { REPORTING_SOLUTIONS_DOCUMENTATION_LINK } from 'shared/constants/links'
import { APP_ROUTES } from 'shared/routes'
import { useMember } from 'workspace'
import { platformsSupportedReporting } from 'shared/components/Prerequisite/constants'
import { type Platforms } from 'shared/api/accounts'
import { hasAccountsWithSupportedPlatforms, hasPreRequisites } from 'shared/components/Prerequisite/utils'
import TableRowLink from 'shared/components/ListView/Table/TableRowLink'
import { type RowLinkProps } from 'shared/components/ListView/Table'
import Start from 'shared/components/Start'

export interface ReportingSolutionConfigWithStatus extends StandardReportResp {
  status: ConfigStatusResp | undefined
  statusEnum: ConfigStatus
}

const mergeConfigsWithStatus = (
  configs?: StandardReportsResp,
  statuses?: ConfigStatusResp[]
): ReportingSolutionConfigWithStatus[] | null => {
  if (configs == null || statuses == null) {
    return null
  }

  const statusMap: Record<string, ConfigStatusResp> = {}

  statuses.forEach((status) => {
    const configId = status.configId
    statusMap[configId] = status
  })

  return configs
    .filter((config) => {
      const reportTemplate = getReportTemplateById(config.templateId)
      return reportTemplate?.active
    })
    .map((config) => {
      return {
        ...config,
        status: statusMap[config.configId.toString()],
        statusEnum: getStatusFromAPIStatusData(config.runSchedule, statusMap[config.configId.toString()]?.status)
      }
    })
}

const ReportingSolutionsRowLink = ({
  row,
  children
}: RowLinkProps<ReportingSolutionConfigWithStatus>): ReactElement => {
  return (
    <TableRowLink href={APP_ROUTES.reportingSolutions.editConfigPage({ configId: row.configId.toString() })}>
      {children}
    </TableRowLink>
  )
}

export const StartPage = (): ReactElement => {
  const { t } = useTranslation('reportingSolutions')
  const { t: tCommon } = useTranslation('common')
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()
  const { query } = useQueryString()
  const { showDagView, shouldShowDagView, handleConfigError } = useDagView('reportingSolution')

  const { data: member, isLoading: isMemberLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const hasEditorAccess = useHasAccess('editor', member?.role ?? 'viewer')

  const { data: reportingSolutionsConfigs, isLoading: isLoadingReportingSolutionsConfigs } =
    useQueryReportingSolutionsConfigs(currentClient.id)
  const { data: reportingSolutionsStatuses, isLoading: isLoadingReportingSolutionsStatuses } =
    useQueryReportingSolutionsConfigsStatuses(currentClient.id)
  const { data: clientAccounts, isLoading: isLoadingClientAccounts } = useQueryClientAccounts(currentClient.id)

  const platformGCP: Platforms = 'gcp'
  const { data: clientAccountGCP, isLoading: isLoadingGCP } = useQueryClientAccountPlatform(
    currentClient.id,
    platformGCP
  )

  const { role: memberRole } = useMember()

  const accounts = hasAccountsWithSupportedPlatforms(clientAccounts, platformsSupportedReporting)

  const { hasAllPreRequisites, hasDataSourceAccount, hasGcpAccount } = hasPreRequisites({
    gcpAccount: clientAccountGCP,
    accounts
  })

  const configId = Number(query?.configId)

  const shouldShowDagViewPopup = shouldShowDagView(query)

  const {
    data: reportingSolutionConfig,
    isSuccess: isReportingSolutionsConfigSuccess,
    isError: isReportingSolutionError,
    error
  } = useQueryReportingSolutionConfigsById(currentClient.id, configId, shouldShowDagViewPopup)

  const { data: reportingSolutionStatus } = useQueryReportingSolutionsConfigsStatusById(
    currentClient.id,
    configId,
    shouldShowDagViewPopup
  )

  if (isReportingSolutionsConfigSuccess && reportingSolutionConfig != null && reportingSolutionStatus != null) {
    showDagView({
      configId: reportingSolutionConfig.configId,
      configName: reportingSolutionConfig.name,
      isActive: reportingSolutionConfig.runSchedule,
      configStatus: getStatusFromAPIStatusData(
        reportingSolutionConfig.runSchedule,
        reportingSolutionStatus?.[0]?.status
      )
    })
  }

  if (isReportingSolutionError) {
    handleConfigError({
      statusCode: error.status_code,
      notFoundMessage: tCommon('notifications.configAccessError'),
      unexpectedErrorMessage: tCommon('notifications.configUnexpectedError')
    })
  }

  const parsedConfigs = useMemo(() => {
    return mergeConfigsWithStatus(reportingSolutionsConfigs, reportingSolutionsStatuses ?? [])
  }, [reportingSolutionsConfigs, reportingSolutionsStatuses])

  const isLoadingData = isLoadingReportingSolutionsConfigs || isMemberLoading

  const isLoadingPreRequisites = isLoadingClientAccounts || isLoadingGCP

  const columns: Array<ListViewColumnDefinition<ReportingSolutionConfigWithStatus>> = useMemo(
    () => [
      {
        Header: t('listView.headings.config'),
        accessor: 'name',
        Cell: (props: ListViewCellProps<ReportingSolutionConfigWithStatus>) => (
          <TitleCell title={props.row.original.name} subTitle={props.row.original.configId.toString()} {...props} />
        )
      },
      {
        Header: t('listView.headings.report'),
        accessor: 'templateId',
        Cell: (props: ListViewCellProps<ReportingSolutionConfigWithStatus>) => {
          const reportTemplate = getReportTemplateById(props.row.original.templateId)
          const textString = reportTemplate?.label ?? props.row.original.templateId
          return (
            <TextCell
              text={textString}
              {...props}
              {...(reportTemplate != null && { annotation: props.row.original.templateId })}
            />
          )
        },
        align: 'center'
      },
      {
        Header: t('listView.headings.updatedAt'),
        accessor: 'updatedAt',
        Cell: (props: ListViewCellProps<ReportingSolutionConfigWithStatus>) => (
          <MemberTimestampCell {...props} timestamp={props.row.original.updatedAt * 1000} />
        ),
        align: 'center'
      },
      {
        Header: t('listView.headings.status'),
        accessor: 'statusEnum',
        Cell: (props: ListViewCellProps<ReportingSolutionConfigWithStatus>) => {
          const dagViewInfo = {
            dagViewType: 'reportingSolution' as const,
            configId: props.row.original.configId,
            configName: props.row.original.name,
            isActive: props.row.original.runSchedule,
            isPageWithConfigId: false
          }
          return (
            <StatusCell
              {...props}
              isLoadingStatus={isLoadingReportingSolutionsStatuses}
              status={props.row.original.statusEnum ?? 'unknown'}
              lastUpdated={props.row.original.status?.lastUpdated}
              errorCode={props.row.original.status?.errorCode}
              errorDetails={getErrorCodeDetails(
                props.row.original.status?.errorCode,
                props.row.original.status?.errorMessage,
                props.row.original.targetProjectId,
                tCommon('dagView.reportingSolution')
              )}
              dagViewInformation={dagViewInfo}
            />
          )
        },
        align: 'right',
        width: '120px'
      }
    ],
    [t, tCommon, isLoadingReportingSolutionsStatuses]
  )

  return (
    <Start
      hasAllPreRequisites={hasAllPreRequisites}
      noPreRequisitesProps={{
        memberRole,
        hasDataSourceAccount,
        hasGcpAccount,
        t
      }}
      emptyStateProps={{
        title: t('emptyState.header'),
        subtitle1: t('emptyState.description'),
        subtitle2: t('emptyState.description2'),
        educationalBlockProps: {
          title: t('emptyState.educationalBlock.title'),
          description: t('emptyState.educationalBlock.description'),
          documentationLinkHref: REPORTING_SOLUTIONS_DOCUMENTATION_LINK,
          documentationLinkText: t('emptyState.educationalBlock.linkText')
        },
        createNewButtonProps: {
          label: t('listView.createNew'),
          hasEditorAccess,
          href: APP_ROUTES.reportingSolutions.createConfigPage
        }
      }}
      listViewProps={{
        isLoading: isLoadingData,
        title: t('titles.reportingSolutions'),
        createNewButtonProps: {
          label: t('listView.createNew'),
          isLoading: isMemberLoading,
          hasEditorAccess,
          isLoadingPreRequisites,
          href: APP_ROUTES.reportingSolutions.createConfigPage
        },
        tabs: {
          accessor: 'statusEnum',
          orderedOptions: ORDERED_TAB_STATUSES,
          allOptionLabel: t('listView.tabs.allStatuses'),
          otherOptionLabel: t('listView.tabs.other')
        },
        tableProps: {
          data: parsedConfigs ?? [],
          columns,
          includePagination: true,
          initialState: {
            pageIndex: 0,
            sortBy: [{ id: 'updatedAt', desc: true }]
          },
          RowLink: ReportingSolutionsRowLink,
          renderMoreMenu: (row: ReportingSolutionConfigWithStatus, closeMenu: () => void) => {
            return (
              <MoreMenuDropDown
                selectedConfig={row}
                accountProjectId={
                  clientAccounts?.find((account) => account.externalAccountId === row.targetProjectId)?.id
                }
                hasEditorAccess={hasEditorAccess}
                closeMenu={closeMenu}
              />
            )
          }
        }
      }}
    />
  )
}

export default StartPage
