import { DatePicker, SelectInput, Input } from '@precis-digital/kurama'
import type { DeepPartial, FieldPath } from 'react-hook-form'
import { type ReportFormData } from '.'
import CaseStatementBuilder from '../CaseStatementBuilder'

export const SCHEDULE_OPTIONS = [
  {
    value: 'daily',
    label: 'Daily'
  },
  {
    value: 'weekly',
    label: 'Weekly'
  },
  {
    value: 'monthly',
    label: 'Monthly'
  }
]

export const INPUTS_FIELD_TYPES = {
  string: Input,
  dropdown: SelectInput,
  date: DatePicker,
  integer: Input,
  cut_off_date: DatePicker,
  case_statement: CaseStatementBuilder,
  json_object_array: Input
}

/**
 * The keys in these objects are the names of the fields in the respective report files found
 * here: ui/apps/me/reportingSolution/constants/reportTemplates
 */

const OTHER_SETTINGS_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  targetCurrency: 'otherSettings.targetCurrency',
  lookbackWindow: 'otherSettings.lookbackWindow',
  ga4MigrationDate: 'otherSettings.ga4MigrationDate',
  holidayRegion: 'otherSettings.holidayRegion',
  gadsConvActions: 'otherSettings.gadsConvActions',
  ga4Events: 'otherSettings.ga4Events',
  bingConvActions: 'otherSettings.bingConvActions',
  facebookConvActions: 'otherSettings.facebookConvActions',
  dv360ConvActions: 'otherSettings.dv360ConvActions',
  audienceGrouping: 'otherSettings.audienceGrouping',
  adConceptGrouping: 'otherSettings.adConceptGrouping',
  adFormatGrouping: 'otherSettings.adFormatGrouping',
  testGrouping: 'otherSettings.testGrouping',
  conversionGrouping: 'otherSettings.conversionGrouping',
  funnelGrouping: 'otherSettings.funnelGrouping',
  customCampaignType: 'otherSettings.customCampaignType',
  customCampaignGrouping: 'otherSettings.customCampaignGrouping',
  customGrouping1: 'otherSettings.customGrouping1',
  customGrouping2: 'otherSettings.customGrouping2',
  customGrouping3: 'otherSettings.customGrouping3',
  customGrouping4: 'otherSettings.customGrouping4'
}

const GOOGLE_ANALYTICS_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  brandIdentifier: 'dataSourceSettings.google_analytics.brandIdentifier',
  customChannelGrouping: 'dataSourceSettings.google_analytics.customChannelGrouping',
  googleAnalyticsCustomChannelGrouping: 'dataSourceSettings.google_analytics.googleAnalyticsCustomChannelGrouping',
  googleAnalyticsMarketChannelGrouping: 'dataSourceSettings.google_analytics.googleAnalyticsMarketChannelGrouping',
  marketChannelGrouping: 'dataSourceSettings.google_analytics.marketChannelGrouping'
}

const GA4_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  ga4CustomChannelGrouping: 'dataSourceSettings.ga4.ga4CustomChannelGrouping',
  ga4MarketChannelGrouping: 'dataSourceSettings.ga4.ga4MarketChannelGrouping',
  pmmMarketSql: 'dataSourceSettings.ga4.pmmMarketSql'
}

const GADS_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  gadsCustomChannelGrouping: 'dataSourceSettings.gads.gadsCustomChannelGrouping',
  gadsMarketChannelGrouping: 'dataSourceSettings.gads.gadsMarketChannelGrouping',
  gadsMarket: 'dataSourceSettings.gads.gadsMarket',
  campaignNameFilter: 'dataSourceSettings.gads.campaignNameFilter',
  conversionNameFilterGads: 'dataSourceSettings.gads.conversionNameFilterGads'
}

const BING_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  bingCustomChannelGrouping: 'dataSourceSettings.bing.bingCustomChannelGrouping',
  bingMarketChannelGrouping: 'dataSourceSettings.bing.bingMarketChannelGrouping'
}

const FACEBOOK_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  facebookCustomChannelGrouping: 'dataSourceSettings.facebook.facebookCustomChannelGrouping',
  facebookMarketChannelGrouping: 'dataSourceSettings.facebook.facebookMarketChannelGrouping',
  facebookCustomMarketGrouping: 'dataSourceSettings.facebook.facebookCustomMarketGrouping',
  conversionNameFilterMeta: 'dataSourceSettings.facebook.conversionNameFilterMeta'
}

const DV360_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  dv360CustomChannelGrouping: 'dataSourceSettings.dv360.dv360CustomChannelGrouping',
  dv360MarketChannelGrouping: 'dataSourceSettings.dv360.dv360MarketChannelGrouping',
  dv360CustomMarketGrouping: 'dataSourceSettings.dv360.dv360CustomMarketGrouping',
  conversionNameFilterDv360: 'dataSourceSettings.dv360.conversionNameFilterDv360'
}

const TIKTOK_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  tiktokCustomChannelGrouping: 'dataSourceSettings.tiktok.tiktokCustomChannelGrouping',
  tiktokMarketChannelGrouping: 'dataSourceSettings.tiktok.tiktokMarketChannelGrouping',
  conversionNameFilterTikTok: 'dataSourceSettings.tiktok.conversionNameFilterTikTok'
}

const SNAPCHAT_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  snapchatCustomChannelGrouping: 'dataSourceSettings.snapchat.snapchatCustomChannelGrouping',
  snapchatMarketChannelGrouping: 'dataSourceSettings.snapchat.snapchatMarketChannelGrouping'
}

const LINKEDIN_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  linkedinCustomChannelGrouping: 'dataSourceSettings.linkedin.linkedinCustomChannelGrouping',
  linkedinMarketChannelGrouping: 'dataSourceSettings.linkedin.linkedinMarketChannelGrouping'
}

const GOOGLE_SEARCH_CONSOLE_INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  gscMarket: 'dataSourceSettings.google_search_console.gscMarket'
}

export const INPUTS_FIELD: Record<string, FieldPath<ReportFormData>> = {
  ...OTHER_SETTINGS_INPUTS_FIELD,
  ...GOOGLE_ANALYTICS_INPUTS_FIELD,
  ...GA4_INPUTS_FIELD,
  ...GADS_INPUTS_FIELD,
  ...BING_INPUTS_FIELD,
  ...FACEBOOK_INPUTS_FIELD,
  ...DV360_INPUTS_FIELD,
  ...TIKTOK_INPUTS_FIELD,
  ...SNAPCHAT_INPUTS_FIELD,
  ...LINKEDIN_INPUTS_FIELD,
  ...GOOGLE_SEARCH_CONSOLE_INPUTS_FIELD
}

export const DEFAULT_FORM_VALUES: DeepPartial<ReportFormData> = {
  dataTarget: {
    advancedSettings: {
      schedule: 'daily'
    }
  },
  isPremium: false
}
