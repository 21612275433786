import { type DataSourcesMap } from 'channelGrouping/types'
import { PLATFORMS } from 'dataSource'
import { useQueryClientAccounts } from 'dataSource/api'
import { useMemo } from 'react'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'
import { useCurrentClient } from 'shared/context/ClientContext'

const useGetDataSourcesMap = (channelGroupingResp: ChannelGroupingResp | undefined): DataSourcesMap | undefined => {
  const { currentClient } = useCurrentClient()
  const { data: clientAccounts } = useQueryClientAccounts(currentClient.id)
  const dataSourcesMap = useMemo(() => {
    if (channelGroupingResp == null || clientAccounts == null) {
      return
    }
    const dataSourcesMap: DataSourcesMap = {}
    PLATFORMS.forEach((dataSource) => {
      if (dataSource.isSupportedByChannelGrouping) {
        dataSourcesMap[dataSource.platform] = {
          platform: dataSource.platform,
          label: dataSource.label,
          iconUrl: dataSource.iconUrl,
          connectedDataSources: clientAccounts.filter((account) => account.platform === dataSource.platform),
          channels: channelGroupingResp.channelGrouping[dataSource.platform] ?? []
        }
      }
    })

    return dataSourcesMap
  }, [channelGroupingResp, clientAccounts])

  return dataSourcesMap
}

export default useGetDataSourcesMap
