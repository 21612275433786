import React, { type ComponentType } from 'react'
import { useHasInternalUserFeatureAccess } from 'shared/hooks/useHasFeatureAccess'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import { useAuth } from 'shared/context/AuthContext'

/**
 * ```ts
 * const MyPage = withInternalFeatureAccessCheck(MyPage)
 * ```
 *
 * When you wrap your pages in this HOC and an already authenticated user visits the page
 * they will be shown the page if they have internal access to the page or shown the NotFound(404) page.
 *
 */

export type WithInternalFeatureAccessCheck = <P extends object>(Component: ComponentType<P>) => React.FC<P>

const withInternalFeatureAccessCheck: WithInternalFeatureAccessCheck = (Component) => {
  return function WithInternalFeatureAccessCheck(props): JSX.Element {
    const { isSuccess } = useAuth()

    const { checkUserAccessToInternalFeature } = useHasInternalUserFeatureAccess()

    if (isSuccess) {
      const userAccess = checkUserAccessToInternalFeature({ route: window.location.pathname })
      return userAccess?.hasAccess === false ? <NotFoundPage {...props} /> : <Component {...props} />
    }
    return <Component {...props} />
  }
}

export default withInternalFeatureAccessCheck
