import { type ReactElement, createContext, useContext } from 'react'
import { type ChannelGroupingDataSourceProviderProps, type ChannelGroupingDataSourceContextProps } from './types'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import { useCurrentChannelGrouping } from './ChannelGroupingContext'
import { DEFAULT_MAIN_FORM_VIEW_TAB } from 'channelGrouping/constants'
import { DATA_SOURCE_DIMENSIONS } from 'channelGrouping/constants/dimensions'
import { DATA_SOURCE_METRICS, DEFAULT_METRIC } from 'channelGrouping/constants/metrics'
import { useMetricsSelectionState } from 'channelGrouping/hooks/useMetricsSelectionState'
import { getDataSourceMetric, getNewChannelId } from 'channelGrouping/utils'
import { type ChannelGrouping } from 'shared/api/channelGroupings'
import { type Platforms } from 'shared/api/accounts'
import useGetDataSourceMetricValues from 'channelGrouping/hooks/useGetDataSourceMetricValues'
import { APP_ROUTES } from 'shared/routes'

const ChannelGroupingDataSourceContext = createContext<ChannelGroupingDataSourceContextProps | undefined>(undefined)

export const useCurrentChannelGroupingDataSource = (): ChannelGroupingDataSourceContextProps => {
  const channelGroupingDataSourceContext = useContext(ChannelGroupingDataSourceContext)
  if (channelGroupingDataSourceContext == null) {
    throw new Error('No channelGroupingDataSourceContext.Provider found when calling channelGroupingDataSourceContext.')
  }
  return channelGroupingDataSourceContext
}

export const ChannelGroupingDataSourceProvider = ({
  dataSource,
  children
}: ChannelGroupingDataSourceProviderProps): ReactElement => {
  const {
    channelGroupingId,
    currentChannelGrouping,
    channelsMap,
    dataSourcesMap,
    updateChannelsInSingleDataSource,
    updateChannelGrouping
  } = useCurrentChannelGrouping()
  const dataSourceItem = dataSourcesMap[dataSource]

  const dataSourceDimensions = DATA_SOURCE_DIMENSIONS[dataSource]
  const dataSourceMetrics = DATA_SOURCE_METRICS[dataSource]
  const { setSelectedMetric: lStorageSetSelectedMetric, selectedMetric } = useMetricsSelectionState(dataSource)

  const setSelectedMetric = (metricId: string): void => {
    lStorageSetSelectedMetric(getDataSourceMetric(dataSource, metricId) ?? DEFAULT_METRIC, dataSource)
  }

  const shouldFetchPerformanceData =
    (dataSourceItem?.channels?.length ?? 0) > 0 && (dataSourceItem?.connectedDataSources.length ?? 0) > 0

  const {
    isLoading: isPerformanceDataLoading,
    isError: isPerformanceDataError,
    ...performanceData
  } = useGetDataSourceMetricValues(
    channelGroupingId,
    dataSource,
    dataSourceItem?.connectedDataSources.map((dataSource) => dataSource.externalAccountId),
    shouldFetchPerformanceData
  )

  const dataSourceChannels = dataSourceItem?.channels ?? []

  const changeChannelPosition: ChannelGroupingDataSourceContextProps['changeChannelPosition'] = (
    channelId,
    newIndex
  ) => {
    const channel = dataSourceChannels.find((channelItem) => channelItem.id === channelId)
    if (channel == null) {
      return
    }

    const oldIndex = dataSourceChannels.map((channelItem) => channelItem.id).indexOf(channel.id)
    const newChannels = [...dataSourceChannels]
    newChannels.splice(oldIndex, 1)
    newChannels.splice(newIndex, 0, channel)
    updateChannelsInSingleDataSource(dataSource, newChannels)
  }

  const updateSingleChannelInDataSource: ChannelGroupingDataSourceContextProps['updateSingleChannelInDataSource'] = (
    channelId,
    newFields,
    queryOptions
  ) => {
    const channel = dataSourceChannels.find((channelItem) => channelItem.id === channelId)
    if (channel == null) {
      return
    }

    const newChannels = [...dataSourceChannels]
    const newChannel = {
      ...channel,
      ...newFields
    }

    newChannels[newChannels.indexOf(channel)] = newChannel
    updateChannelsInSingleDataSource(dataSource, newChannels, queryOptions)
  }

  const deleteSingleChannel: ChannelGroupingDataSourceContextProps['deleteSingleChannel'] = (
    channelId,
    queryOptions
  ) => {
    const channelIndex = dataSourceChannels.findIndex((channelItem) => channelItem.id === channelId)
    if (channelIndex === -1) {
      return
    }

    const newChannels = [...dataSourceChannels]
    newChannels.splice(channelIndex, 1)
    updateChannelsInSingleDataSource(dataSource, newChannels, queryOptions)
  }

  const createNewChannel: ChannelGroupingDataSourceContextProps['createNewChannel'] = (newChannel, queryOptions) => {
    const existingChannelWithSameName = Object.values(channelsMap).find((channel) => channel.name === newChannel.name)

    const newChannels = [...dataSourceChannels]
    newChannels.push({
      ...newChannel,
      id: getNewChannelId(currentChannelGrouping.channelGrouping, newChannel.name)
    })

    if (existingChannelWithSameName != null && existingChannelWithSameName.color !== newChannel.color) {
      const newChannelGrouping: ChannelGrouping = { ...currentChannelGrouping.channelGrouping }

      Object.entries(newChannelGrouping).forEach(([dataSource, channels]) => {
        channels.forEach((channel, index) => {
          if (channel.id === newChannel.id) {
            // @ts-expect-error - This is safe because we've know the channel exists, because we're iterating over the source object
            newChannelGrouping[dataSource as Platforms][index].color = newChannel.color
          }
        })
      })

      newChannelGrouping[dataSource] = newChannels

      updateChannelGrouping({ channelGrouping: newChannelGrouping }, queryOptions)
    } else {
      updateChannelsInSingleDataSource(dataSource, newChannels, queryOptions)
    }
  }

  if (dataSourceItem == null || dataSourceMetrics == null || dataSourceDimensions == null) {
    return (
      <NotFoundPage
        returnPath={`${APP_ROUTES.customGroupings.editConfigPage({
          channelGroupingId: channelGroupingId.toString()
        })}/${DEFAULT_MAIN_FORM_VIEW_TAB}`}
        hasDashboardFrame
      />
    )
  }

  return (
    <ChannelGroupingDataSourceContext.Provider
      value={{
        dataSource,
        dataSourceItem,
        dataSourceDimensions,
        dataSourceMetrics,
        selectedMetric,
        setSelectedMetric,
        changeChannelPosition,
        createNewChannel,
        updateSingleChannelInDataSource,
        deleteSingleChannel,
        performanceData,
        isPerformanceDataLoading,
        isPerformanceDataError,
        shouldFetchPerformanceData
      }}
    >
      {children}
    </ChannelGroupingDataSourceContext.Provider>
  )
}
