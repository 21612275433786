import {
  Avatar,
  Badge,
  DateRangeCalendar,
  Grid,
  MultiSelectDropdown,
  type MultiSelectDropdownItem,
  Typography
} from '@precis-digital/kurama'
import React, { useMemo, type ReactElement, useEffect } from 'react'
import { useTranslation } from 'shared/translations'
import InspectResultsTable from './InspectResultsTable'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import { formatDateToString } from 'shared/dateFns'
import { subDays, subMonths } from 'date-fns'
import { DEFAULT_INSPECT_RESULTS_TABLE_DIMENSIONS } from 'channelGrouping/constants/dimensions'
import { DEFAULT_INSPECT_RESULTS_TABLE_METRICS } from 'channelGrouping/constants/metrics'
import { makeErrorToast } from 'shared/components/Toaster'
import { useSessionStorageState } from 'shared/hooks/useSessionStorageState'
import { OTHER_CHANNEL_COLOR, OTHER_CHANNEL_NAME } from 'channelGrouping/constants'

export interface SelectedDates {
  startDate: string
  endDate: string
}

const InspectResultsTab = (): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const { dataSourceDimensions, dataSourceMetrics, dataSourceItem } = useCurrentChannelGroupingDataSource()
  const defaultSelectedColumns = [
    ...DEFAULT_INSPECT_RESULTS_TABLE_DIMENSIONS[dataSourceItem.platform],
    ...DEFAULT_INSPECT_RESULTS_TABLE_METRICS[dataSourceItem.platform]
  ]

  const [selectedColumns, setSelectedColumns] = useSessionStorageState(
    `inspectResults${dataSourceItem.platform}SelectedColumns`,
    defaultSelectedColumns
  )
  const [localSelectedColumns, setLocalSelectedColumns] = React.useState<string[]>(
    selectedColumns ?? defaultSelectedColumns
  )

  const [filteredChannels, setFilteredChannels] = useSessionStorageState<string[]>(
    `inspectResults${dataSourceItem.platform}FilteredChannels`,
    []
  )
  const [localFilteredChannels, setLocalFilteredChannels] = React.useState<string[]>(filteredChannels ?? [])

  const defaultEndDate = formatDateToString({ date: subDays(new Date(), 1), targetFormat: 'yyyy-MM-dd' })
  const defaultStartDate = formatDateToString({ date: subMonths(new Date(), 1), targetFormat: 'yyyy-MM-dd' })
  const [selectedDates, setSelectedDates] = useSessionStorageState<SelectedDates>(`inspectResultsSelectedDates`, {
    startDate: defaultStartDate,
    endDate: defaultEndDate
  })

  const columnOptions = useMemo(() => {
    const dimensionOptions = dataSourceDimensions.map((dimension) => ({
      label: dimension.label,
      value: dimension.id,
      icon: <Avatar kind="image" imageUrl={dataSourceItem.iconUrl} />,
      subCategory: t('dataSourceFormView.inspectResultsTab.dimensions')
    }))

    const metricOptions = dataSourceMetrics.map((metric) => ({
      label: metric.label,
      value: metric.id,
      icon: <Avatar kind="image" imageUrl={dataSourceItem.iconUrl} />,
      subCategory: t('dataSourceFormView.inspectResultsTab.metrics')
    }))
    return [...dimensionOptions, ...metricOptions]
  }, [dataSourceDimensions, dataSourceMetrics, dataSourceItem.iconUrl, t])

  const handleColumnDropdownChange = (optionValue: string | null, value: MultiSelectDropdownItem[]): void => {
    setLocalSelectedColumns(value.map((v) => v.value))
  }

  const handleColumnDropdownClose = (): void => {
    const dimensionStrings = dataSourceDimensions.map((dimension) => dimension.id)
    const selectedDimensionsCount = localSelectedColumns.filter((column) => dimensionStrings.includes(column)).length

    if (selectedDimensionsCount === 0) {
      makeErrorToast(t('dataSourceFormView.inspectResultsTab.minimumOneDimensionError'))
      setLocalSelectedColumns(selectedColumns ?? [])
      return
    }

    const metricStrings = dataSourceMetrics.map((metric) => metric.id)
    const selectedMetricsCount = localSelectedColumns.filter((column) => metricStrings.includes(column)).length

    if (selectedMetricsCount === 0) {
      makeErrorToast(t('dataSourceFormView.inspectResultsTab.minimumOneMetricError'))
      setLocalSelectedColumns(selectedColumns ?? [])
      return
    }
    setSelectedColumns(localSelectedColumns)
  }

  const channelFilterOptions = useMemo(() => {
    return [
      ...dataSourceItem.channels.map((channel) => ({
        label: channel.name,
        value: channel.name,
        icon: <Badge color={channel.color} />
      })),
      {
        label: OTHER_CHANNEL_NAME,
        value: OTHER_CHANNEL_NAME,
        icon: <Badge color={OTHER_CHANNEL_COLOR} />
      }
    ]
  }, [dataSourceItem.channels])

  useEffect(() => {
    // Check if any the selected values in the filtered channels are not in the channelFilterOptions
    // If so remove them and set the filteredChannels and localFilteredChannels to the list without the removed values
    const filteredChannelsToRemove = localFilteredChannels.filter(
      (channel) => !channelFilterOptions.some((option) => option.value === channel)
    )
    if (filteredChannelsToRemove.length > 0) {
      const newFilteredChannels = localFilteredChannels.filter((channel) => !filteredChannelsToRemove.includes(channel))
      setFilteredChannels(newFilteredChannels)
      setLocalFilteredChannels(newFilteredChannels)
    }
  }, [filteredChannels, channelFilterOptions, localFilteredChannels, setFilteredChannels])

  const handleChannelFilterDropdownChange = (optionValue: string | null, value: MultiSelectDropdownItem[]): void => {
    setLocalFilteredChannels(value.map((v) => v.value))
  }

  const handleChannelFilterDropdownClose = (): void => {
    setFilteredChannels(localFilteredChannels)
  }

  const handleDataRangeSelected = (startDate: string | null, endDate: string | null): void => {
    if (startDate === null || endDate === null) return
    setSelectedDates({ startDate, endDate })
  }

  return (
    <Grid display="flex" flexDirection="column" gap="16px" paddingTop="16px" width="100%">
      <Grid display="flex" alignItems="flex-end" height="40px" justifyContent="space-between">
        <Typography variant="h2">{t('dataSourceFormView.inspectResultsTab.title')}</Typography>
      </Grid>
      <Grid display="flex" justifyContent="space-between">
        <Grid display="flex" alignItems="center" gap="4px">
          <Typography variant="body2">{t('dataSourceFormView.inspectResultsTab.showMe')}</Typography>
          <MultiSelectDropdown
            title={t('dataSourceFormView.inspectResultsTab.selectColumns')}
            buttonTitle={t('dataSourceFormView.inspectResultsTab.allColumns')}
            options={columnOptions}
            value={localSelectedColumns}
            onChange={handleColumnDropdownChange}
            onClose={handleColumnDropdownClose}
          />
          <MultiSelectDropdown
            title={t('dataSourceFormView.inspectResultsTab.filterChannels')}
            buttonTitle={t('dataSourceFormView.inspectResultsTab.allChannels')}
            options={channelFilterOptions}
            value={localFilteredChannels.filter((channel) =>
              channelFilterOptions.some((option) => option.value === channel)
            )}
            onChange={handleChannelFilterDropdownChange}
            onClose={handleChannelFilterDropdownClose}
          />
        </Grid>
        <Grid>
          <DateRangeCalendar
            onDateRangeSelected={handleDataRangeSelected}
            onCancel={() => null}
            displayFormat="MMM Do, YYYY"
            dateRangeSelectedFormat="YYYY-MM-DD"
            defaultStartDate={selectedDates?.startDate ?? defaultStartDate}
            defaultEndDate={selectedDates?.endDate ?? defaultEndDate}
            cancelText={t('dataSourceFormView.inspectResultsTab.cancel')}
            applyText={t('dataSourceFormView.inspectResultsTab.apply')}
          />
        </Grid>
      </Grid>
      <InspectResultsTable
        selectedColumns={selectedColumns ?? defaultSelectedColumns}
        channelsFilter={filteredChannels ?? []}
        selectedDates={
          selectedDates ?? {
            startDate: defaultStartDate,
            endDate: defaultEndDate
          }
        }
      />
    </Grid>
  )
}

export default InspectResultsTab
