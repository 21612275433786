import React, { useState } from 'react'
import { Grid, Divider, Typography, Button, styled, SingleSelectDropdown } from '@precis-digital/kurama'
import { useForm } from 'shared/reactHookForm'
import { type Client } from 'shared/context/ClientContext'
import WorkspaceInput from '../WorkspaceInput'
import { useUpdateClient, useQueryMembers } from 'workspace/api'
import Dots from 'shared/components/Loader/Dots'
import DeleteWorkspacePopup from '../Popup/DeleteWorkspacePopup'
import { currencies, frequentlyUsedCurrencies } from 'shared/constants/currency'
import { useTranslation } from 'shared/translations'
import { EditInputContainer } from './styles'
import { trimWhitespace } from 'shared/utils'
interface FormProps {
  name: string
  url: string
  [key: string]: string
}

interface ProfileProps {
  currentClient: Client
}
const WorkspaceProfile = (props: ProfileProps): React.ReactElement => {
  const { currentClient } = props
  const [isEditName, setIsEditName] = useState<boolean>(false)
  const [isEditUrl, setIsEditUrl] = useState<boolean>(false)
  const [isEditCurrency, setIsEditCurrency] = useState<boolean>(false)
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false)

  const { data: members, isLoading: isLoadingMembers, isSuccess: isMembersSuccess } = useQueryMembers(currentClient.id)

  const { mutate: updateClient, isLoading: isUpdateClientLoading } = useUpdateClient()

  const DEFAULT_VALUES = {
    name: currentClient.name,
    url: currentClient.url ?? '',
    currency: currentClient.currency
  }

  const {
    formState: { isDirty, dirtyFields },
    control,
    clearErrors,
    reset,
    handleSubmit,
    watch,
    setValue
  } = useForm<FormProps>({
    defaultValues: {
      ...DEFAULT_VALUES
    }
  })

  const watchCurrency = watch('currency')

  const handleUpdateClient = async (clientId: string, formData: FormProps): Promise<void> => {
    if (!isDirty) return

    const updatedData: Partial<FormProps> = Object.keys(dirtyFields).reduce(
      (result, dirtyField, index) => ({
        [dirtyField]: formData[dirtyField],
        ...result
      }),
      {}
    )
    if (updatedData?.name != null) {
      updatedData.name = trimWhitespace(updatedData.name)
    }
    updateClient(
      { id: clientId, ...updatedData },
      {
        onSuccess: (data) => {
          reset({ name: data.name, url: data.url, currency: data.currency })
        }
      }
    )
  }

  const { t } = useTranslation('workspace')

  return (
    <Grid>
      {isUpdateClientLoading || isLoadingMembers ? (
        <Dots />
      ) : (
        <>
          <StyledTitle variant="h3">{t('titles.workspaceProfile')}</StyledTitle>
          <StyledDescription variant="body1">{t('descriptions.workspaceProfile')}</StyledDescription>
          <StyledProfileItem>
            {isEditName ? (
              <Grid>
                <Typography variant="h5">{t('titles.companyName')}</Typography>
                <Typography variant="body3">{t('descriptions.companyName')}</Typography>
                <EditInputsContainer>
                  <EditInputContainer>
                    <WorkspaceInput
                      name="name"
                      control={control}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          void handleSubmit(async (updatedClient) => {
                            await handleUpdateClient(currentClient.id, updatedClient)
                            setIsEditName(false)
                          })()
                        }
                      }}
                    />
                  </EditInputContainer>
                  <Button
                    onClick={() => {
                      void handleSubmit(async (updatedClient) => {
                        await handleUpdateClient(currentClient.id, updatedClient)
                        setIsEditName(false)
                      })()
                    }}
                    scheme="light"
                    variant="filled"
                  >
                    {t('buttons.save')}
                  </Button>
                  <Button
                    onClick={() => {
                      reset({
                        name: currentClient.name,
                        url: currentClient.url,
                        currency: currentClient.currency
                      })
                      setIsEditName(false)
                      clearErrors('name')
                    }}
                    scheme="light"
                    variant="tonal"
                  >
                    {t('buttons.cancel')}
                  </Button>
                </EditInputsContainer>
              </Grid>
            ) : (
              <>
                <Grid>
                  <Typography variant="body2">{t('placeholders.companyName')}</Typography>
                  <Typography variant="body3">{currentClient.name}</Typography>
                </Grid>
                <Button
                  variant="text"
                  onClick={() => {
                    setIsEditName(true)
                  }}
                >
                  {t('buttons.change')}
                </Button>
              </>
            )}
          </StyledProfileItem>
          <Divider />
          <StyledProfileItem>
            {isEditUrl ? (
              <Grid>
                <Typography variant="h5">{t('titles.website')}</Typography>
                <Typography variant="body3">{t('descriptions.website')}</Typography>
                <EditInputsContainer>
                  <EditInputContainer>
                    <WorkspaceInput
                      name="url"
                      control={control}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          void handleSubmit(async (updatedClient) => {
                            await handleUpdateClient(currentClient.id, updatedClient)
                            setIsEditUrl(false)
                          })()
                        }
                      }}
                    />
                  </EditInputContainer>
                  <Button
                    onClick={() => {
                      void handleSubmit(async (updatedClient) => {
                        await handleUpdateClient(currentClient.id, updatedClient)
                        setIsEditUrl(false)
                      })()
                    }}
                    scheme="light"
                    variant="filled"
                  >
                    {t('buttons.save')}
                  </Button>
                  <Button
                    onClick={() => {
                      reset({
                        name: currentClient.name,
                        url: currentClient.url,
                        currency: currentClient.currency
                      })
                      setIsEditUrl(false)
                      clearErrors('url')
                    }}
                    scheme="light"
                    variant="tonal"
                  >
                    {t('buttons.cancel')}
                  </Button>
                </EditInputsContainer>
              </Grid>
            ) : (
              <>
                <Grid>
                  <Typography variant="body2">{t('placeholders.website')}</Typography>
                  <Typography variant="body3">{currentClient.url}</Typography>
                </Grid>
                <Button
                  variant="text"
                  onClick={() => {
                    setIsEditUrl(true)
                  }}
                >
                  {t('buttons.change')}
                </Button>
              </>
            )}
          </StyledProfileItem>
          <TransparentDivider>
            <Divider />
          </TransparentDivider>
          <StyledTitle variant="h3">{t('titles.preferences')}</StyledTitle>
          <StyledProfileItem>
            {isEditCurrency ? (
              <Grid>
                <Typography variant="h5">{t('titles.currency')}</Typography>
                <SingleSelectDropdown
                  title={t('titles.currencySelectTitle')}
                  buttonWidth="100%"
                  value={watchCurrency}
                  onSelect={(value) => {
                    setValue('currency', value, { shouldDirty: true })
                  }}
                  options={currencies.map((currency) => ({
                    label: `${currency.name} (${currency.code})`,
                    value: currency.code,
                    subCategory: frequentlyUsedCurrencies.includes(currency.code)
                      ? t('descriptions.frequentlyUsedCurrencies')
                      : t('descriptions.otherCurrencies')
                  }))}
                />
                <Button
                  onClick={() => {
                    void handleSubmit(async (updatedClient) => {
                      await handleUpdateClient(currentClient.id, updatedClient)
                      setIsEditCurrency(false)
                    })()
                  }}
                  scheme="light"
                  variant="filled"
                >
                  {t('buttons.save')}
                </Button>
                <Button
                  onClick={() => {
                    reset({
                      name: currentClient.name,
                      url: currentClient.url,
                      currency: currentClient.currency
                    })
                    setIsEditCurrency(false)
                    clearErrors('currency')
                  }}
                  scheme="light"
                  variant="tonal"
                >
                  {t('buttons.cancel')}
                </Button>
              </Grid>
            ) : (
              <>
                <Grid>
                  <Typography variant="body2">{t('titles.currency')}</Typography>

                  <Typography variant="body3">{currentClient.currency}</Typography>
                </Grid>

                <Button
                  variant="text"
                  onClick={() => {
                    setIsEditCurrency(true)
                  }}
                >
                  {t('buttons.change')}
                </Button>
              </>
            )}
          </StyledProfileItem>

          <TransparentDivider>
            <Divider />
          </TransparentDivider>

          <StyledTitle variant="h3">{t('titles.deleteWorkspace')}</StyledTitle>
          <StyledDescription variant="body1">{t('descriptions.deleteWorkspace')}</StyledDescription>
          <Button
            disabled={!isMembersSuccess || members.length > 1}
            onClick={() => {
              setOpenDeletePopup(true)
            }}
            scheme="light"
            variant="outlined"
          >
            {t('buttons.deleteWorkspace')}
          </Button>
          <DeleteWorkspacePopup
            open={openDeletePopup}
            onClose={() => {
              setOpenDeletePopup(false)
            }}
          />
        </>
      )}
    </Grid>
  )
}

export default WorkspaceProfile

const StyledTitle = styled(Typography)(({ theme }) => ({
  margin: '10px 0'
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: '25px'
}))

const TransparentDivider = styled(Grid)(({ theme }) => ({
  hr: {
    borderColor: 'transparent'
  }
}))

const StyledProfileItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  button: {
    alignSelf: 'center',
    marginTop: '16px',
    '&:first-of-type': {
      marginRight: theme.spacing(1)
    }
  },
  '>div': {
    '>div': {
      width: '100%'
    }
  }
}))

const EditInputsContainer = styled(Grid)(({ theme }) => ({
  form: {
    '>div': {
      width: '100%',
      paddingTop: '10px'
    }
  },
  button: {
    marginTop: '16px',
    '&:first-of-type': {
      marginRight: theme.spacing(1)
    }
  }
}))
