import React from 'react'

export const ShoppingBag = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M6.3493 7.3548L7.59934 7.72122C10.6526 8.61624 14.4434 8.61624 17.4966 7.72122L18.7467 7.3548"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M18.7467 18.37V7.74566C18.7467 7.58425 18.7025 7.42591 18.6191 7.28775L17.279 5.06966C17.1782 4.90283 16.979 4.82305 16.791 4.87418C14.0129 5.62936 11.083 5.62803 8.30549 4.87058C8.11719 4.81923 7.91765 4.89911 7.81684 5.06623L6.47659 7.28794C6.39331 7.42598 6.3493 7.58414 6.3493 7.74535V18.37C6.3493 19.6819 7.39001 20.7455 8.67379 20.7455H16.4222C17.7059 20.7455 18.7467 19.6819 18.7467 18.37Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M10.3867 11.9813L10.3869 13.3868C10.3869 14.5804 11.3545 15.548 12.548 15.548C13.7416 15.548 14.7092 14.5804 14.7092 13.3868L14.7091 11.9813"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
})
