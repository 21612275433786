import { useCallback } from 'react'
import { type ClientFeatureType } from 'shared/api/clients'
import { CLIENT_FEATURE_TO_ROUTE_MAP, INTERNAL_FEATURE_TO_ROUTE_MAP, getFeatureIdFromRoute } from 'shared/routes'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'

interface CLientAccessCheckResult {
  featureId: ClientFeatureType
  hasAccess: boolean
}

interface ClientAccessCheckParams {
  featureId?: ClientFeatureType
  route?: string
}

export const useHasClientFeatureAccess = (): {
  checkClientAccessToFeature: ({ featureId, route }: ClientAccessCheckParams) => CLientAccessCheckResult | null
} => {
  const { currentClient } = useCurrentClient()

  const checkClientAccessToFeature = useCallback(
    ({ featureId, route }: ClientAccessCheckParams): CLientAccessCheckResult | null => {
      const parsedFeatureId = featureId ?? getFeatureIdFromRoute(route, CLIENT_FEATURE_TO_ROUTE_MAP)

      if (parsedFeatureId == null) return null

      const hasAccess = currentClient.featurePlan?.[parsedFeatureId]?.enabled ?? false

      return {
        featureId: parsedFeatureId,
        hasAccess
      }
    },
    [currentClient]
  )

  return {
    checkClientAccessToFeature
  }
}

export const INTERNAL_USER_FEATURES = ['precis_apps', 'workspace_setup', 'mimir'] as const

export type InternalFeatureType = (typeof INTERNAL_USER_FEATURES)[number]

interface UserAccessCheckResult {
  featureId: InternalFeatureType
  hasAccess: boolean
}

interface UserAccessCheckParams {
  featureId?: InternalFeatureType
  route?: string
}

export const useHasInternalUserFeatureAccess = (): {
  checkUserAccessToInternalFeature: ({ featureId, route }: UserAccessCheckParams) => UserAccessCheckResult | null
} => {
  const { currentUser } = useAuth()

  const checkUserAccessToInternalFeature = useCallback(
    ({ featureId, route }: UserAccessCheckParams): UserAccessCheckResult | null => {
      const parsedFeatureId = featureId ?? getFeatureIdFromRoute(route, INTERNAL_FEATURE_TO_ROUTE_MAP)
      if (parsedFeatureId == null) return null

      const userHasInternalFeatureAccess = currentUser?.hasInternalFeatureAccess ?? false

      const isInternalFeature = INTERNAL_USER_FEATURES.includes(parsedFeatureId)

      const hasAccess = userHasInternalFeatureAccess || (!userHasInternalFeatureAccess && !isInternalFeature)

      return {
        hasAccess,
        featureId: parsedFeatureId
      }
    },
    [currentUser]
  )

  return {
    checkUserAccessToInternalFeature
  }
}
