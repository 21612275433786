import { PLATFORM } from 'dataSource'
import { type Platforms } from 'shared/api/accounts'

export const DEFAULT_LOOKBACK_DAYS = 30
export const INCLUDE_INTERCEPT_DEFAULT = false

export const CONVERSION_REDISTRIBUTION = [
  {
    value: 0,
    label: 'None',
    descriptiveLabel: 'No redistribution'
  },
  {
    value: 20,
    label: 'A slight amount',
    descriptiveLabel: 'A slight amount of redistribution'
  },
  {
    value: 40,
    label: 'A moderate amount',
    descriptiveLabel: 'A moderate amount of redistribution'
  },
  {
    value: 60,
    label: 'A significant amount',
    descriptiveLabel: 'A significant amount of redistribution'
  },
  {
    value: 80,
    label: 'A substantial amount',
    descriptiveLabel: 'A substantial amount of redistribution'
  },
  {
    value: 100,
    label: 'As much as possible',
    descriptiveLabel: 'As much redistribution as possible'
  }
]
export const CONVERSION_REDISTRIBUTION_MIN = 'None'
export const CONVERSION_REDISTRIBUTION_MAX = 'As much as possible'

export const EXPECTED_ROAS_REDISTRIBUTION_DEFAULT = 50
export const EXPECTED_ROAS_REDISTRIBUTION = [
  {
    value: 0,
    label: 'No expected return for this channel',
    descriptiveLabel: 'There is no expected return for this channel'
  },
  {
    value: 16,
    label: 'Moderately Below Normal',
    descriptiveLabel: 'ROAS is expected to be moderately lower than normal'
  },
  {
    value: 32,
    label: 'Slightly Below Normal',
    descriptiveLabel: 'ROAS is expected to be slightly lower than normal'
  },
  {
    value: 50,
    label: 'At Normal',
    descriptiveLabel: 'ROAS is expected to be at normal levels'
  },
  {
    value: 68,
    label: 'Slightly Above Normal',
    descriptiveLabel: 'ROAS is expected to be slightly above normal'
  },
  {
    value: 84,
    label: 'Moderately Above Normal',
    descriptiveLabel: 'ROAS is expected to be moderately above normal'
  },
  {
    value: 100,
    label: 'Significantly Above Normal',
    descriptiveLabel: 'ROAS is expected to be significantly above normal'
  }
]
export const EXPECTED_ROAS_REDISTRIBUTION_MIN = EXPECTED_ROAS_REDISTRIBUTION[0].label
export const EXPECTED_ROAS_REDISTRIBUTION_MAX =
  EXPECTED_ROAS_REDISTRIBUTION[EXPECTED_ROAS_REDISTRIBUTION.length - 1].label

export const AD_STOCK_REDISTRIBUTION_DEFAULT = 50
export const AD_STOCK_REDISTRIBUTION = [
  {
    value: 0,
    label: 'Memory immediately fades',
    descriptiveLabel: 'Very low ad stock'
  },
  {
    value: 16,
    label: 'Memory fades much faster than normal',
    descriptiveLabel: 'Low ad stock'
  },
  {
    value: 32,
    label: 'Memory fades faster than normal',
    descriptiveLabel: 'Slightly low ad stock'
  },
  {
    value: 50,
    label: 'Memory fades at a normal rate',
    descriptiveLabel: 'Normal ad stock'
  },
  {
    value: 68,
    label: 'Memory fades slower than normal',
    descriptiveLabel: 'Slightly high ad stock'
  },
  {
    value: 84,
    label: 'Memory fades much slower than normal',
    descriptiveLabel: 'High ad stock'
  },
  {
    value: 100,
    label: 'Memory fades extremely slowly',
    descriptiveLabel: 'Very high ad stock'
  }
]
export const AD_STOCK_REDISTRIBUTION_MIN = AD_STOCK_REDISTRIBUTION[0].label
export const AD_STOCK_REDISTRIBUTION_MAX = AD_STOCK_REDISTRIBUTION[AD_STOCK_REDISTRIBUTION.length - 1].label

export const IMPRESSION_VALUE = [
  {
    value: 0,
    label: 'None'
  },
  {
    value: 20,
    label: 'A slight amount'
  },
  {
    value: 40,
    label: 'A moderate amount'
  },
  {
    value: 60,
    label: 'A significant amount'
  },
  {
    value: 80,
    label: 'A substantial amount'
  },
  {
    value: 100,
    label: 'As much as possible'
  }
]
export const IMPRESSION_VALUE_MIN = 'None'
export const IMPRESSION_VALUE_MAX = 'As much as possible'

export const RECENCY_WEIGHTING_DEFAULT = 50
export const RECENCY_WEIGHTING = [
  {
    value: 0,
    label: 'Much lower than normal',
    descriptiveLabel: 'Much lower than normal recency weighting'
  },
  {
    value: 16,
    label: 'Lower than normal',
    descriptiveLabel: 'Lower than normal recency weighting'
  },
  {
    value: 32,
    label: 'Slightly lower than normal',
    descriptiveLabel: 'Slightly lower than normal recency weighting'
  },
  {
    value: 50,
    label: 'Normal',
    descriptiveLabel: 'Normal recency weighting'
  },
  {
    value: 68,
    label: 'Slightly higher than normal',
    descriptiveLabel: 'Slightly higher than normal recency weighting'
  },
  {
    value: 84,
    label: 'Higher than normal',
    descriptiveLabel: 'Higher than normal recency weigthing'
  },
  {
    value: 100,
    label: 'Much higher than normal',
    descriptiveLabel: 'Much higher than normal recency weighting'
  }
]

export const RECENCY_WEIGHTING_MIN = RECENCY_WEIGHTING[0].label
export const RECENCY_WEIGHTING_MAX = RECENCY_WEIGHTING[RECENCY_WEIGHTING.length - 1].label

export const OLD_BING_ADS_PLATFORM_ID = 'bing'

export const LOOKBACK_DAYS = [30, 60, 90, 120]

export const CREDIT_INPUTS = {
  DDAV2: 'ddav2',
  RBA: 'rba',
  XGBOOST: 'xgboost'
}

export const ATTRIBUTION_MODEL_TYPES = [
  {
    value: CREDIT_INPUTS.DDAV2,
    text: 'Precis DDAv2'
  },
  {
    value: CREDIT_INPUTS.XGBOOST,
    text: 'Integrated Attribution'
  },
  {
    value: CREDIT_INPUTS.RBA,
    text: 'Regression Based Attribution'
  }
]

export const platformsSupported: Platforms[] = [
  PLATFORM.DV360,
  PLATFORM.FACEBOOK,
  PLATFORM.LINKEDIN,
  PLATFORM.SNAPCHAT,
  PLATFORM.TIKTOK,
  PLATFORM.MICROSOFT_ADVERTISING,
  PLATFORM.GOOGLE
]

export const MULTIPLE_PLATFORMS = 'Multiple Platforms'

export const platformsConversionSupported = [...platformsSupported, MULTIPLE_PLATFORMS]

export const GA4_MODEL_TYPES = [
  {
    value: 'lnda',
    text: 'GA4 Session-scoped'
  },
  {
    value: 'native',
    text: 'GA4 Default'
  }
]

export const MODEL_TYPES = [...ATTRIBUTION_MODEL_TYPES, ...GA4_MODEL_TYPES]

export const DEFAULT_CONVERSION_EVENT = 'purchase'
export const DEFAULT_CONVERSION_EVENT_FACEBOOK = 'actions__offsite_conversion_fb_pixel_purchase'
