import { type ConfigReq, useMutationReportAnalytics } from 'home/api'
import { useCallback } from 'react'
import { type UseMutateFunction } from 'react-query'
import {
  type ReportingBaseTotalsGroupedByDateResp,
  type ReportingBaseTotalsResp,
  type ReportingBaseReportResp,
  type Filter,
  type ReportType,
  type AnalyticsReqField,
  type ChannelGroupingPerformanceStandardReportResp,
  type ChannelGroupingPerformanceTotalsResp,
  type ChannelGroupingPerformanceTotalsGroupedByDateResp,
  type ReportingBaseConversionEventResp
} from 'shared/api/analytics'
import { type ClientError } from 'shared/api/fetch'
import { type CurrencyCodes } from 'shared/constants/currency'
import { HttpStatusCodes } from 'shared/httpStatus'

export interface GetHomePageData {
  currentTimePeriod: [string, string]
  previousTimePeriod: [string, string]
  clientId: string
  currency: CurrencyCodes
  reportId: number
  conversionNameFilters?: string[]
  channelAndMarketFilters?: Filter[]
  customFilters?: Filter[]
  reportType: ReportType
  reportAnalyticsVariables: {
    fields: AnalyticsReqField[]
    groupBy: string[]
  }
  totalVariables: {
    fields: AnalyticsReqField[]
  }
}

type ReportAnalytics = ReportingBaseReportResp[] & ChannelGroupingPerformanceStandardReportResp[]

type ReportAnalyticsTotals = ReportingBaseTotalsResp & ChannelGroupingPerformanceTotalsResp

type ReportAnalyticsTotalsGroupedByDate = ReportingBaseTotalsGroupedByDateResp[] &
  ChannelGroupingPerformanceTotalsGroupedByDateResp[]

export const useHomePageData = (): {
  reportDataAnalytics: ReportAnalytics
  previousDataAnalytics: ReportAnalytics
  totalsGroupedByDate: ReportAnalyticsTotalsGroupedByDate
  previousTotalsGroupedByDate: ReportAnalyticsTotalsGroupedByDate
  totalsData: ReportAnalyticsTotals | undefined
  previousTotalsData: ReportAnalyticsTotals | undefined
  isDataLoaded: boolean
  isDataError: boolean
  isDataNotFoundError: boolean
  filterOptionsData: ReportingBaseReportResp[]
  conversionNameAndSources: ReportingBaseConversionEventResp[] | undefined
  getHomePageData: (args: GetHomePageData) => void
  resetHomePageData: () => void
  getFilterOptions: UseMutateFunction<ReportingBaseReportResp[], ClientError, ConfigReq, unknown>
  getConversionNamesAndSources: UseMutateFunction<ReportingBaseConversionEventResp[], ClientError, ConfigReq, unknown>
  isFilterOptionsLoaded: boolean
  isConversionNamesAndSourcesLoaded: boolean
  isConversionNamesAndSourcesError: boolean
  isLoadingConversionNamesAndSources: boolean
} => {
  const {
    mutate: getReportingBaseReportAnalytics,
    isLoading: isLoadingReportingBaseReportAnalytics,
    isIdle: getReportingBaseReportAnalyticsRequestIsIdle,
    isError: isErrorGettingReportingBaseReportAnalytics,
    error: getReportingBaseReportAnalyticsError,
    data: reportDataAnalytics = [],
    reset: resetReportingBaseReportAnalytics
  } = useMutationReportAnalytics<ReportAnalytics>()

  const {
    mutate: getPreviousReportingBaseReportAnalytics,
    isLoading: isLoadingPreviousReportingBaseReportAnalytics,
    isIdle: getPreviousReportingBaseReportAnalyticsRequestIsIdle,
    isError: isErrorGettingPreviousReportingBaseReportAnalytics,
    data: previousDataAnalytics = [],
    reset: resetPreviousReportingBaseReportAnalytics
  } = useMutationReportAnalytics<ReportAnalytics>()

  const {
    mutate: getTotalsGroupedByDate,
    isLoading: isLoadingTotalsGroupedByDate,
    isIdle: getTotalsGroupedByDateRequestIsIdle,
    isError: isErrorGettingTotalsGroupedByDate,
    data: totalsGroupedByDate = [],
    reset: resetTotalsGroupedByDate
  } = useMutationReportAnalytics<ReportAnalyticsTotalsGroupedByDate>()

  const {
    mutate: getTotalsGroupedByPreviousDate,
    isLoading: isLoadingTotalsGroupedByPreviousDate,
    isIdle: getTotalsGroupedByPreviousDateRequestIsIdle,
    isError: isErrorGettingTotalsGroupedByPreviousDate,
    data: previousTotalsGroupedByDate = [],
    reset: resetTotalsGroupedByPreviousDate
  } = useMutationReportAnalytics<ReportAnalyticsTotalsGroupedByDate>()

  const {
    mutate: getTotals,
    isLoading: isLoadingTotalsData,
    isIdle: getTotalsRequestIsIdle,
    isError: isErrorGettingTotalsData,
    data: totalsData,
    reset: resetTotals
  } = useMutationReportAnalytics<[ReportAnalyticsTotals]>()

  const {
    mutate: getPreviousTotals,
    isLoading: isLoadingRawPreviousReportingBaseReportAnalyticsTotals,
    isIdle: getPreviousTotalsRequestIsIdle,
    isError: isErrorGettingPreviousTotalsData,
    data: previousTotalsData,
    reset: resetPreviousTotals
  } = useMutationReportAnalytics<[ReportAnalyticsTotals]>()

  const {
    mutate: getFilterOptions,
    isLoading: isLoadingFilterOptions,
    isIdle: isGetFilterOptionsRequestIdle,
    data: filterOptionsData = [],
    reset: resetFilterOptions
  } = useMutationReportAnalytics()

  const {
    mutate: getConversionNamesAndSources,
    isLoading: isLoadingConversionNamesAndSources,
    isIdle: isGetConversionNamesAndSourcesRequestIdle,
    data: conversionNameAndSources,
    isError: isConversionNamesAndSourcesError,
    error: conversionNamesAndSourcesError,
    reset: resetConversionNamesAndSources
  } = useMutationReportAnalytics<ReportingBaseConversionEventResp[]>()

  const isDataLoading =
    isLoadingReportingBaseReportAnalytics ||
    isLoadingTotalsData ||
    isLoadingTotalsGroupedByDate ||
    isLoadingPreviousReportingBaseReportAnalytics ||
    isLoadingTotalsGroupedByPreviousDate ||
    isLoadingRawPreviousReportingBaseReportAnalyticsTotals

  const isAnyRequestIdle =
    getReportingBaseReportAnalyticsRequestIsIdle ||
    getTotalsRequestIsIdle ||
    getTotalsGroupedByDateRequestIsIdle ||
    getPreviousReportingBaseReportAnalyticsRequestIsIdle ||
    getTotalsGroupedByPreviousDateRequestIsIdle ||
    getPreviousTotalsRequestIsIdle

  const isFilterOptionsLoaded = !isLoadingFilterOptions && !isGetFilterOptionsRequestIdle

  const isDataLoaded = !isDataLoading && !isAnyRequestIdle && isFilterOptionsLoaded

  const isDataError =
    isConversionNamesAndSourcesError ||
    (isErrorGettingReportingBaseReportAnalytics &&
      isErrorGettingTotalsData &&
      isErrorGettingTotalsGroupedByDate &&
      isErrorGettingPreviousReportingBaseReportAnalytics &&
      isErrorGettingTotalsGroupedByPreviousDate &&
      isErrorGettingPreviousTotalsData)

  const isDataNotFoundError =
    getReportingBaseReportAnalyticsError?.status_code === HttpStatusCodes.NOT_FOUND ||
    conversionNamesAndSourcesError?.status_code === HttpStatusCodes.NOT_FOUND

  const getHomePageData = useCallback(
    ({
      currentTimePeriod,
      previousTimePeriod,
      clientId,
      currency,
      reportId,
      customFilters = [],
      reportType,
      reportAnalyticsVariables,
      totalVariables
    }: GetHomePageData): void => {
      const sharedPayload: {
        clientId: string
        targetCurrencyCode: CurrencyCodes
        id: string
        report: ReportType
      } = {
        clientId,
        targetCurrencyCode: currency,
        id: reportId.toString(),
        report: reportType
      }

      const currentDateFilter = [
        {
          field: 'date',
          operator: 'BETWEEN',
          value: currentTimePeriod
        }
      ]

      const previousDateFilter = [
        {
          field: 'date',
          operator: 'BETWEEN',
          value: previousTimePeriod
        }
      ]

      getReportingBaseReportAnalytics({
        ...sharedPayload,
        ...reportAnalyticsVariables,
        filters: [...currentDateFilter, ...customFilters]
      })

      getPreviousReportingBaseReportAnalytics({
        ...sharedPayload,
        ...reportAnalyticsVariables,
        filters: [...previousDateFilter, ...customFilters]
      })

      getTotalsGroupedByDate({
        ...sharedPayload,
        fields: [...totalVariables.fields, { name: 'date' }],
        filters: [...currentDateFilter, ...customFilters],
        groupBy: ['date'],
        orderBy: {
          fields: ['date'],
          direction: 'ASC'
        }
      })

      getTotalsGroupedByPreviousDate({
        ...sharedPayload,
        fields: [...totalVariables.fields, { name: 'date' }],
        filters: [...previousDateFilter, ...customFilters],
        groupBy: ['date']
      })

      getTotals({
        ...sharedPayload,
        ...totalVariables,
        filters: [...currentDateFilter, ...customFilters]
      })

      getPreviousTotals({
        ...sharedPayload,
        ...totalVariables,
        filters: [...previousDateFilter, ...customFilters]
      })
    },
    [
      getPreviousReportingBaseReportAnalytics,
      getPreviousTotals,
      getReportingBaseReportAnalytics,
      getTotals,
      getTotalsGroupedByDate,
      getTotalsGroupedByPreviousDate
    ]
  )

  const resetHomePageData = useCallback(() => {
    resetReportingBaseReportAnalytics()
    resetPreviousReportingBaseReportAnalytics()
    resetTotals()
    resetPreviousTotals()
    resetTotalsGroupedByDate()
    resetTotalsGroupedByPreviousDate()
    resetFilterOptions()
    resetConversionNamesAndSources()
  }, [
    resetConversionNamesAndSources,
    resetFilterOptions,
    resetPreviousReportingBaseReportAnalytics,
    resetPreviousTotals,
    resetReportingBaseReportAnalytics,
    resetTotals,
    resetTotalsGroupedByDate,
    resetTotalsGroupedByPreviousDate
  ])

  return {
    getHomePageData,
    resetHomePageData,
    getFilterOptions,
    getConversionNamesAndSources,
    filterOptionsData,
    isFilterOptionsLoaded,
    isConversionNamesAndSourcesLoaded:
      !isLoadingConversionNamesAndSources &&
      !isGetConversionNamesAndSourcesRequestIdle &&
      !isConversionNamesAndSourcesError,
    isConversionNamesAndSourcesError,
    isLoadingConversionNamesAndSources,
    conversionNameAndSources,
    reportDataAnalytics,
    previousDataAnalytics,
    totalsGroupedByDate,
    previousTotalsGroupedByDate,
    totalsData: totalsData?.[0],
    previousTotalsData: previousTotalsData?.[0],
    isDataLoaded,
    isDataError,
    isDataNotFoundError
  }
}
