import ReactFlow from 'reactflow'
import { styled } from '../../../utils'

export const StyledFlowChart = styled(ReactFlow)(() => ({
  '.react-flow__handle': {
    visibility: 'hidden'
  },
  '.react-flow__node': {
    cursor: 'initial'
  },
  '.react-flow__node:hover': {
    cursor: 'initial'
  },
  '.react-flow__attribution': {
    display: 'none'
  }
}))
