import React from 'react'

export const ArrowTipRight = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M13.6092 7.55732L3.52213 1.28713C2.26059 0.502954 0.618259 1.31506 0.491465 2.72005C0.481072 2.83521 0.503695 2.95049 0.542909 3.05927L2.68463 9.00005L0.542909 14.9408C0.503695 15.0496 0.481068 15.1649 0.491485 15.2801C0.616807 16.6655 2.21903 17.481 3.48148 16.7376L13.5686 10.7974C14.8144 10.0638 14.8362 8.32004 13.6092 7.55732Z"
        fill="#232332"
      />
    </svg>
  )
})
