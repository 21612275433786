import React from 'react'

export const Menu = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M18.0483 8.00439H6.04874C5.56549 8.00439 5.17374 8.39615 5.17374 8.87939C5.17374 9.36264 5.56549 9.75439 6.04874 9.75439H18.0483C18.5316 9.75439 18.9233 9.36264 18.9233 8.87939C18.9233 8.39615 18.5316 8.00439 18.0483 8.00439Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0483 15.2455H6.04874C5.56549 15.2455 5.17374 15.6373 5.17374 16.1205C5.17374 16.6038 5.56549 16.9955 6.04874 16.9955H18.0483C18.5316 16.9955 18.9233 16.6038 18.9233 16.1205C18.9233 15.6373 18.5316 15.2455 18.0483 15.2455Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
