import { Typography, BarChart, Grid, defaultTheme as theme, type BadgeColor, Badge } from '@precis-digital/kurama'
import { head } from 'ramda'
import { useTranslation } from 'shared/translations'
import Dots from 'shared/components/Loader/Dots'
import ChartToolTip from 'shared/components/ChartToolTip'
import { getFormattedValue } from 'shared/numberFormat'
import {
  type AttributionBaseReport,
  preprocessAttributionData,
  transformAttributionDataToBarChart
} from 'attributionModel/transformations'
import { type SelectMetricsType } from 'attributionModel/components/Dashboard'
import { type AttributionResponse } from 'shared/api/analytics'
import type { RequireAtLeastOne } from 'type-fest'
import ChartLegend from 'shared/components/ChartLegend'
import { objectKeys } from 'shared/utils'
import { type AttributionModelType } from './DetailedReportUtils'
import { GA4_EXCLUSION_LIST } from 'attributionModel/constants'
import { useCurrentClient } from 'shared/context/ClientContext'

const xtickCharLimit = 16

interface BarData {
  [key: string]: number | string
  x: string
}

export type BarChartChannelRecord = RequireAtLeastOne<
  {
    revenue?: number
    conversions?: number
    x: string
  },
  'revenue' | 'conversions'
>

export type BarChartChannelRecords = BarChartChannelRecord[]

export const colorsBarChart: Partial<Record<BadgeColor, string>> = {
  info: theme.palette.semantic.info100,
  'dark-mint': theme.palette.graphs.mint100
}

export default function BarChartComparison({
  currentReport,
  attributionReports,
  isDataLoaded,
  selectedMetrics,
  currencyCode,
  currentModelType,
  hasValidFilter,
  configName
}: {
  currentReport: AttributionBaseReport[]
  attributionReports: AttributionResponse[]
  selectedMetrics: SelectMetricsType
  isDataLoaded: boolean
  currencyCode: string
  currentModelType: AttributionModelType
  hasValidFilter: boolean
  configName: string
}): React.ReactElement {
  const { t } = useTranslation('attributionModel')
  const { currentClient } = useCurrentClient()

  const currentChannelData: BarChartChannelRecords =
    currentReport.length > 0 ? transformAttributionDataToBarChart(currentReport, selectedMetrics) : []
  const lndaReportPreprocessed =
    attributionReports.length > 0 ? preprocessAttributionData(attributionReports, 'lndaRevenue', 'lndaConversions') : []
  const ga4ReportPreprocessed =
    attributionReports.length > 0
      ? preprocessAttributionData(
          attributionReports,
          'ga4DefaultAttributionRevenue',
          'ga4DefaultAttributionConversions'
        )
      : []
  const aggregatedChannelData: BarChartChannelRecords = transformAttributionDataToBarChart(
    currentModelType === 'rba' ? ga4ReportPreprocessed : lndaReportPreprocessed,
    selectedMetrics
  )

  const getComparisonModel = (currentModelType: AttributionModelType, hasValidFilter: boolean): string | undefined => {
    if (GA4_EXCLUSION_LIST.includes(currentClient.id.toString())) {
      return undefined
    } else if (currentModelType === 'rba') {
      return !hasValidFilter ? undefined : t('dashboard.modelTypeTitles.ga4Default')
    } else {
      return t('dashboard.modelTypeTitles.lnda')
    }
  }

  const comparisonModel = getComparisonModel(currentModelType, hasValidFilter)

  // create an object of type MultiChannelRecords from aggregatedChannelData and aggregatedChannelData where x is channel
  const multiChannelRecords: BarData[] = currentChannelData.map((record) => {
    const comparisonRecord = aggregatedChannelData.find((comparisonRecord) => comparisonRecord.x === record.x)
    if (comparisonModel !== undefined) {
      return {
        current: record[selectedMetrics] ?? 0,
        [comparisonModel]: comparisonRecord?.[selectedMetrics] ?? 0,
        x: record.x
      }
    }
    return {
      current: record[selectedMetrics] ?? 0,
      x: record.x
    }
  })

  const typeOfProgressValue: string =
    head(currentChannelData)?.revenue != null ? t('dashboard.revenue') : t('dashboard.conversions')

  const tooltipBody = (key: string): string | undefined => {
    if (key === 'current') {
      return typeOfProgressValue
    } else {
      return comparisonModel !== undefined ? `${typeOfProgressValue} • ${comparisonModel}` : undefined
    }
  }

  return (
    <Grid container>
      <Typography variant="h2">{t('dashboard.aggregatedChannelData.title')}</Typography>

      <Grid
        container
        item
        xs={12}
        marginTop={theme.spacing(4)}
        marginBottom={theme.spacing(4)}
        gap={theme.spacing(4)}
        alignItems="center"
      />

      {!isDataLoaded && (
        <Grid container justifyContent="center">
          <Dots />
        </Grid>
      )}
      {isDataLoaded && currentChannelData.length === 0 && (
        <Grid container margin="50px 0" justifyContent="center">
          <Typography variant="h4">{t('dashboard.aggregatedChannelData.noData')}</Typography>
        </Grid>
      )}
      {isDataLoaded && currentChannelData.length > 0 && (
        <Grid height="438px" width="100%">
          {comparisonModel !== undefined && (
            <ChartLegend
              selectedRecords={{
                [configName]: {
                  badgeColor: objectKeys(colorsBarChart)[0]
                },
                [comparisonModel]: {
                  badgeColor: objectKeys(colorsBarChart)[1]
                }
              }}
            />
          )}
          {comparisonModel === undefined && (
            <ChartLegend
              selectedRecords={{
                [configName]: {
                  badgeColor: objectKeys(colorsBarChart)[0]
                }
              }}
            />
          )}
          <BarChart
            data={multiChannelRecords}
            margin={{
              left: 50,
              right: 5,
              top: 50,
              bottom: 50
            }}
            getConfigByDataKey={(dataKey) => {
              if (dataKey === comparisonModel) {
                return {
                  barColor: Object.values(colorsBarChart)[1]
                }
              }
              return {
                barColor: Object.values(colorsBarChart)[0]
              }
            }}
            renderTooltip={({ dataKey, payload }) => {
              return (
                <ChartToolTip
                  {...{
                    customBody: (
                      <span>
                        {Object.keys(payload)
                          .filter((key) => key !== 'x')
                          .map((key) => (
                            <div
                              key={key}
                              style={{
                                width: '326px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div style={{ marginRight: '8px' }}>
                                <Badge
                                  color={
                                    key === comparisonModel
                                      ? objectKeys(colorsBarChart)[1]
                                      : objectKeys(colorsBarChart)[0]
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  marginRight: '16px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {tooltipBody(key)}
                              </div>
                              <div style={{ marginLeft: 'auto', marginRight: '32px', whiteSpace: 'nowrap' }}>
                                {getFormattedValue({
                                  value: payload[key] as number,
                                  id: selectedMetrics,
                                  currencyCode
                                })}
                              </div>
                            </div>
                          ))}
                      </span>
                    ),
                    title: payload.x.toString()
                  }}
                />
              )
            }}
            xAxis={{
              tickLine: false,
              angle: -45,
              height: 100,
              textAnchor: 'end',
              fontSize: 14,
              tickFormatter: (value: string) => {
                // only show first 16 characters to fit in window size
                if (value.length > xtickCharLimit) {
                  return value.slice(0, xtickCharLimit - 3) + '...'
                }
                return value
              }
            }}
            yAxis={{
              label: {
                value: typeOfProgressValue
              },
              tick: {
                textAnchor: 'middle'
              },
              tickFormatter: (value: string) =>
                getFormattedValue({ value: Number(value), id: selectedMetrics, currencyCode, compact: true })
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
