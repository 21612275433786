import { CustomFlipModifier, CustomOffsetModifier, TooltipBodyProps, TooltipProps, TooltipTitleProps } from './types'
import React, { forwardRef, useRef } from 'react'
import { TooltipBodyContainer, TooltipContainer, TooltipTitleContainer, StyledTooltip } from './styles'
import { Fade } from '@mui/material'
import type { Modifier } from '@popperjs/core'

const flipModifier: CustomFlipModifier = {
  name: 'flip',
  enabled: true,
  phase: 'main',
  options: {
    fallbackPlacements: ['top-end', 'top-start', 'bottom-start', 'top', 'bottom', 'right', 'left']
  }
}

const DELAY_IN_MS = 800

const offsetModifier: CustomOffsetModifier = {
  name: 'offset',
  enabled: true,
  phase: 'main',
  options: {
    /**
     * Function that calculates the offset to apply to the tooltip depending on
     * the placement that Popper calculates for it.
     */
    offset: ({ placement, reference }) => {
      switch (placement) {
        case 'bottom-start':
          return [reference.width / 2, -10]
        case 'bottom-end':
          return [-(reference.width / 2) - 2, -10]
        case 'top-start':
          return [reference.width / 2 - 2, -10]
        case 'top-end':
          return [-(reference.width / 2) - 2, -10]
        case 'top':
        case 'bottom':
          return [0, -10]
        default:
          return [0, 0]
      }
    }
  }
}

const setBorderRadiusModifier: Modifier<'setBorderRadius', {}> = {
  name: 'setBorderRadius',
  enabled: true,
  phase: 'afterWrite',
  fn({ state }) {
    /**
     * Function used to find the actual MUI Tooltip and change the border radius
     * dependent on the placement to generate the "arrow" effect.
     */
    const MuiTooltipRef: HTMLElement | null = state.elements.popper.querySelector('.MuiTooltip-tooltip')
    if (MuiTooltipRef != null) {
      if (state.placement === 'bottom-start') {
        MuiTooltipRef.style.borderTopLeftRadius = '0px'
      } else if (state.placement === 'bottom-end') {
        MuiTooltipRef.style.borderTopRightRadius = '0px'
      } else if (state.placement === 'top-start') {
        MuiTooltipRef.style.borderBottomLeftRadius = '0px'
      } else if (state.placement === 'top-end') {
        MuiTooltipRef.style.borderBottomRightRadius = '0px'
      }
    }
  }
}

const TooltipTitle = ({ text }: TooltipTitleProps): React.ReactElement => {
  return <TooltipTitleContainer>{text}</TooltipTitleContainer>
}

const TooltipBody = ({ children }: TooltipBodyProps): React.ReactElement => {
  return <TooltipBodyContainer>{children}</TooltipBodyContainer>
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      open = false,
      kind,
      title,
      children,
      placement = 'bottom-end',
      width,
      maxHeight,
      allowOverflow = false,
      maxWidth,
      disabled = false,
      titleBodyGap,
      shouldDelay = false,
      ...props
    },
    ref
  ): React.ReactElement => {
    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(open)

    const initialOpenState = useRef(tooltipIsOpen)

    const TooltipContent = (): React.ReactElement => {
      const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        event.stopPropagation()
      }

      return (
        <>
          {kind === 'singleline' && title !== undefined ? (
            <TooltipContainer onClick={onClick} kind={kind} titleBodyGap={titleBodyGap}>
              {title}
            </TooltipContainer>
          ) : kind === 'multiline' && props.body !== undefined ? (
            <TooltipContainer onClick={onClick} kind={kind} titleBodyGap={titleBodyGap}>
              <TooltipTitle text={title} />
              <TooltipBody>{props.body}</TooltipBody>
            </TooltipContainer>
          ) : (
            <></>
          )}
        </>
      )
    }

    let modifiers

    switch (placement) {
      case 'right':
        modifiers = [offsetModifier, offsetModifier]
        break
      default:
        modifiers = [flipModifier, offsetModifier, setBorderRadiusModifier]
        break
    }

    return (
      <StyledTooltip
        title={<TooltipContent />}
        kind={kind}
        enterDelay={shouldDelay ? DELAY_IN_MS : undefined}
        enterNextDelay={shouldDelay ? DELAY_IN_MS : undefined}
        placement={placement}
        width={width}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        allowOverflow={allowOverflow}
        open={tooltipIsOpen}
        onOpen={() => !initialOpenState.current && setTooltipIsOpen(true)}
        onClose={() => !initialOpenState.current && setTooltipIsOpen(false)}
        PopperProps={{
          popperOptions: {
            modifiers
          }
        }}
        TransitionComponent={Fade}
        ref={ref}
        {...(disabled && {
          disableFocusListener: true,
          disableHoverListener: true
        })}
        {...props}
      >
        {children as React.ReactElement}
      </StyledTooltip>
    )
  }
)

export default Tooltip
