import { ExpandedNumberFormat } from 'shared/components/NumberFormat'
import type { CellProps, Column, FooterProps } from 'react-table'
import {
  ACTION,
  CAMPAIGN,
  CHANNEL,
  DIMENSION,
  FIRSTSUBHEADER,
  RECOMMENDATION,
  SECONDSUBHEADER,
  PLATFORM,
  REVENUE,
  ADSPEND,
  CONVERSIONS
} from 'budgetOptimiser/constants'
import { Typography, ConnectDataSourcesIcon, Grid } from '@precis-digital/kurama'
import { type FormatIds } from 'shared/numberFormat'
import RecommendationIcon from 'budgetOptimiser/components/Recommendations/RecommendationIcon'
import PercentageChip from 'shared/components/PercentageChip'
import PlatformWithLabel from 'budgetOptimiser/components/Overview/PlatformWithLabel'
import { type Platforms } from 'shared/api/accounts'
import { capitalize, objectKeys, calculateCostPerAction, calculateRoas } from 'shared/utils'
import React from 'react'
import {
  DEFAULT_DATA_COLUMN_PROPERTIES,
  differenceColumnSortTypeFn,
  reactTableMultiSelectFilter
} from 'shared/reactTable'
import { DIFFERENCE_HEADER } from 'shared/reactTable/constants'
import type { SubHeader } from 'shared/reactTable/types'
import { type ColumnProps } from 'budgetOptimiser/components/Overview/DetailedReport'
import { type BudgetOptimizerDataTableRow, type HeaderValue } from 'budgetOptimiser/api/transformations/transform'
import TruncatedTextWithTooltip from 'shared/components/TruncatedTextWithTooltip'
import { extractCampaignNameFromDimensionCampaign } from 'budgetOptimiser/utils'
import { convertSnakeCaseToSentenceCase } from 'shared/utils/stringFormatter'

type SubHeaderUnionType = typeof FIRSTSUBHEADER | typeof SECONDSUBHEADER
type BudgetOptimizerTableRowKeys = keyof BudgetOptimizerDataTableRow

export interface GroupedColumnProps {
  id: BudgetOptimizerTableRowKeys
  firstSubHeaderLabel: string
  secondSubHeaderLabel: string
  currencyCode: string
}

export interface GroupedColumnPropsRatio extends GroupedColumnProps {
  numeratorColumnId: BudgetOptimizerTableRowKeys
  denominatorColumnId: BudgetOptimizerTableRowKeys
  ratioFn: (numerator: number, denominator: number) => number
}

interface TableColumnsProps {
  t: (string: string) => string
  currencyCode: string
  showDataSourceLabel: boolean
}

type NestedColumn = Column<ColumnProps> & {
  columns: Array<Column<BudgetOptimizerDataTableRow>>
}

const getSubGroupColumns = ({
  id,
  firstSubHeaderLabel,
  secondSubHeaderLabel,
  currencyCode
}: GroupedColumnProps): Array<Column<BudgetOptimizerDataTableRow>> => {
  const getSubHeaderColumn = (subHeader: SubHeaderUnionType): Column<BudgetOptimizerDataTableRow> => ({
    ...DEFAULT_DATA_COLUMN_PROPERTIES,
    id: `${id}_${subHeader}`,
    Header: subHeader === FIRSTSUBHEADER ? firstSubHeaderLabel : secondSubHeaderLabel,
    aggregate: 'sum',
    accessor: (value: BudgetOptimizerDataTableRow): number => (value[id] as HeaderValue)[subHeader],
    Cell: ({ value }: { value: number }) => (
      <ExpandedNumberFormat value={value} id={id as FormatIds} currencyCode={currencyCode} />
    ),
    Footer: ({ rows, column }: FooterProps<BudgetOptimizerDataTableRow>) => {
      const total = rows.reduce((sum, row) => sum + (row.values[column.id] as number), 0)
      return <ExpandedNumberFormat value={total} id={id as FormatIds} currencyCode={currencyCode} />
    }
  })

  return [
    getSubHeaderColumn(FIRSTSUBHEADER),
    getSubHeaderColumn(SECONDSUBHEADER),
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      id: `${id}_difference`,
      Header: DIFFERENCE_HEADER,
      accessor: (originalRow: BudgetOptimizerDataTableRow) => originalRow[id],
      aggregate: (rows) => {
        let totalFirstSubHeader = 0
        let totalSecondSubHeader = 0
        rows.forEach((row) => {
          totalFirstSubHeader += row[FIRSTSUBHEADER] as number
          totalSecondSubHeader += row[SECONDSUBHEADER] as number
        })

        return {
          [FIRSTSUBHEADER]: totalFirstSubHeader,
          [SECONDSUBHEADER]: totalSecondSubHeader
        }
      },
      Cell: ({ value }: CellProps<BudgetOptimizerDataTableRow>) => {
        return (
          <PercentageChip
            previousValue={value[FIRSTSUBHEADER]}
            presentValue={value[SECONDSUBHEADER]}
            reverseColorScheme={id === 'costPerAction'}
          />
        )
      },
      Aggregated: ({ value }: { value: SubHeader }) => {
        return (
          <PercentageChip
            previousValue={value[FIRSTSUBHEADER]}
            presentValue={value[SECONDSUBHEADER]}
            reverseColorScheme={id === 'costPerAction'}
          />
        )
      },
      sortType: differenceColumnSortTypeFn,
      Footer: ({ rows, column }: FooterProps<BudgetOptimizerDataTableRow>) => {
        let totalFirstSubHeader = 0
        let totalSecondSubHeader = 0
        rows.forEach((row) => {
          totalFirstSubHeader += row.values[column.id][FIRSTSUBHEADER] as number
          totalSecondSubHeader += row.values[column.id][SECONDSUBHEADER] as number
        })

        return (
          <PercentageChip
            previousValue={totalFirstSubHeader}
            presentValue={totalSecondSubHeader}
            reverseColorScheme={id === 'costPerAction'}
          />
        )
      }
    }
  ]
}

const getSubGroupColumnsRatio = ({
  id,
  firstSubHeaderLabel,
  secondSubHeaderLabel,
  currencyCode,
  numeratorColumnId,
  denominatorColumnId,
  ratioFn
}: GroupedColumnPropsRatio): Array<Column<BudgetOptimizerDataTableRow>> => {
  const accessColumnsForRatio = (
    originalRow: BudgetOptimizerDataTableRow,
    numeratorColumnId: BudgetOptimizerTableRowKeys,
    denominatorColumnId: BudgetOptimizerTableRowKeys
  ): Record<string, HeaderValue> => {
    // this will only be used to access metric columns that are potentially nested
    const numerator = originalRow[numeratorColumnId] as HeaderValue
    const denominator = originalRow[denominatorColumnId] as HeaderValue
    return {
      [numeratorColumnId as string]: numerator,
      [denominatorColumnId as string]: denominator
    }
  }

  const getSubHeaderColumn = (subHeader: SubHeaderUnionType): Column<BudgetOptimizerDataTableRow> => ({
    ...DEFAULT_DATA_COLUMN_PROPERTIES,
    id: `${id}_${subHeader}`,
    Header: subHeader === FIRSTSUBHEADER ? firstSubHeaderLabel : secondSubHeaderLabel,
    aggregate: (rows: Array<Record<string, HeaderValue>>) => {
      let totalNumerator = 0
      let totalDenominator = 0

      rows.forEach((row) => {
        totalNumerator += row[numeratorColumnId][subHeader]
        totalDenominator += row[denominatorColumnId][subHeader]
      })
      return ratioFn(totalNumerator, totalDenominator)
    },
    accessor: (originalRow: BudgetOptimizerDataTableRow) => {
      return accessColumnsForRatio(originalRow, numeratorColumnId, denominatorColumnId)
    },
    Cell: ({ value }: CellProps<BudgetOptimizerDataTableRow>) => (
      <ExpandedNumberFormat
        value={ratioFn(value[numeratorColumnId][subHeader], value[denominatorColumnId][subHeader])}
        id={id as FormatIds}
        currencyCode={currencyCode}
      />
    ),
    Aggregated: ({ value }: { value: number }) => (
      <ExpandedNumberFormat value={value} id={id as FormatIds} currencyCode={currencyCode} />
    ),
    Footer: ({ rows, column }) => {
      let totalNumerator = 0
      let totalDenominator = 0
      rows.forEach((row) => {
        totalNumerator += row.values[`${numeratorColumnId}_${subHeader}`] as number
        totalDenominator += row.values[`${denominatorColumnId}_${subHeader}`] as number
      })

      const total = ratioFn(totalNumerator, totalDenominator)
      return <ExpandedNumberFormat value={total} id={id as FormatIds} currencyCode={currencyCode} />
    }
  })

  return [
    getSubHeaderColumn(FIRSTSUBHEADER),
    getSubHeaderColumn(SECONDSUBHEADER),
    {
      ...DEFAULT_DATA_COLUMN_PROPERTIES,
      id: `${id}_difference`,
      Header: DIFFERENCE_HEADER,
      accessor: (originalRow: BudgetOptimizerDataTableRow): Record<string, HeaderValue> => {
        return accessColumnsForRatio(originalRow, numeratorColumnId, denominatorColumnId)
      },
      aggregate: (rows: Array<Record<string, HeaderValue>>) => {
        let totalNumeratorFirstSubHeader = 0
        let totalDenominatorFirstSubHeader = 0
        let totalNumeratorSecondSubHeader = 0
        let totalDenominatorSecondSubHeader = 0

        rows.forEach((row) => {
          totalNumeratorFirstSubHeader += row[numeratorColumnId][FIRSTSUBHEADER]
          totalDenominatorFirstSubHeader += row[denominatorColumnId][FIRSTSUBHEADER]
          totalNumeratorSecondSubHeader += row[numeratorColumnId][SECONDSUBHEADER]
          totalDenominatorSecondSubHeader += row[denominatorColumnId][SECONDSUBHEADER]
        })

        const ratioFirstSubHeader = ratioFn(totalNumeratorFirstSubHeader, totalDenominatorFirstSubHeader)
        const ratioSecondSubHeader = ratioFn(totalNumeratorSecondSubHeader, totalDenominatorSecondSubHeader)
        return {
          [FIRSTSUBHEADER]: ratioFirstSubHeader,
          [SECONDSUBHEADER]: ratioSecondSubHeader
        }
      },
      Cell: ({ value }: CellProps<BudgetOptimizerDataTableRow>) => {
        const firstRatio = ratioFn(value[numeratorColumnId][FIRSTSUBHEADER], value[denominatorColumnId][FIRSTSUBHEADER])
        const secondRatio = ratioFn(
          value[numeratorColumnId][SECONDSUBHEADER],
          value[denominatorColumnId][SECONDSUBHEADER]
        )
        return (
          <PercentageChip
            previousValue={firstRatio}
            presentValue={secondRatio}
            reverseColorScheme={id === 'costPerAction'}
          />
        )
      },
      Aggregated: ({ value }: { value: SubHeader }) => {
        return (
          <PercentageChip
            previousValue={value[FIRSTSUBHEADER]}
            presentValue={value[SECONDSUBHEADER]}
            reverseColorScheme={id === 'costPerAction'}
          />
        )
      },
      sortType: differenceColumnSortTypeFn,
      Footer: ({ rows, column }) => {
        let totalNumeratorFirstSubHeader = 0
        let totalDenominatorFirstSubHeader = 0
        let totalNumeratorSecondSubHeader = 0
        let totalDenominatorSecondSubHeader = 0

        rows.forEach((row) => {
          totalNumeratorFirstSubHeader += row.values[`${numeratorColumnId}_${FIRSTSUBHEADER}`] as number
          totalDenominatorFirstSubHeader += row.values[`${denominatorColumnId}_${FIRSTSUBHEADER}`] as number
          totalNumeratorSecondSubHeader += row.values[`${numeratorColumnId}_${SECONDSUBHEADER}`] as number
          totalDenominatorSecondSubHeader += row.values[`${denominatorColumnId}_${SECONDSUBHEADER}`] as number
        })

        const ratioFirstSubHeader = ratioFn(totalNumeratorFirstSubHeader, totalDenominatorFirstSubHeader)
        const ratioSecondSubHeader = ratioFn(totalNumeratorSecondSubHeader, totalDenominatorSecondSubHeader)

        return (
          <PercentageChip
            previousValue={ratioFirstSubHeader}
            presentValue={ratioSecondSubHeader}
            reverseColorScheme={id === 'costPerAction'}
          />
        )
      }
    }
  ]
}

export const getTableColumns = ({ t, currencyCode, showDataSourceLabel }: TableColumnsProps): NestedColumn[] => {
  return [
    {
      Header: <Typography variant="h3">{t('dimensions')}</Typography>,
      accessor: DIMENSION,
      columns: [
        {
          ...DEFAULT_DATA_COLUMN_PROPERTIES,
          id: PLATFORM,
          Header: showDataSourceLabel ? 'Data Sources' : <ConnectDataSourcesIcon />,
          accessor: (originalRow: BudgetOptimizerDataTableRow) => originalRow[DIMENSION][PLATFORM],
          Cell: ({ value }: { value: string }) => {
            return <PlatformWithLabel showLabel={showDataSourceLabel} dataSource={value as Platforms} />
          },
          Aggregated: ({ value }: CellProps<BudgetOptimizerDataTableRow>) => {
            return value
          },
          filter: reactTableMultiSelectFilter
        },
        {
          ...DEFAULT_DATA_COLUMN_PROPERTIES,
          id: CHANNEL,
          aggregate: 'uniqueCount',
          Header: capitalize(CHANNEL),
          accessor: (originalRow: BudgetOptimizerDataTableRow) => originalRow[DIMENSION][CHANNEL],
          filter: reactTableMultiSelectFilter,
          Cell: ({ value, row }: CellProps<BudgetOptimizerDataTableRow>) => {
            return <>{convertSnakeCaseToSentenceCase(value)}</>
          }
        },
        {
          ...DEFAULT_DATA_COLUMN_PROPERTIES,
          id: CAMPAIGN,
          Header: capitalize(CAMPAIGN),
          accessor: (originalRow: BudgetOptimizerDataTableRow) => originalRow[DIMENSION][CAMPAIGN],
          aggregate: 'uniqueCount',
          filter: reactTableMultiSelectFilter,
          Cell: ({ value, row }: CellProps<BudgetOptimizerDataTableRow>) => {
            const campaignName = extractCampaignNameFromDimensionCampaign(value)
            return (
              <Grid container justifyContent="space-between" flexWrap="nowrap" spacing={1}>
                <Grid item style={{ maxWidth: '240px' }}>
                  <TruncatedTextWithTooltip tooltipText={campaignName}>{campaignName}</TruncatedTextWithTooltip>
                </Grid>
                <Grid item>
                  <RecommendationIcon rowData={row.original.originalData} />
                </Grid>
              </Grid>
            )
          },
          sortType: (rowA, rowB, columnId) => {
            const a: string = extractCampaignNameFromDimensionCampaign(rowA.original[DIMENSION][CAMPAIGN])
            const b: string = extractCampaignNameFromDimensionCampaign(rowB.original[DIMENSION][CAMPAIGN])
            return a.localeCompare(b)
          }
        },
        // Note this column is not visible to the user and is only used for filtering the data...
        {
          id: ACTION,
          accessor: (originalRow: BudgetOptimizerDataTableRow) => originalRow[RECOMMENDATION][ACTION],
          filter: reactTableMultiSelectFilter
        }
      ]
    },
    {
      Header: <Typography variant="h3">{t('adSpend')}</Typography>,
      accessor: ADSPEND,
      columns: getSubGroupColumns({
        id: ADSPEND,
        firstSubHeaderLabel: t('current'),
        secondSubHeaderLabel: t('modeled'),
        currencyCode
      })
    },
    {
      Header: <Typography variant="h3">{t('revenue')}</Typography>,
      accessor: REVENUE,
      columns: getSubGroupColumns({
        id: REVENUE,
        firstSubHeaderLabel: t('current'),
        secondSubHeaderLabel: t('modeled'),
        currencyCode
      })
    },
    {
      Header: <Typography variant="h3">{t('roas')}</Typography>,
      accessor: 'roas',
      columns: getSubGroupColumnsRatio({
        id: 'roas',
        firstSubHeaderLabel: t('current'),
        secondSubHeaderLabel: t('modeled'),
        currencyCode,
        numeratorColumnId: REVENUE,
        denominatorColumnId: ADSPEND,
        ratioFn: calculateRoas
      })
    },
    {
      Header: <Typography variant="h3">{t('conversions')}</Typography>,
      accessor: 'conversions',
      columns: getSubGroupColumns({
        id: 'conversions',
        firstSubHeaderLabel: t('current'),
        secondSubHeaderLabel: t('modeled'),
        currencyCode
      })
    },

    {
      Header: <Typography variant="h3">{t('costPerAction')}</Typography>,
      accessor: 'costPerAction',
      columns: getSubGroupColumnsRatio({
        id: 'costPerAction',
        firstSubHeaderLabel: t('current'),
        secondSubHeaderLabel: t('modeled'),
        currencyCode,
        numeratorColumnId: ADSPEND,
        denominatorColumnId: CONVERSIONS,
        ratioFn: calculateCostPerAction
      })
    }
  ]
}

interface GetFilterTranslatedKeysProps {
  filter: Record<string, boolean>
  t: (string: string) => string
}

export const getFilterTranslatedKeys = ({ filter, t }: GetFilterTranslatedKeysProps): Record<string, boolean> => {
  if (objectKeys(filter).length !== 0) {
    const result = Object.entries(filter).map(([key, value]) => {
      return { [t(`${key}`)]: value }
    })
    return Object.assign({}, ...result)
  }
  return {}
}

export const mapPlatformName = (platform: Platforms): Platforms => {
  switch (platform.toLowerCase()) {
    case 'bing':
      return 'microsoft_advertising'
    case 'google_ads':
    case 'googleads':
      return 'google'
    default:
      return platform
  }
}
