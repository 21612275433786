import { Avatar, type defaultTheme, styled } from '@precis-digital/kurama'
import { type Client } from 'shared/context/ClientContext'
import { type ClientResp } from 'shared/api/clients'
import React from 'react'
import { type Member } from 'workspace/api'

type AvatarLabelProps = React.ComponentProps<typeof Avatar>
type AvatarUser = Member | { name: string; avatarUrl?: string } | undefined
type AvatarClient = Client | ClientResp | undefined
type UserAvatarProps = Omit<AvatarLabelProps, 'label' | 'kind'> & {
  user?: AvatarUser
  client?: AvatarClient
}

export const AvatarWithLabel = (props: UserAvatarProps): React.ReactElement => {
  const { user, client, ...other } = props

  if (client?.avatarUrl !== undefined) {
    return <Avatar {...other} kind="image" imageUrl={client.avatarUrl} objectFit="contain" />
  }

  if (user?.avatarUrl !== undefined && user?.avatarUrl !== '' && user?.avatarUrl !== null) {
    return <Avatar {...other} kind="image" imageUrl={user.avatarUrl} objectFit="contain" />
  }

  return (
    <AvatarColor
      label={user?.name != null ? getUserLabel(user) : getClientLabel(client)}
      fullLabel={user?.name != null ? user.name : client?.name}
      kind="label"
      {...other}
    />
  )
}

const getClientLabel = (client: AvatarClient): string => {
  return typeof client?.name === 'string' && client.name.length > 0 ? client.name[0] : ''
}

const getUserLabel = (user: AvatarUser): string => {
  return user?.name != null
    ? user.name
        .split(' ')
        .map((word) => {
          return word[0]
        })
        .join('')
    : ''
}

export const labelColors = (theme: typeof defaultTheme): string[] => [
  theme.palette.graphs.beige100,
  theme.palette.graphs.indigo100,
  theme.palette.graphs.yellow100,
  theme.palette.graphs.green100,
  theme.palette.graphs.purple100,
  theme.palette.graphs.purple120,
  theme.palette.graphs.steel100,
  theme.palette.graphs.indigo10,
  theme.palette.graphs.red05,
  theme.palette.graphs.beige110,
  theme.palette.graphs.red50,
  theme.palette.graphs.purple150,
  theme.palette.graphs.pink05,
  theme.palette.graphs.mint100,
  theme.palette.graphs.mint05
]

export const getLabelColor = (theme: typeof defaultTheme, label: string): string => {
  const colors = labelColors(theme)
  const colorIndex = (label?.charCodeAt(0) + label?.charCodeAt(label?.length - 1)) % colors.length
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return colors[colorIndex || 0]
}

export const AvatarColor = styled(Avatar)<{ theme?: typeof defaultTheme; label: string; fullLabel?: string }>(
  ({ theme, label, fullLabel }) => {
    const backgroundColor = getLabelColor(theme, fullLabel ?? label)
    return {
      background: backgroundColor,
      p: {
        color: theme.palette.getContrastText(backgroundColor)
      }
    }
  }
)
