import { LinkProps } from './types'
import { StyledLink } from './styles'
import { forwardRef } from 'react'

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ variant = 'body1', href, target = '_self', children, ...props }, ref): React.ReactElement => {
    return (
      <StyledLink variant={variant} href={href} target={target} {...props} ref={ref}>
        {children}
      </StyledLink>
    )
  }
)
export default Link
