import {
  Button,
  CopyIcon,
  Divider,
  DropdownItem,
  DropdownList,
  EditIcon,
  PlusIcon,
  TrashIcon
} from '@precis-digital/kurama'
import { useMutationCreateChannelGrouping, useMutationDeleteChannelGrouping } from 'channelGrouping/api'
import { useRouter } from 'next/router'
import React, { type ReactElement } from 'react'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'
import { useChangePath } from 'shared/components/Router'
import { makeSuccessToast, makeToastWithLoading } from 'shared/components/Toaster'
import { useCurrentClient } from 'shared/context/ClientContext'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'
import { useGetUsedInConfigs } from 'channelGrouping/hooks/useGetUsedInConfigs'

interface MoreMenuDropdownProps {
  channelGrouping: ChannelGroupingResp
  closeMenu: () => void
  isPageWithConfigId?: boolean
}

const MoreMenuDropdown = ({
  channelGrouping,
  closeMenu,
  isPageWithConfigId = false
}: MoreMenuDropdownProps): ReactElement => {
  const { currentClient } = useCurrentClient()
  const { mutate: deleteChannelGrouping } = useMutationDeleteChannelGrouping()
  const router = useRouter()
  const isListView = router.query.custom_grouping_id == null

  const { usedInConfigs } = useGetUsedInConfigs(channelGrouping)

  const { changePath } = useChangePath()
  const { t } = useTranslation('channelGrouping')

  const handleDeleteConfirm = (): void => {
    const { toastOnError, toastOnSuccess } = makeToastWithLoading()
    deleteChannelGrouping(
      { configId: channelGrouping.id, clientId: currentClient.id },
      {
        onSuccess: () => {
          toastOnSuccess(
            t('popups.deleteChannelGrouping.successDeleteToast', { channelGroupingName: channelGrouping.name })
          )
          if (!isListView) {
            void changePath(APP_ROUTES.customGroupings.basePage)
          }
        },
        onError: (error) => {
          const errorMessage = error.message.toLowerCase()
          const isIAErorr = errorMessage.includes('is used in ia config(s)')
          toastOnError(
            isIAErorr
              ? t('popups.deleteChannelGrouping.iaConfigAttachedErrorToast')
              : t('popups.deleteChannelGrouping.fallbackErrorToast')
          )
        }
      }
    )
  }

  const handleDeleteClick = (): void => {
    void OverScreen.show('deletePopup', {
      deleteConfigProps: {
        id: channelGrouping.id,
        name: channelGrouping.name,
        onDelete: handleDeleteConfirm
      },
      t
    })
    closeMenu()
  }

  const { mutate: createChannelGrouping } = useMutationCreateChannelGrouping()
  const handleCopyClick = (): void => {
    createChannelGrouping(
      {
        name: `Copy of ${channelGrouping.name}`,
        clientId: currentClient.id,
        channelGrouping: channelGrouping.channelGrouping
      },
      {
        onSuccess: async (newChannelGrouping) => {
          makeSuccessToast(t('mainFormView.moreMenu.copyChannelGroupingSuccess', { name: newChannelGrouping.name }), {
            persistThroughRouteChange: true
          })
          if (isListView) {
            closeMenu()
          } else {
            void changePath(APP_ROUTES.customGroupings.basePage)
          }
        }
      }
    )
  }

  const handleEditClick = (): void => {
    if (isListView) {
      void changePath(APP_ROUTES.customGroupings.editConfigPage({ channelGroupingId: channelGrouping.id.toString() }))
      return
    }

    if (router.query.data_source_id == null) {
      void changePath(
        `${APP_ROUTES.customGroupings.editConfigPage({ channelGroupingId: channelGrouping.id.toString() })}/${
          router.query.tab_id as string
        }`,
        { isEditingName: 'true' },
        true
      )
      return
    }

    void changePath(
      APP_ROUTES.customGroupings.dataSourcesPage({ channelGroupingId: channelGrouping.id.toString() }),
      { isEditingName: 'true' },
      false
    )
  }

  const createNewChannelGroupingPopupOverscreen = useOverScreen('createNewChannelGroupingPopup')

  const disableDelete = usedInConfigs == null || usedInConfigs?.length > 0

  return (
    <DropdownList dense minWidth="266px">
      <DropdownItem icon={<EditIcon />} onClick={handleEditClick}>
        {isListView ? t('listView.moreMenu.editChannelGrouping') : t('mainFormView.moreMenu.renameChannelGrouping')}
      </DropdownItem>
      <DropdownItem icon={<CopyIcon />} onClick={handleCopyClick}>
        {t('mainFormView.moreMenu.copyChannelGrouping')}
      </DropdownItem>
      <Divider />
      <DropdownItem
        icon={<TrashIcon />}
        onClick={handleDeleteClick}
        disabled={disableDelete}
        tooltipProps={
          disableDelete
            ? {
                title: t('listView.moreMenu.disableDeleteUsedIn'),
                kind: 'singleline'
              }
            : undefined
        }
      >
        {t('mainFormView.moreMenu.deleteChannelGrouping')}
      </DropdownItem>
      {isPageWithConfigId && (
        <>
          <Divider />
          <Button
            fullWidth
            scheme="light"
            variant="filled"
            onClick={() => {
              void createNewChannelGroupingPopupOverscreen.show()
            }}
            rightIcon={<PlusIcon />}
          >
            {t('listView.createNewButton')}
          </Button>
        </>
      )}
    </DropdownList>
  )
}

export default MoreMenuDropdown
