import { type FormatIds, getFormattedValue } from 'shared/numberFormat'
import React from 'react'

export interface NumberFormatProps {
  value: number
  id: FormatIds
  currencyCode?: string
  compact?: boolean
}

const NumberFormat = ({ value, id, currencyCode, compact, ...props }: NumberFormatProps): React.ReactElement => {
  return <span {...props}>{getFormattedValue({ value, id, currencyCode, compact })}</span>
}

export const ExpandedNumberFormat = (props: NumberFormatProps): React.ReactElement => {
  return <NumberFormat {...props} compact={false} />
}

export default NumberFormat
