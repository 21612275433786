import { colorMap, type BadgeColor } from '@precis-digital/kurama'
import { getPlatformDetailsByPlatform, PLATFORMS, type IPlatform } from 'dataSource'
import { type Platforms } from 'shared/api/accounts'
import { type ChannelGroupingResp, type ChannelGrouping, type ChannelItem } from 'shared/api/channelGroupings'
import { DATA_SOURCE_DIMENSIONS, type Dimension } from './constants/dimensions'
import { DATA_SOURCE_METRICS, type Metric } from './constants/metrics'
import { type UsedInConfig, type ChannelsMap, type ChannelsMapItem } from './types'
import { type AttributionResp } from 'shared/api/attributionModels'
import { USED_IN_CONFIG_TYPES } from './constants'
import { APP_ROUTES } from 'shared/routes'

/**
 * Returns the unique data source types in a given Channel Grouping
 */
export const getUniqueDataSources = (channelGrouping: ChannelGrouping): Platforms[] => {
  const allDataSources = Object.keys(channelGrouping) as Platforms[]
  const uniqueVals = [...new Set(allDataSources)]
  return uniqueVals
}

/**
 * Returns the channel item and index in the channel grouping for a given channel ID and data source
 * If the channel is not found, the function returns undefined for each value in the object.
 */
export const getChannelByIdAndDataSource = (
  channelGrouping: ChannelGrouping,
  channelId: string,
  dataSource: Platforms
): { channel: ChannelItem | undefined; index: number | undefined } => {
  const channelGroupingForDataSource = channelGrouping[dataSource]

  if (channelGroupingForDataSource === undefined) {
    return {
      channel: undefined,
      index: undefined
    }
  }

  const channelIndex = channelGroupingForDataSource.findIndex((channel) => channel.id === channelId)
  return {
    channel: channelGroupingForDataSource[channelIndex],
    index: channelIndex > -1 ? channelIndex : undefined
  }
}

/**
 * Returns a list of data sources supported in the Channel Grouping flow
 */
export const getSupportedDataSources = (): IPlatform[] => {
  return PLATFORMS.filter((dataSource) => dataSource.isSupportedByChannelGrouping)
}

/**
 * Returns the metric object for a given data source and metric ID
 */
export const getDataSourceMetric = (dataSource: Platforms, metricId: string): Metric | undefined => {
  return DATA_SOURCE_METRICS[dataSource].find((metric) => metric.id === metricId)
}

/**
 * Returns the dimension object for a given data source and dimension ID
 */
export const getDataSourceDimension = (dataSource: Platforms, dimensionId: string): Dimension | undefined => {
  return DATA_SOURCE_DIMENSIONS[dataSource].find((dimension) => dimension.id === dimensionId)
}

/**
 * Based on the existing channels we want to find a color that is not used by any of the existing channels
 * If all colors are used, we want to find the color that is used the least
 */
export const getNewChannelColor = (existingChannels?: ChannelItem[]): BadgeColor => {
  const existingColors: BadgeColor[] = existingChannels?.map((channel) => channel.color) ?? []

  for (const [color] of colorMap) {
    const occurrences = existingColors.filter((existingColor) => existingColor === color).length
    if (occurrences === 0) {
      // Color not in the array, therefore we pick it
      return color
    }
  }

  let minCount = Infinity
  let colorToPick: BadgeColor = 'pink'

  for (const [color] of colorMap) {
    const occurrences = existingColors.filter((existingColor) => existingColor === color).length

    if (occurrences < minCount) {
      minCount = occurrences
      colorToPick = color
    }
  }

  return colorToPick
}

/**
 * When creating a new channel, we want to find a unique ID that is not used by any of the existing channels
 * This function will return a new channel ID if the channel name is not found in the channel grouping or the ID of the existing channel
 */
export const getNewChannelId = (channelGrouping: ChannelGrouping, newChannelName: string): string => {
  let newChannelId: string | undefined
  Object.values(channelGrouping).forEach((channelItems) => {
    const channelItem = channelItems.find((channel) => channel.name === newChannelName)
    if (channelItem != null) {
      newChannelId = channelItem.id
    }
  })

  return newChannelId ?? crypto.randomUUID().replace(/-/g, '')
}

/**
 * Returns the channel item in the channel grouping for a given channel name
 */
export const getExistingChannelByName = (
  channelsMap: ChannelsMap,
  channelName: string
): ChannelsMapItem | undefined => {
  let channel: ChannelsMapItem | undefined

  Object.values(channelsMap).forEach((channelItem) => {
    if (channelItem.name.toLowerCase() === channelName.toLowerCase()) {
      channel = channelItem
    }
  })

  return channel
}

/**
 * Filters other config types that are using the same channel grouping
 */
export const getMatchingUsedInConfigs = ({
  channelGrouping,
  attributionConfigs = [],
  budgetOptimisers = []
}: {
  channelGrouping: ChannelGroupingResp
  attributionConfigs?: AttributionResp[]
  budgetOptimisers?: AttributionResp[]
}): UsedInConfig[] => {
  const matchingUsedInConfigs: UsedInConfig[] = []

  attributionConfigs.forEach((config) => {
    if (config.channelGroupingId === channelGrouping.id) {
      const analyticsAccountPlatformDetails = getPlatformDetailsByPlatform(config.analyticsAccount?.platform)
      matchingUsedInConfigs.push({
        id: config.id,
        name: config.name,
        href: APP_ROUTES.attributionModels.editConfigPage({
          configId: config.id.toString()
        }),
        connectedAccounts: [
          {
            name: analyticsAccountPlatformDetails.label,
            imageUrl: analyticsAccountPlatformDetails.iconUrl
          },
          ...config.adAccounts.map((adAccount) => {
            const platformDetails = getPlatformDetailsByPlatform(adAccount.platform)
            return {
              name: platformDetails.label,
              imageUrl: platformDetails.iconUrl
            }
          })
        ],
        updatedBy: config.updatedBy,
        updatedAt: config.updatedAt,
        runSchedule: config.runSchedule,
        type: USED_IN_CONFIG_TYPES.ATTRIBUTION
      })
    }
  })

  budgetOptimisers.forEach((config) => {
    if (config.channelGroupingId === channelGrouping.id) {
      matchingUsedInConfigs.push({
        id: config.id,
        name: config.name,
        href: APP_ROUTES.budgetOptimiser.editConfigPage({
          configId: config.id.toString()
        }),
        connectedAccounts: config.adAccounts.map((adAccount) => {
          const platformDetails = getPlatformDetailsByPlatform(adAccount.platform)
          return {
            name: platformDetails.label,
            imageUrl: platformDetails.iconUrl
          }
        }),
        updatedBy: config.updatedBy,
        updatedAt: config.updatedAt,
        runSchedule: config.runSchedule,
        type: USED_IN_CONFIG_TYPES.BUDGET_OPTIMISER
      })
    }
  })

  return matchingUsedInConfigs
}
