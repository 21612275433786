import {
  Accordion,
  Button,
  ChevronDownIcon,
  Divider,
  Flyout,
  Grid,
  Input,
  Link,
  Radio,
  SelectInput,
  SelectItem,
  Typography,
  styled
} from '@precis-digital/kurama'
import { Search } from '@precis-digital/kurama/src/components/Icons/assets/Search'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { Platforms, ClientAccountsResp } from 'shared/api/accounts'
import { DIMENSION_OPERATORS, type DimensionType, type OperatorType } from 'shared/constants/filterOperators'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { PlatformAvatar } from '.'
import { DATA_SOURCE_DIMENSIONS, DIMENSIONS_IN_THE_FILTER_LIST } from 'channelGrouping/constants/dimensions'
import { PLATFORM } from 'dataSource'
import { REGEX_HELP_LINK } from 'shared/constants/links'

interface FilterFlyoutProps {
  accounts: ClientAccountsResp[]
  onSubmit?: (data: FilterFormValues) => void
}

export interface FilterFormValues {
  selectedAccount: string
  selectedDimension: string
  operator: DimensionType | OperatorType
  value: string
}

const dimensionOperators = Object.values(DIMENSION_OPERATORS).map((dimension) => {
  return dimension
})

const dimensionForGa4 = DIMENSIONS_IN_THE_FILTER_LIST

export function getLabelsForPlatform(platform: Platforms): Array<{ label: string; id: string }> {
  const dimensions = platform === PLATFORM.GA4 ? dimensionForGa4 : DATA_SOURCE_DIMENSIONS[platform]
  return dimensions?.map((dimension) => dimension)
}

const SetupFilterFlyout = ({ accounts, onSubmit }: FilterFlyoutProps): React.ReactElement => {
  const screen = useOverScreen('attributionFilterFlyout')
  const { t } = useTranslation('attributionModel')
  const [search, setSearch] = useState<string>('')

  const { control, handleSubmit, setValue, formState, watch } = useForm<FilterFormValues>({
    defaultValues: {}
  })

  const dimensionFields = watch('operator')

  const isDimensionFieldRegex = dimensionFields === 'not_matches_regex' || dimensionFields === 'matches_regex'

  const [selectedAccount, setSelectedAccount] = useState<number>()

  const findPlatfromInChosenAccount = accounts.find((account) => account.id === selectedAccount)?.platform

  const dimensionFieldsByPLatform =
    findPlatfromInChosenAccount !== undefined ? getLabelsForPlatform(findPlatfromInChosenAccount) : []

  const regexHelperText = (
    <>
      <Typography variant="body2">{t('createModel.operatorRegex')}</Typography>
      <Link variant="body2" href={REGEX_HELP_LINK} target="_blank" rel="noreferrer">
        {t('createModel.regexLink')}
      </Link>
    </>
  )

  const handleValidForm = (data: FilterFormValues): void => {
    onSubmit?.(data)
  }

  return (
    <Flyout
      onClose={(): void => {
        screen.remove()
      }}
      isExpanded={screen.visible}
    >
      <Typography variant="h2">{t('createModel.addFilter')}</Typography>
      <Divider />
      <StyledAccordion
        Summary={<Typography variant="h3"> {t('createModel.selectAccount')}</Typography>}
        Details={
          <>
            <StyledInput
              fullWidth
              icon={<Search />}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              name="accountName"
              placeholder={t('createModel.findAccount')}
            />
            {accounts
              .filter((account) => account.name.toLowerCase().includes(search.toLowerCase()))
              .map((account, index) => (
                <Grid key={index} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Grid display="flex" flexDirection="row" gap="10px">
                    <PlatformAvatar account={account} />
                    <Typography variant="body2">{account.name}</Typography>
                  </Grid>

                  <Controller
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { name, value } }) => {
                      return (
                        <StyledRadio
                          value={account.name}
                          onClick={() => {
                            setValue(name, account.id.toString(), { shouldValidate: true })
                            setSelectedAccount(account.id)
                          }}
                          checked={account.id === Number(value)}
                        />
                      )
                    }}
                    name="selectedAccount"
                  />
                </Grid>
              ))}
          </>
        }
        expandIcon={<ChevronDownIcon />}
        expanded
      />
      <Divider />
      <StyledAccordion
        Summary={<Typography variant="h3"> {t('createModel.dimension')}</Typography>}
        Details={
          <>
            {dimensionFieldsByPLatform?.map((field) => (
              <Grid
                key={field.id}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2">{field.label}</Typography>

                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, value } }) => {
                    return (
                      <StyledRadio
                        value={field.id}
                        onClick={() => {
                          setValue(name, field.id, { shouldValidate: true })
                        }}
                        checked={field.id === value}
                      />
                    )
                  }}
                  name="selectedDimension"
                />
              </Grid>
            ))}
          </>
        }
        expandIcon={<ChevronDownIcon />}
      />
      <Divider />
      <StyledAccordion
        Summary={<Typography variant="h3">{t('createModel.filterCondition')}</Typography>}
        Details={
          <>
            <Typography variant="h5">{t('createModel.operator')}</Typography>
            <Controller
              name="operator"
              control={control}
              rules={{ required: true }}
              render={({ field: { name, value } }) => {
                return (
                  <SelectInput fullWidth value={value ?? ''} onChange={() => {}} name="operator">
                    {dimensionOperators.map((dimension, index) => (
                      <SelectItem
                        key={value}
                        value={dimension.id}
                        onClick={() => {
                          setValue(name, dimension.id, { shouldValidate: true })
                        }}
                      >
                        <Typography variant="body2">{dimension.label}</Typography>
                      </SelectItem>
                    ))}
                  </SelectInput>
                )
              }}
            />
            <Grid marginBottom="32px" />

            <Typography variant="h5">{t('createModel.value')}</Typography>
            {dimensionFields === undefined ? null : (
              <Typography variant="body2">
                {isDimensionFieldRegex ? regexHelperText : t('createModel.operatorNoRegex')}
              </Typography>
            )}
            <Grid marginBottom="8px" />
            <Controller
              name="value"
              control={control}
              rules={{ required: true }}
              render={({ field: { name, value } }) => {
                return (
                  <Grid>
                    <Input
                      fullWidth
                      name={name}
                      value={value}
                      onChange={(e) => {
                        setValue(name, e.target.value, { shouldValidate: true })
                      }}
                    />
                  </Grid>
                )
              }}
            />
          </>
        }
        expandIcon={<ChevronDownIcon />}
      />
      <Grid marginBottom="73px" />
      <StyledFlyoutFooter>
        <Divider />
        <Button
          fullWidth
          variant="filled"
          disabled={!formState.isValid}
          onClick={() => {
            void handleSubmit(handleValidForm)()
            screen.remove()
          }}
        >
          {t('createModel.applyFilter')}
        </Button>
      </StyledFlyoutFooter>
    </Flyout>
  )
}

const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  bottom: 0,
  marginTop: 'auto',
  width: '22rem',
  paddingBottom: '1rem',
  div: {
    ':first-of-type': {
      padding: 0,
      hr: {
        margin: '0 0 1rem 0'
      }
    }
  }
}))

const StyledInput = styled(Input)(({ theme }) => ({
  marginBottom: '16px',
  paddingRight: '9px'
}))

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: '9px 0px 9px 0px'
}))

const StyledAccordion = styled(Accordion)(() => ({
  '::before': {
    backgroundColor: 'transparent'
  },
  '.MuiAccordionDetails-root': {
    padding: '0',
    '.MuiAccordionSummary-content': {
      margin: '0'
    }
  }
}))

export default OverScreen.create(SetupFilterFlyout)
