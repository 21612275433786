import { PLATFORMS, type IPlatform, type CREDENTIAL_ID_KEY, type ERROR_KEY } from 'dataSource/constants'
import { type Platforms, type ClientAccountsResp } from 'shared/api/accounts'
import { type ExternalAccount } from 'shared/api/credentials'
import { type IAccountsList } from './components/Popup/AccountSelectionPopup'
import { clone } from 'ramda'
import { type Client } from 'shared/context/ClientContext'

export const getPlatformDetailsByLabel = (label: string): IPlatform => {
  return PLATFORMS.find((dataSource) => dataSource.label === label) as IPlatform
}

export const getPlatformDetailsByPlatform = (platform: string | undefined): IPlatform => {
  if (platform === 'bing') {
    platform = 'microsoft_advertising'
  }
  return PLATFORMS.find((dataSource) => dataSource.platform === platform) as IPlatform
}

export const isPartOfGooglePlatform = (platform: string): boolean => {
  return (
    platform === 'dv360' ||
    platform === 'analytics' ||
    platform === 'ga4' ||
    platform === 'gcp' ||
    platform === 'google' ||
    platform === 'merchant_center' ||
    platform === 'google_sheets' ||
    platform === 'google_search_console'
  )
}

export const getResponseKeyFromUrl = (url: string, key: typeof CREDENTIAL_ID_KEY | typeof ERROR_KEY): string | null => {
  const urlObject = new URL(url)
  return urlObject.searchParams.get(key)
}

export const mapToAccounts = (
  accounts: ExternalAccount[] | ClientAccountsResp[],
  currentClient: Client,
  platform: Platforms
): IAccountsList[] => {
  return accounts.map((account) => {
    const mappedAccount = clone(account) as IAccountsList
    mappedAccount.selected = false
    mappedAccount.clientId = currentClient.id
    mappedAccount.platform = platform
    mappedAccount.id = account.id.toString()
    return mappedAccount
  })
}

/**
 * Remove any HTML tags, attributes and event handlers or inline script in the string
 */
export const sanitizePostMessageContent = (message: string | undefined): string | undefined => {
  return message?.replace(/<[^>]*>/g, '')?.replace(/on\w+="[^"]*"/g, '')
}

/**
 * This function checks whether the supplied lowercase searchQuery matches account.DisplayName or account.name or account-id
 */
export const SearchQueryMatchesCriteria =
  (searchValueLowerCase: string) =>
  (account: IAccountsList): boolean => {
    const accountName = account.displayName !== undefined ? account.displayName : account.name
    const isNameMatch = Boolean(accountName?.toLowerCase()?.includes(searchValueLowerCase))
    const isRegionIdMatch = Boolean(account.regionId?.toLowerCase()?.includes(searchValueLowerCase))
    const isCountryCodeMatch = Boolean(account.countryCode?.toLowerCase()?.includes(searchValueLowerCase))
    const isIdMatch = Boolean(account.id.toLowerCase().includes(searchValueLowerCase))
    return isNameMatch || isIdMatch || isRegionIdMatch || isCountryCodeMatch
  }

export const areAllExternalAccountsConnected = (
  dataSources: IAccountsList[],
  externalAccounts: ExternalAccount[]
): boolean => {
  if (dataSources.length !== externalAccounts.length) {
    return false
  }
  const externalAccountIds = new Set(externalAccounts.map((account) => account.id))

  for (let i = 0; i < dataSources.length; i++) {
    const dataSourceAccountId = dataSources[i].externalAccountId

    if (!externalAccountIds.has(dataSourceAccountId)) {
      return false
    }
  }

  return true
}

/**
 * Validates a GCP Project ID.
 *
 * @param {string} projectId - The GCP Project ID to be validated.
 * @returns {boolean} - Returns true if the Project ID is valid, false otherwise.
 *
 * @description
 * This function checks the validity of a GCP Project ID based on the following criteria:
 * 1. The length of the string must be between 6 and 30 characters.
 * 2. The string must start with a lowercase letter.
 * 3. The string must only contain lowercase letters, digits, and hyphens.
 * 4. The string must not end with a hyphen.
 */
export const isValidGcpProjectId = (projectId: string): boolean => {
  const minLength = 6
  const maxLength = 30
  const regex = /^[a-z][a-z0-9-]*[a-z0-9]$/

  return projectId.length >= minLength && projectId.length <= maxLength && regex.test(projectId)
}
