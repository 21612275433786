import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'dv360_performance_report',
  active: true,
  label: 'DV360 Performance Report',
  category: 'Platform specific',
  description:
    'The DV360 Performance report represent the base reporting for DV360, and contains segmented reporting on partner level, advertiser level, IO, LI, floodlights, Creative and App/Url and more',
  image_url:
    'https://lh6.googleusercontent.com/uIH3puwZaHDbGp2T4RM7GYNZ4SB8ZMmL5iup2vLgyQuVRMP9wLNfEA2MYZo_DI1Ny6s=w2400',
  authors: [
    {
      name: 'Tobias Hink',
      email: 'hink@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjyNEc4WJk3HgY11avoS-2opMitZKNbLBZcaWvS=s126-p-k-rw-no'
    },
    {
      name: 'Joella Berg',
      email: 'joella@precisdigital.com',
      image_url: 'https://drive.google.com/uc?id=1uX3nOAOFswYoVXn3gqg3DErkA4xEC2uY'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2396782606',
    link_description: 'Knowledge Hub: DV360 Performance Report'
  },
  sources: {
    dv360: {
      reports: [
        'campaign_performance_base',
        'creative_performance',
        'app_url_performance_v4_only',
        'reach_frequency_io_level',
        'reach_frequency_li_level',
        'audience_performance',
        'brand_safety'
      ]
    }
  },
  inputs: {
    dv360: [
      {
        id: 'dv360CustomChannelGrouping',
        type: 'case_statement',
        label: 'Custom Channel Grouping',
        tooltip: 'The Channel Grouping that defines your campaigns, IO, LI etc.',
        required: true,
        mutable: true,
        dimensions: [
          {
            value: 'filter_advertiser',
            label: 'Advertiser ID',
            type: 'string'
          },
          {
            value: 'filter_partner',
            label: 'Partner ID',
            type: 'string'
          },
          {
            value: 'filter_advertiser_name',
            label: 'Advertiser Name',
            type: 'string'
          },
          {
            value: 'filter_media_plan_name',
            label: 'Media Plan Name',
            type: 'string'
          },
          {
            value: 'filter_insertion_order_name',
            label: 'Insertion Order name',
            type: 'string'
          },
          {
            value: 'filter_line_item_name',
            label: 'Line Item Name',
            type: 'string'
          }
        ]
      },
      {
        id: 'dv360CustomMarketGrouping',
        type: 'case_statement',
        label: 'Custom Market Grouping',
        tooltip: 'The Market Grouping that defines your Markets for DV360',
        required: true,
        mutable: true,
        dimensions: [
          {
            value: 'filter_advertiser',
            label: 'Advertiser ID',
            type: 'string'
          },
          {
            value: 'filter_partner',
            label: 'Partner ID',
            type: 'string'
          },
          {
            value: 'filter_advertiser_name',
            label: 'Advertiser Name',
            type: 'string'
          },
          {
            value: 'filter_media_plan_name',
            label: 'Media Plan Name',
            type: 'string'
          },
          {
            value: 'filter_insertion_order_name',
            label: 'Insertion Order name',
            type: 'string'
          },
          {
            value: 'filter_line_item_name',
            label: 'Line Item Name',
            type: 'string'
          }
        ]
      }
    ],
    other: [
      {
        id: 'targetCurrency',
        type: 'dropdown',
        options: [
          {
            value: 'SEK',
            label: 'Swedish Kronar (SEK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197564.png'
          },
          {
            value: 'DKK',
            label: 'Danish Kroner (DKK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197565.png'
          },
          {
            value: 'NOK',
            label: 'Norwegian Kroner (NOK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197579.png'
          },
          {
            value: 'EUR',
            label: 'Euros (EUR)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197615.png'
          },
          {
            value: 'USD',
            label: 'US Dollar (USD)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197484.png'
          }
        ],
        label: 'Target Currency',
        defaultValue: 'EUR',
        tooltip: 'The currency to transform all currency-based columns (cost, revenue, etc) to.',
        required: true,
        mutable: true
      }
    ]
  },
  dashboard: {
    template_report_id: '0b8afa6b-2307-4e4e-9473-a3650cfca038',
    report_name_template: '{name} - DV360 Performance Report - {today}',
    datasources: [
      {
        alias: 'ds180',
        datasourceName: '{name} - DV360 Campaign Base',
        type: 'TABLE',
        tableId: 'dv360_performance_report__base_report_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'ds269',
        datasourceName: '{name} - DV360 Audience',
        type: 'TABLE',
        tableId: 'dv360_performance_report__audience_report_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'ds291',
        datasourceName: '{name} - DV360 Brand Safety',
        type: 'TABLE',
        tableId: 'dv360_performance_report__brand_safety_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'ds250',
        datasourceName: '{name} - DV360 APP/URL',
        type: 'TABLE',
        tableId: 'dv360_performance_report__app_url_report_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      }
    ]
  }
}

export default config
