import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'shopping_insight_bestseller_report',
  active: true,
  label: 'Shopping Insights Bestseller Report',
  category: 'Product analysis',
  description:
    'This solution is one of powerful resources that enables us to provide detailed information to our clients regarding what products that should be prioritized as well as brought in to help boost sales.',
  image_url:
    'https://lh4.googleusercontent.com/MjlG5kIQUN8v7e3YhkeBeHkEGulGRXsUspAP0J5rkQrRLySGuYOYckRqBFEpQMvXdAQ=w2400',
  authors: [
    {
      name: 'Tobias Hink',
      email: 'hink@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjyNEc4WJk3HgY11avoS-2opMitZKNbLBZcaWvS=s126-p-k-rw-no'
    },
    {
      name: 'Richard Drott',
      email: 'richard@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjdVf50meWLWtrD7fZh-C9E7wxLY93SoNKssggIBg=s126-p-k-rw-no'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2371092559/Shopping+Insights+Bestseller+Report',
    link_description: 'Knowledge Hub: Shopping Insights Bestsellers Report'
  },
  dashboard: {
    template_report_id: 'ed683ae0-d16d-4d1d-b73d-7dba000c6bc0',
    report_name_template: 'copy of Shopping Insights Bestsellers Report - {today}',
    datasources: [
      {
        alias: 'ds304',
        datasourceName: 'copy of shopping_insight_bestseller_report',
        type: 'TABLE',
        tableId: 'shopping_insight_bestseller_report_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      }
    ]
  },
  sources: {
    merchant_center: {
      reports: ['BestSellers_TopProducts_Inventory']
    }
  }
}

export default config
