import {
  ArrowRightIcon,
  Button,
  Divider,
  DotsLoader,
  Grid,
  Input,
  SearchIcon,
  Typography,
  styled
} from '@precis-digital/kurama'
import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { type Client, getRootPath, useCurrentClient } from 'shared/context/ClientContext'
import { Controller, useForm } from 'shared/reactHookForm'
import { useQueryClients } from 'workspace/api'
import { StyledCard, StyledCardContent, StyledGraySpan } from './Flyout/styles'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import { useTranslation } from 'shared/translations'
import { useChangePath, useQueryString, useReload } from 'shared/components/Router'
import { UnstyledLink } from 'shared/components/UnstyledLink'

interface WorkspaceSwitcherListProps {
  onClientChange?: (client: Client) => void
  path?: string
}

const WorkspaceSwitcherList = ({ onClientChange, path }: WorkspaceSwitcherListProps): ReactElement => {
  const { t } = useTranslation('workspace')

  const { data: clients, isLoading, isSuccess } = useQueryClients(true)
  const { currentClient } = useCurrentClient()
  const { pathname } = useChangePath()
  const rootPath = getRootPath(pathname)
  const reload = useReload()

  const { query } = useQueryString()

  useEffect(() => {
    if (query.clientId != null && path == null && query.clientId !== currentClient.id.toString()) {
      reload()

      const newClient = clients?.find((client) => client.id.toString() === query.clientId)

      newClient != null && onClientChange?.(newClient)
    }
  }, [clients, currentClient.id, onClientChange, path, query.clientId, reload])

  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (searchInputRef.current != null) {
      searchInputRef.current.focus()
    }
  }, [searchInputRef])

  const [searchInput, setSearchInput] = useState('')
  let sortedClients = clients?.sort((a, b) => a.name.localeCompare(b.name))

  const handleSearchChange = (value: string): void => {
    setSearchInput(value)
  }

  if (searchInput.length > 0) {
    const escapedSearchInput = searchInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLowerCase()
    sortedClients = sortedClients?.filter((client: Client) => {
      return client.name.toLowerCase().match(escapedSearchInput)
    })
  }

  const searchNotFound = sortedClients?.length === 0 && searchInput.length > 0

  const { control, setValue } = useForm<{ workspaceName: string }>({})

  return (
    <>
      <Grid width="350px" maxWidth="100%">
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <Controller
            name="workspaceName"
            control={control}
            render={({ field: { name, value } }) => {
              return (
                <Input
                  assistive
                  name="workspaceName"
                  value={value}
                  fullWidth
                  placeholder={t('placeholders.findWorkspace')}
                  onChange={(e) => {
                    setValue(name, e.target.value)
                    handleSearchChange(e.target.value)
                  }}
                  icon={<SearchIcon />}
                  inputProps={{
                    ref: searchInputRef
                  }}
                />
              )
            }}
          />
        </form>
      </Grid>
      <Grid width="350px" maxWidth="100%">
        <Divider />
      </Grid>
      {isLoading && <DotsLoader />}
      {!isLoading && isSuccess ? (
        <>
          {searchNotFound ? (
            <StyledSearchNotFound>
              <Typography variant="body2">{t('descriptions.searchNotFound')}</Typography>
              <Typography variant="h3">“{searchInput}”</Typography>
            </StyledSearchNotFound>
          ) : (
            sortedClients?.map((client) => {
              const active = client.id !== currentClient?.id

              return (
                <UnstyledLink key={client.id} href={`${path ?? rootPath}?clientId=${client.id}`}>
                  <StyledCard key={client.id} elevation="flat" width="350px" condensedMargin active={active}>
                    <StyledCardContent active={active}>
                      <AvatarWithLabel size="medium" client={client} />
                      <Grid>
                        <Typography variant="h3">{client.name}</Typography>
                        <Typography variant="body3">
                          <StyledGraySpan>{client.url}</StyledGraySpan>
                        </Typography>
                      </Grid>
                    </StyledCardContent>
                    {active && (
                      <StyledGridButtonContainer>
                        <Button onClick={() => {}} variant="tonal" leftIcon={<ArrowRightIcon />} />
                      </StyledGridButtonContainer>
                    )}
                  </StyledCard>
                </UnstyledLink>
              )
            })
          )}
        </>
      ) : null}
    </>
  )
}

const StyledGridButtonContainer = styled(Grid)(() => ({
  '> button': {
    paddingLeft: '8px',
    paddingRight: '8px'
  }
}))

const StyledSearchNotFound = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  overflowWrap: 'anywhere'
}))

export default WorkspaceSwitcherList
