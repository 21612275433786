import { useEffect, type ReactElement, type ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { client as reactQueryClient } from 'shared/reactQuery'
import DashboardFrame from 'shared/components/DashboardFrame'
import { AuthProvider } from 'shared/context/AuthContext'
import { ClientProvider } from 'shared/context/ClientContext'
import { AppProvider } from '@precis-digital/kurama'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider } from 'react-query'
import { appWithTranslation } from 'shared/translations'
import { OverScreen } from 'shared/overScreens/niceModalReact'
import { GoogleTagManagerScript } from 'shared/components/GoogleTagManager'
import ErrorReporterProvider from 'shared/context/ErrorReporterContext'
import { ErrorFallback, type FallbackProps } from 'shared/components/ErrorFallback'
import initLogRocket from 'shared/logRocket'
import useBuildId from 'shared/hooks/useBuildId'
import logger from 'shared/logger'
import { useRouter } from 'next/router'
import { reloadCurrentPage } from 'shared/utils'
import { MimirProvider } from 'shared/context/MimirContext'
import { Toaster } from 'shared/components/Toaster'
import { IntercomScript } from 'shared/components/IntercomScript'

initLogRocket()

const log = logger().child({
  namespace: 'dashboard'
})

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout): ReactElement => {
  log.info('main app')
  const { shouldReload } = useBuildId()
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (): void => {
      void (async () => {
        const shouldReloadPage = await shouldReload()
        if (shouldReloadPage !== undefined && shouldReloadPage) {
          reloadCurrentPage()
        }
      })()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [shouldReload, router.events])

  const getLayout =
    Component.getLayout == null
      ? (page: ReactElement) => {
          return (
            <ClientProvider>
              <OverScreen.Provider>
                <MimirProvider>
                  <DashboardFrame>{page}</DashboardFrame>
                </MimirProvider>
              </OverScreen.Provider>
            </ClientProvider>
          )
        }
      : Component.getLayout

  return (
    <>
      <AppProvider>
        <ErrorReporterProvider
          FallbackUI={({ error, resetError }: FallbackProps): ReactElement => (
            <ErrorFallback error={error} resetError={resetError} hasDashboardFrame />
          )}
        >
          <QueryClientProvider client={reactQueryClient()}>
            <AuthProvider>{getLayout(<Component {...pageProps} />)}</AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </ErrorReporterProvider>
        <Toaster />
      </AppProvider>
      <GoogleTagManagerScript />
      <IntercomScript />
    </>
  )
}

export default appWithTranslation(App)
