import { Grid, Link, Typography } from '@precis-digital/kurama'
import Image from 'next/image'
import { type ReactElement } from 'react'

import legoBlocks from 'public/assets/images/lego_blocks.png'
import { useTranslation } from 'shared/translations'
import { USER_PERMISSIONS_DOCUMENTATION_LINK } from 'shared/constants/links'

const ViewerAccessPrerequisitePage = ({ title }: { title: string }): ReactElement => {
  const { t } = useTranslation('common')

  return (
    <Grid
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      flexDirection="column"
      container
      gap="24px"
    >
      <Grid position="relative" width="30vw" height="49vh">
        <Image src={legoBlocks.src} alt="check-list" fill />
      </Grid>
      <Grid
        item
        display="flex"
        width="50%"
        gap="24px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Typography variant="h2" title={title}>
          {title}
        </Typography>
        <Typography variant="body1" title={t('noPrerequisites.viewerDescription')}>
          {t('noPrerequisites.viewerDescription')}{' '}
          <Link variant="body1" href={USER_PERMISSIONS_DOCUMENTATION_LINK} target="_blank" rel="noreferrer">
            {t('noPrerequisites.learnMore')}
          </Link>
          .
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ViewerAccessPrerequisitePage
