import { forwardRef } from 'react'
import { StyledSkeleton } from './styles'
import { SkeletonProps } from './types'

const Skeleton = forwardRef<HTMLSpanElement, SkeletonProps>(
  ({ height, width = '100%', variant = 'rounded', ...props }, ref) => {
    return (
      <StyledSkeleton
        height={height}
        variant={variant}
        width={width}
        {...props}
        ref={ref}
        data-testid="skeleton-loader"
      />
    )
  }
)

export default Skeleton
