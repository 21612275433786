import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { ConditionInputMultiTextProps } from './types'
import { StyledAutocompleteInput, StyledTextField } from './style'
import Grid from '../../Grid'
import Chip from '../../Chip'
import Typography from '../../Typography'
import { StyledButtonAction, StyledGridContainer } from '../styles'
import { InputState } from '../types'
import { ArrowRightIcon } from '../../Icons'

const ConditionInputMultiText = ({
  value,
  onChange,
  onFocus,
  onBlur,
  label,
  placeholder,
  fullWidth,
  hasError = false,
  width = '472px',
  saved = false,
  autoFocus = false
}: ConditionInputMultiTextProps): ReactElement => {
  const [inputState, setInputState] = useState<InputState>('enabled')
  const [localValue, setLocalValue] = useState<string[] | undefined>(value)
  const [localInputValue, setLocalInputValue] = useState<string | undefined>(undefined)

  const textFieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocus) {
      handleSelectClick()
    }
  }, [])

  const handleSelectClick = (): void => {
    setInputState('focused')
  }

  const handleSave = (): void => {
    onChange(localValue)
    handleClose()
  }

  const handleClear = (): void => {
    setLocalValue(value)
    setLocalInputValue('')
    handleClose()
  }

  const handleClose = (): void => {
    setInputState('enabled')
    textFieldRef.current?.blur()
  }

  useEffect(() => {
    if (inputState === 'focused') {
      textFieldRef.current?.focus()
      if (onFocus != null) {
        onFocus()
      }
    } else if (inputState === 'enabled') {
      if (onBlur != null) {
        onBlur()
      }
    }
  }, [inputState])

  useEffect(() => {
    if (saved) {
      handleClose()
    }
  }, [saved])

  return saved ? (
    <Grid
      container
      height="40px"
      width={fullWidth === true ? '100%' : width}
      alignItems="center"
      justifyContent="flex-end"
      gap="8px"
    >
      {value?.map((item, index) => (
        <Chip key={index} label={item} />
      ))}
    </Grid>
  ) : (
    <StyledGridContainer
      state={inputState}
      onClick={inputState === 'enabled' ? handleSelectClick : undefined}
      width={fullWidth === true ? '100%' : width}
      hasError={hasError}
    >
      <Grid width="100%">
        <Typography variant="h4">{label}</Typography>
        <StyledAutocompleteInput
          key={inputState}
          value={localValue}
          onChange={(e, value) => {
            setLocalValue(value as string[])
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && textFieldRef.current?.value === '') {
              handleSave()
            }

            if (e.key === 'Escape') {
              handleClear()
            }
          }}
          options={[]}
          freeSolo
          multiple
          clearIcon={null}
          renderInput={(params) => (
            <StyledTextField
              inputRef={textFieldRef}
              {...params}
              value={localInputValue}
              onChange={(e) => setLocalInputValue(e.target.value)}
              variant="standard"
              disabled={inputState === 'enabled'}
              placeholder={inputState === 'enabled' && (value?.length ?? 0) === 0 ? placeholder : 'add new'}
            />
          )}
          renderTags={(values, getTagProps) => {
            return (values as string[]).map((value, index) => (
              <Chip
                key={value + inputState}
                label={value?.toString()}
                onDelete={inputState === 'focused' ? (e) => getTagProps({ index }).onDelete(e) : undefined}
              />
            ))
          }}
        />
      </Grid>
      {inputState === 'focused' && localValue != null ? (
        <StyledButtonAction scheme="light" variant="filled" onClick={handleSave}>
          <ArrowRightIcon />
        </StyledButtonAction>
      ) : (
        <></>
      )}
    </StyledGridContainer>
  )
}

export default ConditionInputMultiText
