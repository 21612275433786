import { format } from 'date-fns'

// In BO th campaign name includes the platform name and the campaign name so we need to extract the campaign name
export const extractCampaignNameFromDimensionCampaign = (boCampaignDimension: string): string => {
  const delimiter = ' - '
  const startIndexOfFirstDelimiter = boCampaignDimension.indexOf(delimiter)
  if (startIndexOfFirstDelimiter === -1) {
    return boCampaignDimension
  }
  return boCampaignDimension.substring(startIndexOfFirstDelimiter + delimiter.length)
}

export const computeBudgetOptimiserDateRange = (
  attributionDates: Array<{ distinctTargetDate: string }>
): {
  minDate: string
  maxDate: string
} => {
  if (attributionDates != null && attributionDates.length > 0) {
    const distinctDates = attributionDates.map((item) => item.distinctTargetDate)
    const minDate = format(new Date(distinctDates[0]), 'yyyy-MM-dd')
    const maxDate = format(new Date(distinctDates[distinctDates.length - 1]), 'yyyy-MM-dd')
    return { minDate, maxDate }
  }

  const minDate = format(new Date(1999, 0, 1), 'yyyy-MM-dd')
  const maxDate = format(new Date(9999, 11, 31), 'yyyy-MM-dd')

  return { minDate, maxDate }
}

export const computeBudgetOptimiserMissingDates = (
  attributionDates: Array<{ distinctTargetDate: string }>
): string[] => {
  const distinctDates = attributionDates.map((item) => format(new Date(item.distinctTargetDate), 'yyyy-MM-dd'))
  const minDate = new Date(distinctDates[0])
  const maxDate = new Date(distinctDates[distinctDates.length - 1])
  const missingDates = []
  for (let date = minDate; date <= maxDate; date = new Date(date.setDate(date.getDate() + 1))) {
    const formattedDate = format(date, 'yyyy-MM-dd')
    if (!distinctDates.includes(formattedDate)) {
      missingDates.push(formattedDate)
    }
  }
  return missingDates
}
