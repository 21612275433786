import React, { type ComponentType, useEffect, type ReactElement } from 'react'
import { useAuth, type User } from 'shared/context/AuthContext'
import { getStoredAuthToken } from 'auth/authToken'
import logger from 'shared/logger'
import ProgressBarLoader from 'shared/components/Loader/ProgressBarLoader'
import { getReturnTo, noop } from 'shared/utils'
import { APP_ROUTES } from 'shared/routes'
import AuthRequiredLayout from 'shared/components/AuthRequiredLayout'

const log = logger().child({
  namespace: 'WithPageAuthRequired'
})

const defaultOnRedirecting = (): ReactElement => <ProgressBarLoader />

export interface WithPageAuthRequiredOptions {
  returnTo?: string
  onRedirecting?: () => JSX.Element
}

export type WithPageAuthRequiredProps = Record<string, any>

export interface UserProps {
  user: User
}

const redirect = (returnTo?: string): void => {
  window.location.assign(`${APP_ROUTES.auth.signInPage}?returnTo=${encodeURIComponent(getReturnTo(returnTo))}`)
}

/**
 * ```ts
 * const MyProtectedPage = withPageAuthRequired(MyPage)
 * ```
 *
 * When you wrap your pages in this HOC and an anonymous user visits your page
 * they will be redirected to the sign in page and then returned to the page they were redirected from (after login).
 *
 */
export type WithPageAuthRequired = <P extends WithPageAuthRequiredProps>(
  Component: ComponentType<P & UserProps>,
  options: WithPageAuthRequiredOptions
) => React.FC<P>

const handleAuthError = (logOut: () => void): void => {
  logOut()
  redirect(APP_ROUTES.auth.signInPage)
}

const withPageAuthRequired: WithPageAuthRequired = (Component, options) => {
  return function WithPageAuthRequired(props): JSX.Element {
    const { returnTo, onRedirecting = defaultOnRedirecting } = options
    const { currentUser, error, mustAuth, isSuccess, logOut } = useAuth()

    useEffect(() => {
      log.info(mustAuth.toString())
      log.info(currentUser?.toString() ?? '')

      if (isSuccess && mustAuth) {
        redirect(returnTo)
        return
      }

      if (getStoredAuthToken() == null && mustAuth) {
        redirect(returnTo)
      }
    }, [mustAuth, isSuccess, returnTo, currentUser])

    useEffect(() => {
      error?.status_code != null && mustAuth ? handleAuthError(logOut) : noop()
    }, [mustAuth, error, logOut])

    if (isSuccess && !mustAuth && currentUser != null) {
      return (
        <AuthRequiredLayout>
          <Component user={currentUser} {...props} />
        </AuthRequiredLayout>
      )
    }

    return onRedirecting()
  }
}

export default withPageAuthRequired
