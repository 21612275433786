import { Button, PlusIcon, Skeleton } from '@precis-digital/kurama'
import React, { type MouseEvent, type ReactElement } from 'react'
import { type CreateNewButtonProps } from './types'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'
import { useTranslation } from 'shared/translations'

export type ListViewCreateNewButtonProps = CreateNewButtonProps & {
  isLoading?: boolean
  isLoadingPreRequisites?: boolean
}

const ListViewCreateNewButton = ({
  onClick,
  label,
  hasEditorAccess,
  isLoading = false,
  tooltipTitle,
  isLoadingPreRequisites = false,
  href
}: ListViewCreateNewButtonProps): ReactElement => {
  const { t } = useTranslation('common')

  tooltipTitle = tooltipTitle ?? t('noPermissionMessage')

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    if (onClick != null) {
      e.preventDefault()
      onClick(e)
    }
  }

  if (isLoading || isLoadingPreRequisites) {
    return <Skeleton width={200} height={40} />
  }

  return (
    <Button
      href={href}
      LinkComponent={UnStyledRetainDefaultQueryLink}
      disabled={!hasEditorAccess}
      variant="filled"
      onClick={handleButtonClick}
      rightIcon={<PlusIcon />}
      tooltipProps={{
        title: tooltipTitle,
        kind: 'singleline',
        disabled: hasEditorAccess
      }}
    >
      {label}
    </Button>
  )
}

export default ListViewCreateNewButton
