import {
  Badge,
  Button,
  CancelBkgIcon,
  Card,
  type ConditionalBuilderRowState,
  Divider,
  Grid,
  SingleSelectDropdown,
  Typography,
  styled
} from '@precis-digital/kurama'
import React, { useMemo, type ReactElement } from 'react'
import { type ChannelItem } from 'shared/api/channelGroupings'
import ChannelNameInCard from './ChannelNameInCard'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { capitalize } from 'shared/utils'
import { type BadgeColor, badgeColors } from '@precis-digital/kurama/src/components/Badge/types'
import ChannelsTableCardConditionBuilder from './ChannelsTableCardConditionBuilder'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import { getExistingChannelByName } from 'channelGrouping/utils'
import { useTranslation } from 'shared/translations'
import ChannelNameAutocomplete from './ChannelNameAutocomplete'

interface ChannelsTableNewChannelCardProps {
  newChannel: ChannelItem
  indexForNewChannel: number
  setNewChannel: (newChannel: ChannelItem | undefined) => void
  setHasCreatedFirstChannel: (hasCreatedFirstChannel: boolean) => void
}

const ChannelsTableNewChannelCard = ({
  newChannel,
  setNewChannel,
  indexForNewChannel,
  setHasCreatedFirstChannel
}: ChannelsTableNewChannelCardProps): ReactElement => {
  const { updateChannelAcrossDataSources, channelsMap } = useCurrentChannelGrouping()
  const { createNewChannel, dataSourceItem } = useCurrentChannelGroupingDataSource()
  const { t } = useTranslation('channelGrouping')

  const handleClose = (): void => {
    setNewChannel(undefined)
  }

  const channelWithSameNameAlreadyExistsInDataSource = useMemo(() => {
    return dataSourceItem.channels.some((channel) => channel.name.toLowerCase() === newChannel.name.toLowerCase())
  }, [dataSourceItem.channels, newChannel.name])

  const handleSave = (value: Record<string, ConditionalBuilderRowState[]>): void => {
    if (dataSourceItem.channels.length === 0) {
      setHasCreatedFirstChannel(true)
    }

    const newChannelCopy = {
      ...newChannel,
      rules: value
    }
    createNewChannel(newChannelCopy, {
      onError: () => {
        setNewChannel(newChannelCopy)
      }
    })
    handleClose()
  }

  const otherChannelWithSameName = useMemo(() => {
    return getExistingChannelByName(channelsMap, newChannel.name)
  }, [channelsMap, newChannel.name])

  if (otherChannelWithSameName != null && otherChannelWithSameName.name !== newChannel.name) {
    setNewChannel({
      ...newChannel,
      name: otherChannelWithSameName.name
    })
  }

  if (otherChannelWithSameName != null && otherChannelWithSameName.color !== newChannel.color) {
    setNewChannel({
      ...newChannel,
      color: otherChannelWithSameName.color
    })
  }

  return (
    <StyledCardContainer elevation="mediumDepth">
      <Grid display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h3">{t('dataSourceFormView.channelsTab.tableCard.newChannel.title')}</Typography>
        <Button variant="text" onClick={handleClose} rightIcon={<CancelBkgIcon />} disabled={indexForNewChannel === 0}>
          {t('dataSourceFormView.channelsTab.tableCard.newChannel.cancelButton')}
        </Button>
      </Grid>
      <Grid display="flex" flexDirection="column" alignItems="flex-start" width="100%" marginTop="24px" gap="8px">
        <ChannelNameInCard channel={newChannel} isOtherChannel={false} isNewChannel />
        <Grid display="flex" flexWrap="nowrap">
          <StyledGridInputContainer>
            <ChannelNameAutocomplete
              channelName={newChannel.name}
              channelColor={newChannel.color}
              handleNameChange={(_, option) => {
                setNewChannel({
                  ...newChannel,
                  name: option?.value ?? ''
                })
              }}
              placeholder={t('dataSourceFormView.channelsTab.tableCard.channelNamePlaceholder')}
              noOptionsTextTemplate={t('dataSourceFormView.channelsTab.tableCard.channelNameNoOptionsTextTemplate')}
              acceptCustomInputTemplate={t(
                'dataSourceFormView.channelsTab.tableCard.channelNameAcceptCustomInputTemplate'
              )}
            />
          </StyledGridInputContainer>
          <StyledSingleSelectDropdown
            title={t('dataSourceFormView.channelsTab.tableCard.newChannel.colorSelectTitle')}
            buttonTitle={t('dataSourceFormView.channelsTab.tableCard.newChannel.colorSelectTitle')}
            options={badgeColors.map((color) => ({
              label: capitalize(color),
              value: color,
              icon: <Badge color={color} />
            }))}
            value={newChannel.color}
            buttonWidth="160px"
            onSelect={(value: string) => {
              setNewChannel({
                ...newChannel,
                color: value as BadgeColor
              })
              if (otherChannelWithSameName != null) {
                updateChannelAcrossDataSources(
                  otherChannelWithSameName.id,
                  { color: value as BadgeColor },
                  {
                    onError: () => {
                      setNewChannel({
                        ...newChannel,
                        color: otherChannelWithSameName.color
                      })
                    }
                  }
                )
              }
            }}
          />
        </Grid>
        <Divider />
        <Typography variant="h2">
          {t('dataSourceFormView.channelsTab.tableCard.newChannel.createChannelConditions')}
        </Typography>
        <ChannelsTableCardConditionBuilder
          rules={newChannel.rules}
          onSave={handleSave}
          isNewChannel
          disableSave={channelWithSameNameAlreadyExistsInDataSource}
        />
      </Grid>
    </StyledCardContainer>
  )
}

const StyledCardContainer = styled(Card)(() => ({
  marginTop: 0,
  padding: '20px',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '16px'
}))

const StyledGridInputContainer = styled(Grid)(() => ({
  width: '480px',
  '.MuiInputBase-root': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  '.MuiOutlinedInput-root fieldset': {
    borderRight: 'none',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}))
const StyledSingleSelectDropdown = styled(SingleSelectDropdown)(() => ({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}))

export default ChannelsTableNewChannelCard
