import {
  Accordion,
  Avatar,
  AvatarGroup,
  Card,
  ChevronDownIcon,
  Divider,
  Grid,
  Link,
  SelectInput,
  SelectItem,
  Slider,
  Toggle,
  Typography,
  styled
} from '@precis-digital/kurama'
import { useQueryChannelGrouping, useQueryChannelGroupings } from 'channelGrouping/api'
import useGetChannelsWithDataSources from 'channelGrouping/hooks/useGetChannelsWithDataSources'
import useGetExtendedDataSources from 'channelGrouping/hooks/useGetExtendedDataSources'
import { PLATFORM, PLATFORMS } from 'dataSource'
import { isEmpty, not } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { type Platforms } from 'shared/api/accounts'
import { AccordionFormStep, AccordionFormStepItem } from 'shared/components/AccordionFormStep'
import FormItem from 'shared/components/FormItem'
import { useCurrentClient } from 'shared/context/ClientContext'
import { getNumberOfRequiredInputsPerSection } from 'shared/reactHookForm'
import { useTranslation } from 'shared/translations'
import type { AttributionFormData, Channel, StepProps } from '..'
import {
  AD_STOCK_REDISTRIBUTION,
  AD_STOCK_REDISTRIBUTION_DEFAULT,
  AD_STOCK_REDISTRIBUTION_MAX,
  AD_STOCK_REDISTRIBUTION_MIN,
  CONVERSION_REDISTRIBUTION,
  CONVERSION_REDISTRIBUTION_MAX,
  CONVERSION_REDISTRIBUTION_MIN,
  CREDIT_INPUTS,
  EXPECTED_ROAS_REDISTRIBUTION,
  EXPECTED_ROAS_REDISTRIBUTION_DEFAULT,
  EXPECTED_ROAS_REDISTRIBUTION_MAX,
  EXPECTED_ROAS_REDISTRIBUTION_MIN,
  OLD_BING_ADS_PLATFORM_ID
} from '../constants'
import { allowToEdit, getInputRules } from '../utils'
import { APP_ROUTES } from 'shared/routes'

export type Included = Record<number, boolean>
export type Impression = Record<number, boolean>

const ChannelGroupingStep = ({
  stepNumber,
  title,
  isPreviousOrCurrentStep,
  handleUpdate,
  toggleEdit,
  defaultValues,
  isNewConfig,
  editModes,
  optimizeBudget
}: StepProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel')

  const {
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext<AttributionFormData>()

  const {
    fields: channelsWithDataSourcesFields,
    replace,
    update
  } = useFieldArray({
    control,
    keyName: '_id',
    name: 'channelGrouping.channels'
  })

  const { currentClient } = useCurrentClient()
  const formData = getValues()

  const { channelGrouping: selectedFormChannelGrouping, account: formAccount } = formData

  const { data: channelGroupings } = useQueryChannelGroupings(currentClient?.id)

  const { data: selectedChannelGrouping } = useQueryChannelGrouping(
    selectedFormChannelGrouping?.channelGroupingId,
    currentClient?.id
  )

  const hasNoSelectedChannelGrouping = selectedFormChannelGrouping?.channelGroupingId === undefined

  const extendedDataSourcesWithChannels = useGetExtendedDataSources(selectedChannelGrouping)

  const channelsWithDataSources = useGetChannelsWithDataSources(
    selectedChannelGrouping,
    extendedDataSourcesWithChannels
  )

  const chosenAccountsPlatforms = useMemo(() => {
    const platforms = [...(formAccount?.otherAccounts?.map((account) => account.platform) ?? [])]
    const analyticsPlatform = formAccount?.analyticsAccountPlatform
    if (analyticsPlatform != null) platforms.push(analyticsPlatform)
    return platforms
  }, [formAccount?.analyticsAccountPlatform, formAccount?.otherAccounts])

  const allChannelGroupingDetails = channelGroupings?.map((channel) => channel.channelGrouping) ?? []

  const channelGroupingPlatformAvatarUrls = allChannelGroupingDetails.map((channel) =>
    Object.keys(channel).map((platform) => PLATFORMS.find((p) => p.platform === platform)?.iconUrl)
  )
  const channelGroupingPlatformAvatarPlatforms = allChannelGroupingDetails.map((channel) =>
    Object.keys(channel).map((platform) => PLATFORMS.find((p) => p.platform === platform)?.platform)
  )

  const [expandedChannelsAccordions, setExpandedChannelsAccordions] = useState<Record<string, boolean>>(
    channelsWithDataSourcesFields.reduce((acc, channel) => {
      return {
        ...acc,
        [channel.name.toLowerCase()]: !isNewConfig
      }
    }, {})
  )

  const channelsWithDataSourcesAndInclude = useMemo(() => {
    return channelsWithDataSources
      .map((channel, index) => {
        const extraDetails = defaultValues?.channelGrouping.channels?.find(
          (formChannel: Channel) => formChannel.id === channel.id
        )

        return {
          ...channel,
          ...{
            include: extraDetails?.include ?? true,
            impression: extraDetails?.impression ?? false,
            redistribution: extraDetails?.redistribution ?? 0,
            expectedROAS: extraDetails?.expectedROAS ?? EXPECTED_ROAS_REDISTRIBUTION_DEFAULT,
            adStock: extraDetails?.adStock ?? AD_STOCK_REDISTRIBUTION_DEFAULT
          }
        }
      })
      .filter((channel) => {
        return channel.dataSources.some((dataSource) => chosenAccountsPlatforms.includes(dataSource.platform))
      })
  }, [defaultValues, channelsWithDataSources, chosenAccountsPlatforms])

  useEffect(() => {
    replace(channelsWithDataSourcesAndInclude)
  }, [channelsWithDataSourcesAndInclude, replace])

  const toggleIncluded = (index: number): void => {
    const include = channelsWithDataSourcesFields[index].include ?? false
    update(index, {
      ...channelsWithDataSourcesFields[index],
      include: !include,
      redistribution: getValues(`channelGrouping.channels.${index}.redistribution`),
      impression: getValues(`channelGrouping.channels.${index}.impression`),
      expectedROAS: getValues(`channelGrouping.channels.${index}.expectedROAS`),
      adStock: getValues(`channelGrouping.channels.${index}.adStock`)
    })
  }

  const toggleImpression = (index: number): void => {
    const impression = channelsWithDataSourcesFields[index]?.impression ?? false
    update(index, {
      ...channelsWithDataSourcesFields[index],
      impression: !impression,
      redistribution: getValues(`channelGrouping.channels.${index}.redistribution`),
      include: getValues(`channelGrouping.channels.${index}.include`),
      expectedROAS: getValues(`channelGrouping.channels.${index}.expectedROAS`),
      adStock: getValues(`channelGrouping.channels.${index}.adStock`)
    })
  }

  const getDescriptiveLabelFromValue = (
    distributor: Array<{ value: number; label: string; descriptiveLabel: string }>,
    value: number | undefined
  ): string => {
    const descriptiveLabel = distributor.find((item) => item.value === value)?.descriptiveLabel
    return descriptiveLabel ?? ''
  }

  const getTrueToYes = (value: boolean | undefined): string => {
    return value === true ? 'Yes' : 'No'
  }

  const chosenAccountsWithCorrectBingPlatform = chosenAccountsPlatforms.map((platform) =>
    platform === OLD_BING_ADS_PLATFORM_ID ? PLATFORM.MICROSOFT_ADVERTISING : platform
  )

  // check if every channel grouping has a platform that matches the chosen accounts
  const hasMatchingPlatformsWithChannelGroup = useMemo(() => {
    return chosenAccountsWithCorrectBingPlatform.every((platform) =>
      channelGroupings?.some((channelGrouping) => {
        return Object.keys(channelGrouping.channelGrouping).includes(platform)
      })
    )
  }, [chosenAccountsWithCorrectBingPlatform, channelGroupings])

  const isDDA = formData.model?.id === CREDIT_INPUTS.DDAV2
  const isRBA = formData.model?.id === CREDIT_INPUTS.RBA

  return (
    <AccordionFormStep stepName={t('form.step', { stepNumber })} title={title} expanded={isPreviousOrCurrentStep}>
      <AccordionFormStepItem
        title={t('createModel.selectChannelGrouping')}
        requiredFieldsTally={getNumberOfRequiredInputsPerSection({
          data: formData,
          sectionName: `channelGrouping`,
          errors,
          getInputRules
        })}
        t={t}
        isCreateEvent={isNewConfig}
        expanded={!isNewConfig}
      >
        <FormItem
          name="channelGrouping.channelGroupingId"
          label={t('createModel.channelGroup')}
          rules={{ required: true }}
          currentValue={selectedChannelGrouping?.name}
          helperText={
            <Typography variant="body3">
              {t('createModel.sharedHelperText.channelGrouping.fragment1')}
              <Link href={APP_ROUTES.customGroupings.basePage} target="_blank" rel="noreferrer">
                <Typography variant="body3">
                  {t('createModel.sharedHelperText.channelGrouping.linkFragment')}
                </Typography>
              </Link>
              {t('createModel.sharedHelperText.channelGrouping.fragment2')}
            </Typography>
          }
          {...(isEmpty(channelGroupings) && {
            helperText: (
              <Typography variant="body3">
                {t('form.noChannelGroupings.fragment1')}
                <Link href={APP_ROUTES.customGroupings.basePage} target="_blank" rel="noreferrer">
                  <Typography variant="body3">{t('form.noChannelGroupings.linkFragment')}</Typography>
                </Link>
                {t('form.noChannelGroupings.fragment2')}
              </Typography>
            )
          })}
          {...(not(hasMatchingPlatformsWithChannelGroup) && {
            helperText: (
              <Typography variant="body3">
                {t('form.noChannelGroupings.fragment1')}
                <Link href={APP_ROUTES.customGroupings.basePage} target="_blank" rel="noreferrer">
                  <Typography variant="body3">{t('form.noChannelGroupings.linkFragment')}</Typography>
                </Link>
                {t('form.noChannelGroupings.fragment2')}
              </Typography>
            )
          })}
          render={({ field }) => {
            return (
              <SelectInput disabled={isEmpty(channelGroupings)} fullWidth {...field} value={field.value?.toString()}>
                {channelGroupings?.map((channel, index) => {
                  const avatarUrls = channelGroupingPlatformAvatarUrls[index]
                  const platforms = channelGroupingPlatformAvatarPlatforms[index]

                  const hasMatchingPlatformsWithChannelGroup = chosenAccountsWithCorrectBingPlatform.every((platform) =>
                    platforms.includes(platform as Platforms)
                  )
                  return (
                    <SelectItem key={index} value={channel.id} disabled={!hasMatchingPlatformsWithChannelGroup}>
                      <Grid display="flex" gap="6px">
                        {channel.name}
                        {avatarUrls?.map((url, index) => {
                          if (url == null) return null
                          const hasAvatarPlatformInChosenPlatforms = chosenAccountsPlatforms.includes(
                            platforms[index] as string
                          )
                          return (
                            <StyledAvatar
                              faded={!hasAvatarPlatformInChosenPlatforms}
                              imageUrl={url}
                              kind="image"
                              size="small"
                              key={index}
                            />
                          )
                        })}
                      </Grid>
                    </SelectItem>
                  )
                })}
              </SelectInput>
            )
          }}
          editModes={editModes}
          toggleEdit={toggleEdit}
          handleUpdate={handleUpdate}
          control={control}
          isCreateEvent={isNewConfig}
          canEdit={allowToEdit(optimizeBudget, formData)}
        />
      </AccordionFormStepItem>
      <AccordionFormStepItem title={t('createModel.advanced')} isCreateEvent={isNewConfig}>
        {hasNoSelectedChannelGrouping ? (
          <Typography variant="h5">{t('createModel.channelGroupingAdvanced')}</Typography>
        ) : (
          <Grid>
            {channelsWithDataSourcesFields.map((channel, index) => {
              const name = channel.name.toLowerCase()
              const matchChannelToFormData = formData.channelGrouping.channels.find(
                (formChannel: Channel) => formChannel.id === channel.id
              )
              return (
                <StyledCard key={channel._id} included={channel.include === true}>
                  <StyledAccordion
                    expandIcon={<ChevronDownIcon />}
                    expanded={expandedChannelsAccordions[name]}
                    onChange={() => {
                      setExpandedChannelsAccordions((prevState) => {
                        return {
                          ...prevState,
                          [name]: !prevState[name]
                        }
                      })
                    }}
                    Summary={
                      <Grid>
                        <Grid display="flex" flexDirection="row" alignItems="center">
                          {channel.include === false ? (
                            <StyledTypography variant="h3"> {t('createModel.excluded')} - </StyledTypography>
                          ) : null}
                          <Typography variant="h3">{channel.name}</Typography>
                        </Grid>

                        <Grid display="flex" flexDirection="row" gap="12px" alignItems="center">
                          <AvatarGroup max={2} size="small">
                            {channel.dataSources?.map((dataSource) => {
                              if (dataSource.iconUrl != null) {
                                return (
                                  <Avatar
                                    key={dataSource.platform}
                                    size="small"
                                    kind="image"
                                    imageUrl={dataSource.iconUrl}
                                  />
                                )
                              }
                              return (
                                <Avatar
                                  key={dataSource.platform}
                                  size="small"
                                  kind="label"
                                  label={dataSource.label ?? ''}
                                />
                              )
                            })}
                          </AvatarGroup>
                          {!expandedChannelsAccordions[name] && !optimizeBudget && !isRBA && (
                            <StyledCardTypography variant="body3">
                              {' '}
                              {formData.channelGrouping.channels[index]?.impression === true &&
                                t('createModel.isImpression') + ' • '}
                              {getDescriptiveLabelFromValue(
                                CONVERSION_REDISTRIBUTION,
                                matchChannelToFormData?.redistribution
                              )}
                            </StyledCardTypography>
                          )}
                          {!expandedChannelsAccordions[name] && !optimizeBudget && isRBA && (
                            <StyledCardTypography variant="body3">
                              {' '}
                              {getDescriptiveLabelFromValue(
                                EXPECTED_ROAS_REDISTRIBUTION,
                                matchChannelToFormData?.expectedROAS
                              )}
                              {' • '}
                              {getDescriptiveLabelFromValue(AD_STOCK_REDISTRIBUTION, matchChannelToFormData?.adStock)}
                            </StyledCardTypography>
                          )}
                        </Grid>
                      </Grid>
                    }
                    Details={
                      <>
                        {optimizeBudget && (
                          <FormItem
                            name={`channelGrouping.channels.${index}.include`}
                            xs={12}
                            render={({ field: { name, value } }) => {
                              return (
                                <Grid display="flex" alignItems="center" justifyContent="space-between">
                                  <Grid xs={8} display="flex" flexDirection="column">
                                    <Typography variant="h5">{t('createModel.includeChannel')}</Typography>
                                    <Typography variant="body3">
                                      {t('createModel.boHelperText.includeChannel')}
                                    </Typography>
                                  </Grid>
                                  <Toggle
                                    checked={value ?? false}
                                    onChange={(event) => {
                                      setValue(name, event.target.checked)
                                      toggleIncluded(index)
                                    }}
                                  />
                                </Grid>
                              )
                            }}
                            editModes={editModes}
                            toggleEdit={toggleEdit}
                            currentValue={
                              formData.channelGrouping.channels[index]?.include ?? false ? 'Included' : 'Excluded'
                            }
                            handleUpdate={handleUpdate}
                            control={control}
                            isCreateEvent={isNewConfig}
                            canEdit
                          />
                        )}
                        {(channel.include ?? false) && !optimizeBudget && !isRBA ? (
                          <>
                            {!isDDA && (
                              <>
                                <Divider />
                                <FormItem
                                  name={`channelGrouping.channels.${index}.impression`}
                                  label={t('createModel.impressionsImportance')}
                                  xs={12}
                                  render={({ field: { name, value } }) => {
                                    return (
                                      <>
                                        <Typography variant="h3">{t('createModel.impressionsImportance')}</Typography>
                                        <Grid display="flex" justifyContent="space-between" alignItems="center">
                                          <Typography variant="body2">{t('createModel.impressionsChannel')}</Typography>
                                          <Toggle
                                            checked={value ?? false}
                                            onChange={(event) => {
                                              setValue(name, event.target.checked)
                                              toggleImpression(index)
                                            }}
                                          />
                                        </Grid>
                                      </>
                                    )
                                  }}
                                  editModes={editModes}
                                  toggleEdit={toggleEdit}
                                  currentValue={getTrueToYes(formData.channelGrouping.channels[index]?.impression)}
                                  handleUpdate={handleUpdate}
                                  control={control}
                                  isCreateEvent={isNewConfig}
                                  canEdit
                                />
                              </>
                            )}
                            <Divider />
                            <FormItem
                              name={`channelGrouping.channels.${index}.redistribution`}
                              currentValue={getDescriptiveLabelFromValue(
                                CONVERSION_REDISTRIBUTION,
                                formData.channelGrouping.channels[index]?.redistribution
                              )}
                              label={t('createModel.conversionRed')}
                              xs={12}
                              render={({ field }) => {
                                return (
                                  <>
                                    <Grid display="flex" xs={8}>
                                      <Typography variant="body3">
                                        {t('createModel.amHelperText.conversionRed')}
                                      </Typography>
                                    </Grid>
                                    {field.value != null && (
                                      <Typography variant="body3">
                                        {getDescriptiveLabelFromValue(CONVERSION_REDISTRIBUTION, field.value)}
                                      </Typography>
                                    )}
                                    <Grid>
                                      <StyledSliderGrid>
                                        <StyledSlider
                                          {...field}
                                          defaultValue={0}
                                          step={CONVERSION_REDISTRIBUTION[1].value - CONVERSION_REDISTRIBUTION[0].value}
                                          marks
                                        />
                                      </StyledSliderGrid>
                                      <Grid display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="body3">{CONVERSION_REDISTRIBUTION_MIN}</Typography>
                                        <Typography variant="body3">{CONVERSION_REDISTRIBUTION_MAX}</Typography>
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              }}
                              editModes={editModes}
                              toggleEdit={toggleEdit}
                              handleUpdate={handleUpdate}
                              control={control}
                              isCreateEvent={isNewConfig}
                              canEdit
                            />
                          </>
                        ) : null}

                        {(channel.include ?? false) && !optimizeBudget && isRBA ? (
                          <>
                            <Divider />
                            <FormItem
                              name={`channelGrouping.channels.${index}.expectedROAS`}
                              currentValue={getDescriptiveLabelFromValue(
                                EXPECTED_ROAS_REDISTRIBUTION,
                                formData.channelGrouping.channels[index]?.expectedROAS
                              )}
                              label={t('createModel.expectedROAS')}
                              xs={12}
                              render={({ field }) => {
                                return (
                                  <>
                                    <Grid display="flex" xs={8}>
                                      <Typography variant="body3">
                                        {t('createModel.rbaHelperText.expectedROAS')}
                                      </Typography>
                                    </Grid>
                                    {field.value != null && (
                                      <Typography variant="body3">
                                        {getDescriptiveLabelFromValue(EXPECTED_ROAS_REDISTRIBUTION, field.value)}
                                      </Typography>
                                    )}
                                    <Grid>
                                      <StyledSliderGrid>
                                        <StyledSlider
                                          {...field}
                                          defaultValue={50}
                                          step={null}
                                          marks={EXPECTED_ROAS_REDISTRIBUTION.map((item) => ({ value: item.value }))}
                                        />
                                      </StyledSliderGrid>
                                      <Grid display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="body3">{EXPECTED_ROAS_REDISTRIBUTION_MIN}</Typography>
                                        <Typography variant="body3">{EXPECTED_ROAS_REDISTRIBUTION_MAX}</Typography>
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              }}
                              editModes={editModes}
                              toggleEdit={toggleEdit}
                              handleUpdate={handleUpdate}
                              control={control}
                              isCreateEvent={isNewConfig}
                              canEdit
                            />
                            <Divider />
                            <FormItem
                              name={`channelGrouping.channels.${index}.adStock`}
                              currentValue={getDescriptiveLabelFromValue(
                                AD_STOCK_REDISTRIBUTION,
                                formData.channelGrouping.channels[index]?.adStock
                              )}
                              label={t('createModel.adStock')}
                              xs={12}
                              render={({ field }) => {
                                return (
                                  <>
                                    <Grid display="flex" xs={8}>
                                      <Typography variant="body3">{t('createModel.rbaHelperText.adStock')}</Typography>
                                    </Grid>
                                    {field.value != null && (
                                      <Typography variant="body3">
                                        {getDescriptiveLabelFromValue(AD_STOCK_REDISTRIBUTION, field.value)}
                                      </Typography>
                                    )}
                                    <Grid>
                                      <StyledSliderGrid>
                                        <StyledSlider
                                          {...field}
                                          defaultValue={0}
                                          step={null}
                                          marks={AD_STOCK_REDISTRIBUTION.map((item) => ({ value: item.value }))}
                                        />
                                      </StyledSliderGrid>
                                      <Grid display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="body3">{AD_STOCK_REDISTRIBUTION_MIN}</Typography>
                                        <Typography variant="body3">{AD_STOCK_REDISTRIBUTION_MAX}</Typography>
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              }}
                              editModes={editModes}
                              toggleEdit={toggleEdit}
                              handleUpdate={handleUpdate}
                              control={control}
                              isCreateEvent={isNewConfig}
                              canEdit
                            />
                          </>
                        ) : null}
                      </>
                    }
                  />
                </StyledCard>
              )
            })}
          </Grid>
        )}
      </AccordionFormStepItem>
    </AccordionFormStep>
  )
}

const StyledAvatar = styled(Avatar)(({ faded }: { faded: boolean }) => ({
  opacity: faded ? '40%' : '100%'
}))

const StyledSlider = styled(Slider)(() => ({
  width: '100%'
}))

const StyledAccordion = styled(Accordion)(() => ({
  backgroundColod: 'red'
}))

const StyledTypography = styled(Typography)(() => ({
  color: '#7A93AB'
}))

const StyledSliderGrid = styled(Grid)(() => ({
  width: '100%',
  padding: '0px 20px 0px 20px'
}))

const StyledCard = styled(Card)(({ included }: { included: boolean }) => ({
  width: '100%',
  backgroundColor: included ? 'white' : '#F8FBFC',
  '& > div': {
    backgroundColor: included ? 'white' : '#F8FBFC'
  }
}))

const StyledCardTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150
}))

export default ChannelGroupingStep
