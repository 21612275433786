import { ReactElement, useEffect, useState } from 'react'
import { ConditionalBuilderProps, ConditionalBuilderRowStateEditing } from './types'
import ConditionBuilderRow from '../ConditionBuilderRow'
import Button from '../../Button'
import Grid from '../../Grid'
import {
  StyledAccordionInnerRowsContainer,
  StyledChipUnsavedConditions,
  StyledGridNewConditionalRowCard,
  StyledTypographyErrorMessage
} from './style'
import Typography from '../../Typography'
import Divider from '../../Divider'
import { ChevronDownIcon, MaximizeIcon, MinimizeIcon } from '../../Icons'
import Chip from '../../Chip'
import Badge from '../../Badge'
import { ConditionBuilderRowProps } from '../ConditionBuilderRow/types'

type EditType = 'dimension' | 'operator' | 'value'
type Errors = Record<string, Record<string, ConditionBuilderRowProps['errors']>>

const EMPTY_ROW = {
  dimension: undefined,
  operator: undefined,
  value: undefined
}

const ConditionalBuilder = ({
  value,
  onSave,
  dimensionDefinition,
  operatorDefinition,
  valueDefinition,
  innerRowLabel = 'Or when',
  outerRowLabel = 'And when',
  title = 'This channel contains',
  saveButtonTitle = 'Save and exit',
  disableSave = false
}: ConditionalBuilderProps): ReactElement => {
  const [localValue, setLocalValue] = useState<Record<string, ConditionalBuilderRowStateEditing[]>>(
    JSON.parse(JSON.stringify(value))
  )
  const [expandedCardIndexes, setExpandedCardIndexes] = useState<number[]>(
    Object.keys(localValue).map((index) => parseInt(index))
  )
  const [validationErrors, setValidationErrors] = useState<Errors | undefined>(undefined)
  const [justAddedRow, setJustAddedRow] = useState<number[] | undefined>()

  const handleSave = (): void => {
    if (disableSave) return

    if (validationErrors == null || Object.keys(validationErrors).length === 0) {
      onSave(localValue as ConditionalBuilderProps['value'])
    }
  }

  const getErrorsFromInnerRow = (rowState: ConditionalBuilderRowStateEditing): Record<string, string> => {
    const errors: Record<string, string> = {}
    if (
      rowState.dimension == null ||
      !dimensionDefinition.options.map((option) => option.value).includes(rowState.dimension)
    ) {
      errors.dimension = 'Required'
    }
    if (
      rowState.operator == null ||
      !operatorDefinition.options.map((option) => option.value).includes(rowState.operator)
    ) {
      errors.operator = 'Required'
    }
    if (rowState.value == null || rowState.value === '') {
      errors.value = 'Required'
    }
    return errors
  }

  const addNewOuterRow = (copyPreviousDropdownValues: boolean = true): void => {
    const newLocalValue = { ...localValue }
    const newOuterIndex = Object.keys(newLocalValue).length

    if (!copyPreviousDropdownValues) {
      newLocalValue[newOuterIndex.toString()] = [{ ...EMPTY_ROW }]
    } else {
      const previousLastRow = newLocalValue[Math.max(newOuterIndex - 1, 0)]
      if (previousLastRow?.length > 0) {
        newLocalValue[newOuterIndex.toString()] = [
          {
            ...EMPTY_ROW,
            dimension: previousLastRow[previousLastRow.length - 1].dimension,
            operator: previousLastRow[previousLastRow.length - 1].operator
          }
        ]
      } else {
        newLocalValue[newOuterIndex.toString()] = [
          {
            ...EMPTY_ROW,
            dimension: dimensionDefinition.options[0].value,
            operator: operatorDefinition.options[0].value
          }
        ]
      }
    }
    setLocalValue(newLocalValue)
    setExpandedCardIndexes([newOuterIndex])
  }

  const addNewInnerRow = (outerIndex: number): void => {
    const newLocalValue = { ...localValue }
    newLocalValue[outerIndex.toString()].push({
      ...EMPTY_ROW,
      dimension: newLocalValue[outerIndex.toString()][0].dimension,
      operator: newLocalValue[outerIndex.toString()][0].operator
    })
    setJustAddedRow([outerIndex, newLocalValue[outerIndex.toString()].length - 1])
    setLocalValue(newLocalValue)
  }

  const deleteRow = (outerIndex: number, innerIndex: number): void => {
    let newLocalValue: Record<string, ConditionalBuilderRowStateEditing[]> = {}
    if (localValue[outerIndex]?.length === 1) {
      let hasRemoved = false
      Object.entries(localValue).forEach(([key, object], index) => {
        if (key === outerIndex.toString()) {
          hasRemoved = true
          return
        }
        if (hasRemoved) {
          newLocalValue[index - 1] = object
        } else {
          newLocalValue[index] = object
        }
      })
    } else {
      newLocalValue = { ...localValue }
      newLocalValue[outerIndex].splice(innerIndex, 1)
    }

    if (validationErrors?.[outerIndex]?.[innerIndex] != null) {
      let newValidationErrors = { ...validationErrors }
      const { [innerIndex]: _, ...restOfErrors } = newValidationErrors[outerIndex]
      newValidationErrors[outerIndex] = restOfErrors
      if (Object.keys(newValidationErrors[outerIndex]).length === 0) {
        const { [outerIndex]: __, ...restOfErrors } = newValidationErrors
        newValidationErrors = restOfErrors
      }

      if (Object.keys(newValidationErrors).length === 0) {
        setValidationErrors(undefined)
      } else {
        setValidationErrors(newValidationErrors)
      }
    }

    setLocalValue(newLocalValue)
  }

  const handleExpandCard = (outerIndex: number, expanded: boolean): void => {
    if (expanded) {
      setExpandedCardIndexes([...expandedCardIndexes, outerIndex])
    } else {
      setExpandedCardIndexes(expandedCardIndexes.filter((index) => index !== outerIndex))
    }
  }

  const handleExpandAllClick = (allCardsExpanded: boolean): void => {
    if (allCardsExpanded) {
      setExpandedCardIndexes([])
    } else {
      setExpandedCardIndexes(Object.keys(localValue).map((index) => parseInt(index)))
    }
  }

  const dirtyOuterRowIndexes = Object.entries(localValue)
    .map(([outerRowIndex]) => {
      if (value[outerRowIndex] == null) {
        return [true, outerRowIndex]
      }
      return [JSON.stringify(localValue[outerRowIndex]) !== JSON.stringify(value[outerRowIndex]), outerRowIndex]
    })
    .filter(([isDirty]) => isDirty)
    .map(([, outerRowIndex]) => outerRowIndex)

  const areAllCardsExpanded = Object.keys(localValue).length === expandedCardIndexes.length
  const totalConditionsCount = Object.values(localValue).reduce((acc, curr) => acc + curr.length, 0)

  const entireConditionBuilderIsEmpty = totalConditionsCount === 0
  const isCreatingFirstCondition =
    totalConditionsCount === 1 && Object.keys(getErrorsFromInnerRow(Object.values(localValue)[0][0])).length > 0

  useEffect(() => {
    if (isCreatingFirstCondition) {
      setExpandedCardIndexes([0])
    }
  }, [isCreatingFirstCondition])

  useEffect(() => {
    if (entireConditionBuilderIsEmpty) {
      addNewOuterRow(false)
    }
  }, [entireConditionBuilderIsEmpty])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string | undefined => {
      if (dirtyOuterRowIndexes.length === 0) {
        return undefined
      }

      const confirmationMessage = 'Are you sure you want to leave? You have unsaved changes.'

      ;(event ?? window.event).returnValue = confirmationMessage

      return confirmationMessage
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [dirtyOuterRowIndexes])

  const editRow = (outerIndex: number, innerIndex: number, type: EditType, value?: string): void => {
    const newLocalValue = { ...localValue }
    newLocalValue[outerIndex][innerIndex][type] = value

    if (!(isCreatingFirstCondition && ['dimension', 'operator'].includes(type))) {
      const errors = getErrorsFromInnerRow(newLocalValue[outerIndex][innerIndex])
      updateErrorsForSingleRow(outerIndex, innerIndex, errors)
    }

    setLocalValue(newLocalValue)
  }

  const updateErrorsForSingleRow = (outerIndex: number, innerIndex: number, errors: Record<string, string>): void => {
    if (Object.keys(errors).length > 0) {
      setValidationErrors({
        ...validationErrors,
        [outerIndex]: {
          ...validationErrors?.[outerIndex],
          [innerIndex]: {
            ...errors
          }
        }
      })
    } else {
      const newValidationErrors = { ...validationErrors }
      if (newValidationErrors?.[outerIndex] != null) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newValidationErrors[outerIndex][innerIndex]
        if (Object.keys(newValidationErrors[outerIndex]).length === 0) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete newValidationErrors[outerIndex]
        }
      }
      if (Object.keys(newValidationErrors).length === 0) {
        setValidationErrors(undefined)
      } else {
        setValidationErrors(newValidationErrors)
      }
    }
  }

  return (
    <Grid container flexDirection="column" gap="16px">
      {!isCreatingFirstCondition && (
        <Grid container flexWrap="nowrap" justifyContent="space-between" padding="0px 20px">
          <Grid display="flex" alignItems="center" gap="8px">
            <Typography variant="h4">{title}</Typography>
            <Chip label={`${totalConditionsCount} condition${totalConditionsCount > 1 ? 's' : ''}`} />
          </Grid>
          <Button
            variant="text"
            rightIcon={areAllCardsExpanded ? <MinimizeIcon /> : <MaximizeIcon />}
            onClick={() => handleExpandAllClick(areAllCardsExpanded)}
          >
            {areAllCardsExpanded ? 'Collapse all' : 'Expand all'}
          </Button>
        </Grid>
      )}
      {Object.entries(localValue).map(([outerIndexKey, innerRows], outerIndex) => {
        const rowIsDirty = dirtyOuterRowIndexes.includes(outerIndexKey)
        const rowHasErrors = validationErrors?.[outerIndex] != null
        return (
          <StyledAccordionInnerRowsContainer
            key={`${outerIndex} - ${String(rowHasErrors)} - ${String(rowIsDirty)}`}
            expandIcon={<ChevronDownIcon />}
            expanded={expandedCardIndexes.includes(outerIndex)}
            onChange={(_, expanded: boolean) => handleExpandCard(outerIndex, expanded)}
            TransitionProps={{
              mountOnEnter: true,
              unmountOnExit: true
            }}
            Summary={
              <Grid display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Grid height="40px" alignItems="center" display="flex" gap="8px">
                  <Typography variant="h4">{outerIndex === 0 ? 'When this' : outerRowLabel}</Typography>
                  {!isCreatingFirstCondition && (
                    <Chip label={`${innerRows.length} condition${innerRows.length > 1 ? 's' : ''}`} />
                  )}
                </Grid>

                {(rowHasErrors || rowIsDirty) && (
                  <StyledChipUnsavedConditions hasError={rowHasErrors} label="Unsaved changes" />
                )}
              </Grid>
            }
            Details={
              <>
                {innerRows.map((rowState, innerIndex) => {
                  const error = validationErrors?.[outerIndex]?.[innerIndex]
                  return (
                    <Grid
                      key={`${outerIndex}-${innerIndex}-${rowState?.dimension ?? ''}-${rowState?.operator ?? ''}-${
                        rowState?.value ?? ''
                      }`}
                      marginBottom={innerIndex === innerRows.length - 1 ? '16px' : undefined}
                    >
                      {isCreatingFirstCondition && (
                        <Grid display="flex" flexDirection="column" marginBottom="16px" marginTop="-4px" gap="16px">
                          <Typography variant="body1">
                            You haven't created any conditions for this channel yet.
                          </Typography>
                          <Typography variant="h4">Start out by creating your first condition:</Typography>
                        </Grid>
                      )}
                      <InnerConditionalBuilderRow
                        {...rowState}
                        handleEditRow={(type: EditType, value?: string) => editRow(outerIndex, innerIndex, type, value)}
                        handleDeleteRow={() => deleteRow(outerIndex, innerIndex)}
                        dimensionDefinition={dimensionDefinition}
                        operatorDefinition={operatorDefinition}
                        valueDefinition={valueDefinition}
                        errors={error}
                        autoFocus={justAddedRow?.[0] === outerIndex && justAddedRow?.[1] === innerIndex}
                      />
                      {innerIndex !== innerRows.length - 1 && (
                        <Grid height="32px">
                          <Divider text={innerRowLabel} />
                        </Grid>
                      )}
                    </Grid>
                  )
                })}
                <NewConditionalRowCard handleAddNewRow={() => addNewInnerRow(outerIndex)} label={innerRowLabel} />
              </>
            }
          />
        )
      })}
      <NewConditionalRowCard handleAddNewRow={addNewOuterRow} label={outerRowLabel} />
      <Button
        variant="filled"
        fullWidth
        onClick={handleSave}
        disabled={Object.keys(validationErrors ?? {}).length > 0 || disableSave}
      >
        {saveButtonTitle}
      </Button>
      {validationErrors != null && Object.keys(validationErrors).length > 0 && (
        <Grid display="flex" gap="8px" alignItems="center" marginTop="-8px" marginBottom="-4px">
          <Badge color="error" />
          <StyledTypographyErrorMessage variant="body2">
            There are errors in your conditions. Please fix them before saving.
          </StyledTypographyErrorMessage>
        </Grid>
      )}
    </Grid>
  )
}

interface NewConditionalRowCardProps {
  handleAddNewRow: () => void
  label: string
  disabled?: boolean
}

const NewConditionalRowCard = ({
  handleAddNewRow,
  label,
  disabled = false
}: NewConditionalRowCardProps): ReactElement => {
  return (
    <StyledGridNewConditionalRowCard disabled={disabled}>
      <Typography variant="h4">{label}</Typography>
      <Button variant="filled" onClick={handleAddNewRow} disabled={disabled}>
        Add "{label}"
      </Button>
    </StyledGridNewConditionalRowCard>
  )
}

interface InnerConditionalBuilderRowProps {
  dimension?: string
  operator?: string
  value?: string
  handleEditRow: (type: EditType, value?: string) => void
  handleDeleteRow: () => void
  dimensionDefinition: ConditionalBuilderProps['dimensionDefinition']
  operatorDefinition: ConditionalBuilderProps['operatorDefinition']
  valueDefinition: ConditionalBuilderProps['valueDefinition']
  errors?: ConditionBuilderRowProps['errors']
  autoFocus: boolean
}

const InnerConditionalBuilderRow = ({
  operator,
  dimension,
  value,
  handleEditRow,
  handleDeleteRow,
  dimensionDefinition,
  operatorDefinition,
  valueDefinition,
  errors,
  autoFocus
}: InnerConditionalBuilderRowProps): ReactElement => {
  return (
    <ConditionBuilderRow
      valueType="single"
      dimensionDefinition={dimensionDefinition}
      operatorDefinition={operatorDefinition}
      valueDefinition={valueDefinition}
      dimensionValue={dimension}
      handleEditDimension={(value?: string) => handleEditRow('dimension', value)}
      operatorValue={operator}
      handleEditOperator={(value?: string) => handleEditRow('operator', value)}
      textValue={value}
      handleEditText={(value?: string) => handleEditRow('value', value)}
      isSaved={false}
      onDelete={handleDeleteRow}
      errors={errors}
      autoFocus={autoFocus}
    />
  )
}

export default ConditionalBuilder
