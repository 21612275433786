import zIndex from '@mui/material/styles/zIndex'

export interface ZIndexesTypes extends Partial<typeof zIndex> {
  topNav: number
  sideNav: number
  flyout: number
}

// The fields: mobileStepper, fab, speedDial, appBar, drawer, modal, snackbar, and tooltip, all use default z-index values from Material-UI (https://mui.com/material-ui/customization/z-index/).
// Due to type checking, it's necessary to pass these default values.
const zIndexes = {
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
  topNav: 1000,
  sideNav: 1000,
  flyout: 1300
}

export default zIndexes
