import { type CurrencyCodes } from './constants/currency'

const locale = 'en-US'

export interface Num {
  value: number
  currencyCode?: string
  showCurrency?: boolean
}

export type FormatIds =
  | 'spend'
  | 'adSpend'
  | 'revenue'
  | 'roas'
  | 'conversions'
  | 'impressions'
  | 'costPerAction'
  | 'conversion'
  | 'percentChange'
  | 'videoViews'
  | 'clicks'
  | 'viewableImpressions'
  | 'sessions'
  | 'costPerClick'
  | 'clickThroughRate'
  | 'conversionRate'
  | 'costOfSales'
  | 'costPerThousandImpressions'
  | 'averageOrderValue'
  | 'conversionValue'
  | 'percentDrop'
  | 'percentIncrease'
  | 'budget'
  | 'numeric'

const CURRENCY_FORMAT_IDS = [
  'spend',
  'adSpend',
  'revenue',
  'costPerAction',
  'costPerClick',
  'costPerThousandImpressions',
  'averageOrderValue',
  'conversionValue',
  'budget'
]

const formatToMoney = ({
  value,
  currencyCode = 'EUR',
  compact = true,
  showCurrency = true,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
}: Num & { compact?: boolean; minimumFractionDigits?: number; maximumFractionDigits?: number }): string => {
  const compactNumberFormat: Intl.NumberFormatOptions = {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }

  const defaultNumberFormat: Intl.NumberFormatOptions = {
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits,
    maximumFractionDigits,
    ...(showCurrency && { style: 'currency' })
  }

  // const formatOptions = {...defaultNumberFormat}
  const formatOptions = compact ? { ...defaultNumberFormat, ...compactNumberFormat } : defaultNumberFormat

  return Intl.NumberFormat(locale, formatOptions).format(value)
}

export const formatToNumber = (
  number: number,
  compact: boolean = true,
  maximumFractionDigits: number = 1,
  minimumFractionDigits?: number
): string => {
  const compactNumberFormat: Intl.NumberFormatOptions = {
    notation: 'compact',
    compactDisplay: 'short'
  }

  const defaultNumberFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits
  }
  const formatOptions = compact ? { ...defaultNumberFormat, ...compactNumberFormat } : defaultNumberFormat
  const formatter = new Intl.NumberFormat(locale, formatOptions)
  return formatter.format(number)
}

export const formatToPercent = (
  number: number,
  maximumFractionDigits: number = 0,
  minimumFractionDigits: number = 0
): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits
  })

  return formatter.format(number)
}

export const formatToPercentDrop = (number: number): string => {
  const negativeNumber = (num: number): number => -Math.abs(num)
  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 0
  })

  return formatter.format(negativeNumber(number))
}

export const getFormattedValue = ({
  value,
  currencyCode,
  id,
  compact,
  showCurrency = true
}: Num & { id: FormatIds; compact?: boolean }): string => {
  switch (id) {
    case 'adSpend':
    case 'spend':
    case 'revenue':
    case 'conversionValue':
    case 'budget':
      return formatToMoney({
        value,
        currencyCode,
        compact,
        showCurrency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    case 'costPerAction':
    case 'costPerClick':
    case 'costPerThousandImpressions':
    case 'averageOrderValue':
      return formatToMoney({
        value,
        currencyCode,
        compact,
        showCurrency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
    case 'roas':
      return formatToPercent(value, 0)
    case 'percentChange':
    case 'clickThroughRate':
    case 'conversionRate':
    case 'costOfSales':
      return formatToPercent(value, 2)
    case 'percentIncrease':
      return formatToPercent(value)
    case 'conversions':
      return formatToNumber(value, compact, 0)
    case 'conversion':
    case 'impressions':
    case 'videoViews':
    case 'clicks':
    case 'viewableImpressions':
    case 'sessions':
      return formatToNumber(value, compact, 0)
    case 'percentDrop':
      return formatToPercentDrop(value)
    default:
      return formatToNumber(value, compact)
  }
}

export const formatNumberDifference = ({
  presentValue,
  previousValue,
  id,
  currencyCode
}: {
  presentValue: number
  previousValue: number
  id: FormatIds
  currencyCode?: CurrencyCodes
}): string => {
  const difference = presentValue - previousValue
  let prefix = ''

  if (difference > 0) {
    prefix = '+'
  }

  return `${prefix}${getFormattedValue({
    value: difference,
    id,
    compact: true,
    currencyCode,
    showCurrency: CURRENCY_FORMAT_IDS.includes(id)
  })}`
}

export default formatToMoney
