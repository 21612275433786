import React from 'react'

export const Piechart = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M18 13C18 14.3845 17.5895 15.7378 16.8203 16.889C16.0511 18.0401 14.9579 18.9373 13.6788 19.4672C12.3997 19.997 10.9922 20.1356 9.63437 19.8655C8.2765 19.5954 7.02922 18.9287 6.05026 17.9497C5.07129 16.9708 4.4046 15.7235 4.13451 14.3656C3.86441 13.0078 4.00303 11.6003 4.53285 10.3212C5.06266 9.04213 5.95987 7.94888 7.11101 7.17971C8.26215 6.41054 9.61553 6 11 6V13H18Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14 4C14.7879 4 15.5681 4.15519 16.2961 4.45672C17.0241 4.75825 17.6855 5.20021 18.2426 5.75736C18.7998 6.31451 19.2417 6.97595 19.5433 7.7039C19.8448 8.43185 20 9.21207 20 10L14 10L14 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
})
