import React, { ReactElement } from 'react'
import { ConditionBuilderRowProps } from './types'
import ConditionInputSelect from '../ConditionInputSelect'
import { StyledButtonDelete, StyledDivider, StyledGridBuilderRow } from './style'
import Grid from '../../Grid'
import ConditionInputSingleText from '../ConditionInputSingleText'
import { TrashIcon } from '../../Icons'
import ConditionInputMultiText from '../ConditionInputMultiText'

const ConditionBuilderRow = ({
  isSaved,
  onDelete,
  valueType,
  dimensionDefinition,
  dimensionValue,
  handleEditDimension,
  operatorDefinition,
  operatorValue,
  handleEditOperator,
  valueDefinition,
  textValue,
  handleEditText,
  errors,
  autoFocus
}: ConditionBuilderRowProps): ReactElement => {
  const handleDelete = (): void => {
    onDelete()
  }

  return (
    <StyledGridBuilderRow isSaved={isSaved}>
      <Grid container width="25%" flexDirection="row" flexWrap="nowrap" flexShrink={0}>
        <ConditionInputSelect
          width="calc(100% - 9px)"
          saved={isSaved}
          label={dimensionDefinition.label}
          placeholder={dimensionDefinition.placeholder}
          onChange={handleEditDimension}
          value={dimensionValue}
          options={dimensionDefinition.options}
          hasError={errors?.dimension != null}
        />
        {!isSaved && <StyledDivider orientation="vertical" flexItem />}
      </Grid>
      <Grid container width="25%" flexDirection="row" flexWrap="nowrap" flexShrink={0}>
        <ConditionInputSelect
          width="calc(100% - 9px)"
          saved={isSaved}
          label={operatorDefinition.label}
          placeholder={operatorDefinition.placeholder}
          onChange={handleEditOperator}
          value={operatorValue}
          options={operatorDefinition.options}
          hasError={errors?.operator != null}
        />
        {!isSaved && <StyledDivider orientation="vertical" flexItem />}
      </Grid>
      <Grid container width="50%">
        {valueType === 'single' && (
          <ConditionInputSingleText
            fullWidth
            saved={isSaved}
            label={valueDefinition.label}
            placeholder={valueDefinition.placeholder}
            value={textValue}
            onChange={handleEditText}
            hasError={errors?.value != null}
            autoFocus={autoFocus}
          />
        )}
        {valueType === 'multi' && (
          <ConditionInputMultiText
            fullWidth
            saved={isSaved}
            label={valueDefinition.label}
            placeholder={valueDefinition.placeholder}
            value={textValue}
            onChange={handleEditText}
            hasError={errors?.value != null}
            autoFocus={autoFocus}
          />
        )}
      </Grid>
      <StyledButtonDelete variant="text" onClick={handleDelete}>
        <TrashIcon />
      </StyledButtonDelete>
    </StyledGridBuilderRow>
  )
}

export default ConditionBuilderRow
