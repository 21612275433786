import { type ParsedUrlQuery } from 'querystring'
import { omit } from 'ramda'
import { useChangePath, useQueryString } from 'shared/components/Router'
import { makeErrorToast } from 'shared/components/Toaster'
import { type ConfigStatus } from 'shared/configStatuses'
import { HttpStatusCodes } from 'shared/httpStatus'
import OverScreen from 'shared/overScreens'
import { isNilOrEmpty } from 'shared/utils'
import { DETAILED_STATUS_POPUP_TEXT } from 'shared/components/DagViewStatusPopup'
export const POPUP_QUERY_PARAMETER_KEY = 'popup'
export const CONFIG_ID_QUERY_PARAMETER_KEY = 'configId'

export interface ShowDagViewProps {
  configId: number
  configName: string
  isActive: boolean
  configStatus: ConfigStatus
  useNativeDag?: boolean
}

const showDagView =
  (type: DAGViewTypes, isPageWithConfigId: boolean) =>
  (data: ShowDagViewProps): void => {
    void OverScreen.show('dagViewStatusPopup', {
      ...data,
      type,
      isPageWithConfigId
    })
  }

export type DAGViewTypes = 'attributionModel' | 'reportingSolution' | 'budgetOptimiser' | 'connector'

const shouldShowDagView =
  (isPageWithConfigId: boolean) =>
  (query: ParsedUrlQuery = {}): boolean => {
    return query.popup === DETAILED_STATUS_POPUP_TEXT && (isPageWithConfigId || !isNilOrEmpty(query?.configId))
  }

const useDagView = (
  type?: DAGViewTypes,
  isPageWithConfigId: boolean = false
): {
  showDagView: (data: ShowDagViewProps) => void
  shouldShowDagView: (query: ParsedUrlQuery) => boolean
  handleConfigError: ({
    statusCode,
    notFoundMessage,
    unexpectedErrorMessage
  }: {
    statusCode: number
    notFoundMessage: string
    unexpectedErrorMessage: string
  }) => void
} => {
  const { changePath, populatedPathName } = useChangePath()
  const { query } = useQueryString()
  if (type === undefined) {
    return {
      showDagView: () => {},
      shouldShowDagView: () => false,
      handleConfigError: () => {}
    }
  }
  return {
    showDagView: showDagView(type, isPageWithConfigId),
    shouldShowDagView: shouldShowDagView(isPageWithConfigId),
    handleConfigError: ({ statusCode, notFoundMessage, unexpectedErrorMessage }) => {
      if (statusCode === HttpStatusCodes.NOT_FOUND) {
        makeErrorToast(notFoundMessage)
      } else {
        makeErrorToast(unexpectedErrorMessage)
      }

      void changePath(populatedPathName, omit([CONFIG_ID_QUERY_PARAMETER_KEY, POPUP_QUERY_PARAMETER_KEY])(query), true)
    }
  }
}

export default useDagView
