import config from 'shared/config'
import { logger } from 'shared/utils'

const log = logger().child({
  namespace: 'googleTagManager'
})

export const GTM_ID = 'GTM-NBZJ3N4'

type gtmEventId = 'run_now_click' | 'create_dashboard_click' | 'recommendations_popup_open'
type gtmProductType =
  | 'connectors'
  | 'reporting_solutions'
  | 'attribution_models'
  | 'budget_optimiser'
  | 'enhanced_signals'
  | 'recommendations'

interface BaseGTMEvent {
  event: gtmEventId
  product?: gtmProductType
}

interface RunNowClick extends BaseGTMEvent {
  event: 'run_now_click'
  product: 'connectors' | 'reporting_solutions'
}

interface CreateDashboardClick extends BaseGTMEvent {
  event: 'create_dashboard_click'
  product: 'reporting_solutions'
}

interface RecommendationsPopupClick extends BaseGTMEvent {
  event: 'recommendations_popup_open'
  product: 'recommendations'
}

type GTMEvent = RunNowClick | CreateDashboardClick | RecommendationsPopupClick

declare const window: Window & { dataLayer: GTMEvent[] }

export const sendGTMEvent = (event: GTMEvent): void => {
  if (config('APP_ENV') !== 'production' || typeof window.dataLayer === 'undefined') {
    return
  }
  try {
    window.dataLayer.push(event)
  } catch (e) {
    log.error(`Error sending GTM event: ${(e as Error).message}`)
  }
}
