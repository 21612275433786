import { type DataSourceItem, type ExtendedChannelItem } from 'channelGrouping/types'
import { useMemo } from 'react'
import { type Platforms } from 'shared/api/accounts'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'

type ChannelsMap = Record<string, ExtendedChannelItem>

/**
 * Retrieves the channels with their associated data sources.
 *
 * @param channelGroupingResp - The response containing the channel grouping data.
 * @param extendedDataSources - The extended data sources to be associated with the channels.
 * @returns An array of extended channel items with their associated data sources.
 */
const useGetChannelsWithDataSources = (
  channelGroupingResp?: ChannelGroupingResp,
  extendedDataSources?: DataSourceItem[]
): ExtendedChannelItem[] => {
  const channelsWithDataSources = useMemo(() => {
    if (channelGroupingResp == null || extendedDataSources == null) {
      return []
    }
    const channels: ChannelsMap = {}
    Object.entries(channelGroupingResp.channelGrouping).forEach(([dataSource, channelItems]) => {
      const dataSourceKey = dataSource as Platforms
      channelItems.forEach((channelItem, index) => {
        if (channels[channelItem.id] == null) {
          channels[channelItem.id] = { ...channelItem, dataSources: [], index }
        }

        if (!channels[channelItem.id].dataSources.map((ds) => ds.platform).includes(dataSourceKey)) {
          const dataSourceItem = extendedDataSources.find((ds) => ds.platform === dataSourceKey)
          if (dataSourceItem != null) {
            channels[channelItem.id].dataSources.push(dataSourceItem)
          }
        }
      })
    })

    return Object.values(channels)
  }, [channelGroupingResp, extendedDataSources])

  return channelsWithDataSources
}

export default useGetChannelsWithDataSources
