import { Button, Grid, styled } from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'

interface FormButtonsProps {
  isSaveDisabled: boolean
  onSave: (e: React.MouseEvent<HTMLButtonElement>) => void
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const FormButtons = ({ onSave, onCancel, isSaveDisabled }: FormButtonsProps): React.ReactElement => {
  const { t } = useTranslation('common')
  return (
    <StyledGrid display="flex" gap="8px">
      <Button onClick={onSave} variant="filled" disabled={isSaveDisabled}>
        {t('buttons.save')}
      </Button>
      <Button onClick={onCancel} variant="text">
        {t('buttons.cancel')}
      </Button>
    </StyledGrid>
  )
}

export default FormButtons

export const StyledGrid = styled(Grid)(() => ({
  '& button': {
    padding: '8px 16px'
  }
}))
