import React, { type ComponentProps, type ReactElement } from 'react'
import mimirIconTransparent from 'public/assets/images/mimir-icon-transparent.png'
import { Avatar } from '@precis-digital/kurama'
import { MIMIR_BASE_AGENT_ID } from 'mimir/constants'

type AvatarSize = ComponentProps<typeof Avatar>['size']

interface AgentChatAvatarProps {
  agentId: string
  size?: AvatarSize
}

const AGENT_ID_BACKGROUND_COLOR_MAP: Record<string, string> = {
  [MIMIR_BASE_AGENT_ID]: '#232332',
  'mimir-code': '#A396FF',
  'mimir-seo': '#339C8C',
  'mimir-paid-search': '#FFD7A0',
  'mimir-paid-social': '#5578F5',
  'mimir-legal': '#7469AD',
  'mimir-hr': '#FFB269'
}

export const AgentChatAvatar = ({ agentId, size = 'medium' }: AgentChatAvatarProps): ReactElement => {
  return (
    <Avatar
      imageUrl={mimirIconTransparent.src}
      kind="image"
      size={size}
      backgroundColor={AGENT_ID_BACKGROUND_COLOR_MAP[agentId] ?? '#232332'}
    />
  )
}

interface UserChatAvatarProps {
  imageUrl: string
  size?: AvatarSize
}

export const UserChatAvatar = ({ imageUrl, size = 'medium' }: UserChatAvatarProps): ReactElement => {
  return <Avatar imageUrl={imageUrl} kind="image" size={size} />
}
