import { StyledFlyout, StyledGridTopButtonsContainer } from './styles'
import { Button, CancelBkgIcon } from '../index'
import { FlyoutProps } from './types'

const Flyout = (props: FlyoutProps): React.ReactElement => {
  const { isExpanded, topLeftAdornment, onClose, children, closeText } = props
  return (
    <StyledFlyout anchor="right" open={isExpanded} onClose={onClose}>
      <StyledGridTopButtonsContainer>
        {topLeftAdornment != null ? topLeftAdornment : <div />}
        <Button rightIcon={<CancelBkgIcon />} onClick={onClose} scheme="light" variant="text">
          {topLeftAdornment == null ? closeText ?? 'Cancel' : ''}
        </Button>
      </StyledGridTopButtonsContainer>
      {children}
    </StyledFlyout>
  )
}

export default Flyout
