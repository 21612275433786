import { useQueriesUserProfilesFromUsedInConfigs } from 'channelGrouping/api'
import { type UsedInConfigWithStatus } from 'channelGrouping/types'
import React, { useMemo } from 'react'
import { type ReactElement } from 'react-markdown/lib/react-markdown'
import { MemberTimestampCell, StatusCell, TextCell, TitleWithAvatarsCell } from 'shared/components/ListView'
import ListViewTable, { type RowLinkProps } from 'shared/components/ListView/Table'
import TableRowLink from 'shared/components/ListView/Table/TableRowLink'
import { type ListViewCellProps, type ListViewColumnDefinition } from 'shared/components/ListView/types'
import { getErrorCodeDetails } from 'shared/configStatuses'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'

const UsedInRowLink = ({ row, children }: RowLinkProps<UsedInConfigWithStatus>): ReactElement => {
  return <TableRowLink href={row.href}>{children}</TableRowLink>
}

const UsedInTable = ({
  usedInConfigs,
  isLoadingStatuses
}: {
  usedInConfigs: UsedInConfigWithStatus[]
  isLoadingStatuses: boolean
}): ReactElement => {
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('channelGrouping')
  const { t: tCommon } = useTranslation('common')

  const userDetails = useQueriesUserProfilesFromUsedInConfigs(usedInConfigs, currentClient.id)

  const columns: Array<ListViewColumnDefinition<UsedInConfigWithStatus>> = useMemo(
    () => [
      {
        Header: t('mainFormView.usedInTab.tableHeader.modelName'),
        accessor: 'name',
        Cell: (props: ListViewCellProps<UsedInConfigWithStatus>) => (
          <TitleWithAvatarsCell
            title={props.row.original.name}
            avatars={props.row.original.connectedAccounts}
            avatarDescriber={t('mainFormView.usedInTab.connectedAccounts')}
            {...props}
          />
        )
      },
      {
        Header: t('mainFormView.usedInTab.tableHeader.lastEdited'),
        accessor: 'updatedAt',
        Cell: (props: ListViewCellProps<UsedInConfigWithStatus>) => (
          <MemberTimestampCell
            {...props}
            timestamp={props.row.original.updatedAt}
            memberId={
              userDetails?.find((res) => res.data?.email === props.row.original.updatedBy)?.data?.id ?? undefined
            }
          />
        ),
        align: 'center'
      },
      {
        Header: t('mainFormView.usedInTab.tableHeader.modelType'),
        accessor: 'type',
        Cell: (props: ListViewCellProps<UsedInConfigWithStatus>) => {
          return <TextCell text={t(`mainFormView.usedInTab.modelTypes.${props.row.original.type}`)} {...props} />
        },
        align: 'center'
      },
      {
        Header: t('mainFormView.usedInTab.tableHeader.status'),
        accessor: 'status',
        Cell: (props: ListViewCellProps<UsedInConfigWithStatus>) => {
          const dagViewInfo = {
            dagViewType:
              props.row.original.type === ('budgetOptimiser' as const)
                ? props.row.original.type
                : ('attributionModel' as const),
            configId: props.row.original.id,
            configName: props.row.original.name,
            isActive: props.row.original.runSchedule,
            isPageWithConfigId: false
          }
          return (
            <StatusCell
              isLoadingStatus={isLoadingStatuses}
              status={props.row.original.statusEnum}
              lastUpdated={props.row.original.status?.lastUpdated}
              errorCode={props.row.original.status?.errorCode}
              errorDetails={getErrorCodeDetails(
                props.row.original.status?.errorCode,
                props.row.original.status?.errorMessage,
                props.row.original.status?.targetProjectId,
                props.row.original.type === 'budgetOptimiser'
                  ? tCommon('dagView.budgetOptimiser')
                  : tCommon('dagView.attributionModel')
              )}
              dagViewInformation={dagViewInfo}
              {...props}
            />
          )
        },
        align: 'right',
        width: '120px'
      }
    ],
    [userDetails, tCommon, t, isLoadingStatuses]
  )

  return (
    <ListViewTable
      data={usedInConfigs}
      columns={columns}
      isLoading={false}
      RowLink={UsedInRowLink}
      initialState={{
        pageIndex: 0,
        sortBy: [{ id: 'updatedAt', desc: true }]
      }}
      includePagination
    />
  )
}

export default UsedInTable
