import {
  Accordion,
  Badge,
  type BadgeColor,
  ChevronDownIcon,
  Grid,
  Typography,
  styled,
  Avatar,
  Button,
  EditIcon,
  NoteIcon,
  Input,
  SingleSelectDropdown,
  Divider,
  Chip,
  Card,
  RightIcon,
  TrashIcon
} from '@precis-digital/kurama'
import { badgeColors } from '@precis-digital/kurama/src/components/Badge/types'
import { type Theme } from '@precis-digital/kurama/src/components/theme'
import ConnectedDataSourcesAnnotation from 'channelGrouping/components/ConnectedDataSourcesAnnotation'
import DataSourcesAvatarGroup from 'channelGrouping/components/DataSourcesAvatarGroup'
import { CHANNEL_CATEGORIES, type ChannelCategoryType, DEFAULT_DATA_SOURCE_VIEW_TAB } from 'channelGrouping/constants'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { type ChannelsMapItem, type DataSourceItem } from 'channelGrouping/types'
import React, { useState, type ReactElement, type MouseEvent, type SyntheticEvent } from 'react'
import { type Platforms } from 'shared/api/accounts'
import { useChangePath } from 'shared/components/Router'
import { useTranslation } from 'shared/translations'
import { capitalize } from 'shared/utils'
import { APP_ROUTES } from 'shared/routes'
import { useFeatureFlag } from 'shared/featureFlag'

interface ChannelsTableCardProps {
  channel: ChannelsMapItem
  isExpanded: boolean
  handleExpandClick: (isExpanded: boolean) => void
}

const ChannelsTableCard = ({ channel, isExpanded, handleExpandClick }: ChannelsTableCardProps): ReactElement => {
  const [isShowingNote, setIsShowingNote] = useState<boolean>(false)

  const handleNoteButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setIsShowingNote(!isShowingNote)
    if (!isShowingNote) {
      handleExpandClick(true)
    }
  }

  const handleChange = (_: SyntheticEvent<Element, Event>, expanded: boolean): void => {
    handleExpandClick(expanded)
    if (!expanded) {
      setIsShowingNote(false)
    }
  }

  return (
    <StyledAccordionCardContainer
      expanded={isExpanded}
      onChange={handleChange}
      expandIcon={<ChevronDownIcon />}
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true
      }}
      Summary={
        <ChannelsTableCardSummary
          channel={channel}
          isShowingNote={isShowingNote}
          handleNoteButtonClick={handleNoteButtonClick}
        />
      }
      Details={
        <ChannelsTableCardDetails channel={channel} isShowingNote={isShowingNote} setIsShowingNote={setIsShowingNote} />
      }
    />
  )
}

interface ChannelsTableCardSummaryProps {
  channel: ChannelsMapItem
  isShowingNote: boolean
  handleNoteButtonClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const ChannelsTableCardSummary = ({
  channel,
  isShowingNote,
  handleNoteButtonClick
}: ChannelsTableCardSummaryProps): ReactElement => {
  const { dataSourcesMap } = useCurrentChannelGrouping()
  return (
    <Grid width="100%" display="flex" justifyContent="space-between" flexWrap="nowrap">
      <Grid width="50%" display="flex" flexDirection="row">
        <Grid display="flex" gap="14px" alignItems="center" flexDirection="row">
          <Badge color={channel.color ?? 'steel'} />
          <Typography variant="h3">{channel.name}</Typography>
        </Grid>
        {/* This should be a vertical divider, but it's not available so just here for spacing */}
        <Grid width="32px" height="24px" />{' '}
        <Button variant="text" onClick={handleNoteButtonClick} leftIcon={<NoteIcon />}>
          {isShowingNote ? 'Hide note' : undefined}
        </Button>
      </Grid>
      <Grid width="50%" display="flex" justifyContent="flex-end" marginRight="4px">
        <DataSourcesAvatarGroup
          dataSources={Object.keys(channel.channelRules).map(
            (dataSourceId) => dataSourcesMap[dataSourceId as Platforms] as DataSourceItem
          )}
          size="small"
          max={12}
        />
      </Grid>
    </Grid>
  )
}

interface ChannelsTableCardDetailsProps {
  channel: ChannelsMapItem
  isShowingNote: boolean
  setIsShowingNote: (isShowingNote: boolean) => void
}

const ChannelsTableCardDetails = ({
  channel,
  isShowingNote,
  setIsShowingNote
}: ChannelsTableCardDetailsProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const [isEditingNote, setIsEditingNote] = useState<boolean>(channel.note == null)
  const [localChannelName, setLocalChannelName] = useState<string>(channel.name)
  const [localChannelColor, setLocalChannelColor] = useState<BadgeColor>(channel.color)
  const [localChannelNote, setLocalChannelNote] = useState(channel.note)
  const [localChannelCategory, setLocalChannelCategory] = useState<ChannelCategoryType>(channel.category)
  const { value: canViewAttributionRbaFeature } = useFeatureFlag('attribution-rba')

  const { channelGroupingId, updateChannelAcrossDataSources, dataSourcesMap } = useCurrentChannelGrouping()
  const { changePath } = useChangePath()

  const handleUpdateButtonClick = (): void => {
    updateChannelAcrossDataSources(channel.id, {
      name: localChannelName,
      color: localChannelColor,
      category: localChannelCategory
    })
  }

  const handleSaveNoteButtonClick = (): void => {
    if (localChannelNote === channel.note || localChannelNote == null) {
      setIsEditingNote(false)
      return
    }
    updateChannelAcrossDataSources(
      channel.id,
      {
        note: localChannelNote
      },
      {
        onSuccess: () => {
          setIsEditingNote(false)
        }
      }
    )
  }

  const handleDeleteNoteButtonClick = (): void => {
    setIsShowingNote(false)
    setLocalChannelNote(undefined)
    updateChannelAcrossDataSources(channel.id, {
      note: undefined
    })
  }

  const handleCancelNoteEditingButtonClick = (): void => {
    setLocalChannelColor(channel.color)
    setLocalChannelName(channel.name)
    setLocalChannelCategory(channel.category)
    if (channel.note == null) {
      setIsShowingNote(false)
    } else {
      setIsEditingNote(false)
    }
  }

  const handleRelatedDataSourceCardClick = (dataSource: Platforms): void => {
    void changePath(
      `${APP_ROUTES.customGroupings.dataSourcesPage({
        channelGroupingId: channelGroupingId.toString()
      })}/${dataSource}/${DEFAULT_DATA_SOURCE_VIEW_TAB}`
    )
  }

  const numDataSources = Object.keys(channel.channelRules).length

  const channelCategoryCamelCase: Record<ChannelCategoryType, Record<string, string>> = {
    other: {
      label: t(`mainFormView.channelsTab.category.other`),
      subcategory: t(`mainFormView.channelsTab.subcategory.other`)
    },
    non_paid_direct: {
      label: t(`mainFormView.channelsTab.category.nonPaidDirect`),
      subcategory: t(`mainFormView.channelsTab.subcategory.nonPaidChannels`)
    },
    non_paid_organic: {
      label: t(`mainFormView.channelsTab.category.nonPaidOrganic`),
      subcategory: t(`mainFormView.channelsTab.subcategory.nonPaidChannels`)
    },
    non_paid_push: {
      label: t(`mainFormView.channelsTab.category.nonPaidPush`),
      subcategory: t(`mainFormView.channelsTab.subcategory.nonPaidChannels`)
    },
    non_paid_referral: {
      label: t(`mainFormView.channelsTab.category.nonPaidReferral`),
      subcategory: t(`mainFormView.channelsTab.subcategory.nonPaidChannels`)
    },
    non_paid_other: {
      label: t(`mainFormView.channelsTab.category.nonPaidOther`),
      subcategory: t(`mainFormView.channelsTab.subcategory.nonPaidChannels`)
    },
    paid_search_brand: {
      label: t(`mainFormView.channelsTab.category.paidSearchBrand`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidSearch`)
    },
    paid_search_generic: {
      label: t(`mainFormView.channelsTab.category.paidSearchGeneric`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidSearch`)
    },
    paid_shopping_brand: {
      label: t(`mainFormView.channelsTab.category.paidShoppingBrand`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidShopping`)
    },
    paid_shopping_generic: {
      label: t(`mainFormView.channelsTab.category.paidShoppingGeneric`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidShopping`)
    },
    paid_impression_remarketing: {
      label: t(`mainFormView.channelsTab.category.paidImpressionRemarketing`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidImpressionChannels`)
    },
    paid_impression_prospecting: {
      label: t(`mainFormView.channelsTab.category.paidImpressionProspecting`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidImpressionChannels`)
    },
    paid_impression_awareness: {
      label: t(`mainFormView.channelsTab.category.paidImpressionAwareness`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidImpressionChannels`)
    },
    paid_social_remarketing: {
      label: t(`mainFormView.channelsTab.category.paidSocialRemarketing`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidSocial`)
    },
    paid_social_prospecting: {
      label: t(`mainFormView.channelsTab.category.paidSocialProspecting`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidSocial`)
    },
    paid_social_awareness: {
      label: t(`mainFormView.channelsTab.category.paidSocialAwareness`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidSocial`)
    },
    paid_affiliate: {
      label: t(`mainFormView.channelsTab.category.paidAffiliate`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidOther`)
    },
    paid_other: {
      label: t(`mainFormView.channelsTab.category.paidOther`),
      subcategory: t(`mainFormView.channelsTab.subcategory.paidOther`)
    }
  }

  const badgeColorOptions = badgeColors.map((color) => ({
    label: capitalize(color),
    value: color,
    icon: <Badge color={color} />
  }))

  const channelCategoryOptions = CHANNEL_CATEGORIES.map((category) => {
    return {
      value: category,
      label: channelCategoryCamelCase[category].label,
      subCategory: channelCategoryCamelCase[category].subcategory
    }
  })

  const badgeColorDropdownProps = {
    title: t('mainFormView.channelsTab.tableCard.channelColor'),
    buttonTitle: t('mainFormView.channelsTab.tableCard.channelColor'),
    options: badgeColorOptions,
    buttonWidth: '160px',
    value: localChannelColor,
    onSelect: (value: string) => {
      setLocalChannelColor(value as BadgeColor)
    }
  }

  const channelCategoryDropdownProps = {
    title: t('mainFormView.channelsTab.tableCard.channelCategory'),
    buttonTitle: t('mainFormView.channelsTab.tableCard.channelCategory'),
    buttonWidth: '250px',
    options: channelCategoryOptions,
    value: localChannelCategory.toString(),
    onSelect: (value: string) => {
      setLocalChannelCategory(value as ChannelCategoryType)
    }
  }

  return (
    <Grid display="flex" flexDirection="column" gap="16px">
      {isShowingNote && (
        <StyledGridNoteContainer>
          {isEditingNote ? (
            <Grid width="100%" gap="8px" display="flex" flexDirection="column">
              <Typography variant="h5">{t('mainFormView.channelsTab.tableCard.channelNote')}</Typography>
              <Input
                name="channel_note"
                value={localChannelNote ?? ''}
                rows={3}
                fullWidth
                onChange={(event) => {
                  setLocalChannelNote(event.currentTarget.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault()
                    handleSaveNoteButtonClick()
                  }
                }}
                multiline
              />
              <Grid paddingTop="8px" gap="8px" display="flex">
                <Button
                  variant="filled"
                  disabled={localChannelNote === channel.note}
                  onClick={handleSaveNoteButtonClick}
                >
                  {t('mainFormView.channelsTab.tableCard.saveNote')}
                </Button>
                <Button variant="tonal" onClick={handleCancelNoteEditingButtonClick}>
                  {t('mainFormView.channelsTab.tableCard.cancelNote')}
                </Button>
                {channel.note !== null && (
                  <Button
                    variant="semantic-destructive"
                    onClick={handleDeleteNoteButtonClick}
                    rightIcon={<TrashIcon />}
                  >
                    {t('mainFormView.channelsTab.tableCard.deleteNote')}
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid width="100%">
                <Typography variant="body2">{t('mainFormView.channelsTab.tableCard.channelNote')}</Typography>
                <StyledTypography3LineClamp variant="body3">{channel.note}</StyledTypography3LineClamp>
              </Grid>
              <Grid>
                <Button
                  variant="text"
                  onClick={() => {
                    setIsEditingNote(true)
                  }}
                  rightIcon={<EditIcon />}
                />
              </Grid>
            </>
          )}
        </StyledGridNoteContainer>
      )}
      <StyledGridPropertiesContainer isShowingNote={isShowingNote}>
        <Typography variant="h2">{t('mainFormView.channelsTab.tableCard.channelProperties')}</Typography>
        <Grid display="flex" flexWrap="nowrap">
          <StyledGridInputContainer>
            <Input
              name="channel_name"
              value={localChannelName}
              onChange={(event) => {
                setLocalChannelName(event.currentTarget.value)
              }}
              fullWidth
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleUpdateButtonClick()
                }
              }}
              inputProps={{
                'data-form-type': 'other'
              }}
            />
          </StyledGridInputContainer>
          {canViewAttributionRbaFeature ? (
            <>
              <StyledMiddleSingleSelectDropdown {...badgeColorDropdownProps} />
              <StyledRightEndSingleSelectDropdown {...channelCategoryDropdownProps} />
            </>
          ) : (
            <StyledRightEndSingleSelectDropdown {...badgeColorDropdownProps} />
          )}

          <StyledButtonUpdate
            variant="tonal"
            onClick={handleUpdateButtonClick}
            disabled={
              localChannelName === channel.name &&
              localChannelColor === channel.color &&
              localChannelCategory === channel.category
            }
          >
            {t('mainFormView.channelsTab.tableCard.updateEverywhere')}
          </StyledButtonUpdate>
        </Grid>
        <Typography variant="body3">{t('mainFormView.channelsTab.tableCard.updateAnnotation')}</Typography>
        <Divider />
        <Grid display="flex" gap="8px" alignItems="center">
          <Typography variant="h4">{t('mainFormView.channelsTab.tableCard.channelExistsIn')}</Typography>
          <Chip
            label={t('mainFormView.channelsTab.tableCard.channelExistsInChip', {
              count: numDataSources,
              suffix: numDataSources > 1 ? 's' : ''
            })}
          />
        </Grid>
        <Grid display="flex" flexDirection="column" gap="16px">
          {Object.keys(channel.channelRules).map((dataSourceId) => {
            const dataSource = dataSourcesMap[dataSourceId as Platforms] as DataSourceItem
            return (
              <StyledCardRelatedDataSource
                key={dataSource.platform}
                onClick={() => {
                  handleRelatedDataSourceCardClick(dataSource.platform)
                }}
              >
                <Grid display="flex" justifyContent="space-between" width="100%" alignItems="center">
                  <Grid display="flex" gap="16px">
                    <Avatar size="medium" kind="image" imageUrl={dataSource?.iconUrl ?? ''} />
                    <Grid display="flex" flexDirection="column">
                      <Typography variant="h3">{dataSource?.label}</Typography>
                      <ConnectedDataSourcesAnnotation
                        connectedDataSources={dataSource.connectedDataSources}
                        dataSource={dataSource.platform}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    height="100%"
                    marginLeft="16px"
                    width="40px"
                    justifyContent="center"
                  >
                    <RightIcon />
                  </Grid>
                </Grid>
              </StyledCardRelatedDataSource>
            )
          })}
        </Grid>
      </StyledGridPropertiesContainer>
    </Grid>
  )
}

const StyledGridPropertiesContainer = styled(Grid)<{ isShowingNote: boolean }>(({ isShowingNote, theme }) => ({
  padding: `${isShowingNote ? '24px' : '8px'} 0px 0px 0px`,
  gap: '8px',
  display: 'flex',
  flexDirection: 'column',
  '& > h2': {
    paddingBottom: '16px'
  },
  '& > span': {
    color: theme?.palette.neutrals.stone150 as string
  }
}))

const StyledButtonUpdate = styled(Button)(() => ({
  marginLeft: '16px'
}))

const StyledRightEndSingleSelectDropdown = styled(SingleSelectDropdown)(() => ({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}))

const StyledMiddleSingleSelectDropdown = styled(StyledRightEndSingleSelectDropdown)(() => ({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}))

const StyledGridInputContainer = styled(Grid)(() => ({
  width: '480px',
  '.MuiInputBase-root': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  '.MuiOutlinedInput-root fieldset': {
    borderRight: 'none',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}))

const StyledGridNoteContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme?.palette.neutrals.stone90 as string,
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  borderRadius: '32px'
}))

const StyledTypography3LineClamp = styled(Typography)({
  display: '-webkit-box',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  whiteSpace: 'pre-line'
})

const StyledAccordionCardContainer = styled(Accordion)(({ theme, expanded }: { theme?: Theme; expanded: boolean }) => ({
  borderRadius: theme?.borderRadius.xLarge,
  outline: `1px solid ${theme?.palette.neutrals.stone100 as string}`,
  boxShadow: expanded ? theme?.elevation.mediumDepth : 'none',
  transition: 'box-shadow 200ms ease-in-out',
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  },
  '.MuiAccordionSummary-root': {
    backgroundColor: theme?.palette.neutrals.white0,
    padding: '20px',
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge,
    '.MuiAccordionSummary-expandIconWrapper': {
      width: '40px',
      justifyContent: 'center',
      color: theme?.palette.secondary.main
    },
    '.kurama-AvatarGroup': {
      alignItems: 'center'
    }
  },
  '&.MuiAccordion-root:before': {
    display: 'none'
  },
  '.MuiAccordionDetails-root': {
    padding: '20px',
    paddingTop: 0,
    backgroundColor: theme?.palette.neutrals.white0,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  }
}))

const StyledCardRelatedDataSource = styled(Card)(() => ({
  margin: 0
}))

export default ChannelsTableCard
