declare module '@mui/material/styles' {
  interface Theme {
    elevation: ElevationTypes
  }
  interface ThemeOptions {
    elevation?: ElevationTypes
  }
}

export type ElevationOptions = 'flat' | 'mediumDepth' | 'largeDepth'

export const elevation: ElevationTypes = {
  flat: 'none',
  mediumDepth: '0px 6px 12px rgba(112, 144, 176, 0.12)',
  largeDepth: '0px 16px 32px rgba(112, 144, 176, 0.2)'
}

export type ElevationTypes = { [key in ElevationOptions]: string }
