import { Avatar, Grid, Typography } from '@precis-digital/kurama'
import React from 'react'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

import { type Platforms } from 'shared/api/accounts'
import { mapPlatformName } from 'budgetOptimiser/components/Overview/detailedReportUtils'

export const PlatformWithLabel = ({
  dataSource,
  showLabel
}: {
  dataSource: Platforms
  showLabel: boolean
}): React.ReactElement => {
  const platform = dataSource !== null ? getPlatformDetailsByPlatform(mapPlatformName(dataSource)) : null
  return (
    <Grid container flexWrap="nowrap" alignItems="center" gap="8px">
      {platform !== null && (
        <>
          <Grid item>
            <Avatar size="small" imageUrl={platform?.iconUrl ?? ''} kind="image" />
          </Grid>
          {showLabel && <Typography variant="body2">{platform?.label}</Typography>}
        </>
      )}
      {platform === null && <Typography variant="body2">Unknown</Typography>}
    </Grid>
  )
}
export default PlatformWithLabel
