import React, { useState, useEffect, useMemo } from 'react'
import {
  Avatar,
  Badge,
  DatePicker,
  Divider,
  Grid,
  PopUp,
  styled,
  Tab,
  Tabs,
  Toggle,
  Typography,
  Banner
} from '@precis-digital/kurama'
import { type BadgeColor } from '@precis-digital/kurama/src/components/Badge/types'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import ReturnCurveGraph from './ReturnCurveGraph'
import { format } from 'date-fns'
import { extractCampaignNameFromDimensionCampaign } from 'budgetOptimiser/utils'
import { type Platforms } from 'shared/api/accounts'
import { type DetailedReportData } from 'budgetOptimiser/api/transformations/transform'
import Dots from 'shared/components/Loader/Dots'
import {
  useMutationPreprocData,
  useQueryBudgetOptimisers,
  useMutationCurrencyConversionRates
} from 'budgetOptimiser/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import {
  transformPreprocDataToReturnCurveData,
  type CompleteReturnCurveData
} from 'budgetOptimiser/api/transformations/returnCurve'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

import { mapPlatformName } from 'budgetOptimiser/components/Overview/detailedReportUtils'
import { convertSnakeCaseToSentenceCase } from 'shared/utils/stringFormatter'

interface GraphIndicatorProps {
  text: string
  color: BadgeColor
  value: number
}

interface ReturnCurvePopupProps {
  rowData: DetailedReportData
}

interface GraphIndicatorsProps extends Array<GraphIndicatorProps> {}

interface ReturnCurveGraphContainerProps {
  transformedReturnCurveData?: CompleteReturnCurveData
  isLoading: boolean
  toggleFilters: boolean
  filters: number
  currencyCode: string
  onClickBanner: () => void
}

const ReturnCurveGraphContainer = ({
  transformedReturnCurveData,
  isLoading,
  toggleFilters,
  filters,
  currencyCode,
  onClickBanner
}: ReturnCurveGraphContainerProps): React.ReactElement => {
  if (isLoading) {
    return <Dots />
  }

  if (transformedReturnCurveData === undefined) {
    return (
      <Banner
        inline
        onClick={() => {
          onClickBanner()
        }}
        variant="warning"
      >
        <Typography variant="body2">No data available for this campaign</Typography>
      </Banner>
    )
  }

  return (
    <ReturnCurveGraph
      returnCurveData={transformedReturnCurveData}
      toggleFilters={toggleFilters}
      filters={filters}
      currencyCode={currencyCode}
    />
  )
}

const ReturnCurvePopup = ({ rowData }: ReturnCurvePopupProps): React.ReactElement => {
  const screen = useOverScreen('returnCurvePopup')
  const { t } = useTranslation('budgetOptimiser')
  const { currentClient } = useCurrentClient()

  const targetDate = format(new Date(rowData.targetDate), 'yyyy-MM-dd')

  const { data: budgetOptimiserConfigs } = useQueryBudgetOptimisers(currentClient.id)

  const budgetOptimiserConfig = budgetOptimiserConfigs?.find((config) => {
    return config.id === rowData.configId
  })

  const platform = rowData.platform as Platforms
  const platformURL = getPlatformDetailsByPlatform(mapPlatformName(platform))?.iconUrl

  const currencyCode = currentClient.currency

  const returnCurveTitle =
    rowData.targetType === 'revenue' ? t('returnCurvePopup.revenueTitle') : t('returnCurvePopup.conversionsTitle')

  useEffect(() => {
    if (targetDate === undefined) {
      return
    }
    getBudgetOptimiserPreprocData({
      id: rowData.configId?.toString() ?? '',
      collection: 'bo',
      report: 'bo_preproc',
      fields: [
        { name: 'target_date' },
        { name: 'spend' },
        { name: 'spend_ea' },
        { name: 'clicks' },
        { name: 'clicks_ea' },
        { name: 'conversions' },
        { name: 'impressions' },
        { name: 'date' },
        { name: 'target_currency' }
      ],
      clientId: currentClient.id ?? '',
      filters: [
        {
          field: 'target_date',
          operator: '=',
          value: targetDate
        },
        {
          field: 'campaign_id',
          operator: '=',
          value: rowData.campaignId
        }
      ],
      targetCurrencyCode: currencyCode
    })

    getCurrencyConversionRatesData({
      id: rowData.configId?.toString() ?? '',
      collection: 'conversion_rates',
      report: 'conversion_rates',
      fields: [
        { name: 'date' },
        { name: 'source_date' },
        { name: 'base_currency' },
        { name: 'target_currency' },
        { name: 'exchange_rate' }
      ],
      clientId: currentClient.id ?? '',
      filters: [
        {
          field: 'source_date',
          operator: '=',
          value: targetDate
        },
        {
          field: 'target_currency',
          operator: 'IN',
          value: [rowData.targetCurrency, currencyCode !== null ? currencyCode : rowData.targetCurrency]
        }
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate])

  const {
    mutate: getBudgetOptimiserPreprocData,
    data: preprocData,
    isLoading: isPreprocDataLoading
  } = useMutationPreprocData()

  const {
    mutate: getCurrencyConversionRatesData,
    data: conversionRatesData,
    isLoading: isConversionRatesDataLoading
  } = useMutationCurrencyConversionRates()

  const transformedReturnCurveData = useMemo(() => {
    if (preprocData === undefined || conversionRatesData === undefined) {
      return undefined
    }
    return transformPreprocDataToReturnCurveData(preprocData, rowData, conversionRatesData, currencyCode)
  }, [preprocData, rowData, conversionRatesData, currencyCode])

  const graphIndicators: GraphIndicatorsProps = [
    {
      text: t('returnCurvePopup.graphBadgeCurrent'),
      color: 'info',
      value: 0
    },
    {
      text: t('returnCurvePopup.graphBadgeOptimal'),
      color: 'success',
      value: 0
    },
    {
      text: t('returnCurvePopup.graphBadgeLookback'),
      color: 'steel',
      value: 1
    },
    {
      text: t('returnCurvePopup.graphBadgeWeekdays'),
      color: 'mint',
      value: 2
    },
    {
      text: t('returnCurvePopup.graphBadgeWeekends'),
      color: 'dark-red',
      value: 2
    },
    {
      text: t('returnCurvePopup.graphBadgeOlder'),
      color: 'purple',
      value: 3
    },
    {
      text: t('returnCurvePopup.graphBadgeNewer'),
      color: 'dark-purple',
      value: 3
    }
  ]

  const [toggleFilters, setToggleFilters] = useState(true)
  const [filters, setFilters] = useState<number>(3)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setFilters(newValue)
  }

  const indicatorsBadges = (indicatorIndex?: number): React.ReactElement[] => {
    if (indicatorIndex === undefined) return []
    return graphIndicators
      .filter((indicator) => indicator.value === 0 || (toggleFilters && indicator.value === indicatorIndex))
      .map((indicator, index) => <StyledStatusCell key={index} color={indicator.color} text={indicator.text} />)
  }

  return (
    <StyledPopUp
      title={returnCurveTitle}
      open={screen.visible}
      handleOpen={(): void => {
        screen.remove()
      }}
    >
      <>
        <Grid marginTop="26px" />
        <Grid display="flex" flexDirection="row" width="100%" marginTop="24px">
          {platform !== null && <Avatar size="large" imageUrl={platformURL} kind="image" />}
          <StyledTitleGrid>
            <Grid display="flex" flexDirection="column" alignItems="start">
              <Typography variant="h2">{extractCampaignNameFromDimensionCampaign(rowData.campaign)}</Typography>
              <Typography variant="body1">{convertSnakeCaseToSentenceCase(rowData.platformChannelGrouping)}</Typography>
            </Grid>
            <Grid display="flex" flexDirection="column" alignItems="end" gap="10px">
              <Typography variant="h2">
                {budgetOptimiserConfig?.lookbackDays} {t('returnCurvePopup.dayFragment')}
              </Typography>
              <DatePicker disabled defaultValue={targetDate} value={targetDate} onChange={(date) => {}} />
            </Grid>
          </StyledTitleGrid>
        </Grid>
        <Divider />
        <Grid display="flex" flexDirection="row" width="100%" justifyContent="space-between">
          <Grid display="flex" flexDirection="row" alignItems="center" gap="16px">
            <Toggle
              defaultChecked={toggleFilters}
              onChange={() => {
                setToggleFilters(!toggleFilters)
              }}
              checked={toggleFilters}
            />
            <Typography variant="body2">{t('returnCurvePopup.toggleText')}</Typography>
          </Grid>
          <Grid display="flex" flexDirection="row" gap="24px" alignItems="center">
            <Typography variant="body2">{t('returnCurvePopup.tabHighlight')}</Typography>
            <Tabs currentTab={toggleFilters ? filters : undefined} handleChange={handleTabChange}>
              <Tab value={1} disabled={!toggleFilters}>
                {t('returnCurvePopup.tabNone')}
              </Tab>
              <Tab value={2} disabled={!toggleFilters}>
                {t('returnCurvePopup.tabWeekdays')}
              </Tab>
              <Tab value={3} disabled={!toggleFilters}>
                {t('returnCurvePopup.tabOlder')}
              </Tab>
            </Tabs>
          </Grid>
        </Grid>
        <Divider />
        <Grid display="flex" flexDirection="row" gap="13px" marginBottom="24px">
          {indicatorsBadges(filters)}
          <StyledConfidenceStatusCell>
            <Grid>
              <Badge color="stone" />
              <Typography variant="body3"> {t('returnCurvePopup.graphBadgeInterval')} </Typography>
            </Grid>
          </StyledConfidenceStatusCell>
        </Grid>
        <ReturnCurveGraphContainer
          transformedReturnCurveData={transformedReturnCurveData}
          isLoading={isPreprocDataLoading || isConversionRatesDataLoading}
          toggleFilters={toggleFilters}
          filters={filters}
          currencyCode={currencyCode}
          onClickBanner={() => {
            screen.remove()
          }}
        />
      </>
    </StyledPopUp>
  )
}

const StyledStatusCell = ({
  key,
  color,
  text
}: {
  key: number | string
  color: BadgeColor
  text: string
}): React.ReactElement => (
  <StyledCell key={key}>
    <Grid>
      <Badge color={color} />
      <Typography variant="body3"> {text} </Typography>
    </Grid>
  </StyledCell>
)

export default OverScreen.create(ReturnCurvePopup)

const StyledPopUp = styled(PopUp)(() => ({
  '> .MuiGrid-root': {
    width: '1128px'
  }
}))

const StyledCell = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  '>div': {
    backgroundColor: theme.palette.neutrals.stone100,
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    span: {
      marginLeft: '10px'
    }
  }
}))

const StyledConfidenceStatusCell = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  '>div': {
    backgroundColor: theme.palette.neutrals.white0,
    border: `1px solid ${theme.palette.neutrals.stone100 as string}`,
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    span: {
      marginLeft: '10px'
    }
  }
}))

const StyledTitleGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginLeft: '24px',
  overflowWrap: 'anywhere'
}))
