import { forwardRef, ReactElement } from 'react'
import { DROPDOWN_CLASS_NAME } from '../classNames'
import { StyledPopover } from './styles'
import { DropdownProps } from './types'

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ anchorEl, open, onClose, children, disablePortal, ...props }, ref): ReactElement => {
    return (
      <StyledPopover
        className={DROPDOWN_CLASS_NAME}
        anchorEl={anchorEl}
        disablePortal={disablePortal}
        onClose={onClose}
        open={open}
        disableScrollLock
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        {...props}
        ref={ref}
      >
        {children}
      </StyledPopover>
    )
  }
)

export default Dropdown
