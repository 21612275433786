export const CHANNEL = 'channel'
export const ADSPEND = 'adSpend'
export const REVENUE = 'revenue'
export const ROAS = 'roas'
export const CONVERSIONS = 'conversions'
export const IMPRESSIONS = 'impressions'
export const COSTPERACTION = 'costPerAction'
export const ALLCHANNELS = 'allChannels'
export const METRIC = 'metric'
export const ALLMETRICS = 'allMetrics'

export const DIFFERENCE = 'difference'
export const FIRSTSUBHEADER = 'firstHeader'
export const SECONDSUBHEADER = 'secondHeader'

export const attributionTableID = (modelId: number): string => `ia_credit-allocator_${modelId}__output__main_v1`
export const budgetOptimiserTableID = (modelId: number): string => `ia_curves_${modelId}_optimals_v1`

export const DEFINED_MODELS = {
  lnda: {
    value: 'lnda',
    label: 'LNDA'
  },
  ga4Default: {
    value: 'ga4Default',
    label: 'GA4 Default'
  },
  ga4Lnda: {
    value: 'ga4Lnda',
    label: 'GA4 Session-scoped'
  }
} as const

export const GA4_EXCLUSION_LIST: string[] = ['5158340522934272']
