import { forwardRef } from 'react'
import { FormFrameProps } from './types'
import Grid from '../Grid'
import Skeleton from '../Skeleton'
import Divider from '../Divider'
import { StyledGreyContainer, StyledWhiteContainer } from './styles'

export const FormFrame = forwardRef<HTMLDivElement, FormFrameProps>(
  ({ header, content, footer, information, ...props }, ref): React.ReactElement => {
    const contentSkeleton = (
      <Grid display="flex" flexDirection="column" gap="24px" width="100%">
        <ContentSkeleton />
        <Grid>
          <Divider />
        </Grid>
      </Grid>
    )
    return (
      <Grid ref={ref} container spacing={7} flexWrap="nowrap" {...props}>
        <Grid item xs>
          <form>
            {header ?? <HeaderSkeleton />}
            <Grid marginTop="24px">
              {content.map((element, index) => (
                <Grid key={index}>{element ?? contentSkeleton}</Grid>
              ))}
            </Grid>
            {footer ?? <FooterSkeleton />}
          </form>
        </Grid>
        <Grid item alignSelf="flex-start" position="sticky" top={0}>
          {information ?? <StepperSkeleton />}
        </Grid>
      </Grid>
    )
  }
)

const HeaderSkeleton = (): React.ReactElement => {
  return (
    <Grid display="flex" justifyContent="space-between" alignItems="start">
      <Grid display="flex" gap="22px" flexDirection="column" width="100%">
        <Grid display="flex" flexDirection="column" gap="5px">
          <Skeleton variant="text" height="36px" width="202px" />
          <Skeleton variant="text" height="24px" width="328px" />
        </Grid>
        <Grid display="flex" alignItems="center" width="100%" justifyContent="space-between" height="64px">
          <Skeleton variant="rounded" height="40px" width="96px" />
          <Skeleton variant="rounded" height="40px" width="96px" />
        </Grid>
      </Grid>
    </Grid>
  )
}

const ContentSkeleton = (): React.ReactElement => {
  return (
    <Grid display="flex" flexDirection="column" gap="24px">
      <Grid>
        <Skeleton variant="text" height="16px" width="72px" />
        <Skeleton variant="text" height="34px" width="154px" />
      </Grid>
      <Grid display="flex" flexDirection="column" gap="16px">
        <CardSkeleton />
        <CardSkeleton />
      </Grid>
    </Grid>
  )
}

const CardSkeleton = (): React.ReactElement => {
  return (
    <StyledGreyContainer height="88px" width="100%" padding="1px" borderRadius="32px">
      <StyledWhiteContainer
        padding="20px"
        height="100%"
        display="flex"
        alignItems="center"
        width="100%"
        position="relative"
        borderRadius="32px"
        gap="16px"
      >
        <Skeleton variant="rounded" height="40px" width="40px" />
        <Skeleton variant="rounded" height="40px" width="442px" />
      </StyledWhiteContainer>
    </StyledGreyContainer>
  )
}

const StepperSkeleton = (): React.ReactElement => {
  return (
    <Grid display="flex" flexDirection="column" gap="26px" alignItems="start">
      <Skeleton variant="rounded" height="118px" width="150px" />
      <Skeleton variant="rounded" height="24px" width="99px" />
      <Grid display="flex" alignItems="center" gap="16px">
        <Grid display="flex" gap="12px" flexDirection="column">
          <Skeleton variant="rounded" height="15px" width="44px" />
          <Skeleton variant="rounded" height="15px" width="44px" />
          <Skeleton variant="rounded" height="15px" width="44px" />
          <Skeleton variant="rounded" height="15px" width="44px" />
        </Grid>
        <Grid>
          <Skeleton variant="rounded" height="120px" width="8px" />
        </Grid>
        <Grid display="flex" gap="12px" flexDirection="column">
          <Skeleton variant="rounded" height="15px" width="44px" />
          <Skeleton variant="rounded" height="15px" width="44px" />
          <Skeleton variant="rounded" height="15px" width="44px" />
          <Skeleton variant="rounded" height="15px" width="44px" />
        </Grid>
      </Grid>
    </Grid>
  )
}

const FooterSkeleton = (): React.ReactElement => {
  return (
    <StyledGreyContainer width="100%" padding="1px" borderRadius="32px">
      <StyledWhiteContainer
        display="flex"
        direction="column"
        padding="20px"
        height="100%"
        width="100%"
        borderRadius="32px"
        gap="16px"
      >
        <Skeleton height="28px" width="320px" />
        <Skeleton height="24px" width="542px" />
        <Grid display="flex" gap="8px">
          <Skeleton height="40px" width="148px" />
          <Skeleton height="40px" width="189px" />
        </Grid>
      </StyledWhiteContainer>
    </StyledGreyContainer>
  )
}
