import Link from '../Link'
import { styled } from '../../utils'
import Grid from '../Grid'
import { Theme } from '../theme'
import { CSSObject } from '@mui/material/styles'
import { getClickableStyles } from '../Card/styles'

export const StyledLink = styled(Link)(
  (): CSSObject => ({
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:link': {
      color: 'inherit'
    }
  })
)

export const StyledBanner = styled('div')(
  ({
    inline,
    theme,
    isClickable
  }: {
    inline: boolean | undefined
    isClickable?: boolean
    theme?: Theme
  }): CSSObject => ({
    border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
    borderRadius: inline === true ? theme?.borderRadius.xLarge : theme?.borderRadius.xxLarge,
    transition: 'box-shadow 100ms ease-in-out',
    boxShadow: theme?.elevation.flat,
    display: 'flex',
    ...(isClickable === true && getClickableStyles(theme)),
    alignItems: 'center',
    marginTop: 0,
    justifyContent: 'space-between',
    padding: '23px 20px 21px 20px',
    div: {
      alignSelf: 'center'
    },
    button: {
      alignSelf: 'center',
      paddingLeft: '14px',
      paddingRight: '14px'
    },
    textDecoration: 'none',
    color: 'default'
  })
)

export const StyledContent = styled(Grid)(
  (): CSSObject => ({
    padding: '0 24px'
  })
)

export const StyledIconContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    padding: '8px',
    borderRadius: theme?.borderRadius.medium,
    backgroundColor: theme?.palette.neutrals.stone100
  })
)
