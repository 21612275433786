import React, { Fragment, ReactElement, useEffect, useMemo, useState } from 'react'
import { ConditionInputSelectOption, ConditionInputSelectProps } from './types'
import { StyledGridInputContainer, StyledTypographyPlaceholder } from './style'
import Typography from '../../Typography'
import Dropdown from '../../Dropdown/Dropdown'
import DropdownItem from '../../Dropdown/DropdownItem'
import DropdownListSubCategory from '../../Dropdown/DropdownListSubCategory'
import Divider from '../../Divider'
import DropdownList from '../../Dropdown/DropdownList'
import Grid from '../../Grid'
import { StyledGridContainer } from '../styles'
import { InputState } from '../types'

const renderOption = (option: ConditionInputSelectOption, handleOptionClick: (value: string) => void): ReactElement => {
  return (
    <DropdownItem
      key={option.value}
      onClick={() => handleOptionClick(option.value)}
      annotation={option.annotation}
      icon={option.icon}
      disabled={option.disabled}
    >
      {option.label}
    </DropdownItem>
  )
}

const ConditionInputSelect = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  options,
  placeholder,
  saved = false,
  hasError = false,
  fullWidth,
  width = '472px'
}: ConditionInputSelectProps): ReactElement => {
  const [inputState, setInputState] = useState<InputState>('enabled')
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLDivElement | null>(null)
  const selectedOption = options.find((option) => option.value === value)

  const handleSelectClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setDropdownAnchorEl(event.currentTarget)
    setInputState('focused')
  }

  const handleDropdownClose = (): void => {
    setDropdownAnchorEl(null)
    setInputState('enabled')
  }

  const handleOptionClick = (value: string | undefined): void => {
    onChange(value)
    handleDropdownClose()
  }

  const isDropdownOpen = !(dropdownAnchorEl == null)

  const subCategoryMap = useMemo(() => {
    const map: Record<string, ConditionInputSelectOption[]> = {}
    options.forEach((option) => {
      if (option.subCategory != null) {
        if (map[option.subCategory] == null) {
          map[option.subCategory] = []
        }
        map[option.subCategory].push(option)
      }
    })

    if (Object.keys(map).length > 0) {
      return map
    }
  }, [options])

  useEffect(() => {
    if (inputState === 'focused') {
      if (onFocus != null) {
        onFocus()
      }
    } else if (inputState === 'enabled') {
      if (onBlur != null) {
        onBlur()
      }
    }
  }, [inputState])

  return saved ? (
    <Grid
      container
      height="40px"
      width={fullWidth === true ? '100%' : width}
      alignItems="center"
      gap="4px"
      padding="0px 12px 0px 16px"
    >
      {selectedOption?.icon != null ? (
        <Grid width="24px" height="24px">
          {selectedOption?.icon}
        </Grid>
      ) : (
        <></>
      )}
      <Typography variant="h4" style={{ minHeight: '28px', padding: '3px 0px 1px 0px' }}>
        {selectedOption?.label}
      </Typography>
    </Grid>
  ) : (
    <>
      <StyledGridContainer
        state={inputState}
        onClick={inputState === 'enabled' ? (e) => handleSelectClick(e) : undefined}
        width={fullWidth === true ? '100%' : width}
        hasError={hasError}
      >
        <Grid width="100%">
          <Typography variant="h5">{label}</Typography>
          <StyledGridInputContainer>
            {value == null ? (
              <StyledTypographyPlaceholder variant="body1">{placeholder}</StyledTypographyPlaceholder>
            ) : (
              <></>
            )}
            {value != null && selectedOption?.icon != null ? (
              <Grid width="24px" height="24px">
                {selectedOption?.icon}
              </Grid>
            ) : (
              <></>
            )}
            <Typography variant="body1" style={{ minHeight: '24px' }}>
              {selectedOption?.label}
            </Typography>
          </StyledGridInputContainer>
        </Grid>
      </StyledGridContainer>
      <Dropdown anchorEl={dropdownAnchorEl} open={isDropdownOpen} onClose={handleDropdownClose}>
        <DropdownList dense header={label} minWidth={dropdownAnchorEl?.clientWidth}>
          {subCategoryMap != null
            ? Object.entries(subCategoryMap).map(([subCategory, categoryOptions], index) => {
                return (
                  <Fragment key={subCategory}>
                    {index !== 0 && <Divider />}
                    <DropdownListSubCategory header={subCategory}>
                      {categoryOptions.map((option) => renderOption(option, handleOptionClick))}
                    </DropdownListSubCategory>
                  </Fragment>
                )
              })
            : options.map((option) => renderOption(option, handleOptionClick))}
        </DropdownList>
      </Dropdown>
    </>
  )
}

export default ConditionInputSelect
