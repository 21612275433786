import React from 'react'

export const EnhancedSignals = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12 13.78C12.983 13.78 13.78 12.983 13.78 12C13.78 11.0169 12.983 10.22 12 10.22C11.0169 10.22 10.22 11.0169 10.22 12C10.22 12.983 11.0169 13.78 12 13.78Z"
        fill="currentColor"
      />
      <path
        d="M12 7.64001C9.59002 7.64001 7.64001 9.59001 7.64001 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
})
