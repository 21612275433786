import { type Dispatch, type SetStateAction } from 'react'
import type React from 'react'

export const handleConfirmationChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  confirmationWord: string,
  setConfirmationWord: Dispatch<SetStateAction<string>>
): void => {
  const value = e.target.value.toUpperCase()
  if (value.length <= confirmationWord.length) {
    setConfirmationWord(value)
  }
}
