import { Flyout } from '@precis-digital/kurama'
import { type IntegrationAttributionsResp } from 'shared/api/budgetOptimiser'
import SwitchFlyout, { type Options } from 'shared/components/SwitchFlyout'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'

interface SwitchConfigFlyoutProps {
  budgetOptimiserListConfigs: IntegrationAttributionsResp
  onSelectConfig: (configId: number | string) => void
}

const SwichConfigFlyout = ({
  budgetOptimiserListConfigs,
  onSelectConfig
}: SwitchConfigFlyoutProps): React.ReactElement => {
  const screen = useOverScreen('budgetOptimiserSwitchConfigFlyout')
  const { t } = useTranslation('budgetOptimiser')

  const handleClickOption = (configId: number | string): void => {
    onSelectConfig(configId)
    screen.remove()
  }

  const getFilteredCards = (searchInput: string, options: Options): Options => {
    if (searchInput === '') return options
    return options?.filter((card) => card.name.toLowerCase().match(searchInput.toLowerCase()))
  }

  return (
    <Flyout
      onClose={(): void => {
        screen.remove()
      }}
      isExpanded={screen.visible}
    >
      <SwitchFlyout
        title={t('switchFlyout.title')}
        options={budgetOptimiserListConfigs ?? []}
        searchPlaceholder={t('switchFlyout.placeholder')}
        onClickOption={(option) => {
          handleClickOption(option.id)
        }}
        onFiltering={getFilteredCards}
      />
    </Flyout>
  )
}

export default OverScreen.create(SwichConfigFlyout)
