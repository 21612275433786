import React from 'react'

export const Logout = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M9.7875 20H6.23194C5.76044 20 5.30826 19.8127 4.97486 19.4793C4.64146 19.1459 4.45416 18.6937 4.45416 18.2222V5.77778C4.45416 5.30628 4.64146 4.8541 4.97486 4.5207C5.30826 4.1873 5.76044 4 6.23194 4H9.7875"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.01 16.4442L20.4545 11.9998L16.01 7.55535"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4543 12.0002H9.78766"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
