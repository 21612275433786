import React from 'react'

interface Props {
  width: number
  height: number
}

const BlankIcon: React.FC<Props> = ({ width, height }): React.ReactElement => <svg style={{ width, height }} />

export default BlankIcon
