import { type TargetsTypes, type TransformationTypes } from 'enhancedSignals/types'
import fetch, { baseClient } from './fetch'

const client = fetch()
const transferClient = baseClient({ headers: { 'content-type': 'text/csv' } })
export const paths = {
  enhancedSignals: '/enhanced_signals'
}
type EventName = 'precis_ecomm' | 'purchase' | ''
export interface EnhancedSignalsResp {
  bucketId: string
  clientId: number
  configName: string
  createdAt: number
  createdBy: number
  deleted: boolean
  enabled: boolean
  id: number
  targetCurrency: string | null
  targets: [
    {
      apiSecret: string
      enabled: true
      eventName: EventName
      measurementId: string
      targetId: TargetsTypes
      url: string
      propertyId: string
      sgtmToken: string
      gadsAccountId: string
      authAccount: string
      conversionActionId: string
      firebaseAppId: string
      currency: string
      accessToken: string
      pixelId: string
    }
  ]
  transformations: [
    {
      enabled: boolean
      lookupRules: LookupRule[]
      transformId: TransformationTypes
    }
  ]
  updatedAt: number
  updatedBy: number
  version: 'v1'
}

export interface ConfigIdEnhancedSignalsReq {
  clientId: string
  configId: number
}

export interface CreateSignedUrlReq {
  clientId: string
  configId: number
  fileName: string
  transferType: 'upload' | 'download'
}
export interface CreateUploadUrlResp {
  signedURL: string
}

export interface LookupRule {
  key: string
  operation: 'multiply' | 'divide' | 'add' | 'subtract' | ''
  lookupFile: {
    fileData?: File
    fileName: string
  }
  id?: string
  isNewLookupRule: boolean
}

export interface TransformationsReq {
  transformId: TransformationTypes
  enabled: boolean
  files?: string[]
  lookupRules?: Array<Omit<LookupRule, 'isNewLookupRule'>>
}

export interface TargetsReq {
  apiSecret?: string
  enabled: boolean
  eventName?: EventName
  measurementId?: string
  targetId: TargetsTypes
  url?: string
  propertyId?: string
  sgtmToken?: string
  bearerPath?: string
  firebaseAppId?: string
  gadsAccountId?: string
  authAccount?: string
  conversionActionId?: string
  currency?: string
  accessToken?: string
  pixelId?: string
}
export interface EnhancedSignalsReq {
  version: 'v1'
  configName: string
  id?: number
  clientId: number
  enabled: boolean
  targetCurrency: string | null
  transformations: TransformationsReq[]
  targets: TargetsReq[]
  bucketId?: string
  deleted?: boolean
  updatedBy?: number
  createdBy?: number
  createdAt?: number
}
export interface EditEnhancedSignalsReq extends EnhancedSignalsReq {
  id: number
}

export interface LookupFileMetadataResp {
  bucketName: string
  contentType: string
  fileName: string
  fileSize: number
  lastModified: string
  timeCreated: string
}

export const getAllEnhancedSignalsByClient = async (clientId: string): Promise<EnhancedSignalsResp[]> => {
  return await client.get<EnhancedSignalsResp[]>({
    path: `${paths.enhancedSignals}?client_id=${clientId}`
  })
}

export const getEnhancedSignalById = async (clientId: string, configId: number): Promise<EnhancedSignalsResp> => {
  return await client.get<EnhancedSignalsResp>({
    path: `${paths.enhancedSignals}/${configId}?client_id=${clientId}`
  })
}

export const createEnhancedSignals = async (data: EnhancedSignalsReq): Promise<EnhancedSignalsResp> => {
  return await client.post<EnhancedSignalsResp>({
    path: paths.enhancedSignals,
    data
  })
}

export const updateEnhancedSignals = async (data: EditEnhancedSignalsReq): Promise<EnhancedSignalsResp> => {
  return await client.put<EnhancedSignalsResp>({
    path: `${paths.enhancedSignals}/${data.id}`,
    data
  })
}

export const deleteEnhancedSignals = async (data: ConfigIdEnhancedSignalsReq): Promise<EnhancedSignalsResp> => {
  return await client.del<EnhancedSignalsResp>({
    path: `${paths.enhancedSignals}/${data.configId}?client_id=${data.clientId}`
  })
}

export const createSignedUrl = async (data: CreateSignedUrlReq): Promise<string> => {
  return await client.post<string>({
    path: `${paths.enhancedSignals}/${data.configId}/signed_url`,
    data: {
      clientId: data.clientId,
      transferType: data.transferType,
      fileName: data.fileName
    }
  })
}

export interface UploadFileReq {
  signedURL: string
  file: File
}

export const uploadFile = async ({ file, signedURL }: UploadFileReq): Promise<string> => {
  return await transferClient.put<string>({
    path: signedURL,
    data: file
  })
}

export interface DownloadFileReq {
  signedURL: string
}

export const downloadFile = async ({ signedURL }: DownloadFileReq): Promise<string> => {
  return await transferClient.get<string>({
    path: signedURL
  })
}

export const getLookupFileMetadata = async (
  clientId: string,
  configId: number,
  fileName: string
): Promise<LookupFileMetadataResp> => {
  return await client.get<LookupFileMetadataResp>({
    path: `${paths.enhancedSignals}/${configId}/lookup_file_metadata?client_id=${clientId}&file_name=${fileName}`
  })
}
