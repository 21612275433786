import { styled } from '../../../utils'
import { Theme, CSSObject } from '../../theme'
import { Tabs } from '@mui/material'

export const StyledTabs = styled(Tabs)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    minHeight: 'unset',
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTab-root.Mui-selected': {
      color: 'initial',
      background: theme?.palette.neutrals.stone100,
      borderRadius: theme?.borderRadius.medium
    },
    '& .MuiTab-root:hover': {
      background: theme?.palette.neutrals.stone90,
      borderRadius: theme?.borderRadius.medium
    },
    '& .MuiTabs-flexContainer': {
      gap: '24px'
    }
  })
)
