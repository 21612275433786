import { styled } from '@precis-digital/kurama'
import Link from 'next/link'

export const UnstyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'inherit',
  ':hover': {
    textDecoration: 'none'
  }
}))
