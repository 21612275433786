import React, { type Dispatch, type SetStateAction } from 'react'
import { Button, Grid, Typography, Divider, styled } from '@precis-digital/kurama'
import { StyledFlyoutFooter } from './styles'
import { useTranslation } from 'shared/translations'
import WorkspaceSwitcherList from '../WorkspaceSwitcherList'
import { APP_ROUTES } from 'shared/routes'
import { UnstyledLink } from 'shared/components/UnstyledLink'
interface SwitchWorkspaceProps {
  handleFlyout: Dispatch<SetStateAction<boolean>>
}

const SwitchWorkspaceFlyout = ({ handleFlyout }: SwitchWorkspaceProps): React.ReactElement => {
  const { t } = useTranslation('workspace')

  return (
    <StyledSwitchFlyout>
      <Typography variant="h2">{t('titles.switchWorkspace')}</Typography>
      <WorkspaceSwitcherList
        onClientChange={() => {
          handleFlyout(false)
        }}
      />
      <Grid height="16px" />
      <StyledFlyoutFooter>
        <Divider />
        <Button href={APP_ROUTES.workspace.listPage} LinkComponent={UnstyledLink} variant="filled" fullWidth>
          {t('buttons.viewAllWorkspaces')}
        </Button>
      </StyledFlyoutFooter>
    </StyledSwitchFlyout>
  )
}

const StyledSwitchFlyout = styled(Grid)(() => ({
  paddingTop: '20px',
  paddingBottom: '48px',
  display: 'flex',
  flexDirection: 'column',
  form: {
    paddingTop: '20px',
    paddingBottom: '10px',
    '>div': {
      width: '100%'
    }
  }
}))

export default SwitchWorkspaceFlyout
