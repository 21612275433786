import { type ProgressOverTimeRecords } from 'home/components/ProgressOvertime'
import { type TimePeriodType } from 'home/components/StartPage'
import { formDataKey } from 'home/components/utils'
import { type ReportingBaseMetric } from 'home/reportTemplateParameters/reportingBase'
import { type CalculateTotalsFn, total, transformToTimeSeriesByMetricAndBreakdown } from 'home/transformations'
import { groupBy, map, sum } from 'ramda'
import {
  type ReportingBaseReportResp,
  type ReportingBaseTotalsGroupedByDateResp,
  type ReportingBaseTotalsResp
} from 'shared/api/analytics'
import { formatDateToString } from 'shared/dateFns'
import { type OriginalRow } from 'shared/reactTable/types'
import {
  calculateAverageOverValue,
  calculateClickThroughRate,
  calculateConversionRate,
  calculateCostOfSales,
  calculateCostPerAction,
  calculateCostPerClick,
  calculateCostPerThousandImpressions,
  calculateRoas,
  defaultToZero
} from 'shared/utils'

export interface ReportingBaseReport {
  createdAt: string
  channel: string
  spend: number
  conversionValue: number
  conversions: number
  costPerAction: number
  roas: number
  market: string
  clickThroughRate: number
  conversionRate: number
  costPerClick: number
  costOfSales: number
  costPerThousandImpressions: number
  averageOrderValue: number
  videoViews: number
  impressions: number
  clicks: number
  viewableImpressions: number
  sessions: number
}

export const preprocessReportsToConsistentSchema = (report: ReportingBaseReportResp): ReportingBaseReport => {
  const record = {
    conversionValue: defaultToZero(report.sumConversionValue),
    spend: defaultToZero(report.sumCost),
    conversions: defaultToZero(report.sumConversions),
    createdAt: report.date,
    channel: report.channelGrouping,
    market: report.marketName,
    videoViews: defaultToZero(report.sumVideoViews),
    impressions: defaultToZero(report.sumImpressions),
    clicks: defaultToZero(report.sumClicks),
    viewableImpressions: defaultToZero(report.sumViewableImpressions),
    sessions: defaultToZero(report.sumSessions)
  }
  return {
    ...record,
    costPerAction: defaultToZero(calculateCostPerAction(record.spend, record.conversions)),
    roas: defaultToZero(calculateRoas(record.conversionValue, record.spend)),
    clickThroughRate: defaultToZero(calculateClickThroughRate(record.clicks, record.impressions)),
    conversionRate: defaultToZero(calculateConversionRate(record.conversions, record.clicks)),
    costPerClick: defaultToZero(calculateCostPerClick(record.spend, record.clicks)),
    costOfSales: defaultToZero(calculateCostOfSales(record.spend, record.conversionValue)),
    costPerThousandImpressions: defaultToZero(calculateCostPerThousandImpressions(record.spend, record.impressions)),
    averageOrderValue: defaultToZero(calculateAverageOverValue(record.conversionValue, record.conversions))
  }
}

export const transformToReport = (records: ReportingBaseReportResp[]): ReportingBaseReport[] => {
  return records.map(preprocessReportsToConsistentSchema)
}

export type DetailedReport = Omit<ReportingBaseReport, 'createdAt'>

const transformToDetailedReport = (
  records: ReportingBaseReport[],
  groupByParameters: Array<'channel' | 'market'> = ['channel']
): DetailedReport[] => {
  const reports = groupBy((report) => {
    return groupByParameters.map((parameter) => report[parameter]).join('')
  }, records)

  return Object.keys(reports).map((parameterValue) => {
    const reportsByParameter = reports[parameterValue]

    const sumRecordsByParameter = total(reportsByParameter)

    const conversionValue = sumRecordsByParameter('conversionValue')
    const spend = sumRecordsByParameter('spend')
    const conversions = sumRecordsByParameter('conversions')
    const impressions = sumRecordsByParameter('impressions')
    const clicks = sumRecordsByParameter('clicks')
    const viewableImpressions = sumRecordsByParameter('viewableImpressions')
    const sessions = sumRecordsByParameter('sessions')
    const videoViews = sumRecordsByParameter('videoViews')
    const clickThroughRate = calculateClickThroughRate(clicks, impressions)
    const conversionRate = calculateConversionRate(conversions, clicks)
    const costPerClick = calculateCostPerClick(spend, clicks)
    const costOfSales = calculateCostOfSales(spend, conversionValue)
    const costPerThousandImpressions = calculateCostPerThousandImpressions(spend, impressions)
    const averageOrderValue = calculateAverageOverValue(conversionValue, conversions)

    const costPerAction = calculateCostPerAction(spend, conversions)
    const roas = calculateRoas(conversionValue, spend)

    return {
      spend,
      conversionValue,
      conversions,
      costPerAction,
      roas,
      channel: reportsByParameter[0].channel,
      market: reportsByParameter[0].market,
      viewableImpressions,
      impressions,
      clicks,
      sessions,
      videoViews,
      clickThroughRate,
      conversionRate,
      costPerClick,
      costOfSales,
      costPerThousandImpressions,
      averageOrderValue
    }
  })
}

export interface ReportingBaseSummaryData {
  roas: number
  conversions: number
  conversionValue: number
  costPerAction: number
  spend: number
  clickThroughRate: number
  conversionRate: number
  costPerClick: number
  costOfSales: number
  costPerThousandImpressions: number
  averageOrderValue: number
  videoViews: number
  impressions: number
  clicks: number
  viewableImpressions: number
  sessions: number
}

export const defaultSummaryData: ReportingBaseSummaryData = {
  roas: 0,
  conversions: 0,
  conversionValue: 0,
  costPerAction: 0,
  spend: 0,
  clickThroughRate: 0,
  conversionRate: 0,
  costPerClick: 0,
  costOfSales: 0,
  costPerThousandImpressions: 0,
  averageOrderValue: 0,
  videoViews: 0,
  impressions: 0,
  clicks: 0,
  viewableImpressions: 0,
  sessions: 0
}

export type TransformToReportingBaseSummaryFn = (
  totalsData: ReportingBaseTotalsResp | undefined
) => ReportingBaseSummaryData

export const transformToSummary: TransformToReportingBaseSummaryFn = (totalsData) => {
  if (totalsData == null) {
    return defaultSummaryData
  }

  const conversionValue = defaultToZero(totalsData.sumConversionValue)
  const spend = defaultToZero(totalsData.sumCost)
  const conversions = defaultToZero(totalsData.sumConversions)
  const impressions = defaultToZero(totalsData.sumImpressions)
  const clicks = defaultToZero(totalsData.sumClicks)
  const viewableImpressions = defaultToZero(totalsData.sumViewableImpressions)
  const sessions = defaultToZero(totalsData.sumSessions)
  const videoViews = defaultToZero(totalsData.sumVideoViews)

  return {
    conversionValue,
    spend,
    conversions,
    sessions,
    videoViews,
    impressions,
    clicks,
    viewableImpressions,
    costPerAction: calculateCostPerAction(spend, conversions),
    roas: calculateRoas(conversionValue, spend),
    clickThroughRate: calculateClickThroughRate(clicks, impressions),
    conversionRate: calculateConversionRate(conversions, clicks),
    costPerClick: calculateCostPerClick(spend, clicks),
    costOfSales: calculateCostOfSales(spend, conversionValue),
    costPerThousandImpressions: calculateCostPerThousandImpressions(spend, impressions),
    averageOrderValue: calculateAverageOverValue(conversionValue, conversions)
  }
}

export type TransformToReportingBaseTimeSeriesFn = (
  records: ReportingBaseTotalsGroupedByDateResp[],
  metrics: ReportingBaseMetric[]
) => Record<string, ProgressOverTimeRecords>

export const transformToTimeSeries: TransformToReportingBaseTimeSeriesFn = (
  records: ReportingBaseTotalsGroupedByDateResp[],
  metrics: ReportingBaseMetric[]
) => {
  return metrics.reduce<Record<string, ProgressOverTimeRecords>>((acc, metric) => {
    const totalOnReportTypeByDate: Record<string, number> = {}
    records.forEach(({ date, ...record }) => {
      totalOnReportTypeByDate[date] = transformToSummary(record)[metric]
    })

    acc[metric] = map(
      (date) => ({
        x: formatDateToString({ date, targetFormat: 'MMM do' }),
        [metric]: totalOnReportTypeByDate[date]
      }),
      Object.keys(totalOnReportTypeByDate)
    ) as ProgressOverTimeRecords
    return acc
  }, {})
}

export type TransformToDetailedReportingBaseDataFn = ({
  reports,
  reportsForPreviousTimePeriod,
  selectedDimensions
}: {
  reports: ReportingBaseReport[]
  reportsForPreviousTimePeriod: ReportingBaseReport[]
  selectedDimensions: Array<'market' | 'channel'>
}) => OriginalRow[]

export const transformToDetailedReportData: TransformToDetailedReportingBaseDataFn = ({
  reports,
  reportsForPreviousTimePeriod,
  selectedDimensions
}) => {
  const detailedReports = transformToDetailedReport(reports, selectedDimensions)
  const detailedReportsForPreviousTimePeriod = transformToDetailedReport(
    reportsForPreviousTimePeriod,
    selectedDimensions
  )

  return detailedReports.map((report) => {
    const previousReport = detailedReportsForPreviousTimePeriod.find((previous) => {
      if (selectedDimensions.length === 1) {
        return report[selectedDimensions[0]] === previous[selectedDimensions[0]]
      }
      return report.channel === previous.channel && report.market === previous.market
    })
    return {
      channel: report.channel,
      market: report.market,
      spend: report.spend,
      conversionValue: report.conversionValue,
      roas: report.roas,
      conversions: report.conversions,
      costPerAction: report.costPerAction,
      previousSpend: defaultToZero(previousReport?.spend),
      previousConversionValue: defaultToZero(previousReport?.conversionValue),
      previousRoas: defaultToZero(previousReport?.roas),
      previousConversions: defaultToZero(previousReport?.conversions),
      previousCostPerAction: defaultToZero(previousReport?.costPerAction),
      viewableImpressions: report.viewableImpressions,
      impressions: report.impressions,
      videoViews: report.videoViews,
      sessions: report.sessions,
      costPerClick: report.costPerClick,
      clickThroughRate: report.clickThroughRate,
      conversionRate: report.conversionRate,
      costOfSales: report.costOfSales,
      costPerThousandImpressions: report.costPerThousandImpressions,
      averageOrderValue: report.averageOrderValue,
      clicks: report.clicks,
      previousClicks: defaultToZero(previousReport?.clicks),
      previousViewableImpressions: defaultToZero(previousReport?.viewableImpressions),
      previousImpressions: defaultToZero(previousReport?.impressions),
      previousVideoViews: defaultToZero(previousReport?.videoViews),
      previousSessions: defaultToZero(previousReport?.sessions),
      previousCostPerClick: defaultToZero(previousReport?.costPerClick),
      previousClickThroughRate: defaultToZero(previousReport?.clickThroughRate),
      previousConversionRate: defaultToZero(previousReport?.conversionRate),
      previousCostOfSales: defaultToZero(previousReport?.costOfSales),
      previousCostPerThousandImpressions: defaultToZero(previousReport?.costPerThousandImpressions),
      previousAverageOrderValue: defaultToZero(previousReport?.averageOrderValue)
    }
  })
}

const calculateTotals: CalculateTotalsFn<ReportingBaseReport, ReportingBaseMetric> = ({
  records,
  metrics,
  market,
  channel
}) => {
  return metrics.reduce<Record<string, number>>((acc, metric) => {
    const filteredRecords = records.filter((record) => {
      const hasMarketOrChannel = market != null || channel != null

      return !hasMarketOrChannel || record.market === market || record.channel === channel
    })

    let totalOnDate = 0

    switch (metric) {
      case 'averageOrderValue':
        totalOnDate = calculateAverageOverValue(
          sum(filteredRecords.map((record) => record.conversionValue)),
          sum(filteredRecords.map((record) => record.conversions))
        )
        break
      case 'costPerAction':
        totalOnDate = calculateCostPerAction(
          sum(filteredRecords.map((record) => record.spend)),
          sum(filteredRecords.map((record) => record.conversions))
        )
        break
      case 'roas':
        totalOnDate = calculateRoas(
          sum(filteredRecords.map((record) => record.conversionValue)),
          sum(filteredRecords.map((record) => record.spend))
        )
        break
      case 'clickThroughRate':
        totalOnDate = calculateClickThroughRate(
          sum(filteredRecords.map((record) => record.clicks)),
          sum(filteredRecords.map((record) => record.impressions))
        )
        break
      case 'conversionRate':
        totalOnDate = calculateConversionRate(
          sum(filteredRecords.map((record) => record.conversions)),
          sum(filteredRecords.map((record) => record.clicks))
        )
        break
      case 'costPerClick':
        totalOnDate = calculateCostPerClick(
          sum(filteredRecords.map((record) => record.spend)),
          sum(filteredRecords.map((record) => record.clicks))
        )
        break
      case 'costOfSales':
        totalOnDate = calculateCostOfSales(
          sum(filteredRecords.map((record) => record.spend)),
          sum(filteredRecords.map((record) => record.conversionValue))
        )
        break
      case 'costPerThousandImpressions':
        totalOnDate = calculateCostPerThousandImpressions(
          sum(filteredRecords.map((record) => record.spend)),
          sum(filteredRecords.map((record) => record.impressions))
        )
        break
      default:
        totalOnDate = sum(filteredRecords.map((record) => record[metric]))
    }
    acc[formDataKey({ metric, breakdown: market ?? channel })] = totalOnDate
    return acc
  }, {})
}

export type TransformToReportingBaseTimeSeriesByMetricAndBreakdownFn = ({
  records,
  previousRecords,
  metrics,
  channels,
  markets,
  daysRange,
  isComparisonMode,
  selectedPeriodType
}: {
  records: ReportingBaseReport[]
  previousRecords: ReportingBaseReport[]
  metrics: ReportingBaseMetric[]
  channels: string[]
  markets: string[]
  daysRange: number
  isComparisonMode: boolean
  selectedPeriodType: TimePeriodType
}) => ProgressOverTimeRecords

export const transformToReportingBaseTimeSeriesByMetricAndBreakdown: TransformToReportingBaseTimeSeriesByMetricAndBreakdownFn =
  ({ records, previousRecords, metrics, channels, markets, daysRange, isComparisonMode, selectedPeriodType }) => {
    return transformToTimeSeriesByMetricAndBreakdown({
      records,
      previousRecords,
      metrics,
      channels,
      markets,
      daysRange,
      isComparisonMode,
      selectedPeriodType,
      calculateTotals
    })
  }
