import { ListItemButton, ListItemIcon } from '@mui/material'
import { StyledListItemLogoContainer } from './styles'
import { NavHeaderProps } from './types'
import Logo from '../Logo'

const NavHeader = ({ Link, open }: NavHeaderProps): React.ReactElement => {
  return (
    <Link>
      <StyledListItemLogoContainer open={open}>
        <ListItemButton>
          <ListItemIcon>
            <Logo size="large" />
          </ListItemIcon>
        </ListItemButton>
      </StyledListItemLogoContainer>
    </Link>
  )
}
export default NavHeader
