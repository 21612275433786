import {
  Button,
  Grid,
  MaximizeIcon,
  MinimizeIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  Typography,
  styled
} from '@precis-digital/kurama'
import { type DataSourceItem } from 'channelGrouping/types'
import React, { type ReactElement, useState, useMemo } from 'react'
import { useTranslation } from 'shared/translations'
import DataSourcesTableCard from './DataSourcesTableCard'
import { type Platforms } from 'shared/api/accounts'

interface DataSourcesTableProps {
  dataSources: DataSourceItem[]
}

const DataSourcesTable = ({ dataSources }: DataSourcesTableProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const [expandedDataSources, setExpandedDataSources] = useState<Platforms[]>([])
  const handleExpandButton = (): void => {
    if (expandedDataSources.length > 0) {
      setExpandedDataSources([])
    } else {
      setExpandedDataSources(dataSources.map((dataSource) => dataSource.platform))
    }
  }

  const handleCardExpandClick = (dataSourceToExpand: Platforms, expanded: boolean): void => {
    if (!expanded) {
      setExpandedDataSources(
        expandedDataSources.filter((expandedDataSource) => expandedDataSource !== dataSourceToExpand)
      )
    } else {
      setExpandedDataSources([...expandedDataSources, dataSourceToExpand])
    }
  }

  const [sortColumn, setSortColumn] = useState<'dataSourceName' | 'channels'>('dataSourceName')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const handleSortClick = (newSortColumn: 'dataSourceName' | 'channels'): void => {
    if (sortDirection === 'asc') {
      setSortDirection('desc')
    } else {
      setSortDirection('asc')
    }
    setSortColumn(newSortColumn)
  }

  const sortedDataSources = useMemo(() => {
    return dataSources.sort((a, b) => {
      if (sortColumn === 'dataSourceName') {
        return sortDirection === 'asc' ? b.label.localeCompare(a.label) : a.label.localeCompare(b.label)
      } else {
        return sortDirection === 'asc' ? a.channels.length - b.channels.length : b.channels.length - a.channels.length
      }
    })
  }, [dataSources, sortColumn, sortDirection])

  if (dataSources.length === 0) {
    return <></>
  }

  return (
    <Grid container width="100%" gap="16px">
      <Grid display="flex" justifyContent="space-between" width="100%" alignItems="center" gap="24px">
        <Grid width="50%">
          <StyledGridSortableHeaderContainer
            width="fit-content"
            display="flex"
            alignItems="center"
            gap="2px"
            onClick={() => {
              handleSortClick('dataSourceName')
            }}
          >
            <Typography variant="h5">{t('mainFormView.dataSourcesTab.tableHeader.dataSource')}</Typography>
            <Grid width="16px" height="24px">
              {sortColumn === 'dataSourceName' && (
                <>
                  {sortDirection === 'asc' && <SortAscendingIcon />}
                  {sortDirection === 'desc' && <SortDescendingIcon />}
                </>
              )}
            </Grid>
          </StyledGridSortableHeaderContainer>
        </Grid>
        <Grid width="50%" display="flex" alignItems="center" gap="24px" justifyContent="space-between">
          <Grid display="flex" width="30%" justifyContent="center">
            <StyledGridSortableHeaderContainer
              width="fit-content"
              display="flex"
              alignItems="center"
              gap="2px"
              onClick={() => {
                handleSortClick('channels')
              }}
              marginLeft="16px"
            >
              <Typography variant="h5">{t('mainFormView.dataSourcesTab.tableHeader.channels')}</Typography>
              <Grid width="16px" height="24px">
                {sortColumn === 'channels' && (
                  <>
                    {sortDirection === 'asc' && <SortAscendingIcon />}
                    {sortDirection === 'desc' && <SortDescendingIcon />}
                  </>
                )}
              </Grid>
            </StyledGridSortableHeaderContainer>
          </Grid>
          <Grid display="flex" alignItems="center" gap="24px">
            <Typography variant="h5">{t('mainFormView.dataSourcesTab.tableHeader.data')}</Typography>
            <Button
              variant="text"
              onClick={handleExpandButton}
              leftIcon={expandedDataSources.length > 0 ? <MinimizeIcon /> : <MaximizeIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container flexDirection="column" gap="16px">
        {sortedDataSources.map((dataSource) => (
          <DataSourcesTableCard
            key={dataSource.platform}
            dataSource={dataSource}
            isExpanded={expandedDataSources.includes(dataSource.platform)}
            handleExpandClick={(expanded) => {
              handleCardExpandClick(dataSource.platform, expanded)
            }}
          />
        ))}
      </Grid>
    </Grid>
  )
}

const StyledGridSortableHeaderContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  svg: {
    color: theme.palette.primary.main
  }
}))

export default DataSourcesTable
