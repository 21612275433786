import { ButtonProps } from './types'
import {
  ButtonIconContainer,
  ButtonInnerContainer,
  ButtonChildrenContainer,
  StyledButton,
  ButtonTooltipContainer
} from './styles'
import { forwardRef } from 'react'
import Tooltip from '../Tooltip'
import { SingleLineTooltipProps } from '../Tooltip/types'

const defaultTooltipProps: Omit<SingleLineTooltipProps, 'children'> = {
  kind: 'singleline',
  placement: 'bottom',
  title: undefined,
  body: undefined
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      children,
      onClick,
      disabled = false,
      leftIcon,
      rightIcon,
      scheme = 'light',
      fullWidth,
      LinkComponent,
      href,
      tooltipProps,
      ...props
    },
    ref
  ): React.ReactElement => {
    const hasLeftIcon: boolean = leftIcon !== undefined
    const hasRightIcon: boolean = rightIcon !== undefined
    const hasChildren: boolean = children !== '' && children != null

    const tooltipPropsWithDefault = { ...defaultTooltipProps, ...tooltipProps }

    const BaseButton = (
      <StyledButton
        buttonVariant={variant}
        disabled={disabled}
        onClick={onClick}
        hasLeftIcon={hasLeftIcon}
        hasRightIcon={hasRightIcon}
        hasChildren={hasChildren}
        scheme={scheme}
        fullWidth={fullWidth}
        LinkComponent={LinkComponent}
        href={href}
        {...props}
        ref={ref}
      >
        <ButtonInnerContainer>
          {hasLeftIcon && <ButtonIconContainer>{leftIcon}</ButtonIconContainer>}
          {hasChildren && <ButtonChildrenContainer variant="h5">{children}</ButtonChildrenContainer>}
          {hasRightIcon && <ButtonIconContainer>{rightIcon}</ButtonIconContainer>}
        </ButtonInnerContainer>
      </StyledButton>
    )
    if (tooltipProps == null) {
      return BaseButton
    } else {
      return (
        <Tooltip {...tooltipPropsWithDefault}>
          <ButtonTooltipContainer fullWidth={fullWidth}>{BaseButton}</ButtonTooltipContainer>
        </Tooltip>
      )
    }
  }
)

export default Button
