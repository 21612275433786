import { Typography, type TypographyVariant, styled, type Theme } from '@precis-digital/kurama'
import { type PercentageStatus, getPercentageDisplayValueAndStatus } from 'shared/utils'
import { getTextColorByDifferenceIndicatorAndColorScheme } from './PercentageChip'

interface PercentageTypographyProps {
  presentValue: number
  previousValue: number
  variant?: TypographyVariant
  reverseColorScheme?: boolean
}

const PercentageTypography = ({
  presentValue,
  previousValue,
  reverseColorScheme = false,
  variant = 'body3'
}: PercentageTypographyProps): React.ReactElement => {
  const { displayValue, percentageStatus } = getPercentageDisplayValueAndStatus(presentValue, previousValue)

  return (
    <StyledTypography variant={variant} differenceIndicator={percentageStatus} reverseColorScheme={reverseColorScheme}>
      {displayValue}
    </StyledTypography>
  )
}

export default PercentageTypography

const StyledTypography = styled(Typography)<{
  differenceIndicator: PercentageStatus
  reverseColorScheme: boolean
  theme?: Theme
}>(({ theme, differenceIndicator, reverseColorScheme }) => {
  const color = getTextColorByDifferenceIndicatorAndColorScheme({
    theme,
    differenceIndicator,
    reverseColorScheme
  })

  return {
    color
  }
})
