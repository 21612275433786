import { Button, Grid, Link, styled, Typography } from '@precis-digital/kurama'
import spaceManImage from 'public/assets/images/space-man.svg'
import React from 'react'
import type { ReactElement } from 'react'
import Image from 'next/image'

interface IEmptyStateProps {
  documentationLink: string
  t: (key: string, options?: Record<string, unknown> | undefined) => string
  characterSource?: string
}

const EducationBlock = (props: IEmptyStateProps): ReactElement => {
  return (
    <StyledGridEducationalBlockContainer>
      <StyledGridContentContainer>
        <Typography variant="h2" color="white">
          {props.t('emptyState.educationalBlock.title')}
        </Typography>
        <StyledTwoLineMaxTypography variant="body1" color="white">
          {props.t('emptyState.educationalBlock.description')}
        </StyledTwoLineMaxTypography>
        <Grid>
          <Link target="_blank" rel="noreferrer" href={props.documentationLink}>
            <Button variant="filled" scheme="dark" onClick={() => {}}>
              {props.t('emptyState.educationalBlock.linkText')}
            </Button>
          </Link>
        </Grid>
      </StyledGridContentContainer>
      <StyledGridImageContainer>
        <Image src={props.characterSource ?? spaceManImage.src} alt="character" fill sizes="100vw" />
      </StyledGridImageContainer>
    </StyledGridEducationalBlockContainer>
  )
}

const StyledGridContentContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '42px 60px 42px 24px',
  maxWidth: '644px',
  gap: '15px'
}))

const StyledGridEducationalBlockContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  background: `linear-gradient(90deg, ${(theme?.palette.main.blue50 as string) ?? ''} 0%, ${
    (theme?.palette.main.blue100 as string) ?? ''
  } 100%)`,
  width: '100%',
  padding: '0px 0px 0px 20px',
  gap: '70px',
  borderRadius: '32px',
  display: 'flex',
  justifyContent: 'space-between'
}))

const StyledTwoLineMaxTypography = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordWrap: 'break-word',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  display: '-webkit-box'
}))

const StyledGridImageContainer = styled('div')(() => ({
  position: 'relative',
  width: '400px'
}))

export default EducationBlock
