import type { AttributionResponse } from 'shared/api/analytics'
import type {
  AttributionModelRevenueField,
  AttributionModelConversionsField
} from './components/Dashboard/DetailedReportUtils'
import { defaultToZero } from '../shared/utils'
import type { AttributionModelDetailedReport } from './components/Dashboard/DetailedReport'
import { formatDateToString } from 'shared/dateFns'
import type {
  BarChartChannelRecord,
  BarChartChannelRecords
} from 'attributionModel/components/Dashboard/BarChartComparison'
import { type SelectMetricsType } from 'attributionModel/components/Dashboard'
import { type AttributionModelPerformanceSummary } from 'attributionModel/components/Dashboard/Summary'
import { type ActiveFilter } from 'shared/components/Filter'

export interface AttributionBaseReport {
  revenue: number
  conversions: number
  impressions: number
  spend: number
  date: string
  channel: string
}

export const preprocessAttributionData = (
  data: AttributionResponse[],
  revenueField: AttributionModelRevenueField,
  conversionsField: AttributionModelConversionsField
): AttributionBaseReport[] => {
  return data.map((item: AttributionResponse) => {
    return {
      revenue: defaultToZero(item[revenueField]),
      conversions: defaultToZero(item[conversionsField]),
      spend: defaultToZero(item.spend),
      impressions: defaultToZero(item.impressions),
      date: item.date,
      channel: item.channel
    }
  })
}

const operatorStringFilterFunctionMap = {
  is: (dataParameter: string, value: string) => dataParameter === value,
  not_is: (dataParameter: string, value: string) => dataParameter !== value
}

const operatorArrayFilterFunctionMap = {
  one_of: (dataParameter: string, value: string[]) => value.includes(dataParameter),
  not_one_of: (dataParameter: string, value: string[]) => !value.includes(dataParameter)
}

export const filterAttributionDataByChannel = (
  data: AttributionResponse[],
  filters: Array<ActiveFilter<'channel'>>
): AttributionResponse[] => {
  return data.filter((dataItem) => {
    return filters.every((filter) => {
      let value: string | string[]

      if (Array.isArray(filter.data)) {
        value = filter.data.map((data) => data.value)
        return operatorArrayFilterFunctionMap[filter.operator as keyof typeof operatorArrayFilterFunctionMap](
          dataItem[filter.parameter.value],
          value
        )
      }
      value = typeof filter.data === 'string' ? filter.data : filter.data.value
      return operatorStringFilterFunctionMap[filter.operator as keyof typeof operatorStringFilterFunctionMap](
        dataItem[filter.parameter.value],
        value
      )
    })
  })
}

export const transformAttributionDataToPerformanceSummary = (
  data: AttributionBaseReport[]
): AttributionModelPerformanceSummary => {
  // refactor to do one pass over the data....
  const { revenue, conversions, spend } = data.reduce(
    (acc, curr) => {
      return {
        spend: acc.spend + curr.spend,
        conversions: acc.conversions + curr.conversions,
        revenue: acc.revenue + curr.revenue
      }
    },
    { revenue: 0, conversions: 0, spend: 0 }
  )

  return {
    adSpend: spend,
    conversions,
    revenue,
    roas: defaultToZero(spend) === 0 ? undefined : revenue / spend,
    costPerAction: defaultToZero(conversions) === 0 ? undefined : spend / conversions
  }
}

export const transformAttributionDataToDetailedReport = (
  data: AttributionBaseReport[]
): AttributionModelDetailedReport[] => {
  // first aggregate revenue conversions impressions and spend by channel,
  // creating a object where the key is the channel and the value is the aggregated data
  const dataAggregatedByChannel: Record<string, AttributionModelDetailedReport> = {}
  data.forEach((record: AttributionBaseReport) => {
    if (dataAggregatedByChannel[record.channel] == null) {
      dataAggregatedByChannel[record.channel] = {
        revenue: 0,
        conversions: 0,
        impressions: 0,
        adSpend: 0,
        channel: record.channel,
        roas: null,
        costPerAction: null
      }
    }
    dataAggregatedByChannel[record.channel].revenue += record.revenue
    dataAggregatedByChannel[record.channel].conversions += record.conversions
    dataAggregatedByChannel[record.channel].impressions += record.impressions
    dataAggregatedByChannel[record.channel].adSpend += record.spend
  })

  // go through each channel and add the fields roas, channel and costPerAction
  return Object.keys(dataAggregatedByChannel).map((channel) => {
    const record = dataAggregatedByChannel[channel]
    return {
      ...record,
      roas: defaultToZero(record.adSpend) === 0 ? null : record.revenue / record.adSpend,
      costPerAction: defaultToZero(record.conversions) === 0 ? null : record.adSpend / record.conversions
    }
  })
}

interface AggRecordType {
  date: string
  revenue?: number
  conversions?: number
}

export interface LineData {
  [key: string]: number | string
  x: string
}

export const transformAttributionToTimeSeriesData = (
  data: AttributionBaseReport[],
  metric: SelectMetricsType,
  channels: string[]
): LineData[] => {
  const attributionDataSchemaMetric: 'revenue' | 'conversions' = metric === 'conversions' ? 'conversions' : metric

  const lineDataByChannel: Record<string, LineData[]> = {}

  channels.forEach((channel) => {
    // filter by selected channel and extract the metric and date
    const tsRecords: AggRecordType[] = data
      .filter((record) => record.channel === channel)
      .map((record) => ({
        [metric]: record[attributionDataSchemaMetric],
        date: record.date
      }))

    // aggregate by date and channel
    const aggregatedRecords: Record<string, AggRecordType> = {}
    tsRecords.forEach((record) => {
      const aggRecord = aggregatedRecords[record.date]
      if (aggRecord != null) {
        aggregatedRecords[record.date] = {
          [metric]: defaultToZero(aggRecord[metric]) + defaultToZero(record[metric]),
          date: record.date
        }
      } else {
        aggregatedRecords[record.date] = record
      }
    })

    // sort by date and format the date
    const sortedAggregateRecords: LineData[] = Object.values(aggregatedRecords)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map((record) => ({
        [metric]: record[metric] as number,
        dateFull: formatDateToString({ date: record.date }),
        x: formatDateToString({ date: record.date, targetFormat: 'MMM do' })
      }))

    lineDataByChannel[channel] = sortedAggregateRecords
  })

  // join line data by x
  const lineData: LineData[] = []
  const xValues: string[] = []
  channels.forEach((channel) => {
    lineDataByChannel[channel].forEach((record) => {
      if (!xValues.includes(record.x)) {
        xValues.push(record.x)
        lineData.push({ x: record.x, dateFull: record.dateFull, [channel]: record[metric] })
      } else {
        const existingRecord = lineData.find((r) => r.x === record.x)
        if (existingRecord != null) {
          existingRecord[channel] = record[metric]
        }
      }
    })
  })

  return lineData
}

export const transformAttributionDataToBarChart = (
  data: AttributionBaseReport[],
  metric: SelectMetricsType
): BarChartChannelRecords => {
  // aggregate revenue and conversions by channel
  const dataAggregatedByChannel: Record<string, { revenue: number; conversions: number }> = {}

  data.forEach((record: AttributionBaseReport) => {
    if (dataAggregatedByChannel[record.channel] == null) {
      dataAggregatedByChannel[record.channel] = {
        revenue: 0,
        conversions: 0
      }
    }
    dataAggregatedByChannel[record.channel].revenue += Number(record.revenue)
    dataAggregatedByChannel[record.channel].conversions += Number(record.conversions)
  })

  return Object.entries(dataAggregatedByChannel)
    .map(
      ([channel, { revenue, conversions }]): BarChartChannelRecord => ({
        x: channel,
        ...(metric === 'revenue' ? { revenue: Number(revenue) } : { conversions: Number(conversions) })
      })
    )
    .sort((a, b) => Number(b[metric]) - Number(a[metric]))
}
