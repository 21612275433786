import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import config from 'shared/config'
import { rollbarConfig } from 'shared/errorReporter'
import { useAuth } from './AuthContext'

const ENV = config('APP_ENV')

const ErrorProvider = ({
  children,
  FallbackUI
}: {
  children: React.ReactNode
  FallbackUI: React.ComponentType<{ error: Error | null; resetError: () => void }>
}): React.ReactElement => {
  const { currentUser } = useAuth()

  const config = {
    ...rollbarConfig,
    ...(currentUser?.id != null ? { payload: { ...rollbarConfig.payload, person: { id: currentUser.id } } } : {})
  }

  return ENV === 'production' || ENV === 'staging' ? (
    <RollbarProvider config={config}>
      <ErrorBoundary fallbackUI={FallbackUI as React.ComponentType<{ error: Error | null; resetError: () => void }>}>
        {children}
      </ErrorBoundary>
    </RollbarProvider>
  ) : (
    <>{children}</>
  )
}
export default ErrorProvider
