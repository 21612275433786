import React, { type ReactElement } from 'react'
import { Avatar, Grid, Typography, styled } from '@precis-digital/kurama'
import { type ListViewCellProps } from '../../types'
import BaseCell from './BaseCell'

interface TitleWithIconCellProps<T extends object> extends ListViewCellProps<T> {
  title: string
  platformMeta?: { iconUrl: string }
  subTitle?: string
}

const TitleWithIconCell = <T extends object>({
  title,
  subTitle,
  platformMeta,
  ...props
}: TitleWithIconCellProps<T>): ReactElement => {
  return (
    <BaseCell width={props.column.parsedWidth} align="left">
      <Grid container alignItems="center" wrap="nowrap">
        <Avatar kind="image" imageUrl={platformMeta?.iconUrl ?? ''} />
        <GridItemTextContainer>
          <StyledTypographyTitle variant="h4">{title}</StyledTypographyTitle>
          {subTitle !== null && <StyledTypographySubtitle variant="body3">{subTitle}</StyledTypographySubtitle>}
        </GridItemTextContainer>
      </Grid>
    </BaseCell>
  )
}

const GridItemTextContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(1), // Adjust spacing as needed
  overflow: 'hidden'
}))

const StyledTypographyTitle = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word'
}))

const StyledTypographySubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word'
}))

export default TitleWithIconCell
