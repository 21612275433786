import { styled } from '../../utils'
import Grid from '../Grid'
import { Breadcrumbs, Divider, List } from '@mui/material'
import { Theme, CSSObject } from '../theme'
import { Children, ReactNode } from 'react'

const StyledTopNavigation = styled(Grid)`
  padding: 22px 56px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  h5 {
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button {
    align-self: center;
  }
` as React.ComponentType<any>

const StyledDivider = styled(Divider)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.neutrals.stone100};
  height: 24px;
  margin: 0 36px 0 20px;
` as React.ComponentType<any>

const StyledNavActionSection = styled(Grid)`
  display: inline-flex;
  gap: 16px;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  align-items: center;
  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    background: ${({ theme }: { theme: Theme }) => theme.palette.neutrals.stone100};
    border-radius: ${({ theme }: { theme: Theme }) => theme.borderRadius.medium};
    :hover {
      background-color: ${({ theme }: { theme: Theme }) => theme.palette.neutrals.stone90};
    }
    :active {
      background-color: ${({ theme }: { theme: Theme }) => theme.palette.neutrals.stone100};
    }
  }
` as React.ComponentType<any>

const StyledClientSection = styled(List)`
  padding: 0;
  .MuiListItemButton-root {
    padding: 0 10px;
    .MuiListItem-root {
      align-items: flex-start;
      flex-direction: row-reverse;
      align-items: center;
      text-align: right;
      padding: 0px;
      .MuiListItemAvatar-root {
        min-width: 0;
      }
      .MuiListItemText-root {
        margin-right: 16px;
        .MuiListItemText-primary {
          display: block;
        }
        .MuiListItemText-primary {
          color: ${({ theme }: any) => theme.palette.secondary.main};
        }
        .MuiListItemText-secondary {
          display: block;
          margin-top: -3px;
          color: ${({ theme }: any) => theme.palette.neutrals.stone120};
        }
      }
    }
  }
` as React.ComponentType<any>

export { StyledDivider, StyledTopNavigation, StyledClientSection, StyledNavActionSection }

export const StyledBreadcrumbs = styled(Breadcrumbs)(
  ({ theme, children }: { theme?: Theme; children?: ReactNode }): CSSObject => {
    const breadcrumbCount = Children.count(children) ?? 0

    // 41px is the width of the separators, so need to subtract that from the total width before dividing
    // 24px is the gap between the left side of the nav and the right side, which we also need to subtract
    const breadcrumbItemMaxWidth = `calc((100% - 24px - (41px * ${breadcrumbCount - 1})) / ${breadcrumbCount})`
    return {
      display: 'inline-block',
      width: 'calc(100% - 88px)',
      '.MuiBreadcrumbs-separator': {
        color: theme?.palette.secondary.main
      },
      ol: {
        display: 'flex',
        flexWrap: 'nowrap',
        height: '100%'
      },
      a: {
        color: theme?.palette.secondary.main,
        textDecoration: 'none',
        fontFamily: theme?.typography.h5.fontFamily,
        fontSize: theme?.typography.body2.fontSize,
        ':hover': {
          borderBottom: `1px solid ${theme?.palette.secondary.main ?? ''}`
        }
      },
      '.MuiBreadcrumbs-li': {
        maxWidth: breadcrumbItemMaxWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },
      h5: { color: theme?.palette.neutrals.stone120, marginTop: '1px', maxWidth: 'unset' }
    }
  }
)

export const StyledGridNavButtonsContainer = styled(Grid)(() => ({
  display: 'inline-block',
  'button:first-of-type': {
    marginRight: '8px'
  },
  flexWrap: 'nowrap'
}))
