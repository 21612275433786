import { Button, Divider, Grid, Input, PopUp, SingleSelectDropdown, styled, Typography } from '@precis-digital/kurama'
import React, { type ChangeEvent, type ReactElement, useState } from 'react'
import keyboardImage from 'public/assets/images/keyboard.png'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import { useMutationCreateChannelGrouping } from 'channelGrouping/api'
import { useChangePath } from 'shared/components/Router'
import Dots from 'shared/components/Loader/Dots'
import { useCurrentClient } from 'shared/context/ClientContext'
import { DEFAULT_MAIN_FORM_VIEW_TAB } from 'channelGrouping/constants'
import { STARTING_TEMPLATES } from 'channelGrouping/constants/startingTemplates'
import { APP_ROUTES } from 'shared/routes'
import { trimWhitespace } from 'shared/utils'
const NO_TEMPLATE_ID = 'start_from_scratch'

const CreateNewChannelGroupingPopup = (): ReactElement => {
  const { t } = useTranslation('channelGrouping', { keyPrefix: 'popups.createNewGrouping' })
  const [selectedTemplate, setSelectedTemplate] = useState<string>(NO_TEMPLATE_ID)
  const [nameValue, setNameValue] = useState<string>('')
  const [nameValueError, setNameValueError] = useState<string | undefined>(undefined)
  const { changePath } = useChangePath()
  const { currentClient } = useCurrentClient()

  const createNewChannelGroupingPopupOverscreen = useOverScreen('createNewChannelGroupingPopup')

  const { mutate: createChannelGrouping, isLoading: isLoadingCreatingChannelGrouping } =
    useMutationCreateChannelGrouping()

  const submitNewChannelGrouping = (): void => {
    if (nameValue.length === 0) {
      setNameValueError(t('emptyNameError')?.toString())
      return
    }

    createChannelGrouping(
      {
        name: trimWhitespace(nameValue),
        clientId: currentClient.id,
        channelGrouping: STARTING_TEMPLATES.find((template) => template.id === selectedTemplate)?.channelGrouping
      },
      {
        onSuccess: async (newChannelGrouping) => {
          void changePath(
            `${APP_ROUTES.customGroupings.editConfigPage({
              channelGroupingId: newChannelGrouping.id.toString()
            })}/${DEFAULT_MAIN_FORM_VIEW_TAB}`
          )
          createNewChannelGroupingPopupOverscreen.remove()
        }
      }
    )
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (nameValueError !== undefined) {
      setNameValueError(undefined)
    }
    setNameValue(e.target.value)
  }

  return (
    <PopUp
      open={createNewChannelGroupingPopupOverscreen.visible}
      handleOpen={() => {
        createNewChannelGroupingPopupOverscreen.remove()
      }}
    >
      <StyledGridPromptContainer>
        <Grid style={{ paddingBottom: '16px' }}>
          <Image src={keyboardImage.src} height={163} width={542} alt="create-new-banner" />
        </Grid>
        <Grid style={{ paddingBottom: '16px' }}>
          <StyledTypographyCentered variant="h2">{t('title')}</StyledTypographyCentered>
          <StyledTypographyCentered variant="body2">{t('description')}</StyledTypographyCentered>
        </Grid>
        <StyledGridTextContainer>
          <StyledInput
            helperText={nameValueError}
            placeholder={t('inputPlaceholder')}
            color={nameValueError !== undefined ? 'error' : undefined}
            name={t('nameInputLabel')}
            inputProps={{ 'data-form-type': 'other' }}
            labelText={t('nameInputLabel')}
            value={nameValue}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitNewChannelGrouping()
              }
            }}
          />
        </StyledGridTextContainer>
        <Grid display="flex" flexDirection="column" gap="8px" marginTop="8px">
          <Typography variant="h5">{t('selectTemplate')}</Typography>
          <SingleSelectDropdown
            title={t('selectTemplate')}
            value={selectedTemplate}
            onSelect={(value) => {
              setSelectedTemplate(value)
            }}
            buttonWidth="100%"
            options={[
              {
                value: NO_TEMPLATE_ID,
                label: t('noTemplateLabel'),
                annotation: t('noTemplateAnnotation')
              },
              ...STARTING_TEMPLATES.map((template) => ({
                value: template.id,
                label: template.name,
                annotation: template.description,
                subCategory: template.subcategory
              }))
            ]}
          />
        </Grid>
        <Divider />
        <Grid>
          <StyledButtonFullWidth
            variant="filled"
            onClick={submitNewChannelGrouping}
            disabled={nameValueError != null || nameValue === ''}
          >
            {isLoadingCreatingChannelGrouping ? <Dots /> : t('button')}
          </StyledButtonFullWidth>
        </Grid>
      </StyledGridPromptContainer>
    </PopUp>
  )
}

const StyledGridPromptContainer = styled(Grid)(() => ({ display: 'flex', flexDirection: 'column', gap: '8px' }))

const StyledGridTextContainer = styled(Grid)(() => ({ display: 'flex', flexDirection: 'column' }))

const StyledInput = styled(Input)(() => ({
  maxWidth: '100%',
  backgroundColor: 'black'
}))

const StyledTypographyCentered = styled(Typography)(() => ({
  textAlign: 'center'
}))

const StyledButtonFullWidth = styled(Button)(() => ({
  width: '100%'
}))

export default OverScreen.create(CreateNewChannelGroupingPopup)
