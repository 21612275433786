declare module '@mui/material/styles' {
  interface Theme {
    borderRadius: BorderRadiusTypes
  }
  interface ThemeOptions {
    borderRadius?: BorderRadiusTypes
  }
}

export type BorderRadiusOptions = 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

export const borderRadius: BorderRadiusTypes = {
  xxSmall: '5px',
  xSmall: '11px',
  small: '14px',
  medium: '16px',
  large: '20px',
  xLarge: '24px',
  xxLarge: '32px'
}
export type BorderRadiusTypes = { [key in BorderRadiusOptions]: string }

export default borderRadius
