import { Button, styled, Grid, DotsLoader, defaultTheme as theme } from '@precis-digital/kurama'
import { useTranslation } from 'react-i18next'

interface ActionButtonsProps {
  onSubmit: () => void
  onCancel: () => void
  isSubmitDisabled: boolean
  isAddingAccount?: boolean
  errorOnAddingDataSource?: boolean
}

export const ActionButtons = ({
  onSubmit,
  isSubmitDisabled = true,
  onCancel,
  isAddingAccount = false,
  errorOnAddingDataSource = false
}: ActionButtonsProps): React.ReactElement => {
  const { t } = useTranslation('dataSource')
  return (
    <StyledActions>
      <Button variant="tonal" onClick={onCancel}>
        {t('popups.cancel')}
      </Button>
      <StyledButton variant="filled" onClick={onSubmit} disabled={isSubmitDisabled}>
        {isAddingAccount && !errorOnAddingDataSource ? (
          <CenteredDotsLoader color={theme.palette.neutrals.stone150} />
        ) : (
          t('popups.connectAccounts')
        )}
      </StyledButton>
    </StyledActions>
  )
}

export const StyledActions = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  gap: 8,
  width: '100%'
}))

const StyledButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const CenteredDotsLoader = styled(DotsLoader)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export default ActionButtons
