import { StyledBanner, StyledContent, StyledIconContainer, StyledLink } from './styles'
import { BannerProps } from './types'
import Label from '../Label'
import { ArrowRightIcon } from '../Icons'
import Grid from '../Grid'

const variantMapping = {
  success: 'Tip',
  error: 'Warning',
  warning: 'Caution',
  info: 'Note'
}

const Banner = (props: BannerProps): React.ReactElement => {
  const isClickable = props.onClick != null || props.LinkProps != null
  const BaseBanner = (
    <StyledBanner
      isClickable={isClickable}
      {...(props.onClick != null && { onClick: props.onClick })}
      inline={props.inline}
    >
      <Grid display="flex" alignItems="center">
        <Label text={variantMapping[props.variant]} color={props.variant} />
        <StyledContent>{props.children}</StyledContent>
      </Grid>
      {isClickable && (
        <StyledIconContainer>
          <ArrowRightIcon />
        </StyledIconContainer>
      )}
    </StyledBanner>
  )

  return props.LinkProps != null ? <StyledLink {...props.LinkProps}>{BaseBanner}</StyledLink> : BaseBanner
}

export default Banner
