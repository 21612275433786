import Link, { type LinkProps } from 'next/link'
import { type NextRouter, useRouter } from 'next/router'
import { useCallback, type PropsWithChildren, useState, useEffect, type ReactNode, type ComponentType } from 'react'
import { CLIENT_ID_QUERY_PARAM } from 'shared/routes'
import { type ParsedUrlQuery } from 'querystring'
import { not, omit } from 'ramda'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useBreadcrumbsPathProps } from 'shared/hooks/useBreadcrumbsPathProps'
import { styled } from '@precis-digital/kurama'

type Push = (query: ParsedUrlQuery, reset?: boolean) => Promise<void>

export interface BreadcrumbPathProps {
  label: string
  path: string
}

const DEFAULT_QUERY = [CLIENT_ID_QUERY_PARAM]
export const getDefaultQuery = (router: NextRouter): ParsedUrlQuery => {
  const query = router?.query ?? {}
  return {
    ...(query[CLIENT_ID_QUERY_PARAM] != null ? { [CLIENT_ID_QUERY_PARAM]: query[CLIENT_ID_QUERY_PARAM] } : {})
  }
}

export const useClearQueries = (): (() => Promise<boolean>) => {
  const router = useRouter()
  return async () => {
    return await router.push({
      pathname: router.pathname,
      query: {}
    })
  }
}

interface DefaultQueryLinkProps extends LinkProps {
  query?: ParsedUrlQuery
}
export const RetainDefaultQueryLink = ({
  href,
  query,
  ...props
}: PropsWithChildren<DefaultQueryLinkProps>): React.ReactElement => {
  const router = useRouter()
  const pathname = typeof href === 'object' ? href.pathname : href

  return (
    <Link
      {...props}
      href={{
        pathname,
        query: {
          ...getDefaultQuery(router),
          ...query
        }
      }}
    />
  )
}

export const UnStyledRetainDefaultQueryLink = styled(RetainDefaultQueryLink)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    color: 'inherit',
    textDecoration: 'inherit',
    cursor: 'inherit',
    width: '100%',
    pointerEvents: disabled === true ? 'none' : 'auto'
  })
)

export const useQueryString = (): { query: ParsedUrlQuery; pushQuery: Push } => {
  const router = useRouter()
  const query = router?.query ?? {}

  const pushQuery: Push = async (query, reset): Promise<void> => {
    const defaultQuery = getDefaultQuery(router)
    if (reset === true) {
      Object.keys(router.query).forEach((id) => {
        if (not(DEFAULT_QUERY.includes(id)) && not(router.pathname.includes(id))) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete router.query[id]
        }
      })
    }

    const newQuery = {
      ...router.query,
      ...defaultQuery,
      ...query
    }

    await router?.replace(
      {
        pathname: router.pathname,

        query: newQuery
      },
      undefined,
      { shallow: false }
    )
  }

  return { query, pushQuery }
}

type Reload = () => void

export const useReload = (): Reload => {
  const router = useRouter()
  return router.reload
}

type GoBack = () => void
export const useGoBack = (): GoBack => {
  const router = useRouter()
  return router.back
}

export const useChangePath = (): {
  changePath: (url?: string, query?: ParsedUrlQuery, replace?: boolean) => Promise<void>
  pathname: string
  populatedPathName: string
} => {
  const router = useRouter()
  const pathname = router?.pathname ?? ''
  const populatedPathName = router?.asPath?.split('?')?.[0] ?? ''

  const changePath = async (url?: string, query?: ParsedUrlQuery, replace?: boolean): Promise<void> => {
    const mergedQuery: ParsedUrlQuery = { ...getDefaultQuery(router), ...query }
    const newQuery: ParsedUrlQuery = {}

    Object.entries(mergedQuery).forEach(([key, value]) => {
      if (value != null) newQuery[key] = value
    })

    if (replace === true) {
      await router.replace(
        {
          pathname: url ?? pathname,

          query: newQuery
        },
        undefined,
        { shallow: true }
      )
    } else {
      await router.push({
        pathname: url ?? pathname,

        query: newQuery
      })
    }
  }

  return { changePath, pathname, populatedPathName }
}

export const useOpenCentralAppLink = (): { openCentralAppLink: (url: string) => void } => {
  const { currentClient } = useCurrentClient()

  const openCentralAppLink = (url: string): void => {
    if (window != null) {
      window.open(`${url}?client_id=${currentClient.id}`, '_blank')
    }
  }

  return { openCentralAppLink }
}

interface UseBreadcrumbsProps {
  currentPath: string
  pathProps: BreadcrumbPathProps[]
  Link: ComponentType<{ href: string; children: ReactNode }>
}

export const useBreadcrumbs = (): UseBreadcrumbsProps => {
  const router = useRouter()

  const pathProps = useBreadcrumbsPathProps(router)

  return {
    currentPath: router.pathname,
    pathProps,
    Link: (props) => {
      return <RetainDefaultQueryLink href={props.href}>{props.children}</RetainDefaultQueryLink>
    }
  }
}

/**
 * A hook that implements functionality similar to useState, but uses the URL query parameters as the source of truth.
 * It also updates the URL query parameters when the state is updated.
 * It's implemented using the Next.js router, so it's only available on the client.
 */
export const useQueryParameterState = <T,>(
  key: string,
  defaultValue?: T,
  options?: {
    /**
     * If true, the query parameter will be removed from the URL when the state is set to the default value.
     * Defaults to true.
     */
    removeWhenDefault?: boolean
  }
): [T | undefined, (value: T | undefined) => void] => {
  const { removeWhenDefault = true } = options ?? {}
  const { changePath } = useChangePath()
  const { query } = useQueryString()

  // Helper function to parse query values
  const parseQueryValue = useCallback((queryValue: any, defaultValue: T | undefined): T | undefined => {
    if (queryValue != null) {
      try {
        return JSON.parse(Array.isArray(queryValue) ? queryValue[0] : queryValue)
      } catch (error) {
        return Array.isArray(queryValue) ? queryValue[0] : queryValue
      }
    }
    return defaultValue
  }, [])

  const [value, setValue] = useState<T | undefined>(() => {
    const queryValue = query[key]
    return parseQueryValue(queryValue, defaultValue)
  })

  // Update state when query parameter changes
  useEffect(() => {
    const queryValue = query[key]
    const newValue = parseQueryValue(queryValue, defaultValue)
    setValue(newValue)
  }, [query, key, defaultValue, parseQueryValue])

  const setValueAndQueryParameter = useCallback(
    (newValue: T | undefined) => {
      setValue(newValue)
      if (newValue === defaultValue && removeWhenDefault) {
        void changePath(undefined, omit([key], query), true)
      } else {
        void changePath(undefined, { ...query, [key]: JSON.stringify(newValue) }, true)
      }
    },
    [defaultValue, key, removeWhenDefault, query, changePath]
  )

  return [value, setValueAndQueryParameter]
}
