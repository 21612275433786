import { Grid, styled, Card, Typography } from '@precis-digital/kurama'

export const StyledSummaryCardsContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.stone90,
  padding: '20px',
  display: 'flex',
  borderRadius: '32px',
  width: '100%',
  gap: '24px',
  justifyContent: 'space-between'
}))

export const StyledSummaryCard = styled(Card)(({ theme }) => ({
  borderRadius: '24px',
  width: '100%',
  height: '160px',
  backgroundColor: theme.palette.neutrals.white0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  margin: '0px'
}))

export const StyledSummaryCardNumberContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  h3: {
    marginTop: '0.15rem'
  }
}))

export const StyledDateLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150
}))
