import ListView, { type ListViewProps } from '../ListView'
import React, { type ReactElement } from 'react'
import NoPreRequisites, { type NoPreRequisitesProps } from '../Prerequisite'
import { type ListViewEmptyStateProps } from '../ListView/types'
import ListViewEmptyState from '../ListView/EmptyState'

interface StartPageProps<T extends object> {
  listViewProps: ListViewProps<T>
  noPreRequisitesProps?: NoPreRequisitesProps
  emptyStateProps: ListViewEmptyStateProps
  hasAllPreRequisites?: boolean
}

const Start = <T extends object>({
  listViewProps,
  noPreRequisitesProps,
  emptyStateProps,
  hasAllPreRequisites
}: StartPageProps<T>): ReactElement => {
  return (
    <>
      {!(listViewProps.createNewButtonProps?.isLoadingPreRequisites ?? false) &&
      !(hasAllPreRequisites ?? false) &&
      noPreRequisitesProps !== undefined ? (
        <NoPreRequisites {...noPreRequisitesProps} />
      ) : (
        <>
          {!listViewProps.isLoading && listViewProps.tableProps.data?.length === 0 ? (
            <ListViewEmptyState {...emptyStateProps} />
          ) : (
            <ListView {...listViewProps} />
          )}
        </>
      )}
    </>
  )
}

export default Start
