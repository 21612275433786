import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'attribution_comparison_report',
  active: false,
  label: 'Attribution comparison report',
  category: 'Other',
  description: 'Report to compare results of different attribution models.',
  image_url: '',
  authors: [
    {
      name: 'Josh Lowe',
      email: 'lowe@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a/AEdFTp6VVngjQHuC3k_vO6Zh6yPB8P7bmv1i6osLLriW=s288-p-no'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2633039941/Attribution+comparison+report',
    link_description: 'Knowledge Hub: Attribution comparison report'
  },
  sources: {
    ia: {
      reports: ['ddav2', 'xgboost'],
      optional: true
    }
  },
  inputs: {}
}

export default config
