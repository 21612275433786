import { EditIcon, Grid, Input, Typography, styled } from '@precis-digital/kurama'
import { isEmpty, isNil } from 'ramda'
import React, { useState } from 'react'
import { Controller, useFormContext, type FieldPath, type FieldValues } from 'react-hook-form'

interface BaseFormHeaderProps<FormData extends FieldValues> {
  title: string
  fieldName?: FieldPath<FormData>
  status?: React.ReactNode
  lastEditedText?: string | React.ReactNode
  isCreateEvent: boolean
  fieldsCompleteText?: string
  handleUpdate?: (fieldName: FieldPath<FormData>, onSuccessHandler?: () => void) => void
  moreButton?: React.ReactNode
  extraInfo?: React.ReactNode
}

interface NonEditableFormHeaderProps<FormData extends FieldValues> extends BaseFormHeaderProps<FormData> {
  isEditable?: false
}

interface EditableFormHeaderProps<FormData extends FieldValues> extends BaseFormHeaderProps<FormData> {
  isEditable?: true
  handleUpdate: BaseFormHeaderProps<FormData>['handleUpdate']
  fieldName: BaseFormHeaderProps<FormData>['fieldName']
}

const FormTitle = ({ title, hasUserInput }: { title: string; hasUserInput?: boolean }): React.ReactElement => {
  return (
    <StyledTitleTypography variant="h1" hasUserInput={hasUserInput}>
      {title}
    </StyledTitleTypography>
  )
}
const FormHeader = <FormData extends FieldValues>({
  title,
  lastEditedText,
  fieldName,
  fieldsCompleteText,
  isCreateEvent,
  extraInfo,
  status,
  handleUpdate,
  moreButton,
  isEditable = false
}: NonEditableFormHeaderProps<FormData> | EditableFormHeaderProps<FormData>): React.ReactElement => {
  const [isTextClicked, setIsTextClicked] = useState(false)
  const [isTextHovered, setIsTextHovered] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(true)
  const {
    control,
    formState: { dirtyFields },
    resetField,
    getValues
  } = useFormContext<FormData>()
  const shouldShowEditableFormTitle = (!isTextClicked && !isInputFocused) || (!isTextClicked && !isCreateEvent)
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs gap="4px" alignItems="flex-start">
        {isEditable && fieldName != null ? (
          <Controller
            name={fieldName}
            control={control}
            rules={{
              required: true,
              validate: (value) => (typeof value === 'string' ? !isEmpty(value.trim()) : true)
            }}
            render={({ field }) => {
              const hasUserInput = !isNil(field.value) && !isEmpty((field.value as string).trim())
              return (
                <StyledGrid
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  isEditable
                  onMouseEnter={() => {
                    setIsTextHovered((prev) => !prev)
                  }}
                  onMouseLeave={() => {
                    setIsTextHovered((prev) => !prev)
                  }}
                  onClick={() => {
                    setIsTextClicked(true)
                  }}
                >
                  {shouldShowEditableFormTitle ? (
                    <FormTitle hasUserInput={hasUserInput} title={hasUserInput ? field.value : title} />
                  ) : (
                    <Input
                      {...field}
                      multiline
                      backgroundColor="transparent"
                      fontSizeVariant="h1"
                      fullWidth
                      autoFocus
                      placeholder={title}
                      onBlur={() => {
                        setIsTextClicked(false)
                        setIsTextHovered(false)
                        setIsInputFocused(false)
                        const isFieldModified = dirtyFields[fieldName] === true
                        const currentValue = getValues(fieldName)
                        !isCreateEvent &&
                          isFieldModified &&
                          handleUpdate?.(fieldName, () => {
                            resetField(fieldName, { defaultValue: currentValue, keepDirty: false })
                          })
                      }}
                      onFocus={(event) => {
                        event.currentTarget.setSelectionRange(
                          event.currentTarget.value.length,
                          event.currentTarget.value.length
                        )
                      }}
                    />
                  )}
                  <StyledIconGrid isVisible={shouldShowEditableFormTitle && isTextHovered}>
                    <EditIcon />
                  </StyledIconGrid>
                </StyledGrid>
              )
            }}
          />
        ) : (
          <FormTitle title={title} />
        )}

        {lastEditedText != null && <Typography variant="body3">{lastEditedText}</Typography>}
        <Grid display="flex" alignItems="center" justifyContent="space-between" marginTop="24px">
          <Grid display="flex" alignItems="center" gap="24px">
            {status}
            {extraInfo != null && (
              <Grid display="flex" alignItems="center" gap="24px">
                {extraInfo}
              </Grid>
            )}
            {fieldsCompleteText != null && <Typography variant="body2">{fieldsCompleteText}</Typography>}
          </Grid>
          {moreButton}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormHeader

const StyledGrid = styled(Grid)<{ isEditable?: boolean }>(({ isEditable }) => ({
  ...(isEditable === true && {
    cursor: 'pointer'
  })
}))

const StyledTitleTypography = styled(Typography)<{ hasUserInput?: boolean }>(({ theme, hasUserInput }) => ({
  ...(hasUserInput === false && {
    color: theme.palette.neutrals.stone150,
    opacity: 0.6
  }),
  overflow: 'hidden',
  wordBreak: 'break-word'
}))

const StyledIconGrid = styled(Grid)<{ isVisible: boolean }>(({ isVisible }) => ({
  visibility: isVisible ? 'visible' : 'hidden'
}))
