import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import React, { useState, type ReactElement } from 'react'
import ChannelsTable from './ChannelsTable'
import { Grid, MultiSelectDropdown, type MultiSelectDropdownItem, Typography } from '@precis-digital/kurama'
import EmptyState from './ChannelsEmptyState'
import { type Platforms } from 'shared/api/accounts'
import Image from 'next/image'
import { useTranslation } from 'shared/translations'
import { isEmpty } from 'ramda'

const ChannelsTab = (): ReactElement => {
  const { channelsMap, dataSourcesMap } = useCurrentChannelGrouping()
  const { t } = useTranslation('channelGrouping')
  const [dataSourcesFilter, setDataSourcesFilter] = useState<Platforms[]>([])

  const filteredChannels = Object.values(channelsMap).filter((channelItem) => {
    if (dataSourcesFilter.length === 0) {
      return true
    }

    let matchesFilter = false
    dataSourcesFilter.forEach((dataSourceFilterItem) => {
      if (
        (dataSourcesMap[dataSourceFilterItem]?.channels.map((channel) => channel.id) ?? []).includes(channelItem.id)
      ) {
        matchesFilter = true
      }
    })

    return matchesFilter
  })

  const showEmptyState = isEmpty(channelsMap)

  return (
    <Grid container flexDirection="column" gap="24px">
      {!showEmptyState ? (
        <>
          <Grid display="flex" justifyContent="space-between" marginTop="16px" alignItems="flex-end" height="40px">
            <Typography variant="h2">{t('mainFormView.channelsTab.title')}</Typography>
            <Grid
              width="fit-content"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              height="fit-content"
              gap="8px"
              flexWrap="nowrap"
            >
              <Typography variant="body2">{t('mainFormView.channelsTab.filterLabel')}</Typography>
              <MultiSelectDropdown
                title={t('mainFormView.channelsTab.filterTitle')}
                buttonTitle={t('mainFormView.channelsTab.filterButtonTitle')}
                options={Object.values(dataSourcesMap)
                  .filter((dataSourceItem) => dataSourceItem.channels.length > 0)
                  .map((dataSourceItem) => {
                    return {
                      label: dataSourceItem.label,
                      value: dataSourceItem.platform,
                      icon: (
                        <Image
                          src={dataSourceItem.iconUrl}
                          alt={`${dataSourceItem.label} icon`}
                          style={{ width: '100%', height: '100%' }}
                        />
                      )
                    }
                  })}
                value={dataSourcesFilter}
                onChange={(_: string | null, value: MultiSelectDropdownItem[]) => {
                  setDataSourcesFilter(value.map((item) => item.value) as Platforms[])
                }}
              />
            </Grid>
          </Grid>
          <ChannelsTable channels={filteredChannels} />
        </>
      ) : (
        <EmptyState />
      )}
    </Grid>
  )
}

export default ChannelsTab
