import { styled, Chip } from '@precis-digital/kurama'

export const Severity = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'textColor'
})(({ textColor }: { textColor: string }) => ({
  margin: '2px',
  color: textColor
}))

export default Severity
