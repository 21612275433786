import { Grid, GrowthIcon, styled } from '@precis-digital/kurama'
import { type DetailedReportData } from 'budgetOptimiser/api/transformations/transform'
import React from 'react'
import OverScreen from 'shared/overScreens'
interface RecommendationProps {
  rowData: DetailedReportData
}

export const RecommendationIcon = ({ rowData }: RecommendationProps): React.ReactElement => {
  return (
    <StyledIconContainer
      item
      onClick={() => {
        void OverScreen.show('recommendedActionsFlyout', { rowData })
      }}
    >
      <GrowthIcon />
    </StyledIconContainer>
  )
}
const StyledIconContainer = styled(Grid)(({ theme }) => ({
  width: '32px',
  height: '32px',
  backgroundColor: theme.palette.neutrals.stone100,
  borderRadius: '15px',
  textAlign: 'center',
  padding: '2px',
  cursor: 'pointer'
}))
export default RecommendationIcon
