import { styled } from '../../../utils'
import Grid from '../../Grid'
import { LARGE_AVATAR_WIDTH_EM, MEDIUM_AVATAR_WIDTH_EM, SMALL_AVATAR_WIDTH_EM } from '../Avatar/styles'
import { Size } from '../Avatar/types'
import { AVATAR_CLASS_NAME } from '../classNames'

const getAvatarGroupWidth = (size: Size, max: number, numberOfChildren: number): string => {
  const baseMultiplier = Math.min(max + 1, numberOfChildren)
  const emMultiplier =
    size === 'large' ? LARGE_AVATAR_WIDTH_EM : size === 'medium' ? MEDIUM_AVATAR_WIDTH_EM : SMALL_AVATAR_WIDTH_EM

  if (max >= numberOfChildren) {
    const emToAddForGap = 0.25 * (baseMultiplier - 1)
    return `calc(${emMultiplier * baseMultiplier + emToAddForGap}em)`
  } else {
    const emToSubtractForOverlap = 0.375 * (baseMultiplier - 1)
    return `calc(${emMultiplier * baseMultiplier - emToSubtractForOverlap}em)`
  }
}

export const StyledGridAvatarGroupRoot = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasExtraAvatars' && prop !== 'numberOfChildren'
})(
  ({
    hasExtraAvatars,
    max,
    size,
    numberOfChildren
  }: {
    hasExtraAvatars: boolean
    size: Size
    max: number
    numberOfChildren: number
  }) => ({
    display: 'flex',
    width: getAvatarGroupWidth(size, max, numberOfChildren),
    gap: hasExtraAvatars ? undefined : '4px',
    [`.${AVATAR_CLASS_NAME}`]: {
      margin: hasExtraAvatars ? '0px -3px' : undefined
    },
    [`.${AVATAR_CLASS_NAME}:first-of-type`]: {
      marginLeft: '0px'
    },
    [`.${AVATAR_CLASS_NAME}:last-child`]: {
      marginRight: '0px'
    }
  })
)
