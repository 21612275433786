import React from 'react'

export const Percent = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path d="M17.7042 6.75001L7.20416 17.25" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M8.32916 9.75C9.3647 9.75 10.2042 8.91053 10.2042 7.875C10.2042 6.83947 9.3647 6 8.32916 6C7.29363 6 6.45416 6.83947 6.45416 7.875C6.45416 8.91053 7.29363 9.75 8.32916 9.75Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.5791 18C17.6146 18 18.4541 17.1605 18.4541 16.125C18.4541 15.0895 17.6146 14.25 16.5791 14.25C15.5436 14.25 14.7041 15.0895 14.7041 16.125C14.7041 17.1605 15.5436 18 16.5791 18Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
})
