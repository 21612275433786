import React, { forwardRef } from 'react'
import MUITableContainer from '@mui/material/TableContainer'
import { TableProps } from './types'

export const TableContainer = forwardRef<HTMLDivElement, TableProps>(
  ({ children, ...props }, ref): React.ReactElement => {
    return (
      <MUITableContainer ref={ref} {...props}>
        {children}
      </MUITableContainer>
    )
  }
)
export default TableContainer
