import { Grid } from '@precis-digital/kurama'
import React, { useMemo } from 'react'
import { type ReactElement } from 'react-markdown/lib/react-markdown'
import ChannelsTab from './ChannelsTab'
import DistributionTab from './DistributionTab'
import InspectResultsTab from './InspectResultsTab'
import TabsRow from './TabsRow'
import Header from './Header'
import { ChannelGroupingDataSourceProvider } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { DEFAULT_DATA_SOURCE_VIEW_TAB } from 'channelGrouping/constants'
import { type Platforms } from 'shared/api/accounts'
import MoreMenu from './MoreMenu'
import { APP_ROUTES } from 'shared/routes'

interface DataSourceFormPageProps {
  tabId: string
  dataSource: Platforms
}

const DataSourceFormPage = ({ tabId, dataSource }: DataSourceFormPageProps): ReactElement => {
  const { channelGroupingId } = useCurrentChannelGrouping()

  const TabComponentToRender = useMemo(() => {
    switch (tabId) {
      case 'distribution':
        return DistributionTab
      case 'channels':
        return ChannelsTab
      case 'inspect-results':
        return InspectResultsTab
    }
  }, [tabId])

  if (TabComponentToRender == null) {
    return (
      <NotFoundPage
        hasDashboardFrame
        returnPath={`${APP_ROUTES.customGroupings.dataSourcesPage({
          channelGroupingId: channelGroupingId.toString()
        })}/${dataSource}/${DEFAULT_DATA_SOURCE_VIEW_TAB}`}
      />
    )
  }

  return (
    <ChannelGroupingDataSourceProvider dataSource={dataSource}>
      <Grid container flexDirection="column" gap="24px">
        <Header shouldRenderPerformanceData={tabId !== 'inspect-results'} />
        <Grid display="flex" justifyContent="space-between">
          <TabsRow tabId={tabId} />
          <MoreMenu />
        </Grid>
        <TabComponentToRender />
      </Grid>
    </ChannelGroupingDataSourceProvider>
  )
}

export default DataSourceFormPage
