import {
  Typography,
  LineChart,
  Grid,
  defaultTheme as theme,
  Badge,
  MultiSelectDropdown,
  Tooltip,
  Button,
  Toggle,
  styled,
  type Theme,
  type MultiSelectDropdownItem
} from '@precis-digital/kurama'
import Block from 'shared/components/Block'
import { useTranslation } from 'shared/translations'
import { type Report, transformToFilters, type TransformToTimeSeriesByMetricAndBreakdownFn } from 'home/transformations'
import { useEffect, useMemo, useState } from 'react'
import { ChartToolTip } from 'shared/components/ChartToolTip'
import { getFormattedValue } from 'shared/numberFormat'
import { useCurrentClient } from 'shared/context/ClientContext'
import { sortOptionInAlphabeticalOrder } from 'shared/utils'
import { makeErrorToast } from 'shared/components/Toaster'
import { getDifferenceInDays } from 'shared/dateFns'
import {
  formatTooltipDescriptionByDataKey,
  getChartLegendData,
  getPartsFromDataKey,
  getProgressOverTimeLineConfigs
} from './utils'
import ChartLegend from 'shared/components/ChartLegend'
import { type TimePeriodType } from './StartPage'
import toolsIcon from 'public/assets/images/tools.svg'
import Image from 'next/image'
import { last } from 'ramda'
import { type Metric } from 'home/reportTemplateParameters'

export interface ProgressOverTimeRecord {
  fullDate: string
  x: string
  [key: string]: number | string
}

export type ProgressOverTimeRecords = ProgressOverTimeRecord[]

const MINIMUM_METRICS = 1
const MAXIMUM_METRICS = 2

const MINIMUM_BREAKDOWN = 0
const MAXIMUM_BREAKDOWN = 6

const Y_AXIS_LABEL_OFFSET = 6.5

interface ProgressOverTimeProps<TReport extends Report, TMetric extends Metric> {
  reports: TReport[]
  reportsForPreviousTimePeriod: TReport[]
  timePeriod: [string, string]
  selectedPeriodType?: TimePeriodType
  metrics: readonly TMetric[]
  errorBody?: React.ReactNode
  transformToTimeSeriesByMetricAndBreakdown?: TransformToTimeSeriesByMetricAndBreakdownFn<TReport, TMetric>
}

export default function ProgressOverTime<TReport extends Report, TMetric extends Metric>({
  reports,
  reportsForPreviousTimePeriod,
  timePeriod,
  metrics,
  errorBody,
  selectedPeriodType = 'previous_period',
  transformToTimeSeriesByMetricAndBreakdown
}: Readonly<ProgressOverTimeProps<TReport, TMetric>>): React.ReactElement {
  const { t } = useTranslation('home')

  const {
    currentClient: { currency: targetCurrencyCode }
  } = useCurrentClient()
  const [userSelectedMetrics, setUserSelectedMetrics] = useState<TMetric[]>(['conversionValue' as TMetric])
  const [finalizedMetrics, setFinalizedMetrics] = useState<TMetric[]>(userSelectedMetrics)

  const [userSelectedChannels, setUserSelectedChannels] = useState<MultiSelectDropdownItem[]>([])
  const [finalizedChannels, setFinalizedChannels] = useState<MultiSelectDropdownItem[]>(userSelectedChannels)

  const [userSelectedMarkets, setUserSelectedMarkets] = useState<MultiSelectDropdownItem[]>([])
  const [finalizedMarkets, setFinalizedMarkets] = useState<MultiSelectDropdownItem[]>(userSelectedMarkets)

  const [isComparisonMode, setIsComparisonMode] = useState(false)

  const userSelectedBreakdown = [...userSelectedChannels, ...userSelectedMarkets]

  const selectedBreakdown = useMemo(
    () => [...finalizedChannels, ...finalizedMarkets],
    [finalizedChannels, finalizedMarkets]
  )

  const progressOverTime: ProgressOverTimeRecords = useMemo(() => {
    if (reports.length === 0 || transformToTimeSeriesByMetricAndBreakdown == null) {
      return []
    }
    return transformToTimeSeriesByMetricAndBreakdown({
      records: reports,
      previousRecords: reportsForPreviousTimePeriod,
      metrics: finalizedMetrics,
      daysRange: getDifferenceInDays(...timePeriod),
      markets: finalizedMarkets.map(({ value }) => value),
      channels: finalizedChannels.map(({ value }) => value),
      isComparisonMode: selectedBreakdown.length <= 2 && isComparisonMode,
      selectedPeriodType
    })
  }, [
    isComparisonMode,
    reports,
    reportsForPreviousTimePeriod,
    selectedBreakdown.length,
    finalizedChannels,
    finalizedMarkets,
    finalizedMetrics,
    selectedPeriodType,
    timePeriod,
    transformToTimeSeriesByMetricAndBreakdown
  ])

  const lineConfigs = getProgressOverTimeLineConfigs(progressOverTime)

  const { channels, markets } = transformToFilters(reports)

  const marketOptions = Array.from(markets)
    .map((market) => {
      const hasAtLeastOneChannelSelected = userSelectedChannels.length > MINIMUM_BREAKDOWN
      const hasMaximumBreakdown = userSelectedBreakdown.length === MAXIMUM_BREAKDOWN
      const isNotCurrentlySelected =
        userSelectedMarkets.find((userSelectedMarket) => userSelectedMarket.value === market.id) == null

      return {
        label: market.name,
        value: market.id,
        subCategory: t('markets'),
        disabled: hasAtLeastOneChannelSelected || (hasMaximumBreakdown && isNotCurrentlySelected)
      }
    })
    .sort(sortOptionInAlphabeticalOrder)

  const channelOptions = Array.from(channels)
    .map((channel) => {
      const hasAtLeastOneMarketSelected = userSelectedMarkets.length > 0
      const hasMaximumBreakdown = userSelectedBreakdown.length === MAXIMUM_BREAKDOWN
      const isNotCurrentlySelected =
        userSelectedChannels.find((userSelectedChannel) => userSelectedChannel.value === channel.id) == null

      return {
        label: channel.name,
        value: channel.id,
        subCategory: t('channels'),
        disabled: hasAtLeastOneMarketSelected || (hasMaximumBreakdown && isNotCurrentlySelected)
      }
    })
    .sort(sortOptionInAlphabeticalOrder)

  const breakdownOptions = [...marketOptions, ...channelOptions]

  const clearBreakdown = (): void => {
    setUserSelectedChannels([])
    setUserSelectedMarkets([])
    setFinalizedChannels([])
    setFinalizedMarkets([])
  }

  const handleComparisonMode = (): void => {
    setIsComparisonMode(!isComparisonMode)
  }

  useEffect(() => {
    if (selectedBreakdown.length > 2) {
      setIsComparisonMode(false)
    }
  }, [selectedBreakdown])

  const handleMetricsChange = (_: string | null, value: MultiSelectDropdownItem[]): void => {
    setUserSelectedMetrics(value.map((item) => item.value) as TMetric[])
  }

  const handleMetricsDropdownClose = (): void => {
    if (userSelectedMetrics.length < MINIMUM_METRICS) {
      makeErrorToast(t('filter.minimumOneMetricError'))
      setUserSelectedMetrics(finalizedMetrics)
      return
    }
    if (userSelectedMetrics.length > MAXIMUM_METRICS) {
      makeErrorToast(t('filter.maximumTwoMetricsError'))
      setUserSelectedMetrics(finalizedMetrics)
      return
    }

    setFinalizedMetrics(userSelectedMetrics)
  }

  const handleBreakdownChange = (_: string | null, value: MultiSelectDropdownItem[]): void => {
    const isMarketsSubCategory = last(value)?.subCategory === t('markets')

    if (value.length === 0) {
      clearBreakdown()
      return
    }

    isMarketsSubCategory ? setUserSelectedMarkets(value) : setUserSelectedChannels(value)
  }

  const handleBreakdownDropdownClose = (): void => {
    userSelectedChannels.length > 0
      ? setFinalizedChannels(userSelectedChannels)
      : setFinalizedMarkets(userSelectedMarkets)
  }

  return (
    <Block marginTop="0px">
      <Typography variant="h3">{t('progressOverTime.title')}</Typography>
      {progressOverTime.length === 0 ? (
        <StyledGrid container direction="column" height="408px" alignItems="center" justifyContent="center">
          <Image src={toolsIcon} alt="Tools" width={40} height={40} />
          {errorBody ?? <Typography variant="h5">{t('noDataToDisplay')}</Typography>}
        </StyledGrid>
      ) : (
        <>
          <Grid justifyContent="space-between" display="flex" alignItems="center" width="100%">
            <Grid
              container
              marginTop={theme.spacing(4)}
              marginBottom={theme.spacing(4)}
              gap={theme.spacing(1)}
              alignItems="center"
              flexWrap="nowrap"
            >
              <Grid item>
                <Typography variant="body2">{t('showMe')}</Typography>
              </Grid>

              <Grid item>
                <MultiSelectDropdown
                  title={t('buttons.selectMetrics')}
                  buttonTitle={t('buttons.selectMetrics')}
                  allowSelectAll={false}
                  value={userSelectedMetrics}
                  options={metrics.map((metric) => ({
                    label: t(metric),
                    value: metric,
                    disabled:
                      !userSelectedMetrics.includes(metric) &&
                      userSelectedMetrics.length >= MINIMUM_METRICS &&
                      (selectedBreakdown.length > MINIMUM_BREAKDOWN || userSelectedMetrics.length >= MAXIMUM_METRICS)
                  }))}
                  onChange={handleMetricsChange}
                  onClose={handleMetricsDropdownClose}
                />
              </Grid>
              <Grid item>
                <Tooltip
                  kind="singleline"
                  title="To be able to use the breakdown functionality, please select only 1 metric"
                  body={undefined}
                  disabled={finalizedMetrics.length === MINIMUM_METRICS}
                >
                  <Grid>
                    <MultiSelectDropdown
                      title={t('buttons.selectBreakdown')}
                      buttonWidth="fit-content"
                      buttonTitle={t('buttons.selectBreakdown')}
                      allowSelectAll={false}
                      disabled={finalizedMetrics.length > 1}
                      value={userSelectedBreakdown}
                      options={breakdownOptions}
                      onChange={handleBreakdownChange}
                      onClose={handleBreakdownDropdownClose}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
              {selectedBreakdown.length > 0 && (
                <StyledButton variant="text" scheme="light" onClick={clearBreakdown}>
                  <Typography variant="body2" color={theme.palette.primary.main}>
                    {t('buttons.clearBreakdown')}
                  </Typography>
                </StyledButton>
              )}
            </Grid>
            {selectedBreakdown.length <= 2 && (
              <Grid display="flex" gap="8px" flexShrink={0} alignItems="center">
                <Grid>
                  <Typography variant="body2">{t('compareThis')}</Typography>
                </Grid>
                <Toggle
                  aria-label={t('compareThis')}
                  name="comparsionMode"
                  onChange={handleComparisonMode}
                  checked={isComparisonMode}
                  defaultChecked
                />
              </Grid>
            )}
          </Grid>
          <ChartLegend
            selectedRecords={getChartLegendData({
              progressOverTime,
              lineConfigs,
              selectedBreakdownCount: selectedBreakdown.length,
              selectedMetricsCount: finalizedMetrics.length,
              t,
              selectedPeriodType
            })}
          />
          <Grid height="438px" width="100%">
            <LineChart
              data={progressOverTime}
              getConfigByDataKey={(dataKey) => {
                let lineConfig = {}
                const [metric] = getPartsFromDataKey(dataKey.toString())
                if (finalizedMetrics.length > 1 && metric === finalizedMetrics[1]) {
                  lineConfig = {
                    yAxisId: 'left'
                  }
                }

                lineConfig = {
                  ...lineConfig,
                  ...lineConfigs[dataKey]
                }

                return lineConfig
              }}
              renderTooltip={({ payload }) => {
                return (
                  <ChartToolTip
                    width="fit-content"
                    {...{
                      customBody: (
                        <span>
                          {selectedBreakdown.length <= 2 && isComparisonMode && (
                            <Typography variant="body3" color={theme.palette.custom.grey}>
                              {t('comparedToPreviousPeriod')}
                            </Typography>
                          )}
                          {Object.keys(payload)
                            .filter((key) => key !== 'x' && key !== 'fullDate')
                            .map((key) => {
                              const [metric] = getPartsFromDataKey(key)
                              return (
                                <div
                                  key={key}
                                  style={{
                                    width: '326px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <div style={{ marginRight: '8px' }}>
                                    <Badge color={lineConfigs[key].badgeColor} opacity={lineConfigs[key].lineOpacity} />
                                  </div>
                                  <div
                                    style={{
                                      marginRight: '16px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis'
                                    }}
                                  >
                                    {formatTooltipDescriptionByDataKey({
                                      dataKey: key,
                                      payload,
                                      timePeriod,
                                      selectedBreakdownCount: selectedBreakdown.length,
                                      selectedPeriodType,
                                      t
                                    })}
                                  </div>
                                  <div style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                                    {getFormattedValue({
                                      value: payload[key] as number,
                                      id: metric,
                                      currencyCode: targetCurrencyCode
                                    })}
                                  </div>
                                </div>
                              )
                            })}
                        </span>
                      ),
                      title: payload.x.toString()
                    }}
                  />
                )
              }}
              margin={{
                left: 5,
                right: 5,
                top: 50
              }}
              xAxis={{ tickLine: false }}
              yAxis={finalizedMetrics.map((metric, position) => ({
                label: {
                  value: t(metric)
                },
                tick: {
                  textAnchor: 'middle'
                },
                ...(position === 1 && {
                  yAxisId: 'left',
                  orientation: 'left',
                  label: {
                    value: t(metric),
                    dx: t(metric).length * Y_AXIS_LABEL_OFFSET
                  }
                }),
                tickFormatter: (value: string) =>
                  getFormattedValue({
                    value: Number(value),
                    id: metric,
                    currencyCode: targetCurrencyCode,
                    compact: true
                  })
              }))}
            />
          </Grid>
        </>
      )}
    </Block>
  )
}

const StyledGrid = styled(Grid)<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.stone90,
  borderRadius: theme.borderRadius.medium,
  marginTop: theme.spacing(4),
  '& > img': {
    marginBottom: theme.spacing(3)
  }
}))

const StyledButton = styled(Button)(() => ({
  alignSelf: 'unset'
}))
