import React, { useState } from 'react'
import { PopUp, Typography, Label, Button, Input, Divider } from '@precis-digital/kurama'
import { useUpdateMember, useDeleteMember } from 'workspace/api'
import { useAuth } from 'shared/context/AuthContext'
import {
  StyledCautionCard,
  StyledUpperText,
  StyledLeaveWorkspacePopupFooter,
  StyledPasswordInput,
  StyledTextSpan,
  StyledCautionText
} from '../Popup/styles'
import { type PopupProps } from './types'
import { handleConfirmationChange } from './utils'
import { MembersView } from './MembersView'
import { useIsSoloAdmin } from 'shared/hooks/useIsSoloAdmin'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'
import yellowCone from 'public/assets/images/yellow_cone.png'
import Image from 'next/image'
import { useReload } from 'shared/components/Router'

const CONFIRMATION_LEAVE_WORD = 'LEAVE'

const LeaveWorkspacePopup = (props: PopupProps): React.ReactElement => {
  const { open, onClose } = props
  const reload = useReload()
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()

  const isSoloAdmin = useIsSoloAdmin()
  const { mutateAsync: updateUserAsync } = useUpdateMember()
  const { mutateAsync: deleteUser } = useDeleteMember()

  const { t } = useTranslation('workspace')

  const [leaveInputValue, setLeaveInputValue] = useState('')

  const [selectedNewAdmin, setNewAdmin] = useState<string>('')

  const hasManuallyConfirmedToLeave = leaveInputValue === CONFIRMATION_LEAVE_WORD
  const hasSelectedNewAdmin = selectedNewAdmin !== ''
  const mustSelectNewAdmin = isSoloAdmin

  const leaveInputColor = hasManuallyConfirmedToLeave ? 'success' : 'error'

  const handleSelectedNewAdmin = (adminId: string): void => {
    setNewAdmin(adminId)
  }

  const canLeaveWorkspace = mustSelectNewAdmin
    ? hasManuallyConfirmedToLeave && hasSelectedNewAdmin
    : hasManuallyConfirmedToLeave

  const handleLeaveWorkspace = async (): Promise<void> => {
    if (mustSelectNewAdmin) {
      await updateUserAsync({ clientId: currentClient.id, userId: selectedNewAdmin, role: 'admin' })
    }
    await deleteUser({ clientId: currentClient.id, userId: currentUser?.id ?? '' })
    reload()
  }

  return (
    <>
      <PopUp handleOpen={onClose} open={open}>
        <Image
          src={yellowCone.src}
          width={544}
          height={179}
          alt="yellow cone"
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
        <StyledUpperText>
          <Typography variant="h2"> {t('titles.leaveWorkspacePopup')}</Typography>
        </StyledUpperText>
        <StyledCautionCard>
          <Label color="orange" text="Caution" />
          <StyledCautionText variant="h4">
            <StyledTextSpan>{t('descriptions.cautionBold')}</StyledTextSpan> {t('descriptions.cautionLeavingWorkspace')}
          </StyledCautionText>
        </StyledCautionCard>
        <Typography variant="h3"> {t('titles.confirmLeaveWorkspace')}</Typography>
        <StyledPasswordInput>
          <Typography variant="body2">{t('titles.typeLeave')}</Typography>
          <Input
            name="leave"
            value={leaveInputValue}
            {...(leaveInputValue !== '' ? { color: leaveInputColor } : null)}
            onChange={(e) => {
              handleConfirmationChange(e, CONFIRMATION_LEAVE_WORD, setLeaveInputValue)
            }}
          />
        </StyledPasswordInput>
        {isSoloAdmin ? <MembersView onMemberSelected={handleSelectedNewAdmin} id={selectedNewAdmin} /> : null}
        <Divider />

        <StyledLeaveWorkspacePopupFooter>
          <Button onClick={onClose} scheme="light" variant="outlined">
            {t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              void handleLeaveWorkspace()
            }}
            scheme="light"
            variant="filled"
            disabled={!canLeaveWorkspace}
          >
            {t('buttons.leaveWorkspacePopup')}
          </Button>
        </StyledLeaveWorkspacePopupFooter>
      </PopUp>
    </>
  )
}

export default LeaveWorkspacePopup
