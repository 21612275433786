import {
  createTheme,
  Theme as MUITheme,
  CSSObject as MUICSSObject,
  keyframes as MUIKeyframes
} from '@mui/material/styles'
import typography, { fonts } from './typography'
import { palette, PaletteTypes } from './palette'
import { borderRadius, BorderRadiusTypes } from './borderRadius'
import { elevation, ElevationTypes } from './elevation/types'
import { spacing } from './spacing'
import { breakpoints } from './breakpoint'
import zIndexes, { ZIndexesTypes } from './zIndex'

const components = {
  MuiCssBaseline: {
    styleOverrides: `
    ${fonts.graphikRegular}
    ${fonts.graphikMedium}
    `
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '& .MuiTouchRipple-root .MuiTouchRipple-child': {
          backgroundColor: 'transparent',
          borderRadius: '16px'
        }
      }
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: '#F8FBFC',
          borderRadius: '16px'
        }
      }
    }
  }
}

/**
 * Additional custom properties should be defined here using module-augmentation
 * https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
 */

declare module '@mui/material/styles' {
  export interface Theme {
    palette: PaletteTypes
    spacing: any
    borderRadius: BorderRadiusTypes
    elevation: ElevationTypes
    zIndex: ZIndexesTypes
  }
}

export interface BaseTheme {
  palette: PaletteTypes
  borderRadius: BorderRadiusTypes
}

/**
 * Override Material Ui default theme
 * https://mui.com/material-ui/customization/theming/
 */

const defaultTheme = createTheme({
  palette,
  typography,
  borderRadius,
  spacing,
  elevation,
  components,
  breakpoints,
  zIndex: zIndexes
})
export const keyframes = MUIKeyframes
export type Theme = MUITheme
export type CSSObject = MUICSSObject
export { defaultTheme }
export default defaultTheme
