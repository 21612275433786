import moment from 'moment'
import logger from 'shared/logger'
import {
  DEFAULT_CURRENCY_OPTIONS,
  reportTemplateRegistry,
  type ReportTemplate,
  GA4_MEASUREMENT_ID,
  type DashboardDataSource,
  type DashboardSheetDataSource
} from './constants'
import { type StandardReportResp } from 'shared/api/standardReports'

const log = logger()

const parseReportTemplate = (reportTemplate: ReportTemplate): ReportTemplate => {
  if (reportTemplate.inputs === undefined) {
    return reportTemplate
  }

  // Add default currency options to all currency dropdowns
  Object.entries(reportTemplate.inputs).forEach(([inputId, inputs]) => {
    inputs.forEach((inputObject, idx) => {
      if (
        inputObject.id.indexOf('Currency') > 1 &&
        inputObject.type === 'dropdown' &&
        reportTemplate.inputs !== undefined
      ) {
        reportTemplate.inputs[inputId][idx].options = DEFAULT_CURRENCY_OPTIONS
      }
    })
  })

  return reportTemplate
}

export const getReportTemplateById = (reportId: string): ReportTemplate | undefined => {
  const reportTemplate: ReportTemplate | undefined = reportTemplateRegistry[reportId]
  if (reportTemplate === undefined) {
    log.error(`Report template with id ${reportId} not found`)
    return undefined
  }

  return parseReportTemplate(reportTemplate)
}

export const dateToFormat = (date: Date, format?: string): string => {
  format = format ?? 'DD/MM/YYYY'
  return moment.utc(date).local().format(format)
}

export const getDashboardLink = (
  ReportingSolutionConfig: StandardReportResp,
  sheetsLinks: string[]
): URL | undefined => {
  /**
   * Generates a link for the user to generate a dashboard using the Linking API: https://developers.google.com/datastudio/share/linking-api
   * On click it will use the dashboard information specified in the reportDefinition and
   * the actual config to make a custom link for the config.
   */
  const reportTemplate = getReportTemplateById(ReportingSolutionConfig.templateId)
  const dashboardInfo = reportTemplate?.dashboard

  // If it hasn't been defined return undefined
  if (dashboardInfo == null) {
    return undefined
  }

  const url = new URL('https://datastudio.google.com/reporting/create')
  url.searchParams.set('c.reportId', dashboardInfo.template_report_id)
  url.searchParams.set('c.mode', 'edit')

  const today = dateToFormat(new Date(), 'YYYY-MM-DD')
  url.searchParams.set('r.reportName', dashboardInfo.report_name_template.replace('{today}', today))
  url.searchParams.set('r.measurementId', GA4_MEASUREMENT_ID)

  // For each of the datasources required for the report
  dashboardInfo.datasources.forEach((datasource: DashboardDataSource) => {
    url.searchParams.set(`ds.${datasource.alias}.connector`, 'bigQuery')
    url.searchParams.set(`ds.${datasource.alias}.type`, datasource.type)
    const datasourceName = getDatasourceName(
      ReportingSolutionConfig.name,
      reportTemplate?.id ?? '',
      datasource.datasourceName
    )
    url.searchParams.set(`ds.${datasource.alias}.datasourceName`, datasourceName)
    url.searchParams.set(`ds.${datasource.alias}.projectId`, ReportingSolutionConfig.targetProjectId.toString())
    url.searchParams.set(`ds.${datasource.alias}.datasetId`, ReportingSolutionConfig.targetDataset)

    // Get the id of the table using the same logic used in bifrost-transform
    const tableId = getDataSourceTableName(ReportingSolutionConfig.name, reportTemplate?.id ?? '', datasource.tableId)
    url.searchParams.set(`ds.${datasource.alias}.tableId`, tableId)
    url.searchParams.set(`ds.${datasource.alias}.isPartitioned`, datasource.isPartitioned)
    url.searchParams.set(`ds.${datasource.alias}.refreshFields`, datasource.refreshFields)
  })

  // For each of the sheet datasources required for the report
  dashboardInfo.sheets_datasources?.forEach((sheetsDatasource: DashboardSheetDataSource, index) => {
    const sheetsLink = sheetsLinks[index]
    const match = sheetsLink.match(/\/spreadsheets\/d\/([^/]+)\/edit#gid=([^/]+)/)

    const spreadsheetId = match?.[1]
    const worksheetId = match?.[2]
    if ([sheetsLink, spreadsheetId, worksheetId].includes(undefined)) {
      return
    }

    url.searchParams.set(`ds.${sheetsDatasource.alias}.connector`, 'googleSheets')
    const datasourceName = getDatasourceName(
      ReportingSolutionConfig.name,
      reportTemplate?.id ?? '',
      sheetsDatasource.datasourceName
    )
    url.searchParams.set(`ds.${sheetsDatasource.alias}.datasourceName`, datasourceName)
    url.searchParams.set(`ds.${sheetsDatasource.alias}.spreadsheetId`, spreadsheetId as string)
    url.searchParams.set(`ds.${sheetsDatasource.alias}.worksheetId`, worksheetId as string)
    url.searchParams.set(`ds.${sheetsDatasource.alias}.refreshFields`, sheetsDatasource.refreshFields)
  })

  return url
}

export const getDatasourceName = (name: string, id: string, datasourceName: string): string => {
  const today = dateToFormat(new Date(), 'YYYY-MM-DD')
  return datasourceName.replace('{name}', name.toLowerCase().replace(`${id}_`, '')) + ` - ${today}`
}

export const getDataSourceTableName = (
  targetTableName: string,
  reportId: string,
  dataSourceTableId: string
): string => {
  return dataSourceTableId.replace('{name}', targetTableName.toLowerCase().replace(`${reportId}_`, ''))
}
