import { styled } from '../../utils'
import Grid from '../Grid'
import { CSSObject, Theme } from '@mui/material/styles'

export const StyledContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    height: '100vh',
    background: theme?.palette.main.blue100
  })
)

export const StyledContentContainer = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    backgroundColor: theme?.palette.neutrals.white0,
    flex: '2',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 16px 32px #7090b002',
    borderRadius: `0 ${theme?.borderRadius.xxLarge ?? '32px'} ${theme?.borderRadius.xxLarge ?? '32px'} 0`,
    height: '100vh',
    overflow: 'hidden'
  })
)

export const StyledContentHeader = styled(Grid)({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '30px 62px',
  height: '102px'
})

export const StyleContent = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    padding: '0 48px 52px 48px',
    height: 'calc(100% - 86px)'
  })
)

export const StyledSideContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '72px 0px 24px 0px',
    width: '35%',
    maxWidth: '450px'
  })
)

export const StyledSideArtworkContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    overflow: 'hidden',
    width: '100%',
    svg: {
      position: 'relative',
      left: '50%',
      transform: 'translatex(-136px)'
    }
  })
)
