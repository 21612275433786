import { IconProps } from '../types'
export const Icon = ({ width }: IconProps): React.ReactElement => {
  return (
    <svg width={width} height={width} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#5578F5"
        d="M78.9,.02H21.1C9.44,.02,0,9.46,0,21.1V99.98H78.9c11.65,0,21.1-9.44,21.1-21.09V21.1C100,9.46,90.56,.02,78.9,.02Z"
      />
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#FFFFFF"
          d="M37.59,45.76l7.51-19.09c.3-.76,1.04-1.27,1.86-1.27h6.08c.82,0,1.56,.5,1.86,1.27l7.51,19.09c.52,1.31-.45,2.73-1.86,2.73h-4.08c-.83,0-1.57-.51-1.87-1.29l-3.67-9.62c-.33-.86-1.54-.86-1.87,0l-3.7,9.63c-.3,.77-1.04,1.28-1.87,1.28h-4.04c-1.41,0-2.38-1.42-1.86-2.73Z"
        />
        <path
          fill="#FFFFFF"
          d="M78.39,59.15c-6.19,9.58-16.88,15.45-28.39,15.45s-22.19-5.87-28.39-15.45c-.63-.97-.31-2.28,.7-2.84l3.5-1.96c.92-.51,2.07-.22,2.65,.66,4.75,7.2,12.84,11.6,21.54,11.6s16.79-4.4,21.54-11.6c.58-.88,1.73-1.17,2.65-.66l3.5,1.96c1.01,.57,1.33,1.87,.7,2.84Z"
        />
      </g>
    </svg>
  )
}

export default Icon
