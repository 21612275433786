import { type NavButtonsProps } from '@precis-digital/kurama'
import { useRouter } from 'next/router'
import { last } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useChangePath } from 'shared/components/Router'

const splitPathAndQueryParams = (url: string): PathState => {
  const queryParams: Record<string, string> = {}
  const urlQueryParams = url.split('?')[1]
  if (urlQueryParams != null) {
    urlQueryParams.split('&').forEach((param) => {
      const [key, value] = param.split('=')
      queryParams[key] = value
    })
  }
  return { path: url.split('?')[0], query: queryParams }
}

interface PathState {
  path: string
  query: Record<string, string>
}

export const useNavButtons = (): NavButtonsProps => {
  const router = useRouter()
  const { changePath } = useChangePath()
  const [navigationState, setNavigationState] = useState<Record<'historic' | 'future', PathState[]>>({
    historic: [],
    future: []
  })
  const [hasClickedBackwards, setHasClickedBackwards] = useState<boolean>(false)

  const handleBackwardClick = (): void => {
    const targetPath = last(navigationState.historic)
    if (targetPath != null) {
      setHasClickedBackwards(true)
      void changePath(targetPath.path, targetPath.query)
    }
  }

  const handleForwardClick = (): void => {
    const targetPath = last(navigationState.future)
    if (targetPath != null) {
      void changePath(targetPath.path, targetPath.query)
    }
  }

  // We need to use refs here to avoid the useEffect hook being triggered when the navigationState or hasClickedBackwards state changes
  // That would also work, but it would cause us to subscribe and unsubscribe to the router events every time the state changes
  // This way, we only subscribe once and we can still access the latest state
  const navigationStateRef = useRef(navigationState)
  const hasClickedBackwardsRef = useRef(hasClickedBackwards)

  useEffect(() => {
    navigationStateRef.current = navigationState
    hasClickedBackwardsRef.current = hasClickedBackwards
  }, [navigationState, hasClickedBackwards])

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }): void => {
      const targetUrlState = splitPathAndQueryParams(url)
      const currentUrlState = splitPathAndQueryParams(router.asPath)
      const navigationState = navigationStateRef.current
      const hasClickedBackwards = hasClickedBackwardsRef.current

      if (targetUrlState.path === currentUrlState.path) {
        // If the path is the same as before, we shouldn't touch the navigation state
        return
      }

      if (shallow) {
        // If the route change was shallow, we shouldn't touch the navigation state
        return
      }

      const lastHistoricPath = last(navigationState.historic)
      if (lastHistoricPath != null && lastHistoricPath.path === targetUrlState.path && hasClickedBackwards) {
        // This means the last route change was initiated by the user clicking the back button
        setHasClickedBackwards(false)
        setNavigationState((currentState) => ({
          historic: currentState.historic.slice(0, -1),
          future: [...currentState.future, currentUrlState]
        }))
        return
      }

      const lastFuturePath = last(navigationState.future)
      if (lastFuturePath != null && lastFuturePath.path === targetUrlState.path) {
        // This means the last route change was initiated by the user clicking the forward button
        setNavigationState((currentState) => ({
          historic: [...currentState.historic, currentUrlState],
          future: currentState.future.slice(0, -1)
        }))
        return
      }

      // If we get here, it means the route change was initiated by something else than the user clicking the back or forward button
      // So we need to clear the future navigation state and add the current state to the historic navigation state
      setNavigationState((currentState) => ({
        historic: [...currentState.historic, currentUrlState],
        future: []
      }))
    }

    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange)
    }
  }, [router])

  return {
    handleBackwardClick,
    handleForwardClick,
    isBackwardDisabled: navigationState.historic.length === 0,
    isForwardDisabled: navigationState.future.length === 0
  }
}
