import React, { forwardRef } from 'react'
import MUITableRow from '@mui/material/TableRow'
import { TableRowProps } from './types'

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ children, ...props }, ref): React.ReactElement => {
    return (
      <MUITableRow ref={ref} {...props}>
        {children}
      </MUITableRow>
    )
  }
)
export default TableRow
