import React from 'react'
import { NotificationProps } from './types'
import { StyledNotificationContainer, StyleLabelContainer } from './styles'

const Notification = ({ variant, value, ...props }: NotificationProps): React.ReactElement => {
  return (
    <StyledNotificationContainer variant={variant} {...props}>
      <StyleLabelContainer variant="h5">{value}</StyleLabelContainer>
    </StyledNotificationContainer>
  )
}

export default Notification
