import { type CurrencyCodes } from 'shared/constants/currency'
import { type DetailedReportData } from './transform'
import { type BudgetOptimiserPreprocResp, type CurrencyConversionRatesResp } from 'shared/api/analytics'

const fittedFunc = (x: number, a: number, b: number): number => {
  return a * Math.pow(x, b)
}

const transformParamA = (a: number, b: number, exchangeRate: number): number => {
  const newA = a / Math.pow(exchangeRate, b)
  return newA
}

const conversionsFromSpendByClickFit = (
  dailySpend: number[],
  paramA: number,
  paramB: number,
  newConversionRate: number
): number[] => {
  const dailySpendNormed = dailySpend.map((spend) => spend)
  const dailyClicksNormed = dailySpendNormed.map((spendNormed) => fittedFunc(spendNormed, paramA, paramB))
  const clicks = dailyClicksNormed.map((clicksNormed) => clicksNormed)
  const conversions = clicks.map((clicks) => clicks * newConversionRate)
  return conversions
}

const conversionsFromSpendByImpressionFit = (
  dailySpend: number[],
  imprParamA: number,
  imprParamB: number,
  impressionConversionRate: number
): number[] => {
  const dailySpendNormed = dailySpend.map((spend) => spend)
  const dailyImpressionsNormed = dailySpendNormed.map((spendNormed) => fittedFunc(spendNormed, imprParamA, imprParamB))
  const impressions = dailyImpressionsNormed.map((impressionsNormed) => impressionsNormed)
  const conversions = impressions.map((impressions) => impressions * impressionConversionRate)
  return conversions
}

const conversionsFromSpendByClickAndImpressionFits = (
  dailySpend: number[],
  clickParamA: number,
  clickParamB: number,
  imprParamA: number,
  imprParamB: number,
  newClickConversionRate: number,
  newImpressionConversionRate: number
): number[] => {
  const clickConversions = conversionsFromSpendByClickFit(dailySpend, clickParamA, clickParamB, newClickConversionRate)
  const impressionConversions = conversionsFromSpendByImpressionFit(
    dailySpend,
    imprParamA,
    imprParamB,
    newImpressionConversionRate
  )
  const conversions = clickConversions.map((clickConversion, i) => clickConversion + impressionConversions[i])
  return conversions
}

export const getInputSpend = (
  preprocData: BudgetOptimiserPreprocResp[],
  meanEligibleAuctions: number,
  hasImpressionShare: boolean
): number[] => {
  let dataMaxSpend: number = 0
  let maxSpend: number = 0
  // this is the maximum spend that we trust, even if the variance in spend is large.
  // is set to be 1.5 times the mean spend. this should use the pre-computed value
  // from totalsMeta, but for now we recompute it here.
  let trustMaxTotalSpend: number = 0

  if (hasImpressionShare) {
    dataMaxSpend = Math.max(...preprocData.map((obj) => obj.spendEa)) * meanEligibleAuctions
    trustMaxTotalSpend = 1.5 * getNonZeroMean(preprocData.map((obj) => obj.spendEa)) * meanEligibleAuctions
  } else {
    dataMaxSpend = Math.max(...preprocData.map((obj) => obj.spend))
    trustMaxTotalSpend = 1.5 * getNonZeroMean(preprocData.map((obj) => obj.spend))
  }
  // for plotting we want to take the max of the two, since the optimal point can be at upper boundary
  // the bounds of the array is inclusive range [0, maxSpend]
  maxSpend = Math.max(dataMaxSpend, trustMaxTotalSpend)
  const spendPoints = 100
  const spend = Array.from(Array(spendPoints).keys()).map((i) => (i * maxSpend) / (spendPoints - 1))

  return spend
}

// this function filters out the non-zero spend data and takes the average
export const getNonZeroMean = (dailySpends: number[]): number => {
  const nonZeroSpends = dailySpends.filter((spend) => spend > 0)
  if (nonZeroSpends.length === 0) {
    return 0
  }
  const nonZeroMean = nonZeroSpends.reduce((a, b) => a + b, 0) / nonZeroSpends.length
  return nonZeroMean
}

const getImpressionScatterPlotDaily = (
  preprocData: BudgetOptimiserPreprocResp[],
  newImpressionConversionRate: number,
  newClickConversionRate: number,
  newOrderValue: number,
  targetType: string
): ReturnCurveData[] => {
  const spend = preprocData.map(({ spend }) => spend)
  const clicks = preprocData.map(({ clicks }) => clicks)
  const impressions = preprocData.map(({ impressions }) => impressions)
  const dates = preprocData.map(({ date }) => date)

  // Scale spend, impression conversions, and click conversions by days with clicks
  const scaledSpend = spend.map((spend) => spend)
  const scaledImpressionConversions = impressions.map((impressions) => impressions * newImpressionConversionRate)
  const scaledClickConversions = clicks.map((clicks) => clicks * newClickConversionRate)

  // Calculate total conversions by adding scaled impression conversions and scaled click conversions
  const scaledTotalConversions = scaledImpressionConversions.map(
    (scaledImpressionConversion, i) => scaledImpressionConversion + scaledClickConversions[i]
  )

  const dateObjects = dates.map((dateString) => new Date(dateString))
  const minDate = new Date(Math.min(...dateObjects.map((date) => date.getTime())))
  const maxDate = new Date(Math.max(...dateObjects.map((date) => date.getTime())))

  // Generate scatter plot based on target type
  const scatterPlot = scaledSpend
    .map((scaledSpend, i) => {
      const y = targetType === 'revenue' ? scaledTotalConversions[i] * newOrderValue : scaledTotalConversions[i]

      const date = new Date(dates[i])
      const weekday = date.getDay() >= 1 && date.getDay() <= 5 ? 'weekday' : 'weekend'
      const olderToNewerValue = (date.getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime())
      return { x: scaledSpend, y, label: weekday, olderToNewerValue, date }
    })
    .filter((obj) => obj.y !== 0)

  return scatterPlot
}

const getSearchScatterPlotDaily = (
  preprocData: BudgetOptimiserPreprocResp[],
  newConversionRate: number,
  newOrderValue: number,
  meanEligibleAuctions: number,
  targetType: string
): ReturnCurveData[] => {
  // Extract spend, clicks, and conversions data
  const spend = preprocData.map(({ spendEa }) => spendEa)
  const clicks = preprocData.map(({ clicksEa }) => clicksEa)
  const dates = preprocData.map(({ date }) => date)

  // Generate scatter plot based on target type
  const scaledSpend = spend.map((spend, i) => {
    return { x: spend, y: clicks[i], date: dates[i] }
  })

  const dateObjects = dates.map((dateString) => new Date(dateString))
  const minDate = new Date(Math.min(...dateObjects.map((date) => date.getTime())))
  const maxDate = new Date(Math.max(...dateObjects.map((date) => date.getTime())))

  const scatterPlot = scaledSpend
    .map((obj, i) => {
      const y =
        targetType === 'revenue'
          ? obj.y * meanEligibleAuctions * newConversionRate * newOrderValue
          : obj.y * meanEligibleAuctions * newConversionRate

      const date: Date = new Date(dates[i])
      const weekday: string = date.getDay() >= 1 && date.getDay() <= 5 ? 'weekday' : 'weekend'
      const olderToNewerValue: number = (date.getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime())

      return { x: obj.x * meanEligibleAuctions, y, label: weekday, olderToNewerValue, date }
    })
    .filter((obj) => obj.y !== 0)

  return scatterPlot
}

const conversionsForSpendClickCurve = (
  dailySpend: number[],
  newConversionRate: number,
  paramA: number,
  paramB: number
): number[] => {
  const dailySpendNormed = dailySpend.map((spend) => spend)
  const dailyClicksNormed = dailySpendNormed.map((spendNormed) => fittedFunc(spendNormed, paramA, paramB))
  const clicks = dailyClicksNormed.map((clicksNormed) => clicksNormed)
  const conversions = clicks.map((clicks) => clicks * newConversionRate)
  return conversions
}

const conversionsFromSpendSearch = (
  dailySpend: number[],
  paramA: number,
  paramB: number,
  meanEligibleAuctions: number,
  newConversionRate: number
): number[] => {
  const norm = meanEligibleAuctions
  const dailySpendNormed = dailySpend.map((spend) => spend / norm)
  const dailyClicksNormed = dailySpendNormed.map((spendNormed) => fittedFunc(spendNormed, paramA, paramB))
  const clicks = dailyClicksNormed.map((clicksNormed) => clicksNormed * norm)
  const conversions = clicks.map((clicks) => clicks * newConversionRate)
  return conversions
}

interface LineFitData {
  x: number
  y: number
  confidenceInterval: [number, number]
}

export interface ReturnCurveData {
  x: number
  y: number
  label: string
  date: Date
  [key: string]: number | string | Date | undefined
}

export interface CompleteReturnCurveData {
  lineFitData: LineFitData[]
  scatterPlotData: ReturnCurveData[]
  currentSpend: number
  optimalSpend: number
  currentRevenue: number
  optimalRevenue: number
  currentConversions: number
  optimalConversions: number
  targetType: string
}

export const getReturnCurve = (
  preprocData: BudgetOptimiserPreprocResp[],
  detailedReport: DetailedReportData,
  targetType: string,
  conversionRatesData: CurrencyConversionRatesResp[],
  sourceCurrencyCode: string | null,
  targetCurrencyCode: string | null
): CompleteReturnCurveData => {
  let { paramA, imprParamA } = detailedReport

  const {
    paramB,
    imprParamB,
    meanEligibleAuctions,
    daysWClicks: daysWithClicks,
    newConversionRate,
    newClickConversionRate,
    newImpressionConversionRate,
    newOrderValue,
    confidenceFactor: revenueConfidenceFactor,
    confidenceFactor: conversionsConfidenceFactor,
    hasImpressionShare,
    isImpressionCampaign
  } = detailedReport

  if (targetCurrencyCode !== undefined || targetCurrencyCode !== null) {
    const sourceRate: CurrencyConversionRatesResp | undefined = conversionRatesData.find(
      (rate) => rate.targetCurrency === sourceCurrencyCode
    )
    const targetRate: CurrencyConversionRatesResp | undefined = conversionRatesData.find(
      (rate) => rate.targetCurrency === targetCurrencyCode
    )

    if (sourceRate == null || targetRate == null) {
      throw new Error(`Unable to find exchange rate for ${sourceCurrencyCode ?? ''} to ${targetCurrencyCode ?? ''}`)
    }

    const exchangeRate = targetRate.exchangeRate / sourceRate.exchangeRate

    paramA = transformParamA(paramA, paramB, exchangeRate)
    imprParamA = transformParamA(imprParamA, imprParamB, exchangeRate)
  }

  const {
    optSpend: optimalSpend,
    optConversions: optimalConversions,
    spend: currentSpend,
    conversions: currentConversions,
    revenue: currentRevenue,
    optRevenue
  } = detailedReport

  let inputSpend: number[] = []
  let scatterPlot: ReturnCurveData[] = []
  let conversions: number[] = []
  let revenueFromConversions: number[] = []

  if (hasImpressionShare) {
    inputSpend = getInputSpend(preprocData, meanEligibleAuctions, hasImpressionShare)

    scatterPlot = getSearchScatterPlotDaily(
      preprocData,
      newConversionRate,
      newOrderValue,
      meanEligibleAuctions,
      targetType
    )

    conversions = conversionsFromSpendSearch(inputSpend, paramA, paramB, meanEligibleAuctions, newConversionRate)

    revenueFromConversions = conversions.map((conversions) => conversions * newOrderValue)
  } else if (isImpressionCampaign) {
    inputSpend = getInputSpend(preprocData, meanEligibleAuctions, hasImpressionShare)

    scatterPlot = getImpressionScatterPlotDaily(
      preprocData,
      newImpressionConversionRate,
      newClickConversionRate,
      newOrderValue,
      targetType
    )

    conversions = conversionsFromSpendByClickAndImpressionFits(
      inputSpend,
      paramA,
      paramB,
      imprParamA,
      imprParamB,
      newClickConversionRate,
      newImpressionConversionRate
    )

    revenueFromConversions = conversions.map((conversions) => conversions * newOrderValue)
  } else {
    inputSpend = getInputSpend(preprocData, meanEligibleAuctions, hasImpressionShare)

    scatterPlot = getImpressionScatterPlotDaily(
      preprocData,
      newImpressionConversionRate,
      newClickConversionRate,
      newOrderValue,
      targetType
    )

    conversions = conversionsForSpendClickCurve(inputSpend, newConversionRate, paramA, paramB)
    revenueFromConversions = conversions.map((conversions) => conversions * newOrderValue)
  }

  const data = inputSpend
    .map((value, index) => {
      const y = targetType === 'revenue' ? revenueFromConversions[index] : conversions[index]
      return { x: value, y }
    })
    .sort((a, b) => a.x - b.x)

  const confidenceFactor = targetType === 'revenue' ? revenueConfidenceFactor : conversionsConfidenceFactor

  const withCF: LineFitData[] = data.map((item) => ({
    ...item,
    confidenceInterval: [Math.max(0, item.y * (1 - confidenceFactor)), Math.max(0, item.y * (1 + confidenceFactor))]
  }))

  const finalOutput = {
    lineFitData: withCF,
    scatterPlotData: scatterPlot,
    currentSpend: currentSpend / daysWithClicks,
    optimalSpend: optimalSpend / daysWithClicks,
    currentRevenue: currentRevenue / daysWithClicks,
    optimalRevenue: optRevenue / daysWithClicks,
    currentConversions: currentConversions / daysWithClicks,
    optimalConversions: optimalConversions / daysWithClicks,
    targetType
  }

  return finalOutput
}
export const transformPreprocDataToReturnCurveData = (
  preprocData: BudgetOptimiserPreprocResp[],
  rowData: DetailedReportData,
  conversionRatesData: CurrencyConversionRatesResp[],
  currencyCode: CurrencyCodes
): CompleteReturnCurveData | undefined => {
  if (preprocData.length > 0) {
    const targetCurrency = preprocData[0].targetCurrency
    const returnCurveData = getReturnCurve(
      preprocData,
      rowData,
      rowData.targetType,
      conversionRatesData,
      targetCurrency,
      currencyCode
    )
    return returnCurveData
  }
}
