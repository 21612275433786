import Toggle from '@mui/material/Switch'
import { styled } from '../../utils'
import { Grid } from '../index'
import { Theme, CSSObject } from '../theme'
import { DROPDOWN_ITEM_CLASS_NAME } from '../Dropdown/classNames'
import { listItemClasses } from '@mui/material'

export const StyledToggle = styled(Toggle)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    width: '58px',
    height: '36px',
    padding: 0,
    overflow: 'visible',
    '&:has( > .Mui-focusVisible)': {
      boxShadow: `${theme?.palette.main.blue50 ?? ''} 0px 0px 0px 3px`,
      borderRadius: theme?.borderRadius.medium
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&:hover': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme?.palette.neutrals.stone120
        }
      },
      '&:active': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme?.palette.neutrals.stone100
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none'
        }
      },
      '&.Mui-checked': {
        transform: 'translateX(22px)',
        color: theme?.palette.secondary.main,
        '& + .MuiSwitch-track': {
          backgroundColor: theme?.palette.primary.main,
          opacity: 1,
          border: 0
        },
        '&:hover': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme?.palette.main.blue90
          }
        },
        '&:active': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme?.palette.main.blue110
          },
          color: theme?.palette.neutrals.white0,
          '& > div': {
            backgroundColor: theme?.palette.main.blue90
          }
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 1
        }
      },
      '&.Mui-disabled': {
        color: theme?.palette.neutrals.stone120
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: theme?.palette.neutrals.stone90
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme?.palette.neutrals.stone100,
        opacity: 1
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):hover & .MuiSwitch-switchBase`]: {
      '& + .MuiSwitch-track': {
        backgroundColor: theme?.palette.neutrals.stone120
      },
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme?.palette.main.blue90
        }
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled).${listItemClasses.focusVisible} & .MuiSwitch-switchBase`]: {
      '& + .MuiSwitch-track': {
        backgroundColor: theme?.palette.neutrals.stone120
      },
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme?.palette.main.blue90
        }
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):active & .MuiSwitch-switchBase`]: {
      '& + .MuiSwitch-track': {
        backgroundColor: theme?.palette.neutrals.stone100
      },
      '& .MuiSwitch-thumb': {
        boxShadow: 'none'
      },
      '&.Mui-checked': {
        color: theme?.palette.neutrals.white0,
        '& > div': {
          backgroundColor: theme?.palette.main.blue90
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme?.palette.main.blue110
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: '32px',
      height: '32px',
      borderRadius: theme?.borderRadius.small,
      boxShadow: '0px 6px 12px rgba(35, 35, 50, 0.08)'
    },
    '& .MuiSwitch-track': {
      borderRadius: theme?.borderRadius.medium,
      backgroundColor: theme?.palette.neutrals.stone100,
      opacity: 1
    }
  })
)

export const StyledThumb = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    boxShadow: 'none',
    width: '32px',
    height: '32px',
    borderRadius: theme?.borderRadius.small,
    backgroundColor: theme?.palette.neutrals.white0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
)
