import { Frame2Props } from './types'
import {
  StyledContainer,
  StyledContentContainer,
  StyledSideContainer,
  StyleContent,
  StyledContentHeader,
  StyledSideArtworkContainer
} from './styles'
import Logo from '../Logo'
import { Artwork } from './assets/Artwork'

const Frame2 = ({ headerRight, children }: Frame2Props): React.ReactElement => {
  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledContentHeader>
          <Logo size="large" />
          {headerRight}
        </StyledContentHeader>
        <StyleContent>{children}</StyleContent>
      </StyledContentContainer>
      <StyledSideContainer>
        <StyledSideArtworkContainer>
          <Artwork />
        </StyledSideArtworkContainer>
      </StyledSideContainer>
    </StyledContainer>
  )
}
export default Frame2
