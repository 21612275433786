import React from 'react'

export const Documentation = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M19 14.422H20V13.422H19V14.422ZM4 16.9576C4 17.5099 4.44772 17.9576 5 17.9576C5.55228 17.9576 6 17.5099 6 16.9576H4ZM18 16.9576C18 17.5099 18.4477 17.9576 19 17.9576C19.5523 17.9576 20 17.5099 20 16.9576H18ZM10.4356 7.01746C9.88327 7.01746 9.43555 7.46517 9.43555 8.01746C9.43555 8.56974 9.88327 9.01746 10.4356 9.01746V7.01746ZM13.9254 9.01746C14.4777 9.01746 14.9254 8.56974 14.9254 8.01746C14.9254 7.46517 14.4777 7.01746 13.9254 7.01746V9.01746ZM8.06911 15.422H19V13.422H8.06911V15.422ZM18 14.422V18.6667H20V14.422H18ZM17.5328 19H8.06911V21H17.5328V19ZM8.06911 19C6.83416 19 6 18.1111 6 17.211H4C4 19.3915 5.91402 21 8.06911 21V19ZM18 18.6667C18 18.7259 17.9757 18.7986 17.8977 18.8694C17.8182 18.9417 17.6919 19 17.5328 19V21C18.8032 21 20 20.0432 20 18.6667H18ZM8.06911 13.422C5.91402 13.422 4 15.0305 4 17.211H6C6 16.3109 6.83416 15.422 8.06911 15.422V13.422ZM6 16.9576V6.66666H4V16.9576H6ZM7.9345 5H17.5328V3H7.9345V5ZM18 5.33333V16.9576H20V5.33333H18ZM10.4356 9.01746H13.9254V7.01746H10.4356V9.01746ZM17.5328 5C17.6919 5 17.8182 5.05835 17.8977 5.1306C17.9757 5.20142 18 5.27411 18 5.33333H20C20 3.95676 18.8032 3 17.5328 3V5ZM6 6.66666C6 5.8341 6.77389 5 7.9345 5V3C5.85375 3 4 4.55371 4 6.66666H6Z"
        fill="currentColor"
      />
    </svg>
  )
})
