import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQuery,
  useQueryClient,
  type UseQueryResult
} from 'react-query'
import { type fetch, precisApps } from 'shared/api'
import { createPrecisAppConversation, type CreatePrecisAppConversationReq } from 'shared/api/precisApps'

export const queryKeys = {
  precisApp: 'precisApp',
  precisApps: 'precisApps'
}

export const useQueryPrecisApps = (
  clientId: string,
  enabled: boolean = true
): UseQueryResult<precisApps.PrecisApps, fetch.ClientError> => {
  return useQuery({
    queryKey: [queryKeys.precisApps, clientId.toString()],
    queryFn: async () => {
      const returnedApps = await precisApps.getPrecisApps(clientId)
      return returnedApps.filter((app) => {
        return app.releaseType !== 'prototype' || app.clientMetadata?.hasPrototypeAccess
      })
    },
    enabled: clientId.toString() !== '' && enabled
  })
}

export const useQueryPrecisApp = (
  clientId: string,
  precisAppId: string
): UseQueryResult<precisApps.PrecisAppResp, fetch.ClientError> => {
  return useQuery({
    queryKey: [queryKeys.precisApp, clientId, precisAppId],
    queryFn: async () => await precisApps.getPrecisApp(clientId, precisAppId),
    enabled: clientId !== ''
  })
}

export const useUpdateClientPrecisAppRelation = (
  options?: UseMutationOptions<
    precisApps.PrecisAppRelationship,
    fetch.ClientError,
    precisApps.UpdateClientPrecisAppRelationReq
  >
): UseMutationResult<
  precisApps.PrecisAppRelationship,
  fetch.ClientError,
  precisApps.UpdateClientPrecisAppRelationReq
> => {
  const queryClient = useQueryClient()
  return useMutation(precisApps.updateClientPrecisAppRelation, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries([queryKeys.precisApp, variables.clientId, variables.precisAppId])

      queryClient.setQueryData(
        [queryKeys.precisApps, variables.clientId],
        (oldData: precisApps.PrecisApps | undefined) => {
          if (oldData == null) {
            return []
          }

          return oldData.map((app) => {
            if (app.id === data.precisAppId) {
              return {
                ...app,
                clientMetadata: data
              }
            }

            return app
          })
        }
      )

      if (options?.onSuccess != null) {
        void options?.onSuccess(data, variables, context)
      }
    }
  })
}

export const useUpdatePrecisApp = (
  options?: UseMutationOptions<precisApps.PrecisAppResp, fetch.ClientError, precisApps.UpdatePrecisAppReq>
): UseMutationResult<precisApps.PrecisAppResp, fetch.ClientError, precisApps.UpdatePrecisAppReq> => {
  const queryClient = useQueryClient()
  return useMutation(precisApps.updatePrecisApp, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries([queryKeys.precisApp])

      if (options?.onSuccess != null) {
        void options?.onSuccess(data, variables, context)
      }
    }
  })
}

export const useMutationCreateConversation = (): UseMutationResult<
  string,
  fetch.ClientError,
  CreatePrecisAppConversationReq
> => {
  const queryClient = useQueryClient()
  return useMutation(async (data: CreatePrecisAppConversationReq) => await createPrecisAppConversation(data), {
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.precisApp])
    }
  })
}
