import React, { forwardRef } from 'react'
import { ListSubheader } from '@mui/material'
import { SelectInputListSubHeaderProps } from './types'

export const SelectInputListSubHeader = forwardRef<HTMLLIElement, SelectInputListSubHeaderProps>(
  ({ children, ...props }, ref): React.ReactElement => {
    return (
      <ListSubheader ref={ref} {...props}>
        {children}
      </ListSubheader>
    )
  }
)
