import { useRouter } from 'next/router'
import { useEffect, type ReactElement } from 'react'
import ErrUnExpected from './Error/UnExpected'

export interface FallbackProps {
  error: Error | null | undefined
  resetError: () => void
  hasDashboardFrame?: boolean
}

export const ErrorFallback = function ({ error, resetError, hasDashboardFrame = false }: FallbackProps): ReactElement {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', resetError)

    return () => {
      router.events.off('routeChangeComplete', resetError)
    }
  }, [router.events, resetError])

  return <ErrUnExpected error={error} hasDashboardFrame={hasDashboardFrame} />
}
