import { Button, Grid, Input, PopUp, Typography, styled } from '@precis-digital/kurama'
import { useState, type ReactElement } from 'react'
import { type DashboardSheetDataSource } from 'reportingSolution/constants'
import { getDashboardLink, getReportTemplateById } from 'reportingSolution/utils'
import { type StandardReportResp } from 'shared/api/standardReports'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'

export const createDashboardLinkHandler = (selectedConfig: StandardReportResp): void => {
  const reportTemplate = getReportTemplateById(selectedConfig.templateId)
  const hasSheetsSources = (reportTemplate?.dashboard?.sheets_datasources?.length ?? 0) > 0

  const openDashboardLink = (sheetsLinks: string[]): void => {
    const dashboardLink = getDashboardLink(selectedConfig, sheetsLinks)
    if (dashboardLink != null) {
      window.open(dashboardLink.toString(), '_blank')
    }
  }

  const sheetsLinks: string[] = []
  if (hasSheetsSources) {
    void OverScreen.show('inputSheetsLinkPopup', {
      sheetsSources: reportTemplate?.dashboard?.sheets_datasources ?? [],
      onConfirm: (sheetsLinks: string[]): void => {
        openDashboardLink(sheetsLinks)
      }
    })
  } else {
    openDashboardLink(sheetsLinks)
  }
}

interface InputSheetsLinkPopupProps {
  sheetsSources: DashboardSheetDataSource[]
  onConfirm: (sheetsLinks: string[]) => void
}

export const InputSheetsLinkPopup = OverScreen.create(
  ({ sheetsSources, onConfirm }: InputSheetsLinkPopupProps): ReactElement => {
    const screen = useOverScreen('inputSheetsLinkPopup')
    const { t } = useTranslation('reportingSolutions')
    const [sheetsInputs, setSheetsInputs] = useState<string[]>(sheetsSources.map(() => ''))
    const [hasErrors, setHasErrors] = useState<boolean>(false)

    const validateSheetsInputs = (sheetsInputs: string[]): boolean => {
      let hasErrorsLocal = false
      sheetsInputs.forEach((sheetsInput, index) => {
        if (sheetsInput === '') {
          hasErrorsLocal = true
        }
      })

      setHasErrors(hasErrorsLocal)

      return !hasErrorsLocal
    }

    return (
      <PopUp
        title={t('popups.createDashboardPopup.title', { suffix: sheetsSources.length > 0 ? 's' : '' })}
        open={screen.visible}
        handleOpen={(): void => {
          screen.remove()
        }}
      >
        <Grid container flexDirection="column" width="100%" gap="16px">
          <Typography variant="body2">{t('popups.createDashboardPopup.subTitle')}</Typography>
          <Grid width="100%">
            {sheetsSources.map((sheetsSource, index) => (
              <Grid container flexDirection="column" key={sheetsSource.alias}>
                <Input
                  labelText={t('popups.createDashboardPopup.inputLabel', {
                    suffix: sheetsSources.length > 1 ? '#' + String(index + 1) : ''
                  })}
                  name={sheetsSource.alias}
                  value={sheetsInputs[index] ?? ''}
                  onChange={(event) => {
                    const newSheetsInputs = [...sheetsInputs]
                    newSheetsInputs[index] = event.target.value
                    setSheetsInputs(newSheetsInputs)
                    setHasErrors(false)
                  }}
                  fullWidth
                />
                <Typography variant="body3">{sheetsSource.helperText}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container alignItems="flex-end" marginTop="16px" flexDirection="column" gap="4px">
            <StyledButtonConfirm
              onClick={() => {
                if (validateSheetsInputs(sheetsInputs)) {
                  onConfirm(sheetsInputs)
                }
              }}
              variant="filled"
            >
              {t('popups.createDashboardPopup.submitButton')}
            </StyledButtonConfirm>
            <StyledTypographyRed variant="body3">
              {hasErrors && t('popups.createDashboardPopup.errorText')}
            </StyledTypographyRed>
          </Grid>
        </Grid>
      </PopUp>
    )
  }
)

const StyledButtonConfirm = styled(Button)(() => ({
  alignSelf: 'flex-end'
}))

const StyledTypographyRed = styled(Typography)(({ theme }) => ({
  color: theme.palette.semantic.error100
}))
