import Grid from '../Grid'
import { Theme } from '../theme'
import { styled } from '../../utils'
import { InputState } from './types'
import Button from '../Button'

export const StyledGridContainer = styled(Grid)(
  ({ theme, state, width, hasError }: { theme?: Theme; state: InputState; width: string; hasError: boolean }) => ({
    padding: '12px 12px 12px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme?.borderRadius.xLarge,
    outline: hasError ? `1px solid ${theme?.palette.semantic.error100 as string}` : 'none',
    width,
    boxShadow: state === 'focused' ? theme?.elevation.mediumDepth : 'none',
    backgroundColor: state === 'enabled' ? theme?.palette.neutrals.stone90 : theme?.palette.neutrals.white0,
    ':hover': {
      backgroundColor: state === 'enabled' ? theme?.palette.neutrals.stone100 : undefined,
      cursor: state === 'enabled' ? 'pointer' : 'default'
    },
    transition: 'box-shadow 100ms ease-in-out',
    zIndex: 1
  })
)

export const StyledButtonAction = styled(Button)(({ theme }: { theme?: Theme }) => ({
  zIndex: 1301,
  alignSelf: 'center',
  padding: '8px'
}))
