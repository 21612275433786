import React, { useState, useMemo } from 'react'
import {
  Typography,
  Grid,
  styled,
  Table,
  TableBody,
  TableBodyCell,
  TableHeaderCell,
  TableRow,
  TableHead,
  SortDescendingIcon,
  SortAscendingIcon,
  SingleSelectDropdown
} from '@precis-digital/kurama'
import { useTable, useSortBy, type Row, useGlobalFilter, type Column } from 'react-table'
import { MemberCard } from 'workspace/components/WorkspaceDetails/MemberCard'
import { type MembersResp, type Member, useQueryMembers } from 'workspace/api'
import Dots from 'shared/components/Loader/Dots'
import { capitalize } from 'shared/utils/stringFormatter'
import { useAuth, type User } from 'shared/context/AuthContext'
import { type MemberRole } from 'shared/api/clients'
import { equals, toLower } from 'ramda'
import { useTranslation } from 'shared/translations'

interface MembersListProps {
  clientId: string
}
interface MembersListContainerProps {
  members?: MembersResp
  currentUser?: User
}
const userRoleFilterOptions: Record<string, MemberRole | string> = {
  all: 'all_permissions',
  viewer: 'viewer',
  editor: 'editor',
  admin: 'admin'
}

const tableColumns = [
  {
    Header: 'Name',
    id: 'name',
    accessor: (originalRow: Member) => originalRow,
    sortType: (rowA: Row<Member>, rowB: Row<Member>): number => {
      return rowA.original.name.localeCompare(rowB.original.name)
    }
  }
]

const filterByRole = (rows: Array<Row<Member>>, columnIds: string[], globalFilterValue: string): Array<Row<Member>> => {
  const result = rows.filter((row) => {
    const role = toLower(row.original.role)
    const filter = toLower(globalFilterValue)
    return equals(role, filter)
  })
  if (globalFilterValue === userRoleFilterOptions.all) return rows
  return result.length > 0 ? result : []
}

export const MembersTableContainer = ({ clientId }: MembersListProps): React.ReactElement => {
  const { currentUser } = useAuth()
  const { data: members, isLoading: isLoadingMembers } = useQueryMembers(clientId)
  if (isLoadingMembers) {
    return (
      <Grid container>
        <Dots />
      </Grid>
    )
  }
  return <MembersTable members={members} currentUser={currentUser} />
}

const MembersTable = ({ members, currentUser }: MembersListContainerProps): React.ReactElement => {
  const [permissionValue, setPermissionValue] = useState<string>('all_permissions')

  const { t } = useTranslation('workspace')

  const handlePermissionsfilter = (value: string): void => {
    setGlobalFilter(value)
    setPermissionValue(value)
  }
  const columns: Array<Column<Member>> = useMemo(() => tableColumns, [])
  const data = useMemo(() => (members?.length !== undefined ? members : []), [members])
  const globalFilter = useMemo(() => filterByRole, [])

  const initialState = { sortBy: [{ id: 'name', desc: false }] }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    { columns, data, globalFilter, initialState },
    useGlobalFilter,
    useSortBy
  )

  const adminsRole = members?.filter((item) => item.role.toLocaleLowerCase() === 'admin')
  const shouldShowMoreActions: boolean = members?.length === 1 && currentUser?.id === members[0].id

  return (
    <>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
            return (
              <TableRow {...restHeaderGroup} key={key}>
                {headerGroup.headers.map((column) => {
                  const { key } = column.getHeaderProps()
                  return (
                    <StyledMembersListTableHeaderCell key={key}>
                      <StyledMembersListTableHeaderColumnContainer
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <Typography variant="h4"> {column.render('Header')} </Typography>{' '}
                        <StyledMembersListIcon>
                          {column.isSorted ? (
                            column.isSortedDesc === true ? (
                              <SortAscendingIcon />
                            ) : (
                              <SortDescendingIcon />
                            )
                          ) : (
                            <Grid width="16px" height="24px" />
                          )}
                        </StyledMembersListIcon>
                      </StyledMembersListTableHeaderColumnContainer>
                      <StyledMembersListTableHeaderActions>
                        <Typography variant="body2"> {t('descriptions.permissions')}</Typography>
                        <SingleSelectDropdown
                          title={t('titles.permissionsFilterTitle')}
                          buttonTitle={t('titles.permissionsFilterAll')}
                          value={permissionValue}
                          onSelect={handlePermissionsfilter}
                          options={Object.keys(userRoleFilterOptions).map((role) => ({
                            label: capitalize(role),
                            value: userRoleFilterOptions[role]
                          }))}
                        />
                      </StyledMembersListTableHeaderActions>
                    </StyledMembersListTableHeaderCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row)
              const { key, ...resetRowProps } = row.getRowProps()
              return (
                <TableRow {...resetRowProps} key={key}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <TableBodyCell {...restCellProps} key={key}>
                        <MemberCard
                          member={cell.value}
                          shouldShowMoreActions={shouldShowMoreActions}
                          currentUser={currentUser}
                          adminsRole={adminsRole}
                        />
                      </TableBodyCell>
                    )
                  })}
                </TableRow>
              )
            })
          ) : (
            <Typography variant="body1"> {t('descriptions.noMembersFound')}</Typography>
          )}
        </TableBody>
      </Table>
    </>
  )
}

const StyledMembersListTableHeaderCell = styled(TableHeaderCell)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
  gap: 24,
  div: {
    ':first-of-type': {
      display: 'inline-flex'
    },
    ':last-child': {
      flexGrow: 0
    }
  }
}))

const StyledMembersListTableHeaderColumnContainer = styled(Grid)(() => ({
  width: 'fit-content'
}))

const StyledMembersListIcon = styled(Grid)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer'
}))
const StyledMembersListTableHeaderActions = styled(Grid)(() => ({
  display: 'inline-flex',
  gap: 16,
  p: {
    lineHeight: '40px'
  },
  '>div': {
    width: '136px'
  }
}))
export default MembersTableContainer
