import React from 'react'

export const AttributionModels = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <g clipPath="url(#clip0_12_782)">
        <path
          d="M11.9 20C16.3183 20 19.9 16.4183 19.9 12C19.9 7.58172 16.3183 4 11.9 4C7.48172 4 3.89999 7.58172 3.89999 12C3.89999 16.4183 7.48172 20 11.9 20Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M7.89999 5.46002V11.23"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path d="M11.9 7.25V11.23" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <path
          d="M15.9 5.46002V11.23"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_782">
          <rect width="18" height="18" fill="white" transform="translate(2.89999 3)" />
        </clipPath>
      </defs>
    </svg>
  )
})
