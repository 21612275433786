import { styled, alpha } from '../../utils'
import Grid from '../Grid'
import { Modal as MuiModal } from '@mui/material'
import { Theme, CSSObject } from '../theme'
import Button from '../Button'

export const StyledPopUpBackdrop = styled(MuiModal)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    overflowY: 'auto',
    paddingTop: '5%',
    paddingBottom: '5%',
    '.MuiBackdrop-root': {
      backgroundColor: alpha(`${theme?.palette.graphs.steel100 ?? '#9AB3BA'}`, 0.5)
    }
  })
)

export const StyledPopUp = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    width: '640px',
    backgroundColor: `${theme?.palette.neutrals.white0 ?? '#FFFFFF'}`,
    border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
    margin: 'auto',
    borderRadius: `${theme?.borderRadius.xxLarge ?? '32px'}`,
    boxShadow: `${theme?.elevation.largeDepth ?? '0px 16px 32px rgba(112, 144, 176, 0.2)'}`,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px',
    gap: '4px',
    outline: 'none',
    zIndex: theme?.zIndex.modal
  })
)

export const StyledCloseButton = styled(Button)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    alignSelf: 'end',
    h4: {
      fontSize: theme?.typography.body2.fontSize
    }
  })
)

export const StyledContentBox = styled(Grid)(() => ({
  padding: '16px',
  width: '100%'
}))
