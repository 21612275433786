import React from 'react'

export const Plug = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M20 12L20 16C20 18.2091 18.2091 20 16 20V20C13.7909 20 12 18.2091 12 16L12 14.8582"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7 6.64692L17 6.64692M9.49842 4V6.64789M14.5034 4V6.64789M8.01981 6.66092V10.6282C8.01981 11.25 8.27437 11.8442 8.72323 12.27L9.48038 12.9884C10.1653 13.6382 11.0699 14 12.0098 14C12.9587 14 13.8712 13.6313 14.5581 12.9705L15.2876 12.2685C15.7296 11.8433 15.9797 11.2538 15.9797 10.6375V6.66092H8.01981Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
})
