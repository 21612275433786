import React, { type ReactElement } from 'react'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'

interface TableRowLinkProps {
  href: string
  children: React.ReactNode
}

const TableRowLink = ({ href, children }: TableRowLinkProps): ReactElement => {
  return <UnStyledRetainDefaultQueryLink href={href}>{children}</UnStyledRetainDefaultQueryLink>
}

export default TableRowLink
