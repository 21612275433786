import React, { forwardRef } from 'react'
import MUITableHead from '@mui/material/TableHead'
import { TableHeadProps } from './types'

export const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  ({ children, ...props }, ref): React.ReactElement => {
    return (
      <MUITableHead ref={ref} {...props}>
        {children}
      </MUITableHead>
    )
  }
)
export default TableHead
