import React from 'react'

export const Load = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M7.12203 13.3874C7.00223 12.8483 6.46805 12.5084 5.92892 12.6282C5.38979 12.748 5.04985 13.2822 5.16966 13.8213L7.12203 13.3874ZM8.36236 17.1444L8.98384 16.3609L8.36236 17.1444ZM12.3138 18.4973L12.3247 19.4973L12.3138 18.4973ZM16.235 17.0588L15.5966 16.2891L16.235 17.0588ZM19.3547 13.6671C19.4627 13.1255 19.1113 12.5988 18.5696 12.4908C18.028 12.3827 17.5014 12.7342 17.3933 13.2758L19.3547 13.6671ZM8.37415 15.4787C8.89243 15.6695 9.46726 15.404 9.65807 14.8858C9.84889 14.3675 9.58343 13.7927 9.06515 13.6018L8.37415 15.4787ZM6.12924 13.5865L6.47474 12.6481C6.2251 12.5562 5.94914 12.5676 5.70789 12.6796C5.46664 12.7917 5.27999 12.9953 5.18923 13.2454L6.12924 13.5865ZM4.24749 15.8402C4.05908 16.3593 4.32719 16.9329 4.84634 17.1213C5.36549 17.3098 5.93909 17.0416 6.12751 16.5225L4.24749 15.8402ZM5.16966 13.8213C5.53037 15.4445 6.43818 16.8944 7.74088 17.9278L8.98384 16.3609C8.04056 15.6127 7.38322 14.5628 7.12203 13.3874L5.16966 13.8213ZM7.74088 17.9278C9.04359 18.9612 10.662 19.5153 12.3247 19.4973L12.303 17.4974C11.099 17.5105 9.92713 17.1092 8.98384 16.3609L7.74088 17.9278ZM12.3247 19.4973C13.9874 19.4792 15.5934 18.89 16.8733 17.8285L15.5966 16.2891C14.6698 17.0577 13.5069 17.4843 12.303 17.4974L12.3247 19.4973ZM16.8733 17.8285C18.1532 16.767 19.0293 15.2978 19.3547 13.6671L17.3933 13.2758C17.1577 14.4566 16.5234 15.5204 15.5966 16.2891L16.8733 17.8285ZM9.06515 13.6018L6.47474 12.6481L5.78374 14.525L8.37415 15.4787L9.06515 13.6018ZM5.18923 13.2454L4.24749 15.8402L6.12751 16.5225L7.06924 13.9277L5.18923 13.2454Z"
        fill="currentColor"
      />
      <path
        d="M17.3335 10.6124C17.4533 11.1516 17.9875 11.4915 18.5266 11.3717C19.0657 11.2519 19.4057 10.7177 19.2859 10.1786L17.3335 10.6124ZM16.0932 6.85551L15.4717 7.63894L16.0932 6.85551ZM12.1417 5.50257L12.1308 4.50263L12.1417 5.50257ZM8.22058 6.9411L8.85894 7.71083L8.85894 7.71083L8.22058 6.9411ZM5.10087 10.3328C4.99281 10.8744 5.34428 11.4011 5.88589 11.5091C6.4275 11.6172 6.95416 11.2657 7.06222 10.7241L5.10087 10.3328ZM16.0924 8.55319C15.5729 8.36571 14.9998 8.63485 14.8123 9.15434C14.6248 9.67383 14.894 10.2469 15.4135 10.4344L16.0924 8.55319ZM18.3494 10.4309L18.0099 11.3715C18.2602 11.4618 18.536 11.4487 18.7766 11.3351C19.0171 11.2214 19.2024 11.0166 19.2916 10.766L18.3494 10.4309ZM20.2167 8.16522C20.4017 7.64487 20.1299 7.07301 19.6096 6.88793C19.0892 6.70285 18.5174 6.97463 18.3323 7.49498L20.2167 8.16522ZM19.2859 10.1786C18.9252 8.55536 18.0174 7.1055 16.7146 6.07208L15.4717 7.63894C16.415 8.38723 17.0723 9.43707 17.3335 10.6124L19.2859 10.1786ZM16.7146 6.07208C15.4119 5.03867 13.7936 4.48455 12.1308 4.50263L12.1526 6.50251C13.3565 6.48942 14.5284 6.89066 15.4717 7.63894L16.7146 6.07208ZM12.1308 4.50263C10.4681 4.5207 8.86214 5.10988 7.58221 6.17136L8.85894 7.71083C9.78573 6.94221 10.9486 6.5156 12.1526 6.50251L12.1308 4.50263ZM7.58221 6.17136C6.30228 7.23285 5.4262 8.70211 5.10087 10.3328L7.06222 10.7241C7.29779 9.54333 7.93215 8.47945 8.85894 7.71083L7.58221 6.17136ZM15.4135 10.4344L18.0099 11.3715L18.6889 9.49027L16.0924 8.55319L15.4135 10.4344ZM19.2916 10.766L20.2167 8.16522L18.3323 7.49498L17.4072 10.0958L19.2916 10.766Z"
        fill="currentColor"
      />
    </svg>
  )
})
