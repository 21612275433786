import React from 'react'
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  YAxisProps,
  ScatterChart as RechartsScatterChart,
  Scatter,
  Cell,
  Tooltip
} from 'recharts'
import { CustomTooltipProps, ScatterChartProps } from './types'
import { useTheme } from '@mui/material/styles'

const ScatterChart = ({
  containerWidth = '100%',
  containerHeight = '100%',
  data,
  xAxis,
  yAxis,
  getConfigByDataKey,
  getDotColor,
  renderTooltip,
  ...rest
}: ScatterChartProps): React.ReactElement => {
  const theme = useTheme()

  const defaultXAxisProps = {
    tickLine: false,
    axisLine: { stroke: theme.palette.neutrals.stone100 },
    tick: { fontWeight: theme.typography.fontWeightBold },
    stroke: theme.palette.secondary.main
  }

  const defaultYAxisProps: YAxisProps = {
    orientation: 'right',
    axisLine: false,
    tickLine: false,
    tick: { textAnchor: 'end' },
    stroke: theme.palette.secondary.main
  }

  const defaultYLabelProps = {
    position: 'top',
    offset: 15,
    stroke: theme.palette.secondary.main,
    style: { textAnchor: 'end' }
  }

  const yDataKeys = Object.keys(data[0]).filter((key) => key !== 'x')

  const CustomTooltip = ({ active = false, payload }: CustomTooltipProps): React.ReactElement | null => {
    if (active && payload != null && payload?.length > 0 && renderTooltip != null) {
      return renderTooltip({ dataKey: payload[0].dataKey, payload: payload[0].payload })
    }

    return null
  }

  return (
    <ResponsiveContainer width={containerWidth} height={containerHeight}>
      <RechartsScatterChart data={data} {...rest}>
        <XAxis
          dataKey="x"
          {...{
            ...defaultXAxisProps,
            ...xAxis
          }}
        />
        <YAxis
          {...{
            ...defaultYAxisProps,
            ...yAxis,
            label: {
              ...defaultYLabelProps,
              ...yAxis?.label
            }
          }}
        />
        {yDataKeys.map((key, index) => (
          <Scatter key={index} dataKey={key} {...getConfigByDataKey?.(key)}>
            {data.map((entry, index) => {
              const fillColor = getDotColor?.(entry)
              return <Cell key={`cell-${index}`} {...(fillColor != null && { fill: fillColor })} />
            })}
          </Scatter>
        ))}
        <Tooltip content={<CustomTooltip />} />
      </RechartsScatterChart>
    </ResponsiveContainer>
  )
}
export default ScatterChart
