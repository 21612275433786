import React from 'react'

export const Cells = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M8.00009 4L12.0002 6.2837V10.8511L8.00009 13.1348L4 10.8511V6.2837L8.00009 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11.9995 10.8652L15.9996 13.1489V17.7163L11.9995 20L7.99945 17.7163V13.1489L11.9995 10.8652Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M16 4L20.0001 6.2837V10.8511L16 13.1348L11.9999 10.8511V6.2837L16 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
})
