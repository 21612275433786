import { Grid, PauseIcon, PlayIcon, Tooltip, styled } from '@precis-digital/kurama'
import { TextCell, TitleWithIconCell } from 'shared/components/ListView'
import { type ListViewCellProps, type ListViewColumnDefinition } from 'shared/components/ListView/types'
import React, { useMemo, type ReactElement } from 'react'
import Image from 'next/image'
import { useQueryClientAccounts } from '../api'
import { useTranslation } from 'shared/translations'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryMember } from 'workspace/api'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'
import { type ClientAccountsResp } from 'shared/api/accounts'
import { DATASOURCE_DOCUMENTATION_LINK } from 'shared/constants/links'
import { capitalize } from 'shared/utils'
import { OverScreen } from 'shared/overScreens/niceModalReact'
import { PLATFORMS } from 'dataSource/constants'
import MoreMenuDropDown from './MoreMenuDropDown'
import { useClientServiceAccountEmail } from 'shared/hooks/useClientServiceAccountEmail'
import Start from 'shared/components/Start'
import StatusWithBadge from 'shared/components/StatusWithBadge'
import { getUsersTimeZone, parseDateToUTC } from 'shared/dateFns'

export interface ClientAccountsWithMetadata extends ClientAccountsResp {
  connectionStatus: 'connected' | 'disconnected'
  recommendationEngine: 'enabled' | 'paused' | 'not_supported'
}

const StartPage = (): ReactElement => {
  const { t } = useTranslation('dataSource')

  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()

  const { data: member, isLoading: isMemberLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const {
    data: clientAccounts = [],
    isLoading,
    isSuccess: isClientAccountsSuccess
  } = useQueryClientAccounts(currentClient.id)
  const processedClientAccounts: ClientAccountsWithMetadata[] = useMemo(() => {
    return clientAccounts.map((account) => {
      const platformMeta = PLATFORMS.find((platform) => platform.platform === account.platform)

      let recommendationEngineStatus: ClientAccountsWithMetadata['recommendationEngine']
      if (platformMeta?.supportsRecommendationsEngine === true) {
        if (account.recommendationsEngineActive) {
          recommendationEngineStatus = 'enabled'
        } else {
          recommendationEngineStatus = 'paused'
        }
      } else {
        recommendationEngineStatus = 'not_supported'
      }

      return {
        ...account,
        connectionStatus: account.connected ? 'connected' : 'disconnected',
        recommendationEngine: recommendationEngineStatus
      }
    })
  }, [clientAccounts])

  const hasEditorAccess = useHasAccess('editor', member?.role ?? 'viewer')

  const columns: Array<ListViewColumnDefinition<ClientAccountsWithMetadata>> = useMemo(
    () => [
      {
        Header: t('listTable.headings.dataSource'),
        accessor: 'name',
        Cell: (props: ListViewCellProps<ClientAccountsWithMetadata>) => {
          const sourceMeta = getPlatformDetailsByPlatform(props.row.original.platform)
          return (
            <TitleWithIconCell
              title={props.row.original.name ?? ''}
              subTitle={props.row.original.externalAccountId}
              platformMeta={sourceMeta}
              {...props}
            />
          )
        }
      },
      {
        Header: t('listTable.headings.credentials'),
        accessor: 'credentialsId',
        Cell: ({ row, ...cellProps }: ListViewCellProps<ClientAccountsWithMetadata>) => {
          const clientServiceAccount = useClientServiceAccountEmail()
          const { credentialsId, credentialsName } = row.original
          const isServiceAccountAuthenticated = credentialsId === undefined
          const text = isServiceAccountAuthenticated ? t('listTable.row.alvieServiceAccount') : credentialsName
          const annotation = isServiceAccountAuthenticated ? clientServiceAccount : credentialsId

          return <TextCell text={text} annotation={annotation} row={row} {...cellProps} />
        },
        align: 'center'
      },
      {
        Header: t('listTable.headings.status'),
        accessor: 'connectionStatus',
        Cell: (props: ListViewCellProps<ClientAccountsWithMetadata>) => {
          const platformMeta = PLATFORMS.find((platform) => platform.platform === props.row.original.platform)
          const status = props.row.original.connectionStatus
          const timeZone = getUsersTimeZone()
          const date = props.row.original.updatedAt
          const dateInLocalTimeZone = parseDateToUTC(date, timeZone)

          return (
            <StyledStatusCell>
              <Grid>
                <StatusWithBadge status={status} lastUpdated={dateInLocalTimeZone} />
                {platformMeta?.supportsRecommendationsEngine === true &&
                !props.row.original.recommendationsEngineActive ? (
                  <StyledPauseIconContainer>
                    <Tooltip kind="singleline" title={t(`listTable.row.recommendationsDisabled`)} body={undefined}>
                      <StyledPauseIcon />
                    </Tooltip>
                    <StyledBackgroundDiv />
                  </StyledPauseIconContainer>
                ) : null}
              </Grid>
            </StyledStatusCell>
          )
        },
        align: 'right',
        width: '140px'
      },
      {
        accessor: 'platform'
      },
      {
        accessor: 'recommendationEngine'
      },
      {
        accessor: 'updatedAt'
      }
    ],
    [t]
  )

  return (
    <Start
      emptyStateProps={{
        title: t('emptyState.title'),
        subtitle1: t('emptyState.description'),
        subtitle2: t('emptyState.description2'),
        educationalBlockProps: {
          title: t('emptyState.educationalBlock.title'),
          description: t('emptyState.educationalBlock.description'),
          documentationLinkHref: DATASOURCE_DOCUMENTATION_LINK,
          documentationLinkText: t('emptyState.educationalBlock.linkText')
        },
        createNewButtonProps: {
          label: t('connectButton'),
          hasEditorAccess,
          onClick: () => {
            void OverScreen.show('connectNewDataSourcePopup')
          },
          tooltipTitle: t('noAccess.tooltipTitle')
        }
      }}
      listViewProps={{
        isLoading: isLoading || isMemberLoading || !isClientAccountsSuccess,
        title: t('titles.connectDataSource'),
        createNewButtonProps: {
          label: t('connectButton'),
          isLoading: isMemberLoading,
          hasEditorAccess,
          onClick: () => {
            void OverScreen.show('connectNewDataSourcePopup')
          },
          tooltipTitle: t('noAccess.tooltipTitle')
        },
        persistFilters: true,
        filters: [
          {
            type: 'multi-select',
            accessor: 'platform',
            options: 'distinct_values',
            title: t('filters.selectPlatforms'),
            valueTranslator: (value) => {
              const platformMeta = getPlatformDetailsByPlatform(value)
              const platformLabel = platformMeta?.label ?? capitalize(value)
              return {
                label: platformLabel,
                value,
                icon: <Image width={24} height={24} src={platformMeta?.iconUrl ?? ''} alt={`${platformLabel} icon`} />
              }
            },
            noFilterLabel: t('filters.allPlatforms')
          },
          {
            type: 'multi-select',
            accessor: 'recommendationEngine',
            urlKey: 'recommendationSetting',
            options: [
              { label: t('filters.enabled'), value: 'enabled', icon: <PlayIcon /> },
              { label: t('filters.paused'), value: 'paused', icon: <PauseIcon /> }
            ],
            title: t('filters.selectRecommendationSettings'),
            noFilterLabel: t('filters.allRecommendationSettings'),
            buttonWidth: 'fit-content'
          }
        ],
        tabs: {
          accessor: 'connectionStatus',
          orderedOptions: ['connected', 'disconnected'],
          allOptionLabel: t('filters.allStatuses'),
          otherOptionLabel: t('filters.other')
        },

        tableProps: {
          data: processedClientAccounts ?? [],
          columns,
          includePagination: true,
          initialState: {
            pageIndex: 0,
            sortBy: [
              { id: 'connectionStatus', desc: true },
              { id: 'updatedAt', desc: true }
            ],
            hiddenColumns: ['platform', 'recommendationEngine', 'updatedAt']
          },
          renderMoreMenu: (row: ClientAccountsWithMetadata, closeMenu: () => void) => {
            return <MoreMenuDropDown clientAccount={row} hasEditorAccess={hasEditorAccess} closeMenu={closeMenu} />
          }
        }
      }}
    />
  )
}

const StyledStatusCell = styled(Grid)(({ theme }) => ({
  '>div': {
    position: 'relative',
    backgroundColor: theme.palette.neutrals.stone100,
    borderRadius: '16px'
  }
}))
const StyledPauseIconContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20px',
  width: '20px',
  position: 'absolute',
  top: '-8px',
  right: '-6px'
}))

const StyledPauseIcon = styled(PauseIcon)(() => ({
  zIndex: 1
}))

const StyledBackgroundDiv = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: '8px',
  width: '16px',
  height: '16px',
  position: 'absolute'
}))

export default StartPage
