import React from 'react'

export const Placeholder = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M11.9999 20C16.4182 20 20 16.4182 20 11.9999C20 7.58153 16.4182 3.99976 11.9999 3.99976C7.58153 3.99976 3.99976 7.58153 3.99976 11.9999C3.99976 16.4182 7.58153 20 11.9999 20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
