import { Notification, Tab, Tabs, Typography } from '@precis-digital/kurama'
import { MAIN_FORM_VIEW_TABS } from 'channelGrouping/constants'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import React, { type SyntheticEvent, useState, type ReactElement, useEffect } from 'react'
import { useChangePath } from 'shared/components/Router'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'

interface TabsRowProps {
  tabId: string
}

const TabsRow = ({ tabId }: TabsRowProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const { changePath } = useChangePath()
  const { channelGroupingId, channelsMap, dataSourcesMap, usedInConfigs } = useCurrentChannelGrouping()

  const [tabIndex, setTabIndex] = useState(MAIN_FORM_VIEW_TABS.indexOf(tabId))

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number): void => {
    void changePath(
      `${APP_ROUTES.customGroupings.editConfigPage({ channelGroupingId: channelGroupingId.toString() })}/${
        MAIN_FORM_VIEW_TABS[newValue]
      }`,
      undefined,
      true
    )
    setTabIndex(newValue)
  }

  useEffect(() => {
    setTabIndex(MAIN_FORM_VIEW_TABS.indexOf(tabId))
  }, [tabId])

  return (
    <Tabs currentTab={tabIndex} handleChange={handleTabChange} variant="standard">
      <Tab value={0} adornmentPosition="right">
        <Typography variant="h5">{t('mainFormView.tabs.data-sources')}</Typography>
        <Notification
          variant="tonal"
          value={Object.values(dataSourcesMap)
            .filter(
              (dataSourceItem) => dataSourceItem.channels.length > 0 && dataSourceItem.connectedDataSources.length > 0
            )
            .length.toString()}
        />
      </Tab>
      <Tab value={1} adornmentPosition="right">
        <Typography variant="h5">{t('mainFormView.tabs.channels')}</Typography>
        <Notification variant="tonal" value={Object.keys(channelsMap).length.toString()} />
      </Tab>
      <Tab value={2}>
        <Typography variant="h5">{t('mainFormView.tabs.used-in')}</Typography>
        <Notification variant="tonal" value={usedInConfigs.length.toString()} />
      </Tab>
    </Tabs>
  )
}

export default TabsRow
