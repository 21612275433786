import { useGate, type GateResult } from 'statsig-react'
import { FeatureFlagProvider as provider } from './provider'

type Flags = 'attribution-rba'

// Feature flags represent feature gates in statsig.com
export const FEATURE_FLAGS: Record<string, Flags> = {
  attributionRba: 'attribution-rba'
}

export const FeatureFlagProvider = provider

export const useFeatureFlag = (flag: Flags): GateResult => useGate(flag)
