import { ROARR, Roarr, type Logger } from 'roarr'
import { get } from 'shared/lstore'
import createSerializeErrorMiddleware from '@roarr/middleware-serialize-error'

let canWrite: string | null = '*'

if (typeof window !== 'undefined') {
  canWrite = get('debug')
}

ROARR.write = (message) => {
  if (canWrite != null) {
    // eslint-disable-next-line
    console.log(JSON.parse(message))
  }
}

export default function log(): Logger {
  return Roarr.child({
    application: 'alvie'
  }).child({
    middleware: [createSerializeErrorMiddleware()]
  })
}
