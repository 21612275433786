import React from 'react'

export const UnLock = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M15.9652 6.64818C15.3978 5.10703 13.841 4.00003 12.0093 4.00003C9.6985 3.99138 7.81712 5.74528 7.80701 7.91863V7.93593V9.79275"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5131 19.9999H8.4869C6.56138 19.9999 5 18.5314 5 16.7196V13.0112C5 11.1993 6.56138 9.7308 8.4869 9.7308H15.5131C17.4386 9.7308 19 11.1993 19 13.0112V16.7196C19 18.5314 17.4386 19.9999 15.5131 19.9999Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0005 13.9048V15.8265"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
