import { StyledPopUp, StyledPopUpBackdrop, StyledContentBox, StyledCloseButton } from './styles'
import Grid from '../Grid'
import Typography from '../Typography'
import { CancelBkgIcon } from '../Icons'
import { PopupProps } from './types'
import { forwardRef } from 'react'

const PopUp = forwardRef<HTMLDivElement, PopupProps>(
  ({ handleOpen, open = false, children, title, ...props }, ref): React.ReactElement => {
    return (
      <StyledPopUpBackdrop
        open={open}
        {...props}
        ref={ref}
        onClose={(e, reason) => {
          const mouseEvent = e as React.MouseEvent<HTMLButtonElement, MouseEvent>
          handleOpen(mouseEvent, reason)
        }}
      >
        <StyledPopUp>
          <Grid container justifyContent="space-between" alignItems="flex-start">
            {title == null || typeof title === 'string' ? (
              <Typography variant="h2">{title === undefined ? '' : title}</Typography>
            ) : (
              title
            )}
            <StyledCloseButton
              rightIcon={<CancelBkgIcon />}
              onClick={(e) => handleOpen(e, 'closeButtonClick')}
              scheme="light"
              variant="text"
            >
              Cancel
            </StyledCloseButton>
          </Grid>
          <StyledContentBox>{children}</StyledContentBox>
        </StyledPopUp>
      </StyledPopUpBackdrop>
    )
  }
)

export default PopUp
