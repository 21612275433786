import toCamelCase from 'camelcase-keys'
export const capitalize = (name: string): string => name[0]?.toUpperCase() + name.slice(1)

export const ensureAbsoluteUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return url
  }
  return `https://${url}`
}

export const convertSnakeCaseToSentenceCase = (text: string): string =>
  text
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ')

export const snakeCaseToTitleCase = (str: string): string => {
  const words = str.split(/[_-]/)
  const titleCase = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

  return titleCase
}

export const camelCaseToTitleCase = (str: string): string => {
  const result = str.replace(/([A-Z])|(\d+)/g, ' $&')
  return result.charAt(0).toUpperCase() + result.slice(1).trim()
}

export const convertSnakeCaseToCamelCase = (text: string): string =>
  Object.keys(toCamelCase({ [text.toLowerCase()]: true }))[0]

/**
 * Function to convert a list of strings into a sentence. For example, ['bananas, 'apples', 'oranges'] will be converted to 'bananas, apples and oranges'
 * @param list list of strings to concat into a sentence. For example ['bananas, 'apples', 'oranges']
 * @returns string that is a sentence. For example 'bananas, apples and oranges'
 */
export const convertListOfStringsToSentence = (list: string[]): string => {
  if (list.length === 1) {
    return list[0]
  }
  return `${list.slice(0, -1).join(', ')} and ${list.slice(-1)[0]}`
}

/**
 * Formats a page title based on the provided parameters.
 * @param {object} params - The parameters object.
 * @param {string} params.pageTitle - The main page name.
 * @param {string} params.subPageTitle - The optional sub-page name.
 * @returns {string} The formatted page title.
 */
export const formatPageMetaTitle = ({
  subPageTitle,
  pageTitle
}: {
  pageTitle: string
  subPageTitle?: string
}): string => {
  let formattedTitle = ''

  if (subPageTitle != null && subPageTitle !== '') {
    formattedTitle += `${subPageTitle} | `
  }

  formattedTitle += `${pageTitle} | Alvie`
  return formattedTitle.trim()
}

/**
 * Removes leading and trailing whitespace and newline characters from a string.
 *
 * @param {string} string - The string to be cleaned.
 * @returns {string} The cleaned string with no leading or trailing whitespace or newline characters.
 */
export const trimWhitespace = (string: string): string => string.trim()
