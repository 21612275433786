// Source of truth for all permissions.
// Combine this shared/components/Role.tsx to create a declarative way to handle
// permissions in code.
// eg. <Role authorizedRoles={permissions.DELETE_WORKSPACE}> </Role>
// jsdoc example:

enum permissions {
  DELETE_WORKSPACE = 'DELETE_WORKSPACE',
  REMOVE_ADMIN_FROM_WORKSPACE = 'REMOVE_ADMIN_FROM_WORKSPACE',
  REMOVE_EDITOR_FROM_WORKSPACE = 'REMOVE_EDITOR_FROM_WORKSPACE',
  REMOVE_VIEWER_FROM_WORKSPACE = 'REMOVE_VIEWER_FROM_WORKSPACE',
  EDIT_PROFILE_WORKSPACE = 'EDIT_PROFILE_WORKSPACE',
  ASSIGN_AS_ADMIN_WORKSPACE = 'ASSIGN_AS_ADMIN_WORKSPACE',
  ASSIGN_AS_EDITOR_WORKSPACE = 'ASSIGN_AS_EDITOR_WORKSPACE',
  ASSIGN_AS_VIEWER_WORKSPACE = 'ASSIGN_AS_VIEWER_WORKSPACE',
  INVITE_MEMBERS_WORKSPACE = 'INVITE_MEMBERS_WORKSPACE',
  REVOKE_PENDING_INVITATIONS_WORKSPACE = 'REVOKE_PENDING_INVITATIONS_WORKSPACE',
  VIEW_PENDING_INVITATIONS_WORKSPACE = 'VIEW_PENDING_INVITATIONS_WORKSPACE',
  CREATE_EDIT_CONNECTOR = 'CREATE_EDIT_CONNECTOR',
  CREATE_EDIT_REPORTING_SOLUTION = 'CREATE_EDIT_REPORTING_SOLUTION',
  CREATE_EDIT_ENHANCED_SIGNALS = 'CREATE_EDIT_ENHANCED_SIGNALS',
  VIEW_WORKSPACE_DETAILS = 'VIEW_WORKSPACE_DETAILS',
  VIEW_EDITOR_FLYOUT = 'VIEW_EDITOR_FLYOUT',
  VIEW_ADMIN_FLYOUT = 'VIEW_ADMIN_FLYOUT',
  VIEW_CONNECTOR = 'VIEW_CONNECTOR',
  VIEW_REPORTING_SOLUTION = 'VIEW_REPORTING_SOLUTION',
  VIEW_ENHANCED_SIGNALS = 'VIEW_ENHANCED_SIGNALS',
  ENTER_PREREQUISITE_PAGE = 'ENTER_PREREQUISITE_PAGE'
}
export enum roles {
  VIEWER = 'viewer',
  EDITOR = 'editor',
  ADMIN = 'admin'
}

const PERMISSIONS: Record<permissions, roles[]> = {
  [permissions.DELETE_WORKSPACE]: [roles.ADMIN],
  [permissions.REMOVE_ADMIN_FROM_WORKSPACE]: [roles.ADMIN],
  [permissions.REMOVE_EDITOR_FROM_WORKSPACE]: [roles.EDITOR, roles.ADMIN],
  [permissions.REMOVE_VIEWER_FROM_WORKSPACE]: [roles.EDITOR, roles.ADMIN],
  [permissions.EDIT_PROFILE_WORKSPACE]: [roles.ADMIN],
  [permissions.ENTER_PREREQUISITE_PAGE]: [roles.EDITOR, roles.ADMIN],
  [permissions.VIEW_WORKSPACE_DETAILS]: [roles.ADMIN],
  [permissions.ASSIGN_AS_ADMIN_WORKSPACE]: [roles.ADMIN],
  [permissions.ASSIGN_AS_EDITOR_WORKSPACE]: [roles.ADMIN, roles.EDITOR],
  [permissions.ASSIGN_AS_VIEWER_WORKSPACE]: [roles.VIEWER, roles.EDITOR, roles.ADMIN],
  [permissions.INVITE_MEMBERS_WORKSPACE]: [roles.EDITOR, roles.ADMIN],
  [permissions.REVOKE_PENDING_INVITATIONS_WORKSPACE]: [roles.EDITOR, roles.ADMIN],
  [permissions.VIEW_PENDING_INVITATIONS_WORKSPACE]: [roles.ADMIN],
  [permissions.VIEW_CONNECTOR]: [roles.VIEWER, roles.EDITOR, roles.ADMIN],
  [permissions.CREATE_EDIT_CONNECTOR]: [roles.EDITOR, roles.ADMIN],
  [permissions.CREATE_EDIT_REPORTING_SOLUTION]: [roles.EDITOR, roles.ADMIN],
  [permissions.VIEW_EDITOR_FLYOUT]: [roles.EDITOR],
  [permissions.VIEW_ADMIN_FLYOUT]: [roles.ADMIN],
  [permissions.VIEW_REPORTING_SOLUTION]: [roles.VIEWER, roles.EDITOR, roles.ADMIN],
  [permissions.CREATE_EDIT_ENHANCED_SIGNALS]: [roles.EDITOR, roles.ADMIN],
  [permissions.VIEW_ENHANCED_SIGNALS]: [roles.VIEWER, roles.EDITOR, roles.ADMIN]
}

export default PERMISSIONS
