import { useQueryChannelGroupings } from 'channelGrouping/api'
import { getMatchingUsedInConfigs, getUniqueDataSources } from 'channelGrouping/utils'
import React, { useMemo, type ReactElement } from 'react'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'
import { MemberTimestampCell, TextCell, TitleWithAvatarsCell } from 'shared/components/ListView'
import { type ListViewCellProps, type ListViewColumnDefinition } from 'shared/components/ListView/types'
import { CUSTOM_GROUPING_DOCUMENTATION_LINK } from 'shared/constants/links'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { useTranslation } from 'shared/translations'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

import { useQueryMember } from 'workspace/api'
import { useOverScreen } from 'shared/overScreens/niceModalReact'
import { DEFAULT_MAIN_FORM_VIEW_TAB } from 'channelGrouping/constants'
import MoreMenuDropdown from './MoreMenuDropdown'
import { APP_ROUTES } from 'shared/routes'
import { useQueryClientAccounts } from 'dataSource/api'
import { platformsSupportedCustomGrouping } from 'shared/components/Prerequisite/constants'
import { useMember } from 'workspace'
import { hasAccountsWithSupportedPlatforms, hasPreRequisites } from 'shared/components/Prerequisite/utils'
import TableRowLink from 'shared/components/ListView/Table/TableRowLink'
import { type RowLinkProps } from 'shared/components/ListView/Table'
import Start from 'shared/components/Start'
import { useQueryIntegratedAttributions } from 'attributionModel/api'
import { useQueryBudgetOptimisers } from 'budgetOptimiser/api'
import { type UsedInConfig } from 'channelGrouping/types'

const ChannelGroupingRowLink = ({ row, children }: RowLinkProps<ChannelGroupingResp>): ReactElement => {
  return (
    <TableRowLink
      href={`${APP_ROUTES.customGroupings.editConfigPage({
        channelGroupingId: row.id.toString()
      })}/${DEFAULT_MAIN_FORM_VIEW_TAB}`}
    >
      {children}
    </TableRowLink>
  )
}

const StartPage = (): ReactElement => {
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('channelGrouping')
  const createNewChannelGroupingPopupOverscreen = useOverScreen('createNewChannelGroupingPopup')

  const { data: member, isLoading: isMemberLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const hasEditorAccess = useHasAccess('editor', member?.role ?? 'viewer')
  const { data: channelGroupings, isLoading: isLoadingChannelGroupings } = useQueryChannelGroupings(currentClient.id)

  const { data: clientAccount, isLoading: isLoadingClientAccounts } = useQueryClientAccounts(currentClient.id)
  const { data: attributionConfigs, isLoading: isLoadingAttributionConfigs } = useQueryIntegratedAttributions(
    currentClient.id
  )
  const { data: budgetOptimisers, isLoading: isLoadingBudgetOptimisers } = useQueryBudgetOptimisers(currentClient.id)

  const { role: memberRole } = useMember()

  const { hasAllPreRequisites, hasDataSourceAccount } = hasPreRequisites({
    accounts: hasAccountsWithSupportedPlatforms(clientAccount, platformsSupportedCustomGrouping)
  })

  const usedInConfigsMap: Record<number, UsedInConfig[]> | null = useMemo(() => {
    if (attributionConfigs == null || budgetOptimisers == null || channelGroupings == null) return null

    const usedInConfigsMap: Record<number, UsedInConfig[]> = {}

    channelGroupings.forEach((channelGrouping) => {
      const usedInConfigs = getMatchingUsedInConfigs({
        channelGrouping,
        attributionConfigs,
        budgetOptimisers
      })

      usedInConfigsMap[channelGrouping.id] = usedInConfigs
    })

    return usedInConfigsMap
  }, [attributionConfigs, budgetOptimisers, channelGroupings])

  const columns: Array<ListViewColumnDefinition<ChannelGroupingResp>> = useMemo(
    () => [
      {
        Header: t('listView.headings.name'),
        accessor: 'name',
        Cell: (props: ListViewCellProps<ChannelGroupingResp>) => {
          const uniqueDataSources = getUniqueDataSources(props.row.original.channelGrouping)
          const avatars = uniqueDataSources.map((dataSource) => {
            const dataSourceMeta = getPlatformDetailsByPlatform(dataSource)
            return { name: dataSourceMeta?.label ?? dataSource, imageUrl: dataSourceMeta?.iconUrl }
          })
          return <TitleWithAvatarsCell title={props.row.original.name} avatars={avatars} {...props} />
        },
        width: '50%'
      },
      {
        Header: t('listView.headings.lastUpdated'),
        accessor: 'updatedAt',
        Cell: (props: ListViewCellProps<ChannelGroupingResp>) => (
          <MemberTimestampCell
            timestamp={props.row.original.updatedAt}
            memberId={props.row.original.updatedBy}
            {...props}
          />
        ),
        align: 'center'
      },
      {
        Header: t('listView.headings.usedIn'),
        accessor: 'usedIn',
        Cell: (props: ListViewCellProps<ChannelGroupingResp>) => {
          const usedInConfigs = usedInConfigsMap?.[props.row.original.id]
          const usedInConfigsCount = usedInConfigs == null ? 0 : usedInConfigs.length
          return (
            <TextCell
              text={t('listView.usedIn.otherModels', {
                count: usedInConfigsCount,
                suffix: usedInConfigsCount === 1 ? '' : 's'
              })}
              {...props}
            />
          )
        },
        align: 'right'
      }
    ],
    [t, usedInConfigsMap]
  )

  const isLoadingData =
    isLoadingChannelGroupings ||
    isMemberLoading ||
    isLoadingAttributionConfigs ||
    isLoadingBudgetOptimisers ||
    usedInConfigsMap == null

  const isLoadingPreRequisites = isLoadingClientAccounts

  return (
    <Start
      hasAllPreRequisites={hasAllPreRequisites}
      noPreRequisitesProps={{
        memberRole,
        t,
        hasDataSourceAccount
      }}
      emptyStateProps={{
        title: t('listView.emptyState.header'),
        subtitle1: t('listView.emptyState.description'),
        subtitle2: t('listView.emptyState.description2'),
        educationalBlockProps: {
          title: t('listView.emptyState.educationalBlock.title'),
          description: t('listView.emptyState.educationalBlock.description'),
          documentationLinkHref: CUSTOM_GROUPING_DOCUMENTATION_LINK,
          documentationLinkText: t('listView.emptyState.educationalBlock.linkText')
        },
        createNewButtonProps: {
          label: t('listView.createNewButton'),
          hasEditorAccess,
          onClick: () => {
            void createNewChannelGroupingPopupOverscreen.show()
          }
        }
      }}
      listViewProps={{
        isLoading: isLoadingData,
        title: t('titles.listView'),
        createNewButtonProps: {
          label: t('listView.createNewButton'),
          isLoading: isMemberLoading,
          hasEditorAccess,
          isLoadingPreRequisites,
          onClick: () => {
            void createNewChannelGroupingPopupOverscreen.show()
          }
        },
        tableProps: {
          data: channelGroupings ?? [],
          columns,
          includePagination: true,
          initialState: {
            pageIndex: 0,
            sortBy: [{ id: 'updatedAt', desc: true }]
          },
          RowLink: ChannelGroupingRowLink,
          renderMoreMenu: (row, closeMenu) => {
            return <MoreMenuDropdown channelGrouping={row} closeMenu={closeMenu} />
          }
        }
      }}
    />
  )
}

export default StartPage
