import { Avatar, Button, Card, CheckIcon, Divider, Grid, Link, styled, Typography } from '@precis-digital/kurama'
import { useRef } from 'react'
import { useResizeObserver } from 'shared/hooks/useResizeObserver'
import { useTranslation } from 'shared/translations'

interface IConnectedCardProps {
  title: string
  description: string
  imageUrl: string
  connected: boolean
  handleClick: () => void
  channelGrouping?: boolean
  cardLink?: string
}

const ConnectedCard = (props: IConnectedCardProps): React.ReactElement => {
  const { t } = useTranslation('common')
  const cardRef = useRef<HTMLDivElement>(null)

  const { width } = useResizeObserver(cardRef)

  const truncateText = (text: string): string => {
    const maxChars = (width * 2) / 7 - (props.cardLink != null ? 10 : 0)
    if (text.length <= maxChars) {
      return text
    }
    return text.substring(0, maxChars - 3) + '...'
  }

  return (
    <StyledCard connected={props.connected} ref={cardRef}>
      <Avatar imageUrl={props.imageUrl} kind="image" size="medium" />
      <StyledTypographyLineClamped variant="h4" title={props.title}>
        {props.title}
      </StyledTypographyLineClamped>
      <StyledTypography variant="body3" title={props.description}>
        {truncateText(props.description)}{' '}
        {props.cardLink !== undefined ? (
          <Link variant="body3" href={props.cardLink} target="_blank" rel="noreferrer">
            {t('learnMore')}
          </Link>
        ) : null}
      </StyledTypography>
      <Divider />
      <Grid display="flex" alignItems="center" justifyContent="center" width="100%">
        {props.connected ? (
          <Button fullWidth disabled onClick={() => {}} scheme="light" variant="filled" rightIcon={<CheckIcon />}>
            {props.channelGrouping !== undefined ? t('noPrerequisites.created') : t('noPrerequisites.connected')}
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={() => {
              props.handleClick()
            }}
            scheme="light"
            variant="filled"
          >
            {props.channelGrouping !== undefined ? t('noPrerequisites.create') : t('noPrerequisites.connect')}
          </Button>
        )}
      </Grid>
    </StyledCard>
  )
}

export default ConnectedCard

const StyledCard = styled(Card)<{ connected: boolean }>(({ theme, connected }) => ({
  backgroundColor: connected ? theme.palette.neutrals.stone100 : theme.palette.neutrals.white0,
  width: '100%',
  maxWidth: '50%',
  height: '244px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: connected ? 'none' : '0px 6px 12px rgba(112, 144, 176, 0.12)'
}))

const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center'
}))

const StyledTypographyLineClamped = styled(Typography)(() => ({
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '1',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))
