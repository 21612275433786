import { CLIENT_NAME } from 'auth/constants'
import { APP_ROUTES } from 'shared/routes'

export const getValueFromPathName = (url: string, searchKey: string): string | null => {
  const urlSearchParams = new URLSearchParams(url)
  return urlSearchParams.get(searchKey) ?? null
}

export const getWorkspaceNameFromUrl = (returnTo: string): string | null => {
  const url = returnTo?.split('?') ?? []
  if (url.length === 0 || url[0] !== APP_ROUTES.workspace.joinWorkspacePage) {
    return null
  }
  return getValueFromPathName(`?${url[1]}`, CLIENT_NAME)
}
