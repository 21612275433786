import React, { forwardRef } from 'react'
import { StyledProgress } from './Styles'
import { ProgressProps } from './Types'
import { LinearProgress } from '@mui/material'

export const Progress = forwardRef<HTMLDivElement, ProgressProps>(
  (
    { value, variant = 'determinate', indicatorColor, orientation = 'horizontal', ...props },
    ref
  ): React.ReactElement => {
    const minValueError = 0
    const maxValueError = 25
    const minValueWarning = 26
    const maxValueWarning = 50
    const errorColorValue = value >= minValueError && value <= maxValueError
    const warningColorValue = value >= minValueWarning && value <= maxValueWarning
    const progressColorIndicator = errorColorValue ? 'error' : warningColorValue ? 'warning' : 'success'

    return (
      <StyledProgress
        orientation={orientation}
        value={value}
        indicatorColor={indicatorColor ?? progressColorIndicator}
        {...props}
        ref={ref}
      >
        <LinearProgress variant={variant} value={value} />
      </StyledProgress>
    )
  }
)

export default Progress
