import { Flyout } from '@precis-digital/kurama'
import { groupBy } from 'ramda'
import { getReportTemplateById } from 'reportingSolution/utils'
import { type ReportTemplateId } from 'shared/api/standardReports'
import SwitchFlyout, { type Options } from 'shared/components/SwitchFlyout'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { sortReportsByParameters } from './utils'

export interface ReportConfig {
  id: number | string
  subtitle?: string
  name: string
  templateId?: ReportTemplateId
  isSelected?: boolean
}

interface SwithConfigProps {
  configs: ReportConfig[]
  onSelected: (config: ReportConfig) => void
}

const SwichConfigFlyout = ({ configs, onSelected }: SwithConfigProps): React.ReactElement => {
  const screen = useOverScreen('switchReportHomeFlyout')
  const { t } = useTranslation('home')

  configs.sort(sortReportsByParameters)

  const mappedConfigs = configs.map((config) => {
    return {
      id: config.id,
      name: config.name,
      subtitle: config.subtitle,
      type: config.templateId != null ? getReportTemplateById(config.templateId)?.label : config.templateId,
      isSelected: config.isSelected
    }
  })

  const groupReportsByType = (options: Options): Record<string, Options> =>
    groupBy((report) => report.type ?? '', options)

  const handleFiltering = (searchInput: string, options: Options): Options => {
    if (searchInput === '') return options
    return options?.filter((card) => card.name.toLowerCase().match(searchInput.toLowerCase()))
  }

  return (
    <Flyout
      onClose={(): void => {
        screen.remove()
      }}
      isExpanded={screen.visible}
    >
      <SwitchFlyout
        title={t('switchReport.title')}
        options={mappedConfigs}
        groupByParameter={groupReportsByType}
        searchPlaceholder={t('switchReport.placeholder')}
        onClickOption={onSelected}
        onFiltering={handleFiltering}
      />
    </Flyout>
  )
}

export default OverScreen.create(SwichConfigFlyout)
