import { Card, Typography, Avatar, styled, Grid, Toggle, SelectInput, Chip } from '@precis-digital/kurama'
import MenuItem from '@mui/material/MenuItem'

import { type IAccountsList, type IPricingPlan } from 'dataSource/components/Popup/AccountSelectionPopup'
import { type IPlatform } from 'dataSource/constants'
import { useTranslation } from 'react-i18next'
import { type Dispatch, type SetStateAction } from 'react'
import * as React from 'react'

interface DataSourcePlateToggleProps {
  isAccountSelected?: boolean
  isConnected?: boolean
  isDisabled?: boolean
}

interface AccountPlateProps {
  account: IAccountsList
  has360?: boolean
  externalAccountId?: string
  isAccountSelected?: boolean
  handleSelectAccount: (accountId: string) => void
  dataSource: IPlatform
  pricingPlans: IPricingPlan[]
  setPricingPlans: Dispatch<SetStateAction<IPricingPlan[]>>
  disabled?: boolean
}

export type SelectChangeEvent<T = string> =
  | (Event & { target: { value: T; name: string } })
  | React.ChangeEvent<HTMLInputElement>

export const AccountPlate = ({
  account,
  isAccountSelected,
  handleSelectAccount,
  dataSource,
  pricingPlans,
  setPricingPlans,
  disabled = false
}: AccountPlateProps): React.ReactElement => {
  const { t } = useTranslation('dataSource')
  const isAnalyticsPlatformSelected: boolean | undefined = dataSource.platform === 'analytics' && isAccountSelected
  const selectedPricingPlan = pricingPlans.findIndex((item) => item.id === account.id)
  const isConnectedAccount = account.connected
  const pricingPlan =
    account.has360 === true
      ? t('popups.accountSelection.paidPricingPlan')
      : t('popups.accountSelection.freePricingPlan')
  const displayNameOrName = account.displayName ?? account.name
  const externalAccountIdOrId = account.externalAccountId ?? account.id

  const handlePricingPlanChange = (e: SelectChangeEvent<unknown>): void => {
    const res = [...pricingPlans]
    const foundIndex = res.findIndex((element) => element.id === account.id)
    if (foundIndex === -1) {
      res.push({ id: account.id, pricingPlan: e.target.value as 'free' | 'paid' })
    } else {
      res[foundIndex] = { id: account.id, pricingPlan: e.target.value as 'free' | 'paid' }
    }
    setPricingPlans(res)
  }

  return (
    <StyledAccountPlate isAccountSelected={isAccountSelected} isConnected={account.connected} isDisabled={disabled}>
      <StyledAccountPlateContent>
        <Avatar size="medium" imageUrl={dataSource.iconUrl} kind="image" />
        <StyledAccountText>
          <Typography title={displayNameOrName} variant="h3">
            {displayNameOrName}
          </Typography>
          <Typography variant="body3" title={externalAccountIdOrId}>
            {externalAccountIdOrId}
          </Typography>
        </StyledAccountText>
        <StyledAccountToggle
          onChange={() => {
            handleSelectAccount(account.id)
          }}
          defaultChecked={false}
          checked={isAccountSelected ?? false}
          disabled={disabled}
        />
      </StyledAccountPlateContent>
      {isAnalyticsPlatformSelected === true && !isConnectedAccount && (
        <StyledSelectInputContainer>
          <SelectInput
            name="pricingPlan"
            onChange={handlePricingPlanChange}
            placeholder={t('popups.accountSelection.pricingPlanPlaceholder')}
            value={selectedPricingPlan === -1 ? 'none' : pricingPlans[selectedPricingPlan].pricingPlan}
            disabled={disabled}
          >
            <MenuItem disabled value="none">
              <Typography variant="body2" className="select-placeholder">
                {t('popups.accountSelection.pricingPlanPlaceholder')}
              </Typography>
            </MenuItem>
            <MenuItem value="free">{t('popups.accountSelection.freePricingPlan')}</MenuItem>
            <MenuItem value="paid">{t('popups.accountSelection.paidPricingPlan')}</MenuItem>
          </SelectInput>
        </StyledSelectInputContainer>
      )}
      {isAnalyticsPlatformSelected === true && Boolean(isConnectedAccount) && (
        <Chip key={pricingPlan} label={pricingPlan} />
      )}
    </StyledAccountPlate>
  )
}

export const StyledAccountPlate = styled(Card)<DataSourcePlateToggleProps>(
  ({ theme, isAccountSelected = false, isConnected = false, isDisabled = false }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 20,
    gap: 16,
    width: '100%',
    margin: 0,
    backgroundColor: isAccountSelected && !isDisabled ? 'initial' : theme?.palette.neutrals.stone90,
    pointerEvents: isConnected ? 'none' : 'auto',
    opacity: isConnected ? '0.5' : '1'
  })
)

export const StyledAccountPlateContent = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  gap: 24
}))
export const StyledAccountText = styled(Grid)(({ theme }) => ({
  width: '67%',
  '& span': {
    color: theme?.palette.neutrals.stone150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  '& h3': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

export const StyledAccountToggle = styled(Toggle)(() => ({
  marginLeft: 'auto'
}))

export const StyledSelectInputContainer = styled(Grid)(() => ({
  maxWidth: '100%',
  '& div': {
    maxWidth: '100%'
  }
}))

export default AccountPlate
