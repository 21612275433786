import { ReactElement } from 'react'
import 'reactflow/dist/style.css'
import { FlowChartProps } from './types'
import FlowChartNode from './Node'
import { StyledFlowChart } from './styles'
import { ArrowTipRightIcon, CircleIcon } from '../../Icons'
import defaultTheme from '../../theme'
import { Controls } from 'reactflow'

const CUSTOM_MARKER_END_ID = 'custom-marker-end'
const CUSTOM_MARKER_START_ID = 'custom-marker-start'

const DEFAULT_NODE_TYPES = { FlowChartNode }

const DEFAULT_EDGE_PROPS = {
  markerEnd: CUSTOM_MARKER_END_ID,
  markerStart: CUSTOM_MARKER_START_ID,
  style: {
    strokeWidth: 1.5,
    stroke: defaultTheme.palette.secondary.main
  },
  type: 'smoothstep'
}

const FlowChart = ({
  nodes = [],
  edges = [],
  showControls = true,
  fitView = true,
  nodeTypes = DEFAULT_NODE_TYPES
}: FlowChartProps): ReactElement => {
  const parsedNodes = nodes.map(({ type, id, position, ...data }) => ({
    type: type?.toString() ?? 'FlowChartNode',
    id,
    position,
    data
  }))

  const parsedEdges = edges.map((edge) => ({
    ...DEFAULT_EDGE_PROPS,
    ...edge
  }))

  const parsedNodeTypes = { ...DEFAULT_NODE_TYPES, ...nodeTypes }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <svg style={{ position: 'absolute', top: 0, left: 0 }}>
        <defs>
          <marker id={CUSTOM_MARKER_START_ID} viewBox="0 0 40 40" markerHeight={20} markerWidth={20} refX={5} refY={12}>
            <CircleIcon />
          </marker>
        </defs>
      </svg>
      <svg style={{ position: 'absolute', top: 0, left: 0 }}>
        <defs>
          <marker id={CUSTOM_MARKER_END_ID} viewBox="0 0 40 40" markerHeight={30} markerWidth={30} refX={12} refY={9}>
            <ArrowTipRightIcon />
          </marker>
        </defs>
      </svg>
      <StyledFlowChart nodeTypes={parsedNodeTypes} nodes={parsedNodes} edges={parsedEdges} fitView={fitView}>
        {showControls && <Controls showInteractive={false} />}
      </StyledFlowChart>
    </div>
  )
}

export default FlowChart
