import { Grid, styled } from '@precis-digital/kurama'

export const StyledGridRightSideContainer = styled(Grid)(() => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  button: {
    alignSelf: 'center'
  }
}))

export const StyledGridTextContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '0px 48px',
  gap: '8px'
}))

export const StyledGridErrorCodeMessageContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '4px'
}))
