import { defaultTheme, ReturnCurveChart, Grid, Typography } from '@precis-digital/kurama'
import ChartToolTip from 'shared/components/ChartToolTip'
import { getFormattedValue } from 'shared/numberFormat'

import React from 'react'

import { type FormatIds } from 'shared/numberFormat'

import { useTranslation } from 'shared/translations'

export interface ModelledPoint {
  x: number
  y: number
  label: string
}

export interface CurveGraphData {
  scatterPlotData: ReturnCurveData[]
  lineFitData: LineFitData[]
  currentSpend: number
  currentRevenue: number
  currentConversions: number
  optimalSpend: number
  optimalRevenue: number
  optimalConversions: number
  targetType: string
}

const filterMapping = {
  weekdaysWeekends: 2,
  olderNewer: 3
}

export interface ReturnCurveData {
  x: number
  y: number
  label?: string
  date?: Date
  olderToNewerValue?: number
  [key: string]: number | string | Date | undefined
}

export interface ScatterPlotData {
  x: number
  y: number
  label: string
}

export interface LineFitData {
  x: number
  y: number
  confidenceInterval: [number, number]
}

interface ReturnCurveGraphProps {
  returnCurveData: CurveGraphData
  toggleFilters: boolean
  filters: number
  currencyCode: string
}

const ReturnCurveGraph = ({
  returnCurveData,
  toggleFilters,
  filters,
  currencyCode
}: ReturnCurveGraphProps): React.ReactElement => {
  const { t } = useTranslation('budgetOptimiser')
  const scatterPlotDataWithLabel = returnCurveData.scatterPlotData.map((dataPoint) => ({
    x: dataPoint.x,
    y: dataPoint.y,
    label: dataPoint.label,
    color: `${defaultTheme.palette.graphs.purple100.toString()}${Math.round(
      (dataPoint.olderToNewerValue ?? 0.5) * 255
    ).toString(16)}`,
    date: dataPoint.date
  }))

  const maxDailySpend = returnCurveData.scatterPlotData.reduce((max, dataPoint) => {
    return dataPoint.x > max ? dataPoint.x : max
  }, -Infinity)

  const maxModelledSpend = Math.max(returnCurveData.optimalSpend, returnCurveData.currentSpend)
  const maxSpend = Math.ceil(Math.max(maxDailySpend, maxModelledSpend) / 50) * 50

  return (
    <ReturnCurveChart
      margin={{
        bottom: 50,
        left: 40,
        right: 20,
        top: 50
      }}
      yAxis={{
        label: {
          value: returnCurveData.targetType === 'revenue' ? 'Daily Revenue' : 'Daily Conversions'
        }
      }}
      xAxis={{
        label: {
          value: 'Daily Spend'
        },
        domain: maxModelledSpend > maxDailySpend ? [0, maxSpend] : undefined
      }}
      containerHeight={700}
      containerWidth="100%"
      scatterPlotData={scatterPlotDataWithLabel}
      lineFitData={returnCurveData.lineFitData}
      currentPoint={{
        x: returnCurveData.currentSpend,
        y:
          returnCurveData.targetType === 'revenue'
            ? returnCurveData.currentRevenue
            : returnCurveData.currentConversions,
        label: 'current'
      }}
      optimalPoint={{
        x: returnCurveData.optimalSpend,
        y:
          returnCurveData.targetType === 'revenue'
            ? returnCurveData.optimalRevenue
            : returnCurveData.optimalConversions,
        label: 'optimal'
      }}
      showScatterPlot={toggleFilters}
      showLine
      getDotColor={(data: any) => {
        switch (data.label) {
          case 'optimal':
            return defaultTheme.palette.semantic.success100
          case 'current':
            return defaultTheme.palette.primary.main
          case 'weekday':
            if (filters === filterMapping.weekdaysWeekends) {
              return defaultTheme.palette.graphs.mint05
            }
            break
          case 'weekend':
            if (filters === filterMapping.weekdaysWeekends) {
              return defaultTheme.palette.graphs.red50
            }
            break
          default:
            return defaultTheme.palette.graphs.steel100
        }

        if (filters === filterMapping.olderNewer) {
          const color = data.color
          return color
        }

        return defaultTheme.palette.graphs.steel100
      }}
      renderTooltip={({ dataKey, payload }) => {
        return (
          <ChartToolTip
            {...{
              customBody: (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {returnCurveData.targetType === 'revenue'
                        ? t('returnCurvePopup.revenue')
                        : t('returnCurvePopup.conversions')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body2">
                      {getFormattedValue({
                        value: payload[dataKey] as number,
                        id: returnCurveData.targetType as FormatIds,
                        currencyCode
                      })}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2">{t('returnCurvePopup.spend')}</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body2">
                      {getFormattedValue({
                        value: payload.x,
                        id: returnCurveData.targetType as FormatIds,
                        currencyCode
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              ),
              title:
                payload.label === 'optimal'
                  ? t('returnCurvePopup.optimalValues')
                  : payload.label === 'current'
                  ? t('returnCurvePopup.currentValues')
                  : payload.date?.toISOString().slice(0, 10) ?? ''
            }}
          />
        )
      }}
      lineProps={{
        type: 'monotone'
      }}
    />
  )
}

export default ReturnCurveGraph
