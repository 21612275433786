import React from 'react'

export const Mapping = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M7 15V14C7 12.8954 7.89543 12 9 12H15C16.1046 12 17 12.8954 17 14V15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M12 8.47559L12 11.6537" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <rect x="4" y="16" width="6" height="3.99993" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
      <rect x="9" y="4" width="6" height="3.99993" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
      <rect
        x="14.3834"
        y="16"
        width="6"
        height="3.99993"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
})
