import React from 'react'

export const Feed = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M6.67139 17.2789H5.67139C5.67139 17.8312 6.1191 18.2789 6.67139 18.2789V17.2789ZM6.83549 16.0766L7.75344 16.4733L7.75344 16.4733L6.83549 16.0766ZM8.089 13.1761L9.00694 13.5728L9.00694 13.5728L8.089 13.1761ZM8.17004 12.8495L9.16695 12.9281V12.9281L8.17004 12.8495ZM8.43308 9.51227L7.43617 9.43369V9.43369L8.43308 9.51227ZM17.5894 9.58733L18.5875 9.52511L17.5894 9.58733ZM17.7921 12.839L18.7902 12.7767V12.7767L17.7921 12.839ZM17.8767 13.1849L18.7908 12.7792V12.7792L17.8767 13.1849ZM19.1568 16.0689L20.0708 15.6632L20.0708 15.6632L19.1568 16.0689ZM19.3288 17.2789V18.2789C19.8811 18.2789 20.3288 17.8312 20.3288 17.2789H19.3288ZM7.67139 17.2789V16.87H5.67139V17.2789H7.67139ZM7.75344 16.4733L9.00694 13.5728L7.17105 12.7794L5.91754 15.6799L7.75344 16.4733ZM9.16695 12.9281L9.42999 9.59084L7.43617 9.43369L7.17314 12.7709L9.16695 12.9281ZM16.5913 9.64955L16.794 12.9012L18.7902 12.7767L18.5875 9.52511L16.5913 9.64955ZM16.9627 13.5906L18.2428 16.4746L20.0708 15.6632L18.7908 12.7792L16.9627 13.5906ZM18.3288 16.8803V17.2789H20.3288V16.8803H18.3288ZM19.3288 16.2789H6.67139V18.2789H19.3288V16.2789ZM18.2428 16.4746C18.2995 16.6023 18.3288 16.7405 18.3288 16.8803H20.3288C20.3288 16.461 20.2409 16.0464 20.0708 15.6632L18.2428 16.4746ZM16.794 12.9012C16.8089 13.1392 16.866 13.3726 16.9627 13.5906L18.7908 12.7792C18.7904 12.7784 18.7902 12.7776 18.7902 12.7767L16.794 12.9012ZM13.0086 6.2832C14.9044 6.2832 16.4734 7.75742 16.5913 9.64955L18.5875 9.52511C18.4038 6.57877 15.9606 4.2832 13.0086 4.2832V6.2832ZM9.42999 9.59084C9.57717 7.72349 11.1354 6.2832 13.0086 6.2832V4.2832C10.0918 4.2832 7.66536 6.52594 7.43617 9.43369L9.42999 9.59084ZM9.00694 13.5728C9.09537 13.3681 9.14944 13.1503 9.16695 12.9281L7.17314 12.7709C7.17291 12.7738 7.1722 12.7767 7.17105 12.7794L9.00694 13.5728ZM7.67139 16.87C7.67139 16.7336 7.69931 16.5986 7.75344 16.4733L5.91754 15.6799C5.75516 16.0557 5.67139 16.4607 5.67139 16.87H7.67139Z"
        fill="#232332"
      />
      <path d="M13.0002 4V5.2832" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M11.1125 17.9081C11.1125 18.156 11.1613 18.4015 11.2562 18.6305C11.3511 18.8595 11.4901 19.0677 11.6654 19.243C11.8407 19.4183 12.0489 19.5573 12.2779 19.6522C12.5069 19.7471 12.7524 19.7959 13.0003 19.7959C13.2483 19.7959 13.4937 19.7471 13.7228 19.6522C13.9518 19.5573 14.1599 19.4183 14.3352 19.243C14.5106 19.0677 14.6496 18.8595 14.7445 18.6305C14.8394 18.4015 14.8882 18.156 14.8882 17.9081"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
})
