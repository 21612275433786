import { Popper, autocompleteClasses, outlinedInputClasses } from '@mui/material'
import { Theme } from '../theme'
import { styled } from '../../utils'
import Typography from '../Typography'
import { StyledInput } from '../Input/styles'

export const StyledPopper = styled(Popper)(({ theme }: { theme?: Theme }) => ({
  width: 'fit-content',
  zIndex: 1200,
  [`& .${autocompleteClasses.paper}`]: {
    color: 'inherit',
    borderRadius: theme?.borderRadius.xxLarge,
    boxShadow: theme?.elevation.mediumDepth,
    height: '100%',
    maxHeight: 'calc((95vh / 2) - 40px)'
  },
  [`& .${autocompleteClasses.loading}`]: {
    ...theme?.typography.body2,
    color: theme?.palette.secondary.main,
    padding: '16px 24px'
  }
}))

export const StyledTypographyNoOptionsText = styled(Typography)(({ theme }: { theme?: Theme }) => ({
  color: theme?.palette.neutrals.stone120,
  padding: '12px 0px 8px 0px'
}))

export const StyledInputAutocompleteTextField = styled(StyledInput)<{ color?: 'error' | 'success' }>(
  ({ theme, color }) => ({
    [`.${autocompleteClasses.inputRoot}.${outlinedInputClasses.root}`]: {
      [`&:has(.${autocompleteClasses.tag})`]: {
        backgroundColor: theme?.palette.neutrals.white0,
        paddingLeft: '4px',
        paddingTop: '4px',
        paddingBottom: '4px',
        rowGap: '8px',
        columnGap: '4px',
        [`.${autocompleteClasses.input}`]: {
          padding: '4px'
        }
      },
      paddingLeft: '8px',
      padding: '8px 16px',
      [`.${autocompleteClasses.input}`]: {
        padding: '0px',
        paddingLeft: '4px'
      },
      [`.${autocompleteClasses.tag}`]: {
        margin: '0px',
        maxWidth: '100%'
      },
      ...(color !== undefined
        ? {
            borderColor: color,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: color
              }
            }
          }
        : {})
    }
  })
)
