import React from 'react'

export const Clock = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        d="M12.0002 9.28615V11.6809L13.6379 13.3186M11.0182 18H12.9818C15.7533 18 18 15.7533 18 12.9818V11.0182C18 8.24671 15.7533 6 12.9818 6H11.0182C8.24671 6 6 8.2467 6 11.0182V12.9818C6 15.7533 8.2467 18 11.0182 18Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
})
