import React, { type Dispatch, type SetStateAction } from 'react'
import { DropdownItem } from '@precis-digital/kurama'
import { type Client } from 'shared/context/ClientContext'
import { type Member } from 'workspace/api'
import { type User } from 'shared/context/AuthContext'
import { type UseMutateFunction } from 'react-query'
import { type MemberResp, type PatchUpdateMemberRoleReq } from 'shared/api/clients'
import { type ClientError } from 'shared/api/fetch'
import { useTranslation } from 'shared/translations'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { useChangePath } from 'shared/components/Router'
import { APP_ROUTES } from 'shared/routes'

interface AssignNewRoleProps {
  member: Member
  currentUser: User | undefined
  currentClient: Client
  updateMember: UseMutateFunction<MemberResp, ClientError, PatchUpdateMemberRoleReq, unknown>
  setDropdownAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>
}

const AssignNewRole = ({
  member,
  currentUser,
  currentClient,
  updateMember,
  setDropdownAnchorEl
}: AssignNewRoleProps): React.ReactElement => {
  const myself: boolean = member.id === currentUser?.id

  const { t } = useTranslation('workspace')

  const { changePath } = useChangePath()

  const handleUpdateMember = (clientId: string, userId: string, role: string): void => {
    const { toastOnError, toastOnSuccess } = makeToastWithLoading()
    setDropdownAnchorEl(null)
    updateMember(
      { clientId, userId, role },
      {
        onSuccess(data) {
          toastOnSuccess(`${member.name} was successfully assigned as ${data.role}`)
          if (myself && role !== 'admin') {
            void changePath(APP_ROUTES.home.basePage)
          }
        },
        onError() {
          toastOnError(`An error occurred while assigning ${member.name} as ${role}. Please try again`)
        }
      }
    )
  }
  return (
    <>
      {member.role === 'viewer' && (
        <>
          <DropdownItem
            onClick={() => {
              handleUpdateMember(currentClient.id, member.id, 'editor')
            }}
            annotation={t('descriptions.editorDescriptionAssign')}
          >
            {t('titles.assignEditor')}
          </DropdownItem>
        </>
      )}
      {(member.role === 'editor' || member.role === 'admin') && (
        <>
          <DropdownItem
            onClick={() => {
              handleUpdateMember(currentClient.id, member.id, 'viewer')
            }}
            annotation={t('descriptions.viewerDescriptionAssign')}
          >
            {`${t('fragments.assign')} ${myself ? t('fragments.me') : ''} ${t('fragments.viewer')}`}
          </DropdownItem>
        </>
      )}
      {member.role === 'admin' && (
        <>
          <DropdownItem
            onClick={() => {
              handleUpdateMember(currentClient.id, member.id, 'editor')
            }}
            annotation={t('descriptions.editorDescriptionAssign')}
          >
            {`${t('fragments.assign')} ${myself ? t('fragments.me') : ''} ${t('fragments.editor')}`}
          </DropdownItem>
        </>
      )}
      {(member.role === 'viewer' || member.role === 'editor') && (
        <>
          <DropdownItem
            annotation={t('descriptions.adminDescriptionAssign')}
            onClick={() => {
              handleUpdateMember(currentClient.id, member.id, 'admin')
            }}
          >
            {t('titles.assignAdmin')}
          </DropdownItem>
        </>
      )}
    </>
  )
}

export default AssignNewRole
