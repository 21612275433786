import React from 'react'

export const CancelBkg = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <g>
        <path
          d="M3 10.5272C3 6.37005 6.37006 3 10.5272 3H13.4728C17.6299 3 21 6.37006 21 10.5272V13.4728C21 17.6299 17.6299 21 13.4728 21H10.5272C6.37005 21 3 17.6299 3 13.4728V10.5272Z"
          fill="#EEF4F6"
        />
      </g>
      <path
        d="M14.0163 9.98358L9.98349 14.0164"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0164 14.0164L9.98361 9.98358"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
