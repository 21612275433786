import { styled as MUIStyled, CreateMUIStyled, alpha as MUIAlpha } from '@mui/system'
import { BadgeColor } from '../components/Badge/types'

export const styled = ((): CreateMUIStyled => {
  return MUIStyled
})()

/**
 * The Alpha function is used to specify the opacity of a color. Read more about it here https://developer.mozilla.org/en-US/docs/Glossary/Alpha
 */

export const alpha = (color: string, value: number): string => {
  return MUIAlpha(color, value)
}

export type color = 'success' | 'error' | undefined

export const getInputBadgeColor = (color: color): BadgeColor => {
  if (color === 'success') {
    return 'success'
  }
  return 'error'
}
