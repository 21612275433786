import React from 'react'

export const MortarBoard = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M18.3286 11.9381C18.3269 11.3859 17.8777 10.9395 17.3255 10.9413C16.7732 10.943 16.3269 11.3921 16.3286 11.9444L18.3286 11.9381ZM17.3398 15.5424H18.3398L18.3398 15.5392L17.3398 15.5424ZM6.78024 15.5424H7.78024L7.78023 15.5392L6.78024 15.5424ZM7.76904 12.0055C7.76729 11.4532 7.31815 11.007 6.76587 11.0087C6.21359 11.0105 5.7673 11.4596 5.76905 12.0119L7.76904 12.0055ZM4 10.0954L3.54431 9.2053C3.2282 9.36713 3.02162 9.68447 3.00159 10.039C2.98156 10.3936 3.15108 10.7322 3.44696 10.9286L4 10.0954ZM12 6L12.4557 5.10986C12.1696 4.96338 11.8304 4.96338 11.5443 5.10986L12 6ZM20 10.0954L20.553 10.9286C20.8489 10.7322 21.0184 10.3936 20.9984 10.039C20.9784 9.68447 20.7718 9.36713 20.4557 9.2053L20 10.0954ZM12 15.4058L11.447 16.2389C11.7821 16.4614 12.2179 16.4614 12.553 16.2389L12 15.4058ZM16.3286 11.9444L16.3398 15.5455L18.3398 15.5392L18.3286 11.9381L16.3286 11.9444ZM14.6127 17H9.70999V19H14.6127V17ZM7.78023 15.5392L7.76904 12.0055L5.76905 12.0119L5.78024 15.5455L7.78023 15.5392ZM9.70999 17C9.06337 17 8.56446 16.8481 8.25399 16.6185C7.98572 16.42 7.78024 16.1114 7.78024 15.5424H5.78024C5.78024 16.71 6.25858 17.6302 7.06466 18.2264C7.82854 18.7914 8.7945 19 9.70999 19V17ZM16.3398 15.5424C16.3398 16.1689 16.1416 16.4746 15.9295 16.6479C15.6835 16.8488 15.2585 17 14.6127 17V19C15.5289 19 16.4675 18.7907 17.1946 18.1969C17.9554 17.5756 18.3398 16.6525 18.3398 15.5424H16.3398ZM4.45569 10.9856L12.4557 6.89014L11.5443 5.10986L3.54431 9.2053L4.45569 10.9856ZM11.5443 6.89014L19.5443 10.9856L20.4557 9.2053L12.4557 5.10986L11.5443 6.89014ZM19.447 9.26228L11.447 14.5726L12.553 16.2389L20.553 10.9286L19.447 9.26228ZM12.553 14.5726L4.55304 9.26228L3.44696 10.9286L11.447 16.2389L12.553 14.5726Z"
        fill="#232332"
      />
      <path d="M20 10.2012V13.5735" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
})
