// Documentation
export const DATASOURCE_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2673639437/Setting+up+Data+Sources'
export const GCP_DATASOURCE_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2674196482/Google+Cloud+Platform'
export const CUSTOM_GROUPING_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2673704970/How+to+create+a+custom+grouping'
export const ATTRIBUTION_MODELS_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2673901581/Attribution+Models'
export const ATTRIBUTION_MODELS_AVAIABLE_MODELS_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2673672269/The+available+Attribution+Models'
export const BUDGET_OPTIMISER_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2673934350/Budget+Optimiser'
export const BUDGET_OPTIMISER_FACEBOOK_CONVERSION_ACTIONS_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/SF/pages/2476212247/IA+BO+conversion+actions+cheat+sheet'
export const USER_PERMISSIONS_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2673278980/Managing+Workspace+Access'
export const MIMIR_RELEVANT_INTERNAL_PRECIS_DOCUMENTS_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2592964614/How+Mimir+works#Relevant-internal-Precis-Documents'
export const CONNECTORS_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2673672210/Connectors'
export const ENHANCED_SIGNALS_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2736029757/Enhanced+Signals'
export const REPORTING_SOLUTIONS_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2316206081/Reporting+Solutions'
export const REPORTING_SOLUTIONS_DEPRECATION_DOCUMENTATION_LINK =
  'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2797043716/Migrating+from+a+deprecated+report'
export const REGEX_HELP_LINK = 'https://regex101.com/'

// Central App Links
export const CENTRAL_APP_REPORTING_SOLUTION_NEW =
  'https://precis-central.appspot.com/precis-apps/standardised-reporting/new'
export const CENTRAL_APP_REPORTING_SOLUTION_CONFIG =
  'https://precis-central.appspot.com/precis-apps/standardised-reporting'

export const CENTRAL_APP_HEALTH_CHECKERS_LINK = 'https://precis-central.appspot.com/health/recommendations'
