import { styled, Grid, Typography } from '@precis-digital/kurama'
import Image from 'next/image'
import { type ReactElement } from 'react'
import legoBlocks from 'public/assets/images/lego_blocks.png'
import dataSource from 'public/assets/images/data_sources.svg'
import GCP from 'public/assets/images/gcp.svg'
import analytics from 'public/assets/images/google_analytics_4.svg'
import CustomGroupings from 'public/assets/images/custom_groupings.svg'
import OverScreen from 'shared/overScreens'
import Role from '../Role'
import { type clients } from 'shared/api'
import ConnectedCard from './ConnectedCard'
import ViewerAccessPrerequisitePage from './ViewerAccess'
import PERMISSIONS from 'shared/constants/permissions'
import { APP_ROUTES } from 'shared/routes'
import { useChangePath } from '../Router'
import {
  CUSTOM_GROUPING_DOCUMENTATION_LINK,
  DATASOURCE_DOCUMENTATION_LINK,
  GCP_DATASOURCE_DOCUMENTATION_LINK
} from 'shared/constants/links'

export interface NoPreRequisitesProps {
  memberRole: clients.MemberRole
  hasGcpAccount?: boolean | null
  hasDataSourceAccount?: boolean | null
  hasCustomGrouping?: boolean | null
  hasAnalyticsAccount?: boolean | null
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}
const NoPreRequisites = ({
  memberRole,
  hasGcpAccount,
  hasDataSourceAccount,
  hasCustomGrouping,
  hasAnalyticsAccount,
  t
}: NoPreRequisitesProps): ReactElement => {
  const handleUnauthorized = (): ReactElement => {
    return <ViewerAccessPrerequisitePage title={t('noPreRequisites.viewerTitle')} />
  }
  const { changePath } = useChangePath()

  return (
    <Role
      authorizedMemberRoles={PERMISSIONS.ENTER_PREREQUISITE_PAGE}
      memberRole={memberRole}
      onUnauthorized={handleUnauthorized}
    >
      <StyledNoPreRequisitesText display="flex" flexDirection="row">
        <StyledGrid>
          <StyledAttributionsModelsHeader item xs={12}>
            <Typography variant="h1">{t('noPreRequisites.productTitle')}</Typography>
          </StyledAttributionsModelsHeader>
          <Typography variant="h3">{t('noPreRequisites.title')}</Typography>
          <Typography variant="body2">{t('noPreRequisites.inOrderTo')} </Typography>
        </StyledGrid>
        <Grid position="relative" width="30vw" height="45vh" maxHeight="320px">
          <StyledImage src={legoBlocks.src} alt="check-list" fill />
        </Grid>
      </StyledNoPreRequisitesText>
      <StyledCardWrapper>
        {hasDataSourceAccount !== undefined && (
          <ConnectedCard
            handleClick={() => {
              void OverScreen.show('connectNewDataSourcePopup')
            }}
            title={t('noPreRequisites.connectedCardDS.title')}
            description={t('noPreRequisites.connectedCardDS.description')}
            imageUrl={dataSource.src}
            connected={hasDataSourceAccount as boolean}
            cardLink={DATASOURCE_DOCUMENTATION_LINK}
          />
        )}

        {hasGcpAccount !== undefined && (
          <ConnectedCard
            handleClick={() => {
              void OverScreen.show('connectNewDataSourcePopup')
            }}
            title={t('noPreRequisites.connectedCardGCP.title')}
            description={t('noPreRequisites.connectedCardGCP.description')}
            imageUrl={GCP.src}
            connected={hasGcpAccount as boolean}
            cardLink={GCP_DATASOURCE_DOCUMENTATION_LINK}
          />
        )}
        {hasCustomGrouping !== undefined && (
          <ConnectedCard
            handleClick={() => {
              void changePath(APP_ROUTES.customGroupings.basePage)
            }}
            title={t('noPreRequisites.connectedCardCustomGrouping.title')}
            description={t('noPreRequisites.connectedCardCustomGrouping.description')}
            imageUrl={CustomGroupings.src}
            connected={hasCustomGrouping as boolean}
            channelGrouping
            cardLink={CUSTOM_GROUPING_DOCUMENTATION_LINK}
          />
        )}
        {hasAnalyticsAccount !== undefined && (
          <ConnectedCard
            handleClick={() => {
              void OverScreen.show('connectNewDataSourcePopup')
            }}
            title={t('noPreRequisites.connectedCardAnalytics.title')}
            description={t('noPreRequisites.connectedCardAnalytics.description')}
            imageUrl={analytics.src}
            connected={hasAnalyticsAccount as boolean}
          />
        )}
      </StyledCardWrapper>
    </Role>
  )
}

export default NoPreRequisites

const StyledAttributionsModelsHeader = styled(Grid)(() => ({
  display: 'flex',
  marginBottom: '72px'
}))

const StyledNoPreRequisitesText = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  h3: {
    marginBottom: '11px'
  }
}))

const StyledGrid = styled(Grid)(() => ({
  width: '50%',
  whiteSpace: 'break-spaces'
}))

const StyledCardWrapper = styled(Grid)(() => ({
  display: 'flex',
  marginTop: '32px',
  justifyContent: 'center',
  '& > div': {
    marginRight: '16px'
  }
}))

const StyledImage = styled(Image)(() => ({
  objectFit: 'contain'
}))
