import { Button, Grid, Typography, styled } from '@precis-digital/kurama'

export const StyledFlyout = styled(Grid)(({ theme }) => ({
  paddingBottom: '48px'
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  margin: '10px 0'
}))

export const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: '25px'
}))

export const StyledProfileItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  button: {
    alignSelf: 'center',
    marginTop: '16px',
    '&:first-of-type': {
      marginRight: theme.spacing(1)
    }
  },
  '>div': {
    width: '100%'
  }
}))

export const EditInputContainer = styled(Grid)(({ theme }) => ({
  '>div': {
    width: '100%',
    paddingTop: theme.spacing(2)
  }
}))

export const EditInputsContainer = styled(Grid)(({ theme }) => ({
  form: {
    '>div': {
      width: '100%',
      paddingTop: '10px'
    }
  },
  button: {
    marginTop: '16px',
    '&:first-of-type': {
      marginRight: theme.spacing(1)
    }
  }
}))

export const StyledBackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '25px'
}))
