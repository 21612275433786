import { type ConnectorPlatformType } from 'connectors/constants'
import { type DeepPartial } from 'react-hook-form'
import { type ConnectorFormData } from '.'
import connectorBehaviourOptionsConfigs from './DateRangeStep/connectorBehaviourOptions.json'
import relativeDateOptionsForConnectorBehaviourV4 from './DateRangeStep/relativeDateOptionsForConnectorBehaviourV4.json'
import snapchatAttributionWindows from './ReportStep/snapchatAttributionWindowOptions.json'
import { subDays } from 'shared/dateFns'

interface ScheduleOption {
  value: string
  label: string
  exclude?: ConnectorPlatformType[]
}

export const WORKSPACES_WITH_ACCESS_TO_UA = [
  '5157937446125568', // viaplay
  '5442890968858624', // klarna
  '5128977475698688', // Min Doktor
  '5144255815221248', // Hjärnfonden
  '5108970706436096', // Adlibris
  '5087696061988864', // Active Brands
  '5107759200600064', // Energy Fyn
  '5126771523452928', // Summerbird
  '5122394733674496', // summerbird
  '5193328970694656', // KidsBrandStore
  '5078748101607424', // Synsam
  '6296229931646976', // Safira
  '6285215282495488', // Personio
  '5182865356619776', // Nordfrim
  '5161603200712704', // Roofnest
  '5081315498000384', // Precis Digital - Own Marketing
  '5148213363343360', // Apohem
  '5098989242286080', // Karo Pharma
  '5096673241989120', // NABA
  '5166078873305088', // FlowNeuroscience
  '5198251271651328', // ICANIWILL
  '5074544066822144', // Strömma
  '5072974033977344', // Collector
  '5069112342478848', // Nordvik Bolig
  '6278576252911616', // Walley
  '6282259074121728', // Rofa
  '5169068868894720', // Babyland
  '5097350968115200', // John Henric
  '6223871942328320', // JM AB
  '5175941265358848' // Dominos
]

export const ADMIN_USERS_WITH_ACCESS_TO_UA = [
  '5171996539224064', // jonas@precisdigital.com
  '6246998197927936', // malik@precisdigital.com
  '6320354460958720', // erica@precisdigital.com
  '5083961319489536', // lowe@precisdigital.com
  '6212907431886848' // hink@precisdigital.com
]

export const SCHEDULE_OPTIONS: ScheduleOption[] = [
  {
    value: 'daily',
    label: 'Daily'
  },
  {
    value: 'weekly',
    label: 'Weekly',
    exclude: ['merchant_center']
  },
  {
    value: 'monthly',
    label: 'Monthly',
    exclude: ['merchant_center']
  }
]

export const PLATFORM_DEFAULT_REFRESH_DAYS = {
  gads: 60
}

export const PLATFORMS_WITHOUT_DATES = ['merchant_center', 'google_sheets']

export const DEFAULT_FILL_FROM_DAYS_LOOKBACK = 400

export const DEFAULT_REFRESH_DAYS = 30

export const MINIMUM_DAYS_TO_REFRESH = 1
export const MAXIMUM_DAYS_TO_REFRESH = 180

export const DEFAULT_FORM_VALUES: DeepPartial<ConnectorFormData> = {
  dataSource: {},
  report: {
    basicSettings: {
      truncateList: ['BestSellers_TopBrands', 'BestSellers_TopProducts'],
      truncate: true
    },
    advancedSettings: {
      actionAttributionWindows: [],
      useAccountAttributionSetting: false,
      useUnifiedAttributionSetting: false,
      snapchatSwipeUpAttributionWindow: snapchatAttributionWindows.swipeUpAttributionWindow['28_DAY'],
      snapchatViewAttributionWindow: snapchatAttributionWindows.viewAttributionWindow['1_DAY']
    }
  },
  dateRange: {
    basicSettings: {
      version: connectorBehaviourOptionsConfigs.V2.value,
      fillFromDate: subDays(new Date(), DEFAULT_FILL_FROM_DAYS_LOOKBACK),
      relativeFromDateType: relativeDateOptionsForConnectorBehaviourV4.startEndDate.value,
      relativeIncludeCurrent: false
    },
    advancedSettings: {
      daysToRefresh: DEFAULT_REFRESH_DAYS
    }
  },
  dataTarget: {
    basicSettings: {
      targetDataset: 'precis_connectors'
    },
    advancedSettings: {
      schedule: 'daily'
    }
  },
  dv360UsePartnerId: false,
  thirdPartyConnectorActive: true
}

export const GOOGLE_ADS_TABLES_WITHOUT_METRICS = [
  'accessible_bidding_strategy',
  'account_budget',
  'account_budget_proposal',
  'account_link',
  'ad_group_ad_label',
  'ad_group_asset_set',
  'ad_group_bid_modifier',
  'ad_group_criterion',
  'ad_group_criterion_customizer',
  'ad_group_criterion_label',
  'ad_group_criterion_simulation',
  'ad_group_customizer',
  'ad_group_extension_setting',
  'ad_group_feed',
  'ad_group_label',
  'ad_group_simulation',
  'ad_parameter',
  'asset',
  'asset_group',
  'asset_group_asset',
  'asset_group_listing_group_filter',
  'asset_group_signal',
  'asset_set',
  'audience',
  'batch_job',
  'bidding_data_exclusion',
  'bidding_seasonality_adjustment',
  'bidding_strategy_simulation',
  'billing_setup',
  'call_view',
  'campaign_asset_set',
  'campaign_conversion_goal',
  'campaign_criterion',
  'campaign_criterion_simulation',
  'campaign_customizer',
  'campaign_draft',
  'campaign_extension_setting',
  'campaign_feed',
  'campaign_label',
  'campaign_shared_set',
  'campaign_simulation',
  'carrier_constant',
  'change_event',
  'change_status',
  'combined_audience',
  'conversion_custom_variable',
  'conversion_goal_campaign_config',
  'conversion_value_rule',
  'conversion_value_rule_set',
  'currency_constant',
  'custom_audience',
  'custom_conversion_goal',
  'custom_interest',
  'customer_asset_set',
  'customer_client',
  'customer_client_link',
  'customer_conversion_goal',
  'customer_customizer',
  'customer_extension_setting',
  'customer_feed',
  'customer_label',
  'customer_manager_link',
  'customer_negative_criterion',
  'customer_user_access',
  'customer_user_access_invitation',
  'customizer_attribute',
  'detailed_demographic',
  'domain_category',
  'experiment',
  'experiment_arm',
  'feed',
  'feed_item_set',
  'feed_item_set_link',
  'feed_item_target',
  'feed_mapping',
  'geo_target_constant',
  'keyword_plan',
  'keyword_plan_ad_group',
  'keyword_plan_ad_group_keyword',
  'keyword_plan_campaign',
  'keyword_plan_campaign_keyword',
  'keyword_theme_constant',
  'label',
  'language_constant',
  'lead_form_submission_data',
  'life_event',
  'media_file',
  'mobile_app_category_constant',
  'mobile_device_constant',
  'offline_user_data_job',
  'operating_system_version_constant',
  'product_bidding_category_constant',
  'product_link',
  'qualifying_question',
  'recommendation',
  'remarketing_action',
  'shared_criterion',
  'shared_set',
  'smart_campaign_setting',
  'third_party_app_analytics_link',
  'topic_constant',
  'user_interest',
  'user_list'
]
