import { Button, Dropdown, Grid, MoreIcon } from '@precis-digital/kurama'
import React, { useMemo, useState } from 'react'
import { type ReactElement } from 'react-markdown/lib/react-markdown'
import Header from './Header'
import TabsRow from './TabsRow'
import DataSourcesTab from './DataSourcesTab'
import ChannelsTab from './ChannelsTab'
import UsedInTab from './UsedInTab'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { DEFAULT_MAIN_FORM_VIEW_TAB } from 'channelGrouping/constants'
import MoreMenuDropdown from '../MoreMenuDropdown'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'
interface MainFormPageProps {
  tabId: string
}

const MainFormPage = ({ tabId }: MainFormPageProps): ReactElement => {
  const { channelGroupingId, currentChannelGrouping } = useCurrentChannelGrouping()
  const { t } = useTranslation('channelGrouping')

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null)

  const TabComponentToRender = useMemo(() => {
    switch (tabId) {
      case 'data-sources':
        return DataSourcesTab
      case 'channels':
        return ChannelsTab
      case 'used-in':
        return UsedInTab
    }
  }, [tabId])

  if (TabComponentToRender == null) {
    return (
      <NotFoundPage
        hasDashboardFrame
        returnPath={`${APP_ROUTES.customGroupings.editConfigPage({
          channelGroupingId: channelGroupingId.toString()
        })}/${DEFAULT_MAIN_FORM_VIEW_TAB}`}
      />
    )
  }

  const menuOpen = Boolean(menuAnchorEl)

  const handleMoreMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMoreMenuClose = (): void => {
    setMenuAnchorEl(null)
  }

  return (
    <>
      <Grid container flexDirection="column" gap="24px">
        <Header />
        <Grid display="flex" justifyContent="space-between">
          <TabsRow tabId={tabId} />
          <Button variant="text" rightIcon={<MoreIcon />} onClick={handleMoreMenuButtonClick}>
            {t('mainFormView.moreMenu.more')}
          </Button>
        </Grid>
        <TabComponentToRender />
      </Grid>
      <Dropdown open={menuOpen} anchorEl={menuAnchorEl} onClose={handleMoreMenuClose}>
        <MoreMenuDropdown channelGrouping={currentChannelGrouping} closeMenu={handleMoreMenuClose} isPageWithConfigId />
      </Dropdown>
    </>
  )
}

export default MainFormPage
