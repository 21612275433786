import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Card,
  styled,
  MoreIcon,
  Button,
  Dropdown,
  Divider,
  TrashIcon,
  AccountIcon,
  DropdownList,
  DropdownItem
} from '@precis-digital/kurama'
import { CurrentUser } from 'workspace/components/CurrentUser'
import AssignNewRole from './AssignNewRole'
import { useCurrentClient } from 'shared/context/ClientContext'
import { type Member, useDeleteMember, useUpdateMemberRole } from 'workspace/api'
import { type User } from 'shared/context/AuthContext'
import { LeaveWorkspaceButton } from '../Flyout/ViewerFlyout'
import { capitalize } from 'shared/utils/stringFormatter'
import OverScreen from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import { makeToastWithLoading } from 'shared/components/Toaster'

interface MembersListCardProps {
  shouldShowMoreActions: boolean
  isCurrentUser: boolean
}

interface MemberCardListProps {
  shouldShowMoreActions: boolean
  member: Member
  currentUser: User | undefined
  adminsRole?: Member[]
}

export const MemberCard = ({
  shouldShowMoreActions,
  member,
  currentUser,
  adminsRole
}: MemberCardListProps): React.ReactElement => {
  const { currentClient } = useCurrentClient()
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { mutate: updateMember } = useUpdateMemberRole()
  const { mutateAsync: deleteMember } = useDeleteMember()

  const { t } = useTranslation('workspace')

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    event.preventDefault()
    setDropdownAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setDropdownAnchorEl(null)
  }
  const isDropdownOpen = !(dropdownAnchorEl == null)

  const handleCardClick = (id: string): void => {
    setDropdownAnchorEl(null)
    void OverScreen.show('profileFlyout', { id })
  }

  const currentUserDropdown = member.id === currentUser?.id

  const handleRemoveMember = async (): Promise<void> => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    setDropdownAnchorEl(null)
    await deleteMember(
      {
        clientId: currentClient.id,
        userId: member.id
      },
      {
        onSuccess() {
          toastOnSuccess(`Successfully removed ${member.name} from this workspace`)
        },
        onError() {
          toastOnError(`An error occuring while removing ${member.name} from this workspace. Please try again.`)
        }
      }
    )
  }

  return (
    <>
      <StyledMembersListCard
        shouldShowMoreActions={shouldShowMoreActions}
        isCurrentUser={member.id === currentUser?.id}
      >
        <StyledMemberslistAvatarContainer>
          <AvatarWithLabel size="medium" user={member} />
          <Grid>
            <Typography variant="h3">
              {' '}
              <CurrentUser memberName={member.name} memberId={member.id} currentUserId={currentUser?.id ?? ''} />
            </Typography>
            <Typography variant="body3">{member.email}</Typography>
          </Grid>
        </StyledMemberslistAvatarContainer>
        <StyledMembersListMoreMenuContainer>
          <Typography variant="h3">{capitalize(member.role)}</Typography>
          {!shouldShowMoreActions && (
            <>
              <Button variant="text" onClick={handleClick} rightIcon={<MoreIcon />} />
              <Dropdown anchorEl={dropdownAnchorEl} open={isDropdownOpen} onClose={handleClose}>
                <DropdownList>
                  {adminsRole != null && adminsRole.length <= 1 && member.id === currentUser?.id ? null : (
                    <>
                      <AssignNewRole
                        member={member}
                        currentUser={currentUser}
                        currentClient={currentClient}
                        updateMember={updateMember}
                        setDropdownAnchorEl={setDropdownAnchorEl}
                      />
                      <Divider />
                    </>
                  )}
                  <DropdownItem
                    onClick={() => {
                      handleCardClick(member.id)
                    }}
                    icon={<AccountIcon />}
                  >
                    {t('buttons.userProfile')}
                  </DropdownItem>
                  {currentUserDropdown ? (
                    <LeaveWorkspaceButton />
                  ) : (
                    <DropdownItem
                      onClick={() => {
                        void handleRemoveMember()
                      }}
                      icon={<TrashIcon />}
                    >
                      {t('buttons.remove')}
                    </DropdownItem>
                  )}
                </DropdownList>
              </Dropdown>
            </>
          )}
        </StyledMembersListMoreMenuContainer>
      </StyledMembersListCard>
    </>
  )
}

const StyledMembersListMoreMenuContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 0,
  gap: 8,
  height: 40,
  flex: 'none',
  flexWrap: 'wrap',
  alignContent: 'center',
  h3: {
    lineHeight: '2.75rem'
  }
}))
const StyledMembersListCard = styled(Card)<MembersListCardProps>(({ shouldShowMoreActions, isCurrentUser, theme }) => ({
  margin: '16px 0 0 0',
  backgroundColor: isCurrentUser && theme.palette.neutrals.stone90
}))

const StyledMemberslistAvatarContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '1rem',
  gap: 16,
  flex: 'none',
  'div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column'
  },
  span: {
    color: theme.palette.neutrals.stone150
  }
}))

export const StyledDropdownButton = styled(Grid)(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  display: 'inline-flex',
  width: '100%',
  paddingTop: '20px'
}))

export const StyledIcon = styled(Grid)({
  paddingRight: '10px'
})

export default MemberCard
