import { ChatMessageRole, MIMIR_BASE_AGENT_ID } from 'mimir/constants'
import { type ChatMessage } from 'mimir/types'
import { chatMessageFactory, getContextFromMessages } from 'mimir/utils'
import { type ReactElement, type ReactNode, createContext, useContext, useState } from 'react'
import { useAuth } from './AuthContext'
import { useMutationMimirAgentChat } from 'mimir/api'
import { useCurrentClient } from './ClientContext'
import { type ClientError } from 'shared/api/fetch'
import { type MimirAgentMessage, type MimirAgentChatRequest } from 'shared/api/mimir'
import { useQueryUserProfile } from 'profile/api'

interface MimirContextProps {
  isMimirOpen: boolean
  toggleMimir: () => void
  askMimir: (questionRequest: {
    userFacingMessage: string
    questionPrompt?: string
    clearHistory?: boolean
    options?: { onError: (error: ClientError, variables: MimirAgentChatRequest, context: unknown) => void }
    customDataContext?: string
  }) => void
  messages: ChatMessage[]
  clearMessageHistory: () => void
  isWaitingForResponse: boolean
  dataContext?: string
  suggestedQuestions?: string[]
  setDataContext: (dataContext: string | undefined) => void
  setSuggestedQuestions: (suggestedQuestions: string[] | undefined) => void
}

const MimirContext = createContext<MimirContextProps | undefined>(undefined)

export const useMimir = (): MimirContextProps => {
  const mimirContext = useContext(MimirContext)
  if (mimirContext == null) {
    throw new Error('No mimirContext.Provider found when calling mimirContext.')
  }

  return mimirContext
}

export const MimirProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [isMimirOpen, setIsMimirOpen] = useState<boolean>(false)
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const { currentUser } = useAuth()
  const { data: currentUserDetails } = useQueryUserProfile(currentUser?.id as string)
  const { currentClient } = useCurrentClient()

  const [dataContext, setDataContext] = useState<string | undefined>()
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[] | undefined>()

  const toggleMimir = (): void => {
    setIsMimirOpen((prev) => !prev)
  }

  const { mutate: submitQuestionToAgent, isLoading: isSubmittingQuestion } = useMutationMimirAgentChat()

  const askMimir: MimirContextProps['askMimir'] = ({
    userFacingMessage,
    questionPrompt,
    clearHistory,
    options,
    customDataContext
  }) => {
    if (!isMimirOpen) {
      toggleMimir()
    }

    let conversationContext: MimirAgentMessage[] = []

    if (clearHistory === true) {
      clearMessageHistory()
    } else {
      conversationContext = getContextFromMessages(messages)
    }

    if (customDataContext != null && dataContext !== customDataContext) {
      setDataContext(customDataContext)
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      chatMessageFactory({
        role: ChatMessageRole.user,
        text: userFacingMessage,
        avatarUrl: currentUserDetails?.avatarUrl
      })
    ])

    const dataContextToUse = customDataContext ?? dataContext
    const hookContextPrefix =
      dataContextToUse != null
        ? `You are looking at a page on a website. The following data represents what the user is seeing. If relevant use it to answer the user's question: DATA: ${dataContextToUse}\n`
        : ''

    submitQuestionToAgent(
      {
        question:
          questionPrompt ?? `Answer the following message as short and concise as possible: \n${userFacingMessage}`,
        skipRag: dataContextToUse != null,
        agentId: `${MIMIR_BASE_AGENT_ID}`,
        clientId: currentClient.id,
        contextPrompts: [
          ...conversationContext,
          ...(dataContextToUse != null ? [{ content: hookContextPrefix, role: ChatMessageRole.user }] : [])
        ]
      },
      {
        onSuccess: (data) => {
          const message = chatMessageFactory({
            role: ChatMessageRole.assistant,
            text: data.message,
            agentId: MIMIR_BASE_AGENT_ID,
            relatedDocuments: data.relatedLinks
          })
          setMessages((prevMessages) => [...prevMessages, message])
        },
        onError: (error, variables, context) => {
          setMessages((prevMessages) => [...prevMessages].slice(0, -1))
          if (options?.onError != null) {
            options.onError(error, variables, context)
          }
        }
      }
    )
  }

  const clearMessageHistory = (): void => {
    setMessages([])
  }

  return (
    <MimirContext.Provider
      value={{
        isMimirOpen,
        toggleMimir,
        messages,
        askMimir,
        clearMessageHistory,
        isWaitingForResponse: isSubmittingQuestion,
        dataContext,
        setDataContext,
        suggestedQuestions,
        setSuggestedQuestions
      }}
    >
      {children}
    </MimirContext.Provider>
  )
}
