import React, { forwardRef } from 'react'
import MUITable from '@mui/material/Table'
import { TableProps } from './types'

export const Table = forwardRef<HTMLTableElement, TableProps>(({ children, ...props }, ref): React.ReactElement => {
  return (
    <MUITable ref={ref} {...props}>
      {children}
    </MUITable>
  )
})
export default Table
