import React from 'react'

export const Mimir = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M12.7 19.4001C12.6 19.4001 12.5 19.4001 12.3 19.4001H12H11.6C8.5 19.4001 5.1 19.4001 4.2 17.1001C3.6 15.5001 4.2 12.7001 4.8 10.7001C4.5 10.6001 4.1 10.4001 3.8 10.2001C3.3 9.60009 3 9.10009 3 8.30009C3 7.30009 3.4 6.20009 4.1 5.60009C5.4 4.40009 7.1 4.30009 8.4 5.20009C8.8 5.40009 9 5.70009 9.1 6.00009C10.3 5.70009 11.4 5.60009 11.9 5.60009C12.4 5.60009 13.5 5.70009 14.7 6.00009C14.8 5.70009 15.1 5.40009 15.4 5.20009C16.8 4.20009 18.4 4.40009 19.7 5.60009C20.5 6.30009 21 7.40009 21 8.30009C21 9.00009 20.7 9.60009 20.3 9.90009C19.9 10.2001 19.6 10.3001 19.2 10.4001C19.8 12.4001 20.5 15.3001 19.8 16.8001C18.9 19.3001 15.7 19.4001 12.7 19.4001ZM12 18.0001H12.4C14.9 18.0001 17.9 18.0001 18.5 16.6001C18.9 15.6001 18.6 13.1001 17.5 10.2001L17.1 9.00009L18.3 9.30009C18.7 9.40009 19 9.30009 19.3 9.10009C19.4 9.00009 19.5 8.70009 19.5 8.40009C19.5 7.80009 19.2 7.10009 18.8 6.80009C17.7 5.80009 16.8 6.10009 16.1 6.50009C16 6.50009 16 6.60009 16 6.80009L16.3 8.00009L15.2 7.60009C13.9 7.20009 12.4 7.10009 12 7.10009C11.6 7.10009 10.1 7.20009 8.8 7.60009L7.6 8.00009L7.9 6.80009C8 6.60009 7.9 6.40009 7.8 6.40009C7.1 6.00009 6.1 5.70009 5.1 6.70009C4.7 7.10009 4.4 7.70009 4.4 8.30009C4.4 8.60009 4.5 8.80009 4.6 9.00009C5 9.20009 5.3 9.30009 5.6 9.20009L6.8 8.90009L6.4 10.1001C5.4 13.0001 5.1 15.5001 5.4 16.5001C6.1 18.0001 9.1 18.0001 11.6 18.0001H12Z"
        fill="currentColor"
      />
      <path
        d="M12 14.8C12.4 14.8 13.1 14.1 13.1 13.7C13.1 13.3 12 13.3 12 13.3C12 13.3 10.9 13.3 10.9 13.7C10.9 14.1 11.6 14.8 12 14.8Z"
        fill="currentColor"
      />
      <path
        d="M8.50001 15.2C9.60001 15.6 10.7 15.9 11.9 15.9C13.1 15.9 14.2 15.6 15.3 15.2C14.4 16.3 12.8 16.8 11.4 16.7C10.6 16.6 9.80001 16.3 9.20001 15.9C8.80001 15.6 8.60001 15.5 8.50001 15.2Z"
        fill="currentColor"
      />
      <path
        d="M9.09999 12.3999C9.29999 12.3999 9.49999 12.3999 9.79999 12.4999C9.89999 12.3999 9.89999 12.0999 9.89999 11.8999C9.89999 11.2999 9.49999 10.8999 9.09999 10.8999C8.69999 10.8999 8.29999 11.2999 8.29999 11.8999C8.29999 12.0999 8.39999 12.2999 8.39999 12.4999C8.59999 12.4999 8.79999 12.3999 9.09999 12.3999Z"
        fill="currentColor"
      />
      <path
        d="M14.9 12.3999C15.1 12.3999 15.3 12.3999 15.6 12.4999C15.7 12.3999 15.7 12.0999 15.7 11.8999C15.7 11.2999 15.3 10.8999 14.9 10.8999C14.5 10.8999 14.1 11.2999 14.1 11.8999C14.1 12.0999 14.2 12.2999 14.2 12.4999C14.5 12.4999 14.7 12.3999 14.9 12.3999Z"
        fill="currentColor"
      />
    </svg>
  )
})
