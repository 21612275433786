export const DEFAULT_CURRENCY_CODE: CurrencyCodes = 'EUR'

export type CurrencyCodes =
  | 'AUD'
  | 'BGN'
  | 'BRL'
  | 'CAD'
  | 'CHF'
  | 'CNY'
  | 'CZK'
  | 'DKK'
  | 'EUR'
  | 'GBP'
  | 'HKD'
  | 'HUF'
  | 'IDR'
  | 'ILS'
  | 'INR'
  | 'ISK'
  | 'JPY'
  | 'KRW'
  | 'MXN'
  | 'MYR'
  | 'NOK'
  | 'NZD'
  | 'PHP'
  | 'PLN'
  | 'RON'
  | 'SEK'
  | 'SGD'
  | 'THB'
  | 'TRY'
  | 'USD'
  | 'ZAR'

export interface Currency {
  code: CurrencyCodes
  name: string
}

export const frequentlyUsedCurrencies = ['DKK', 'NOK', 'SEK', 'EUR', 'GBP', 'USD']

export const currencies: Currency[] = [
  {
    code: 'GBP',
    name: 'British Pound Sterling'
  },
  {
    code: 'DKK',
    name: 'Danish Krone'
  },
  {
    code: 'EUR',
    name: 'Euro'
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone'
  },
  {
    code: 'SEK',
    name: 'Swedish Krona'
  },
  {
    code: 'USD',
    name: 'United States Dollar'
  },
  {
    code: 'AUD',
    name: 'Australian Dollar'
  },
  {
    code: 'BRL',
    name: 'Brazilian Real'
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev'
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar'
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan'
  },
  {
    code: 'CZK',
    name: 'Czech Koruna'
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar'
  },
  { code: 'HUF', name: 'Hungarian Forint' },
  {
    code: 'ISK',
    name: 'Icelandic Króna'
  },
  {
    code: 'INR',
    name: 'Indian Rupee'
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah'
  },
  {
    code: 'ILS',
    name: 'Israeli New Shekel'
  },
  {
    code: 'JPY',
    name: 'Japanese Yen'
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit'
  },
  {
    code: 'MXN',
    name: 'Mexican Peso'
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar'
  },
  {
    code: 'PHP',
    name: 'Philippine Peso'
  },
  {
    code: 'PLN',
    name: 'Polish Złoty'
  },
  {
    code: 'RON',
    name: 'Romanian Leu'
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar'
  },
  {
    code: 'ZAR',
    name: 'South African Rand'
  },
  {
    code: 'KRW',
    name: 'South Korean Won'
  },
  {
    code: 'CHF',
    name: 'Swiss Franc'
  },
  {
    code: 'THB',
    name: 'Thai Baht'
  },
  {
    code: 'TRY',
    name: 'Turkish Lira'
  }
]
