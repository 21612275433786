import React, { createContext, useState } from 'react'
import { ThemeProvider as MUIThemeProvider } from '@mui/system'
import CssBaseline from '@mui/material/CssBaseline'
import defaultTheme from '../../components/theme'

interface Props {
  children: React.ReactNode
}

/**
 * - MUIThemeProvider is used to provide theme object to the components
 * */

const ThemeProvider = ({ children }: Props): React.ReactElement => {
  return (
    <MUIThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}

const AppContext = createContext<{}>({})
AppContext.displayName = 'AppContext'

const AppProvider = ({ children }: Props): React.ReactElement => {
  const [defaultValue] = useState({})
  return (
    <AppContext.Provider value={defaultValue}>
      <ThemeProvider>{children}</ThemeProvider>
    </AppContext.Provider>
  )
}

export default AppProvider
