import React, { type SyntheticEvent, type ReactElement } from 'react'
import { type ControlledListViewTabs } from './types'
import { Tabs, Tab, Notification, Typography, Grid, Skeleton, styled } from '@precis-digital/kurama'
import { capitalize } from 'shared/utils'

interface ListViewTabsProps<T> {
  tabs: ControlledListViewTabs<T>
  handleTabChange: (event: SyntheticEvent<Element, Event>, newValue: number) => void
  data?: T[]
  isLoading: boolean
}

const ListViewTabs = <T,>({ tabs, handleTabChange, data, isLoading }: ListViewTabsProps<T>): ReactElement => {
  const translatorFunc = tabs.valueTranslator ?? ((value) => capitalize(value))
  return isLoading ? (
    <Grid container gap="24px">
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <Skeleton key={index} height={40} width="140px" />
        ))}
    </Grid>
  ) : (
    <Tabs value={tabs.value} handleChange={handleTabChange}>
      <Tab value={0} adornmentPosition="right">
        <Typography variant="h5">{tabs.allOptionLabel ?? 'All'}</Typography>
        <StyledNotification
          disabled={(data?.length ?? 0) === 0}
          variant="tonal"
          value={(data?.length ?? 0).toString()}
        />
      </Tab>
      {tabs.options.map((tab) => (
        <Tab key={tab.label} value={tab.tabValue} adornmentPosition="right" disabled={tab.count === 0}>
          <Typography variant="h5">{translatorFunc(tab.label.toString())}</Typography>
          <StyledNotification disabled={tab.count === 0} variant="tonal" value={tab.count.toString()} />
        </Tab>
      ))}
    </Tabs>
  )
}

export default ListViewTabs

const StyledNotification = styled(Notification)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  ...(disabled === true && { opacity: '0.7' })
}))
