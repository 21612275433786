import React, { useState, useEffect } from 'react'
import {
  PopUp,
  Typography,
  styled,
  Button,
  Grid,
  ArrowLeftIcon,
  Avatar,
  Input,
  CopyIcon,
  Divider,
  Banner
} from '@precis-digital/kurama'
import OverScreen from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { type IPlatform } from 'dataSource/constants'
import { useCurrentClient } from 'shared/context/ClientContext'
import { copyToClipboard } from 'shared/utils'
import { makeSuccessToast, makeToastWithLoading } from 'shared/components/Toaster'
import { useAddAccount, useReconnectAccount } from 'dataSource/api'
import { isValidGcpProjectId } from 'dataSource/utils'
import { useClientServiceAccountEmail } from 'shared/hooks/useClientServiceAccountEmail'

interface IntitialGCPProject {
  initialProjectId: string
  accountId: number
}

export interface ServiceAccountAccessPopupProps {
  datasource: IPlatform
  initialGCPProject?: IntitialGCPProject
}

export const ServiceAccountAccessPopup = ({
  datasource,
  initialGCPProject = undefined
}: ServiceAccountAccessPopupProps): React.ReactElement => {
  const { t: tDataSource } = useTranslation('dataSource')
  const { t } = useTranslation('dataSource', { keyPrefix: 'popups.serviceAccountAccessPopup' })
  const { currentClient } = useCurrentClient()
  const [targetGCPProjectId, setTargetGCPProjectId] = useState(initialGCPProject?.initialProjectId ?? '')
  const hastargetGCPProjectValidationError = !isValidGcpProjectId(targetGCPProjectId)
  const [errorOnAddingDataSource, setErrorOnAddingDataSource] = useState(false)
  const { mutateAsync: addAccount } = useAddAccount()
  const { mutateAsync: reconnectAccount } = useReconnectAccount()
  const clientServiceAccount = useClientServiceAccountEmail()

  useEffect(() => {
    setErrorOnAddingDataSource(false)
  }, [targetGCPProjectId])

  const handleConnectDataSource = async (): Promise<void> => {
    setErrorOnAddingDataSource(false)
    const { toastOnSuccess, cancelToast } = makeToastWithLoading()
    if (initialGCPProject != null && initialGCPProject?.initialProjectId !== '') {
      try {
        await reconnectAccount({
          clientId: currentClient.id,
          accountId: initialGCPProject?.accountId.toString() ?? '',
          credentialsId: null
        })
        OverScreen.remove('serviceAccountAccessPopup')
        OverScreen.remove('userOrServiceAccountSelectionPopup')
        toastOnSuccess(t('successfullyAddedDataSource', { externalAccountId: targetGCPProjectId }))
      } catch {
        setErrorOnAddingDataSource(true)
        cancelToast()
      }
    } else {
      try {
        await addAccount({
          name: targetGCPProjectId,
          clientId: currentClient.id,
          platform: 'gcp',
          externalAccountId: targetGCPProjectId
        })
        OverScreen.remove('serviceAccountAccessPopup')
        OverScreen.remove('connectNewDataSourcePopup')
        toastOnSuccess(t('successfullyAddedDataSource', { externalAccountId: targetGCPProjectId }))
      } catch (error) {
        setErrorOnAddingDataSource(true)
        cancelToast()
      }
    }
  }

  const handleClose = (): void => {
    OverScreen.remove('serviceAccountAccessPopup')
  }

  const handleCopyServiceAccountClick = (): void => {
    void copyToClipboard(clientServiceAccount)
    makeSuccessToast(t('copiedServiceAccount'))
  }

  return (
    <PopUp open handleOpen={handleClose}>
      <StyledHeader>
        <StyledBackButton variant="text" onClick={handleClose} leftIcon={<ArrowLeftIcon />}>
          {tDataSource('buttons.backButton')}
        </StyledBackButton>
      </StyledHeader>
      <StyledTitleContainer>
        <Avatar size="large" imageUrl={datasource.iconUrl} kind="image" />
        <Typography variant="h2">{t('grantAccessFirstTitle')}</Typography>
      </StyledTitleContainer>
      {errorOnAddingDataSource && (
        <Banner variant="error">
          <Typography variant="body2">
            <strong>{t('ohSnap')}</strong>{' '}
            {t('somethingWentWrongAccessing', {
              gcpProjectId: targetGCPProjectId
            })}
          </Typography>
        </Banner>
      )}
      <StyledInputExplainerText>
        <Avatar size="small" imageUrl={datasource.iconUrl} kind="image" />
        <Typography variant="body2">
          <strong>
            {t('enterYourAccountId', {
              datasourceLabel: datasource.label,
              externalEntityLabel: datasource.externalEntityLabel
            })}
          </strong>
        </Typography>
        <Typography variant="body3">{t('required')}</Typography>
      </StyledInputExplainerText>
      <Typography variant="body3">{t('projectIdExplainer')}</Typography>
      <StyledInputContainer>
        <Input
          name={t('gcpProjectId')}
          placeholder={t('gcpProjectId')}
          onChange={(e) => {
            setTargetGCPProjectId(e.target.value)
          }}
          value={targetGCPProjectId}
          fullWidth
          {...(targetGCPProjectId.length !== 0 &&
            hastargetGCPProjectValidationError && {
              color: 'error',
              helperText: t('invalidProjectId')
            })}
          disabled={initialGCPProject != null && initialGCPProject?.initialProjectId !== ''}
        />
      </StyledInputContainer>
      <StyledSecondTitleContainer>
        <Typography variant="h3"> {t('grantAccessSecondTitle')}</Typography>
      </StyledSecondTitleContainer>
      <Typography variant="body1">{t('pleaseGrantPermissions')}</Typography>
      <StyledRolesContainer>
        <StyledListContainer>
          <StyledListItem>
            <Typography variant="body1">{t('bigqueryDataEditor')}</Typography>
          </StyledListItem>
          <StyledListItem>
            <Typography variant="body1">{t('bigqueryJobUser')}</Typography>
          </StyledListItem>
        </StyledListContainer>
        <Typography variant="body1" style={{ marginTop: '8px' }}>
          {t('or')}
        </Typography>
        <StyledListContainer>
          <StyledListItem>
            <Typography variant="body1">{t('bigqueryAdmin')}</Typography>
          </StyledListItem>
        </StyledListContainer>
      </StyledRolesContainer>

      <Typography variant="body2">
        <strong>{t('copyServiceAccountBelow')}</strong>
      </Typography>
      <StyledServiceAccountCopyContainer>
        <Typography variant="body3">{clientServiceAccount}</Typography>
        <RightAlignContainer>
          <Button variant="text" rightIcon={<CopyIcon />} onClick={handleCopyServiceAccountClick}>
            {t('copy')}
          </Button>
        </RightAlignContainer>
      </StyledServiceAccountCopyContainer>
      <BottomExplanationContainer>
        <Typography variant="body3">{t('copyServiceAccountExplainer')}</Typography>
        <StyledMightTakeAfewMinutesTypography variant="body3">
          {t('mightTakeAFewMinutes')}
        </StyledMightTakeAfewMinutesTypography>
      </BottomExplanationContainer>
      <Divider />
      <StyledActions>
        <Button variant="tonal" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button
          onClick={() => {
            void handleConnectDataSource()
          }}
          scheme="light"
          variant="filled"
          disabled={targetGCPProjectId.length === 0 || hastargetGCPProjectValidationError}
        >
          {t('connect')}
        </Button>
      </StyledActions>
    </PopUp>
  )
}

const BottomExplanationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
})

const StyledListContainer = styled('ul')({
  margin: 0,
  padding: 0,
  paddingLeft: '24px'
})

const StyledListItem = styled('li')({
  display: 'list-item',
  alignItems: 'center',
  textAlign: 'left',
  listStylePosition: 'outside',
  marginBottom: '8px',
  '&:last-child': {
    marginTop: '8px',
    marginBottom: 0
  }
})

export const StyledMightTakeAfewMinutesTypography = styled(Typography)(() => ({
  fontStyle: 'italic'
}))

const RightAlignContainer = styled('div')({
  marginLeft: 'auto'
})

export const StyledRolesContainer = styled(Grid)(() => ({
  paddingTop: '8px',
  paddingBottom: '24px',
  listStylePosition: 'inside'
}))

export const StyledSecondTitleContainer = styled(Grid)(() => ({
  paddingBottom: '8px'
}))

export const StyledInputContainer = styled('div')(() => ({
  paddingTop: '8px',
  paddingBottom: '24px'
}))
export const StyledServiceAccountCopyContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
  paddingBottom: '12px'
}))

export const StyledInputExplainerText = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'left',
  gap: '4px',
  marginTop: '10px',
  marginBottom: '10px'
}))

export const StyledActions = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: 0,
  gap: 8,
  width: '100%'
}))

const StyledBackButton = styled(Button)(() => ({
  position: 'absolute',
  top: '-60px',
  left: '-16px'
}))

const StyledTitleContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px'
}))

const StyledHeader = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  '& h2': {
    marginTop: '25px',
    marginBottom: '27px'
  }
}))

export default OverScreen.create(ServiceAccountAccessPopup)
