import React, { ReactElement, forwardRef } from 'react'
import { DotsLoaderProps } from './types'
import { StyledDivLoaderContainer } from './styles'
import defaultTheme from '../theme'

const DotsLoader = forwardRef<HTMLDivElement, DotsLoaderProps>(
  (
    {
      height = 40,
      width = 40,
      radius = 10,
      color = defaultTheme.palette.primary.main,
      dataTestId = 'three-dots-loading',
      ...props
    },
    ref
  ): ReactElement => {
    return (
      <StyledDivLoaderContainer ref={ref} {...props}>
        <svg
          width={width}
          height={height}
          viewBox="0 0 120 30"
          xmlns="http://www.w3.org/2000/svg"
          fill={color}
          data-testid={dataTestId}
        >
          <circle cx="15" cy="15" r={Number(radius) + 6}>
            <animate
              attributeName="r"
              from="15"
              to="15"
              begin="0s"
              dur="1.5s"
              values="15;9;15"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              from="1"
              to="1"
              begin="0s"
              dur="1.5s"
              values="1;.5;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="60" cy="15" r={radius} attributeName="fill-opacity" from="1" to="0.3">
            <animate
              attributeName="r"
              from="9"
              to="9"
              begin="0s"
              dur="1.5s"
              values="9;15;9"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              from="0.5"
              to="0.5"
              begin="0s"
              dur="1.5s"
              values=".5;1;.5"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="105" cy="15" r={Number(radius) + 6}>
            <animate
              attributeName="r"
              from="15"
              to="15"
              begin="0s"
              dur="1.5s"
              values="15;9;15"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              from="1"
              to="1"
              begin="0s"
              dur="1.5"
              values="1;.5;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </StyledDivLoaderContainer>
    )
  }
)

export default DotsLoader
