import { type IPlatform } from 'dataSource/constants'
import { type Platforms } from './accounts'
import fetch from './fetch'
const client = fetch()

export interface IntegrationAttributionResp {
  id: number
  clientId: string
  clientName: string
  name: string
  sourceProjectId: number
  sourceProject: string
  sourceAuthAccount: string
  targetProjectId: string
  targetProject: string
  targetAuthAccount: string
  sourceDataset: string
  targetDataset: string
  targetCurrency: string
  lookbackDays?: number
  xgboostLookback?: number
  xgboostModelLookback?: number
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
  creditAllocator?: boolean
  performanceCurves?: boolean
  reallocations?: Reallaction
  analyticsAccount?: AnalyticsAccountOutput
  adAccounts: AddAccountOutput[]
  runSchedule: boolean
  schedule: string
  creditInput: 'ddav2' | 'xgboost' | 'native'
}

export interface Reallaction {
  channel: string
  allocation: number
}

export interface AnalyticsAccountOutput {
  accountId: number
  filterConditions?: FilterCondition
  externalAccountId: string
  platform: string
  has360?: boolean
  accountName: string
  channelGrouping: [ChannelDefinitionAnalytics]
  userIdIndex?: number
  channelGroupingSql?: string
  credentialsUsed: string
}

export type AddAccountOutput = {
  accountId: number
  accountName: string
  channelGrouping: ChannelGrouping
  channelGroupingSql: string
  connectorsConfigs: number
  externalAccountId: string
  filterConditions: FilterCondition
  gclidBiddingChannels: string
  impressionsChannels?: string
  platform: Platforms
  filterConditionsSql?: string
  conversionField?: string
  clickField?: string
} & Pick<IPlatform, 'iconUrl'>

export interface FilterCondition {
  name: string
  rules: Rule
}

export interface Rule {
  field: string
  operator: string
  value: string
}

export interface ChannelGrouping {
  exclude: boolean
  impressionChannel: boolean
  name: Platforms
  rules: Rule
}

export interface ChannelDefinitionAnalytics {
  name: string
  exclude?: boolean
  rules: Rule
}

export const paths = {
  integrationAttributions: '/integrated_attributions'
}

export interface IntegrationAttributionsResp extends Array<IntegrationAttributionResp> {}

export const getBudgetOptimiserConfigs = async (clientId: string): Promise<IntegrationAttributionsResp> => {
  return await client.get<IntegrationAttributionsResp>({
    path: `${paths.integrationAttributions}?client_id=${clientId}&performance_curves=true`
  })
}

export const getBudgetOptimiserConfig = async (
  clientId: string,
  modelId: number
): Promise<IntegrationAttributionResp> => {
  return await client.get<IntegrationAttributionResp>({
    path: `${paths.integrationAttributions}/${modelId}?client_id=${clientId}`
  })
}
