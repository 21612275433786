import React from 'react'
import { Typography, Grid, Link, styled, Toggle } from '@precis-digital/kurama'
import { useQueryMembers } from 'workspace/api'
import Dots from 'shared/components/Loader/Dots'
import { useCurrentClient } from 'shared/context/ClientContext'
import { StyledAdminCard, StyledBoldText, StyledAssignedText, StyledAdminWrapper } from '../Popup/styles'
import { useAuth } from 'shared/context/AuthContext'
import { useTranslation } from 'react-i18next'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'

interface MembersViewProps {
  id: string
  onMemberSelected: (id: string) => void
}

const StyledLinkSpan = styled('span')({
  fontSize: '16px',
  fontFamily: 'Graphik-Regular'
})

export const MembersView = (props: MembersViewProps): React.ReactElement => {
  const { currentClient } = useCurrentClient()
  const { currentUser } = useAuth()
  const { t } = useTranslation('user')

  const { data: members, isLoading: isLoadingMembers, isSuccess: isMembersSuccess } = useQueryMembers(currentClient.id)

  return (
    <>
      <Typography variant="h3"> {t('titles.assign')}</Typography>
      <StyledAssignedText variant="h4">
        {t('descriptions.assignPart1')}
        <Link>
          <StyledLinkSpan>{t('descriptions.assignLink')}</StyledLinkSpan>
        </Link>
        {t('descriptions.assignPart2')}
      </StyledAssignedText>
      {isLoadingMembers ? (
        <Dots />
      ) : isMembersSuccess ? (
        members
          .filter((member) => currentUser?.id !== member.id)
          .map((member) => (
            <StyledAdminWrapper key={member.id}>
              <StyledAdminCard key={member.id}>
                <AvatarWithLabel size="medium" user={member} />
                <Grid>
                  <StyledBoldText variant="body2">{member.name}</StyledBoldText>
                  <Typography variant="body3">{member.email}</Typography>
                </Grid>
              </StyledAdminCard>
              <Toggle
                defaultChecked={false}
                onChange={() => {
                  props.onMemberSelected(member.id)
                }}
                checked={props.id === member.id}
              />
            </StyledAdminWrapper>
          ))
      ) : null}
    </>
  )
}
