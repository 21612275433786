import { Drawer as MUIDrawer } from '@mui/material'
import Grid from '../Grid'
import { styled, alpha } from '../../utils'
import { CSSObject } from '@mui/material/styles'
import { Theme } from '../theme'

export const StyledFlyout = styled(MUIDrawer)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    '.MuiPaper-root.MuiDrawer-paper': {
      overflowY: 'auto',
      border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
      borderTopLeftRadius: `${theme?.borderRadius.xxLarge ?? '32px'}`,
      borderBottomLeftRadius: `${theme?.borderRadius.xxLarge ?? '32px'}`,
      boxShadow: '0px 16px 32px rgba(112, 144, 176, 0.2)',
      padding: '24px',
      paddingBottom: '16px',
      width: '400px',
      zIndex: theme?.zIndex.flyout
    },
    '.MuiBackdrop-root': {
      backgroundColor: alpha(`${theme?.palette.graphs.steel100 ?? '#9AB3BA'}`, 0.5)
    }
  })
)

export const StyledGridTopButtonsContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}))

export const StyledAvatarContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    textAlign: 'center',
    paddingBottom: '6px',
    '>div': {
      backgroundColor: theme?.palette.graphs.mint100,
      margin: '0 auto',
      marginTop: '20px',
      p: {
        color: theme?.palette.neutrals.white0,
        fontSize: theme?.typography.h3.fontSize,
        fontWeight: theme?.typography.h3.fontWeight
      }
    },
    h2: {
      marginTop: '25px',
      marginBottom: '3px'
    },
    button: {
      display: 'inline-flex',
      marginBottom: '6px',
      marginTop: '16px',
      width: '100%'
    }
  })
)
