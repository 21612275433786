import { useEffect, useState } from 'react'

export const useResizeObserver = <T extends HTMLElement>(
  ref: React.RefObject<T>
): { width: number; height: number } => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const currentRef = ref.current

    if (currentRef == null) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0]
      if (entry != null) {
        const { width, height } = entry.contentRect
        setDimensions({ width, height })
      }
    })

    observer.observe(currentRef)

    return () => {
      observer.unobserve(currentRef)
    }
  }, [ref])

  return dimensions
}
