import rocketIcon from 'public/assets/images/rocket.png'
import React from 'react'
import { Typography, styled, Grid, Link } from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'
import { useQueryClientAccounts } from 'dataSource/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import Image from 'next/image'
import OverScreen from 'shared/overScreens'

export default function NoRecommendations(): React.ReactElement {
  const { t } = useTranslation('recommendation')
  const { currentClient } = useCurrentClient()
  const { data: clientAccounts, isSuccess } = useQueryClientAccounts(currentClient.id)
  const hasDataSources = isSuccess && clientAccounts?.length > 0
  const handleAddDataSourceClick = (): void => {
    void OverScreen.show('connectNewDataSourcePopup')
  }
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" gap="24px">
      <Grid container direction="column" alignItems="center" width="80%">
        <StyledGridImageContainer>
          <Image src={rocketIcon} alt="Rocket ship" style={{ width: '100%', height: 'auto' }} />
        </StyledGridImageContainer>
        <Grid display="flex" alignItems="center" direction="column" padding="0px 15%" textAlign="center" gap="8px">
          <Typography variant="h4">{t('noDataTitle')}</Typography>
          {hasDataSources ? (
            <Typography variant="body3">
              {t('noRecommendationsDescription')}{' '}
              <Link variant="body3" href="data-sources">
                {t('dataSources')}
              </Link>{' '}
              {t('checkBackLater')}
            </Typography>
          ) : (
            <Typography variant="body3">
              {t('noDataSourcesDescription')}
              {'  '}
              <Link variant="body3" onClick={handleAddDataSourceClick}>
                {t('addADataSource')}
              </Link>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const StyledGridImageContainer = styled(Grid)(() => ({
  width: '45%',
  marginTop: '32px',
  transform: 'translateX(32.93%)', // The center of the rocket in the image is 32.93% to the left of the middle.
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))
