import { forwardRef, ReactElement } from 'react'
import { DROPDOWN_LIST_CLASS_NAME } from '../classNames'
import { StyledUlDropdownList, StyledTypographyCategoryHeader } from './styles'
import { DropdownListProps } from './types'
import Checkbox from '../../Checkbox'
import Grid from '../../Grid'

export const DropdownList = forwardRef<HTMLUListElement, DropdownListProps>(
  (
    {
      header,
      headerControlOnChange,
      headerControlValue,
      minWidth = 0,
      maxWidth = 396,
      dense = false,
      children,
      ...props
    },
    ref
  ): ReactElement => {
    return (
      <StyledUlDropdownList
        className={DROPDOWN_LIST_CLASS_NAME}
        dense={dense}
        minWidth={minWidth}
        maxWidth={maxWidth}
        {...props}
        ref={ref}
      >
        {header !== undefined && (
          <Grid display="flex" justifyContent="space-between" alignItems="flex-end" width="100%">
            <StyledTypographyCategoryHeader variant="h4">{header}</StyledTypographyCategoryHeader>
            {headerControlOnChange != null && headerControlValue != null && (
              <Checkbox
                onChange={headerControlOnChange}
                checked={headerControlValue === 'checked'}
                indeterminate={headerControlValue === 'indeterminate'}
              />
            )}
          </Grid>
        )}
        {children}
      </StyledUlDropdownList>
    )
  }
)

export default DropdownList
