import { ChannelGroupingProvider } from 'channelGrouping/context/ChannelGroupingContext'
import React, { type ReactElement } from 'react'
import MainFormPage from './MainFormPage'
import DataSourceFormPage from './DataSourceFormPage'
import { type Platforms } from 'shared/api/accounts'

interface FormPageProps {
  tabId: string
  channelGroupingId: string
  dataSource?: Platforms
}

const FormPage = ({ tabId, channelGroupingId, dataSource }: FormPageProps): ReactElement => {
  return (
    <ChannelGroupingProvider channelGroupingId={parseFloat(channelGroupingId)}>
      {dataSource == null ? (
        <MainFormPage tabId={tabId} />
      ) : (
        <DataSourceFormPage tabId={tabId} dataSource={dataSource} />
      )}
    </ChannelGroupingProvider>
  )
}

export default FormPage
