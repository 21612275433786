import { Input } from '@mui/material'
import { styled } from '../../../utils'
import { Theme } from '../../theme'
import Grid from '../../Grid'
import Typography from '../../Typography'
import { InputState } from '../types'

export const StyledInput = styled(Input)(({ theme, state }: { theme?: Theme; state: InputState }) => ({
  width: '100%',
  input: {
    pointerEvents: state === 'focused' ? 'auto' : 'none',
    padding: 0,
    border: '1px solid red',
    fontSize: theme?.typography.body2.fontSize,
    lineHeight: theme?.typography.body2.lineHeight
  }
}))

export const StyledGridInputContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '3px 0px 1px 0px',
  gap: '4px',
  p: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

export const StyledTypographyPlaceholder = styled(Typography)(({ theme }: { theme?: Theme }) => ({
  color: theme?.palette.neutrals.stone120
}))
