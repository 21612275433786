import { Grid, HistoryIcon, Link, Typography } from '@precis-digital/kurama'
import { useQueryUserProfile } from 'profile/api'
import React, { type ReactElement } from 'react'
import { formatDateTimeToAtFormat } from 'shared/dateFns'
import OverScreen from 'shared/overScreens'

interface MemberTimestampLineProps {
  timestamp: string | number | Date
  memberId?: string
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}

const MemberTimestampLine = ({ timestamp, memberId, t }: MemberTimestampLineProps): ReactElement => {
  const { data: userDetails, isLoading: isUserDetailsLoading } = useQueryUserProfile(memberId)

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.stopPropagation()
    e.preventDefault()
    void OverScreen.show('profileFlyout', { id: memberId })
  }

  return (
    <Grid display="flex" alignItems="center" gap="4px">
      <HistoryIcon />
      <Typography variant="body3">
        {t('memberTimestamp', { timestamp: formatDateTimeToAtFormat(timestamp), by: memberId != null ? ' by' : '' })}
        {memberId != null && (
          <>
            {' '}
            <Link href="" onClick={handleLinkClick}>
              <Typography variant="body3">
                {isUserDetailsLoading ? '...' : userDetails?.name ?? t('memberTimestampUnknown')}
              </Typography>
            </Link>
          </>
        )}
      </Typography>
    </Grid>
  )
}

export default MemberTimestampLine
