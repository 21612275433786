import { Typography, styled } from '@precis-digital/kurama'
import { type ReactNode, type ReactElement } from 'react'
interface TruncatedTextWithTooltipProps {
  children: ReactNode
  tooltipText: string
}

const TruncatedTextWithTooltip = ({ children, tooltipText }: TruncatedTextWithTooltipProps): ReactElement => {
  return (
    <StyledTruncatedText variant="body2" title={tooltipText}>
      {children}
    </StyledTruncatedText>
  )
}

const StyledTruncatedText = styled(Typography)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}))

export default TruncatedTextWithTooltip
