import React from 'react'

export const Info = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M4 11.5659C4 7.8706 6.99561 4.875 10.6909 4.875H13.3091C17.0044 4.875 20 7.87061 20 11.5659V14.1841C20 17.8794 17.0044 20.875 13.3091 20.875H10.6909C6.9956 20.875 4 17.8794 4 14.1841V11.5659Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 12.831L12 16.0888"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.66125L12 9.71809"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
