import { Button, EditIcon, PlusIcon, styled } from '@precis-digital/kurama'
import React, { type ReactElement } from 'react'
import { type CaseStatementDimension } from 'reportingSolution/constants'
import { useTranslation } from 'shared/translations'
import OverScreen from 'shared/overScreens'
import { type CaseStatementBuilderPopupProps } from 'reportingSolution/components/CaseStatementBuilder/CaseStatementBuilderPopup'

interface CaseStatementBuilderProps {
  value?: string
  platform?: string
  label: string
  dimensions: CaseStatementDimension[]
  onChange: (value: string) => void
}

const CaseStatementBuilder = ({
  value,
  platform,
  label,
  dimensions,
  onChange
}: CaseStatementBuilderProps): ReactElement => {
  const { t } = useTranslation('reportingSolutions')

  const handleSave = (value: string): void => {
    onChange(value)
  }

  const isEditing = value != null && value !== ''
  const title = isEditing ? t('form.editChannel', { label }) : t('form.createChannel', { label })

  const handleOpenPopup = (value?: string): void => {
    void OverScreen.show<CaseStatementBuilderPopupProps>('caseStatementBuilderPopup', {
      title,
      t,
      value: value ?? '',
      dimensions,
      handleSave,
      label,
      platform
    })
  }

  return (
    <>
      {isEditing ? (
        <StyledButton
          variant="filled"
          leftIcon={<EditIcon />}
          onClick={() => {
            handleOpenPopup(value)
          }}
        >
          {title}
        </StyledButton>
      ) : (
        <StyledButton
          variant="outlined"
          leftIcon={<PlusIcon />}
          onClick={() => {
            handleOpenPopup()
          }}
        >
          {title}
        </StyledButton>
      )}
    </>
  )
}

const StyledButton = styled(Button)(() => ({
  alignSelf: 'flex-start !important'
}))

export default CaseStatementBuilder
