import Checkbox from '@mui/material/Checkbox'
import { styled } from '../../utils'
import { Theme, CSSObject } from '../theme'
import Grid from '../Grid'
import { DROPDOWN_ITEM_CLASS_NAME } from '../Dropdown/classNames'
import { listItemClasses } from '@mui/material'

export const StyledCheckbox = styled(Checkbox)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.neutrals.white0,
    '&.Mui-focusVisible': {
      '> div': {
        border: `1px solid ${theme?.palette.semantic.info100 ?? ''}`,
        boxShadow: `0px 0px 0px 3px ${theme?.palette.neutrals.stone100 ?? ''}`
      }
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& > div': {
        backgroundColor: theme?.palette.neutrals.stone90,
        color: theme?.palette.secondary.main
      }
    },
    '&:active': {
      '& > div': {
        backgroundColor: theme?.palette.main.blue50,
        border: 'none',
        color: theme?.palette.neutrals.white0
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):hover &`]: {
      backgroundColor: 'transparent',
      '& > div': {
        backgroundColor: theme?.palette.neutrals.stone90,
        color: theme?.palette.secondary.main
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled).${listItemClasses.focusVisible} &`]: {
      '& > div': {
        backgroundColor: theme?.palette.neutrals.stone90,
        color: theme?.palette.secondary.main
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):active &`]: {
      '& > div': {
        backgroundColor: theme?.palette.main.blue50,
        border: 'none',
        color: theme?.palette.neutrals.white0
      }
    },

    '&.Mui-disabled': {
      '& > div': {
        backgroundColor: theme?.palette.neutrals.stone90,
        color: theme?.palette.neutrals.stone90
      }
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme?.palette.neutrals.white0,
      '&.Mui-focusVisible': {
        '> div': {
          boxShadow: `0px 0px 0px 3px ${theme?.palette.neutrals.stone100 ?? ''}`
        }
      },
      '&:hover': {
        '& > div': {
          backgroundColor: theme?.palette.main.blue90,
          color: theme?.palette.neutrals.white0
        }
      },
      '&:active': {
        '& > div': {
          backgroundColor: theme?.palette.main.blue110
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):hover &`]: {
        '& > div': {
          backgroundColor: theme?.palette.main.blue90,
          color: theme?.palette.neutrals.white0
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled).${listItemClasses.focusVisible} &`]: {
        '& > div': {
          backgroundColor: theme?.palette.main.blue90,
          color: theme?.palette.secondary.main
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):active &`]: {
        '& > div': {
          backgroundColor: theme?.palette.main.blue110
        }
      },
      '&.Mui-disabled': {
        '& > div': {
          backgroundColor: theme?.palette.neutrals.stone90,
          color: theme?.palette.neutrals.stone120
        }
      }
    },
    '&.Mui-checked': {
      color: theme?.palette.neutrals.white0,
      '&.Mui-focusVisible': {
        '> div': {
          boxShadow: `0px 0px 0px 3px ${theme?.palette.neutrals.stone100 ?? ''}`
        }
      },
      '&:hover': {
        '& > div': {
          backgroundColor: theme?.palette.main.blue90,
          color: theme?.palette.neutrals.white0
        }
      },
      '&:active': {
        '& > div': {
          backgroundColor: theme?.palette.main.blue110
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):hover &`]: {
        '& > div': {
          backgroundColor: theme?.palette.main.blue90,
          color: theme?.palette.neutrals.white0
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled).${listItemClasses.focusVisible} &`]: {
        '& > div': {
          backgroundColor: theme?.palette.main.blue90,
          color: theme?.palette.neutrals.white0
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):active &`]: {
        '& > div': {
          backgroundColor: theme?.palette.main.blue110
        }
      },
      '&.Mui-disabled': {
        '& > div': {
          backgroundColor: theme?.palette.neutrals.stone90,
          color: theme?.palette.neutrals.stone120
        }
      }
    }
  })
)

export const StyledCheckboxFrame = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    height: '32px',
    width: '32px',
    border: `2px solid ${theme?.palette.neutrals.stone100 ?? ''}`,
    borderRadius: theme?.borderRadius.small,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
)

export const StyledCheckboxInside = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    height: '32px',
    width: '32px',
    borderRadius: theme?.borderRadius.small,
    backgroundColor: theme?.palette.main.blue100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
)
