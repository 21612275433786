import {
  Accordion,
  Button,
  ChevronDownIcon,
  Divider,
  Flyout,
  Grid,
  defaultTheme as theme,
  styled,
  Typography,
  CancelBkgIcon,
  SelectInput,
  SelectItem,
  Input,
  DropdownItem,
  DropdownList,
  Radio,
  Notification,
  TrashIcon,
  Autocomplete,
  type AutocompleteOptionItem,
  type Theme
} from '@precis-digital/kurama'
import { type ReactNode, useState, useEffect } from 'react'
import OverScreen from 'shared/overScreens/niceModalReact'
import { type ActiveFilter, type OperatorOptions, type Option, type Operator } from '.'
import { equals } from 'ramda'

const spacing = theme.spacing

type FilterTabs = 'newFilter' | 'activeFilters'
export interface FilterFlyoutProps {
  parameterOptions: Option[]
  operatorOptions: OperatorOptions
  activeFilters: ActiveFilter[]
  onUpdateFilters: (filters: ActiveFilter[]) => void
  parseFilterToListItemLabel: (filter: ActiveFilter) => ReactNode
  showActiveFilters?: boolean
  t: (key: string, options?: Record<string, unknown> | undefined) => string
  onHide: () => void
  isFlyoutOpen: boolean
}

export const FilterFlyout = ({
  parameterOptions,
  operatorOptions,
  onUpdateFilters,
  parseFilterToListItemLabel,
  t,
  onHide,
  activeFilters = [],
  showActiveFilters = false,
  isFlyoutOpen
}: FilterFlyoutProps): React.ReactElement => {
  const [selectedActiveFilters, setSelectedActiveFilters] = useState<ActiveFilter[]>(activeFilters)

  const [selectedParameter, setSelectedParameter] = useState<Option | null>(null)
  const [selectedOperator, setSelectedOperator] = useState<Operator | null>(null)
  const [selectedData, setSelectedData] = useState<ActiveFilter['data'] | null>(null)

  const [expandedParameter, setExpandedParameter] = useState(true)

  const sortInAlphabeticalOrder = (a: Option, b: Option): number => a.label.localeCompare(b.label)

  if (selectedParameter == null && parameterOptions.length === 1) {
    setSelectedParameter(parameterOptions[0])
    setExpandedParameter(false)
  }

  const [isOpen, setIsOpen] = useState(isFlyoutOpen)

  const handleClearFilter = (): void => {
    setSelectedParameter(null)
    setSelectedOperator(null)
    setSelectedData(null)
    setExpandedParameter(true)
  }

  const handleClearAll = (): void => {
    setSelectedActiveFilters([])
    onUpdateFilters([])
    setSelectedTab('newFilter')
  }

  useEffect(() => {
    if (selectedParameter == null && selectedOperator == null) return
    setSelectedData(null)
  }, [selectedParameter, selectedOperator])

  const [selectedTab, setSelectedTab] = useState<FilterTabs>(showActiveFilters ? 'activeFilters' : 'newFilter')

  const disableApplyButton = selectedParameter == null || selectedData == null

  const handleClose = (): void => {
    handleApplyFilter()
    setIsOpen(false)
    onHide()
  }

  const handleApplyFilter = (): void => {
    if (selectedParameter != null && selectedOperator != null && selectedData != null) {
      const filter: ActiveFilter = {
        parameter: {
          value: selectedParameter.value,
          label: selectedParameter.label
        },
        operator: selectedOperator.id,
        data: selectedData
      }
      setSelectedActiveFilters([...selectedActiveFilters, filter])
      onUpdateFilters([...selectedActiveFilters, filter])
    }
  }

  const AddNewFilter = (
    <Grid paddingBottom={spacing(8)} maxHeight="80%" overflow="hidden auto">
      <Grid>
        <StyledAccordion
          onChange={(_, expanded) => {
            setExpandedParameter(expanded)
          }}
          expanded={expandedParameter}
          Summary={
            <StyledSummaryWrapper>
              <Typography variant="h3">{t('filter.filterParameterLabelText')}</Typography>
            </StyledSummaryWrapper>
          }
          Details={
            <Grid paddingLeft={spacing(2)} paddingRight={spacing(2)}>
              <DropdownList style={{ padding: 0 }}>
                {parameterOptions.sort(sortInAlphabeticalOrder).map((option) => {
                  return (
                    <DropdownItem
                      key={option.value}
                      onClick={() => {
                        setSelectedParameter(option)
                        setExpandedParameter(false)
                      }}
                      control={<Radio checked={selectedParameter?.value === option.value} />}
                    >
                      {option.label}
                    </DropdownItem>
                  )
                })}
              </DropdownList>
            </Grid>
          }
          expandIcon={<ChevronDownIcon />}
        />
        {!expandedParameter && selectedParameter != null && (
          <Grid>
            <Typography variant="body2">{selectedParameter?.label}</Typography>
          </Grid>
        )}
      </Grid>
      <Divider />
      <Grid>
        <StyledConditionAccordion
          expanded={selectedParameter != null}
          disabled={selectedParameter == null}
          Summary={
            <StyledSummaryWrapper>
              <Typography variant="h3">{t('filter.createFilterConditions')}</Typography>
            </StyledSummaryWrapper>
          }
          Details={
            <Grid container flexDirection="column" gap={spacing(6)}>
              <Grid container flexDirection="column" gap={spacing(1)}>
                <Typography variant="h5">{t('filter.selectOperator')}</Typography>
                <SelectInput
                  name="operator"
                  value={selectedOperator?.id ?? ''}
                  fullWidth
                  onChange={(event) => {
                    const conditionOperator = operatorOptions[event.target.value as string]
                    setSelectedOperator(conditionOperator)
                  }}
                >
                  {Object.values(operatorOptions).map((dimension, index) => {
                    return (
                      <SelectItem value={dimension.id} key={index}>
                        <Typography variant="body2">{dimension.label}</Typography>
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              </Grid>
              <Grid container flexDirection="column" gap={spacing(1)}>
                {selectedOperator == null || selectedOperator.type === 'string' ? (
                  <>
                    <Typography variant="h5">{t('filter.value')}</Typography>
                    <Input
                      name="value"
                      value={(selectedData as string) ?? ''}
                      fullWidth
                      onChange={(event) => {
                        setSelectedData(event.target.value)
                      }}
                    />
                  </>
                ) : selectedOperator.type === 'single_select' ? (
                  <>
                    <Typography variant="h5">{t('filter.value')}</Typography>
                    <SelectInput
                      name="value"
                      fullWidth
                      onChange={(event) => {
                        setSelectedData({
                          value: event.target.value as string,
                          label:
                            selectedParameter?.options?.find((option) => option.value === event.target.value)?.label ??
                            ''
                        })
                      }}
                      value={(selectedData as Option)?.value ?? ''}
                    >
                      {selectedParameter?.options?.sort(sortInAlphabeticalOrder).map((option, index) => {
                        return (
                          <SelectItem key={index} value={option.value}>
                            {option.label}
                          </SelectItem>
                        )
                      })}
                    </SelectInput>
                  </>
                ) : (
                  selectedOperator.type === 'multi_select' &&
                  (selectedData == null || Array.isArray(selectedData)) && (
                    <>
                      <Typography variant="h5">{t('filter.value')}</Typography>
                      <Autocomplete
                        allowCustomInput={false}
                        onChange={(_, value) => {
                          setSelectedData(value)
                        }}
                        fullWidth
                        multiple
                        dropdownPlacement="top"
                        value={(selectedData as AutocompleteOptionItem[]) ?? []}
                        options={
                          selectedParameter?.options?.sort(sortInAlphabeticalOrder).map((option) => ({
                            value: option.value,
                            label: option.label
                          })) ?? []
                        }
                      />
                    </>
                  )
                )}
              </Grid>
            </Grid>
          }
          expandIcon={null}
        />
      </Grid>

      <Grid container marginTop={spacing(3)} gap={spacing(2)}>
        <Button
          onClick={() => {
            handleApplyFilter()
            handleClearFilter()
            setSelectedTab('activeFilters')
          }}
          variant="filled"
          disabled={disableApplyButton}
          tooltipProps={disableApplyButton ? { kind: 'singleline', title: t('filter.fillInAllFields') } : undefined}
        >
          {t('buttons.applyFilter')}
        </Button>
        <Button
          onClick={handleClearFilter}
          scheme="light"
          variant="tonal"
          disabled={selectedParameter == null && selectedOperator == null && selectedData == null}
          rightIcon={<CancelBkgIcon />}
        >
          {t('buttons.clearAll')}
        </Button>
      </Grid>
    </Grid>
  )

  const ActiveFilters = (
    <Grid display="flex" direction="column" gap={spacing(1)}>
      <Grid
        display="flex"
        height="40px"
        alignItems="center"
        justifyContent="space-between"
        gap="16px"
        alignSelf="stretch"
      >
        <Typography variant="h3">{t('filter.activeFilters')}</Typography>
        <Button
          onClick={() => {
            handleClearAll()
          }}
          variant="text"
          scheme="light"
        >
          {t('buttons.clearAll')}
        </Button>
      </Grid>
      <Grid container flexDirection="column" xs={12} gap="8px">
        {selectedActiveFilters.map((filter, index) => {
          return (
            <ActiveFilterGrid key={index}>
              <Grid display="flex" padding="20px" alignItems="center" gap="8px 4px" xs flexWrap="wrap">
                {parseFilterToListItemLabel(filter)}
              </Grid>
              <Grid display="flex" justifyContent="center" height="100%" alignItems="center">
                <StyledDeleteButton
                  style={{ padding: 0, height: '100%' }}
                  onClick={() => {
                    const currentFilters = selectedActiveFilters.filter((_, i) => i !== index)
                    setSelectedActiveFilters(currentFilters)
                    onUpdateFilters(currentFilters)
                    currentFilters.length === 0 && setSelectedTab('newFilter')
                  }}
                  variant="text"
                >
                  <TrashIcon />
                </StyledDeleteButton>
              </Grid>
            </ActiveFilterGrid>
          )
        })}
      </Grid>
    </Grid>
  )
  return (
    <Flyout closeText={t('buttons.close')} onClose={handleClose} isExpanded={isOpen}>
      <Typography variant="h2">{t('filter.filters')}</Typography>
      <Divider />
      {selectedTab === 'newFilter' ? AddNewFilter : ActiveFilters}
      <StyledFlyoutFooter>
        <Divider />
        <Grid display="flex" style={{ backgroundColor: theme.palette.neutrals.stone90 }} borderRadius="16px">
          <StyledButton
            fullWidth
            onClick={() => {
              setSelectedTab('newFilter')
            }}
            variant={equals(selectedTab, 'newFilter') ? 'tonal' : 'text'}
          >
            {t('buttons.addNewFilter')}
          </StyledButton>
          <StyledButton
            fullWidth
            onClick={() => {
              setSelectedTab('activeFilters')
            }}
            disabled={selectedActiveFilters.length === 0}
            variant={equals(selectedTab, 'activeFilters') ? 'tonal' : 'text'}
            rightIcon={<Notification value={selectedActiveFilters.length.toString()} variant="tonal" />}
          >
            <Typography variant="h5">{t('filter.activeFilters')}</Typography>
          </StyledButton>
        </Grid>
      </StyledFlyoutFooter>
    </Flyout>
  )
}

export default OverScreen.create(FilterFlyout)

const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  paddingBottom: spacing(2),
  bottom: 0,
  width: '22rem'
}))

const StyledSummaryWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '48px',
  justifyContent: 'center'
}))

const StyledAccordion = styled(Accordion)(() => ({
  'div:first-of-type[role="region"] > div': {
    padding: '18px 0 12px 0'
  }
}))

const StyledConditionAccordion = styled(StyledAccordion)(({ theme }) => ({
  '> div:first-of-type[role="button"]': {
    cursor: 'default'
  }
}))

const StyledButton = styled(Button)(({ variant, theme }) => {
  return {
    ...(variant === 'text' && {
      backgroundColor: theme.palette.neutrals.stone90
    }),

    '&:focus': {
      outlineColor: 'transparent',
      boxShadow: `0px 0px 0px 1px ${theme?.palette.neutrals.stone100 as string}`,
      background: theme?.palette.neutrals.stone100
    }
  }
})

const ActiveFilterGrid = styled(Grid)<{ theme?: Theme }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.borderRadius.xxLarge,
  backgroundColor: theme.palette.neutrals.stone90
}))

const StyledDeleteButton = styled(Button)<{ theme?: Theme }>(({ theme }) => ({
  padding: theme.spacing(1),
  borderLeft: `1px solid ${theme?.palette.neutrals.stone100}`,
  borderRadius: `0 ${theme?.borderRadius.xLarge} ${theme?.borderRadius.xLarge} 0`,
  height: '100%'
}))
