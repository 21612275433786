import {
  Accordion,
  Button,
  Card,
  ChevronDownIcon,
  Chip,
  Divider,
  Grid,
  TrashIcon,
  Typography,
  styled,
  MoveIcon
} from '@precis-digital/kurama'
import { type Theme } from '@precis-digital/kurama/src/components/theme'
import React, { type ReactNode } from 'react'
import { type DraggableProvided } from '@hello-pangea/dnd'

interface AccordionFormStepProps {
  children: ReactNode
  stepName: string
  title: string
  expanded?: boolean
  isLastStep?: boolean
}

export const AccordionFormStep = ({
  children,
  stepName,
  title,
  expanded = false,
  isLastStep = false
}: AccordionFormStepProps): React.ReactElement => {
  return (
    <>
      <StyledAccordion
        disabled={!expanded}
        expanded={expanded}
        Summary={
          <Grid display="flex" flexDirection="column" gap="6px">
            <Typography variant="body2">{stepName}</Typography>
            <Grid height="40px">
              <Typography variant="h2">{title}</Typography>
            </Grid>
          </Grid>
        }
        Details={
          <Grid display="flex" flexDirection="column" gap="16px">
            {children}
          </Grid>
        }
        expandIcon={null}
      />
      {!isLastStep && <Divider />}
    </>
  )
}

const StyledAccordion = styled(Accordion)(({ disabled, theme }: { disabled: boolean; theme?: Theme }) => ({
  color: disabled ? theme?.palette.neutrals.stone120 : undefined,
  '&.MuiAccordion-root': {
    margin: 0
  },
  '.MuiAccordionSummary-root.Mui-disabled': {
    opacity: 1
  },
  '&::before': {
    opacity: 0
  },
  '> div:first-of-type[role="button"]': {
    cursor: 'default'
  },
  '>.MuiCollapse-root': {
    padding: '0'
  },
  '.MuiAccordionDetails-root': {
    padding: '24px 0',
    '.MuiAccordionDetails-root': {
      paddingTop: '16px'
    }
  }
}))

interface BaseAccordionFormStepItemProps {
  children: ReactNode
  title: string
  expanded?: boolean
  onChange?: () => void
  isCreateEvent?: boolean
  requiredFieldsTally?: [number, number]
  t?: (key: string, options?: Record<string, unknown> | undefined) => string
  onDelete?: () => void
  draggableProvided?: DraggableProvided
  hasCustomContent?: boolean
}

interface AccordionFormStepItemPropsWithTally extends BaseAccordionFormStepItemProps {
  requiredFieldsTally: [number, number]
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}

interface AccordionFormStepItemPropsWithoutTally extends BaseAccordionFormStepItemProps {
  requiredFieldsTally?: undefined
}

export const AccordionFormStepItem = ({
  children,
  title,
  expanded,
  isCreateEvent = false,
  requiredFieldsTally,
  onDelete,
  onChange,
  draggableProvided,
  t,
  hasCustomContent = false
}: AccordionFormStepItemPropsWithTally | AccordionFormStepItemPropsWithoutTally): React.ReactElement => {
  const parsedDetailsChildren = !hasCustomContent
    ? React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === React.Fragment) {
          return React.Children.toArray(child.props.children)
        }
        return child
      })
    : null

  const [controlledExpanded, setControlledExpanded] = React.useState(expanded ?? false)

  const handleOnChange = (): void => {
    if (onChange != null) {
      onChange()
    }
    setControlledExpanded((prev) => !prev)
  }
  return (
    <StyledCard elevation={controlledExpanded ? 'mediumDepth' : undefined}>
      <Accordion
        onChange={handleOnChange}
        expanded={controlledExpanded}
        Summary={
          <Grid container display="flex" alignItems="center" height="48px">
            {draggableProvided != null && (
              <StyledGridIcon {...draggableProvided?.dragHandleProps}>
                <MoveIcon />
              </StyledGridIcon>
            )}
            <Grid xs>
              <Typography variant="h3">{title}</Typography>
            </Grid>
            {onDelete != null && (
              <StyledDeleteContainer>
                <Button variant="text" onClick={onDelete} leftIcon={<TrashIcon />} />
              </StyledDeleteContainer>
            )}
            {isCreateEvent && requiredFieldsTally != null && !requiredFieldsTally?.every((tally) => tally === 0) && (
              <StyledChip
                label={
                  t('form.requiredFieldsTally', {
                    currentNumber: requiredFieldsTally[0],
                    totalNumber: requiredFieldsTally[1]
                  }) ?? ''
                }
                isEqualTally={requiredFieldsTally[0] === requiredFieldsTally[1]}
              />
            )}
          </Grid>
        }
        Details={
          <Grid display="flex" flexDirection="column" gap="16px">
            {parsedDetailsChildren != null
              ? parsedDetailsChildren?.map((child, index) => {
                  const isLastStep = index === parsedDetailsChildren.length - 1
                  return (
                    <Grid display="flex" flexDirection="column" key={index}>
                      {child}
                      {!isLastStep && <Divider />}
                    </Grid>
                  )
                })
              : children}
          </Grid>
        }
        expandIcon={<ChevronDownIcon />}
      />
    </StyledCard>
  )
}

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  margin: '0',
  '.MuiCollapse-vertical': {
    '.MuiAccordionDetails-root': {
      paddingBottom: '16px'
    }
  }
}))

const StyledChip = styled(Chip)<{ isEqualTally: boolean }>(({ theme, isEqualTally }) => ({
  backgroundColor: isEqualTally ? theme.palette.semantic.success05 : theme.palette.neutrals.stone100,
  color: isEqualTally ? theme.palette.semantic.success100 : theme.palette.secondary.main
}))
const StyledDeleteContainer = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(2)
}))

const StyledGridIcon = styled(Grid)(({ theme, disabled }: { theme?: Theme; disabled?: boolean }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: disabled === true ? theme?.palette.neutrals.stone120 : undefined,
  height: '40px',
  width: '40px',
  flexShrink: 0,
  borderRadius: theme?.borderRadius.medium,
  ...(disabled !== true
    ? {
        '&:hover': {
          backgroundColor: theme?.palette.neutrals.stone100
        }
      }
    : {})
}))
