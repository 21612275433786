import Grid from '../Grid'
import { styled } from '../../utils'
import { Theme, CSSObject } from '../theme'
import Divider from '../Divider'

export const StyledDateRangePickerWrapper = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    cursor: 'pointer',
    position: 'relative',
    width: '298px',
    zIndex: 1300,
    '& > svg': {
      position: 'absolute',
      right: '0px',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },

    '.DayPicker__withBorder': {
      border: 'none',
      boxShadow: 'none'
    },
    '.CalendarMonth': {
      backgroundColor: 'transparent'
    },
    '.CalendarDay__default': {
      border: 'none'
    },
    '.DateInput_input__focused': {
      borderBottom: '2px solid transparent',
      background: 'transparent'
    },
    '.DateRangePicker_picker ': {
      border: '1px solid #E5E5E5',
      padding: theme?.spacing(7),
      height: 'fit-content',
      width: 'fit-content',
      borderRadius: `${theme?.borderRadius.xxLarge ?? '32px'}`,
      boxShadow: `${theme?.elevation.largeDepth ?? '0px 16px 32px rgba(112, 144, 176, 0.2)'}`
    },
    '.DayPickerNavigation_button__default': {
      border: 'none'
    },
    '.DateInput_fang': {
      display: 'none'
    },
    '.DayPickerKeyboardShortcuts_show__bottomRight ': {
      display: 'none'
    },
    '.CalendarDay__default:hover': {
      borderRadius: `${theme?.borderRadius.medium ?? '16px'}`,
      backgroundColor: theme?.palette.main.blue100,
      width: '40px',
      height: '40px',
      color: theme?.palette.neutrals.white0,
      borderColor: 'transparent'
    },
    '.CalendarDay__blocked_out_of_range': {
      color: theme?.palette.neutrals.stone120
    },
    '.CalendarDay__blocked_out_of_range:hover': {
      color: theme?.palette.neutrals.stone120,
      backgroundColor: 'unset'
    },
    '.CalendarDay__selected': {
      borderRadius: `${theme?.borderRadius.medium ?? '16px'}`,
      backgroundColor: theme?.palette.main.blue100,
      width: '40px',
      height: '40px',
      color: theme?.palette.neutrals.white0
    },
    '.CalendarDay__selected_span': {
      backgroundColor: theme?.palette.neutrals.stone100,
      color: theme?.palette.secondary.main
    },
    '.CalendarDay__hovered_span': {
      backgroundColor: theme?.palette.neutrals.stone100
    },
    '.DateRangePickerInput__withBorder': {
      border: 'none',
      borderRadius: '26px',
      height: '40px',
      width: '298px',
      padding: '0px 8px',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '2px',
      paddingRight: '40px',
      boxShadow: `0px 0px 0px 1px ${theme?.palette.neutrals.stone100 as string} inset`,
      ':hover': {
        backgroundColor: theme?.palette.neutrals.stone100,
        boxShadow: 'none'
      }
    },
    '.DateInput': {
      background: 'transparent',
      backgroundColor: 'transparent',
      height: '40px',
      width: '298px',
      display: 'flex',
      justifyContent: 'inline'
    },
    '.DateInput_input': {
      backgroundColor: 'transparent',
      fontSize: '14px',
      textAlign: 'center',
      padding: '0px',
      cursor: 'pointer'
    },
    '.DateRangePickerInput_arrow': {
      display: 'flex',
      alignSelf: 'center',
      paddingBottom: '3px'
    },
    '.DateRangePickerInput_calendarIcon': {
      margin: '0px',
      padding: '0px'
    },
    '.DayPickerNavigation > .DayPickerNavigation_button': {
      position: 'absolute',
      top: '22px',
      '&:nth-of-type(1)': {
        left: '0px'
      },
      '&:nth-of-type(2)': {
        right: '0px'
      }
    }
  })
)

export const StyledCalendarInfoContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    gap: theme?.spacing(1),
    flexDirection: 'column'
  })
)

export const StyledCalendarInfo = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    gap: theme?.spacing(1)
  })
)

export const StyledDivider = styled(Divider)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    padding: '8px 0'
  })
)
