import React from 'react'
import { ExpandedNumberFormat } from 'shared/components/NumberFormat'
import { calculateRoas, calculateCostPerAction } from 'shared/utils'
import type { ReactTableRows, ReactTableColumn } from 'shared/reactTable/types'
import type { FormatIds } from '../numberFormat'

interface BasicFooterProps {
  rows: ReactTableRows
  column: ReactTableColumn
  currencyCode: string
}

interface RoasFooterProps extends BasicFooterProps {
  revenueField: string
  spendField: string
  currencyCode: string
}

interface CpaFooterProps extends BasicFooterProps {
  conversionField: string
  spendField: string
  currencyCode: string
}

export const BasicSumColumnFooter = ({ rows, column, currencyCode }: BasicFooterProps): React.ReactElement => {
  const total = rows.reduce((sum, row) => sum + (row.original[column.id] as number), 0)
  return <ExpandedNumberFormat value={total} id={column.id as FormatIds} currencyCode={currencyCode} />
}

export const BasicRoasFooter = ({
  rows,
  column,
  currencyCode,
  revenueField,
  spendField
}: RoasFooterProps): React.ReactElement => {
  const totalRevenue = rows.reduce((sum, row) => sum + (row.original[revenueField] as number), 0)
  const totalSpend = rows.reduce((sum, row) => sum + (row.original[spendField] as number), 0)
  const total = calculateRoas(totalRevenue, totalSpend)
  return <ExpandedNumberFormat value={total} id={column.id as FormatIds} currencyCode={currencyCode} />
}

export const BasicCpaFooter = ({
  rows,
  column,
  currencyCode,
  conversionField,
  spendField
}: CpaFooterProps): React.ReactElement => {
  const totalConversions = rows.reduce((sum, row) => sum + (row.original[conversionField] as number), 0)
  const totalSpend = rows.reduce((sum, row) => sum + (row.original[spendField] as number), 0)
  const total = calculateCostPerAction(totalSpend, totalConversions)
  return <ExpandedNumberFormat value={total} id={column.id as FormatIds} currencyCode={currencyCode} />
}
