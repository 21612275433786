import Radio from '@mui/material/Radio'
import { styled } from '../../../utils'
import { Theme, CSSObject } from '../../theme'
import Grid from '../../Grid'
import { DROPDOWN_ITEM_CLASS_NAME } from '../../Dropdown/classNames'
import { listItemClasses } from '@mui/material'

export const StyledRadio = styled(Radio)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.neutrals.white0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& > div': {
        border: `10px solid ${theme?.palette.main.blue50 ?? ''}`
      }
    },
    '&:active': {
      '& > div': {
        border: `10px solid ${theme?.palette.main.blue50 ?? ''}`,
        backgroundColor: theme?.palette.main.blue50
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):hover &`]: {
      backgroundColor: 'transparent',
      '& > div': {
        border: `10px solid ${theme?.palette.main.blue50 ?? ''}`
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled).${listItemClasses.focusVisible} &`]: {
      backgroundColor: 'transparent',
      '& > div': {
        border: `10px solid ${theme?.palette.main.blue50 ?? ''}`
      }
    },
    [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):active &`]: {
      '& > div': {
        border: `10px solid ${theme?.palette.main.blue50 ?? ''}`,
        backgroundColor: theme?.palette.main.blue50
      }
    },
    '&.Mui-focusVisible': {
      '> div': {
        border: `1px solid ${theme?.palette.semantic.info100 ?? ''}`,
        boxShadow: `0px 0px 0px 3px ${theme?.palette.neutrals.stone100 ?? ''}`
      }
    },
    '&.Mui-disabled': {
      '& > div': {
        backgroundColor: theme?.palette.neutrals.stone90,
        color: theme?.palette.neutrals.stone90
      }
    },
    '&.Mui-checked': {
      color: theme?.palette.main.blue100,
      '&:hover': {
        backgroundColor: 'transparent',
        '& > div': {
          border: `10px solid ${theme?.palette.main.blue90 ?? ''}`
        }
      },
      '&.Mui-focusVisible': {
        '> div': {
          boxShadow: `0px 0px 0px 3px ${theme?.palette.neutrals.stone100 ?? ''}`
        }
      },
      '&:active': {
        '& > div': {
          border: `10px solid ${theme?.palette.main.blue110 ?? ''}`,
          backgroundColor: 'transparent'
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):hover &`]: {
        backgroundColor: 'transparent',
        '& > div': {
          border: `10px solid ${theme?.palette.main.blue90 ?? ''}`
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled).${listItemClasses.focusVisible} &`]: {
        backgroundColor: 'transparent',
        '& > div': {
          border: `10px solid ${theme?.palette.main.blue90 ?? ''}`
        }
      },
      [`.${DROPDOWN_ITEM_CLASS_NAME}:not(.disabled):active &`]: {
        '& > div': {
          border: `10px solid ${theme?.palette.main.blue110 ?? ''}`,
          backgroundColor: 'transparent'
        }
      },
      '&.Mui-disabled': {
        '> div': {
          backgroundColor: theme?.palette.neutrals.stone120,
          border: `10px solid ${theme?.palette.neutrals.stone90 ?? ''}`
        }
      }
    }
  })
)

export const StyledRadioFrame = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    height: '32px',
    width: '32px',
    border: `2px solid ${theme?.palette.neutrals.stone100 ?? ''}`,
    borderRadius: theme?.borderRadius.small,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
)

export const StyledRadioInside = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    height: '32px',
    width: '32px',
    borderRadius: theme?.borderRadius.small,
    backgroundColor: theme?.palette.neutrals.white0,
    border: `10px solid ${theme?.palette.main.blue100 ?? ''}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
)
