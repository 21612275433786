import Grid from '../../Grid'
import { styled } from '../../../utils'
import { Theme } from '../../theme'
import Accordion from '../../Accordion'
import Card from '../../Card'
import Chip from '../../Chip'
import Typography from '../../Typography'

export const StyledGridNewConditionalRowCard = styled(Grid)(
  ({ theme, disabled }: { theme?: Theme; disabled: boolean }) => ({
    padding: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: disabled ? theme?.palette.neutrals.stone120 : theme?.palette.secondary.main,
    backgroundColor: disabled ? theme?.palette.neutrals.stone90 : theme?.palette.neutrals.stone100,
    borderRadius: theme?.borderRadius.xLarge,
    button: {
      alignSelf: 'center'
    }
  })
)

export const StyledAccordionInnerRowsContainer = styled(Accordion)(
  ({ theme, expanded }: { theme?: Theme; expanded: boolean }) => ({
    borderRadius: theme?.borderRadius.xLarge,
    outline: expanded ? `1px solid ${theme?.palette.neutrals.stone100 as string}` : 'none',
    boxShadow: expanded ? theme?.elevation.mediumDepth : 'none',
    transition: 'box-shadow 200ms ease-in-out',
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '&:first-of-type': {
      borderTopLeftRadius: theme?.borderRadius.xLarge,
      borderTopRightRadius: theme?.borderRadius.xLarge
    },
    '> .MuiButtonBase-root.MuiAccordionSummary-root': {
      backgroundColor: expanded ? theme?.palette.neutrals.white0 : theme?.palette.neutrals.stone90,
      padding: '20px',
      borderTopLeftRadius: theme?.borderRadius.xLarge,
      borderTopRightRadius: theme?.borderRadius.xLarge,
      borderBottomLeftRadius: theme?.borderRadius.xLarge,
      borderBottomRightRadius: theme?.borderRadius.xLarge,
      '.MuiAccordionSummary-expandIconWrapper': {
        width: '40px',
        justifyContent: 'center',
        color: theme?.palette.secondary.main
      }
    },
    '&.MuiAccordion-root:before': {
      display: 'none'
    },
    '.MuiAccordionDetails-root': {
      padding: '20px',
      paddingTop: 0,
      backgroundColor: theme?.palette.neutrals.white0,
      borderBottomLeftRadius: theme?.borderRadius.xLarge,
      borderBottomRightRadius: theme?.borderRadius.xLarge
    }
  })
)

export const StyledCardBuilderContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}))

export const StyledChipUnsavedConditions = styled(Chip)(
  ({ hasError, theme }: { hasError: boolean; theme?: Theme }) => ({
    backgroundColor: hasError ? theme?.palette.semantic.error05 : theme?.palette.main.blue05,
    color: hasError ? theme?.palette.semantic.error100 : theme?.palette.main.blue100,
    '&:hover': {
      cursor: 'pointer'
    }
  })
)

export const StyledTypographyErrorMessage = styled(Typography)(({ theme }: { theme?: Theme }) => ({
  color: theme?.palette.semantic.error100
}))
