import { type Platforms } from './accounts'
import fetch from './fetch'
const client = fetch()

export type ExternalAppType = 'google_sheet' | 'google_colab' | 'looker_studio' | 'precis_central_app'

type AppType = 'external' | 'native' | 'alvie'

export type AppVertical = 'ecommerce' | 'b2b_saas' | 'app_subscription'

export type PrecisAppsPlatforms =
  | Platforms
  | 'shopify'
  | 'ahrefs'
  | 'google_search_console'
  | 'merchant_center'
  | 'snapchat'
  | 'tiktok'

type AppStatus = 'enabled' | 'deleted'

export type AppReleaseType = 'prototype' | 'beta' | 'production'

export interface PrecisAppResp {
  id: string
  title: string
  description: string
  uri?: string
  externalAppLink?: string
  externalAppType?: ExternalAppType
  createdBy: number
  createdAt: string
  updatedBy: number
  updatedAt: string
  type: AppType
  appVerticals: AppVertical[]
  relatedPlatforms: PrecisAppsPlatforms[]
  tags: string[]
  status: AppStatus
  releaseType: AppReleaseType
  content: object[]
  userGuideLink: string
  userGuideName: string
  ownedBy: number
  numberOfUsingClients: number
  clientMetadata: PrecisAppRelationship
}

export type AppRelationshipStatus = 'using' | 'not_using' | 'error'

type AppRelationshipPlan = 'free' | 'paid'

export interface Note {
  text: string
  createdAt: string
  createdBy: number
}

export interface PrecisAppRelationship {
  clientId: string
  precisAppId: string
  createdAt: string
  createdBy: number
  updatedAt: string
  updatedBy: number
  status: AppRelationshipStatus
  notes: Note[]
  plan: AppRelationshipPlan
  hasPrototypeAccess: boolean
  hasAccess: boolean
  conversationId?: string
}

export interface PrecisApps extends Array<PrecisAppResp> {}

export const getPrecisApps = async (clientId: string): Promise<PrecisApps> => {
  return await client.get<PrecisApps>({
    path: `/clients/${clientId}/precis_apps`
  })
}

export const getPrecisApp = async (clientId: string, precisAppId: string): Promise<PrecisAppResp> => {
  return await client.get<PrecisAppResp>({
    path: `/clients/${clientId}/precis_apps/${precisAppId}`
  })
}

export interface UpdateClientPrecisAppRelationReq {
  clientId: string
  precisAppId: string
  status: AppRelationshipStatus
  notes: Note[]
  plan: AppRelationshipPlan
  hasPrototypeAccess: boolean
}

export const updateClientPrecisAppRelation = async ({
  clientId,
  precisAppId,
  status,
  notes,
  plan,
  hasPrototypeAccess
}: UpdateClientPrecisAppRelationReq): Promise<PrecisAppRelationship> => {
  return await client.put<PrecisAppRelationship>({
    path: `/clients/${clientId}/precis_apps/${precisAppId}`,
    data: {
      client_id: clientId,
      precis_app_id: precisAppId,
      status,
      notes,
      plan,
      has_prototype_access: hasPrototypeAccess
    }
  })
}

export interface UpdatePrecisAppReq {
  precisAppId: string
  precisApp: PrecisAppResp
}

export interface CreatePrecisAppConversationReq {
  precisAppId: string
  clientId: string
  content: string
}

export const updatePrecisApp = async ({ precisAppId, precisApp }: UpdatePrecisAppReq): Promise<PrecisAppResp> => {
  return await client.put<PrecisAppResp>({
    path: `/precis_apps/${precisAppId}`,
    data: precisApp
  })
}

export const createPrecisAppConversation = async ({
  precisAppId,
  clientId,
  content
}: CreatePrecisAppConversationReq): Promise<string> => {
  return await client.post<string>({
    path: `/clients/${clientId}/precis_apps/${precisAppId}/start_conversation`,
    data: { content }
  })
}
