import { Grid, SingleSelectDropdown, Typography } from '@precis-digital/kurama'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { type ReactElement } from 'react'
import { useTranslation } from 'shared/translations'
import ChannelsTable from './ChannelsTable'

const ChannelsTab = (): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const { dataSourceItem, dataSourceMetrics, selectedMetric, setSelectedMetric } = useCurrentChannelGroupingDataSource()

  return (
    <Grid display="flex" flexDirection="column" gap="16px" paddingTop="16px">
      <Grid display="flex" justifyContent="space-between" alignItems="flex-end" height="40px">
        <Typography variant="h2">
          {t('dataSourceFormView.channelsTab.title', { dataSourceLabel: dataSourceItem.label })}
        </Typography>
        <Grid
          width="fit-content"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height="fit-content"
          gap="8px"
          flexWrap="nowrap"
        >
          <Typography variant="body2">{t('dataSourceFormView.channelsTab.metricsLabel')}</Typography>
          <SingleSelectDropdown
            title={t('dataSourceFormView.channelsTab.metricsTitle')}
            buttonTitle={t('dataSourceFormView.channelsTab.metricsButtonTitle')}
            options={dataSourceMetrics.map((metric) => ({
              label: metric.label,
              value: metric.id
            }))}
            value={selectedMetric.id}
            onSelect={(value) => {
              setSelectedMetric(value)
            }}
          />
        </Grid>
      </Grid>
      <ChannelsTable />
    </Grid>
  )
}

export default ChannelsTab
