import {
  ArrowLeftIcon,
  Button,
  CopyIcon,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownList,
  MoreIcon,
  TrashIcon
} from '@precis-digital/kurama'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { type ReactElement, useState } from 'react'
import { useChangePath } from 'shared/components/Router'
import { makeErrorToast, makeToastWithLoading } from 'shared/components/Toaster'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'
import { channelGrouping as channelGroupingApi } from 'shared/api'
import { useCurrentClient } from 'shared/context/ClientContext'

const MoreMenu = (): ReactElement => {
  const { currentChannelGrouping, deleteAllChannelsInDataSource } = useCurrentChannelGrouping()
  const { dataSource, dataSourceItem } = useCurrentChannelGroupingDataSource()
  const { changePath } = useChangePath()
  const { t } = useTranslation('channelGrouping')
  const { currentClient } = useCurrentClient()
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null)
  const menuOpen = Boolean(menuAnchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setMenuAnchorEl(null)
  }

  const handleCopySqlClick = (): void => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    handleClose()
    channelGroupingApi
      .getChannelGrouping(currentChannelGrouping.id, currentClient.id, true)
      .then((data) => {
        const sqlString = data.channelGroupingSqls?.[dataSource]
        if (sqlString === undefined) {
          toastOnError(t('dataSourceFormView.moreMenu.copySqlError', { dataSourceName: dataSourceItem.label }))
          return
        }

        void navigator.clipboard.writeText(sqlString).then(() => {
          toastOnSuccess(t('dataSourceFormView.moreMenu.copySqlSuccess', { dataSourceName: dataSourceItem.label }))
        })
      })
      .catch(() => {
        toastOnError(t('dataSourceFormView.moreMenu.copySqlError', { dataSourceName: dataSourceItem.label }))
      })
  }

  const handleGoBackClick = (): void => {
    void changePath(
      APP_ROUTES.customGroupings.dataSourcesPage({ channelGroupingId: currentChannelGrouping.id.toString() })
    )
  }

  const handleDeleteAllChannelsClick = (): void => {
    const { toastOnSuccess } = makeToastWithLoading({ persistThroughRouteChange: true })
    handleClose()
    deleteAllChannelsInDataSource(dataSource, {
      onSuccess: () => {
        toastOnSuccess(
          t('dataSourceFormView.moreMenu.deleteAllChannelsSuccess', { dataSourceName: dataSourceItem.label })
        )
        void changePath(
          APP_ROUTES.customGroupings.dataSourcesPage({ channelGroupingId: currentChannelGrouping.id.toString() })
        )
      },
      onError: (error) => {
        const errorMessage = error.message.toLowerCase()
        const isIAErorr = errorMessage.includes('requires missing data sources')
        makeErrorToast(
          isIAErorr ? t('popups.updateChannelGrouping.iaErrorToast') : t('popups.updateChannelGrouping.errorToast')
        )
      }
    })
  }

  return (
    <>
      <Button variant="text" rightIcon={<MoreIcon />} onClick={handleClick}>
        {t('dataSourceFormView.moreMenu.more')}
      </Button>
      <Dropdown open={menuOpen} anchorEl={menuAnchorEl} onClose={handleClose}>
        <DropdownList dense minWidth="326px">
          <DropdownItem icon={<ArrowLeftIcon />} onClick={handleGoBackClick}>
            {t('dataSourceFormView.moreMenu.goBack')}
          </DropdownItem>
          <DropdownItem
            icon={<CopyIcon />}
            onClick={handleCopySqlClick}
            disabled={dataSourceItem.channels.length === 0}
          >
            {t('dataSourceFormView.moreMenu.copySql', { dataSourceName: dataSourceItem.label })}
          </DropdownItem>
          <Divider />
          <DropdownItem
            icon={<TrashIcon />}
            onClick={handleDeleteAllChannelsClick}
            disabled={dataSourceItem.channels.length === 0}
          >
            {t('dataSourceFormView.moreMenu.deleteAllChannels', { dataSourceName: dataSourceItem.label })}
          </DropdownItem>
        </DropdownList>
      </Dropdown>
    </>
  )
}

export default MoreMenu
