import React from 'react'
import { StyledSelectContainer, StyledSelect, StyledMessage } from './styles'
import { SelectProps } from './types'
import { ChevronDownIcon } from '../../Icons'
import Badge from '../../Badge'

const SelectInput = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ name, value = '', onChange, disabled, placeholder, helperText, children, fullWidth = false, ...props }, ref) => {
    return (
      <StyledSelectContainer fullWidth={fullWidth}>
        <StyledSelect
          {...props}
          name={name}
          fullWidth={fullWidth}
          onChange={onChange}
          value={value}
          ref={ref}
          disabled={disabled}
          IconComponent={ChevronDownIcon}
          placeholder={placeholder}
        >
          {children}
        </StyledSelect>
        {helperText != null && (
          <StyledMessage className="helper-text">
            <Badge color={props.color !== undefined ? props.color : 'info'} />
            <span>{helperText}</span>
          </StyledMessage>
        )}
      </StyledSelectContainer>
    )
  }
)

export default SelectInput
