import {
  type BadgeColor,
  Grid,
  SortAscendingIcon,
  SortDescendingIcon,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Typography,
  colorMap,
  styled,
  Skeleton
} from '@precis-digital/kurama'
import { OTHER_CHANNEL_COLOR, OTHER_CHANNEL_NAME } from 'channelGrouping/constants'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { useMemo, type ReactElement, type ComponentProps } from 'react'
import { type Column, useSortBy, useTable, type CellProps } from 'react-table'
import { useCurrentClient } from 'shared/context/ClientContext'
import formatToMoney, { formatToNumber } from 'shared/numberFormat'
import { useTranslation } from 'shared/translations'

interface DistributionTableRowRecord {
  channelName: string
  metricValue: number
  distributionFraction: number
  channelColor: BadgeColor
}

const DistributionFractionCell = (props: CellProps<DistributionTableRowRecord, number>): ReactElement => (
  <Grid paddingRight="48px">
    <StyledGridColouredBar distributionFraction={props.value} color={colorMap.get(props.row.original.channelColor)} />
  </Grid>
)

const ChannelNameCell = (props: CellProps<DistributionTableRowRecord, string>): ReactElement => {
  const channelIndex = props.value.substring(0, 2)
  const channelName = props.value.substring(3)
  return (
    <Grid display="flex" flexWrap="nowrap" gap="8px" paddingRight="24px">
      <Grid width="24px" height="24px" alignItems="center" display="flex">
        <Typography variant="body2">{channelIndex}</Typography>
      </Grid>
      <Typography variant="body2">{channelName}</Typography>
    </Grid>
  )
}

const MetricValueCell = (props: CellProps<DistributionTableRowRecord, number>): ReactElement => {
  const { currentClient } = useCurrentClient()
  const { selectedMetric } = useCurrentChannelGroupingDataSource()
  const formattedValue =
    selectedMetric.type === 'currency'
      ? formatToMoney({ value: props.value, currencyCode: currentClient.currency, compact: false })
      : formatToNumber(props.value, false)
  return (
    <Grid paddingRight="16px">
      <Typography variant="body2">{formattedValue}</Typography>
    </Grid>
  )
}

const DistributionTable = (): ReactElement => {
  const { performanceData, isPerformanceDataLoading, selectedMetric, dataSourceItem } =
    useCurrentChannelGroupingDataSource()
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('channelGrouping')

  const columns: Array<Column<DistributionTableRowRecord>> = useMemo(
    () => [
      {
        Header: t('dataSourceFormView.distributionTab.groupTableHeader'),
        accessor: 'channelName',
        Cell: ChannelNameCell,
        width: '31%',
        align: 'left'
      },
      {
        Header: selectedMetric.label,
        accessor: 'metricValue',
        Cell: MetricValueCell,
        width: '14%',
        align: 'right'
      },
      {
        Header: 'Distribution',
        accessor: 'distributionFraction',
        Cell: DistributionFractionCell,
        width: '55%',
        align: 'left',
        sortType: (rowA, rowB) => {
          return rowA.original.metricValue - rowB.original.metricValue
        }
      }
    ],
    [selectedMetric.label, t]
  )

  const alignMap: Record<string, ComponentProps<'th'>['align']> = {
    distributionFraction: 'left',
    channelName: 'left',
    metricValue: 'right'
  }

  const maxMetricValue = Math.max(
    ...Object.values(performanceData?.channelsValues ?? {}).map((metricValue) => metricValue[selectedMetric.id] ?? 0)
  )

  const sumMetricsValue = performanceData?.sumValues?.[selectedMetric.id] ?? 0

  const data: DistributionTableRowRecord[] = useMemo(() => {
    if (performanceData.channelsValues == null) {
      return []
    }

    return [...dataSourceItem.channels, { name: OTHER_CHANNEL_NAME, color: OTHER_CHANNEL_COLOR }].map(
      (channel, index) => ({
        channelName: `${(index + 1).toString().padStart(2, '0')} ${channel.name}`,
        metricValue: performanceData.channelsValues?.[channel.name]?.[selectedMetric.id] ?? 0,
        distributionFraction:
          (performanceData.channelsValues?.[channel.name]?.[selectedMetric.id] ?? 0) / maxMetricValue,
        channelColor: channel.color
      })
    )
  }, [performanceData, selectedMetric, maxMetricValue, dataSourceItem])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'channelName',
            desc: false
          }
        ]
      }
    },
    useSortBy
  )

  return (
    <Grid>
      <StyledTable {...getTableProps()}>
        <TableHead>
          <TableRow>
            {headerGroups[0].headers.map((column, index) => {
              const columnProps = column.getHeaderProps(column.getSortByToggleProps())
              const columnAccessor = column.id
              const sortingIcon = column.isSorted ? (
                column.isSortedDesc === true ? (
                  <SortDescendingIcon />
                ) : (
                  <SortAscendingIcon />
                )
              ) : null
              return (
                <TableHeaderCell width={column.width} align={alignMap[columnAccessor]} key={columnProps.key}>
                  <Grid
                    display="flex"
                    width="100%"
                    justifyContent={alignMap[columnAccessor] === 'right' ? 'flex-end' : 'flex-start'}
                    paddingRight={index === 1 ? '16px' : '0'}
                  >
                    <StyledGridSortableHeaderContainer
                      width="fit-content"
                      display="flex"
                      alignItems="center"
                      gap="2px"
                      {...columnProps}
                    >
                      {alignMap[columnAccessor] !== 'left' && (
                        <Grid width="16px" height="24px">
                          {sortingIcon}
                        </Grid>
                      )}
                      <Typography variant="h5">{column.render('Header')}</Typography>
                      {alignMap[columnAccessor] !== 'right' && (
                        <Grid width="16px" height="24px">
                          {sortingIcon}
                        </Grid>
                      )}
                    </StyledGridSortableHeaderContainer>
                  </Grid>
                </TableHeaderCell>
              )
            })}
          </TableRow>
        </TableHead>
        {!isPerformanceDataLoading && (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const rowProps = row.getRowProps()
              return (
                <TableRow {...rowProps} key={rowProps.key}>
                  {row.cells.map((cell) => {
                    const cellProps = cell.getCellProps()
                    const cellAccessor = cell.column.id
                    return (
                      <TableBodyCell {...cellProps} align={alignMap[cellAccessor]} key={cellProps.key}>
                        {cell.render('Cell')}
                      </TableBodyCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </StyledTable>
      {!isPerformanceDataLoading && (
        <Grid
          display="flex"
          width="100%"
          marginTop="12px"
          height="52px"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid width="31%">
            <Typography variant="h3">{t('dataSourceFormView.distributionTab.allChannels')}</Typography>
          </Grid>
          <Grid width="14%" display="flex" justifyContent="flex-end">
            <Grid paddingRight="16px">
              <Typography variant="h3">
                {selectedMetric.type === 'currency'
                  ? formatToMoney({ value: sumMetricsValue, currencyCode: currentClient.currency })
                  : formatToNumber(sumMetricsValue)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {isPerformanceDataLoading &&
        Array.from({ length: 5 }).map((_, index) => (
          <Grid key={index} height="48px" display="flex" alignItems="center">
            <Skeleton height="36px" />
          </Grid>
        ))}
    </Grid>
  )
}

const StyledGridSortableHeaderContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  svg: {
    color: theme.palette.primary.main
  }
}))

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  borderSpacing: '24px 0px',
  'th, td': {
    height: '48px'
  },
  'tr:not(:last-child) > td': {
    borderBottom: `1px solid ${theme?.palette.neutrals.stone100 as string}`
  },
  p: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const StyledGridColouredBar = styled('div')(
  ({ color, distributionFraction }: { color?: string; distributionFraction: number }) => ({
    borderRadius: '5px',
    height: '24px',
    width: `${Math.max(distributionFraction, 0.005) * 100}%`,
    backgroundColor: color
  })
)

export default DistributionTable
