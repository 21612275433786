import { CSSObject, Theme } from '@mui/material'
import Typography from '../../Typography'
import { styled } from '../../../utils'
import { AvatarKind, Size } from './types'
import { CSSProperties } from 'react'

export const SMALL_AVATAR_WIDTH_EM = 1.5
export const MEDIUM_AVATAR_WIDTH_EM = 2.5
export const LARGE_AVATAR_WIDTH_EM = 4

export const getSizedStyles = (
  size: Size,
  theme?: Theme
): { width: string; height: string; borderRadius: string | undefined; p: { fontSize: string } } => {
  switch (size) {
    case 'small':
      return {
        width: `${SMALL_AVATAR_WIDTH_EM}em`,
        height: `${SMALL_AVATAR_WIDTH_EM}em`,
        borderRadius: theme?.borderRadius.xSmall,
        p: {
          fontSize: '0.5em'
        }
      }
    case 'medium':
      return {
        width: `${MEDIUM_AVATAR_WIDTH_EM}em`,
        height: `${MEDIUM_AVATAR_WIDTH_EM}em`,
        borderRadius: theme?.borderRadius.medium,
        p: {
          fontSize: '0.875em'
        }
      }
    case 'large':
      return {
        width: `${LARGE_AVATAR_WIDTH_EM}em`,
        height: `${LARGE_AVATAR_WIDTH_EM}em`,
        borderRadius: theme?.borderRadius.xLarge,
        p: {
          fontSize: '1.25em'
        }
      }
  }
}

const getKindBackgroundColor = (kind: AvatarKind, theme?: Theme): string | undefined => {
  switch (kind) {
    case 'image':
      return theme?.palette.neutrals.white0
    case 'label':
      return theme?.palette.main.blue50
    case 'number':
      return theme?.palette.main.blue90
  }
}

export const StyledImage = styled('img')(({ objectFit }: { objectFit: CSSProperties['objectFit'] }) => ({
  width: '100%',
  height: '100%',
  objectFit
}))

export const StyledAvatar = styled('div')(
  ({
    size,
    kind,
    theme,
    backgroundColor
  }: {
    size: Size
    kind: AvatarKind
    theme?: Theme
    backgroundColor?: string
  }): CSSObject => ({
    backgroundColor: backgroundColor ?? getKindBackgroundColor(kind, theme),
    border: backgroundColor !== undefined ? 'none' : `1px solid ${theme?.palette.neutrals.stone90 ?? ''}`,
    overflow: 'hidden',
    flexShrink: 0,
    ...getSizedStyles(size, theme)
  })
)

export const StyledLabel = styled(Typography)(
  ({ theme, kind }: { theme?: Theme; kind?: AvatarKind }): CSSObject => ({
    color: kind === 'number' ? theme?.palette.neutrals.white0 : theme?.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textTransform: 'uppercase',
    ...theme?.typography.h3
  })
)
