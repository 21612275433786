import React, { ReactElement, ReactNode } from 'react'
import { StyledInput, StyledLabel, StyledMessage, StyledClearButton } from './styles'
import { InputType, InputProps, getBadgeColor } from './types'
import Badge from '../Badge'
import { CancelBkg } from '../Icons/assets/CancelBkg'
import { IconButton, InputAdornment } from '@mui/material'
import Grid from '../Grid'

const RightIcon = ({
  onClick,
  children
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}): ReactElement => {
  if (onClick != null) {
    return (
      <InputAdornment position="end">
        <IconButton onClick={onClick}>{children}</IconButton>
      </InputAdornment>
    )
  }

  return <InputAdornment position="end">{children}</InputAdornment>
}

const LeftIcon = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Grid width="24px" height="24px">
      {children}
    </Grid>
  )
}

const TYPE_INPUT_MODE_MAP: Record<InputType, 'text' | 'numeric'> = {
  text: 'text',
  number: 'numeric'
}

const NUMERIC_INPUT_PATTERN = '[0-9]*'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      value,
      disabled = false,
      onChange,
      onBlur,
      onRightIconClick,
      color,
      helperText,
      placeholder = '',
      assistive = false,
      clearInput,
      fullWidth = false,
      rightIcon,
      multiline,
      minRows,
      maxRows = 3,
      rows,
      type = 'text',
      onFocus,
      inputProps,
      InputProps,
      autoFocus = false,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {props.labelText != null && <StyledLabel>{props.labelText}</StyledLabel>}
        <StyledInput
          assistive={assistive}
          id={props.id}
          name={name}
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={props.onKeyDown}
          color={color}
          disabled={disabled}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          isRightIconClickable={onRightIconClick != null}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          rows={rows}
          onFocus={onFocus}
          hasLeftIcon={props.icon != null}
          {...(props.fontSizeVariant != null && { fontSizeVariant: props.fontSizeVariant })}
          {...(props.backgroundColor != null && { backgroundColor: props.backgroundColor })}
          InputProps={{
            startAdornment: props.icon != null && <LeftIcon>{props.icon}</LeftIcon>,
            endAdornment:
              clearInput != null ? (
                <InputAdornment position="end">
                  <StyledClearButton onClick={clearInput} disabled={disabled}>
                    <CancelBkg />
                  </StyledClearButton>
                </InputAdornment>
              ) : rightIcon != null ? (
                <RightIcon onClick={onRightIconClick}>{rightIcon}</RightIcon>
              ) : undefined,
            ...InputProps
          }}
          onClick={props.onClick}
          inputProps={{
            inputMode: TYPE_INPUT_MODE_MAP[type],
            ...(type === 'number' && {
              pattern: NUMERIC_INPUT_PATTERN
            }),
            ...inputProps
          }}
        />
        {helperText != null && (
          <StyledMessage>
            <Badge color={getBadgeColor(color)} />
            <span>{helperText}</span>
          </StyledMessage>
        )}
      </>
    )
  }
)

export default Input
