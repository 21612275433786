import {
  Accordion,
  Button,
  ChevronDownIcon,
  Divider,
  DropdownItem,
  DropdownList,
  Flyout,
  Grid,
  Radio,
  Slider,
  styled,
  Typography
} from '@precis-digital/kurama'
import { useState } from 'react'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { type OptimisationStrategy } from 'budgetOptimiser/api/transformations/transform'

interface Option {
  id: string
  text: string
  disabled?: boolean
}

export type AdSpendDistrubution = 'global' | 'platform_channel_grouping' | 'platform'
export type OptimisationTargetValue = '-20 %' | '-10 %' | '0 %' | '10 %' | '20 %'

export interface Filters {
  adSpendDistrubution: AdSpendDistrubution
  optimisationStrategy: OptimisationStrategy
  optimisationTargetValue: OptimisationTargetValue
}
interface EditFlyoutProps {
  onSave: (selectedOptions: Filters) => void
  filters: Filters
  hasConversionsButNoRevenue?: boolean
}

const marks = [
  {
    value: -20,
    label: '-20%'
  },
  {
    value: -10,
    label: '-10%'
  },
  {
    value: -5,
    label: '-5%'
  },
  {
    value: 0,
    label: '0%'
  },
  {
    value: 5,
    label: '5%'
  },
  {
    value: 10,
    label: '10%'
  },
  {
    value: 20,
    label: '20%'
  }
]

export const EditFlyout = ({ onSave, filters, hasConversionsButNoRevenue }: EditFlyoutProps): React.ReactElement => {
  const { t } = useTranslation('budgetOptimiser')

  const screen = useOverScreen('budgetOptimiserEditFlyout')

  const distributionOptions: Option[] = [
    {
      id: 'platform_channel_grouping',
      text: t('editFlyout.platform_channel_grouping')
    },
    {
      id: 'platform',
      text: t('editFlyout.platform')
    },
    {
      id: 'global',
      text: t('editFlyout.global')
    }
  ]

  const optimisingOptions: Option[] = [
    {
      id: 'revenue',
      text: t('editFlyout.revenue'),
      disabled: hasConversionsButNoRevenue === true
    },
    {
      id: 'conversions',
      text: t('editFlyout.conversion')
    }
  ]

  const [selectedDistributionOption, setSelectedDistributionOption] = useState<Option | undefined>(
    distributionOptions.find((o) => o.id === filters?.adSpendDistrubution)
  )
  const [selectedOptimisingOption, setSelectedOptimisingOption] = useState<Option | undefined>(
    optimisingOptions.find((option) => option.id === filters.optimisationStrategy)
  )

  const [optimisationTargetValue, setOptimisationTargetValue] = useState<OptimisationTargetValue>(
    filters?.optimisationTargetValue
  )
  const [expandedDistribution, setExpandedDistribution] = useState(false)
  const [expandedOptimisation, setExpandedOptimisation] = useState(false)
  const [expandedOptimisationTarget, setexpandedOptimisationTarget] = useState(false)

  const handleSave = (): void => {
    screen.remove()
    const selectedOptions: Filters = {
      adSpendDistrubution: selectedDistributionOption?.id as AdSpendDistrubution,
      optimisationStrategy: selectedOptimisingOption?.id as OptimisationStrategy,
      optimisationTargetValue
    }
    onSave(selectedOptions)
  }

  return (
    <Flyout
      onClose={(): void => {
        screen.remove()
      }}
      isExpanded={screen.visible}
    >
      <StyledHeader variant="h2">{t('editFlyout.budgetOptimisation')}</StyledHeader>

      <Divider />
      <StyledAccordion
        onChange={(event, expanded) => {
          setExpandedOptimisation(expanded)
        }}
        expanded={expandedOptimisation}
        Summary={
          <StyledSummaryWrapper>
            <Typography variant="h3">{t('editFlyout.optimisation')}</Typography>
            {!expandedOptimisation && <Typography variant="body2">{selectedOptimisingOption?.text}</Typography>}
          </StyledSummaryWrapper>
        }
        Details={
          <OptimisingFor
            options={optimisingOptions}
            onChange={(option) => {
              setSelectedOptimisingOption(option)
              setExpandedOptimisation(false)
            }}
            selectedOption={selectedOptimisingOption}
          />
        }
        expandIcon={<ChevronDownIcon />}
      />

      <Divider />
      <StyledAccordion
        onChange={(event, expanded) => {
          setexpandedOptimisationTarget(expanded)
        }}
        expanded={expandedOptimisationTarget}
        Summary={
          <StyledSummaryWrapper>
            <Typography variant="h3">{t('editFlyout.addSpendTarget')}</Typography>
            {!expandedOptimisationTarget && (
              <Typography variant="body2">
                <>
                  {' '}
                  {optimisationTargetValue} {t('editFlyout.change')}{' '}
                </>
              </Typography>
            )}
          </StyledSummaryWrapper>
        }
        Details={
          <OptimisationTarget
            onChangeValue={(value) => {
              setOptimisationTargetValue(`${value} %` as OptimisationTargetValue)
            }}
            value={parseInt(optimisationTargetValue)}
          />
        }
        expandIcon={<ChevronDownIcon />}
      />

      <Divider />

      <StyledAccordion
        onChange={(event, expanded) => {
          setExpandedDistribution(expanded)
        }}
        expanded={expandedDistribution}
        Summary={
          <StyledSummaryWrapper>
            <Typography variant="h3">{t('editFlyout.distribution')}</Typography>
            {!expandedDistribution && <Typography variant="body2">{selectedDistributionOption?.text}</Typography>}
          </StyledSummaryWrapper>
        }
        Details={
          <AdSpendDistribution
            options={distributionOptions}
            onChange={(option) => {
              setSelectedDistributionOption(option)
              setExpandedDistribution(false)
            }}
            selectedOption={selectedDistributionOption}
          />
        }
        expandIcon={<ChevronDownIcon />}
      />

      <StyledFlyoutFooter>
        <Divider />
        <StyledButtonWrapper>
          <Button
            onClick={(): void => {
              screen.remove()
            }}
            variant="outlined"
          >
            {t('editFlyout.cancelButton')}
          </Button>
          <Button
            onClick={() => {
              handleSave()
            }}
            variant="filled"
          >
            {t('editFlyout.saveButton')}
          </Button>
        </StyledButtonWrapper>
      </StyledFlyoutFooter>
    </Flyout>
  )
}

interface AdSpendDistributionProps {
  onChange: (selectedOption: Option) => void
  options: Option[]
  selectedOption?: Option
}

interface OptimisationTargetProps {
  onChangeValue: (value: number) => void
  value?: number
}

const AdSpendDistribution = ({ onChange, options, selectedOption }: AdSpendDistributionProps): React.ReactElement => {
  return (
    <StyledDropdownList>
      {options.map((option) => (
        <DropdownItem
          key={option.id}
          onClick={() => {
            onChange(option)
          }}
          control={<Radio checked={selectedOption?.id === option.id} />}
        >
          {option.text}
        </DropdownItem>
      ))}
    </StyledDropdownList>
  )
}

const OptimisingFor = ({ onChange, options, selectedOption }: AdSpendDistributionProps): React.ReactElement => {
  return (
    <StyledDropdownList>
      {options.map((option) => (
        <DropdownItem
          key={option.id}
          onClick={() => {
            onChange(option)
          }}
          control={<Radio checked={selectedOption?.id === option.id} />}
          disabled={option.disabled === true}
        >
          {option.text}
        </DropdownItem>
      ))}
    </StyledDropdownList>
  )
}

const OptimisationTarget = ({ onChangeValue, value }: OptimisationTargetProps): React.ReactElement => {
  const { t } = useTranslation('budgetOptimiser')
  const handleChange = (event: Event, newValue: number | number[]): void => {
    if (typeof newValue === 'number') {
      onChangeValue(newValue)
    }
  }
  return (
    <>
      <StyledAdSpendWrapper>
        <Typography variant="body1">{t('editFlyout.howMuchSpend')}</Typography>
      </StyledAdSpendWrapper>
      <Typography variant="h5">
        {value}% {t('editFlyout.more')}
      </Typography>
      <StyledSlider marks={marks} max={20} min={-20} onChange={handleChange} value={value} step={null} />
    </>
  )
}

export default OverScreen.create(EditFlyout)

const StyledDropdownList = styled(DropdownList)(() => ({
  padding: 0
}))

const StyledAccordion = styled(Accordion)(() => ({
  '::before': {
    backgroundColor: 'transparent'
  },
  '.MuiAccordionDetails-root': {
    padding: '0',
    '.MuiAccordionSummary-content': {
      margin: '0'
    }
  }
}))

const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  bottom: 0,
  marginTop: 'auto',
  width: '22rem',
  paddingBottom: '1rem',
  div: {
    ':first-of-type': {
      padding: 0,
      hr: {
        margin: '0 0 1rem 0'
      }
    },
    justifyContent: 'end'
  }
}))

const StyledButtonWrapper = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px'
}))

const StyledSummaryWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const StyledSlider = styled(Slider)(() => ({
  width: '332px',
  '& .MuiSlider-markLabel': {
    marginLeft: '3%'
  }
}))

const StyledAdSpendWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '14px',
  gap: '4px',
  marginBottom: '16px'
}))

const StyledHeader = styled(Typography)(() => ({
  marginTop: '5px'
}))
