import { type ChannelGrouping } from 'shared/api/channelGroupings'

export const STARTING_TEMPLATES: Array<{
  id: string
  name: string
  description: string
  subcategory: string
  channelGrouping: ChannelGrouping
}> = [
  {
    id: 'precis_market_grouping',
    name: 'Precis Market Grouping',
    description: "A template for grouping data into markets based on Precis' best practices.",
    subcategory: 'Precis Best Practice Templates',
    channelGrouping: {
      analytics: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.dk'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- dk -'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'denmark'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: 'Sweden'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '- se -'
              },
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.se'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- se -'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '- no -'
              },
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.no'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- no -'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'norway'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '- fi -'
              },
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.fi'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- fi -'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'finland'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '- uk -'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.co.uk'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- uk -'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: '- us -'
              },
              {
                dimension: 'profile_name',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- us -'
              }
            ]
          }
        }
      ],
      dv360: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: ' dk'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: ' dk'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: 'denmark'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: ' se'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: ' se'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: 'sweden'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: ' no'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: ' no'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: ' fi'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: ' fi'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: 'united kingdom'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'filter_advertiser_name',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'filter_insertion_order_name',
                operator: 'contains',
                value: 'united states'
              }
            ]
          }
        }
      ],
      facebook: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '- dk -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '- dk -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' dk'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '- se -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '- se -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' se'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '- no -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '- no -'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' no'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '- fi -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '- fi -'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' fi'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '- uk -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '- uk -'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' uk'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '- us -'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '- us -'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' us'
              }
            ]
          }
        }
      ],
      ga4: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.dk'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- dk -'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.se'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- se -'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.no'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- no -'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'norway'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.fi'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- fi -'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'hostname',
                operator: 'contains',
                value: '.co.uk'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- uk -'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: 'test',
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: '- us -'
              }
            ]
          }
        }
      ],
      google: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '- dk -'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '- dk -'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: ' dk'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '- se -'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '- se -'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: ' se'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '- no -'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '- no -'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: ' no'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '- fi -'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '- fi -'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: ' fi'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '- uk -'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '- uk -'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'united kingdom'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'customer__descriptive_name',
                operator: 'contains',
                value: 'unites states'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: '- us -'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'united states'
              }
            ]
          }
        }
      ],
      linkedin: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' dk'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' dk'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' se'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' se'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'sweden'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' no'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' no'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' fi'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' fi'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'united kingdom'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'account_name',
                operator: 'contains',
                value: 'united states'
              }
            ]
          }
        }
      ],
      microsoft_advertising: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: ' dk'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: ' dk'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'denmark'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: ' se'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: ' se'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: ' no'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: ' no'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: ' fi'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: ' fi'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'united kingdom'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'AccountName',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'united states'
              }
            ]
          }
        }
      ],
      tiktok: [
        {
          color: 'pink',
          id: '6cdd5b9e60964daca0f1ae40c7f98f9f',
          name: 'Denmark',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: '-dk-'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: ' dk'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' dk'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'denmark'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-dk-'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '624fa928ddf545898fd189d1a95df189',
          name: 'Sweden',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: ' se'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'sweden'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-se-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' se'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: '32126ffcd93547de8eb972ec0b15fadb',
          name: 'Norway',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: ' no'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-no-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'norway'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' no'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: 'd5a3f7c2b4f04656ae31e6b943ccb142',
          name: 'Finland',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: ' fi'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'finland'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-fi-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' fi'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '2c5a06767f214f2d881194129f418f5a',
          name: 'United Kingdom',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: 'united kingdom'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' uk'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-uk-'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'united kingdom'
              }
            ]
          }
        },
        {
          color: 'dark-beige',
          id: 'ded43bc82af44cc3a5b144d78fe3dc17',
          name: 'United States',
          note: null,
          category: 'other',
          rules: {
            0: [
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: '-us-'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'advertiser_name',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: ' us'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: 'united states'
              },
              {
                dimension: 'campaign_name',
                operator: 'contains',
                value: '-us-'
              }
            ]
          }
        }
      ]
    }
  },
  {
    id: 'precis_channel_grouping',
    name: 'Precis Channel Grouping',
    description: "A template for grouping data into channels based on Precis' best practices.",
    subcategory: 'Precis Best Practice Templates',
    channelGrouping: {
      analytics: [
        {
          color: 'dark-beige',
          id: '16d52948768e473d9168a94ffd3682fc',
          name: 'Paid Search - Brand',
          note: null,
          category: 'paid_search_brand',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'own brand'
              }
            ]
          }
        },
        {
          color: 'indigo',
          id: 'db091280059444a181b94d25ea5cda64',
          name: 'Paid Search - Shopping',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'shopping'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '3b8e7d2d2c8141969151619a67b30d0c',
          name: 'Paid Search - Search',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'search'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'dsa'
              }
            ]
          }
        },
        {
          color: 'dark-indigo',
          id: '294a188ca6e4418a8cb0a82e8ea4385a',
          name: 'Paid Search - PMax',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'pmax'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'performance max'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'performance-max'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '8c1da7cd5ba541829933f6bc1c62bc44',
          name: 'Paid Search - Video',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'video'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'youtube'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '4e6b2ccaf8f04f22a8b5095b40b64972',
          name: 'Paid Search - Display',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'display'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '932f545c5f8e4cd1bf06064e94f6ea9f',
          name: 'Paid Search - Other',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ]
          }
        },
        {
          color: 'red',
          id: '88b4b42d4f0c45e680ba7429a6e73961',
          name: 'Paid Social',
          note: null,
          category: 'paid_social_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'pinterest / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'pinterest / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'tiktok / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'tiktok / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'snapchat / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'snapchat / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'linkedin / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'linkedin / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / paidsocial'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / paid'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: 'd46aec35a4ac4b55869e31e8a82943eb',
          name: 'Programmatic',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'dfa / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'dbm / cpm'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '259a81e7c58d42f484844b054b4cf78f',
          name: 'Affiliates',
          note: null,
          category: 'paid_affiliate',
          rules: {
            '0': [
              {
                dimension: 'medium',
                operator: 'contains',
                value: 'affiliate'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: '207c56edd740467eb3ada5e11fce1405',
          name: 'Organic Search',
          note: null,
          category: 'non_paid_organic',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'equals',
                value: 'google'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'bing'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'yahoo'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'duckduckgo'
              }
            ],
            '1': [
              {
                dimension: 'medium',
                operator: 'equals',
                value: 'organic'
              }
            ]
          }
        },
        {
          color: 'green',
          id: 'f34165387eef4544a62995fc118446e6',
          name: 'Organic Social',
          note: null,
          category: 'non_paid_other',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'contains',
                value: 'facebook'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'instagram'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'pinterest'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'snapchat'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'tiktok'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'linkedin'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'm.facebook.com'
              },
              {
                dimension: 'source_medium',
                operator: 'contains',
                value: 'lm.facebook.com / referral'
              },
              {
                dimension: 'source_medium',
                operator: 'contains',
                value: 'l.facebook.com / referral'
              }
            ]
          }
        },
        {
          color: 'purple',
          id: '411f79cd35dc4036b7c81217750e09b7',
          name: 'Email',
          note: null,
          category: 'non_paid_push',
          rules: {
            '0': [
              {
                dimension: 'medium',
                operator: 'equals',
                value: 'email'
              }
            ]
          }
        },
        {
          color: 'dark-purple',
          id: '16998a7752d54a1aadb5b04070d53c7b',
          name: 'Referral',
          note: null,
          category: 'non_paid_referral',
          rules: {
            '0': [
              {
                dimension: 'medium',
                operator: 'equals',
                value: 'referral'
              }
            ]
          }
        },
        {
          color: 'extra-dark-purple',
          id: 'f122bc9a01ab4beea5bb453a32bde1e2',
          name: 'Price Comparison Sites',
          note: null,
          category: 'paid_affiliate',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'equals',
                value: 'idealo'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'billiger'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'kelkoo'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'bestcheck'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'pricerunner'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'prisjakt'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'prisjagt'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'hintaopas'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'hintaseuranta'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'hinta'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'prisguiden'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'shopello'
              }
            ]
          }
        },
        {
          color: 'pink',
          id: '7a7cadb9ce1e443aaf2b2652907ab981',
          name: 'Direct',
          note: null,
          category: 'non_paid_direct',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'equals',
                value: '(direct)'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: '(not set) / (not set)'
              }
            ]
          }
        }
      ],
      dv360: [
        {
          color: 'mint',
          id: 'd46aec35a4ac4b55869e31e8a82943eb',
          name: 'Programmatic',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'filter_advertiser_name',
                operator: 'matches_regex',
                value: '.*'
              }
            ]
          }
        }
      ],
      facebook: [
        {
          color: 'red',
          id: '88b4b42d4f0c45e680ba7429a6e73961',
          name: 'Paid Social',
          note: null,
          category: 'paid_social_prospecting',
          rules: {
            '0': [
              {
                dimension: 'account_name',
                operator: 'matches_regex',
                value: '.'
              }
            ]
          }
        }
      ],
      ga4: [
        {
          color: 'dark-beige',
          id: '16d52948768e473d9168a94ffd3682fc',
          name: 'Paid Search - Brand',
          note: null,
          category: 'paid_search_brand',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'own brand'
              }
            ]
          }
        },
        {
          color: 'indigo',
          id: 'db091280059444a181b94d25ea5cda64',
          name: 'Paid Search - Shopping',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'shopping'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '3b8e7d2d2c8141969151619a67b30d0c',
          name: 'Paid Search - Search',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ]
          }
        },
        {
          color: 'dark-indigo',
          id: '294a188ca6e4418a8cb0a82e8ea4385a',
          name: 'Paid Search - PMax',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'pmax'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'performance max'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'performance-max'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '8c1da7cd5ba541829933f6bc1c62bc44',
          name: 'Paid Search - Video',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'video'
              },
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'youtube'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '4e6b2ccaf8f04f22a8b5095b40b64972',
          name: 'Paid Search - Display',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              }
            ],
            '1': [
              {
                dimension: 'campaign',
                operator: 'contains',
                value: 'display'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '932f545c5f8e4cd1bf06064e94f6ea9f',
          name: 'Paid Search - Other',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'bing / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'google / cpc'
              }
            ]
          }
        },
        {
          color: 'red',
          id: '88b4b42d4f0c45e680ba7429a6e73961',
          name: 'Paid Social',
          note: null,
          category: 'paid_social_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'pinterest / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'pinterest / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'tiktok / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'tiktok / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'snapchat / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'snapchat / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'linkedin / cpc'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'linkedin / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / paidsocial'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'facebook / paid'
              }
            ]
          }
        },
        {
          color: 'mint',
          id: 'd46aec35a4ac4b55869e31e8a82943eb',
          name: 'Programmatic',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'dfa / cpm'
              },
              {
                dimension: 'source_medium',
                operator: 'equals',
                value: 'dbm / cpm'
              }
            ]
          }
        },
        {
          color: 'steel',
          id: '259a81e7c58d42f484844b054b4cf78f',
          name: 'Affiliates',
          note: null,
          category: 'paid_affiliate',
          rules: {
            '0': [
              {
                dimension: 'medium',
                operator: 'contains',
                value: 'affiliate'
              }
            ]
          }
        },
        {
          color: 'beige',
          id: '207c56edd740467eb3ada5e11fce1405',
          name: 'Organic Search',
          note: null,
          category: 'non_paid_organic',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'equals',
                value: 'google'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'bing'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'yahoo'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'duckduckgo'
              }
            ],
            '1': [
              {
                dimension: 'medium',
                operator: 'equals',
                value: 'organic'
              }
            ]
          }
        },
        {
          color: 'green',
          id: 'f34165387eef4544a62995fc118446e6',
          name: 'Organic Social',
          note: null,
          category: 'non_paid_other',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'contains',
                value: 'facebook'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'instagram'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'pinterest'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'snapchat'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'tiktok'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'linkedin'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'm.facebook.com'
              },
              {
                dimension: 'source_medium',
                operator: 'contains',
                value: 'lm.facebook.com / referral'
              },
              {
                dimension: 'source_medium',
                operator: 'contains',
                value: 'l.facebook.com / referral'
              }
            ]
          }
        },
        {
          color: 'purple',
          id: '411f79cd35dc4036b7c81217750e09b7',
          name: 'Email',
          note: null,
          category: 'non_paid_push',
          rules: {
            '0': [
              {
                dimension: 'medium',
                operator: 'equals',
                value: 'email'
              }
            ]
          }
        },
        {
          color: 'dark-purple',
          id: '16998a7752d54a1aadb5b04070d53c7b',
          name: 'Referral',
          note: null,
          category: 'non_paid_referral',
          rules: {
            '0': [
              {
                dimension: 'medium',
                operator: 'equals',
                value: 'referral'
              }
            ]
          }
        },
        {
          color: 'extra-dark-purple',
          id: 'f122bc9a01ab4beea5bb453a32bde1e2',
          name: 'Price Comparison Sites',
          note: null,
          category: 'paid_affiliate',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'equals',
                value: 'idealo'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'billiger'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'kelkoo'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'bestcheck'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'pricerunner'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'prisjakt'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'prisjagt'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'hintaopas'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'hintaseuranta'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'hinta'
              },
              {
                dimension: 'source',
                operator: 'equals',
                value: 'prisguiden'
              },
              {
                dimension: 'source',
                operator: 'contains',
                value: 'shopello'
              }
            ]
          }
        },
        {
          color: 'pink',
          id: '7a7cadb9ce1e443aaf2b2652907ab981',
          name: 'Direct',
          note: null,
          category: 'non_paid_direct',
          rules: {
            '0': [
              {
                dimension: 'source',
                operator: 'equals',
                value: '(direct)'
              }
            ]
          }
        }
      ],
      google: [
        {
          color: 'dark-beige',
          id: '16d52948768e473d9168a94ffd3682fc',
          name: 'Paid Search - Brand',
          note: null,
          category: 'paid_search_brand',
          rules: {
            '0': [
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'own brand'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '3b8e7d2d2c8141969151619a67b30d0c',
          name: 'Paid Search - Search',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'search'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'dsa'
              }
            ]
          }
        },
        {
          color: 'indigo',
          id: 'db091280059444a181b94d25ea5cda64',
          name: 'Paid Search - Shopping',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'shopping'
              }
            ]
          }
        },
        {
          color: 'dark-indigo',
          id: '294a188ca6e4418a8cb0a82e8ea4385a',
          name: 'Paid Search - PMax',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'pmax'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'performance-max'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'performance max'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '4e6b2ccaf8f04f22a8b5095b40b64972',
          name: 'Paid Search - Display',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'display'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '8c1da7cd5ba541829933f6bc1c62bc44',
          name: 'Paid Search - Video',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'video'
              },
              {
                dimension: 'campaign__name',
                operator: 'contains',
                value: 'youtube'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '932f545c5f8e4cd1bf06064e94f6ea9f',
          name: 'Paid Search - Other',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'customer__descriptive_name',
                operator: 'matches_regex',
                value: '.'
              }
            ]
          }
        }
      ],
      linkedin: [
        {
          color: 'red',
          id: '88b4b42d4f0c45e680ba7429a6e73961',
          name: 'Paid Social',
          note: null,
          category: 'paid_social_prospecting',
          rules: {
            '0': [
              {
                dimension: 'account_name',
                operator: 'matches_regex',
                value: '.'
              }
            ]
          }
        }
      ],
      microsoft_advertising: [
        {
          color: 'dark-beige',
          id: '16d52948768e473d9168a94ffd3682fc',
          name: 'Paid Search - Brand',
          note: null,
          category: 'paid_search_brand',
          rules: {
            '0': [
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'own brand'
              }
            ]
          }
        },
        {
          color: 'indigo',
          id: 'db091280059444a181b94d25ea5cda64',
          name: 'Paid Search - Shopping',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'shopping'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '3b8e7d2d2c8141969151619a67b30d0c',
          name: 'Paid Search - Search',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'search'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'dsa'
              }
            ]
          }
        },
        {
          color: 'dark-indigo',
          id: '294a188ca6e4418a8cb0a82e8ea4385a',
          name: 'Paid Search - PMax',
          note: null,
          category: 'paid_shopping_generic',
          rules: {
            '0': [
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'pmax'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'performance max'
              },
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'performance-max'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '4e6b2ccaf8f04f22a8b5095b40b64972',
          name: 'Paid Search - Display',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'display'
              }
            ]
          }
        },
        {
          color: 'dark-mint',
          id: '8c1da7cd5ba541829933f6bc1c62bc44',
          name: 'Paid Search - Video',
          note: null,
          category: 'paid_impression_prospecting',
          rules: {
            '0': [
              {
                dimension: 'CampaignName',
                operator: 'contains',
                value: 'video'
              }
            ]
          }
        },
        {
          color: 'yellow',
          id: '932f545c5f8e4cd1bf06064e94f6ea9f',
          name: 'Paid Search - Other',
          note: null,
          category: 'paid_search_generic',
          rules: {
            '0': [
              {
                dimension: 'AccountName',
                operator: 'matches_regex',
                value: '.'
              }
            ]
          }
        }
      ],
      tiktok: [
        {
          color: 'red',
          id: '88b4b42d4f0c45e680ba7429a6e73961',
          name: 'Paid Social',
          note: null,
          category: 'paid_social_prospecting',
          rules: {
            '0': [
              {
                dimension: 'advertiser_name',
                operator: 'matches_regex',
                value: '.'
              }
            ]
          }
        }
      ]
    }
  }
]
