import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderGroupParams,
  FilterOptionsState,
  PopperProps,
  Autocomplete as MUIAutocomplete,
  createFilterOptions,
  AutocompleteRenderOptionState,
  AutocompleteRenderGetTagProps,
  AutocompleteValue
} from '@mui/material'
import {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  AutocompleteOptionItem,
  AutocompleteProps,
  ListboxComponentProps,
  RenderInputParams,
  RenderOptionProps,
  SaveCustomValueTextComponentProps
} from './types'
import DropdownList from '../Dropdown/DropdownList'
import DropdownItem from '../Dropdown/DropdownItem'
import { StyledInputAutocompleteTextField, StyledPopper, StyledTypographyNoOptionsText } from './styles'
import DropdownListSubCategory from '../Dropdown/DropdownListSubCategory'
import Grid from '../Grid'
import Chip from '../Chip'
import Divider from '../Divider'
import Checkbox from '../Checkbox'
import Radio from '../Radio/Radio'
import { ChevronDownIcon } from '../Icons'
import Typography from '../Typography'
import DotsLoader from '../DotsLoader'

const renderInput = (
  params: RenderInputParams,
  color?: 'error' | 'success' | undefined,
  helperText?: string | undefined
): ReactNode => {
  return (
    <div>
      <StyledInputAutocompleteTextField
        {...params}
        value={!params.multiple ? params.inputElement?.value?.toString() ?? '' : undefined}
        color={color}
        assistive={false}
        isRightIconClickable={false}
        hasLeftIcon={false}
      />
      {helperText !== undefined && (
        <Typography color={color} variant="body3">
          {helperText}
        </Typography>
      )}
    </div>
  )
}

const PopperComponent = (props: PopperProps): ReactElement => {
  return (
    <StyledPopper
      disablePortal={props.disablePortal}
      open={props.open}
      anchorEl={props.anchorEl}
      role={props.role}
      style={props.style}
      placement={props.placement}
      onMouseDown={(event) => event.preventDefault()}
    >
      {props.children}
    </StyledPopper>
  )
}

const SaveCustomValueTextComponent = ({
  acceptCustomInputTemplate,
  inputValue
}: SaveCustomValueTextComponentProps): ReactElement => {
  const [firstFragment, secondFragment] = acceptCustomInputTemplate.split('{chipKey}')
  return (
    <Grid display="flex" flexWrap="nowrap" alignItems="center" gap="4px">
      <Typography variant="h5">{firstFragment.replace('{value}', inputValue)}</Typography>
      <Chip label="Enter" />
      <Typography variant="h5">{secondFragment.replace('{value}', inputValue)}</Typography>
    </Grid>
  )
}

const ListboxComponent = forwardRef<HTMLUListElement, ListboxComponentProps>(
  (props: ListboxComponentProps, ref): ReactElement => {
    const shouldRenderSaveCustomValueText =
      props.allowCustomInput &&
      props.inputElement?.value != null &&
      props.inputElement?.value !== '' &&
      !props.hasExactMatch &&
      !props.elementIsAlreadyInValue

    return (
      <DropdownList
        dense
        role={props.role}
        id={props.id}
        aria-labelledby={props['aria-labelledby']}
        maxWidth="100%"
        ref={ref}
      >
        {props.children}
        {shouldRenderSaveCustomValueText && (
          <>
            {props.numFilteredOptions > 0 ? <Divider /> : <Grid height="4px" />}
            <SaveCustomValueTextComponent
              inputValue={props.inputElement?.value as string}
              acceptCustomInputTemplate={props.acceptCustomInputTemplate}
            />
          </>
        )}
      </DropdownList>
    )
  }
)

const renderGroup = (params: AutocompleteRenderGroupParams): ReactElement => {
  if (params.group === '') return <>{params.children}</>

  return <DropdownListSubCategory header={params.group}>{params.children}</DropdownListSubCategory>
}

const renderOption = (
  props: RenderOptionProps,
  option: AutocompleteOptionItem,
  state: AutocompleteRenderOptionState,
  noOptionsTextTemplate: string,
  inputValue: string,
  multiple: boolean
): ReactElement => {
  const onClick = props.onClick ?? (() => {})
  if (option.label === 'CUSTOM_INPUT_OTHER_OPTIONS') return <></>

  if (option.label === 'CUSTOM_INPUT_NO_OPTIONS')
    return (
      <StyledTypographyNoOptionsText variant="h5">
        {noOptionsTextTemplate.replace('{value}', inputValue)}
      </StyledTypographyNoOptionsText>
    )

  return (
    <DropdownItem
      tabIndex={props.tabIndex}
      id={props.id}
      data-option-index={props['data-option-index']}
      aria-disabled={option.disabled}
      aria-selected={props['aria-selected']}
      role={props.role}
      onClick={onClick}
      key={option.value}
      annotation={option.annotation}
      icon={option.icon}
      disabled={option.disabled}
      control={multiple ? <Checkbox checked={state.selected} /> : <Radio checked={state.selected} />}
      annotationLineType={option.annotationLineType}
      tooltipProps={option.tooltipProps}
    >
      {option.customLabelComponent ?? option.label}
    </DropdownItem>
  )
}

const renderTags = (value: AutocompleteOptionItem[], getTagProps: AutocompleteRenderGetTagProps): ReactElement => {
  return (
    <>
      {value.map((option, index) => {
        const tagProps = getTagProps({ index })

        return <Chip label={option.label} avatar={option.icon} {...tagProps} key={tagProps.key} />
      })}
    </>
  )
}

const defaultIsOptionEqualToValue = <T extends AutocompleteOptionItem>(option: T, value: string): boolean =>
  option.value === value

const Autocomplete = <T extends AutocompleteOptionItem, Multiple extends boolean, AllowCustomInput extends boolean>({
  options,
  value,
  onChange,
  multiple,
  allowCustomInput,
  fullWidth = false,
  placeholder = 'Add item...',
  noOptionsTextTemplate = 'Sorry, we couldn’t find anything with the name "{value}".',
  acceptCustomInputTemplate = 'Press {chipKey} to use "{value}" as item.',
  customInputIcon,
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  dropdownPlacement,
  color,
  helperText,
  isLoadingOptions = false,
  loadingText = 'Loading options...'
}: AutocompleteProps<T, Multiple, AllowCustomInput>): ReactElement => {
  const autocompleteRef = useRef<HTMLDivElement>(null)
  const [localInputElement, setLocalInputElement] = useState<HTMLInputElement | null>(null)

  useEffect(() => {
    if (autocompleteRef.current !== null) {
      setLocalInputElement(autocompleteRef.current?.querySelector('input'))
    }
  }, [autocompleteRef.current])

  const handleChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      newValue: AutocompleteValue<T, Multiple, true, AllowCustomInput>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T> | undefined
    ): void => {
      if (Array.isArray(newValue)) {
        if (reason === 'removeOption' && details?.option.label === 'CUSTOM_INPUT_NO_OPTIONS') {
          // Due to an internal bug in MUI, this needs to handle the special case where there's only 1 chip in the value
          // and the user inputs the exact same value as that 1 chip. For some reason that comes through as a "removeOption", but
          // it should simply be treated as any other "duplicate" value input in freesolo.
          onChange(event, value, reason, details)
          if (localInputElement != null) localInputElement.value = ''
          return
        }
        const parsedValue = newValue.map((rowValue) => {
          if (typeof rowValue === 'string') {
            const optionToCreateIfNoMatch = { value: rowValue, label: rowValue, icon: customInputIcon }
            const matchingOption = options.find((option) => isOptionEqualToValue(option, rowValue))

            return matchingOption ?? (optionToCreateIfNoMatch as AutocompleteOptionItem)
          }

          return rowValue
        })

        const uniqueValues = new Set()
        const filteredValues = parsedValue.filter((rowValue) => {
          if (uniqueValues.has(rowValue.value)) {
            return false
          } else {
            uniqueValues.add(rowValue.value)
            return true
          }
        })

        onChange(event, filteredValues as Multiple extends true ? T[] : T | null, reason, details)
        return
      }

      const isNotMultipleInputValue = typeof newValue === 'string'
      if (isNotMultipleInputValue) {
        const optionToCreateIfNoMatch = { value: newValue, label: newValue }
        const matchingOption = options.find((option) => isOptionEqualToValue(option, newValue))
        if (matchingOption?.disabled === true) return // don't allow selecting disabled options

        onChange(
          event,
          (matchingOption ?? (optionToCreateIfNoMatch as AutocompleteOptionItem)) as Multiple extends true
            ? T[]
            : T | null,
          reason,
          details
        )
      } else {
        onChange(event, newValue as Multiple extends true ? T[] : T | null, reason, details)
      }
    },
    [customInputIcon, value]
  )

  const filter = createFilterOptions<T>()
  const ListBoxComponentWithInputValue = useCallback(
    forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>((props, ref): ReactElement => {
      const filteredOptions = filter(options, {
        inputValue: localInputElement?.value ?? '',
        getOptionLabel: (option) => option.label
      })
      const hasExactMatch = filteredOptions.some((option) =>
        isOptionEqualToValue(option, localInputElement?.value ?? '')
      )
      const elementIsAlreadyInValue = Array.isArray(value)
        ? value?.some((option) => isOptionEqualToValue(option, localInputElement?.value ?? ''))
        : false

      return (
        <ListboxComponent
          {...props}
          ref={ref}
          inputElement={localInputElement}
          allowCustomInput={allowCustomInput}
          numFilteredOptions={filteredOptions.length}
          hasExactMatch={hasExactMatch}
          acceptCustomInputTemplate={acceptCustomInputTemplate}
          elementIsAlreadyInValue={elementIsAlreadyInValue}
        />
      )
    }),
    [localInputElement, allowCustomInput, acceptCustomInputTemplate, value]
  )

  const customFilterOptions = useCallback(
    (options: T[], params: FilterOptionsState<T>): T[] => {
      const filteredOptions = filter(options, params)

      const { inputValue } = params

      const isExisting = options.some((option) => isOptionEqualToValue(option, inputValue))
      if (inputValue !== '' && !isExisting) {
        const customInputOption = {
          value: inputValue,
          label: filteredOptions.length === 0 ? 'CUSTOM_INPUT_NO_OPTIONS' : 'CUSTOM_INPUT_OTHER_OPTIONS',
          icon: customInputIcon
        }

        filteredOptions.push(customInputOption as T)
      }

      return filteredOptions
    },
    [customInputIcon]
  )

  return (
    <MUIAutocomplete<T, Multiple, boolean, AllowCustomInput>
      value={value}
      freeSolo={allowCustomInput}
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value
      }}
      multiple={multiple}
      fullWidth={fullWidth}
      onChange={handleChange}
      renderInput={(params) =>
        renderInput({ ...params, placeholder, multiple, inputElement: localInputElement }, color, helperText)
      }
      renderOption={(options, params, state) =>
        renderOption(options, params, state, noOptionsTextTemplate, localInputElement?.value ?? '', multiple)
      }
      blurOnSelect={!multiple}
      selectOnFocus={false}
      clearOnBlur
      disableCloseOnSelect={multiple}
      popupIcon={<ChevronDownIcon />}
      filterOptions={customFilterOptions}
      renderTags={renderTags}
      options={options}
      PopperComponent={PopperComponent}
      componentsProps={{
        popper: { placement: dropdownPlacement }
      }}
      ListboxComponent={ListBoxComponentWithInputValue}
      groupBy={(option) => option.subCategory ?? ''}
      renderGroup={renderGroup}
      sx={{
        '&.MuiAutocomplete-root': {
          width: '326px'
        },
        '&.MuiAutocomplete-fullWidth': {
          width: '100%'
        }
      }}
      ref={autocompleteRef}
      color={color}
      loading={isLoadingOptions}
      loadingText={
        <Grid display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{loadingText}</Typography>
          <DotsLoader height={24} width={24} />
        </Grid>
      }
    />
  )
}

export default Autocomplete
