import MUITableCell from '@mui/material/TableCell'
import { CSSObject } from '../../theme'
import { styled } from '../../../utils'
import { TableHeaderCellProps } from './types'

export const StyleTableHeaderCell = styled(MUITableCell)(
  (): CSSObject => ({
    border: 'none',
    padding: 0
  })
) as React.ComponentType<TableHeaderCellProps>
