import React from 'react'

export const Search = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M11.1112 18.2224C15.0386 18.2224 18.2224 15.0386 18.2224 11.1112C18.2224 7.1838 15.0386 4 11.1112 4C7.1838 4 4 7.1838 4 11.1112C4 15.0386 7.1838 18.2224 11.1112 18.2224Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20.0003L16.1333 16.1335"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
