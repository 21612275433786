import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'pmm_report',
  active: true,
  label: 'Product Mix Modeling Report',
  category: 'Product analysis',
  description:
    'This report utilizes a MACD methodology. This method is designed to compare short-term (7 day) vs. medium term (30 day) trends. The dashboard evaluates whether different products, brands and categories are surging or plummeting in demand.',
  image_url:
    'https://lh3.googleusercontent.com/fife/APg5EOar2E5rUdSgYBpO_GwDWw_rU4JPWmMhjBcBI7evJWXu__hFJa-N9ojtRmH_FCcJ3qKKAVsN3rxXLw6YjTEp0W2l74ddst5uMfp5PRmhzRVnTifXamYGDJEuS5cqQY_l76aB_uSknYp6UFTTar79HK9YV0jf76BgK-_29x8KGEpkjSS27jYRVBvdOmevE-rG5_qeKMb1vF5hHj-3RlT3r1UzX3Wh2peQRb0WyScH1suoPnqN4Qr8DhSk6dWAC53YcBr4TYsi0PQ_Fa17e9iOAm6PPOZAv39-7LHV5S7mbOlvpuK6pObHBXqvpFMu4eywG4MX99oSScKEE1NEkmlKDdup9yZ9pe8J27VDAGdAy6-jsDLdJDKb9jIY17eFZ0j4iC5S6Cvv76eHpS6En6ZNCl4_J5pwieu6_q7IJ6h6S7NEW7JEIbI3QI96pACUQ7JialorVAW0W9dS0mim3ujQyBAr-SB8phvHpUXH6b_5RmJ9A8yb2reGrLrh2jdcBYah0NrgnfypqX3ZyIykyUXxfW_tQbTAy171KyQnK35aoZmJzrUzJSamtyW7eDt4BnWeD-fYylFQTv-rkIrlaUJ4cF-6aZQjdbfIe7z7ebyoKsUmfgAKwEiYMDDklZ6595-7iZsmslBBBQd1OIjEQI3iIr7oAWCQIgGOpM80vAuCXOjBQF1aY8lIxRPtTI9_CJTe6NYqPihOuUS-YTQvERTYiUv5db-pwL-qMdh_D1BFyrpJmqGSi4ALCDLoUhNK9_5pgncEGuuY3cVFoMLrz-6lvQ7xZappGb1nvGmrWfYYJTH9LuY7qyfPpYkPOS7gMR-wSPKElgCq2Rnak14fTpLIlVCL-rhTWT52GQj4W1Dehljbv0jM63ZTvSlG2zTxI3lTa-Eohd1bk6s_RsTa51rNsVhkGXW3T7IUpV32xwWAkxl-9ZIg74XtU5NRV4Xu3FHzv68ABVe_6g=w3024-h1668',
  authors: [
    {
      name: 'Julius Antonio Bladt',
      email: 'julius@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14Gix6wIcs3Jtvskd3AU_lfhPTb5Ua90aqlw5InWd=s256-c-k-no'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: '/',
    link_description: 'Knowledge Hub: PMM report'
  },
  dashboard: {
    template_report_id: 'c73cb750-bd33-4958-8fc1-7d7957845bfa',
    report_name_template: 'copy of Product Mix Modeling Report - {today}',
    datasources: [
      {
        alias: 'pmm_products_base_metrics',
        datasourceName: '{name} - Products Base Metrics Table',
        type: 'TABLE',
        tableId: 'pmm_product_base_metrics_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      },
      {
        alias: 'pmm_base_metrics',
        datasourceName: '{name} - Base Metrics Table',
        type: 'TABLE',
        tableId: 'pmm_ga4_base_metrics_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      },
      {
        alias: 'pmm_category',
        datasourceName: '{name} - Category Table',
        type: 'TABLE',
        tableId: 'pmm_item_category_level_report_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      },
      {
        alias: 'pmm_brand',
        datasourceName: '{name} - Brand Table',
        type: 'TABLE',
        tableId: 'pmm_item_brand_level_report_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      },
      {
        alias: 'pmm_sku_market',
        datasourceName: '{name} - SKU Market Table',
        type: 'TABLE',
        tableId: 'pmm_sku_market_level_report_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      },
      {
        alias: 'pmm_brand_market',
        datasourceName: '{name} - Brand Market Table',
        type: 'TABLE',
        tableId: 'pmm_brand_market_level_report_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      },
      {
        alias: 'pmm_category_market',
        datasourceName: '{name} - Category Market Table',
        type: 'TABLE',
        tableId: 'pmm_category_market_level_report_{name}',
        isPartitioned: 'false',
        refreshFields: 'false'
      }
    ]
  },
  sources: {
    ga4: {
      reports: ['pmm_base', 'campaign_performance']
    }
  },
  inputs: {
    ga4: [
      {
        id: 'pmmMarketSql',
        type: 'case_statement',
        label: 'Custom Market Grouping',
        helperText: 'The Market Grouping that defines your markets in GA4.',
        required: true,
        mutable: true,
        dimensions: [
          {
            value: 'view_or_property_id',
            label: 'Property ID',
            type: 'string'
          },
          {
            value: 'hostname',
            label: 'Hostname',
            type: 'string'
          }
        ],
        defaultValue:
          "CASE WHEN (LOWER(hostname) LIKE '%.dk%') THEN 'Denmark' WHEN (LOWER(hostname) LIKE '%.se%') THEN 'Sweden' WHEN (LOWER(hostname) LIKE '%.no%') THEN 'Norway' WHEN (LOWER(hostname) LIKE '%.fi%') THEN 'Finland' WHEN (LOWER(hostname) LIKE '%.de%') THEN 'Germany' ELSE 'Other' END"
      }
    ],
    other: [
      {
        id: 'targetCurrency',
        type: 'dropdown',
        label: 'Target Currency',
        defaultValue: 'EUR',
        tooltip: 'The currency to transform all currency-based columns (cost, revenue, etc) to.',
        required: true,
        mutable: true
      }
    ]
  }
}

export default config
