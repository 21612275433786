import {
  Divider,
  Flyout,
  Grid,
  defaultTheme as theme,
  styled,
  Typography,
  DropdownList,
  DropdownItem,
  Button,
  Checkbox
} from '@precis-digital/kurama'
import { useState } from 'react'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { type TFunction } from 'i18next'
import { makeErrorToast } from 'shared/components/Toaster'
import { sortOptionInAlphabeticalOrder } from 'shared/utils'
import { store } from 'shared/lstore'

const spacing = theme.spacing

const MINIMUM_METRICS = 2
const MAXIMUM_METRICS = 12

interface Option {
  value: string
  label: string
}

export interface TopCardMetricsFlyoutProps {
  metricOptions: Option[]
  selectedMetrics: string[]
  setSelectedMetrics: (selectedMetrics: string[]) => void
  t: TFunction<'home'>
  metricsStorageKey: string
}

export const TopCardMetricsFlyout = ({
  metricOptions,
  selectedMetrics,
  setSelectedMetrics,
  t,
  metricsStorageKey
}: TopCardMetricsFlyoutProps): React.ReactElement => {
  const [newlySelectedMetrics, setNewlySelectedMetrics] = useState<string[]>(selectedMetrics)

  const screen = useOverScreen('topCardMetricsFlyout')

  const handleClose = (): void => {
    screen.remove()
  }

  const handleSave = (): void => {
    if (newlySelectedMetrics.length < MINIMUM_METRICS) {
      makeErrorToast(t('topCardMetrics.minimumTwoMetricsError'))
      return
    }

    if (newlySelectedMetrics.length > MAXIMUM_METRICS) {
      makeErrorToast(t('topCardMetrics.maximumTwelveMetricsError'))
      return
    }

    setSelectedMetrics(newlySelectedMetrics)
    store(metricsStorageKey, JSON.stringify(newlySelectedMetrics))
    screen.remove()
  }

  const handleOptionClick = (option: Option): void => {
    const filterByMetric = (item: string): boolean => item !== option.value
    setNewlySelectedMetrics((prev) => {
      let currentlySelectedMetrics = prev
      currentlySelectedMetrics = prev.includes(option.value) ? prev.filter(filterByMetric) : [...prev, option.value]

      return currentlySelectedMetrics
    })
  }

  metricOptions.sort(sortOptionInAlphabeticalOrder)

  return (
    <Flyout closeText={t('buttons.close')} onClose={handleClose} isExpanded={screen.visible}>
      <Typography variant="h2">{t('buttons.selectMetrics')}</Typography>
      <Divider />
      <Grid paddingBottom="70px">
        <DropdownList style={{ padding: 0 }}>
          {metricOptions.map((option) => {
            const optionIsSelected = newlySelectedMetrics.includes(option.value)
            return (
              <DropdownItem
                key={option.value}
                onClick={() => {
                  handleOptionClick(option)
                }}
                disabled={newlySelectedMetrics.length >= MAXIMUM_METRICS && !optionIsSelected}
                control={<Checkbox checked={optionIsSelected} />}
              >
                <Grid display="flex" gap="4px" justifyContent="space-between" width="100%">
                  <Typography variant="body1">{option.label}</Typography>
                  {optionIsSelected && (
                    <Grid width="1em">
                      <StyledTypographyGrey variant="body1">
                        {newlySelectedMetrics.indexOf(option.value) + 1}
                      </StyledTypographyGrey>
                    </Grid>
                  )}
                </Grid>
              </DropdownItem>
            )
          })}
        </DropdownList>
      </Grid>

      <StyledFlyoutFooter>
        <Divider />
        <Grid display="flex" justifyContent="flex-end" gap="8px">
          <Button
            onClick={(): void => {
              screen.remove()
            }}
            variant="outlined"
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              handleSave()
            }}
            variant="filled"
          >
            {t('buttons.updateMetrics')}
          </Button>
        </Grid>
      </StyledFlyoutFooter>
    </Flyout>
  )
}

export default OverScreen.create(TopCardMetricsFlyout)

const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  paddingBottom: spacing(2),
  bottom: 0,
  width: '22rem'
}))

const StyledTypographyGrey = styled(Typography)(({ theme }) => ({
  color: theme?.palette.neutrals.stone150
}))
