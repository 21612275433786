import { useQuery, useMutation, type UseQueryResult, type UseMutationResult } from 'react-query'
import {
  signIn,
  getCurrentLoggedUser,
  signUp,
  type AuthUserResp,
  type AuthSignUpReq,
  type AuthSignInReq,
  type MeUserResp
} from 'shared/api/auth'
import { type fetch } from 'shared/api'

export const queryKeys = {
  loggedInUser: 'loggedInUser'
}

export const useSignIn = (): UseMutationResult<AuthUserResp, fetch.ClientError, AuthSignInReq> => {
  return useMutation(signIn)
}

export const useSignUp = (): UseMutationResult<AuthUserResp, fetch.ClientError, AuthSignUpReq> => {
  return useMutation(signUp)
}

const thirtyMinute = 30 * 60 * 1000

export const UseQueryLoggedInUser = (accessToken: string): UseQueryResult<MeUserResp, fetch.ClientError> => {
  // eslint-disable-next-line
  const enable = !!accessToken

  return useQuery([queryKeys.loggedInUser, accessToken], async () => await getCurrentLoggedUser(), {
    enabled: enable,
    retry: false,
    staleTime: thirtyMinute
  })
}
