import React, { type ReactNode, useRef, useState, useEffect, useCallback } from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Typography,
  styled,
  SortDescendingIcon,
  SortAscendingIcon,
  TablePagination,
  Skeleton,
  TableContainer
} from '@precis-digital/kurama'
import type {
  TablePropGetter,
  TableProps,
  TableBodyPropGetter,
  TableBodyProps,
  TableHeaderProps,
  Row,
  HeaderGroup,
  ColumnInstance
} from 'react-table'
import BlankIconSpacer from 'shared/components/BlankIconSpacer'

type StylesList = 'container' | 'table'
interface ReportTableProps<T extends object> {
  tableProps: {
    styles?: Partial<Record<StylesList, React.CSSProperties>>
    getTableProps: (propGetter?: TablePropGetter<T> | undefined) => TableProps
    getTableBodyProps: (propGetter?: TableBodyPropGetter<T> | undefined) => TableBodyProps
    headerGroups: Array<HeaderGroup<T>>
    rows: Array<Row<T>>
    prepareRow: (row: Row<T>) => void
    renderFilters?: () => React.ReactNode
    pagination?: {
      count: number
      page: number
      handleChangePage: (event: unknown, newPage: number) => void
      rowsPerPage: number
      rowsPerPageOptions: Array<
        | number
        | {
            value: number
            label: string
          }
      >
      handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
      shouldDisableNextButton?: boolean
    }
    footerGroups?: Array<HeaderGroup<T>>
  }
  t: (key: string, options?: Record<string, unknown> | undefined) => string
  basic?: boolean
  isLoading?: boolean
  basicColumnsIds: string[]
  stickyColumnsIds?: string[]
}

interface ExtendedCellStylesProps {
  alignRight: boolean
  shouldShowBorder: boolean
  columnLeftOffset: number
  isStickyColumn: boolean
}

interface SortableHeaderCellProps {
  column: ColumnInstance<any>
  alignRight: boolean
}

const SortableHeaderText: React.FC<SortableHeaderCellProps> = ({ column, alignRight }) => {
  const sortArrow: ReactNode = column.isSorted ? (
    column.isSortedDesc === true ? (
      <SortDescendingIcon />
    ) : (
      <SortAscendingIcon />
    )
  ) : (
    <BlankIconSpacer width={16} height={24} />
  )

  if (alignRight) {
    return (
      <Typography variant="h5">
        <StyledSortIcon isSorted={column.isSorted}>{sortArrow}</StyledSortIcon>
        {column.render('Header')}
      </Typography>
    )
  }
  return (
    <Typography variant="h5">
      {column.render('Header')}
      <StyledSortIcon isSorted={column.isSorted}>{sortArrow}</StyledSortIcon>
    </Typography>
  )
}

export const ReportTable = <T extends object>({
  tableProps: {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    renderFilters,
    footerGroups = [],
    styles,
    pagination
  },
  basic = true,
  isLoading = false,
  t,
  basicColumnsIds,
  stickyColumnsIds = []
}: ReportTableProps<T>): React.ReactElement => {
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({})
  const arrayOfRefs = useRef<Array<React.RefObject<HTMLTableCellElement>>>(
    stickyColumnsIds.map(() => React.createRef())
  )
  const parentRef = React.useRef(null)

  useEffect(() => {
    const observers: ResizeObserver[] = []

    arrayOfRefs.current.forEach((ref, index) => {
      if (ref?.current != null) {
        const resizeObserver = new ResizeObserver(() => {
          setColumnWidths((prevState) => ({ ...prevState, [index]: ref.current?.clientWidth }))
        })
        resizeObserver.observe(ref.current)
        observers.push(resizeObserver)
      }
    })

    return () => {
      observers.forEach((observer) => {
        observer.disconnect()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...arrayOfRefs.current.map((c) => c.current), headerGroups])

  const getCellStylesFromColumn = (
    basicColumnsIds: string[],
    column: ColumnInstance<any>,
    index: number
  ): ExtendedCellStylesProps => {
    const alignRight: boolean = !basicColumnsIds.includes(column.id)
    const isStickyColumn = stickyColumnsIds.includes(column.id)
    const shouldShowBorder =
      column.parent?.columns !== undefined && column.parent?.columns[column.parent?.columns.length - 1].id === column.id

    let columnLeftOffset = 0
    if (index === 1) {
      columnLeftOffset = columnWidths[0]
    }
    if (index === 2) {
      columnLeftOffset = columnWidths[0] + columnWidths[1]
    }
    return {
      columnLeftOffset,
      isStickyColumn,
      shouldShowBorder,
      alignRight
    }
  }

  const renderLoader = useCallback((): ReactNode => {
    if (pagination?.rowsPerPage != null && pagination?.count != null) {
      return Array.from({
        length: pagination.count === -1 ? pagination.rowsPerPage : Math.min(pagination.rowsPerPage, pagination.count)
      }).map((_, index) => (
        <TableRow key={index}>
          <TableBodyCell
            colSpan={headerGroups[0].headers.length + (headerGroups[1] != null ? headerGroups[1].headers.length : 0)}
            height={48}
          >
            <Skeleton height={40} />
          </TableBodyCell>
        </TableRow>
      ))
    }

    return (
      <TableRow>
        <TableBodyCell
          colSpan={headerGroups[0].headers.length + (headerGroups[1] != null ? headerGroups[1].headers.length : 0)}
        >
          <Skeleton height={273} />
        </TableBodyCell>
      </TableRow>
    )
  }, [pagination?.rowsPerPage, headerGroups, pagination?.count])

  if (rows.length === 0 && !isLoading) {
    return (
      <Grid container marginTop="24px">
        {renderFilters?.()}
        <Grid container margin="50px 0" justifyContent="center" alignItems="center" minHeight={150}>
          <Typography variant="h4">{t('noDetailedReport')}</Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container marginTop="24px">
      {renderFilters?.()}
      <StyledTableContainer ref={parentRef} style={styles?.container}>
        <StyledTable {...getTableProps()} style={styles?.table}>
          <StyledTableHead>
            {headerGroups.map((headerGroup, headerGroupIndex) => {
              const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
              return (
                <StyledTableRow {...restHeaderGroup} key={key}>
                  {headerGroup.headers.map((column, columnIndex) => {
                    const { key } = column.getHeaderProps()
                    const { shouldShowBorder, alignRight, isStickyColumn, columnLeftOffset } = getCellStylesFromColumn(
                      basicColumnsIds,
                      column,
                      columnIndex
                    )

                    if (column.parent !== undefined) {
                      return (
                        <StyledTableSubHeaderCell
                          {...{ basic, shouldShowBorder, alignRight, isStickyColumn, columnLeftOffset }}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={key}
                          ref={arrayOfRefs.current[columnIndex]}
                          width="100px"
                        >
                          <SortableHeaderText column={column} alignRight={alignRight} />
                        </StyledTableSubHeaderCell>
                      )
                    } else {
                      // This indicates if there are 2 headers and this one is the first(top) one, In that case
                      // it behaves more like a column group label...
                      const isParentHeader = headerGroups.length > 1 && headerGroupIndex === 0
                      const alignRightParentHeader = alignRight && !isParentHeader
                      const sortByToggleProps = isParentHeader ? {} : column.getSortByToggleProps()
                      const isFirstColumnSticky = stickyColumnsIds.length > 0
                      const width = column.width
                      return (
                        <StyledTableHeaderCell
                          {...{
                            basic,
                            columnLeftOffset,
                            isStickyColumn: isFirstColumnSticky,
                            shouldShowBorder,
                            alignRight: alignRightParentHeader,
                            isParentHeader
                          }}
                          {...column.getHeaderProps(sortByToggleProps)}
                          key={key}
                          ref={arrayOfRefs.current[columnIndex]}
                          width={width}
                        >
                          <SortableHeaderText column={column} alignRight={alignRightParentHeader} />
                        </StyledTableHeaderCell>
                      )
                    }
                  })}
                </StyledTableRow>
              )
            })}
          </StyledTableHead>

          {isLoading
            ? renderLoader()
            : rows.length > 0 && (
                <TableBody {...getTableBodyProps()}>
                  {rows.map((row, rowIndex) => {
                    prepareRow(row)
                    return (
                      <StyledTableBodyRow {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell, columnIndex) => {
                          const { shouldShowBorder, alignRight, isStickyColumn, columnLeftOffset } =
                            getCellStylesFromColumn(basicColumnsIds, cell.column, columnIndex)
                          return (
                            <StyledTableBodyCell
                              {...{ basic, isStickyColumn, shouldShowBorder, columnLeftOffset, alignRight }}
                              {...cell.getCellProps()}
                              key={columnIndex}
                              width={cell.column.width}
                            >
                              {cell.isAggregated ? cell.render('Aggregated') : cell.render('Cell')}
                            </StyledTableBodyCell>
                          )
                        })}
                      </StyledTableBodyRow>
                    )
                  })}
                  <>
                    {footerGroups.map((group, footerGroupIndex) => {
                      const { key } = group.getFooterGroupProps()
                      return (
                        <StyledFooterRow {...group.getFooterGroupProps()} key={key}>
                          {group.headers.map((column, columnIdx) => {
                            if (footerGroupIndex !== 0) return null
                            const { alignRight, isStickyColumn, columnLeftOffset } = getCellStylesFromColumn(
                              basicColumnsIds,
                              column,
                              columnIdx
                            )
                            const { key } = column.getFooterProps()
                            if (columnIdx === 0) {
                              return (
                                <StyledTableBodyCell
                                  {...{ basic, isStickyColumn, shouldShowBorder: false, alignRight, columnLeftOffset }}
                                  {...column.getFooterProps()}
                                  key={key}
                                >
                                  <Typography variant="h4">{t('total')}</Typography>
                                </StyledTableBodyCell>
                              )
                            }
                            return (
                              <StyledTableBodyCell
                                {...{ basic, isStickyColumn, shouldShowBorder: false, alignRight, columnLeftOffset }}
                                {...column.getFooterProps()}
                                key={key}
                              >
                                <Typography variant="h4">{column.render('Footer')}</Typography>
                              </StyledTableBodyCell>
                            )
                          })}
                        </StyledFooterRow>
                      )
                    })}
                  </>
                </TableBody>
              )}
        </StyledTable>
      </StyledTableContainer>
      {pagination != null && (
        <TablePagination
          count={pagination.count}
          page={pagination.page}
          onPageChange={pagination.handleChangePage}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to }) => {
            const isServerSide = pagination.count === -1
            return `${from}–${to} of ${isServerSide ? 'many' : pagination.count}`
          }}
          shouldDisableNextButton={pagination.shouldDisableNextButton}
        />
      )}
    </Grid>
  )
}

interface ConditionalStylesProps {
  basic: boolean
  isStickyColumn: boolean
  isParentHeader?: boolean
}

type StyledTableBodyCellProps = TableBodyProps & ConditionalStylesProps & ExtendedCellStylesProps

type StyledTableHeaderCellProps = TableHeaderProps & ConditionalStylesProps & ExtendedCellStylesProps
type StyledTableSubHeaderCellProps = StyledTableHeaderCellProps & ExtendedCellStylesProps
const StyledTable = styled(Table)(() => ({
  margin: '24px 0',
  borderCollapse: 'collapse'
}))

const StyledTableSubHeaderCell = styled(TableHeaderCell, {
  shouldForwardProp: (prop) => prop !== 'isStickyColumn' && prop !== 'columnLeftOffset'
})<StyledTableSubHeaderCellProps>(({ theme, shouldShowBorder, alignRight, isStickyColumn, columnLeftOffset }) => ({
  textAlign: alignRight ? 'right' : 'start',
  textWrap: 'nowrap',
  padding: '12px 0px 12px 16px',
  backgroundColor: theme.palette.neutrals.white0,

  ...(shouldShowBorder && {
    borderRight: `1px solid ${theme.palette.neutrals.stone100 as string}`
  }),
  ...(isStickyColumn && {
    position: 'sticky',
    left: columnLeftOffset,
    zIndex: 3
  })
}))
const StyledTableHeaderCell = styled(TableHeaderCell, {
  shouldForwardProp: (prop) =>
    prop !== 'basic' && prop !== 'isStickyColumn' && prop !== 'columnLeftOffset' && prop !== 'isParentHeader'
})<StyledTableHeaderCellProps>(({ theme, alignRight, isStickyColumn }) => ({
  textWrap: 'nowrap',
  paddingLeft: '16px',
  textAlign: alignRight ? 'right' : 'start',

  ...(isStickyColumn && {
    '&:first-of-type': {
      position: 'sticky',
      left: 0,
      zIndex: 3,
      backgroundColor: theme.palette.neutrals.white0
    }
  }),

  '&:first-of-type': {
    paddingLeft: '0px'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: '32px',
  height: '32px'
}))

const StyledFooterRow = styled(TableRow)(() => ({
  marginTop: '16px',
  height: '52px',
  position: 'sticky',
  bottom: 0,
  zIndex: 2
}))

const StyledTableBodyCell = styled(TableBodyCell, {
  shouldForwardProp: (prop) => prop !== 'columnLeftOffset' && prop !== 'basic' && prop !== 'isStickyColumn'
})<StyledTableBodyCellProps>(({ theme, isStickyColumn, columnLeftOffset, shouldShowBorder, alignRight }) => ({
  textWrap: 'nowrap',
  paddingLeft: '16px',
  textAlign: alignRight ? 'right' : 'start',
  backgroundColor: theme.palette.neutrals.white0,
  ...(isStickyColumn && {
    position: 'sticky',
    left: columnLeftOffset,
    zIndex: 1
  }),

  ...(shouldShowBorder && {
    borderRight: `1px solid ${theme.palette.neutrals.stone100 as string}`
  }),

  '&:first-of-type': {
    paddingLeft: '0px'
  }
}))

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
  height: '48px',
  borderBottom: `1px solid ${theme.palette.neutrals.stone100 as string}`,
  ':not(:nth-last-of-type(2))': {
    borderBottom: `1px solid ${theme.palette.neutrals.stone100 as string}`
  }
}))

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  backgroundColor: theme.palette.neutrals.white0
}))

const StyledTableContainer = styled(TableContainer)(() => ({
  marginTop: 20,
  overflow: 'auto',
  maxHeight: 800,
  minHeight: 400,
  padding: '0 15px 0 0'
}))

const StyledSortIcon = styled('span')<{ isSorted: boolean }>(({ theme, isSorted }) => ({
  color: isSorted ? theme.palette.primary.main : 'initial',
  verticalAlign: 'top'
}))

export default ReportTable
