import React from 'react'
import { GridProps } from './types'
import { Grid as MUIGrid } from '@mui/material'

const Grid = React.forwardRef<HTMLDivElement, GridProps>((props, ref): React.ReactElement => {
  return (
    <MUIGrid {...props} ref={ref}>
      {props.children}
    </MUIGrid>
  )
})
export default Grid
