import Card from '../Card'
import { styled } from '../../utils'
import { Theme } from '../theme'
import Grid from '../Grid'
import { ToasterVariant } from './types'
import { LinearProgress } from '@mui/material'
import Typography from '../Typography'

export const StyledCardToaster = styled(Card)(({ theme }: { theme?: Theme }) => ({
  boxShadow: theme?.elevation.mediumDepth,
  position: 'fixed',
  bottom: '32px',
  right: '32px',
  zIndex: 1500,
  padding: '19px 20px',
  width: '352px',
  margin: 0,
  backgroundColor: theme?.palette.neutrals.white0
}))

export const StyledGridCheckContainer = styled(Grid)(
  ({ variant, theme }: { variant: ToasterVariant; theme?: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    borderRadius: theme?.borderRadius.small,
    backgroundColor: variant === 'success' ? theme?.palette.semantic.success90 : theme?.palette.semantic.error100,
    color: theme?.palette.neutrals.white0
  })
)

export const StyledProgressLoader = styled(LinearProgress)(
  ({ isPaused, theme }: { isPaused: boolean; theme?: Theme }) => ({
    height: '16px',
    borderRadius: '8px',
    backgroundColor: theme?.palette.neutrals.stone100,
    '.MuiLinearProgress-bar1Determinate': {
      borderRadius: '8px',
      backgroundColor: isPaused ? theme?.palette.neutrals.stone120 : theme?.palette.semantic.success90,
      transition: 'backgroundColor 0.1s linear, width 0.1s linear'
    }
  })
)

export const StyledTypographyToasterDescription = styled(Typography)(() => ({
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden'
}))
