import React, { forwardRef } from 'react'
import MUITableBody from '@mui/material/TableBody'
import { TableBodyProps } from './types'

export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ children, ...props }, ref): React.ReactElement => {
    return (
      <MUITableBody ref={ref} {...props}>
        {children}
      </MUITableBody>
    )
  }
)
export default TableBody
