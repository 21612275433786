import { styled } from '../../../utils'
import { TablePagination } from '@mui/material'
import Grid from '../../Grid'

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  width: '100%',
  borderBottom: 'none',
  '.MuiTablePagination-toolbar': {
    borderTop: `1px solid ${theme.palette.neutrals.stone100 as string}`,
    justifyContent: 'space-between',
    minHeight: '88px'
  },
  '.MuiTablePagination-spacer': {
    display: 'none'
  },
  '.MuiInputBase-root': {
    position: 'absolute',
    left: '0',
    border: `1px solid ${theme.palette.neutrals.stone100 as string}`,
    borderRadius: '16px',
    lineHeight: '29px',
    margin: 0,
    svg: {
      display: 'none'
    },
    input: {
      height: '100%'
    },
    '.MuiSelect-select': {
      paddingRight: '36px',
      paddingLeft: '16px',
      width: '16px',
      display: 'flex',
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  },
  '.MuiTablePagination-selectLabel': {
    color: theme.palette.neutrals.stone150,
    position: 'absolute',
    left: '78px'
  },
  '.MuiTablePagination-displayedRows': {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))
export const StyledPaginationActions = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  border: `1px solid ${theme.palette.neutrals.stone100 as string}`,
  borderRadius: '16px',
  display: 'flex',
  gap: '6px'
}))
