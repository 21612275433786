import { Typography, Grid, styled, Avatar } from '@precis-digital/kurama'

interface WorkspaceProps {
  name: string
}

export const Workspace = ({ name }: WorkspaceProps): React.ReactElement => {
  return (
    <StyledtAccountSection item xs={12}>
      <Avatar size="medium" kind="label" label={name} />
      <StyledtAccountHeader variant="h5">{name}</StyledtAccountHeader>
    </StyledtAccountSection>
  )
}

const StyledtAccountSection = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '25px'
}))
const StyledtAccountHeader = styled(Typography)(() => ({
  marginTop: '8px',
  textTransform: 'capitalize'
}))

export default Workspace
