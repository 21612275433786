import { Autocomplete, Grid, Link, SelectInput, SelectItem } from '@precis-digital/kurama'
import { PLATFORM } from 'dataSource'
import { useQueryClientAccountPlatform } from 'dataSource/api'
import { isEmpty } from 'ramda'
import { useFormContext } from 'react-hook-form'
import { type Platforms } from 'shared/api/accounts'
import { AccordionFormStep, AccordionFormStepItem } from 'shared/components/AccordionFormStep'
import FormItem from 'shared/components/FormItem'
import { ASSETS_BASE_URL } from 'shared/constants'
import { useCurrentClient } from 'shared/context/ClientContext'
import { getNumberOfRequiredInputsPerSection } from 'shared/reactHookForm'
import { useTranslation } from 'shared/translations'
import type { AttributionFormData, StepProps } from '..'
import { PlatformAvatar } from '../AccountStep'
import { allowToEdit, getInputRules } from '../utils'
import { APP_ROUTES } from 'shared/routes'
import { useMemo } from 'react'
import { useQueryGcpMetadata } from 'connectors/api'

const DatasetStep: React.FC<StepProps> = ({
  stepNumber,
  title,
  isPreviousOrCurrentStep,
  handleUpdate,
  isNewConfig,
  toggleEdit,
  editModes,
  optimizeBudget
}): React.ReactElement => {
  const { t } = useTranslation('attributionModel')
  const {
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext<AttributionFormData>()

  const { currentClient } = useCurrentClient()

  const platformGcp: Platforms = PLATFORM.GCP

  const { data: clientAccountGCP } = useQueryClientAccountPlatform(currentClient.id, platformGcp)
  const hasBasicSettingDatasetValidationError = errors?.datasets?.target?.type === 'validate'
  const hasAdvancedSettingDatasetValidationError = errors?.datasets?.source?.type === 'validate'

  const formData = getValues()

  const chosenGcpTargetAccount = clientAccountGCP?.find((account) => account.id === Number(formData.datasets?.gcp))
  const { data: gcpMetadata, isLoading: isLoadingBigqueryDatasets } = useQueryGcpMetadata(
    currentClient.id,
    chosenGcpTargetAccount != null ? Number(chosenGcpTargetAccount.id) : undefined
  )
  const datasetOptions = useMemo(() => {
    if (gcpMetadata == null) return []
    return gcpMetadata.bigqueryDatasets.map((dataset) => ({
      value: dataset.datasetId,
      label: dataset.datasetId
    }))
  }, [gcpMetadata])

  return (
    <AccordionFormStep stepName={t('form.step', { stepNumber })} title={title} expanded={isPreviousOrCurrentStep}>
      <AccordionFormStepItem
        title={t('createModel.basic')}
        requiredFieldsTally={getNumberOfRequiredInputsPerSection({
          data: formData,
          sectionName: `datasets`,
          errors,
          getInputRules
        })}
        t={t}
        isCreateEvent={isNewConfig}
        expanded={!isNewConfig}
      >
        <>
          <FormItem
            name="datasets.gcp"
            label={t('createModel.dataSetsBasic')}
            labelIcon={`${ASSETS_BASE_URL}/gcp.svg`}
            {...{
              helperText: isEmpty(clientAccountGCP) ? (
                <>
                  {t('form.noGCPAccount')}{' '}
                  <Link href={APP_ROUTES.dataSources.basePage} style={{ fontSize: '0.95em' }}>
                    {t('form.addGCPAccount')}
                  </Link>
                </>
              ) : optimizeBudget ? (
                t('createModel.boHelperText.dataSetsBasic')
              ) : (
                t('createModel.amHelperText.dataSetsBasic')
              )
            }}
            rules={{ required: true }}
            currentValue={
              clientAccountGCP?.find((account) => Number(account.id) === Number(formData.datasets?.gcp))?.name
            }
            render={({ field }) => {
              return (
                <SelectInput fullWidth {...field} disabled={isEmpty(clientAccountGCP)}>
                  {clientAccountGCP?.map((account, index) => {
                    return (
                      <SelectItem key={account.id} value={account.id}>
                        <Grid display="flex" gap="6px">
                          <PlatformAvatar account={account} />
                          {account.name}
                        </Grid>
                      </SelectItem>
                    )
                  })}
                </SelectInput>
              )
            }}
            editModes={editModes}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            control={control}
            isCreateEvent={isNewConfig}
            canEdit={allowToEdit(optimizeBudget, formData)}
          />
          <FormItem
            name="datasets.target"
            currentValue={formData.datasets?.target}
            label={t('createModel.dataSetsBasic2')}
            rules={getInputRules({ fieldName: 'datasets.target', data: formData })}
            labelIcon={`${ASSETS_BASE_URL}/google_bigquery.svg`}
            helperText={t('createModel.sharedHelperText.dataSetsBasic2')}
            defaultValue="precis_io"
            render={({ field }) => {
              return (
                <Autocomplete
                  options={datasetOptions}
                  value={{
                    value: field.value ?? '',
                    label: field.value ?? ''
                  }}
                  placeholder={t('form.modelBigqueryDataset')}
                  multiple={false}
                  fullWidth
                  allowCustomInput
                  onChange={(event, newValue) => {
                    field.onChange(newValue?.value ?? '')
                    setValue('datasets.target', newValue?.value ?? '')
                  }}
                  color={hasBasicSettingDatasetValidationError ? 'error' : undefined}
                  helperText={
                    hasBasicSettingDatasetValidationError ? t('form.targetDataSetValidationErrorText') : undefined
                  }
                  isLoadingOptions={isLoadingBigqueryDatasets}
                  loadingText={t('form.targetDatasetLoadingText')}
                  acceptCustomInputTemplate={t('form.datasetCustomInputTemplate')}
                  noOptionsTextTemplate={t('form.datasetNoOptionsTextTemplate')}
                />
              )
            }}
            editModes={editModes}
            toggleEdit={toggleEdit}
            handleUpdate={handleUpdate}
            control={control}
            isCreateEvent={isNewConfig}
            canEdit={allowToEdit(optimizeBudget, formData)}
          />
        </>
      </AccordionFormStepItem>
      <AccordionFormStepItem title={t('createModel.advanced')}>
        <FormItem
          name="datasets.source"
          label={t('createModel.dataSetsAdvanced')}
          currentValue={formData.datasets?.source}
          labelIcon={`${ASSETS_BASE_URL}/google_bigquery.svg`}
          helperText={t('createModel.sharedHelperText.dataSetsAdvanced')}
          rules={getInputRules({ fieldName: 'datasets.source', data: formData })}
          render={({ field }) => {
            return (
              <Autocomplete
                options={datasetOptions}
                value={{
                  value: field.value ?? '',
                  label: field.value ?? ''
                }}
                placeholder={t('form.connectorsBigqueryDataset')}
                multiple={false}
                fullWidth
                allowCustomInput
                onChange={(event, newValue) => {
                  field.onChange(newValue?.value ?? '')
                }}
                color={hasAdvancedSettingDatasetValidationError ? 'error' : undefined}
                helperText={
                  hasAdvancedSettingDatasetValidationError ? t('form.targetDataSetValidationErrorText') : undefined
                }
                isLoadingOptions={isLoadingBigqueryDatasets}
                loadingText={t('form.targetDatasetLoadingText')}
                acceptCustomInputTemplate={t('form.datasetCustomInputTemplate')}
                noOptionsTextTemplate={t('form.datasetNoOptionsTextTemplate')}
              />
            )
          }}
          editModes={editModes}
          toggleEdit={toggleEdit}
          handleUpdate={handleUpdate}
          control={control}
          isCreateEvent={isNewConfig}
          canEdit={allowToEdit(optimizeBudget, formData)}
        />
      </AccordionFormStepItem>
    </AccordionFormStep>
  )
}

export default DatasetStep
