import { styled } from '../../utils'
import Grid from '../Grid'
import { Theme } from '../theme'
import { CSSObject } from '@mui/material/styles'

export const StyledCardContainer = styled(Grid)(() => ({
  width: '352px'
}))

export const getClickableStyles = (theme?: Theme): CSSObject => {
  return {
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme?.elevation.largeDepth
    }
  }
}

export const StyledCard = styled('div')(
  ({
    elevation,
    isClickable,
    theme
  }: {
    elevation: 'flat' | 'mediumDepth' | 'largeDepth'
    isClickable: boolean
    theme?: Theme
  }): CSSObject => ({
    border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
    borderRadius: `${theme?.borderRadius.xxLarge ?? '32px'}`,
    transition: 'box-shadow 100ms ease-in-out',
    boxShadow: theme?.elevation[elevation],
    color: theme?.palette.secondary.main,
    padding: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 0',
    ...(isClickable && getClickableStyles(theme))
  })
)

export const StyledAvatarContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    div: {
      ':first-of-type': {
        marginRight: '16px'
      }
    }
  })
)
