import Grid from '../../Grid'
import { styled } from '../../../utils'
import { Theme } from '../../theme'
import { Divider } from '@mui/material'
import Button from '../../Button'

export const StyledGridBuilderRow = styled(Grid)(({ theme, isSaved }: { theme?: Theme; isSaved: boolean }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  backgroundColor: isSaved ? undefined : theme?.palette.neutrals.stone90,
  borderRadius: theme?.borderRadius.xLarge,
  minHeight: '56px'
}))

export const StyledDivider = styled(Divider)(({ theme }: { theme?: Theme }) => ({
  color: theme?.palette.neutrals.stone100,
  margin: '12px 4px'
}))

export const StyledButtonDelete = styled(Button)(({ theme }: { theme?: Theme }) => ({
  padding: '8px',
  borderLeft: `1px solid ${theme?.palette.neutrals.stone100 as string}`,
  borderRadius: theme?.borderRadius.xLarge,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  height: '80px'
}))
