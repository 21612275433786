import React from 'react'

export const Pin = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M16.4773 12.0524L20.1484 8.38128C20.5998 7.92981 20.5998 7.19783 20.1484 6.74636L17.7079 4.30589C17.2564 3.85442 16.5244 3.85442 16.073 4.30589L12.4019 7.97699M8.83079 15.6336L4.63208 19.8323M12.9966 19.8029L14.873 17.9265C16.9046 15.8949 16.9046 12.601 14.873 10.5694L13.8847 9.58104C11.853 7.54942 8.55911 7.54942 6.52748 9.58104L4.65111 11.4574L12.9966 19.8029Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
