import { useQueryAttributionConfigStatuses, useQueryIntegratedAttributions } from 'attributionModel/api'
import { useQueryBudgetOptimiserConfigStatuses, useQueryBudgetOptimisers } from 'budgetOptimiser/api'
import { USED_IN_CONFIG_TYPES } from 'channelGrouping/constants'
import { type UsedInConfigWithStatus } from 'channelGrouping/types'
import { getMatchingUsedInConfigs } from 'channelGrouping/utils'
import { useMemo } from 'react'
import { type ConfigStatusResp } from 'shared/api/analytics'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'
import { getStatusMarketingEvaluation } from 'shared/configStatuses'
import { useCurrentClient } from 'shared/context/ClientContext'

export const useGetUsedInConfigs = (
  channelGroupingResp?: ChannelGroupingResp
): {
  usedInConfigs: UsedInConfigWithStatus[] | null
  isLoadingUsedInConfigsStatuses: boolean
} => {
  const { currentClient } = useCurrentClient()
  const { data: attributionConfigs } = useQueryIntegratedAttributions(currentClient.id)
  const { data: attributionConfigsStatuses, isLoading: isLoadingAttributionConfigsStatuses } =
    useQueryAttributionConfigStatuses(currentClient.id)

  const { data: budgetOptimisers } = useQueryBudgetOptimisers(currentClient.id)
  const { data: budgetOptimisersStatuses, isLoading: isLoadingBudgetOptimisersStatuses } =
    useQueryBudgetOptimiserConfigStatuses(currentClient.id)

  const usedInConfigs = useMemo(() => {
    if (attributionConfigs == null || budgetOptimisers == null || channelGroupingResp == null) return null
    const matchingUsedInConfigs = getMatchingUsedInConfigs({
      channelGrouping: channelGroupingResp,
      attributionConfigs,
      budgetOptimisers
    })

    const attributionConfigsStatusesMap: Record<number, ConfigStatusResp> = {}
    attributionConfigsStatuses?.forEach((statusResp) => {
      attributionConfigsStatusesMap[Number(statusResp.configId)] = statusResp
    })

    const budgetOptimisersStatusesMap: Record<number, ConfigStatusResp> = {}
    budgetOptimisersStatuses?.forEach((statusResp) => {
      budgetOptimisersStatusesMap[Number(statusResp.configId)] = statusResp
    })

    const matchingUsedInConfigsWithStatus = matchingUsedInConfigs.map((config) => {
      const statusMap =
        config.type === USED_IN_CONFIG_TYPES.ATTRIBUTION ? attributionConfigsStatusesMap : budgetOptimisersStatusesMap
      const statusInfo = statusMap[config.id]

      return {
        ...config,
        statusEnum: getStatusMarketingEvaluation(config.runSchedule, statusInfo?.status),
        status: statusInfo
      }
    })

    return matchingUsedInConfigsWithStatus
  }, [channelGroupingResp, attributionConfigs, budgetOptimisers, attributionConfigsStatuses, budgetOptimisersStatuses])

  return {
    usedInConfigs,
    isLoadingUsedInConfigsStatuses: isLoadingAttributionConfigsStatuses || isLoadingBudgetOptimisersStatuses
  }
}
