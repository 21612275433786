import { type ClientFeatureType } from 'shared/api/clients'
import { type InternalFeatureType } from 'shared/hooks/useHasFeatureAccess'

export const CLIENT_ID_QUERY_PARAM = 'clientId'

const HOME_BASE_ROUTE = '/'
const AUTH_BASE_ROUTE = '/auth'
const REPORTING_SOLUTIONS_BASE_ROUTE = '/reporting-solutions'
const CONNECTORS_BASE_ROUTE = '/connectors'
const ATTRIBUTION_MODELS_BASE_ROUTE = '/attribution-models'
const DATA_SOURCES_BASE_ROUTE = '/data-sources'
const BUDGET_OPTIMISER_BASE_ROUTE = '/budget-optimiser'
const MIMIR_BASE_ROUTE = '/mimir'
const PRECIS_APPS_BASE_ROUTE = '/precis-apps'
const WORKSPACE_BASE_ROUTE = '/workspace'
const ENHANCED_SIGNALS_BASE_ROUTE = '/enhanced-signals'
const CUSTOM_GROUPINGS_BASE_ROUTE = '/custom-groupings'
const CENTRAL_APP_BASE_ROUTE = 'https://precis-central.appspot.com'
const ACCOUNT_BASE_ROUTE = '/account'
const ERROR500_BASE_ROUTE = '/500'
/**
 * A registry for all app routes
 */
export const APP_ROUTES = {
  home: {
    basePage: HOME_BASE_ROUTE
  },
  auth: {
    signUpPage: `${AUTH_BASE_ROUTE}/signup`,
    signInPage: `${AUTH_BASE_ROUTE}/signin`
  },
  account: {
    basePage: ACCOUNT_BASE_ROUTE,
    deleteAccountPage: `${ACCOUNT_BASE_ROUTE}/delete-account`
  },
  reportingSolutions: {
    basePage: REPORTING_SOLUTIONS_BASE_ROUTE,
    createConfigPage: `${REPORTING_SOLUTIONS_BASE_ROUTE}/new`,
    editConfigPage: function ({ configId }: { configId: string }): string {
      return `${REPORTING_SOLUTIONS_BASE_ROUTE}/${configId}`
    }
  },
  connectors: {
    basePage: CONNECTORS_BASE_ROUTE,
    createConfigPage: `${CONNECTORS_BASE_ROUTE}/new`,
    editConfigPage: function ({ configId }: { configId: string }): string {
      return `${CONNECTORS_BASE_ROUTE}/${configId}`
    }
  },
  attributionModels: {
    basePage: ATTRIBUTION_MODELS_BASE_ROUTE,
    createConfigPage: `${ATTRIBUTION_MODELS_BASE_ROUTE}/new`,
    editConfigPage: function ({ configId }: { configId: string }): string {
      return `${ATTRIBUTION_MODELS_BASE_ROUTE}/${configId}`
    },
    dashboardPage: function ({ configId }: { configId: string }): string {
      return `${ATTRIBUTION_MODELS_BASE_ROUTE}/${configId}/dashboard`
    }
  },
  dataSources: {
    basePage: DATA_SOURCES_BASE_ROUTE
  },
  budgetOptimiser: {
    basePage: BUDGET_OPTIMISER_BASE_ROUTE,
    createConfigPage: `${BUDGET_OPTIMISER_BASE_ROUTE}/new`,
    editConfigPage: function ({ configId }: { configId: string }): string {
      return `${BUDGET_OPTIMISER_BASE_ROUTE}/${configId}`
    },
    dashboardPage: function ({ configId }: { configId: string }): string {
      return `${BUDGET_OPTIMISER_BASE_ROUTE}/${configId}/dashboard`
    }
  },
  mimir: {
    basePage: MIMIR_BASE_ROUTE,
    chatPage: `${MIMIR_BASE_ROUTE}/chat`,
    searchPage: `${MIMIR_BASE_ROUTE}/search`
  },
  precisApps: {
    basePage: PRECIS_APPS_BASE_ROUTE,
    appPage: function ({ appId }: { appId: string }): string {
      return `${PRECIS_APPS_BASE_ROUTE}/${appId}`
    },
    appTabPage: function ({ appId, tab }: { appId: string; tab: string }): string {
      return `${PRECIS_APPS_BASE_ROUTE}/${appId}/${tab}`
    }
  },
  workspace: {
    basePage: WORKSPACE_BASE_ROUTE,
    setupPage: `${WORKSPACE_BASE_ROUTE}/setup`,
    listPage: `${WORKSPACE_BASE_ROUTE}/list`,
    noWorkspacePage: `${WORKSPACE_BASE_ROUTE}/noworkspace`,
    joinWorkspacePage: `${WORKSPACE_BASE_ROUTE}/join-workspace`
  },
  enhancedSignals: {
    basePage: ENHANCED_SIGNALS_BASE_ROUTE,
    createConfigPage: `${ENHANCED_SIGNALS_BASE_ROUTE}/new`,
    editConfigPage: function ({ configId }: { configId: string }): string {
      return `${ENHANCED_SIGNALS_BASE_ROUTE}/${configId}/edit`
    },
    dashboardPage: function ({ configId }: { configId: string }): string {
      return `${ENHANCED_SIGNALS_BASE_ROUTE}/${configId}/dashboard`
    }
  },
  customGroupings: {
    basePage: CUSTOM_GROUPINGS_BASE_ROUTE,
    editConfigPage: function ({ channelGroupingId }: { channelGroupingId: string }): string {
      return `${CUSTOM_GROUPINGS_BASE_ROUTE}/${channelGroupingId}`
    },
    usedInTabPage: function ({ channelGroupingId }: { channelGroupingId: string }): string {
      return `${CUSTOM_GROUPINGS_BASE_ROUTE}/${channelGroupingId}/used-in`
    },
    dataSourcesPage: function ({ channelGroupingId }: { channelGroupingId: string }): string {
      return `${CUSTOM_GROUPINGS_BASE_ROUTE}/${channelGroupingId}/data-sources`
    },
    inspectResultsPage: function ({
      channelGroupingId,
      dataSource
    }: {
      channelGroupingId: string
      dataSource: string
    }): string {
      return `${CUSTOM_GROUPINGS_BASE_ROUTE}/${channelGroupingId}/data-sources/${dataSource}/inspect-results`
    },
    channelsPage: function ({
      channelGroupingId,
      dataSource
    }: {
      channelGroupingId: string
      dataSource: string
    }): string {
      return `${CUSTOM_GROUPINGS_BASE_ROUTE}/${channelGroupingId}/data-sources/${dataSource}/channels`
    }
  },
  centralApp: {
    basePage: CENTRAL_APP_BASE_ROUTE,
    budgetOptimiser: {
      createConfigPage: `${CENTRAL_APP_BASE_ROUTE}/budget-optimiser/new`,
      editConfigPage: function ({ configId }: { configId: string }): string {
        return `${CENTRAL_APP_BASE_ROUTE}/budget-optimiser/${configId}`
      }
    },
    integratedAttribution: {
      createConfigPage: `${CENTRAL_APP_BASE_ROUTE}/integrated-attribution/new`
    },
    attributionModels: {
      createConfigPage: `${CENTRAL_APP_BASE_ROUTE}/attribution-models/new`,
      editConfigPage: function ({ configId }: { configId: string }): string {
        return `${CENTRAL_APP_BASE_ROUTE}/attribution-models/${configId}`
      }
    },
    credentialsPage: `${CENTRAL_APP_BASE_ROUTE}/credentials`
  },
  error500: {
    basePage: ERROR500_BASE_ROUTE
  }
} as const

export const CLIENT_FEATURE_TO_ROUTE_MAP: Record<ClientFeatureType, string> = {
  reportingSolutions: APP_ROUTES.reportingSolutions.basePage,
  connectors: APP_ROUTES.connectors.basePage,
  budgetOptimiser: APP_ROUTES.budgetOptimiser.basePage,
  attributionModels: APP_ROUTES.attributionModels.basePage,
  enhancedSignals: APP_ROUTES.enhancedSignals.basePage
}

export const INTERNAL_FEATURE_TO_ROUTE_MAP: Record<InternalFeatureType, string> = {
  precis_apps: APP_ROUTES.precisApps.basePage,
  workspace_setup: APP_ROUTES.workspace.setupPage,
  mimir: APP_ROUTES.mimir.basePage
}

export const getFeatureIdFromRoute = <T extends string | number | symbol>(
  currentRoute: string | undefined,
  featureRouteMap: Record<T, string>
): T | undefined => {
  if (currentRoute == null) return

  for (const feature in featureRouteMap) {
    if (currentRoute.includes(featureRouteMap[feature])) {
      return feature
    }
  }
}
