import { Grid, Typography, defaultTheme } from '@precis-digital/kurama'
import Image from 'next/image'
import rubikIcon from 'public/assets/images/rubik.svg'
import { useTranslation } from 'shared/translations'

const ReportDataLoading = (): React.ReactElement => {
  const { t } = useTranslation('home')
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      paddingTop={defaultTheme.spacing(3)}
      gap={defaultTheme.spacing(5)}
    >
      <Image src={rubikIcon.src} alt="rubik" width={120} height={120} sizes="20vw" />
      <Grid container direction="column" alignItems="center">
        <Typography variant="h5">{t('dataLoadingTitle')}</Typography>
        <Typography variant="body3">{t('dataLoadingDescription')}</Typography>
      </Grid>
    </Grid>
  )
}

export default ReportDataLoading
