import React, { type ReactElement } from 'react'
import BaseCell from './BaseCell'
import { type ListViewCellProps } from '../../types'
import { formatDateToString } from 'shared/dateFns'
import { Grid, Typography, styled } from '@precis-digital/kurama'
import { useQueryUserProfile } from 'profile/api'
import OverScreen from 'shared/overScreens'
import { textAlignToFlexJustify } from '../../utils'

interface MemberTimestampCellProps<T extends object> extends ListViewCellProps<T> {
  timestamp: string | number | Date
  memberId?: string
}

const MemberTimestampCell = <T extends object>({
  timestamp,
  memberId,
  ...props
}: MemberTimestampCellProps<T>): ReactElement => {
  const { data: userDetails, isLoading: isUserDetailsLoading } = useQueryUserProfile(memberId)
  const handleLinkClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.stopPropagation()
    e.preventDefault()
    void OverScreen.show('profileFlyout', { id: memberId })
  }
  return (
    <BaseCell width={props.column.parsedWidth} align={props.column.align}>
      <Grid container justifyContent={textAlignToFlexJustify(props.column.align)} gap="4px">
        <Typography variant="body2">{formatDateToString({ date: timestamp })}</Typography>
        {memberId != null && (
          <Typography variant="body2">
            by{' '}
            <StyledSpan variant="body3" onClick={handleLinkClick}>
              {isUserDetailsLoading ? '...' : userDetails?.name}
            </StyledSpan>
          </Typography>
        )}
      </Grid>
    </BaseCell>
  )
}

const StyledSpan = styled(Typography)(({ theme }) => ({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  letterSpacing: 'inherit',
  lineHeight: 'inherit',
  color: theme?.palette.main.blue100,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
}))

export default MemberTimestampCell
