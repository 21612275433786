import { PLATFORM } from 'dataSource'
import { type Platforms } from 'shared/api/accounts'
import { type ReportTemplateId } from 'shared/api/standardReports'
import { type ConfigStatus } from 'shared/configStatuses'
import { DIMENSION_OPERATORS_WITH_TYPES } from 'shared/constants/filterOperators'

export const CHANNEL = 'channel'
export const MARKET = 'market'
export const SPEND = 'spend'
export const CONVERSION_VALUE = 'conversionValue'
export const CONVERSIONS = 'conversions'
export const ROAS = 'roas'
export const COST_PER_ACTION = 'costPerAction'

export const ALLCHANNELS = 'allChannels'
export const METRIC = 'metric'
export const ALLMETRICS = 'allMetrics'

export const DIMENSIONS = [CHANNEL, MARKET] as const

export const STATUSES_TO_SHOW_HOMEPAGE_DATA_FOR: ConfigStatus[] = ['active', 'running', 'unknown', 'paused', 'error']

export const STATUSES_TO_SHOW_BANNER_FOR: ConfigStatus[] = ['paused', 'error']

export const REPORT_TEMPLATE_WITH_CONVERSION_EVENTS: ReportTemplateId[] = ['reporting_base']

export const CONVERSION_EVENT_DEFAULT = {
  source: 'ga',
  name: 'purchase'
}

export const CONVERSION_SOURCE_TO_DATASOURCE_MAP: Record<string, Platforms> = {
  msads: PLATFORM.MICROSOFT_ADVERTISING,
  gads: PLATFORM.GOOGLE,
  facebook: PLATFORM.FACEBOOK,
  snapchat: PLATFORM.SNAPCHAT,
  tiktok: PLATFORM.TIKTOK,
  ga: PLATFORM.GA4,
  dv360: PLATFORM.DV360,
  linkedin: PLATFORM.LINKEDIN
}

export const OPERATOR_OPTIONS = {
  [DIMENSION_OPERATORS_WITH_TYPES.is.id]: DIMENSION_OPERATORS_WITH_TYPES.is,
  [DIMENSION_OPERATORS_WITH_TYPES.not_is.id]: DIMENSION_OPERATORS_WITH_TYPES.not_is,
  [DIMENSION_OPERATORS_WITH_TYPES.one_of.id]: DIMENSION_OPERATORS_WITH_TYPES.one_of,
  [DIMENSION_OPERATORS_WITH_TYPES.not_one_of.id]: DIMENSION_OPERATORS_WITH_TYPES.not_one_of
}
