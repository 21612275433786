import React, { type Dispatch, type SetStateAction } from 'react'
import { ArrowRightIcon, Divider, Typography, Button, styled, EditIcon } from '@precis-digital/kurama'
import WorkspaceProfile from './WorkspaceProfile'
import {
  StyledFlyoutFooter,
  StyledFullWidthButton,
  StyledCard,
  StyledCardContent,
  StyledFlyoutContainer
} from './styles'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'

interface AdminFlyoutProps {
  handleFlyout: Dispatch<SetStateAction<boolean>>
  isAdminWorkspaceProfile: boolean
  setIsAdminWorkspaceProfile: Dispatch<SetStateAction<boolean>>
}

const AdminFlyout = ({
  handleFlyout,
  isAdminWorkspaceProfile,
  setIsAdminWorkspaceProfile
}: AdminFlyoutProps): React.ReactElement => {
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('workspace')

  const handleMembersClick = (): void => {
    handleFlyout(false)
  }

  return (
    <StyledFlyoutContainer>
      <Divider />
      {!isAdminWorkspaceProfile ? (
        <>
          <UnStyledRetainDefaultQueryLink href={APP_ROUTES.workspace.basePage}>
            <StyledCard onClick={handleMembersClick}>
              <StyledCardContent>
                <Typography variant="h3">{t('titles.members')}</Typography>
              </StyledCardContent>

              <StyledButtonContainer>
                <Button onClick={handleMembersClick} variant="tonal">
                  <ArrowRightIcon />
                </Button>
              </StyledButtonContainer>
            </StyledCard>
          </UnStyledRetainDefaultQueryLink>
          <StyledFlyoutFooter>
            <Divider />
            <StyledFullWidthButton
              variant="filled"
              leftIcon={<EditIcon />}
              onClick={() => {
                setIsAdminWorkspaceProfile(true)
              }}
            >
              {t('buttons.editWorkspace')}
            </StyledFullWidthButton>
          </StyledFlyoutFooter>
        </>
      ) : (
        <WorkspaceProfile currentClient={currentClient} />
      )}
    </StyledFlyoutContainer>
  )
}

const StyledButtonContainer = styled('div')(({ theme }) => ({
  button: {
    padding: 0,
    div: {
      marginRight: '0px'
    }
  }
}))

export default AdminFlyout
