import { useMemo } from 'react'
import { type FieldValues, type FormState } from 'react-hook-form'
import { isNilOrEmpty } from 'shared/utils'

const INITIAL_FORM_STEP = 1

const useCurrentFormStep = <FormStep extends { key: string | string[] }, FormData extends FieldValues>(
  arrangedFormSteps: FormStep[],
  formState: FormState<FormData>,
  ignoreDirtyFields?: boolean
): number => {
  return useMemo(() => {
    if (ignoreDirtyFields !== true && isNilOrEmpty(formState.dirtyFields)) {
      return INITIAL_FORM_STEP
    }

    for (const [index, step] of arrangedFormSteps.entries()) {
      const key = step.key
      const isValidStep = Array.isArray(key)
        ? key.every((singleKey) => isNilOrEmpty(formState.errors[singleKey]))
        : isNilOrEmpty(formState.errors[key])

      if (!isValidStep) {
        return index + 1
      }
    }
    return arrangedFormSteps.length
  }, [arrangedFormSteps, formState, ignoreDirtyFields])
}

export default useCurrentFormStep
