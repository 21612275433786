import React, { type ReactElement } from 'react'
import { type ListViewCellProps } from '../../types'
import { Avatar, AvatarGroup, Grid, Typography, styled } from '@precis-digital/kurama'
import BaseCell from './BaseCell'

interface TitleWithAvatarsCellProps<T extends object> extends ListViewCellProps<T> {
  title: string
  avatars: Array<{
    name: string
    imageUrl?: string
  }>
  /**
   * The plural version of the word.
   * The singular version will be automatically determined.
   */
  avatarDescriber?: string
}

const TitleWithAvatarsCell = <T extends object>({
  title,
  avatars,
  avatarDescriber,
  ...props
}: TitleWithAvatarsCellProps<T>): ReactElement => {
  return (
    <BaseCell width={props.column.parsedWidth} align="left">
      <Grid container flexDirection="column" overflow="hidden">
        <StyledTypographyTitle variant="h3" title={title}>
          {title}
        </StyledTypographyTitle>
        <Grid container alignItems="center" gap="4px" overflow="hidden" flexWrap="nowrap">
          <AvatarGroup size="small" max={2}>
            {avatars.map((avatar) => {
              if (avatar.imageUrl != null) {
                return <Avatar key={avatar.name} size="small" kind="image" imageUrl={avatar.imageUrl} />
              }
              return <Avatar key={avatar.name} size="small" kind="label" label={avatar.name} />
            })}
          </AvatarGroup>
          {avatarDescriber != null && (
            <StyledTypographyAnnotation variant="body3">
              {avatars.length} {avatarDescriber.substring(0, avatarDescriber.length - (avatars.length === 1 ? 1 : 0))}
            </StyledTypographyAnnotation>
          )}
        </Grid>
      </Grid>
    </BaseCell>
  )
}

const StyledTypographyTitle = styled(Typography)(() => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineClamp: 1
}))

const StyledTypographyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export default TitleWithAvatarsCell
