import { Grid, Toggle, Typography } from '@precis-digital/kurama'
import { useMutationAnalyticsAttribution } from 'attributionModel/api'
import ComparisonFilters, { type AttributionModel } from 'attributionModel/components/Dashboard/ComparisonFilters'
import Filters from 'attributionModel/components/Dashboard/DetailedReportFilters'
import {
  ADSPEND,
  CHANNEL,
  CONVERSIONS,
  COSTPERACTION,
  DEFINED_MODELS,
  FIRSTSUBHEADER,
  IMPRESSIONS,
  REVENUE,
  ROAS,
  SECONDSUBHEADER
} from 'attributionModel/constants'
import {
  preprocessAttributionData,
  transformAttributionDataToDetailedReport,
  type AttributionBaseReport
} from 'attributionModel/transformations'
import React, { useEffect, useMemo, useCallback } from 'react'
import { useFilters, useSortBy, useTable, type Column } from 'react-table'
import { type AttributionResp } from 'shared/api/attributionModels'
import { ReportTable } from 'shared/components/ReportTable'
import { formatDateToString } from 'shared/dateFns'
import { useTranslation } from 'shared/translations'
import {
  getBasicTableColumns,
  getComparisonTableColumns,
  getRevenueConversionsFieldFromModelType,
  getTimePeriod,
  type DefinedAttributionModelType,
  type TimePeriod,
  type TimePeriodType
} from './DetailedReportUtils'
import { objectFromEntries, objectKeys } from 'shared/utils'
import { useCurrentClient } from 'shared/context/ClientContext'
export interface AttributionModelDetailedReport {
  channel: string
  revenue: number
  roas: number | null
  conversions: number
  adSpend: number
  costPerAction: number | null
  impressions: number
}

interface DetailedReportingProps {
  data: AttributionModelDetailedReport[]
  isDataLoading: boolean
  currentIntegratedAttribution: AttributionResp | undefined
  dataPrevTimePeriod: AttributionModelDetailedReport[]
  isMissingDataPrevTimePeriod: boolean
  dataPrevYearTimePeriod: AttributionModelDetailedReport[]
  isMissingDataPrevYearTimePeriod: boolean
  dateRange: {
    startDate: string
    endDate: string
  }
  currencyCode: string
  hasConversionsButNoRevenue: boolean
}

export interface ColumnProps {
  [CHANNEL]: string
  [ADSPEND]: number
  [REVENUE]: number
  [ROAS]: number
  [CONVERSIONS]: number
  [COSTPERACTION]: number
  [IMPRESSIONS]: number
}

export interface ComparisonColumnProps {
  [CHANNEL]: string
  [ADSPEND]: number
  [REVENUE]: Record<string, number>
  [ROAS]: Record<string, number>
  [CONVERSIONS]: Record<string, number>
  [COSTPERACTION]: Record<string, number>
  [IMPRESSIONS]: Record<string, number>
}

const columnNames = {
  [CHANNEL]: 'channel',
  [ADSPEND]: 'adSpend',
  [REVENUE]: 'revenue',
  [ROAS]: 'roas',
  [CONVERSIONS]: 'conversions',
  [COSTPERACTION]: 'costPerAction',
  [IMPRESSIONS]: 'impressions'
} as const

export type Metric = keyof Pick<
  typeof columnNames,
  'adSpend' | 'revenue' | 'roas' | 'conversions' | 'costPerAction' | 'impressions'
>

const timePeriodInitialValue: TimePeriod = {
  value: '',
  startDate: '',
  endDate: ''
}
const modelInitialValue: AttributionModel = {
  name: '',
  id: 0,
  type: ''
}
export type ComparisonType = 'timePeriod' | 'config'

const calcCompareData = (
  timePeriodValue: TimePeriodType,
  modelId: number,
  dataPrevTimePeriod: AttributionModelDetailedReport[],
  dataPrevYearTimePeriod: AttributionModelDetailedReport[],
  dataModelCompare: AttributionModelDetailedReport[]
): AttributionModelDetailedReport[] => {
  if (timePeriodValue === 'previousTimePeriod') {
    return dataPrevTimePeriod
  }
  if (timePeriodValue === 'samePeriodLastYear') {
    return dataPrevYearTimePeriod
  }

  if (modelId !== modelInitialValue.id) {
    return dataModelCompare
  }
  return []
}

function isDefinedAttributionModelType(value: any): value is DefinedAttributionModelType {
  return Boolean(Object.values(DEFINED_MODELS).find((model) => model.value === value))
}

export const DetailedReport = ({
  data,
  isDataLoading,
  currentIntegratedAttribution,
  dataPrevTimePeriod,
  isMissingDataPrevTimePeriod,
  dataPrevYearTimePeriod,
  isMissingDataPrevYearTimePeriod,
  dateRange: { startDate, endDate },
  currencyCode,
  hasConversionsButNoRevenue
}: DetailedReportingProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel', { keyPrefix: 'dashboard' })
  const [channelsFilter, setChannelsFilter] = React.useState<Record<string, boolean>>({})
  const [metricsFilter, setMetricsFilter] = React.useState<Record<Metric, boolean>>({
    [ADSPEND]: false,
    [REVENUE]: false,
    [ROAS]: false,
    [CONVERSIONS]: false,
    [COSTPERACTION]: false,
    [IMPRESSIONS]: false
  })
  const [isComparisonMode, setIsComparisonMode] = React.useState<boolean>(false)
  const [isComparisonModeSelected, setIsComparisonModeSelected] = React.useState<boolean>(false)
  const [timePeriod, setTimePeriod] = React.useState<TimePeriod>(timePeriodInitialValue)
  const [model, setModel] = React.useState<AttributionModel>(modelInitialValue)
  const compareType: ComparisonType = timePeriod.value !== '' ? 'timePeriod' : 'config'
  const {
    mutate: getCompareDetailedReport,
    data: compareModelAttributionResponse,
    isLoading: isCompareDetailedReportLoading
  } = useMutationAnalyticsAttribution()
  const { currentClient } = useCurrentClient()

  const handleComparisonMode = (): void => {
    setIsComparisonMode(!isComparisonMode)
    setTimePeriod(timePeriodInitialValue)
    setModel(modelInitialValue)
    setIsComparisonModeSelected(false)
  }
  const handleTimePeriod = (timePeriodValue: TimePeriodType): void => {
    const { startTimePeriod, endTimePeriod } = getTimePeriod(timePeriodValue, startDate, endDate)
    setTimePeriod({
      ...timePeriod,
      value: timePeriodValue,
      startDate: startTimePeriod,
      endDate: endTimePeriod
    })
    setIsComparisonModeSelected(true)
    setModel(modelInitialValue)
  }

  const handleModel = (model: AttributionModel): void => {
    setTimePeriod(timePeriodInitialValue)
    setModel(model)
    setIsComparisonModeSelected(true)
    getCompareDetailedReport({
      id: model.id.toString(),
      collection: 'attribution',
      report: 'attribution',
      clientId: currentClient.id,
      filters: [
        {
          field: 'date',
          operator: 'BETWEEN',
          value: [startDate, endDate]
        }
      ],
      targetCurrencyCode: currencyCode
    })
  }

  const dataModelCompare: AttributionModelDetailedReport[] = useMemo((): AttributionModelDetailedReport[] => {
    if (
      currentIntegratedAttribution != null &&
      compareModelAttributionResponse != null &&
      model.type !== modelInitialValue.type
    ) {
      const { revenue: revenueField, conversions: conversionsField } = getRevenueConversionsFieldFromModelType(
        model.type
      )
      const tmpPreprocess: AttributionBaseReport[] = preprocessAttributionData(
        compareModelAttributionResponse,
        revenueField,
        conversionsField
      )
      return transformAttributionDataToDetailedReport(tmpPreprocess)
    }
    return []
  }, [compareModelAttributionResponse, currentIntegratedAttribution, model.type])

  const compareData = useMemo(() => {
    return calcCompareData(
      timePeriod.value,
      model.id,
      dataPrevTimePeriod,
      dataPrevYearTimePeriod,
      dataModelCompare ?? []
    )
  }, [timePeriod.value, model.id, dataPrevTimePeriod, dataPrevYearTimePeriod, dataModelCompare])

  const mappedTableData = React.useMemo(() => {
    if (isComparisonModeSelected) {
      if (compareData === undefined || compareData.length === 0) {
        return []
      }
      const allChannels = [
        ...new Set([...data.map((item) => item.channel), ...compareData.map((item) => item.channel)])
      ]
      const result = allChannels.map((channel) => {
        const data1Item = data.find((item) => item.channel === channel)
        const data2Item = compareData.find((item) => item.channel === channel)
        const mappedChannels: Record<string, string | number | Record<string, number>> = {}
        Object.values(columnNames).forEach((key) => {
          if (key === CHANNEL) {
            mappedChannels[key] = channel
          } else {
            mappedChannels[key] = {
              [FIRSTSUBHEADER]: data1Item == null ? 0 : data1Item[key] ?? 0,
              [SECONDSUBHEADER]: data2Item == null ? 0 : data2Item[key] ?? 0
            }
          }
        })
        return mappedChannels
      })
      return result
    }
    return data.map((item) => {
      return Object.fromEntries(
        Object.entries(columnNames).map(([columnName, columnValue]) => {
          return [columnName, item[columnValue] ?? 0]
        })
      )
    })
  }, [data, isComparisonModeSelected, compareData])
  useEffect(() => {
    const channelsFiltersInitialValues = Object.fromEntries(mappedTableData.map((item) => [item[CHANNEL], true]))
    const metricFiltersInitialValues = objectFromEntries<Array<[Metric, boolean]>>(
      objectKeys(columnNames)
        .filter((key) => key !== CHANNEL)
        .map((key) => {
          if (hasConversionsButNoRevenue && (key === ROAS || key === REVENUE)) {
            return [key as Metric, false]
          }
          return [key as Metric, true]
        })
    )
    setChannelsFilter(channelsFiltersInitialValues)
    setMetricsFilter(metricFiltersInitialValues)
  }, [mappedTableData, hasConversionsButNoRevenue])

  const handleModelCallback = useCallback(handleModel, [
    currencyCode,
    currentClient.id,
    endDate,
    getCompareDetailedReport,
    startDate
  ])

  useEffect(() => {
    if (isComparisonModeSelected && model.name !== '') {
      handleModelCallback(model)
    }
  }, [isComparisonModeSelected, currencyCode, startDate, endDate, model, handleModelCallback])

  const columns: ReadonlyArray<Column<object>> = React.useMemo(() => {
    let firstSubHeaderLabel = ''
    let secondSubHeaderLabel = ''
    if (isComparisonModeSelected) {
      switch (compareType) {
        case 'timePeriod': {
          const { startTimePeriod, endTimePeriod } = getTimePeriod(timePeriod.value, startDate, endDate)
          firstSubHeaderLabel = `${formatDateToString({ date: startDate })} - ${formatDateToString({
            date: endDate
          })}`
          secondSubHeaderLabel = `${formatDateToString({ date: startTimePeriod })} - ${formatDateToString({
            date: endTimePeriod
          })}`
          break
        }
        case 'config': {
          firstSubHeaderLabel = currentIntegratedAttribution?.name ?? ''
          secondSubHeaderLabel = isDefinedAttributionModelType(model.type)
            ? t(`modelTypeTitles.${model.type}`)
            : model.name
          break
        }
      }
    }

    const tableColumns = isComparisonModeSelected
      ? getComparisonTableColumns({
          t,
          channelsFilter,
          subgroup: { firstSubHeaderLabel, secondSubHeaderLabel },
          currencyCode
        })
      : (getBasicTableColumns({ t, channelsFilter, currencyCode }) as any)

    const isAllMetricsSelected = Object.values(metricsFilter).every((value) => !value)
    return tableColumns.filter((column: any) => {
      if (column.accessor !== undefined && typeof column.accessor === 'string') {
        return column.accessor === CHANNEL ? true : isAllMetricsSelected || metricsFilter[column.accessor as Metric]
      }
      return false
    })
  }, [
    isComparisonModeSelected,
    t,
    channelsFilter,
    currencyCode,
    metricsFilter,
    compareType,
    timePeriod.value,
    startDate,
    endDate,
    currentIntegratedAttribution?.name,
    model.type,
    model.name
  ])

  const renderFilters = (): React.ReactElement => {
    return (
      <Grid container>
        <Grid container item xs={12}>
          <>
            <Filters
              metricsFilter={metricsFilter}
              setMetricsFilter={setMetricsFilter}
              hasConversionsButNoRevenue={hasConversionsButNoRevenue}
            />
            <Grid item xs={3} display="inline-flex" justifyContent="end" alignItems="center" gap="16px">
              <Typography variant="body2">{t('compareThis')}</Typography>
              <Toggle
                aria-label={t('compareThis')}
                name="comparsionMode"
                onChange={handleComparisonMode}
                checked={isComparisonMode}
                defaultChecked
              />
            </Grid>
          </>
        </Grid>
        {isComparisonMode && (
          <ComparisonFilters
            timePeriod={timePeriod.value}
            handleTimePeriod={handleTimePeriod}
            model={model}
            handleModel={handleModel}
            currentIntegratedAttribution={currentIntegratedAttribution}
            isMissingDataPrevTimePeriod={isMissingDataPrevTimePeriod}
            isMissingDataPrevYearTimePeriod={isMissingDataPrevYearTimePeriod}
          />
        )}
      </Grid>
    )
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } = useTable(
    {
      columns,
      data: mappedTableData,
      initialState: {
        sortBy: [
          {
            id: 'channel',
            desc: false
          }
        ]
      }
    },
    useFilters,
    useSortBy
  )

  return (
    <>
      <Grid style={{ minHeight: '600px' }}>
        <Typography variant="h2">{t('detailedReporting')}</Typography>
        <ReportTable
          t={t}
          basic={!isComparisonModeSelected}
          tableProps={{
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            renderFilters,
            footerGroups
          }}
          isLoading={isDataLoading || isCompareDetailedReportLoading}
          basicColumnsIds={[CHANNEL]}
          stickyColumnsIds={[CHANNEL]}
        />
      </Grid>
    </>
  )
}

export default DetailedReport
