import React from 'react'

export const History = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M12.4544 8.38153V11.5745L14.638 13.7582M11.145 20H13.7633C17.4586 20 20.4542 17.0044 20.4542 13.3091V10.6909C20.4542 6.99561 17.4586 4 13.7633 4H11.145C7.44977 4 4.45416 6.9956 4.45416 10.6909V13.3091C4.45416 17.0044 7.44977 20 11.145 20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
})
