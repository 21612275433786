import { type ConditionalBuilderRowState } from '@precis-digital/kurama'

import { type CaseStatementRow } from './types'
import { type CaseStatementDimension } from 'reportingSolution/constants'
import { DIMENSION_ALIAS_MAP } from './constants'
import { type TFunction } from 'i18next'

export const transformRowToSql = (rule: ConditionalBuilderRowState): string => {
  switch (rule.operator) {
    case 'exactly_matches':
      return `LOWER(${rule.dimension}) = '${rule.value.toLowerCase()}'`

    case 'not_exactly_matches':
      return `NOT(LOWER(${rule.dimension}) = '${rule.value.toLowerCase()}')`

    case 'contains':
      return `CONTAINS_SUBSTR(LOWER(${rule.dimension}), r'${rule.value.toLowerCase()}')`

    case 'not_contains':
      return `NOT(CONTAINS_SUBSTR(LOWER(${rule.dimension}), r'${rule.value.toLowerCase()}'))`

    case 'matches_regex':
      return `REGEXP_CONTAINS(LOWER(${rule.dimension}),'${rule.value}')`

    case 'not_matches_regex':
      return `NOT(REGEXP_CONTAINS(LOWER(${rule.dimension}),'${rule.value}'))`

    default:
      throw new Error(`Parsing failed for ${JSON.stringify(rule)}`)
  }
}

interface ConditionalBuildersToSqlProps {
  conditionalBuilderRows: CaseStatementRow[]
}
export const conditionalBuildersToSqlString = ({ conditionalBuilderRows }: ConditionalBuildersToSqlProps): string => {
  if (conditionalBuilderRows.length === 0) {
    return ''
  }

  const sqlString = `CASE
  ${conditionalBuilderRows
    .map((row) => {
      return ` WHEN (${Object.values(row.rules)
        .map((orRules) => {
          return orRules
            .map((orRule) => {
              return transformRowToSql(orRule)
            })
            .join(' OR ')
        })
        .join(') AND (')}) THEN '${row.name}' `
    })
    .join('\n')}
   ELSE 'Other'
   END`

  return sqlString
}

interface SqlToConditionalBuildersProps {
  sql: string
  allowedDimensions: CaseStatementDimension[]
  platform?: string
  t: TFunction<'reportingSolutions'>
}

export const sqlStringToConditionalBuilders = ({
  sql,
  allowedDimensions,
  platform,
  t
}: SqlToConditionalBuildersProps): CaseStatementRow[] => {
  if (sql === '') {
    return []
  }

  const allowedDimensionsValues = allowedDimensions.map((dimension) => dimension.value.toLowerCase())

  const cleanedString = (/CASE([\s\S]*?)ELSE/g.exec(sql.replace(/\s{2,}/g, ' ').replace(/\n/g, ''))?.[1] ?? '').trim()
  const rawWhensList = cleanedString.split('WHEN')
  rawWhensList.shift()
  const whens = rawWhensList.map((rawWhen) => `WHEN${rawWhen}`)

  const rows: CaseStatementRow[] = whens.map((when, idx) => {
    const row: CaseStatementRow = {
      name: /THEN '([\s\S]*?)'/g.exec(when)?.[1] ?? '',
      rules: {},
      uuid: Math.random().toString(36).substring(7)
    }

    const ands = (/WHEN ([\s\S]*?) THEN/g.exec(when)?.[1] ?? '').split(') AND (')
    ands[0] = ands[0].substring(1)
    ands[ands.length - 1] = ands[ands.length - 1].substring(0, ands[ands.length - 1].length - 1)

    row.rules = ands.reduce<Record<string, ConditionalBuilderRowState[]>>((accumulator, and, index) => {
      const ors = and.split(' OR ')

      const formattedOrs = ors.map((or) => {
        let dimensionInput = /LOWER\(([\s\S]*?)\)/g.exec(or)?.[1]?.toLowerCase()
        if (dimensionInput == null) {
          // If the dimension is not wrapped in a LOWER function
          // it must be a REGEXP_CONTAINS function from the CustomGroupings flow
          // which doesn't have the LOWER function.
          dimensionInput = /REGEXP_CONTAINS\(([\s\S]*?),/g.exec(or)?.[1]
        }

        if (dimensionInput == null || dimensionInput === '') {
          throw new Error(`Parsing failed for ${or}`)
        }

        if (!allowedDimensionsValues.includes(dimensionInput)) {
          if (platform == null) {
            throw new Error(t('notifications.dimensionNotAllowed', { dimension: dimensionInput }))
          }

          const sameDimensionWithDifferentName = findDimensionWithSameName(dimensionInput, platform, allowedDimensions)
          if (sameDimensionWithDifferentName == null) {
            throw new Error(t('notifications.dimensionNotAllowed', { dimension: dimensionInput }))
          }

          dimensionInput = sameDimensionWithDifferentName.value
        }

        if (or.includes(' = ')) {
          // Must be an exactly_matches type
          return {
            dimension: dimensionInput,
            operator: or.startsWith('NOT') ? 'not_exactly_matches' : 'exactly_matches',
            value: /'([\s\S]*?)'/g.exec(or)?.[1] ?? ''
          }
        } else if (or.includes('CONTAINS_SUBSTR')) {
          // Must be a contains type
          return {
            dimension: dimensionInput,
            operator: or.startsWith('NOT') ? 'not_contains' : 'contains',
            value: /r'([\s\S]*?)'/g.exec(or)?.[1] ?? ''
          }
        } else if (or.includes(' LIKE ')) {
          // This is legacy, some channel groupings still contains like but will on save be using contains
          return {
            dimension: dimensionInput,
            operator: or.startsWith('NOT') ? 'not_contains' : 'contains',
            value: /'%([\s\S]*?)%'/g.exec(or)?.[1] ?? ''
          }
        } else if (or.includes('REGEXP_CONTAINS')) {
          // Must be a matches_regex type
          return {
            dimension: dimensionInput,
            operator: or.startsWith('NOT') ? 'not_matches_regex' : 'matches_regex',
            value: /'([\s\S]*?)'/g.exec(or)?.[1] ?? ''
          }
        } else {
          throw new Error(`Parsing failed for ${or}`)
        }
      })

      accumulator[index.toString()] = formattedOrs
      return accumulator
    }, {})
    return row
  })

  return rows
}

/**
 * Sometimes users might input a SQL string with a dimension that means the same thing
 * but has a different name for the specific report.
 * This function will attempt to find a list of dimensions that have the same meaning for the
 * platform and then try to find one of those "same name dimensions" in the list of allowed dimensions.
 */
const findDimensionWithSameName = (
  dimension: string,
  platform: string,
  allowedDimensions: CaseStatementDimension[]
): CaseStatementDimension | undefined => {
  const potentialDimensions = DIMENSION_ALIAS_MAP[platform]
  if (potentialDimensions == null) {
    return undefined
  }

  const sameNameDimensionsArray = potentialDimensions.find((potentialDimensionArray) =>
    potentialDimensionArray.includes(dimension)
  )

  if (sameNameDimensionsArray == null) {
    return undefined
  }

  const allowedDimensionsValues = allowedDimensions.map((dimension) => dimension.value)

  let foundDimension: CaseStatementDimension | undefined

  sameNameDimensionsArray.forEach((potentialDimension) => {
    if (allowedDimensionsValues.includes(potentialDimension)) {
      foundDimension = allowedDimensions.find((allowedDimension) => allowedDimension.value === potentialDimension)
    }
  })

  return foundDimension
}
