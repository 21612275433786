export const palette = {
  primary: {
    main: '#5578F5'
  },
  secondary: {
    main: '#232332'
  },
  main: {
    blue05: '#F5F7FE',
    blue50: '#9BB0F9',
    blue90: '#6C8CFE',
    blue100: '#5578F5',
    blue110: '#3259E7',
    blue120: '#254CD8'
  },
  neutrals: {
    white0: '#FFFFFF',
    stone90: '#F8FBFC',
    stone100: '#EEF4F6',
    stone120: '#B5C7D4',
    stone150: '#7A93AB'
  },
  semantic: {
    error110: '#FF2B44',
    error100: '#FF4C61',
    error90: '#FF6C7D',
    error05: '#FFF0F5',
    warning110: '#FF830C',
    warning100: '#FF9F43',
    warning90: '#FFB269',
    warning05: '#FFFAE8',
    success110: '#0E9975',
    success100: '#10AC84',
    success90: '#12C194',
    success05: '#E8FDF7',
    info100: '#5578F5',
    info05: '#F5F7FE'
  },
  graphs: {
    pink05: '#FADCF5',
    steel100: '#9AB3BA',
    mint05: '#ACE0D2',
    purple120: '#4D4763',
    beige100: '#ECE4D1',
    indigo10: '#D2D8FD',
    indigo100: '#A396FF',
    mint100: '#339C8C',
    yellow100: '#FFD7A0',
    red05: '#FDC2C2',
    green100: '#005F4B',
    red50: '#FFAD8D',
    beige110: '#EDDBC7',
    purple100: '#7469AD',
    purple150: '#3A2E32'
  },
  custom: {
    grey: '#F4F8F8'
  }
}

export type PaletteType = typeof palette

export interface PaletteTypes extends PaletteType {
  getContrastText: (color: string) => string
}

export default palette
