import { useMutation, type UseMutationResult, useQuery, type UseQueryResult, useQueryClient } from 'react-query'
import { clients, type fetch, messages } from 'shared/api'
import { type PatchClientReq, type PatchUpdateMemberRoleReq, type MemberResp } from 'shared/api/clients'

export const queryKeys = {
  allMembers: 'allMembers',
  verifyToken: 'verifyToken',
  member: 'member',
  allMessages: 'allMessages',
  allClients: 'allClients'
}

export const useCreateNewClient = (): UseMutationResult<clients.ClientResp, fetch.ClientError, clients.ClientReq> => {
  const queryClient = useQueryClient()
  return useMutation(clients.createClient, {
    onMutate: (newClient) => {
      const prevClientsList: clients.ClientsResp | undefined = queryClient.getQueryData(queryKeys.allClients)
      if (prevClientsList !== undefined) {
        queryClient.setQueryData(queryKeys.allClients, [...prevClientsList, newClient])
      }

      return { prevClientsList }
    },
    onError: async (_, __, context) => {
      if (context?.prevClientsList != null) {
        queryClient.setQueryData(queryKeys.allClients, [...context.prevClientsList])
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKeys.allMembers)
      await queryClient.invalidateQueries(queryKeys.allClients)
    }
  })
}

export interface Member {
  name: string
  email: string
  id: string
  role: 'viewer' | 'editor' | 'admin'
  avatarUrl?: string
  createdAt: string
  updatedAt: string
}

export interface MembersResp extends Array<Member> {}

export const useQueryMembers = (clientId: string): UseQueryResult<MembersResp, fetch.ClientError> => {
  return useQuery([queryKeys.allMembers, clientId], async () => await clients.getMembers(clientId), {
    enabled: Boolean(clientId)
  })
}

export const useQueryMessages = (clientId: string): UseQueryResult<messages.MessagesResp, fetch.ClientError> => {
  return useQuery([queryKeys.allMessages], async () => await messages.getMessages(clientId), {
    enabled: Boolean(clientId)
  })
}

export const useQueryMember = (
  clientId: string,
  userId: string
): UseQueryResult<clients.MemberResp, fetch.ClientError> => {
  return useQuery(
    [`${queryKeys.member}`, clientId.toString(), userId.toString()],
    async () => await clients.getMember(clientId.toString(), userId.toString()),
    {
      enabled: Boolean(clientId.toString() !== '' && userId.toString() !== '')
    }
  )
}

export const useCreateMessage = (): UseMutationResult<
  messages.MessageResp,
  fetch.ClientError,
  messages.CreateMessageReq
> => {
  const queryClient = useQueryClient()
  return useMutation(messages.createMessage, {
    onError: async () => {
      await queryClient.invalidateQueries(queryKeys.allMessages)
    }
  })
}

export const useResendMessage = (): UseMutationResult<
  messages.MessageResp,
  fetch.ClientError,
  messages.ResendMessageReq
> => {
  return useMutation(messages.resendMessage)
}

export const useQueryClients = (enabled: boolean = true): UseQueryResult<clients.ClientsResp, fetch.ClientError> => {
  return useQuery([queryKeys.allClients], async () => await clients.getClients(), {
    enabled
  })
}

export const useQueryClientsWithField = (
  enabled: boolean = true,
  field: string
): UseQueryResult<clients.ClientsResp, fetch.ClientError> => {
  return useQuery([queryKeys.allClients], async () => await clients.getClientsWithField(field), {
    enabled,
    staleTime: 0
  })
}

export const useUpdateClient = (): UseMutationResult<clients.ClientResp, fetch.ClientError, PatchClientReq> => {
  const queryClient = useQueryClient()
  return useMutation(clients.updateClient, {
    onSuccess: async (data) => {
      queryClient.setQueryData([queryKeys.allClients], (prevClientsList: clients.ClientResp[] | undefined) => {
        if (prevClientsList !== undefined) {
          return prevClientsList.map((client) => {
            if (client.id === data.id) {
              return data
            }
            return client
          })
        }
        return []
      })
    }
  })
}

export const useUpdateMemberRole = (): UseMutationResult<MemberResp, fetch.ClientError, PatchUpdateMemberRoleReq> => {
  const queryClient = useQueryClient()
  return useMutation(clients.updateMemberRole, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.allMembers)
    }
  })
}

export const useUpdateMessage = (): UseMutationResult<
  messages.MessageResp,
  fetch.ClientError,
  messages.UpdateMessageReq
> => {
  const queryClient = useQueryClient()
  return useMutation(messages.updateMessage, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.allMessages)
    }
  })
}

export const useUpdateMember = (): UseMutationResult<clients.MemberResp, fetch.ClientError, clients.PatchMemberReq> => {
  return useMutation(clients.updateMember)
}

export const useDeleteMember = (): UseMutationResult<
  clients.MemberResp,
  fetch.ClientError,
  clients.DeleteMemberReq
> => {
  const queryClient = useQueryClient()
  return useMutation(clients.deleteMember, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.allMembers)
    }
  })
}

export const useDeleteClient = (): UseMutationResult<
  clients.DeleteClientResp,
  fetch.ClientError,
  clients.DeleteClientReq
> => {
  const queryClient = useQueryClient()
  return useMutation(clients.deleteClient, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.allClients)
    }
  })
}

export const useVerifyToken = (token: string): UseQueryResult<messages.MessageResp, fetch.ClientError> => {
  return useQuery([queryKeys.verifyToken, token], async () => await messages.verifyToken({ token }), {
    enabled: token !== undefined
  })
}
