import { type clients } from 'shared/api'

export interface RoleProps {
  children: React.ReactNode
  onUnauthorized: () => React.ReactElement
  memberRole?: clients.MemberRole
  authorizedMemberRoles: clients.MemberRole[]
}

/**
 * Verify if the user is authorized to access this component
 *
 */
export const Role = ({
  authorizedMemberRoles,
  memberRole = 'viewer',
  onUnauthorized,
  children
}: RoleProps): React.ReactElement => {
  const hasAccess = authorizedMemberRoles.includes(memberRole)
  return !hasAccess ? onUnauthorized() : <>{children}</>
}

export default Role
