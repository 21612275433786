import React from 'react'
import Grid from '../Grid'
import { ListItem, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material'
import { ClientSectionProps, TopNavigationProps, NavActionsSectionProps } from './types'
import {
  StyledDivider,
  StyledTopNavigation,
  StyledClientSection,
  StyledNavActionSection,
  StyledBreadcrumbs,
  StyledGridNavButtonsContainer
} from './styles'
import { RightIcon, Typography } from '../index'
import { ArrowLeftIcon, ArrowRightIcon } from '../Icons'
import Button from '../Button'

const TopNavigation = ({
  clientSection,
  navActionsSection,
  navButtons,
  breadcrumbs
}: TopNavigationProps): React.ReactElement => {
  return (
    <>
      <StyledTopNavigation container>
        <Grid
          item
          display="flex"
          alignItems="center"
          gap="24px"
          height="57px"
          overflow="hidden"
          flexGrow={1}
          whiteSpace="nowrap"
          paddingRight="24px"
        >
          {navButtons != null && (
            <StyledGridNavButtonsContainer>
              <Button
                variant="text"
                leftIcon={<ArrowLeftIcon />}
                onClick={navButtons.handleBackwardClick}
                disabled={navButtons.isBackwardDisabled}
              />
              <Button
                variant="text"
                rightIcon={<ArrowRightIcon />}
                onClick={navButtons.handleForwardClick}
                disabled={navButtons.isForwardDisabled}
              />
            </StyledGridNavButtonsContainer>
          )}
          {breadcrumbs != null && (
            <StyledBreadcrumbs separator={<RightIcon />}>
              {breadcrumbs.pathProps.map((breadcrumbPathProps, index, breadcrumbsPathProps) =>
                breadcrumbsPathProps.length - 1 === index || breadcrumbPathProps.disabled === true ? (
                  <Typography key={breadcrumbPathProps.path} variant="h5">
                    {breadcrumbPathProps.label}
                  </Typography>
                ) : (
                  <breadcrumbs.Link key={breadcrumbPathProps.path} href={breadcrumbPathProps.path}>
                    {breadcrumbPathProps.label}
                  </breadcrumbs.Link>
                )
              )}
            </StyledBreadcrumbs>
          )}
        </Grid>
        <Grid display="flex" flexDirection="row-reverse" alignItems="center">
          {clientSection.name != null && (
            <>
              <Grid item>
                <ClientSection clientSection={clientSection} />
              </Grid>
              <Grid item display="flex" alignItems="center">
                <StyledDivider orientation="vertical" />
              </Grid>
            </>
          )}
          <StyledNavActionSection item>
            <NavActions navActionsSection={navActionsSection} />
          </StyledNavActionSection>
        </Grid>
      </StyledTopNavigation>
    </>
  )
}

const NavActions = ({ navActionsSection }: { navActionsSection: NavActionsSectionProps[] }): React.ReactElement => {
  return (
    <>
      {navActionsSection.map(({ IconComponent, onClick, ariaLabel, buttonRef }: NavActionsSectionProps) => {
        return (
          <Button
            key={ariaLabel}
            aria-label={ariaLabel}
            ref={buttonRef}
            variant="tonal"
            onClick={onClick}
            leftIcon={IconComponent}
          />
        )
      })}
    </>
  )
}

const ClientSection = ({
  clientSection: { name, url, IconComponent, handleOnClick }
}: {
  clientSection: ClientSectionProps
}): React.ReactElement => {
  return (
    <StyledClientSection onClick={handleOnClick} role="group" aria-label="client menu">
      <ListItemButton>
        <ListItem>
          <ListItemAvatar>{IconComponent}</ListItemAvatar>
          <ListItemText
            primary={`${name ?? ''}`}
            primaryTypographyProps={{
              variant: 'h5'
            }}
            secondary={`${url ?? ''}`}
            secondaryTypographyProps={{
              variant: 'body2'
            }}
          />
        </ListItem>
      </ListItemButton>
    </StyledClientSection>
  )
}

export default TopNavigation
