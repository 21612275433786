import { type SyntheticEvent, useCallback, useEffect, useState, useMemo } from 'react'
import {
  type ListViewTabsDefinition,
  type ControlledListViewFilter,
  type ListViewFilterDefinition,
  type ListViewFilterOption,
  type ControlledListViewTabs,
  type ListViewTabsOption
} from './types'
import { ALL_OPTIONS_FILTER_VALUE } from './constants'
import { type IdType } from 'react-table'
import { difference, uniq } from 'ramda'
import { useQueryString } from 'shared/components/Router'
import { transformQueryParamsToFilters, transformFiltersToQueryParams } from './utils'
import { isNilOrEmpty } from 'shared/utils'

export type controlledFilterValues<T> = Record<IdType<T>, string | string[] | null | undefined>

const getDistinctFilterValueOptions = <T>(filter: ListViewFilterDefinition<T>, data: T[]): ListViewFilterOption[] => {
  const allValues = data.map((row) => row[filter.accessor as keyof T])
  return uniq(allValues)
    .map((value) => {
      if (typeof value !== 'string' && typeof value !== 'undefined') {
        throw new Error(
          `Filter ${
            filter.accessor
          } is of type ${typeof value} but only string values are supported when using distinct_values`
        )
      }
      const parsedValue = (value ?? '') as string
      if (filter.valueTranslator != null) {
        return filter.valueTranslator(parsedValue, data.find((row) => row[filter.accessor as keyof T] === value) as T)
      }

      return {
        label: parsedValue,
        value: parsedValue
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const useGetControlledFilters = <T>(
  filters?: Array<ListViewFilterDefinition<T>>,
  data?: T[],
  persistFilters: boolean = false
): {
  filterValues: controlledFilterValues<T>
  controlledFilters?: Array<ControlledListViewFilter<T>>
  initialFilterValues: controlledFilterValues<T>
  resetFilters: () => void
} => {
  const { pushQuery, query } = useQueryString()

  const { defaultFilterValues, initialFilterValues } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const defaultFilterValues: controlledFilterValues<T> = {} as controlledFilterValues<T>
    filters?.forEach((filter) => {
      defaultFilterValues[filter.accessor] = filter.defaultValue
    })
    let initialFilterValues = defaultFilterValues
    if (!isNilOrEmpty(query?.q) && !Array.isArray(query.q)) {
      initialFilterValues = transformQueryParamsToFilters(query.q, filters)
    }

    return {
      defaultFilterValues,
      initialFilterValues
    }
  }, [filters, query])

  const [filterValues, setFilterValues] = useState<controlledFilterValues<T>>(initialFilterValues)

  const handleFilterChange = useCallback(
    (accessor: IdType<T>, value: string | string[] | null): void => {
      setFilterValues((prev) => {
        const selectedFilterValues = {
          ...prev,
          [accessor]: value === ALL_OPTIONS_FILTER_VALUE ? null : value
        }
        if (persistFilters) {
          const queryValue = transformFiltersToQueryParams(selectedFilterValues, filters)
          void pushQuery(queryValue !== '' ? { q: queryValue } : {}, true)
        }
        return selectedFilterValues
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding pushQuery to dependency array puts the component in an infinite loop
    [persistFilters, filters]
  )

  const resetFilters = useCallback(() => {
    setFilterValues(defaultFilterValues)
    const queryValue = transformFiltersToQueryParams(defaultFilterValues, filters)
    void pushQuery(queryValue !== '' ? { q: queryValue } : {}, true)
  }, [defaultFilterValues, pushQuery, filters])

  const [controlledFilters, setControlledFilters] = useState<Array<ControlledListViewFilter<T>> | undefined>()

  useEffect(() => {
    setControlledFilters(
      filters?.map((filter, index) => {
        let options: ListViewFilterOption[] = []

        if (filter.options === 'distinct_values') {
          const allValues = data?.map((row) => row[filter.accessor as keyof T])
          if (allValues != null) {
            options = getDistinctFilterValueOptions(filter, data ?? [])
          }
        } else if (typeof filter.options === 'function') {
          options = filter.options(data ?? [])
        } else {
          options = filter.options
        }

        return {
          ...filter,
          options,
          value: filterValues[filter.accessor],
          onChange: (value: string | string[]) => {
            handleFilterChange(filter.accessor, value)
          }
        }
      })
    )
  }, [filters, filterValues, handleFilterChange, data])

  return { filterValues, controlledFilters, initialFilterValues, resetFilters }
}

export const useGetControlledTabs = <T>(
  tabs?: ListViewTabsDefinition<T>,
  data?: T[]
): {
  controlledTabs?: ControlledListViewTabs<T>
  handleTabChange: (event: SyntheticEvent<Element, Event>, newValue: number) => void
} => {
  const [tabValue, setTabValue] = useState<number>(0)

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number): void => {
    setTabValue(newValue)
  }

  const controlledTabs = useMemo(() => {
    if (tabs == null) {
      return undefined
    }

    const dataAccessorValues = (data?.map((row) => row[tabs.accessor as keyof T]) ?? []).map((value) => {
      if (typeof value !== 'string' && typeof value !== 'undefined') {
        throw new Error(`Tab ${tabs.accessor} is of type ${typeof value} but only string values are supported`)
      }
      return (value ?? '') as string
    })

    const otherValues = difference(uniq(dataAccessorValues), tabs.orderedOptions)

    const definedOptions: ListViewTabsOption[] = uniq(tabs.orderedOptions)
      .map((value, index) => {
        const parsedValue = value ?? ''
        return {
          label: parsedValue,
          tabValue: index + 1,
          value: parsedValue,
          count: dataAccessorValues.filter((accessorValue) => accessorValue === value).length ?? 0
        }
      })
      .filter((option) => option.value !== '')

    const otherTabCount =
      dataAccessorValues.filter((accessorValue) => !tabs.orderedOptions.includes(accessorValue)).length ?? 0

    return {
      ...tabs,
      options: [
        ...definedOptions,
        ...(otherTabCount > 0
          ? [
              {
                label: tabs.otherOptionLabel,
                tabValue: tabs.orderedOptions.length + 1,
                value: otherValues,
                count: otherTabCount
              }
            ]
          : [])
      ],
      value: tabValue
    }
  }, [data, tabs, tabValue])

  return { controlledTabs, handleTabChange }
}
