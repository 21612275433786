import { type ChatMessageRole, type DocumentSourceType } from 'mimir/constants'
import fetch from './fetch'
import { omit } from 'ramda'

const client = fetch()

export type MimirServiceType = 'azure-openai' | 'openai' | 'google-palm'
export type MimirModelType = 'gpt35' | 'gpt4' | 'mimir_gpt35' | 'mimir_gpt4' | 'chat_bison' | 'codechat_bison'
export interface ChatContextMessage {
  user: 'assistant' | 'user'
  message: string
}

export interface MimirReq {
  question: string
  clientId: string
  contextPrompts: ChatContextMessage[]
  model: MimirModelType
  service: MimirServiceType
}

interface MetaData {
  createdAt: string
  parentId: number
  spaceId: number
  spaceKey: string
  spaceName: string
  status: string
}

export interface RelatedLink {
  createdAt: string
  documentLink: string
  enabled: boolean
  id: string
  metaData: MetaData
  normalisedSimilarityScore: number
  similarityScore: number
  title: string
  type: string
  updatedAt: string
}

export interface MimirResponse {
  message: string
  relatedLinks?: RelatedLink[]
}

export const postQuestion = async (data: MimirReq, token?: string): Promise<MimirResponse> => {
  return await client.post<MimirResponse>({
    path: `/mimir/chat`,
    data,
    token
  })
}

export interface MimirAgentSearchRequest {
  agentId: string
  query: string
  clientId: string
  resultCount?: number
  pageToken?: string
}

export interface MimirAgentDocument {
  id: string
  title: string
  summary: string
  description: string
  uri: string
  sourceType: DocumentSourceType | string
  sourceCategory: string
  sourceCategoryUri: string
  sourceFaviconUri?: string
  gscLink: string
  snippets: string[]
}

export interface MimirAgentSearchResponse {
  summary: string
  documents: MimirAgentDocument[]
  total_size: number
  next_page_token?: string
}

export const agentSearch = async (params: MimirAgentSearchRequest): Promise<MimirAgentSearchResponse> => {
  const paramsAsStrings = {
    client_id: params.clientId,
    query: params.query
  }

  const queryString = new URLSearchParams(paramsAsStrings).toString()
  return await client.get<MimirAgentSearchResponse>({
    path: `/mimir/agents/${params.agentId}/search?${queryString}`
  })
}

export interface MimirAgentMessage {
  role: ChatMessageRole
  content: string
}

export interface MimirAgentChatRequest {
  agentId: string
  clientId: string
  question: string
  skipRag?: boolean
  contextPrompts: MimirAgentMessage[]
}

export interface MimirAgentChatResponse {
  message: string
  relatedLinks?: MimirAgentDocument[]
}

export const agentChat = async (params: MimirAgentChatRequest): Promise<MimirAgentChatResponse> => {
  const requestPayload = omit(['agentId'], params)
  return await client.post<MimirAgentChatResponse>({
    path: `/mimir/agents/${params.agentId}/chat`,
    data: requestPayload
  })
}
