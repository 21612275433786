import React, { forwardRef } from 'react'
import { TypographyProps } from './types'
import { Typography as MUITypography } from '@mui/material'

const Typography = forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ variant = 'body1', children, ...props }, ref): React.ReactElement => {
    return (
      <MUITypography variant={variant} {...props} ref={ref}>
        {children}
      </MUITypography>
    )
  }
)
export default Typography
