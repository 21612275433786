import React, { type ReactElement } from 'react'
import BaseCell from './BaseCell'
import { type ListViewCellProps } from '../../types'
import { Typography, styled } from '@precis-digital/kurama'

const DefaultCell = <T extends object>({ value, ...props }: ListViewCellProps<T>): ReactElement => {
  return (
    <BaseCell width={props.column.parsedWidth} align={props.column.align}>
      <StyledTypographyValue variant="body2">{value}</StyledTypographyValue>
    </BaseCell>
  )
}

const StyledTypographyValue = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export default DefaultCell
