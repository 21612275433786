import React, { useState } from 'react'
import { Typography, Button, Link, Avatar } from '@precis-digital/kurama'
import { StyledAvatarContainer } from './styles'
import { type MemberRole } from 'shared/api/clients'
import InviteNewMembersPopup from '../Popup/InviteNewMembersPopup'
import { useTranslation } from 'shared/translations'
import { AvatarColor } from 'shared/components/AvatarWithLabel'
import { ensureAbsoluteUrl } from 'shared/utils'

interface AvatarContainerProps {
  name: string
  url: string
  avatarUrl?: string
  role: MemberRole
}

const AvatarContainer = (props: AvatarContainerProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const { name, url, avatarUrl, role } = props

  const { t } = useTranslation('workspace')

  return (
    <StyledAvatarContainer>
      {avatarUrl !== undefined ? (
        <Avatar kind="image" imageUrl={avatarUrl} size="large" objectFit="contain" />
      ) : (
        <AvatarColor kind="label" label={name !== '' && name != null ? name[0] : ''} size="large" fullLabel={name} />
      )}
      <Typography variant="h2">{name}</Typography>
      <Link variant="body3" href={ensureAbsoluteUrl(url)} target="_blank" rel="noreferrer">
        {url}
      </Link>
      {role === 'editor' ? (
        <>
          <Button
            scheme="light"
            variant="filled"
            onClick={() => {
              setIsOpen(true)
            }}
          >
            {t('buttons.inviteMembers')}
          </Button>
          <InviteNewMembersPopup
            open={isOpen}
            onClose={() => {
              setIsOpen(false)
            }}
          />
        </>
      ) : null}
    </StyledAvatarContainer>
  )
}

export default AvatarContainer
