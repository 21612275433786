import React from 'react'
import { RightIcon, Card, PopUp, Typography, Avatar, styled, Grid } from '@precis-digital/kurama'
import { PLATFORMS, type IPlatform } from 'dataSource/constants'
import { useTranslation } from 'react-i18next'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useAuth } from 'shared/context/AuthContext'
import { useQueryClientCredentials } from 'dataSource/api'
import { useFetchAccountCredentials, useOpenDataSourceAuthenticationModal } from 'dataSource/hooks'
import UserOrServiceAccountSelectionAccordion from '../UserOrServiceAccountSelectionAccordion'

export const ConnectNewDataSourcePopup = (): React.ReactElement => {
  const { t } = useTranslation('dataSource', { keyPrefix: 'popups.connectNewDataSource' })
  const connectNewDataSourcePopup = useOverScreen('connectNewDataSourcePopup')
  const [selectedDataSource, setSelectedDataSource] = React.useState<IPlatform | null>(null)

  const { currentUser } = useAuth()
  const {
    data: credentials,
    isSuccess: isCredentialsSuccess,
    refetch
  } = useQueryClientCredentials(currentUser?.id as string, selectedDataSource?.platform ?? '')

  useFetchAccountCredentials(selectedDataSource, refetch)
  useOpenDataSourceAuthenticationModal(
    isCredentialsSuccess,
    selectedDataSource,
    credentials,
    setSelectedDataSource,
    true
  )

  const handlePoupClose = (): void => {
    connectNewDataSourcePopup.remove()
  }

  return (
    <PopUp open={connectNewDataSourcePopup.visible} handleOpen={handlePoupClose}>
      <StyledHeader>
        <Typography variant="h2">{t('title')}</Typography>
        <Typography variant="body1">{t('description')}</Typography>
      </StyledHeader>
      {PLATFORMS.map((platform) =>
        platform.supportsServiceAccountAccess ? (
          <UserOrServiceAccountSelectionAccordion
            key={platform.label}
            platform={platform}
            setSelectedDataSource={setSelectedDataSource}
          />
        ) : (
          <StyledDataSourcePlate
            key={platform.label}
            onClick={() => {
              setSelectedDataSource(platform)
            }}
          >
            <Avatar imageUrl={platform.iconUrl} kind="image" />
            <Typography variant="h3">{platform.label}</Typography>
            <RightIcon />
          </StyledDataSourcePlate>
        )
      )}
    </PopUp>
  )
}

export const StyledHeader = styled(Grid)(() => ({
  textAlign: 'center'
}))
export const StyledDataSourcePlate = styled(Card)(() => ({
  justifyContent: 'initial',
  '& h3': {
    marginLeft: '20px'
  },
  '& svg': {
    marginLeft: 'auto'
  }
}))

export default OverScreen.create(ConnectNewDataSourcePopup)
