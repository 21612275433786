import { useMemo, type ReactElement } from 'react'
import { MemberTimestampCell, StatusCell, TextCell, TitleWithAvatarsCell } from 'shared/components/ListView'

import { MoreMenu, transformAttributionRespToReq } from 'attributionModel'
import { type ConfigWithStatus } from 'attributionModel/components/StartPage'
import {
  useQueryBudgetOptimiserById,
  useQueryBudgetOptimiserConfigStatusById,
  useQueryBudgetOptimiserConfigStatuses,
  useQueryBudgetOptimisers
} from 'budgetOptimiser/api'
import { PLATFORMS } from 'dataSource/constants'
import { useQueriesUserProfilesFromIAConfigs } from 'profile/api'
import { type AttributionResp } from 'shared/api/attributionModels'
import { type ListViewCellProps, type ListViewColumnDefinition } from 'shared/components/ListView/types'
import { useQueryString } from 'shared/components/Router'
import { ORDERED_TAB_STATUSES, getErrorCodeDetails, getStatusMarketingEvaluation } from 'shared/configStatuses'
import { BUDGET_OPTIMISER_DOCUMENTATION_LINK } from 'shared/constants/links'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import useDagView from 'shared/hooks/useDagView'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { useTranslation } from 'shared/translations'
import { useQueryMember } from 'workspace/api'
import { APP_ROUTES } from 'shared/routes'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { useMutationUpdateAttribution } from 'attributionModel/api'
import { getPlatformDetailsByPlatform } from 'dataSource'
import TableRowLink from 'shared/components/ListView/Table/TableRowLink'
import { type RowLinkProps } from 'shared/components/ListView/Table'
import Start from 'shared/components/Start'
import { useQueryClientAccountPlatform, useQueryClientAccounts } from 'dataSource/api'
import { useMember } from 'workspace'
import { useQueryChannelGroupings } from 'channelGrouping/api'
import { hasAccountsWithSupportedPlatforms, hasPreRequisites } from 'shared/components/Prerequisite/utils'
import { platformsSupportedBo } from 'shared/components/Prerequisite/constants'
import { type Platforms } from 'shared/api/accounts'

const getPlatformNames = (attribution: AttributionResp): string[] => {
  const platformNames: string[] = attribution.adAccounts.map((account) => account.platform)
  if (attribution.analyticsAccount != null) platformNames.push(attribution.analyticsAccount?.platform)
  return platformNames
}

const getAccountIconUrl = (platformName: string): string | undefined => {
  return PLATFORMS.find((platform) => platform.platform === platformName)?.iconUrl
}

const BudgetOptimiserRowLink = ({ row, children }: RowLinkProps<ConfigWithStatus>): ReactElement => {
  return (
    <TableRowLink href={APP_ROUTES.budgetOptimiser.dashboardPage({ configId: `${row.id}` })}>{children}</TableRowLink>
  )
}

const StartPage = (): ReactElement => {
  const { t, i18n } = useTranslation('budgetOptimiser')
  const { t: tCommon } = useTranslation('common')
  const { query } = useQueryString()
  const { showDagView, shouldShowDagView, handleConfigError } = useDagView('budgetOptimiser')

  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()

  const { data: integratedAttributions, isLoading } = useQueryBudgetOptimisers(currentClient.id)
  const { data: integratedAttributionsStatuses, isLoading: isLoadingIntegratedAttributionsStatuses } =
    useQueryBudgetOptimiserConfigStatuses(currentClient.id)
  const { mutate: updateIntegrationAtributionModel } = useMutationUpdateAttribution()

  const platformGCP: Platforms = 'gcp'
  const { data: clientAccountGCP, isLoading: isLoadingClientAccountGCP } = useQueryClientAccountPlatform(
    currentClient.id,
    platformGCP
  )

  const { data: clientAccounts, isLoading: isClientAccountIsLoading } = useQueryClientAccounts(currentClient.id)

  const { role: memberRole } = useMember()

  const { data: channelGroupings, isLoading: isLoadingChannelGrouping } = useQueryChannelGroupings(currentClient?.id)

  const { hasAllPreRequisites, hasCustomGrouping, hasDataSourceAccount, hasGcpAccount } = hasPreRequisites({
    gcpAccount: clientAccountGCP,
    channelGroupings,
    accounts: hasAccountsWithSupportedPlatforms(clientAccounts, platformsSupportedBo)
  })

  const integratedAttributionsWithStatuses = useMemo(() => {
    if (integratedAttributions != null) {
      return integratedAttributions.map((attribution) => {
        const status = integratedAttributionsStatuses?.find((status) => status.configId === attribution.id.toString())
        return {
          ...attribution,
          status,
          statusEnum: getStatusMarketingEvaluation(attribution.runSchedule, status?.status)
        }
      })
    }
  }, [integratedAttributions, integratedAttributionsStatuses])

  const shouldShowDagViewPopup = shouldShowDagView(query)
  const configId = Number(query?.configId)

  const {
    data: budgetOptimiserConfig,
    isSuccess: isBudgetOptimiserConfigSuccess,
    isError: isBudgetOptimiserError,
    error
  } = useQueryBudgetOptimiserById(currentClient.id, configId, shouldShowDagViewPopup)

  const { data: budgetOptimiserStatus } = useQueryBudgetOptimiserConfigStatusById(
    currentClient.id,
    configId,
    shouldShowDagViewPopup
  )

  if (isBudgetOptimiserConfigSuccess && budgetOptimiserConfig != null && budgetOptimiserStatus != null) {
    showDagView({
      configId: budgetOptimiserConfig.id,
      configName: budgetOptimiserConfig.name,
      isActive: budgetOptimiserConfig.runSchedule,
      configStatus: getStatusMarketingEvaluation(budgetOptimiserConfig.runSchedule, budgetOptimiserStatus?.[0]?.status),
      useNativeDag: budgetOptimiserConfig.creditInput === 'native'
    })
  }

  if (isBudgetOptimiserError) {
    handleConfigError({
      statusCode: error.status_code,
      notFoundMessage: tCommon('notifications.configAccessError'),
      unexpectedErrorMessage: tCommon('notifications.configUnexpectedError')
    })
  }

  const userDetails = useQueriesUserProfilesFromIAConfigs(integratedAttributions)

  const { data: member, isLoading: isMemberLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const hasEditorAccess = useHasAccess('editor', member?.role ?? 'viewer')
  const columns: Array<ListViewColumnDefinition<ConfigWithStatus>> = useMemo(
    () => [
      {
        Header: t('listTable.titles.modelName'),
        accessor: 'name',
        Cell: (props: ListViewCellProps<ConfigWithStatus>) => {
          const accounts = getPlatformNames(props.row.original)
          const avatars = accounts.map((account) => {
            return {
              name: getPlatformDetailsByPlatform(account).label,
              imageUrl: getAccountIconUrl(getPlatformDetailsByPlatform(account).platform)
            }
          })
          return (
            <TitleWithAvatarsCell
              title={props.row.original.name}
              avatars={avatars}
              avatarDescriber={t('listTable.table.connectedAccounts')}
              {...props}
            />
          )
        }
      },
      {
        Header: t('listTable.titles.model'),
        accessor: 'creditInput',
        Cell: (props: ListViewCellProps<ConfigWithStatus>) => {
          const modelType =
            props.row.original.creditInput === 'native' && props.row.original.analyticsAccount?.platform !== 'ga4'
              ? 'platform'
              : props.row.original.creditInput
          return (
            <TextCell
              text={
                i18n?.exists(`budgetOptimiser:listTable.modelTitles.titles.${modelType}`)
                  ? t(`listTable.modelTitles.titles.${modelType}`)
                  : t(`listTable.modelTitles.titles.default`)
              }
              {...props}
            />
          )
        },
        align: 'center',
        width: '150px'
      },
      {
        Header: t('listTable.titles.lastEdited'),
        accessor: 'updatedAt',
        Cell: (props: ListViewCellProps<AttributionResp>) => (
          <MemberTimestampCell
            {...props}
            timestamp={props.row.original.updatedAt}
            memberId={userDetails?.find((res) => res.data?.email === props.row.original.updatedBy)?.data?.id ?? ''}
          />
        ),
        align: 'center'
      },
      {
        Header: t('listTable.titles.status'),
        accessor: 'statusEnum',
        Cell: (props: ListViewCellProps<ConfigWithStatus>) => {
          const dagViewInfo = {
            dagViewType: 'budgetOptimiser' as const,
            configId: props.row.original.id,
            configName: props.row.original.name,
            isActive: props.row.original.runSchedule,
            isPageWithConfigId: false
          }
          return (
            <StatusCell
              {...props}
              isLoadingStatus={isLoadingIntegratedAttributionsStatuses}
              status={props.row.original.statusEnum ?? 'unknown'}
              lastUpdated={props.row.original.status?.lastUpdated}
              errorCode={props.row.original.status?.errorCode}
              errorDetails={getErrorCodeDetails(
                props.row.original.status?.errorCode,
                props.row.original.status?.errorMessage,
                props.row.original.targetProject,
                tCommon('dagView.budgetOptimiser')
              )}
              dagViewInformation={dagViewInfo}
            />
          )
        },
        align: 'right',
        width: '120px'
      }
    ],
    [i18n, t, tCommon, userDetails, isLoadingIntegratedAttributionsStatuses]
  )

  const notAllowedToDelete = (modelId: number): boolean => {
    const model = integratedAttributions?.find((item) => item.id === modelId)
    if (model != null) {
      const hasPerformanceCurve = model.performanceCurves
      const hasCreditAllocator = model.creditAllocator
      const hasChannelGroupingId = model.channelGroupingId != null
      return hasPerformanceCurve && hasCreditAllocator && hasChannelGroupingId
    }
    return false
  }
  const isDataLoading = isLoading || isMemberLoading || isClientAccountIsLoading

  const isLoadingPreRequisites = isLoadingChannelGrouping || isLoadingClientAccountGCP

  return (
    <Start
      hasAllPreRequisites={hasAllPreRequisites}
      noPreRequisitesProps={{
        memberRole,
        t,
        hasGcpAccount,
        hasDataSourceAccount,
        hasCustomGrouping
      }}
      emptyStateProps={{
        title: t('emptyTable.header'),
        subtitle1: t('emptyTable.statement'),
        subtitle2: t('emptyTable.statement2'),
        educationalBlockProps: {
          title: t('emptyState.educationalBlock.title'),
          description: t('emptyState.educationalBlock.description'),
          documentationLinkHref: BUDGET_OPTIMISER_DOCUMENTATION_LINK,
          documentationLinkText: t('emptyState.educationalBlock.linkText')
        },
        ...(currentUser?.hasInternalFeatureAccess === true && {
          createNewButtonProps: {
            label: t('buttons.createNewModel'),
            hasEditorAccess,
            href: APP_ROUTES.budgetOptimiser.createConfigPage
          }
        })
      }}
      listViewProps={{
        isLoading: isDataLoading,
        title: t('meta.title'),
        ...(currentUser?.hasInternalFeatureAccess === true && {
          createNewButtonProps: {
            label: t('buttons.createNewModel'),
            isLoading: isMemberLoading,
            hasEditorAccess,
            isLoadingPreRequisites,
            href: APP_ROUTES.budgetOptimiser.createConfigPage
          }
        }),
        tabs: {
          accessor: 'statusEnum',
          orderedOptions: ORDERED_TAB_STATUSES,
          allOptionLabel: t('listTable.tabs.allStatuses'),
          otherOptionLabel: t('listTable.tabs.other')
        },
        tableProps: {
          data: integratedAttributionsWithStatuses ?? [],
          includePagination: true,
          columns,
          initialState: {
            pageIndex: 0,
            sortBy: [{ id: 'updatedAt', desc: true }]
          },
          RowLink: BudgetOptimiserRowLink,
          renderMoreMenu: (row: ConfigWithStatus, closeMenu: () => void) => {
            return (
              <MoreMenu
                selectedModel={{
                  ...row,
                  modelId: row.id,
                  isActive: row.runSchedule,
                  status: row.statusEnum,
                  targetProjectId: row.targetProject,
                  targetDatasetId: row.targetDataset
                }}
                path="budget-optimiser"
                onCloseMenu={closeMenu}
                hasEditorAccess={hasEditorAccess}
                allowDelete={notAllowedToDelete(row.id)}
                allowPause={row.channelGroupingId != null}
                onModelStatusChange={() => {
                  const { toastOnError, toastOnSuccess } = makeToastWithLoading()
                  const selectedConfig = integratedAttributions?.find(
                    (config) => Number(row.id) === Number(config.id)
                  ) as AttributionResp
                  updateIntegrationAtributionModel(
                    {
                      data: {
                        ...transformAttributionRespToReq(selectedConfig),
                        runSchedule: !row.runSchedule
                      },
                      configId: row.id
                    },
                    {
                      onSuccess: () => {
                        toastOnSuccess(t('form.configUpdated'))
                      },
                      onError: () => {
                        toastOnError(t('form.configNotUpdatedError'))
                      }
                    }
                  )
                }}
                editPagePath={
                  row.channelGroupingId != null
                    ? APP_ROUTES.budgetOptimiser.editConfigPage({ configId: `${row.id}` })
                    : APP_ROUTES.centralApp.budgetOptimiser.editConfigPage({
                        configId: `${row.id}`
                      })
                }
              />
            )
          }
        }
      }}
    />
  )
}

export default StartPage
