import { Autocomplete, TextField } from '@mui/material'
import { styled } from '../../../utils'
import { Theme } from '../../theme'

export const StyledAutocompleteInput = styled(Autocomplete)(({ theme }) => ({
  width: '100%'
}))

export const StyledTextField = styled(TextField)(({ theme }: { theme?: Theme }) => ({
  fieldset: {
    height: '32px',
    lineHeight: '32px'
  },
  '.MuiInputBase-root': {
    gap: '4px'
  },
  '.MuiInput-root': {
    padding: 0,
    '&:before': {
      borderBottom: 'none'
    },
    '&:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: 'none'
    },
    '&:after': {
      borderBottom: 'none'
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      input: {
        color: theme?.palette.secondary.main,
        '-webkitTextFillColor': 'inherit'
      }
    },
    '&.Mui-disabled:before': {
      borderBottomStyle: 'none'
    },
    '.MuiInput-input': {
      padding: 0,
      height: '32px'
    }
  },
  'input::placeholder': {
    color: theme?.palette.neutrals.stone120,
    fontSize: theme?.typography.body1.fontSize,
    opacity: 1
  }
}))
