import React from 'react'
import Grid from '../../Grid'
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  YAxisProps,
  XAxisProps,
  BarChart as RechartsBarChart,
  Bar,
  Tooltip,
  LabelList
} from 'recharts'
import { BarChartProps, CustomTooltipProps, LabelProps, BarChartData } from './types'
import { useTheme } from '@mui/material/styles'

import typography from '../../theme/typography'

const CustomBarLabel = (
  props: Partial<LabelProps>,
  data: BarChartData[],
  containerWidth: string | number
): React.ReactElement => {
  const theme = useTheme()
  const barChartPadding = 10

  const x = typeof props.x === 'number' ? props.x : 0
  const y = typeof props.y === 'number' ? props.y : 0
  const width = typeof props.width === 'number' ? props.width : 100
  const height = typeof props.height === 'number' ? props.height : 20
  const index = typeof props.index === 'number' ? props.index : 0

  let text: string = data[index].y as string

  let textWidth = calculateTextWidth(text, `${typography.htmlFontSize}px ${typography.fontFamily}`)
  const availableSpace = (containerWidth as number) - width

  if (availableSpace < textWidth) {
    const labelX = x + width - barChartPadding
    const labelY = y + height / 2

    while (textWidth > width - barChartPadding) {
      text = text.slice(0, -1)
      const newText = text + '...'
      textWidth = calculateTextWidth(newText, `${typography.htmlFontSize}px ${typography.fontFamily}`)
      if (textWidth <= width) {
        const finalText = text.slice(0, -3) + '...'
        text = finalText
        break
      }
    }

    return (
      <text x={labelX} y={labelY} fill={theme.palette.neutrals.white0} textAnchor="end" dominantBaseline="middle">
        {text}
      </text>
    )
  } else {
    const labelX = width + barChartPadding
    const labelY = y + height / 2

    return (
      <text x={labelX} y={labelY} fill={theme.palette.secondary.main} textAnchor="start" dominantBaseline="middle">
        {text}
      </text>
    )
  }
}

const calculateTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (context === null) {
    throw new Error('Could not get canvas context')
  }
  context.font = font
  const measurement = context.measureText(text)
  return Math.ceil(measurement.width)
}

const BarChart = ({
  containerWidth = '100%',
  containerHeight = '100%',
  layout = 'horizontal',
  internalLabels = false,
  barRadius = 0,
  overflow = false,
  data,
  xAxis,
  yAxis,
  getConfigByDataKey,
  renderTooltip,
  cursor,
  ...rest
}: BarChartProps): React.ReactElement => {
  const theme = useTheme()

  const defaultXAxisProps = {
    tickLine: false,
    axisLine: { stroke: theme.palette.neutrals.stone100 },
    tick: { fontWeight: theme.typography.fontWeightBold },
    stroke: theme.palette.secondary.main
  }

  const defaultYAxisProps: YAxisProps = {
    orientation: 'right',
    axisLine: false,
    tickLine: false,
    tick: { textAnchor: 'end' },
    stroke: theme.palette.secondary.main
  }

  const defaultXAxisPropsVertical: XAxisProps = {
    tickLine: false,
    axisLine: { stroke: theme.palette.neutrals.stone100 },
    tick: { fontWeight: theme.typography.fontWeightBold },
    stroke: theme.palette.secondary.main,
    type: 'number'
  }

  const defaultYAxisPropsVertical: YAxisProps = {
    orientation: 'left',
    axisLine: false,
    tickLine: false,
    stroke: theme.palette.secondary.main,
    type: 'category'
  }

  const defaultXLabelPropsVertical = {
    position: 'bottom',
    offset: 25,
    stroke: theme.palette.secondary.main,
    style: { textAnchor: 'end' }
  }

  const defaultYLabelProps = {
    position: 'top',
    offset: 25,
    stroke: theme.palette.secondary.main,
    style: { textAnchor: 'end' },
    dx: 10
  }

  const defaultBarProps = {
    barColor: theme.palette.primary.main
  }

  const yDataKeys = Object.keys(data[0]).filter((key) => key !== 'x')
  const xDataKeys = Object.keys(data[0]).filter((key) => key !== 'y')

  const CustomTooltip = ({ active = false, payload }: CustomTooltipProps): React.ReactElement | null => {
    if (active && payload != null && payload?.length > 0 && renderTooltip != null) {
      return renderTooltip({ dataKey: payload[0].dataKey, payload: payload[0].payload })
    }

    return null
  }

  const overflowHeight = data.length * 50

  return (
    <>
      {layout === 'horizontal' ? (
        <ResponsiveContainer width={containerWidth} height={containerHeight}>
          <RechartsBarChart data={data} {...rest} layout={layout}>
            <XAxis
              dataKey="x"
              padding={{ right: 30 }}
              interval={0}
              {...{
                ...defaultXAxisProps,
                ...xAxis
              }}
            />
            <YAxis
              {...{
                ...defaultYAxisProps,
                ...yAxis,
                label: {
                  ...defaultYLabelProps,
                  ...yAxis?.label
                }
              }}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={cursor === undefined ? { fill: theme.palette.neutrals.stone100, fillOpacity: 0.5 } : cursor}
            />
            {yDataKeys.map((dataKey, index) => {
              const barWithDefaults = { ...defaultBarProps, ...getConfigByDataKey?.(dataKey) }
              const { barColor, ...barProps } = barWithDefaults
              return <Bar key={index} dataKey={dataKey} fill={barColor} {...barProps} />
            })}
          </RechartsBarChart>
        </ResponsiveContainer>
      ) : (
        <Grid style={{ height: containerHeight, width: containerWidth, overflowY: overflow ? 'auto' : 'visible' }}>
          <ResponsiveContainer width={containerWidth} height={overflow ? overflowHeight : containerHeight}>
            <RechartsBarChart data={data} {...rest} layout={layout}>
              <YAxis
                dataKey="y"
                {...{
                  ...defaultYAxisPropsVertical,
                  ...yAxis
                }}
              />
              <XAxis
                {...{
                  ...defaultXAxisPropsVertical,
                  ...xAxis,
                  label: {
                    ...defaultXLabelPropsVertical,
                    ...xAxis?.label
                  }
                }}
              />
              {xDataKeys.map((dataKey, index) => {
                const barWithDefaults = { ...defaultBarProps, ...getConfigByDataKey?.(dataKey) }
                const { barColor, ...barProps } = barWithDefaults

                return internalLabels ? (
                  <Bar
                    key={index}
                    dataKey={dataKey}
                    fill={barColor}
                    {...barProps}
                    radius={[0, barRadius, barRadius, 0]}
                  >
                    <LabelList content={(props) => CustomBarLabel(props, data, containerWidth)} />
                  </Bar>
                ) : (
                  <Bar key={index} dataKey={dataKey} fill={barColor} {...barProps} />
                )
              })}

              <Tooltip
                content={<CustomTooltip />}
                cursor={cursor === undefined ? { fill: theme.palette.neutrals.stone100, fillOpacity: 0.5 } : cursor}
              />
            </RechartsBarChart>
          </ResponsiveContainer>
        </Grid>
      )}
    </>
  )
}
export default BarChart
