import { useMutation, type UseMutationResult, useQuery, type UseQueryResult, useQueryClient } from 'react-query'
import { enhancedSignals, type fetch, analytics } from 'shared/api'
import { HttpStatusCodes } from 'shared/httpStatus'

export const queryKeys = {
  enhancedSignalsConfigs: 'enhancedSignalsConfigs',
  enhancedSignalsMetadata: 'enhancedSignalsMetadata'
}

export const useQueryEnhancedSignalsConfigs = (
  clientId: string
): UseQueryResult<enhancedSignals.EnhancedSignalsResp[], fetch.ClientError> => {
  return useQuery(
    [queryKeys.enhancedSignalsConfigs, clientId],
    async () => await enhancedSignals.getAllEnhancedSignalsByClient(clientId),
    {
      enabled: clientId !== ''
    }
  )
}

export const useQueryEnhancedSignalsById = (
  clientId: string,
  configId: number,
  isEnabled: boolean = true
): UseQueryResult<enhancedSignals.EnhancedSignalsResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.enhancedSignalsConfigs, clientId, configId],
    async () => await enhancedSignals.getEnhancedSignalById(clientId, configId),
    {
      enabled: isEnabled && clientId !== ''
    }
  )
}

export const useMutaionCreateEnhancedSignals = (): UseMutationResult<
  enhancedSignals.EnhancedSignalsResp,
  fetch.ClientError,
  enhancedSignals.EnhancedSignalsReq
> => {
  return useMutation(
    async (data: enhancedSignals.EnhancedSignalsReq) => await enhancedSignals.createEnhancedSignals(data)
  )
}

export const useMutationUpdateEnhancedSignals = (): UseMutationResult<
  enhancedSignals.EnhancedSignalsResp,
  fetch.ClientError,
  enhancedSignals.EditEnhancedSignalsReq
> => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: enhancedSignals.EditEnhancedSignalsReq) => await enhancedSignals.updateEnhancedSignals(data),
    {
      onSettled: () => {
        void queryClient.invalidateQueries(queryKeys.enhancedSignalsConfigs)
      }
    }
  )
}

export const useMutationDeleteEnhancedSignals = (): UseMutationResult<
  enhancedSignals.EnhancedSignalsResp,
  fetch.ClientError,
  enhancedSignals.ConfigIdEnhancedSignalsReq
> => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: enhancedSignals.ConfigIdEnhancedSignalsReq) => await enhancedSignals.deleteEnhancedSignals(data),
    {
      onSettled: () => {
        void queryClient.invalidateQueries(queryKeys.enhancedSignalsConfigs)
      }
    }
  )
}

export const useMutationCreateSignedUrl = (): UseMutationResult<
  string,
  fetch.ClientError,
  enhancedSignals.CreateSignedUrlReq
> => {
  return useMutation(async (data: enhancedSignals.CreateSignedUrlReq) => await enhancedSignals.createSignedUrl(data))
}

export const useMutationUploadFile = (): UseMutationResult<
  string,
  fetch.ClientError,
  enhancedSignals.UploadFileReq
> => {
  const queryClient = useQueryClient()
  return useMutation(async (data: enhancedSignals.UploadFileReq) => await enhancedSignals.uploadFile(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.enhancedSignalsMetadata)
    }
  })
}

export const useMutationDownloadFile = (): UseMutationResult<
  string,
  fetch.ClientError,
  enhancedSignals.DownloadFileReq
> => {
  return useMutation(async (data: enhancedSignals.DownloadFileReq) => await enhancedSignals.downloadFile(data))
}

export const useMutationAnalyticsEnhancedSignalsEvents = <T>(): UseMutationResult<
  T[],
  fetch.ClientError,
  analytics.AnalyticsReq
> => {
  return useMutation(analytics.postEnhancedSignalsEvents<T>)
}

export const useQueryLookupFileMetadata = (
  clientId: string,
  configId: number,
  fileName: string,
  isEnabled: boolean
): UseQueryResult<enhancedSignals.LookupFileMetadataResp, fetch.ClientError> => {
  const queryClient = useQueryClient()

  return useQuery(
    [queryKeys.enhancedSignalsMetadata, clientId, configId, fileName],
    async () => await enhancedSignals.getLookupFileMetadata(clientId, configId, fileName),
    {
      enabled: clientId !== '' && isEnabled,
      onError: (error) => {
        if (error.status_code === HttpStatusCodes.NOT_FOUND) {
          queryClient.setQueriesData([queryKeys.enhancedSignalsMetadata, clientId, configId, fileName], null)
        }
      }
    }
  )
}
