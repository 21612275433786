import React, { forwardRef } from 'react'
import { StyledCheckbox, StyledCheckboxFrame, StyledCheckboxInside } from './styles'
import { CheckboxProps } from './types'
import { CheckIcon, MinusIcon } from '../index'

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ disabled = false, onChange, checked, indeterminate = false, ...props }, ref): React.ReactElement => {
    return (
      <StyledCheckbox
        icon={
          <StyledCheckboxFrame>
            {' '}
            <CheckIcon />
          </StyledCheckboxFrame>
        }
        checkedIcon={
          <StyledCheckboxInside>
            {' '}
            <CheckIcon />{' '}
          </StyledCheckboxInside>
        }
        indeterminateIcon={
          <StyledCheckboxInside>
            {' '}
            <MinusIcon />{' '}
          </StyledCheckboxInside>
        }
        disabled={disabled}
        onChange={onChange as (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void}
        checked={checked}
        indeterminate={indeterminate}
        {...props}
        ref={ref}
      />
    )
  }
)

export default Checkbox
