import React from 'react'

export const ConnectDataSources = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <g clipPath="url(#clip0_12_755)">
        <path
          d="M8.8 13.8C9.79411 13.8 10.6 12.9941 10.6 12C10.6 11.0059 9.79411 10.2 8.8 10.2C7.80589 10.2 7 11.0059 7 12C7 12.9941 7.80589 13.8 8.8 13.8Z"
          fill="currentColor"
        />
        <path
          d="M15 13.8C15.9941 13.8 16.8 12.9941 16.8 12C16.8 11.0059 15.9941 10.2 15 10.2C14.0059 10.2 13.2 11.0059 13.2 12C13.2 12.9941 14.0059 13.8 15 13.8Z"
          fill="currentColor"
        />
        <path
          d="M11.9 20C16.3183 20 19.9 16.4183 19.9 12C19.9 7.58172 16.3183 4 11.9 4C7.48172 4 3.89999 7.58172 3.89999 12C3.89999 16.4183 7.48172 20 11.9 20Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_755">
          <rect width="18" height="18" fill="white" transform="translate(2.89999 3)" />
        </clipPath>
      </defs>
    </svg>
  )
})
