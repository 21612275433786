import React from 'react'

export const ChannelGroupings = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <g clipPath="url(#clip0_12_795)">
        <path
          d="M19.9 12C19.9 16.4 16.3 20 11.9 20C7.49999 20 3.89999 16.4 3.89999 12H19.9Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7.69998 8H15.9" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M9.69998 4H14" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_12_795">
          <rect width="18" height="18" fill="white" transform="translate(2.89999 3)" />
        </clipPath>
      </defs>
    </svg>
  )
})
