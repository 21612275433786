import { SetupFilterFlyout } from 'attributionModel'
import { EditFlyout, RecommendedActions, ReturnCurvePopup, SwitchConfigFlyout } from 'budgetOptimiser'
import { CreateNewChannelGroupingPopup } from 'channelGrouping'
import {
  AccountSelectionPopup,
  ConnectNewDataSourcePopup,
  OauthWindow,
  SelectDataSourceCredentialsPopup,
  ServiceAccountAccessPopup,
  UserOrServiceAccountSelectionPopup
} from 'dataSource'
import { ConversionEventsFlyout, SwitchReportFlyout, TopCardMetricsFlyout } from 'home'
import { DeleteProfilePopup, ProfileFlyout, UnableToDeleteAccountPopup } from 'profile'
import { CaseStatementBuilderPopup, InputSheetsLinkPopup } from 'reportingSolution'
import DagViewStatusPopup from 'shared/components/DagViewStatusPopup'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import GlobalFilterFlyout from 'shared/components/Filter/FilterFlyout'
import { RecommendationsPopup } from 'recommendations'
import DeletePopup from 'shared/components/DeletePopup'

OverScreen.register('connectNewDataSourcePopup', ConnectNewDataSourcePopup)
OverScreen.register('serviceAccountAccessPopup', ServiceAccountAccessPopup)
OverScreen.register('userOrServiceAccountSelectionPopup', UserOrServiceAccountSelectionPopup)
OverScreen.register('accountSelectionPopup', AccountSelectionPopup)
OverScreen.register('selectDataSourceCredentialsPopup', SelectDataSourceCredentialsPopup)
OverScreen.register('unableToDeleteAccountPopup', UnableToDeleteAccountPopup)
OverScreen.register('deleteAccountPopup', DeleteProfilePopup)
OverScreen.register('budgetOptimiserEditFlyout', EditFlyout)
OverScreen.register('recommendedActionsFlyout', RecommendedActions)
OverScreen.register('budgetOptimiserSwitchConfigFlyout', SwitchConfigFlyout)
OverScreen.register('profileFlyout', ProfileFlyout)
OverScreen.register('switchReportHomeFlyout', SwitchReportFlyout)
OverScreen.register('returnCurvePopup', ReturnCurvePopup)
OverScreen.register('attributionFilterFlyout', SetupFilterFlyout)
OverScreen.register('inputSheetsLinkPopup', InputSheetsLinkPopup)
OverScreen.register('createNewChannelGroupingPopup', CreateNewChannelGroupingPopup)
OverScreen.register('dagViewStatusPopup', DagViewStatusPopup)
OverScreen.register('oauthWindow', OauthWindow)
OverScreen.register('globalFilterFlyout', GlobalFilterFlyout)
OverScreen.register('topCardMetricsFlyout', TopCardMetricsFlyout)
OverScreen.register('conversionEventsFlyout', ConversionEventsFlyout)
OverScreen.register('recommendationsPopup', RecommendationsPopup)
OverScreen.register('deletePopup', DeletePopup)
OverScreen.register('caseStatementBuilderPopup', CaseStatementBuilderPopup)

export { useOverScreen }
export default OverScreen

export * from './types'
