import React from 'react'

export const Comment = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M6.52541 17.6751L7.21796 18.3965C7.61602 18.0143 7.62929 17.382 7.24763 16.9834L6.52541 17.6751ZM3.95529 20.1425L3.26274 19.4212C2.9683 19.7039 2.87521 20.1371 3.02756 20.5158C3.17991 20.8945 3.54712 21.1425 3.95529 21.1425V20.1425ZM12.2823 20.1425L12.2849 19.1425H12.2823V20.1425ZM19.3039 12.1426C19.3039 16.0086 16.1699 19.1426 12.3039 19.1426V21.1426C17.2745 21.1426 21.3039 17.1131 21.3039 12.1426H19.3039ZM12.3039 5.14258C16.1699 5.14258 19.3039 8.27659 19.3039 12.1426H21.3039C21.3039 7.17202 17.2745 3.14258 12.3039 3.14258V5.14258ZM5.30393 12.1426C5.30393 8.27659 8.43793 5.14258 12.3039 5.14258V3.14258C7.33336 3.14258 3.30393 7.17202 3.30393 12.1426H5.30393ZM7.24763 16.9834C6.04269 15.7253 5.30393 14.0213 5.30393 12.1426H3.30393C3.30393 14.5571 4.25603 16.7513 5.80319 18.3668L7.24763 16.9834ZM4.64784 20.8639L7.21796 18.3965L5.83286 16.9537L3.26274 19.4212L4.64784 20.8639ZM12.2823 19.1425H3.95529V21.1425H12.2823V19.1425ZM12.3039 19.1426C12.2976 19.1426 12.2913 19.1426 12.2849 19.1426L12.2797 21.1425C12.2877 21.1426 12.2958 21.1426 12.3039 21.1426V19.1426Z"
        fill="currentColor"
      />
    </svg>
  )
})
