import Dropdown from '../Dropdown/Dropdown'
import React, { ReactElement, useMemo, useState } from 'react'
import { SingleSelectDropdownItem, SingleSelectDropdownProps } from './types'
import DropdownList from '../Dropdown/DropdownList'
import DropdownItem from '../Dropdown/DropdownItem'
import Radio from '../Radio/Radio'
import { ChevronDownIcon, ChevronUpIcon } from '../Icons'
import { StyledButtonSelectOpener } from './styles'
import DropdownListSubCategory from '../Dropdown/DropdownListSubCategory'
import Grid from '../Grid'
import Divider from '../Divider'

const renderOption = (
  option: SingleSelectDropdownItem,
  isSelected: boolean,
  handleOptionClick: (value: string) => void
): ReactElement => {
  return (
    <DropdownItem
      key={option.value}
      onClick={() => handleOptionClick(option.value)}
      control={<Radio checked={isSelected} />}
      annotation={option.annotation}
      annotationLineType={option.annotationLineType}
      icon={option.icon}
      disabled={option.disabled}
      tooltipProps={option.tooltipProps}
    >
      {option.label}
    </DropdownItem>
  )
}

const SingleSelectDropdown = ({
  title,
  buttonTitle,
  value,
  options,
  onSelect,
  disabled = false,
  buttonWidth = '158px',
  dropdownMinWidth = '297px',
  disablePortal,
  ...props
}: SingleSelectDropdownProps): ReactElement => {
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setDropdownAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setDropdownAnchorEl(null)
  }

  const handleOptionClick = (value: string): void => {
    onSelect(value)
    handleClose()
  }

  const localButtonTitle = useMemo(() => {
    const selectedOption = options.find((option) => option.value === value)
    return selectedOption?.label ?? buttonTitle ?? title
  }, [value, title, options])

  const buttonLeftIcon = useMemo(() => {
    const selectedOption = options.find((option) => option.value === value)
    if (selectedOption?.icon == null) {
      return undefined
    }
    return (
      <Grid display="flex" width="24px" height="24px" alignItems="center" justifyContent="center">
        {selectedOption?.icon}
      </Grid>
    )
  }, [value, options])

  const subCategoryMap = useMemo(() => {
    const map: Record<string, SingleSelectDropdownItem[]> = {}
    options.forEach((option) => {
      if (map[option.subCategory ?? ''] == null) {
        map[option.subCategory ?? ''] = []
      }
      map[option.subCategory ?? ''].push(option)
    })

    if (Object.keys(map).length > 0) {
      return map
    }
  }, [options])

  const isDropdownOpen = !(dropdownAnchorEl == null)

  return (
    <>
      <StyledButtonSelectOpener
        variant="outlined"
        onClick={handleButtonClick}
        leftIcon={buttonLeftIcon}
        rightIcon={isDropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        width={buttonWidth}
        disabled={disabled}
        {...props}
      >
        {localButtonTitle}
      </StyledButtonSelectOpener>
      <Dropdown anchorEl={dropdownAnchorEl} open={isDropdownOpen} onClose={handleClose} disablePortal={disablePortal}>
        <DropdownList dense minWidth={dropdownMinWidth} header={title}>
          {subCategoryMap != null
            ? Object.entries(subCategoryMap).map(([subCategory, categoryOptions], index) => {
                return (
                  <React.Fragment key={subCategory}>
                    {index !== 0 && <Divider />}
                    <DropdownListSubCategory header={subCategory}>
                      {categoryOptions.map((option) => renderOption(option, option.value === value, handleOptionClick))}
                    </DropdownListSubCategory>
                  </React.Fragment>
                )
              })
            : options.map((option) => renderOption(option, option.value === value, handleOptionClick))}
        </DropdownList>
      </Dropdown>
    </>
  )
}

export default SingleSelectDropdown
