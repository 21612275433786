import React from 'react'

export const BarChart = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M14.1165 5.61495H6.50293C6.01968 5.61495 5.62793 6.0067 5.62793 6.48995C5.62793 6.9732 6.01968 7.36495 6.50293 7.36495H14.1165C14.5998 7.36495 14.9915 6.9732 14.9915 6.48995C14.9915 6.0067 14.5998 5.61495 14.1165 5.61495Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9169 16.3662H6.50287C6.01962 16.3662 5.62787 16.7579 5.62787 17.2412C5.62787 17.7244 6.01962 18.1162 6.50287 18.1162H10.9169C11.4002 18.1162 11.7919 17.7244 11.7919 17.2412C11.7919 16.7579 11.4002 16.3662 10.9169 16.3662Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5025 11.125H6.50293C6.01968 11.125 5.62793 11.5168 5.62793 12C5.62793 12.4832 6.01968 12.875 6.50293 12.875H18.5025C18.9858 12.875 19.3775 12.4832 19.3775 12C19.3775 11.5168 18.9858 11.125 18.5025 11.125Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
