import { Tooltip } from '@precis-digital/kurama'
import { type ReactElement } from 'react'

export interface Data {
  x: string
  [key: string]: number | string
}

export interface ChartToolTipProps {
  value: string
  payload: Data
  header: string
  customBody?: never
  title?: never
  width?: string
}

export interface ChartToolTipPropsWithBody {
  value?: never
  payload?: never
  header?: never
  customBody: JSX.Element
  title: string
  width?: string
}

export const ChartToolTip = ({
  value,
  payload,
  header,
  customBody,
  title,
  width = '326px'
}: ChartToolTipProps | ChartToolTipPropsWithBody): ReactElement => (
  <Tooltip
    open
    title={title ?? (payload.x ?? payload.y).toString()}
    kind="multiline"
    placement="top-end"
    width={width}
    body={
      customBody ?? (
        <span>
          {header}: {value}
        </span>
      )
    }
  >
    <span>&nbsp;</span>
  </Tooltip>
)

export default ChartToolTip
