import React from 'react'

export const List = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M18.3 14.875H5.7C4.76112 14.875 4 15.6361 4 16.575V19.175C4 20.1139 4.76112 20.875 5.7 20.875H18.3C19.2389 20.875 20 20.1139 20 19.175V16.575C20 15.6361 19.2389 14.875 18.3 14.875Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3 4.875H5.7C4.76112 4.875 4 5.63612 4 6.575V9.175C4 10.1139 4.76112 10.875 5.7 10.875H18.3C19.2389 10.875 20 10.1139 20 9.175V6.575C20 5.63612 19.2389 4.875 18.3 4.875Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
