import { styled } from '../../utils'
import Typography from '../Typography'
import { NotificationVariant } from './types'
import { Theme } from '@mui/material/styles'

export const StyleLabelContainer = styled(Typography)`
  /* Note: There's no Typography Variant that exactly matches these parameters */
  font-size: 0.75rem;
  line-height: 1.25rem;
  min-height: 20px;

  height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`
export const StyledNotificationContainer = styled('div')(
  ({ variant, theme }: { variant: NotificationVariant; theme?: Theme }): any => ({
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'start', // prevent container from stretching
    height: '20px',
    padding: '0px 8px',
    borderRadius: theme?.borderRadius.xSmall,
    background: variant === 'filled' ? theme?.palette.main.blue90 : theme?.palette.neutrals.stone90,
    color: variant === 'filled' ? theme?.palette.neutrals.white0 : theme?.palette.primary.main
  })
)
