import { Avatar, Button, Divider, Grid, Label, PopUp, Typography, styled } from '@precis-digital/kurama'
import { type MouseEvent, useState } from 'react'
import DeleteInput from 'shared/components/DeleteInput'
import { CONFIRMATION_DELETE_WORD } from 'shared/constants'
import { OverScreen, useOverScreen } from 'shared/overScreens/niceModalReact'
import { StyledCautionCard, StyledCautionText } from 'workspace/components/Popup/styles'

interface DeleteConfigProps {
  id: number
  name: string
  platformIconUrl: string
  onDelete: () => void
}

interface DeletePopupProps {
  deleteConfigProps?: DeleteConfigProps
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}

const DeletePopup = ({ deleteConfigProps, t }: DeletePopupProps): React.ReactElement => {
  const [deleteInputValue, setDeleteInputValue] = useState('')
  const [hasUnderstanding, setHasUnderstanding] = useState(false)
  const hasManuallyConfirmedToDelete = deleteInputValue === CONFIRMATION_DELETE_WORD
  const deleteInputColor = hasManuallyConfirmedToDelete ? 'success' : 'error'

  const canDeleteConfig = hasManuallyConfirmedToDelete && hasUnderstanding

  const handleDeleteConfig = (e: MouseEvent): void => {
    e.stopPropagation()
    deleteConfigProps?.onDelete()
    deleteConfig.remove()
  }

  const deleteConfig = useOverScreen('deletePopup')

  return (
    <PopUp
      open={deleteConfig.visible}
      handleOpen={(): void => {
        deleteConfig.remove()
      }}
    >
      {deleteConfigProps?.platformIconUrl !== undefined && (
        <StyledImg>
          <Avatar imageUrl={deleteConfigProps?.platformIconUrl} kind="image" size="large" />
        </StyledImg>
      )}
      <StyledTitleWrapper>
        <Typography variant="h2"> {t('popups.delete.title', { configName: deleteConfigProps?.name })}</Typography>
      </StyledTitleWrapper>

      <Divider />

      <StyledCautionCard>
        <Label color="orange" text={t('popups.delete.caution')} />
        <StyledCautionText variant="h4">{t('popups.delete.cautionDescription')}</StyledCautionText>
      </StyledCautionCard>

      <DeleteInput
        deleteInputValue={deleteInputValue}
        hasUnderstanding={hasUnderstanding}
        setHasUnderstanding={setHasUnderstanding}
        setDeleteInputValue={setDeleteInputValue}
        confirmDeleteWord={CONFIRMATION_DELETE_WORD}
        deleteInputColor={deleteInputColor}
        translations={t}
      />
      <Divider />
      <StyledPopupFooter display="flex" justifyContent="flex-end" marginTop="24px">
        <Button
          onClick={() => {
            deleteConfig.remove()
          }}
          scheme="light"
          variant="outlined"
        >
          {t('popups.delete.cancelButton')}
        </Button>
        <Button onClick={handleDeleteConfig} scheme="light" variant="semantic-warning" disabled={!canDeleteConfig}>
          {t('popups.delete.deleteButton')}
        </Button>
      </StyledPopupFooter>
    </PopUp>
  )
}

export default OverScreen.create(DeletePopup)

export const StyledPopupFooter = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(1)
}))

export const StyledImg = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

export const StyledTitleWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'center',
  marginTop: '25px',
  '>h2': {
    display: 'flex',
    flexDirection: 'column',
    overflowWrap: 'anywhere',
    '>p': {
      alignSelf: 'center',
      color: theme.palette.main.blue100
    },
    marginBottom: '10px'
  }
}))
