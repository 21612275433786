import React from 'react'
import { HttpStatusCodes } from 'shared/httpStatus'
import logger from 'shared/logger'

const log = logger().child({
  namespace: 'useBuildId'
})

// eslint-disable-next-line no-process-env
const BUILD_ID = process.env.BUILD_ID as string

const useBuildId = (): { shouldReload: () => Promise<boolean> } => {
  const shouldReload = React.useCallback(async (): Promise<boolean> => {
    log.info(BUILD_ID)

    if (typeof window !== 'undefined' && BUILD_ID != null) {
      const response = await fetch(`/_next/static/${BUILD_ID}/_buildManifest.js`, {
        method: 'HEAD',
        headers: {
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
          'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT'
        }
      })

      return response.status === HttpStatusCodes.NOT_FOUND
    }
    return false
  }, [])

  return {
    shouldReload
  }
}

export default useBuildId
