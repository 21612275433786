import { Notification, Tab, Tabs, Typography } from '@precis-digital/kurama'
import { DATA_SOURCE_VIEW_TABS } from 'channelGrouping/constants'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { useState, type ReactElement, type SyntheticEvent, useEffect } from 'react'
import { useChangePath } from 'shared/components/Router'
import { useTranslation } from 'shared/translations'
import { APP_ROUTES } from 'shared/routes'
interface TabsRowProps {
  tabId: string
}

const TabsRow = ({ tabId }: TabsRowProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const { changePath } = useChangePath()
  const { channelGroupingId } = useCurrentChannelGrouping()
  const { dataSource, dataSourceItem } = useCurrentChannelGroupingDataSource()

  const [tabIndex, setTabIndex] = useState(DATA_SOURCE_VIEW_TABS.indexOf(tabId))

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number): void => {
    void changePath(
      `${APP_ROUTES.customGroupings.dataSourcesPage({
        channelGroupingId: channelGroupingId.toString()
      })}/${dataSource}/${DATA_SOURCE_VIEW_TABS[newValue]}`,
      undefined,
      true
    )
    setTabIndex(newValue)
  }

  useEffect(() => {
    setTabIndex(DATA_SOURCE_VIEW_TABS.indexOf(tabId))
  }, [tabId])

  return (
    <Tabs currentTab={tabIndex} handleChange={handleTabChange} variant="standard">
      <Tab value={0} adornmentPosition="right">
        <Typography variant="h5">{t(`dataSourceFormView.tabs.channels`)}</Typography>
        <Notification variant="tonal" value={dataSourceItem.channels.length.toString()} />
      </Tab>
      <Tab value={1}>
        <Typography variant="h5">{t(`dataSourceFormView.tabs.distribution`)}</Typography>
      </Tab>
      <Tab value={2}>
        <Typography variant="h5">{t(`dataSourceFormView.tabs.inspect-results`)}</Typography>
      </Tab>
    </Tabs>
  )
}

export default TabsRow
