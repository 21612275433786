import { useCallback, forwardRef, useState } from 'react'
import Typography from '../Typography'
import Badge from '../Badge'

import { UploadIcon } from '../Icons'
import { useDropzone } from 'react-dropzone'
import { FileMetaData, FileUploadProps, FileRejection, DropEvent } from './types'
import Chip from '../Chip'
import Grid from '../Grid'
import { StyledFileUploadContainer, StyledFileInformation, StyledMessage } from './styles'
import Tooltip from '../Tooltip'

export const FileUpload = forwardRef<HTMLDivElement, FileUploadProps>(
  (
    {
      onUpload,
      onDelete,
      accept = {},
      name,
      placeholder,
      disabled = false,
      disabledFileMetaData = false,
      maxFiles,
      maxSize,
      minSize,
      customValidation,
      mapFileTypeToIcon,
      InputProps,
      TooltipProps,
      helperText,
      multiple = false,
      value,
      ...props
    },
    ref
  ): React.ReactElement => {
    const [files, setFiles] = useState<FileMetaData[]>(value ?? [])

    const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false)
    const onDrop = useCallback((files: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
      setIsPreviewMode(false)
      const mappedFiles = files.map((file) => {
        return {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified.toString()
        }
      })
      setFiles(mappedFiles)
      onUpload(files, rejectedFiles, event)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      onDragEnter: () => setIsPreviewMode(true),
      accept,
      multiple,
      maxFiles,
      maxSize,
      minSize,
      disabled,

      validator: customValidation
    })
    const handleDeleteFile = (currentFile: FileMetaData): void => {
      onDelete?.(currentFile.name)
      setFiles(files.filter((f) => f.name !== currentFile.name))
    }
    const shouldShowPlaceholder = files.length === 0 && !isPreviewMode
    const shouldShowHelperText = helperText != null && files.length > 0
    return (
      <>
        <StyledFileUploadContainer
          {...getRootProps()}
          disabled={disabled}
          isPreviewMode={isPreviewMode}
          isError={shouldShowHelperText}
          {...props}
          ref={ref}
        >
          <input name={name} {...getInputProps()} {...InputProps} />
          {shouldShowPlaceholder ? (
            <Grid container justifyContent="center">
              <UploadIcon />
              <Typography variant="h5">{placeholder}</Typography>
            </Grid>
          ) : (
            <Grid container gap={2}>
              {files.map((file) =>
                disabledFileMetaData ? (
                  <Chip
                    label={file.name.toLocaleString()}
                    key={file.name}
                    onDelete={() => {
                      handleDeleteFile(file)
                    }}
                    avatar={mapFileTypeToIcon?.(file.type.toString())}
                  />
                ) : (
                  <Tooltip
                    kind="multiline"
                    body={
                      <StyledFileInformation>
                        <li>Name: {file.name}</li>
                        <li>Size: {mapBytesToSize(Number(file.size))}</li>
                        <li>Type: {file.type}</li>
                        <li>Last Modified: {formatTimestamp(Number(file.lastModified))}</li>
                      </StyledFileInformation>
                    }
                    title="File Information"
                    key={`${file.name}-${file.lastModified}`}
                    {...TooltipProps}
                  >
                    <Grid>
                      <Chip
                        label={file.name.toLocaleString()}
                        key={file.name}
                        onDelete={() => {
                          handleDeleteFile(file)
                        }}
                        avatar={mapFileTypeToIcon?.(file.type.toString())}
                      />
                    </Grid>
                  </Tooltip>
                )
              )}
            </Grid>
          )}
        </StyledFileUploadContainer>
        {shouldShowHelperText && (
          <StyledMessage>
            <Badge color="error" />
            <Typography variant="body3">{helperText}</Typography>
          </StyledMessage>
        )}
      </>
    )
  }
)

export const mapBytesToSize = (bytes: number): string => {
  const fileSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 Byte'
  }
  const index = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${Math.round(bytes / Math.pow(1024, index))} ${fileSizes[index]}`
}

export const formatTimestamp = (lastModified: number): string => {
  const date = new Date(lastModified)
  if (isNaN(date.getTime())) {
    return 'Invalid date'
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export default FileUpload
