import { AccordionSummary, AccordionDetails } from '@mui/material'
import { AccordionProps } from './types'
import { StyledAccordion } from './styles'
import { forwardRef } from 'react'

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  ({ expandIcon, onChange, Summary, Details, expanded, disabled = false, ...props }, ref): React.ReactElement => {
    return (
      <StyledAccordion {...props} ref={ref} onChange={onChange} expanded={expanded} disabled={disabled}>
        <AccordionSummary expandIcon={expandIcon}>{Summary}</AccordionSummary>
        <AccordionDetails>{Details}</AccordionDetails>
      </StyledAccordion>
    )
  }
)

export default Accordion
