import { Grid, styled, Typography, AreaChart, type Theme, defaultTheme } from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'
import NumberFormat from 'shared/components/NumberFormat'
import Block from 'shared/components/Block'
import PercentageChip from 'shared/components/PercentageChip'
import { useCurrentClient } from 'shared/context/ClientContext'
import NumberSummaryTooltip from 'shared/components/NumberSummaryTooltip'
import { type ProgressOverTimeRecords } from './ProgressOvertime'
import { objectKeys } from 'shared/utils'
import toolsIcon from 'public/assets/images/tools.svg'
import Image from 'next/image'
import { type ChannelGroupingSummaryData } from 'home/transformations/channelGrouping'
import { type ReportingBaseSummaryData } from 'home/transformations/reportingBase'
import { type Metric } from 'home/reportTemplateParameters'
import { COSTPERACTION } from 'attributionModel/constants'

export type SummaryData = ChannelGroupingSummaryData | ReportingBaseSummaryData
interface SummaryBlockProps {
  startDate: string
  endDate: string
  summaryData: SummaryData | null
  summaryDataForPreviousTimePeriod: SummaryData | null
  progressOverTimeRecords: Partial<Record<Extract<keyof SummaryData, Metric>, ProgressOverTimeRecords>>
  previousTimePeriod: [string, string]
  errorBody?: React.ReactNode
}

const Summary = ({
  startDate,
  endDate,
  progressOverTimeRecords,
  summaryData,
  summaryDataForPreviousTimePeriod,
  previousTimePeriod,
  errorBody
}: SummaryBlockProps): React.ReactElement => {
  const { t } = useTranslation('home')

  const {
    currentClient: { currency: targetCurrencyCode }
  } = useCurrentClient()

  return (
    <Grid container aria-label="performace summary" spacing={2} marginTop="6px" marginBottom="16px">
      {objectKeys(progressOverTimeRecords).map((metric) => {
        const progressOverTime = progressOverTimeRecords[metric]
        const reverseColorScheme = metric === COSTPERACTION
        return (
          <Grid
            item
            xs
            minWidth={objectKeys(progressOverTimeRecords).length % 3 === 1 ? '50%' : '33.333%'}
            key={metric}
          >
            <StyledBlock marginTop="0px">
              <Typography variant="h5">{t(metric)}</Typography>
              {progressOverTime != null && progressOverTime.length > 0 ? (
                <>
                  <Grid width="99%" height="60px" marginBottom="12px" marginTop="20px">
                    <AreaChart
                      getConfigByDataKey={(dataKey) => {
                        return { hasGradient: true }
                      }}
                      data={progressOverTime}
                      xAxis={{
                        hide: true
                      }}
                      yAxis={{
                        hide: true
                      }}
                    />
                  </Grid>
                  {summaryData != null && summaryDataForPreviousTimePeriod != null && (
                    <StyledNumberWrapper>
                      <Typography variant="h3" style={{ flex: ' 1 1 0', display: 'flex' }}>
                        <NumberFormat
                          value={summaryData[metric]}
                          currencyCode={targetCurrencyCode}
                          id={metric}
                          compact
                        />
                      </Typography>

                      <NumberSummaryTooltip
                        presentValue={summaryData[metric]}
                        previousValue={summaryDataForPreviousTimePeriod[metric]}
                        previousTimePeriod={previousTimePeriod}
                        timePeriod={[startDate, endDate]}
                        currencyCode={targetCurrencyCode}
                        id={metric}
                        title={t(metric)}
                        reverseColorScheme={reverseColorScheme}
                      >
                        <PercentageChip
                          presentValue={summaryData[metric]}
                          previousValue={summaryDataForPreviousTimePeriod[metric]}
                          reverseColorScheme={reverseColorScheme}
                        />
                      </NumberSummaryTooltip>
                    </StyledNumberWrapper>
                  )}
                </>
              ) : (
                <StyledGrid
                  container
                  direction="column"
                  padding={defaultTheme.spacing(4)}
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Image src={toolsIcon} alt="Tools" width={40} height={40} />
                  {errorBody ?? <Typography variant="h5">{t('noReportDataAvailable')}</Typography>}
                </StyledGrid>
              )}
            </StyledBlock>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Summary

const StyledNumberWrapper = styled(Grid)(() => ({
  display: 'flex',
  width: '100%',
  h3: {
    marginTop: '0.15rem'
  }
}))

const StyledGrid = styled(Grid)<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.stone90,
  borderRadius: theme.borderRadius.medium,
  marginTop: theme.spacing(4),
  '& > img': {
    marginBottom: theme.spacing(3)
  }
}))

const StyledBlock = styled(Block)(({ theme }) => ({
  marginBottom: 0,
  height: '100%'
}))
