import { type GetHomePageData } from 'home/hooks/useHomePageData'
import { type TransformToTimeSeriesByMetricAndBreakdownFn } from 'home/transformations'
import {
  type ChannelGroupingPerformanceReport,
  type TransformToChannelGroupingSummaryFn,
  type TransformToChannelGroupingTimeSeriesFn,
  type TransformToDetailedChannelGroupingDataFn
} from 'home/transformations/channelGrouping'
import {
  type ReportingBaseReport,
  type TransformToDetailedReportingBaseDataFn,
  type TransformToReportingBaseSummaryFn,
  type TransformToReportingBaseTimeSeriesFn
} from 'home/transformations/reportingBase'
import {
  type AnalyticsReqField,
  type ChannelGroupingPerformanceStandardReportResp,
  type ReportingBaseReportResp
} from 'shared/api/analytics'
import { type ReportingBaseColumnProps, type ReportingBaseMetric, reportingBaseParameters } from './reportingBase'
import {
  type ChannelGroupingColumnProps,
  type ChannelGroupingMetric,
  channelGroupingParameters
} from './channelGrouping'
import { type MultiSelectDropdownItem } from '@precis-digital/kurama'
import { type ReportTemplateId } from 'shared/api/standardReports'

export type Metric = ReportingBaseMetric | ChannelGroupingMetric

export interface ReportParameters {
  reportAnalyticsVariables: GetHomePageData['reportAnalyticsVariables']
  marketFieldVariableName: string
  channelFieldVariableName: string
  totalsVariables: GetHomePageData['totalVariables']
  filterVariables: {
    fields: AnalyticsReqField[]
    groupBy: string[]
  }
  conversionEventVariables?: {
    fields: AnalyticsReqField[]
    groupBy: string[]
  }
  customDataFilters?: GetHomePageData['customFilters']
  transformations: {
    transformToReport:
      | ((records: ReportingBaseReportResp[]) => ReportingBaseReport[])
      | ((records: ChannelGroupingPerformanceStandardReportResp[]) => ChannelGroupingPerformanceReport[])
    transformToDetailedReportData: TransformToDetailedChannelGroupingDataFn | TransformToDetailedReportingBaseDataFn
    transformToSummary: TransformToReportingBaseSummaryFn | TransformToChannelGroupingSummaryFn
    transformToTimeSeries: TransformToReportingBaseTimeSeriesFn | TransformToChannelGroupingTimeSeriesFn
    transformToTimeSeriesByMetricAndBreakdown:
      | TransformToTimeSeriesByMetricAndBreakdownFn<ChannelGroupingPerformanceReport, ChannelGroupingMetric>
      | TransformToTimeSeriesByMetricAndBreakdownFn<ReportingBaseReport, ReportingBaseMetric>
  }
  metrics: readonly ReportingBaseMetric[] | ChannelGroupingMetric[]
  getSelectedConversionEvents?: (reportId: string) => MultiSelectDropdownItem[] | undefined
  storeSelectedConversionEvents?: (reportId: string, selectedConversionEvents: MultiSelectDropdownItem[]) => void
  getSelectedTopCardMetrics: () => Metric[]
  localStorageKeyForMetrics: string
  metricsToPreviousMetricsMap:
    | Record<ChannelGroupingMetric, keyof ChannelGroupingColumnProps>
    | Record<ReportingBaseMetric, keyof ReportingBaseColumnProps>
}

export const reportsTypeParametersMap: Partial<Record<ReportTemplateId, ReportParameters>> = {
  reporting_base: reportingBaseParameters,
  channel_grouping_performance_report_v2: channelGroupingParameters
}
