import { type ClientAccountsResp, type Platforms } from 'shared/api/accounts'
import { type ChannelGroupingsResp } from 'shared/api/channelGroupings'

const hasAllPreRequisites = (...preRequisites: Array<boolean | null>): boolean => {
  for (const preRequisite of preRequisites) {
    if (preRequisite === false) {
      return false
    }
  }
  return true
}

export const hasAccountsWithSupportedPlatforms = (
  accounts: ClientAccountsResp[] | undefined,
  platformsSupported: Platforms[]
): boolean => {
  return (accounts ?? []).filter((account) => platformsSupported.includes(account.platform)).length > 0
}

interface UseHasPreRequisitesProps {
  gcpAccount?: ClientAccountsResp[]
  channelGroupings?: ChannelGroupingsResp
  accounts?: boolean
  analyticsAccount?: boolean
}

interface UseHasPreRequisitesReturn {
  hasGcpAccount: boolean | null
  hasDataSourceAccount: boolean | null
  hasCustomGrouping: boolean | null
  hasAnalyticsAccount: boolean | null
  hasAllPreRequisites: boolean
}

export const hasPreRequisites = ({
  gcpAccount,
  channelGroupings,
  accounts,
  analyticsAccount
}: UseHasPreRequisitesProps): UseHasPreRequisitesReturn => {
  const hasGcpAccount = gcpAccount == null ? null : gcpAccount !== undefined && gcpAccount?.length > 0
  const hasCustomGrouping =
    channelGroupings == null
      ? null
      : (channelGroupings?.map((channelGrouping) => channelGrouping.channelGrouping) ?? []).length > 0
  const hasDataSourceAccount = accounts ?? null
  const hasAnalyticsAccount = analyticsAccount ?? null
  return {
    hasGcpAccount,
    hasDataSourceAccount,
    hasCustomGrouping,
    hasAnalyticsAccount,
    hasAllPreRequisites: hasAllPreRequisites(hasGcpAccount, hasDataSourceAccount, hasCustomGrouping)
  }
}
