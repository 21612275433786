import { Grid, styled, Typography } from '@precis-digital/kurama'
import React from 'react'
import type { ReactElement } from 'react'
import { type ListViewEmptyStateProps } from './types'
import ListViewCreateNewButton from './CreateNewButton'
import EducationBlock from '../EducationBlock'
import { useAuth } from 'shared/context/AuthContext'

const ListViewEmptyState = ({
  title,
  subtitle1,
  subtitle2,
  educationalBlockProps,
  createNewButtonProps
}: ListViewEmptyStateProps): ReactElement => {
  /**
   * Current user check is only  due to documentation links being internal.
   * This will be removed our documentation is public
   */
  const { currentUser } = useAuth()
  return (
    <StyledGridEmptyStateContainer>
      <StyledGridEmptyStateTextContainer>
        <Grid display="flex" flexDirection="column" gap="16px">
          <Grid>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="body2">{subtitle1}</Typography>
            <Typography variant="body2">{subtitle2}</Typography>
          </Grid>
          {createNewButtonProps != null && <ListViewCreateNewButton {...createNewButtonProps} />}
        </Grid>
      </StyledGridEmptyStateTextContainer>
      {currentUser?.hasInternalFeatureAccess === true && (
        <EducationBlock
          documentationLink={educationalBlockProps.documentationLinkHref}
          t={(key) => {
            switch (key) {
              case 'emptyState.educationalBlock.title':
                return educationalBlockProps.title
              case 'emptyState.educationalBlock.description':
                return educationalBlockProps.description
              case 'emptyState.educationalBlock.linkText':
                return educationalBlockProps.documentationLinkText
              default:
                return ''
            }
          }}
          characterSource={educationalBlockProps.characterSource}
        />
      )}
    </StyledGridEmptyStateContainer>
  )
}

const StyledGridEmptyStateContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}))

const StyledGridEmptyStateTextContainer = styled(Grid)(() => ({
  padding: '24px 0px',
  h2: {
    marginBottom: '8px'
  }
}))

export default ListViewEmptyState
