import {
  Divider,
  DropdownItem,
  DropdownList,
  PauseIcon,
  PlayIcon,
  type TooltipProps,
  TrashIcon
} from '@precis-digital/kurama'
import { type ClientAccountsWithMetadata } from 'dataSource/components/StartPage'
import { useTranslation } from 'shared/translations'
import { useDeleteClientAccounts, useQueryClientCredentials, useUpdateRecommendationsForAccount } from 'dataSource/api'
import { makeErrorToast, makeSuccessToast } from 'shared/components/Toaster'
import { useCurrentClient } from 'shared/context/ClientContext'
import OverScreen from 'shared/overScreens'
import Image from 'next/image'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'
import { useFetchAccountCredentials, useOpenDataSourceAuthenticationModal } from 'dataSource/hooks'
import { useAuth } from 'shared/context/AuthContext'
import { PLATFORMS, type IPlatform } from 'dataSource/constants'
import React, { useState } from 'react'

interface MoreMenuDropDownProps {
  clientAccount: ClientAccountsWithMetadata
  hasEditorAccess?: boolean
  closeMenu: () => void
}

const MoreMenuDropDown = ({
  clientAccount: row,
  hasEditorAccess = false,
  closeMenu
}: MoreMenuDropDownProps): React.ReactElement => {
  const { t } = useTranslation('dataSource')
  const { t: tCommon } = useTranslation('common')
  const { mutateAsync: updateRecommendationsEngineActiveForAccount } = useUpdateRecommendationsForAccount()
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()
  const { mutate: deleteClientAccounts } = useDeleteClientAccounts(currentClient.id)
  const {
    data: credentials,
    isSuccess: isCredentialsSuccess,
    isError: isCredentialError,
    refetch
  } = useQueryClientCredentials(currentUser?.id as string, row?.platform ?? '')
  const [selectedPlatform, setSelectedPlatform] = useState<IPlatform | null>(null)
  useFetchAccountCredentials(selectedPlatform ?? null, refetch)
  useOpenDataSourceAuthenticationModal(
    isCredentialsSuccess,
    selectedPlatform,
    credentials,
    setSelectedPlatform,
    false,
    true,
    row.id
  )
  const handleToggleRecommendations = (accountId: number, accountName: string, enable: boolean): void => {
    try {
      const successMessage = enable
        ? t('listTable.toasters.recommendationsEnabledToaster', { accountName })
        : t('listTable.toasters.recommendationsPausedToaster', { accountName })

      void updateRecommendationsEngineActiveForAccount({
        accountId,
        recommendationsEngineActive: enable,
        clientId: currentClient.id
      })

      makeSuccessToast(successMessage)
    } catch (error) {
      makeErrorToast(t('listTable.toasters.unexpectedError'))
    }
  }
  const handleReconnectClick = (): void => {
    const selectedPlatform = PLATFORMS.find((item) => {
      return item.platform === row.platform
    })
    setSelectedPlatform(selectedPlatform ?? null)
  }

  if (selectedPlatform !== null && (isCredentialsSuccess || isCredentialError)) {
    closeMenu()
  }
  const platformMeta = getPlatformDetailsByPlatform(row.platform)
  const disabledPropsBasedOnUserAccess: {
    disabled: boolean
    tooltipProps?: TooltipProps
  } = {
    disabled: !hasEditorAccess,
    ...(!hasEditorAccess && { tooltipProps: { kind: 'singleline', title: tCommon('noPermissionMessage') } })
  }

  const handleDeleteAccount = (e: MouseEvent): void => {
    deleteClientAccounts(row.id)
  }

  const { iconUrl } = getPlatformDetailsByPlatform(row.platform)

  const platformIcon = (
    <Image width={24} height={24} src={platformMeta.commonIconUrl} alt={`${platformMeta.commonIconUrl} icon`} />
  )
  return (
    <DropdownList minWidth="300">
      <>
        <DropdownItem onClick={handleReconnectClick} icon={platformIcon}>
          {t('more.reconnect')}
        </DropdownItem>
        {platformMeta?.supportsRecommendationsEngine ? (
          <>
            {row.recommendationsEngineActive ? (
              <DropdownItem
                onClick={() => {
                  handleToggleRecommendations(row.id, row.name, false)
                  closeMenu()
                }}
                {...disabledPropsBasedOnUserAccess}
                icon={<PauseIcon />}
              >
                {t('more.disableRecommendations')}
              </DropdownItem>
            ) : (
              <DropdownItem
                onClick={() => {
                  handleToggleRecommendations(row.id, row.name, true)
                  closeMenu()
                }}
                {...disabledPropsBasedOnUserAccess}
                icon={<PlayIcon />}
              >
                {t('more.enableRecommendations')}
              </DropdownItem>
            )}
          </>
        ) : null}
        <Divider />
        <DropdownItem
          onClick={() => {
            closeMenu()
            void OverScreen.show('deletePopup', {
              deleteConfigProps: {
                id: row.id,
                name: row.name,
                platformIconUrl: iconUrl,
                onDelete: handleDeleteAccount
              },
              t
            })
          }}
          {...disabledPropsBasedOnUserAccess}
          icon={<TrashIcon />}
        >
          {t('more.delete')}
        </DropdownItem>
      </>
    </DropdownList>
  )
}

export default MoreMenuDropDown
