import { Grid, Skeleton } from '@precis-digital/kurama'
import { Fragment, forwardRef } from 'react'
import NoRecommendations from 'recommendations/components/NoRecommendations'

interface RecommendationFrameProps {
  header: React.ReactNode | undefined | null
  actions: React.ReactNode | undefined | null
  content: React.ReactNode[] | undefined | null
}

export const RecommendationFrame = forwardRef<HTMLDivElement, RecommendationFrameProps>(function Frame(
  { header, actions, content },
  ref
): React.ReactElement {
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="flex-center" marginBottom="8px">
        {header != null && header}
      </Grid>
      <Grid container marginTop="-8px" marginRight="-8px" justifyContent="flex-end">
        {actions != null && actions}
      </Grid>
      <Grid container justifyContent="space-between" alignItems="flex-start" ref={ref} overflow="auto">
        {Array.isArray(content) ? (
          content.length > 0 ? (
            content.map((element, index) => {
              return <Fragment key={index}>{element}</Fragment>
            })
          ) : (
            <NoRecommendations />
          )
        ) : (
          <ContentSkeleton />
        )}
      </Grid>
    </>
  )
})

export const ContentSkeleton = (): React.ReactElement => {
  return (
    <Grid container flexDirection="column" gap="16px">
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <Skeleton key={index} height="126px" data-testid="content-skeleton-loader" />
        ))}
    </Grid>
  )
}

export default RecommendationFrame
