import React, { ReactElement } from 'react'
import { AvatarGroupProps } from './types'
import { AVATAR_GROUP_CLASS_NAME } from '../classNames'
import { StyledGridAvatarGroupRoot } from './styles'
import Avatar from '../Avatar'

const AvatarGroup = React.forwardRef<HTMLDivElement, AvatarGroupProps>(
  ({ max = 3, size = 'medium', children, ...props }, ref): ReactElement => {
    const childrenArray = React.Children.toArray(children)
    const numberOfChildren = childrenArray.length

    const maxAvatars = Math.min(numberOfChildren, max)
    const extraAvatars = Math.max(numberOfChildren - max, numberOfChildren - maxAvatars, 0)
    const hasExtraAvatars = extraAvatars > 0

    return (
      <StyledGridAvatarGroupRoot
        hasExtraAvatars={hasExtraAvatars}
        className={AVATAR_GROUP_CLASS_NAME}
        max={max}
        size={size}
        numberOfChildren={numberOfChildren}
        {...props}
        ref={ref}
      >
        {childrenArray.slice(0, maxAvatars)}
        {hasExtraAvatars && <Avatar size={size} number={`+${extraAvatars}`} kind="number" />}
      </StyledGridAvatarGroupRoot>
    )
  }
)

export default AvatarGroup
