import React from 'react'

export const Folder = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M21 18.8C21 19.2774 20.8104 19.7353 20.4728 20.0728C20.1352 20.4104 19.6774 20.6 19.2 20.6H4.8C4.32261 20.6 3.86477 20.4104 3.52721 20.0728C3.18964 19.7353 3 19.2774 3 18.8V6.20002C3 5.72263 3.18964 5.2648 3.52721 4.92723C3.86477 4.58967 4.32261 4.40002 4.8 4.40002H9.3L11.1 7.10002H19.2C19.6774 7.10002 20.1352 7.28967 20.4728 7.62723C20.8104 7.9648 21 8.42263 21 8.90002V18.8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
