export const get = (key: string): string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key)
  }
  return null
}

export const store = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value)
  }
}

export const remove = (key: string): void => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key)
  }
}

export const removeAll = (): void => {
  if (typeof window !== 'undefined') {
    localStorage.clear()
  }
}

export const LOCAL_STORAGE_KEYS = {
  PRECIS_APPS_GRID_VIEW_PREFERENCE: 'precisAppsGridViewPreference',
  MIMIR_MODEL_TYPE_PREFERENCE: 'mimirModelTypePreference',
  SIDE_NAVIGATION_STATE: 'sideNavigationState',
  CHANNEL_GROUPING_METRIC_SELECTION: 'channelGroupingMetricSelection',
  REPORTING_BASE_HOME_PAGE_SUMMARY_CARDS_METRIC_SELECTION: 'reportingBaseHomePageSummaryCardsMetricSelection',
  REPORTING_BASE_HOME_PAGE_CONVERSION_EVENTS_SELECTION: (reportId: string) =>
    `reportingBaseHomePageConversionEventsSelection_${reportId}`,
  CHANNEL_GROUPING_HOME_PAGE_SUMMARY_CARDS_METRIC_SELECTION: 'channelGroupingHomePageSummaryCardsMetricSelection',
  SELECTED_REPORT_ID_FOR_CLIENT_HOMEPAGE: (clientId: string): string => `selectedReportIdForClientHomepage_${clientId}`,
  HOME_PAGE_COMPARISON_PERIOD_SELECTION: (reportId: string) => `homePageComparisonPeriodSelection_${reportId}`
}
