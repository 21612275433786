import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownListSubCategory,
  FilterIcon,
  Grid,
  Checkbox,
  styled,
  Radio,
  Badge,
  SortDescendingIcon,
  SortAscendingIcon
} from '@precis-digital/kurama'
import { getPlatformDetailsByPlatform } from 'dataSource'
import { useState } from 'react'
import { type MutationFunction, type RecommendationActionsProps, type SortByTypes } from 'recommendations/types'
import {
  convertPlatformName,
  getFeed,
  getFilterByQuery,
  getSeverityChipStyles,
  getSortByQuery
} from 'recommendations/utils'
import { type Filter, type FeedPlatforms, type AnalyticsReqOrderBy } from 'shared/api/analytics'
import Image from 'next/image'
import SortIcon from 'public/assets/images/sort_icon.png'
import { useFilterByPlatform } from 'recommendations/hooks'
import { useQueryPlatformList } from 'recommendations/api'
import { useTranslation } from 'shared/translations'

export const RecommendationActions = ({
  fetchFeed,
  currentClient,
  disabled,
  customFilters
}: RecommendationActionsProps): React.ReactElement => {
  const { t } = useTranslation('recommendation')
  const { data: platformList = [] } = useQueryPlatformList(currentClient.id)
  const [selectedPlatform, setSelectedPlatform] = useFilterByPlatform(platformList)
  const [selectedSortBy, setSelectedSortBy] = useState<SortByTypes>('date_DESC')
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [dropdownFiltersAnchorEl, setDropdownFiltersAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleOpenSortByDropdown = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    event.preventDefault()
    setDropdownAnchorEl(event.currentTarget)
  }
  const handleOpenFilterByDropdown = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    event.preventDefault()
    setDropdownFiltersAnchorEl(event.currentTarget)
  }
  const isDropdownOpen = !(dropdownAnchorEl == null)
  const isDropdownFiltersOpen = !(dropdownFiltersAnchorEl == null)
  const handleCloseSortByDropdown = (): void => {
    setDropdownAnchorEl(null)
  }
  const handleCloseFilterByDropdown = (): void => {
    setDropdownFiltersAnchorEl(null)
  }
  const handleFilterByChange = (platform: FeedPlatforms): void => {
    const newSelectedPlatform = { ...selectedPlatform, [platform]: !selectedPlatform[platform] }
    setSelectedPlatform(newSelectedPlatform)
    const shouldFilterByPlatform = Object.values(selectedPlatform).some((isSelected) => isSelected)
    const options: { filters: Filter[]; orderBy: AnalyticsReqOrderBy } = {
      filters: [],
      orderBy: getSortByQuery(selectedSortBy)
    }
    if (shouldFilterByPlatform) {
      options.filters = getFilterByQuery(newSelectedPlatform)
    }
    getFeed(fetchFeed as MutationFunction, options)(currentClient)
  }
  const handleSortByChange = (sortBy: SortByTypes): void => {
    setSelectedSortBy(sortBy)
    const orderBy = getSortByQuery(sortBy)
    getFeed(fetchFeed as MutationFunction, { orderBy, filters: getFilterByQuery(selectedPlatform) })(currentClient)
  }

  const info = getSeverityChipStyles(1)
  const warning = getSeverityChipStyles(2)
  const critical = getSeverityChipStyles(5)
  return (
    <>
      <Button
        onClick={handleOpenSortByDropdown}
        leftIcon={
          <StyledIconsContainer>
            <Image
              src={SortIcon.src}
              alt="Sort"
              width="24"
              height="24"
              style={{ opacity: disabled === true ? 0.25 : 1 }}
            />
          </StyledIconsContainer>
        }
        variant="text"
        disabled={disabled}
      />
      <Dropdown anchorEl={dropdownAnchorEl} open={isDropdownOpen} onClose={handleCloseSortByDropdown}>
        <DropdownList minWidth="300px" header={t('sortBy.title')} dense>
          <DropdownListSubCategory header="Date">
            <DropdownItem
              onClick={() => {
                handleSortByChange('date_ASC')
              }}
              icon={<SortAscendingIcon />}
              control={<Radio checked={selectedSortBy === 'date_ASC'} />}
            >
              {t('sortBy.asc')}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSortByChange('date_DESC')
              }}
              icon={<SortDescendingIcon />}
              control={<Radio checked={selectedSortBy === 'date_DESC'} />}
            >
              {t('sortBy.desc')}
            </DropdownItem>
          </DropdownListSubCategory>
        </DropdownList>
      </Dropdown>

      <Button onClick={handleOpenFilterByDropdown} leftIcon={<FilterIcon />} variant="text" disabled={disabled} />
      <Dropdown anchorEl={dropdownFiltersAnchorEl} open={isDropdownFiltersOpen} onClose={handleCloseFilterByDropdown}>
        <DropdownList minWidth="300px" header="Filter By" dense>
          <DropdownListSubCategory header="Platform">
            {platformList.length > 0
              ? platformList.map((platform) => {
                  const platformData = getPlatformDetailsByPlatform(convertPlatformName(platform.distinctPlatform))
                  return (
                    <DropdownItem
                      onClick={() => {
                        handleFilterByChange(platform.distinctPlatform)
                      }}
                      control={<Checkbox checked={selectedPlatform[platform.distinctPlatform]} />}
                      key={platform.distinctPlatform}
                      icon={<Avatar size="small" kind="image" imageUrl={platformData?.iconUrl} />}
                    >
                      {platformData.label}
                    </DropdownItem>
                  )
                })
              : null}
          </DropdownListSubCategory>

          {customFilters?.filters.severity != null && (
            <DropdownListSubCategory header="Severity">
              <DropdownItem
                onClick={() => {
                  customFilters?.setFilters({
                    ...customFilters.filters,
                    severity: {
                      ...customFilters.filters.severity,
                      critical: !customFilters.filters.severity.critical
                    }
                  })
                }}
                control={<Checkbox checked={customFilters?.filters.severity.critical} />}
                icon={<Badge color="error" />}
              >
                {critical.label}
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  customFilters?.setFilters({
                    ...customFilters.filters,
                    severity: {
                      ...customFilters.filters.severity,
                      warning: !customFilters.filters.severity.warning
                    }
                  })
                }}
                control={<Checkbox checked={customFilters?.filters.severity.warning} />}
                icon={<Badge color="yellow" />}
              >
                {warning.label}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  customFilters?.setFilters({
                    ...customFilters.filters,
                    severity: {
                      ...customFilters.filters.severity,
                      info: !customFilters.filters.severity.info
                    }
                  })
                }}
                control={<Checkbox checked={customFilters?.filters.severity.info} />}
                icon={<Badge color="info" />}
              >
                {info.label}
              </DropdownItem>
            </DropdownListSubCategory>
          )}
        </DropdownList>
      </Dropdown>
    </>
  )
}

const StyledIconsContainer = styled(Grid)(() => ({
  gap: '0px',
  display: 'flex'
}))

export default RecommendationActions
