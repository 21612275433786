import { Theme, type CSSObject } from '../theme'
import { styled, type color } from '../../utils'
import { TextField } from '@mui/material'

export const StyledInput = styled(TextField)<{ assistive?: boolean; theme?: Theme; color: color; fullWidth?: boolean }>(
  ({ theme, assistive = false, color, fullWidth = false }) => ({
    width: fullWidth ? '100%' : '200px',
    '.MuiOutlinedInput-root': {
      paddingLeft: '12px',
      paddingRight: '8px',
      borderRadius: assistive ? theme?.borderRadius.xLarge : theme?.borderRadius.medium,
      ...(assistive && {
        height: '56px'
      }),
      cursor: 'pointer',
      height: assistive && '56px',
      boxShadow: `0px 0px 0px 1px ${theme?.palette.neutrals.stone100} inset`,
      border: 'none',
      fieldset: {
        border: 'none'
      },
      input: {
        fontFamily: assistive ? theme?.typography.h4.fontFamily : theme?.typography.body2.fontFamily,
        fontSize: assistive ? theme?.typography.h4.fontSize : theme?.typography.body2.fontSize,
        padding: '8px 16px 8px 0px',
        cursor: 'pointer'
      },
      '&:hover': {
        backgroundColor: theme?.palette.neutrals.stone100,
        boxShadow: 'none',
        fieldset: {
          border: 'none'
        }
      },
      '&:focus': {
        backgroundColor: theme?.palette.neutrals.stone100,
        fieldset: {
          border: 'none'
        }
      },
      ...(color === 'success' && {
        ...StyledSuccessInput(theme)
      }),
      ...(color === 'error' && {
        ...StyledErrorInput(theme)
      })
    },
    '.MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: theme?.palette.neutrals.stone100,
      fieldset: {
        border: 'none'
      },
      ...(color === 'success' && {
        ...SuccessInputFocus(theme)
      }),
      ...(color === 'error' && {
        ...ErrorInputFocus(theme)
      })
    },
    '.MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: theme?.palette.neutrals.stone90,
      color: theme?.palette.neutrals.stone120,
      fieldset: {
        border: 'none'
      },
      '.MuiInputAdornment-root': {
        color: theme.palette.neutrals.stone120
      }
    },
    '.MuiInputAdornment-root': {
      color: theme.palette.secondary.main
    }
  })
)

export const StyledMessage = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme?.typography.body3.fontFamily,
    fontSize: theme?.typography.body3.fontSize,
    marginTop: '8px',
    paddingLeft: '6px',
    span: {
      paddingLeft: '6px'
    }
  })
)

export const StyledSuccessInput = (theme?: Theme): CSSObject => ({
  backgroundColor: theme?.palette.neutrals.white0,
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`
  },
  '&:hover': {
    fieldset: {
      border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`,
      boxShadow: `0 0 0 3px ${theme?.palette.semantic.success05 ?? '#E8FDF7'}`
    }
  }
})

export const SuccessInputFocus = (theme?: Theme): CSSObject => ({
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`,
    boxShadow: `0 0 0 3px ${theme?.palette.semantic.success05 ?? '#E8FDF7'}`
  }
})

export const StyledErrorInput = (theme?: Theme): CSSObject => ({
  backgroundColor: theme?.palette.neutrals.white0,
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`
  },
  '&:hover': {
    fieldset: {
      border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`,
      boxShadow: `0 0 0 3px ${theme?.palette.semantic.error05 ?? '#FFF0F5'}`
    }
  }
})

export const ErrorInputFocus = (theme?: Theme): CSSObject => ({
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`,
    boxShadow: `0 0 0 3px ${theme?.palette.semantic.error05 ?? '#FFF0F5'}`
  }
})
