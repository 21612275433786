import { Typography, defaultTheme as theme, styled, Grid, Chip } from '@precis-digital/kurama'
import { type ActiveFilter } from '.'
import { Fragment } from 'react'
import { OPERATOR_OPTIONS } from 'home/constants'
import { type OperatorWithInputType } from 'shared/constants/filterOperators'

const FilterListItemLabel = ({
  filter,
  includeText,
  operatorOptions = OPERATOR_OPTIONS
}: {
  filter: ActiveFilter
  includeText: string
  operatorOptions?: Record<string, OperatorWithInputType>
}): React.ReactElement => {
  const value = filter.data

  let itemLabel: React.ReactElement
  if (Array.isArray(value)) {
    itemLabel = (
      <Grid display="flex" rowGap={theme.spacing(1)} alignItems="center" flexWrap="wrap">
        {value.map((item, index) => {
          const isLastElement = index === value.length - 1
          const isFirstElement = index === 0
          return (
            <Fragment key={item.value}>
              {isLastElement && value.length > 1 && (
                <StyledTypography applyLeftMargin variant="h4">
                  and
                </StyledTypography>
              )}
              {!isFirstElement && !isLastElement && <StyledTypography variant="h4">,</StyledTypography>}
              <StyledListItemChip label={item.label} />
            </Fragment>
          )
        })}
      </Grid>
    )
  } else {
    itemLabel =
      typeof value === 'string' ? <StyledListItemChip label={value} /> : <StyledListItemChip label={value.label} />
  }

  return (
    <>
      <Typography variant="h4">{includeText}</Typography>
      <StyledListItemChip label={filter.parameter.label} />
      <Typography variant="h4">{operatorOptions[filter.operator].label?.toLowerCase()}</Typography>
      {itemLabel}
    </>
  )
}

export default FilterListItemLabel

const StyledListItemChip = styled(Chip)(() => ({
  height: 'auto',
  '& span': {
    whiteSpace: 'normal'
  }
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'applyLeftMargin'
})<{
  applyLeftMargin?: boolean
}>(({ applyLeftMargin }) => ({
  marginRight: '0.2rem',
  ...(applyLeftMargin === true && { marginLeft: '0.2rem' })
}))
