import {
  transformToChannelGroupingTimeSeriesByMetricAndBreakdown,
  transformToDetailedReportData,
  transformToReport,
  transformToSummary,
  transformToTimeSeries
} from 'home/transformations/channelGrouping'
import { LOCAL_STORAGE_KEYS, get as getFromStore } from 'shared/lstore'
import { type ReportParameters } from '.'
import { channelGroupingReportAPIVariables } from 'home/api'

export const channelGroupingMetrics = ['spend', 'conversionValue', 'conversions', 'costPerAction', 'roas'] as const

export type ChannelGroupingMetric = (typeof channelGroupingMetrics)[number]

export interface ChannelGroupingColumnProps {
  channel: string
  market: string
  spend: number
  conversionValue: number
  previousSpend: number
  previousConversionValue: number
  roas: number
  previousRoas: number
  conversions: number
  previousConversions: number
  costPerAction: number
  previousCostPerAction: number
}

export const channelGroupingParameters: ReportParameters = {
  ...channelGroupingReportAPIVariables,
  transformations: {
    transformToReport,
    transformToDetailedReportData,
    transformToSummary,
    transformToTimeSeries,
    transformToTimeSeriesByMetricAndBreakdown: transformToChannelGroupingTimeSeriesByMetricAndBreakdown
  },
  metrics: channelGroupingMetrics,
  getSelectedTopCardMetrics: () => {
    const existingMetricsSelection = getFromStore(
      LOCAL_STORAGE_KEYS.CHANNEL_GROUPING_HOME_PAGE_SUMMARY_CARDS_METRIC_SELECTION
    )
    return existingMetricsSelection != null
      ? JSON.parse(existingMetricsSelection)
      : ['spend', 'conversionValue', 'conversions', 'costPerAction', 'roas']
  },
  localStorageKeyForMetrics: LOCAL_STORAGE_KEYS.CHANNEL_GROUPING_HOME_PAGE_SUMMARY_CARDS_METRIC_SELECTION,
  metricsToPreviousMetricsMap: {
    spend: 'previousSpend',
    conversionValue: 'previousConversionValue',
    roas: 'previousRoas',
    conversions: 'previousConversions',
    costPerAction: 'previousCostPerAction'
  }
}
