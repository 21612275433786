import { ReactElement } from 'react'
import { FlowChartNodeProps } from './types'
import Tooltip from '../../../Tooltip'
import { StyledCard } from './styles'
import defaultTheme from '../../../theme'
import Grid from '../../../Grid'
import { Handle, Position } from 'reactflow'

const FlowChartNode = ({
  data: { customNode, labelIcon, label, statusIcon, statusColor, tooltipContent }
}: FlowChartNodeProps): ReactElement => {
  const ChartNode = (
    <Grid>
      <Handle type="target" position={Position.Left} />
      <StyledCard>
        {customNode ?? (
          <Grid container height="100%" gap="8px">
            <Grid item xs display="flex" alignItems="center" flexWrap="nowrap">
              <Grid display="flex" padding="4px">
                {labelIcon}
              </Grid>
              {label}
            </Grid>

            <Grid
              item
              bgcolor={statusColor}
              justifyContent="center"
              xs={3}
              color={defaultTheme.palette.neutrals.white0}
              alignItems="center"
              display="flex"
              height="100%"
            >
              {statusIcon}
            </Grid>
          </Grid>
        )}
      </StyledCard>
      <Handle type="source" position={Position.Right} />
    </Grid>
  )

  return (
    <>
      {tooltipContent != null ? (
        <Tooltip {...tooltipContent} kind="multiline">
          {ChartNode}
        </Tooltip>
      ) : (
        ChartNode
      )}
    </>
  )
}

export default FlowChartNode
