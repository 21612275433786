import fetch from './fetch'
const client = fetch()

export const paths = {
  standardReports: '/standard_reports'
}

export interface StandardReportsResp extends Array<StandardReportResp> {}

export interface Source {
  account: number | string
  accountId?: string
  dataset?: string
  projectId?: string
  configId?: number
  configName?: string
}

export type SourceType =
  | 'gads'
  | 'facebook'
  | 'bing'
  | 'dv360'
  | 'googleAnalytics'
  | 'ga4'
  | 'googleSearchConsole'
  | 'tiktok'
  | 'snapchat'
  | 'linkedin'
type InputType = SourceType | 'other' | 'ia'

interface DashboardCreationLog {
  createdBy: string
  createdAt: number
}

export type Input = Record<
  string,
  | string
  | number
  | boolean
  | Date
  | undefined
  | {
      value: number
      name: string
      accountId: string
    }
>

export type ReportTemplateId =
  | 'reporting_base'
  | 'channel_grouping_performance_report_v2'
  | 'facebook_visual_creative_report'
  | 'shopping_insight_bestseller_report'
  | 'spend_impact_brand_analysis_report'
  | 'gads_keywords_quality_score'
  | 'pmax_breakdown_report_v2'
  | 'shopping_insight_report_v2'
  | 'pmm_report'
  | 'attribution_comparison_report'
  | 'dv360_performance_report'
  | 'black_friday_base_report'
  | 'arima_plus_report'
  | 'search_synergies_report'

export interface StandardReportResp {
  clientId: number
  configId: number
  createdAt: number
  createdBy: string
  updatedAt: number
  updatedBy: string
  targetAuthAccount: string
  targetProjectId: string
  targetDataset: string
  templateId: ReportTemplateId
  name: string
  sources: { [K in SourceType]?: Source[] }
  inputs: { [K in InputType]?: Input[] }
  runSchedule: boolean
  schedule: string
  dashboardCreationLog: DashboardCreationLog[]
  isPremium: boolean
  connectorConfigs?: number[]
  connectorSources?: { [K in SourceType]?: string[] }
  destinationTable?: string
  transformInputs?: Record<string, string>
}

export type Sources = Record<string, Source[]>
export type Inputs = Record<string, Input[]>
export interface StandardReportReq {
  clientId: number
  targetProjectId: string
  targetDataset: string
  templateId: string
  name: string
  sources: Sources
  inputs: Inputs
  runSchedule: boolean
  schedule: string
  dashboardCreationLog?: DashboardCreationLog[]
  isPremium: boolean
}

export interface ClientConfigIdReq {
  clientId: string
  configId: number
}

export const getStandardReports = async (clientId: string): Promise<StandardReportsResp> => {
  return await client.get<StandardReportsResp>({
    path: `${paths.standardReports}?client_id=${clientId}`
  })
}

export const getStandardReportById = async (clientId: string, configId: number): Promise<StandardReportResp> => {
  return await client.get<StandardReportResp>({
    path: `${paths.standardReports}/${configId}?client_id=${clientId}`
  })
}

export const createStandardReport = async (data: StandardReportReq): Promise<StandardReportResp> => {
  const path = data.runSchedule ? `${paths.standardReports}?run_job_immediately=true` : paths.standardReports
  return await client.post<StandardReportResp>({
    path,
    data
  })
}

export const updateStandardReport = async (data: StandardReportReq, configId: number): Promise<StandardReportResp> => {
  return await client.put<StandardReportResp>({
    path: `${paths.standardReports}/${configId}`,
    data
  })
}

export const runStandardReportConfig = async (data: ClientConfigIdReq): Promise<void> => {
  await client.post({
    path: `${paths.standardReports}/run`,
    data
  })
}

export const deleteStandardReport = async (data: ClientConfigIdReq): Promise<StandardReportResp> => {
  return await client.del<StandardReportResp>({
    path: `${paths.standardReports}/${data.configId}?client_id=${data.clientId}`
  })
}
