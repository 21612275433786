import React from 'react'
import { StyledFlyout, StyledTitle, StyledProfileItem } from 'profile/components/Flyout/styles'
import { Button, Grid, Typography, Divider, CopyIcon } from '@precis-digital/kurama'
import Dots from 'shared/components/Loader/Dots'
import { useQueryUserProfile } from 'profile/api'
import { useAuth } from 'shared/context/AuthContext'
import { useTranslation } from 'next-i18next'
import { StyledAvatarContainer } from 'workspace/components/Flyout/styles'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryMembers } from 'workspace/api'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import { capitalize, copyToClipboard } from 'shared/utils'
import { makeErrorToast, makeSuccessToast } from 'shared/components/Toaster'

interface IFlyoutProps {
  selectedUserId?: string
}
const ExternalProfileFlyout = ({ selectedUserId }: IFlyoutProps): React.ReactElement => {
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()

  const idToFetch = selectedUserId !== undefined ? selectedUserId : currentUser?.id

  const { data: userDetails, isLoading: isUserDetailsLoading } = useQueryUserProfile(
    idToFetch ?? (currentUser?.id as string)
  )
  const { data: members } = useQueryMembers(currentClient?.id)

  const workspaceUser = members?.find((member) => {
    return member.id.toString() === idToFetch?.toString()
  })

  const { t } = useTranslation(['user', 'common'])

  const DEFAULT_VALUES = {
    name: userDetails?.name ?? '',
    email: userDetails?.email ?? '',
    phone: userDetails?.phone ?? '',
    companyName: userDetails?.companyName ?? '',
    jobTitle: userDetails?.jobTitle ?? ''
  }

  const handleCopy = async (text: string, type: string): Promise<void> => {
    await copyToClipboard(text)
      .then(() => {
        makeSuccessToast(t('common:notifications.copyToClipboard', { name: userDetails?.name ?? '', type }))
      })
      .catch(() => {
        makeErrorToast(t('common:notifications.copyToClipboardError'))
      })
  }

  return isUserDetailsLoading ? (
    <Dots />
  ) : (
    <StyledFlyout>
      <StyledAvatarContainer>
        <AvatarWithLabel size="large" user={userDetails} />
        <Typography variant="h2">Hi, I'm {userDetails?.name}</Typography>
        {currentClient !== undefined && (
          <Typography variant="body3">
            {workspaceUser?.role == null
              ? t('user:descriptions.undefinedUser', { workspace: currentClient.name })
              : t('user:descriptions.user', {
                  role: capitalize(workspaceUser?.role ?? ''),
                  workspace: currentClient.name
                })}
          </Typography>
        )}
      </StyledAvatarContainer>
      <Divider />
      <StyledTitle variant="h3">{t('user:titles.about')}</StyledTitle>
      {DEFAULT_VALUES.email !== '' && (
        <>
          <StyledProfileItem>
            <>
              <Grid>
                <Typography variant="body2">{t('user:titles.email')}</Typography>
                <Typography variant="body3">{DEFAULT_VALUES.email}</Typography>
              </Grid>
              <Button
                variant="text"
                onClick={() => {
                  void (async () => {
                    await handleCopy(DEFAULT_VALUES.email, 'email')
                  })()
                }}
              >
                {t('user:buttons.copy')}
                <CopyIcon />
              </Button>
            </>
          </StyledProfileItem>
          <Divider />
        </>
      )}
      {DEFAULT_VALUES.phone !== '' && (
        <>
          <StyledProfileItem>
            <Grid>
              <Typography variant="body2">{t('user:titles.phoneNumber')}</Typography>
              <Typography variant="body3">{DEFAULT_VALUES.phone}</Typography>
            </Grid>
            <Button
              variant="text"
              onClick={() => {
                void (async () => {
                  await handleCopy(DEFAULT_VALUES.phone, 'phone number')
                })()
              }}
            >
              {t('user:buttons.copy')}
              <CopyIcon />
            </Button>
          </StyledProfileItem>
          <Divider />
        </>
      )}
      {DEFAULT_VALUES.companyName !== '' && (
        <>
          <StyledProfileItem>
            <Grid>
              <Typography variant="body2">{t('user:titles.worksAt')}</Typography>
              <Typography variant="body3">{DEFAULT_VALUES.companyName}</Typography>
            </Grid>
          </StyledProfileItem>
          <Divider />
        </>
      )}
      {DEFAULT_VALUES.jobTitle !== '' && (
        <>
          <StyledProfileItem>
            <Grid>
              <Typography variant="body2">{t('user:titles.jobTitle')}</Typography>
              <Typography variant="body3">{DEFAULT_VALUES.jobTitle}</Typography>
            </Grid>
          </StyledProfileItem>
          <Divider />
        </>
      )}
    </StyledFlyout>
  )
}

export default ExternalProfileFlyout
