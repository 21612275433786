import { Grid, styled } from '@precis-digital/kurama'
import React, { type ReactNode, type ReactElement } from 'react'

interface BaseCellProps {
  width?: string | number
  align?: 'left' | 'center' | 'right'
  children: ReactNode
}

const BaseCell = ({ width, align, children }: BaseCellProps): ReactElement => {
  let minWidth: string | number = 0
  if (width != null && typeof width === 'string' && width.includes('px')) {
    // If the width is set as a pixel value, we need to set the minWidth to the same value
    minWidth = width
  }
  return (
    <StyledGridCell width={width} align={align} minWidth={minWidth}>
      {children}
    </StyledGridCell>
  )
}

const StyledGridCell = styled(Grid)(
  ({
    align,
    width,
    minWidth
  }: {
    align?: 'left' | 'center' | 'right'
    width?: string | number
    minWidth: string | number
  }) => ({
    textAlign: align,
    width,
    maxWidth: width,
    minWidth,
    overflow: 'hidden'
  })
)

export default BaseCell
