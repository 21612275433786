import { useState, useRef, useEffect, type ReactElement } from 'react'
import type { CredentialResponse } from 'google-one-tap'
import { Typography, Grid, styled, Link as KuramaLink, Frame2, defaultTheme as theme } from '@precis-digital/kurama'
import { useAuth } from '../../shared/context/AuthContext'
import LoadGsiScript from 'shared/components/LoadGsiScript'
import jwtDecode from 'jwt-decode'
import { useRouter } from 'next/router'
import { type auth } from 'shared/api'
import ProgressBarLoader from 'shared/components/Loader/ProgressBarLoader'
import { useTranslation } from 'shared/translations'
import { Workspace } from 'auth/components/Workspace'
import { getWorkspaceNameFromUrl } from 'auth/utils'
import { isValidReturnToUrl, getQueryParamsAndPathnameFromUrl } from 'shared/utils'
import config from 'shared/config'
import { APP_ROUTES } from 'shared/routes'
import { useChangePath } from 'shared/components/Router'

const StyledOauthButtonContainer = styled(Grid)((): any => ({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center'
})) as any

const StyledContainer = styled(Grid)((): any => ({
  textAlign: 'center'
}))

const StyledSignInDescription = styled(Grid)((): any => ({
  marginTop: '5px'
}))

interface tokenDetails {
  email: string
  picture: string
  given_name: string
  name: string
}

export const SignIn = (): ReactElement => {
  const router = useRouter()
  const refButton = useRef<HTMLSpanElement>(null)
  const { mustAuth, login, isLoading, signInError } = useAuth()
  const { locale } = useRouter()
  const { t } = useTranslation('auth', { keyPrefix: 'signIn' })
  const returnTo = router.query.returnTo as string
  const workspaceName = getWorkspaceNameFromUrl(returnTo)
  // eslint-disable-next-line
  const [_, setMounted] = useState(false)

  useEffect(() => {
    if (refButton.current != null) {
      setMounted(true)
    }
  }, [])

  const handleCredentialResponse = (credentialRes: CredentialResponse): void => {
    const idToken = credentialRes.credential
    const tokenDetails: tokenDetails = jwtDecode(idToken)

    const user: auth.AuthSignInReq = {
      email: tokenDetails.email,
      name: tokenDetails.name,
      idToken,
      mode: 'sign-in',
      idTokenProvider: 'google'
    }

    void login(user)
  }

  const { changePath } = useChangePath()
  if (!mustAuth) {
    if (isValidReturnToUrl(returnTo, config('NEXT_PUBLIC_DASHBOARD_URL') as string)) {
      const { queryParams, pathname } = getQueryParamsAndPathnameFromUrl(
        returnTo,
        config('NEXT_PUBLIC_DASHBOARD_URL') as string
      )
      void changePath(pathname, queryParams)
    } else {
      void changePath(APP_ROUTES.home.basePage)
    }
  }

  return (
    <>
      {isLoading || (!mustAuth && <ProgressBarLoader />)}
      {mustAuth && (
        <Frame2>
          <StyledContainer container>
            <Grid item xs={12} width="100px" display="flex" gap="4px" flexDirection="column" alignItems="center">
              <Typography variant="h2">
                {workspaceName !== null ? t('titleWithWorkspace', { workspaceName }) : t('title')}
              </Typography>
              <Typography variant="body3"> {t('subTitle')}</Typography>
            </Grid>
            {signInError != null && (
              <Grid item xs={12} marginTop={theme.spacing(3)}>
                <Typography color={theme.palette.semantic.error110}> {t('error')}</Typography>
              </Grid>
            )}
            {workspaceName !== null ? <Workspace name={workspaceName} /> : null}
            <StyledOauthButtonContainer item xs={12}>
              <span ref={refButton}>
                {refButton.current != null && (
                  <LoadGsiScript
                    onCredentialResponse={handleCredentialResponse}
                    authId={refButton.current}
                    locale={locale}
                    text="signin_with"
                  />
                )}
              </span>
            </StyledOauthButtonContainer>
            <StyledSignInDescription item xs={12}>
              <Typography variant="body3">
                {t('question')}{' '}
                <KuramaLink variant="body3" href={APP_ROUTES.auth.signUpPage}>
                  {t('toSignUp')}
                </KuramaLink>
              </Typography>
            </StyledSignInDescription>
          </StyledContainer>
        </Frame2>
      )}
    </>
  )
}
export default SignIn
