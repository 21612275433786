import { useMutation, useQuery, type UseMutationResult, type UseQueryResult } from 'react-query'
import { type fetch, analytics, standardReports } from 'shared/api'
import { type CurrencyCodes } from 'shared/constants/currency'
import { queryKeys as reportingSolutionsQueryKeys } from 'reportingSolution/api'

export interface ConfigReq {
  id: string
  targetCurrencyCode: CurrencyCodes
  report: analytics.ReportType
  clientId: string
  filters?: analytics.Filter[]
  fields?: analytics.AnalyticsReqField[]
  groupBy?: string[]
  orderBy?: analytics.AnalyticsReqOrderBy
}

export interface TotalsReq extends ConfigReq {
  orderBy?: analytics.AnalyticsReqOrderBy
}

export const channelGroupingReportAPIVariables = {
  reportAnalyticsVariables: {
    fields: [
      { name: 'transaction_revenue', aggregation: 'SUM' },
      { name: 'transactions', aggregation: 'SUM' },
      { name: 'ad_cost', aggregation: 'SUM' },
      { name: 'date' },
      { name: 'market_channel_grouping' },
      { name: 'custom_channel_grouping' }
    ],
    groupBy: ['date', 'market_channel_grouping', 'custom_channel_grouping']
  },
  marketFieldVariableName: 'market_channel_grouping',
  channelFieldVariableName: 'custom_channel_grouping',
  totalsVariables: {
    fields: [
      { name: 'transaction_revenue', aggregation: 'SUM' },
      { name: 'transactions', aggregation: 'SUM' },
      { name: 'ad_cost', aggregation: 'SUM' }
    ]
  },
  filterVariables: {
    fields: [{ name: 'market_channel_grouping' }, { name: 'custom_channel_grouping' }],
    groupBy: ['market_channel_grouping', 'custom_channel_grouping']
  },
  customDataFilters: [
    {
      field: 'is_combined_data',
      operator: '=',
      value: true
    }
  ]
}

export const reportingBaseAPIVariables = {
  reportAnalyticsVariables: {
    fields: [
      { name: 'conversion_value', aggregation: 'SUM' },
      { name: 'conversions', aggregation: 'SUM' },
      { name: 'cost', aggregation: 'SUM' },
      { name: 'date' },
      { name: 'market_name' },
      { name: 'channel_grouping' },
      { name: 'video_views', aggregation: 'SUM' },
      { name: 'impressions', aggregation: 'SUM' },
      { name: 'clicks', aggregation: 'SUM' },
      { name: 'viewable_impressions', aggregation: 'SUM' },
      { name: 'sessions', aggregation: 'SUM' }
    ],
    groupBy: ['date', 'market_name', 'channel_grouping']
  },
  marketFieldVariableName: 'market_name',
  channelFieldVariableName: 'channel_grouping',
  totalsVariables: {
    fields: [
      { name: 'conversion_value', aggregation: 'SUM' },
      { name: 'conversions', aggregation: 'SUM' },
      { name: 'cost', aggregation: 'SUM' },
      { name: 'video_views', aggregation: 'SUM' },
      { name: 'impressions', aggregation: 'SUM' },
      { name: 'clicks', aggregation: 'SUM' },
      { name: 'viewable_impressions', aggregation: 'SUM' },
      { name: 'sessions', aggregation: 'SUM' }
    ]
  },
  filterVariables: {
    fields: [{ name: 'market_name' }, { name: 'channel_grouping' }],
    groupBy: ['market_name', 'channel_grouping']
  },
  conversionEventVariables: {
    fields: [
      { name: 'conversion_name' },
      { name: 'conversion_source' },
      {
        name: 'conversions',
        aggregation: 'SUM'
      }
    ],
    groupBy: ['conversion_name', 'conversion_source'],
    orderBy: {
      fields: ['conversions'],
      direction: 'DESC'
    }
  }
}

export const useMutationReportAnalytics = <
  T extends
    | analytics.ReportingBaseReportResp[]
    | analytics.ReportingBaseConversionEventResp[]
    | [analytics.ReportingBaseTotalsResp]
    | analytics.ReportingBaseTotalsGroupedByDateResp[]
    | analytics.ChannelGroupingPerformanceTotalsGroupedByDateResp[]
    | [analytics.ChannelGroupingPerformanceTotalsResp]
    | analytics.ReportingBaseTotalsGroupedByDateAndBreakdownResp = analytics.ReportingBaseReportResp[]
>(): UseMutationResult<T, fetch.ClientError, ConfigReq> => {
  return useMutation({
    async mutationFn(variables) {
      return await analytics.postReport({
        collection: 'standard_reports',
        ...variables
      })
    }
  })
}

export const useQueryStandardReports = (
  clientId: string
): UseQueryResult<standardReports.StandardReportsResp, fetch.ClientError> => {
  return useQuery(
    [reportingSolutionsQueryKeys.reportingSolutionsConfigs, clientId],
    async () => await standardReports.getStandardReports(clientId),
    {
      enabled: clientId !== ''
    }
  )
}

export const useMutationStandardReportStatuses = (): UseMutationResult<
  analytics.ConfigStatusResp[],
  fetch.ClientError,
  string
> => {
  return useMutation(
    async (clientId) => await analytics.postConfigStatuses('transform_client_configs_status', clientId, true)
  )
}
