import { getStoredAuthToken } from 'auth'
import toCamelCase from 'camelcase-keys'
import { once } from 'ramda'
import config from 'shared/config'
import { pause } from 'shared/utils'
import toSnakeCase from 'snakecase-keys'

const canMock = config('NEXT_PUBLIC_API_MOCKING') === 'enabled'

if (canMock) {
  require('./mocks')
}

const pauseOnce = once(pause)
const API = config('NEXT_PUBLIC_API_URL') as string

const DEFAULT_SERVER_ERROR_CODE = 500

export interface ClientError {
  message: string
  status_code: number
  code?: string
  traceId?: string
}

class ApiClientError extends Error {
  status_code: number
  code: string | undefined
  message: string

  constructor(error: ClientError) {
    super(error.message)
    this.code = error.code
    this.message = error.message
    this.status_code = error.status_code
  }
}

export interface ClientArgs {
  method: string
  path: string
  data?: object
  token?: string
  rawResponse?: boolean
}

interface CustomClientArgs {
  method: string
  data?: object
  path: string
}
const grabTokenFromStore = (): string | undefined => {
  if (typeof window !== 'undefined') {
    return getStoredAuthToken()
  }
}

const authorizationHeaders = (token: string | undefined): { Authorization?: string } => {
  return token != null ? { Authorization: `Bearer ${token}` } : {}
}

interface TBaseClientArgs {
  path: string
  method: string
  data?: object
  token?: string
  rawResponse?: boolean
}

interface IClient {
  get: <T>(args: Omit<TBaseClientArgs, 'method'>) => Promise<T>
  post: <T>(args: Omit<TBaseClientArgs, 'method'>) => Promise<T>
  patch: <T>(args: Omit<TBaseClientArgs, 'method'>) => Promise<T>
  put: <T>(args: Omit<TBaseClientArgs, 'method'>) => Promise<T>
  del: <T>(args: Omit<TBaseClientArgs, 'method'>) => Promise<T>
}

type IGetCustomArgs = (args: TBaseClientArgs) => {
  headers: object
  data: object | null
  path: string
  responseHandler?: {
    json: <T>(json: T) => T
    text: <T>(text: T) => T
    blob: <T>(blob: T) => T
  }
}
export function baseClient(config: globalThis.RequestInit, getCustomArgs?: IGetCustomArgs): IClient {
  const api = async <DataType>(args: TBaseClientArgs): Promise<DataType> => {
    if (canMock) {
      // Give enough time to get msw to register workers and handlers
      await pauseOnce(1000)
    }
    const customArgs = getCustomArgs?.(args)
    const body = customArgs?.data != null ? JSON.stringify({ ...customArgs.data }) : (args.data as BodyInit)
    const headers = { ...config?.headers, ...customArgs?.headers }
    const response = await fetch(customArgs?.path ?? args.path, {
      method: args.method,
      ...{ headers },
      ...(args.data != null
        ? {
            body
          }
        : { body: null })
    }).catch((error) => {
      throw new ApiClientError({
        message: error?.message,
        status_code: DEFAULT_SERVER_ERROR_CODE
      })
    })
    switch (response.headers.get('content-type')) {
      case 'application/json': {
        const json = await response.json()
        if (json.error != null) {
          throw new ApiClientError({
            message: json.error?.message,
            code: json.error?.code,
            status_code: response.status
          })
        }
        return customArgs?.responseHandler?.json<DataType>(json) ?? json
      }
      case 'application/octet-stream': {
        const blob = (await response.blob()) as unknown as DataType
        return customArgs?.responseHandler?.blob(blob) ?? blob
      }
      default: {
        const text = (await response.text()) as unknown as DataType
        return customArgs?.responseHandler?.text(text) ?? text
      }
    }
  }

  return {
    get: async <T>(args: Omit<CustomClientArgs, 'method'>) => await api<T>({ ...args, method: 'GET' }),
    post: async <T>(args: Omit<CustomClientArgs, 'method'>) => await api<T>({ ...args, method: 'POST' }),
    patch: async <T>(args: Omit<CustomClientArgs, 'method'>) => await api<T>({ ...args, method: 'PATCH' }),
    put: async <T>(args: Omit<CustomClientArgs, 'method'>) => await api<T>({ ...args, method: 'PUT' }),
    del: async <T>(args: Omit<CustomClientArgs, 'method'>) => await api<T>({ ...args, method: 'DELETE' })
  }
}

export default function client(config?: globalThis.RequestInit): IClient {
  const defaultConfig = {
    headers: { 'content-type': 'application/json' }
  }
  const baseConfig = {
    ...defaultConfig,
    ...config
  }

  interface TGetCustomArgs {
    headers: object
    data: object | null
    path: string
    responseHandler?: any
  }
  const getCustomArgs = (args: ClientArgs): TGetCustomArgs => {
    const token = args.token != null ? args.token : grabTokenFromStore()
    return {
      path: `${API}${args.path}`,
      headers: { ...defaultConfig.headers, ...authorizationHeaders(token) },
      data: args?.data != null ? { ...toSnakeCase(args.data, { deep: true }) } : null,
      responseHandler: {
        json: (response: any) => {
          // Return raw response if flag is true
          if (args.rawResponse === true) {
            return toCamelCase(response, { deep: true })
          }
          // Default behavior: return only the data part
          return toCamelCase(response.data, { deep: true })
        }
      }
    }
  }

  return baseClient(baseConfig, getCustomArgs)
}
