import { PLATFORM } from 'dataSource/constants'

export const ERROR_CODES = {
  [PLATFORM.MICROSOFT_ADVERTISING]: {
    ERR_NO_AD_ACCOUNT: {
      errorMessage: 'Authentication failed. Either supplied credentials are invalid or the account is inactive',
      key: 'microsoft_ad_no_ad_account_found'
    }
  },
  ERR_OAUTH: 'failed_to_login'
} as const
