import { Chip, Grid, Link, Skeleton, Typography, styled } from '@precis-digital/kurama'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { ChatMessageRole, MIMIR_AGENT_OPTIONS } from 'mimir/constants'
import { AgentChatAvatar, UserChatAvatar } from './ChatAvatar'
import { type ReactElement } from 'react'
import { type ChatMessage as TChatMessage } from 'mimir/types'
import { getDocumentSourceTypeMeta } from 'mimir/utils'

interface ChatMessageProps {
  message: TChatMessage
  size?: 'small' | 'large'
  isLoading?: boolean
  renderLinks?: boolean
}

const ChatMessage = ({
  message,
  size = 'large',
  isLoading = false,
  renderLinks = true
}: ChatMessageProps): ReactElement => {
  const isAgent = message.role === ChatMessageRole.assistant
  const isSystem = message.role === ChatMessageRole.system
  const avatarSize = size === 'small' ? 'small' : 'medium'

  const avatarComponent = isAgent ? (
    <AgentChatAvatar agentId={message.agentId} size={avatarSize} />
  ) : isSystem ? (
    <></>
  ) : (
    <UserChatAvatar imageUrl={message?.avatarUrl} size={avatarSize} />
  )

  const parsedMessage = message.text.replace(/(?<!\n)\n(?!\n)/g, '  \n')

  const agentName = isAgent ? MIMIR_AGENT_OPTIONS.find((agent) => agent.id === message.agentId)?.name : undefined

  return (
    <StyledGridChatMessageContainer isAgent={isAgent}>
      <StyledGridChatAvatarContainer size={size}>
        {isLoading ? (
          <StyledSkeletonAvatar height={40} variant="rounded">
            {avatarComponent}
          </StyledSkeletonAvatar>
        ) : agentName != null && agentName !== 'Mimir' ? (
          <Grid textAlign="center">
            {avatarComponent}
            <StyledTypographyAnnotation variant="body3">{agentName.replace('Mimir ', '')}</StyledTypographyAnnotation>
          </Grid>
        ) : (
          avatarComponent
        )}
      </StyledGridChatAvatarContainer>
      <StyledGridChatBubble>
        {isLoading ? (
          Array.from({ length: size === 'small' ? 2 : 3 }).map((val, index) => {
            return <StyledSkeletonBody2TextLine height={size === 'small' ? 18 : 24} key={index} variant="rounded" />
          })
        ) : (
          <Typography variant={size === 'small' ? 'body3' : 'body2'}>
            <ReactMarkdown
              components={{
                code({ node, inline, className, children, style, ref, ...props }) {
                  const safeClassName = className === undefined ? '' : className
                  const match = /language-(\w+)/.exec(safeClassName)
                  return inline === undefined && match !== null ? (
                    <SyntaxHighlighter
                      {...props}
                      language={match[1]}
                      PreTag="div"
                      customStyle={{ fontSize: '0.75rem' }}
                    >
                      {String(children).replace(/\n$/, '')}
                    </SyntaxHighlighter>
                  ) : (
                    <code {...props} className={className}>
                      {children}
                    </code>
                  )
                },
                a({ node, className, children, style, ref, ...props }) {
                  return (
                    <Link {...props} className={className} target="_blank" rel="noopener noreferrer">
                      <Typography variant={size === 'small' ? 'body3' : 'body2'}>{children}</Typography>
                    </Link>
                  )
                },
                ul({ node, className, children, style, ref, ...props }) {
                  return (
                    <ul {...props} className={className} style={{ paddingInlineStart: '16px' }}>
                      {children}
                    </ul>
                  )
                }
              }}
            >
              {parsedMessage}
            </ReactMarkdown>
          </Typography>
        )}
        {isAgent && renderLinks && message?.relatedDocuments != null && (
          <Grid container columnGap="8px" rowGap="8px" paddingBottom="8px">
            {message?.relatedDocuments.map((doc) => {
              const documentSourceTypeMeta = getDocumentSourceTypeMeta(doc)
              return (
                <a href={doc.uri} target="_blank" rel="noreferrer" key={doc.id}>
                  <Chip
                    key={doc.id}
                    avatarSrc={documentSourceTypeMeta.imageUrl}
                    label={
                      (documentSourceTypeMeta.sourceCategoryParser != null
                        ? `${documentSourceTypeMeta.sourceCategoryParser(doc.sourceCategory) + ' > '}`
                        : '') + doc.title
                    }
                    onClick={() => {}}
                  />
                </a>
              )
            })}
          </Grid>
        )}
      </StyledGridChatBubble>
    </StyledGridChatMessageContainer>
  )
}

const StyledSkeletonAvatar = styled(Skeleton)(() => ({
  '& > .kurama-Avatar': {
    visibility: 'initial',
    opacity: 0.5
  },
  '& div[kind="image"]': {
    visibility: 'initial',
    opacity: 0.5
  },
  span: {
    display: 'none'
  },
  borderRadius: '16px'
}))

const StyledSkeletonBody2TextLine = styled(Skeleton)(({ theme }) => ({
  margin: '14px 0px',
  backgroundColor: theme.palette.neutrals.stone100
}))

export const StyledGridChatMessageContainer = styled(Grid)<{ isAgent: boolean }>(({ theme, isAgent }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '0.5rem',
  borderRadius: '24px',
  backgroundColor: isAgent ? theme.palette.neutrals.stone90 : theme.palette.neutrals.white0
}))

export const StyledGridChatAvatarContainer = styled(Grid)<{ size: 'small' | 'large' }>(({ size }) => ({
  width: size === 'small' ? '24px' : '40px',
  margin: '10px'
}))

export const StyledGridChatBubble = styled(Grid)({
  width: '100%',
  paddingRight: '1rem'
})

const StyledTypographyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150,
  fontStyle: 'italic'
}))

export default ChatMessage
