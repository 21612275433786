import React from 'react'

export const SortDescending = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M8.00015 16.1156L8.00015 7.8327"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1979 13.6745L7.99993 16.116L5.80199 13.6745"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
