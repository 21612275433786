import { StatsigProvider } from 'statsig-react'

interface User {
  userID: string
  custom: {
    clientId: string
    clientName: string
  }
  privateAttributes: {
    email: string
  }
}

export const FeatureFlagProvider = ({
  children,
  user,
  platformKey
}: {
  children: React.ReactNode
  platformKey: string
  user: User
}): React.ReactElement => {
  return (
    <StatsigProvider sdkKey={platformKey} user={user}>
      {children}
    </StatsigProvider>
  )
}
