import { useMutation, useQuery, type UseMutationResult, type UseQueryResult } from 'react-query'
import { type fetch, mimir } from 'shared/api'

export const useMutationQuestionMimir = (): UseMutationResult<
  mimir.MimirResponse,
  fetch.ClientError,
  mimir.MimirReq
> => {
  return useMutation(mimir.postQuestion, { useErrorBoundary: false, retry: 1 })
}

export const useQueryMimirSearch = (
  props: mimir.MimirAgentSearchRequest
): UseQueryResult<mimir.MimirAgentSearchResponse, fetch.ClientError> => {
  return useQuery(
    ['mimirAgentSearch', props.clientId, props.agentId, props.query],
    async () => await mimir.agentSearch(props),
    {
      enabled: props.clientId !== '' && props.agentId !== '' && props.query !== '',
      refetchOnWindowFocus: false,
      cacheTime: Infinity
    }
  )
}

export const useMutationMimirAgentChat = (): UseMutationResult<
  mimir.MimirAgentChatResponse,
  fetch.ClientError,
  mimir.MimirAgentChatRequest
> => {
  return useMutation(mimir.agentChat, { useErrorBoundary: false, retry: 0 })
}
