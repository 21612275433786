import React, { forwardRef, ReactElement } from 'react'
import { DROPDOWN_ITEM_CLASS_NAME } from '../classNames'
import {
  StyledGridIconContainer,
  StyledLiDropdownItem,
  StyledGridTopRowContainer,
  StyledTypographyAnnotation,
  StyledGridControlContainer,
  StyledGridLeftContainer,
  StyledTypographyLineClamped
} from './styles'
import { DropdownItemProps } from './types'
import { SingleLineTooltipProps } from '../../Tooltip/types'
import Tooltip from '../../Tooltip'
import Grid from '../../Grid'

const defaultTooltipProps: SingleLineTooltipProps = {
  kind: 'singleline',
  placement: 'right',
  title: undefined,
  body: undefined
}
export const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>(
  (
    {
      onClick,
      icon,
      rightIcon,
      annotation,
      annotationLineType = 'doubleline',
      control,
      tooltipProps,
      disabled = false,
      children,
      ...props
    },
    ref
  ): ReactElement => {
    if (control?.props.onClick != null || control?.props.onChange != null) {
      throw new Error(
        `The passed control cannot have onClick or onChange handlers when used in a DropdownItem. Interactions should be handled only in the DropdownItem onClick handler.`
      )
    }
    const localOnClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
      if (disabled) {
        return
      }
      return onClick(event)
    }
    const clonedControl = control != null && React.cloneElement(control, { disabled })
    const classNames = [DROPDOWN_ITEM_CLASS_NAME]
    if (disabled) {
      classNames.push('disabled')
    }
    const tooltipPropsWithDefault = { ...defaultTooltipProps, ...tooltipProps }
    const BaseLiDropDownItem = (
      <StyledLiDropdownItem
        className={classNames.join(' ')}
        onClick={localOnClick}
        disabled={disabled}
        {...props}
        ref={ref}
      >
        <StyledGridLeftContainer>
          <StyledGridTopRowContainer>
            {icon !== undefined && <StyledGridIconContainer>{icon}</StyledGridIconContainer>}
            {typeof children === 'string' ? (
              <StyledTypographyLineClamped
                disabled={disabled}
                variant={annotation !== undefined ? 'h4' : 'body1'}
                title={tooltipProps == null ? children : undefined}
              >
                {children}
              </StyledTypographyLineClamped>
            ) : (
              children
            )}
            {rightIcon !== undefined && <StyledGridIconContainer>{rightIcon}</StyledGridIconContainer>}
          </StyledGridTopRowContainer>
          {annotation !== undefined && (
            <StyledTypographyAnnotation
              title={annotation}
              variant="body2"
              hasIcon={icon !== undefined}
              annotationLineType={annotationLineType}
            >
              {annotation}
            </StyledTypographyAnnotation>
          )}
        </StyledGridLeftContainer>
        {clonedControl != null && <StyledGridControlContainer>{clonedControl}</StyledGridControlContainer>}
      </StyledLiDropdownItem>
    )
    return tooltipProps == null ? (
      BaseLiDropDownItem
    ) : (
      <Tooltip {...tooltipPropsWithDefault}>
        <Grid display="inline-flex" width="100%">
          {BaseLiDropDownItem}
        </Grid>
      </Tooltip>
    )
  }
)
export default DropdownItem
