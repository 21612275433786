import {
  type CalculateTotalsFn,
  total,
  transformToTimeSeriesByMetricAndBreakdown,
  type TransformToTimeSeriesByMetricAndBreakdownFn
} from 'home/transformations'
import { groupBy, map, sum } from 'ramda'
import {
  type ChannelGroupingPerformanceTotalsResp,
  type ChannelGroupingPerformanceStandardReportResp,
  type ChannelGroupingPerformanceTotalsGroupedByDateResp
} from 'shared/api/analytics'
import { calculateCostPerAction, calculateRoas, defaultToZero } from 'shared/utils'
import { formatDateToString } from 'shared/dateFns'
import { type OriginalRow } from 'shared/reactTable/types'
import { formDataKey } from 'home/components/utils'
import { type ProgressOverTimeRecords } from 'home/components/ProgressOvertime'
import { type ChannelGroupingMetric } from 'home/reportTemplateParameters/channelGrouping'

export interface ChannelGroupingPerformanceReport {
  createdAt: string
  channel: string
  spend: number
  conversionValue: number
  conversions: number
  costPerAction: number
  roas: number
  market: string
}

export const preprocessReportsToConsistentSchema = (
  report: ChannelGroupingPerformanceStandardReportResp
): ChannelGroupingPerformanceReport => {
  const record = {
    conversionValue: defaultToZero(report.sumTransactionRevenue),
    spend: defaultToZero(report.sumAdCost),
    conversions: defaultToZero(report.sumTransactions),
    createdAt: report.date,
    channel: report.customChannelGrouping,
    market: report.marketChannelGrouping
  }
  return {
    ...record,
    costPerAction: defaultToZero(calculateCostPerAction(record.spend, record.conversions)),
    roas: defaultToZero(calculateRoas(record.conversionValue, record.spend))
  }
}

export const transformToReport = (
  records: ChannelGroupingPerformanceStandardReportResp[]
): ChannelGroupingPerformanceReport[] => {
  return records.map(preprocessReportsToConsistentSchema)
}

export type DetailedReport = Omit<ChannelGroupingPerformanceReport, 'createdAt'>

const transformToDetailedReport = (
  records: ChannelGroupingPerformanceReport[],
  groupByParameters: Array<'channel' | 'market'> = ['channel']
): DetailedReport[] => {
  const reports = groupBy((report) => {
    return groupByParameters.map((parameter) => report[parameter]).join('')
  }, records)
  return Object.keys(reports).map((parameterValue: string) => {
    const reportsByParameter = reports[parameterValue]

    const sumRecordsByParameter = total(reportsByParameter)

    const conversionValue = sumRecordsByParameter('conversionValue')
    const spend = sumRecordsByParameter('spend')
    const conversions = sumRecordsByParameter('conversions')

    const costPerAction = calculateCostPerAction(spend, conversions)
    const roas = calculateRoas(conversionValue, spend)

    return {
      spend,
      conversionValue,
      conversions,
      costPerAction,
      roas,
      channel: reportsByParameter[0].channel,
      market: reportsByParameter[0].market
    }
  })
}

export interface ChannelGroupingSummaryData {
  roas: number
  conversions: number
  conversionValue: number
  costPerAction: number
  spend: number
}

export const defaultSummaryData: ChannelGroupingSummaryData = {
  roas: 0,
  conversions: 0,
  conversionValue: 0,
  costPerAction: 0,
  spend: 0
}

export type TransformToChannelGroupingSummaryFn = (
  totalsData: ChannelGroupingPerformanceTotalsResp | undefined
) => ChannelGroupingSummaryData

export const transformToSummary: TransformToChannelGroupingSummaryFn = (totalsData) => {
  if (totalsData == null) {
    return defaultSummaryData
  }

  const conversionValue = defaultToZero(totalsData.sumTransactionRevenue)
  const spend = defaultToZero(totalsData.sumAdCost)
  const conversions = defaultToZero(totalsData.sumTransactions)

  return {
    conversionValue,
    spend,
    conversions,
    costPerAction: calculateCostPerAction(spend, conversions),
    roas: calculateRoas(conversionValue, spend)
  }
}

export type TransformToChannelGroupingTimeSeriesFn = (
  records: ChannelGroupingPerformanceTotalsGroupedByDateResp[],
  metrics: ChannelGroupingMetric[]
) => Record<string, ProgressOverTimeRecords>

export const transformToTimeSeries: TransformToChannelGroupingTimeSeriesFn = (
  records: ChannelGroupingPerformanceTotalsGroupedByDateResp[],
  metrics: ChannelGroupingMetric[]
) => {
  return metrics.reduce<Record<string, ProgressOverTimeRecords>>((acc, metric) => {
    const totalOnReportTypeByDate: Record<string, number> = {}
    records.forEach(({ date, ...record }) => {
      totalOnReportTypeByDate[date] = transformToSummary(record)[metric]
    })

    acc[metric] = map(
      (date) => ({
        x: formatDateToString({ date, targetFormat: 'MMM do' }),
        [metric]: totalOnReportTypeByDate[date]
      }),
      Object.keys(totalOnReportTypeByDate)
    ) as ProgressOverTimeRecords
    return acc
  }, {})
}

export type TransformToDetailedChannelGroupingDataFn = ({
  reports,
  reportsForPreviousTimePeriod,
  selectedDimensions
}: {
  reports: ChannelGroupingPerformanceReport[]
  reportsForPreviousTimePeriod: ChannelGroupingPerformanceReport[]
  selectedDimensions: Array<'channel' | 'market'>
}) => OriginalRow[]

export const transformToDetailedReportData: TransformToDetailedChannelGroupingDataFn = ({
  reports,
  reportsForPreviousTimePeriod,
  selectedDimensions
}) => {
  const detailedReports = transformToDetailedReport(reports, selectedDimensions)
  const detailedReportsForPreviousTimePeriod = transformToDetailedReport(
    reportsForPreviousTimePeriod,
    selectedDimensions
  )

  return detailedReports.map((report) => {
    const previousReport = detailedReportsForPreviousTimePeriod.find((previous) => {
      if (selectedDimensions.length === 1) {
        return report[selectedDimensions[0]] === previous[selectedDimensions[0]]
      }
      return report.channel === previous.channel && report.market === previous.market
    })
    return {
      channel: report.channel,
      market: report.market,
      spend: report.spend,
      conversionValue: report.conversionValue,
      roas: report.roas,
      conversions: report.conversions,
      costPerAction: report.costPerAction,
      previousSpend: defaultToZero(previousReport?.spend),
      previousConversionValue: defaultToZero(previousReport?.conversionValue),
      previousRoas: defaultToZero(previousReport?.roas),
      previousConversions: defaultToZero(previousReport?.conversions),
      previousCostPerAction: defaultToZero(previousReport?.costPerAction)
    }
  })
}

const calculateTotals: CalculateTotalsFn<ChannelGroupingPerformanceReport, ChannelGroupingMetric> = ({
  records,
  metrics,
  market,
  channel
}) => {
  return metrics.reduce<Record<string, number>>((acc, metric) => {
    const filteredRecords = records.filter((record) => {
      const hasMarketOrChannel = market != null || channel != null

      return !hasMarketOrChannel || record.market === market || record.channel === channel
    })

    let totalOnDate = 0

    switch (metric) {
      case 'costPerAction':
        totalOnDate = calculateCostPerAction(
          sum(filteredRecords.map((record) => record.spend)),
          sum(filteredRecords.map((record) => record.conversions))
        )
        break
      case 'roas':
        totalOnDate = calculateRoas(
          sum(filteredRecords.map((record) => record.conversionValue)),
          sum(filteredRecords.map((record) => record.spend))
        )
        break
      default:
        totalOnDate = sum(filteredRecords.map((record) => record[metric]))
    }
    acc[formDataKey({ metric, breakdown: market ?? channel })] = totalOnDate
    return acc
  }, {})
}

export const transformToChannelGroupingTimeSeriesByMetricAndBreakdown: TransformToTimeSeriesByMetricAndBreakdownFn<
  ChannelGroupingPerformanceReport,
  ChannelGroupingMetric
> = ({ records, previousRecords, metrics, channels, markets, daysRange, isComparisonMode, selectedPeriodType }) => {
  return transformToTimeSeriesByMetricAndBreakdown({
    records,
    previousRecords,
    metrics,
    channels,
    markets,
    daysRange,
    isComparisonMode,
    selectedPeriodType,
    calculateTotals
  })
}
