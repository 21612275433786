import React, { type ReactElement } from 'react'
import { useForm } from 'shared/reactHookForm'
import {
  Typography,
  Grid,
  Button,
  Divider,
  styled,
  Frame2,
  Link as KuramaLink,
  LogoutIcon,
  type AutocompleteOptionItem,
  SingleSelectDropdown,
  DotsLoader,
  defaultTheme
} from '@precis-digital/kurama'
import withPageAuthRequired from 'auth/WithPageAuthRequired'
import WorkspaceInput from 'workspace/components/WorkspaceInput'
import { queryKeys as workspaceQueryKeys, useCreateNewClient, useQueryClients } from 'workspace/api'
import { useTranslation } from 'shared/translations'
import { useChangePath } from 'shared/components/Router'
import Link from 'next/link'
import ProgressBarLoader from 'shared/components/Loader/ProgressBarLoader'
import { useAuth } from 'shared/context/AuthContext'
import { useQueryClient } from 'react-query'
import withInternalFeatureAccessCheck from 'shared/withInternalFeatureAccessCheck'
import { APP_ROUTES } from 'shared/routes'
import { currencies, frequentlyUsedCurrencies } from 'shared/constants/currency'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { trimWhitespace } from 'shared/utils'
export enum ButtonVariant {
  filled = 'filled'
}

const REQUIRED_FIELDS = ['name', 'currency']

const DEFAULT_VALUES = {
  name: '',
  currency: 'EUR'
}

export interface FormProps {
  name: string
  url: string
  [key: string]: any
}

export const Workspace = (): ReactElement => {
  const { logOut } = useAuth()
  const { mutate: createNewClient, isLoading: isCreatingNewClient } = useCreateNewClient()
  const { changePath } = useChangePath()
  const { data: clients, isLoading: isLoadingClients } = useQueryClients(true)

  const clientsCount = clients?.length ?? 0

  const { t } = useTranslation('workspace')

  const { control, watch, handleSubmit, setValue } = useForm<FormProps>({
    defaultValues: {
      ...DEFAULT_VALUES
    }
  })

  const getDisabled = (): boolean => {
    const values = watch()
    let res = false

    for (const [key, value] of Object.entries(values)) {
      if (REQUIRED_FIELDS.includes(key) && value.length === 0) {
        res = true
      }
    }

    return res
  }

  const watchCurrency = watch('currency')

  const currencyOptions: AutocompleteOptionItem[] = currencies.map((currency) => ({
    label: `${currency.name} (${currency.code})`,
    value: currency.code,
    subCategory: frequentlyUsedCurrencies.includes(currency.code)
      ? t('descriptions.frequentlyUsedCurrencies')
      : t('descriptions.otherCurrencies')
  }))

  const queryClient = useQueryClient()

  const handleWorkspaceEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !getDisabled()) {
      handleWorkspaceCreateClick()
    }
  }

  const handleWorkspaceCreateClick = (): void => {
    void handleSubmit(async (client) => {
      const { toastOnSuccess, toastOnError } = makeToastWithLoading()
      const newClient = {
        ...client,
        name: trimWhitespace(client.name)
      }
      createNewClient(newClient, {
        onSuccess: async (newClient) => {
          toastOnSuccess(t('workspaceSetup.toasters.createWorkspaceSuccess', { workspaceName: newClient.name }))
          await changePath(`${APP_ROUTES.home.basePage}`, {
            clientId: newClient.id
          }).then(async () => {
            await queryClient.invalidateQueries([workspaceQueryKeys.allClients])
          })
        },
        onError: () => {
          toastOnError(t('workspaceSetup.toasters.createWorkspaceError', { workspaceName: newClient.name }))
        }
      })
    })()
  }

  return isLoadingClients || clients == null ? (
    <ProgressBarLoader />
  ) : (
    <Frame2
      headerRight={
        <Button
          variant="outlined"
          rightIcon={<LogoutIcon />}
          onClick={() => {
            logOut()
          }}
        >
          {t('buttons.logout')}
        </Button>
      }
    >
      <Grid display="flex" flexDirection="column" alignItems="center">
        <Grid maxWidth="600px" display="flex" flexDirection="column" gap="6px" paddingBottom="16px" textAlign="center">
          <Typography variant="h2">{t('workspaceSetup.titles.setUpWorkspace')}</Typography>
          <Typography variant="body1">
            {clientsCount > 0 &&
              (clientsCount > 1 ? (
                <>
                  {t('workspaceSetup.titles.subTitle.fragment1')}
                  <Link href="/workspace/list" passHref legacyBehavior>
                    <KuramaLink>{t('workspaceSetup.titles.subTitle.fragment2', { count: clientsCount })}</KuramaLink>
                  </Link>
                  {t('workspaceSetup.titles.subTitle.fragment3')}
                </>
              ) : (
                <>
                  {t('workspaceSetup.titles.subTitleExactlyOne.fragment1')}
                  <Link href={`/?clientId=${clients?.[0].id}`} passHref>
                    <KuramaLink>{t('workspaceSetup.titles.subTitleExactlyOne.fragment2')}</KuramaLink>
                  </Link>
                  {t('workspaceSetup.titles.subTitleExactlyOne.fragment3')}
                </>
              ))}
          </Typography>
        </Grid>
        <StyledInputsContainer>
          <form>
            <Grid>
              <WorkspaceInput
                name="name"
                labelText="What's your company name?"
                control={control}
                onKeyDown={handleWorkspaceEnterKeyDown}
                helperText="apples"
              />
            </Grid>
            <Grid>
              <Typography variant="h5" style={{ marginBottom: '8px' }}>
                {t('titles.currencyCreateTitle')}
              </Typography>
              <SingleSelectDropdown
                title={t('titles.currencySelectTitle')}
                buttonWidth="100%"
                value={watchCurrency}
                onSelect={(value) => {
                  setValue('currency', value, { shouldDirty: true })
                }}
                options={currencyOptions}
              />
            </Grid>
            <Grid>
              <WorkspaceInput
                name="url"
                labelText="Company website"
                placeholder="https://"
                control={control}
                onKeyDown={handleWorkspaceEnterKeyDown}
              />
            </Grid>
            <Divider />
          </form>
        </StyledInputsContainer>
        <Grid item xs={12} maxWidth="353px" width="100%" paddingTop="3px">
          <Button
            variant={ButtonVariant.filled}
            fullWidth
            onClick={isCreatingNewClient ? () => null : handleWorkspaceCreateClick}
            leftIcon={isCreatingNewClient && <DotsLoader color={defaultTheme.palette.neutrals.white0} />}
            disabled={getDisabled()}
          >
            {!isCreatingNewClient && t('workspaceSetup.buttons.createMyWorkspace')}
          </Button>
        </Grid>
      </Grid>
    </Frame2>
  )
}

const StyledInputsContainer = styled(Grid)((): any => ({
  marginTop: '35px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'column',
  form: {
    '>div': {
      width: '353px',
      textAlign: 'left',
      marginBottom: '32px',
      '&:last-of-type': {
        marginBottom: '0px'
      },
      '.MuiTextField-root': {
        width: '100%'
      }
    }
  }
}))

export default withInternalFeatureAccessCheck(
  withPageAuthRequired(Workspace, {
    returnTo: APP_ROUTES.workspace.setupPage
  })
)
