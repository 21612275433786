import React from 'react'
import {
  LineChart as RechartLineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  YAxisProps,
  Tooltip,
  Legend
} from 'recharts'
import { CustomTooltipProps, LineChartProps, LineConfig } from './types'
import { useTheme } from '@mui/material/styles'

const LineChart = ({
  containerWidth = '100%',
  containerHeight = '100%',
  data,
  xAxis,
  yAxis,
  getConfigByDataKey,
  renderTooltip,
  showLegend = false,
  lineOpacity,
  ...rest
}: LineChartProps): React.ReactElement => {
  const theme = useTheme()

  const defaultLineProps: Pick<LineConfig, 'type' | 'lineWidth' | 'lineColor' | 'lineOpacity'> = {
    type: 'monotone',
    lineWidth: 4,
    lineColor: theme.palette.primary.main,
    lineOpacity: 1
  }

  const defaultXAxisProps = {
    tickLine: false,
    axisLine: { stroke: theme.palette.neutrals.stone100 },
    tick: { fontWeight: theme.typography.fontWeightBold },
    stroke: theme.palette.secondary.main
  }

  const defaultYAxisProps: YAxisProps = {
    orientation: 'right',
    axisLine: false,
    tickLine: false,
    tick: { textAnchor: 'end' },
    stroke: theme.palette.secondary.main
  }

  const defaultYLabelProps = {
    position: 'top',
    offset: 25,
    stroke: theme.palette.secondary.main,
    style: { textAnchor: 'end' },
    dx: 10
  }

  const yDataKeys = Object.keys(data[0]).filter((key) => key !== 'x')

  const CustomTooltip = ({ active = false, payload }: CustomTooltipProps): React.ReactElement | null => {
    if (active && payload != null && payload?.length > 0 && renderTooltip != null) {
      return renderTooltip({ dataKey: payload[0].dataKey, payload: payload[0].payload })
    }

    return null
  }

  const yAxes = Array.isArray(yAxis) ? yAxis : [yAxis]

  return (
    <ResponsiveContainer width={containerWidth} height={containerHeight}>
      <RechartLineChart data={data} {...rest}>
        <XAxis
          dataKey="x"
          minTickGap={24}
          padding={{ right: 30 }}
          {...{
            ...defaultXAxisProps,
            ...xAxis
          }}
        />
        {yDataKeys.map((dataKey, index) => {
          const lineWithDefaults = { ...defaultLineProps, ...getConfigByDataKey?.(dataKey) }
          const { lineColor, lineWidth, lineOpacity, ...lineProps } = lineWithDefaults
          return (
            <Line
              key={index}
              dataKey={dataKey}
              stroke={lineColor}
              strokeWidth={lineWidth}
              opacity={lineOpacity}
              {...lineProps}
              dot={false}
            />
          )
        })}
        {yAxes.map((yAxis) => (
          <YAxis
            key={yAxis?.yAxisId}
            {...{
              ...defaultYAxisProps,
              ...yAxis,
              label: {
                ...defaultYLabelProps,
                ...yAxis?.label
              }
            }}
          />
        ))}
        {renderTooltip != null && <Tooltip content={<CustomTooltip />} />}
        {showLegend ? <Legend /> : null}
      </RechartLineChart>
    </ResponsiveContainer>
  )
}
export default LineChart
