import { SelectItemProps } from './types'
import React, { forwardRef } from 'react'
import { MenuItem } from '@mui/material'

export const SelectItem = forwardRef<HTMLLIElement, SelectItemProps>(
  ({ children, selected, ...props }, ref): React.ReactElement => {
    return (
      <MenuItem selected={selected} ref={ref} {...props}>
        {children}
      </MenuItem>
    )
  }
)
