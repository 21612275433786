import {
  ConditionalBuilder,
  type ConditionalBuilderRowState,
  Grid,
  PopUp,
  styled,
  Accordion,
  ChevronDownIcon,
  Button,
  Typography,
  TrashIcon,
  MoveVerticalIcon,
  Input,
  PlusIcon,
  CopyIcon,
  DownloadIcon
} from '@precis-digital/kurama'
import React, { useState, type ReactElement, useEffect, type SyntheticEvent } from 'react'
import { conditionalBuildersToSqlString, sqlStringToConditionalBuilders } from './utils'
import { type CaseStatementDimension } from 'reportingSolution/constants'
import { type CaseStatementRow } from './types'
import { OPERATORS } from './constants'
import { type Theme } from '@precis-digital/kurama/src/components/theme'
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DropResult,
  type DraggableProvidedDragHandleProps
} from '@hello-pangea/dnd'
import { copyToClipboard, isNilOrEmpty, pasteFromClipboard } from 'shared/utils'
import { makeErrorToast, makeSuccessToast, makeToastWithLoading } from 'shared/components/Toaster'
import { type TFunction } from 'i18next'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'

export interface CaseStatementBuilderPopupProps {
  handleSave: (value: string) => void
  dimensions: CaseStatementDimension[]
  value: string
  title: string
  label: string
  t: TFunction<'reportingSolutions'>
  platform?: string
}

const CaseStatementBuilderPopup = ({
  handleSave,
  value,
  dimensions,
  title,
  label,
  t,
  platform
}: CaseStatementBuilderPopupProps): ReactElement => {
  const screen = useOverScreen('caseStatementBuilderPopup')

  const [valueAsRows, setValueAsRows] = useState<CaseStatementRow[] | undefined>(undefined)

  useEffect(() => {
    try {
      const parsedData = sqlStringToConditionalBuilders({
        sql: value,
        allowedDimensions: dimensions,
        platform,
        t
      })
      setValueAsRows(parsedData)
    } catch (e) {
      makeErrorToast((e as Error).message)
    }
  }, [value, dimensions, platform, t])

  const handleSingleRowSave = (row: CaseStatementRow): void => {
    if (valueAsRows == null) return
    const index = valueAsRows?.findIndex((val) => val.uuid === row.uuid)
    const newValueAsRows = [...valueAsRows]
    newValueAsRows[index] = row
    setValueAsRows(newValueAsRows)
  }

  const handleSingleRowDelete = (row: CaseStatementRow): void => {
    if (valueAsRows == null) return
    const index = valueAsRows?.findIndex((val) => val.uuid === row.uuid)
    const newValueAsRows = [...valueAsRows]
    newValueAsRows.splice(index, 1)
    setValueAsRows(newValueAsRows)
  }

  const addNewChannel = (): void => {
    if (valueAsRows == null) return
    const newChannel: CaseStatementRow = {
      uuid: Math.random().toString(36).substring(7),
      name: t('popups.channelGrouping.channelNumber', { number: valueAsRows.length + 1 }),
      rules: {
        '0': [
          {
            dimension: dimensions[0].value,
            operator: OPERATORS[0].value,
            value: ''
          }
        ]
      }
    }
    setValueAsRows([...valueAsRows, newChannel])
  }

  const onDragEnd = (result: DropResult): void => {
    if (result.destination == null || valueAsRows == null) return

    const items = [...valueAsRows]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setValueAsRows(items)
  }

  const handleSaveAndClosePopup = (): void => {
    handleSave(conditionalBuildersToSqlString({ conditionalBuilderRows: valueAsRows ?? [] }))
    screen.remove()
  }

  return (
    <StyledPopUp handleOpen={handleSaveAndClosePopup} open={screen.visible} title={title}>
      <Grid container gap="10px" justifyContent="flex-end">
        <Button
          variant="text"
          scheme="light"
          disabled={isNilOrEmpty(valueAsRows)}
          onClick={() => {
            void (async () => {
              await copyToClipboard(conditionalBuildersToSqlString({ conditionalBuilderRows: valueAsRows ?? [] }))
                .then(() => {
                  makeSuccessToast(t('notifications.copiedToClipboard'))
                })
                .catch((e) => {
                  makeErrorToast(e.message)
                })
            })()
          }}
          leftIcon={<CopyIcon />}
        >
          {t('buttons.copySqlToClipboard')}
        </Button>
        <Button
          variant="tonal"
          onClick={() => {
            void (async () => {
              const { toastOnError, toastOnSuccess } = makeToastWithLoading()
              const text = await pasteFromClipboard()
              if (isNilOrEmpty(text?.trim())) {
                toastOnError(t('notifications.noDataOnClipboardError'))
              }
              try {
                const parsedData = sqlStringToConditionalBuilders({
                  sql: text,
                  allowedDimensions: dimensions,
                  platform,
                  t
                })
                if (isNilOrEmpty(parsedData)) {
                  toastOnError(t('notifications.noChannelsFoundInDataError'))
                }
                setValueAsRows(parsedData)
                toastOnSuccess(t('notifications.importedSqlSuccess'))
              } catch (e) {
                toastOnError((e as Error).message)
              }
            })()
          }}
          leftIcon={<DownloadIcon />}
        >
          {t('buttons.importSqlFromClipboard')}
        </Button>
      </Grid>
      <Typography variant="body2">
        {valueAsRows != null && valueAsRows.length === 1
          ? t('popups.channelGrouping.groupingContainsOneChannel', { label })
          : t('popups.channelGrouping.groupingContainsChannels', { label, number: valueAsRows?.length })}
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="channels">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              {valueAsRows?.map((val, index) => (
                <Draggable key={val.uuid} draggableId={val.uuid} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <SingleChannelRow
                        index={index}
                        key={val.uuid}
                        row={val}
                        dimensions={dimensions}
                        handleSave={handleSingleRowSave}
                        handleDelete={handleSingleRowDelete}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button fullWidth variant="outlined" onClick={addNewChannel} leftIcon={<PlusIcon />}>
        {t('buttons.addNewChannel')}
      </Button>
      <Button variant="filled" onClick={handleSaveAndClosePopup} style={{ alignSelf: 'flex-end' }}>
        {t('buttons.save')}
      </Button>
    </StyledPopUp>
  )
}

interface SingleChannelRowProps {
  index: number
  row: CaseStatementRow
  dimensions: CaseStatementDimension[]
  handleSave: (row: CaseStatementRow) => void
  handleDelete: (row: CaseStatementRow) => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}

const SingleChannelRow = ({
  index,
  row,
  dimensions,
  handleSave,
  handleDelete,
  dragHandleProps
}: SingleChannelRowProps): ReactElement => {
  const handleLocalSave = (value: Record<string, ConditionalBuilderRowState[]>): void => {
    setIsExpanded(false)
    handleSave({
      ...row,
      rules: value,
      name: localName
    })
  }

  const handleAccordionChange = (_: SyntheticEvent<Element, Event>, expanded: boolean): void => {
    setIsExpanded(expanded)
    if (!expanded && row.name !== localName) {
      handleNameSave()
    }
  }

  const handleNameSave = (): void => {
    handleSave({
      ...row,
      name: localName
    })
    setIsExpanded(false)
  }

  const [localName, setLocalName] = useState<string>(row.name)

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={handleAccordionChange}
      Summary={
        <Grid display="flex" justifyContent="space-between" width="100%">
          <Grid display="flex" gap="16px" alignItems="center">
            <Grid {...dragHandleProps} style={{ cursor: 'grab' }} alignItems="center">
              <MoveVerticalIcon />
            </Grid>
            <Typography variant="body2">{(index + 1).toString().padStart(2, '0')}</Typography>
            {isExpanded ? (
              <Input
                name="temp"
                onClick={(e) => {
                  e.stopPropagation()
                }}
                value={localName}
                onChange={(e) => {
                  setLocalName(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleNameSave()
                  }
                }}
              />
            ) : (
              <Typography variant="h3">{row.name}</Typography>
            )}
          </Grid>
          <Grid display="flex">
            <Button
              variant="text"
              onClick={() => {
                handleDelete(row)
              }}
              leftIcon={<TrashIcon />}
            />
          </Grid>
        </Grid>
      }
      Details={
        <ConditionalBuilder
          value={row.rules}
          onSave={handleLocalSave}
          saveButtonTitle="Save channel"
          dimensionDefinition={{
            label: 'Dimension',
            options: dimensions.map((dimension) => ({
              label: dimension.label,
              value: dimension.value
            })),
            placeholder: 'Select dimension'
          }}
          operatorDefinition={{
            label: 'Operator',
            options: OPERATORS,
            placeholder: 'Select operator'
          }}
          valueDefinition={{
            label: 'Value',
            placeholder: 'Enter value'
          }}
        />
      }
      expandIcon={<ChevronDownIcon />}
    />
  )
}

const StyledPopUp = styled(PopUp)(() => ({
  '> .MuiGrid-root': {
    width: '1128px',
    maxWidth: '90vw',
    '> .MuiGrid-root:not(.MuiGrid-container)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    }
  }
}))

const StyledAccordion = styled(Accordion)(({ theme, expanded }: { theme?: Theme; expanded: boolean }) => ({
  borderRadius: theme?.borderRadius.xLarge,
  outline: `1px solid ${theme?.palette.neutrals.stone100 as string}`,
  boxShadow: expanded ? theme?.elevation.mediumDepth : 'none',
  transition: 'box-shadow 200ms ease-in-out',
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  },
  '.MuiAccordionSummary-root': {
    padding: '20px',
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge,
    '.MuiAccordionSummary-expandIconWrapper': {
      width: '40px',
      justifyContent: 'center',
      color: theme?.palette.secondary.main
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme?.palette.neutrals.white0
    }
  },
  '&.MuiAccordion-root:before': {
    display: 'none'
  },
  '.MuiAccordionDetails-root': {
    paddingTop: 0,
    backgroundColor: theme?.palette.neutrals.white0,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  }
}))

export default OverScreen.create(CaseStatementBuilderPopup)
