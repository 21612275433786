import { type MemberRole } from './clients'
import fetch from './fetch'
const client = fetch()

export const paths = {
  messages: '/messages'
}

type messageStatus = 'pending' | 'revoked' | 'accepted'

export interface MessageResp {
  toEmail: string
  fromEmail: string
  clientId: string
  createdAt: string
  updatedAt: string
  type: string
  status: messageStatus
  id: string
  role: MemberRole
}

export interface MessagesResp extends Array<MessageResp> {}

export const getMessages = async (userId: string): Promise<MessagesResp[]> => {
  return await client.get<MessagesResp[]>({
    path: `${paths.messages}?client_id=${userId}`
  })
}
export interface CreateMessageReq {
  toEmail: string
  clientId: string
  role: MemberRole
}
export const createMessage = async (data: CreateMessageReq): Promise<MessageResp> => {
  return await client.post<MessageResp>({
    path: `${paths.messages}`,
    data
  })
}

export interface ResendMessageReq {
  messageId: string
  clientId: string
}

export const resendMessage = async ({ messageId, clientId }: ResendMessageReq): Promise<MessageResp> => {
  return await client.post<MessageResp>({
    path: `${paths.messages}/${messageId}/resend`,
    data: {
      clientId
    }
  })
}

export interface UpdateMessageReq {
  messageId: string
  clientId: string
}

export const updateMessage = async ({ messageId, clientId }: UpdateMessageReq): Promise<MessageResp> => {
  return await client.patch<MessageResp>({
    path: `${paths.messages}/${messageId}`,
    data: {
      status: 'revoked',
      clientId
    }
  })
}

interface VerifyTokenReq {
  token: string
}

export const verifyToken = async ({ token }: VerifyTokenReq): Promise<MessageResp> => {
  return await client.get<MessageResp>({
    path: `${paths.messages}/verify?token=${token}`
  })
}
