import React from 'react'
import { PopUp, Typography, Button, styled, Link, Divider, Grid } from '@precis-digital/kurama'
import { StyledUpperText } from 'workspace/components/Popup/styles'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'react-i18next'
import yellowCone from 'public/assets/images/yellow_cone.png'
import Image from 'next/image'

const UnableToDeleteAccountPopup = (): React.ReactElement => {
  const unableToDeleteAccountPopup = useOverScreen('unableToDeleteAccountPopup')
  const { t } = useTranslation('user')
  const handlePopupClose = (): void => {
    unableToDeleteAccountPopup.remove()
  }
  return (
    <>
      <PopUp open={unableToDeleteAccountPopup.visible} handleOpen={handlePopupClose}>
        <Image
          src={yellowCone.src}
          width={544}
          height={179}
          alt="yellow cone"
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
        <StyledUpperText>
          <Typography variant="h2">{t('titles.unableToDeleteAccountPopup')}</Typography>
        </StyledUpperText>
        <StyledText variant="h4">
          {t('descriptions.unableToDeleteAccountPart1')}
          <Link>
            <StyledLinkSpan>{t('descriptions.unableToDeleteAccountPart2')}</StyledLinkSpan>
          </Link>
          {t('descriptions.unableToDeleteAccountPart3')}
        </StyledText>
        <Divider />
        <StyledLeaveWorkspacePopupFooter>
          <Button onClick={handlePopupClose} scheme="light" variant="filled">
            {t('buttons.understand')}
          </Button>
        </StyledLeaveWorkspacePopupFooter>
      </PopUp>
    </>
  )
}

const StyledLinkSpan = styled('span')({
  fontSize: '16px',
  fontFamily: 'Graphik-Regular'
})

const StyledText = styled(Typography)({
  marginTop: '22px',
  marginBottom: '9px',
  fontFamily: 'Graphik-Regular',
  textAlign: 'center'
})

const StyledLeaveWorkspacePopupFooter = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '9px',
  '>Button': {
    marginLeft: theme.spacing(1)
  }
}))

export default OverScreen.create(UnableToDeleteAccountPopup)
