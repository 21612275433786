import { styled } from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'

interface CurrentUserProps {
  memberName: string
  memberId: string
  currentUserId: string
}

export const CurrentUser = ({ memberName, memberId, currentUserId }: CurrentUserProps): React.ReactElement => {
  const { t } = useTranslation('workspace')

  if (memberId === currentUserId) {
    return (
      <>
        <StyledGraySpan>{t('descriptions.currentUser')} - </StyledGraySpan> {memberName}
      </>
    )
  }
  return <>{memberName}</>
}
const StyledGraySpan = styled('span')(({ theme }) => ({
  color: theme.palette.neutrals.stone150
}))
