import { TextField } from '@mui/material'
import { styled } from '../../utils'
import { CSSObject } from '@mui/material/styles'
import { Theme } from '../theme'
import { color } from './types'
import { Variant } from '../../components/Typography/types'

export const StyledInput = styled(TextField)(
  ({
    theme,
    value,
    color,
    assistive,
    fontSizeVariant,
    backgroundColor,
    isRightIconClickable,
    hasLeftIcon
  }: {
    theme?: Theme
    value?: string | number
    color: color
    assistive: boolean
    fontSizeVariant?: Variant
    backgroundColor?: string
    isRightIconClickable: boolean
    hasLeftIcon: boolean
  }): CSSObject => {
    const stringValue = value?.toString() ?? ''
    return {
      '.MuiOutlinedInput-root': {
        borderRadius: assistive ? theme?.borderRadius.xLarge : theme?.borderRadius.medium,
        padding: 0,
        backgroundColor:
          backgroundColor ??
          (stringValue.length > 0 ? theme?.palette.neutrals.white0 : theme?.palette.neutrals.stone100),
        ...(assistive && {
          height: '56px'
        }),
        paddingRight: isRightIconClickable ? '6px' : '14px',
        paddingLeft: assistive ? '16px' : hasLeftIcon ? '8px' : undefined,
        height: assistive && '56px',
        input: {
          ...InputOrTextAreaStyles(stringValue, assistive, hasLeftIcon, fontSizeVariant, theme)
        },
        textarea: {
          ...InputOrTextAreaStyles(stringValue, assistive, hasLeftIcon, fontSizeVariant, theme)
        },
        fieldset: {
          border: stringValue.length >= 0 ? `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}` : 'none',
          lineHeight: assistive ? theme?.typography.h4.lineHeight : theme?.typography.body2.lineHeight
        },
        '&:hover': {
          backgroundColor: backgroundColor ?? theme?.palette.neutrals.stone90,
          border: 'none',
          fieldset: {
            border: 'none'
          }
        },
        '&:focus': {
          backgroundColor: backgroundColor ?? theme?.palette.neutrals.white0,
          fieldset: {
            border: `1px solid ${theme?.palette.primary.main ?? '#5578F5'}`,
            boxShadow: `0 0 0 3px ${theme?.palette.semantic.info05 ?? '#F5F7FE'}`
          }
        },
        ...(color === 'success' && {
          ...StyledSuccessInput(theme)
        }),
        ...(color === 'error' && {
          ...StyledErrorInput(theme)
        }),
        ...(backgroundColor === 'transparent' && {
          ...StyledTransparentBackgroundInput()
        })
      },
      '.MuiOutlinedInput-root.Mui-focused': {
        backgroundColor: theme?.palette.neutrals.white0,
        fieldset: {
          border: `1px solid ${theme?.palette?.primary?.main ?? '#5578F5'}`,
          boxShadow: `0 0 0 3px ${theme?.palette.semantic.info05 ?? '#F5F7FE'}`
        },
        ...(color === 'success' && {
          ...SuccessInputFocus(theme)
        }),
        ...(color === 'error' && {
          ...ErrorInputFocus(theme)
        }),
        ...(backgroundColor === 'transparent' && {
          ...StyledTransparentBackgroundFocus()
        })
      },
      '.MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme?.palette.neutrals.stone90,
        color: theme?.palette.neutrals.stone120,
        fieldset: {
          border: 'none'
        }
      },
      'input:invalid + fieldset': {
        ...ErrorFieldSet(theme)
      },
      'input:invalid:focus + fieldset': {
        ...ErrorFieldSet(theme)
      },
      'input:invalid:hover + fieldset': {
        ...ErrorFieldSet(theme, true)
      }
    }
  }
)

const InputOrTextAreaStyles = (
  value: string,
  assistive: boolean,
  hasLeftIcon: boolean,
  fontSizeVariant?: Variant,
  theme?: Theme
): CSSObject => ({
  fontFamily: assistive ? theme?.typography.h4.fontFamily : theme?.typography.body2.fontFamily,
  fontSize: assistive ? theme?.typography.h4.fontSize : theme?.typography.body2.fontSize,
  height: assistive ? theme?.typography.h4.lineHeight : theme?.typography.body2.lineHeight,
  ...(fontSizeVariant != null && {
    fontFamily: theme?.typography[fontSizeVariant].fontFamily,
    fontSize: theme?.typography[fontSizeVariant].fontSize,
    lineHeight: theme?.typography[fontSizeVariant].lineHeight,
    height: theme?.typography[fontSizeVariant].lineHeight,
    letterSpacing: theme?.typography[fontSizeVariant].letterSpacing
  }),
  padding: '8px 16px',
  paddingLeft: assistive ? '16px' : hasLeftIcon ? '4px' : undefined,
  '&::placeholder': {
    color: value.length > 0 ? theme?.palette.secondary.main : theme?.palette.neutrals.stone150
  }
})

const StyledTransparentBackgroundInput = (): CSSObject => ({
  paddingRight: 0,
  '> input, textarea': {
    border: 'none',
    padding: 0
  },
  fieldset: {
    border: 'none',
    boxShadow: 'none'
  }
})

const StyledTransparentBackgroundFocus = (): CSSObject => ({
  fieldset: {
    border: 'none',
    boxShadow: 'none'
  }
})

const StyledSuccessInput = (theme?: Theme): CSSObject => ({
  backgroundColor: theme?.palette.neutrals.white0,
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`
  },
  '&:hover': {
    fieldset: {
      border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`,
      boxShadow: `0 0 0 3px ${theme?.palette.semantic.success05 ?? '#E8FDF7'}`
    }
  }
})

const SuccessInputFocus = (theme?: Theme): CSSObject => ({
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.success100 ?? '#10AC84'}`,
    boxShadow: `0 0 0 3px ${theme?.palette.semantic.success05 ?? '#E8FDF7'}`
  }
})

const ErrorFieldSet = (theme?: Theme, isHover: boolean = false): CSSObject => ({
  border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`,
  ...(isHover && { boxShadow: `0 0 0 3px ${theme?.palette.semantic.error05 ?? '#FFF0F5'}` })
})

const StyledErrorInput = (theme?: Theme): CSSObject => ({
  backgroundColor: theme?.palette.neutrals.white0,
  fieldset: {
    ...ErrorFieldSet(theme)
  },
  '&:hover': {
    fieldset: {
      ...ErrorFieldSet(theme, true)
    }
  }
})

const ErrorInputFocus = (theme?: Theme): CSSObject => ({
  fieldset: {
    border: `1px solid ${theme?.palette.semantic.error100 ?? '#FF4C61'}`,
    boxShadow: `0 0 0 3px ${theme?.palette.semantic.error05 ?? '#FFF0F5'}`
  }
})

export const StyledLabel = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.secondary.main,
    fontFamily: theme?.typography.h5.fontFamily,
    fontSize: theme?.typography.h5.fontSize,
    marginBottom: '8px'
  })
)

export const StyledMessage = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    color: theme?.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme?.typography.body3.fontFamily,
    fontSize: theme?.typography.body3.fontSize,
    marginTop: '8px',
    paddingLeft: '6px',
    span: {
      paddingLeft: '6px'
    }
  })
)

export const StyledClearButton = styled('button')(
  (): CSSObject => ({
    cursor: 'pointer',
    display: 'flex',
    border: 'none',
    padding: '0',
    backgroundColor: 'transparent'
  })
)
