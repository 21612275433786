import React from 'react'

export const Platforms = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M8.3 14H5.7C4.76112 14 4 14.7611 4 15.7V18.3C4 19.2389 4.76112 20 5.7 20H8.3C9.23888 20 10 19.2389 10 18.3V15.7C10 14.7611 9.23888 14 8.3 14Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3 4H5.7C4.76112 4 4 4.76112 4 5.7V8.3C4 9.23888 4.76112 10 5.7 10H8.3C9.23888 10 10 9.23888 10 8.3V5.7C10 4.76112 9.23888 4 8.3 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3 14H15.7C14.7611 14 14 14.7611 14 15.7V18.3C14 19.2389 14.7611 20 15.7 20H18.3C19.2389 20 20 19.2389 20 18.3V15.7C20 14.7611 19.2389 14 18.3 14Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3 4H15.7C14.7611 4 14 4.76112 14 5.7V8.3C14 9.23888 14.7611 10 15.7 10H18.3C19.2389 10 20 9.23888 20 8.3V5.7C20 4.76112 19.2389 4 18.3 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
