import React from 'react'

export const Note = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M9.20728 6.95084V3.96118M14.9341 6.95099V3.96134M12 12.4688H8.96495M14 16.031H8.96077M6 5.71085H18V17.425C18 18.8471 17.0119 20 15.7929 20H8.20709C6.98815 20 6 18.8471 6 17.425V5.71085Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
