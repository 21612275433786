import React from 'react'

export const More = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M13.0194 12C13.0194 11.5169 12.6278 11.1253 12.1447 11.1253C11.6616 11.1253 11.27 11.5169 11.27 12C11.27 12.4831 11.6616 12.8747 12.1447 12.8747C12.6278 12.8747 13.0194 12.4831 13.0194 12Z"
        fill="#232332"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89592 12C6.89592 11.5169 6.5043 11.1252 6.0212 11.1252C5.53811 11.1252 5.14648 11.5169 5.14648 12C5.14648 12.4831 5.53811 12.8747 6.0212 12.8747C6.5043 12.8747 6.89592 12.4831 6.89592 12Z"
        fill="#232332"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1421 12C19.1421 11.5169 18.7505 11.1253 18.2674 11.1253C17.7843 11.1253 17.3926 11.5169 17.3926 12C17.3926 12.4831 17.7843 12.8747 18.2674 12.8747C18.7505 12.8747 19.1421 12.4831 19.1421 12Z"
        fill="#232332"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
