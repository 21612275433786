import { CREDENTIAL_ID_KEY, ERROR_KEY, type IPlatform } from 'dataSource/constants'
import { ERROR_CODES } from 'dataSource/errorCodes'
import NewWindow from 'shared/NewWindow'
import { useTranslation } from 'react-i18next'
import OverScreen from 'shared/overScreens/niceModalReact'
import { sanitizePostMessageContent } from 'dataSource/utils'
import { makeErrorToast } from 'shared/components/Toaster'
import { logAndReportError } from 'shared/errorReporter'

interface OauthWindowProps {
  selectedDataSource: IPlatform
  onOauthComplete: (credentialId: string) => void
}

export const OauthWindow = ({ selectedDataSource, onOauthComplete }: OauthWindowProps): React.ReactElement => {
  const { t } = useTranslation('dataSource')
  const handleOauthWindowOnUnload = (): void => {
    OverScreen.remove('oauthWindow')
  }

  const handleOauthWindowOnOpen = (): void => {
    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        const hasReceivedCredentialId = event.origin === window.location.origin && CREDENTIAL_ID_KEY in event.data
        if (hasReceivedCredentialId) {
          const credentialId = sanitizePostMessageContent(event.data[CREDENTIAL_ID_KEY])

          if (credentialId !== undefined && credentialId !== '') {
            onOauthComplete(credentialId)
          } else {
            const errorMessage = t('windows.oauth.credentialIdNotFound')
            logAndReportError(Error(errorMessage))
            makeErrorToast(errorMessage)
          }
        }

        const hasReceivedError = event.origin === window.location.origin && ERROR_KEY in event.data
        if (hasReceivedError) {
          const errorMessage = sanitizePostMessageContent(event.data[ERROR_KEY]) ?? ERROR_CODES.ERR_OAUTH
          logAndReportError(Error(errorMessage))
          makeErrorToast(t(`popups.selectDataSourceCredentialsPopup.errors.${errorMessage}` as any))
        }
      },
      false
    )
  }
  const handleOauthWindowOnBlock = (): void => {
    makeErrorToast(t('windows.oauth.blockedNewWindowError'))
  }
  return (
    <NewWindow
      url={selectedDataSource?.oauthUrl}
      onBlock={handleOauthWindowOnBlock}
      onOpen={handleOauthWindowOnOpen}
      onUnload={handleOauthWindowOnUnload}
      center="screen"
      features={{
        width: screen.width / 2,
        height: screen.height / 2
      }}
    />
  )
}

export default OverScreen.create(OauthWindow)
