/**
 * Represents a custom error that extends the built-in Error class.
 */

class CustomError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options)
    this.name = this.constructor.name
  }
}

class InternalServerError extends CustomError {
  constructor(options?: ErrorOptions) {
    super('Something unexpected happened', options)
  }
}

export { InternalServerError }
export default CustomError
