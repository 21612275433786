import React from 'react'

export const Lock = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M16.1098 9.78289V7.92421C16.1098 5.74868 14.2734 3.98437 12.0089 3.98437C9.74443 3.97484 7.90078 5.7305 7.89087 7.9069V7.92421V9.78289"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4427 20.0001H8.55736C6.67045 20.0001 5.14038 18.531 5.14038 16.7174V13.0043C5.14038 11.1907 6.67045 9.72156 8.55736 9.72156H15.4427C17.3296 9.72156 18.8597 11.1907 18.8597 13.0043V16.7174C18.8597 18.531 17.3296 20.0001 15.4427 20.0001Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 13.9V15.8228"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
