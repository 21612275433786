import React from 'react'
import {
  Button,
  Card,
  Divider,
  PopUp,
  Typography,
  Avatar,
  styled,
  Grid,
  ArrowRightIcon,
  ArrowLeftIcon,
  InfoIcon,
  Tooltip
} from '@precis-digital/kurama'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import { useTranslation } from 'shared/translations'
import OverScreen from 'shared/overScreens/niceModalReact'
import { type users } from 'shared/api'
import { isPartOfGooglePlatform } from 'dataSource/utils'
import { useQueryClientCredentials, useReconnectAccount } from 'dataSource/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import { makeToastWithLoading } from 'shared/components/Toaster'
import { useAuth } from 'shared/context/AuthContext'

export type SelectDataSourceCredentialsPopupProps =
  | {
      datasource: {
        iconUrl: string
        commonIconUrl: string
        label: string
        oauthUrl: string
        platform: string
      }
      credentials: any
      onGoBack?: () => void
      isReconnect: false
      internalAccountId: never
    }
  | {
      datasource: {
        iconUrl: string
        commonIconUrl: string
        label: string
        oauthUrl: string
        platform: string
      }
      credentials: any
      onGoBack?: () => void
      isReconnect: true
      internalAccountId: string
    }

export const SelectDataSourceCredentialsPopup = ({
  datasource = {
    iconUrl: '',
    label: '',
    oauthUrl: '',
    platform: '',
    commonIconUrl: ''
  },
  credentials = [],
  onGoBack,
  isReconnect = false,
  internalAccountId
}: SelectDataSourceCredentialsPopupProps): React.ReactElement => {
  const { t } = useTranslation('dataSource')
  const { mutateAsync: reconnectAccount } = useReconnectAccount()
  const { currentClient } = useCurrentClient()
  const { currentUser } = useAuth()
  const { data: clientCredentials = credentials, refetch: refetchClientCredentials } = useQueryClientCredentials(
    currentUser?.id as string,
    datasource?.platform
  )
  const handlePoupClose = (): void => {
    OverScreen.remove('selectDataSourceCredentialsPopup')
  }

  const handleCredential = async ({ id: credentialId }: { id: string }): Promise<void> => {
    if (isReconnect) {
      const { toastOnError, toastOnSuccess } = makeToastWithLoading()
      try {
        await reconnectAccount(
          {
            accountId: internalAccountId,
            credentialsId: credentialId,
            clientId: currentClient.id
          },
          {
            onSuccess: () => {
              OverScreen.remove('selectDataSourceCredentialsPopup')
              toastOnSuccess(t('popups.selectDataSourceCredentialsPopup.credentialsUpdatedSuccessMessage'))
            },
            onError: () => {
              toastOnError(t('popups.selectDataSourceCredentialsPopup.credentialsUpdatedErrorMessage'))
            }
          }
        )
      } catch (error) {
        toastOnError(t('popups.selectDataSourceCredentialsPopup.credentialsUpdatedErrorMessage'))
      }
    } else {
      OverScreen.remove('selectDataSourceCredentialsPopup')
      void OverScreen.show('accountSelectionPopup', {
        platform: datasource.platform,
        credentialId,
        datasource,
        credentials: clientCredentials
      })
    }
  }

  const handleOpenOauthWindow = (): void => {
    void OverScreen.show('oauthWindow', {
      selectedDataSource: datasource,
      onOauthComplete: async (credentialId: string) => {
        void refetchClientCredentials()
        void handleCredential({ id: credentialId })
      }
    })
  }

  return (
    <PopUp open handleOpen={handlePoupClose}>
      <StyledHeader>
        {onGoBack != null && (
          <StyledBackButton variant="text" onClick={onGoBack} leftIcon={<ArrowLeftIcon />}>
            {t('buttons.backButton')}
          </StyledBackButton>
        )}
        <Avatar size="large" imageUrl={datasource.iconUrl} kind="image" />
        <Typography variant="h2">
          {isPartOfGooglePlatform(datasource?.platform)
            ? t('popups.selectDataSourceCredentialsPopup.title', { label: 'Google' })
            : t('popups.selectDataSourceCredentialsPopup.title', { label: datasource?.label })}
        </Typography>
      </StyledHeader>
      <Divider />
      <StyledSubheader variant="h4">
        {t('popups.selectDataSourceCredentialsPopup.subtitle', { label: datasource?.label })}
      </StyledSubheader>
      <StyledItemsList>
        {clientCredentials?.map((credential: users.CredentialsResp) => (
          <StyledDataSourcePlate
            key={credential.id}
            onClick={() => {
              if (credential.isMissingScopes) {
                handleOpenOauthWindow()
              } else {
                void handleCredential(credential)
              }
            }}
          >
            <Grid display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Grid display="flex" alignItems="center">
                <AvatarWithLabel user={credential} />
                <StyledCardText>
                  <Typography variant="h3">{credential.name}</Typography>
                  <Typography variant="body3">{credential.id}</Typography>
                </StyledCardText>
              </Grid>
              <Grid display="flex" alignItems="center">
                {credential.isMissingScopes && (
                  <Tooltip
                    title={t('popups.selectDataSourceCredentialsPopup.credentialsOutOfSyncTitle')}
                    kind="multiline"
                    body={
                      <Grid display="flex" flexDirection="column" gap="8px">
                        <Typography variant="body2">
                          {t('popups.selectDataSourceCredentialsPopup.credentialsOutOfSyncBody.fragment1')}
                        </Typography>
                        <Typography variant="body2">
                          {t('popups.selectDataSourceCredentialsPopup.credentialsOutOfSyncBody.fragment2')}
                        </Typography>
                      </Grid>
                    }
                  >
                    <StyledInfoIcon />
                  </Tooltip>
                )}
                <Button
                  aria-label="update credential"
                  onClick={() => {
                    void handleCredential(credential)
                  }}
                  variant="tonal"
                  leftIcon={<ArrowRightIcon />}
                />
              </Grid>
            </Grid>
          </StyledDataSourcePlate>
        ))}
      </StyledItemsList>
      <StyledFooterBlock>
        <Typography variant="body2">
          {isPartOfGooglePlatform(datasource?.platform)
            ? t('popups.selectDataSourceCredentialsPopup.signInDescription', { label: 'Google' })
            : t('popups.selectDataSourceCredentialsPopup.signInDescription', { label: datasource?.label })}
        </Typography>
        <Button onClick={handleOpenOauthWindow} scheme="light" variant="outlined">
          <StyledButtonImage size="small" imageUrl={datasource?.commonIconUrl} kind="image" />
          <Typography variant="h5">
            {isPartOfGooglePlatform(datasource?.platform)
              ? t('popups.selectDataSourceCredentialsPopup.addNewCredential', { label: 'Google' })
              : t('popups.selectDataSourceCredentialsPopup.addNewCredential', { label: datasource?.label })}
          </Typography>
        </Button>
        <Typography variant="body2">{t('popups.selectDataSourceCredentialsPopup.credentialsInfo')}</Typography>
      </StyledFooterBlock>
    </PopUp>
  )
}

const StyledBackButton = styled(Button)(() => ({
  position: 'absolute',
  top: '-60px',
  left: '-16px'
}))

const StyledHeader = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  '& h2': {
    marginTop: '25px',
    marginBottom: '27px'
  }
}))

export const StyledSubheader = styled(Typography)(() => ({
  marginTop: '4px',
  marginBottom: '8px'
}))

const StyledItemsList = styled(Grid)(() => ({
  '& >div:first-of-type': {
    marginTop: '0'
  }
}))

export const StyledInfoIcon = styled(InfoIcon)(() => ({
  color: '#FF830C',
  paddingRight: '24px'
}))

export const StyledDataSourcePlate = styled(Card)(() => ({
  justifyContent: 'initial',
  '& button': {
    marginLeft: 'auto'
  }
}))
const StyledCardText = styled(Grid)(({ theme }) => ({
  marginLeft: '16px',
  '& span': {
    color: theme.palette.neutrals.stone150
  }
}))
const StyledFooterBlock = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  '& p:first-of-type': {
    paddingTop: '16px'
  },
  '& p:last-of-type': {
    color: theme.palette.neutrals.stone150
  },
  '& button': {
    width: '100%',
    margin: '16px 0'
  }
}))

const StyledButtonImage = styled(Avatar)(() => ({
  marginRight: '4px'
}))

export const StyledArtwork = styled(Grid)(() => ({
  marginLeft: '190px'
}))

export const StyledBannerWarning = styled(Grid)(() => ({
  marginBottom: '26px',
  div: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export default OverScreen.create(SelectDataSourceCredentialsPopup)
