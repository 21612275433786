import React from 'react'

export const Shop = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M18.892 12.2674V18.2862C18.892 19.7136 17.7349 20.8706 16.3075 20.8706H7.69275C6.26542 20.8706 5.10834 19.7136 5.10834 18.2862V12.2674"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M4.00897 8.9052V10.136C4.00897 11.6074 5.20181 12.8002 6.67324 12.8002C8.14467 12.8002 9.33751 11.6074 9.33751 10.136C9.33751 11.6062 10.5294 12.8002 11.9996 12.8002C13.4699 12.8002 14.6617 11.6062 14.6617 10.136C14.6617 11.6074 15.8548 12.8002 17.3262 12.8002C18.7978 12.8002 19.991 11.6072 19.991 10.1356V8.9052L18.0688 4.87939H5.92895L4.00897 8.9052Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
})
