import { type MultiSelectDropdownItem, colorMap, type BadgeColor } from '@precis-digital/kurama'
import {
  formatDateToString,
  getCorrespondingPreviousPeriodDate,
  getCorrespondingPreviousYearDate,
  getDifferenceInDays
} from 'shared/dateFns'
import { capitalize, isNilOrEmpty } from 'shared/utils'
import { type ProgressOverTimeRecords } from './ProgressOvertime'
import { type ChartLegendProps } from 'shared/components/ChartLegend'
import { type TFunction } from 'i18next'
import { type ActiveFilter } from 'shared/components/Filter'
import { type Filter } from 'shared/api/analytics'
import { DIMENSION_OPERATORS_WITH_TYPES } from 'shared/constants/filterOperators'
import { type TimePeriodType } from './StartPage'
import { type Metric } from 'home/reportTemplateParameters'
import { type StandardReportResp, type ReportTemplateId } from 'shared/api/standardReports'
import { head } from 'ramda'
import { type ConfigStatus } from 'shared/configStatuses'
import { REPORT_TEMPLATE_WITH_CONVERSION_EVENTS, STATUSES_TO_SHOW_HOMEPAGE_DATA_FOR } from 'home/constants'

const PREVIOUS = 'previous'

export const formDataKey = ({
  metric,
  breakdown,
  isPreviousMetric = false
}: {
  metric: Metric
  breakdown?: string | null
  isPreviousMetric?: boolean
}): string => {
  let dataKey = metric

  if (breakdown != null) {
    dataKey += `_${breakdown}`
  }

  if (isPreviousMetric) {
    dataKey += `_${PREVIOUS}`
  }

  return dataKey
}

export const getPartsFromDataKey = (dataKey: string): [Metric, string | null, boolean] => {
  const parts = dataKey.split('_')

  const metric = parts[0]

  const hasBreakdown = parts.length > 1 && parts[1] !== PREVIOUS
  const breakdown = hasBreakdown ? parts[1] : null

  const isPreviousMetric = dataKey.endsWith(PREVIOUS) ?? false

  return [metric as Metric, breakdown, isPreviousMetric]
}

export type LineConfig = Record<
  string,
  {
    lineColor: string
    badgeColor: BadgeColor
    lineOpacity?: number
  }
>

const sortDataKeys = (dataKeyA: string, dataKeyB: string): number => {
  if (dataKeyA.endsWith(PREVIOUS) && !dataKeyB.endsWith(PREVIOUS)) {
    return 1
  } else if (!dataKeyA.endsWith(PREVIOUS) && dataKeyB.endsWith(PREVIOUS)) {
    return -1
  } else {
    return 0
  }
}

const chartColors: BadgeColor[] = ['info', 'dark-mint', 'dark-red', 'dark-indigo', 'steel', 'purple']

export const getProgressOverTimeLineConfigs = (progressOverTime: ProgressOverTimeRecords): LineConfig => {
  if (isNilOrEmpty(progressOverTime)) {
    return {}
  }

  const dataKeys = Object.keys(progressOverTime[0]).filter((key) => !['x', 'fullDate'].includes(key))

  const chartColorMap: Record<
    string,
    {
      lineColor: string
      badgeColor: BadgeColor
      lineOpacity?: number
    }
  > = {}

  dataKeys.sort(sortDataKeys)

  dataKeys.forEach((dataKey, index) => {
    const [metric, breakdown, isPreviousMetric] = getPartsFromDataKey(dataKey)

    if (isPreviousMetric) {
      chartColorMap[dataKey] = {
        lineColor: chartColorMap[formDataKey({ metric, breakdown })].lineColor,
        lineOpacity: 0.15,
        badgeColor: chartColorMap[formDataKey({ metric, breakdown })].badgeColor
      }
    } else {
      const badgeColor = chartColors[index % chartColors.length]
      const lineColor = colorMap.get(chartColors[index % chartColors.length])

      if (lineColor != null) {
        chartColorMap[dataKey] = {
          lineColor,
          badgeColor
        }
      }
    }
  })

  return chartColorMap
}

export const formatTooltipDescriptionByDataKey = ({
  dataKey,
  payload,
  timePeriod,
  selectedBreakdownCount,
  selectedPeriodType,
  t
}: {
  dataKey: string
  payload: Record<string, string | number>
  timePeriod: [string, string]
  selectedBreakdownCount: number
  selectedPeriodType: TimePeriodType
  t: TFunction<'home'>
}): string => {
  const [metric, currentBreakdown, isPreviousMetric] = getPartsFromDataKey(dataKey)

  const correspondingPreviousDate = formatDateToString({
    date:
      selectedPeriodType === 'previous_period'
        ? getCorrespondingPreviousPeriodDate(payload.fullDate?.toString(), getDifferenceInDays(...timePeriod))
        : getCorrespondingPreviousYearDate(payload.fullDate?.toString())
  })

  let titleKey: string = t(metric)

  if (currentBreakdown != null) {
    titleKey = `${titleKey} • ${currentBreakdown}`
  }

  if (selectedBreakdownCount <= 2) {
    titleKey = `${titleKey} • ${
      isPreviousMetric ? correspondingPreviousDate : formatDateToString({ date: payload.fullDate })
    }`
  }

  return titleKey
}

export const getChartLegendData = ({
  progressOverTime,
  lineConfigs,
  t,
  selectedMetricsCount,
  selectedBreakdownCount,
  selectedPeriodType
}: {
  progressOverTime: ProgressOverTimeRecords
  lineConfigs: LineConfig
  t: TFunction<'home'>
  selectedMetricsCount: number
  selectedBreakdownCount: number
  selectedPeriodType: TimePeriodType
}): ChartLegendProps['selectedRecords'] => {
  if (isNilOrEmpty(progressOverTime)) {
    return {}
  }

  const dataKeys = Object.keys(progressOverTime[0]).filter((key) => !['x', 'fullDate'].includes(key))

  const legendRecords: ChartLegendProps['selectedRecords'] = {}

  dataKeys.sort(sortDataKeys)
  dataKeys.forEach((key) => {
    const [metric, breakdown, isPreviousMetric] = getPartsFromDataKey(key)
    let legendText = ''

    const previousPeriodText =
      selectedPeriodType === 'previous_period'
        ? t('progressOverTime.previousTimePeriod')
        : t('progressOverTime.previousYear')

    const suffix = isPreviousMetric ? ` • ${previousPeriodText}` : ''
    if (selectedBreakdownCount === 0) {
      legendText = `${t(metric)}${suffix}`
    } else {
      legendText = `${capitalize(breakdown ?? '')}${suffix}`
    }

    legendRecords[legendText] = {
      badgeColor: lineConfigs[key].badgeColor,
      opacity: lineConfigs[key].lineOpacity
    }
  })

  return legendRecords
}

export const transformFiltersToAPIFormat = ({
  selectedMarketAndChannelFilters,
  selectedConversionEvents
}: {
  selectedMarketAndChannelFilters: ActiveFilter[]
  selectedConversionEvents?: MultiSelectDropdownItem[]
}): Filter[] => {
  const filters = selectedMarketAndChannelFilters.map((filter) => {
    const filterValue = filter.data

    if (typeof filterValue === 'string' || !Array.isArray(filterValue)) {
      return {
        field: filter.parameter.value,
        operator: filter.operator === DIMENSION_OPERATORS_WITH_TYPES.is.id ? '=' : '!=',
        value: typeof filterValue === 'string' ? filterValue : filterValue.value
      }
    }

    const selectedValues = filterValue.map((option) => option.value)

    return {
      field: filter.parameter.value,
      operator: filter.operator === DIMENSION_OPERATORS_WITH_TYPES.one_of.id ? 'IN' : 'NOT_IN',
      value: selectedValues
    }
  })

  if (selectedConversionEvents != null && selectedConversionEvents.length > 0) {
    filters.push({
      field: 'source_conv_name',
      operator: 'IN',
      value: selectedConversionEvents.map((event) => {
        const prefix = event.subCategory != null ? `${event.subCategory}: ` : ''
        return `${prefix}${event.value}`
      })
    })
  }

  return filters
}

const PRIORITY_REPORT_TEMPLATES: ReportTemplateId[] = ['reporting_base']

const sortReportByRunSchedule = (firstRunSchedule: boolean, secondRunSchedule: boolean): number => {
  if (firstRunSchedule && !secondRunSchedule) {
    return -1
  }
  if (!firstRunSchedule && secondRunSchedule) {
    return 1
  }
  return 0
}

const sortReportByTemplateId = (firstTemplateId: ReportTemplateId, secondTemplateId: ReportTemplateId): number => {
  if (PRIORITY_REPORT_TEMPLATES.includes(firstTemplateId) && !PRIORITY_REPORT_TEMPLATES.includes(secondTemplateId)) {
    return -1
  }
  if (!PRIORITY_REPORT_TEMPLATES.includes(firstTemplateId) && PRIORITY_REPORT_TEMPLATES.includes(secondTemplateId)) {
    return 1
  }

  return firstTemplateId >= secondTemplateId ? 1 : -1
}

export const sortReportsByParameters = <
  T extends {
    templateId?: ReportTemplateId
    name: string
    runSchedule?: boolean
  }
>(
  a: T,
  b: T
): number => {
  if (a.runSchedule != null && b.runSchedule != null && a.runSchedule !== b.runSchedule) {
    return sortReportByRunSchedule(a.runSchedule, b.runSchedule)
  }

  if (a.templateId != null && b.templateId != null && a.templateId !== b.templateId) {
    return sortReportByTemplateId(a.templateId, b.templateId)
  }

  return a.name >= b.name ? 1 : -1
}

export const getSelectedStandardReportConfig = (
  standardReportsConfig: StandardReportResp[],
  selectedReportId?: string | null
): StandardReportResp | undefined => {
  let selectedReport: StandardReportResp | undefined
  if (selectedReportId != null) {
    selectedReport = standardReportsConfig.find((report) => report.configId === parseInt(selectedReportId))
  }

  if (selectedReport == null) {
    standardReportsConfig.sort(sortReportsByParameters)
    selectedReport = head(standardReportsConfig)
  }

  return selectedReport
}

export const checkIfReportConfigAndStatusLoaded = ({
  hasSuccessfullyLoadedStandardReportsConfig,
  isLoadingReportStatuses,
  getStandardReportStatusesRequestIsIdle
}: {
  hasSuccessfullyLoadedStandardReportsConfig: boolean
  isLoadingReportStatuses: boolean
  getStandardReportStatusesRequestIsIdle: boolean
}): boolean =>
  hasSuccessfullyLoadedStandardReportsConfig && !isLoadingReportStatuses && !getStandardReportStatusesRequestIsIdle

export const checkIfReportDataLoadingViewConditionSatisfied = ({
  status,
  isDataNotFoundError
}: {
  status: ConfigStatus | undefined
  isDataNotFoundError: boolean
}): boolean => isDataNotFoundError && status != null && (status === 'running' || status === 'unknown')

export const checkIfCustomContentConditionSatisfied = ({
  isReportConfigAndStatusLoaded,
  shouldShowErrorView,
  shouldShowCustomNonErrorView,
  shouldShowNoDataView
}: {
  isReportConfigAndStatusLoaded: boolean
  shouldShowErrorView: boolean
  shouldShowCustomNonErrorView: boolean
  shouldShowNoDataView: boolean
}): boolean =>
  isReportConfigAndStatusLoaded && (shouldShowErrorView || shouldShowCustomNonErrorView || shouldShowNoDataView)

export const checkIfToolbarConditionSatisfied = ({
  isCustomContentConditionSatisfied,
  isReportDataLoaded,
  isFilterOptionsLoaded,
  isConversionNameAndSourceConditionSatisfied
}: {
  isCustomContentConditionSatisfied: boolean
  isReportDataLoaded: boolean
  isFilterOptionsLoaded: boolean
  isConversionNameAndSourceConditionSatisfied: boolean
}): boolean =>
  isCustomContentConditionSatisfied ||
  (isReportDataLoaded && isFilterOptionsLoaded && isConversionNameAndSourceConditionSatisfied)

export const checkIfReportTemplateWithNoConversionEvents = (
  selectedStandardReportConfig: StandardReportResp | undefined
): boolean =>
  selectedStandardReportConfig != null &&
  !REPORT_TEMPLATE_WITH_CONVERSION_EVENTS.includes(selectedStandardReportConfig.templateId)

export const checkIfValidStatusForShowingHomePageData = (selectedReportStatus: ConfigStatus | undefined): boolean =>
  selectedReportStatus != null && STATUSES_TO_SHOW_HOMEPAGE_DATA_FOR.includes(selectedReportStatus)

export const checkIfFilterConditionIsSatisfied = (
  isValidStatusForShowingHomePageData: boolean,
  filterDataCount = 0
): boolean => isValidStatusForShowingHomePageData && filterDataCount > 0

export const checkIfReportDataUnknownErrorViewConditionSatisfied = ({
  isDataError,
  status,
  isReportDataLoadingViewConditionSatisfied
}: {
  isDataError: boolean
  status: ConfigStatus | undefined
  isReportDataLoadingViewConditionSatisfied: boolean
}): boolean => isDataError && status !== 'error' && !isReportDataLoadingViewConditionSatisfied

export const checkIfReportDataKnownErrorViewConditionSatisfied = ({
  isDataNotFoundError,
  status
}: {
  isDataNotFoundError: boolean
  status: ConfigStatus | undefined
}): boolean => status === 'error' && isDataNotFoundError

export const checkIfNoReportDataViewConditionSatisfied = ({
  hasSuccessfullyLoadedNecessaryData,
  isDataError,
  dataAnalyticsCount,
  status
}: {
  hasSuccessfullyLoadedNecessaryData: boolean
  isDataError: boolean
  dataAnalyticsCount: number
  status: ConfigStatus | undefined
}): boolean => hasSuccessfullyLoadedNecessaryData && !isDataError && status !== 'error' && dataAnalyticsCount === 0
