import type React from 'react'
import { useEffect } from 'react'
import { type IPlatform } from 'dataSource/constants'
import OverScreen from 'shared/overScreens/niceModalReact'
import { type CredentialsResp } from 'shared/api/users'
import { useQueryClient } from 'react-query'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryClientAccounts } from 'dataSource/api'

export const useOpenDataSourceAuthenticationModal = (
  isCredentialsSuccess: boolean,
  dataSource: IPlatform | null,
  credentials: CredentialsResp[] | undefined,
  setSelectedPlatform: React.Dispatch<React.SetStateAction<IPlatform | null>>,
  showGoBackButton: boolean,
  isReconnect?: boolean,
  internalAccountId?: number
): void => {
  const queryClient = useQueryClient()
  const { currentClient } = useCurrentClient()
  const { data: account } = useQueryClientAccounts(currentClient.id ?? '')
  const selectedAccount = account?.find((acc) => acc.id === internalAccountId)
  const handleGoBackFromUserCredentials = (): void => {
    OverScreen.remove('selectDataSourceCredentialsPopup')
    void OverScreen.show('connectNewDataSourcePopup')
  }

  useEffect(() => {
    if (isReconnect === true && dataSource?.supportsServiceAccountAccess === true) {
      void OverScreen.show('userOrServiceAccountSelectionPopup', {
        platform: dataSource,
        dataSourceToReconnect: selectedAccount
      })
      OverScreen.remove('connectNewDataSourcePopup')
    } else if (dataSource !== null && isCredentialsSuccess && credentials != null && credentials.length > 0) {
      void OverScreen.show('selectDataSourceCredentialsPopup', {
        datasource: dataSource,
        credentials,
        isReconnect,
        onGoBack: showGoBackButton ? handleGoBackFromUserCredentials : undefined,
        internalAccountId
      })
      OverScreen.remove('connectNewDataSourcePopup')
      setSelectedPlatform(null)
    } else if (isCredentialsSuccess && credentials != null && credentials.length === 0) {
      void OverScreen.show('oauthWindow', {
        selectedDataSource: dataSource,
        onOauthComplete: async (credentialId: string) => {
          await queryClient.invalidateQueries(['clientCredentials'])
          OverScreen.remove('connectNewDataSourcePopup')
          await OverScreen.show('accountSelectionPopup', {
            platform: dataSource?.platform,
            dataSource,
            credentialId,
            credentials
          })
        }
      })
      setSelectedPlatform(null)
    }
  }, [
    isCredentialsSuccess,
    dataSource,
    credentials,
    showGoBackButton,
    internalAccountId,
    isReconnect,
    queryClient,
    setSelectedPlatform,
    selectedAccount
  ])
}
export const useFetchAccountCredentials = (dataSource: IPlatform | null, refetch: any): void => {
  useEffect(() => {
    if (dataSource !== null) {
      void (async () => {
        await refetch()
      })()
    }
  }, [dataSource, refetch])
}
