import React from 'react'
import { Grid, MultiSelectDropdown, type MultiSelectDropdownItem, Typography, styled } from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'
import { objectFromEntries, objectKeys } from 'shared/utils'
import { type Metric } from './DetailedReport'
import { REVENUE, ROAS } from 'attributionModel/constants'
import { makeErrorToast } from 'shared/components/Toaster'

interface FiltersProps {
  metricsFilter: Record<Metric, boolean>
  setMetricsFilter: React.Dispatch<React.SetStateAction<Record<Metric, boolean>>>
  hasConversionsButNoRevenue: boolean
}

export const Filters = ({
  metricsFilter,
  setMetricsFilter,
  hasConversionsButNoRevenue
}: FiltersProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel')

  const handleMetricsFilterChange = (value: MultiSelectDropdownItem[]): void => {
    if (value.length >= 1) {
      const newMetricFilter: Record<Metric, boolean> = objectFromEntries<Array<[Metric, boolean]>>(
        objectKeys(metricsFilter).map((key) => [key, value.map((item) => item.value).includes(key)])
      )
      setMetricsFilter(newMetricFilter)
    } else {
      makeErrorToast(t('dashboard.metricLimitNotMet'))
    }
  }

  return (
    <Grid container item xs={9} gap="8px" alignItems="center">
      <StyledFiltersGroupText variant="body2">{t('dashboard.showMe')}</StyledFiltersGroupText>
      <MultiSelectDropdown
        title={t('dashboard.selectMetrics')}
        buttonTitle={t('dashboard.allMetrics')}
        allowSelectAll={!hasConversionsButNoRevenue}
        options={objectKeys(metricsFilter).map((key) => ({
          label: t(`dashboard.${key}`),
          value: key,
          ...(hasConversionsButNoRevenue && (key === REVENUE || key === ROAS)
            ? {
                disabled: true,
                tooltipProps: {
                  kind: 'singleline',
                  title: t('dashboard.revenueAndRoasDisabled')
                }
              }
            : {})
        }))}
        value={objectKeys(metricsFilter).filter((key) => metricsFilter[key])}
        onChange={(_, value) => {
          handleMetricsFilterChange(value)
        }}
      />
    </Grid>
  )
}

const StyledFiltersGroupText = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1)
}))

export default Filters
