import React, { ReactElement } from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, ListItem } from '@mui/material'

import { StyledBoxChevronContainer, StyledListFooterContainer, StyledListItemNavItem } from './styles'
import { NavFooterProps, AccountActions } from './types'
import { ChevronDownIcon, ChevronUpIcon } from '../Icons'
import { styled } from '../../utils'
import Grid from '../Grid'

const NavFooter = ({ sideNavProps, accountSection: AccountSection }: NavFooterProps): React.ReactElement => {
  const handleClick = (): void => {
    sideNavProps.toggleNavFooter()
  }

  return (
    <StyledListFooterContainer open={sideNavProps.isNavFooterOpen} aria-labelledby="nested-list-subheader">
      <StyledListItemNavItem>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{AccountSection.AvatarComponent}</ListItemIcon>
          <ListItemText
            primary={AccountSection.fullName}
            primaryTypographyProps={{ variant: 'h5' }}
            secondary={AccountSection.email}
            secondaryTypographyProps={{ variant: 'body3' }}
          />
          <StyledBoxChevronContainer isNavBarOpen={sideNavProps.isNavBarOpen}>
            {sideNavProps.isNavFooterOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </StyledBoxChevronContainer>
        </ListItemButton>
      </StyledListItemNavItem>
      <Collapse in={sideNavProps.isNavFooterOpen}>
        <List component="div">
          {AccountSection.accountActionslist.map((item: AccountActions, index) => {
            return <FooterMenuItem key={`${item.label}-${index}`} {...item} />
          })}
        </List>
      </Collapse>
    </StyledListFooterContainer>
  )
}

const FooterMenuItem = ({ label, handleClick }: AccountActions): ReactElement => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  return (
    <StyledListItemFooterItem onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <ListItemButton>
        <ListItemIcon>
          <Grid width="40px" />
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            variant: isHovered ? 'h5' : 'body2'
          }}
          onClick={handleClick}
        />
      </ListItemButton>
    </StyledListItemFooterItem>
  )
}

const StyledListItemFooterItem = styled(ListItem)(() => ({
  height: '40px',
  '.MuiButtonBase-root': {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default NavFooter
