import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { type DatePickerProps } from './types'
import moment from 'moment'
import { getInputBadgeColor } from '../../utils'
import { StyledInput, StyledMessage } from './styles'
import { Badge, CalendarIcon, ChevronDownIcon, ChevronUpIcon } from '../../components'
import React from 'react'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers'
import { InputAdornment, type TextFieldProps } from '@mui/material'

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      onChange,
      value,
      minDate,
      maxDate,
      disabled = false,
      selectedDateFormat = 'YYYY-MM-DD',
      helperText,
      color,
      defaultValue,
      fullWidth = false,
      disableTextEdit = false,
      actions = ['clear'],
      shouldDisableDate,
      ...props
    },
    ref
  ): React.ReactElement => {
    const parsedMinDate = moment(minDate)
    const parsedMaxDate = moment(maxDate)
    const parsedDefault = moment(defaultValue)
    const parsedValue = moment(value)

    const [isOpen, setIsOpen] = React.useState(false)

    return (
      <>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MUIDatePicker
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            disabled={disabled}
            {...props}
            {...(defaultValue != null && { defaultValue: parsedDefault })}
            {...(value != null && { value: parsedValue })}
            onChange={(value) => {
              return moment.isMoment(value) ? onChange(value.toDate()) : onChange(value)
            }}
            ref={ref}
            {...(minDate != null && { minDate: parsedMinDate })}
            {...(maxDate != null && { maxDate: parsedMaxDate })}
            shouldDisableDate={
              shouldDisableDate != null
                ? (day) => {
                    const value = moment.isMoment(day) ? day.toDate() : day
                    return shouldDisableDate(value)
                  }
                : undefined
            }
            format={selectedDateFormat}
            slotProps={{
              actionBar: { actions },
              textField: {
                color,
                fullWidth,
                onFocus: disableTextEdit ? (e) => e.currentTarget.blur() : undefined,
                onClick: disableTextEdit ? () => setIsOpen(true) : undefined,
                InputProps: {
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                    >
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                    >
                      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </InputAdornment>
                  )
                }
              }
            }}
            slots={{
              textField: StyledInput as React.ElementType<TextFieldProps>
            }}
          />
        </LocalizationProvider>
        {helperText != null && (
          <StyledMessage>
            <Badge color={getInputBadgeColor(color)} />
            <span>{helperText}</span>
          </StyledMessage>
        )}
      </>
    )
  }
)

export default DatePicker
