import { Divider, Grid, SingleSelectDropdown, Typography } from '@precis-digital/kurama'
import { type SingleSelectDropdownItem, type TooltipProps } from '@precis-digital/kurama'
import { useQueryIntegratedAttributions } from 'attributionModel/api'
import {
  getAttributionModelTypes,
  type AttributionModelType,
  type DefinedAttributionModelType,
  type TimePeriodType
} from 'attributionModel/components/Dashboard/DetailedReportUtils'
import { DEFINED_MODELS, GA4_EXCLUSION_LIST } from 'attributionModel/constants'
import { useMemo, useState } from 'react'
import { type AttributionResp } from 'shared/api/attributionModels'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useTranslation } from 'shared/translations'
import { objectEntries } from 'shared/utils'
import { hasValidFilters } from './utils'

export interface AttributionModel {
  name: string
  id: number
  type: AttributionModelType
}

interface ComparisonFiltersProps {
  timePeriod: string
  handleTimePeriod: (timePeriod: TimePeriodType) => void
  model: AttributionModel
  handleModel: (model: AttributionModel) => void
  currentIntegratedAttribution: AttributionResp | undefined
  isMissingDataPrevTimePeriod: boolean
  isMissingDataPrevYearTimePeriod: boolean
}

export const ComparisonFilters = ({
  timePeriod,
  handleTimePeriod,
  model,
  handleModel,
  currentIntegratedAttribution,
  isMissingDataPrevTimePeriod,
  isMissingDataPrevYearTimePeriod
}: ComparisonFiltersProps): React.ReactElement => {
  const { t, i18n } = useTranslation('attributionModel', { keyPrefix: 'dashboard' })
  const { currentClient } = useCurrentClient()
  const [currentIntegratedAttributionValue, setLocalCurrentIntegratedAttributionValue] = useState('')

  const { data: integratedAttributions, isSuccess: isModelsSuccess } = useQueryIntegratedAttributions(currentClient.id)

  const defaultModelOptions: SingleSelectDropdownItem[] = [
    {
      label: DEFINED_MODELS.lnda.label,
      value: DEFINED_MODELS.lnda.value,
      subCategory: t(`titles.rulesBasedModels`),
      tooltipProps: {
        kind: 'multiline',
        title: DEFINED_MODELS.lnda.label,
        body: t(`tooltipText.${DEFINED_MODELS.lnda.value}`)
      }
    },
    {
      label: DEFINED_MODELS.ga4Default.label,
      value: DEFINED_MODELS.ga4Default.value,
      subCategory: t(`titles.ga4Models`),
      tooltipProps: {
        kind: 'multiline',
        title: DEFINED_MODELS.ga4Default.label,
        body: t(`tooltipText.${DEFINED_MODELS.ga4Default.value}`)
      }
    },
    {
      label: DEFINED_MODELS.ga4Lnda.label,
      value: DEFINED_MODELS.ga4Lnda.value,
      subCategory: t(`titles.ga4Models`),
      tooltipProps: {
        kind: 'multiline',
        title: DEFINED_MODELS.ga4Lnda.label,
        body: t(`tooltipText.${DEFINED_MODELS.ga4Lnda.value}`)
      }
    }
  ]

  const { modelsOptions, modelsMap } = useMemo(() => {
    if (!isModelsSuccess) return { modelsOptions: [], modelsMap: {} }
    const modelTypes = getAttributionModelTypes(integratedAttributions)

    const modelOptions: SingleSelectDropdownItem[] = []
    const modelsMap: Record<string, AttributionModel> = {}
    objectEntries(modelTypes).forEach(([key, value]) => {
      value.forEach((item) => {
        modelsMap[item.id] = item
        modelOptions.push({
          label: item.name,
          value: item.id.toString(),
          subCategory: i18n?.exists(`attributionModel:dashboard.titles.${key}`)
            ? t(`titles.${key}`)
            : t(`titles.default`)
        })
      })
    })

    return { modelsOptions: modelOptions, modelsMap }
  }, [i18n, integratedAttributions, isModelsSuccess, t])

  const disabledTimePeriodDropdown = isMissingDataPrevTimePeriod && isMissingDataPrevYearTimePeriod

  const hasValidFilter = currentIntegratedAttribution !== undefined && hasValidFilters(currentIntegratedAttribution)

  const hideModel = (model: SingleSelectDropdownItem): boolean => {
    if (GA4_EXCLUSION_LIST.includes(currentClient.id.toString())) {
      return model.value !== 'ga4Default' && model.value !== 'ga4Lnda' && model.value !== 'lnda'
    } else if (currentIntegratedAttribution?.creditInput === 'rba') {
      if (!hasValidFilter) {
        return model.value !== 'ga4Default' && model.value !== 'ga4Lnda' && model.value !== 'lnda'
      }
      return model.value !== 'lnda'
    }
    if (!hasValidFilter) {
      return model.value !== 'ga4Default' && model.value !== 'ga4Lnda'
    }
    return true
  }

  const comparisonModels = [
    ...defaultModelOptions.filter((model) => hideModel(model)),
    ...modelsOptions.filter((model) => model.value !== currentIntegratedAttribution?.id?.toString())
  ]

  const showMissingDataTooltip = (hasMissingData: boolean): TooltipProps | undefined => {
    if (hasMissingData) {
      return {
        kind: 'singleline',
        title: t('missingDataWarning.noComparison')
      }
    }
    return undefined
  }

  return (
    <>
      <Divider />
      <Grid container gap="16px" item xs={12} display="inline-flex" alignItems="center">
        <Typography variant="body2">{t('with')}</Typography>
        <SingleSelectDropdown
          disabled={disabledTimePeriodDropdown}
          title={t('selectTimePeriod')}
          buttonTitle={t('selectTimePeriod')}
          buttonWidth="200px"
          value={timePeriod}
          options={[
            {
              label: t('previousTimePeriod'),
              value: 'previousTimePeriod',
              disabled: isMissingDataPrevTimePeriod,
              tooltipProps: showMissingDataTooltip(isMissingDataPrevTimePeriod)
            },
            {
              label: t('samePeriodLastYear'),
              value: 'samePeriodLastYear',
              disabled: isMissingDataPrevYearTimePeriod,
              tooltipProps: showMissingDataTooltip(isMissingDataPrevYearTimePeriod)
            }
          ]}
          onSelect={(value) => {
            handleTimePeriod(value as TimePeriodType)
          }}
        />
        <Typography variant="body2">{t('or')}</Typography>
        <SingleSelectDropdown
          title={t('selectAnotherModel')}
          buttonTitle={t('selectAnotherModel')}
          buttonWidth="200px"
          value={
            model.id.toString() === currentIntegratedAttribution?.id?.toString()
              ? currentIntegratedAttributionValue
              : model.id.toString()
          }
          options={comparisonModels}
          disabled={comparisonModels.length === 0}
          onSelect={(value) => {
            let currentValue = value
            const hasDefinedModel = Object.values(DEFINED_MODELS).find((model) => model.value === value) != null
            if (hasDefinedModel) {
              currentValue = currentIntegratedAttribution?.id?.toString() ?? ''
            }
            const model = modelsMap[currentValue]
            if (hasDefinedModel) {
              model.type = value as DefinedAttributionModelType
            }
            setLocalCurrentIntegratedAttributionValue(value)
            handleModel(model)
          }}
        />
      </Grid>
    </>
  )
}
export default ComparisonFilters
