import { Banner, Grid, SingleSelectDropdown, Typography } from '@precis-digital/kurama'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { type ReactElement } from 'react'
import { useTranslation } from 'shared/translations'
import DistributionTable from './DistributionTable'

const DistributionTab = (): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const { selectedMetric, setSelectedMetric, dataSourceMetrics, isPerformanceDataError } =
    useCurrentChannelGroupingDataSource()

  return (
    <Grid display="flex" flexDirection="column" gap="16px" paddingTop="16px">
      <Grid display="flex" justifyContent="space-between" alignItems="flex-end" height="40px">
        <Typography variant="h2">{t('dataSourceFormView.distributionTab.title')}</Typography>
        {!isPerformanceDataError && (
          <Grid
            width="fit-content"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height="fit-content"
            gap="8px"
            flexWrap="nowrap"
          >
            <Typography variant="body2">{t('dataSourceFormView.distributionTab.metricsLabel')}</Typography>

            <SingleSelectDropdown
              title={t('dataSourceFormView.distributionTab.metricsTitle')}
              buttonTitle={t('dataSourceFormView.distributionTab.metricsButtonTitle')}
              options={dataSourceMetrics.map((metric) => ({
                label: metric.label,
                value: metric.id
              }))}
              value={selectedMetric.id}
              onSelect={(value) => {
                setSelectedMetric(value)
              }}
            />
          </Grid>
        )}
      </Grid>
      {isPerformanceDataError ? (
        <Banner variant="error">{t('dataSourceFormView.distributionTab.errorFetchingData')}</Banner>
      ) : (
        <DistributionTable />
      )}
    </Grid>
  )
}

export default DistributionTab
