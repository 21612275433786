import React from 'react'

export const Move = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M13.4735 14.8747C13.4735 14.3916 13.0819 14 12.5988 14C12.1157 14 11.7241 14.3916 11.7241 14.8747C11.7241 15.3578 12.1157 15.7494 12.5988 15.7494C13.0819 15.7494 13.4735 15.3578 13.4735 14.8747Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.35003 14.8747C7.35003 14.3916 6.9584 14 6.47531 14C5.99221 14 5.60059 14.3916 5.60059 14.8747C5.60059 15.3578 5.99221 15.7494 6.47531 15.7494C6.9584 15.7494 7.35003 15.3578 7.35003 14.8747Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5962 14.8747C19.5962 14.3916 19.2046 14 18.7215 14C18.2384 14 17.8467 14.3916 17.8467 14.8747C17.8467 15.3578 18.2384 15.7494 18.7215 15.7494C19.2046 15.7494 19.5962 15.3578 19.5962 14.8747Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4735 9.12526C13.4735 8.64217 13.0819 8.25054 12.5988 8.25054C12.1157 8.25054 11.7241 8.64217 11.7241 9.12526C11.7241 9.60836 12.1157 9.99998 12.5988 9.99998C13.0819 9.99998 13.4735 9.60836 13.4735 9.12526Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.35003 9.1252C7.35003 8.64211 6.9584 8.25048 6.47531 8.25048C5.99221 8.25048 5.60059 8.64211 5.60059 9.1252C5.60059 9.6083 5.99221 9.99992 6.47531 9.99992C6.9584 9.99992 7.35003 9.6083 7.35003 9.1252Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5962 9.12526C19.5962 8.64217 19.2046 8.25054 18.7215 8.25054C18.2384 8.25054 17.8467 8.64217 17.8467 9.12526C17.8467 9.60836 18.2384 9.99998 18.7215 9.99998C19.2046 9.99998 19.5962 9.60836 19.5962 9.12526Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
