import passport from 'passport'
import { Strategy as AmazonStrategy } from 'passport-amazon'
import { Strategy as FacebookStrategy } from 'passport-facebook'
import {
  Strategy as OAuth2Strategy,
  type StrategyOptions as OAuth2StrategyOptions,
  type VerifyFunction as OAuth2VerifyFunction
} from 'passport-oauth2'
import { Strategy as SnapchatStrategy } from 'passport-snapchat'
import { Strategy as TiktokStrategy } from '@precis-digital/passport-tiktok'
import { Strategy as GoogleStrategy, type StrategyOptions } from 'passport-google-oauth20'
import { Strategy as MicrosoftStrategy } from '@precis-digital/passport-microsoft'
import { Strategy as LinkedInStrategy } from 'passport-linkedin-oauth2'
import { type CredentialShareResp, createCredential } from 'shared/api/credentials'
import logger from 'shared/logger'
import config from 'shared/config'

const DASHBOARD_URL = config('NEXT_PUBLIC_DASHBOARD_URL') as string

/***
 * Overall, this sets up an OAuth authentication flow using Passport.js and Facebook as the authentication provider.
 * It provides utility functions to generate URLs and defines the necessary authentication configurations.
 */

const log = logger().child({ module: 'data-source/oauth' })

export interface InternalProfile {
  externalUserId: string
  externalUserName: string
  externalUserEmail?: string
}

interface Profile {
  id: string
  displayName: string
}

interface AmazonProfile extends Profile {
  _json?: { email: string }
}

interface GoogleProfile extends Profile {
  _json?: { email: string }
}

interface SnapchatProfile extends Profile {
  _json?: {
    me: {
      id: string
      display_name: string
    }
  }
}

interface TiktokProfile extends Profile {
  _json?: {
    data: {
      id: string
      display_name: string
    }
  }
}

export enum SupportedPlatformsEnum {
  AMAZON = 'amazon',
  CRITEO = 'criteo',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  LINKEDIN = 'linkedin',
  MICROSOFT = 'microsoft_advertising',
  MICROSOFT_ADVERTISING = 'microsoft',
  SNAPCHAT = 'snapchat',
  TIKTOK = 'tiktok'
}

export const SUPPORTED_PLATFORMS_TO_PASSPORT_PLATFORMS = {
  microsoft_advertising: 'microsoft',
  amazon: 'amazon',
  criteo: 'oauth2',
  facebook: 'facebook',
  google: 'google',
  linkedin: 'linkedin',
  snapchat: 'snapchat',
  tiktok: 'tiktok'
}

export const SUPPORTED_PLATFORMS = [
  SupportedPlatformsEnum.AMAZON,
  SupportedPlatformsEnum.CRITEO,
  SupportedPlatformsEnum.FACEBOOK,
  SupportedPlatformsEnum.GOOGLE,
  SupportedPlatformsEnum.LINKEDIN,
  SupportedPlatformsEnum.MICROSOFT,
  SupportedPlatformsEnum.SNAPCHAT,
  SupportedPlatformsEnum.TIKTOK
] as const

export type SupportedPlatforms = (typeof SUPPORTED_PLATFORMS)[number]

export interface Config {
  clientID: string
  clientSecret: string
  developerToken?: string
  callbackURL: string
  authorizationURL?: string
  tokenURL?: string
  scopes: string[]
  strategy: (authToken: string) => passport.Strategy
  mappedProfileFields: <T extends Profile>(profile: T) => InternalProfile
  params?: {
    accessType?: string
    prompt?: string
  }
}

const defaultMappedProfileFields = (profile: Profile): InternalProfile => {
  return {
    externalUserId: profile.id,
    externalUserName: profile.displayName
  }
}

type SupportedConfigs = Record<SupportedPlatforms, Config>

export const getCallbackUrl = (platform: SupportedPlatforms): string => {
  return `${DASHBOARD_URL}/api/data-source/oauth/callback/${platform}`
}
export const generateOauthLoginUrl = (platform: string): string => {
  return `${DASHBOARD_URL}/api/data-source/oauth/login/${platform}`
}

const verify = ({ authToken, platform }: { platform: SupportedPlatforms; authToken: string }) =>
  function (
    accessToken: string,
    refreshToken: string,
    profile: Profile,
    done: (err: Error | null, credential?: CredentialShareResp) => void
  ) {
    const internalProfile: InternalProfile = supportedPlatformConfigs[platform].mappedProfileFields(profile)
    log.info(`Creating credential from oauth ${internalProfile.externalUserId}`)
    createCredential(
      {
        platform,
        scopes: supportedPlatformConfigs[platform].scopes,
        accessToken,
        refreshToken,
        ...internalProfile
      },
      authToken
    )
      .then((credential) => {
        done(null, credential)
      })
      .catch((err) => {
        log.error(err)
        done(err)
      })
  }

class CriteoStrategy extends OAuth2Strategy {
  constructor(options: OAuth2StrategyOptions, verify: OAuth2VerifyFunction) {
    super(options, verify)
    this._oauth2.useAuthorizationHeaderforGET(true)
  }
}

export const supportedPlatformConfigs: SupportedConfigs = {
  [SupportedPlatformsEnum.AMAZON]: {
    clientID: config('AMAZON_APP_ID') as string,
    clientSecret: config('AMAZON_APP_SECRET') as string,
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.AMAZON),
    authorizationURL: 'https://eu.account.amazon.com/ap/oa',
    tokenURL: 'https://api.amazon.co.uk/auth/o2/token',
    scopes: ['advertising::campaign_management', 'profile'],
    mappedProfileFields: (profile: AmazonProfile): InternalProfile => {
      return {
        externalUserId: profile._json?.email as string,
        externalUserName: profile.displayName
      }
    },
    strategy: (authToken: string) => {
      const config = {
        clientID: supportedPlatformConfigs.amazon.clientID,
        clientSecret: supportedPlatformConfigs.amazon.clientSecret,
        callbackURL: supportedPlatformConfigs.amazon.callbackURL,
        authorizationURL: supportedPlatformConfigs.amazon.authorizationURL,
        tokenURL: supportedPlatformConfigs.amazon.tokenURL
      }
      return new AmazonStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.AMAZON }))
    }
  },
  [SupportedPlatformsEnum.CRITEO]: {
    clientID: config('CRITEO_API_ID') as string,
    clientSecret: config('CRITEO_API_SECRET') as string,
    authorizationURL: 'https://consent.criteo.com/request',
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.CRITEO),
    tokenURL: 'https://api.criteo.com/oauth2/token',
    scopes: [],
    mappedProfileFields: (profile: AmazonProfile): InternalProfile => {
      return {
        externalUserId: '',
        externalUserName: ''
      }
    },
    strategy: (authToken: string) => {
      const config = {
        clientID: supportedPlatformConfigs.criteo.clientID,
        clientSecret: supportedPlatformConfigs.criteo.clientSecret,
        callbackURL: supportedPlatformConfigs.criteo.callbackURL,
        authorizationURL: supportedPlatformConfigs.criteo.authorizationURL as string,
        tokenURL: supportedPlatformConfigs.criteo.tokenURL as string
      }
      return new CriteoStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.CRITEO }))
    }
  },
  [SupportedPlatformsEnum.FACEBOOK]: {
    clientID: config('FACEBOOK_APP_ID') as string,
    clientSecret: config('FACEBOOK_APP_SECRET') as string,
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.FACEBOOK),
    scopes: ['business_management', 'instagram_basic', 'ads_management', 'ads_read', 'public_profile'],
    mappedProfileFields: defaultMappedProfileFields,
    strategy: (authToken: string) => {
      const config = {
        clientID: supportedPlatformConfigs.facebook.clientID,
        clientSecret: supportedPlatformConfigs.facebook.clientSecret,
        callbackURL: supportedPlatformConfigs.facebook.callbackURL
      }
      return new FacebookStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.FACEBOOK }))
    }
  },
  [SupportedPlatformsEnum.GOOGLE]: {
    clientID: config('GOOGLE_APP_ID') as string,
    clientSecret: config('GOOGLE_APP_SECRET') as string,
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.GOOGLE),
    scopes: [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/bigquery',
      'https://www.googleapis.com/auth/cloud-platform',
      'https://www.googleapis.com/auth/analytics.readonly',
      'https://www.googleapis.com/auth/display-video',
      'https://www.googleapis.com/auth/adwords',
      'https://www.googleapis.com/auth/spreadsheets.readonly',
      'https://www.googleapis.com/auth/webmasters',
      'https://www.googleapis.com/auth/doubleclickbidmanager',
      'https://www.googleapis.com/auth/content',
      'https://www.googleapis.com/auth/tagmanager.edit.containers',
      'https://www.googleapis.com/auth/analytics.edit'
    ],
    params: {
      accessType: 'offline',
      prompt: 'consent'
    },
    mappedProfileFields: (profile: GoogleProfile): InternalProfile => {
      return {
        externalUserId: profile.id,
        externalUserName: profile.displayName,
        externalUserEmail: profile._json?.email
      }
    },
    strategy: (authToken: string) => {
      const config: StrategyOptions = {
        clientID: supportedPlatformConfigs.google.clientID,
        clientSecret: supportedPlatformConfigs.google.clientSecret,
        callbackURL: supportedPlatformConfigs.google.callbackURL,
        passReqToCallback: false
      }

      return new GoogleStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.GOOGLE }))
    }
  },
  [SupportedPlatformsEnum.LINKEDIN]: {
    clientID: config('LINKEDIN_APP_ID') as string,
    clientSecret: config('LINKEDIN_APP_SECRET') as string,
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.LINKEDIN),
    mappedProfileFields: defaultMappedProfileFields,
    scopes: [
      'r_organization_social',
      'r_1st_connections_size',
      'r_ads_reporting',
      'rw_organization_admin',
      'r_basicprofile',
      'r_ads',
      'w_member_social'
    ],
    strategy: (authToken: string) => {
      const config = {
        clientID: supportedPlatformConfigs.linkedin.clientID,
        clientSecret: supportedPlatformConfigs.linkedin.clientSecret,
        callbackURL: supportedPlatformConfigs.linkedin.callbackURL,
        scope: supportedPlatformConfigs.linkedin.scopes
      }
      return new LinkedInStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.LINKEDIN }))
    }
  },
  [SupportedPlatformsEnum.MICROSOFT]: {
    clientID: config('MICROSOFT_ADVERTISING_APP_ID') as string,
    clientSecret: config('MICROSOFT_ADVERTISING_APP_SECRET') as string,
    developerToken: config('MICROSOFT_ADVERTISING_DEVELOPER_TOKEN') as string,
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.MICROSOFT),
    scopes: ['https://ads.microsoft.com/msads.manage', 'offline_access'],
    mappedProfileFields: defaultMappedProfileFields,
    strategy: (authToken: string) => {
      const config = {
        clientID: supportedPlatformConfigs.microsoft_advertising.clientID,
        clientSecret: supportedPlatformConfigs.microsoft_advertising.clientSecret,
        callbackURL: supportedPlatformConfigs.microsoft_advertising.callbackURL,
        scope: supportedPlatformConfigs.microsoft_advertising.scopes,
        developerToken: supportedPlatformConfigs.microsoft_advertising.developerToken
      }
      return new MicrosoftStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.MICROSOFT }))
    }
  },
  [SupportedPlatformsEnum.SNAPCHAT]: {
    clientID: config('SNAPCHAT_APP_ID') as string,
    clientSecret: config('SNAPCHAT_APP_SECRET') as string,
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.SNAPCHAT),
    scopes: ['snapchat-marketing-api'],
    mappedProfileFields: (profile: SnapchatProfile): InternalProfile => {
      return {
        externalUserId: profile._json?.me?.id as string,
        externalUserName: profile._json?.me?.display_name as string
      }
    },
    strategy: (authToken: string) => {
      const config = {
        clientID: supportedPlatformConfigs.snapchat.clientID,
        clientSecret: supportedPlatformConfigs.snapchat.clientSecret,
        callbackURL: supportedPlatformConfigs.snapchat.callbackURL,
        scope: supportedPlatformConfigs.snapchat.scopes,
        profileURL: 'https://adsapi.snapchat.com/v1/me'
      }
      return new SnapchatStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.SNAPCHAT }))
    }
  },
  [SupportedPlatformsEnum.TIKTOK]: {
    clientID: config('TIKTOK_APP_ID') as string,
    clientSecret: config('TIKTOK_APP_SECRET') as string,
    callbackURL: getCallbackUrl(SupportedPlatformsEnum.TIKTOK),
    scopes: [''],
    mappedProfileFields: (profile: TiktokProfile): InternalProfile => {
      return {
        externalUserId: profile._json?.data.id.toString() as string,
        externalUserName: profile._json?.data.display_name as string
      }
    },
    strategy: (authToken: string) => {
      const config = {
        clientID: supportedPlatformConfigs.tiktok.clientID,
        clientSecret: supportedPlatformConfigs.tiktok.clientSecret,
        callbackURL: supportedPlatformConfigs.tiktok.callbackURL,
        authorizationURL: 'https://business-api.tiktok.com/portal/auth',
        tokenURL: 'https://business-api.tiktok.com/open_api/v1.2/oauth2/access_token/',
        profileURL: 'https://business-api.tiktok.com/open_api/v1.2/user/info/'
      }
      return new TiktokStrategy(config, verify({ authToken, platform: SupportedPlatformsEnum.TIKTOK }))
    }
  }
}

export const addStrategiesForAllSupportedPlatforms = (authToken: string): void => {
  SUPPORTED_PLATFORMS.forEach((platform) => {
    passport.use(supportedPlatformConfigs[platform].strategy(authToken))
  })
}
