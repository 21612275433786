import { Avatar, DotsLoader, Grid, Tooltip, Typography, styled } from '@precis-digital/kurama'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { type ReactNode, type ReactElement } from 'react'
import formatToMoney, { formatToNumber } from 'shared/numberFormat'
import ConnectedDataSourcesAnnotation from '../ConnectedDataSourcesAnnotation'
import { useCurrentClient } from 'shared/context/ClientContext'
import { formatDateToString } from 'shared/dateFns'
import NoPerformanceDataSymbol from '../NoPerformanceDataSymbol'
import { useTranslation } from 'shared/translations'

interface HeaderProps {
  shouldRenderPerformanceData: boolean
}

const Header = ({ shouldRenderPerformanceData }: HeaderProps): ReactElement => {
  const {
    dataSourceItem,
    dataSource,
    selectedMetric,
    performanceData,
    isPerformanceDataLoading,
    isPerformanceDataError
  } = useCurrentChannelGroupingDataSource()
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('channelGrouping')

  const metricSum = performanceData?.sumValues?.[selectedMetric.id] ?? 0

  const renderPerformanceDataValue = (): ReactNode => {
    if (isPerformanceDataLoading) {
      return <></>
    }
    if (isPerformanceDataError) {
      return <NoPerformanceDataSymbol />
    }
    return selectedMetric.type === 'currency'
      ? formatToMoney({
          value: metricSum,
          currencyCode: currentClient.currency
        })
      : formatToNumber(metricSum)
  }

  return (
    <Grid display="flex" justifyContent="space-between" height="76px" alignItems="flex-start">
      <Grid display="flex" gap="16px">
        <Avatar kind="image" imageUrl={dataSourceItem.iconUrl} size="large" />
        <Grid display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="h2">{dataSourceItem.label}</Typography>
          <ConnectedDataSourcesAnnotation
            connectedDataSources={dataSourceItem.connectedDataSources}
            dataSource={dataSource}
            accountsMetricValues={performanceData?.accountsValues}
            selectedMetric={selectedMetric}
            isPerformanceDataError={isPerformanceDataError}
          />
        </Grid>
      </Grid>
      {shouldRenderPerformanceData && (
        <Grid display="flex" flexDirection="column" alignItems="flex-end">
          <Grid display="flex" gap="12px">
            {isPerformanceDataLoading && <DotsLoader width={36} height={36} />}
            <Typography variant="h2">
              {renderPerformanceDataValue()} {selectedMetric.label}
            </Typography>
          </Grid>
          <Tooltip
            title={`${formatDateToString({ date: performanceData.dateStart })} to ${formatDateToString({
              date: performanceData.dateEnd
            })}`}
            body={t('performanceDataDateRangeTooltip')}
            kind="multiline"
          >
            <StyledTypographyAnnotation variant="body3">
              {t('dataSourceFormView.duringLast14Days')}
            </StyledTypographyAnnotation>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

const StyledTypographyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone120,
  fontStyle: 'italic'
}))

export default Header
