import React, { useState } from 'react'
import { PopUp, Typography, Label, Button, Divider } from '@precis-digital/kurama'
import { useUpdateMember } from 'workspace/api'
import { useAuth } from 'shared/context/AuthContext'
import {
  StyledCautionCard,
  StyledUpperText,
  StyledLeaveWorkspacePopupFooter,
  StyledTextSpan,
  StyledCautionText
} from '../../workspace/components/Popup/styles'
import { MembersView } from '../../workspace/components/Popup/MembersView'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useDeleteUsers } from 'profile/api'
import { useTranslation } from 'react-i18next'
import { useIsSoloAdmin } from 'shared/hooks/useIsSoloAdmin'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import DeleteInput from '../../shared/components/DeleteInput'
import yellowCone from 'public/assets/images/yellow_cone.png'
import Image from 'next/image'
import { useReload } from 'shared/components/Router'

const CONFIRMATION_DELETE_WORD = 'DELETE'

const DeleteProfilePopup = (): React.ReactElement => {
  const deleteAccountPopup = useOverScreen('deleteAccountPopup')
  const { t } = useTranslation('user')
  const isSoloAdmin = useIsSoloAdmin()

  const { currentUser, logOut } = useAuth()
  const { currentClient } = useCurrentClient()

  const { mutateAsync: updateUserAsync } = useUpdateMember()
  const { mutateAsync: deleteUserAsync } = useDeleteUsers()
  const [deleteInputValue, setDeleteInputValue] = useState('')
  const [hasUnderstanding, setHasUnderstanding] = useState(false)

  const reload = useReload()

  const [selectedNewAdmin, setNewAdmin] = useState<string>('')

  const hasManuallyConfirmedToDelete = deleteInputValue === CONFIRMATION_DELETE_WORD
  const hasSelectedNewAdmin = selectedNewAdmin !== ''
  const mustSelectNewAdmin = isSoloAdmin

  const deleteInputColor = hasManuallyConfirmedToDelete ? 'success' : 'error'

  const handleSelectedNewAdmin = (adminId: string): void => {
    setNewAdmin(adminId)
  }

  const canLeaveWorkspace = mustSelectNewAdmin
    ? hasManuallyConfirmedToDelete && hasSelectedNewAdmin && hasUnderstanding
    : hasManuallyConfirmedToDelete && hasUnderstanding

  const handleDeleteAccount = async (): Promise<void> => {
    if (mustSelectNewAdmin) {
      await updateUserAsync({ clientId: currentClient.id, userId: selectedNewAdmin, role: 'admin' })
    }
    await deleteUserAsync(currentUser?.id ?? '')
    logOut()
    reload()
  }

  const handlePopupClose = (): void => {
    deleteAccountPopup.remove()
  }

  return (
    <>
      <PopUp handleOpen={handlePopupClose} open={deleteAccountPopup.visible}>
        <Image
          src={yellowCone.src}
          width={544}
          height={179}
          alt="yellow cone"
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
        <StyledUpperText>
          <Typography variant="h2">{t('titles.deleteAccountPopup')}</Typography>
        </StyledUpperText>
        <StyledCautionCard>
          <Label color="orange" text="Caution" />
          <StyledCautionText variant="h4">
            <StyledTextSpan>{t('descriptions.actionBold')} </StyledTextSpan> {t('descriptions.actionLight')}
          </StyledCautionText>
        </StyledCautionCard>
        <DeleteInput
          deleteInputValue={deleteInputValue}
          hasUnderstanding={hasUnderstanding}
          setHasUnderstanding={setHasUnderstanding}
          setDeleteInputValue={setDeleteInputValue}
          confirmDeleteWord={CONFIRMATION_DELETE_WORD}
          deleteInputColor={deleteInputColor}
          translations={t}
        />
        {isSoloAdmin ? <MembersView onMemberSelected={handleSelectedNewAdmin} id={selectedNewAdmin} /> : null}
        <Divider />

        <StyledLeaveWorkspacePopupFooter>
          <Button onClick={handlePopupClose} scheme="light" variant="outlined">
            {t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              void handleDeleteAccount()
            }}
            scheme="light"
            variant="filled"
            disabled={!canLeaveWorkspace}
          >
            {t('buttons.delete')}
          </Button>
        </StyledLeaveWorkspacePopupFooter>
      </PopUp>
    </>
  )
}

export default OverScreen.create(DeleteProfilePopup)
