import { Chip, type Theme, styled } from '@precis-digital/kurama'
import { type PercentageStatus, getPercentageDisplayValueAndStatus } from 'shared/utils'

interface PercentageChipProps {
  presentValue: number
  previousValue: number
  reverseColorScheme?: boolean
}

export const getTextColorByDifferenceIndicatorAndColorScheme = ({
  theme,
  differenceIndicator,
  reverseColorScheme
}: {
  theme: Theme
  differenceIndicator: PercentageStatus
  reverseColorScheme: boolean
}): string => {
  let color = theme.palette.neutrals.stone150
  switch (differenceIndicator) {
    case 'positive':
      color = !reverseColorScheme ? theme.palette.semantic.success100 : theme.palette.semantic.error100
      break
    case 'negative':
      color = !reverseColorScheme ? theme.palette.semantic.error100 : theme.palette.semantic.success100
      break
  }

  return color
}

export const PercentageChip = ({
  presentValue,
  previousValue,
  reverseColorScheme = false
}: PercentageChipProps): React.ReactElement => {
  const { displayValue, percentageStatus } = getPercentageDisplayValueAndStatus(presentValue, previousValue)
  return (
    <StyledChip label={displayValue} differrenceIndicator={percentageStatus} reverseColorScheme={reverseColorScheme} />
  )
}

export default PercentageChip

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'differrenceIndicator' && prop !== 'reverseColorScheme'
})<{ differrenceIndicator: PercentageStatus; reverseColorScheme: boolean; theme?: Theme }>(
  ({ theme, differrenceIndicator: differrenceIndicatorColor, reverseColorScheme }) => ({
    backgroundColor:
      differrenceIndicatorColor === 'equal'
        ? theme.palette.neutrals.stone100
        : differrenceIndicatorColor === 'positive'
        ? !reverseColorScheme
          ? theme.palette.semantic.success05
          : theme.palette.semantic.error05
        : !reverseColorScheme
        ? theme.palette.semantic.error05
        : theme.palette.semantic.success05,

    color: getTextColorByDifferenceIndicatorAndColorScheme({
      differenceIndicator: differrenceIndicatorColor,
      reverseColorScheme,
      theme
    })
  })
)
