import { CSSObject, Theme } from '../../theme'
import { styled } from '../../../utils'
import Grid from '../../Grid'
import Typography from '../../Typography'
import { AnnotationLineType } from './types'
import { listItemClasses } from '@mui/material'

/**
 * The main component for the Dropdown Item
 */
export const StyledLiDropdownItem = styled('li')(
  ({ disabled, theme }: { disabled: boolean; theme?: Theme }): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    userSelect: 'none',
    width: 'calc(100% + 20px)',
    margin: '0px -8px 0px -12px',
    borderRadius: theme?.borderRadius.medium,
    borderWidth: '0px 8px 0px 12px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    padding: '8px 8px 8px 0px',
    ...(!disabled && {
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme?.palette.neutrals.stone90,
        borderColor: theme?.palette.neutrals.stone90
      }
    }),
    ...(disabled && {
      svg: { color: theme?.palette.neutrals.stone120 }
    }),
    [`&.${listItemClasses.focusVisible}`]: {
      cursor: 'pointer',
      backgroundColor: theme?.palette.neutrals.stone90,
      borderColor: theme?.palette.neutrals.stone90
    }
  })
)

/**
 * The Grid container that ensures all the content on the left
 * stays in the same column.
 * All content on the left is essentially all content that is not
 * the optionally passed control.
 */
export const StyledGridLeftContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}))

export const StyledTypographyLineClamped = styled(Typography)(
  ({ disabled, theme }: { disabled?: boolean; theme?: Theme }) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    color: disabled === true ? theme?.palette.neutrals.stone120 : undefined,
    width: '100%'
  })
)

/**
 * Styling of the Typography to create the grey annotation.
 */
export const StyledTypographyAnnotation = styled(StyledTypographyLineClamped)(
  ({
    theme,
    annotationLineType,
    hasIcon
  }: {
    theme?: Theme
    annotationLineType: AnnotationLineType
    hasIcon: boolean
  }): CSSObject => ({
    color: theme?.palette.neutrals.stone120,
    marginLeft: hasIcon ? '32px' : '0px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: annotationLineType === 'singleline' ? '1' : '2',
    WebkitBoxOrient: 'vertical'
  })
)

/**
 * Styling of the TopRow which is the icon + children.
 */
export const StyledGridTopRowContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
    h4: {
      padding: '3px 0px 1px 0px'
    }
  })
)

/**
 * Styling to ensure the icon is centered.
 */
export const StyledGridIconContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0
  })
)

/**
 * Styling to ensure the control is centered.
 */
export const StyledGridControlContainer = styled(Grid)(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    margin: '-9px',
    marginLeft: '16px',
    'span.MuiSwitch-root': {
      margin: '9px 0px'
    }
  })
)
