import React from 'react'

export const Copy = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2161 9.62642H12.3068C10.5955 9.62642 9.52283 10.8256 9.52283 12.5233V17.1031C9.52283 18.8008 10.5905 20 12.3068 20H17.2161C18.9324 20 20 18.8008 20 17.1031V12.5233C20 10.8256 18.9324 9.62642 17.2161 9.62642Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.78395 14.3736C5.06768 14.3736 4 13.1743 4 11.4767V6.8969C4 5.19923 5.07267 4 6.78395 4H11.6933C13.4095 4 14.4772 5.19923 14.4772 6.8969"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
