import React, { type ReactElement } from 'react'
import { type ListViewCellProps } from '../../types'
import { Avatar, AvatarGroup, Grid, Tooltip, type TooltipProps } from '@precis-digital/kurama'
import BaseCell from './BaseCell'

interface AvatarCellsWithTooltipProps<T extends object> extends ListViewCellProps<T> {
  tooltipProps: Omit<TooltipProps, 'children'> & { kind: 'multiline'; body: ReactElement }
  avatars: Array<{
    name: string
    imageUrl?: string
  }>
}

const AvatarCellsWithTooltip = <T extends object>({
  tooltipProps,
  avatars,
  ...props
}: AvatarCellsWithTooltipProps<T>): ReactElement => {
  return (
    <BaseCell width={props.column.parsedWidth}>
      <Tooltip {...tooltipProps}>
        <Grid container justifyContent="center">
          <AvatarGroup max={2} size="small">
            {avatars.map((avatar) => {
              if (avatar.imageUrl != null) {
                return <Avatar key={avatar.name} size="small" kind="image" imageUrl={avatar.imageUrl} />
              }
              return <Avatar key={avatar.name} size="small" kind="label" label={avatar.name} />
            })}
          </AvatarGroup>
        </Grid>
      </Tooltip>
    </BaseCell>
  )
}

export default AvatarCellsWithTooltip
