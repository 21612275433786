import React, { ReactElement } from 'react'
import {
  StyledBoxChevronContainer,
  StyledCollapseNavItemChildren,
  StyledGridNavContentSubItemContainer,
  StyledListItemNavItem,
  StyledListNavContentContainer
} from './styles'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import {
  NavContentItemWithChildren as TNavContentItemWithChildren,
  NavContentItemWithoutChildren as TNavContentItemWithoutChildren,
  NavContentProps,
  NavContentWithChildrenItemProps,
  NavContentWithoutChildrenItemProps,
  NavContentSubItemProps
} from './types'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, CollapseLeftIcon, ExpandRightIcon } from '../Icons'
import Grid from '../Grid'
import Typography from '../Typography'

const NavContent = ({
  content,
  selectedIndex,
  selectedChildIndex,
  isNavBarOpen,
  handleNavBarToggle,
  handleContentItemWithChildrenToggle,
  expandedIndexes
}: NavContentProps): ReactElement => {
  return (
    <StyledListNavContentContainer open={isNavBarOpen}>
      <StyledListItemNavItem>
        <ListItemButton onClick={handleNavBarToggle}>
          <ListItemIcon>{isNavBarOpen ? <CollapseLeftIcon /> : <ExpandRightIcon />}</ListItemIcon>
          <ListItemText
            primary="Hide Menu"
            primaryTypographyProps={{
              variant: 'body3'
            }}
          />
        </ListItemButton>
      </StyledListItemNavItem>
      {content.map((item, index) => {
        if ((item as TNavContentItemWithChildren).subItems != null) {
          return (
            <NavContentItemWithChildren
              key={`${item.label}-${index}`}
              {...item}
              isSelected={index === selectedIndex}
              selectedChildIndex={selectedChildIndex}
              isNavBarOpen={isNavBarOpen}
              handleContentItemWithChildrenToggle={handleContentItemWithChildrenToggle}
              isExpanded={expandedIndexes.includes(index)}
              index={index}
            />
          )
        } else {
          return (
            <NavContentItemWithoutChildren
              key={`${item.label}-${index}`}
              {...(item as TNavContentItemWithoutChildren)}
              isSelected={index === selectedIndex}
            />
          )
        }
      })}
    </StyledListNavContentContainer>
  )
}

const NavContentItemWithoutChildren = ({
  label,
  Link,
  path,
  IconComponent,
  isSelected,
  textColor
}: NavContentWithoutChildrenItemProps): ReactElement => {
  return (
    <Link href={path}>
      <StyledListItemNavItem textColor={textColor} isSelected={isSelected}>
        <ListItemButton>
          <ListItemIcon>{IconComponent}</ListItemIcon>
          <ListItemText primary={label} primaryTypographyProps={{ variant: 'h5' }} />
        </ListItemButton>
      </StyledListItemNavItem>
    </Link>
  )
}

const NavContentItemWithChildren = ({
  label,
  IconComponent,
  subItems = [],
  selectedChildIndex,
  isSelected,
  isExpanded,
  isNavBarOpen,
  handleContentItemWithChildrenToggle,
  index
}: NavContentWithChildrenItemProps): ReactElement => {
  const handleClick = (): void => {
    handleContentItemWithChildrenToggle(index)
  }

  return (
    <>
      <StyledListItemNavItem isSelected={isSelected} isExpanded={isExpanded && isNavBarOpen}>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{IconComponent}</ListItemIcon>
          <ListItemText primary={label} primaryTypographyProps={{ variant: 'h5' }} />
          <StyledBoxChevronContainer isNavBarOpen={isNavBarOpen}>
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </StyledBoxChevronContainer>
        </ListItemButton>
      </StyledListItemNavItem>
      <StyledCollapseNavItemChildren
        in={isExpanded && isNavBarOpen}
        open={isExpanded && isNavBarOpen}
        isSelected={isSelected}
      >
        <Grid paddingTop="4px" gap="2px" paddingBottom="16px" display="flex" flexDirection="column">
          {subItems.map((item, index) => (
            <NavContentItemChild
              key={`${item.label}-${item.path}`}
              {...item}
              isSelected={isSelected && selectedChildIndex === index}
              textColor={item.textColor}
              IconComponent={item.IconComponent}
            />
          ))}
        </Grid>
      </StyledCollapseNavItemChildren>
    </>
  )
}

const NavContentItemChild = ({
  label,
  Link,
  path,
  isSelected,
  textColor,
  IconComponent
}: NavContentSubItemProps): ReactElement => {
  return (
    <Link href={path}>
      <ListItem>
        <StyledGridNavContentSubItemContainer
          hasCustomIcon={IconComponent != null}
          textColor={textColor}
          isSelected={isSelected}
        >
          <Grid width="24px" height="24px" display="block" flexShrink={0}>
            {IconComponent ?? <CheckIcon />}
          </Grid>
          <Typography variant={isSelected ? 'h5' : 'body2'} title={label}>
            {label}
          </Typography>
        </StyledGridNavContentSubItemContainer>
      </ListItem>
    </Link>
  )
}

export default NavContent
