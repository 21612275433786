import { accounts, users, type fetch, credentials } from 'shared/api'
import {
  useQuery,
  useMutation,
  useQueryClient,
  type UseQueryResult,
  type UseMutationResult,
  type UseInfiniteQueryResult
} from 'react-query'
import { type Platforms } from 'shared/api/accounts'
import { useInfiniteQuery } from 'react-query'
import { type ExternalAccountsResp } from 'shared/api/credentials'

export const queryKeys = {
  clientAccounts: 'clientAccounts',
  externalAccounts: 'externalAccounts',
  clientCredentials: 'clientCredentials'
}

export const useQueryClientAccounts = (
  clientId: string,
  enabled: boolean = true
): UseQueryResult<accounts.ClientAccountsResp[], fetch.ClientError> => {
  return useQuery([queryKeys.clientAccounts], async () => await accounts.getClientAccounts(clientId), {
    enabled: clientId !== '' && enabled,
    refetchOnWindowFocus: false
  })
}

export const useQueryClientAccountPlatform = (
  clientId: string,
  platform: Platforms
): UseQueryResult<accounts.ClientAccountsResp[], fetch.ClientError> => {
  return useQuery(
    [queryKeys.clientAccounts, clientId, platform],
    async () => await accounts.getClientAccountPlatform(clientId, platform),
    {
      enabled: clientId !== '',
      refetchOnWindowFocus: false
    }
  )
}

export const useDeleteClientAccounts = (
  clientId: string
): UseMutationResult<accounts.ClientAccountsResp, fetch.ClientError, number> => {
  const queryClient = useQueryClient()

  return useMutation(async (accountId: number) => await accounts.deleteClientAccount(clientId, accountId), {
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries(queryKeys.clientAccounts)
    }
  })
}

export const useQueryClientCredentials = (
  userId: string,
  platform: string
): UseQueryResult<users.CredentialsResp[], fetch.ClientError> => {
  return useQuery(
    [queryKeys.clientCredentials, userId, platform],
    async () => await users.getUserCredentials(userId, platform),
    {
      enabled: false
    }
  )
}

export const useQueryExternalAccounts = (
  platform: string,
  credentialId: string,
  searchQuery: string
): UseInfiniteQueryResult<ExternalAccountsResp, fetch.ClientError> => {
  return useInfiniteQuery(
    [queryKeys.externalAccounts, credentialId, platform, searchQuery],
    async ({ pageParam }) => {
      const response = await credentials.getExternalAccounts(platform, credentialId, pageParam, searchQuery)
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.nextCursor != null && lastPage.nextCursor !== '') {
          return lastPage.nextCursor
        }
        return undefined
      },
      enabled: credentialId !== ''
    }
  )
}

export const useAddAccount = (): UseMutationResult<
  accounts.AddAccountResp,
  fetch.ClientError,
  accounts.AddAccountReq
> => {
  const queryClient = useQueryClient()
  return useMutation(accounts.addAccount, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.clientAccounts)
    }
  })
}

export const useReconnectAccount = (): UseMutationResult<
  accounts.AddAccountResp,
  fetch.ClientError,
  accounts.ReconnectAccountReq
> => {
  const queryClient = useQueryClient()
  return useMutation(accounts.reconnectAccount, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.clientAccounts)
    }
  })
}

export const useUpdateRecommendationsForAccount = (): UseMutationResult<
  accounts.AddAccountResp,
  fetch.ClientError,
  accounts.UpdateRecommendationsForAccountReq
> => {
  const queryClient = useQueryClient()
  return useMutation(accounts.updateRecommendationsForAccount, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.clientAccounts)
    }
  })
}
