import { Link as MUILink } from '@mui/material'
import { CSSObject } from '@mui/material/styles'
import { styled } from '../../utils'
import { StyledLinkProps } from './types'

export const StyledLink = styled(MUILink)(
  ({ theme }: StyledLinkProps): CSSObject => ({
    textDecoration: 'none',
    '&:link': {
      color: theme?.palette.main.blue100
    },
    '&:hover': {
      textDecoration: 'underline'
    }
  })
) as typeof MUILink
