import Rollbar from 'rollbar'
import config from 'shared/config'
import logger from 'shared/logger'
import { isClientEnvironment } from 'shared/utils'

let serverRollbar: Rollbar
let clientRollbar: Rollbar

const log = logger().child({
  namespace: 'errorReporter'
})

const canReportErrorToErrorReporter = config('APP_ENV') === 'production' || config('APP_ENV') === 'staging'

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: isClientEnvironment()
    ? (config('NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN') as string)
    : (config('ROLLBAR_SERVER_TOKEN') as string),
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: config('APP_ENV') as string
}

export const rollbarBrowserConfig: Rollbar.Configuration = {
  ...rollbarConfig,
  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: true,
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: false
  },
  payload: {
    environment: config('APP_ENV') as string,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config('NEXT_PUBLIC_VERSION') as string,
        guess_uncaught_frames: true
      }
    }
  }
}

export const rollbarServerConfig: Rollbar.Configuration = {
  ...rollbarConfig,
  payload: {
    environment: config('APP_ENV') as string,
    server: {
      code_version: config('NEXT_PUBLIC_VERSION') as string
    }
  }
}
if (canReportErrorToErrorReporter) {
  serverRollbar = new Rollbar(rollbarServerConfig)
  clientRollbar = new Rollbar(rollbarBrowserConfig)
}

export const logAndReportError = (err: Error): void => {
  log.error(err.message)
  if (canReportErrorToErrorReporter) {
    const rollbar = isClientEnvironment() ? clientRollbar : serverRollbar
    try {
      rollbar.error(err)
    } catch (e) {
      log.error(`Reporting error failed: ${(e as Error).message}`)
    }
  }
}

export const transform = ({ sessionUrl }: { sessionUrl: string }): void => {
  try {
    clientRollbar.configure({
      transform: (payload: Rollbar.Payload) => {
        payload.sessionUrl = sessionUrl
      }
    })
  } catch (e) {
    log.error(`Error transforming payload: ${(e as Error).message}`)
  }
}
