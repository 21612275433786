import { Avatar, Grid, Typography } from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'

interface FormLabelProps {
  labelText: React.ReactNode
  required?: boolean
  iconUrl?: string
  secondaryLabel?: React.ReactNode
}

const FormLabel = ({ labelText, required, iconUrl, secondaryLabel }: FormLabelProps): React.ReactElement => {
  const { t } = useTranslation('common')

  return (
    <>
      <Grid display="flex" gap="8px" alignItems="center">
        {iconUrl != null && <Avatar size="small" kind="image" imageUrl={iconUrl} />}
        <Typography variant="h5">{labelText}</Typography>
        {required !== undefined && (
          <Typography variant="body3">{required ? t('form.required') : t('form.optional')}</Typography>
        )}
      </Grid>
      <Typography variant="body3">{secondaryLabel}</Typography>
    </>
  )
}

export default FormLabel
