import { styled } from '../../../utils'
import { Theme, CSSObject } from '../../theme'
import { Tab } from '@mui/material'
import { AdornmentPosition } from './types'

const calculatePadding = (adornmentPosition?: AdornmentPosition): string => {
  switch (adornmentPosition) {
    case 'left':
      return '8px 16px 8px 8px'
    case 'right':
      return '8px 8px 8px 16px'
    default:
      return '8px 16px'
  }
}

export const StyledTab = styled(Tab)(
  ({ theme, adornmentPosition }: { theme?: Theme; adornmentPosition?: AdornmentPosition }): CSSObject => ({
    fontFamily: theme?.typography.h5.fontFamily,
    textTransform: 'initial',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '40px',
    gap: '4px',
    padding: calculatePadding(adornmentPosition),
    div: {
      alignSelf: 'center'
    },
    color: theme?.palette.secondary.main,
    '&.Mui-disabled': {
      color: theme?.palette.neutrals.stone120
    }
  })
)
