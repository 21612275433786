import { Card, Grid, styled, Button, Accordion, Typography } from '@precis-digital/kurama'
import { type CSSObject } from '@mui/material/styles'

interface StyledCardProps {
  active?: boolean
  shadow?: boolean
  condensedMargin?: boolean
  width?: string
}

interface StyledCardContentProps {
  active?: boolean
}

export const StyledAvatarContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  paddingBottom: '6px',
  maxWidth: '350px',
  '>div': {
    margin: '0 auto',
    marginTop: theme.spacing(4)
  },
  h2: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  button: {
    display: 'inline-flex',
    marginBottom: '6px',
    marginTop: theme.spacing(3),
    width: '100%'
  }
}))

export const StyledCardContent = styled('div')<StyledCardContentProps>(
  ({ active, theme }): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: active !== false ? 'pointer' : 'default',
    div: {
      ':first-of-type': {
        marginRight: theme.spacing(3)
      },
      '&:nth-of-type(2)': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '48px'
      }
    },
    h3: {
      maxWidth: '166px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    span: {
      maxWidth: '166px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  })
)

export const StyledGraySpan = styled('span')(
  ({ theme }): CSSObject => ({
    color: theme.palette.neutrals.stone150
  })
)

export const StyledCard = styled(Card)<StyledCardProps>(
  ({ active, shadow = false, condensedMargin = false, width, theme }): CSSObject => ({
    maxWidth: '100%',
    width,
    marginTop: condensedMargin ? '8px' : '16px',
    marginBottom: condensedMargin ? '8px' : '16px',
    button: {
      alignSelf: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    ...(active !== false && {
      ':hover': {
        boxShadow: '0px 6px 12px rgba(112, 144, 176, 0.12)'
      }
    }),
    hr: {
      width
    },
    boxShadow: shadow ? '0px 6px 12px rgba(112, 144, 176, 0.12)' : 'none'
  })
)

export const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  bottom: 0,
  marginTop: 'auto',
  width: '22rem',
  paddingBottom: '1rem',
  div: {
    ':first-of-type': {
      padding: 0,
      hr: {
        margin: '0 0 1rem 0'
      }
    }
  }
}))

export const StyledFullWidthButton = styled(Button)(({ theme }) => ({
  width: '100%'
}))

export const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  borderRadius: 'none',
  width: '100%',
  '.MuiAccordionSummary-root': {
    padding: '0',
    '.MuiAccordionSummary-content': {
      margin: '0'
    },
    '&.Mui-expanded': {
      minHeight: 'auto'
    }
  },
  '.MuiAccordionDetails-root': {
    paddingTop: '18px',
    button: {
      width: '100%',
      '&:first-of-type': {
        marginBottom: '16px'
      }
    }
  }
}))

export const StyledFlyoutContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(8),
  maxWidth: '350px',
  '>h3': {
    marginBottom: '16px'
  }
}))

export const LeaveWorkspaceButton = styled(Grid)(({ theme }) => ({
  paddingTop: '14px',
  display: 'inline-flex',
  color: theme.palette.secondary.main,
  cursor: 'pointer'
}))

export const StyledTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  fontFamily: 'Graphik-Medium'
})

export const StyledRoleContainer = styled(Grid)({
  marginTop: '25px'
})

export const StyledAssignRole = styled(Typography)({
  cursor: 'pointer'
})

export const StyledAssignRoleDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone120
}))

export const StyledTypographyAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone150
}))

export const EditInputContainer = styled(Grid)(({ theme }) => ({
  '>div': {
    width: '100%',
    paddingTop: '10px'
  }
}))
