import { Avatar, AvatarGroup, Grid, Tooltip, Typography } from '@precis-digital/kurama'
import { type DataSourceItem } from 'channelGrouping/types'
import React, { type ReactElement } from 'react'
import { useTranslation } from 'shared/translations'

interface DataSourcesAvatarGroupProps {
  dataSources: DataSourceItem[]
  size: 'small' | 'large'
  max: number
}

const DataSourcesAvatarGroup = ({ dataSources, size, max }: DataSourcesAvatarGroupProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')

  const AvatarGroupComponent = (
    <AvatarGroup max={max} size={size}>
      {dataSources.map((dataSource) => {
        if (dataSource.iconUrl != null) {
          return <Avatar key={dataSource.platform} size={size} kind="image" imageUrl={dataSource.iconUrl} />
        }
        return <Avatar key={dataSource.platform} size={size} kind="label" label={dataSource.label ?? ''} />
      })}
    </AvatarGroup>
  )

  if (dataSources.length <= max) {
    return AvatarGroupComponent
  }

  return (
    <Tooltip
      title={t('dataSourceTypes')}
      body={
        <Grid display="flex" flexDirection="column" gap="8px">
          {dataSources.map((dataSource) => (
            <Grid key={dataSource.platform} display="flex" gap="8px">
              <Avatar size="small" kind="image" imageUrl={dataSource.iconUrl ?? ''} />
              <Grid display="flex" flexDirection="column">
                <Typography variant="body2">{dataSource.label}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      }
      kind="multiline"
    >
      <Grid display="flex" alignItems="center">
        {AvatarGroupComponent}
      </Grid>
    </Tooltip>
  )
}

export default DataSourcesAvatarGroup
