import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  EditIcon,
  Grid,
  MoreIcon,
  Notification,
  SwitchIcon,
  TargetIcon
} from '@precis-digital/kurama'
import { useState } from 'react'
import { useTranslation } from 'shared/translations'

export interface MoreMenuDropdownProps {
  canSwitchReports: boolean
  handleSwitchReports: () => void
  handleSelectTopCardMetrics: () => void
  handleSelectConversionEvents?: () => void
  topCardMetricsCount: number
  conversionEventsCount?: number
  disableConversionEvents: boolean
  disableConversionEventsReason?: string
}
const MoreMenuDropdown = ({
  canSwitchReports,
  handleSwitchReports,
  handleSelectTopCardMetrics,
  handleSelectConversionEvents,
  topCardMetricsCount,
  conversionEventsCount = 0,
  disableConversionEvents,
  disableConversionEventsReason
}: MoreMenuDropdownProps): React.ReactElement => {
  const { t } = useTranslation('home')

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    setDropdownAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setDropdownAnchorEl(null)
  }

  const isDropdownOpen = dropdownAnchorEl != null
  return (
    <>
      <Button fullWidth variant="outlined" onClick={handleClick} aria-label={t('moreMenu')} rightIcon={<MoreIcon />}>
        {t('buttons.more')}
      </Button>

      <Dropdown anchorEl={dropdownAnchorEl} open={isDropdownOpen} onClose={handleClose}>
        <DropdownList minWidth="300px">
          {canSwitchReports && (
            <DropdownItem
              onClick={() => {
                handleSwitchReports()
                handleClose()
              }}
              icon={<SwitchIcon />}
            >
              {t('buttons.switchReport')}
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              handleSelectTopCardMetrics()
              handleClose()
            }}
            icon={<EditIcon />}
            rightIcon={
              topCardMetricsCount > 0 ? (
                <Grid display="flex" alignItems="center">
                  <Notification value={topCardMetricsCount.toString()} variant="tonal" />
                </Grid>
              ) : undefined
            }
          >
            {t('buttons.selectTopCardMetrics')}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleSelectConversionEvents?.()
              handleClose()
            }}
            tooltipProps={{
              title: disableConversionEventsReason,
              kind: 'singleline',
              disabled: disableConversionEventsReason == null
            }}
            icon={<TargetIcon />}
            rightIcon={
              conversionEventsCount > 0 ? (
                <Grid display="flex" alignItems="center">
                  <Notification value={conversionEventsCount.toString()} variant="tonal" />
                </Grid>
              ) : undefined
            }
            disabled={disableConversionEvents}
          >
            {t('dropDown.selectConversionEvents')}
          </DropdownItem>
        </DropdownList>
      </Dropdown>
    </>
  )
}

export default MoreMenuDropdown
