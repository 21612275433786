import React from 'react'
import { type UseControllerProps } from 'react-hook-form'
import { useController } from 'shared/reactHookForm'
import { type FormProps } from './WorkspaceSetup'
import { Input } from '@precis-digital/kurama'

interface InputProps {
  name: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  [key: string]: any
}

const WorkspaceInput = (props: InputProps & UseControllerProps<FormProps>): React.ReactElement => {
  const { field, fieldState } = useController(props)
  const { placeholder, labelText } = props
  const errorMessage: string | undefined = fieldState.error?.message

  return (
    <Input
      name={field.name}
      placeholder={placeholder}
      labelText={labelText}
      value={field.value}
      onChange={(e) => {
        field.onChange(e.target.value)
      }}
      onKeyDown={props.onKeyDown}
      ref={field.ref}
      helperText={errorMessage}
      {...(errorMessage !== undefined && { color: 'error' })}
    />
  )
}

WorkspaceInput.displayName = 'WorkspaceInput'

export default WorkspaceInput
