import { FrameProps } from './types'
import {
  StyledFrame,
  StyledSideNavigationContainer,
  StyledContentContainer,
  StyledTopNavigation,
  StyledContent,
  StyledFrameContainer
} from './styles'
import { useCallback, useEffect, useRef, useState } from 'react'

const Frame = ({ SideNavigation, TopNavigation, children }: FrameProps): React.ReactElement => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = useCallback(() => {
    if (contentRef.current != null) {
      const userHasScrolled = contentRef.current.scrollTop > 0
      setIsScrolled((prev) => (prev !== userHasScrolled ? userHasScrolled : prev))
    }
  }, [])

  useEffect(() => {
    if (contentRef.current != null) {
      const contentElement = contentRef.current
      contentElement.addEventListener('scroll', handleScroll)

      return () => {
        contentElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [contentRef, handleScroll])

  return (
    <StyledFrameContainer>
      <StyledFrame>
        <StyledSideNavigationContainer>{SideNavigation}</StyledSideNavigationContainer>
        <StyledContentContainer>
          <StyledTopNavigation isContentScrolled={isScrolled}>{TopNavigation}</StyledTopNavigation>
          <StyledContent ref={contentRef}>{children}</StyledContent>
        </StyledContentContainer>
      </StyledFrame>
    </StyledFrameContainer>
  )
}

export default Frame
