import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'gads_keywords_quality_score',
  active: true,
  label: 'Google Ads Keyword Quality Score Dashboard',
  category: 'Platform specific',
  description:
    'The classic Precis Quality Score Dashboard powered by BQ. Get current and historical Quality score data instantly and locate optimization areas',
  image_url:
    'https://lh3.googleusercontent.com/fife/APg5EObH7gciIZ9P6AEhfxDGC1fHxMtubgK3-_JRulBLp7F3fJnGrN0sLrPGGP6INQ-vlVSuhRhNoH7yVZwTTYgkTbFL-8yrD6DOT7ixUsEErsxxf-cE2ckifzhUN0t3mQbCOB-knd655moKbMbrIw2BxjRw5l0HHCp4Yow1bVWy_zcmNDFkp_ddfduXnWPuJmAtOugK8_0cJlsafdSlZa2aUCM47RCj3KElR7V5y375np6P7cMdqGKy4B5dsj4k3kD4wp1ao2ixJEoh5AsZS1o8TiEV3yldT7e8oXqBYz6zY0kbin2leI9pcD1E7rUuhbVHLT95d2YZYJgBPFpGEcnvSi48A8yaydhTVyWvaiN8Y8sC3jbD9e0ye5szoC4BhsANaZZ0S2TDsXsUvovj2M5MirqHnvZ8z1VvpBDD8PXmQyxUmeXfhk4-TI7fo-JKI1hVlJzh1kjhEzretJv7lq28xHJqFWABhGQQOXXwlIxascx24EpF4ynek03Pgb4DcTPf7LD1jyo5bC1QIDDbOFdAXZr_j8GRawSj2wAHdVIXw7OzqcvERm5Is50Prk3A63dzQxGP_Tthq8cqY8ECd93cEq6CIcxlVdRHW587IppYOVaOmgh73h6r9vrryQc3QZxo849LToefSCS_XAfTp6Hh9MlargpvF7Y5Y5-fCAvT3zCOy7rEGdhzvq6MNOnght2be87Liq2bQPb5bMroM8AIoBWzZY76nyy2c1OZ2bq1ctunbQkMIfkULi52H81AuAzCuDaPj21AOUOuWoYvPhTk2XFVBtmY6EI4-pZB4yt9pHaZvgw-bgPGawUEuMaE10s5ny3kOiIumNp3kNibmcqj2s5VUzwmB8U5kD17qglCrUrTCySqiOKRdd0UYYVomD22Ac4MJX-4Ov68ihgBuqtBwutIkHlfirIinLGCdOVnC8i20iZVob13kKbBpXJynCWkDskauClOuATaB60eYRrkOltou6qTEIDtl6gaRTir4108ESkoPwQJVt41qflSaC-boQ7oVTZQixlQirwQ_38AqvJM9Aa5GRM3X9aoRNy-V4sfEbBC66sg47qhAmDagsGRkUSf1YxO7xS1gFDswv8nz8HuLFjhMW8pzuEDw4lgVr6jDxNuJWNYG2FvFvajxKtjfNjLvB8e6xLy_AJaJFUbpANWOzichBFmEYtaMStaF0o91H60tm2BdI2QrKNImNZ8C9xYVfqCLtk2Bn0gj_5b97WUQX4qbtJMIHNCqxGGTE9LaEgSyXLbLj14ev6cL-Mm840vTj9NQ7wCGm_SN3ic-SXR6ysFCOSO9cA6S5ormB8jt_UsO1OjLVrmVhtLRYsocVCI-8hwRwTeB6cCullV_arZ5RY4vrSUZGXcRGPcVE_8z2rdjcMpgkscSOlkOROl2wwrMRVAb8pkK_fZrK5zQuRKj8T4Q2bpz6IBBriyfrpLd8vF5n-ijfYig7Y6R2yKNruO-dB2pnfDI6gVc4Qg4FzBKMQa=w1305-h944',
  authors: [
    {
      name: 'Tobias Hink',
      email: 'hink@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjyNEc4WJk3HgY11avoS-2opMitZKNbLBZcaWvS=s126-p-k-rw-no'
    },
    {
      name: 'Joella Berg',
      email: 'joella@precisdigital.com',
      image_url: 'https://drive.google.com/uc?id=1uX3nOAOFswYoVXn3gqg3DErkA4xEC2uY'
    },
    {
      name: 'Andreas Arentoft',
      email: 'andreas@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/ACNPEu_6cvpU6OWIpUfFjVCrxNgHCnidgxrYCHyS9YmBaiM=s126-p-k-rw-no'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2395897860',
    link_description: 'Knowledge Hub: Google Ads Keyword Quality Score Dashboard'
  },
  dashboard: {
    template_report_id: '197a5949-55af-41a4-b9e3-c54d02ebee3b',
    report_name_template: 'copy of Keyword Quality Score Report - {today}',
    datasources: [
      {
        alias: 'ds1290',
        datasourceName: 'copy of Keyword Quality Score datasource',
        type: 'TABLE',
        tableId: 'gads_keywords_quality_score_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      }
    ]
  },
  sources: {
    gads: {
      reports: ['keywords_performance_base']
    }
  },
  inputs: {
    other: [
      {
        id: 'targetCurrency',
        type: 'dropdown',
        options: [
          {
            value: 'SEK',
            label: 'Swedish Kronar (SEK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197564.png'
          },
          {
            value: 'DKK',
            label: 'Danish Kroner (DKK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197565.png'
          },
          {
            value: 'NOK',
            label: 'Norwegian Kroner (NOK)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197579.png'
          },
          {
            value: 'EUR',
            label: 'Euros (EUR)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197615.png'
          },
          {
            value: 'USD',
            label: 'US Dollar (USD)',
            icon_url: 'https://cdn-icons-png.flaticon.com/512/197/197484.png'
          }
        ],
        label: 'Target Currency',
        defaultValue: 'EUR',
        tooltip: 'The currency to transform all currency-based columns (cost, revenue, etc) to.',
        required: true,
        mutable: true
      }
    ]
  }
}

export default config
