import { useState, useEffect, useCallback } from 'react'

import { get, store } from 'shared/sstore'

// Overload when defaultValue is provided
export function useSessionStorageState<T>(key: string, defaultValue: T): [T, (value: T) => void]

// Overload when defaultValue is not provided
export function useSessionStorageState<T>(key: string): [T | undefined, (value: T) => void]

/**
 * A hook that implements functionality similar to useState, but uses the sessionStorage as the source of truth.
 * It also updates the sessionStorage when the state is updated.
 */
export function useSessionStorageState<T>(
  key: string,
  defaultValue?: T
): [T, (value: T) => void] | [T | undefined, (value: T) => void] {
  // Read the initial state from sessionStorage or use the default value
  const readValue = useCallback((): T | undefined => {
    if (typeof window === 'undefined') {
      return defaultValue
    }

    try {
      const item = get(key)
      if (item != null) {
        return JSON.parse(item)
      }
      return defaultValue
    } catch (error) {
      return defaultValue
    }
  }, [key, defaultValue])

  const [storedValue, setStoredValue] = useState<T | undefined>(readValue)

  const setValue = useCallback(
    (value: T) => {
      if (typeof window === 'undefined') {
        return
      }

      try {
        setStoredValue(value)
        store(key, JSON.stringify(value))
      } catch (error) {}
    },
    [key]
  )

  useEffect(() => {
    const handleStorageChange = (): void => {
      setStoredValue(readValue())
    }

    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key, readValue])

  return [storedValue, setValue]
}
