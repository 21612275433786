import {
  Accordion,
  Badge,
  Banner,
  ChevronDownIcon,
  type ConditionalBuilderRowState,
  Divider,
  Grid,
  Link,
  MoveIcon,
  Typography,
  styled,
  SingleSelectDropdown,
  TrashIcon,
  Button,
  DotsLoader,
  type AutocompleteOptionItem
} from '@precis-digital/kurama'
import { type Theme } from '@precis-digital/kurama/src/components/theme'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { useState, type ReactElement, useEffect } from 'react'
import { useChangePath } from 'shared/components/Router'
import formatToMoney, { formatToNumber } from 'shared/numberFormat'
import ChannelsTableCardConditionBuilder from './ChannelsTableCardConditionBuilder'
import { type DraggableProvided } from '@hello-pangea/dnd'
import { makeErrorToast } from 'shared/components/Toaster'
import ChannelNameInCard from './ChannelNameInCard'
import { OTHER_CHANNEL_NAME } from 'channelGrouping/constants'
import { type BadgeColor, badgeColors } from '@precis-digital/kurama/src/components/Badge/types'
import { capitalize } from 'shared/utils'
import { type ChannelItem } from 'shared/api/channelGroupings'
import { getExistingChannelByName, getNewChannelId } from 'channelGrouping/utils'
import { useCurrentClient } from 'shared/context/ClientContext'
import NoPerformanceDataSymbol from 'channelGrouping/components/NoPerformanceDataSymbol'
import DataSourcesAvatarGroup from 'channelGrouping/components/DataSourcesAvatarGroup'
import { useTranslation } from 'shared/translations'
import ChannelNameAutocomplete from './ChannelNameAutocomplete'
import { APP_ROUTES } from 'shared/routes'

interface ChannelsTableCardProps {
  channel: ChannelItem
  index: number
  isExpanded: boolean
  isAnyCardExpanded: boolean
  handleExpandClick: (isExpanded: boolean) => void
  draggableProvided?: DraggableProvided
}

const ChannelsTableCard = ({
  channel,
  index,
  isExpanded,
  isAnyCardExpanded,
  handleExpandClick,
  draggableProvided
}: ChannelsTableCardProps): ReactElement => {
  const handleChange = (_: React.SyntheticEvent<Element, Event>, expanded: boolean): void => {
    handleExpandClick(expanded)
  }

  const [localChannel, setLocalChannel] = useState<ChannelItem>(channel)
  useEffect(() => {
    setLocalChannel(channel)
  }, [channel])

  return (
    <StyledAccordionCardContainer
      expanded={isExpanded}
      onChange={handleChange}
      expandIcon={<ChevronDownIcon />}
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true
      }}
      Summary={
        <ChannelsTableCardSummary
          channel={localChannel}
          index={index}
          draggableProvided={draggableProvided}
          isAnyCardExpanded={isAnyCardExpanded}
        />
      }
      Details={
        <ChannelsTableCardDetails
          channel={localChannel}
          setLocalChannel={setLocalChannel}
          handleExpandClick={handleExpandClick}
        />
      }
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
    />
  )
}

interface ChannelsTableCardSummaryProps {
  channel: ChannelItem
  index: number
  isAnyCardExpanded: boolean
  draggableProvided?: DraggableProvided
}

const ChannelsTableCardSummary = ({
  channel,
  index,
  isAnyCardExpanded,
  draggableProvided
}: ChannelsTableCardSummaryProps): ReactElement => {
  const { dataSourcesMap } = useCurrentChannelGrouping()
  const {
    performanceData,
    isPerformanceDataLoading,
    isPerformanceDataError,
    shouldFetchPerformanceData,
    selectedMetric
  } = useCurrentChannelGroupingDataSource()
  const { currentClient } = useCurrentClient()
  const ruleCount = Object.values(channel.rules).reduce((acc, ruleItems) => acc + ruleItems.length, 0)
  const isOtherChannel = channel.name === OTHER_CHANNEL_NAME
  const dragTooltipMessage = isOtherChannel
    ? "The 'Other' channel cannot be moved around since it will always be the last channel."
    : 'Changing the order of channels is not possible when a channel is being edited. Close all channels first.'

  const dataSourcesWithThisChannel = Object.values(dataSourcesMap).filter((dataSourceItem) =>
    dataSourceItem.channels.map((channelItem) => channelItem.id).includes(channel.id)
  )

  const metricValue = performanceData.channelsValues?.[channel.name]?.[selectedMetric.id] ?? 0

  const renderPerformanceData = (): ReactElement => {
    if (isPerformanceDataLoading) {
      return <DotsLoader height={30} width={30} />
    }
    if (isPerformanceDataError) {
      return <NoPerformanceDataSymbol />
    }
    return (
      <Typography variant="body2">
        {selectedMetric.type === 'currency'
          ? formatToMoney({
              value: metricValue,
              currencyCode: currentClient.currency
            })
          : formatToNumber(metricValue)}
      </Typography>
    )
  }
  return (
    <Grid display="flex" gap="24px" width="100%">
      <Grid width="60%" display="flex" gap="24px">
        {isAnyCardExpanded || isOtherChannel ? (
          <StyledGridIcon
            disabled
            title={dragTooltipMessage}
            draggable="true"
            onDragEnd={(e) => {
              makeErrorToast(dragTooltipMessage)
            }}
            {...draggableProvided?.dragHandleProps}
          >
            <MoveIcon />
          </StyledGridIcon>
        ) : (
          <StyledGridIcon {...draggableProvided?.dragHandleProps}>
            <MoveIcon />
          </StyledGridIcon>
        )}
        <Grid display="flex" gap="14px" alignItems="center" width="100%">
          <Grid width="20px">
            <Typography variant="body2">{(index + 1).toString().padStart(2, '0')}</Typography>
          </Grid>
          <Grid padding="6px">
            <Badge color={channel.color} />
          </Grid>
          <StyledTypography1LineClamped variant="h3">{channel.name}</StyledTypography1LineClamped>
        </Grid>
      </Grid>
      <Grid width="40%" display="flex" alignItems="center" gap="16px" textAlign="center">
        <Grid display="flex" justifyContent="flex-end" width="60px">
          <DataSourcesAvatarGroup size="small" max={2} dataSources={dataSourcesWithThisChannel} />
        </Grid>
        <Grid display="flex" width="calc(100% - 60px)">
          <Grid width="50%">{ruleCount > 0 && <Typography variant="body2">{ruleCount}</Typography>}</Grid>
          <Grid width="50%" display="flex" alignItems="center" justifyContent="center" height="24px">
            {shouldFetchPerformanceData && renderPerformanceData()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

interface ChannelsTableCardDetailsProps {
  channel: ChannelItem
  setLocalChannel: (channel: ChannelItem) => void
  handleExpandClick: (isExpanded: boolean) => void
}

const ChannelsTableCardDetails = ({
  channel,
  setLocalChannel,
  handleExpandClick
}: ChannelsTableCardDetailsProps): ReactElement => {
  const { channelGroupingId, currentChannelGrouping, updateChannelAcrossDataSources, channelsMap, dataSourcesMap } =
    useCurrentChannelGrouping()
  const {
    dataSource,
    selectedMetric,
    dataSourceItem,
    updateSingleChannelInDataSource,
    deleteSingleChannel,
    performanceData,
    isPerformanceDataLoading,
    shouldFetchPerformanceData
  } = useCurrentChannelGroupingDataSource()
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('channelGrouping')

  const [localChannelName, setLocalChannelName] = useState<string>(channel.name)

  const { changePath } = useChangePath()

  const handleInspectResultsClick = (): void => {
    void changePath(
      APP_ROUTES.customGroupings.inspectResultsPage({ channelGroupingId: channelGroupingId.toString(), dataSource })
    )
  }

  const handleSave = (value: Record<string, ConditionalBuilderRowState[]>): void => {
    setLocalChannel({ ...channel, rules: value })
    updateSingleChannelInDataSource(
      channel.id,
      { rules: value },
      {
        onError: () => {
          handleExpandClick(true)
          setLocalChannel(channel)
        }
      }
    )
    handleExpandClick(false)
  }

  const handleDelete = (): void => {
    deleteSingleChannel(channel.id, {
      onError: (error) => {
        handleExpandClick(true)

        const errorMessage = error.message.toLowerCase()
        const isIAError = errorMessage.includes('requires missing data sources')
        makeErrorToast(
          isIAError ? t('popups.updateChannelGrouping.iaErrorToast') : t('popups.updateChannelGrouping.errorToast')
        )
      }
    })
    handleExpandClick(false)
  }

  const isOtherChannel = channel.name === OTHER_CHANNEL_NAME

  const channelMetricValue = performanceData.channelsValues?.[channel.name]?.[selectedMetric.id] ?? 0
  const dataSourceSumValue = performanceData.sumValues?.[selectedMetric.id] ?? 0

  const renderPerformanceDataComparisonValues = (): ReactElement => {
    let fractionString = ''
    let totalString = ''
    if (selectedMetric.type === 'currency') {
      fractionString = formatToMoney({
        value: channelMetricValue,
        currencyCode: currentClient.currency
      })
      totalString = formatToMoney({
        value: dataSourceSumValue,
        currencyCode: currentClient.currency
      })
    } else {
      fractionString = formatToNumber(channelMetricValue)
      totalString = formatToNumber(dataSourceSumValue)
    }

    return (
      <Typography variant="h5">
        {t('dataSourceFormView.channelsTab.tableCard.performanceDataFractionSentence.textFraction', {
          numerator: fractionString,
          denominator: totalString
        })}
      </Typography>
    )
  }

  const handleNameChange = (_: React.SyntheticEvent<Element, Event>, value: AutocompleteOptionItem | null): void => {
    const dataSourcesWithThisChannel = Object.values(dataSourcesMap).filter((dataSourceItem) => {
      return dataSourceItem.channels.some((channelItem) => channelItem.id === channel.id)
    })

    const otherChannelWithSameName = getExistingChannelByName(channelsMap, value?.value ?? '')

    const newChannelName = value?.value ?? ''

    if (
      newChannelName === channel.name &&
      (otherChannelWithSameName == null || otherChannelWithSameName.id === channel.id)
    )
      return

    if (newChannelName === '') {
      return
    }

    const newChannelFields: { name: string; id?: string } = {
      name: newChannelName
    }

    if (otherChannelWithSameName != null) {
      newChannelFields.id = otherChannelWithSameName.id
    }

    if (dataSourcesWithThisChannel.length > 1) {
      newChannelFields.id = getNewChannelId(currentChannelGrouping.channelGrouping, newChannelName)
    }

    updateSingleChannelInDataSource(channel.id, newChannelFields)

    setLocalChannel({ ...channel, name: newChannelName })
    setLocalChannelName(newChannelName)
  }

  return (
    <Grid display="flex" flexDirection="column" gap="16px">
      <Grid display="flex" flexDirection="column" paddingTop="20px">
        <ChannelNameInCard channel={channel} isOtherChannel={isOtherChannel} />
        <Grid height="16px" />
        {!isOtherChannel && (
          <Grid display="flex" justifyContent="space-between">
            <Grid display="flex" flexWrap="nowrap">
              <StyledGridInputContainer>
                <ChannelNameAutocomplete
                  channelName={localChannelName}
                  channelColor={channel.color}
                  handleNameChange={handleNameChange}
                  placeholder={t('dataSourceFormView.channelsTab.tableCard.channelNamePlaceholder')}
                  noOptionsTextTemplate={t('dataSourceFormView.channelsTab.tableCard.channelNameNoOptionsTextTemplate')}
                  acceptCustomInputTemplate={t(
                    'dataSourceFormView.channelsTab.tableCard.channelNameAcceptCustomInputTemplate'
                  )}
                />
              </StyledGridInputContainer>
              <StyledSingleSelectDropdown
                title="Channel color"
                buttonTitle="Channel color"
                options={badgeColors.map((color) => ({
                  label: capitalize(color),
                  value: color,
                  icon: <Badge color={color} />
                }))}
                value={channel.color}
                buttonWidth="160px"
                onSelect={(value: string) => {
                  setLocalChannel({ ...channel, color: value as BadgeColor })
                  updateChannelAcrossDataSources(channel.id, { color: value as BadgeColor })
                }}
              />
            </Grid>
            <Grid marginLeft="16px">
              <Button variant="semantic-destructive" onClick={handleDelete} rightIcon={<TrashIcon />}>
                {t('dataSourceFormView.channelsTab.tableCard.deleteChannelButton')}
              </Button>
            </Grid>
          </Grid>
        )}
        <Divider />
        {shouldFetchPerformanceData && (
          <Grid display="flex" gap="0.25em">
            {isPerformanceDataLoading ? <DotsLoader height={24} width={24} /> : renderPerformanceDataComparisonValues()}
            <Typography variant="body2">
              {t('dataSourceFormView.channelsTab.tableCard.performanceDataFractionSentence.fragment1', {
                metricLabel: selectedMetric.label.toLowerCase(),
                dataSourceLabel: dataSourceItem.label
              })}
            </Typography>
            <Link onClick={handleInspectResultsClick} href="#">
              <Typography variant="body2">
                {t('dataSourceFormView.channelsTab.tableCard.performanceDataFractionSentence.inspectResults')}
              </Typography>
            </Link>
            <Typography variant="body2">
              {t('dataSourceFormView.channelsTab.tableCard.performanceDataFractionSentence.fragment2')}
            </Typography>
          </Grid>
        )}
      </Grid>
      {isOtherChannel ? (
        <>
          <Banner variant="info">
            <b>{t('dataSourceFormView.channelsTab.tableCard.otherChannel.note')}</b>{' '}
            {t('dataSourceFormView.channelsTab.tableCard.otherChannel.noteText')}
          </Banner>
          <Banner variant="success">
            <b>{t('dataSourceFormView.channelsTab.tableCard.otherChannel.tip')}</b>{' '}
            {t('dataSourceFormView.channelsTab.tableCard.otherChannel.tipText')}
          </Banner>
        </>
      ) : (
        <ChannelsTableCardConditionBuilder rules={channel.rules} onSave={handleSave} />
      )}
    </Grid>
  )
}

const StyledAccordionCardContainer = styled(Accordion)(({ theme, expanded }: { theme?: Theme; expanded: boolean }) => ({
  marginTop: theme?.spacing(4),
  borderRadius: theme?.borderRadius.xLarge,
  outline: `1px solid ${theme?.palette.neutrals.stone100 as string}`,
  boxShadow: expanded ? theme?.elevation.mediumDepth : 'none',
  transition: 'box-shadow 200ms ease-in-out',
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0,
    marginTop: theme?.spacing(4)
  },
  '&:first-of-type': {
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  },
  '> .MuiAccordionSummary-root': {
    backgroundColor: theme?.palette.neutrals.white0,
    padding: '20px',
    borderTopLeftRadius: theme?.borderRadius.xLarge,
    borderTopRightRadius: theme?.borderRadius.xLarge,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge,
    '.MuiAccordionSummary-expandIconWrapper': {
      width: '40px',
      justifyContent: 'center',
      color: theme?.palette.secondary.main
    },
    '.kurama-AvatarGroup': {
      alignItems: 'center'
    }
  },
  '&.MuiAccordion-root:before': {
    display: 'none'
  },
  '.MuiAccordionDetails-root': {
    padding: '20px',
    paddingTop: 0,
    backgroundColor: theme?.palette.neutrals.white0,
    borderBottomLeftRadius: theme?.borderRadius.xLarge,
    borderBottomRightRadius: theme?.borderRadius.xLarge
  }
}))

const StyledGridIcon = styled(Grid)(({ theme, disabled }: { theme?: Theme; disabled?: boolean }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: disabled === true ? theme?.palette.neutrals.stone120 : undefined,
  height: '40px',
  width: '40px',
  flexShrink: 0,
  borderRadius: theme?.borderRadius.medium,
  ...(disabled !== true
    ? {
        '&:hover': {
          backgroundColor: theme?.palette.neutrals.stone100
        }
      }
    : {})
}))

const StyledTypography1LineClamped = styled(Typography)(() => ({
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '1',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

const StyledGridInputContainer = styled(Grid)(() => ({
  width: '480px',
  '.MuiInputBase-root': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  '.MuiOutlinedInput-root fieldset': {
    borderRight: 'none',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}))
const StyledSingleSelectDropdown = styled(SingleSelectDropdown)(() => ({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}))

export default ChannelsTableCard
