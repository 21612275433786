import fetch from './fetch'
const client = fetch()

type mode = 'sign-in' | 'sign-up'
export interface AuthUserResp {
  idTokenProvider: 'google'
  idToken: string
  email: string
  avatarUrl: string
  accessToken: string
  name: string
  displayName: string
  mode: mode
  hasInternalFeatureAccess: boolean
  expires: string
}

type tokenProviders = 'google'

export interface AuthReq {
  email: string
  displayName?: string
  name?: string
  avatarUrl?: string
  mode?: mode
  idTokenProvider: tokenProviders
  idToken: string
}

export type AuthSignInReq = Omit<AuthReq, 'mode' | 'idTokenProvider'> & {
  mode: 'sign-in'
  idTokenProvider: 'google'
}
export type AuthSignUpReq = Omit<AuthReq, 'mode'> & { mode: 'sign-up' }

// Details provided when we look for details for the currently logged in User
export interface MeUserResp {
  userId: string
  userEmail: string
  avatarUrl: string
  hasInternalFeatureAccess?: boolean
}

export const paths = {
  auth: '/auth'
}

export const signIn = async (user: AuthSignInReq): Promise<AuthUserResp> => {
  return await client.post<AuthUserResp>({
    path: paths.auth,
    data: user
  })
}

export const signUp = async (user: AuthSignUpReq): Promise<AuthUserResp> => {
  return await client.post<AuthUserResp>({
    path: paths.auth,
    data: user
  })
}

export const getCurrentLoggedUser = async (): Promise<MeUserResp> => {
  return await client.get<MeUserResp>({
    path: `/auth`
  })
}
