import { Typography } from '@precis-digital/kurama'
import PercentageChip from 'shared/components/PercentageChip'
import { formatDateToString, getPreviousTimePeriod } from 'shared/dateFns'
import { useTranslation } from 'shared/translations'
import NumberFormat from 'shared/components/NumberFormat'
import {
  StyledDateLabel,
  StyledSummaryCardsContainer,
  StyledSummaryCard,
  StyledSummaryCardNumberContainer
} from 'shared/components/DashboardCards'
import NumberSummaryTooltip from 'shared/components/NumberSummaryTooltip'
import { type CurrencyCodes } from 'shared/constants/currency'

interface PerformanceSummaryBlocksProps {
  spend?: number
  startDate: string
  endDate: string
  revenue?: number
  costPerAction?: number
  roas?: number
  conversions?: number
  selectedMetrics: 'revenue' | 'conversions'
  roasPrevTimePeriod?: number
  revenuePrevTimePeriod?: number
  spendPrevTimePeriod?: number
  costPerActionPrevTimePeriod?: number
  conversionPrevTimePeriod?: number
  currencyCode: CurrencyCodes
}

export interface AttributionModelPerformanceSummary {
  adSpend: number
  conversions: number
  revenue: number
  roas?: number
  costPerAction?: number
}

const PerformanceSummaryBlocks = ({
  spend,
  revenue,
  roas,
  costPerAction,
  startDate,
  endDate,
  conversions,
  selectedMetrics,
  roasPrevTimePeriod,
  revenuePrevTimePeriod,
  spendPrevTimePeriod,
  costPerActionPrevTimePeriod,
  conversionPrevTimePeriod,
  currencyCode
}: PerformanceSummaryBlocksProps): React.ReactElement => {
  const { t } = useTranslation('attributionModel')

  const formattedStartDate = startDate !== '' && formatDateToString({ date: startDate })
  const formattedEndDate = endDate !== '' && formatDateToString({ date: endDate })

  const revenueOrConversion = selectedMetrics === 'revenue' ? revenue : conversions
  const roasRevenueOrConversion = selectedMetrics === 'revenue' ? roas : costPerAction
  const roasPrevTimePeriodRevenueOrConversion =
    selectedMetrics === 'revenue' ? roasPrevTimePeriod : costPerActionPrevTimePeriod
  const revenuePrevTimePeriodRevenueOrConversion =
    selectedMetrics === 'revenue' ? revenuePrevTimePeriod : conversionPrevTimePeriod

  const [prevStartDate, prevEndDate] = getPreviousTimePeriod(startDate, endDate)

  return (
    <>
      <Typography variant="h2">{t('dashboard.performanceTitle')}</Typography>
      <StyledSummaryCardsContainer aria-label="performace summary">
        <StyledSummaryCard>
          <Typography variant="h5">{t('dashboard.adSpend')}</Typography>
          <StyledSummaryCardNumberContainer>
            <Typography variant="h3">
              <NumberFormat value={spend ?? 0} id="adSpend" currencyCode={currencyCode} />
            </Typography>
            <NumberSummaryTooltip
              presentValue={spend ?? 0}
              previousValue={spendPrevTimePeriod ?? 0}
              previousTimePeriod={[prevStartDate, prevEndDate]}
              timePeriod={[startDate, endDate]}
              currencyCode={currencyCode}
              id={selectedMetrics}
              title={t('dashboard.adSpend')}
            >
              <PercentageChip presentValue={spend ?? 0} previousValue={spendPrevTimePeriod ?? 0} />
            </NumberSummaryTooltip>
          </StyledSummaryCardNumberContainer>
          <StyledDateLabel variant="body2">
            {formattedStartDate} - {formattedEndDate}
          </StyledDateLabel>
          <></>
        </StyledSummaryCard>
        <StyledSummaryCard>
          <Typography variant="h5">
            {selectedMetrics === 'revenue' ? t('dashboard.revenue') : t('dashboard.conversions')}
          </Typography>
          <StyledSummaryCardNumberContainer>
            <Typography variant="h3">
              {selectedMetrics === 'revenue' ? (
                <NumberFormat value={revenueOrConversion ?? 0} id="revenue" currencyCode={currencyCode} />
              ) : (
                <NumberFormat value={revenueOrConversion ?? 0} id="conversions" currencyCode={currencyCode} />
              )}
            </Typography>
            <NumberSummaryTooltip
              presentValue={revenueOrConversion ?? 0}
              previousValue={revenuePrevTimePeriodRevenueOrConversion ?? 0}
              previousTimePeriod={[prevStartDate, prevEndDate]}
              timePeriod={[startDate, endDate]}
              currencyCode={currencyCode}
              id={selectedMetrics}
              title={selectedMetrics === 'revenue' ? t('dashboard.revenue') : t('dashboard.conversions')}
            >
              <PercentageChip
                presentValue={revenueOrConversion ?? 0}
                previousValue={revenuePrevTimePeriodRevenueOrConversion ?? 0}
              />
            </NumberSummaryTooltip>
          </StyledSummaryCardNumberContainer>
          <StyledDateLabel variant="body2">
            {formattedStartDate} - {formattedEndDate}
          </StyledDateLabel>
          <></>
        </StyledSummaryCard>
        <StyledSummaryCard>
          <Typography variant="h5">
            {selectedMetrics === 'revenue' ? t('dashboard.roas') : t('dashboard.costPerAction')}
          </Typography>
          <StyledSummaryCardNumberContainer>
            <Typography variant="h3">
              {selectedMetrics === 'revenue' ? (
                <NumberFormat value={roasRevenueOrConversion ?? 0} id="roas" currencyCode={currencyCode} />
              ) : (
                <NumberFormat value={roasRevenueOrConversion ?? 0} id="costPerAction" currencyCode={currencyCode} />
              )}
            </Typography>
            <NumberSummaryTooltip
              presentValue={roasRevenueOrConversion ?? 0}
              previousValue={roasPrevTimePeriodRevenueOrConversion ?? 0}
              previousTimePeriod={[prevStartDate, prevEndDate]}
              timePeriod={[startDate, endDate]}
              currencyCode={currencyCode}
              id={selectedMetrics}
              title={selectedMetrics === 'revenue' ? t('dashboard.roas') : t('dashboard.costPerAction')}
              reverseColorScheme={selectedMetrics !== 'revenue'}
            >
              <PercentageChip
                presentValue={roasRevenueOrConversion ?? 0}
                previousValue={roasPrevTimePeriodRevenueOrConversion ?? 0}
                reverseColorScheme={selectedMetrics !== 'revenue'}
              />
            </NumberSummaryTooltip>
          </StyledSummaryCardNumberContainer>
          <StyledDateLabel variant="body2">
            {formattedStartDate} - {formattedEndDate}
          </StyledDateLabel>
        </StyledSummaryCard>
      </StyledSummaryCardsContainer>
    </>
  )
}

export default PerformanceSummaryBlocks
