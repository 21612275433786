import { Avatar, AvatarGroup, Card, Grid, Tooltip, Typography, styled } from '@precis-digital/kurama'
import { type GroupedCard, type Account, type RecommendationsFullRule } from 'recommendations/types'
import { type MouseEvent, useMemo } from 'react'
import {
  isMessageTruncated,
  truncateMessage,
  getSeverityChipStyles,
  getSeverityTooltipContent,
  convertPlatformName,
  getCardMetadataFromTranslations
} from 'recommendations/utils'
import { formatDateTimeToAtFormat, formatDateToRelativeFormat } from 'shared/dateFns'
import { getPlatformDetailsByPlatform } from 'dataSource'
import { snakeCaseToTitleCase } from 'shared/utils'
import { MoveAffectedAccountsThreshold } from 'recommendations/constants'
import Severity from 'recommendations/components/Severity'
function TooltipBody(accounts: Account[]): React.ReactElement {
  return (
    <Grid display="flex" flexDirection="column" gap="4px">
      {accounts.map((account) => (
        <Grid key={account.id} display="flex" gap="8px" alignItems="center">
          <Avatar size="small" kind="image" imageUrl={account.iconUrl} />
          <Grid display="flex" flexDirection="column">
            <StyledTypography1LineClamp variant="body2">{account.name}</StyledTypography1LineClamp>
            <StyledTypographyId variant="body3">{account.id}</StyledTypographyId>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

interface RecommendationCardProps {
  onClick: (e: MouseEvent<HTMLDivElement>) => void
  data: GroupedCard
  maxCharacters: number
  cardContainerWidth: number
  t: (key: string, options?: Record<string, unknown> | undefined) => string
  shouldTruncateText: boolean
}

export const RecommendationCard = ({
  onClick,
  data,
  maxCharacters,
  cardContainerWidth,
  shouldTruncateText,
  t
}: RecommendationCardProps): React.ReactElement => {
  const cardRuleId = data.ruleId
  const cardRule: RecommendationsFullRule = useMemo(() => {
    return getCardMetadataFromTranslations(t, cardRuleId)
  }, [cardRuleId, t])

  const platformMetaData = getPlatformDetailsByPlatform(convertPlatformName(cardRule.Platform))

  const iconUrl = platformMetaData?.iconUrl
  const sortedAccounts = data.accounts.slice().sort((a, b) => a.name.localeCompare(b.name))
  const severityMeta = getSeverityChipStyles(cardRule.severity ?? 1)
  const SeverityTooltipContent = getSeverityTooltipContent(cardRule.severity)
  const properCaseTitle = snakeCaseToTitleCase(cardRule.Title)
  return (
    <StyledRecommendationCard key={data.messageId} onClick={onClick}>
      <StyledCardGrid>
        <Grid>
          <Typography variant="h5">{properCaseTitle}</Typography>
          <StyledCardContentTypography variant="body3">
            {snakeCaseToTitleCase(cardRule.category)} •
            <Tooltip title={formatDateTimeToAtFormat(data.timestamp)} kind="singleline">
              <span style={{ marginLeft: '0.4ch', marginRight: '0.4ch' }}>
                {formatDateToRelativeFormat(data.timestamp)}
              </span>
            </Tooltip>{' '}
            {cardContainerWidth > MoveAffectedAccountsThreshold && (
              <>
                •
                <StyledTooltipFitContent
                  width="fit-content"
                  title="Affected data sources:"
                  body={TooltipBody(sortedAccounts)}
                  kind="multiline"
                >
                  <Typography variant="body3" style={{ marginLeft: '0.2ch' }}>
                    <AvatarGroup max={2} size="small">
                      {sortedAccounts.map((account) => (
                        <Avatar size="small" kind="image" imageUrl={iconUrl} key={account.name} />
                      ))}
                    </AvatarGroup>
                  </Typography>
                </StyledTooltipFitContent>
              </>
            )}
          </StyledCardContentTypography>
        </Grid>
        <Grid>
          <Grid container direction="row-reverse">
            <Grid item display="flex" flexDirection="column" alignItems="center">
              <Tooltip kind="multiline" title={SeverityTooltipContent.title} body={SeverityTooltipContent.body}>
                <div>
                  <Severity
                    label={severityMeta.label}
                    textColor={severityMeta.color}
                    backgroundColor={severityMeta.backgroundColor}
                  />
                </div>
              </Tooltip>
              {cardContainerWidth <= MoveAffectedAccountsThreshold && (
                <StyledTooltipFitContent
                  width="fit-content"
                  title="Affected data sources:"
                  body={TooltipBody(sortedAccounts)}
                  kind="multiline"
                >
                  <Typography variant="body3">
                    <AvatarGroup max={2} size="small">
                      {sortedAccounts.map((account) => (
                        <Avatar size="small" kind="image" imageUrl={iconUrl} key={account.name} />
                      ))}
                    </AvatarGroup>
                  </Typography>
                </StyledTooltipFitContent>
              )}
            </Grid>
          </Grid>
        </Grid>
      </StyledCardGrid>
      <Grid sx={{ marginTop: '8px' }}>
        {shouldTruncateText && isMessageTruncated(data.message, maxCharacters) ? (
          <Typography variant="body3">{truncateMessage(data.message, maxCharacters)}</Typography>
        ) : (
          <Typography variant="body3">{data.message}</Typography>
        )}
      </Grid>
    </StyledRecommendationCard>
  )
}
const StyledRecommendationCard = styled(Card)(({ theme }) => ({
  marginTop: '0',
  borderRadius: '24px',
  backgroundColor: theme.palette.neutrals.white0,
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  width: '100%'
}))

const StyledTooltipFitContent = styled(Tooltip)(() => ({
  maxWidth: '400px'
}))
const StyledCardGrid = styled(Grid)`
  display: flex;
  flex-direction: row; // Use 'flex-direction' instead of 'direction'
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 4px;
`
const StyledCardContentTypography = styled(Typography)(() => ({
  display: 'flex'
}))

const StyledTypography1LineClamp = styled(Typography)(() => ({
  display: '-webkit-box',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))
const StyledTypographyId = styled(StyledTypography1LineClamp)(({ theme }) => ({
  color: theme?.palette.neutrals.stone120,
  marginTop: '-2px'
}))
export default RecommendationCard
