import { type BadgeColor } from '@precis-digital/kurama'
import { type UsedInConfigType } from 'channelGrouping/types'
import { PLATFORM } from 'dataSource'
import { type Platforms } from 'shared/api/accounts'

export const MAIN_FORM_VIEW_TABS = ['data-sources', 'channels', 'used-in']
export const DEFAULT_MAIN_FORM_VIEW_TAB = 'data-sources'

export const DATA_SOURCE_VIEW_TABS = ['channels', 'distribution', 'inspect-results']
export const DEFAULT_DATA_SOURCE_VIEW_TAB = 'channels'

export const OTHER_CHANNEL_NAME = 'Other'
export const OTHER_CHANNEL_COLOR: BadgeColor = 'stone'
export const DATA_SOURCES_WITHOUT_CURRENCIES: Platforms[] = [PLATFORM.GA4]

export const CHANNEL_CATEGORIES = [
  'other',
  'non_paid_direct',
  'non_paid_organic',
  'non_paid_push',
  'non_paid_referral',
  'non_paid_other',
  'paid_search_brand',
  'paid_search_generic',
  'paid_shopping_brand',
  'paid_shopping_generic',
  'paid_impression_remarketing',
  'paid_impression_prospecting',
  'paid_impression_awareness',
  'paid_social_remarketing',
  'paid_social_prospecting',
  'paid_social_awareness',
  'paid_affiliate',
  'paid_other'
] as const
export type ChannelCategoryType = (typeof CHANNEL_CATEGORIES)[number]
export const USED_IN_CONFIG_TYPES: Record<string, UsedInConfigType> = {
  ATTRIBUTION: 'attribution',
  BUDGET_OPTIMISER: 'budgetOptimiser'
}
