import React from 'react'

export const Help3 = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M11.9659 13.2057V12.4823C11.9659 12.2062 12.194 11.9891 12.4594 11.9128C12.6358 11.8621 12.8044 11.7843 12.9587 11.6812C13.2526 11.4848 13.4816 11.2057 13.6169 10.8792C13.7522 10.5526 13.7876 10.1933 13.7186 9.84663C13.6496 9.49998 13.4794 9.18155 13.2295 8.93162C12.9796 8.6817 12.6612 8.5115 12.3145 8.44254C11.9679 8.37359 11.6085 8.40898 11.282 8.54424C10.9554 8.6795 10.6763 8.90855 10.48 9.20243C10.2836 9.49631 10.2473 9.84663 10.2473 9.84663"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="11.9999" cy="15.9105" r="0.97624" fill="#232332" />
      <g mix-blend-mode="multiply">
        <path
          d="M4.00002 10.6909C4.00002 6.99563 6.99562 4.00002 10.6909 4.00002H13.3091C17.0044 4.00002 20 6.99563 20 10.6909V13.3092C20 17.0044 17.0044 20 13.3091 20H10.6909C6.99562 20 4.00002 17.0044 4.00002 13.3091V10.6909Z"
          fill="#EEF4F6"
        />
      </g>
    </svg>
  )
})
