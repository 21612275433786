export const Label = ({ height }: { height: string }): React.ReactElement => {
  return (
    <svg height={height} viewBox="0 0 43 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.6004 15.2337C38.4579 15.2337 39.3021 14.96 39.9639 14.4475C40.3984 14.1092 41.1298 14.1291 41.5677 14.4641L42.0453 14.829C42.1249 14.8887 42.1415 15.0031 42.0818 15.0811C41.0651 16.4112 39.4082 17.2189 37.6021 17.2189C34.4525 17.2189 31.9199 14.7543 31.9199 11.56C31.9199 8.36562 34.2253 5.87781 37.4644 5.87781C40.7036 5.87781 43.0089 8.50162 43.0089 11.5368C43.0089 11.7623 42.9957 11.9481 42.9791 12.1255C42.9708 12.2184 42.8912 12.2897 42.7983 12.2897H34.0876C34.3845 13.9549 35.7528 15.2337 37.6021 15.2337H37.6004ZM37.486 7.8863C35.8855 7.8863 34.6748 8.91129 34.222 10.3194C34.1838 10.4371 34.2734 10.5566 34.3961 10.5566H40.5477C40.6654 10.5566 40.7533 10.4471 40.7251 10.3327C40.3868 8.93616 39.1097 7.88796 37.4843 7.88796L37.486 7.8863Z"
        fill="#232332"
      />
      <path
        d="M28.0938 5.95235H30.3361C30.4423 5.95235 30.5269 6.03694 30.5269 6.14309V17.1011C30.5269 17.2072 30.4423 17.2918 30.3361 17.2918H28.0938C27.9876 17.2918 27.903 17.2072 27.903 17.1011V6.14309C27.903 6.03694 27.9876 5.95235 28.0938 5.95235ZM27.6211 2.52414C27.6211 3.38658 28.321 4.08649 29.1834 4.08649C30.0459 4.08649 30.7458 3.38658 30.7458 2.52414C30.7458 1.66169 30.0459 0.961792 29.1834 0.961792C28.321 0.961792 27.6211 1.66169 27.6211 2.52414Z"
        fill="#232332"
      />
      <path
        d="M15.5191 5.95239H17.9289C18.0102 5.95239 18.0832 6.0038 18.1097 6.08175L20.8546 14.0743C20.9143 14.2468 21.1581 14.2451 21.2162 14.0743L23.9196 6.08341C23.9461 6.00546 24.0191 5.95405 24.1004 5.95405H26.2714C26.4041 5.95405 26.4953 6.08507 26.4505 6.20946L22.4916 17.1675C22.465 17.2438 22.3921 17.2935 22.3125 17.2935H19.5228C19.4432 17.2935 19.3719 17.2438 19.3437 17.1675L15.3416 6.20946C15.2968 6.08507 15.388 5.95405 15.5207 5.95405L15.5191 5.95239Z"
        fill="#232332"
      />
      <path
        d="M11.4651 0.706421H12.3773C13.2166 0.706421 13.8982 1.38643 13.8982 2.22731V17.1011C13.8982 17.2073 13.8136 17.2919 13.7075 17.2919H11.4651C11.359 17.2919 11.2744 17.2073 11.2744 17.1011V0.897152C11.2744 0.791006 11.359 0.706421 11.4651 0.706421Z"
        fill="#232332"
      />
      <path
        d="M0.00166016 13.9316C0.00166016 11.2862 2.55913 10.3326 5.81154 10.3326H7.03057C7.13672 10.3326 7.2213 10.248 7.2213 10.1418V9.74711C7.2213 8.22955 6.70052 7.46994 5.16139 7.46994C3.90753 7.46994 3.23748 8.01891 3.03679 8.98087C3.00694 9.12682 2.86265 9.24789 2.71338 9.24789H0.670053C0.553955 9.24789 0.466053 9.15335 0.479322 9.03725C0.787811 6.54446 2.84606 5.49792 5.31398 5.49792C7.78189 5.49792 9.84512 6.53783 9.84512 9.6393V17.1011C9.84512 17.2072 9.76053 17.2918 9.65438 17.2918H7.45516C7.34901 17.2918 7.26442 17.2072 7.26442 17.1011V16.2088C7.26442 16.0363 7.05379 15.9534 6.93437 16.0794C6.22949 16.8274 5.28412 17.2918 3.79475 17.2918C1.71328 17.2918 0 16.295 0 13.9316H0.00166016ZM7.2213 13.1073V12.1702C7.2213 12.0641 7.13672 11.9795 7.03057 11.9795H5.87788C3.88265 11.9795 2.60392 12.4356 2.60392 13.8222C2.60392 14.7758 3.1247 15.4044 4.42499 15.4044C6.0288 15.4044 7.2213 14.5585 7.2213 13.1057V13.1073Z"
        fill="#232332"
      />
    </svg>
  )
}

export default Label
