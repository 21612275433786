import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { ConditionInputSingleTextProps } from './types'
import { InputState } from '../types'
import { StyledGridContainer } from '../styles'
import Grid from '../../Grid'
import Typography from '../../Typography'
import { StyledTextField } from './style'
import { debounce } from '@mui/material'

const ConditionInputSingleText = ({
  value,
  onChange,
  onFocus,
  onBlur,
  label,
  placeholder,
  fullWidth,
  hasError = false,
  width = '472px',
  saved = false,
  autoFocus = false
}: ConditionInputSingleTextProps): ReactElement => {
  const [inputState, setInputState] = useState<InputState>('enabled')
  const [localValue, setLocalValue] = useState<string | undefined>(value)

  const textFieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocus) {
      handleSelectClick()
    }
  }, [])

  const debouncedOnChange = useCallback(debounce(onChange, 1000), [onChange])
  useEffect(() => {
    if (hasError) {
      debouncedOnChange(localValue)
    }
  }, [localValue, hasError])

  const handleSelectClick = (): void => {
    setInputState('focused')
    textFieldRef.current?.focus()
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
    setInputState('focused')
    event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length)

    if (onFocus != null) {
      onFocus()
    }
  }

  const handleBlur = (): void => {
    setInputState('enabled')
    if (onBlur != null) {
      onBlur()
    }
    onChange(localValue)
  }

  const handleCancel = (): void => {
    setInputState('enabled')
    setLocalValue(value)
  }

  useEffect(() => {
    if (textFieldRef?.current != null) {
      if (inputState === 'focused') {
        textFieldRef.current.focus()
      } else {
        textFieldRef.current.blur()
      }
    }
  }, [inputState])

  return saved ? (
    <Grid
      container
      height="40px"
      width={fullWidth === true ? '100%' : width}
      alignItems="center"
      gap="4px"
      padding="0px 12px 0px 16px"
    >
      <Typography variant="h4" style={{ padding: '3px 0px 1px 0px' }}>
        {value}
      </Typography>
    </Grid>
  ) : (
    <StyledGridContainer
      state={inputState}
      onClick={inputState === 'enabled' ? () => handleSelectClick() : undefined}
      width={fullWidth === true ? '100%' : width}
      hasError={hasError}
    >
      <Grid width="100%">
        <Typography variant="h4">{label}</Typography>
        <StyledTextField
          value={localValue}
          onChange={(e) => {
            setLocalValue(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleBlur()
            }

            if (e.key === 'Escape') {
              handleCancel()
            }
          }}
          inputProps={{
            ref: textFieldRef,
            onFocus: handleFocus,
            onBlur: handleBlur
          }}
          variant="standard"
          disabled={inputState === 'enabled'}
          placeholder={placeholder}
        />
      </Grid>
    </StyledGridContainer>
  )
}

export default ConditionInputSingleText
