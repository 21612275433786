import React, { type ReactElement } from 'react'
import plant from 'public/assets/images/plant.png'
import { Grid, styled, Typography } from '@precis-digital/kurama'
import Image from 'next/image'
import { useTranslation } from 'shared/translations'

const UsedInEmptyState = (): ReactElement => {
  const { t } = useTranslation('channelGrouping')

  return (
    <StyledGridEmptyStateContainer>
      <StyledGridImageContainer>
        <Image src={plant.src} alt="a plant" width={640} height={257} />
      </StyledGridImageContainer>
      <StyledGridParagraph>
        <Typography variant="h3">{t('mainFormView.usedInTab.emptyState.title')}</Typography>
        <Typography variant="body2">{t('mainFormView.usedInTab.emptyState.description')}</Typography>
      </StyledGridParagraph>
    </StyledGridEmptyStateContainer>
  )
}

const StyledGridEmptyStateContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px 0px 0px'
}))

const StyledGridParagraph = styled(Grid)(() => ({
  width: '540px',
  textAlign: 'center',
  gap: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  p: {
    maxWidth: '380px'
  }
}))

const StyledGridImageContainer = styled(Grid)(() => ({
  position: 'relative'
}))

export default UsedInEmptyState
