import React, { useRef, useState } from 'react'
import { ChevronDownIcon, Input } from '@precis-digital/kurama'
import { StyledMailInputButton, StyledMailInputWrapper } from '../Popup/styles'
import { type MemberRole } from 'shared/api/clients'
import { RoleDropdown } from './DropDownAdminInvite'

interface MailInputProps {
  index: number
  role: MemberRole
  email: string
  setEmail: (index: number, email: string) => void
  setValidEmail: (index: number, valid: boolean) => void
  duplicateEmailCheck: (email: string) => boolean
  setRole: (index: number, role: MemberRole) => void
  alreadyMemberEmailCheck: (email: string) => boolean
  handleSendInvitations: () => Promise<void>
}

export const MailInput = (props: MailInputProps): React.ReactElement => {
  const {
    index,
    role,
    email,
    setEmail,
    setRole,
    setValidEmail,
    duplicateEmailCheck,
    alreadyMemberEmailCheck,
    handleSendInvitations
  } = props

  const isValidEmail = (email: string): boolean =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) &&
    !duplicateEmailCheck(email) &&
    !alreadyMemberEmailCheck(email)

  const mailInputColor = isValidEmail(email) ? 'success' : 'error'

  const mailHelperText = alreadyMemberEmailCheck(email)
    ? `You can't invite an existing member`
    : duplicateEmailCheck(email)
    ? `You can't add the same email multiple times`
    : undefined
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const anchorElementRef = useRef(null)

  return (
    <StyledMailInputWrapper>
      <div>
        <Input
          name="Input"
          value={email}
          {...(email.length > 0 ? { color: mailInputColor } : null)}
          helperText={mailHelperText}
          {...(mailHelperText !== undefined && { color: mailInputColor })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void handleSendInvitations()
            }
          }}
          onChange={(e) => {
            setEmail(index, e.target.value)
            setValidEmail(index, isValidEmail(e.target.value))
          }}
          placeholder="Ex: name@example.com"
        />
      </div>
      <div ref={anchorElementRef}>
        <StyledMailInputButton
          rightIcon={<ChevronDownIcon />}
          onClick={() => {
            setDropdownOpen(true)
          }}
          scheme="light"
          variant="outlined"
        >
          {role}
        </StyledMailInputButton>
        <RoleDropdown
          anchorElementRef={anchorElementRef}
          open={dropdownOpen}
          onSelect={(role: MemberRole) => {
            setRole(index, role)
            setDropdownOpen(false)
          }}
          onClose={() => {
            setDropdownOpen(false)
          }}
        />
      </div>
    </StyledMailInputWrapper>
  )
}
