import type { NextApiRequest, NextApiResponse } from 'next'
import { assertApiReqRes } from 'shared/utils'
import logger from 'shared/logger'

const log = logger()

interface Session {
  user: {
    name: string
    email: string
  }
  accessToken: string
}

/**
 * Get current logged in User and fails if no user is logged in.
 */
export const getSession = (req: NextApiRequest, res: NextApiResponse): Session => {
  assertApiReqRes(req, res)

  const name = req.cookies.userName as string
  const email = req.cookies.userEmail as string
  const accessToken = req.cookies.authToken as string

  const session = {
    user: {
      name,
      email
    },
    accessToken
  }

  log.info(session, 'user sessions ')

  if (session.user.email == null || session.user.name == null || session.accessToken == null) {
    throw new Error('No session found')
  }

  return session
}
