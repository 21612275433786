import React, { type ReactElement } from 'react'
import { Typography, Grid, Button, Input, styled } from '@precis-digital/kurama'
import withPageAuthRequired from 'auth/WithPageAuthRequired'
import { useAuth } from 'shared/context/AuthContext'
import { useDeleteUsers } from 'profile/api'
import { logger } from 'shared/utils'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import withInternalFeatureAccessCheck from 'shared/withInternalFeatureAccessCheck'
import { APP_ROUTES } from 'shared/routes'
import { useChangePath } from 'shared/components/Router'

const log = logger().child({ namespace: 'delete-account' })

export enum ButtonVariant {
  filled = 'filled',
  outlined = 'outlined'
}
const confirmationWord = 'delete'

const AccountDeletion = (): ReactElement => {
  const { changePath } = useChangePath()
  const { currentUser, logOut } = useAuth()
  const { mutate: deleteUsers } = useDeleteUsers()

  const [value, setValue] = React.useState<string>('')
  const [error, setError] = React.useState<boolean>(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value)
    setError(false)
  }
  const handleSubmit = (): void => {
    log.info(`deleting user ${currentUser?.id as string}`)
    deleteUsers(currentUser?.id as string)
    logOut()
    void changePath(APP_ROUTES.auth.signUpPage)
  }

  const isConfirmationWordValid = value.toLowerCase() === confirmationWord

  const checkError = (): void => {
    setError(!isConfirmationWordValid)
  }

  return (
    <Grid container style={{ marginTop: '26px', flexDirection: 'column', alignItems: 'center' }}>
      <Grid item xs={12}>
        <StyledMultlineContent variant="h2">Delete your account?</StyledMultlineContent>
      </Grid>
      <StyledDescription item xs={12}>
        <StyledMultlineContent variant="body1">
          By deleting your account, all your account data will be <b>completely removed</b> from{'\n'}
          Alvie.
        </StyledMultlineContent>
      </StyledDescription>
      <StyledtAccountSection item xs={12}>
        <AvatarWithLabel size="medium" user={currentUser} />
        <StyledtAccountHeader variant="h5">{currentUser?.name ?? ''}</StyledtAccountHeader>
        <StyledtAccountSubHeader variant="body2">{currentUser?.email ?? ''}</StyledtAccountSubHeader>
      </StyledtAccountSection>
      <StyledtNewWorkSpace item xs={12}>
        <Input
          {...(error && value !== ''
            ? { color: 'error', helperText: `Please, type ${confirmationWord.toUpperCase()}` }
            : {})}
          name="confirmDelete"
          value={value}
          onChange={handleChange}
          onBlur={checkError}
          labelText="Type DELETE to confirm your decision"
        />
      </StyledtNewWorkSpace>
      <StyledActions item xs={12}>
        <StyledDelete variant="semantic-destructive" onClick={handleSubmit} disabled={!isConfirmationWordValid}>
          Delete my account
        </StyledDelete>
        <Button
          variant={ButtonVariant.outlined}
          onClick={() => {
            void changePath(APP_ROUTES.home.basePage)
          }}
        >
          Cancel
        </Button>
      </StyledActions>
    </Grid>
  )
}

export const StyledDescription = styled(Grid)(() => ({
  marginTop: '5px'
}))

export const StyledMultlineContent = styled(Typography)(() => ({
  textAlign: 'center',
  whiteSpace: 'break-spaces'
}))

export const StyledtAccountSection = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '25px'
}))
export const StyledtAccountHeader = styled(Typography)(() => ({
  marginTop: '8px'
}))
export const StyledtAccountSubHeader = styled(Typography)(() => ({
  color: '#B5C7D4'
}))

export const StyledtNewWorkSpace = styled(Grid)(() => ({
  marginTop: '40px',
  '& input': {
    minWidth: '353px'
  }
}))

export const StyledActions = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: '24px',
  gap: '16px',
  '& button': {
    minWidth: '353px'
  }
}))

export const StyledDelete = styled(Button)(({ theme }) => ({
  background: theme.palette.semantic.warning100,
  '&:hover': {
    background: theme.palette.semantic.warning90
  },
  '&:focus': {
    background: theme.palette.semantic.warning100,
    outlineColor: theme.palette.graphs.yellow100
  },
  '&:active': {
    background: theme.palette.semantic.warning110,
    outlineColor: 'transparent'
  }
}))

export default withInternalFeatureAccessCheck(
  withPageAuthRequired(AccountDeletion, {
    returnTo: APP_ROUTES.account.deleteAccountPage
  })
)
