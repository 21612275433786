import { SupportedPlatformsEnum, type SupportedPlatforms } from 'dataSource/oauth'
import { infiniteScrollSupportMap } from 'dataSource/components/Popup/constants'
import fetch from './fetch'

const client = fetch()

export interface ExternalAccount {
  id: string
  displayName?: string
  partnerId?: string
  propertyId?: string
  parentId?: string
  name?: string
  regionId?: string
  countryCode?: string
  canManageClients?: boolean
}

export interface ExternalAccountsResp {
  data: ExternalAccount[]
  previousCursor?: string
  nextCursor?: string
}

export interface CredentialReq {
  externalUserId: string
  externalUserName: string
  platform: SupportedPlatforms
  accessToken: string
  scopes: string[]
  refreshToken?: string
}

export interface CredentialShareResp {
  externalUserId: string
  externalUserName: string
  externalUserEmail?: string
  id: string
  credentialId: string
  credentialType: string
  user: string
  createdBy: string
  createdDate?: number
}

export const paths = {
  credentials: '/credentials'
}
export const getExternalAccounts = async (
  dataSource: string,
  credentialId: string,
  cursor?: string,
  searchQuery?: string
): Promise<ExternalAccountsResp> => {
  const isDataSourceSupported = infiniteScrollSupportMap[dataSource as keyof typeof infiniteScrollSupportMap]

  let url = `${paths.credentials}/${credentialId}/external_accounts?platform=${dataSource}`

  if (isDataSourceSupported) {
    if (cursor != null) {
      url += `&cursor=${cursor}`
    }

    if (searchQuery != null) {
      url += `&q=${searchQuery}`
    }
  }

  return await client.get<ExternalAccountsResp>({ path: url, rawResponse: true })
}

export const createCredential = async (credential: CredentialReq, token?: string): Promise<CredentialShareResp> => {
  return await client.post<CredentialShareResp>({
    path: `${paths.credentials}`,
    data:
      credential.platform === SupportedPlatformsEnum.LINKEDIN ||
      credential.platform === SupportedPlatformsEnum.SNAPCHAT ||
      credential.platform === SupportedPlatformsEnum.TIKTOK
        ? { ...credential, scopes: credential.scopes.toString() }
        : credential,
    token
  })
}
