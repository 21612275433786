import Grid from '../Grid'
import { styled } from '../../utils'
import { CSSObject } from '@mui/system'

export const StyledContainer = styled(Grid)(
  ({ paddingLeft }: any): CSSObject => ({
    display: 'inline-flex',
    alignItems: 'center'
  })
)

export const StyledLabelContainer = styled(Grid)(
  ({ paddingLeft }: any): CSSObject => ({
    display: 'inline-flex',
    paddingLeft,
    alignItems: 'center'
  })
)
