import Dropdown from '../Dropdown/Dropdown'
import { Fragment, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { MultiSelectDropdownItem, MultiSelectDropdownProps } from './types'
import DropdownList from '../Dropdown/DropdownList'
import DropdownItem from '../Dropdown/DropdownItem'
import { ChevronDownIcon, ChevronUpIcon } from '../Icons'
import { StyledButtonSelectOpener, StyledTypographyMoreText } from './styles'
import DropdownListSubCategory from '../Dropdown/DropdownListSubCategory'
import Checkbox from '../Checkbox'
import Divider from '../Divider'
import Grid from '../Grid'
import Typography from '../Typography'

const defaultIsOptionEqualToValue = (
  option: MultiSelectDropdownItem,
  value: string | MultiSelectDropdownItem
): boolean => {
  if (typeof value === 'string') {
    return value === option.value
  }
  return value.value === option.value && value.subCategory === option.subCategory
}

const renderOption = (
  option: MultiSelectDropdownItem,
  isSelected: boolean,
  handleOptionClick: (optionValue: MultiSelectDropdownItem, isSelected: boolean, subCategory?: string) => void
): ReactElement => {
  return (
    <DropdownItem
      key={option.value}
      onClick={() => handleOptionClick(option, isSelected)}
      control={<Checkbox checked={isSelected} />}
      annotation={option.annotation}
      annotationLineType={option.annotationLineType}
      icon={option.icon}
      disabled={option.disabled}
      tooltipProps={option.tooltipProps}
    >
      {option.label}
    </DropdownItem>
  )
}

const areAllOptionsSelected = (
  value: Array<string | MultiSelectDropdownItem>,
  options: MultiSelectDropdownItem[]
): boolean => {
  const optionValuesSet = new Set(options.map((option) => option.value))

  for (const element of value) {
    const valueAtIndex = element
    const currentValue = typeof valueAtIndex === 'string' ? valueAtIndex : valueAtIndex.value
    if (!optionValuesSet.has(currentValue)) {
      return false
    }
  }

  return value.length === options.length
}

const useGetButtonTitle = ({
  value,
  buttonTitle,
  moreText,
  options
}: {
  value: Array<string | MultiSelectDropdownItem>
  buttonTitle?: string
  options: MultiSelectDropdownItem[]
  moreText: string
  tooltipTitle?: string
}): ReactNode => {
  const localButtonTitle = useMemo(() => {
    if (value.length === 0 || areAllOptionsSelected(value, options)) {
      return buttonTitle
    }

    const selectedOptionLabels = options
      .filter((option) =>
        value.some((selectedValue) => {
          if (typeof selectedValue === 'string') {
            return selectedValue === option.value
          }
          return selectedValue.value === option.value
        })
      )
      .map((option) => option.label)

    return (
      <Grid display="flex">
        <Typography variant="body2">{selectedOptionLabels[0]}</Typography>
        {value.length > 1 && (
          <StyledTypographyMoreText variant="body2">
            +{value.length - 1} {moreText}
          </StyledTypographyMoreText>
        )}
      </Grid>
    )
  }, [value, options])

  return localButtonTitle
}

const MultiSelectDropdown = ({
  title,
  buttonTitle,
  value = [],
  options,
  onChange,
  onClose,
  disabled = false,
  moreText = 'more',
  buttonWidth = '158px',
  dropdownMinWidth = '297px',
  allowSelectAll = true,
  isOptionEqualToValue = defaultIsOptionEqualToValue
}: MultiSelectDropdownProps): ReactElement => {
  const enabledOptions = useMemo(() => {
    return options.filter((option) => !(option.disabled ?? false))
  }, [options])
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setDropdownAnchorEl(event.currentTarget)
  }
  const localButtonTitle = useGetButtonTitle({ value, buttonTitle, options: enabledOptions, moreText })

  const handleClose = (): void => {
    setDropdownAnchorEl(null)
    onClose?.()
  }

  const handleOptionClick = (optionValue: MultiSelectDropdownItem, isSelected: boolean): void => {
    let newValues = options.filter((option) =>
      value.some((selectedValue) => {
        return isOptionEqualToValue(option, selectedValue)
      })
    )

    if (isSelected) {
      newValues = newValues.filter((selectedValue) => {
        return !isOptionEqualToValue(selectedValue, optionValue)
      })
    } else {
      newValues = [...newValues, optionValue]
    }
    onChange(optionValue.value, newValues)
  }

  const [headerControlValue, setHeaderControlValue] = useState<'checked' | 'indeterminate' | 'unchecked'>()
  const handleHeaderControlChange = (): void => {
    if (headerControlValue === 'checked') {
      onChange(null, [])
      setHeaderControlValue('unchecked')
    } else {
      onChange(null, enabledOptions)
      setHeaderControlValue('checked')
    }
  }

  useEffect(() => {
    if (value.length === enabledOptions.length) {
      setHeaderControlValue('checked')
    } else if (value.length > 0) {
      setHeaderControlValue('indeterminate')
    } else {
      setHeaderControlValue('unchecked')
    }
  }, [value.length, enabledOptions.length])

  const subCategoryMap = useMemo(() => {
    const map: Record<string, MultiSelectDropdownItem[]> = {}
    options.forEach((option) => {
      if (option.subCategory != null) {
        if (map[option.subCategory] == null) {
          map[option.subCategory] = []
        }
        map[option.subCategory].push(option)
      }
    })

    if (Object.keys(map).length > 0) {
      return map
    }
  }, [options])

  const isDropdownOpen = dropdownAnchorEl != null

  return (
    <>
      <StyledButtonSelectOpener
        variant="outlined"
        onClick={handleButtonClick}
        rightIcon={isDropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        width={buttonWidth}
        disabled={disabled}
      >
        {localButtonTitle}
      </StyledButtonSelectOpener>
      <Dropdown anchorEl={dropdownAnchorEl} open={isDropdownOpen} onClose={handleClose}>
        <DropdownList
          dense
          minWidth={dropdownMinWidth}
          header={title}
          headerControlValue={allowSelectAll ? headerControlValue : undefined}
          headerControlOnChange={allowSelectAll ? handleHeaderControlChange : undefined}
        >
          {subCategoryMap != null
            ? Object.entries(subCategoryMap).map(([subCategory, categoryOptions], index) => {
                return (
                  <Fragment key={subCategory}>
                    {index !== 0 && <Divider />}
                    <DropdownListSubCategory header={subCategory}>
                      {categoryOptions.map((option) =>
                        renderOption(
                          option,
                          value.some((valueItem) => {
                            return isOptionEqualToValue(option, valueItem)
                          }),
                          handleOptionClick
                        )
                      )}
                    </DropdownListSubCategory>
                  </Fragment>
                )
              })
            : options.map((option) =>
                renderOption(
                  option,
                  value.some((valueItem) => {
                    return isOptionEqualToValue(option, valueItem)
                  }),
                  handleOptionClick
                )
              )}
        </DropdownList>
      </Dropdown>
    </>
  )
}

export default MultiSelectDropdown
