import { Grid, Link, Typography, defaultTheme } from '@precis-digital/kurama'
import Image from 'next/image'
import starWandIcon from 'public/assets/images/star_wand.svg'
import { UnStyledRetainDefaultQueryLink } from 'shared/components/Router'
import { APP_ROUTES } from 'shared/routes'
import { useTranslation } from 'shared/translations'

const NoReport = (): React.ReactElement => {
  const { t } = useTranslation('home')
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      paddingTop={defaultTheme.spacing(3)}
      gap={defaultTheme.spacing(5)}
    >
      <Image src={starWandIcon.src} alt="star wand icon" width={335} height={169} />
      <Grid container direction="column" alignItems="center" maxWidth={400} textAlign="center">
        <Typography variant="h5">{t('noReportTitle')}</Typography>
        <Typography variant="body3">
          {t('noReportPrompt1')}
          <UnStyledRetainDefaultQueryLink href={APP_ROUTES.reportingSolutions.createConfigPage}>
            <Link variant="body3" href="#">
              {t('noReportPrompt2')}
            </Link>{' '}
          </UnStyledRetainDefaultQueryLink>
          {t('noReportPrompt3')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default NoReport
