export const INPUT_RULES = {
  name: {
    maxLength: {
      value: 60,
      message: 'Please, provide a shorter name with maximum of 60 symbols.'
    }
  },
  phone: {
    pattern: {
      value: /[0-9]/,
      message: 'Please, provide valid phone number'
    }
  },
  jobTitle: {
    maxLength: {
      value: 60,
      message: 'Please, provide a shorter job title with maximum of 60 symbols.'
    }
  }
}
