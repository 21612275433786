import React, { type ReactElement } from 'react'
import { type CreateNewButtonProps } from './types'
import { Grid, Typography, styled } from '@precis-digital/kurama'
import ListViewCreateNewButton from './CreateNewButton'

interface ListViewHeaderProps {
  title: string
  createNewButtonProps?: CreateNewButtonProps
}

const ListViewHeader = ({ title, createNewButtonProps }: ListViewHeaderProps): ReactElement => {
  return (
    <StyledGridHeaderContainer container justifyContent="space-between" flexWrap="nowrap">
      <Typography variant="h1">{title}</Typography>
      {createNewButtonProps != null && <ListViewCreateNewButton {...createNewButtonProps} />}
    </StyledGridHeaderContainer>
  )
}

const StyledGridHeaderContainer = styled(Grid)(({ theme }) => ({
  button: {
    marginTop: '4px'
  }
}))

export default ListViewHeader
