import { Grid, Skeleton, styled } from '@precis-digital/kurama'
import React, { useEffect, type ReactElement } from 'react'

interface HomeFrameProps {
  header: React.ReactNode | null
  toolbar: React.ReactNode | null
  content: React.ReactNode | null
  rightAlignedContent: React.ReactNode
  additionalContent: React.ReactNode | null
  summaryCardsCount?: number
}

const Layout = ({
  summaryCardsCount,
  header,
  toolbar,
  content,
  rightAlignedContent,
  additionalContent
}: HomeFrameProps): ReactElement => {
  const numberOfSummaryCards = summaryCardsCount != null && summaryCardsCount > 0 ? summaryCardsCount : 3

  const leftAlignedContentRef = React.useRef<HTMLDivElement>(null)
  const leftAlignedContent = leftAlignedContentRef.current

  const [leftAlignedContentHeight, setLeftAlignedContentHeight] = React.useState(710)

  useEffect(() => {
    if (leftAlignedContent != null) {
      const resizeObserver = new ResizeObserver(() => {
        const height = (leftAlignedContent?.clientHeight ?? 710) - 35
        setLeftAlignedContentHeight(height > 710 ? height : 710)
      })

      resizeObserver.observe(leftAlignedContent)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [leftAlignedContent])

  return (
    <>
      {header ?? <Skeleton height={40} />}
      {toolbar ?? (
        <Grid display="flex" width="100%" justifyContent="space-between" height="48px" alignItems="center">
          <Grid display="flex" height="40px" gap="8px">
            <Skeleton height="100%" width="97px" />
            <Skeleton height="100%" width="123px" />
          </Grid>

          <Grid display="flex" height="40px" gap="8px">
            <Grid item xs>
              <Skeleton height="100%" width="298px" />
            </Grid>

            <Skeleton height="100%" width="40px" />
            <Skeleton height="100%" width="87px" />
          </Grid>
        </Grid>
      )}
      <Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} ref={leftAlignedContentRef}>
            {content ?? (
              <>
                <Grid container spacing={2} marginTop="6px" marginBottom="16px">
                  {Array(numberOfSummaryCards)
                    .fill(0)
                    .map((value: number, index) => (
                      <Grid
                        height="200px"
                        item
                        xs
                        minWidth={numberOfSummaryCards % 3 === 1 ? '50%' : '33.333%'}
                        key={value + index}
                      >
                        <Skeleton height="100%" width="100%" />
                      </Grid>
                    ))}
                </Grid>
                <Grid margin="16px 0px">
                  <Skeleton height="618px" width="100%" />
                </Grid>
              </>
            )}
          </Grid>
          <StyledGrid
            item
            xs={4}
            height={leftAlignedContentHeight}
            maxHeight={leftAlignedContentHeight}
            marginBottom="16px"
          >
            {rightAlignedContent}
          </StyledGrid>
        </Grid>
        {additionalContent ?? <Skeleton height="500px" width="100%" />}
      </Grid>
    </>
  )
}

export default Layout

const StyledGrid = styled(Grid)(() => ({
  transition: 'height 100ms ease-in-out'
}))
