import { forwardRef } from 'react'
import { StyledCard } from './styles'
import { CardProps } from './types'

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, onClick, elevation = 'flat', ...props }, ref): React.ReactElement => {
    const isClickable = onClick != null
    return (
      <StyledCard {...props} ref={ref} onClick={onClick} isClickable={isClickable} elevation={elevation}>
        {children}
      </StyledCard>
    )
  }
)

export default Card
