import React from 'react'

export const Briefcase = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M4.04272 9.71973C4.04272 8.8913 4.7143 8.21973 5.54272 8.21973H18.4573C19.2857 8.21973 19.9573 8.8913 19.9573 9.71973V17.647C19.9573 19.3038 18.6141 20.647 16.9573 20.647H7.04272C5.38587 20.647 4.04272 19.3038 4.04272 17.647V9.71973Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M15.2468 8.06165V7.10303C15.2468 5.99846 14.3513 5.10303 13.2468 5.10303H10.9313C9.82671 5.10303 8.93127 5.99846 8.93127 7.10303V8.06165"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="12.0893"
        y1="15.5717"
        x2="12.0893"
        y2="13.2949"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.2207 12.3966L5.80744 13.0501C9.6831 14.6462 14.4949 14.6462 18.3706 13.0501L19.9573 12.3966"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
})
