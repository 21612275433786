import { styled } from '../../utils'
import Typography from '../Typography'
import { Theme } from '@mui/material/styles'

export const StyledDividerContainer = styled('div')<{ hasTextElement: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ hasTextElement }) => (hasTextElement ? '4px 0px' : '15.5px 0px')};
  gap: 8px;
  width: 100%;
  mix-blend-mode: multiply;
`
export const StyledTextContainer = styled(Typography)`
  color: ${({ theme }: { theme?: Theme }) => theme?.palette.neutrals.stone120};
`
export const StyledLine = styled('hr')<{ color?: string }>`
  height: 0px;
  border-width: 0.5px;
  margin: 0px;
  border-style: solid;
  flex-grow: 1;
  ${({ color, theme }) =>
    color != null
      ? `
    border-color: ${color};
    border-top: 0.5px solid ${color};
  `
      : `
    border-color: ${theme?.palette.neutrals.stone100 as string};
  `}
`
