import { styled } from '../../utils'
import { Button as MUIButton } from '@mui/material'
import { ButtonScheme, StyledButtonProps } from './types'
import { CSSObject, Theme } from '@mui/material/styles'
import Typography from '../Typography'
import Grid from '../Grid'

export const calculateButtonPadding = (props: StyledButtonProps): string => {
  // you should have 16px of padding on the side where there's text, but not if there's an
  // icon present

  if (!props.hasChildren) {
    return '8px'
  }
  const rightSidePadding = !props.hasRightIcon ? '16px' : '8px'
  const leftSidePadding = !props.hasLeftIcon ? '16px' : '8px'
  return `8px ${rightSidePadding} 8px ${leftSidePadding}`
}
export const ButtonInnerContainer = styled('div')`
  height: 24px;
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
`
export const ButtonIconContainer = styled('div')`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 24px;
  height: 24px;
`
export const ButtonChildrenContainer = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: center;
`

export const ButtonTooltipContainer = styled(Grid)(({ fullWidth }: { fullWidth?: boolean; theme?: Theme }) => ({
  display: 'inline-flex',
  width: fullWidth === true ? '100%' : 'auto'
}))

export const StyledButton = styled(MUIButton, {
  shouldForwardProp: (prop) =>
    prop !== 'hasLeftIcon' && prop !== 'hasRightIcon' && prop !== 'hasChildren' && prop !== 'buttonVariant'
})((props: StyledButtonProps): any => ({
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  padding: calculateButtonPadding(props),
  borderRadius: props.theme?.borderRadius.medium,
  minWidth: '40px',
  minHeight: '40px',
  textTransform: 'none',
  alignSelf: 'start',
  outlineColor: 'transparent',
  outlineWidth: '3px',
  outlineStyle: 'solid',
  '& .MuiPaper-root': {
    border: 'initial'
  },
  ...(props.buttonVariant === 'filled' && {
    ...StyledFilledButton(props.scheme, props.theme)
  }),
  ...(props.buttonVariant === 'tonal' && {
    ...StyledTonalButton(props.scheme, props.theme)
  }),
  ...(props.buttonVariant === 'outlined' && {
    ...StyledOutlineButton(props.scheme, props.theme)
  }),
  ...(props.buttonVariant === 'text' && {
    ...StyledTextButton(props.scheme, props.theme)
  }),
  ...(props.buttonVariant === 'semantic-destructive' && {
    ...StyledDestructiveButton(props.theme)
  }),
  ...(props.buttonVariant === 'semantic-warning' && {
    ...StyledWarningButton(props.theme)
  }),
  ...(props.buttonVariant === 'semantic-promo' && {
    ...StyledPromoButton(props.theme)
  })
}))

const StyledFilledButton = (scheme: ButtonScheme, theme?: Theme): CSSObject => ({
  color: theme?.palette.neutrals.white0,
  background: scheme === 'light' ? theme?.palette.primary.main : theme?.palette.main.blue110,
  '&:hover': {
    background: scheme === 'light' ? theme?.palette.main.blue90 : theme?.palette.main.blue120
  },
  '&:focus': {
    background: scheme === 'light' ? theme?.palette.primary.main : theme?.palette.main.blue110,
    outlineColor: scheme === 'light' ? theme?.palette.main.blue50 : theme?.palette.main.blue90
  },
  '&:active': {
    background: theme?.palette.main.blue110,
    outlineColor: 'transparent'
  },
  '&:disabled, &.Mui-disabled': {
    background: scheme === 'light' ? '#F8FBFC' : '#F8FBFC1F',
    color: scheme === 'light' ? theme?.palette.neutrals.stone120 : '#FFFFFF66'
  }
})

const StyledTonalButton = (scheme: ButtonScheme, theme?: Theme): CSSObject => ({
  color: scheme === 'light' ? theme?.palette.secondary.main : theme?.palette.neutrals.white0,
  background: scheme === 'light' ? theme?.palette.neutrals.stone100 : theme?.palette.main.blue90,
  '&:hover': {
    background: scheme === 'light' ? theme?.palette.neutrals.stone90 : theme?.palette.main.blue110
  },
  '&:focus': {
    background: scheme === 'light' ? theme?.palette.neutrals.white0 : theme?.palette.main.blue90,
    outlineColor: scheme === 'light' ? theme?.palette.main.blue05 : theme?.palette.main.blue50,
    boxShadow: `0px 0px 0px 1px ${
      scheme === 'light' ? (theme?.palette.primary.main as string) : (theme?.palette.semantic.info100 as string)
    } inset`
  },
  '&:active': {
    background: scheme === 'light' ? theme?.palette.neutrals.stone100 : theme?.palette.main.blue120,
    outlineColor: 'transparent',
    boxShadow: 'none'
  },
  '&:disabled, &.Mui-disabled': {
    background: scheme === 'light' ? '#F8FBFC' : '#F8FFFC1F',
    color: scheme === 'light' ? theme?.palette.neutrals.stone120 : '#FFFFFF66'
  }
})

const StyledOutlineButton = (scheme: ButtonScheme, theme?: Theme): CSSObject => ({
  color: scheme === 'light' ? theme?.palette.secondary.main : theme?.palette.neutrals.white0,
  boxShadow: `0px 0px 0px 1px ${theme?.palette.neutrals.stone100 as string} inset`,
  '&:hover': {
    background: scheme === 'light' ? theme?.palette.neutrals.stone100 : theme?.palette.main.blue90,
    boxShadow: scheme === 'dark' && `0px 0px 0px 1px ${theme?.palette.main.blue50 as string} inset`
  },
  '&:focus': {
    background: scheme === 'light' && theme?.palette.neutrals.white0,
    outlineColor: scheme === 'light' ? theme?.palette.main.blue05 : theme?.palette.main.blue90,
    boxShadow: `0px 0px 0px 1px ${
      scheme === 'light' ? (theme?.palette.primary.main as string) : (theme?.palette.neutrals.white0 as string)
    } inset`
  },
  '&:active': {
    background: scheme === 'light' ? theme?.palette.neutrals.stone90 : theme?.palette.main.blue110,
    outlineColor: 'transparent',
    boxShadow: 'none'
  },
  '&:disabled, &.Mui-disabled': {
    background: scheme === 'light' && theme?.palette.neutrals.white0,
    boxShadow: scheme === 'dark' && '0px 0px 0px 1px #EEF4F61F inset',
    color: scheme === 'light' ? theme?.palette.neutrals.stone120 : '#FFFFFF66'
  }
})

const StyledTextButton = (scheme: ButtonScheme, theme?: Theme): CSSObject => ({
  color: scheme === 'light' ? theme?.palette.secondary.main : theme?.palette.neutrals.white0,
  '&:hover': {
    background: scheme === 'light' ? theme?.palette.neutrals.stone100 : theme?.palette.main.blue90
  },
  '&:focus': {
    background: scheme === 'light' ? theme?.palette.neutrals.white0 : theme?.palette.main.blue90,
    outlineColor: scheme === 'light' ? theme?.palette.main.blue05 : theme?.palette.main.blue50,
    boxShadow: `0px 0px 0px 1px ${
      scheme === 'light' ? (theme?.palette.primary.main as string) : (theme?.palette.semantic.info100 as string)
    } inset`
  },
  '&:active': {
    background: scheme === 'light' ? theme?.palette.neutrals.stone90 : theme?.palette.main.blue110,
    outlineColor: 'transparent',
    boxShadow: 'none'
  },
  '&:disabled, &.Mui-disabled': {
    color: scheme === 'light' ? theme?.palette.neutrals.stone120 : '#FFFFFF66'
  }
})

const StyledDestructiveButton = (theme?: Theme): CSSObject => ({
  color: theme?.palette.neutrals.white0,
  background: theme?.palette.semantic.error100,
  '&:hover': {
    background: theme?.palette.semantic.error90
  },
  '&:focus': {
    background: theme?.palette.semantic.error100,
    outlineColor: theme?.palette.graphs.red05
  },
  '&:active': {
    background: theme?.palette.semantic.error110,
    outlineColor: 'transparent'
  },
  '&:disabled, &.Mui-disabled': {
    background: theme?.palette.neutrals.stone90,
    color: theme?.palette.neutrals.stone120
  }
})

const StyledWarningButton = (theme?: Theme): CSSObject => ({
  color: theme?.palette.neutrals.white0,
  background: theme?.palette.semantic.warning100,
  '&:hover': {
    background: theme?.palette.semantic.warning90
  },
  '&:focus': {
    background: theme?.palette.semantic.warning100,
    outlineColor: theme?.palette.graphs.yellow100
  },
  '&:active': {
    background: theme?.palette.semantic.warning110,
    outlineColor: 'transparent'
  },
  '&:disabled, &.Mui-disabled': {
    background: theme?.palette.neutrals.stone90,
    color: theme?.palette.neutrals.stone120
  }
})

const StyledPromoButton = (theme?: Theme): CSSObject => ({
  color: theme?.palette.neutrals.white0,
  background: theme?.palette.semantic.success100,
  '&:hover': {
    background: theme?.palette.semantic.success90
  },
  '&:focus': {
    background: theme?.palette.semantic.success100,
    outlineColor: theme?.palette.graphs.mint05
  },
  '&:active': {
    background: theme?.palette.semantic.success110,
    outlineColor: 'transparent'
  },
  '&:disabled, &.Mui-disabled': {
    background: theme?.palette.neutrals.stone90,
    color: theme?.palette.neutrals.stone120
  }
})
