import { Moment } from 'moment'
import { Dispatch, SetStateAction } from 'react'

export interface handleDatesChangeProps {
  newStartDate: Moment | null
  newEndDate: Moment | null
  previousStartDate: Moment | null
  previousEndDate: Moment | null
  setStartDate: Dispatch<SetStateAction<Moment | null>>
  setEndDate: Dispatch<SetStateAction<Moment | null>>
}
/**
 * Used to determine what the clicked date is and how to handle what the selected date range should be.
 *  - If the user has not selected any date range, the click will be the Start Date
 *  - If the user has both a Start Date and an End Date, the click will reset the selection (i.e. make the click Start Date)
 *  - If the user has only a Start Date selected, it will dynamically pick what the selected date is supposed to be.
 *    - If the newly clicked date is before the previously picked date, it will set the new date as the start date and make the pr evious date the end date.
 *    - If the new clicked date is after the previously picked date, it will set the new date as the end date, and keep the previous date the start date.
 *    - If the new clicked date is exactly the previous picked date, it will set both the start and end date to the new date.
 */
export const handleDatesChange = ({
  newStartDate,
  newEndDate,
  previousStartDate,
  previousEndDate,
  setStartDate,
  setEndDate
}: handleDatesChangeProps): void => {
  if (previousEndDate != null && previousStartDate != null) {
    // When a full date range is already selected, we want to reset the start date to whatever was selected
    setStartDate(newStartDate ?? newEndDate)
    setEndDate(null)
  } else {
    // This is a way to decide if the user is selecting a new start or end date
    const newDate = (newStartDate === previousStartDate ? newEndDate : newStartDate) as Moment
    if (previousEndDate == null) {
      if (previousStartDate?.isAfter(newDate) === true) {
        setEndDate(previousStartDate)
        setStartDate(newDate)
      } else if (previousStartDate?.isBefore(newDate) === true) {
        setEndDate(newDate)
        setStartDate(previousStartDate)
      } else {
        setStartDate(previousStartDate)
        setEndDate(previousStartDate)
      }
    } else {
      setStartDate(newDate)
      setEndDate(null)
    }
  }
}
