import React, { type Dispatch, type SetStateAction, useState } from 'react'
import AvatarContainer from './AvatarContainer'
import ViewerFlyout from './ViewerFlyout'
import EditorFlyout from './EditorFlyout'
import AdminFlyout from './AdminFlyout'
import SwitchWorkspaceFlyout from './SwitchWorkspaceFlyout'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useQueryMember } from 'workspace/api'
import Dots from 'shared/components/Loader/Dots'

interface FlyoutContentProps {
  isSwitchWorkspace: boolean
  isUserProfile: boolean
  setIsUserProfile: Dispatch<SetStateAction<boolean>>
  isAdminWorkspaceProfile: boolean
  setIsAdminWorkspaceProfile: Dispatch<SetStateAction<boolean>>
  handleFlyout: Dispatch<SetStateAction<boolean>>
}

const FlyoutContent = (props: FlyoutContentProps): React.ReactElement => {
  const { isSwitchWorkspace, isAdminWorkspaceProfile, setIsAdminWorkspaceProfile, handleFlyout } = props
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()
  const { data: member, isLoading: isMemberLoading } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabIndex(newValue)
  }
  if (isSwitchWorkspace) {
    return <SwitchWorkspaceFlyout handleFlyout={handleFlyout} />
  }
  return (
    <>
      <AvatarContainer
        name={currentClient.name}
        url={currentClient.url ?? ''}
        avatarUrl={currentClient.avatarUrl}
        role={member?.role ?? 'viewer'}
      />
      {isMemberLoading && <Dots />}
      {member?.role === 'viewer' && <ViewerFlyout />}
      {member?.role === 'editor' && <EditorFlyout tab={tabIndex} handleTabChange={handleTabChange} />}
      {member?.role === 'admin' && (
        <AdminFlyout
          handleFlyout={handleFlyout}
          isAdminWorkspaceProfile={isAdminWorkspaceProfile}
          setIsAdminWorkspaceProfile={setIsAdminWorkspaceProfile}
        />
      )}
    </>
  )
}

export default FlyoutContent
