export type ConfidenceLevel = 'low' | 'medium' | 'high'
export type IndicatorColor = 'success' | 'warning' | 'error'

export const getColorIndicatorFromConfidenceLevel = (confidence: ConfidenceLevel): IndicatorColor => {
  switch (confidence) {
    case 'high':
      return 'success'
    case 'medium':
      return 'warning'
  }
  return 'error'
}

export const getConfidenceLevel = (value: number): ConfidenceLevel => {
  if (value >= 0 && value <= 0.4) {
    return 'high'
  }
  if (value > 0.4 && value <= 0.5) {
    return 'medium'
  }
  return 'low'
}
