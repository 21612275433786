import React from 'react'

export const HistorySmall = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M9.00016 6.28615V8.68088L10.6379 10.3186M8.01815 15H9.98184C12.7533 15 15 12.7533 15 9.98185V8.01816C15 5.24671 12.7533 3 9.98185 3H8.01816C5.24671 3 3 5.2467 3 8.01815V9.98184C3 12.7533 5.2467 15 8.01815 15Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
})
