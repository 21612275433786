import { Button, Frame2, Grid, Link, LogoutIcon, Typography, styled } from '@precis-digital/kurama'
import React, { type ReactElement } from 'react'
import { useAuth } from 'shared/context/AuthContext'
import { useTranslation } from 'shared/translations'
import WorkspaceSwitcherList from '../WorkspaceSwitcherList'
import { useQueryClients } from 'workspace/api'
import ProgressBarLoader from 'shared/components/Loader/ProgressBarLoader'
import withPageAuthRequired from 'auth/WithPageAuthRequired'
import { redirectToPage } from 'shared/utils'
import withInternalFeatureAccessCheck from 'shared/withInternalFeatureAccessCheck'
import { APP_ROUTES } from 'shared/routes'

const WorkspacesList = (): ReactElement => {
  const { t } = useTranslation('workspace')
  const { currentUser, logOut } = useAuth()
  const { data: clients, isLoading: isLoadingClients } = useQueryClients(true)

  const clientsCount = clients?.length

  const isPreciser = currentUser?.email.includes('@precisdigital.com')

  if (clients?.length === 0) {
    redirectToPage(APP_ROUTES.workspace.noWorkspacePage)
  }

  return isLoadingClients || clients == null ? (
    <ProgressBarLoader />
  ) : clients?.length === 0 ? (
    <></>
  ) : (
    <Frame2
      headerRight={
        <Button
          variant="outlined"
          rightIcon={<LogoutIcon />}
          onClick={() => {
            logOut()
          }}
        >
          {t('buttons.logout')}
        </Button>
      }
    >
      <Grid display="flex" alignItems="center" gap="16px" flexDirection="column" height="100%" marginTop="25p">
        <Grid maxWidth="600px" display="flex" flexDirection="column" gap="6px" paddingBottom="16px" textAlign="center">
          <Typography variant="h2">{t('workspaceList.titles.switcharoo')}</Typography>
          <Typography variant="body1">
            {isPreciser === true ? (
              <>
                {t('workspaceList.titles.subTitlePreciser.fragment1', {
                  count: clientsCount,
                  suffix: clientsCount === 1 ? '' : 's',
                  specWord: clientsCount === 1 ? 'that' : 'one'
                })}
                <Link href={APP_ROUTES.workspace.setupPage}>
                  {t('workspaceList.titles.subTitlePreciser.fragment2')}
                </Link>
                {t('workspaceList.titles.subTitlePreciser.fragment3')}
              </>
            ) : (
              t('workspaceList.titles.subTitle', {
                count: clientsCount,
                suffix: clientsCount === 1 ? '' : 's',
                specWord: clientsCount === 1 ? 'that' : 'one'
              })
            )}
          </Typography>
        </Grid>
        <StyledGridListContainer>
          <WorkspaceSwitcherList path={APP_ROUTES.home.basePage} />
        </StyledGridListContainer>
      </Grid>
    </Frame2>
  )
}

const StyledGridListContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  overflowY: 'auto',
  scrollbarGutter: 'stable both-edges'
}))

export default withInternalFeatureAccessCheck(
  withPageAuthRequired(WorkspacesList, {
    returnTo: APP_ROUTES.workspace.listPage
  })
)
