import React, { useMemo, type ReactElement, useEffect } from 'react'
import { type SelectedDates } from '.'
import { useQueryChannelGroupingPerformanceData } from 'channelGrouping/api'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import {
  type AnalyticsReqOrderBy,
  type AnalyticsRespRecord,
  type ChannelGroupingReportType
} from 'shared/api/analytics'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { DATA_SOURCE_ACCOUNT_ID_DIMENSION_MAP, type Dimension } from 'channelGrouping/constants/dimensions'
import { type Metric } from 'channelGrouping/constants/metrics'
import { type Column, usePagination, useSortBy, useTable, type CellProps, useFilters } from 'react-table'
import ReportTable from 'shared/components/ReportTable'
import { useTranslation } from 'shared/translations'
import { convertSnakeCaseToCamelCase } from 'shared/utils'
import formatToMoney, { formatToNumber } from 'shared/numberFormat'
import { Badge, Banner, Grid, Typography, styled } from '@precis-digital/kurama'
import { DATA_SOURCES_WITHOUT_CURRENCIES } from 'channelGrouping/constants'
import { useShouldUseAnalyticsQueryCache } from 'channelGrouping/hooks/useShouldUseAnalyticsCache'
import { useSessionStorageState } from 'shared/hooks/useSessionStorageState'
import { DEFAULT_DATA_COLUMN_PROPERTIES } from 'shared/reactTable'
interface InspectResultsTableProps {
  selectedColumns: string[]
  channelsFilter: string[]
  selectedDates: SelectedDates
}

const InspectResultsTable = ({
  selectedColumns,
  channelsFilter,
  selectedDates
}: InspectResultsTableProps): ReactElement => {
  const { channelGroupingId, channelsMap, currentChannelGrouping } = useCurrentChannelGrouping()
  const { dataSourceItem, dataSourceDimensions, dataSourceMetrics } = useCurrentChannelGroupingDataSource()
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('channelGrouping')

  const [pageSize, setPageSize] = useSessionStorageState<number>('inspectResultsPageSize', 10)
  const [page, setPage] = React.useState<number>(0)

  const accountIdDimension = DATA_SOURCE_ACCOUNT_ID_DIMENSION_MAP[dataSourceItem.platform]

  const selectedDimensionColumns = useMemo(() => {
    return selectedColumns
      .map((selectedColumn) => {
        const dimension = dataSourceDimensions.find((dimension) => dimension.id === selectedColumn)
        return dimension != null
          ? {
              ...dimension,
              apiResponseId: convertSnakeCaseToCamelCase(dimension.id)
            }
          : undefined
      })
      .filter((selectedDimension) => selectedDimension != null) as Array<Dimension & { apiResponseId: string }>
  }, [selectedColumns, dataSourceDimensions])

  const selectedMetricColumns = useMemo(() => {
    return selectedColumns
      .map((selectedColumn) => {
        const metric = dataSourceMetrics.find((metric) => metric.id === selectedColumn)
        return metric != null
          ? {
              ...metric,
              apiResponseId: convertSnakeCaseToCamelCase(`sum_${metric.id}`)
            }
          : undefined
      })
      .filter((selectedMetric) => selectedMetric !== undefined) as Array<Metric & { apiResponseId: string }>
  }, [selectedColumns, dataSourceMetrics])

  const [localSortBy, setLocalSortBy] = React.useState<{ id: string; desc?: boolean }>({
    id: selectedMetricColumns?.[0]?.apiResponseId,
    desc: true
  })

  const channelsFilterObject = useMemo(() => {
    if (channelsFilter.length === 0) {
      return undefined
    }
    return {
      field: 'channel_grouping',
      operator: 'IN',
      value: channelsFilter
    }
  }, [channelsFilter])

  const shouldUseQueryCache = useShouldUseAnalyticsQueryCache(currentChannelGrouping.updatedAt)

  const apiRequestOrderBy = useMemo(() => {
    let apiEquivalentSortById
    if (localSortBy.id === 'channelGrouping') {
      apiEquivalentSortById = 'channel_grouping'
    } else {
      apiEquivalentSortById = localSortBy.id.startsWith('sum')
        ? selectedMetricColumns.find((metric) => metric.apiResponseId === localSortBy.id)?.id
        : selectedDimensionColumns.find((dimension) => dimension.apiResponseId === localSortBy.id)?.id
    }

    const orderBy: AnalyticsReqOrderBy = {
      fields: [apiEquivalentSortById ?? selectedMetricColumns?.[0].id],
      direction: localSortBy.desc === true ? 'DESC' : 'ASC'
    }

    return orderBy
  }, [localSortBy, selectedDimensionColumns, selectedMetricColumns])

  const {
    data: performanceDataResponse,
    isError,
    isFetching
  } = useQueryChannelGroupingPerformanceData({
    report: dataSourceItem.platform as ChannelGroupingReportType,
    clientId: currentClient.id,
    channelGroupingId,
    currencyCode: DATA_SOURCES_WITHOUT_CURRENCIES.includes(dataSourceItem.platform)
      ? undefined
      : currentClient.currency,
    fields: [
      {
        name: 'channel_grouping'
      },
      ...selectedDimensionColumns.map((dimension) => ({
        name: dimension.id
      })),
      ...selectedMetricColumns.map((metric) => ({
        name: metric.id,
        aggregation: 'SUM'
      }))
    ],
    dateStart: new Date(selectedDates.startDate),
    dateEnd: new Date(selectedDates.endDate),
    pageNum: page,
    pageSize,
    useCache: shouldUseQueryCache,
    filters: [
      {
        field: accountIdDimension as string,
        operator: 'IN',
        value: dataSourceItem.connectedDataSources.map((dataSource) => dataSource.externalAccountId)
      },
      ...(channelsFilterObject != null ? [channelsFilterObject] : [])
    ],
    orderBy: apiRequestOrderBy,
    enabled: true
  })

  const { widthPerDimension, widthPerMetric } = useMemo(() => {
    const dimensionUnit = 60 * selectedDimensionColumns.length
    const metricUnit = 40 * selectedMetricColumns.length
    const totalUnits = dimensionUnit + metricUnit

    const widthPerUnit = 100 / totalUnits
    const widthPerDimension = `${widthPerUnit * 60}%`
    const widthPerMetric = `${widthPerUnit * 40}%`

    return { widthPerDimension, widthPerMetric }
  }, [selectedDimensionColumns, selectedMetricColumns])

  const columns: Array<Column<AnalyticsRespRecord>> = useMemo(
    () => [
      {
        ...DEFAULT_DATA_COLUMN_PROPERTIES,
        Header: 'Group',
        accessor: 'channelGrouping',
        Cell: (props: CellProps<AnalyticsRespRecord>) => {
          const channel = Object.values(channelsMap).find((channel) => channel.name === props.value)
          return (
            <Grid display="flex" gap="8px" alignItems="center">
              <Badge color={channel?.color ?? 'steel'} />
              <StyledTypographyEllipsis variant="body2">{props.value}</StyledTypographyEllipsis>
            </Grid>
          )
        },
        width: widthPerDimension
      },
      ...selectedDimensionColumns.map((dimension) => ({
        ...DEFAULT_DATA_COLUMN_PROPERTIES,
        Header: dimension.label,
        accessor: dimension.apiResponseId,
        width: widthPerDimension,
        Cell: (props: CellProps<AnalyticsRespRecord>) => (
          <StyledTypographyEllipsis variant="body2" title={props.value}>
            {props.value}
          </StyledTypographyEllipsis>
        )
      })),
      ...selectedMetricColumns.map((metric) => ({
        ...DEFAULT_DATA_COLUMN_PROPERTIES,
        Header: metric.label,
        accessor: metric.apiResponseId,
        Cell: (props: CellProps<AnalyticsRespRecord>) => {
          const formattedNumber =
            metric.type === 'currency'
              ? formatToMoney({ value: props.value as number, currencyCode: currentClient.currency, compact: false })
              : formatToNumber(props.value as number, false)
          return (
            <StyledTypographyEllipsis variant="body2" title={formattedNumber}>
              {formattedNumber}
            </StyledTypographyEllipsis>
          )
        },
        width: widthPerMetric
      }))
    ],
    [
      selectedDimensionColumns,
      selectedMetricColumns,
      currentClient.currency,
      channelsMap,
      widthPerDimension,
      widthPerMetric
    ]
  )

  const parsedData = useMemo(() => {
    if (performanceDataResponse == null) {
      return []
    }
    return performanceDataResponse.data
  }, [performanceDataResponse])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize: setTablePageSize,
    gotoPage,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data: parsedData,
      initialState: {
        sortBy: [localSortBy],
        pageIndex: 0
      },
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      pageCount: Math.ceil((performanceDataResponse?.totalRows ?? -1) / pageSize)
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  useEffect(() => {
    const newSortBy = sortBy?.[0]
    if (newSortBy != null) {
      setLocalSortBy(newSortBy)
      gotoPage(0)
    }
  }, [sortBy, gotoPage])

  if (isError) {
    return (
      <Grid>
        <Banner variant="error">
          <Typography variant="body1">{t('dataSourceFormView.inspectResultsTab.errorFetchingData')}</Typography>
        </Banner>
      </Grid>
    )
  }

  return (
    <ReportTable
      tableProps={{
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        styles: {
          container: { marginTop: 0, maxWidth: '100%', maxHeight: '100%' },
          table: { marginTop: 0, tableLayout: 'fixed' }
        },
        prepareRow,
        renderFilters: () => <></>,
        pagination: {
          count: performanceDataResponse?.totalRows ?? -1,
          page,
          handleChangePage,
          rowsPerPage: pageSize,
          rowsPerPageOptions: [10, 20, 50, 100],
          handleChangeRowsPerPage: (event) => {
            setPageSize(parseInt(event.target.value, 10))
            setTablePageSize(parseInt(event.target.value, 10))
            gotoPage(0)
          },
          shouldDisableNextButton: rows.length < pageSize
        }
      }}
      isLoading={isFetching}
      t={t}
      basicColumnsIds={[
        'channelGrouping',
        ...selectedDimensionColumns.map((dimension) => convertSnakeCaseToCamelCase(dimension.id))
      ]}
    />
  )
}

const StyledTypographyEllipsis = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export default InspectResultsTable
