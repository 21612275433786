import { Grid, Progress, Typography, styled } from '@precis-digital/kurama'

interface FormProgressProps {
  steps: string[]
  currentStep: number
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}
const FormProgress = ({ steps, currentStep, t }: FormProgressProps): React.ReactElement => {
  const value = (currentStep / steps.length) * 100

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h4">{t('progress')}</Typography>
      </Grid>
      <Grid item display="flex" flexDirection="row" gap="8px" marginTop="4px">
        <StyledGrid>
          {steps.map((_, index) => {
            const isActive = index + 1 <= currentStep
            return (
              <StyledTypography variant={isActive ? 'h5' : 'body3'} active={isActive} key={index}>
                {t('form.step', { stepNumber: index + 1 })}
              </StyledTypography>
            )
          })}
        </StyledGrid>
        <Progress value={value} orientation="vertical" indicatorColor="info" />
        <StyledGrid>
          {steps.map((step, index) => {
            const isActive = index + 1 <= currentStep
            return (
              <StyledTypography variant={isActive ? 'h5' : 'body3'} active={isActive} key={index}>
                {step}
              </StyledTypography>
            )
          })}
        </StyledGrid>
      </Grid>
    </Grid>
  )
}

export default FormProgress

const StyledTypography = styled(Typography)<{ active?: boolean }>(({ active = false, theme }) => ({
  ...(!active && {
    color: theme.palette.neutrals.stone120
  })
}))

const StyledGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  height: '120px',
  justifyContent: 'space-around'
})
