export const get = (key: string): string | null => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(key)
  }
  return null
}

export const store = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, value)
  }
}

export const remove = (key: string): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(key)
  }
}

export const removeAll = (): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.clear()
  }
}
