import {
  Badge,
  Grid,
  Typography,
  styled,
  type TypographyVariant,
  Tooltip,
  DotsLoader,
  defaultTheme
} from '@precis-digital/kurama'
import React from 'react'
import {
  type ConfigStatus,
  STATUS_COLOR_MAP,
  STATUSES_WITH_TOOLTIP,
  type ErrorDetails,
  type ErrorCodeType
} from 'shared/configStatuses'
import StatusTooltipBody from './StatusTooltipBody'
import { type DateInput, formatDateTimeToAtFormat } from 'shared/dateFns'
import useDagView, { type DAGViewTypes } from 'shared/hooks/useDagView'
import { useTranslation } from 'shared/translations'

interface StatusBadgeProps {
  typographyVariant?: TypographyVariant
  status: ConfigStatus
  errorCode?: ErrorCodeType | null
  lastUpdated?: DateInput
  dagViewInformation?: {
    dagViewType?: DAGViewTypes
    configId?: number
    configName?: string
    isActive?: boolean
    isPageWithConfigId?: boolean
  }
  hasTooltip?: boolean
  isLoading?: boolean
  errorDetails?: ErrorDetails | null
}
const StatusWithBadge = ({
  typographyVariant = 'h4',
  status,
  errorCode,
  lastUpdated,
  dagViewInformation = undefined,
  isLoading = false,
  hasTooltip,
  errorDetails,
  ...props
}: StatusBadgeProps): React.ReactElement => {
  const { t } = useTranslation('common')
  const showTooltip = hasTooltip ?? STATUSES_WITH_TOOLTIP.includes(status)
  const badgeColor = STATUS_COLOR_MAP[status]
  const formattedLastUpdate = lastUpdated !== undefined ? formatDateTimeToAtFormat(lastUpdated) : t('unknown')
  const { showDagView } = useDagView(dagViewInformation?.dagViewType, dagViewInformation?.isPageWithConfigId)

  const openDagView = (): void => {
    if (dagViewInformation == null) {
      return
    }
    if (
      dagViewInformation.configId != null &&
      dagViewInformation.configName != null &&
      dagViewInformation.isActive != null
    ) {
      showDagView({
        configId: dagViewInformation.configId,
        configName: dagViewInformation.configName,
        isActive: dagViewInformation.isActive,
        configStatus: status
      })
    }
  }

  const handleBadgeClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!showTooltip) {
      return
    }
    e.preventDefault()
    openDagView()
  }

  if (isLoading) {
    return (
      <StyledGrid display="flex" alignItems="center" gap="10px" padding="8px 24px">
        <DotsLoader color={defaultTheme.palette.neutrals.stone150} height={24} width={36} />
      </StyledGrid>
    )
  }

  const renderTextBadge = (): React.ReactElement => {
    return (
      <StyledGrid
        display="flex"
        alignItems="center"
        gap="10px"
        padding="8px 16px"
        {...props}
        onClick={(e) => {
          handleBadgeClick(e)
        }}
      >
        <StyledStatusTypography hasBadge={badgeColor != null} variant={typographyVariant}>
          {t(`status.${status}`)}
        </StyledStatusTypography>
        {badgeColor != null && <Badge color={badgeColor} />}
      </StyledGrid>
    )
  }

  if (!showTooltip) {
    return renderTextBadge()
  }

  return (
    <Tooltip
      title={
        <TooltipTitleContainer>
          {`${t('lastUpdate')}: ${formattedLastUpdate}`}
          <Badge color={badgeColor ?? 'stone'} />
        </TooltipTitleContainer>
      }
      kind="multiline"
      body={
        <StatusTooltipBody
          status={status}
          hasLinkToDagView={dagViewInformation != null}
          errorCode={errorCode}
          errorDetails={errorDetails ?? null}
          openDagView={openDagView}
        />
      }
      width="auto"
      maxWidth="300px"
    >
      {renderTextBadge()}
    </Tooltip>
  )
}

const StyledStatusTypography = styled(Typography)<{ hasBadge: boolean }>(({ hasBadge, theme }) => ({
  color: hasBadge ? theme.palette.secondary.main : theme.palette.neutrals.stone150
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.stone100,
  borderRadius: '16px'
}))

export const TooltipTitleContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
  width: '100%'
}))

export default StatusWithBadge
