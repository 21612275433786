import {
  format,
  differenceInDays,
  sub,
  subMonths,
  subDays,
  subYears,
  formatDistanceToNow,
  addDays,
  isWithinInterval,
  differenceInCalendarDays
} from 'date-fns'

const DEFAULT_LOCALE = 'en-US'
const UTC_TIMEZONE = 'Etc/UTC'
export type DateInput = number | string | Date

/**
 * Parses a date string or date number or actual Date to a UTC date.
 */
export const parseDateToUTC = (date: DateInput, timeZone: string): Date => {
  const utcDateObj = typeof date === 'string' && !date.endsWith('Z') ? new Date(date + 'Z') : new Date(date)
  return new Date(utcDateObj.toLocaleString('en-US', { timeZone }))
}

/**
 * Formats a date string or date number or actual Date to a date-fns format.
 * Defaults to "MMM Do, yyyy"
 * Ex: "2022-12-01" becomes "Dec 1st, 2022"
 * Format follows: https://date-fns.org/docs/format
 */
export const formatDateToString = ({
  date,
  targetFormat = 'MMM do, yyyy',
  timeZone
}: {
  date: number | string | Date
  targetFormat?: string
  timeZone?: string
}): string => {
  const timeZoneString = timeZone ?? getUsersTimeZone()

  let dateObj = new Date(date)
  if (dateObj.toString() === 'Invalid Date') return ''

  const dateInUTCFormat = new Date(dateObj.toLocaleString(DEFAULT_LOCALE, { timeZone: UTC_TIMEZONE }))

  // Adjust the date for the time zone offset
  const offset = getTimeZoneOffset(timeZoneString, dateInUTCFormat)
  dateObj = new Date(dateInUTCFormat.getTime() - offset)

  return format(dateObj, targetFormat)
}

/**
 * Formats a datetime string or datetime number or actual Date the Alvie relative datetime format.
 * Ex: "2022-12-01 05:05:05" becomes "Dec 1st, 2022 at 05:05"
 */
export const formatDateTimeToAtFormat = (datetime: DateInput, timeZone?: string): string => {
  return formatDateToString({ date: datetime, targetFormat: "MMM do, yyyy 'at' HH:mm", timeZone })
}

export const formatDateToRelativeFormat = (date: DateInput, timeZone?: string): string => {
  const timeZoneString = timeZone ?? getUsersTimeZone()

  let dateObj = new Date(date)

  const dateInUTCFormat = new Date(dateObj.toLocaleString(DEFAULT_LOCALE, { timeZone: UTC_TIMEZONE }))

  const offset = getTimeZoneOffset(timeZoneString, dateInUTCFormat)
  dateObj = new Date(dateInUTCFormat.getTime() - offset)

  return formatDistanceToNow(dateObj, { addSuffix: true })
}

export const getUsersTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getTimeZoneOffset = (timeZone: string, date: Date): number => {
  const utcDate = new Date(date.toLocaleString(DEFAULT_LOCALE, { timeZone: UTC_TIMEZONE }))
  const tzDate = new Date(date.toLocaleString(DEFAULT_LOCALE, { timeZone }))
  const offset = utcDate.getTime() - tzDate.getTime()
  return offset
}

export const calculateDifferenceInDays = (dateLeft: number | Date, dateRight: number | Date): number => {
  return differenceInDays(dateLeft, dateRight)
}

export const getPreviousTimePeriod = (
  startDate: string,
  endDate: string,
  targetFormat = 'yyyy-MM-dd'
): [string, string] => {
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)
  const diff = endDateObj.getTime() - startDateObj.getTime()
  const diffInDays = diff / (1000 * 3600 * 24) + 1
  const prevStartDate = subDays(startDateObj, diffInDays)
  const prevEndDate = subDays(endDateObj, diffInDays)
  return [format(prevStartDate, targetFormat), format(prevEndDate, targetFormat)]
}

export const getDifferenceInDays = (startDate: string, endDate: string): number => {
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)

  return differenceInCalendarDays(endDateObj, startDateObj)
}

export const getCorrespondingPreviousPeriodDate = (date: string, numberOfDays: number): string => {
  const dateObj = new Date(date)
  return format(subDays(dateObj, numberOfDays + 1), 'yyyy-MM-dd')
}

export const getCorrespondingPreviousYearDate = (date: string): string => {
  const dateObj = new Date(date)
  return format(subYears(dateObj, 1), 'yyyy-MM-dd')
}

export const getPreviousYearTimePeriod = (
  startDate: string,
  endDate: string,
  targetFormat = 'yyyy-MM-dd'
): [string, string] => {
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)
  const prevYearStartDate = subYears(startDateObj, 1)
  const prevYearEndDate = subYears(endDateObj, 1)
  return [format(prevYearStartDate, targetFormat), format(prevYearEndDate, targetFormat)]
}

type PeriodOfTheDay = 'morning' | 'day' | 'afternoon' | 'evening'

export const getPeriodOfTimeToday = (): PeriodOfTheDay => {
  const hour = new Date().getHours()
  if (hour < 11) {
    return 'morning'
  } else if (hour >= 11 && hour < 14) {
    return 'day'
  } else if (hour >= 14 && hour < 17) {
    return 'afternoon'
  } else {
    return 'evening'
  }
}

export { sub, format, subYears, subMonths, subDays, differenceInDays, isWithinInterval, addDays }
