import fetch from './fetch'
const client = fetch()

export type Platforms =
  | 'analytics'
  | 'criteo'
  | 'ga4'
  | 'google'
  | 'facebook'
  | 'amazon'
  | 'dv360'
  | 'microsoft_advertising'
  | 'linkedin'
  | 'merchant_center'
  | 'gcp'
  | 'google_search_console'
  | 'google_sheets'
  | 'snapchat'
  | 'tiktok'

export interface ClientAccountsResp {
  createdAt: string
  updatedAt: string
  id: number
  accountId: string
  name: string
  connected: boolean
  platform: Platforms
  canManageClients: boolean
  credentialsId: string
  credentialsName: string
  externalAccountId: string
  recommendationsEngineActive: boolean
}

export interface AddAccountReq {
  clientId: string
  platform: string
  externalAccountId: string
  displayName?: string
  partnerId?: string
  has_360?: boolean
  externalPropertyId?: string
  externalParentAccountId?: string
  name?: string
  credentialsId?: string
  regionId?: string
  countryCode?: string
  canManageClients?: boolean
}

export interface ReconnectAccountReq {
  accountId: string
  credentialsId?: string | null
  clientId: string
}

export interface UpdateRecommendationsForAccountReq {
  accountId: number
  recommendationsEngineActive: boolean
  clientId: string
}

export interface AddAccountResp {
  displayName?: string
  partnerId?: string
  id: string
  name: string
}

export interface SpreadsheetTab {
  columnCount: number
  id: string
  index: number
  rowCount: number
  title: string
  type: string
}
export interface SpreadsheetMetadataResp {
  autoRecalc: string
  externalAccountId: string
  locale: string
  platform: string
  tabs: SpreadsheetTab[]
  timeZone: string
  title: string
  url: string
}

export interface GCPAccessControl {
  role: string
  specialGroup?: string
  userByEmail?: string
}

export interface GCPBigQueryDataset {
  access: GCPAccessControl[]
  creationTime: string
  datasetId: string
  defaultPartitionExpirationMs: number | null
  defaultTableExpirationMs: number | null
  description: string | null
  labels: Record<string, string>
  lastModifiedTime: string
  location: string
}

export interface GCPParentInfo {
  id: string
  type: string
}

export interface GCPMetadataResp {
  bigqueryDatasets: GCPBigQueryDataset[]
  createTime: string
  labels: Record<string, string>
  lifecycleState: string
  name: string
  parent: GCPParentInfo
  projectId: string
  projectNumber: string
  statusCode: number
}

export const paths = {
  accounts: '/accounts'
}

export const getClientAccounts = async (clientId: string): Promise<ClientAccountsResp[]> => {
  return await client.get<ClientAccountsResp[]>({
    path: `${paths.accounts}?client_id=${clientId}`
  })
}

export const getClientAccountPlatform = async (
  clientId: string,
  platform: Platforms
): Promise<ClientAccountsResp[]> => {
  return await client.get<ClientAccountsResp[]>({
    path: `${paths.accounts}?client_id=${clientId}&platform=${platform}`
  })
}

export const addAccount = async (data: AddAccountReq): Promise<AddAccountResp> => {
  const requestData: AddAccountReq = { ...data }
  // Merchant center name is retrieved in the backend after addAccount request is made, see:
  // https://github.com/Precis-Digital/precis-user-platform/blob/86628dda732c10b9d071df25b275a0d63f3d3f6e/server/accounts/service.py#L155-L158
  if (data.platform === 'merchant_center') {
    delete requestData.name
  }
  return await client.post({
    path: `${paths.accounts}`,
    data: requestData
  })
}
export const reconnectAccount = async (data: ReconnectAccountReq): Promise<AddAccountResp> => {
  const { accountId, credentialsId, clientId } = data
  return await client.patch({
    path: `${paths.accounts}/${accountId}?client_id=${clientId}`,
    data: {
      credentialsId
    }
  })
}

export const updateRecommendationsForAccount = async (
  data: UpdateRecommendationsForAccountReq
): Promise<AddAccountResp> => {
  const { accountId, recommendationsEngineActive, clientId } = data
  return await client.patch({
    path: `${paths.accounts}/${accountId}?client_id=${clientId}`,
    data: {
      recommendationsEngineActive
    }
  })
}

export const deleteClientAccount = async (clientId: string, accountId: number): Promise<ClientAccountsResp> => {
  return await client.del<ClientAccountsResp>({
    path: `${paths.accounts}/${accountId}?client_id=${clientId}`
  })
}

export const getGoogleSheetsAccountMetadata = async (
  clientId: string,
  accountId: number
): Promise<SpreadsheetMetadataResp> => {
  return await client.get<SpreadsheetMetadataResp>({
    path: `/accounts/${accountId}/google_sheets_metadata?client_id=${clientId}`
  })
}

export const getGCPAccountMetadata = async (clientId: string, accountId: number): Promise<GCPMetadataResp> => {
  return await client.get<GCPMetadataResp>({
    path: `/accounts/${accountId}/gcp_metadata?client_id=${clientId}`
  })
}
