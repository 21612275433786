import { type ConnectorPlatformType } from 'connectors/constants'

export interface UserDefinedFormInputOption {
  label: string
  value: string
}

export interface UserDefinedFormInputField {
  label?: string
  helperText?: string
  required?: boolean
  defaultValue?: string
  defaultValues?: string[]
  options?: UserDefinedFormInputOption[]
}

export interface UserDefinedFormInputPlatform {
  dimensions?: UserDefinedFormInputField
  metrics?: UserDefinedFormInputField
  /*
   * This should be a regex matching the separator for multiple inputs.
   * The default regex is /\W+/ which matches any non-word character.
   * If the platform allows other special characters as part of the input,
   * then those should be included in the regex, ie:
   * /[^\w.]/ allows the dot as part of metrics names for google ads
   */
  multipleInputSeparator?: RegExp
  table?: UserDefinedFormInputField
  mode?: UserDefinedFormInputField
  reportType?: UserDefinedFormInputField
  breakdown?: UserDefinedFormInputField
  breakdowns?: UserDefinedFormInputField
  actionFields?: UserDefinedFormInputField
  level?: UserDefinedFormInputField
  searchTypes?: UserDefinedFormInputField
  pivot?: UserDefinedFormInputField
  dataLevel?: UserDefinedFormInputField
  worksheetUrl?: UserDefinedFormInputField
  merchantCenterDimensions?: UserDefinedFormInputField
  truncate?: UserDefinedFormInputField
  truncateList?: UserDefinedFormInputField
}

export type UserDefinedFormInputs = Record<ConnectorPlatformType, UserDefinedFormInputPlatform>

export const userDefinedFormInputs: UserDefinedFormInputs = {
  gads: {
    metrics: {
      label: 'Google Ads Metrics',
      helperText: "The Metrics for your report. For example: 'metrics.clicks' or 'metrics.impressions'",
      required: false
    },
    dimensions: {
      label: 'Google Ads Dimensions',
      helperText: "The Dimensions for your report. For example: 'segments.date' or 'campaign.name'",
      required: true
    },
    multipleInputSeparator: /[^\w.]+/,
    table: {
      label: 'API Table',
      helperText: "The table from the API to use. For example: 'campaign_view' or 'search_term_view'",
      required: true
    }
  },
  ga4: {
    metrics: {
      label: 'GA4 Metrics',
      helperText: "The Metrics for your report. For example: 'sessions' or 'eventCount'",
      required: true
    },
    dimensions: {
      label: 'GA4 Dimensions',
      helperText: "The Dimensions for your report. For example: 'eventName' or 'sessionCampaignName'",
      required: true
    },
    multipleInputSeparator: /[^\w:]+/
  },
  google_analytics: {
    metrics: {
      label: 'Google Analytics Metrics',
      helperText: "The Metrics for your report. For example: 'sessions' or 'transactions'.",
      required: true
    },
    dimensions: {
      label: 'Google Analytics Dimensions',
      helperText: "The Dimensions for your report. For example: 'device' or 'source'.",
      required: true
    }
  },
  ahrefs: {
    metrics: {
      label: 'Ahrefs Metrics',
      helperText: "The Metrics for your report. For example: 'ahrefs_rank' or 'domain_rating'",
      required: true
    },
    dimensions: {
      label: 'Ahrefs Dimensions',
      helperText: "The Dimensions for your report. For example: 'title' or 'language'",
      required: true
    },
    table: {
      label: 'Ahrefs Table Name',
      helperText: "The table you're pulling from. For example: 'backlinks' or 'anchors'",
      required: true
    },
    mode: {
      label: 'Report Mode',
      helperText: "The mode to pull the data in. For example: 'domain' or 'subdomain'.",
      required: true
    }
  },
  bing: {
    metrics: {
      label: 'Microsoft Ads Metrics',
      helperText: "The Metrics for your report. For example: 'Impressions' or 'Clicks'",
      required: true
    },
    dimensions: {
      label: 'Microsoft Ads Dimensions',
      helperText: "The Dimensions for your report. For example: 'CampaignName' or 'CurrencyCode'",
      required: true
    },
    reportType: {
      label: 'Microsoft Ads Report Type',
      helperText:
        "The Type of report to extract from. For example: 'CampaignPerformanceReportRequest' or 'AudiencePerformanceReportRequest'",
      required: true
    }
  },
  dv360: {
    metrics: {
      label: 'DV360 Metrics',
      helperText: "The Metrics for your report. For example: 'metric_impressions' or 'metric_clicks'",
      required: true
    },
    dimensions: {
      label: 'DV360 Dimensions',
      helperText: "The Dimensions for your report. For example: 'filter_media_plan_name' or 'filter_country'",
      required: true
    },
    reportType: {
      label: 'DV360 Report Type',
      helperText: "The Type of report to extract from. For example: 'STANDARD' or 'YOUTUBE'"
    }
  },
  facebook: {
    metrics: {
      label: 'Facebook Ads Metrics',
      helperText: "The Metrics for your report. For example: 'spend' or 'impressions'",
      required: false
    },
    dimensions: {
      label: 'Facebook Ads Dimensions',
      helperText: "The Dimensions for your report. For example: 'campaign_name' or 'adset_name'",
      required: true
    },
    breakdowns: {
      label: 'Breakdowns',
      helperText: "The breakdowns for your report. For example: 'country' or 'gender'.",
      required: false
    },
    actionFields: {
      label: 'Action Fields',
      helperText:
        "The action fields to use in your report. For example: 'landing_page_view', 'link_click' or 'purchase'.",
      required: false
    },
    level: {
      label: 'Aggregation Level',
      helperText: "The level of which to aggregate metrics. For example, pick 'ad' to aggregate on ad level.",
      required: true,
      options: [
        {
          label: 'Account',
          value: 'account'
        },
        {
          label: 'Campaign',
          value: 'campaign'
        },
        {
          label: 'Ad Set',
          value: 'adset'
        },
        {
          label: 'Ad',
          value: 'ad'
        }
      ]
    }
  },
  google_search_console: {
    dimensions: {
      label: 'Search Console Dimensions',
      helperText: "The Dimensions for your report. For example: 'page', 'query' or 'country'",
      required: true
    },
    searchTypes: {
      label: 'Search Console Type',
      helperText: "The Type of report to extract from. For example: 'web' or 'image'",
      required: true,
      defaultValues: ['web'],
      options: [
        {
          label: 'Web',
          value: 'web'
        },
        {
          label: 'News',
          value: 'news'
        },
        {
          label: 'Image',
          value: 'image'
        },
        {
          label: 'Video',
          value: 'video'
        }
      ]
    }
  },
  linkedin: {
    dimensions: {
      label: 'LinkedIn Dimensions',
      helperText: "The Dimensions for your report. For example: 'campaign_name' or 'campaign_id'",
      required: true
    },
    metrics: {
      label: 'LinkedIn Metrics',
      helperText: "The Metrics for your report. For example: 'impressions' or 'costInLocalCurrency'",
      required: true
    },
    pivot: {
      label: 'Pivot',
      helperText: "The way to pivot the LinkedIn data. For example: 'campaign'",
      required: true
    }
  },
  shopify: {
    dimensions: {
      label: 'Shopify Dimensions',
      helperText: "The Dimensions for your report. For example: 'name' or 'currency'",
      required: true
    },
    metrics: {
      label: 'Shopify Metrics',
      helperText: "The Metrics for your report. For example: 'total_discounts' or 'total_price'",
      required: true
    },
    table: {
      label: 'Shopify Table',
      helperText: "The table to pull data from. For example: 'orders' or 'customers'",
      required: true
    }
  },
  snapchat: {
    dimensions: {
      label: 'Snapchat Dimensions',
      helperText: "The Dimensions for your report. For example: 'adsquad' or 'campaign'",
      required: true
    },
    metrics: {
      label: 'Snapchat Metrics',
      helperText: "The Metrics for your report. For example: 'spend' or 'impressions'",
      required: true
    },
    breakdown: {
      label: 'Breakdown',
      helperText: "The breakdown for your report. For example: 'adsquad' or 'campaign'",
      required: true
    }
  },
  tiktok: {
    dimensions: {
      label: 'TikTok Dimensions',
      helperText: "The Dimensions for your report. For example: 'campaign_name' or 'campaign_id'",
      required: true
    },
    metrics: {
      label: 'TikTok Metrics',
      helperText: "The Metrics for your report. For example: 'spend' or 'impressions'",
      required: true
    },
    reportType: {
      label: 'Report Type',
      helperText: "The Type of report you want to pull. For example: 'BASIC'",
      required: true
    },
    dataLevel: {
      label: 'Data Level',
      helperText: "The level of the data you want to pull. For example: 'AUCTION_CAMPAIGN'",
      required: true
    }
  },
  merchant_center: {
    merchantCenterDimensions: {
      label: 'Merchant Center Tables',
      helperText:
        "The tables for your config. Can be 'products', 'regional_inventories', 'local_inventories', 'price_benchmarks' or 'best_sellers'",
      required: true,
      options: [
        {
          label: 'Product and product issues',
          value: 'products'
        },
        {
          label: 'Regional inventories',
          value: 'regional_inventories'
        },
        {
          label: 'Local inventories',
          value: 'local_inventories'
        },
        {
          label: 'Price benchmarks',
          value: 'price_benchmarks'
        },
        {
          label: 'Best sellers',
          value: 'best_sellers'
        }
      ]
    },
    truncate: {
      label: 'Truncate',
      helperText: 'Set this to true if you want to use the truncate list.',
      required: false
    },
    truncateList: {
      label: 'Truncate Tables',
      helperText:
        "The names of tables to keep pruned. For example, to prune a table called 'Products_123', put 'Products' here.",
      required: false,
      options: [
        {
          label: 'Products',
          value: 'Products'
        },
        {
          label: 'Products_PriceBenchmarks',
          value: 'Products_PriceBenchmarks'
        },
        {
          label: 'BestSellers_TopBrands',
          value: 'BestSellers_TopBrands'
        },
        {
          label: 'BestSellers_TopProducts',
          value: 'BestSellers_TopProducts'
        },
        {
          label: 'BestSellers_TopProducts_Inventory',
          value: 'BestSellers_TopProducts_Inventory'
        }
      ]
    }
  },
  google_sheets: {
    dimensions: {
      helperText:
        "The names of columns in your Spreadsheet that contain text values. For example 'Quarter' or 'Market'. Required if no metrics are specified.",
      required: false
    },
    metrics: {
      helperText:
        "The names of columns in your Spreadsheet that contain number values. For example 'Budget' or 'Target ROAS'. Required if no dimensions are specified.",
      required: false
    },
    multipleInputSeparator: /[\t,]/,
    worksheetUrl: {
      required: false
    }
  },
  amazon: {
    table: {
      label: 'Amazon Ads Report Type Id',
      helperText: 'The report type id from the API to use',
      required: true,
      options: [
        {
          label: 'Sponsored Brands Campaigns',
          value: 'sbCampaigns'
        },
        {
          label: 'Sponsored Display Campaigns',
          value: 'sdCampaigns'
        },
        {
          label: 'Sponsored Products Campaigns',
          value: 'spCampaigns'
        },
        {
          label: 'Sponsored Television Campaigns - BETA US Only',
          value: 'stCampaigns'
        },
        {
          label: 'Sponsored Brands Ad Groups',
          value: 'sbAdGroup'
        },
        {
          label: 'Sponsored Display Ad Groups',
          value: 'sdAdGroup'
        },
        {
          label: 'Sponsored Display Placement',
          value: 'sbCampaignPlacement'
        },
        {
          label: 'Sponsored Brands Targeting',
          value: 'sbTargeting'
        },
        {
          label: 'Sponsored Display Targeting',
          value: 'sdTargeting'
        },
        {
          label: 'Sponsored Products Targeting',
          value: 'spTargeting'
        },
        {
          label: 'Sponsored Television Targeting - BETA US Only',
          value: 'stTargeting'
        },
        {
          label: 'Sponsored Brands Search Term',
          value: 'sbSearchTerm'
        },
        {
          label: 'Sponsored Products Search Term',
          value: 'spSearchTerm'
        },
        {
          label: 'Sponsored Display Advertised Product',
          value: 'sdAdvertisedProduct'
        },
        {
          label: 'Sponsored Products Advertised Product',
          value: 'spAdvertisedProduct'
        },
        {
          label: 'Sponsored Brands Ad',
          value: 'sbAds'
        },
        {
          label: 'Sponsored Brands Purchased Product',
          value: 'sbPurchasedProduct'
        },
        {
          label: 'Sponsored Display Purchased Product',
          value: 'sdPurchasedProduct'
        },
        {
          label: 'Sponsored Products Purchased Product',
          value: 'spPurchasedProduct'
        },
        {
          label: 'Sponsored Brands Gross and Invalid Traffic',
          value: 'sbGrossAndInvalids'
        },
        {
          label: 'Sponsored Display Gross and Invalid Traffic',
          value: 'sdGrossAndInvalids'
        },
        {
          label: 'Sponsored Products Gross and Invalid Traffic',
          value: 'spGrossAndInvalids'
        }
      ]
    },
    metrics: {
      label: 'Amazon Ads Metrics',
      helperText: "The Metrics for your report. For example: 'clicks' or 'impressions'",
      required: false
    },
    dimensions: {
      label: 'Amazon Ads Dimensions',
      helperText: "The Dimensions for your report. For example: 'date' or 'campaignName'",
      required: true
    },
    breakdowns: {
      label: 'Group By',
      helperText:
        "The level of the granularity for the report. For example, pick 'campaign' to aggregate on Campaign level.",
      required: true
    },
    level: {
      label: 'Time Unit',
      helperText: "The time unit used to pull the data in. 'Daily' or 'Summary'.",
      required: true,
      options: [
        {
          label: 'Daily',
          value: 'DAILY'
        },
        {
          label: 'Summary',
          value: 'SUMMARY'
        }
      ]
    }
  },
  criteo: {
    table: {
      label: 'Criteo Report Endpoint',
      helperText: 'The endpoint for the API to use',
      required: true,
      defaultValue: 'statistics/report',
      options: [
        {
          label: 'Campaign Statistics',
          value: 'statistics/report'
        }
      ]
    },
    dimensions: {
      label: 'Criteo Dimensions',
      helperText: "The Dimensions for your report. For example: 'Day' or 'Campaign'",
      required: true
    },
    metrics: {
      label: 'Criteo Metrics',
      helperText: "The Metrics for your report. For example: 'Clicks' or 'Displays'",
      required: false
    }
  }
}
