import { QueryClient } from 'react-query'
import { type fetch } from 'shared/api'
import { HttpStatusCodes } from 'shared/httpStatus'

const STALETIME_IN_MILLI_SECONDS = 60 * 1000 // 1 minute
const CACHETIME_IN_MILLI_SECONDS = 60 * 5 * 1000 // 5 minutes
const MAX_RETRY_COUNT = 2

const passToErrorBoundary = (error: unknown): boolean => {
  return (error as fetch.ClientError).status_code >= 500
}

const retryFn = (failureCount: number, error: unknown): boolean => {
  return (
    (error as fetch.ClientError).status_code >= HttpStatusCodes.INTERNAL_SERVER_ERROR && failureCount <= MAX_RETRY_COUNT
  )
}

const defaultOptions = {
  queries: {
    useErrorBoundary: passToErrorBoundary,
    cacheTime: CACHETIME_IN_MILLI_SECONDS,
    staleTime: STALETIME_IN_MILLI_SECONDS,
    retry: retryFn
  },
  mutations: {
    useErrorBoundary: passToErrorBoundary
  }
}

const queryClient = new QueryClient({
  defaultOptions
})

export const client = (): QueryClient => {
  return queryClient
}
