import {
  Button,
  Card,
  Divider,
  Grid,
  Input,
  RightIcon,
  SearchIcon,
  styled,
  Tooltip,
  Typography
} from '@precis-digital/kurama'
import { useState } from 'react'
import { Controller, useForm } from 'shared/reactHookForm'
import { capitalize } from 'shared/utils'

interface SwitchFlyoutProps {
  title: string
  searchPlaceholder: string
  options: Options
  onClickOption: (option: Option) => void
  onFiltering: (searchInput: string, options: Options) => Options
  groupByParameter?: (options: Options) => Record<string, Options>
}

export interface Option {
  id: number | string
  name: string
  subtitle?: string
  type?: string
  isSelected?: boolean
}
export interface Options extends Array<Option> {}

const BaseFlyoutCard = ({
  option,
  onClickOption
}: {
  option: Option
  onClickOption: (option: Option) => void
}): React.ReactElement => {
  return (
    <StyledCard
      onClick={
        option.isSelected !== true
          ? () => {
              onClickOption(option)
            }
          : undefined
      }
      key={option.id}
      elevation="flat"
    >
      <StyledCardContent>
        <Tooltip body={undefined} title={capitalize(option.name)} kind="singleline" placement="top-start">
          <Typography variant="h5">{capitalize(option.name)}</Typography>
        </Tooltip>

        <Typography variant="body3">
          <StyledGraySpan>{option.subtitle}</StyledGraySpan>
        </Typography>
      </StyledCardContent>
      {option.isSelected !== true && (
        <StyledButtonContainer>
          <Button
            onClick={() => {
              onClickOption(option)
            }}
            variant="text"
            scheme="light"
          >
            <RightIcon />
          </Button>
        </StyledButtonContainer>
      )}
    </StyledCard>
  )
}

const SwitchFlyout = (props: SwitchFlyoutProps): React.ReactElement => {
  const { title, searchPlaceholder, options, onClickOption, onFiltering, groupByParameter } = props
  const [searchInput, setSearchInput] = useState('')

  const filteredOptions = onFiltering(searchInput, options)

  const groupedOptions = groupByParameter != null ? groupByParameter(filteredOptions) : undefined

  const { control, setValue } = useForm({})
  return (
    <StyledSwitchFlyout>
      <Typography variant="h2">{title}</Typography>
      <form>
        <Controller
          name="searchInput"
          control={control}
          render={({ field: { name, value } }) => {
            return (
              <Input
                assistive
                name="searchInput"
                value={value}
                placeholder={searchPlaceholder}
                onChange={(e) => {
                  setValue(name, e.target.value)
                  setSearchInput(e.target.value)
                }}
                icon={<SearchIcon />}
              />
            )
          }}
        />
      </form>
      <Divider />
      {groupedOptions == null
        ? filteredOptions?.map((option) => {
            return <BaseFlyoutCard key={option.id} option={option} onClickOption={onClickOption} />
          })
        : Object.keys(groupedOptions).map((parameter) => {
            return (
              <Grid key={parameter}>
                <Typography variant="h5">
                  <StyledGraySpan>{parameter}</StyledGraySpan>
                </Typography>
                {groupedOptions[parameter].map((option) => {
                  return <BaseFlyoutCard key={option.id} option={option} onClickOption={onClickOption} />
                })}
              </Grid>
            )
          })}
    </StyledSwitchFlyout>
  )
}
export default SwitchFlyout

const StyledSwitchFlyout = styled(Grid)(({ theme }) => ({
  paddingTop: '20px',
  paddingBottom: '48px',
  form: {
    paddingTop: '20px',
    paddingBottom: '10px',
    '>div': {
      width: '100%'
    }
  }
}))

const StyledButtonContainer = styled('div')(({ theme }) => ({
  width: '40px',
  button: {
    padding: 0
  }
}))

export const StyledCard = styled(Card)(({ theme }) => ({
  button: {
    alignSelf: 'center'
  }
}))

export const StyledCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  overflow: 'hidden',
  div: {
    ':first-of-type': {
      marginRight: theme.spacing(3)
    }
  },
  h5: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

export const StyledGraySpan = styled('span')(({ theme }) => ({
  color: theme.palette.neutrals.stone150
}))

export const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  bottom: 0,
  marginTop: 'auto',
  width: '22rem',
  paddingBottom: '1rem',
  div: {
    ':first-of-type': {
      padding: 0,
      hr: {
        margin: '0 0 1rem 0'
      }
    }
  }
}))
