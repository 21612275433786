import {
  Typography,
  LineChart,
  Grid,
  defaultTheme as theme,
  MultiSelectDropdown,
  type BadgeColor,
  Badge
} from '@precis-digital/kurama'
import { useTranslation } from 'shared/translations'
import Dots from 'shared/components/Loader/Dots'
import { useEffect, useState } from 'react'
import ChartToolTip from 'shared/components/ChartToolTip'
import ChartLegend from 'shared/components/ChartLegend'
import { getFormattedValue } from 'shared/numberFormat'
import { type AttributionBaseReport, transformAttributionToTimeSeriesData } from 'attributionModel/transformations'
import { type SelectMetricsType } from 'attributionModel/components/Dashboard'
import { makeErrorToast } from 'shared/components/Toaster'
import { defaultToZero, objectKeys } from 'shared/utils'

const MAX_CHANNELS = 6

export const colorsLineChart: Partial<Record<BadgeColor, string>> = {
  info: theme.palette.semantic.info100,
  'dark-mint': theme.palette.graphs.mint100,
  'dark-red': theme.palette.graphs.red50,
  'dark-indigo': theme.palette.graphs.indigo100,
  steel: theme.palette.graphs.steel100,
  purple: theme.palette.graphs.purple100
}

if (objectKeys(colorsLineChart).length !== MAX_CHANNELS) {
  throw new Error(
    `The length of channelColors (${objectKeys(colorsLineChart).length}) does not equal MAX_CHANNELS (${MAX_CHANNELS}).`
  )
}

const topTwoChannels = (data: AttributionBaseReport[], metric: SelectMetricsType): string[] => {
  // Aggregate the revenue for each channel
  const channelMetrics: Record<string, number> = {}
  data.forEach((report) => {
    channelMetrics[report.channel] = defaultToZero(channelMetrics[report.channel]) + defaultToZero(report[metric])
  })

  // Sort the channels by aggregated revenue in descending order
  const sortedChannels = Object.entries(channelMetrics).sort((a, b) => b[1] - a[1])

  // Get the top two channels
  const topTwoChannels = sortedChannels.slice(0, 2).map(([channel]) => channel)

  return topTwoChannels
}

export default function ProgressOverTime({
  reports,
  isDataLoaded,
  selectedMetrics,
  currencyCode
}: {
  reports: AttributionBaseReport[]
  selectedMetrics: SelectMetricsType
  isDataLoaded: boolean
  currencyCode: string
}): React.ReactElement {
  const { t } = useTranslation('attributionModel')
  const typeOfProgressValue: string =
    selectedMetrics === 'revenue' ? t('dashboard.revenue') : t('dashboard.conversions')

  const channels: string[] = [...new Set(reports.map((report) => report.channel))].sort((a, b) => a.localeCompare(b))

  const [selectedChannels, setSelectedChannels] = useState<string[]>(['Direct'])

  useEffect(() => {
    const updatedDefaultChannels = topTwoChannels(reports, selectedMetrics)
    setSelectedChannels(updatedDefaultChannels)
  }, [selectedMetrics, reports])

  const progressOverTimeData = transformAttributionToTimeSeriesData(reports, selectedMetrics, selectedChannels)

  return (
    <Grid container>
      <Typography variant="h2">{t('dashboard.progressOverTime.title')}</Typography>

      <Grid
        container
        item
        xs={12}
        marginTop={theme.spacing(4)}
        marginBottom={theme.spacing(4)}
        gap={theme.spacing(4)}
        alignItems="center"
      >
        <Grid item>
          <Typography variant="body2">{t('dashboard.showMe')}</Typography>
        </Grid>
        <Grid item>
          <MultiSelectDropdown
            title="Select channel"
            buttonTitle="Select channel"
            buttonWidth="200px"
            allowSelectAll={false}
            value={selectedChannels}
            options={channels.map((channel) => ({ label: channel, value: channel }))}
            onChange={(_, value) => {
              if (value.length >= 1 && value.length <= MAX_CHANNELS) {
                setSelectedChannels(value.map((item) => item.value))
              } else if (value.length > MAX_CHANNELS) {
                makeErrorToast(t('dashboard.progressOverTime.channelLimitExceeded', { limit: MAX_CHANNELS }))
              } else {
                makeErrorToast(t('dashboard.progressOverTime.channelLimitNotMet'))
              }
            }}
          />
        </Grid>
      </Grid>

      {!isDataLoaded && (
        <Grid container justifyContent="center">
          <Dots />
        </Grid>
      )}
      {isDataLoaded && progressOverTimeData.length === 0 && (
        <Grid container margin="50px 0" justifyContent="center">
          <Typography variant="h4">{t('dashboard.progressOverTime.noData')}</Typography>
        </Grid>
      )}
      {isDataLoaded && progressOverTimeData.length > 0 && (
        <Grid height="438px" width="100%">
          <ChartLegend
            selectedRecords={selectedChannels.reduce(
              (result: Record<string, { badgeColor: BadgeColor }>, channel, index) => {
                const badgeColor = objectKeys(colorsLineChart)[index]
                result[channel] = { badgeColor }
                return result
              },
              {}
            )}
          />
          <LineChart
            data={progressOverTimeData}
            margin={{
              left: 50,
              right: 50,
              top: 50,
              bottom: 50
            }}
            getConfigByDataKey={(dataKey) => {
              const colorIndex = selectedChannels.findIndex((channel) => channel === dataKey)
              return {
                lineColor: Object.values(colorsLineChart)[colorIndex],
                lineOpacity: 1
              }
            }}
            renderTooltip={({ dataKey, payload }) => {
              return (
                <ChartToolTip
                  {...{
                    customBody: (
                      <span>
                        {Object.keys(payload)
                          .filter((key) => key !== 'x' && key !== 'dateFull')
                          .map((key) => (
                            <div
                              key={key}
                              style={{
                                width: '326px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div style={{ marginRight: '8px' }}>
                                <Badge
                                  color={
                                    objectKeys(colorsLineChart)[
                                      selectedChannels.findIndex((channel) => channel === key)
                                    ]
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  marginRight: '16px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {typeOfProgressValue} • {key}
                              </div>
                              <div style={{ marginLeft: 'auto', marginRight: '32px', whiteSpace: 'nowrap' }}>
                                {getFormattedValue({
                                  value: payload[key] as number,
                                  id: selectedMetrics,
                                  currencyCode
                                })}
                              </div>
                            </div>
                          ))}
                      </span>
                    ),
                    title: payload.dateFull.toString()
                  }}
                />
              )
            }}
            xAxis={{ tickLine: false }}
            yAxis={{
              label: {
                value: typeOfProgressValue
              },
              tick: {
                textAnchor: 'middle'
              },
              tickFormatter: (value: string) =>
                getFormattedValue({ value: Number(value), id: selectedMetrics, currencyCode, compact: true })
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
