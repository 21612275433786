import { type DataSourceItem } from 'channelGrouping/types'
import { getSupportedDataSources } from 'channelGrouping/utils'
import { useQueryClientAccounts } from 'dataSource/api'
import { useMemo } from 'react'
import { type ChannelGroupingResp } from 'shared/api/channelGroupings'
import { useCurrentClient } from 'shared/context/ClientContext'

/**
 * Custom hook that retrieves extended data sources based on the channel grouping response and client accounts.
 * @param channelGroupingResp The channel grouping response.
 * @returns An array of extended data sources or undefined.
 */
const useGetExtendedDataSources = (channelGroupingResp?: ChannelGroupingResp): DataSourceItem[] | undefined => {
  const { currentClient } = useCurrentClient()
  const { data: clientAccounts } = useQueryClientAccounts(currentClient.id)

  return useMemo(() => {
    if (channelGroupingResp === undefined || clientAccounts === undefined) {
      return undefined
    }

    const supportedDataSources = getSupportedDataSources()

    return supportedDataSources.map((dataSource) => {
      return {
        platform: dataSource.platform,
        channels: channelGroupingResp.channelGrouping[dataSource.platform] ?? [],
        connectedDataSources: clientAccounts.filter((account) => account.platform === dataSource.platform),
        label: dataSource?.label ?? '',
        iconUrl: dataSource?.iconUrl ?? ''
      }
    })
  }, [channelGroupingResp, clientAccounts])
}

export default useGetExtendedDataSources
