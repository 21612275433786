import { type FeaturePlan } from 'shared/api/clients'

export const DEFAULT_FEATURE_PLAN: FeaturePlan = {
  name: 'default plan',
  connectors: {
    enabled: false,
    price: 500,
    priceCurrency: 'EUR',
    ahrefs: false,
    dv360: false,
    facebook: false,
    googleAds: false,
    ga4: false,
    googleAnalytics: false,
    merchantCenter: false,
    googleSearchConsole: false,
    googleSheets: false,
    linkedin: false,
    microsoftAdvertising: false,
    shopify: false,
    snapchat: false,
    tiktok: false
  },
  enhancedSignals: {
    enabled: false,
    price: 1000,
    priceCurrency: 'EUR',
    transformations: { profit: false },
    targets: {
      ga4MpGtagPrecisEcomm: false,
      ga4MpGtagPurchase: false,
      ga4MpFirebasePurchase: false,
      ga4MpFirebasePrecisEcomm: false,
      gadsClickConversions: false,
      sgtm: false,
      metaConversion: false
    }
  },
  budgetOptimiser: { enabled: false, price: 1000, priceCurrency: 'EUR' },
  attributionModels: {
    enabled: false,
    price: 1000,
    priceCurrency: 'EUR',
    ddaV2: false,
    integratedAttribution: false,
    rba: false
  },
  reportingSolutions: {
    enabled: false,
    price: 250,
    priceCurrency: 'EUR',
    arimaPlusForecastingReport: false,
    blackFridayReport: false,
    channelGroupingPerformanceReportV2: false,
    dv360PerformanceReport: false,
    facebookVisualCreativeReport: false,
    googleAdsKeywordQualityScoreDashboard: false,
    performanceMaxBreakdownReportV2: false,
    productMixModelingReport: false,
    searchSynergiesReport: false,
    shoppingInsightsBestsellerReport: false,
    shoppingInsightsReportV2: false,
    spendImpactBrandAnalysisReport: false
  }
}
