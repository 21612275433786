import { styled } from '../../utils'
import { Theme } from '../theme'

import Card from '../Card'

interface StyledFileUploadProps {
  disabled: boolean
  theme: Theme
  isPreviewMode: boolean
  isError: boolean
}
export const StyledFileUploadContainer = styled(Card)(
  ({ disabled, theme, isPreviewMode, isError }: StyledFileUploadProps) => ({
    backgroundColor: isPreviewMode
      ? theme?.palette.neutrals.stone100
      : disabled
      ? theme?.palette.neutrals.stone100
      : theme?.palette.neutrals.white0,
    opacity: isPreviewMode ? 0.5 : 1,
    color: disabled ? theme?.palette.neutrals.stone120 : 'initial',
    minHeight: '72px',
    border: isError ? `1px solid ${theme.palette.semantic.error100}` : `1px solid ${theme.palette.neutrals.stone100}`
  })
)

export const StyledFileInformation = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: `0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`
}))

export const StyledMessage = styled('div')(({ theme }: { theme?: Theme }) => ({
  color: theme?.palette.secondary.main,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '6px',
  span: {
    paddingLeft: '6px'
  }
}))
