import React, { ReactElement, forwardRef, useEffect, useState } from 'react'
import { ToasterProps } from './types'
import {
  StyledCardToaster,
  StyledGridCheckContainer,
  StyledProgressLoader,
  StyledTypographyToasterDescription
} from './styles'
import Grid from '../Grid'
import Typography from '../Typography'
import DotsLoader from '../DotsLoader'
import { Grow, Slide } from '@mui/material'
import Button from '../Button'
import { CancelIcon } from '../Icons'

const DEFAULT_PROGRESS_VALUE = 0

const Toaster = forwardRef<HTMLDivElement, ToasterProps>(
  (
    {
      variant,
      open,
      onClose,
      loading = false,
      onConfirm,
      description,
      loadingMessage,
      loadingIcon = <DotsLoader />,
      successDelay,
      headerMessage,
      headerIcon,
      confirmButtonMessage,
      ...props
    },
    ref
  ): ReactElement | null => {
    const [progressValue, setProgressValue] = useState<number>(DEFAULT_PROGRESS_VALUE)
    const [isHovering, setIsHovering] = useState<boolean>(false)

    if (successDelay == null && confirmButtonMessage == null) {
      throw new Error('Toaster must have either a successDelay or a confirmButtonMessage.')
    }

    const handleConfirm = (): void => {
      if (onConfirm != null) onConfirm()
      handleClose()
    }

    const handleClose = (): void => {
      onClose()
    }

    useEffect(() => {
      if (open && !loading && successDelay != null) {
        // If the toaster is finished and supposed to slowly close (successDelay != null)
        // this animates the progress bar by increments of 1 every 1/100th of the successDelay
        // If the user is hovering the toaster, however, the animation will be paused
        const interval = setInterval(() => {
          if (isHovering) return
          setProgressValue((prevProgressValue) => (prevProgressValue >= 100 ? 100 : prevProgressValue + 1))
        }, successDelay / 100)

        return () => {
          clearInterval(interval)
        }
      }
    }, [loading, successDelay, open, isHovering])

    useEffect(() => {
      if (progressValue >= 100) {
        // Because the animation is actually a transition of 0.1s, we need to wait for it to finish
        // before we can close the toaster.
        const timeout = setTimeout(() => {
          handleConfirm()
        }, 50)

        return () => clearTimeout(timeout)
      }
    }, [progressValue])

    useEffect(() => {
      if (!open) {
        // If the toaster is closed, we need to reset the progress bar and the hovering state
        // This is done in a timeout, so the user doesn't see it because of the <Slide> animation
        const timeout = setTimeout(() => {
          setProgressValue(DEFAULT_PROGRESS_VALUE)
          setIsHovering(false)
        }, 200)

        return () => clearTimeout(timeout)
      }
    }, [open])

    return (
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <StyledCardToaster
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          ref={ref}
          {...props}
        >
          {loading && (
            <Grid display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Typography variant="h3">{loadingMessage}</Typography>
              {loadingIcon}
            </Grid>
          )}
          {!loading && (
            <Grow in style={{ transformOrigin: '50% 100% 0' }}>
              <Grid display="flex" flexDirection="column" gap="16px" width="100%">
                <Grid display="flex" justifyContent="space-between" alignItems="center">
                  <Grid display="flex" gap="16px" alignItems="center">
                    <StyledGridCheckContainer variant={variant}>{headerIcon}</StyledGridCheckContainer>
                    <Typography variant="h3">{headerMessage}</Typography>
                  </Grid>
                  <Button variant="text" leftIcon={<CancelIcon />} onClick={handleClose} />
                </Grid>
                <StyledTypographyToasterDescription
                  title={typeof description === 'string' ? description : undefined}
                  variant="body1"
                >
                  {description}
                </StyledTypographyToasterDescription>
                {successDelay == null ? (
                  <Button variant="tonal" onClick={handleConfirm} fullWidth>
                    {confirmButtonMessage}
                  </Button>
                ) : (
                  <StyledProgressLoader variant="determinate" value={progressValue} isPaused={isHovering} />
                )}
              </Grid>
            </Grow>
          )}
        </StyledCardToaster>
      </Slide>
    )
  }
)

export default Toaster
