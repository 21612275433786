import { DividerProps } from './types'
import { StyledDividerContainer, StyledLine, StyledTextContainer } from './styles'
import { DIVIDER_CLASS_NAME } from './classNames'

const Divider = ({ text, color }: DividerProps): React.ReactElement => {
  const renderTextElement: boolean = !(text === '' || text === undefined)

  return (
    <StyledDividerContainer className={DIVIDER_CLASS_NAME} hasTextElement={renderTextElement}>
      {renderTextElement && <StyledTextContainer variant="h5">{text}</StyledTextContainer>}
      <StyledLine color={color} />
    </StyledDividerContainer>
  )
}

export default Divider
