import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '../../utils'
import { Theme } from '../theme'
import { type Color } from './types'
export const StyledLinearProgressGlobalLoader = styled(LinearProgress)<{ theme?: Theme; backgroundColor?: Color }>(
  ({ theme, backgroundColor }) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '4px',
    zIndex: 1300,
    ...(backgroundColor === 'blue' && {
      backgroundColor: theme.palette.main.blue05
    }),
    ...(backgroundColor === 'yellow' && {
      backgroundColor: theme.palette.semantic.warning05
    }),
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: theme?.borderRadius.xSmall,

      ...(backgroundColor === 'blue' && {
        backgroundColor: theme.palette.primary.main
      }),
      ...(backgroundColor === 'yellow' && {
        backgroundColor: theme.palette.semantic.warning100
      }),
      transitionDuration: '5s'
    }
  })
)
