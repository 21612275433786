import { type Operator } from './types'

export const OPERATORS: Array<{ value: Operator; label: string }> = [
  {
    value: 'exactly_matches',
    label: 'Exactly matches'
  },
  {
    value: 'not_exactly_matches',
    label: 'Does not exactly match'
  },
  {
    value: 'contains',
    label: 'Contains'
  },
  {
    value: 'not_contains',
    label: 'Does not contain'
  },
  {
    value: 'matches_regex',
    label: 'Matches regex'
  },
  {
    value: 'not_matches_regex',
    label: 'Does not match regex'
  }
]

export const DIMENSION_ALIAS_MAP: Record<string, string[][]> = {
  ga4: [
    ['view_or_property_id', 'property_id'],
    ['streamName'],
    ['streamId'],
    ['account_name'],
    ['hostname'],
    ['campaign'],
    ['campaign_id'],
    ['source'],
    ['medium'],
    ['source_medium']
  ],
  google_analytics: [
    ['view_or_property_id', 'profile_name'],
    ['hostname'],
    ['campaign'],
    ['adwordsCampaignId'],
    ['source'],
    ['medium'],
    ['source_medium'],
    ['web_property_id'],
    ['account_id'],
    ['profile_name']
  ],
  gads: [
    ['accountid', 'customer__id'],
    ['accountname', 'customer__descriptive_name'],
    ['campaign', 'campaign__name'],
    ['campaign__id'],
    ['campaign__status'],
    ['campaign__advertising_channel_type'],
    ['campaign__advertising_channel_sub_type']
  ],
  bing: [
    ['accountid', 'AccountNumber'],
    ['accountname', 'AccountName'],
    ['campaign', 'CampaignName'],
    ['CampaignId'],
    ['CampaignType'],
    ['AdDistribution'],
    ['Network']
  ],
  facebook: [
    ['accountid', 'account_id'],
    ['accountname', 'account_name'],
    ['campaign', 'campaign_name'],
    ['campaign_id'],
    ['buying_type'],
    ['objective'],
    ['adset_name'],
    ['adset_id'],
    ['ad_name'],
    ['ad_id']
  ],
  dv360: [
    ['advertiser_id', 'filter_advertiser'],
    ['advertiser_name', 'filter_advertiser_name'],
    ['filter_partner'],
    ['filter_partner_name'],
    ['filter_advertiser'],
    ['filter_advertiser_name'],
    ['filter_insertion_order'],
    ['filter_insertion_order_name'],
    ['filter_line_item'],
    ['filter_line_item_name'],
    ['filter_media_plan'],
    ['filter_media_plan_name', 'campaign'],
    ['filter_creative_type'],
    ['filter_position_in_content']
  ],
  tiktok: [
    ['advertiser_name'],
    ['account_id'],
    ['campaign_name'],
    ['campaign_id'],
    ['objective_type'],
    ['campaign_dedicate_type']
  ],
  linkedin: [
    ['account_name'],
    ['account_id'],
    ['campaign_name'],
    ['campaign_id'],
    ['campaign_type'],
    ['campaign_objective_type']
  ],
  snapchat: [['account_name'], ['account_id'], ['name'], ['id'], ['objective']]
}
