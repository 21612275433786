import React, { useEffect, useState } from 'react'
import { Link, Typography, Grid, Card, Avatar, Button, styled } from '@precis-digital/kurama'
import { useQueryMessages, useUpdateMessage, useResendMessage } from 'workspace/api'
import Dots from 'shared/components/Loader/Dots'
import { useTranslation } from 'shared/translations'
import { formatDateTimeToAtFormat } from 'shared/dateFns'
import { StyledTypographyAnnotation } from '../Flyout/styles'
import { USER_PERMISSIONS_DOCUMENTATION_LINK } from 'shared/constants/links'
import { makeToastWithLoading } from 'shared/components/Toaster'

interface PendingInvitesProps {
  clientId: string
}

export const PendingInvites = ({ clientId }: PendingInvitesProps): React.ReactElement => {
  const { t } = useTranslation('workspace')
  return (
    <StyledPendingInvitesContainer container>
      <Typography variant="h3">{t('titles.pendingInvites')}</Typography>
      <StyledPendingInvitesDescription variant="body2">
        {t('descriptions.pendingInvites')}
        <Link variant="body2" href={USER_PERMISSIONS_DOCUMENTATION_LINK} target="_blank" rel="noreferrer">
          {' '}
          {t('descriptions.pendingInvitesLink')}
        </Link>
      </StyledPendingInvitesDescription>
      <PendingInvitesList clientId={clientId} />
    </StyledPendingInvitesContainer>
  )
}

const PendingInvitesList = ({ clientId }: PendingInvitesProps): React.ReactElement => {
  const [currentPendingInviteId, setCurrentPendingInviteId] = useState<string>('')
  const { data: messages, isLoading: isLoadingMessages } = useQueryMessages(clientId)

  const { t } = useTranslation('workspace')

  const { mutate: resendMessage } = useResendMessage()
  const { mutate: updateMessage, isLoading: isUpdateMessageLoading } = useUpdateMessage()

  const handleResendMessage = (messageId: string, clientId: string, toEmail: string): void => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    resendMessage(
      { messageId, clientId },
      {
        onSuccess() {
          toastOnSuccess(`Notification to ${toEmail} was successfully sent.`)
        },
        onError() {
          toastOnError(`An error occuring while notifying ${toEmail}. Please try again.`)
        }
      }
    )
  }

  const handleRevokeInvite = ({
    messageId,
    clientId,
    toEmail
  }: {
    messageId: string
    clientId: string
    toEmail: string
  }): void => {
    const { toastOnSuccess, toastOnError } = makeToastWithLoading()
    setCurrentPendingInviteId(messageId)
    updateMessage(
      { messageId, clientId },
      {
        onSuccess() {
          toastOnSuccess(`Successfully revoked invitation for ${toEmail}.`)
        },
        onError() {
          toastOnError(`An error occuring while revoking invitation for ${toEmail}. Please try again.`)
        }
      }
    )
  }

  useEffect(() => {
    if (!isUpdateMessageLoading) {
      setCurrentPendingInviteId('')
    }
  }, [isUpdateMessageLoading])

  if (isLoadingMessages) {
    return (
      <Grid container>
        <Dots />
      </Grid>
    )
  }

  if (messages?.length === 0) {
    return <StyledNoPendingInvites> {t('titles.noPendingInvites')}</StyledNoPendingInvites>
  }

  return (
    <StyledPendingInvitesListContainer>
      {messages?.map((message) => (
        <Card key={message.id} elevation="flat">
          <StyledWorkSpaceAvatarContainer>
            <Avatar kind="label" label={message.toEmail} size="medium" />
            <Grid className="description">
              <Typography variant="h3">{message.toEmail}</Typography>
              <StyledTypographyAnnotation variant="body3">
                {t('descriptions.sentOn', {
                  datetime: formatDateTimeToAtFormat(`${message.createdAt}Z`)
                })}
              </StyledTypographyAnnotation>
            </Grid>
          </StyledWorkSpaceAvatarContainer>
          <StyledPendingInvitesListActionsContainer>
            <Button
              variant="tonal"
              onClick={() => {
                handleRevokeInvite({ messageId: message.id, clientId: message.clientId, toEmail: message.toEmail })
              }}
              disabled={isUpdateMessageLoading && currentPendingInviteId === message.id}
            >
              {isUpdateMessageLoading && currentPendingInviteId === message.id ? <Dots /> : t('buttons.revokeInvite')}
            </Button>
            <Button
              variant="filled"
              onClick={() => {
                handleResendMessage(message.id, message.clientId, message.toEmail)
              }}
            >
              {t('buttons.notifyPending')}
            </Button>
          </StyledPendingInvitesListActionsContainer>
        </Card>
      ))}
    </StyledPendingInvitesListContainer>
  )
}

const StyledPendingInvitesContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  gap: 24,
  flex: 'none',
  alignSelf: 'stretch',
  marginTop: '24px'
}))
const StyledPendingInvitesDescription = styled(Typography)(() => ({
  width: '43%'
}))
const StyledPendingInvitesListContainer = styled(Grid)(() => ({
  width: '100%'
}))
const StyledPendingInvitesListActionsContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 0,
  gap: 8,
  height: 40,
  flex: 'none',
  button: {
    padding: '8px 16px'
  }
}))

const StyledWorkSpaceAvatarContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  height: 64,
  flex: 'none'
}))

const StyledNoPendingInvites = styled(Typography)(() => ({
  marginBottom: '100px'
}))

export default PendingInvites
