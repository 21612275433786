import { isClientEnvironment } from './utils'
import config from 'shared/config'
import logger from 'shared/logger'

const log = logger().child({
  namespace: 'intercom'
})

const APP_ID = config('NEXT_PUBLIC_INTERCOM_APP_ID') as string

export const boot = ({ email, userId, clientId }: { email: string; userId: string; clientId: string }): void => {
  if (isClientEnvironment() && window?.Intercom != null) {
    try {
      window.Intercom('boot', {
        app_id: APP_ID,
        email,
        user_id: userId,
        company_id: clientId
      })
      log.info('Intercom booted')
    } catch (e) {
      log.error(`Intercom boot failed: ${(e as Error).message}`)
    }
  }
}

export const shutdown = (): void => {
  if (isClientEnvironment() && window?.Intercom != null) {
    try {
      window.Intercom('shutdown')
      log.info('Intercom shutdown')
    } catch (e) {
      log.error(`Intercom shutdown failed: ${(e as Error).message}`)
    }
  }
}

export const update = ({ email, userId, clientId }: { email: string; userId: string; clientId: string }): void => {
  if (isClientEnvironment() && window?.Intercom != null) {
    try {
      window.Intercom('update', {
        email,
        company_id: clientId,
        user_id: userId
      })
      log.info('Intercom updated')
    } catch (e) {
      log.error(`Intercom update failed: ${(e as Error).message}`)
    }
  }
}
