import { useState } from 'react'

export default function useExpandedCards(): [boolean[], (index: number, expanded: boolean) => void] {
  const [expandedCards, setExpandedCards] = useState<boolean[]>([])
  const setExpandedCard = (index: number, expanded: boolean): void => {
    const expandedCardsToUpdate: boolean[] = [...expandedCards]
    expandedCardsToUpdate[index] = expanded
    setExpandedCards(expandedCardsToUpdate)
  }
  return [expandedCards, setExpandedCard]
}
