import { Avatar, AvatarGroup, Grid, Typography, styled } from '@precis-digital/kurama'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { type ReactElement } from 'react'
import { type ChannelItem } from 'shared/api/channelGroupings'
import { useTranslation } from 'shared/translations'
import { convertListOfStringsToSentence } from 'shared/utils'

interface ChannelNameInCardProps {
  channel: ChannelItem
  isOtherChannel?: boolean
  isNewChannel?: boolean
}

const ChannelNameInCard = ({
  channel,
  isOtherChannel = false,
  isNewChannel = false
}: ChannelNameInCardProps): ReactElement => {
  const { dataSourceItem } = useCurrentChannelGroupingDataSource()
  const { dataSourcesMap } = useCurrentChannelGrouping()
  const { t } = useTranslation('channelGrouping')

  const dataSourcesWithThisChannel = Object.values(dataSourcesMap).filter((dataSourceItem) => {
    if (isNewChannel) {
      return dataSourceItem.channels.map((channelItem) => channelItem.name).includes(channel.name)
    } else {
      return dataSourceItem.channels.some((channelItem) => channelItem.id === channel.id)
    }
  })

  const isOnlyDataSourceWithThisChannel = dataSourcesWithThisChannel.length === 1
  const dataSourcesLabels = dataSourcesWithThisChannel.map((dataSource) => dataSource.label)

  return (
    <Grid paddingBottom="16px">
      {channel.name !== '' && <StyledTypography2LineClamped variant="h2">{channel.name}</StyledTypography2LineClamped>}
      <Grid display="flex" gap="4px">
        {dataSourcesWithThisChannel.length > 0 && (
          <AvatarGroup size="small" max={2}>
            {dataSourcesWithThisChannel.map((dataSource) => (
              <Avatar key={dataSource.platform} kind="image" imageUrl={dataSource.iconUrl} size="small" />
            ))}
          </AvatarGroup>
        )}
        <Typography variant="body2">
          {isOtherChannel && t('dataSourceFormView.channelsTab.channelOccursInAllDataSources')}
          {isNewChannel &&
            (dataSourcesWithThisChannel.length === 0
              ? t('dataSourceFormView.channelsTab.channelIsBrandNew')
              : t('dataSourceFormView.channelsTab.channelIsBrandNewButIsInOtherDataSources', {
                  otherDataSourcesSentence: convertListOfStringsToSentence(dataSourcesLabels)
                }))}
          {!isNewChannel &&
            !isOtherChannel &&
            (isOnlyDataSourceWithThisChannel
              ? t('dataSourceFormView.channelsTab.channelOnlyExistIn', {
                  dataSourceLabel: dataSourceItem.label
                })
              : t('dataSourceFormView.channelsTab.channelExistsInOtherDataSources', {
                  otherDataSourcesSentence: convertListOfStringsToSentence(dataSourcesLabels)
                }))}
        </Typography>
      </Grid>
    </Grid>
  )
}

const StyledTypography1LineClamped = styled(Typography)(() => ({
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '1',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

const StyledTypography2LineClamped = styled(StyledTypography1LineClamped)(() => ({
  '-webkit-line-clamp': '2',
  height: '36px'
}))

export default ChannelNameInCard
