import { type UsedInConfig } from 'channelGrouping/types'
import {
  useMutation,
  type UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  type UseQueryResult
} from 'react-query'
import { analytics, channelGrouping, clients, type fetch } from 'shared/api'
import { formatDateToString } from 'shared/dateFns'

export const queryKeys = {
  channelGroupings: 'channelgroupings',
  channelGrouping: 'channelgrouping',
  channelGroupingPerformanceData: 'channelGroupingPerformanceData',
  userDetailsByEmails: 'userDetailsByEmails'
}

export const useQueryChannelGroupings = (
  clientId: string
): UseQueryResult<channelGrouping.ChannelGroupingsResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.channelGroupings, clientId],
    async () => await channelGrouping.getChannelGroupings(clientId),
    {
      enabled: clientId !== ''
    }
  )
}

export const useQueryChannelGrouping = (
  channelGroupingId: number,
  clientId: string
): UseQueryResult<channelGrouping.ChannelGroupingResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.channelGrouping, channelGroupingId, clientId],
    async () => await channelGrouping.getChannelGrouping(channelGroupingId, clientId),
    {
      enabled: channelGroupingId !== undefined,
      refetchOnWindowFocus: false
    }
  )
}

export const useMutationCreateChannelGrouping = (): UseMutationResult<
  channelGrouping.ChannelGroupingResp,
  fetch.ClientError,
  channelGrouping.CreateChannelGroupingReq
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data) => await channelGrouping.createChannelGrouping(data),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries([queryKeys.channelGroupings, data.clientId])
    }
  })
}

export const useMutationUpdateChannelGrouping = (): UseMutationResult<
  channelGrouping.ChannelGroupingResp,
  fetch.ClientError,
  channelGrouping.UpdateChannelGroupingReq
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data) => await channelGrouping.updateChannelGrouping(data),
    onMutate: async (newData) => {
      // Backup the existing data
      const oldData: channelGrouping.ChannelGroupingResp | undefined = queryClient.getQueryData([
        queryKeys.channelGrouping,
        newData.id,
        newData.clientId
      ])

      // Optimistically update to the new value
      queryClient.setQueryData([queryKeys.channelGrouping, newData.id, newData.clientId], newData)

      // Return the old data to be used in onError or onSettled if needed
      return { oldData }
    },
    onError: (_, variables, context) => {
      queryClient.setQueryData([queryKeys.channelGrouping, variables.id, variables.clientId], context?.oldData)
    },
    onSettled: async (_, __, variables) => {
      // Whether the mutation succeeds or fails, refetch the query
      await queryClient.invalidateQueries([queryKeys.channelGrouping, variables.id, variables.clientId])
      await queryClient.invalidateQueries([queryKeys.channelGroupingPerformanceData, variables.id, variables.clientId])
    }
  })
}

export const useMutationDeleteChannelGrouping = (): UseMutationResult<
  string,
  fetch.ClientError,
  channelGrouping.DeleteChannelGroupingReq
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data) => await channelGrouping.deleteChannelGrouping(data),
    onSuccess: async (_, variables) => {
      void queryClient.invalidateQueries([queryKeys.channelGrouping, variables.configId, variables.clientId])
      void queryClient.invalidateQueries([queryKeys.channelGroupings, variables.clientId])
    },
    onError: async (_, variables) => {
      await queryClient.invalidateQueries([queryKeys.channelGroupings, variables.clientId])
    }
  })
}

export const useQueryChannelGroupingPerformanceData = ({
  report,
  clientId,
  channelGroupingId,
  currencyCode,
  fields,
  filters,
  dateStart,
  dateEnd,
  useCache = true,
  enabled,
  pageSize,
  pageNum,
  orderBy
}: {
  report: analytics.ChannelGroupingReportType
  clientId: string
  channelGroupingId: number
  currencyCode?: string
  fields: analytics.AnalyticsReqField[]
  filters?: analytics.Filter[]
  dateStart: Date
  dateEnd: Date
  useCache?: boolean
  enabled: boolean
  pageSize?: number
  pageNum?: number
  orderBy?: analytics.AnalyticsReqOrderBy
}): UseQueryResult<analytics.AnalyticsResp, Omit<analytics.AnalyticsReq, 'collection'>> => {
  return useQuery({
    queryKey: [
      queryKeys.channelGroupingPerformanceData,
      channelGroupingId,
      clientId,
      report,
      fields.map((field) => field.name).join(','),
      dateStart.toISOString(),
      dateEnd.toISOString(),
      filters?.map((filter) => filter.field + filter.operator + (filter.value ?? '').toString()).join(','),
      orderBy?.fields.join(','),
      orderBy?.direction,
      useCache,
      pageNum,
      pageSize
    ],
    queryFn: async () => {
      return await analytics.postChannelGroupingReport({
        id: channelGroupingId.toString(),
        report,
        clientId,
        targetCurrencyCode: currencyCode,
        fields,
        limit: pageSize,
        cursor: pageNum != null && pageSize != null ? pageNum * pageSize : undefined,
        groupBy: fields.filter((field) => field.aggregation == null).map((field) => field.name),
        filters: [
          {
            field: 'date',
            operator: 'BETWEEN',
            value: [
              formatDateToString({ date: dateStart, targetFormat: 'yyyy-MM-dd' }),
              formatDateToString({ date: dateEnd, targetFormat: 'yyyy-MM-dd' })
            ]
          },
          ...(filters ?? [])
        ],
        useCache,
        orderBy
      })
    },
    refetchOnWindowFocus: false,
    enabled,
    keepPreviousData: pageNum != null,
    staleTime: Infinity,
    cacheTime: Infinity
  })
}

export const useQueriesUserProfilesFromUsedInConfigs = (
  usedInConfigs: UsedInConfig[],
  clientId: string
): Array<UseQueryResult<clients.UserDetailsResp | null>> => {
  return useQueries(
    usedInConfigs
      .filter((config) => config.updatedBy != null)
      .map((config) => {
        return {
          queryKey: [queryKeys.userDetailsByEmails, config.updatedBy],
          queryFn: async () => await clients.getUserDetailsByEmail(config.updatedBy, clientId),
          staleTime: Infinity,
          cacheTime: Infinity,
          refetchOnWindowFocus: false
        }
      })
  )
}
