import {
  Button,
  Grid,
  MaximizeIcon,
  MinimizeIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  Typography,
  styled
} from '@precis-digital/kurama'
import { type ChannelsMapItem } from 'channelGrouping/types'
import React, { useState, type ReactElement } from 'react'
import ChannelsTableCard from './ChannelsTableCard'
import { useTranslation } from 'shared/translations'

interface ChannelsTableProps {
  channels: ChannelsMapItem[]
}

const ChannelsTable = ({ channels }: ChannelsTableProps): ReactElement => {
  const { t } = useTranslation('channelGrouping')
  const [expandedChannelIds, setExpandedChannelIds] = useState<string[]>([])

  const handleExpandButton = (): void => {
    if (expandedChannelIds.length > 0) {
      setExpandedChannelIds([])
    } else {
      setExpandedChannelIds(channels.map((channelItem) => channelItem.id))
    }
  }

  const handleCardExpandClick = (channelIdToExpand: string, expanded: boolean): void => {
    if (!expanded) {
      setExpandedChannelIds(expandedChannelIds.filter((channelId) => channelId !== channelIdToExpand))
    } else {
      setExpandedChannelIds([...expandedChannelIds, channelIdToExpand])
    }
  }

  const [nameSortType, setNameSortType] = useState<'asc' | 'desc'>('desc')
  const handleSortClick = (): void => {
    if (nameSortType === 'asc') {
      setNameSortType('desc')
    } else {
      setNameSortType('asc')
    }
  }

  const sortedChannels = channels.sort((a, b) => {
    if (nameSortType === 'asc') {
      return a.name.localeCompare(b.name)
    } else {
      return b.name.localeCompare(a.name)
    }
  })

  return (
    <Grid container width="100%" gap="16px">
      <Grid display="flex" justifyContent="space-between" width="100%" alignItems="center">
        <StyledGridSortableHeaderContainer
          width="fit-content"
          display="flex"
          alignItems="center"
          gap="2px"
          onClick={handleSortClick}
        >
          <Typography variant="h5">{t('mainFormView.channelsTab.tableHeader.channels')}</Typography>
          <Grid width="16px" height="24px">
            {nameSortType === 'asc' && <SortAscendingIcon />}
            {nameSortType === 'desc' && <SortDescendingIcon />}
          </Grid>
        </StyledGridSortableHeaderContainer>
        <Grid display="flex" alignItems="center" gap="24px">
          <Grid>
            <Typography variant="h5">{t('mainFormView.channelsTab.tableHeader.dataSources')}</Typography>
          </Grid>
          <Button
            variant="text"
            onClick={handleExpandButton}
            leftIcon={expandedChannelIds.length > 0 ? <MinimizeIcon /> : <MaximizeIcon />}
          />
        </Grid>
      </Grid>
      <Grid container flexDirection="column" gap="16px">
        {sortedChannels.map((channel) => (
          <ChannelsTableCard
            key={channel.id}
            channel={channel}
            isExpanded={expandedChannelIds.includes(channel.id)}
            handleExpandClick={(expanded) => {
              handleCardExpandClick(channel.id, expanded)
            }}
          />
        ))}
      </Grid>
    </Grid>
  )
}

const StyledGridSortableHeaderContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  svg: {
    color: theme.palette.primary.main
  }
}))

export default ChannelsTable
