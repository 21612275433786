/* eslint-disable no-process-env */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const zod = require('zod')

const clientEnvSchema = zod.object({
  NEXT_PUBLIC_GOOGLE_SIGN_CLIENT_ID: zod.string(),
  NEXT_PUBLIC_API_URL: zod.string(),
  NEXT_PUBLIC_VERSION: zod.string(),
  NEXT_PUBLIC_DASHBOARD_URL: zod.string(),
  NEXT_PUBLIC_BASE_DOMAIN_URL: zod.string(),
  NEXT_PUBLIC_INTERCOM_APP_ID: zod.string(),
  NEXT_PUBLIC_AUTH_COOKIES_EXPIRY_DAYS: zod
    .string()
    .transform((days) => parseInt(days))
    .refine((expiry) => !isNaN(expiry), {
      message: 'must be a number'
    }),
  NEXT_PUBLIC_API_MOCKING: zod.string().optional().default('disabled'),
  NEXT_PUBLIC_LOGROCKET_APP_ID: zod.string(),
  NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN: zod.string(),
  NEXT_PUBLIC_ALVIE_SA_DOMAIN_NAME: zod.string(),
  NEXT_PUBLIC_STATSIG_CLIENT_KEY: zod.string()
})

const serverEnvSchema = zod.object({
  AMAZON_APP_ID: zod.string(),
  AMAZON_APP_SECRET: zod.string(),
  CRITEO_API_ID: zod.string(),
  CRITEO_API_SECRET: zod.string(),
  FACEBOOK_APP_ID: zod.string(),
  FACEBOOK_APP_SECRET: zod.string(),
  SNAPCHAT_APP_ID: zod.string(),
  SNAPCHAT_APP_SECRET: zod.string(),
  TIKTOK_APP_ID: zod.string(),
  TIKTOK_APP_SECRET: zod.string(),
  GOOGLE_APP_ID: zod.string(),
  GOOGLE_APP_SECRET: zod.string(),
  LINKEDIN_APP_ID: zod.string(),
  LINKEDIN_APP_SECRET: zod.string(),
  MICROSOFT_ADVERTISING_APP_ID: zod.string(),
  MICROSOFT_ADVERTISING_APP_SECRET: zod.string(),
  API_URL: zod.string(),
  MICROSOFT_ADVERTISING_DEVELOPER_TOKEN: zod.string(),
  NODE_ENV: zod.string().default('production'),
  APP_ENV: zod.string().default('production'),
  ROLLBAR_SERVER_TOKEN: zod.string(),
  I18N_DEBUG: zod
    .string()
    .optional()
    .transform((flag) => flag?.trim() === 'true')
})

const configs = {
  NEXT_PUBLIC_GOOGLE_SIGN_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_SIGN_CLIENT_ID,
  NEXT_PUBLIC_DASHBOARD_URL: process.env.NEXT_PUBLIC_DASHBOARD_URL,
  NEXT_PUBLIC_BASE_DOMAIN_URL: process.env.NEXT_PUBLIC_BASE_DOMAIN_URL,
  NEXT_PUBLIC_AUTH_COOKIES_EXPIRY_DAYS: process.env.NEXT_PUBLIC_AUTH_COOKIES_EXPIRY_DAYS,
  NEXT_PUBLIC_API_MOCKING: process.env.NEXT_PUBLIC_API_MOCKING,
  NEXT_PUBLIC_VERSION: process.env.NEXT_PUBLIC_VERSION,
  NEXT_PUBLIC_LOGROCKET_APP_ID: process.env.NEXT_PUBLIC_LOGROCKET_APP_ID,
  NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL || process.env.API_URL,
  NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  NEXT_PUBLIC_ALVIE_SA_DOMAIN_NAME: process.env.NEXT_PUBLIC_ALVIE_SA_DOMAIN_NAME,
  NEXT_PUBLIC_STATSIG_CLIENT_KEY: process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY,
  API_URL: process.env.API_URL || process.env.NEXT_PUBLIC_API_URL,
  AMAZON_APP_ID: process.env.AMAZON_APP_ID,
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.APP_ENV,
  AMAZON_APP_SECRET: process.env.AMAZON_APP_SECRET,
  CRITEO_API_ID: process.env.CRITEO_API_ID,
  CRITEO_API_SECRET: process.env.CRITEO_API_SECRET,
  FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
  FACEBOOK_APP_SECRET: process.env.FACEBOOK_APP_SECRET,
  SNAPCHAT_APP_ID: process.env.SNAPCHAT_APP_ID,
  SNAPCHAT_APP_SECRET: process.env.SNAPCHAT_APP_SECRET,
  TIKTOK_APP_ID: process.env.TIKTOK_APP_ID,
  TIKTOK_APP_SECRET: process.env.TIKTOK_APP_SECRET,
  GOOGLE_APP_ID: process.env.GOOGLE_APP_ID,
  GOOGLE_APP_SECRET: process.env.GOOGLE_APP_SECRET,
  LINKEDIN_APP_ID: process.env.LINKEDIN_APP_ID,
  LINKEDIN_APP_SECRET: process.env.LINKEDIN_APP_SECRET,
  MICROSOFT_ADVERTISING_APP_ID: process.env.MICROSOFT_ADVERTISING_APP_ID,
  MICROSOFT_ADVERTISING_APP_SECRET: process.env.MICROSOFT_ADVERTISING_APP_SECRET,
  MICROSOFT_ADVERTISING_DEVELOPER_TOKEN: process.env.MICROSOFT_ADVERTISING_DEVELOPER_TOKEN,
  I18N_DEBUG: process.env.I18N_DEBUG,

  ROLLBAR_SERVER_TOKEN: process.env.ROLLBAR_SERVER_TOKEN
}

const mergedSchema = serverEnvSchema.merge(clientEnvSchema)

const isServer = typeof window === 'undefined'

const isTestOrDevEnvironment = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'
const isStagingOrProductionEnvironment = !isTestOrDevEnvironment

// custom env provided in package.json build script
const isRunningNextBuild = process.env.NEXT_APP_BUILD === 'true'

/**
 * Validate all environment configs for test and development environment
 * and before app startup in staging/production. Otherwise, validate only client-side envs.
 * This is to prevent having to expose server-side envs in staging/production configurations before they are available
 */
const parsed =
  isServer && (isTestOrDevEnvironment || (isStagingOrProductionEnvironment && !isRunningNextBuild))
    ? mergedSchema.safeParse(configs)
    : clientEnvSchema.safeParse(configs)

if (!parsed.success) {
  // validation error
  throw new Error('Config validation error \n' + JSON.stringify(parsed.error.flatten().fieldErrors))
}

const parsedConfigs = {
  ...parsed.data,
  APP_ENV: process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV,
  NODE_ENV: process.env.NODE_ENV || process.env.NEXT_PUBLIC_NODE_ENV
}

/**
 * @param {string} key - The name of the environmental variable
 * @return {string | boolean | number}
 */
module.exports = (key) => {
  const value = parsedConfigs[key]

  return value
}
