import { SliderThumb } from '@mui/material'
import Box from '@mui/material/Box'
import { MoveVerticalIcon } from '../index'
import { forwardRef } from 'react'

import { StyledSlider } from './styles'
import { SliderProps } from './types'

interface SliderThumbComponentProps extends React.HTMLAttributes<unknown> {}

function SliderThumbComponent(props: SliderThumbComponentProps): React.ReactElement {
  const { children, ...other } = props
  return (
    <SliderThumb {...other}>
      {children}
      <MoveVerticalIcon />
    </SliderThumb>
  )
}

export const Slider = forwardRef<HTMLSpanElement, SliderProps>(
  (
    { marks, defaultValue, onChange, onChangeCommitted, max, min, value, step, showValueLabel = false, ...props },
    ref
  ): React.ReactElement => {
    return (
      <Box>
        <StyledSlider
          slots={{ thumb: SliderThumbComponent }}
          marks={marks}
          defaultValue={defaultValue}
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          max={max}
          min={min}
          value={value}
          step={step}
          {...props}
          ref={ref}
          valueLabelDisplay={showValueLabel ? 'auto' : 'off'}
        />
      </Box>
    )
  }
)

export default Slider
