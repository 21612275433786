import {
  Accordion,
  Avatar,
  Button,
  ChevronDownIcon,
  Divider,
  Flyout,
  Grid,
  GrowthIcon,
  Progress,
  styled,
  Typography
} from '@precis-digital/kurama'
import { useState, type ReactElement } from 'react'
import OverScreen, { useOverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { capitalize } from 'shared/utils'
import { getPlatformDetailsByPlatform } from 'dataSource/utils'

import { mapPlatformName } from './Overview/detailedReportUtils'
import { type RecommendedAction, type DetailedReportData } from 'budgetOptimiser/api/transformations/transform'
import {
  getConfidenceLevel,
  getColorIndicatorFromConfidenceLevel,
  type IndicatorColor,
  type ConfidenceLevel
} from './Recommendations/util'
import { type Platforms } from 'shared/api/accounts'

interface RecommendedActionsProps {
  rowData: DetailedReportData
}

export const showReturnCurve = (rowData: DetailedReportData): boolean => {
  const { targetType, newConversions, newOrderValue, fittable } = rowData
  if (!fittable) {
    return false
  }
  if (targetType === 'revenue') {
    return newOrderValue !== null && newOrderValue !== 0
  }
  if (targetType === 'conversions') {
    return newConversions !== null && newConversions !== 0
  }
  return true
}

export const RecommendedActions = ({ rowData }: RecommendedActionsProps): React.ReactElement => {
  const screen = useOverScreen('recommendedActionsFlyout')
  const { t } = useTranslation('budgetOptimiser')
  const [expandedActions, setExpandedActions] = useState(true)
  const [expandedRecommended, setExpandedRecommended] = useState(true)

  const handleReturnCurvePopupClick = (): void => {
    screen.remove()
    void OverScreen.show('returnCurvePopup', { rowData })
  }
  const platform = rowData.platform as Platforms
  const platformURL = getPlatformDetailsByPlatform(mapPlatformName(platform))?.iconUrl

  const shouldShowConfidence: boolean = rowData.confidenceFactor != null && rowData.isOptimizable
  const shouldShowReturnCurve: boolean = showReturnCurve(rowData)

  return (
    <Flyout
      onClose={(): void => {
        screen.remove()
      }}
      isExpanded={screen.visible}
    >
      <Grid display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginTop="8px">
        <Grid display="flex" flexDirection="column">
          <StyledHeader variant="h2">{capitalize(rowData.platformChannelGrouping)}</StyledHeader>
          <Typography variant="body2">{mapPlatformName(platform)}</Typography>
        </Grid>
        <Avatar imageUrl={platformURL} kind="image" size="medium" />
      </Grid>
      <Divider />
      <StyledAccordion
        onChange={(event, expanded) => {
          setExpandedActions(expanded)
        }}
        expanded={expandedActions}
        Summary={<Typography variant="h3">{t('recommendedActionsFlyout.action')}</Typography>}
        Details={<Action action={rowData.action} />}
        expandIcon={<ChevronDownIcon />}
      />
      {shouldShowConfidence && (
        <StyledAccordion
          onChange={(event, expanded) => {
            setExpandedRecommended(expanded)
          }}
          expanded={expandedRecommended}
          Summary={
            <>
              <Typography variant="h3">{t('recommendedActionsFlyout.confidenceRecommended')}</Typography>
            </>
          }
          Details={<Confidence confidenceFactor={rowData.confidenceFactor} />}
          expandIcon={<ChevronDownIcon />}
        />
      )}
      {shouldShowReturnCurve && (
        <StyledFlyoutFooter>
          <Divider />
          <FullWidthButton
            onClick={() => {
              handleReturnCurvePopupClick()
            }}
            scheme="light"
            variant="filled"
            leftIcon={<GrowthIcon />}
          >
            View {rowData.targetType} return curve
          </FullWidthButton>
        </StyledFlyoutFooter>
      )}
      {!shouldShowReturnCurve && (
        <StyledFlyoutFooter>
          <Divider />
          <FullWidthButton
            disabled
            scheme="light"
            variant="filled"
            leftIcon={<GrowthIcon />}
            onClick={() => {
              handleReturnCurvePopupClick()
            }}
          >
            {t(`recommendedActionsFlyout.insufficientReturnCurveData`)}
          </FullWidthButton>
        </StyledFlyoutFooter>
      )}
    </Flyout>
  )
}

interface ActionProps {
  action: RecommendedAction
}

const Action = ({ action }: ActionProps): ReactElement => {
  const { t } = useTranslation('budgetOptimiser')
  return (
    <>
      <Grid display="flex" flexDirection="column" gap={24} marginTop="20px">
        <Typography variant="h5">{t(`recommendedActionsFlyout.recommendations.${action}`)}</Typography>
      </Grid>
    </>
  )
}

const Confidence = ({ confidenceFactor }: { confidenceFactor: number }): ReactElement => {
  const { t } = useTranslation('budgetOptimiser')
  const getConfidence = (value: number): { confidence: ConfidenceLevel; colorIndicator: IndicatorColor } => {
    return {
      confidence: getConfidenceLevel(value),
      colorIndicator: getColorIndicatorFromConfidenceLevel(getConfidenceLevel(value))
    }
  }
  return (
    <>
      <Grid marginTop="20px" />
      <Typography variant="h5">
        {capitalize(getConfidence(confidenceFactor).confidence)} {t('recommendedActionsFlyout.confidence')}
      </Typography>
      <Typography variant="body3">{t('recommendedActionsFlyout.confidenceExample')}</Typography>
      <StyledTitleProgress
        value={Math.round((1 - confidenceFactor) * 100)}
        indicatorColor={getConfidence(confidenceFactor).colorIndicator}
      />
      <Grid display="flex" justifyContent="space-between" marginTop="8px">
        <Typography variant="body3">{t('recommendedActionsFlyout.low')}</Typography>
        <Typography variant="body3">{t('recommendedActionsFlyout.high')}</Typography>
      </Grid>

      <Divider />
      <Grid marginBottom="50px" />
    </>
  )
}

export default OverScreen.create(RecommendedActions)

const StyledHeader = styled(Typography)(() => ({
  marginTop: '5px'
}))

const StyledAccordion = styled(Accordion)(() => ({
  '::before': {
    backgroundColor: 'transparent'
  },
  '.MuiAccordionDetails-root': {
    padding: '0',
    '.MuiAccordionSummary-content': {
      margin: '0'
    }
  }
}))

const StyledTitleProgress = styled(Progress)(() => ({
  width: '352px',
  marginTop: '8px'
}))

const StyledFlyoutFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.white0,
  position: 'fixed',
  bottom: 0,
  marginTop: 'auto',
  width: '22rem',
  paddingBottom: '1rem',
  div: {
    ':first-of-type': {
      padding: 0,
      hr: {
        margin: '0 0 1rem 0'
      }
    }
  }
}))

const FullWidthButton = styled(Button)(({ theme }) => ({
  width: '100%'
}))
