import {
  type AutocompleteOptionItem,
  Button,
  Chip,
  DateRangeCalendar,
  defaultTheme as theme,
  Dropdown,
  Grid,
  MoreIcon,
  PlusIcon,
  styled,
  Tooltip,
  Typography,
  type Theme,
  type DateRangeCalendarProps
} from '@precis-digital/kurama'
import { useState, type ReactElement, type MouseEvent, type ReactNode, useRef, useEffect } from 'react'
import OverScreen, { useOverScreen } from 'shared/overScreens'
import { type FilterFlyoutProps } from './FilterFlyout'
import { type OperatorInputType } from 'shared/constants/filterOperators'
import React from 'react'
import { noop } from 'shared/utils'

export interface Option<ValueType = string> {
  value: ValueType
  label: string
  options?: Option[]
}

export interface ActiveFilter<T extends string = string> {
  parameter: Option<T>
  operator: string
  data: string | Option | AutocompleteOptionItem[]
}

export interface Operator<Type extends OperatorInputType = OperatorInputType> {
  id: string
  label: string
  type: Type
}

export interface CalendarProps extends Omit<DateRangeCalendarProps, 'onCancel'> {
  onCancel?: () => void
}

export type OperatorOptions = Record<string, Operator>
interface FilterProps<ParameterType extends string> {
  renderMoreMenu?: (closeMenu: () => void) => ReactElement
  DateRangeCalendarProps: CalendarProps | null
  parameterOptions: Option[]
  operatorOptions: OperatorOptions
  onUpdateFilters: (filters: Array<ActiveFilter<ParameterType>>) => void
  activeFilters: Array<ActiveFilter<ParameterType>>
  parseFilterToChipLabel: (filter: ActiveFilter<ParameterType>) => string
  renderListItemLabel: (filter: ActiveFilter<ParameterType>) => ReactNode
  additionalCustomControls?: ReactNode
  t: (key: string, options?: Record<string, unknown>) => string
}

const Filter = <ParameterType extends string>({
  DateRangeCalendarProps,
  renderMoreMenu,
  parameterOptions,
  operatorOptions,
  activeFilters,
  onUpdateFilters,
  parseFilterToChipLabel,
  renderListItemLabel,
  additionalCustomControls,
  t
}: FilterProps<ParameterType>): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  const filtersGrid = ref.current
  const [numberOfHiddenFilters, setNumberOfHiddenFilters] = useState(0)
  const [filterGridWidth, setFilterGridWidth] = useState(20)

  useEffect(() => {
    if (filtersGrid != null) {
      const resizeObserver = new ResizeObserver(() => {
        setFilterGridWidth(filtersGrid.clientWidth)
      })

      resizeObserver.observe(filtersGrid)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [filtersGrid])

  useEffect(() => {
    if (filtersGrid == null) {
      return
    }
    const options = {
      root: filtersGrid,
      threshold: 1
    }
    const observer = new IntersectionObserver((entries) => {
      setNumberOfHiddenFilters(
        entries.filter((entry) => {
          return !entry.isIntersecting
        }).length
      )
    }, options)

    activeFilters.forEach((_, index) => {
      const childNode = filtersGrid.children?.[index]
      if (childNode != null) {
        observer.observe(childNode)
      }
    })

    return () => {
      observer.disconnect()
    }
  }, [filtersGrid, activeFilters, filterGridWidth])

  const filterFlyout = useOverScreen('globalFilterFlyout')

  let DateRangeComponent: ReactElement | null = null

  if (DateRangeCalendarProps != null) {
    const {
      displayFormat = 'MMM Do, YYYY',
      dateRangeSelectedFormat = 'YYYY-MM-DD',
      onCancel = noop,
      ...rest
    } = DateRangeCalendarProps

    DateRangeComponent = (
      <DateRangeCalendar
        displayFormat={displayFormat}
        dateRangeSelectedFormat={dateRangeSelectedFormat}
        cancelText={t('buttons.cancel')}
        applyText={t('buttons.applyDateRange')}
        onCancel={onCancel}
        {...rest}
      />
    )
  }

  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleMoreButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setMoreMenuAnchorEl(e.currentTarget)
  }

  const handleMoreMenuClose = (): void => {
    setMoreMenuAnchorEl(null)
  }
  const isMoreMenuOpen = moreMenuAnchorEl != null

  const handleOnFilterClick = ({ showActiveFilters }: { showActiveFilters?: boolean } = {}): void => {
    void OverScreen.show<FilterFlyoutProps>('globalFilterFlyout', {
      parameterOptions,
      operatorOptions,
      activeFilters,
      onUpdateFilters,
      parseFilterToListItemLabel: renderListItemLabel,
      showActiveFilters,
      t,
      onHide: () => {
        filterFlyout.remove()
      },
      isFlyoutOpen: true
    })
  }
  return (
    <>
      <Grid display="flex" item xs justifyContent="space-between" gap="32px" alignItems="center" minWidth={0}>
        <StyledAddFiltersGrid
          hasFilters={activeFilters.length > 0}
          padding="4px"
          display="flex"
          gap="8px"
          alignItems="center"
          overflow="hidden"
        >
          {activeFilters.length > 0 && (
            <Grid ref={ref} item xs display="flex" position="relative" gap="8px" overflow="hidden">
              {activeFilters.map((filter, index) => (
                <Grid key={index}>
                  <Chip
                    label={parseFilterToChipLabel(filter)}
                    onDelete={() => {
                      const currentFilters = activeFilters.filter((_, i) => i !== index)
                      onUpdateFilters(currentFilters)
                    }}
                    onClick={() => {
                      handleOnFilterClick({ showActiveFilters: true })
                    }}
                  />
                </Grid>
              ))}
              {numberOfHiddenFilters > 0 && (
                <StyledGradientGrid position="absolute" top={0} width="32px" height="32px" />
              )}
            </Grid>
          )}

          {activeFilters.length > 0 && numberOfHiddenFilters > 0 && (
            <Tooltip
              kind="multiline"
              title="Click to view active filters"
              body={
                <Grid>
                  {activeFilters.map((filter, index) => (
                    <li key={index}>{parseFilterToChipLabel(filter)}</li>
                  ))}
                </Grid>
              }
            >
              <Button
                onClick={() => {
                  handleOnFilterClick({ showActiveFilters: true })
                }}
                variant="text"
                scheme="light"
              >
                <Typography variant="body3" color={theme.palette.primary.main}>
                  + {t('filter.moreFiltersText', { count: numberOfHiddenFilters })}
                </Typography>
              </Button>
            </Tooltip>
          )}

          <Grid>
            <Button
              scheme="light"
              variant={activeFilters.length > 0 ? 'text' : 'tonal'}
              onClick={() => {
                handleOnFilterClick()
              }}
              {...(activeFilters.length === 0 && { rightIcon: <PlusIcon /> })}
            >
              {t('buttons.addFilters')}
            </Button>
          </Grid>
        </StyledAddFiltersGrid>

        <Grid item display="flex" gap="8px" flexShrink={0}>
          {DateRangeCalendarProps != null && DateRangeComponent}
          {additionalCustomControls}
          {renderMoreMenu != null && (
            <Grid container alignItems="center" height="100%" justifyContent="center">
              <Button
                fullWidth
                variant="outlined"
                onClick={handleMoreButtonClick}
                aria-label="more-menu"
                rightIcon={<MoreIcon />}
              >
                {t('buttons.more')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {renderMoreMenu != null && (
        <Dropdown open={isMoreMenuOpen} anchorEl={moreMenuAnchorEl} onClose={handleMoreMenuClose}>
          {renderMoreMenu(handleMoreMenuClose)}
        </Dropdown>
      )}
    </>
  )
}

export default Filter

const StyledAddFiltersGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasFilters'
})<{ hasFilters: boolean; theme?: Theme }>(({ theme, hasFilters }) => ({
  ...(hasFilters && {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.neutrals.stone90,
    borderRadius: theme.borderRadius.medium
  }),
  paddingLeft: !hasFilters ? 0 : undefined
}))

const StyledGradientGrid = styled(Grid)<{ theme?: Theme }>(({ theme }) => ({
  left: 'calc(100% - 32px)',
  pointerEvents: 'none',
  background: `linear-gradient(270deg, ${theme.palette.neutrals.stone90} 0%, rgba(248, 251, 252, 0.00) 100%)`
}))
