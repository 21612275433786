import React from 'react'

export const PresentationBoard = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M9 13.7947L11.0659 10.6948L12.9344 12.795L15 9.79468"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.22021 17.5093L7 20.2499" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M15.7798 17.5093L17 20.2499" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M11.9623 4.24927V6.25069" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M4 8.25C4 7.14543 4.89543 6.25 6 6.25H18C19.1046 6.25 20 7.14543 20 8.25V15.25C20 16.3546 19.1046 17.25 18 17.25H6C4.89543 17.25 4 16.3546 4 15.25V8.25Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
