import { Avatar, ConditionalBuilder, type ConditionalBuilderRowState } from '@precis-digital/kurama'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { type ReactElement } from 'react'
import { type ChannelItem } from 'shared/api/channelGroupings'
import { useTranslation } from 'shared/translations'

interface ChannelsTableCardConditionBuilderProps {
  rules: ChannelItem['rules']
  onSave: (value: Record<string, ConditionalBuilderRowState[]>) => void
  disableSave?: boolean
  isNewChannel?: boolean
}

const ChannelsTableCardConditionBuilder = ({
  rules,
  onSave,
  disableSave = false,
  isNewChannel = false
}: ChannelsTableCardConditionBuilderProps): ReactElement => {
  const { dataSourceDimensions, dataSourceItem } = useCurrentChannelGroupingDataSource()
  const { t } = useTranslation('channelGrouping')

  const handleSave = (value: Record<string, ConditionalBuilderRowState[]>): void => {
    onSave(value)
  }

  return (
    <ConditionalBuilder
      value={rules}
      onSave={handleSave}
      title={t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.title')}
      dimensionDefinition={{
        label: t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.dimensionLabel'),
        options: dataSourceDimensions.map((dimension) => ({
          value: dimension.id,
          label: dimension.label,
          icon: <Avatar kind="image" imageUrl={dataSourceItem.iconUrl} size="small" />
        })),
        placeholder: t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.dimensionPlaceholder')
      }}
      operatorDefinition={{
        label: t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.operatorLabel'),
        options: dataSourceDimensions[0].operators.map((operator) => ({
          value: operator.id,
          label: operator.label
        })),
        placeholder: t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.operatorPlaceholder')
      }}
      valueDefinition={{
        label: t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.valueLabel'),
        placeholder: t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.valuePlaceholder')
      }}
      saveButtonTitle={
        isNewChannel
          ? t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.saveButtonNewChannel')
          : t('dataSourceFormView.channelsTab.tableCard.conditionBuilder.saveButton')
      }
      disableSave={disableSave}
    />
  )
}

export default ChannelsTableCardConditionBuilder
