import { type ConnectorPlatformType, MERCHANT_CENTER } from 'connectors/constants'
import { getConnectorSourceMeta } from 'connectors/utils'
import { difference, isEmpty, uniq } from 'ramda'
import {
  type DeepPartial,
  type FieldPath,
  type FieldValues,
  type PathValue,
  type UseFormSetValue
} from 'react-hook-form'
import { type ClientAccountsResp } from 'shared/api/accounts'
import { type ConnectorsRegistryResp, type ConnectorReq, type ConnectorResp } from 'shared/api/connectors'
import { formatDateToString, subDays } from 'shared/dateFns'
import { type InputRules } from 'shared/reactHookForm'
import { isNilOrEmpty, trimWhitespace } from 'shared/utils'
import { type ConnectorFormData } from '.'
import {
  DEFAULT_FORM_VALUES,
  GOOGLE_ADS_TABLES_WITHOUT_METRICS,
  MAXIMUM_DAYS_TO_REFRESH,
  MINIMUM_DAYS_TO_REFRESH
} from './constants'
import connectorBehaviourOptionsConfigs from './DateRangeStep/connectorBehaviourOptions.json'
import { userDefinedFormInputs } from './ReportStep/userDefinedFormInputs'
import { type ConfigStatus, getStatusFromAPIStatusData } from 'shared/configStatuses'
import { type E2EStatus } from 'shared/api/analytics'

export const getDefaultFillFromDate = (defaultDaysLookBack: number): Date => {
  return subDays(new Date(), defaultDaysLookBack)
}

export const transformFormDataForAPI = (
  clientId: string,
  data: ConnectorFormData,
  accounts: ClientAccountsResp[]
): ConnectorReq => {
  const parsedFillFromDate =
    data.dateRange?.basicSettings?.fillFromDate != null
      ? formatDateToString({ date: data.dateRange.basicSettings.fillFromDate, targetFormat: 'yyyy-MM-dd' })
      : data.dateRange?.basicSettings?.fillFromDate
  const parsedFillToDate =
    data.dateRange?.basicSettings?.fillToDate != null
      ? formatDateToString({ date: data.dateRange.basicSettings.fillToDate, targetFormat: 'yyyy-MM-dd' })
      : data.dateRange?.basicSettings?.fillToDate

  const snapchatSwipeUpAttributionWindow = data.report.advancedSettings.snapchatSwipeUpAttributionWindow
  const snapchatViewAttributionWindow = data.report.advancedSettings.snapchatViewAttributionWindow

  const sourceAccount = accounts.find(
    (account) => account.externalAccountId === data.dataSource.basicSettings.accountSource
  )

  const sourceAuthAccount =
    sourceAccount != null
      ? sourceAccount.credentialsId
      : data.dataSource.relatedSettings?.accountSourceCredentials ?? data.dataSource.basicSettings.sourceAuthAccount

  const targetAccount = accounts.find(
    (account) => account.externalAccountId === data.dataTarget.basicSettings.gcpAccount
  )

  const targetAuthAccount =
    targetAccount?.credentialsId !== undefined
      ? targetAccount.credentialsId
      : data.dataTarget.basicSettings.targetAuthAccount

  return {
    job: trimWhitespace(data.connectorName),
    targetDataset: data.dataTarget.basicSettings.targetDataset,
    sourceAuthAccount,
    ...(sourceAccount?.id != null && { sourceAccountId: Number(sourceAccount?.id) }),
    ...(targetAccount?.id != null && { targetAccountId: Number(targetAccount?.id) }),
    accountId: (data.dataSource.basicSettings?.accountSource ?? data.dataSource.basicSettings.accountId) as string,
    report: data.report.basicSettings.type,
    clientId,
    targetProjectId: data.dataTarget.basicSettings.targetProjectId,
    service: data.dataSource.basicSettings.platform,
    targetAuthAccount,
    fillFromDate: parsedFillFromDate,
    fillToDate: parsedFillToDate,
    version: data.dateRange.basicSettings.version,
    actionAttributionWindows: data.report.advancedSettings.actionAttributionWindows,
    schedule: data.dataTarget.advancedSettings.schedule,
    truncate: data.report.basicSettings.truncateList.length > 0,
    truncateList: data.report.basicSettings.truncateList,
    thirdPartyConnectorActive: data.thirdPartyConnectorActive,
    dv360UsePartnerId: data.dv360UsePartnerId ?? false,
    daysToRefresh: data.dateRange.advancedSettings?.daysToRefresh,
    runSchedule: data.isActive,
    relativeFromDate: {
      ...(data.dateRange.basicSettings.version === connectorBehaviourOptionsConfigs.V4.value && {
        ...(data.dateRange.basicSettings.relativeFromDateType != null && {
          type: data.dateRange.basicSettings.relativeFromDateType
        }),
        ...(data.dateRange.basicSettings.relativeFromDate != null && {
          x: parseInt(data.dateRange.basicSettings.relativeFromDate, 10)
        }),
        ...(data.dateRange.basicSettings.relativeIncludeCurrent != null && {
          includeCurrent: data.dateRange.basicSettings.relativeIncludeCurrent
        })
      })
    },
    ...(data.dataSource.basicSettings.platform === 'snapchat' && {
      snapchatAttributionWindows: {
        swipeUpAttributionWindow: snapchatSwipeUpAttributionWindow,
        viewAttributionWindow: snapchatViewAttributionWindow
      }
    }),
    query: {
      ...(data.report.basicSettings.queryDimensions != null && {
        dimensions: data.report.basicSettings.queryDimensions
      }),
      ...(data.report.basicSettings.queryMetrics != null && { metrics: data.report.basicSettings.queryMetrics }),
      ...(data.report.basicSettings.queryTable != null && { table: data.report.basicSettings.queryTable }),
      ...(data.report.basicSettings.queryActionFields != null && {
        actionFields: data.report.basicSettings.queryActionFields
      }),
      ...(data.report.basicSettings.queryBreakdowns != null && {
        breakdowns: data.report.basicSettings.queryBreakdowns
      }),
      ...(data.report.basicSettings.queryLevel != null && { level: data.report.basicSettings.queryLevel }),
      ...(data.report.basicSettings.queryBreakdown != null && { breakdown: data.report.basicSettings.queryBreakdown }),
      ...(data.report.basicSettings.queryPivot != null && { pivot: data.report.basicSettings.queryPivot }),
      ...(data.report.basicSettings.queryReportType != null && {
        reportType: data.report.basicSettings.queryReportType
      }),
      ...(data.report.basicSettings.querySearchTypes != null && {
        searchTypes: data.report.basicSettings.querySearchTypes
      }),
      ...(data.report.basicSettings.queryMode != null && { mode: data.report.basicSettings.queryMode }),
      ...(data.report.basicSettings.queryMetrics != null &&
        data.report.basicSettings.queryDimensions != null && {
          metrics: difference(data.report.basicSettings.queryMetrics, data.report.basicSettings.queryDimensions)
        }),
      ...(data.report.basicSettings.queryDataLevel != null && { dataLevel: data.report.basicSettings.queryDataLevel }),
      ...(data.report.basicSettings.queryWorksheetUrl != null && {
        worksheetUrl: data.report.basicSettings.queryWorksheetUrl
      })
    },
    ...(data.dataTarget.basicSettings.reportId != null && { reportId: data.dataTarget.basicSettings.reportId }),
    ...(data.report.advancedSettings.includeZeroImpressions != null && {
      includeZeroImpressions: data.report.advancedSettings.includeZeroImpressions
    }),
    ...(data.report.advancedSettings.useAccountAttributionSetting != null &&
      data.report.advancedSettings.useUnifiedAttributionSetting != null && {
        facebookAttributionSettings: {
          useAccountAttributionSetting: data.report.advancedSettings.useAccountAttributionSetting,
          useUnifiedAttributionSetting: data.report.advancedSettings.useUnifiedAttributionSetting
        }
      }),
    ...(data.dataSource.basicSettings.platform === 'merchant_center' && { report: 'Products' })
  }
}

export const transformAPIDataForForm = (connectorConfigData: ConnectorResp): ConnectorFormData => {
  const platformMeta = getConnectorSourceMeta(connectorConfigData.service as ConnectorPlatformType)

  const basicSettings =
    platformMeta != null && platformMeta.implemented !== true
      ? {
          platform: connectorConfigData.service as ConnectorPlatformType,
          accountId: connectorConfigData.accountId,
          sourceAuthAccount: connectorConfigData.sourceAuthAccount
        }
      : {
          platform: connectorConfigData.service as ConnectorPlatformType,
          accountSource: connectorConfigData.accountId,
          sourceAuthAccount: connectorConfigData.sourceAuthAccount
        }

  return {
    report: {
      basicSettings: {
        type: connectorConfigData.report,
        truncateList: connectorConfigData.truncateList,
        truncate: connectorConfigData.truncate,
        queryDimensions: connectorConfigData.query.dimensions,
        queryMetrics: connectorConfigData.query.metrics,
        queryTable: connectorConfigData.query.table,
        queryLevel: connectorConfigData.query.level,
        queryReportType: connectorConfigData.query.reportType,
        querySearchTypes: connectorConfigData.query.searchTypes,
        queryMode: connectorConfigData.query.mode,
        queryActionFields: connectorConfigData.query.actionFields,
        queryBreakdowns: connectorConfigData.query.breakdowns,
        queryPivot: connectorConfigData.query.pivot,
        queryBreakdown: connectorConfigData.query.breakdown,
        queryDataLevel: connectorConfigData.query.dataLevel,
        queryWorksheetUrl: connectorConfigData.query.worksheetUrl
      },
      advancedSettings: {
        useAccountAttributionSetting: connectorConfigData.facebookAttributionSettings?.useAccountAttributionSetting,
        useUnifiedAttributionSetting: connectorConfigData.facebookAttributionSettings?.useUnifiedAttributionSetting,
        actionAttributionWindows: connectorConfigData.actionAttributionWindows,
        includeZeroImpressions: connectorConfigData.includeZeroImpressions,
        snapchatSwipeUpAttributionWindow: connectorConfigData.snapchatAttributionWindows?.swipeUpAttributionWindow,
        snapchatViewAttributionWindow: connectorConfigData.snapchatAttributionWindows?.viewAttributionWindow
      }
    },
    dataSource: {
      basicSettings
    },
    dateRange: {
      basicSettings: {
        version: connectorConfigData.version,
        fillFromDate: connectorConfigData.fillFromDate,
        fillToDate: connectorConfigData.fillToDate,
        relativeFromDateType: connectorConfigData.relativeFromDate?.type,
        relativeIncludeCurrent: connectorConfigData.relativeFromDate?.includeCurrent,
        relativeFromDate: connectorConfigData.relativeFromDate?.x
      },
      advancedSettings: {
        daysToRefresh: connectorConfigData.daysToRefresh
      }
    },
    dataTarget: {
      basicSettings: {
        targetDataset: connectorConfigData.targetDataset,
        targetAuthAccount: connectorConfigData.targetAuthAccount,
        targetProjectId: connectorConfigData.targetProjectId,
        reportId: connectorConfigData.reportId,
        gcpAccount: connectorConfigData.targetProjectId
      },
      advancedSettings: {
        schedule: connectorConfigData.schedule
      }
    },
    connectorName: connectorConfigData.job,
    dv360UsePartnerId: connectorConfigData.dv360UsePartnerId,
    thirdPartyConnectorActive: connectorConfigData.thirdPartyConnectorActive,
    isActive: connectorConfigData.runSchedule,
    configId: connectorConfigData.configId,
    systemGenerated: connectorConfigData.systemGenerated
  }
}

interface SetCustomMultiInputValueProps<FormData extends FieldValues> {
  newEntry: string
  allValues: string[]
  reason: 'createOption' | 'selectOption' | 'removeOption' | 'blur' | 'clear'
  fieldName: FieldPath<FormData>
  setValue: UseFormSetValue<FormData>
  /**
   * null indicates it's not pasted, otherwise it contains the types of value pasted
   */
  multiInputStringPastedTypes: readonly string[] | null
  multipleInputSeparator: RegExp | undefined
}

/**
 * Validates a domain name string.
 *
 * @param {string} value - The domain name string to be validated.
 * @returns {boolean} - Returns true if the domain name is valid, false otherwise.
 *
 * @description
 * This function checks the validity of a domain name string based on the following criteria:
 * 1. The string must start with a series of alphanumeric characters.
 * 2. The string may continue with a sequence of zero or more substrings
 * made up of a sequence of alphanumeric characters preceded by a dash or a dot.
 * 3. The string must end with a sequence of alphanumeric characters from 2 to 6 characters long.
 *
 */
export const validateDomainName = (value: string): boolean => /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,6}$/.test(value)

/**
 * Validates an email string.
 *
 * @param {string} value - The email string to be validated.
 * @returns {boolean} - Returns true if the email is valid, false otherwise.
 *
 * @description
 * This function checks the validity of an email string based on a regular expression:
 * The part before the @ character is one or more sequences of alphanumeric characters
 * separated by a dash or a dot or an underscore.
 * The part after the @ character is the same as the domain name above
 *
 */
export const validateEmail = (value: string): boolean =>
  /^[a-z0-9]+([-._][a-z0-9]+)*@[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,6}$/.test(value)

/**
 * Validates an hexadecimal string.
 *
 * @param {string} value - The hexadecimal string to be validated.
 * @returns {boolean} - Returns true if the hexadecimal is valid, false otherwise.
 *
 * @description
 * This function checks the validity of a hexadecimal string.
 *
 */
export const validateHexadecimal = (value: string): boolean => /^[0-9a-f]+$/.test(value)

/**
 * Validates a dataset string.
 *
 * @param {string} dataset - The dataset string to be validated.
 * @returns {boolean} - Returns true if the dataset is valid, false otherwise.
 *
 * @description
 * This function checks the validity of a dataset string based on the following criteria:
 * 1. The length of the string must be less than or equal to 1024 characters.
 * 2. The string must only contain alphanumeric characters (letters, digits) and underscores.
 * 3. The string must not start with an underscore to avoid hiding it in Bigquery UI.
 *
 */
export const validateDataset = (dataset: string): boolean => {
  const stringValue = dataset
  const startsWithUnderscore = stringValue.startsWith('_')

  const containsOnlyAlphanumericAndUnderscore = /^\w*$/.test(stringValue)

  return stringValue.length <= 1024 && containsOnlyAlphanumericAndUnderscore && !startsWithUnderscore
}

export const validateTablePrefix = (tablePrefix: string): boolean => /^\w*$/.test(tablePrefix)

const multiInputPasteSourceSeparator: Record<string, RegExp> = {
  'application/x-vnd.google-docs-embedded-grid_range_clip+wrapped': /\t/
}

export const setCustomMultiInputValue = <FormData extends FieldValues>({
  newEntry,
  allValues,
  reason,
  fieldName,
  setValue,
  multiInputStringPastedTypes,
  multipleInputSeparator = /\W+/
}: SetCustomMultiInputValueProps<FormData>): void => {
  const multiInputStringPastedType = multiInputStringPastedTypes?.find((type) => type in multiInputPasteSourceSeparator)

  if (multiInputStringPastedType != null)
    multipleInputSeparator = multiInputPasteSourceSeparator[multiInputStringPastedType]
  let parsedValues: string[] = allValues
  if (['createOption', 'blur'].includes(reason)) {
    if (multiInputStringPastedTypes != null && multipleInputSeparator.test(newEntry)) {
      const newValues = newEntry
        .split(multipleInputSeparator)
        .map((val) => val.trim())
        .filter((val) => !isEmpty(val))
      parsedValues = parsedValues
        .filter((val) => val !== newEntry)
        .map((val) => val.trim())
        .filter((val) => !newValues.includes(val))
        .concat(newValues)
    } else {
      parsedValues = parsedValues.map((val) => val.trim()).filter((val) => !isEmpty(val))
    }

    parsedValues = uniq(parsedValues)
  }

  setValue(fieldName, parsedValues as PathValue<FormData, FieldPath<FormData>>, { shouldValidate: true })
}

export const getInputRules = <T extends FieldPath<ConnectorFormData>>({
  fieldName,
  data
}: {
  fieldName: T
  data: ConnectorFormData
}): InputRules<ConnectorFormData, T> => {
  const dataSource = data?.dataSource?.basicSettings?.platform

  const STATIC_INPUT_RULES: Partial<Record<FieldPath<ConnectorFormData>, InputRules<ConnectorFormData, T>>> = {
    'dataSource.basicSettings.platform': {
      required: true
    },
    'dataSource.basicSettings.accountSource': {
      required: true
    },
    'report.basicSettings.type': {
      required: true
    },
    'dateRange.basicSettings.version': {
      required: true
    },
    'dateRange.basicSettings.relativeFromDateType': {
      required: true
    },
    'dataTarget.advancedSettings.schedule': {
      required: true
    },
    'dateRange.advancedSettings.daysToRefresh': {
      validate: (value) => {
        const valueAsNumber = value as number
        return (
          isNilOrEmpty(value) || (valueAsNumber >= MINIMUM_DAYS_TO_REFRESH && valueAsNumber <= MAXIMUM_DAYS_TO_REFRESH)
        )
      }
    }
  }

  let DYNAMIC_INPUT_RULES: Partial<Record<FieldPath<ConnectorFormData>, InputRules<ConnectorFormData, T>>> | undefined
  const platform = data?.dataSource?.basicSettings?.platform
  if (dataSource != null && platform != null) {
    DYNAMIC_INPUT_RULES = {
      'dataSource.basicSettings.accountId': {
        required: true,
        validate: (value) => {
          switch (platform) {
            case 'ahrefs':
            case 'shopify':
              return validateDomainName(value as string)
            default:
              return true
          }
        }
      },
      'dataSource.basicSettings.sourceAuthAccount': {
        required: true,
        validate: (value) => {
          switch (platform) {
            case 'ahrefs':
              return validateEmail(value as string)
            case 'shopify':
              return validateHexadecimal(value as string)
            default:
              return true
          }
        }
      },
      'report.basicSettings.queryDimensions': {
        required:
          dataSource === 'merchant_center'
            ? userDefinedFormInputs[dataSource]?.merchantCenterDimensions?.required
            : userDefinedFormInputs[dataSource]?.dimensions?.required,
        validate: (value) => {
          return !(
            platform === 'google_sheets' &&
            isNilOrEmpty(value) &&
            isNilOrEmpty(data.report.basicSettings.queryMetrics)
          )
        }
      },
      'report.basicSettings.queryMetrics': {
        required: userDefinedFormInputs[dataSource].metrics?.required === true,
        validate: (value) => {
          return !(
            platform === 'google_sheets' &&
            isNilOrEmpty(value) &&
            isNilOrEmpty(data.report.basicSettings.queryDimensions)
          )
        }
      },
      'report.basicSettings.queryTable': {
        required: userDefinedFormInputs[dataSource].table?.required === true
      },
      'report.basicSettings.queryActionFields': {
        required: userDefinedFormInputs[dataSource].actionFields?.required === true
      },
      'report.basicSettings.queryBreakdowns': {
        required: userDefinedFormInputs[dataSource].breakdowns?.required === true
      },
      'report.basicSettings.queryLevel': {
        required: userDefinedFormInputs[dataSource].level?.required === true
      },
      'report.basicSettings.queryBreakdown': {
        required: userDefinedFormInputs[dataSource].breakdown?.required === true
      },
      'report.basicSettings.queryPivot': {
        required: userDefinedFormInputs[dataSource].pivot?.required === true
      },
      'report.basicSettings.queryReportType': {
        required: userDefinedFormInputs[dataSource].reportType?.required === true
      },
      'report.basicSettings.querySearchTypes': {
        required: userDefinedFormInputs[dataSource].searchTypes?.required === true
      },
      'report.basicSettings.queryMode': {
        required: userDefinedFormInputs[dataSource].mode?.required === true
      },
      'report.basicSettings.queryDataLevel': {
        required: userDefinedFormInputs[dataSource].dataLevel?.required === true
      },
      'report.basicSettings.queryWorksheetUrl': {
        required: userDefinedFormInputs[dataSource].worksheetUrl?.required === true
      },
      'dateRange.basicSettings.relativeFromDate': {
        required: !(
          data.report.basicSettings?.type === 'user_defined' &&
          dataSource === 'gads' &&
          GOOGLE_ADS_TABLES_WITHOUT_METRICS.includes(data.report.basicSettings.queryTable)
        )
      },
      'dataTarget.basicSettings.gcpAccount': {
        required: true
      },
      'dataTarget.basicSettings.targetDataset': {
        required: true,
        validate: (value) => {
          return validateDataset(value as string)
        }
      },
      'dataTarget.basicSettings.reportId': {
        required: true,
        validate: (value) => {
          return validateTablePrefix(value as string)
        }
      }
    }
  }

  return STATIC_INPUT_RULES[fieldName] ?? DYNAMIC_INPUT_RULES?.[fieldName] ?? {}
}

export const getInputLabels = ({
  fieldName,
  data,
  t
}: {
  fieldName: FieldPath<ConnectorFormData>
  data: ConnectorFormData
  isCreateEvent?: boolean
  t: (key: string, options?: Record<string, unknown> | undefined) => string
}): string => {
  const dataSource = data.dataSource?.basicSettings?.platform
  let INPUT_LABELS: Partial<Record<FieldPath<ConnectorFormData>, string>> | undefined

  const platformMeta = getConnectorSourceMeta(dataSource)

  INPUT_LABELS = {
    connectorName: t('form.connectorName'),
    'dataSource.basicSettings.platform': t('form.selectDataSourceType'),
    'dataSource.basicSettings.accountSource': t('form.selectDataSource'),
    'dateRange.basicSettings.version': t('form.selectConnectorBehaviour'),
    'dateRange.basicSettings.relativeFromDateType': t('form.range'),
    'dateRange.basicSettings.relativeFromDate': t('form.relativeDateValue'),
    'dateRange.basicSettings.fillFromDate': t('form.selectFromDate'),
    'dateRange.basicSettings.fillToDate': t('form.selectToDate'),
    'dateRange.advancedSettings.daysToRefresh': t('form.daysToRefresh'),
    'dateRange.basicSettings.relativeIncludeCurrent': t('form.includeCurrentMonth'),
    'report.basicSettings.type': t('form.selectReport'),
    'report.basicSettings.truncateList': t('form.selectTablesToTruncate'),
    'report.basicSettings.queryTable': t('form.inputAPITable'),
    'report.basicSettings.queryActionFields': t('form.inputActionFields'),
    'report.basicSettings.queryBreakdowns': t('form.inputBreakdowns'),
    'report.basicSettings.queryLevel': t('form.selectAggregationLevel'),
    'report.basicSettings.queryBreakdown': t('form.inputBreakdown'),
    'report.basicSettings.queryPivot': t('form.inputPivot'),
    'report.basicSettings.queryReportType': t('form.inputReportType'),
    'report.basicSettings.querySearchTypes': t('form.searchTypes'),
    'report.basicSettings.queryMode': t('form.reportMode'),
    'report.basicSettings.queryDataLevel': t('form.dataLevel'),
    'report.advancedSettings.includeZeroImpressions': t('form.includeZeroImpressions'),
    'report.advancedSettings.useAccountAttributionSetting': t('form.useAccountAttributionSetting'),
    'report.advancedSettings.useUnifiedAttributionSetting': t('form.useUnifiedAttributionSetting'),
    'report.advancedSettings.actionAttributionWindows': t('form.selectActionAttributionWindow'),
    'report.advancedSettings.snapchatSwipeUpAttributionWindow': t('form.snapchatSwipeUpAttributionWindow'),
    'report.advancedSettings.snapchatViewAttributionWindow': t('form.snapchatViewAttributionWindow'),
    'dataTarget.basicSettings.gcpAccount': t('form.selectProject', { platform: 'Google Cloud' }),
    'dataTarget.basicSettings.targetDataset': t('form.bigQueryDatasetID'),
    'dataTarget.basicSettings.reportId': t('form.tablePrefixForUserDefinedReports'),
    'dataTarget.advancedSettings.schedule': t('form.selectSchedule'),
    'report.basicSettings.queryMetrics': t('form.inputMetrics'),
    'report.basicSettings.queryWorksheetUrl': t('form.worksheetUrl')
  }

  if (dataSource != null && platformMeta != null) {
    INPUT_LABELS = {
      ...INPUT_LABELS,
      'dataSource.basicSettings.accountId': `${platformMeta?.label} ${t('form.accountId')}`,
      'dataSource.basicSettings.sourceAuthAccount': `${platformMeta?.label} ${t('form.authAccount')}`,
      'report.basicSettings.queryDimensions':
        dataSource === 'merchant_center'
          ? t('form.selectPlatformTables', { platform: MERCHANT_CENTER })
          : t('form.inputDimensions')
    }
  }

  return INPUT_LABELS[fieldName] ?? ''
}

export const checkIfFormIsReadyToShow = ({
  isNewNonCopyConfig,
  isConnectorConfigSuccess,
  isFormDataNilOrEmpty
}: {
  isNewNonCopyConfig: boolean
  isConnectorConfigSuccess: boolean
  isFormDataNilOrEmpty: boolean
}): boolean => {
  return isNewNonCopyConfig || (isConnectorConfigSuccess && !isFormDataNilOrEmpty)
}

export const getFormValuesBasedOnNewOrExistingConfig = ({
  selectedConfig,
  isNewNonCopyConfig
}: {
  selectedConfig: ConnectorResp | undefined
  isNewNonCopyConfig: boolean
}): {
  defaultValues?: DeepPartial<ConnectorFormData>
  values?: ConnectorFormData
} => {
  return {
    defaultValues: isNewNonCopyConfig ? DEFAULT_FORM_VALUES : undefined,
    ...(selectedConfig != null && { values: transformAPIDataForForm(selectedConfig) })
  }
}

export const getConfigStatusBasedOnNewOrExistingConfig = ({
  isEditConfig,
  selectedConfig,
  status
}: {
  isEditConfig: boolean
  selectedConfig: ConnectorResp | undefined
  status: E2EStatus | undefined
}): ConfigStatus => {
  return isEditConfig && selectedConfig != null ? getStatusFromAPIStatusData(selectedConfig.runSchedule, status) : 'new'
}

export const getReportStepMultiInputOptionProps = ({
  fieldName,
  connectorsRegistryData
}: {
  fieldName:
    | 'report.basicSettings.queryDimensions'
    | 'report.basicSettings.queryMetrics'
    | 'report.basicSettings.queryActionFields'
    | 'report.basicSettings.queryBreakdowns'
    | 'report.basicSettings.queryPivot'
  connectorsRegistryData: ConnectorsRegistryResp
}):
  | {
      options: string[]
      allowCustomInput: boolean
      disableCloseOnSelect: boolean
    }
  | undefined => {
  let propsForOptions

  switch (fieldName) {
    case 'report.basicSettings.queryDimensions':
      if (connectorsRegistryData.dimensions != null) {
        propsForOptions = {
          options: connectorsRegistryData.dimensions,
          allowCustomInput: true,
          disableCloseOnSelect: true
        }
      }
      break
    case 'report.basicSettings.queryMetrics':
      if (connectorsRegistryData.metrics != null) {
        propsForOptions = {
          options: connectorsRegistryData.metrics,
          allowCustomInput: true,
          disableCloseOnSelect: true
        }
      }
      break
    case 'report.basicSettings.queryActionFields':
      if (connectorsRegistryData.actionFields != null) {
        propsForOptions = {
          options: connectorsRegistryData.actionFields,
          allowCustomInput: true,
          disableCloseOnSelect: true
        }
      }
      break
    case 'report.basicSettings.queryBreakdowns':
      if (connectorsRegistryData.breakdowns != null) {
        propsForOptions = {
          options: connectorsRegistryData.breakdowns,
          allowCustomInput: true,
          disableCloseOnSelect: true
        }
      }
      break
  }

  return propsForOptions
}
