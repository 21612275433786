import React, { forwardRef } from 'react'
import { StyledRadio, StyledRadioFrame, StyledRadioInside } from './styles'
import { RadioProps } from './types'

const Radio = forwardRef<HTMLButtonElement, RadioProps>(
  ({ disabled = false, onClick, checked, value, ...props }, ref): React.ReactElement => {
    return (
      <StyledRadio
        icon={<StyledRadioFrame> </StyledRadioFrame>}
        checkedIcon={<StyledRadioInside> </StyledRadioInside>}
        disabled={disabled}
        onClick={onClick}
        checked={checked}
        value={value}
        {...props}
        ref={ref}
      />
    )
  }
)

export default Radio
