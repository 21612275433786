import { CSSObject, Theme } from '../theme'
import { styled } from '../../utils'
import { type PaletteTypes } from '../theme/palette'
import { ProgressProps, SemanticColor, Orientation } from './Types'
import { linearProgressClasses } from '@mui/material'

const semanticColorMap: Record<SemanticColor, keyof PaletteTypes['semantic']> = {
  error: 'error90',
  warning: 'warning90',
  success: 'success90',
  info: 'info100'
}

const orientationStaticStylesMap: Record<Orientation, Object> = {
  horizontal: {
    height: '24px'
  },
  vertical: {
    width: '8px',
    height: '100%'
  }
}

const getDynamicOrientationStyles = ({ value, orientation }: { value: number; orientation: Orientation }): Object => {
  return {
    ...(orientation === 'vertical' && {
      transform: `translateY(${value - 100}%) !important`
    })
  }
}

interface StyledProgressProps extends Pick<ProgressProps, 'indicatorColor' | 'value' | 'orientation'> {}

export const StyledProgress = styled('div')(
  ({
    theme,
    indicatorColor,
    orientation,
    value
  }: StyledProgressProps & { theme?: Theme; indicatorColor: SemanticColor; orientation: Orientation }): CSSObject => ({
    height: 'inherit',
    [`.${linearProgressClasses.root}`]: {
      ...orientationStaticStylesMap[orientation],
      borderRadius: theme?.borderRadius.medium,
      backgroundColor: theme?.palette.neutrals.stone100 ?? '#EEF4F6'
    },
    [`.${linearProgressClasses.bar1Determinate}`]: {
      border: 'none',
      ...orientationStaticStylesMap[orientation],
      ...getDynamicOrientationStyles({ value, orientation }),
      borderRadius: theme?.borderRadius.medium,
      backgroundColor: theme?.palette.semantic[semanticColorMap[indicatorColor]]
    }
  })
)
