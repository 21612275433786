/**
 *  fonts source: https://fonts.cdnfonts.com/css/graphik
 */

export const fonts = {
  graphikMedium: `
            @font-face {
              font-family: 'Graphik-Medium';
              font-style: normal;
              font-weight: 500;
              src: url('https://fonts.cdnfonts.com/s/27112/GraphikMedium.woff') format('woff');
            }`,
  graphikRegular: `
            @font-face {
              font-family: 'Graphik-Regular';
              font-style: normal;
              font-weight: 400;
              src: url('https://fonts.cdnfonts.com/s/27112/GraphikRegular.woff') format('woff');
            }`
}

/**
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    h6: false
    subtitle1: false
    subtitle2: false
    button: false
    caption: false
    overline: false
    fontWeightLight: false
    fontWeightRegular: false
    fontWeightMedium: false
    fontWeightBold: false
  }
}
export const typography = {
  fontFamily: 'Graphik-Regular',
  htmlFontSize: 16,
  fontSize: 14,
  // Headline-x-large
  h1: {
    fontWeight: 500,
    letterSpacing: '0.003em',
    fontSize: '2.375rem',
    lineHeight: '2.75rem',
    fontFamily: 'Graphik-Medium'
  },
  // Headline-large
  h2: {
    fontWeight: 500,
    letterSpacing: '0.003em',
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    fontFamily: 'Graphik-Medium'
  },
  // Headline-medium
  h3: {
    fontWeight: 500,
    letterSpacing: '0.003em',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontFamily: 'Graphik-Medium'
  },
  // Headline-small
  h4: {
    fontWeight: 500,
    letterSpacing: '0.003em',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: 'Graphik-Medium'
  },
  // Headline-x-small
  h5: {
    fontWeight: 500,
    fontSize: '0.875rem',
    letterSpacing: '0.003em',
    lineHeight: '1.5rem',
    fontFamily: 'Graphik-Medium'
  },
  // Body-medium
  body1: {
    fontFamily: 'Graphik-Regular',
    fontWeight: 400,
    fontSize: '1rem',
    letterSpacing: '0.003em',
    lineHeight: '1.5rem'
  },
  // Body-small
  body2: {
    fontFamily: 'Graphik-Regular',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.003em',
    lineHeight: '1.5rem'
  },
  // Body-x-small
  body3: {
    fontFamily: 'Graphik-Regular',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.003em',
    lineHeight: '1.25rem'
  }
}
export type TypographyOptions = typeof typography
export default typography
