import React from 'react'

export const Pause = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M4 10.6909C4 6.9956 6.99561 4 10.6909 4H13.3091C17.0044 4 20 6.99561 20 10.6909V13.3091C20 17.0044 17.0044 20 13.3091 20H10.6909C6.9956 20 4 17.0044 4 13.3091V10.6909Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10.3233 14.5708L10.3233 9.4292"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6766 14.5708L13.6766 9.4292"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
