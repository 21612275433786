import { Theme } from '@mui/material'
import { styled } from '../../utils'
import Grid from '../Grid'

export const StyledFrameContainer = styled(Grid)(() => ({
  transform: 'scale(1)',
  height: '100vh'
}))
export const StyledFrame = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  backgroundColor: theme.palette.custom.grey
}))

export const StyledSideNavigationContainer = styled(Grid)<{ theme?: Theme }>(({ theme }) => ({
  flex: '0 0 auto',
  zIndex: theme.zIndex.sideNav
}))

export const StyledContentContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.neutrals.white0,
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '32px 0px 0px 32px',
  overflowX: 'auto'
}))

export const StyledTopNavigation = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isContentScrolled'
})<{ isContentScrolled: boolean; theme?: Theme }>(({ isContentScrolled, theme }) => ({
  flex: '0 0 auto',
  background: theme?.palette.neutrals.white0,
  borderRadius: `32px 4px 0px 4px`,
  width: '100%',
  position: 'sticky',
  zIndex: theme.zIndex.topNav,
  top: '0',
  right: '0',
  boxShadow: isContentScrolled ? theme?.elevation.mediumDepth : 'none',
  transition: 'box-shadow 0.1s ease-in-out'
}))

export const StyledContent = styled(Grid)({
  flex: '1 1 auto',
  padding: '48px',
  overflowY: 'scroll'
})
