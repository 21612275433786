import Script from 'next/script'
import { type ReactElement } from 'react'
import config from 'shared/config'
import { GTM_ID } from 'shared/googleTagManager'

export const GoogleTagManagerScript = (): ReactElement => {
  if (config('APP_ENV') !== 'production') return <></>

  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
    </Script>
  )
}

export const GoogleTagManagerNoscript = (): ReactElement => {
  if (config('APP_ENV') !== 'production') return <></>

  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `
      }}
    />
  )
}
