import React, { type ReactElement } from 'react'
import { Typography, Grid, Button, styled, Frame2, LogoutIcon, Link } from '@precis-digital/kurama'
import NextLink from 'next/link'
import withPageAuthRequired from 'auth/WithPageAuthRequired'
import { useAuth } from 'shared/context/AuthContext'
import { useTranslation } from 'shared/translations'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import withInternalFeatureAccessCheck from 'shared/withInternalFeatureAccessCheck'
import { APP_ROUTES } from 'shared/routes'
import { UnstyledLink } from 'shared/components/UnstyledLink'

export enum ButtonVariant {
  filled = 'filled'
}

const NoWorkSpace = (): ReactElement => {
  const { t } = useTranslation('workspace')

  const { currentUser, logOut } = useAuth()

  const isPreciser = currentUser?.email.includes('@precisdigital.com') ?? false

  return (
    <Frame2
      headerRight={
        <Button
          variant="outlined"
          rightIcon={<LogoutIcon />}
          onClick={() => {
            logOut()
          }}
        >
          {t('buttons.logout')}
        </Button>
      }
    >
      <StyledContainer>
        <StyledDescription display="flex" flexDirection="column" gap="4px">
          <StyledMultlineContent variant="h2">{t('noWorkspace.titles.ohSnap')}</StyledMultlineContent>
          <StyledMultlineContent variant="body1">
            {!isPreciser ? (
              t('noWorkspace.descriptions.requestToJoinNotPreciser')
            ) : (
              <>
                {t('noWorkspace.descriptions.requestToJoin')}{' '}
                <NextLink href="/workspace/setup" passHref>
                  <Link>{t('noWorkspace.descriptions.requestToJoinLink')}</Link>
                </NextLink>{' '}
                {t('noWorkspace.descriptions.requestToJoinFragment')}
              </>
            )}
          </StyledMultlineContent>
        </StyledDescription>
        <StyledAccountSection item xs={12}>
          <AvatarWithLabel size="medium" user={currentUser} />
          <StyledAccountHeader variant="h5">{currentUser?.name ?? ''}</StyledAccountHeader>
          <StyledAccountSubHeader variant="body2">{currentUser?.email}</StyledAccountSubHeader>
        </StyledAccountSection>

        {isPreciser && <StyledNewWorkSpace variant="h5">{t('noWorkspace.titles.createWorkspace')}</StyledNewWorkSpace>}
        <StyledActions item xs={12}>
          {isPreciser && (
            <Button href={APP_ROUTES.workspace.setupPage} LinkComponent={UnstyledLink} variant={ButtonVariant.filled}>
              {t('noWorkspace.buttons.createMyWorkspace')}
            </Button>
          )}
          <Typography variant="body1">
            <NextLink href={APP_ROUTES.account.deleteAccountPage} passHref>
              <Link>{t('noWorkspace.descriptions.deleteAccountLink')}</Link>
            </NextLink>
          </Typography>
        </StyledActions>
      </StyledContainer>
    </Frame2>
  )
}

const StyledContainer = styled(Grid)(() => ({
  textAlign: 'center',
  paddingTop: '26px'
}))

const StyledDescription = styled(Grid)(() => ({
  marginTop: '5px'
}))

const StyledMultlineContent = styled(Typography)(() => ({
  whiteSpace: 'break-spaces'
}))

const StyledAccountSection = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '25px'
}))
const StyledAccountHeader = styled(Typography)(() => ({
  marginTop: '8px'
}))
const StyledAccountSubHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.stone120
}))

const StyledNewWorkSpace = styled(Typography)(() => ({
  width: '100%',
  marginTop: '16px'
}))

const StyledActions = styled(Grid)(() => ({
  marginTop: '16px',
  '& button': {
    minWidth: '353px'
  },
  '& p': {
    marginTop: '16px'
  }
}))

export default withInternalFeatureAccessCheck(
  withPageAuthRequired(NoWorkSpace, {
    returnTo: APP_ROUTES.workspace.noWorkspacePage
  })
)
