import { LogoProps } from './types'
import { StyledLabelContainer, StyledContainer } from './styles'
import Icon from './assets/Icon'
import Label from './assets/Label'

const Logo = ({ size = 'medium' }: LogoProps): React.ReactElement => {
  let logoWidth: string
  let labelHeight: string
  let labelPaddingLeft: string
  switch (size) {
    case 'large':
      logoWidth = '36'
      labelHeight = '18'
      labelPaddingLeft = '24px'
      break

    case 'medium':
      logoWidth = '20'
      labelHeight = '14'
      labelPaddingLeft = '18px'
      break

    case 'small':
      logoWidth = '12'
      labelHeight = '10'
      labelPaddingLeft = '12px'
      break
  }
  return (
    <StyledContainer>
      <Icon width={logoWidth} />
      <StyledLabelContainer paddingLeft={labelPaddingLeft}>
        <Label height={labelHeight} />
      </StyledLabelContainer>
    </StyledContainer>
  )
}

export default Logo
