import { Button, Card, Dropdown, Grid, MoreIcon, RightIcon, Skeleton, styled } from '@precis-digital/kurama'
import React, { type ReactNode, useState, type MouseEvent, type ReactElement } from 'react'
import { type ListViewCell, type ListViewRow } from '../types'
import { type RowLinkProps } from '.'

interface ListViewTableBodyProps<T extends object> {
  page: Array<ListViewRow<T>>
  prepareRow: (row: ListViewRow<T>) => void
  onRowClick?: (row: T) => void
  RowLink?: (props: RowLinkProps<T>) => ReactElement
  renderMoreMenu?: (row: T, closeMenu: () => void) => ReactElement
  isLoading: boolean
}

const ListViewTableBody = <T extends object>({
  page,
  prepareRow,
  onRowClick,
  RowLink,
  renderMoreMenu,
  isLoading
}: ListViewTableBodyProps<T>): ReactElement => {
  return (
    <Grid container flexDirection="column" gap="16px">
      {isLoading
        ? Array(10)
            .fill(0)
            .map((_, index) => <Skeleton key={index} height="92px" data-testid="skeleton-loader" />)
        : page.map((row) => {
            prepareRow(row)
            return (
              <ListViewTableRow
                key={row.id}
                row={row}
                onRowClick={onRowClick}
                RowLink={RowLink}
                renderMoreMenu={renderMoreMenu}
              />
            )
          })}
    </Grid>
  )
}
interface ListViewTableRowProps<T extends object> {
  row: ListViewRow<T>
  onRowClick?: (row: T) => void
  RowLink?: (props: RowLinkProps<T>) => ReactElement
  renderMoreMenu?: (row: T, closeMenu: () => void) => ReactElement
}

interface CardRowWrapperProps {
  RowLink?: (props: RowLinkProps<any>) => ReactElement
  onRowClick?: (row: any) => void
  row: ListViewRow<any>
  children: ReactNode
}
const CardRowWrapper = ({ RowLink, onRowClick, row, children }: CardRowWrapperProps): ReactElement => {
  if (RowLink != null) {
    return (
      <RowLink row={row.original}>
        <StyledCard onClick={() => null}>{children}</StyledCard>
      </RowLink>
    )
  }

  if (onRowClick != null) {
    return (
      <StyledCard
        onClick={() => {
          onRowClick(row.original)
        }}
      >
        {children}
      </StyledCard>
    )
  }

  return <StyledCard>{children}</StyledCard>
}

const ListViewTableRow = <T extends object>({
  row,
  onRowClick,
  RowLink,
  renderMoreMenu
}: ListViewTableRowProps<T>): ReactElement => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleMoreButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    e.preventDefault()
    setMoreMenuAnchorEl(e.currentTarget)
  }

  const handleMoreMenuClose = (): void => {
    setMoreMenuAnchorEl(null)
  }
  const isMoreMenuOpen = moreMenuAnchorEl != null

  return (
    <>
      <CardRowWrapper row={row} onRowClick={onRowClick} RowLink={RowLink}>
        <Grid container alignItems="center" width="100%" flexWrap="nowrap" gap="4px">
          {row.cells.map((cell: ListViewCell<T>, index: number) => (
            <React.Fragment key={`${cell.row.id}-${index}`}>{cell.render('Cell')}</React.Fragment>
          ))}
          {renderMoreMenu != null ? (
            <Grid container alignItems="center" height="100%" width="40px" justifyContent="center">
              <Button fullWidth variant="text" onClick={handleMoreButtonClick} aria-label="more-menu">
                <MoreIcon />
              </Button>
            </Grid>
          ) : (
            (onRowClick != null || RowLink != null) && (
              <Grid container alignItems="center" height="100%" marginLeft="16px" width="40px" justifyContent="center">
                <RightIcon />
              </Grid>
            )
          )}
        </Grid>
      </CardRowWrapper>
      <Dropdown open={isMoreMenuOpen} anchorEl={moreMenuAnchorEl} onClose={handleMoreMenuClose}>
        {renderMoreMenu != null ? renderMoreMenu(row.original, handleMoreMenuClose) : <></>}
      </Dropdown>
    </>
  )
}

const StyledCard = styled(Card)(() => ({
  height: '92px',
  margin: 0,
  width: '100%'
}))

export default ListViewTableBody
