import fetch from './fetch'

const client = fetch()

export const paths = {
  connectors: '/connectors',
  reportRegistry: '/connectors/report_registry',
  dataExplorer: '/connectors/preview'
}

export interface ConnectorsRegistryResp {
  metrics: string[]
  dimensions: string[]
  table?: string
  breakdowns?: string[]
  actionFields?: string[]
}

export interface ConnectorResp {
  createdAt: number
  updatedAt: number
  job: string
  createdBy: string
  updatedBy: string
  version: string
  sourceAuthAccount: string
  sourceAccountId: number
  targetAuthAccount: string
  targetAccountId: number
  targetProjectId: string
  targetDataset: string
  service: string
  accountId: string
  fillToDate: Date
  fillFromDate: Date
  relativeFromDate: {
    type: string
    includeCurrent: boolean
    x: string
  }
  daysToRefresh: number
  report: string
  reportId: string
  query: {
    dimensions: string[]
    table: string
    metrics: string[]
    level: string
    breakdown: string
    reportType: string
    pivot: string[]
    searchTypes: string[]
    mode: string
    breakdowns: string[]
    actionFields: string[]
    dataLevel: string
    worksheetUrl: string
  }
  clientId: number
  configId: number
  runSchedule: boolean
  schedule: string
  destinationTable: string
  truncateList: string[]
  truncate: boolean
  thirdPartyConnectorActive: boolean
  snapchatAttributionWindows: {
    swipeUpAttributionWindow: string
    viewAttributionWindow: string
  }
  actionAttributionWindows: string[]
  facebookAttributionSettings: {
    useAccountAttributionSetting: boolean
    useUnifiedAttributionSetting: boolean
  }
  includeZeroImpressions: boolean
  dv360UsePartnerId: boolean
  premiumConnector: boolean
  systemGenerated: boolean
}

export interface ConnectorsResp extends Array<ConnectorResp> {}
export interface ConnectorStatus {
  clientId: number
  configId: string
  createdAt: number
  heartbeat: any
  jobType: string
  message: string
  status: string
  taskId: string
  taskRetryCount: number
  updatedAt: number
}

export type E2EStatus = 'fail' | 'queued' | 'running' | 'done' | 'fetch_failed'

export interface ConfigStatusResp {
  clientId: number | string
  connectorsInformation: Record<
    string,
    {
      config: ConnectorResp
      status: ConnectorStatus
    }
  >
  e2EStatus: E2EStatus
  failedConnectors: object
  failedTransforms: object
  lastUpdate: number
  transformInformation: object
}

type ConnectorExploreData = Record<string, number | string | object | Array<string | number | object>>

export type ConnectorExploreResp = ConnectorExploreData[]

export type ReportRegistryResp = Record<
  string,
  {
    reports: Record<
      string,
      {
        category: string
        description: string
        descriptiveName: string
        dimensions: string[]
        breakdowns: string[]
        metrics: string[]
        level: string
        mode: string
        orderBy: string
        reportId: string
        showInUi: boolean
        static: boolean
        table: string
        pivot: string
        breakdown: string
        dataLevel: string
      }
    >
  }
>

export interface ConnectorReq {
  sourceAuthAccount: string
  sourceAccountId?: number
  accountId: string
  job: string
  report: string
  targetProjectId: string
  targetDataset: string
  clientId: string
  service: string
  targetAuthAccount: string
  targetAccountId?: number
  reportId?: string
  includeZeroImpressions?: boolean
  fillFromDate: string | number | Date
  fillToDate: string | number | Date
  query: Record<string, string | string[]>
  version: string
  actionAttributionWindows: string[]
  schedule: string
  truncate: boolean
  truncateList: string[]
  runSchedule?: boolean
  relativeFromDate: Record<string, string | number | boolean>
  dv360UsePartnerId?: boolean
  thirdPartyConnectorActive: boolean
  configId?: number
  daysToRefresh: number
  facebookAttributionSettings?: {
    useAccountAttributionSetting: boolean
    useUnifiedAttributionSetting: boolean
  }
  snapchatAttributionWindows?: {
    swipeUpAttributionWindow?: string
    viewAttributionWindow?: string
  }
}

export interface ConfigIdConnectorReq {
  clientId: string
  configId: number
}

export const getAllConnectorsForClient = async (clientId: string): Promise<ConnectorResp[]> => {
  return await client.get<ConnectorResp[]>({
    path: `${paths.connectors}?client_id=${clientId}`
  })
}

export const getReportRegistryByClient = async (clientId: string): Promise<ReportRegistryResp> => {
  return await client.get<ReportRegistryResp>({
    path: `${paths.reportRegistry}?client_id=${clientId}`
  })
}

export const postConnector = async (data: ConnectorReq): Promise<ConnectorResp> => {
  const path = data.runSchedule === true ? `${paths.connectors}?run_job_immediately=true` : paths.connectors
  return await client.post<ConnectorResp>({
    path,
    data
  })
}

export const updateConnector = async (data: Partial<ConnectorReq>): Promise<ConnectorResp> => {
  return await client.put<ConnectorResp>({
    path: paths.connectors,
    data
  })
}

export const getConnectorForClient = async (clientId: string, configId: number): Promise<ConnectorResp> => {
  return await client.get<ConnectorResp>({
    path: `${paths.connectors}/${configId}?client_id=${clientId}`
  })
}

export const deleteConnector = async (data: ConfigIdConnectorReq): Promise<ConnectorResp> => {
  return await client.del<ConnectorResp>({
    path: `${paths.connectors}/${data.configId}?client_id=${data.clientId}`
  })
}

export const runConnector = async (data: ConfigIdConnectorReq): Promise<void> => {
  await client.post({
    path: `${paths.connectors}/run`,
    data
  })
}

export const exploreConnectorsTable = async (data: ConfigIdConnectorReq): Promise<ConnectorExploreResp> => {
  return await client.post({
    path: `${paths.dataExplorer}`,
    data
  })
}
