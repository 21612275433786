import React, { Fragment } from 'react'
import { AreaChart as RechartAreaChart, Area, XAxis, YAxis, ResponsiveContainer, YAxisProps, Tooltip } from 'recharts'
import { AreaChartProps, AreaConfig, CustomTooltipProps } from './types'
import { useTheme } from '@mui/material/styles'

const AreaChart = ({
  containerWidth = '100%',
  containerHeight = '100%',
  data,
  getConfigByDataKey,
  renderTooltip,
  xAxis,
  yAxis,
  ...rest
}: AreaChartProps): React.ReactElement => {
  const theme = useTheme()

  const defaultAreaProps: Pick<AreaConfig, 'type' | 'lineColor' | 'lineWidth' | 'areaColor'> = {
    type: 'monotone',
    lineWidth: 4,
    lineColor: theme.palette.primary.main,
    areaColor: theme.palette.primary.main
  }

  const defaultXAxisProps = {
    tickLine: false,
    axisLine: { stroke: theme.palette.neutrals.stone100 },
    tick: { fontWeight: theme.typography.fontWeightBold },
    stroke: theme.palette.secondary.main
  }

  const defaultYAxisProps: YAxisProps = {
    orientation: 'right',
    axisLine: false,
    tickLine: false,
    tick: { textAnchor: 'end' },
    stroke: theme.palette.secondary.main
  }

  const defaultYLabelProps = {
    position: 'top',
    offset: 15,
    stroke: theme.palette.secondary.main,
    style: { textAnchor: 'end' }
  }

  const CustomTooltip = ({ active = false, payload }: CustomTooltipProps): React.ReactElement | null => {
    if (active && payload != null && payload?.length > 0 && renderTooltip != null) {
      return renderTooltip({ dataKey: payload[0].dataKey, payload: payload[0].payload })
    }

    return null
  }

  const yDataKeys = Object.keys(data[0]).filter((key) => key !== 'x')

  return (
    <ResponsiveContainer width={containerWidth} height={containerHeight}>
      <RechartAreaChart data={data} {...rest}>
        <XAxis
          dataKey="x"
          {...{
            ...defaultXAxisProps,
            ...xAxis
          }}
        />
        {yDataKeys.map((dataKey, index) => {
          const areaWithDefaults = {
            ...defaultAreaProps,
            ...getConfigByDataKey?.(dataKey)
          }
          const { lineColor, lineWidth, areaColor, hasGradient = false, ...areaProps } = areaWithDefaults
          return (
            <Fragment key={index}>
              {hasGradient && (
                <defs>
                  <linearGradient id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
                    <stop stopColor={areaColor} stopOpacity="0.54" />
                    <stop offset="0.636016" stopColor={areaColor} stopOpacity="0.0978453" />
                    <stop offset="1" stopColor={areaColor} stopOpacity="0" />
                  </linearGradient>
                </defs>
              )}
              <Area
                stroke={lineColor}
                strokeWidth={lineWidth}
                dataKey={dataKey}
                fill={hasGradient ? `url(#gradient${index})` : areaColor}
                {...areaProps}
              />
            </Fragment>
          )
        })}
        <YAxis
          {...{
            ...defaultYAxisProps,
            ...yAxis,
            label: {
              ...defaultYLabelProps,
              ...yAxis?.label
            }
          }}
        />
        {renderTooltip != null && <Tooltip content={<CustomTooltip />} />}
      </RechartAreaChart>
    </ResponsiveContainer>
  )
}
export default AreaChart
