import { type ReportTemplate } from 'reportingSolution/constants'

const config: ReportTemplate = {
  id: 'shopping_insight_report_v2',
  active: true,
  label: 'Shopping Insights Report V2',
  category: 'Product analysis',
  description:
    'The 2nd versions of this dashboard containing detailed insights and data points relating to the products carried in Google shopping. This is an extremely easy to onboard and useful dashboard highlighting everything from feed issues, performance metrics, price comparison, stock issues and much more that will help you realise and explain performance.',
  image_url:
    'https://lh3.googleusercontent.com/drive-viewer/AFGJ81pg8OosC5jDY-uAwsrAu6zKvI1NnCPWsUtYNL9LsQcwKBLQO6l5Up6g_VqS8myMdikCRhQ3bf1_4_a30w3rYU32rxH6=s1600',
  authors: [
    {
      name: 'Tobias Hink',
      email: 'hink@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjyNEc4WJk3HgY11avoS-2opMitZKNbLBZcaWvS=s126-p-k-rw-no'
    },
    {
      name: 'Richard Drott',
      email: 'richard@precisdigital.com',
      image_url: 'https://lh3.googleusercontent.com/a-/AOh14GjdVf50meWLWtrD7fZh-C9E7wxLY93SoNKssggIBg=s126-p-k-rw-no'
    }
  ],
  documentation: {
    type: 'knowledge_hub',
    link: 'https://precisdigital.atlassian.net/wiki/spaces/TECHPLATFORM/pages/2628878368/Shopping+Insights+Report+V2',
    link_description: 'Knowledge Hub: Shopping Insights Report V2'
  },
  dashboard: {
    template_report_id: 'b40fdd16-14f0-42ff-8029-9da71da98ded',
    report_name_template: 'copy of Shopping Insights Report (V2) - {today}',
    datasources: [
      {
        alias: 'ds145',
        datasourceName: 'shopping_insight_report_v2_{name}',
        type: 'TABLE',
        tableId: 'shopping_insight_report_v2_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      },
      {
        alias: 'ds341',
        datasourceName: 'shopping_insight_report_issues_v2_{name}',
        type: 'TABLE',
        tableId: 'shopping_insight_report_v2_issues_{name}',
        isPartitioned: 'true',
        refreshFields: 'false'
      }
    ]
  },
  sources: {
    merchant_center: {
      reports: ['Products', 'Products_PriceBenchmarks']
    },
    gads: {
      reports: ['shopping_merchant_center_insights']
    }
  },
  inputs: {
    other: [
      {
        id: 'targetCurrency',
        type: 'dropdown',
        label: 'Target Currency',
        defaultValue: 'EUR',
        tooltip: 'The currency to transform all currency-based columns (cost, revenue, etc) to.',
        required: true,
        mutable: true
      },
      {
        id: 'lookbackWindow',
        type: 'integer',
        label: 'Lookback window',
        helperText:
          'Lookback period for merchant center data (This affects Titles, Product Types, Custom Labels, Price Benchmarks and more).',
        tooltip: 'Number of days to refresh on each run',
        defaultValue: 90,
        required: true,
        mutable: true,
        minRange: 1,
        maxRange: 720
      }
    ]
  }
}

export default config
