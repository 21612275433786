import LogRocket from 'logrocket'
import config from 'shared/config'
import { transform as transformErrorReporter } from 'shared/errorReporter'
import { type User } from './context/AuthContext'
import { type Client } from './context/ClientContext'
import { logger } from 'shared/utils'

const log = logger().child({
  namespace: 'logRocket'
})
const NODE_ENV = config('APP_ENV') as string

const LOGROCKET_APP_ID = config('NEXT_PUBLIC_LOGROCKET_APP_ID') as string

const canUseLogRocket = NODE_ENV === 'production' && LOGROCKET_APP_ID != null

function setUp(): void {
  try {
    if (typeof window !== 'undefined' && canUseLogRocket) {
      LogRocket.init(LOGROCKET_APP_ID, {
        network: {
          requestSanitizer: (request) => {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            request.headers['Authorization'] = ''
            return request
          }
        }
      })
    }

    LogRocket.getSessionURL((sessionURL) => {
      transformErrorReporter({ sessionUrl: sessionURL })
    })
  } catch (e) {
    log.error(`Error setting up LogRocket: ${(e as Error).message}`)
  }
}

export const identify = ({ user, client }: { user: User; client: Client }): void => {
  try {
    if (user.id != null && canUseLogRocket) {
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
        userId: user.id,
        client: client.name,
        clientId: client.id,
        workspace: client.name
      })
    }
  } catch (e) {
    log.error(`Error identifying user: ${(e as Error).message}`)
  }
}

export default setUp
