import { ComponentPropsWithRef, MouseEvent } from 'react'
import { BadgeColor } from '../Badge/types'
import { Variant } from '../../components/Typography/types'
import { InputBaseComponentProps, InputProps as MUIInputProps } from '@mui/material'

export const getBadgeColor = (color: color): BadgeColor => {
  if (color === 'success') {
    return 'success'
  }
  return 'error'
}

export type color = 'success' | 'error' | undefined

export type InputType = 'text' | 'number'

export interface BaseInputProps extends ComponentPropsWithRef<'input'> {
  /**
   * An Input name
   */
  name: string
  /**
   * An Input value
   */
  value: string | number
  /**
   * An Input placeholder
   */
  placeholder?: string
  /**
   * Indicate if the Input should be disabled
   */
  disabled?: boolean
  /**
   * Handler for change events
   */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Handler for onBlur events
   */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  /**
   * Indicates if Input color is Success or Error
   */
  color?: color
  /**
   * Input Label text
   */
  labelText?: string
  /**
   * A helper text if Input is in Success or Error state
   */
  helperText?: string
  /**
   * Tells if the input should have style for assistive input
   */
  assistive?: boolean
  /**
   * If the input field is an assistive field, you can have an icon as a start adornment
   */
  icon?: React.ReactNode
  /**
   * If passed, the right adornment will display this.
   */
  rightIcon?: React.ReactNode
  /**
   * If passed, the right adornment will be clickable.
   */
  onRightIconClick?: React.MouseEventHandler<HTMLButtonElement>
  /**
   * Handler for clear input event
   */
  clearInput?: (e: MouseEvent<HTMLButtonElement>) => void
  /**
   * Determines if input occupies full width of its container
   */
  fullWidth?: boolean
  /**
   * Determines font size of input
   */
  fontSizeVariant?: Variant
  /**
   * Determines background color of input
   */
  backgroundColor?: string
  /**
   * Whether the input should be multiline
   */
  multiline?: boolean
  /**
   * Number of rows to display when multiline is true
   */
  rows?: number
  /**
   * Maximum number of rows to display when multiline is true
   */
  maxRows?: number
  /**
   * Minimum number of rows to display when multiline is true
   */
  minRows?: number
  /**
   * The type of input
   */
  type?: InputType

  /**
   * Handler when input receives focus
   */
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  /**
   * Props to be passed to the 'input' element
   */
  inputProps?: InputBaseComponentProps
  /**
   * Props passed to the MUI Input component under the TextField
   */
  InputProps?: Partial<MUIInputProps>
  /**
   * If true, the input will be focused during the first mount.
   * @default false
   */
  autoFocus?: boolean
}

export interface TextInputProps extends BaseInputProps {
  type?: 'text'
  value: string
}

export interface NumberInputProps extends BaseInputProps {
  type?: 'number'
  value: number
}

export type InputProps = TextInputProps | NumberInputProps
