import { styled } from '../../../utils'
import { Theme } from '@mui/material'
import { CSSObject } from '../../theme'
import Typography from '../../Typography'

export const StyledUlDropdownList = styled('ul')(
  ({
    theme,
    dense,
    minWidth,
    maxWidth
  }: {
    theme?: Theme
    dense: boolean
    minWidth?: string | number
    maxWidth?: string | number
  }): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px',
    borderRadius: theme?.borderRadius.xxLarge,
    margin: 0,
    gap: dense ? '2px' : '4px',
    minWidth,
    maxWidth,
    '.kurama-DropdownItem > .MuiGrid-root > .MuiGrid-root > p.MuiTypography-body1': {
      fontSize: dense ? theme?.typography.body2.fontSize : theme?.typography.body1.fontSize,
      lineHeight: dense ? theme?.typography.body2.lineHeight : theme?.typography.body1.lineHeight,
      padding: dense ? '0px' : '3px 0px 1px'
    },
    '.kurama-Divider': {
      padding: '7.5px 0px'
    }
  })
)

export const StyledTypographyCategoryHeader = styled(Typography)(
  (): CSSObject => ({
    padding: '3px 0px 12px 0px',
    '&:has(~ .kurama-DropdownListSubCategoryHeader)': {
      // If a sibling exists with className .kurama-DropdownListSubCategoryHeader
      padding: '3px 0px 1px 0px'
    }
  })
)
