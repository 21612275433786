import React from 'react'

export const Circle = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M21 12C21 16.6944 17.1944 20.5 12.5 20.5C7.80558 20.5 4 16.6944 4 12C4 7.30558 7.80558 3.5 12.5 3.5C17.1944 3.5 21 7.30558 21 12Z"
        fill="white"
        stroke="#232332"
        strokeWidth="7"
      />
    </svg>
  )
})
