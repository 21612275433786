import React, { useEffect } from 'react'

import { StyledDrawer, StyledGridDrawerFooter } from './styles'
import { NavContentItem, SideNavigationProps } from '../../components/SideNavigation/types'
import NavHeader from './NavHeader'
import NavFooter from './NavFooter'
import NavContent from './NavContent'

const SideNavigation = ({
  isExpanded = false,
  onToogleClick,
  isFooterExpanded = false,
  navContent,
  navFooter,
  navHeader
}: SideNavigationProps): React.ReactElement => {
  const [navBarOpen, setNavBarOpen] = React.useState<boolean>(isExpanded)
  const [navFooterOpen, setNavFooterOpen] = React.useState<boolean>(isFooterExpanded)
  const [navList, setNavList] = React.useState<NavContentItem[]>(navContent.content)
  const [expandedIndexes, setExpandedIndexes] = React.useState<number[]>([])

  const controlledByParent = onToogleClick != null
  const open = controlledByParent ? isExpanded : navBarOpen
  const toggleSideNav = controlledByParent ? onToogleClick : () => setNavBarOpen((prev) => !prev)

  useEffect(() => {
    setNavList(navContent.content)
  }, [navContent])

  const handleNavBarToggle = (): void => {
    if (open) {
      setNavFooterOpen(false)
      if (navContent.selectedChildIndex != null && navContent.selectedIndex != null) {
        setExpandedIndexes([navContent.selectedIndex])
      } else {
        setExpandedIndexes([])
      }
    }
    toggleSideNav()
  }

  const addIndexToExpandedIndexes = (index: number): void => {
    setExpandedIndexes((prev) => [...prev, index])
  }

  const removeIndexFromExpandedIndexes = (index: number): void => {
    setExpandedIndexes((prev) => prev.filter((item) => item !== index))
  }

  const handleContentItemWithChildrenToggle = (index: number): void => {
    const isIndexExpanded = expandedIndexes.includes(index)

    if (!open) {
      toggleSideNav()
      const isClickedItemAlreadyExpanded = index === navContent.selectedIndex && isIndexExpanded
      if (isClickedItemAlreadyExpanded) {
        return
      }
    }

    isIndexExpanded ? removeIndexFromExpandedIndexes(index) : addIndexToExpandedIndexes(index)
  }

  const handleNavFooterToggle = (): void => {
    if (!navFooterOpen && !open) {
      toggleSideNav()
    }
    setNavFooterOpen((prev) => !prev)
  }

  const [prevSelectedIndex, setPrevSelectedIndex] = React.useState<number | undefined>(undefined)
  if (prevSelectedIndex !== navContent.selectedIndex && navContent.selectedIndex != null) {
    setExpandedIndexes([navContent.selectedIndex])
    setPrevSelectedIndex(navContent.selectedIndex)
  }

  return (
    <aside role="nav">
      <StyledDrawer variant="permanent" open={open}>
        <NavHeader {...navHeader} open={open} />
        <NavContent
          content={navList}
          selectedIndex={navContent.selectedIndex}
          selectedChildIndex={navContent.selectedChildIndex}
          expandedIndexes={expandedIndexes}
          isNavBarOpen={open}
          handleNavBarToggle={handleNavBarToggle}
          handleContentItemWithChildrenToggle={handleContentItemWithChildrenToggle}
        />

        <StyledGridDrawerFooter>
          <NavFooter
            accountSection={navFooter}
            sideNavProps={{
              isNavBarOpen: open,
              isNavFooterOpen: navFooterOpen,
              toggleNavFooter: handleNavFooterToggle
            }}
          />
        </StyledGridDrawerFooter>
      </StyledDrawer>
    </aside>
  )
}

export default SideNavigation
