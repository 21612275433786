import fetch from './fetch'
import { type BadgeColor } from '@precis-digital/kurama'
import { type Platforms } from './accounts'
import snakecaseKeys from 'snakecase-keys'
import { type ChannelCategoryType } from 'channelGrouping/constants'
const client = fetch()

const CHANNEL_GROUPING_SQLS = 'channel_grouping_sqls'
export interface RuleItem {
  operator: string
  dimension: string
  value: string
}

export interface ChannelItem {
  name: string
  id: string
  note?: string | null
  color: BadgeColor
  category: ChannelCategoryType
  rules: Record<string, RuleItem[]>
}

type statuses = 'ENABLED' | 'DELETED'

export type ChannelGrouping = Partial<Record<Platforms, ChannelItem[]>>
export type ChannelGroupingSqls = Record<keyof ChannelGrouping, string>
export interface ChannelGroupingResp {
  id: number
  clientId: number
  status: statuses
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  name: string
  usedIn?: string[]
  channelGrouping: ChannelGrouping
  channelGroupingSqls?: ChannelGroupingSqls
}
export const paths = {
  channelGroupings: '/channel_groupings'
}

export interface ChannelGroupingsResp extends Array<ChannelGroupingResp> {}

export const getChannelGroupings = async (
  clientId: string,
  includeSqls: boolean = false
): Promise<ChannelGroupingsResp> => {
  let path = `${paths.channelGroupings}?client_id=${clientId}`
  if (includeSqls) {
    path = `${path}&fields=${CHANNEL_GROUPING_SQLS}`
  }
  const response = await client.get<ChannelGroupingsResp>({
    path
  })

  return response.map((channelGrouping) => ({
    ...channelGrouping,
    channelGrouping: snakecaseKeys(channelGrouping.channelGrouping),
    channelGroupingSqls:
      channelGrouping.channelGroupingSqls == null ? undefined : snakecaseKeys(channelGrouping.channelGroupingSqls)
  }))
}

export const getChannelGrouping = async (
  channelGroupingId: number,
  clientId: string,
  includeSqls: boolean = false
): Promise<ChannelGroupingResp> => {
  let path = `${paths.channelGroupings}/${channelGroupingId}?client_id=${clientId}`
  if (includeSqls) {
    path = `${path}&fields=${CHANNEL_GROUPING_SQLS}`
  }
  const response = await client.get<ChannelGroupingResp>({
    path
  })

  return {
    ...response,
    channelGrouping: snakecaseKeys(response.channelGrouping),
    channelGroupingSqls: response.channelGroupingSqls == null ? undefined : snakecaseKeys(response.channelGroupingSqls)
  }
}

export interface CreateChannelGroupingReq {
  name: string
  clientId: string
  channelGrouping?: ChannelGrouping
}

export const createChannelGrouping = async (data: CreateChannelGroupingReq): Promise<ChannelGroupingResp> => {
  const response = await client.post<ChannelGroupingResp>({
    path: `${paths.channelGroupings}`,
    data: {
      ...data,
      channelGrouping: data.channelGrouping ?? {}
    }
  })

  return { ...response, channelGrouping: snakecaseKeys(response.channelGrouping) }
}

export interface UpdateChannelGroupingReq extends ChannelGroupingResp {}

export const updateChannelGrouping = async (data: UpdateChannelGroupingReq): Promise<ChannelGroupingResp> => {
  const response = await client.put<ChannelGroupingResp>({
    path: `${paths.channelGroupings}/${data.id}?client_id=${data.clientId}`,
    data
  })

  return { ...response, channelGrouping: snakecaseKeys(response.channelGrouping) }
}

export interface DeleteChannelGroupingReq {
  configId: number
  clientId: string
}

export const deleteChannelGrouping = async (data: DeleteChannelGroupingReq): Promise<string> => {
  return await client.del<string>({
    path: `${paths.channelGroupings}/${data.configId}?client_id=${data.clientId}`
  })
}
