import {
  Divider,
  Typography,
  Accordion,
  styled,
  LogoutIcon,
  Grid,
  ChevronDownIcon,
  AccountIcon,
  DropdownItem
} from '@precis-digital/kurama'
import { StyledCard, StyledCardContent, StyledFlyoutContainer, StyledGraySpan } from './styles'
import Dots from 'shared/components/Loader/Dots'
import { useCurrentClient } from 'shared/context/ClientContext'
import { type Member, useQueryMembers } from 'workspace/api'
import { useAuth, type User } from 'shared/context/AuthContext'
import { type CSSObject } from '@mui/material/styles'
import React, { type ReactElement, useState, useMemo } from 'react'
import { CurrentUser } from 'workspace/components/CurrentUser'
import { LeaveWorkspacePopup } from 'workspace/components/Popup'
import { capitalize } from 'shared/utils/stringFormatter'
import useExpandedCards from 'shared/hooks/useExpandedCards'
import { StyledIcon, StyledDropdownButton } from '../WorkspaceDetails/MemberCard'
import OverScreen from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'

export const LeaveWorkspaceButton = (): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('workspace')
  return (
    <>
      <DropdownItem
        onClick={() => {
          setIsOpen(true)
        }}
        icon={<LogoutIcon />}
      >
        {t('buttons.leaveWorkspace')}
      </DropdownItem>
      <LeaveWorkspacePopup
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      />
    </>
  )
}

const ViewerFlyout = (): React.ReactElement => {
  const { currentClient } = useCurrentClient()
  const [shadow, setShadow] = useExpandedCards()
  const { currentUser } = useAuth()
  const { data: members, isLoading: isLoadingMembers, isSuccess: isMembersSuccess } = useQueryMembers(currentClient.id)

  const { t } = useTranslation('workspace')

  const sortedMembers = useMemo(() => {
    return members?.sort((a, b) => a.name.localeCompare(b.name))
  }, [members])

  return (
    <StyledFlyoutContainer>
      <Divider />
      <Typography variant="h3">{t('titles.members')}</Typography>
      {isLoadingMembers ? (
        <Dots />
      ) : isMembersSuccess ? (
        sortedMembers
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((member, index) => {
            if (member.id === currentUser?.id) {
              return (
                <StyledCard key={member.id} shadow={shadow[index]} condensedMargin>
                  <Accordion
                    onChange={() => {
                      setShadow(index, !shadow[index])
                    }}
                    Summary={<CardContentWrapper member={member} key={member.id} currentUser={currentUser} />}
                    expandIcon={<ChevronDownIcon />}
                    Details={<LeaveWorkspaceButton />}
                  />
                </StyledCard>
              )
            } else {
              return (
                <StyledCard key={member.id} condensedMargin>
                  <Accordion
                    onChange={() => {
                      setShadow(index, !shadow[index])
                    }}
                    Summary={<CardContentWrapper member={member} key={member.id} currentUser={currentUser} />}
                    expandIcon={<ChevronDownIcon />}
                    Details={
                      <StyledDropdownButton
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          void OverScreen.show('profileFlyout', { id: member?.id })
                        }}
                      >
                        <StyledIcon>
                          <AccountIcon />
                        </StyledIcon>
                        <Typography variant="h4"> {t('buttons.userProfile')} </Typography>
                      </StyledDropdownButton>
                    }
                  />
                </StyledCard>
              )
            }
          })
      ) : null}
    </StyledFlyoutContainer>
  )
}

interface CardWrapperProps {
  key?: React.Key
  member: Member
  currentUser?: User
}
const CardContentWrapper = (props: CardWrapperProps): ReactElement => {
  const { member, currentUser } = props
  return (
    <>
      <StyledCardContent>
        <AvatarWithLabel size="medium" user={member} />
        <Grid>
          <Typography variant="h3">
            <CurrentUser memberName={member.name} memberId={member.id} currentUserId={currentUser?.id ?? ''} />
          </Typography>
          <Typography variant="body3">
            <StyledGraySpan>{member.email}</StyledGraySpan>
          </Typography>
        </Grid>
      </StyledCardContent>
      <StyledRole variant="body2">{capitalize(member.role)}</StyledRole>
    </>
  )
}

export const StyledLeaveWorkspaceButton = styled(Grid)(
  ({ theme }): CSSObject => ({
    paddingTop: '14px',
    display: 'inline-flex',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  })
)

const StyledRole = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  fontFamily: 'Graphik-Medium'
})

export default ViewerFlyout
