import { Typography } from '@precis-digital/kurama'
import { useState, type ReactElement } from 'react'
import RecommendationFrame from 'recommendations/components/RecommendationFrame'
import { useTranslation } from 'shared/translations'
import RecommendationActions from 'recommendations/components/RecommendationActions'
import MimirTriggerButton from 'shared/components/MimirTriggerButton'
import { formatFeedDataForMimir } from 'home/mimirUtils'

import { useQueryClientAccounts } from 'dataSource/api'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useFeed } from 'recommendations/hooks'
import { type ClientSideFilters } from 'recommendations/types'

const StartPage = (): ReactElement => {
  const { currentClient } = useCurrentClient()
  const { t } = useTranslation('recommendation')
  const { data: dataSources } = useQueryClientAccounts(currentClient.id)
  const [clientSideFilters, setClientSideFilters] = useState<ClientSideFilters>({
    severity: {
      info: true,
      warning: true,
      critical: true
    }
  })
  const {
    cards: content,
    feedData = [],
    groupedData,
    fetchFeed
  } = useFeed({
    currentClient,
    dataSources,
    customFilters: {
      filters: clientSideFilters
    },
    shouldTruncateText: false
  })
  return (
    <RecommendationFrame
      header={<Typography variant="h2">{t('titles.recommendations')}</Typography>}
      actions={
        <>
          <RecommendationActions
            customFilters={{
              filters: clientSideFilters,
              setFilters: setClientSideFilters
            }}
            currentClient={currentClient}
            fetchFeed={fetchFeed}
            disabled={groupedData.length === 0}
          />
          <MimirTriggerButton
            userFacingMessage="Which of the recommendations shown on this page are most important to address?"
            questionPrompt="First write 5 key insights based on the summary statistics. Then write an executive summary of the data and insights and next actions. Be clear and concise."
            context={formatFeedDataForMimir(feedData, dataSources)}
            disabled={groupedData.length === 0}
          />
        </>
      }
      content={content}
    />
  )
}

export default StartPage
