import React from 'react'

export const Filter = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M13.6232 15.7892H5.0459"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0453 15.7889C18.0453 17.01 17.0554 17.9999 15.8343 17.9999C14.6132 17.9999 13.6233 17.01 13.6233 15.7889C13.6233 14.5669 14.6132 13.5779 15.8343 13.5779C17.0554 13.5779 18.0453 14.5669 18.0453 15.7889Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46777 8.21088H18.0457"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.04565 8.21103C5.04565 9.43305 6.03556 10.4221 7.25668 10.4221C8.4778 10.4221 9.46771 9.43305 9.46771 8.21103C9.46771 6.9899 8.4778 6 7.25668 6C6.03556 6 5.04565 6.9899 5.04565 8.21103Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
