import React from 'react'

export const Bifrost = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M16.6 19.5C17 19 17.3 18.5 17.4 17.9C17.6 17.3 17.6 16.7 17.5 16.1C17.5 16 17.5 16 17.5 15.9L17.4 15.7C17.4 15.6 17.3 15.5 17.3 15.3V15.2C17.2 15.1 17.2 15 17.1 14.8L17 14.6C17 14.5 16.9 14.5 16.9 14.4C16.6 13.9 16.1 13.5 15.6 13.2C15.1 12.9 14.5 12.7 13.9 12.7H13.6H12.3V3H9.39999V12.8H6.39999V15.5H9.29999V16.9C9.29999 17.4 9.39999 18 9.59999 18.5C9.79999 19 10.1 19.5 10.5 19.8C10.9 20.2 11.3 20.5 11.8 20.7C12.3 20.9 12.8 21 13.4 21C14 21 14.6 20.9 15.1 20.6C15.7 20.4 16.2 20 16.6 19.5ZM14.5 17.8C14.2 18.1 13.9 18.3 13.5 18.3C13.1 18.3 12.8 18.2 12.5 17.9C12.2 17.6 12.1 17.3 12.1 16.9V15.5H13.4C13.6 15.5 13.8 15.6 14 15.7C14.4 15.9 14.6 16.3 14.7 16.6C14.9 17.1 14.8 17.5 14.5 17.8Z"
        fill="currentColor"
      />
      <path d="M16.4 3H13.7V6.9H16.4V3Z" fill="currentColor" />
    </svg>
  )
})
