import { Autocomplete, Box } from '@mui/material'
import React, { ReactElement } from 'react'
import { getInputBadgeColor } from '../../utils'
import Badge from '../Badge'
import { StyledInput, StyledMessage } from './styles'
import { MultiInputProps } from './types'

const MultiInput = React.forwardRef(
  <T, U extends boolean>(
    {
      name,
      value,
      disabled = false,
      onBlur,
      onChange,
      onPaste,
      color,
      helperText,
      options = [],
      fullWidth = false,
      isOptionEqualToValue,
      allowCustomInput,
      disableCloseOnSelect = false,
      renderOption
    }: MultiInputProps<T, U>,
    ref: React.Ref<HTMLInputElement>
  ): ReactElement => {
    const hasOptions = options.length > 0
    const getRenderOptionFunction = (): ((props: object, option: T, state: object) => React.ReactNode) | undefined => {
      if (renderOption != null) {
        return (props: object, option: T, state: object) => <Box {...props}>{renderOption(option)}</Box>
      }
      return undefined
    }
    return (
      <>
        <Autocomplete<T, true, boolean, U>
          value={value}
          multiple
          freeSolo={(allowCustomInput as U) ?? (!hasOptions as U)}
          disableCloseOnSelect={disableCloseOnSelect}
          onBlur={onBlur}
          onPaste={onPaste}
          onChange={onChange}
          color={color}
          fullWidth={fullWidth}
          disabled={disabled}
          autoSelect={!hasOptions}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <StyledInput
              color={color}
              hasValue={value?.length != null && value.length > 0}
              {...params}
              name={name}
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
          renderOption={getRenderOptionFunction()}
          ref={ref}
          options={options}
        />
        {helperText != null && (
          <StyledMessage>
            <Badge color={getInputBadgeColor(color)} />
            <span>{helperText}</span>
          </StyledMessage>
        )}
      </>
    )
  }
) as <T, U extends boolean = true>(props: MultiInputProps<T, U> & { ref?: React.Ref<HTMLInputElement> }) => ReactElement

export default MultiInput
