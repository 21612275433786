import { styled } from '../../../../utils'
import { Theme } from '../../../theme'
import { CSSObject } from '@mui/material/styles'

export const StyledCard = styled('div')(
  ({ theme }: { theme?: Theme }): CSSObject => ({
    backgroundColor: theme?.palette.neutrals.white0 ?? '#FFFFFF',
    width: '260px',
    height: '70px',
    border: `1px solid ${theme?.palette.neutrals.stone100 ?? '#EEF4F6'}`,
    borderRadius: theme?.borderRadius.small,
    transition: 'box-shadow 100ms ease-in-out',
    boxShadow: theme?.elevation.mediumDepth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    '& div': {
      overflow: 'hidden',
      flexWrap: 'nowrap'
    },
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
)
