import { Button, Chip, Grid, Typography, styled } from '@precis-digital/kurama'
import { isEmpty } from 'ramda'
import React, { isValidElement, type ReactNode } from 'react'
import {
  Controller,
  type Control,
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  type PathValue
} from 'react-hook-form'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import { useHasAccess } from 'shared/hooks/useHasAccess'
import { type InputRules } from 'shared/reactHookForm'
import { useTranslation } from 'shared/translations'
import { useQueryMember } from 'workspace/api'
import FormButtons from './FormButtons'
import FormLabel from './FormLabel'

const EditItem = ({
  label,
  currentValue,
  canEdit = false,
  hasEditAccess,
  onEditClick
}: {
  label: ReactNode
  currentValue?: FormItemCurrentValue
  canEdit?: boolean
  onEditClick: () => void
  hasEditAccess?: boolean
}): React.ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Grid display="flex" gap="24px" alignItems="center" alignSelf="stretch">
      <StyledItem xs display="flex" flexDirection="column">
        <label>{label}</label>
        {Array.isArray(currentValue) && currentValue.length > 0 ? (
          <Grid container gap="6px">
            {currentValue.map((value, index) => (
              <Chip key={index} label={value} />
            ))}
          </Grid>
        ) : (
          <Typography variant="body2">
            {currentValue != null && !isEmpty(currentValue)
              ? isValidElement(currentValue)
                ? currentValue
                : currentValue.toString()
              : t('form.notSet')}
          </Typography>
        )}
      </StyledItem>
      {canEdit && (
        <Button
          {...(hasEditAccess === false && { tooltipProps: { kind: 'singleline', title: t('noPermissionMessage') } })}
          disabled={hasEditAccess === false}
          variant="text"
          onClick={onEditClick}
        >
          {t('form.edit')}
        </Button>
      )}
    </Grid>
  )
}

export type FormItemCurrentValue = string | string[] | number | Date | boolean | React.ReactElement

interface BaseFormItemProps<FormData extends FieldValues, T extends FieldPath<FormData>> {
  label?: string
  labelIcon?: string
  helperText?: ReactNode
  name: T
  control: Control<FormData>
  rules?: InputRules<FormData, T>
  render: ({ field }: { field: ControllerRenderProps<FormData, T> }) => React.ReactElement
  isCreateEvent: boolean
  defaultValue?: PathValue<FormData, T>
  currentValue?: FormItemCurrentValue
  selectedLabel?: React.ReactNode
  xs?: number | boolean
  editModes?: Record<string, boolean>
  handleUpdate?: (name: T, callback: () => void) => void
  toggleEdit?: (name: T) => void
  onCancelEdit?: () => void
  isSaveDisabled?: boolean
}

interface NewFormItem<FormData extends FieldValues, T extends FieldPath<FormData>>
  extends BaseFormItemProps<FormData, T> {
  canEdit?: false
}

interface EditableFormItem<FormData extends FieldValues, T extends FieldPath<FormData>>
  extends BaseFormItemProps<FormData, T> {
  canEdit?: true
  editModes: BaseFormItemProps<FormData, T>['editModes']
  handleUpdate: BaseFormItemProps<FormData, T>['handleUpdate']
  toggleEdit: BaseFormItemProps<FormData, T>['toggleEdit']
}

const FormItem = <FormData extends FieldValues, T extends FieldPath<FormData>>({
  label,
  labelIcon,
  helperText,
  name,
  control,
  rules,
  render,
  isCreateEvent,
  currentValue,
  selectedLabel,
  xs = 8,
  defaultValue,
  canEdit,
  isSaveDisabled = false,
  editModes,
  handleUpdate,
  toggleEdit,
  onCancelEdit
}: NewFormItem<FormData, T> | EditableFormItem<FormData, T>): React.ReactElement => {
  const { currentUser } = useAuth()
  const { currentClient } = useCurrentClient()

  const { data: member } = useQueryMember(currentClient.id, currentUser?.id ?? '')
  const hasEditAccess = useHasAccess('editor', member?.role ?? 'viewer')

  const editMode = editModes?.[name]

  return (isCreateEvent && canEdit !== false) || editMode === true ? (
    <Grid xs={xs} display="flex" flexDirection="column" gap="16px">
      {label != null && (
        <label htmlFor={name}>
          <FormLabel
            labelText={label}
            required={rules?.required === true}
            iconUrl={labelIcon}
            secondaryLabel={helperText}
          />
        </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={render as () => React.ReactElement}
      />
      {!isCreateEvent && canEdit === true && (
        <FormButtons
          isSaveDisabled={isSaveDisabled}
          onSave={() => {
            handleUpdate?.(name, () => {
              toggleEdit?.(name)
            })
          }}
          onCancel={() => {
            onCancelEdit?.()
            toggleEdit?.(name)
          }}
        />
      )}
    </Grid>
  ) : (
    <EditItem
      label={<FormLabel labelText={selectedLabel ?? label ?? ''} iconUrl={labelIcon} />}
      canEdit={canEdit}
      hasEditAccess={hasEditAccess}
      currentValue={currentValue}
      onEditClick={() => {
        toggleEdit?.(name)
      }}
    />
  )
}

export const StyledItem = styled(Grid)(() => ({
  wordBreak: 'break-word'
}))
export default FormItem
