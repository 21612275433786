import { Grid, styled, Typography, HistoryIcon, Banner, DashboardFrame } from '@precis-digital/kurama'
import { formatDateToString, getPreviousTimePeriod, getPreviousYearTimePeriod } from 'shared/dateFns'
import {
  useMutationAnalyticsAttribution,
  useMutationAnalyticsAttributionDates,
  useMutationAttributionConfigStatusById,
  useQueryIntegratedAttribution,
  useQueryIntegratedAttributions
} from 'attributionModel/api'
import PerformanceSummaryBlocks, { type AttributionModelPerformanceSummary } from './Summary'
import { useCallback, useEffect, useMemo, useState } from 'react'
import BarChartComparison from './BarChartComparison'
import { DetailedReport } from './DetailedReport'
import type { AttributionModelDetailedReport } from './DetailedReport'
import { useTranslation } from 'shared/translations'
import { useCurrentClient } from 'shared/context/ClientContext'
import {
  type AttributionModelType,
  getRevenueConversionsFieldFromModelType
} from 'attributionModel/components/Dashboard/DetailedReportUtils'
import ProgressOverTime from './ProgressOverTime'
import { capitalize, isClientMismatchError, transformCamelToSnakeCase } from 'shared/utils'
import {
  hasValidFilters,
  computeAttributionDateRange,
  computeAttributionMissingDates,
  getDefaultDateRange,
  getMissingDataInDateRange,
  allowOutsideRange
} from './utils'
import type { AttributionBaseReport } from 'attributionModel/transformations'
import {
  transformAttributionDataToPerformanceSummary,
  transformAttributionDataToDetailedReport,
  preprocessAttributionData,
  filterAttributionDataByChannel
} from 'attributionModel/transformations'
import { useQueryString } from 'shared/components/Router'
import { MoreMenuDropdownBudgetOptimiserDashboard } from 'budgetOptimiser'
import useDagView from 'shared/hooks/useDagView'
import StatusWithBadge from 'shared/components/StatusWithBadge'
import { type ConfigStatus, getErrorCodeDetails, getStatusMarketingEvaluation } from 'shared/configStatuses'
import { useAuth } from 'shared/context/AuthContext'
import NotFoundPage from 'shared/components/Error/NotFoundPage'
import Filter, { type ActiveFilter } from 'shared/components/Filter'
import { DIMENSION_OPERATORS_WITH_TYPES } from 'shared/constants/filterOperators'
import { uniq } from 'ramda'
import { APP_ROUTES } from 'shared/routes'
import FilterListItemLabel from 'shared/components/Filter/ListItemLabel'
import { MissingDatesWarningBanner } from './MissingDatesBanner'
import { GA4_EXCLUSION_LIST } from 'attributionModel/constants'

interface DashboardProps {
  modelId: number
}

export type SelectMetricsType = 'revenue' | 'conversions'

const operatorOptions = {
  [DIMENSION_OPERATORS_WITH_TYPES.is.id]: DIMENSION_OPERATORS_WITH_TYPES.is,
  [DIMENSION_OPERATORS_WITH_TYPES.not_is.id]: DIMENSION_OPERATORS_WITH_TYPES.not_is,
  [DIMENSION_OPERATORS_WITH_TYPES.one_of.id]: DIMENSION_OPERATORS_WITH_TYPES.one_of,
  [DIMENSION_OPERATORS_WITH_TYPES.not_one_of.id]: DIMENSION_OPERATORS_WITH_TYPES.not_one_of
}

const Dashboard = ({ modelId }: DashboardProps): React.ReactElement => {
  const { currentClient } = useCurrentClient()
  const { showDagView, shouldShowDagView } = useDagView('attributionModel', true)
  const { query } = useQueryString()
  const { currentUser } = useAuth()

  const {
    data: integratedAttribution,
    isLoading: isLoadingIntegratedAttribution,
    isSuccess: isSuccessIntegratedAttribution,
    isError: isErrorIntegratedAttribution,
    error: errorIntegratedAttribution
  } = useQueryIntegratedAttribution(modelId, currentClient.id)

  const isBudgetOptimiserOnlyConfig = integratedAttribution?.creditAllocator === false

  const {
    mutate: getAttributionConfigStatus,
    data: integratedAttributionStatus,
    isLoading: isLoadingintegratedAttributionStatus
  } = useMutationAttributionConfigStatusById()

  const { isLoading: isLoadingIntegratedAttributions } = useQueryIntegratedAttributions(currentClient.id)

  useEffect(() => {
    if (modelId !== 0 && !isLoadingintegratedAttributionStatus && integratedAttributionStatus == null) {
      getAttributionConfigStatus({ clientId: currentClient.id, modelId })
    }
  }, [
    currentClient.id,
    modelId,
    isLoadingintegratedAttributionStatus,
    integratedAttributionStatus,
    getAttributionConfigStatus
  ])

  let status: ConfigStatus = 'unknown'

  if (integratedAttributionStatus != null && integratedAttribution != null) {
    status = getStatusMarketingEvaluation(integratedAttribution?.runSchedule, integratedAttributionStatus[0]?.status)
  }

  const { t: tAttributionModels } = useTranslation('attributionModel')
  const { t: tCommon } = useTranslation('common')

  if (
    isSuccessIntegratedAttribution &&
    integratedAttribution != null &&
    integratedAttributionStatus != null &&
    shouldShowDagView(query)
  ) {
    showDagView({
      configId: integratedAttribution.id,
      configName: integratedAttribution.name,
      isActive: integratedAttribution.runSchedule,
      configStatus: status
    })
  }

  /// this is from raw report...
  // const {mutate: getRawData, data: rawData = [], isLoading: isRawDataLoading} = useMutationAttributionReport()
  const {
    mutate: getAttributionDates,
    data: attributionDates = [],
    isLoading: isAttributionDatesLoading,
    isIdle: isAttributionDatesRequestIdle,
    isSuccess: isSuccessAttributionDates,
    isError: isErrorAttributionDates
  } = useMutationAnalyticsAttributionDates()
  const {
    mutate: getAttributionData,
    data: attributionData = [],
    isLoading: isAttributionDataLoading,
    isIdle: isAttributionDataRequestIdle
  } = useMutationAnalyticsAttribution()
  const {
    mutate: getAttributionDataPreviousTimePeriod,
    data: attributionDataPreviousTimePeriod = [],
    isLoading: isAttributionDataPreviousTimePeriodLoading,
    isIdle: isAttributionDataPreviousTimePeriodRequestIdle
  } = useMutationAnalyticsAttribution()
  const {
    mutate: getAttributionDataPreviousYearTimePeriod,
    data: attributionDataPreviousYearTimePeriod = [],
    isLoading: isAttributionDataPreviousYearTimePeriodLoading,
    isIdle: isAttributionDataPreviousYearTimePeriodRequestIdle
  } = useMutationAnalyticsAttribution()

  const isAttributionLoaded = !(
    isAttributionDatesRequestIdle ||
    isAttributionDataRequestIdle ||
    isAttributionDataPreviousTimePeriodRequestIdle ||
    isAttributionDataPreviousYearTimePeriodRequestIdle ||
    isLoadingIntegratedAttribution ||
    isAttributionDataLoading ||
    isAttributionDataPreviousTimePeriodLoading ||
    isAttributionDataPreviousYearTimePeriodLoading ||
    isAttributionDatesLoading ||
    isLoadingIntegratedAttributions
  )

  const [selectedFilters, setSelectedFilters] = useState<Array<ActiveFilter<'channel'>>>([])

  const filteredData = useMemo(() => {
    return filterAttributionDataByChannel(attributionData, selectedFilters)
  }, [attributionData, selectedFilters])

  // START --- PREPROCESS DATA FROM API TO STANDARD SCHEMA FOR ALL SOURCES

  const preprocessedAttributionData: AttributionBaseReport[] = useMemo((): AttributionBaseReport[] => {
    if (integratedAttribution != null && !isBudgetOptimiserOnlyConfig) {
      const { revenue: revenueField, conversions: conversionsField } = getRevenueConversionsFieldFromModelType(
        integratedAttribution.creditInput
      )
      return preprocessAttributionData(filteredData, revenueField, conversionsField)
    }
    return []
  }, [filteredData, integratedAttribution, isBudgetOptimiserOnlyConfig])

  const preprocessedAttributionDataPreviousTimePeriod: AttributionBaseReport[] =
    useMemo((): AttributionBaseReport[] => {
      if (integratedAttribution != null && !isBudgetOptimiserOnlyConfig) {
        const { revenue: revenueField, conversions: conversionsField } = getRevenueConversionsFieldFromModelType(
          integratedAttribution.creditInput
        )
        return preprocessAttributionData(attributionDataPreviousTimePeriod, revenueField, conversionsField)
      }
      return []
    }, [integratedAttribution, attributionDataPreviousTimePeriod, isBudgetOptimiserOnlyConfig])

  const preprocessedAttributionDataPreviousYearTimePeriod: AttributionBaseReport[] =
    useMemo((): AttributionBaseReport[] => {
      if (integratedAttribution != null && !isBudgetOptimiserOnlyConfig) {
        const { revenue: revenueField, conversions: conversionsField } = getRevenueConversionsFieldFromModelType(
          integratedAttribution.creditInput
        )
        return preprocessAttributionData(attributionDataPreviousYearTimePeriod, revenueField, conversionsField)
      }
      return []
    }, [integratedAttribution, attributionDataPreviousYearTimePeriod, isBudgetOptimiserOnlyConfig])

  // END

  // START -- Transform preprocessed data to performance summary data

  const performanceSummaryData = useMemo((): AttributionModelPerformanceSummary => {
    return transformAttributionDataToPerformanceSummary(preprocessedAttributionData)
  }, [preprocessedAttributionData])

  const performanceSummaryDataPrevTimePeriod = useMemo((): AttributionModelPerformanceSummary => {
    return transformAttributionDataToPerformanceSummary(preprocessedAttributionDataPreviousTimePeriod)
  }, [preprocessedAttributionDataPreviousTimePeriod])

  // END

  // START -- Transform data for detailed report

  const detailedReportData: AttributionModelDetailedReport[] = useMemo((): AttributionModelDetailedReport[] => {
    return transformAttributionDataToDetailedReport(preprocessedAttributionData)
  }, [preprocessedAttributionData])

  const detailedReportPrevTimePeriodData: AttributionModelDetailedReport[] =
    useMemo((): AttributionModelDetailedReport[] => {
      return transformAttributionDataToDetailedReport(preprocessedAttributionDataPreviousTimePeriod)
    }, [preprocessedAttributionDataPreviousTimePeriod])

  const detailedReportPrevYearTimePeriodData: AttributionModelDetailedReport[] =
    useMemo((): AttributionModelDetailedReport[] => {
      return transformAttributionDataToDetailedReport(preprocessedAttributionDataPreviousYearTimePeriod)
    }, [preprocessedAttributionDataPreviousYearTimePeriod])

  const attributionDateRange: { minDate: Date; maxDate: Date } = computeAttributionDateRange(attributionDates)

  const missingDates: string[] = computeAttributionMissingDates(attributionDates)

  const todaysDate = new Date()

  useEffect(() => {
    const attributionDateField = [{ name: 'date', aggregation: 'DISTINCT' }]
    const modelFilter =
      integratedAttribution != null && !isBudgetOptimiserOnlyConfig
        ? [
            {
              field: transformCamelToSnakeCase(
                getRevenueConversionsFieldFromModelType(integratedAttribution.creditInput).conversions
              ),
              operator: 'IS NOT NULL'
            }
          ]
        : []

    integratedAttribution != null &&
      getAttributionDates({
        id: integratedAttribution.id.toString(),
        collection: 'attribution',
        report: 'attribution',
        clientId: currentClient.id,
        fields: attributionDateField,
        orderBy: { fields: ['date'], direction: 'ASC' },
        filters: modelFilter
      })
  }, [
    integratedAttribution?.id,
    currentClient.id,
    integratedAttribution?.creditInput,
    integratedAttribution,
    getAttributionDates,
    isBudgetOptimiserOnlyConfig
  ])

  const { startDefaultDate, endDefaultDate } = getDefaultDateRange(attributionDateRange, todaysDate)

  const [startDate, setStartDate] = useState<string>(startDefaultDate)
  const [endDate, setEndDate] = useState<string>(endDefaultDate)

  const [missingDatesInDateRange, setMissingDatesInDateRange] = useState<string[]>([])
  const [missingDatesInComparisonDateRange, setMissingDatesInComparisonDateRange] = useState<string[]>([])

  const [hasConversionsButNoRevenue, setHasConversionsButNoRevenue] = useState<boolean>(false)

  const handleDateChange = useCallback(
    (startDate: string, endDate: string, currencyCode: string): void => {
      const attributionFields = [
        { name: 'dda_v2_revenue' },
        { name: 'dda_v2_conversions' },

        { name: 'ia_user_based_reallocation_revenue' },
        { name: 'ia_user_based_reallocation_conversions' },

        { name: 'regression_based_attribution_revenue' },
        { name: 'regression_based_attribution_conversions' },

        { name: 'lnda_revenue' },
        { name: 'lnda_conversions' },

        { name: 'ga4_default_attribution_revenue' },
        { name: 'ga4_default_attribution_conversions' },

        { name: 'ga4_lnda_attribution_revenue' },
        { name: 'ga4_lnda_attribution_conversions' },

        { name: 'date' },
        { name: 'channel' },
        { name: 'spend' },
        { name: 'impressions' }
      ]

      // TODO: START THIS is the replacement for the current approach

      if (integratedAttribution != null) {
        getAttributionData({
          id: integratedAttribution.id.toString(),
          collection: 'attribution',
          report: 'attribution',
          clientId: currentClient.id,
          fields: attributionFields,
          filters: [
            {
              field: 'date',
              operator: 'BETWEEN',
              value: [startDate, endDate]
            }
          ],
          targetCurrencyCode: currencyCode
        })

        getAttributionDataPreviousTimePeriod({
          id: integratedAttribution.id.toString(),
          collection: 'attribution',
          report: 'attribution',
          clientId: currentClient.id,
          fields: attributionFields,
          filters: [
            {
              field: 'date',
              operator: 'BETWEEN',
              value: [getPreviousTimePeriod(startDate, endDate)[0], getPreviousTimePeriod(startDate, endDate)[1]]
            }
          ],
          targetCurrencyCode: currencyCode
        })

        getAttributionDataPreviousYearTimePeriod({
          id: integratedAttribution.id.toString(),
          collection: 'attribution',
          report: 'attribution',
          clientId: currentClient.id,
          fields: attributionFields,
          filters: [
            {
              field: 'date',
              operator: 'BETWEEN',
              value: [
                getPreviousYearTimePeriod(startDate, endDate)[0],
                getPreviousYearTimePeriod(startDate, endDate)[1]
              ]
            }
          ],
          targetCurrencyCode: currencyCode
        })
      }
    },
    [
      getAttributionData,
      getAttributionDataPreviousTimePeriod,
      getAttributionDataPreviousYearTimePeriod,
      currentClient.id,
      integratedAttribution
    ]
  )

  const attributionDateSuccessOrError = isSuccessAttributionDates || isErrorAttributionDates

  useEffect(() => {
    if (
      attributionDateSuccessOrError &&
      isSuccessIntegratedAttribution &&
      startDate != null &&
      endDate != null &&
      !(isAttributionDatesLoading || isAttributionDatesRequestIdle)
    ) {
      handleDateChange(startDate, endDate, currentClient.currency)
    }
  }, [
    attributionDateSuccessOrError,
    isSuccessIntegratedAttribution,
    endDate,
    startDate,
    currentClient,
    handleDateChange,
    isAttributionDatesLoading,
    isAttributionDatesRequestIdle
  ])

  const modelType: AttributionModelType = integratedAttribution?.creditInput
  const lastEditedAt =
    integratedAttribution != null
      ? formatDateToString({
          date: integratedAttribution?.updatedAt
        })
      : ''

  const [selectedMetrics, setSelectedMetrics] = useState<SelectMetricsType>('revenue')

  useEffect(() => {
    if (
      GA4_EXCLUSION_LIST.includes(currentClient.id.toString()) ||
      (performanceSummaryData.revenue === 0 && performanceSummaryData.conversions > 0)
    ) {
      setHasConversionsButNoRevenue(true)
    }
  }, [currentClient.id, performanceSummaryData])

  const pausedConfig = integratedAttribution?.runSchedule === false

  const metricToShow = hasConversionsButNoRevenue ? 'conversions' : selectedMetrics

  const canFilter = attributionData.length > 0

  const showMissingDatesBanner =
    (missingDatesInDateRange != null && missingDatesInDateRange.length > 0) ||
    (missingDatesInComparisonDateRange != null && missingDatesInComparisonDateRange.length > 0)

  const conversionField =
    integratedAttribution !== undefined && !isBudgetOptimiserOnlyConfig
      ? getRevenueConversionsFieldFromModelType(integratedAttribution?.creditInput).conversions
      : undefined

  const isMissingDataPrevTimePeriod =
    conversionField === undefined ||
    attributionDataPreviousTimePeriod?.length === 0 ||
    attributionDataPreviousTimePeriod.some((item) => item[conversionField] == null)

  const isMissingDataPrevYearTimePeriod =
    conversionField === undefined ||
    attributionDataPreviousYearTimePeriod?.length === 0 ||
    attributionDataPreviousYearTimePeriod.some((item) => item[conversionField] == null)

  if (isErrorIntegratedAttribution && isClientMismatchError(errorIntegratedAttribution)) {
    return <NotFoundPage hasDashboardFrame returnPath={APP_ROUTES.attributionModels.basePage} />
  }

  if (isBudgetOptimiserOnlyConfig) {
    return <NotFoundPage hasDashboardFrame returnPath={APP_ROUTES.attributionModels.basePage} />
  }

  return (
    <DashboardFrame
      header={
        isLoadingIntegratedAttribution ? null : (
          <>
            {showMissingDatesBanner && (
              <Grid marginBottom="23px">
                <MissingDatesWarningBanner
                  selected={missingDatesInDateRange}
                  comparison={missingDatesInComparisonDateRange}
                />
              </Grid>
            )}
            {pausedConfig && currentUser?.hasInternalFeatureAccess === true ? (
              <Grid marginBottom="30px">
                <Banner
                  LinkProps={{
                    href: APP_ROUTES.attributionModels.editConfigPage({ configId: `${modelId}` })
                  }}
                  variant="info"
                >
                  <span>{tAttributionModels('dashboard.banner')}</span>
                </Banner>
              </Grid>
            ) : null}

            <StyledHeader variant="h4">
              {modelType != null
                ? tAttributionModels(`dashboard.titles.${modelType}`) ?? tAttributionModels(`dashboard.titles.default`)
                : ''}
            </StyledHeader>

            <StyledModelName variant="h1">
              {integratedAttribution != null ? capitalize(integratedAttribution?.name) : ''}
            </StyledModelName>
            <StyledLastEdited>
              <HistoryIcon />
              <Typography variant="body2">
                {' '}
                {integratedAttribution != null
                  ? tCommon('lastEditedAt', {
                      date: lastEditedAt
                    })
                  : ''}
              </Typography>
            </StyledLastEdited>
          </>
        )
      }
      toolbar={
        integratedAttribution == null || !isAttributionLoaded ? null : (
          <StyledCalendarWrapper>
            <StatusWithBadge
              isLoading={isLoadingintegratedAttributionStatus}
              typographyVariant="h4"
              status={status}
              errorCode={integratedAttributionStatus?.[0]?.errorCode}
              errorDetails={getErrorCodeDetails(
                integratedAttributionStatus?.[0]?.errorCode,
                integratedAttributionStatus?.[0]?.errorMessage,
                integratedAttributionStatus?.[0]?.targetProjectId,
                tCommon('dagView.attributionModel')
              )}
              lastUpdated={integratedAttribution?.updatedAt}
              dagViewInformation={{
                dagViewType: 'attributionModel',
                configId: modelId,
                configName: integratedAttribution.name,
                isActive: integratedAttribution.runSchedule,
                isPageWithConfigId: true
              }}
            />
            {canFilter && (
              <Filter
                DateRangeCalendarProps={
                  isSuccessAttributionDates
                    ? {
                        displayFormat: 'MMM Do, YYYY',
                        onDateRangeSelected: (startDate, endDate) => {
                          if (startDate != null && endDate != null) {
                            setStartDate(startDate)
                            setEndDate(endDate)
                            const missingDatesInDateRange = getMissingDataInDateRange(startDate, endDate, missingDates)
                            setMissingDatesInDateRange(missingDatesInDateRange)
                            const [prevTimePeriodStart, prevTimePeriodEnd] = getPreviousTimePeriod(startDate, endDate)
                            const missingDatesInComparisonDateRange = getMissingDataInDateRange(
                              prevTimePeriodStart,
                              prevTimePeriodEnd,
                              missingDates,
                              computeAttributionDateRange(attributionDates)
                            )
                            setMissingDatesInComparisonDateRange(missingDatesInComparisonDateRange)
                          }
                        },
                        dateRangeSelectedFormat: 'YYYY-MM-DD',
                        onCancel: () => {},
                        defaultStartDate: startDate,
                        defaultEndDate: endDate,
                        cancelText: tAttributionModels('buttons.cancel'),
                        applyText: tAttributionModels('buttons.applyDateRange'),
                        isOutsideRange: (date) => allowOutsideRange(date, attributionDateRange, missingDates)
                      }
                    : null
                }
                operatorOptions={operatorOptions}
                parameterOptions={[
                  {
                    value: 'channel',
                    label: 'Channel',
                    options: uniq(
                      attributionData.map((data) => ({
                        value: data.channel,
                        label: data.channel
                      }))
                    )
                  }
                ]}
                activeFilters={selectedFilters}
                onUpdateFilters={setSelectedFilters}
                parseFilterToChipLabel={(filter) => {
                  const operator = operatorOptions[filter.operator].label?.toLowerCase()

                  let value: string = ''
                  if (Array.isArray(filter.data)) {
                    value = filter.data.map((item) => item.label ?? item.value).join(', ')
                  } else {
                    value = typeof filter.data === 'string' ? filter.data : filter.data.label
                  }
                  return `${filter.parameter.label} ${operator} ${value?.toString() ?? ''}`
                }}
                renderListItemLabel={(filter) => {
                  return <FilterListItemLabel filter={filter} includeText={tAttributionModels('filter.include')} />
                }}
                t={tAttributionModels}
              />
            )}

            <Grid>
              <MoreMenuDropdownBudgetOptimiserDashboard
                configId={integratedAttribution.id}
                channelGroupingId={integratedAttribution.channelGroupingId}
                moreButton={capitalize(tAttributionModels('dashboard.more'))}
                editButton={tAttributionModels('dashboard.editModel')}
                createButton={tAttributionModels('buttons.createNewModel')}
                viewDetailedStatusButton={tAttributionModels('dashboard.viewDetailedStatus')}
                path="attribution-models"
                configName={integratedAttribution.name}
                isActive={integratedAttribution.runSchedule}
                configStatus={status}
                showMetricButton={
                  metricToShow === 'conversions' && !hasConversionsButNoRevenue
                    ? tAttributionModels('buttons.showRevenue')
                    : !hasConversionsButNoRevenue
                    ? tAttributionModels('buttons.showConversions')
                    : null
                }
                onSelectMetric={() => {
                  setSelectedMetrics(metricToShow === 'revenue' ? 'conversions' : 'revenue')
                }}
                isPageWithConfigId
              />
            </Grid>
          </StyledCalendarWrapper>
        )
      }
      summary={
        !isAttributionLoaded ? null : (
          <PerformanceSummaryBlocks
            roas={performanceSummaryData?.roas}
            revenue={performanceSummaryData?.revenue}
            spend={performanceSummaryData?.adSpend}
            costPerAction={performanceSummaryData?.costPerAction}
            conversions={performanceSummaryData?.conversions}
            startDate={startDate}
            endDate={endDate}
            selectedMetrics={metricToShow}
            roasPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.roas}
            revenuePrevTimePeriod={performanceSummaryDataPrevTimePeriod?.revenue}
            spendPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.adSpend}
            costPerActionPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.costPerAction}
            conversionPrevTimePeriod={performanceSummaryDataPrevTimePeriod?.conversions}
            currencyCode={currentClient.currency}
          />
        )
      }
      content={[
        !isAttributionLoaded ? null : (
          <BarChartComparison
            currentReport={preprocessedAttributionData}
            attributionReports={attributionData}
            isDataLoaded={isAttributionLoaded}
            selectedMetrics={metricToShow}
            currencyCode={currentClient.currency}
            currentModelType={modelType}
            hasValidFilter={isSuccessIntegratedAttribution && hasValidFilters(integratedAttribution)}
            configName={capitalize(integratedAttribution?.name ?? 'current')}
          />
        ),
        !isAttributionLoaded ? null : (
          <DetailedReport
            dateRange={{ startDate, endDate }}
            currentIntegratedAttribution={integratedAttribution}
            data={detailedReportData}
            isDataLoading={!isAttributionLoaded}
            dataPrevTimePeriod={detailedReportPrevTimePeriodData}
            isMissingDataPrevTimePeriod={isMissingDataPrevTimePeriod}
            dataPrevYearTimePeriod={detailedReportPrevYearTimePeriodData}
            isMissingDataPrevYearTimePeriod={isMissingDataPrevYearTimePeriod}
            currencyCode={currentClient.currency}
            hasConversionsButNoRevenue={hasConversionsButNoRevenue}
          />
        ),
        !isAttributionLoaded ? null : (
          <ProgressOverTime
            reports={preprocessedAttributionData}
            isDataLoaded={isAttributionLoaded}
            selectedMetrics={metricToShow}
            currencyCode={currentClient.currency}
          />
        )
      ]}
    />
  )
}
export default Dashboard

const StyledHeader = styled(Typography)(() => ({
  marginBottom: '23px'
}))

const StyledCalendarWrapper = styled(Grid)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

const StyledModelName = styled(Typography)(() => ({
  marginBottom: '8px'
}))

const StyledLastEdited = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'inline-flex'
}))
