import Head from 'next/head'
import { type ReactNode } from 'react'

interface AuthRequiredLayoutProps {
  children: ReactNode
}

const AuthRequiredLayout = ({ children }: AuthRequiredLayoutProps): JSX.Element => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      {children}
    </>
  )
}

export default AuthRequiredLayout
