import React from 'react'

export const Share = React.forwardRef<SVGSVGElement>((props, ref): React.ReactElement => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <path
        d="M3 12C3 13.6569 4.34315 15 6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4338 18C13.4338 19.6569 14.777 21 16.4338 21C18.0907 21 19.4338 19.6569 19.4338 18C19.4338 16.3431 18.0907 15 16.4338 15C14.777 15 13.4338 16.3431 13.4338 18Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4338 5.95654C13.4338 7.6134 14.777 8.95654 16.4338 8.95654C18.0907 8.95654 19.4338 7.6134 19.4338 5.95654C19.4338 4.29969 18.0907 2.95654 16.4338 2.95654C14.777 2.95654 13.4338 4.29969 13.4338 5.95654Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2891 16.1866L8.79379 13.6012"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3854 7.71082L8.86005 10.3334"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
