import { useMutation, useQuery, useQueryClient, type UseMutationResult, type UseQueryResult } from 'react-query'
import { analytics, integrationAttributions, type fetch } from 'shared/api'

export const queryKeys = {
  integrationAttribution: 'integratedAttributions',
  integratedAttributionConfigStatus: 'integratedAttributionConfigStatus',
  integratedAttributionDagStatus: 'integratedAttributionDagStatus',
  budgetOptimser: 'budgetOptimiser'
}

export const useQueryIntegratedAttributions = (
  clientId: string
): UseQueryResult<integrationAttributions.IntegrationAttributionsResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.integrationAttribution, clientId],
    async () => await integrationAttributions.getIntegrationAtributions(clientId),
    {
      enabled: clientId !== ''
    }
  )
}

export const useQueryIntegratedAttribution = (
  modelId: number,
  clientId: string = '',
  enabled: boolean = true
): UseQueryResult<integrationAttributions.AttributionResp, fetch.ClientError> => {
  return useQuery(
    [queryKeys.integrationAttribution, modelId, clientId],
    async () => await integrationAttributions.getIntegrationAtribution(modelId, clientId),
    {
      enabled: clientId !== '' && enabled
    }
  )
}

export const useQueryDagStatusByModelId = (
  clientId: string,
  modelId: number,
  enabled: boolean = true
): UseQueryResult<[analytics.DagStatusResp] | [], fetch.ClientError> => {
  return useQuery(
    [queryKeys.integratedAttributionDagStatus, clientId, modelId],
    async () =>
      await analytics.postConfigStatusById<[analytics.DagStatusResp] | []>('attribution_dag', clientId, modelId, true),
    {
      enabled: clientId !== '' && enabled
    }
  )
}

export const useMutationAnalyticsAttribution = (): UseMutationResult<
  analytics.AttributionResponse[],
  fetch.ClientError,
  analytics.AnalyticsReq
> => {
  return useMutation(analytics.postAttribution)
}

export const useMutationAnalyticsAttributionDates = (): UseMutationResult<
  analytics.DatesInAttributionReportResp[],
  fetch.ClientError,
  analytics.AnalyticsReq
> => {
  return useMutation(analytics.postGetDatesInAttributionReport)
}

export const useQueryAttributionConfigStatuses = (
  clientId: string,
  enabled: boolean = true
): UseQueryResult<analytics.ConfigStatusResp[], fetch.ClientError> => {
  return useQuery(
    [queryKeys.integratedAttributionConfigStatus, clientId],
    async () => {
      return await analytics.postConfigStatuses('attribution_client_configs_status', clientId, true)
    },
    { enabled }
  )
}

interface AttributionConfigStatusByIdReq {
  clientId: string
  modelId: number
}
export const useMutationAttributionConfigStatusById = (): UseMutationResult<
  [analytics.ConfigStatusResp],
  fetch.ClientError,
  AttributionConfigStatusByIdReq
> => {
  return useMutation(async (data: AttributionConfigStatusByIdReq) => {
    return await analytics.postConfigStatusById<[analytics.ConfigStatusResp]>(
      'attribution_model_config_status',
      data.clientId,
      data.modelId,
      true
    )
  })
}

export const useMutationDeleteConfig = (): UseMutationResult<
  integrationAttributions.AttributionResp,
  fetch.ClientError,
  integrationAttributions.ConfigIdAndClientIdReq
> => {
  const queryClient = useQueryClient()

  return useMutation(integrationAttributions.deleteIntegrationAtribution, {
    onMutate: async (deletedConfig) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.budgetOptimser, deletedConfig.clientId] })
      await queryClient.cancelQueries({ queryKey: [queryKeys.integrationAttribution, deletedConfig.clientId] })

      const existingConfigs = queryClient.getQueryData([queryKeys.budgetOptimser, deletedConfig.clientId])

      queryClient.setQueryData<integrationAttributions.AttributionResp[]>(
        [queryKeys.budgetOptimser, deletedConfig.clientId],
        (oldConfigs) => {
          return oldConfigs != null ? oldConfigs.filter((config) => config.id !== deletedConfig.modelId) : []
        }
      )

      return { existingConfigs }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([queryKeys.budgetOptimser], context?.existingConfigs)
      queryClient.setQueryData([queryKeys.integrationAttribution], context?.existingConfigs)
    },
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.budgetOptimser)
      void queryClient.invalidateQueries(queryKeys.integrationAttribution)
    }
  })
}

export const useMutationCreateAttribution = (): UseMutationResult<
  integrationAttributions.AttributionResp,
  fetch.ClientError,
  integrationAttributions.AttributionModelReq
> => {
  return useMutation(integrationAttributions.createIntegrationAtribution)
}

export const useMutationUpdateAttribution = (): UseMutationResult<
  integrationAttributions.AttributionResp,
  fetch.ClientError,
  { data: integrationAttributions.AttributionModelReq; configId: number }
> => {
  const queryClient = useQueryClient()

  return useMutation(integrationAttributions.updateIntegrationAtribution, {
    onMutate: async (updatedConfigData) => {
      await queryClient.cancelQueries({
        queryKey: [queryKeys.integrationAttribution, updatedConfigData.configId, updatedConfigData.data.clientId]
      })
      await queryClient.cancelQueries({
        queryKey: [queryKeys.budgetOptimser, updatedConfigData.configId, updatedConfigData.data.clientId]
      })

      const previousConfig = queryClient.getQueryData<integrationAttributions.AttributionResp>([
        queryKeys.integrationAttribution,
        updatedConfigData.configId,
        updatedConfigData.data.clientId
      ])

      previousConfig != null &&
        queryClient.setQueryData(
          [queryKeys.integrationAttribution, updatedConfigData.configId, updatedConfigData.data.clientId],
          {
            ...previousConfig,
            ...updatedConfigData.data
          }
        )

      return { previousConfig, updatedConfigData }
    },
    onError: (_, __, context) => {
      context?.previousConfig != null &&
        queryClient.setQueryData(
          [
            queryKeys.integrationAttribution,
            context?.updatedConfigData.configId,
            context?.updatedConfigData.data.clientId
          ],
          context.previousConfig
        )
      queryClient.setQueryData(
        [queryKeys.budgetOptimser, context?.updatedConfigData.configId, context?.updatedConfigData.data.clientId],
        context?.previousConfig
      )
    },
    onSettled: () => {
      void queryClient.invalidateQueries(queryKeys.integrationAttribution)
      void queryClient.invalidateQueries(queryKeys.budgetOptimser)
    }
  })
}
