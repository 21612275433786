import {
  Autocomplete,
  type AutocompleteOptionItem,
  Badge,
  type BadgeColor,
  Grid,
  Typography,
  styled
} from '@precis-digital/kurama'
import { type Theme } from '@precis-digital/kurama/src/components/theme'
import DataSourcesAvatarGroup from 'channelGrouping/components/DataSourcesAvatarGroup'
import { useCurrentChannelGrouping } from 'channelGrouping/context/ChannelGroupingContext'
import { useCurrentChannelGroupingDataSource } from 'channelGrouping/context/ChannelGroupingDataSourceContext'
import React, { type ReactElement, useMemo, type SyntheticEvent } from 'react'
import { useTranslation } from 'shared/translations'

interface ChannelNameAutocompleteProps {
  channelName: string
  channelColor: BadgeColor
  handleNameChange: (event: SyntheticEvent<Element, Event>, value: AutocompleteOptionItem | null) => void
  placeholder: string
  noOptionsTextTemplate: string
  acceptCustomInputTemplate: string
}

const ChannelNameAutocomplete = ({
  channelName,
  channelColor,
  handleNameChange,
  placeholder,
  noOptionsTextTemplate,
  acceptCustomInputTemplate
}: ChannelNameAutocompleteProps): ReactElement => {
  const { channelsMap, dataSourcesMap } = useCurrentChannelGrouping()
  const { dataSourceItem } = useCurrentChannelGroupingDataSource()
  const { t } = useTranslation('channelGrouping')

  const dataSourceNamesInThisChannel = dataSourceItem.channels.map((channel) => channel.name)

  const channelsOptions = Object.values(channelsMap)
    .map((channelFromMap) => {
      const isAlreadyInThisDataSource = dataSourceNamesInThisChannel.includes(channelFromMap.name)
      const dataSourcesWithThisChannel = Object.values(dataSourcesMap).filter((dataSourceItem) => {
        return dataSourceItem.channels.some((channelItem) => channelItem.id === channelFromMap.id)
      })
      return {
        label: channelFromMap.name,
        value: channelFromMap.name,
        icon: <Badge color={channelFromMap.color} />,
        subCategory: isAlreadyInThisDataSource
          ? t('dataSourceFormView.channelsTab.tableCard.channelsAlreadyInDataSource')
          : t('dataSourceFormView.channelsTab.tableCard.channelsInOtherDataSources'),
        disabled: isAlreadyInThisDataSource,
        customLabelComponent: (
          <Grid display="flex" alignItems="center" justifyContent="space-between" width="100%" gap="8px">
            <StyledTypographyOptionTitle variant="body2" disabled={isAlreadyInThisDataSource}>
              {channelFromMap.name}
            </StyledTypographyOptionTitle>
            <DataSourcesAvatarGroup dataSources={dataSourcesWithThisChannel} size="small" max={2} />
          </Grid>
        )
      }
    })
    .sort((a, b) => {
      if (a.subCategory === b.subCategory) {
        return a.label.localeCompare(b.label)
      }
      return b.subCategory.localeCompare(a.subCategory)
    })

  const autoCompleteValue = useMemo(
    () => ({
      value: channelName,
      label: channelName,
      icon: <Badge color={channelColor} />
    }),
    [channelName, channelColor]
  )

  return (
    <Autocomplete
      value={autoCompleteValue}
      multiple={false}
      options={channelsOptions}
      onChange={handleNameChange}
      fullWidth
      allowCustomInput
      isOptionEqualToValue={(option, value) => option.value.toLowerCase() === value.toLowerCase()}
      placeholder={placeholder}
      noOptionsTextTemplate={noOptionsTextTemplate}
      acceptCustomInputTemplate={acceptCustomInputTemplate}
    />
  )
}

export const StyledTypographyOptionTitle = styled(Typography)(
  ({ disabled, theme }: { disabled?: boolean; theme?: Theme }) => ({
    color: disabled === true ? theme?.palette.neutrals.stone120 : undefined
  })
)

export default ChannelNameAutocomplete
