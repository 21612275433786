import type { NextApiResponse, NextApiRequest, NextApiHandler } from 'next'
import { assertApiReqRes } from '../shared/utils'
import { getSession } from './session'
import logger from 'shared/logger'
import { APP_ROUTES } from 'shared/routes'

const log = logger()

/**
 * Wraps an  API route to check if a user is logged in . If they're not logged in the
 * handler will redirect to the login page.
 */

export default function withApiAuthRequired(apiRoute: NextApiHandler): NextApiHandler {
  return async (req: NextApiRequest, res: NextApiResponse): Promise<void> => {
    assertApiReqRes(req, res)

    log.info('Validating if user is logged in')
    try {
      getSession(req, res)
    } catch (error) {
      log.info(`redirecting to ${APP_ROUTES.auth.signInPage}`)
      res.redirect(APP_ROUTES.auth.signInPage)
    }
    await apiRoute(req, res)
  }
}
