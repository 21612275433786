import {
  ArrowLeftIcon,
  Avatar,
  Button,
  Flyout,
  Frame,
  GlobalLoader,
  LockIcon,
  defaultTheme as theme,
  MimirIcon,
  PrecisAppsIcon,
  ShopIcon,
  SideNavigation,
  SwitchIcon,
  TopNavigation,
  SettingsIcon,
  Grid,
  Typography
} from '@precis-digital/kurama'
import { type NavContentItem } from '@precis-digital/kurama/src/components/SideNavigation/types'
import withPageAuthRequired from 'auth/WithPageAuthRequired'
import { useRouter } from 'next/router'
import { useQueryPrecisApps } from 'precisApp/api'
import { DEFAULT_PRECIS_APPS_TAB } from 'precisApp/constants'
import { useQueryUserProfile } from 'profile/api'
import { useCallback, useEffect, useMemo, useRef, useState, type ReactElement, type ReactNode } from 'react'
import { useIsFetching, useIsMutating } from 'react-query'
import { AvatarWithLabel } from 'shared/components/AvatarWithLabel'
import ErrInvalidClient from 'shared/components/Error/InvalidClient'
import ProgressBarLoader from 'shared/components/Loader/ProgressBarLoader'
import { RetainDefaultQueryLink as Link, useBreadcrumbs, useReload } from 'shared/components/Router'
import { ALVIE_APPS_REGISTRY } from 'shared/constants/apps'
import { useAuth } from 'shared/context/AuthContext'
import { useCurrentClient } from 'shared/context/ClientContext'
import ErrorReporterProvider from 'shared/context/ErrorReporterContext'
import { useMimir } from 'shared/context/MimirContext'
import useDashboardDataPreloader from 'shared/hooks/useDashboardDataPreloader'
import { useHasClientFeatureAccess } from 'shared/hooks/useHasFeatureAccess'
import { useNavButtons } from 'shared/hooks/useNavButtons'
import { identify as logRocketIdentify } from 'shared/logRocket'
import { LOCAL_STORAGE_KEYS, get, store } from 'shared/lstore'
import { boot as bootIntercom, update as updateIntercom } from 'shared/intercom'
import MimirPopover from 'shared/components/MimirPopover'
import NoAccessToFeature from 'shared/components/Error/NoAccessToFeature'
import { type ClientFeatureType } from 'shared/api/clients'
import 'shared/overScreens'
import { OverScreen } from 'shared/overScreens/niceModalReact'
import { useTranslation } from 'shared/translations'
import withInternalFeatureAccessCheck from 'shared/withInternalFeatureAccessCheck'
import FlyoutContent from 'workspace/components/Flyout'
import { ErrorFallback } from '../ErrorFallback'
import { APP_ROUTES } from 'shared/routes'
import config from 'shared/config'
import { FeatureFlagProvider } from 'shared/featureFlag'

const STATSIG_CLIENT_KEY = config('NEXT_PUBLIC_STATSIG_CLIENT_KEY') as string

export enum AvatarKind {
  label = 'label',
  image = 'image'
}

interface LinkProps {
  children: React.ReactNode
  href?: string
}

const getPathNameIndexes = (
  pathname: string,
  navContentItems: NavContentItem[]
): { primaryIndex: number | undefined; childIndex: number | undefined } => {
  let primaryIndex
  let childIndex
  for (let i = 0; i < navContentItems.length; i++) {
    const item = navContentItems[i]
    if ('path' in item) {
      if (pathname.startsWith(item.path)) {
        primaryIndex = i
      }
    } else if (item.subItems != null) {
      item.subItems.forEach((subItem, subIndex) => {
        const firstTwoSegmentsOfItemPath = subItem.path.split('/').slice(0, 3).join('/')
        if (pathname.startsWith(firstTwoSegmentsOfItemPath)) {
          primaryIndex = i
          childIndex = subIndex
        }
      })
    }
  }
  return { primaryIndex, childIndex }
}

const FrameWithAuthAndInternalAccessCheck = withInternalFeatureAccessCheck(
  withPageAuthRequired(Frame, { onRedirecting: () => <> </> })
)

const DashboardFrame = (props: { children?: React.ReactNode }): ReactElement => {
  const { t } = useTranslation('common')
  const { toggleMimir } = useMimir()
  const mimirButtonRef = useRef<HTMLButtonElement>(null)
  const { currentUser, logOut } = useAuth()
  const { data: currentUserDetails } = useQueryUserProfile(currentUser?.id as string)
  const { currentClient, isValidClient, error } = useCurrentClient()
  const [open, setIsOpen] = useState<boolean>(false)
  const [isSwitchWorkspace, setIsSwitchWorkspace] = useState<boolean>(false)
  const [isUserProfile, setIsUserProfile] = useState<boolean>(false)
  const [isAdminWorkspaceProfile, setIsAdminWorkspaceProfile] = useState<boolean>(false)
  const { checkClientAccessToFeature } = useHasClientFeatureAccess()
  const { isPreloaded, isSuccess: areAllPreloadedRequestsSuccessful } = useDashboardDataPreloader()
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  const isApiLoading = isFetching > 0 || isMutating > 0

  const router = useRouter()
  const breadcrumbs = useBreadcrumbs()
  const navButtons = useNavButtons()
  const reload = useReload()

  const [isSideNavExpanded, setIsSideNavExpanded] = useState<boolean>(() =>
    get(LOCAL_STORAGE_KEYS.SIDE_NAVIGATION_STATE) == null
      ? true
      : JSON.parse(get(LOCAL_STORAGE_KEYS.SIDE_NAVIGATION_STATE) as string)
  )

  const handleSideNavToggle = (): void => {
    store(LOCAL_STORAGE_KEYS.SIDE_NAVIGATION_STATE, (!isSideNavExpanded).toString())
    setIsSideNavExpanded(!isSideNavExpanded)
  }

  useEffect(() => {
    if (currentClient.id !== '' && currentUser?.id != null) {
      logRocketIdentify({
        user: currentUser,
        client: currentClient
      })
    }
  }, [currentClient, currentUser])

  useEffect(() => {
    if (isPreloaded && areAllPreloadedRequestsSuccessful && currentUser?.id != null && currentUser?.email != null) {
      const details = {
        email: currentUser?.email,
        userId: currentUser?.id,
        clientId: currentClient.id
      }
      bootIntercom(details)
      updateIntercom(details)
    }
  }, [currentUser, currentClient, areAllPreloadedRequestsSuccessful, isPreloaded])

  const { data: precisApps } = useQueryPrecisApps(currentClient.id, currentUser?.hasInternalFeatureAccess === true)

  const filteredAndSortedPrecisApps = useMemo(
    () =>
      precisApps
        ?.filter((app) => ['using', 'error'].includes(app?.clientMetadata?.status) && app.type !== 'alvie')
        .sort((a, b) => {
          if (a.clientMetadata.updatedAt < b.clientMetadata.updatedAt) {
            return 1
          }
          if (a.clientMetadata.updatedAt > b.clientMetadata.updatedAt) {
            return -1
          }
          return 0
        }) ?? [],
    [precisApps]
  )
  const getAdditionalSideNavPropsBasedOnAccess = useCallback(
    (featureId: ClientFeatureType, isSubItem?: boolean): { IconComponent?: ReactNode; textColor?: string } => {
      const clientAccess = checkClientAccessToFeature({ featureId })

      const icon = isSubItem === true && clientAccess?.hasAccess !== true ? <LockIcon /> : undefined

      return {
        ...(icon != null && { IconComponent: <LockIcon /> }),
        textColor: clientAccess?.hasAccess !== true ? theme.palette.neutrals.stone150 : undefined
      }
    },
    [checkClientAccessToFeature]
  )

  const navContentItems = useMemo(
    () => [
      {
        label: `${t('sideNavigation.home')}`,
        Link: (props: LinkProps) => {
          return <Link href={props.href ?? ''}>{props.children}</Link>
        },
        IconComponent: <ShopIcon />,
        path: '/'
      },
      {
        label: `${t('sideNavigation.setup')}`,
        IconComponent: <SettingsIcon />,
        subItems: [
          {
            label: `${t('sideNavigation.connectDataSource')}`,
            Link: (props: LinkProps) => {
              return <Link href={props.href ?? ''}>{props.children}</Link>
            },
            path: APP_ROUTES.dataSources.basePage
          },
          {
            label: `${t('sideNavigation.customGroupings')}`,
            Link: (props: LinkProps) => {
              return <Link href={props.href ?? ''}>{props.children}</Link>
            },
            path: APP_ROUTES.customGroupings.basePage
          }
        ]
      },
      {
        label: `${t('sideNavigation.dataAndReporting')}`,
        IconComponent: ALVIE_APPS_REGISTRY['bifrost-extract-connectors'].icon,
        subItems: [
          {
            label: `${t('sideNavigation.connectors')}`,
            Link: (props: LinkProps) => {
              return <Link href={props.href ?? ''}>{props.children}</Link>
            },
            path: ALVIE_APPS_REGISTRY['bifrost-extract-connectors'].uri,
            ...getAdditionalSideNavPropsBasedOnAccess('connectors', true)
          },
          {
            label: `${t('sideNavigation.reportingSolutions')}`,
            Link: (props: LinkProps) => {
              return <Link href={props.href ?? ''}>{props.children}</Link>
            },
            path: ALVIE_APPS_REGISTRY['precis-reporting-solutions'].uri,
            ...getAdditionalSideNavPropsBasedOnAccess('reportingSolutions', true)
          },
          {
            label: `${t('sideNavigation.enhancedSignals')}`,
            Link: (props: LinkProps) => {
              return <Link href={props.href ?? ''}>{props.children}</Link>
            },
            path: ALVIE_APPS_REGISTRY['enhanced-signals'].uri,
            ...getAdditionalSideNavPropsBasedOnAccess('enhancedSignals', true)
          }
        ]
      },
      {
        label: `${t('sideNavigation.marketingEvaluation')}`,
        IconComponent: ALVIE_APPS_REGISTRY['attribution-models'].icon,
        subItems: [
          {
            label: `${t('sideNavigation.attributionModels')}`,
            Link: (props: LinkProps) => {
              return <Link href={props.href ?? ''}>{props.children}</Link>
            },
            path: ALVIE_APPS_REGISTRY['attribution-models'].uri,
            ...getAdditionalSideNavPropsBasedOnAccess('attributionModels', true)
          },
          {
            label: `${t('sideNavigation.budgetOptimiser')}`,
            Link: (props: LinkProps) => {
              return <Link href={props.href ?? ''}>{props.children}</Link>
            },
            path: ALVIE_APPS_REGISTRY['budget-optimiser'].uri,
            ...getAdditionalSideNavPropsBasedOnAccess('budgetOptimiser', true)
          }
        ]
      },
      ...(currentUser?.hasInternalFeatureAccess === true
        ? [
            {
              label: `${t('sideNavigation.mimir')}`,
              IconComponent: ALVIE_APPS_REGISTRY.mimir.icon,
              subItems: [
                {
                  label: 'Chat',
                  Link: (props: LinkProps) => {
                    return <Link href={props.href ?? ''}>{props.children}</Link>
                  },
                  path: `${ALVIE_APPS_REGISTRY.mimir.uri}/chat`
                },
                {
                  label: 'Search',
                  Link: (props: LinkProps) => {
                    return <Link href={props.href ?? ''}>{props.children}</Link>
                  },
                  path: `${ALVIE_APPS_REGISTRY.mimir.uri}/search`
                }
              ]
            },
            {
              label: `${t('sideNavigation.precisApps')}`,
              IconComponent: <PrecisAppsIcon />,
              ...(filteredAndSortedPrecisApps.length > 0
                ? {
                    subItems: [
                      {
                        label: `${t('sideNavigation.allPrecisApps')}`,
                        Link: (props: LinkProps) => {
                          return <Link href={props.href ?? ''}>{props.children}</Link>
                        },
                        path: APP_ROUTES.precisApps.basePage
                      },
                      ...filteredAndSortedPrecisApps.map((app) => {
                        return {
                          label: app.title,
                          Link: (props: LinkProps) => {
                            return <Link href={props.href ?? ''}>{props.children}</Link>
                          },
                          path: APP_ROUTES.precisApps.appTabPage({ appId: `${app.id}`, tab: DEFAULT_PRECIS_APPS_TAB })
                        }
                      })
                    ]
                  }
                : {
                    Link: (props: LinkProps) => {
                      return <Link href={props.href ?? ''}>{props.children}</Link>
                    },
                    path: APP_ROUTES.precisApps.basePage
                  })
            }
          ]
        : [])
    ],
    [currentUser?.hasInternalFeatureAccess, filteredAndSortedPrecisApps, getAdditionalSideNavPropsBasedOnAccess, t]
  )

  const { primaryIndex, childIndex } = getPathNameIndexes(router.asPath, navContentItems)

  const defaultSideArgs = {
    onToogleClick: handleSideNavToggle,
    isExpanded: isSideNavExpanded,
    navContent: {
      content: navContentItems,
      selectedIndex: primaryIndex,
      selectedChildIndex: childIndex
    },
    navFooter: {
      AvatarComponent: <AvatarWithLabel user={currentUserDetails} />,
      accountActionslist: [
        {
          label: `${t('sideNavigation.publicProfile')}`,
          handleClick: () => {
            void OverScreen.show('profileFlyout', { id: currentUser?.id })
          }
        },
        {
          label: `${t('sideNavigation.logout')}`,
          handleClick: () => {
            logOut()
            reload()
          }
        }
      ]
    },
    navHeader: {
      Link: (props: LinkProps) => {
        return <Link href="/">{props.children}</Link>
      },
      label: 'alvie'
    }
  }

  const defaultNavArgs = {
    clientSection: {
      name: currentClient.name === '' ? undefined : currentClient.name,
      url: currentClient.url,
      IconComponent: <AvatarWithLabel {...(isValidClient ? { client: currentClient } : {})} />,
      handleOnClick: () => {
        if (isValidClient) {
          setIsOpen(true)
          setIsSwitchWorkspace(false)
          setIsUserProfile(false)
        }
      }
    },
    navActionsSection: [
      {
        IconComponent: <SwitchIcon />,
        onClick: () => {
          setIsSwitchWorkspace(true)
          setIsOpen(true)
        },
        ariaLabel: 'clients'
      },
      ...(currentUser?.hasInternalFeatureAccess === true
        ? [
            {
              IconComponent: <MimirIcon />,
              onClick: () => {
                toggleMimir()
              },
              ariaLabel: 'mimir',
              buttonRef: mimirButtonRef
            }
          ]
        : [])
    ],
    breadcrumbs,
    navButtons
  }

  const sideArgs = Object.assign(defaultSideArgs, {
    navFooter: Object.assign(defaultSideArgs.navFooter, {
      isExpanded: true,
      email: currentUser?.email ?? '',
      fullName: currentUser?.name ?? '',
      isNavExpanded: true,
      IconComponent: <Avatar kind={AvatarKind.label} label="je" />
    })
  })

  const SNavigation = <SideNavigation {...sideArgs} />

  const TNavigation = <TopNavigation {...defaultNavArgs} />

  const clientAccessToFeature = checkClientAccessToFeature({ route: router.asPath })

  const isValidClientWithAccess = isValidClient && clientAccessToFeature?.hasAccess !== false

  return !isPreloaded ? (
    <ProgressBarLoader />
  ) : (
    <>
      <Flyout
        onClose={() => {
          setIsOpen(false)
        }}
        isExpanded={open}
        topLeftAdornment={
          isAdminWorkspaceProfile ? (
            <Button
              variant="text"
              onClick={() => {
                setIsAdminWorkspaceProfile(false)
              }}
              leftIcon={<ArrowLeftIcon />}
            >
              {t('backToOverview')}
            </Button>
          ) : !isUserProfile && !isSwitchWorkspace ? (
            <Button
              variant="text"
              onClick={() => {
                setIsSwitchWorkspace(true)
              }}
              leftIcon={<SwitchIcon />}
            >
              {t('switchWorkspace')}
            </Button>
          ) : undefined
        }
      >
        <FlyoutContent
          isSwitchWorkspace={isSwitchWorkspace}
          isUserProfile={isUserProfile}
          setIsUserProfile={setIsUserProfile}
          handleFlyout={setIsOpen}
          isAdminWorkspaceProfile={isAdminWorkspaceProfile}
          setIsAdminWorkspaceProfile={setIsAdminWorkspaceProfile}
        />
      </Flyout>
      {config('APP_ENV') === 'stagingprod' && (
        <Grid
          container
          height="40px"
          width="100%"
          top="0px"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: theme.palette.semantic.error05 }}
        >
          <Typography variant="h4">
            Warning! This is an in-development deployment targeting our production api. Please be careful with changes.
          </Typography>
        </Grid>
      )}

      <FrameWithAuthAndInternalAccessCheck {...{ SideNavigation: SNavigation, TopNavigation: TNavigation }}>
        <MimirPopover anchorEl={mimirButtonRef.current} />

        <GlobalLoader isLoading={isApiLoading} />

        {isValidClientWithAccess && (
          <ErrorReporterProvider FallbackUI={ErrorFallback}>
            <FeatureFlagProvider
              platformKey={STATSIG_CLIENT_KEY}
              user={{
                userID: currentUser?.id as string,
                custom: {
                  clientId: currentClient.id,
                  clientName: currentClient.name
                },
                privateAttributes: {
                  email: currentUser?.email as string
                }
              }}
            >
              {props.children}
            </FeatureFlagProvider>
          </ErrorReporterProvider>
        )}
        {error == null && clientAccessToFeature?.hasAccess === false && (
          <NoAccessToFeature featureId={clientAccessToFeature.featureId} />
        )}
        {error != null && <ErrInvalidClient error={error} />}
      </FrameWithAuthAndInternalAccessCheck>
    </>
  )
}

export default DashboardFrame
